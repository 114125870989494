import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg, InlineLinkButton } from '@merchant/shared/components'
import { Flex, Tooltip, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { useBoolean } from 'usehooks-ts'
import { lang } from './lang'
import type { UnavailableCurrencyModalRef } from '../UnavailableCurrencyModal'
import type { TooltipProps } from '@merchant/ui-kit/ant-design'
import { useCurrencies, type ProjectAddress } from '~api'
import { ActionButton } from '~components'
import { QueryParams } from '~constants/routes'
import { Actions } from '~types/actionModal'
import { isCurrencyDepositAvailable } from '~utils'

const { Text } = Typography

type Props = {
    data: ProjectAddress
    openUnavailableCurrencyModal: UnavailableCurrencyModalRef['open'] | undefined
} & TooltipProps

export function DepositButton({ data, openUnavailableCurrencyModal, ...props }: Props) {
    const { data: currencies } = useCurrencies()
    const { value: isTooltipOpen, setValue: setIsTooltipOpen } = useBoolean(false)
    const currency = currencies?.[data.currency]
    const addressPayinEnabled = isCurrencyDepositAvailable(currency)

    return (
        <Tooltip
            open={isTooltipOpen}
            onOpenChange={setIsTooltipOpen}
            data-merchant={`${props['data-merchant']}-tooltip`}
            placement="bottom"
            title={
                !addressPayinEnabled && (
                    <Flex vertical>
                        <Text
                            type="danger"
                            style={{ fontSize: 12, fontWeight: 700 }}
                            data-merchant={`${props['data-merchant']}-tooltip-title`}
                        >
                            <FormattedMessage
                                {...lang.unavailableDeposits}
                                values={{ currency: data.currency, network: data.network || data.currency }}
                            />
                        </Text>
                        <InlineLinkButton
                            onClick={() => {
                                openUnavailableCurrencyModal?.({
                                    currency: data.currency,
                                    network: data.network || data.currency,
                                })
                                setIsTooltipOpen(false)
                            }}
                            style={{ fontSize: 12, textAlign: 'left', textWrap: 'wrap', textDecoration: 'underline' }}
                            data-merchant={`${props['data-merchant']}-tooltip-unavailable-deposits-button`}
                        >
                            <FormattedMessage
                                {...lang.unavailableDepositsButton}
                                values={{ currency: data.currency, network: data.network || data.currency }}
                            />
                        </InlineLinkButton>
                    </Flex>
                )
            }
        >
            <ActionButton
                context="deposits"
                action={Actions.deposit}
                data-merchant={props['data-merchant']}
                icon={<AppSvg name={assets.arrowDownStraight} />}
                label="deposit"
                disabled={!addressPayinEnabled}
                queryParams={{ [QueryParams.id]: data.id }}
            />
        </Tooltip>
    )
}
