import { useProject, useTotps } from '~api'

export function useData() {
    const { data: project, isLoading: isProjectLoading } = useProject()
    const { hasActiveTotp, isLoading: isTotpsLoading, mutate: mutateTotps } = useTotps()

    return {
        project,
        isProjectLoading,
        hasActiveTotp,
        isTotpsLoading,
        mutateTotps,
    }
}
