import { assets } from '@merchant/shared/assets/nameMap'
import {
    EllipsisMiddle,
    InlineLinkButton,
    AppSvg,
    CopyButton,
    StatisticFormattedNumber,
} from '@merchant/shared/components'
import { useBreakpoint } from '@merchant/shared/hooks'
import { getAddressTagName, getExplorerLink } from '@merchant/shared/utils'
import { Divider, Flex, Typography } from '@merchant/ui-kit/ant-design'
import { upperFirst } from 'lodash-es'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { lang as commonLang } from '../../lang'
import { lang } from './lang'
import { formatNumberToFractionDigits } from './utils'
import type { ContentRow } from './types'
import type { Withdrawal } from '~api'
import { useCurrencies, useWhitelist, WithdrawalState } from '~api'
import { NoValueColumnContent } from '~components'
import { globalLang } from '~globalLang'

const { Text } = Typography

interface Props {
    data: Partial<Withdrawal> & { whitelistKey?: string }
    context: 'summary' | 'progress'
    hash?: string | undefined
}

// eslint-disable-next-line max-lines-per-function, complexity
export function WithdrawalInformation({ data, context, hash }: Props) {
    const intl = useIntl()
    const { data: whitelistData } = useWhitelist()
    const { data: currencies } = useCurrencies()
    const breakpoints = useBreakpoint()

    const selectedCurrencyData = data.receiveCurrency ? currencies?.[data.receiveCurrency] : null
    const selectedNetworkData = selectedCurrencyData?.networks.find(
        networkObj => networkObj.code === data.receiveNetwork
    )
    const explorerLink = getExplorerLink({
        currencies,
        hash,
        currencyCode: data.receiveCurrency,
        networkCode: data.receiveNetwork,
    })
    const addressTagName = upperFirst(
        getAddressTagName(currencies, data.receiveCurrency, data.receiveNetwork) ||
            intl.formatMessage(globalLang.addressTag)
    )

    const whitelistedAddress = whitelistData?.addresses.find(item => item.key === data.whitelistKey)

    const contentRows: ContentRow[] = [
        {
            label: <FormattedMessage {...lang.whitelistName} />,
            content: <Text data-merchant="withdrawal-summary-whitelist-name">{whitelistedAddress?.name}</Text>,
            shouldSkip: !whitelistData?.enabled,
        },
        {
            label: <FormattedMessage {...lang.address} />,
            content: (
                <span>
                    <EllipsisMiddle
                        prefixCount={16}
                        suffixCount={16}
                        divider="."
                        data-merchant={`withdrawal-summary-address-value-${data.address}`}
                    >
                        {data.address}
                    </EllipsisMiddle>
                    &nbsp;
                    <CopyButton
                        data-merchant="withdrawal-summary-address-copy-button"
                        type="link"
                        inputValue={data.address || ''}
                        notificationContentSubject={lang.address}
                    />
                </span>
            ),
        },
        {
            label: <FormattedMessage {...lang.note} />,
            content: (
                <Text
                    data-merchant="withdrawal-summary-note"
                    ellipsis={{ tooltip: <Text data-merchant="withdrawal-summary-note-tooltip">{data.note}</Text> }}
                >
                    {data.note || NoValueColumnContent}
                </Text>
            ),
        },
        {
            label: addressTagName,
            content: (
                <>
                    <EllipsisMiddle
                        prefixCount={16}
                        suffixCount={16}
                        divider="."
                        data-merchant={`withdrawal-summary-address-tag-value-${data.addressTag}`}
                    >
                        {data.addressTag}
                    </EllipsisMiddle>
                    <CopyButton
                        data-merchant="withdrawal-summary-address-tag-copy-button"
                        type="link"
                        notificationContentSubject={addressTagName}
                        inputValue={data.addressTag || ''}
                    />
                </>
            ),
            shouldSkip: !data.addressTag,
        },
        {
            label: <FormattedMessage {...lang.receiveCurrency} />,
            content: (
                <span>
                    <Text data-merchant="withdrawal-summary-currency-name" type="secondary">
                        {selectedCurrencyData?.name}
                    </Text>{' '}
                    <Text data-merchant="withdrawal-summary-currency">{selectedCurrencyData?.code}</Text>
                </span>
            ),
        },
        {
            label: <FormattedMessage {...lang.receiveNetwork} />,
            shouldSkip: !selectedNetworkData,
            content: (
                <span>
                    <Text type="secondary" data-merchant="withdrawal-summary-network">
                        {selectedNetworkData?.protocol}
                    </Text>{' '}
                    {selectedNetworkData?.name}
                </span>
            ),
        },
        {
            label: <FormattedMessage {...lang.withdrawalAmount} />,
            content: (
                <Text data-merchant={null} style={{ fontWeight: context === 'summary' ? 600 : 400 }}>
                    <StatisticFormattedNumber
                        valueRender={value => <span data-merchant="withdrawal-summary-amount">{value}</span>}
                        value={data.creditAmount}
                        formatter={formatNumberToFractionDigits(2)}
                        suffix={selectedCurrencyData?.code}
                    />
                </Text>
            ),
        },
        {
            label: <FormattedMessage {...lang.withdrawalFee} />,
            content: (
                <Text data-merchant={null} style={{ fontWeight: context === 'summary' ? 600 : 400 }}>
                    <StatisticFormattedNumber
                        valueRender={value => <span data-merchant="withdrawal-summary-fee">{value}</span>}
                        value={data.networkFee}
                        formatter={formatNumberToFractionDigits(2)}
                        suffix={data.receiveCurrency}
                    />
                </Text>
            ),
        },
        {
            type: 'DIVIDER',
            shouldSkip: context !== 'summary',
        },
        {
            label: <FormattedMessage {...lang.receiveAmount} />,
            content: (
                <Text data-merchant={null} style={{ fontWeight: context === 'summary' ? 600 : 400 }}>
                    <StatisticFormattedNumber
                        valueRender={value => <span data-merchant="withdrawal-summary-receive-amount">{value}</span>}
                        value={data.receiveAmount}
                        formatter={formatNumberToFractionDigits(2)}
                        suffix={selectedCurrencyData?.code}
                    />
                </Text>
            ),
        },
        {
            shouldSkip: context !== 'progress' || !data.payoutTxnId,
            label: <FormattedMessage {...commonLang.transactionId} />,
            content: (
                <>
                    <Text data-merchant="withdrawal-summary-tx-id">{data.payoutTxnId}</Text>
                    <CopyButton
                        data-merchant="withdrawal-summary-tx-id-copy-button"
                        inputValue={data.payoutTxnId || ''}
                        type="link"
                        notificationContentSubject={commonLang.transactionId}
                    />
                </>
            ),
        },
        {
            shouldSkip: !data.id,
            label: <FormattedMessage {...lang.withdrawalId} />,
            content: (
                <>
                    <Text data-merchant="withdrawal-summary-id">{data.id}</Text>
                    <CopyButton
                        data-merchant="withdrawal-summary-id-copy-button"
                        inputValue={data.id || ''}
                        type="link"
                        notificationContentSubject={lang.withdrawalId}
                    />
                </>
            ),
        },
        {
            shouldSkip: context !== 'progress' || data.state === WithdrawalState.Failed || !hash,
            label: hash && (
                <Flex gap={4} align="center" data-merchant="withdrawal-summary-hash-label">
                    <FormattedMessage {...globalLang.hash} />
                    {explorerLink && (
                        <InlineLinkButton
                            target="_blank"
                            href={explorerLink}
                            data-merchant="withdrawal-explorer-link"
                            icon={<AppSvg size={16} name={assets.share} />}
                        />
                    )}
                </Flex>
            ),
            content: hash && (
                <>
                    <EllipsisMiddle
                        prefixCount={6}
                        suffixCount={6}
                        divider="."
                        data-merchant="withdrawal-summary-hash-value"
                    >
                        {hash}
                    </EllipsisMiddle>
                    <CopyButton
                        data-merchant="withdrawal-summary-hash-copy-button"
                        type="link"
                        inputValue={hash}
                        notificationContentSubject={globalLang.hash}
                    />
                </>
            ),
        },
    ]

    return (
        <Flex gap={16} vertical>
            {contentRows
                .filter(({ shouldSkip }) => !shouldSkip)
                .map(({ content, label, type }, index) =>
                    type === 'DIVIDER' ? (
                        <Divider key={index} style={{ marginBlock: 0 }} />
                    ) : (
                        <Flex
                            style={{ minHeight: 21 }}
                            key={index}
                            vertical={!breakpoints.md}
                            justify="space-between"
                            align="middle"
                            gap={12}
                        >
                            <Text data-merchant={null} type="secondary" style={{ textWrap: 'nowrap' }}>
                                {label}
                            </Text>
                            <Flex gap={8} align="center" style={{ maxWidth: '80%' }}>
                                {content}
                            </Flex>
                        </Flex>
                    )
                )}
        </Flex>
    )
}
