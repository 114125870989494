import { errorHandler } from './../'
import type { CurrencyExtended } from './types'
import type { SWRConfiguration } from 'swr'
import type { GetHoldingCurrenciesResponseInner } from '~api/instances/cabinet-api'
import type { Currencies } from '~api/types'

export const sortCurrenciesSkippingFirst = (currencies: CurrencyExtended[] = []) => {
    const firstCurrency = currencies[0]
    if (!firstCurrency) {
        return []
    }

    return [firstCurrency, ...currencies.slice(1).sort((a, b) => a.code.localeCompare(b.code))]
}

export const prepareCurrenciesForSaving = (
    currencies: CurrencyExtended[],
    defaultCurrency: string | undefined,
    currenciesMap: Currencies | undefined
): string[] => {
    const currencyCodesWithoutDefaultCurrency = currencies
        .filter(c => c.code !== defaultCurrency && currenciesMap?.[c.code]?.crypto)
        .map(c => c.code)

    return defaultCurrency
        ? [defaultCurrency, ...currencyCodesWithoutDefaultCurrency]
        : currencyCodesWithoutDefaultCurrency
}

export const getIfSetupIsFinished = (currencies?: GetHoldingCurrenciesResponseInner[]) => {
    return currencies ? currencies[0]?.selected : false
}

export function getDefaultSwrConfig(key: symbol): SWRConfiguration {
    return {
        onError: error => errorHandler.addError(key, error),
        onSuccess: () => errorHandler.removeError(key),
    }
}
