import { CopyButton, EllipsisMiddle } from '@merchant/shared/components'
import { getAddressTagName, parseDateAndTime } from '@merchant/shared/utils'
import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage, useIntl } from 'react-intl'
import { lang } from './lang'
import { WithdrawalInitiator } from './WithdrawalInitiator'
import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import type { Currencies } from '~api/types'
import { useCurrencies, type Network, type Withdrawal } from '~api'
import { NoValueColumnContent } from '~components'
import { getRow } from '~features/WithdrawalsPage/views/Withdrawal/utils'
import { useNetworksMap } from '~hooks'

const { Text } = Typography

type GetRows = ({
    data,
    networks,
    currencies,
    intl,
}: {
    data: Withdrawal
    networks: Record<string, Network>
    currencies: Currencies | undefined
    intl: IntlShape
}) => ReactNode[]

const getRows: GetRows = ({ data, networks, currencies, intl }) => {
    const createdAt = parseDateAndTime(data.createdAt)
    const network = data.receiveNetwork ? networks[data.receiveNetwork] : undefined
    const tagName = getAddressTagName(currencies, data.receiveCurrency, network?.code) || lang.addressTag

    return [
        getRow(
            lang.date,
            <>
                <Text type="secondary" data-merchant="withdrawal-timeline-created-date">
                    {createdAt?.date}
                </Text>
                &nbsp;
                <Text data-merchant="withdrawal-timeline-created-date">{createdAt?.time}</Text>
            </>
        ),
        getRow(
            lang.creditAmount,
            <Text data-merchant="withdrawal-timeline-creditAmount">
                {data.creditAmount} {data.creditCurrency}
            </Text>
        ),
        getRow(
            lang.receiveAmount,
            <Text data-merchant="withdrawal-timeline-receiveAmount">
                {data.receiveAmount} {data.receiveCurrency}
            </Text>
        ),
        getRow(
            lang.receiveNetwork,
            data.receiveNetwork ? (
                <Text data-merchant="withdrawal-timeline-receiveNetwork">
                    {network ? `${network.name} ${network.protocol}` : data.receiveNetwork}
                </Text>
            ) : (
                NoValueColumnContent
            )
        ),
        data.nominalReceiveAmount &&
            getRow(
                lang.nominalReceive,
                <Text data-merchant="withdrawal-timeline-nominalReceiveAmount">
                    {data.nominalReceiveAmount} {data.nominalReceiveCurrency}
                </Text>
            ),
        getRow(
            lang.address,
            <Flex gap={4} align="center">
                <EllipsisMiddle
                    data-merchant="withdrawal-timeline-address"
                    prefixCount={8}
                    suffixCount={10}
                    divider="."
                >
                    {data.address}
                </EllipsisMiddle>
                <CopyButton
                    data-merchant="withdrawal-timeline-address-copy-button"
                    inputValue={data.address}
                    type="text"
                    notificationContentSubject={lang.address}
                />
            </Flex>
        ),
        getRow(
            tagName,
            data.addressTag ? (
                <Flex gap={4} align="center">
                    <EllipsisMiddle
                        data-merchant="withdrawal-timeline-addressTag"
                        prefixCount={8}
                        suffixCount={10}
                        divider="."
                    >
                        {data.addressTag}
                    </EllipsisMiddle>
                    <CopyButton
                        data-merchant="withdrawal-timeline-addressTag-copy-button"
                        inputValue={data.addressTag}
                        type="text"
                        notificationContentSubject={tagName}
                    />
                </Flex>
            ) : (
                NoValueColumnContent
            )
        ),
        (data.createdBy?.projectMember || data.createdBy?.apiKey) &&
            getRow(intl.formatMessage(lang.initiator, { origin: data.origin }), <WithdrawalInitiator data={data} />),
    ]
}

interface Props {
    data: Withdrawal
}

export function CreatedTimeline({ data }: Props) {
    const { networks } = useNetworksMap()
    const { data: currencies } = useCurrencies()
    const intl = useIntl()

    return (
        <Flex vertical gap={16}>
            <Text data-merchant="withdrawal-timeline-created-label">
                <FormattedMessage {...lang.createdTimeline} />
            </Text>
            {getRows({ data, networks, currencies, intl })}
        </Flex>
    )
}
