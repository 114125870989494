/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Transaction } from './Transaction';
import {
    TransactionFromJSON,
    TransactionFromJSONTyped,
    TransactionToJSON,
} from './Transaction';

/**
 * 
 * @export
 * @interface ListTransactions200Response
 */
export interface ListTransactions200Response {
    /**
     * 
     * @type {string}
     * @memberof ListTransactions200Response
     */
    cursorNext?: string;
    /**
     * 
     * @type {string}
     * @memberof ListTransactions200Response
     */
    cursorPrev?: string;
    /**
     * 
     * @type {Array<Transaction>}
     * @memberof ListTransactions200Response
     */
    transactions: Array<Transaction>;
}

/**
 * Check if a given object implements the ListTransactions200Response interface.
 */
// @ts-ignore
export function instanceOfListTransactions200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "transactions" in value;

    return isInstance;
}

// @ts-ignore
export function ListTransactions200ResponseFromJSON(json: any): ListTransactions200Response {
    return ListTransactions200ResponseFromJSONTyped(json, false);
}

// @ts-ignore
export function ListTransactions200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListTransactions200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cursorNext': !exists(json, 'cursor_next') ? undefined : json['cursor_next'],
        'cursorPrev': !exists(json, 'cursor_prev') ? undefined : json['cursor_prev'],
        'transactions': ((json['transactions'] as Array<any>).map(TransactionFromJSON)),
    };
}

// @ts-ignore
export function ListTransactions200ResponseToJSON(value?: ListTransactions200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cursor_next': value.cursorNext,
        'cursor_prev': value.cursorPrev,
        'transactions': ((value.transactions as Array<any>).map(TransactionToJSON)),
    };
}

