import { AppCoin } from '@merchant/shared/components'
import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import { capitalize } from 'lodash-es'
import type { FiatWithdrawalMethod } from '~api/instances/cabinet-api'
import type { WithdrawalCurrency } from '~features/ActionModal/types'

const { Text } = Typography

export function getWithdrawalFiatCurrencySelectData(fiatCurrencies: Record<string, WithdrawalCurrency> | undefined) {
    return Object.values(fiatCurrencies || {}).map(fiatCurrency => {
        const { code = '', name } = fiatCurrency

        return {
            ...fiatCurrency,
            key: code,
            value: code,
            label: (
                <Flex key={code} gap={8} align="center">
                    <AppCoin currencyCode={code} size="small" />
                    <div style={{ marginRight: 'auto' }}>
                        <Text data-merchant={null}>{code}</Text>&nbsp;
                        <Text data-merchant={null} type="secondary">
                            {name}
                        </Text>
                    </div>
                </Flex>
            ),
        }
    })
}

export function getWithdrawalFiatMethodSelectData(fiatWithdrawalMethods: FiatWithdrawalMethod[] | undefined) {
    return fiatWithdrawalMethods?.map(fiatMethod => {
        return {
            ...fiatMethod,
            key: fiatMethod.name,
            value: fiatMethod.name,
            label: capitalize(fiatMethod.name),
        }
    })
}
