import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { menuKeys } from './const'
import { selectedMenuItemPredicate, shouldSkipMenuItemCheck } from './utils'
import type { useMenuItems } from './useMenuItems'

interface Props {
    menuItems: ReturnType<typeof useMenuItems>
}

export const useMenuKeys = ({ menuItems }: Props) => {
    const [selectedItem, setSelectedItem] = useState<string | null>(null)
    const [selectedParent, setSelectedParent] = useState<string | null>(null)
    const [openKeys, setOpenKeys] = useState<string[]>([])
    const location = useLocation()

    useEffect(() => {
        const locationPath = location.pathname + location.search
        if (shouldSkipMenuItemCheck(locationPath)) {
            return
        }

        const selectedMenuItem = Object.values(menuKeys).find(item => selectedMenuItemPredicate(item, locationPath))

        const selectedParent =
            menuItems
                .find(m => {
                    if (m && 'children' in m) {
                        return m.children?.find(c => c?.key === selectedMenuItem) !== undefined
                    }

                    return false
                })
                ?.key.toString() ?? null

        setSelectedParent(selectedParent)
        setSelectedItem(selectedMenuItem || locationPath)
    }, [location.pathname, location.search, location, menuItems])

    useEffect(() => {
        selectedParent && setOpenKeys(prev => Array.from(new Set(prev).add(selectedParent)))
    }, [selectedParent])

    return {
        selectedKey: selectedItem,
        expandedParent: selectedParent,
        areDefaultsSet: !!selectedItem,
        openKeys,
        setOpenKeys,
    }
}
