import type { ValuesAsKeys } from '@merchant/shared/types'
import { routes as ApiRoutes, QueryParams } from '~constants/routes'
import { AddressesShowOptions } from '~features/Addresses/const'
import { MerchantSettingsShowOptions } from '~features/MerchantPage/components/MerchantSettings/types'
import { MerchantTeamShowOptions } from '~features/MerchantPage/components/TeamManagement/types'
import { SettingsShowOptions } from '~features/SettingsPage/const'
import { WithdrawalsShowOptions } from '~features/WithdrawalsPage/const'
import { generateQuery } from '~utils'

export const menuKeys = {
    ordersParent: `${ApiRoutes.orders}_parent`,
    ordersHistory: ApiRoutes.orders,
    createOrder: ApiRoutes.ordersCreate,
    balances: ApiRoutes.balances,
    transactions: ApiRoutes.transactions,
    merchantParent: `${ApiRoutes.merchant}_parent`,
    merchantGeneral: `${ApiRoutes.merchantSettings}${generateQuery({ [QueryParams.show]: MerchantSettingsShowOptions.general })}`,
    merchantAssets: `${ApiRoutes.merchantSettings}${generateQuery({ [QueryParams.show]: MerchantSettingsShowOptions.assets })}`,
    merchantPaymentSettings: `${ApiRoutes.merchantSettings}${generateQuery({ [QueryParams.show]: MerchantSettingsShowOptions.paymentsSettings })}`,
    merchantTeamManagement: `${ApiRoutes.merchantTeam}${generateQuery({ [QueryParams.show]: MerchantTeamShowOptions.management })}`,
    merchantTeamAddMembers: `${ApiRoutes.merchantTeam}${generateQuery({ [QueryParams.show]: MerchantTeamShowOptions.addMembers })}`,
    addresses: `${ApiRoutes.addresses}_parent`,
    staticAddresses: `${ApiRoutes.addresses}${generateQuery({ [QueryParams.show]: AddressesShowOptions.staticAddresses })}`,
    depositAddresses: `${ApiRoutes.addresses}${generateQuery({ [QueryParams.show]: AddressesShowOptions.depositAddresses })}`,
    integrations: ApiRoutes.integrations,
    settingsParent: `${ApiRoutes.settings}_parent`,
    settingsGeneral: `${ApiRoutes.settings}${generateQuery({ [QueryParams.show]: SettingsShowOptions.general })}`,
    settingsSecurity: `${ApiRoutes.settings}${generateQuery({ [QueryParams.show]: SettingsShowOptions.security })}`,
    withdrawalsParent: `${ApiRoutes.withdrawals}_parent`,
    withdrawalsHistory: `${ApiRoutes.withdrawals}${generateQuery({ [QueryParams.show]: WithdrawalsShowOptions.history })}`,
    withdrawalsWhitelist: `${ApiRoutes.withdrawals}${generateQuery({ [QueryParams.show]: WithdrawalsShowOptions.whitelist })}`,
} as const

export const menuItemsPredicate: Partial<Record<ValuesAsKeys<typeof menuKeys>, (locationPath: string) => boolean>> = {
    [menuKeys.ordersHistory]: (locationPath: string) =>
        locationPath.startsWith(menuKeys.ordersHistory) && !locationPath.startsWith(menuKeys.createOrder),
    [menuKeys.balances]: (locationPath: string) => locationPath.startsWith(menuKeys.balances),
    [menuKeys.transactions]: (locationPath: string) => locationPath.startsWith(menuKeys.transactions),
    [menuKeys.createOrder]: (locationPath: string) => locationPath.startsWith(menuKeys.createOrder),
    [menuKeys.merchantTeamManagement]: (locationPath: string) => locationPath.startsWith(ApiRoutes.merchantTeam),
    [menuKeys.addresses]: (locationPath: string) => locationPath.startsWith(menuKeys.addresses),
    [menuKeys.depositAddresses]: (locationPath: string) => locationPath.startsWith(menuKeys.depositAddresses),
    [menuKeys.staticAddresses]: (locationPath: string) => locationPath.startsWith(menuKeys.staticAddresses),
    [menuKeys.withdrawalsHistory]: (locationPath: string) =>
        [menuKeys.withdrawalsHistory, ApiRoutes.withdrawals].some(path => locationPath.startsWith(path)) &&
        !locationPath.startsWith(menuKeys.withdrawalsWhitelist),
} as const

export const INLINE_INDENT = 12
