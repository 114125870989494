import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { Tooltip, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'

const { Text } = Typography

export function SuccessFiatWithdrawalTooltip() {
    return (
        <Tooltip
            overlayStyle={{ maxWidth: 320 }}
            title={
                <Text type="secondary" style={{ fontSize: 12 }} data-merchant={null}>
                    <FormattedMessage {...lang.successFiatWithdrawalTooltip} />
                </Text>
            }
            placement="bottom"
            data-merchant="success-fiat-withdrawal-tooltip"
        >
            <Text type="secondary" data-merchant="success-fiat-withdrawal-tooltip-target">
                <AppSvg name={assets.questionMark} />
            </Text>
        </Tooltip>
    )
}
