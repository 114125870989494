import type { PostHogProvider } from 'posthog-js/react'
import type { ComponentProps } from 'react'

export const getPosthogConfig = (): ComponentProps<typeof PostHogProvider> => {
    const isDevelopment = import.meta.env.MODE === 'development'

    if (isDevelopment) {
        return {}
    }

    return {
        apiKey: import.meta.env.VITE_POSTHOG_KEY,
        options: {
            api_host: import.meta.env.VITE_POSTHOG_API_HOST,
        },
    }
}
