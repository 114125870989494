import { HttpError } from '@merchant/shared/api'
import { selectedProjectIdKey } from '@merchant/shared/constants/localStorage'
import { useNotifications } from '@merchant/shared/contexts'
import { useMemo } from 'react'
import useSWR from 'swr'
import { useLocalStorage } from 'usehooks-ts'
import { apiRestKeys } from '../keys'
import { useAuthSwr } from '../useAuthSwr'
import { getNotFoundProjectNotification, isFiatWithdrawalFeeRequest } from './utils'
import type { ExtendedWhitelistData, WhitelistWithKey } from './types'
import type { AwaitedReturnType } from '@merchant/shared/api/types'
import type { SWRConfiguration, SWRResponse } from 'swr'
import type {
    GetProjectRequest,
    GetProjectSwapRateRequest,
    GetProjectSwapRequest,
    GetProjectFiatWithdrawalMethodsRequest,
    GetProjectFiatWithdrawalFeeRequest,
    GetProjectWithdrawalRequest,
    GetProjectBalancesSnapshotRequest,
    GetCurrenciesRequest,
    GetProjectAddressRequest,
} from '~api/instances/cabinet-api'
import { authApi, merchantApi, projectApi } from '~api'
import { WithdrawalState, TOTPStatus, Permission, MerchantStatus, ProjectStatus } from '~api/instances/cabinet-api'
import { nonEditableRoles } from '~constants/roles'
import { useUserPermissionContext } from '~contexts'
import { getWhitelistUniqueKey } from '~features/WhitelistPage/utils'

const WITHDRAWAL_REFRESH_INTERVAL = 5000
const BALANCES_REFRESH_INTERVAL = 30000

export const useMerchant = (config?: SWRConfiguration<AwaitedReturnType<typeof merchantApi.getMerchant>>) => {
    const swrResponse = useAuthSwr(apiRestKeys.getMerchant, () => merchantApi.getMerchant(), config)
    const isDisabled = swrResponse.data?.status === MerchantStatus.Disabled

    return { ...swrResponse, isDisabled }
}

export const useTotps = (config?: SWRConfiguration<AwaitedReturnType<typeof authApi.listTOTPs>>) => {
    const swrResponse = useAuthSwr(apiRestKeys.getTotps, () => authApi.listTOTPs(), {
        revalidateIfStale: true,
        ...config,
    })
    const hasActiveTotp = swrResponse.data?.some(totp => totp.status === TOTPStatus.Active)

    return { ...swrResponse, hasActiveTotp }
}

export const useProjects = ({ shouldFetch }: { shouldFetch: boolean } = { shouldFetch: true }) => {
    const [selectedProjectId, setSelectedProjectId] = useLocalStorage(selectedProjectIdKey, '')

    return useAuthSwr(shouldFetch ? apiRestKeys.getProjects : null, projectApi.listProjects.bind(projectApi), {
        onSuccess(data) {
            if (!selectedProjectId && data.thumbs[0]?.id) {
                setSelectedProjectId(data.thumbs[0].id)
            }
        },
    })
}

export const useProject = ({
    params,
    config,
}: {
    params?: GetProjectRequest
    config?: SWRConfiguration<AwaitedReturnType<typeof projectApi.getProject>>
} = {}) => {
    const [selectedProjectId, setSelectedProjectId] = useLocalStorage<string>(selectedProjectIdKey, '')
    const { data: projects } = useProjects({
        shouldFetch: !params?.projectId && !selectedProjectId,
    })
    const projectId = params?.projectId || selectedProjectId || projects?.thumbs[0]?.id || ''
    const {
        notification: { api: notificationApi },
    } = useNotifications()

    const projectSwr = useAuthSwr(
        projectId ? apiRestKeys.getProject + projectId : null,
        () => projectApi.getProject({ ...params, projectId }),
        {
            ...config,
            onSuccess(data, key, cfg) {
                if (!selectedProjectId) {
                    setSelectedProjectId(data.id)
                }
                config?.onSuccess?.(data, key, cfg)
            },
            onError(err, key, cfg) {
                if (HttpError.isInstance(err) && [404, 403, 400].includes(err.status || -1)) {
                    notificationApi.open({
                        message: getNotFoundProjectNotification(projectId),
                    })
                    setSelectedProjectId(projects?.thumbs[0]?.id || '')
                }
                config?.onError?.(err, key, cfg)
            },
        }
    )

    return {
        ...projectSwr,
        isDisabled: projectSwr.data?.status === ProjectStatus.Disabled,
    }
}

export const useApiKeys = () => {
    const { data: project } = useProject()
    const projectId = project?.id || ''
    const { checkUserPermission } = useUserPermissionContext()

    return useAuthSwr(
        projectId && checkUserPermission(Permission.SeeIntegration) ? apiRestKeys.getApiKey + projectId : null,
        () => projectApi.listAPIKeys({ projectId })
    )
}

export const useProjectCallback = () => {
    const { data: project } = useProject()
    const projectId = project?.id || ''
    const { checkUserPermission } = useUserPermissionContext()

    return useAuthSwr(
        projectId && checkUserPermission(Permission.SeeIntegration) ? apiRestKeys.getCallback + projectId : null,
        () => projectApi.getCallback({ projectId }),
        {
            shouldRetryOnError(err) {
                if (!(err instanceof HttpError)) {
                    return true
                }

                return err.status !== 404
            },
        }
    )
}

export const useBalances = () => {
    const { data: project } = useProject()
    const projectId = project?.id || ''
    const { checkUserPermission } = useUserPermissionContext()

    return useAuthSwr(
        projectId && checkUserPermission(Permission.SeeBalance) ? apiRestKeys.getBalances + projectId : null,
        () => projectApi.getProjectBalances({ projectId }),
        {
            revalidateIfStale: true,
            refreshInterval: BALANCES_REFRESH_INTERVAL,
        }
    )
}

export const useHoldingCurrencies = (
    config?: SWRConfiguration<AwaitedReturnType<typeof projectApi.getProjectHoldingCurrencies>> & {
        process?: (
            data?: AwaitedReturnType<typeof projectApi.getProjectHoldingCurrencies>
        ) => AwaitedReturnType<typeof projectApi.getProjectHoldingCurrencies> | undefined
    }
) => {
    const { data: project } = useProject()
    const projectId = project?.id || ''

    const { data, ...rest } = useAuthSwr(
        projectId ? apiRestKeys.getHoldingCurrencies + projectId : null,
        () => projectApi.getProjectHoldingCurrencies({ projectId }),
        config
    )

    const processed = config?.process?.(data) ?? data
    const hasSelectedHoldingCurrency = data?.some(({ selected }) => selected === true)

    const swrReturn = { ...rest, data: processed }

    return { ...swrReturn, data: processed, hasSelectedHoldingCurrency }
}

export function useCurrencies(
    params?: GetCurrenciesRequest,
    config?: SWRConfiguration<AwaitedReturnType<typeof merchantApi.getCurrencies>>,
    process?: never
): SWRResponse<AwaitedReturnType<typeof merchantApi.getCurrencies>>
export function useCurrencies<T>(
    params?: GetCurrenciesRequest,
    config?: SWRConfiguration<T>,
    process?: (data: AwaitedReturnType<typeof merchantApi.getCurrencies>) => T
): SWRResponse<T>
export function useCurrencies<T>(
    params: GetCurrenciesRequest = {},
    config?: SWRConfiguration<AwaitedReturnType<typeof merchantApi.getCurrencies>>,
    process?: unknown | ((data: AwaitedReturnType<typeof merchantApi.getCurrencies>) => T)
): SWRResponse<AwaitedReturnType<typeof merchantApi.getCurrencies>> | SWRResponse<T> {
    const { data, ...rest } = useSWR(
        `${apiRestKeys.getCurrencies}${Object.values(params).filter(Boolean).join('_')}`,
        () => merchantApi.getCurrencies(params),
        {
            revalidateIfStale: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            ...config,
        }
    )

    const processed = typeof process === 'function' ? process(data) : data

    return { data: processed, ...rest }
}

export const usePayments = ({ shouldFetch }: { shouldFetch: boolean } = { shouldFetch: true }) => {
    const { data: project } = useProject()
    const projectId = project?.id || ''

    return useAuthSwr(shouldFetch && projectId ? apiRestKeys.getPayments + projectId : null, () =>
        projectApi.listProjectPayments({ projectId })
    )
}

export const usePayment = (
    { orderId }: { orderId: string },
    config?: SWRConfiguration<AwaitedReturnType<typeof projectApi.getProjectPayment>>
) => {
    const { data: project } = useProject()
    const projectId = project?.id || ''

    return useAuthSwr(
        projectId ? apiRestKeys.getPayment + projectId + orderId : null,
        () => projectApi.getProjectPayment({ paymentId: orderId, projectId }),
        config
    )
}

export const useWhitelist = () => {
    const { data: project } = useProject()
    const projectId = project?.id || ''

    return useAuthSwr<ExtendedWhitelistData>(projectId ? apiRestKeys.getWhitelist + projectId : null, async () => {
        const whitelist = await projectApi.getProjectWhitelist({
            projectId,
        })
        const { addresses, enabled } = whitelist
        const formattedAddresses: WhitelistWithKey[] = addresses.map(item => ({
            ...item,
            key: getWhitelistUniqueKey(item),
        }))

        return { addresses: formattedAddresses, enabled }
    })
}

export const useSwapRate = (
    {
        shouldFetch,
        getKey,
        params,
    }: {
        shouldFetch: boolean
        getKey: (key: string) => Record<string, unknown>
        params: Omit<GetProjectSwapRateRequest, 'projectId'>
    },
    config?: SWRConfiguration<AwaitedReturnType<typeof projectApi.getProjectSwapRate>>
) => {
    const { data: project } = useProject()
    const projectId = project?.id || ''

    return useAuthSwr(
        shouldFetch && projectId ? getKey(apiRestKeys.getSwapRate + projectId) : null,
        () => projectApi.getProjectSwapRate({ ...params, projectId }),
        { dedupingInterval: 0, ...config }
    )
}

export const useSwapData = (
    { params }: { params: Omit<GetProjectSwapRequest, 'projectId'> },
    config?: SWRConfiguration<AwaitedReturnType<typeof projectApi.getProjectSwap>>
) => {
    const { data: project } = useProject()
    const projectId = project?.id || ''

    return useAuthSwr(
        projectId ? apiRestKeys.getSwapData + projectId + params.id : null,
        () => projectApi.getProjectSwap({ ...params, projectId }),
        config
    )
}

export const useProjectBlocklist = () => {
    const { data: project } = useProject()
    const projectId = project?.id || ''

    return useAuthSwr(projectId ? apiRestKeys.getProjectBlocklist + projectId : null, () =>
        projectApi.getProjectBlocklist({ projectId })
    )
}

export const useWithdrawal = (
    {
        params,
        shouldFetch,
    }: {
        params: Omit<GetProjectWithdrawalRequest, 'projectId'>
        shouldFetch: boolean
    },
    config?: SWRConfiguration<AwaitedReturnType<typeof projectApi.getProjectWithdrawal>>
) => {
    const { data: project } = useProject()
    const projectId = project?.id || ''

    return useAuthSwr(
        shouldFetch && projectId ? apiRestKeys.getWithdrawal + projectId + params.withdrawalId : null,
        () => projectApi.getProjectWithdrawal({ ...params, projectId }),
        {
            shouldRetryOnError: true,
            refreshInterval: latestData => {
                return !latestData ||
                    latestData.state === WithdrawalState.Failed ||
                    latestData.state === WithdrawalState.Success
                    ? 0
                    : WITHDRAWAL_REFRESH_INTERVAL
            },
            ...config,
        }
    )
}

export const useFiatWithdrawalMethods = (
    {
        params,
        shouldFetch,
    }: {
        params: Omit<GetProjectFiatWithdrawalMethodsRequest, 'projectId'>
        shouldFetch: boolean
    },
    config?: SWRConfiguration<AwaitedReturnType<typeof projectApi.getProjectFiatWithdrawalMethods>>
) => {
    const { data: project } = useProject()
    const projectId = project?.id || ''

    return useAuthSwr(
        shouldFetch && projectId ? `${apiRestKeys.getFiatWithdrawalMethods}_${projectId}_${params.currency}` : null,
        () =>
            projectApi.getProjectFiatWithdrawalMethods({
                ...params,
                projectId,
            }),
        config
    )
}

export function useFiatWithdrawalFee({
    params,
    shouldFetch,
}: {
    params: Partial<GetProjectFiatWithdrawalFeeRequest>
    shouldFetch: boolean
}) {
    const areFiatWithdrawalFeeParamsValid = isFiatWithdrawalFeeRequest(params)
    const { data: project } = useProject()
    const projectId = project?.id || ''

    return useAuthSwr(
        areFiatWithdrawalFeeParamsValid && projectId && shouldFetch
            ? `${apiRestKeys.getFiatMethodFee}_${projectId}_${params.amount}_${params.currency}_${params.method}`
            : null,
        () =>
            areFiatWithdrawalFeeParamsValid && projectId && shouldFetch
                ? projectApi.getProjectFiatWithdrawalFee({
                      ...params,
                      projectId,
                  })
                : null
    )
}

export function useProjectTeamMembers(
    config?: SWRConfiguration<AwaitedReturnType<typeof projectApi.getProjectTeamMembers>>
) {
    const { checkUserPermission } = useUserPermissionContext()
    const { data: project } = useProject()
    const projectId = project?.id || ''

    return useAuthSwr(
        projectId && checkUserPermission(Permission.SeeProjectTeamMembers)
            ? apiRestKeys.getProjectTeamMembers + projectId
            : null,
        () => projectApi.getProjectTeamMembers({ projectId }),
        {
            shouldRetryOnError: err => !(err instanceof HttpError) || err.status !== 404,
            ...config,
        }
    )
}

export function useProjectRoles(config?: SWRConfiguration<AwaitedReturnType<typeof projectApi.getProjectRoles>>) {
    const { data: project } = useProject()
    const projectId = project?.id || ''

    return useAuthSwr(
        projectId ? apiRestKeys.getProjectRoles + projectId : null,
        () => projectApi.getProjectRoles({ projectId }),
        config
    )
}

export function useEditableProjectRoles() {
    const { data, ...rest } = useProjectRoles()

    const editableRoles = useMemo(() => data?.filter(role => !nonEditableRoles.includes(role.id)), [data])

    return {
        data: editableRoles,
        ...rest,
    }
}

export function useProjectPermissions(
    config?: SWRConfiguration<AwaitedReturnType<typeof projectApi.listAllPermissions>>
) {
    const { data: project } = useProject()
    const projectId = project?.id || ''

    return useAuthSwr(
        projectId ? apiRestKeys.getProjectPermissions + projectId : null,
        () => projectApi.listAllPermissions({ projectId }),
        config
    )
}

export function useBalanceSnapshots(
    params: Omit<GetProjectBalancesSnapshotRequest, 'projectId'>,
    config?: SWRConfiguration<AwaitedReturnType<typeof projectApi.getProjectBalancesSnapshot>>
) {
    const { data: project } = useProject()
    const projectId = project?.id || ''

    return useAuthSwr(
        [apiRestKeys.getBalanceSnapshots, params.currencies, params.date.toISOString(), params.includeZeroes].join('-'),
        () => projectApi.getProjectBalancesSnapshot({ projectId, ...params }),
        config
    )
}

export function useWithdrawals(config?: SWRConfiguration<AwaitedReturnType<typeof projectApi.listProjectWithdrawals>>) {
    const { data: project } = useProject()
    const projectId = project?.id || ''

    return useAuthSwr(apiRestKeys.getProjectWithdrawals, () => projectApi.listProjectWithdrawals({ projectId }), config)
}

export function useApiKeyPermissions() {
    const { data: project } = useProject()
    const projectId = project?.id || ''

    return useAuthSwr(apiRestKeys.getApiKeyPermissions, async () => projectApi.listAPIKeysPermissions({ projectId }), {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    })
}

export function useDepositAddress(
    params: Omit<GetProjectAddressRequest, 'projectId'>,
    config?: SWRConfiguration<AwaitedReturnType<typeof projectApi.getProjectAddress>>
) {
    const { data: project } = useProject()
    const projectId = project?.id || ''

    return useAuthSwr(
        apiRestKeys.getDepositAddress + params.addressId,
        () => projectApi.getProjectAddress({ projectId, ...params }),
        config
    )
}
