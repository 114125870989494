import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    modalTitle: {
        id: 'whitelist-page.modal.delete_whitelist_modal_title',
        defaultMessage: 'Delete address from whitelist',
    },
    submitButton: {
        id: 'whitelist-page.button.delete_whitelist_modal_submit_button',
        defaultMessage: 'Confirm',
    },
    deleteWhitelistAlertDescription: {
        id: 'whitelist-page.button.delete_whitelist_modal_alert_description',
        defaultMessage:
            'All withdrawals from your account will be suspended for <boldText>72 hours</boldText> every time you delete withdrawal address. This is a security measure for the safety of your funds.',
    },
    successNotificationTitle: {
        id: 'whitelist-page.notification.delete_whitelist_modal_success_title',
        defaultMessage: 'Your address has been deleted',
    },
    successNotificationDescription: {
        id: 'whitelist-page.notification.delete_whitelist_modal_success_description',
        defaultMessage: 'You have successfully deleted your address from the whitelist',
    },
})
