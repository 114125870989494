import { assets } from '@merchant/shared/assets/nameMap'
import { InlineLinkButton, AppSvg } from '@merchant/shared/components'
import { useBreakpoint } from '@merchant/shared/hooks'
import { Button, Col, Divider, Flex, Row, Space, Switch, Table, Typography } from '@merchant/ui-kit/ant-design'
import cn from 'classnames'
import { isEmpty, noop } from 'lodash-es'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
    DeleteApiKeyModal,
    EditApiKeyModal,
    CreateApiKeyModal,
    CallbackUrls,
    ToggleApiKeyStatusModal,
    PermissionsColumn,
} from './components'
import { integrationCommonButtonStyle } from './components/CallbackUrls/const'
import { Modes, columnDataIndexes, deactivatedStyles } from './const'
import { lang } from './lang'
import styles from './style.module.css'
import { useData } from './useData'
import { getDateContent, getKeyContent } from './utils'
import type { Breakpoints } from '@merchant/shared/hooks'
import type { ColumnsType } from '@merchant/ui-kit/ant-design/es/table'
import { type APIKey } from '~api/instances/cabinet-api'
import { NoValueColumnContent, PageContentContainer, SkeletonTable } from '~components'
import { ActionSuspendedAlert } from '~components/ActionSuspended/ActionSuspendedAlert'
import { SuspensionContext } from '~components/ActionSuspended/types'
import { withDirtyCheckingContextProvider } from '~hoc/withDirtyCheckingContextProvider'

const { Text, Title, Paragraph } = Typography

type GetColumns = (params: {
    handleTableActionClick: (record: APIKey, mode: Modes) => void
    breakpoints: Breakpoints
    isIntegrationEditAllowed: boolean
}) => ColumnsType<APIKey>

// eslint-disable-next-line max-lines-per-function
const getColumns: GetColumns = ({ handleTableActionClick, breakpoints, isIntegrationEditAllowed }) => {
    const columns: ColumnsType<APIKey> = [
        {
            title: <FormattedMessage {...lang.status} />,
            key: columnDataIndexes.active,
            dataIndex: columnDataIndexes.active,
            className: cn({ [styles.statusColumnMd || '']: !breakpoints.lg }),
            render: (active: APIKey['active'], record) => (
                <Switch
                    data-merchant={`${record.id}-api-key-status-switch`}
                    disabled={!isIntegrationEditAllowed}
                    checked={active}
                    onClick={() => handleTableActionClick(record, Modes.toggleStatus)}
                />
            ),
            width: 60,
        },
        {
            title: <FormattedMessage {...lang.key} />,
            onCell: ({ id }) => ({ 'data-merchant': `${id}-public-key-cell` }) as React.HTMLAttributes<HTMLElement>,
            key: columnDataIndexes.publicKey,
            dataIndex: columnDataIndexes.publicKey,
            hidden: !breakpoints.lg,
            render: (_, record) => getKeyContent(record, breakpoints),
        },
        {
            title: <FormattedMessage {...lang.key} />,
            key: 'title&publicKey&createdAt&createdBy',
            hidden: breakpoints.lg || !breakpoints.md,
            render: (_, record) => (
                <Flex vertical>
                    <Text data-merchant={null}>{getKeyContent(record, breakpoints)}</Text>
                    <Flex gap={2}>{getDateContent(record.createdAt, { isActive: record.active })}</Flex>
                    <Paragraph data-merchant={null} type="secondary" style={{ marginBlock: 0 }}>
                        <FormattedMessage
                            {...lang.createdByCompact}
                            values={{ name: <Text data-merchant={null}>{record.createdBy?.name}</Text> }}
                        />
                    </Paragraph>
                    <Paragraph data-merchant={null} type="secondary" style={{ marginBlock: 0 }}>
                        {record.createdBy?.email}
                    </Paragraph>
                </Flex>
            ),
            width: 'auto',
        },
        {
            title: (
                <>
                    <FormattedMessage {...lang.key} />, <FormattedMessage {...lang.created} />,{' '}
                    <FormattedMessage {...lang.lastUse} />, <FormattedMessage {...lang.createdBy} />,{' '}
                    <FormattedMessage {...lang.permissions} />
                </>
            ),
            key: 'all_info',
            hidden: breakpoints.md,
            render: (_, record) => (
                <Flex vertical>
                    <Text data-merchant={null}>{getKeyContent(record, breakpoints)}</Text>
                    <Text data-merchant={null} type="secondary">
                        <FormattedMessage
                            {...lang.createdCompact}
                            values={{
                                date: (
                                    <Text data-merchant={null}>
                                        {getDateContent(record.createdAt, {
                                            isActive: record.active,
                                            isCompact: true,
                                        })}
                                    </Text>
                                ),
                            }}
                        />
                    </Text>
                    <Text data-merchant={null} type="secondary">
                        <FormattedMessage
                            {...lang.lastUseCompact}
                            values={{
                                date: (
                                    <Text data-merchant={null}>
                                        {getDateContent(record.lastUsedAt, {
                                            isActive: record.active,
                                            isCompact: true,
                                        })}
                                    </Text>
                                ),
                            }}
                        />
                    </Text>
                    <Paragraph data-merchant={null} type="secondary" style={{ marginBlock: 0 }}>
                        <FormattedMessage
                            {...lang.createdByCompact}
                            values={{ name: <Text data-merchant={null}>{record.createdBy?.name}</Text> }}
                        />
                    </Paragraph>
                    <Paragraph ellipsis={{ rows: 2 }} data-merchant={null} type="secondary" style={{ marginBlock: 0 }}>
                        {record.createdBy?.email}
                    </Paragraph>
                    <Divider style={{ marginBlock: 8 }} />
                    <Paragraph data-merchant={null} style={{ marginBlock: 0, fontWeight: 700 }}>
                        <FormattedMessage {...lang.permissionsCompact} />
                    </Paragraph>
                    <PermissionsColumn apiKey={record} compact />
                </Flex>
            ),
            width: 'auto',
        },
        {
            title: (
                <div style={{ textWrap: 'nowrap' }}>
                    <FormattedMessage {...lang.created} />
                </div>
            ),
            hidden: !breakpoints.lg,
            key: columnDataIndexes.createdAt,
            dataIndex: columnDataIndexes.createdAt,
            render: (_, { createdAt, id, active }) => (
                <Flex vertical={!breakpoints.lg} gap={2} data-merchant={`${id}-api-key-created-at`}>
                    {getDateContent(createdAt, { isActive: active })}
                </Flex>
            ),
        },
        {
            hidden: !breakpoints.md,
            key: columnDataIndexes.lastUsedAt,
            dataIndex: columnDataIndexes.lastUsedAt,
            title: <FormattedMessage {...lang.lastUse} />,
            width: 120,
            render: (_, { lastUsedAt, id, active }) => (
                <span data-merchant={`${id}-api-key-last-used-at`}>
                    {getDateContent(lastUsedAt, { isActive: active })}
                </span>
            ),
        },
        {
            hidden: !breakpoints.lg,
            key: columnDataIndexes.createdBy,
            dataIndex: columnDataIndexes.createdBy,
            title: <FormattedMessage {...lang.createdBy} />,
            render: (createdBy: APIKey['createdBy']) =>
                createdBy ? (
                    <Paragraph
                        data-merchant={`${createdBy.id}-api-key-created-by`}
                        ellipsis={{
                            rows: 2,
                            tooltip: {
                                'data-merchant': `${createdBy.id}-api-key-created-by-tooltip`,
                                placement: breakpoints.md ? 'bottom' : 'bottomRight',
                                overlayInnerStyle: { minWidth: 300 },
                            },
                        }}
                    >
                        {createdBy.name && <div>{createdBy.name}</div>}
                        <Text data-merchant={null} type="secondary">
                            {createdBy.email}
                        </Text>
                    </Paragraph>
                ) : (
                    NoValueColumnContent
                ),
            width: 180,
        },
        {
            hidden: !breakpoints.md,
            key: columnDataIndexes.permissions,
            dataIndex: columnDataIndexes.permissions,
            title: <FormattedMessage {...lang.permissions} />,
            render: (_, apiKey) => <PermissionsColumn apiKey={apiKey} />,
            width: 120,
        },
        {
            title: <FormattedMessage {...lang.actions} />,
            key: 'actions',
            render: (_, record) => (
                <Flex gap={8} justify="flex-end">
                    <InlineLinkButton
                        disabled={!isIntegrationEditAllowed}
                        style={{ verticalAlign: 'middle' }}
                        icon={<AppSvg size={20} name={assets.edit} />}
                        data-merchant={`${record.id}-edit-api-key-button`}
                        onClick={() => handleTableActionClick(record, Modes.edit)}
                    />
                    <InlineLinkButton
                        disabled={!isIntegrationEditAllowed}
                        data-merchant={`${record.id}-delete-api-key-button`}
                        style={{ verticalAlign: 'middle' }}
                        icon={<AppSvg size={20} name={assets.trash} />}
                        onClick={() => handleTableActionClick(record, Modes.delete)}
                    />
                </Flex>
            ),
            align: 'right',
            width: 55,
        },
    ]

    return columns.map(column => ({
        ...column,
        onCell: row => ({ ...column.onCell?.(row), style: row.active ? undefined : deactivatedStyles }),
    }))
}

// eslint-disable-next-line max-lines-per-function
function Component() {
    const breakpoints = useBreakpoint()

    const {
        apiKeys,
        handleCreateSuccess,
        handleDeleteSuccess,
        handleEditSuccess,
        handleStatusToggleSuccess,
        handleTableActionClick,
        handleGenerateApiKeyClick,
        isApiKeysLoading,
        mode,
        setMode,
        selectedApiKey,
        resetModals,
        isIntegrationEditAllowed,

        closeSuspensionModal,
        isSuspensionModalOpen,
        projectBlocklist,
        resolveBlocklist,
    } = useData()

    const isBlocked = projectBlocklist?.blocked
    const isDataEmpty = isEmpty(apiKeys)

    return (
        <>
            <DeleteApiKeyModal
                open={mode === Modes.delete}
                apiKeyId={selectedApiKey?.id}
                onCancel={resetModals}
                onModalSuccess={handleDeleteSuccess}
            />
            <EditApiKeyModal
                open={mode === Modes.edit}
                selectedApiKey={selectedApiKey}
                onDeleteClick={() => setMode(Modes.delete)}
                onCancel={resetModals}
                onModalSuccess={handleEditSuccess}
            />
            <CreateApiKeyModal
                open={mode === Modes.create}
                onCancel={resetModals}
                onCreateSuccess={handleCreateSuccess}
                closeModal={resetModals}
            />
            <ToggleApiKeyStatusModal
                open={mode === Modes.toggleStatus}
                selectedApiKey={selectedApiKey}
                onModalSuccess={handleStatusToggleSuccess}
                onCancel={resetModals}
            />
            {isBlocked && (
                <div style={{ marginTop: 12 }}>
                    <ActionSuspendedAlert
                        isModalOpen={isSuspensionModalOpen}
                        onClose={closeSuspensionModal}
                        expiresAt={projectBlocklist.blockItems[0]?.expiresAt}
                        blockReason={projectBlocklist.blockItems[0]?.reason}
                        context={SuspensionContext.Integration}
                    />
                </div>
            )}
            <PageContentContainer styles={{ body: { padding: !breakpoints.md ? 12 : undefined } }}>
                <Row justify="space-between" align="middle">
                    <Col style={{ fontSize: 16 }}>
                        <FormattedMessage {...lang.apiKeys} />
                    </Col>
                    <Col>
                        <Button
                            size="middle"
                            style={integrationCommonButtonStyle}
                            disabled={!isIntegrationEditAllowed}
                            data-merchant="add-api-key-button"
                            type="primary"
                            onClick={handleGenerateApiKeyClick}
                        >
                            <FormattedMessage {...lang.generateApiKey} />
                        </Button>
                    </Col>
                </Row>
                {!isDataEmpty && <Divider style={{ margin: '12px 0 0' }} />}
                <SkeletonTable
                    loading={isApiKeysLoading}
                    active={isApiKeysLoading}
                    rowCount={3}
                    columns={getColumns({
                        handleTableActionClick: noop,
                        breakpoints,
                        isIntegrationEditAllowed,
                    })}
                    dataMerchant="api-keys-skeleton-table"
                >
                    <Table<APIKey>
                        showHeader={!isDataEmpty}
                        data-merchant="api-keys-table"
                        locale={{
                            emptyText: (
                                <Space direction="vertical" size={8} style={{ marginBlock: 24 }}>
                                    <Title data-merchant="empty-api-keys-title" level={3}>
                                        <FormattedMessage {...lang.emptyApiKeysTitle} />
                                    </Title>
                                    <Title data-merchant="empty-api-keys-subtitle" level={5}>
                                        <FormattedMessage {...lang.emptyApiKeysSubtitle} />
                                    </Title>
                                </Space>
                            ),
                        }}
                        tableLayout={!breakpoints.lg ? 'auto' : 'fixed'}
                        dataSource={apiKeys}
                        rowKey="id"
                        columns={getColumns({
                            handleTableActionClick,
                            breakpoints,
                            isIntegrationEditAllowed,
                        })}
                        pagination={false}
                        scroll={{
                            x: breakpoints.md ? true : undefined,
                            y: breakpoints.md ? 400 : 600,
                        }}
                        onRow={({ id }) => ({
                            'data-merchant': `${id}-integrations-table-row`,
                            style: { fontSize: 14 },
                        })}
                    />
                </SkeletonTable>
            </PageContentContainer>
            <CallbackUrls resolveBlocklist={resolveBlocklist} isIntegrationEditAllowed={isIntegrationEditAllowed} />
        </>
    )
}

export const Integrations = withDirtyCheckingContextProvider(Component)
