/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  APIErrorResponse,
  APIKey,
  AddProjectTeamMembersRequest,
  Balance,
  BalanceSnapshot,
  Blocklist,
  CalcWithdrawalAmountsRequest,
  Callback,
  CallbackResponse,
  CreateAPIKeyRequest,
  CreateCustomerAddressRequest,
  CreateCustomerAddressResponse,
  CreateFiatWithdrawalRequest,
  CreateFiatWithdrawalResponse,
  CreatePaymentRequest,
  CreateProjectAddressRequest,
  CreateProjectRequest,
  CreateWithdrawalRequest,
  CustomerAddress,
  DeleteAPIKeyRequest,
  DeleteCallbackRequest,
  FiatWithdrawalMethod,
  GetHoldingCurrenciesResponseInner,
  GetWhitelistResponse,
  GetWithdrawalFeeResponse,
  ListAddresses200Response,
  ListAllPermissions200Response,
  ListPayments200Response,
  ListProjectAddresses200Response,
  ListProjectWithdrawals200Response,
  ListProjectsResponse,
  ListTransactions200Response,
  Payment,
  PaymentState,
  PaymentSwapConfig,
  Project,
  ProjectAPIKeyPermissionDetails,
  ProjectAddress,
  ProjectRole,
  ProjectTeamMember,
  Swap,
  SwapRate,
  SwapRequest,
  TOTPCredentials,
  TransactionStatus,
  TransactionType,
  TransactionsSorting,
  UpdateAPIKeyRequest,
  UpdateCallbackRequest,
  UpdateCustomerAddressRequest,
  UpdateProjectAddressRequest,
  UpdateProjectRequest,
  UpdateProjectRolesRequest,
  UpdateProjectTeamMemberRequest,
  ValidateWhitelistAddressRequest,
  ValidateWhitelistAddressResponse,
  WhitelistAddressUpdateRequest,
  WhitelistUpdateRequest,
  Withdrawal,
  WithdrawalAmounts,
  WithdrawalState,
  WithdrawalsSorting,
} from '../models/index';
import {
    APIErrorResponseFromJSON,
    APIErrorResponseToJSON,
    APIKeyFromJSON,
    APIKeyToJSON,
    AddProjectTeamMembersRequestFromJSON,
    AddProjectTeamMembersRequestToJSON,
    BalanceFromJSON,
    BalanceToJSON,
    BalanceSnapshotFromJSON,
    BalanceSnapshotToJSON,
    BlocklistFromJSON,
    BlocklistToJSON,
    CalcWithdrawalAmountsRequestFromJSON,
    CalcWithdrawalAmountsRequestToJSON,
    CallbackFromJSON,
    CallbackToJSON,
    CallbackResponseFromJSON,
    CallbackResponseToJSON,
    CreateAPIKeyRequestFromJSON,
    CreateAPIKeyRequestToJSON,
    CreateCustomerAddressRequestFromJSON,
    CreateCustomerAddressRequestToJSON,
    CreateCustomerAddressResponseFromJSON,
    CreateCustomerAddressResponseToJSON,
    CreateFiatWithdrawalRequestFromJSON,
    CreateFiatWithdrawalRequestToJSON,
    CreateFiatWithdrawalResponseFromJSON,
    CreateFiatWithdrawalResponseToJSON,
    CreatePaymentRequestFromJSON,
    CreatePaymentRequestToJSON,
    CreateProjectAddressRequestFromJSON,
    CreateProjectAddressRequestToJSON,
    CreateProjectRequestFromJSON,
    CreateProjectRequestToJSON,
    CreateWithdrawalRequestFromJSON,
    CreateWithdrawalRequestToJSON,
    CustomerAddressFromJSON,
    CustomerAddressToJSON,
    DeleteAPIKeyRequestFromJSON,
    DeleteAPIKeyRequestToJSON,
    DeleteCallbackRequestFromJSON,
    DeleteCallbackRequestToJSON,
    FiatWithdrawalMethodFromJSON,
    FiatWithdrawalMethodToJSON,
    GetHoldingCurrenciesResponseInnerFromJSON,
    GetHoldingCurrenciesResponseInnerToJSON,
    GetWhitelistResponseFromJSON,
    GetWhitelistResponseToJSON,
    GetWithdrawalFeeResponseFromJSON,
    GetWithdrawalFeeResponseToJSON,
    ListAddresses200ResponseFromJSON,
    ListAddresses200ResponseToJSON,
    ListAllPermissions200ResponseFromJSON,
    ListAllPermissions200ResponseToJSON,
    ListPayments200ResponseFromJSON,
    ListPayments200ResponseToJSON,
    ListProjectAddresses200ResponseFromJSON,
    ListProjectAddresses200ResponseToJSON,
    ListProjectWithdrawals200ResponseFromJSON,
    ListProjectWithdrawals200ResponseToJSON,
    ListProjectsResponseFromJSON,
    ListProjectsResponseToJSON,
    ListTransactions200ResponseFromJSON,
    ListTransactions200ResponseToJSON,
    PaymentFromJSON,
    PaymentToJSON,
    PaymentStateFromJSON,
    PaymentStateToJSON,
    PaymentSwapConfigFromJSON,
    PaymentSwapConfigToJSON,
    ProjectFromJSON,
    ProjectToJSON,
    ProjectAPIKeyPermissionDetailsFromJSON,
    ProjectAPIKeyPermissionDetailsToJSON,
    ProjectAddressFromJSON,
    ProjectAddressToJSON,
    ProjectRoleFromJSON,
    ProjectRoleToJSON,
    ProjectTeamMemberFromJSON,
    ProjectTeamMemberToJSON,
    SwapFromJSON,
    SwapToJSON,
    SwapRateFromJSON,
    SwapRateToJSON,
    SwapRequestFromJSON,
    SwapRequestToJSON,
    TOTPCredentialsFromJSON,
    TOTPCredentialsToJSON,
    TransactionStatusFromJSON,
    TransactionStatusToJSON,
    TransactionTypeFromJSON,
    TransactionTypeToJSON,
    TransactionsSortingFromJSON,
    TransactionsSortingToJSON,
    UpdateAPIKeyRequestFromJSON,
    UpdateAPIKeyRequestToJSON,
    UpdateCallbackRequestFromJSON,
    UpdateCallbackRequestToJSON,
    UpdateCustomerAddressRequestFromJSON,
    UpdateCustomerAddressRequestToJSON,
    UpdateProjectAddressRequestFromJSON,
    UpdateProjectAddressRequestToJSON,
    UpdateProjectRequestFromJSON,
    UpdateProjectRequestToJSON,
    UpdateProjectRolesRequestFromJSON,
    UpdateProjectRolesRequestToJSON,
    UpdateProjectTeamMemberRequestFromJSON,
    UpdateProjectTeamMemberRequestToJSON,
    ValidateWhitelistAddressRequestFromJSON,
    ValidateWhitelistAddressRequestToJSON,
    ValidateWhitelistAddressResponseFromJSON,
    ValidateWhitelistAddressResponseToJSON,
    WhitelistAddressUpdateRequestFromJSON,
    WhitelistAddressUpdateRequestToJSON,
    WhitelistUpdateRequestFromJSON,
    WhitelistUpdateRequestToJSON,
    WithdrawalFromJSON,
    WithdrawalToJSON,
    WithdrawalAmountsFromJSON,
    WithdrawalAmountsToJSON,
    WithdrawalStateFromJSON,
    WithdrawalStateToJSON,
    WithdrawalsSortingFromJSON,
    WithdrawalsSortingToJSON,
} from '../models/index';

export interface AddAddressToProjectWhitelistRequest {
    projectId: string;
    whitelistAddressUpdateRequest: WhitelistAddressUpdateRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface AddProjectTeamMembersOperationRequest {
    projectId: string;
    addProjectTeamMembersRequest: AddProjectTeamMembersRequest;
}

export interface CalcWithdrawalAmountsOperationRequest {
    projectId: string;
    calcWithdrawalAmountsRequest: CalcWithdrawalAmountsRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface CancelProjectWithdrawalRequest {
    projectId: string;
    id: string;
    xSpanId?: string;
    xTraceId?: string;
}

export interface CreateAPIKeyOperationRequest {
    projectId: string;
    createAPIKeyRequest: CreateAPIKeyRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface CreateAddressRequest {
    projectId: string;
    createCustomerAddressRequest: CreateCustomerAddressRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface CreateCustomerAddressOperationRequest {
    projectId: string;
    createCustomerAddressRequest: CreateCustomerAddressRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface CreatePaymentOperationRequest {
    projectId: string;
    createPaymentRequest: CreatePaymentRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface CreateProjectOperationRequest {
    createProjectRequest: CreateProjectRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface CreateProjectAddressOperationRequest {
    projectId: string;
    createProjectAddressRequest: CreateProjectAddressRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface CreateProjectFiatWithdrawalRequest {
    projectId: string;
    createFiatWithdrawalRequest: CreateFiatWithdrawalRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface CreateProjectWithdrawalRequest {
    projectId: string;
    createWithdrawalRequest: CreateWithdrawalRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface DeleteAPIKeyOperationRequest {
    projectId: string;
    apiKeyId: string;
    deleteAPIKeyRequest: DeleteAPIKeyRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface DeleteAddressRequest {
    projectId: string;
    addressId: string;
    xSpanId?: string;
    xTraceId?: string;
}

export interface DeleteAddressFromProjectWhitelistRequest {
    projectId: string;
    whitelistAddressUpdateRequest: WhitelistAddressUpdateRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface DeleteCallbackOperationRequest {
    projectId: string;
    deleteCallbackRequest: DeleteCallbackRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface DeleteCustomerAddressRequest {
    projectId: string;
    addressId: string;
    xSpanId?: string;
    xTraceId?: string;
}

export interface DeleteProjectAddressRequest {
    projectId: string;
    addressId: string;
    xSpanId?: string;
    xTraceId?: string;
}

export interface ExportProjectBalancesSnapshotRequest {
    projectId: string;
    date: Date;
    xSpanId?: string;
    xTraceId?: string;
    currencies?: Array<string>;
    includeZeroes?: boolean;
}

export interface ExportProjectPaymentsRequest {
    projectId: string;
    xSpanId?: string;
    xTraceId?: string;
    id?: string;
    state?: PaymentState;
    orderId?: string;
    txnHash?: string;
    payinAddress?: string;
    customerEmail?: string;
    customerId?: string;
    dateFrom?: Date;
    dateTo?: Date;
}

export interface ExportProjectTransactionsRequest {
    projectId: string;
    xSpanId?: string;
    xTraceId?: string;
    sort?: TransactionsSorting;
    type?: TransactionType;
    status?: TransactionStatus;
    dateFrom?: Date;
    dateTo?: Date;
    updatedAtFrom?: Date;
    updatedAtTo?: Date;
    id?: string;
    operationId?: string;
}

export interface ExportProjectWithdrawalsRequest {
    projectId: string;
    xSpanId?: string;
    xTraceId?: string;
    id?: string;
    state?: WithdrawalState;
    note?: string;
    orderId?: string;
    origin?: string;
    creditCurrency?: string;
    creditAmount?: string;
    receiveCurrency?: string;
    receiveNetwork?: string;
    receiveAmount?: string;
    address?: string;
    addressTag?: string;
    hash?: string;
    createDateFrom?: Date;
    createDateTo?: Date;
    updateDateFrom?: Date;
    updateDateTo?: Date;
    sortBy?: WithdrawalsSorting;
}

export interface GetAddressRequest {
    projectId: string;
    addressId: string;
    xSpanId?: string;
    xTraceId?: string;
}

export interface GetCallbackRequest {
    projectId: string;
    xSpanId?: string;
    xTraceId?: string;
}

export interface GetCustomerAddressRequest {
    projectId: string;
    addressId: string;
    xSpanId?: string;
    xTraceId?: string;
}

export interface GetProjectRequest {
    projectId: string;
    xSpanId?: string;
    xTraceId?: string;
}

export interface GetProjectAddressRequest {
    projectId: string;
    addressId: string;
    xSpanId?: string;
    xTraceId?: string;
}

export interface GetProjectBalanceRequest {
    projectId: string;
    currency: string;
    xSpanId?: string;
    xTraceId?: string;
}

export interface GetProjectBalancesRequest {
    projectId: string;
    xSpanId?: string;
    xTraceId?: string;
}

export interface GetProjectBalancesSnapshotRequest {
    projectId: string;
    date: Date;
    xSpanId?: string;
    xTraceId?: string;
    currencies?: Array<string>;
    includeZeroes?: boolean;
}

export interface GetProjectBlocklistRequest {
    projectId: string;
    xSpanId?: string;
    xTraceId?: string;
}

export interface GetProjectFiatWithdrawalFeeRequest {
    projectId: string;
    currency: string;
    amount: string;
    method: string;
    xSpanId?: string;
    xTraceId?: string;
}

export interface GetProjectFiatWithdrawalMethodsRequest {
    projectId: string;
    currency: string;
    xSpanId?: string;
    xTraceId?: string;
}

export interface GetProjectHoldingCurrenciesRequest {
    projectId: string;
    xSpanId?: string;
    xTraceId?: string;
}

export interface GetProjectPaymentRequest {
    projectId: string;
    paymentId: string;
    xSpanId?: string;
    xTraceId?: string;
}

export interface GetProjectRolesRequest {
    projectId: string;
}

export interface GetProjectSwapRequest {
    projectId: string;
    id: string;
    xSpanId?: string;
    xTraceId?: string;
}

export interface GetProjectSwapRateRequest {
    projectId: string;
    from: string;
    to: string;
    amountFrom: string;
    xSpanId?: string;
    xTraceId?: string;
}

export interface GetProjectTeamMembersRequest {
    projectId: string;
}

export interface GetProjectWhitelistRequest {
    projectId: string;
    xSpanId?: string;
    xTraceId?: string;
}

export interface GetProjectWithdrawalRequest {
    projectId: string;
    withdrawalId: string;
    xSpanId?: string;
    xTraceId?: string;
}

export interface ListAPIKeysRequest {
    projectId: string;
    xSpanId?: string;
    xTraceId?: string;
}

export interface ListAPIKeysPermissionsRequest {
    projectId: string;
    xSpanId?: string;
    xTraceId?: string;
}

export interface ListAddressesRequest {
    projectId: string;
    xSpanId?: string;
    xTraceId?: string;
    id?: string;
    currency?: string;
    network?: string;
    address?: string;
    addressTag?: string;
    customerId?: string;
    createdAtFrom?: Date;
    createdAtTo?: Date;
    cursor?: string;
    limit?: number;
}

export interface ListAllPermissionsRequest {
    projectId: string;
}

export interface ListCustomerAddressesRequest {
    projectId: string;
    xSpanId?: string;
    xTraceId?: string;
    id?: string;
    currency?: string;
    network?: string;
    address?: string;
    addressTag?: string;
    customerId?: string;
    createdAtFrom?: Date;
    createdAtTo?: Date;
    cursor?: string;
    limit?: number;
}

export interface ListProjectAddressesRequest {
    projectId: string;
    xSpanId?: string;
    xTraceId?: string;
    id?: string;
    currency?: string;
    network?: string;
    swap?: PaymentSwapConfig;
    holdingCurrency?: string;
    address?: string;
    addressTag?: string;
    createdAtFrom?: Date;
    createdAtTo?: Date;
    cursor?: string;
    limit?: number;
}

export interface ListProjectPaymentsRequest {
    projectId: string;
    xSpanId?: string;
    xTraceId?: string;
    id?: string;
    origin?: string;
    state?: PaymentState;
    orderId?: string;
    customerEmail?: string;
    customerId?: string;
    txnHash?: string;
    payinAddress?: string;
    dateFrom?: Date;
    dateTo?: Date;
    limit?: number;
    cursor?: string;
}

export interface ListProjectTransactionsRequest {
    projectId: string;
    xSpanId?: string;
    xTraceId?: string;
    limit?: number;
    cursor?: string;
    sort?: TransactionsSorting;
    type?: TransactionType;
    status?: TransactionStatus;
    dateFrom?: Date;
    dateTo?: Date;
    updatedAtFrom?: Date;
    updatedAtTo?: Date;
    id?: string;
    operationId?: string;
}

export interface ListProjectWithdrawalsRequest {
    projectId: string;
    xSpanId?: string;
    xTraceId?: string;
    id?: string;
    state?: WithdrawalState;
    note?: string;
    orderId?: string;
    origin?: string;
    creditCurrency?: string;
    creditAmount?: string;
    receiveCurrency?: string;
    receiveNetwork?: string;
    receiveAmount?: string;
    address?: string;
    addressTag?: string;
    hash?: string;
    createAtFrom?: Date;
    createAtTo?: Date;
    updateAtFrom?: Date;
    updateAtTo?: Date;
    sortBy?: WithdrawalsSorting;
    limit?: number;
    cursor?: string;
}

export interface ListProjectsRequest {
    xSpanId?: string;
    xTraceId?: string;
}

export interface ProjectSwapRequest {
    projectId: string;
    swapRequest: SwapRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface RemoveProjectTeamMemberRequest {
    projectId: string;
    teamMemberId: string;
    tOTPCredentials: TOTPCredentials;
}

export interface ResendProjectTeamMemberInvitationRequest {
    projectId: string;
    teamMemberId: string;
}

export interface SendProjectPaymentCallbackRequest {
    projectId: string;
    paymentId: string;
    xSpanId?: string;
    xTraceId?: string;
}

export interface SendProjectWithdrawalCallbackRequest {
    projectId: string;
    withdrawalId: string;
    xSpanId?: string;
    xTraceId?: string;
}

export interface SendTestCallbackRequest {
    projectId: string;
    xSpanId?: string;
    xTraceId?: string;
    type?: SendTestCallbackType;
}

export interface SetProjectHoldingCurrenciesRequest {
    projectId: string;
    requestBody: Array<string>;
    xSpanId?: string;
    xTraceId?: string;
}

export interface UpdateAPIKeyOperationRequest {
    projectId: string;
    apiKeyId: string;
    updateAPIKeyRequest: UpdateAPIKeyRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface UpdateAddressRequest {
    projectId: string;
    addressId: string;
    updateCustomerAddressRequest: UpdateCustomerAddressRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface UpdateCallbackOperationRequest {
    projectId: string;
    updateCallbackRequest: UpdateCallbackRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface UpdateCustomerAddressOperationRequest {
    projectId: string;
    addressId: string;
    updateCustomerAddressRequest: UpdateCustomerAddressRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface UpdateProjectOperationRequest {
    projectId: string;
    updateProjectRequest: UpdateProjectRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface UpdateProjectAddressOperationRequest {
    projectId: string;
    addressId: string;
    updateProjectAddressRequest: UpdateProjectAddressRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface UpdateProjectRolesOperationRequest {
    projectId: string;
    updateProjectRolesRequest: UpdateProjectRolesRequest;
}

export interface UpdateProjectTeamMemberOperationRequest {
    projectId: string;
    teamMemberId: string;
    updateProjectTeamMemberRequest: UpdateProjectTeamMemberRequest;
}

export interface UpdateProjectWhitelistRequest {
    projectId: string;
    whitelistUpdateRequest: WhitelistUpdateRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface ValidateProjectWhitelistAddressRequest {
    projectId: string;
    validateWhitelistAddressRequest: ValidateWhitelistAddressRequest;
    xSpanId?: string;
    xTraceId?: string;
}

/**
 * 
 */
export class ProjectApi extends runtime.BaseAPI {

    /**
     * Add address to whitelist for withdrawal.
     * Add address to whitelist for withdrawal
     */
    async addAddressToProjectWhitelistRaw(requestParameters: AddAddressToProjectWhitelistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling addAddressToProjectWhitelist.');
        }

        if (requestParameters.whitelistAddressUpdateRequest === null || requestParameters.whitelistAddressUpdateRequest === undefined) {
            throw new runtime.RequiredError('whitelistAddressUpdateRequest','Required parameter requestParameters.whitelistAddressUpdateRequest was null or undefined when calling addAddressToProjectWhitelist.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/whitelist/addresses`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WhitelistAddressUpdateRequestToJSON(requestParameters.whitelistAddressUpdateRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add address to whitelist for withdrawal.
     * Add address to whitelist for withdrawal
     */
    async addAddressToProjectWhitelist(requestParameters: AddAddressToProjectWhitelistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addAddressToProjectWhitelistRaw(requestParameters, initOverrides);
    }

    /**
     * Adds a new project team members.
     * Add project team members
     */
    async addProjectTeamMembersRaw(requestParameters: AddProjectTeamMembersOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProjectTeamMember>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling addProjectTeamMembers.');
        }

        if (requestParameters.addProjectTeamMembersRequest === null || requestParameters.addProjectTeamMembersRequest === undefined) {
            throw new runtime.RequiredError('addProjectTeamMembersRequest','Required parameter requestParameters.addProjectTeamMembersRequest was null or undefined when calling addProjectTeamMembers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/team_members`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddProjectTeamMembersRequestToJSON(requestParameters.addProjectTeamMembersRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectTeamMemberFromJSON));
    }

    /**
     * Adds a new project team members.
     * Add project team members
     */
    async addProjectTeamMembers(requestParameters: AddProjectTeamMembersOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProjectTeamMember>> {
        const response = await this.addProjectTeamMembersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Calculate withdrawal receive amount and fee.
     * Calculate withdrawal amounts
     */
    async calcWithdrawalAmountsRaw(requestParameters: CalcWithdrawalAmountsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawalAmounts>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling calcWithdrawalAmounts.');
        }

        if (requestParameters.calcWithdrawalAmountsRequest === null || requestParameters.calcWithdrawalAmountsRequest === undefined) {
            throw new runtime.RequiredError('calcWithdrawalAmountsRequest','Required parameter requestParameters.calcWithdrawalAmountsRequest was null or undefined when calling calcWithdrawalAmounts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/withdrawals/calc`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CalcWithdrawalAmountsRequestToJSON(requestParameters.calcWithdrawalAmountsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WithdrawalAmountsFromJSON(jsonValue));
    }

    /**
     * Calculate withdrawal receive amount and fee.
     * Calculate withdrawal amounts
     */
    async calcWithdrawalAmounts(requestParameters: CalcWithdrawalAmountsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawalAmounts> {
        const response = await this.calcWithdrawalAmountsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cancels withdrawal.
     * Cancel withdrawal
     */
    async cancelProjectWithdrawalRaw(requestParameters: CancelProjectWithdrawalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling cancelProjectWithdrawal.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cancelProjectWithdrawal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/withdrawals/{id}/cancel`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Cancels withdrawal.
     * Cancel withdrawal
     */
    async cancelProjectWithdrawal(requestParameters: CancelProjectWithdrawalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.cancelProjectWithdrawalRaw(requestParameters, initOverrides);
    }

    /**
     * Create API key for the project.
     * Create API key
     */
    async createAPIKeyRaw(requestParameters: CreateAPIKeyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<APIKey>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling createAPIKey.');
        }

        if (requestParameters.createAPIKeyRequest === null || requestParameters.createAPIKeyRequest === undefined) {
            throw new runtime.RequiredError('createAPIKeyRequest','Required parameter requestParameters.createAPIKeyRequest was null or undefined when calling createAPIKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/api_keys`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAPIKeyRequestToJSON(requestParameters.createAPIKeyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIKeyFromJSON(jsonValue));
    }

    /**
     * Create API key for the project.
     * Create API key
     */
    async createAPIKey(requestParameters: CreateAPIKeyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<APIKey> {
        const response = await this.createAPIKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates static deposit address.
     * Create address
     * @deprecated
     */
    async createAddressRaw(requestParameters: CreateAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCustomerAddressResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling createAddress.');
        }

        if (requestParameters.createCustomerAddressRequest === null || requestParameters.createCustomerAddressRequest === undefined) {
            throw new runtime.RequiredError('createCustomerAddressRequest','Required parameter requestParameters.createCustomerAddressRequest was null or undefined when calling createAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/addresses`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCustomerAddressRequestToJSON(requestParameters.createCustomerAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCustomerAddressResponseFromJSON(jsonValue));
    }

    /**
     * Creates static deposit address.
     * Create address
     * @deprecated
     */
    async createAddress(requestParameters: CreateAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCustomerAddressResponse> {
        const response = await this.createAddressRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates static deposit address for the customer.
     * Create customer address
     */
    async createCustomerAddressRaw(requestParameters: CreateCustomerAddressOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCustomerAddressResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling createCustomerAddress.');
        }

        if (requestParameters.createCustomerAddressRequest === null || requestParameters.createCustomerAddressRequest === undefined) {
            throw new runtime.RequiredError('createCustomerAddressRequest','Required parameter requestParameters.createCustomerAddressRequest was null or undefined when calling createCustomerAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/addresses/customer`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCustomerAddressRequestToJSON(requestParameters.createCustomerAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCustomerAddressResponseFromJSON(jsonValue));
    }

    /**
     * Creates static deposit address for the customer.
     * Create customer address
     */
    async createCustomerAddress(requestParameters: CreateCustomerAddressOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCustomerAddressResponse> {
        const response = await this.createCustomerAddressRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create new payment
     * Create new payment
     */
    async createPaymentRaw(requestParameters: CreatePaymentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Payment>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling createPayment.');
        }

        if (requestParameters.createPaymentRequest === null || requestParameters.createPaymentRequest === undefined) {
            throw new runtime.RequiredError('createPaymentRequest','Required parameter requestParameters.createPaymentRequest was null or undefined when calling createPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/payments`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePaymentRequestToJSON(requestParameters.createPaymentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentFromJSON(jsonValue));
    }

    /**
     * Create new payment
     * Create new payment
     */
    async createPayment(requestParameters: CreatePaymentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Payment> {
        const response = await this.createPaymentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates new project.
     * Create project
     */
    async createProjectRaw(requestParameters: CreateProjectOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Project>> {
        if (requestParameters.createProjectRequest === null || requestParameters.createProjectRequest === undefined) {
            throw new runtime.RequiredError('createProjectRequest','Required parameter requestParameters.createProjectRequest was null or undefined when calling createProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateProjectRequestToJSON(requestParameters.createProjectRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectFromJSON(jsonValue));
    }

    /**
     * Creates new project.
     * Create project
     */
    async createProject(requestParameters: CreateProjectOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Project> {
        const response = await this.createProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates static deposit address for the project.
     * Create project address
     */
    async createProjectAddressRaw(requestParameters: CreateProjectAddressOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectAddress>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling createProjectAddress.');
        }

        if (requestParameters.createProjectAddressRequest === null || requestParameters.createProjectAddressRequest === undefined) {
            throw new runtime.RequiredError('createProjectAddressRequest','Required parameter requestParameters.createProjectAddressRequest was null or undefined when calling createProjectAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/addresses/project`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateProjectAddressRequestToJSON(requestParameters.createProjectAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectAddressFromJSON(jsonValue));
    }

    /**
     * Creates static deposit address for the project.
     * Create project address
     */
    async createProjectAddress(requestParameters: CreateProjectAddressOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectAddress> {
        const response = await this.createProjectAddressRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Withdraw fiat
     * Withdraw fiat
     */
    async createProjectFiatWithdrawalRaw(requestParameters: CreateProjectFiatWithdrawalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateFiatWithdrawalResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling createProjectFiatWithdrawal.');
        }

        if (requestParameters.createFiatWithdrawalRequest === null || requestParameters.createFiatWithdrawalRequest === undefined) {
            throw new runtime.RequiredError('createFiatWithdrawalRequest','Required parameter requestParameters.createFiatWithdrawalRequest was null or undefined when calling createProjectFiatWithdrawal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/withdrawals/fiat`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateFiatWithdrawalRequestToJSON(requestParameters.createFiatWithdrawalRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateFiatWithdrawalResponseFromJSON(jsonValue));
    }

    /**
     * Withdraw fiat
     * Withdraw fiat
     */
    async createProjectFiatWithdrawal(requestParameters: CreateProjectFiatWithdrawalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateFiatWithdrawalResponse> {
        const response = await this.createProjectFiatWithdrawalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Withdraw to specified address
     * Withdraw to specified address
     */
    async createProjectWithdrawalRaw(requestParameters: CreateProjectWithdrawalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Withdrawal>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling createProjectWithdrawal.');
        }

        if (requestParameters.createWithdrawalRequest === null || requestParameters.createWithdrawalRequest === undefined) {
            throw new runtime.RequiredError('createWithdrawalRequest','Required parameter requestParameters.createWithdrawalRequest was null or undefined when calling createProjectWithdrawal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/withdrawals`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateWithdrawalRequestToJSON(requestParameters.createWithdrawalRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WithdrawalFromJSON(jsonValue));
    }

    /**
     * Withdraw to specified address
     * Withdraw to specified address
     */
    async createProjectWithdrawal(requestParameters: CreateProjectWithdrawalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Withdrawal> {
        const response = await this.createProjectWithdrawalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes API Key by its id.
     * Delete API Key
     */
    async deleteAPIKeyRaw(requestParameters: DeleteAPIKeyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling deleteAPIKey.');
        }

        if (requestParameters.apiKeyId === null || requestParameters.apiKeyId === undefined) {
            throw new runtime.RequiredError('apiKeyId','Required parameter requestParameters.apiKeyId was null or undefined when calling deleteAPIKey.');
        }

        if (requestParameters.deleteAPIKeyRequest === null || requestParameters.deleteAPIKeyRequest === undefined) {
            throw new runtime.RequiredError('deleteAPIKeyRequest','Required parameter requestParameters.deleteAPIKeyRequest was null or undefined when calling deleteAPIKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/api_keys/{api_key_id}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"api_key_id"}}`, encodeURIComponent(String(requestParameters.apiKeyId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteAPIKeyRequestToJSON(requestParameters.deleteAPIKeyRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes API Key by its id.
     * Delete API Key
     */
    async deleteAPIKey(requestParameters: DeleteAPIKeyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAPIKeyRaw(requestParameters, initOverrides);
    }

    /**
     * Deletes the static deposit address.
     * Delete address
     * @deprecated
     */
    async deleteAddressRaw(requestParameters: DeleteAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling deleteAddress.');
        }

        if (requestParameters.addressId === null || requestParameters.addressId === undefined) {
            throw new runtime.RequiredError('addressId','Required parameter requestParameters.addressId was null or undefined when calling deleteAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/addresses/{address_id}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"address_id"}}`, encodeURIComponent(String(requestParameters.addressId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes the static deposit address.
     * Delete address
     * @deprecated
     */
    async deleteAddress(requestParameters: DeleteAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAddressRaw(requestParameters, initOverrides);
    }

    /**
     * Delete address from whitelist for withdrawal.
     * Delete address from whitelist for withdrawal
     */
    async deleteAddressFromProjectWhitelistRaw(requestParameters: DeleteAddressFromProjectWhitelistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling deleteAddressFromProjectWhitelist.');
        }

        if (requestParameters.whitelistAddressUpdateRequest === null || requestParameters.whitelistAddressUpdateRequest === undefined) {
            throw new runtime.RequiredError('whitelistAddressUpdateRequest','Required parameter requestParameters.whitelistAddressUpdateRequest was null or undefined when calling deleteAddressFromProjectWhitelist.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/whitelist/addresses`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: WhitelistAddressUpdateRequestToJSON(requestParameters.whitelistAddressUpdateRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete address from whitelist for withdrawal.
     * Delete address from whitelist for withdrawal
     */
    async deleteAddressFromProjectWhitelist(requestParameters: DeleteAddressFromProjectWhitelistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAddressFromProjectWhitelistRaw(requestParameters, initOverrides);
    }

    /**
     * Removes the project callback.
     * Delete callback
     */
    async deleteCallbackRaw(requestParameters: DeleteCallbackOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling deleteCallback.');
        }

        if (requestParameters.deleteCallbackRequest === null || requestParameters.deleteCallbackRequest === undefined) {
            throw new runtime.RequiredError('deleteCallbackRequest','Required parameter requestParameters.deleteCallbackRequest was null or undefined when calling deleteCallback.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/callback`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteCallbackRequestToJSON(requestParameters.deleteCallbackRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes the project callback.
     * Delete callback
     */
    async deleteCallback(requestParameters: DeleteCallbackOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCallbackRaw(requestParameters, initOverrides);
    }

    /**
     * Deletes the static deposit address for the customer.
     * Delete customer address
     */
    async deleteCustomerAddressRaw(requestParameters: DeleteCustomerAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling deleteCustomerAddress.');
        }

        if (requestParameters.addressId === null || requestParameters.addressId === undefined) {
            throw new runtime.RequiredError('addressId','Required parameter requestParameters.addressId was null or undefined when calling deleteCustomerAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/addresses/customer/{address_id}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"address_id"}}`, encodeURIComponent(String(requestParameters.addressId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes the static deposit address for the customer.
     * Delete customer address
     */
    async deleteCustomerAddress(requestParameters: DeleteCustomerAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCustomerAddressRaw(requestParameters, initOverrides);
    }

    /**
     * Deletes the static deposit address for the project.
     * Delete project address
     */
    async deleteProjectAddressRaw(requestParameters: DeleteProjectAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling deleteProjectAddress.');
        }

        if (requestParameters.addressId === null || requestParameters.addressId === undefined) {
            throw new runtime.RequiredError('addressId','Required parameter requestParameters.addressId was null or undefined when calling deleteProjectAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/addresses/project/{address_id}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"address_id"}}`, encodeURIComponent(String(requestParameters.addressId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes the static deposit address for the project.
     * Delete project address
     */
    async deleteProjectAddress(requestParameters: DeleteProjectAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProjectAddressRaw(requestParameters, initOverrides);
    }

    /**
     * Exports project\'s balances snapshot for a given date.
     * Export project balances snapshot
     */
    async exportProjectBalancesSnapshotRaw(requestParameters: ExportProjectBalancesSnapshotRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling exportProjectBalancesSnapshot.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling exportProjectBalancesSnapshot.');
        }

        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = (requestParameters.date as any).toISOString();
        }

        if (requestParameters.currencies) {
            queryParameters['currencies'] = requestParameters.currencies.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.includeZeroes !== undefined) {
            queryParameters['include_zeroes'] = requestParameters.includeZeroes;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // MerchantTokenQuery authentication
        }

        const response = await this.request({
            path: `/projects/{project_id}/balances/snapshots/export`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Exports project\'s balances snapshot for a given date.
     * Export project balances snapshot
     */
    async exportProjectBalancesSnapshot(requestParameters: ExportProjectBalancesSnapshotRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportProjectBalancesSnapshotRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Exports project\'s payments to a CSV file.
     * Export payments
     */
    async exportProjectPaymentsRaw(requestParameters: ExportProjectPaymentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling exportProjectPayments.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        if (requestParameters.orderId !== undefined) {
            queryParameters['order_id'] = requestParameters.orderId;
        }

        if (requestParameters.txnHash !== undefined) {
            queryParameters['txn_hash'] = requestParameters.txnHash;
        }

        if (requestParameters.payinAddress !== undefined) {
            queryParameters['payin_address'] = requestParameters.payinAddress;
        }

        if (requestParameters.customerEmail !== undefined) {
            queryParameters['customer_email'] = requestParameters.customerEmail;
        }

        if (requestParameters.customerId !== undefined) {
            queryParameters['customer_id'] = requestParameters.customerId;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = (requestParameters.dateTo as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // MerchantTokenQuery authentication
        }

        const response = await this.request({
            path: `/projects/{project_id}/payments/export`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Exports project\'s payments to a CSV file.
     * Export payments
     */
    async exportProjectPayments(requestParameters: ExportProjectPaymentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportProjectPaymentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Exports project\'s transactions to a CSV file.
     * Export project\'s transactions
     */
    async exportProjectTransactionsRaw(requestParameters: ExportProjectTransactionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling exportProjectTransactions.');
        }

        const queryParameters: any = {};

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = (requestParameters.dateTo as any).toISOString();
        }

        if (requestParameters.updatedAtFrom !== undefined) {
            queryParameters['updated_at_from'] = (requestParameters.updatedAtFrom as any).toISOString();
        }

        if (requestParameters.updatedAtTo !== undefined) {
            queryParameters['updated_at_to'] = (requestParameters.updatedAtTo as any).toISOString();
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.operationId !== undefined) {
            queryParameters['operation_id'] = requestParameters.operationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // MerchantTokenQuery authentication
        }

        const response = await this.request({
            path: `/projects/{project_id}/transactions/export`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Exports project\'s transactions to a CSV file.
     * Export project\'s transactions
     */
    async exportProjectTransactions(requestParameters: ExportProjectTransactionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportProjectTransactionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Exports project\'s withdrawals to a CSV file.
     * Export withdrawals
     */
    async exportProjectWithdrawalsRaw(requestParameters: ExportProjectWithdrawalsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling exportProjectWithdrawals.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        if (requestParameters.note !== undefined) {
            queryParameters['note'] = requestParameters.note;
        }

        if (requestParameters.orderId !== undefined) {
            queryParameters['order_id'] = requestParameters.orderId;
        }

        if (requestParameters.origin !== undefined) {
            queryParameters['origin'] = requestParameters.origin;
        }

        if (requestParameters.creditCurrency !== undefined) {
            queryParameters['credit_currency'] = requestParameters.creditCurrency;
        }

        if (requestParameters.creditAmount !== undefined) {
            queryParameters['credit_amount'] = requestParameters.creditAmount;
        }

        if (requestParameters.receiveCurrency !== undefined) {
            queryParameters['receive_currency'] = requestParameters.receiveCurrency;
        }

        if (requestParameters.receiveNetwork !== undefined) {
            queryParameters['receive_network'] = requestParameters.receiveNetwork;
        }

        if (requestParameters.receiveAmount !== undefined) {
            queryParameters['receive_amount'] = requestParameters.receiveAmount;
        }

        if (requestParameters.address !== undefined) {
            queryParameters['address'] = requestParameters.address;
        }

        if (requestParameters.addressTag !== undefined) {
            queryParameters['address_tag'] = requestParameters.addressTag;
        }

        if (requestParameters.hash !== undefined) {
            queryParameters['hash'] = requestParameters.hash;
        }

        if (requestParameters.createDateFrom !== undefined) {
            queryParameters['create_date_from'] = (requestParameters.createDateFrom as any).toISOString();
        }

        if (requestParameters.createDateTo !== undefined) {
            queryParameters['create_date_to'] = (requestParameters.createDateTo as any).toISOString();
        }

        if (requestParameters.updateDateFrom !== undefined) {
            queryParameters['update_date_from'] = (requestParameters.updateDateFrom as any).toISOString();
        }

        if (requestParameters.updateDateTo !== undefined) {
            queryParameters['update_date_to'] = (requestParameters.updateDateTo as any).toISOString();
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // MerchantTokenQuery authentication
        }

        const response = await this.request({
            path: `/projects/{project_id}/withdrawals/export`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Exports project\'s withdrawals to a CSV file.
     * Export withdrawals
     */
    async exportProjectWithdrawals(requestParameters: ExportProjectWithdrawalsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportProjectWithdrawalsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the static deposit address.
     * Get address
     * @deprecated
     */
    async getAddressRaw(requestParameters: GetAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerAddress>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getAddress.');
        }

        if (requestParameters.addressId === null || requestParameters.addressId === undefined) {
            throw new runtime.RequiredError('addressId','Required parameter requestParameters.addressId was null or undefined when calling getAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/addresses/{address_id}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"address_id"}}`, encodeURIComponent(String(requestParameters.addressId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerAddressFromJSON(jsonValue));
    }

    /**
     * Returns the static deposit address.
     * Get address
     * @deprecated
     */
    async getAddress(requestParameters: GetAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerAddress> {
        const response = await this.getAddressRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns callback url and signature public key.
     * Get callback
     */
    async getCallbackRaw(requestParameters: GetCallbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Callback>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getCallback.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/callback`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CallbackFromJSON(jsonValue));
    }

    /**
     * Returns callback url and signature public key.
     * Get callback
     */
    async getCallback(requestParameters: GetCallbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Callback> {
        const response = await this.getCallbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the static deposit address for the customer.
     * Get customer address
     */
    async getCustomerAddressRaw(requestParameters: GetCustomerAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerAddress>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getCustomerAddress.');
        }

        if (requestParameters.addressId === null || requestParameters.addressId === undefined) {
            throw new runtime.RequiredError('addressId','Required parameter requestParameters.addressId was null or undefined when calling getCustomerAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/addresses/customer/{address_id}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"address_id"}}`, encodeURIComponent(String(requestParameters.addressId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerAddressFromJSON(jsonValue));
    }

    /**
     * Returns the static deposit address for the customer.
     * Get customer address
     */
    async getCustomerAddress(requestParameters: GetCustomerAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerAddress> {
        const response = await this.getCustomerAddressRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns project by its id.
     */
    async getProjectRaw(requestParameters: GetProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Project>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectFromJSON(jsonValue));
    }

    /**
     * Returns project by its id.
     */
    async getProject(requestParameters: GetProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Project> {
        const response = await this.getProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the static deposit address for the project.
     * Get project address
     */
    async getProjectAddressRaw(requestParameters: GetProjectAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectAddress>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectAddress.');
        }

        if (requestParameters.addressId === null || requestParameters.addressId === undefined) {
            throw new runtime.RequiredError('addressId','Required parameter requestParameters.addressId was null or undefined when calling getProjectAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/addresses/project/{address_id}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"address_id"}}`, encodeURIComponent(String(requestParameters.addressId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectAddressFromJSON(jsonValue));
    }

    /**
     * Returns the static deposit address for the project.
     * Get project address
     */
    async getProjectAddress(requestParameters: GetProjectAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectAddress> {
        const response = await this.getProjectAddressRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get project\'s balance for specific currency.
     * Get balance
     */
    async getProjectBalanceRaw(requestParameters: GetProjectBalanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Balance>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectBalance.');
        }

        if (requestParameters.currency === null || requestParameters.currency === undefined) {
            throw new runtime.RequiredError('currency','Required parameter requestParameters.currency was null or undefined when calling getProjectBalance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/balances/{currency}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"currency"}}`, encodeURIComponent(String(requestParameters.currency))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BalanceFromJSON(jsonValue));
    }

    /**
     * Get project\'s balance for specific currency.
     * Get balance
     */
    async getProjectBalance(requestParameters: GetProjectBalanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Balance> {
        const response = await this.getProjectBalanceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of project\'s balances.
     * Get balances
     */
    async getProjectBalancesRaw(requestParameters: GetProjectBalancesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Balance>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectBalances.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/balances`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BalanceFromJSON));
    }

    /**
     * Get list of project\'s balances.
     * Get balances
     */
    async getProjectBalances(requestParameters: GetProjectBalancesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Balance>> {
        const response = await this.getProjectBalancesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns project\'s balances snapshot for a given date.
     * Get balances snapshot
     */
    async getProjectBalancesSnapshotRaw(requestParameters: GetProjectBalancesSnapshotRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BalanceSnapshot>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectBalancesSnapshot.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling getProjectBalancesSnapshot.');
        }

        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = (requestParameters.date as any).toISOString();
        }

        if (requestParameters.currencies) {
            queryParameters['currencies'] = requestParameters.currencies.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.includeZeroes !== undefined) {
            queryParameters['include_zeroes'] = requestParameters.includeZeroes;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/balances/snapshots`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BalanceSnapshotFromJSON(jsonValue));
    }

    /**
     * Returns project\'s balances snapshot for a given date.
     * Get balances snapshot
     */
    async getProjectBalancesSnapshot(requestParameters: GetProjectBalancesSnapshotRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BalanceSnapshot> {
        const response = await this.getProjectBalancesSnapshotRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reports whether certain operations are temporarily blocked.
     * Get project blocklist
     */
    async getProjectBlocklistRaw(requestParameters: GetProjectBlocklistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blocklist>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectBlocklist.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/blocklist`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BlocklistFromJSON(jsonValue));
    }

    /**
     * Reports whether certain operations are temporarily blocked.
     * Get project blocklist
     */
    async getProjectBlocklist(requestParameters: GetProjectBlocklistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blocklist> {
        const response = await this.getProjectBlocklistRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Estimate fee for fiat withdrawal operation
     * Estimate fee for fiat withdrawal operation
     */
    async getProjectFiatWithdrawalFeeRaw(requestParameters: GetProjectFiatWithdrawalFeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetWithdrawalFeeResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectFiatWithdrawalFee.');
        }

        if (requestParameters.currency === null || requestParameters.currency === undefined) {
            throw new runtime.RequiredError('currency','Required parameter requestParameters.currency was null or undefined when calling getProjectFiatWithdrawalFee.');
        }

        if (requestParameters.amount === null || requestParameters.amount === undefined) {
            throw new runtime.RequiredError('amount','Required parameter requestParameters.amount was null or undefined when calling getProjectFiatWithdrawalFee.');
        }

        if (requestParameters.method === null || requestParameters.method === undefined) {
            throw new runtime.RequiredError('method','Required parameter requestParameters.method was null or undefined when calling getProjectFiatWithdrawalFee.');
        }

        const queryParameters: any = {};

        if (requestParameters.currency !== undefined) {
            queryParameters['currency'] = requestParameters.currency;
        }

        if (requestParameters.amount !== undefined) {
            queryParameters['amount'] = requestParameters.amount;
        }

        if (requestParameters.method !== undefined) {
            queryParameters['method'] = requestParameters.method;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/withdrawals/fiat/fee`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetWithdrawalFeeResponseFromJSON(jsonValue));
    }

    /**
     * Estimate fee for fiat withdrawal operation
     * Estimate fee for fiat withdrawal operation
     */
    async getProjectFiatWithdrawalFee(requestParameters: GetProjectFiatWithdrawalFeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetWithdrawalFeeResponse> {
        const response = await this.getProjectFiatWithdrawalFeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get fiat withdrawal methods
     * Get fiat withdrawal methods
     */
    async getProjectFiatWithdrawalMethodsRaw(requestParameters: GetProjectFiatWithdrawalMethodsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FiatWithdrawalMethod>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectFiatWithdrawalMethods.');
        }

        if (requestParameters.currency === null || requestParameters.currency === undefined) {
            throw new runtime.RequiredError('currency','Required parameter requestParameters.currency was null or undefined when calling getProjectFiatWithdrawalMethods.');
        }

        const queryParameters: any = {};

        if (requestParameters.currency !== undefined) {
            queryParameters['currency'] = requestParameters.currency;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/withdrawals/fiat/methods`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FiatWithdrawalMethodFromJSON));
    }

    /**
     * Get fiat withdrawal methods
     * Get fiat withdrawal methods
     */
    async getProjectFiatWithdrawalMethods(requestParameters: GetProjectFiatWithdrawalMethodsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FiatWithdrawalMethod>> {
        const response = await this.getProjectFiatWithdrawalMethodsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of all available holding currencies with enabled status.
     * Get holding currencies
     */
    async getProjectHoldingCurrenciesRaw(requestParameters: GetProjectHoldingCurrenciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetHoldingCurrenciesResponseInner>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectHoldingCurrencies.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/holding_currencies`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetHoldingCurrenciesResponseInnerFromJSON));
    }

    /**
     * Get a list of all available holding currencies with enabled status.
     * Get holding currencies
     */
    async getProjectHoldingCurrencies(requestParameters: GetProjectHoldingCurrenciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetHoldingCurrenciesResponseInner>> {
        const response = await this.getProjectHoldingCurrenciesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get payment by id.
     * Get payment
     */
    async getProjectPaymentRaw(requestParameters: GetProjectPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Payment>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectPayment.');
        }

        if (requestParameters.paymentId === null || requestParameters.paymentId === undefined) {
            throw new runtime.RequiredError('paymentId','Required parameter requestParameters.paymentId was null or undefined when calling getProjectPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/payments/{payment_id}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"payment_id"}}`, encodeURIComponent(String(requestParameters.paymentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentFromJSON(jsonValue));
    }

    /**
     * Get payment by id.
     * Get payment
     */
    async getProjectPayment(requestParameters: GetProjectPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Payment> {
        const response = await this.getProjectPaymentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns project team member roles.
     * Get project roles
     */
    async getProjectRolesRaw(requestParameters: GetProjectRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProjectRole>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectRoles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/roles`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectRoleFromJSON));
    }

    /**
     * Returns project team member roles.
     * Get project roles
     */
    async getProjectRoles(requestParameters: GetProjectRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProjectRole>> {
        const response = await this.getProjectRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns data about swap.
     * Get swap information
     */
    async getProjectSwapRaw(requestParameters: GetProjectSwapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Swap>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectSwap.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProjectSwap.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/swaps/{id}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SwapFromJSON(jsonValue));
    }

    /**
     * Returns data about swap.
     * Get swap information
     */
    async getProjectSwap(requestParameters: GetProjectSwapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Swap> {
        const response = await this.getProjectSwapRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns estimated swap rate.
     * Get estimated swap rate
     */
    async getProjectSwapRateRaw(requestParameters: GetProjectSwapRateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SwapRate>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectSwapRate.');
        }

        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling getProjectSwapRate.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling getProjectSwapRate.');
        }

        if (requestParameters.amountFrom === null || requestParameters.amountFrom === undefined) {
            throw new runtime.RequiredError('amountFrom','Required parameter requestParameters.amountFrom was null or undefined when calling getProjectSwapRate.');
        }

        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        if (requestParameters.amountFrom !== undefined) {
            queryParameters['amount_from'] = requestParameters.amountFrom;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/swaps/rate`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SwapRateFromJSON(jsonValue));
    }

    /**
     * Returns estimated swap rate.
     * Get estimated swap rate
     */
    async getProjectSwapRate(requestParameters: GetProjectSwapRateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SwapRate> {
        const response = await this.getProjectSwapRateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns project team members.
     * Get project team members
     */
    async getProjectTeamMembersRaw(requestParameters: GetProjectTeamMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProjectTeamMember>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectTeamMembers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/team_members`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectTeamMemberFromJSON));
    }

    /**
     * Returns project team members.
     * Get project team members
     */
    async getProjectTeamMembers(requestParameters: GetProjectTeamMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProjectTeamMember>> {
        const response = await this.getProjectTeamMembersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get address whitelist for withdrawal.
     * Get address whitelist for withdrawal
     */
    async getProjectWhitelistRaw(requestParameters: GetProjectWhitelistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetWhitelistResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectWhitelist.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/whitelist`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetWhitelistResponseFromJSON(jsonValue));
    }

    /**
     * Get address whitelist for withdrawal.
     * Get address whitelist for withdrawal
     */
    async getProjectWhitelist(requestParameters: GetProjectWhitelistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetWhitelistResponse> {
        const response = await this.getProjectWhitelistRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns data about withdrawal.
     * Get withdrawal information
     */
    async getProjectWithdrawalRaw(requestParameters: GetProjectWithdrawalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Withdrawal>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectWithdrawal.');
        }

        if (requestParameters.withdrawalId === null || requestParameters.withdrawalId === undefined) {
            throw new runtime.RequiredError('withdrawalId','Required parameter requestParameters.withdrawalId was null or undefined when calling getProjectWithdrawal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/withdrawals/{withdrawal_id}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"withdrawal_id"}}`, encodeURIComponent(String(requestParameters.withdrawalId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WithdrawalFromJSON(jsonValue));
    }

    /**
     * Returns data about withdrawal.
     * Get withdrawal information
     */
    async getProjectWithdrawal(requestParameters: GetProjectWithdrawalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Withdrawal> {
        const response = await this.getProjectWithdrawalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List API keys associated with the project.
     * List API keys
     */
    async listAPIKeysRaw(requestParameters: ListAPIKeysRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<APIKey>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling listAPIKeys.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/api_keys`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(APIKeyFromJSON));
    }

    /**
     * List API keys associated with the project.
     * List API keys
     */
    async listAPIKeys(requestParameters: ListAPIKeysRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<APIKey>> {
        const response = await this.listAPIKeysRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List API keys permissions.
     * List API keys permissions
     */
    async listAPIKeysPermissionsRaw(requestParameters: ListAPIKeysPermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProjectAPIKeyPermissionDetails>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling listAPIKeysPermissions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/api_keys/permissions`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectAPIKeyPermissionDetailsFromJSON));
    }

    /**
     * List API keys permissions.
     * List API keys permissions
     */
    async listAPIKeysPermissions(requestParameters: ListAPIKeysPermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProjectAPIKeyPermissionDetails>> {
        const response = await this.listAPIKeysPermissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of static deposit addresses.
     * List addresses
     * @deprecated
     */
    async listAddressesRaw(requestParameters: ListAddressesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListAddresses200Response>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling listAddresses.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.currency !== undefined) {
            queryParameters['currency'] = requestParameters.currency;
        }

        if (requestParameters.network !== undefined) {
            queryParameters['network'] = requestParameters.network;
        }

        if (requestParameters.address !== undefined) {
            queryParameters['address'] = requestParameters.address;
        }

        if (requestParameters.addressTag !== undefined) {
            queryParameters['address_tag'] = requestParameters.addressTag;
        }

        if (requestParameters.customerId !== undefined) {
            queryParameters['customer_id'] = requestParameters.customerId;
        }

        if (requestParameters.createdAtFrom !== undefined) {
            queryParameters['created_at_from'] = (requestParameters.createdAtFrom as any).toISOString();
        }

        if (requestParameters.createdAtTo !== undefined) {
            queryParameters['created_at_to'] = (requestParameters.createdAtTo as any).toISOString();
        }

        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/addresses`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListAddresses200ResponseFromJSON(jsonValue));
    }

    /**
     * Returns a list of static deposit addresses.
     * List addresses
     * @deprecated
     */
    async listAddresses(requestParameters: ListAddressesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListAddresses200Response> {
        const response = await this.listAddressesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all project team member permissions.
     * Lists all permissions
     */
    async listAllPermissionsRaw(requestParameters: ListAllPermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListAllPermissions200Response>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling listAllPermissions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/permissions`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListAllPermissions200ResponseFromJSON(jsonValue));
    }

    /**
     * List all project team member permissions.
     * Lists all permissions
     */
    async listAllPermissions(requestParameters: ListAllPermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListAllPermissions200Response> {
        const response = await this.listAllPermissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of static deposit addresses for the customer.
     * List customer addresses
     */
    async listCustomerAddressesRaw(requestParameters: ListCustomerAddressesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListAddresses200Response>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling listCustomerAddresses.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.currency !== undefined) {
            queryParameters['currency'] = requestParameters.currency;
        }

        if (requestParameters.network !== undefined) {
            queryParameters['network'] = requestParameters.network;
        }

        if (requestParameters.address !== undefined) {
            queryParameters['address'] = requestParameters.address;
        }

        if (requestParameters.addressTag !== undefined) {
            queryParameters['address_tag'] = requestParameters.addressTag;
        }

        if (requestParameters.customerId !== undefined) {
            queryParameters['customer_id'] = requestParameters.customerId;
        }

        if (requestParameters.createdAtFrom !== undefined) {
            queryParameters['created_at_from'] = (requestParameters.createdAtFrom as any).toISOString();
        }

        if (requestParameters.createdAtTo !== undefined) {
            queryParameters['created_at_to'] = (requestParameters.createdAtTo as any).toISOString();
        }

        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/addresses/customer`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListAddresses200ResponseFromJSON(jsonValue));
    }

    /**
     * Returns a list of static deposit addresses for the customer.
     * List customer addresses
     */
    async listCustomerAddresses(requestParameters: ListCustomerAddressesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListAddresses200Response> {
        const response = await this.listCustomerAddressesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of static deposit addresses for the project.
     * List project addresses
     */
    async listProjectAddressesRaw(requestParameters: ListProjectAddressesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListProjectAddresses200Response>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling listProjectAddresses.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.currency !== undefined) {
            queryParameters['currency'] = requestParameters.currency;
        }

        if (requestParameters.network !== undefined) {
            queryParameters['network'] = requestParameters.network;
        }

        if (requestParameters.swap !== undefined) {
            queryParameters['swap'] = requestParameters.swap;
        }

        if (requestParameters.holdingCurrency !== undefined) {
            queryParameters['holding_currency'] = requestParameters.holdingCurrency;
        }

        if (requestParameters.address !== undefined) {
            queryParameters['address'] = requestParameters.address;
        }

        if (requestParameters.addressTag !== undefined) {
            queryParameters['address_tag'] = requestParameters.addressTag;
        }

        if (requestParameters.createdAtFrom !== undefined) {
            queryParameters['created_at_from'] = (requestParameters.createdAtFrom as any).toISOString();
        }

        if (requestParameters.createdAtTo !== undefined) {
            queryParameters['created_at_to'] = (requestParameters.createdAtTo as any).toISOString();
        }

        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/addresses/project`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListProjectAddresses200ResponseFromJSON(jsonValue));
    }

    /**
     * Returns a list of static deposit addresses for the project.
     * List project addresses
     */
    async listProjectAddresses(requestParameters: ListProjectAddressesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListProjectAddresses200Response> {
        const response = await this.listProjectAddressesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns project\'s payments.
     * List payments
     */
    async listProjectPaymentsRaw(requestParameters: ListProjectPaymentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListPayments200Response>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling listProjectPayments.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.origin !== undefined) {
            queryParameters['origin'] = requestParameters.origin;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        if (requestParameters.orderId !== undefined) {
            queryParameters['order_id'] = requestParameters.orderId;
        }

        if (requestParameters.customerEmail !== undefined) {
            queryParameters['customer_email'] = requestParameters.customerEmail;
        }

        if (requestParameters.customerId !== undefined) {
            queryParameters['customer_id'] = requestParameters.customerId;
        }

        if (requestParameters.txnHash !== undefined) {
            queryParameters['txn_hash'] = requestParameters.txnHash;
        }

        if (requestParameters.payinAddress !== undefined) {
            queryParameters['payin_address'] = requestParameters.payinAddress;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = (requestParameters.dateTo as any).toISOString();
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/payments`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListPayments200ResponseFromJSON(jsonValue));
    }

    /**
     * Returns project\'s payments.
     * List payments
     */
    async listProjectPayments(requestParameters: ListProjectPaymentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListPayments200Response> {
        const response = await this.listProjectPaymentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of project\'s transactions
     * List project\'s transactions
     */
    async listProjectTransactionsRaw(requestParameters: ListProjectTransactionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListTransactions200Response>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling listProjectTransactions.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = (requestParameters.dateTo as any).toISOString();
        }

        if (requestParameters.updatedAtFrom !== undefined) {
            queryParameters['updated_at_from'] = (requestParameters.updatedAtFrom as any).toISOString();
        }

        if (requestParameters.updatedAtTo !== undefined) {
            queryParameters['updated_at_to'] = (requestParameters.updatedAtTo as any).toISOString();
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.operationId !== undefined) {
            queryParameters['operation_id'] = requestParameters.operationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/transactions`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListTransactions200ResponseFromJSON(jsonValue));
    }

    /**
     * Returns a list of project\'s transactions
     * List project\'s transactions
     */
    async listProjectTransactions(requestParameters: ListProjectTransactionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListTransactions200Response> {
        const response = await this.listProjectTransactionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns data about withdrawals.
     * List withdrawals information
     */
    async listProjectWithdrawalsRaw(requestParameters: ListProjectWithdrawalsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListProjectWithdrawals200Response>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling listProjectWithdrawals.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        if (requestParameters.note !== undefined) {
            queryParameters['note'] = requestParameters.note;
        }

        if (requestParameters.orderId !== undefined) {
            queryParameters['order_id'] = requestParameters.orderId;
        }

        if (requestParameters.origin !== undefined) {
            queryParameters['origin'] = requestParameters.origin;
        }

        if (requestParameters.creditCurrency !== undefined) {
            queryParameters['credit_currency'] = requestParameters.creditCurrency;
        }

        if (requestParameters.creditAmount !== undefined) {
            queryParameters['credit_amount'] = requestParameters.creditAmount;
        }

        if (requestParameters.receiveCurrency !== undefined) {
            queryParameters['receive_currency'] = requestParameters.receiveCurrency;
        }

        if (requestParameters.receiveNetwork !== undefined) {
            queryParameters['receive_network'] = requestParameters.receiveNetwork;
        }

        if (requestParameters.receiveAmount !== undefined) {
            queryParameters['receive_amount'] = requestParameters.receiveAmount;
        }

        if (requestParameters.address !== undefined) {
            queryParameters['address'] = requestParameters.address;
        }

        if (requestParameters.addressTag !== undefined) {
            queryParameters['address_tag'] = requestParameters.addressTag;
        }

        if (requestParameters.hash !== undefined) {
            queryParameters['hash'] = requestParameters.hash;
        }

        if (requestParameters.createAtFrom !== undefined) {
            queryParameters['create_at_from'] = (requestParameters.createAtFrom as any).toISOString();
        }

        if (requestParameters.createAtTo !== undefined) {
            queryParameters['create_at_to'] = (requestParameters.createAtTo as any).toISOString();
        }

        if (requestParameters.updateAtFrom !== undefined) {
            queryParameters['update_at_from'] = (requestParameters.updateAtFrom as any).toISOString();
        }

        if (requestParameters.updateAtTo !== undefined) {
            queryParameters['update_at_to'] = (requestParameters.updateAtTo as any).toISOString();
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/withdrawals`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListProjectWithdrawals200ResponseFromJSON(jsonValue));
    }

    /**
     * Returns data about withdrawals.
     * List withdrawals information
     */
    async listProjectWithdrawals(requestParameters: ListProjectWithdrawalsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListProjectWithdrawals200Response> {
        const response = await this.listProjectWithdrawalsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List merchant\'s projects
     * List projects
     */
    async listProjectsRaw(requestParameters: ListProjectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListProjectsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListProjectsResponseFromJSON(jsonValue));
    }

    /**
     * List merchant\'s projects
     * List projects
     */
    async listProjects(requestParameters: ListProjectsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListProjectsResponse> {
        const response = await this.listProjectsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Swaps project\'s coins.
     * Swap coins
     */
    async projectSwapRaw(requestParameters: ProjectSwapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Swap>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectSwap.');
        }

        if (requestParameters.swapRequest === null || requestParameters.swapRequest === undefined) {
            throw new runtime.RequiredError('swapRequest','Required parameter requestParameters.swapRequest was null or undefined when calling projectSwap.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/swaps`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SwapRequestToJSON(requestParameters.swapRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SwapFromJSON(jsonValue));
    }

    /**
     * Swaps project\'s coins.
     * Swap coins
     */
    async projectSwap(requestParameters: ProjectSwapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Swap> {
        const response = await this.projectSwapRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes project team member.
     * Remove project team member
     */
    async removeProjectTeamMemberRaw(requestParameters: RemoveProjectTeamMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling removeProjectTeamMember.');
        }

        if (requestParameters.teamMemberId === null || requestParameters.teamMemberId === undefined) {
            throw new runtime.RequiredError('teamMemberId','Required parameter requestParameters.teamMemberId was null or undefined when calling removeProjectTeamMember.');
        }

        if (requestParameters.tOTPCredentials === null || requestParameters.tOTPCredentials === undefined) {
            throw new runtime.RequiredError('tOTPCredentials','Required parameter requestParameters.tOTPCredentials was null or undefined when calling removeProjectTeamMember.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/team_members/{team_member_id}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"team_member_id"}}`, encodeURIComponent(String(requestParameters.teamMemberId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: TOTPCredentialsToJSON(requestParameters.tOTPCredentials),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes project team member.
     * Remove project team member
     */
    async removeProjectTeamMember(requestParameters: RemoveProjectTeamMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeProjectTeamMemberRaw(requestParameters, initOverrides);
    }

    /**
     * Resends project team member invitation
     * Resend project team member invitation
     */
    async resendProjectTeamMemberInvitationRaw(requestParameters: ResendProjectTeamMemberInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling resendProjectTeamMemberInvitation.');
        }

        if (requestParameters.teamMemberId === null || requestParameters.teamMemberId === undefined) {
            throw new runtime.RequiredError('teamMemberId','Required parameter requestParameters.teamMemberId was null or undefined when calling resendProjectTeamMemberInvitation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/team_members/{team_member_id}/resend_invitation`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"team_member_id"}}`, encodeURIComponent(String(requestParameters.teamMemberId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Resends project team member invitation
     * Resend project team member invitation
     */
    async resendProjectTeamMemberInvitation(requestParameters: ResendProjectTeamMemberInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.resendProjectTeamMemberInvitationRaw(requestParameters, initOverrides);
    }

    /**
     * Sends status callback for this payment, if configured.
     * Send payment callback
     */
    async sendProjectPaymentCallbackRaw(requestParameters: SendProjectPaymentCallbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CallbackResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling sendProjectPaymentCallback.');
        }

        if (requestParameters.paymentId === null || requestParameters.paymentId === undefined) {
            throw new runtime.RequiredError('paymentId','Required parameter requestParameters.paymentId was null or undefined when calling sendProjectPaymentCallback.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/payments/{payment_id}/callback`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"payment_id"}}`, encodeURIComponent(String(requestParameters.paymentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CallbackResponseFromJSON(jsonValue));
    }

    /**
     * Sends status callback for this payment, if configured.
     * Send payment callback
     */
    async sendProjectPaymentCallback(requestParameters: SendProjectPaymentCallbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CallbackResponse> {
        const response = await this.sendProjectPaymentCallbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create job for sending callback with current withdrawal\'s status. Need for debug purposes.
     * Send withdrawal callback
     */
    async sendProjectWithdrawalCallbackRaw(requestParameters: SendProjectWithdrawalCallbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CallbackResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling sendProjectWithdrawalCallback.');
        }

        if (requestParameters.withdrawalId === null || requestParameters.withdrawalId === undefined) {
            throw new runtime.RequiredError('withdrawalId','Required parameter requestParameters.withdrawalId was null or undefined when calling sendProjectWithdrawalCallback.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/withdrawals/{withdrawal_id}/callback`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"withdrawal_id"}}`, encodeURIComponent(String(requestParameters.withdrawalId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CallbackResponseFromJSON(jsonValue));
    }

    /**
     * Create job for sending callback with current withdrawal\'s status. Need for debug purposes.
     * Send withdrawal callback
     */
    async sendProjectWithdrawalCallback(requestParameters: SendProjectWithdrawalCallbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CallbackResponse> {
        const response = await this.sendProjectWithdrawalCallbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sends test callback to the configured url and returns its response.
     * Send test callback
     */
    async sendTestCallbackRaw(requestParameters: SendTestCallbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CallbackResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling sendTestCallback.');
        }

        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/callback/test`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CallbackResponseFromJSON(jsonValue));
    }

    /**
     * Sends test callback to the configured url and returns its response.
     * Send test callback
     */
    async sendTestCallback(requestParameters: SendTestCallbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CallbackResponse> {
        const response = await this.sendTestCallbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the enabled status of holding currencies.
     * Update holding currencies
     */
    async setProjectHoldingCurrenciesRaw(requestParameters: SetProjectHoldingCurrenciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling setProjectHoldingCurrencies.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling setProjectHoldingCurrencies.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/holding_currencies`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the enabled status of holding currencies.
     * Update holding currencies
     */
    async setProjectHoldingCurrencies(requestParameters: SetProjectHoldingCurrenciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setProjectHoldingCurrenciesRaw(requestParameters, initOverrides);
    }

    /**
     * Updates API Key properties.
     * Update API Key
     */
    async updateAPIKeyRaw(requestParameters: UpdateAPIKeyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<APIKey>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling updateAPIKey.');
        }

        if (requestParameters.apiKeyId === null || requestParameters.apiKeyId === undefined) {
            throw new runtime.RequiredError('apiKeyId','Required parameter requestParameters.apiKeyId was null or undefined when calling updateAPIKey.');
        }

        if (requestParameters.updateAPIKeyRequest === null || requestParameters.updateAPIKeyRequest === undefined) {
            throw new runtime.RequiredError('updateAPIKeyRequest','Required parameter requestParameters.updateAPIKeyRequest was null or undefined when calling updateAPIKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/api_keys/{api_key_id}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"api_key_id"}}`, encodeURIComponent(String(requestParameters.apiKeyId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAPIKeyRequestToJSON(requestParameters.updateAPIKeyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIKeyFromJSON(jsonValue));
    }

    /**
     * Updates API Key properties.
     * Update API Key
     */
    async updateAPIKey(requestParameters: UpdateAPIKeyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<APIKey> {
        const response = await this.updateAPIKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the static deposit address.
     * Update address
     * @deprecated
     */
    async updateAddressRaw(requestParameters: UpdateAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling updateAddress.');
        }

        if (requestParameters.addressId === null || requestParameters.addressId === undefined) {
            throw new runtime.RequiredError('addressId','Required parameter requestParameters.addressId was null or undefined when calling updateAddress.');
        }

        if (requestParameters.updateCustomerAddressRequest === null || requestParameters.updateCustomerAddressRequest === undefined) {
            throw new runtime.RequiredError('updateCustomerAddressRequest','Required parameter requestParameters.updateCustomerAddressRequest was null or undefined when calling updateAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/addresses/{address_id}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"address_id"}}`, encodeURIComponent(String(requestParameters.addressId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCustomerAddressRequestToJSON(requestParameters.updateCustomerAddressRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates the static deposit address.
     * Update address
     * @deprecated
     */
    async updateAddress(requestParameters: UpdateAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateAddressRaw(requestParameters, initOverrides);
    }

    /**
     * Updates callback url and/or issues a new signature public key.
     * Update callback
     */
    async updateCallbackRaw(requestParameters: UpdateCallbackOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Callback>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling updateCallback.');
        }

        if (requestParameters.updateCallbackRequest === null || requestParameters.updateCallbackRequest === undefined) {
            throw new runtime.RequiredError('updateCallbackRequest','Required parameter requestParameters.updateCallbackRequest was null or undefined when calling updateCallback.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/callback`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCallbackRequestToJSON(requestParameters.updateCallbackRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CallbackFromJSON(jsonValue));
    }

    /**
     * Updates callback url and/or issues a new signature public key.
     * Update callback
     */
    async updateCallback(requestParameters: UpdateCallbackOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Callback> {
        const response = await this.updateCallbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the static deposit address for the customer.
     * Update customer address
     */
    async updateCustomerAddressRaw(requestParameters: UpdateCustomerAddressOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling updateCustomerAddress.');
        }

        if (requestParameters.addressId === null || requestParameters.addressId === undefined) {
            throw new runtime.RequiredError('addressId','Required parameter requestParameters.addressId was null or undefined when calling updateCustomerAddress.');
        }

        if (requestParameters.updateCustomerAddressRequest === null || requestParameters.updateCustomerAddressRequest === undefined) {
            throw new runtime.RequiredError('updateCustomerAddressRequest','Required parameter requestParameters.updateCustomerAddressRequest was null or undefined when calling updateCustomerAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/addresses/customer/{address_id}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"address_id"}}`, encodeURIComponent(String(requestParameters.addressId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCustomerAddressRequestToJSON(requestParameters.updateCustomerAddressRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates the static deposit address for the customer.
     * Update customer address
     */
    async updateCustomerAddress(requestParameters: UpdateCustomerAddressOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCustomerAddressRaw(requestParameters, initOverrides);
    }

    /**
     * Updates projects settings.
     * Update project
     */
    async updateProjectRaw(requestParameters: UpdateProjectOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Project>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling updateProject.');
        }

        if (requestParameters.updateProjectRequest === null || requestParameters.updateProjectRequest === undefined) {
            throw new runtime.RequiredError('updateProjectRequest','Required parameter requestParameters.updateProjectRequest was null or undefined when calling updateProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateProjectRequestToJSON(requestParameters.updateProjectRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectFromJSON(jsonValue));
    }

    /**
     * Updates projects settings.
     * Update project
     */
    async updateProject(requestParameters: UpdateProjectOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Project> {
        const response = await this.updateProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the static deposit address for the project.
     * Update project address
     */
    async updateProjectAddressRaw(requestParameters: UpdateProjectAddressOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling updateProjectAddress.');
        }

        if (requestParameters.addressId === null || requestParameters.addressId === undefined) {
            throw new runtime.RequiredError('addressId','Required parameter requestParameters.addressId was null or undefined when calling updateProjectAddress.');
        }

        if (requestParameters.updateProjectAddressRequest === null || requestParameters.updateProjectAddressRequest === undefined) {
            throw new runtime.RequiredError('updateProjectAddressRequest','Required parameter requestParameters.updateProjectAddressRequest was null or undefined when calling updateProjectAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/addresses/project/{address_id}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"address_id"}}`, encodeURIComponent(String(requestParameters.addressId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateProjectAddressRequestToJSON(requestParameters.updateProjectAddressRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates the static deposit address for the project.
     * Update project address
     */
    async updateProjectAddress(requestParameters: UpdateProjectAddressOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateProjectAddressRaw(requestParameters, initOverrides);
    }

    /**
     * Updates project team member roles.
     * Update project roles
     */
    async updateProjectRolesRaw(requestParameters: UpdateProjectRolesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProjectRole>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling updateProjectRoles.');
        }

        if (requestParameters.updateProjectRolesRequest === null || requestParameters.updateProjectRolesRequest === undefined) {
            throw new runtime.RequiredError('updateProjectRolesRequest','Required parameter requestParameters.updateProjectRolesRequest was null or undefined when calling updateProjectRoles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/roles`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateProjectRolesRequestToJSON(requestParameters.updateProjectRolesRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectRoleFromJSON));
    }

    /**
     * Updates project team member roles.
     * Update project roles
     */
    async updateProjectRoles(requestParameters: UpdateProjectRolesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProjectRole>> {
        const response = await this.updateProjectRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates project team member.
     * Update project team member
     */
    async updateProjectTeamMemberRaw(requestParameters: UpdateProjectTeamMemberOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectTeamMember>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling updateProjectTeamMember.');
        }

        if (requestParameters.teamMemberId === null || requestParameters.teamMemberId === undefined) {
            throw new runtime.RequiredError('teamMemberId','Required parameter requestParameters.teamMemberId was null or undefined when calling updateProjectTeamMember.');
        }

        if (requestParameters.updateProjectTeamMemberRequest === null || requestParameters.updateProjectTeamMemberRequest === undefined) {
            throw new runtime.RequiredError('updateProjectTeamMemberRequest','Required parameter requestParameters.updateProjectTeamMemberRequest was null or undefined when calling updateProjectTeamMember.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/team_members/{team_member_id}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"team_member_id"}}`, encodeURIComponent(String(requestParameters.teamMemberId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateProjectTeamMemberRequestToJSON(requestParameters.updateProjectTeamMemberRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectTeamMemberFromJSON(jsonValue));
    }

    /**
     * Updates project team member.
     * Update project team member
     */
    async updateProjectTeamMember(requestParameters: UpdateProjectTeamMemberOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectTeamMember> {
        const response = await this.updateProjectTeamMemberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update whitelist enabled field.
     * Update whitelist
     */
    async updateProjectWhitelistRaw(requestParameters: UpdateProjectWhitelistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling updateProjectWhitelist.');
        }

        if (requestParameters.whitelistUpdateRequest === null || requestParameters.whitelistUpdateRequest === undefined) {
            throw new runtime.RequiredError('whitelistUpdateRequest','Required parameter requestParameters.whitelistUpdateRequest was null or undefined when calling updateProjectWhitelist.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/whitelist`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WhitelistUpdateRequestToJSON(requestParameters.whitelistUpdateRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update whitelist enabled field.
     * Update whitelist
     */
    async updateProjectWhitelist(requestParameters: UpdateProjectWhitelistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateProjectWhitelistRaw(requestParameters, initOverrides);
    }

    /**
     * Validate address without adding it
     * Validate address without adding it
     */
    async validateProjectWhitelistAddressRaw(requestParameters: ValidateProjectWhitelistAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidateWhitelistAddressResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling validateProjectWhitelistAddress.');
        }

        if (requestParameters.validateWhitelistAddressRequest === null || requestParameters.validateWhitelistAddressRequest === undefined) {
            throw new runtime.RequiredError('validateWhitelistAddressRequest','Required parameter requestParameters.validateWhitelistAddressRequest was null or undefined when calling validateProjectWhitelistAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{project_id}/whitelist/addresses/validate`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ValidateWhitelistAddressRequestToJSON(requestParameters.validateWhitelistAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidateWhitelistAddressResponseFromJSON(jsonValue));
    }

    /**
     * Validate address without adding it
     * Validate address without adding it
     */
    async validateProjectWhitelistAddress(requestParameters: ValidateProjectWhitelistAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidateWhitelistAddressResponse> {
        const response = await this.validateProjectWhitelistAddressRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
  * @export
  * @enum {string}
  */
export enum SendTestCallbackType {
    Payment = 'payment',
    Withdrawal = 'withdrawal'
}
