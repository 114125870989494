export type ErrorInner = {
    code: string
    message: string
}

export type APIErrorResponseBase = {
    errors?: ErrorInner[]
}

export enum PosthogHttpErrorEvents {
    cabinetHttpError = 'ms_lk_error',
}

export interface HttpErrorEventDataMap {
    [PosthogHttpErrorEvents.cabinetHttpError]: {
        message: string
        request_url: string
        screen_url: string
        project_id: string | null
    }
}
