import { Flex, Spin } from '@merchant/ui-kit/ant-design'
import { useState } from 'react'
import { steps } from './types'
import { useData } from './useData'
import { useSwapCurrencies } from './useSwapCurrencies'
import { ConvertInput, ConvertProgress } from './views'
import { useActionModalContext } from '~features/ActionModal/context'
import { PlaceholderView } from '~features/PlaceholderView'

export function Convert() {
    const [step, setStep] = useState(steps.convertInput)
    const { setTitle: setModalTitle, closeModal, setShouldRevalidateDependentTables } = useActionModalContext()
    const { notification, projectId, isLoadingProject, isLoadingPlaceholder, placeholderName } = useData({
        step,
        setModalTitle,
        setShouldRevalidateDependentTables,
    })

    const {
        send: swapCurrencies,
        data: swapResponse,
        isLoading: isSwapCurrenciesLoading,
        error: swapError,
    } = useSwapCurrencies({ projectId, setStep, notification })

    if (!isLoadingPlaceholder && placeholderName) {
        return (
            <PlaceholderView
                name={placeholderName}
                spaceProps={{
                    style: {
                        padding: 0,
                        width: '100%',
                        justifyContent: 'center',
                    },
                    styles: {
                        item: {
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                        },
                    },
                }}
                buttonProps={{ block: true, 'data-merchant': 'convert-placeholder-no-merchant-ok-button' }}
            />
        )
    }

    const toInputStep = () => setStep(steps.convertInput)

    const initialData = swapResponse
        ? {
              send: {
                  currency: swapResponse.currencyFrom,
                  value: swapResponse.amountFrom,
              },
              receive: {
                  currency: swapResponse.currencyFrom,
                  value: '',
              },
          }
        : undefined

    const views = {
        [steps.convertInput]: (
            <ConvertInput initialData={initialData} handleSwap={swapCurrencies} isSwapping={isSwapCurrenciesLoading} />
        ),
        [steps.convertProgress]: swapResponse ? (
            <ConvertProgress
                onRetry={toInputStep}
                isFailedRequest={swapError !== undefined}
                trxId={swapResponse.txnId}
                onClose={closeModal}
            />
        ) : null,
    }

    if (isLoadingProject || isLoadingPlaceholder) {
        return (
            <Flex justify="center" style={{ height: 100 }} align="center" data-merchant="withdrawal-crypto-loading">
                <Spin size="large" spinning data-merchant="withdrawal-crypto-loading-spin" />
            </Flex>
        )
    }

    if (step in views) {
        return views[step]
    }

    return null
}
