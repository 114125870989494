import { DEFAULT_PRECISION } from '~constants'

export function getCurrencyMinPrecision<
    T extends { [key: string]: { precision: number; code: string; networks: { precision: number }[] } | undefined },
>(currencyCode?: string | null, currencies?: T): number {
    if (!currencyCode || !currencies) {
        return DEFAULT_PRECISION
    }

    const currentCurrency = currencies[currencyCode]
    const networksMinPrecision = Math.min(...(currentCurrency?.networks || []).map(({ precision }) => precision))

    return Math.min(networksMinPrecision, currentCurrency?.precision || DEFAULT_PRECISION)
}
