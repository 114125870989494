import { Typography } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'

const { Text } = Typography

const errorWrapperStyles: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 12,
    marginTop: 6,
    minHeight: 18,
}
const errorStyles: React.CSSProperties = {
    fontSize: 12,
    maxWidth: '80%',
}

interface ErrorMessageProps {
    isSelectedPairSupported: boolean | undefined
    isCurrencyPairSelected: boolean
    isConvertible: boolean | undefined
    isSourceCurrencySelected: boolean
}

export function ErrorMessage({
    isSelectedPairSupported,
    isCurrencyPairSelected,
    isConvertible,
    isSourceCurrencySelected,
}: ErrorMessageProps) {
    return (
        <div style={errorWrapperStyles}>
            <Text data-merchant={null} type="danger" style={errorStyles}>
                {!isSelectedPairSupported && isCurrencyPairSelected && (
                    <div>
                        <FormattedMessage {...lang.notSupportedPair} />
                    </div>
                )}
                {!isConvertible && isSourceCurrencySelected && (
                    <div>
                        <FormattedMessage {...lang.notConvertibleCurrency} />
                    </div>
                )}
            </Text>
        </div>
    )
}
