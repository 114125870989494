/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NetworkManagingCoin } from './NetworkManagingCoin';
import {
    NetworkManagingCoinFromJSON,
    NetworkManagingCoinFromJSONTyped,
    NetworkManagingCoinToJSON,
} from './NetworkManagingCoin';

/**
 * 
 * @export
 * @interface Network
 */
export interface Network {
    /**
     * 
     * @type {string}
     * @memberof Network
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Network
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Network
     */
    protocol: string;
    /**
     * 
     * @type {number}
     * @memberof Network
     */
    type: number;
    /**
     * 
     * @type {boolean}
     * @memberof Network
     */
    _default: boolean;
    /**
     * 
     * @type {NetworkManagingCoin}
     * @memberof Network
     */
    managingCoin?: NetworkManagingCoin;
    /**
     * 
     * @type {number}
     * @memberof Network
     */
    precision: number;
    /**
     * 
     * @type {boolean}
     * @memberof Network
     */
    payinEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Network
     */
    payoutEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof Network
     */
    minimumPayinAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof Network
     */
    minimumDirectPayinAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof Network
     */
    addressRegex?: string;
    /**
     * 
     * @type {string}
     * @memberof Network
     */
    addressTagName?: string;
    /**
     * 
     * @type {string}
     * @memberof Network
     */
    addressTagRegex?: string;
    /**
     * 
     * @type {string}
     * @memberof Network
     */
    cryptoExplorer?: string;
}

/**
 * Check if a given object implements the Network interface.
 */
// @ts-ignore
export function instanceOfNetwork(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "protocol" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "_default" in value;
    isInstance = isInstance && "precision" in value;
    isInstance = isInstance && "payinEnabled" in value;
    isInstance = isInstance && "payoutEnabled" in value;

    return isInstance;
}

// @ts-ignore
export function NetworkFromJSON(json: any): Network {
    return NetworkFromJSONTyped(json, false);
}

// @ts-ignore
export function NetworkFromJSONTyped(json: any, ignoreDiscriminator: boolean): Network {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'code': json['code'],
        'protocol': json['protocol'],
        'type': json['type'],
        '_default': json['default'],
        'managingCoin': !exists(json, 'managing_coin') ? undefined : NetworkManagingCoinFromJSON(json['managing_coin']),
        'precision': json['precision'],
        'payinEnabled': json['payin_enabled'],
        'payoutEnabled': json['payout_enabled'],
        'minimumPayinAmount': !exists(json, 'minimum_payin_amount') ? undefined : json['minimum_payin_amount'],
        'minimumDirectPayinAmount': !exists(json, 'minimum_direct_payin_amount') ? undefined : json['minimum_direct_payin_amount'],
        'addressRegex': !exists(json, 'address_regex') ? undefined : json['address_regex'],
        'addressTagName': !exists(json, 'address_tag_name') ? undefined : json['address_tag_name'],
        'addressTagRegex': !exists(json, 'address_tag_regex') ? undefined : json['address_tag_regex'],
        'cryptoExplorer': !exists(json, 'crypto_explorer') ? undefined : json['crypto_explorer'],
    };
}

// @ts-ignore
export function NetworkToJSON(value?: Network | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'code': value.code,
        'protocol': value.protocol,
        'type': value.type,
        'default': value._default,
        'managing_coin': NetworkManagingCoinToJSON(value.managingCoin),
        'precision': value.precision,
        'payin_enabled': value.payinEnabled,
        'payout_enabled': value.payoutEnabled,
        'minimum_payin_amount': value.minimumPayinAmount,
        'minimum_direct_payin_amount': value.minimumDirectPayinAmount,
        'address_regex': value.addressRegex,
        'address_tag_name': value.addressTagName,
        'address_tag_regex': value.addressTagRegex,
        'crypto_explorer': value.cryptoExplorer,
    };
}

