/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  APIErrorResponse,
  Balance,
  CallbackResponse,
  Currency,
  GetWhitelistResponse,
  ListPayments200Response,
  ListTransactions200Response,
  Merchant,
  Payment,
  PaymentState,
  Swap,
  SwapRate,
  SwapRequest,
  TransactionStatus,
  TransactionType,
  TransactionsSorting,
  UpdateMerchantRequest,
  ValidateWhitelistAddressRequest,
  ValidateWhitelistAddressResponse,
  WhitelistAddressUpdateRequest,
  WhitelistUpdateRequest,
} from '../models/index';
import {
    APIErrorResponseFromJSON,
    APIErrorResponseToJSON,
    BalanceFromJSON,
    BalanceToJSON,
    CallbackResponseFromJSON,
    CallbackResponseToJSON,
    CurrencyFromJSON,
    CurrencyToJSON,
    GetWhitelistResponseFromJSON,
    GetWhitelistResponseToJSON,
    ListPayments200ResponseFromJSON,
    ListPayments200ResponseToJSON,
    ListTransactions200ResponseFromJSON,
    ListTransactions200ResponseToJSON,
    MerchantFromJSON,
    MerchantToJSON,
    PaymentFromJSON,
    PaymentToJSON,
    PaymentStateFromJSON,
    PaymentStateToJSON,
    SwapFromJSON,
    SwapToJSON,
    SwapRateFromJSON,
    SwapRateToJSON,
    SwapRequestFromJSON,
    SwapRequestToJSON,
    TransactionStatusFromJSON,
    TransactionStatusToJSON,
    TransactionTypeFromJSON,
    TransactionTypeToJSON,
    TransactionsSortingFromJSON,
    TransactionsSortingToJSON,
    UpdateMerchantRequestFromJSON,
    UpdateMerchantRequestToJSON,
    ValidateWhitelistAddressRequestFromJSON,
    ValidateWhitelistAddressRequestToJSON,
    ValidateWhitelistAddressResponseFromJSON,
    ValidateWhitelistAddressResponseToJSON,
    WhitelistAddressUpdateRequestFromJSON,
    WhitelistAddressUpdateRequestToJSON,
    WhitelistUpdateRequestFromJSON,
    WhitelistUpdateRequestToJSON,
} from '../models/index';

export interface AddAddressToWhitelistRequest {
    whitelistAddressUpdateRequest: WhitelistAddressUpdateRequest;
}

export interface DeleteAddressFromWhitelistRequest {
    whitelistAddressUpdateRequest: WhitelistAddressUpdateRequest;
}

export interface ExportMerchantTransactionsRequest {
    xSpanId?: string;
    xTraceId?: string;
    sort?: TransactionsSorting;
    type?: TransactionType;
    status?: TransactionStatus;
    dateFrom?: Date;
    dateTo?: Date;
    updatedAtFrom?: Date;
    updatedAtTo?: Date;
    id?: string;
}

export interface ExportPaymentsRequest {
    xSpanId?: string;
    xTraceId?: string;
    projectId?: string;
    id?: string;
    state?: PaymentState;
    orderId?: string;
    customerEmail?: string;
    customerId?: string;
    dateFrom?: Date;
    dateTo?: Date;
}

export interface GetCurrenciesRequest {
    includeDisabled?: boolean;
}

export interface GetMerchantRequest {
    xSpanId?: string;
    xTraceId?: string;
}

export interface GetMerchantBalanceRequest {
    currency: string;
    xSpanId?: string;
    xTraceId?: string;
}

export interface GetMerchantBalancesRequest {
    xSpanId?: string;
    xTraceId?: string;
}

export interface GetPaymentRequest {
    paymentId: string;
    xSpanId?: string;
    xTraceId?: string;
}

export interface GetSwapRequest {
    id: string;
}

export interface GetSwapRateRequest {
    from: string;
    to: string;
    amountFrom: string;
    xSpanId?: string;
    xTraceId?: string;
}

export interface ListPaymentsRequest {
    xSpanId?: string;
    xTraceId?: string;
    projectId?: string;
    id?: string;
    origin?: string;
    state?: PaymentState;
    orderId?: string;
    customerEmail?: string;
    txnHash?: string;
    payinAddress?: string;
    dateFrom?: Date;
    dateTo?: Date;
    limit?: number;
    cursor?: string;
}

export interface ListTransactionsRequest {
    xSpanId?: string;
    xTraceId?: string;
    limit?: number;
    cursor?: string;
    sort?: TransactionsSorting;
    type?: TransactionType;
    status?: TransactionStatus;
    dateFrom?: Date;
    dateTo?: Date;
    updatedAtFrom?: Date;
    updatedAtTo?: Date;
    id?: string;
}

export interface SendPaymentCallbackRequest {
    paymentId: string;
    xSpanId?: string;
    xTraceId?: string;
}

export interface SwapOperationRequest {
    swapRequest: SwapRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface UpdateMerchantOperationRequest {
    updateMerchantRequest: UpdateMerchantRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface UpdateWhitelistRequest {
    whitelistUpdateRequest: WhitelistUpdateRequest;
}

export interface ValidateWhitelistAddressOperationRequest {
    validateWhitelistAddressRequest: ValidateWhitelistAddressRequest;
}

/**
 * 
 */
export class MerchantApi extends runtime.BaseAPI {

    /**
     * Add address to whitelist for withdrawal.
     * Add address to whitelist for withdrawal
     * @deprecated
     */
    async addAddressToWhitelistRaw(requestParameters: AddAddressToWhitelistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.whitelistAddressUpdateRequest === null || requestParameters.whitelistAddressUpdateRequest === undefined) {
            throw new runtime.RequiredError('whitelistAddressUpdateRequest','Required parameter requestParameters.whitelistAddressUpdateRequest was null or undefined when calling addAddressToWhitelist.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchant/whitelist/addresses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WhitelistAddressUpdateRequestToJSON(requestParameters.whitelistAddressUpdateRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add address to whitelist for withdrawal.
     * Add address to whitelist for withdrawal
     * @deprecated
     */
    async addAddressToWhitelist(requestParameters: AddAddressToWhitelistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addAddressToWhitelistRaw(requestParameters, initOverrides);
    }

    /**
     * Delete address from whitelist for withdrawal.
     * Delete address from whitelist for withdrawal
     * @deprecated
     */
    async deleteAddressFromWhitelistRaw(requestParameters: DeleteAddressFromWhitelistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.whitelistAddressUpdateRequest === null || requestParameters.whitelistAddressUpdateRequest === undefined) {
            throw new runtime.RequiredError('whitelistAddressUpdateRequest','Required parameter requestParameters.whitelistAddressUpdateRequest was null or undefined when calling deleteAddressFromWhitelist.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchant/whitelist/addresses`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: WhitelistAddressUpdateRequestToJSON(requestParameters.whitelistAddressUpdateRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete address from whitelist for withdrawal.
     * Delete address from whitelist for withdrawal
     * @deprecated
     */
    async deleteAddressFromWhitelist(requestParameters: DeleteAddressFromWhitelistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAddressFromWhitelistRaw(requestParameters, initOverrides);
    }

    /**
     * Exports merchant\'s transactions to a CSV file.
     * Export merchant\'s transactions
     * @deprecated
     */
    async exportMerchantTransactionsRaw(requestParameters: ExportMerchantTransactionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = (requestParameters.dateTo as any).toISOString();
        }

        if (requestParameters.updatedAtFrom !== undefined) {
            queryParameters['updated_at_from'] = (requestParameters.updatedAtFrom as any).toISOString();
        }

        if (requestParameters.updatedAtTo !== undefined) {
            queryParameters['updated_at_to'] = (requestParameters.updatedAtTo as any).toISOString();
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // MerchantTokenQuery authentication
        }

        const response = await this.request({
            path: `/merchant/transactions/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Exports merchant\'s transactions to a CSV file.
     * Export merchant\'s transactions
     * @deprecated
     */
    async exportMerchantTransactions(requestParameters: ExportMerchantTransactionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportMerchantTransactionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Exports merchant\'s payments to a CSV file.
     * Export payments
     * @deprecated
     */
    async exportPaymentsRaw(requestParameters: ExportPaymentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['project_id'] = requestParameters.projectId;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        if (requestParameters.orderId !== undefined) {
            queryParameters['order_id'] = requestParameters.orderId;
        }

        if (requestParameters.customerEmail !== undefined) {
            queryParameters['customer_email'] = requestParameters.customerEmail;
        }

        if (requestParameters.customerId !== undefined) {
            queryParameters['customer_id'] = requestParameters.customerId;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = (requestParameters.dateTo as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // MerchantTokenQuery authentication
        }

        const response = await this.request({
            path: `/merchant/payments/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Exports merchant\'s payments to a CSV file.
     * Export payments
     * @deprecated
     */
    async exportPayments(requestParameters: ExportPaymentsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportPaymentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns currency index.
     * Get currencies
     */
    async getCurrenciesRaw(requestParameters: GetCurrenciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: Currency; }>> {
        const queryParameters: any = {};

        if (requestParameters.includeDisabled !== undefined) {
            queryParameters['include_disabled'] = requestParameters.includeDisabled;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/merchant/currencies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, CurrencyFromJSON));
    }

    /**
     * Returns currency index.
     * Get currencies
     */
    async getCurrencies(requestParameters: GetCurrenciesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: Currency; }> {
        const response = await this.getCurrenciesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get merchant
     */
    async getMerchantRaw(requestParameters: GetMerchantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Merchant>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchant`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantFromJSON(jsonValue));
    }

    /**
     * Get merchant
     */
    async getMerchant(requestParameters: GetMerchantRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Merchant> {
        const response = await this.getMerchantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get merchant\'s balance for specific currency and network.
     * Get balance
     * @deprecated
     */
    async getMerchantBalanceRaw(requestParameters: GetMerchantBalanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Balance>> {
        if (requestParameters.currency === null || requestParameters.currency === undefined) {
            throw new runtime.RequiredError('currency','Required parameter requestParameters.currency was null or undefined when calling getMerchantBalance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchant/balances/{currency}`.replace(`{${"currency"}}`, encodeURIComponent(String(requestParameters.currency))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BalanceFromJSON(jsonValue));
    }

    /**
     * Get merchant\'s balance for specific currency and network.
     * Get balance
     * @deprecated
     */
    async getMerchantBalance(requestParameters: GetMerchantBalanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Balance> {
        const response = await this.getMerchantBalanceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of merchant\'s balances.
     * Get balances
     * @deprecated
     */
    async getMerchantBalancesRaw(requestParameters: GetMerchantBalancesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Balance>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchant/balances`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BalanceFromJSON));
    }

    /**
     * Get list of merchant\'s balances.
     * Get balances
     * @deprecated
     */
    async getMerchantBalances(requestParameters: GetMerchantBalancesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Balance>> {
        const response = await this.getMerchantBalancesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get payment by id.
     * Get payment
     * @deprecated
     */
    async getPaymentRaw(requestParameters: GetPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Payment>> {
        if (requestParameters.paymentId === null || requestParameters.paymentId === undefined) {
            throw new runtime.RequiredError('paymentId','Required parameter requestParameters.paymentId was null or undefined when calling getPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchant/payments/{payment_id}`.replace(`{${"payment_id"}}`, encodeURIComponent(String(requestParameters.paymentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentFromJSON(jsonValue));
    }

    /**
     * Get payment by id.
     * Get payment
     * @deprecated
     */
    async getPayment(requestParameters: GetPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Payment> {
        const response = await this.getPaymentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns data about swap.
     * Get swap information
     * @deprecated
     */
    async getSwapRaw(requestParameters: GetSwapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Swap>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSwap.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchant/swaps/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SwapFromJSON(jsonValue));
    }

    /**
     * Returns data about swap.
     * Get swap information
     * @deprecated
     */
    async getSwap(requestParameters: GetSwapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Swap> {
        const response = await this.getSwapRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns estimated swap rate.
     * Get estimated swap rate
     * @deprecated
     */
    async getSwapRateRaw(requestParameters: GetSwapRateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SwapRate>> {
        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling getSwapRate.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling getSwapRate.');
        }

        if (requestParameters.amountFrom === null || requestParameters.amountFrom === undefined) {
            throw new runtime.RequiredError('amountFrom','Required parameter requestParameters.amountFrom was null or undefined when calling getSwapRate.');
        }

        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        if (requestParameters.amountFrom !== undefined) {
            queryParameters['amount_from'] = requestParameters.amountFrom;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchant/swaps/rate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SwapRateFromJSON(jsonValue));
    }

    /**
     * Returns estimated swap rate.
     * Get estimated swap rate
     * @deprecated
     */
    async getSwapRate(requestParameters: GetSwapRateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SwapRate> {
        const response = await this.getSwapRateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get address whitelist for withdrawal.
     * Get address whitelist for withdrawal
     * @deprecated
     */
    async getWhitelistRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetWhitelistResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchant/whitelist`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetWhitelistResponseFromJSON(jsonValue));
    }

    /**
     * Get address whitelist for withdrawal.
     * Get address whitelist for withdrawal
     * @deprecated
     */
    async getWhitelist(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetWhitelistResponse> {
        const response = await this.getWhitelistRaw(initOverrides);
        return await response.value();
    }

    /**
     * List payments related to the merchant.
     * List payments
     * @deprecated
     */
    async listPaymentsRaw(requestParameters: ListPaymentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListPayments200Response>> {
        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['project_id'] = requestParameters.projectId;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.origin !== undefined) {
            queryParameters['origin'] = requestParameters.origin;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        if (requestParameters.orderId !== undefined) {
            queryParameters['order_id'] = requestParameters.orderId;
        }

        if (requestParameters.customerEmail !== undefined) {
            queryParameters['customer_email'] = requestParameters.customerEmail;
        }

        if (requestParameters.txnHash !== undefined) {
            queryParameters['txn_hash'] = requestParameters.txnHash;
        }

        if (requestParameters.payinAddress !== undefined) {
            queryParameters['payin_address'] = requestParameters.payinAddress;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = (requestParameters.dateTo as any).toISOString();
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchant/payments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListPayments200ResponseFromJSON(jsonValue));
    }

    /**
     * List payments related to the merchant.
     * List payments
     * @deprecated
     */
    async listPayments(requestParameters: ListPaymentsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListPayments200Response> {
        const response = await this.listPaymentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of merchant transactions: payments, swaps and withdrawals
     * List merchant\'s transactions
     * @deprecated
     */
    async listTransactionsRaw(requestParameters: ListTransactionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListTransactions200Response>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = (requestParameters.dateTo as any).toISOString();
        }

        if (requestParameters.updatedAtFrom !== undefined) {
            queryParameters['updated_at_from'] = (requestParameters.updatedAtFrom as any).toISOString();
        }

        if (requestParameters.updatedAtTo !== undefined) {
            queryParameters['updated_at_to'] = (requestParameters.updatedAtTo as any).toISOString();
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchant/transactions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListTransactions200ResponseFromJSON(jsonValue));
    }

    /**
     * Returns a list of merchant transactions: payments, swaps and withdrawals
     * List merchant\'s transactions
     * @deprecated
     */
    async listTransactions(requestParameters: ListTransactionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListTransactions200Response> {
        const response = await this.listTransactionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sends status callback for this payment, if configured.
     * Send payment callback
     * @deprecated
     */
    async sendPaymentCallbackRaw(requestParameters: SendPaymentCallbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CallbackResponse>> {
        if (requestParameters.paymentId === null || requestParameters.paymentId === undefined) {
            throw new runtime.RequiredError('paymentId','Required parameter requestParameters.paymentId was null or undefined when calling sendPaymentCallback.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchant/payments/{payment_id}/callback`.replace(`{${"payment_id"}}`, encodeURIComponent(String(requestParameters.paymentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CallbackResponseFromJSON(jsonValue));
    }

    /**
     * Sends status callback for this payment, if configured.
     * Send payment callback
     * @deprecated
     */
    async sendPaymentCallback(requestParameters: SendPaymentCallbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CallbackResponse> {
        const response = await this.sendPaymentCallbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Swaps merchant\'s coins.
     * Swap coins
     * @deprecated
     */
    async swapRaw(requestParameters: SwapOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Swap>> {
        if (requestParameters.swapRequest === null || requestParameters.swapRequest === undefined) {
            throw new runtime.RequiredError('swapRequest','Required parameter requestParameters.swapRequest was null or undefined when calling swap.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchant/swaps`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SwapRequestToJSON(requestParameters.swapRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SwapFromJSON(jsonValue));
    }

    /**
     * Swaps merchant\'s coins.
     * Swap coins
     * @deprecated
     */
    async swap(requestParameters: SwapOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Swap> {
        const response = await this.swapRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates merchants information and settings
     * Update merchant
     */
    async updateMerchantRaw(requestParameters: UpdateMerchantOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Merchant>> {
        if (requestParameters.updateMerchantRequest === null || requestParameters.updateMerchantRequest === undefined) {
            throw new runtime.RequiredError('updateMerchantRequest','Required parameter requestParameters.updateMerchantRequest was null or undefined when calling updateMerchant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchant`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMerchantRequestToJSON(requestParameters.updateMerchantRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantFromJSON(jsonValue));
    }

    /**
     * Updates merchants information and settings
     * Update merchant
     */
    async updateMerchant(requestParameters: UpdateMerchantOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Merchant> {
        const response = await this.updateMerchantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update whitelist enabled field.
     * Update whitelist
     * @deprecated
     */
    async updateWhitelistRaw(requestParameters: UpdateWhitelistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.whitelistUpdateRequest === null || requestParameters.whitelistUpdateRequest === undefined) {
            throw new runtime.RequiredError('whitelistUpdateRequest','Required parameter requestParameters.whitelistUpdateRequest was null or undefined when calling updateWhitelist.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchant/whitelist`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WhitelistUpdateRequestToJSON(requestParameters.whitelistUpdateRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update whitelist enabled field.
     * Update whitelist
     * @deprecated
     */
    async updateWhitelist(requestParameters: UpdateWhitelistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateWhitelistRaw(requestParameters, initOverrides);
    }

    /**
     * Validate address without adding it
     * Validate address without adding it
     * @deprecated
     */
    async validateWhitelistAddressRaw(requestParameters: ValidateWhitelistAddressOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidateWhitelistAddressResponse>> {
        if (requestParameters.validateWhitelistAddressRequest === null || requestParameters.validateWhitelistAddressRequest === undefined) {
            throw new runtime.RequiredError('validateWhitelistAddressRequest','Required parameter requestParameters.validateWhitelistAddressRequest was null or undefined when calling validateWhitelistAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchant/whitelist/addresses/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ValidateWhitelistAddressRequestToJSON(requestParameters.validateWhitelistAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidateWhitelistAddressResponseFromJSON(jsonValue));
    }

    /**
     * Validate address without adding it
     * Validate address without adding it
     * @deprecated
     */
    async validateWhitelistAddress(requestParameters: ValidateWhitelistAddressOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidateWhitelistAddressResponse> {
        const response = await this.validateWhitelistAddressRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
