import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { Flex, Typography, theme } from '@merchant/ui-kit/ant-design'
import type { ProjectTeamMember } from '~api/instances/cabinet-api'
import { NoValueColumnContent } from '~components'

const { Text } = Typography
const { useToken } = theme

interface Props {
    member: ProjectTeamMember
}

export function MemberName({ member }: Props) {
    const { joined, name, id } = member
    const { token } = useToken()

    if (!name) {
        return NoValueColumnContent
    }

    return (
        <Flex align="center" gap={4}>
            {!joined && (
                <AppSvg
                    size={20}
                    data-merchant={`members-table-not-joined-icon-${id}`}
                    name={assets.clock}
                    color={token.colorTextDescription}
                />
            )}
            <Text
                style={{ fontSize: 'inherit' }}
                type={!joined ? 'secondary' : undefined}
                data-merchant={`team-management-name-${id}`}
            >
                {name}
            </Text>
        </Flex>
    )
}
