import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg, CopyButton, InlineLinkButton } from '@merchant/shared/components'
import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { lang } from './lang'
import { routes } from '~constants/routes'

const { Text } = Typography

interface Props {
    id?: string
}

export function WithdrawalHeading({ id }: Props) {
    const navigate = useNavigate()

    return (
        <Flex vertical>
            <Text data-merchant="withdrawals-heading" style={{ opacity: 0.4 }}>
                <FormattedMessage {...lang.pageTitle} />
            </Text>
            <Flex align="center">
                <InlineLinkButton
                    data-merchant="withdrawal-heading-back-button"
                    onClick={() => navigate(routes.withdrawals)}
                    size="large"
                    icon={<AppSvg name={assets.arrowNarrowLeft} size={20} />}
                />
                &nbsp;
                <Text data-merchant={null} style={{ fontSize: 20 }}>
                    <FormattedMessage {...lang.pageSubtitle} />
                </Text>
                &nbsp;
                <Text type="secondary" style={{ fontSize: 20 }} data-merchant="withdrawal-heading-id">
                    {id}
                </Text>
                &nbsp;
                <CopyButton inputValue={id || ''} data-merchant="withdrawal-heading-copy-button" />
            </Flex>
        </Flex>
    )
}
