import { Row, Col, Button, type SelectProps, type FormListFieldData } from '@merchant/ui-kit/ant-design'
import { RolesSelect } from '~features/MerchantPage/components/TeamManagement/components'

interface Props extends Pick<SelectProps, 'options'> {
    roleLabel: string
    name: FormListFieldData['name']
    remove: (index: number | number[]) => void
    isTheOnlyListItem: boolean
}

export function RoleSelectWrapper({ isTheOnlyListItem, name, remove, roleLabel, ...selectProps }: Props) {
    return (
        <Row gutter={12} wrap={false}>
            <Col flex={1}>
                <RolesSelect
                    placeholder={roleLabel}
                    name={name}
                    context="add"
                    data-merchant="add-members-role"
                    roleLabel={roleLabel}
                    {...selectProps}
                />
            </Col>
            <Col flex="52px">
                {!isTheOnlyListItem && (
                    <Button
                        shape="circle"
                        onClick={() => remove(name)}
                        data-merchant={`add-members-remove-item-${name}`}
                    >
                        —
                    </Button>
                )}
            </Col>
        </Row>
    )
}
