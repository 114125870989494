import { NotFound } from '@merchant/shared/components'
import { type RouteProps } from 'react-router-dom'
import type { ConfigProviderProps } from '@merchant/ui-kit/ant-design'
import { Permission } from '~api/instances/cabinet-api'
import { routes as AppRoutes } from '~constants/routes'
import '@merchant/shared/styles/style.css'
import '@merchant/shared/styles/antd-override.style.css'
import {
    BalancesPage,
    OrdersPage,
    IntegrationsPage,
    MerchantPage,
    SettingsPage,
    TransactionsPage,
    PaymentConstructor,
    SnapshotsPage,
    OrderDetailsPage,
    WithdrawalsPage,
    AddressesPage,
    WithdrawalDetailsPage,
} from '~features'

export const navMenuConfigTheme: ConfigProviderProps['theme'] = {
    components: {
        Menu: {
            itemSelectedBg: 'transparent',
            linkHoverDecoration: 'transparent',
            subMenuItemBg: 'transparent',
            itemHoverBg: 'transparent',
            controlItemBgActive: 'transparent',
            controlItemBgHover: 'transparent',
            itemActiveBg: 'transparent',
        },
    },
}

export const dynamicRoutes: (RouteProps & {
    oneOfRequiredPermissions?: Permission | Permission[]
})[] = [
    {
        path: AppRoutes.orders,
        element: <OrdersPage />,
        oneOfRequiredPermissions: Permission.SeePaymentHistory,
    },
    {
        path: AppRoutes.ordersDetails,
        element: <OrderDetailsPage />,
        oneOfRequiredPermissions: Permission.SeePaymentHistory,
    },
    {
        path: AppRoutes.withdrawals,
        element: <WithdrawalsPage />,
        oneOfRequiredPermissions: [Permission.SeeWithdrawals, Permission.SeeWithdrawalWhitelist],
    },
    {
        path: AppRoutes.withdrawalsDetails,
        element: <WithdrawalDetailsPage />,
        oneOfRequiredPermissions: Permission.SeeWithdrawals,
    },
    {
        path: AppRoutes.transactions,
        element: <TransactionsPage />,
        oneOfRequiredPermissions: Permission.SeeTransactions,
    },
    {
        path: AppRoutes.balances,
        element: <BalancesPage />,
        oneOfRequiredPermissions: Permission.SeeBalance,
    },
    {
        path: AppRoutes.balancesSnapshots,
        element: <SnapshotsPage />,
        oneOfRequiredPermissions: Permission.SeeBalance,
    },
    {
        path: AppRoutes.addresses,
        element: <AddressesPage />,
        oneOfRequiredPermissions: Permission.SeeDepositAddresses,
    },
    {
        path: AppRoutes.integrations,
        element: <IntegrationsPage />,
        oneOfRequiredPermissions: Permission.SeeIntegration,
    },
    {
        path: AppRoutes.ordersCreate,
        element: <PaymentConstructor />,
        oneOfRequiredPermissions: [Permission.CreateDepositAddress, Permission.CreatePayment],
    },
    {
        path: AppRoutes.settings,
        element: <SettingsPage />,
    },
    {
        path: `${AppRoutes.merchant}/*`,
        element: <MerchantPage />,
    },
    {
        path: AppRoutes.notFound,
        element: <NotFound dataMerchantPrefix="route" />,
    },
]
