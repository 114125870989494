import { Space, Divider, Button } from '@merchant/ui-kit/ant-design'
import { usePostHog } from 'posthog-js/react'
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { ConvertInformation } from '../../ConvertInformation'
import { convertCaptureEventParams, PosthogConvertEvents } from '../../posthog'
import { OperationState } from '../ConvertState'
import { SWAP_REFRESH_INTERVAL } from './const'
import { lang } from './lang'
import { mapAPISwapRate } from './utils'
import { useMerchant, useProject, useSwapData } from '~api'
import { SwapStatus } from '~api/instances/cabinet-api'
import { globalLang } from '~globalLang'

interface Props {
    trxId: string
    onClose: () => void
    onRetry: () => void
    isFailedRequest: boolean
}

export function ConvertProgress({ trxId, isFailedRequest, onClose, onRetry }: Props) {
    const { data: { id: projectId = '' } = {} } = useProject()
    const { data: { id: merchantId = '' } = {} } = useMerchant()
    const posthog = usePostHog()
    const { data: swapData, isLoading: isLoadingSwap } = useSwapData(
        { params: { id: trxId } },
        {
            refreshInterval: data => (!data || data.status === SwapStatus.Pending ? SWAP_REFRESH_INTERVAL : 0),
        }
    )

    useEffect(() => {
        if (!swapData || !projectId || !merchantId) {
            return
        }

        posthog.capture(
            ...convertCaptureEventParams[PosthogConvertEvents.convertStatusChange]({
                ...mapAPISwapRate(swapData),
                status: swapData.status,
                merchant_id: merchantId,
                project_id: projectId,
            })
        )
    }, [swapData, posthog, projectId, merchantId])

    return (
        <>
            <OperationState state={swapData?.status} />
            {!isFailedRequest && (
                <>
                    <Divider style={{ margin: '24px 0 16px 0' }} />
                    <ConvertInformation data={swapData} isLoading={isLoadingSwap} />
                </>
            )}
            {swapData?.status === SwapStatus.Failed ? (
                <Space size={12} direction="horizontal" style={{ width: '100%' }} styles={{ item: { width: '100%' } }}>
                    <Button
                        block
                        onClick={onClose}
                        size="large"
                        style={{ marginTop: 24 }}
                        data-merchant="convert-progress-failed-close-button"
                    >
                        <FormattedMessage {...lang.close} />
                    </Button>
                    <Button
                        block
                        type="primary"
                        onClick={onRetry}
                        size="large"
                        style={{ marginTop: 24 }}
                        data-merchant="convert-progress-failed-retry-button"
                    >
                        <FormattedMessage {...globalLang.tryAgain} />
                    </Button>
                </Space>
            ) : (
                <Button
                    block
                    type="primary"
                    onClick={onClose}
                    size="large"
                    style={{ marginTop: 24 }}
                    data-merchant="convert-progress-close-button"
                >
                    <FormattedMessage {...lang.close} />
                </Button>
            )}
        </>
    )
}
