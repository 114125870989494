import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    submitButtonTooltip: {
        id: 'merchant-page.tooltip.submit_button_tooltip',
        defaultMessage: 'After creation, settings can be changed anytime',
    },
    namePlaceholder: {
        id: 'merchant-page.input.name_placeholder',
        defaultMessage: 'Shop name',
    },
    descriptionPlaceholder: {
        id: 'merchant-page.input.description_placeholder',
        defaultMessage: 'Description',
    },
    linkPlaceholder: {
        id: 'merchant-page.input.link_placeholder',
        defaultMessage: 'Your Website URL, https://...',
    },
    emailPlaceholder: {
        id: 'merchant-page.input.email_placeholder',
        defaultMessage: 'example@gmail.com',
    },
    nameLabel: {
        id: 'merchant-page.form.name_label',
        defaultMessage: 'Name',
    },
    descriptionLabel: {
        id: 'merchant-page.form.name_and_description_label',
        defaultMessage: 'Description',
    },
    linkLabel: {
        id: 'merchant-page.form.link_label',
        defaultMessage: 'Link',
    },
    supportEmailLabel: {
        id: 'merchant-page.form.support_email_label',
        defaultMessage: 'Support email',
    },
    statusLabel: {
        id: 'merchant-page.form.status_label',
        defaultMessage: 'Status',
    },
    active: {
        id: 'merchant-page.select.active',
        defaultMessage: 'Active',
    },
    inactive: {
        id: 'merchant-page.select.inactive',
        defaultMessage: 'Inactive',
    },
    createMerchant: {
        id: 'merchant-page.button.create_merchant',
        defaultMessage: 'Create merchant',
    },

    websiteLinkTooltip: {
        id: 'merchant-page.tooltip.website_link',
        defaultMessage: 'Your Website URL: Main website address, e.g., {url}',
    },
    websiteLinkTooltipUrl: {
        id: 'merchant-page.tooltip.website_link_url',
        defaultMessage: 'https://yourwebsite.com',
    },
    merchantDisabledAlert: {
        id: 'merchant-page.alert.merchant_disabled',
        defaultMessage:
            'Merchant has been disabled. Please contact {support} or your personal account manager for assistance.',
    },
    nameAndDescriptionTooltip: {
        id: 'merchant-page.tooltip.name_description_tooltip',
        defaultMessage:
            'Input your project\'s name and a short description. Example, "ShopName" and "Best shop of digital art with crypto payments"',
    },
    supportEmailTooltip: {
        id: 'merchant-page.tooltip.support_email_tooltip',
        defaultMessage: 'Provide a support email for customer queries. Example: support@yourwebsite.com.',
    },
    statusTooltip: {
        id: 'merchant-page.tooltip.status_tooltip',
        defaultMessage:
            'Shows if your merchant profile is "Active". You can set it to "Inactive" to pause your store\'s operations.',
    },
    statusCannotBeChanged: {
        id: 'merchant-page.tooltip.cannot_change_status',
        defaultMessage:
            'Status cannot be changed. Please contact <support>support</support> or your personal account manager for assistance.',
    },
    createNewMerchant: {
        id: 'merchant-page.typography.create_new_merchant',
        defaultMessage: 'Create new merchant',
    },
})
