import { useCurrencyOptions } from './hooks'
import type { Currency } from '~api'
import type { SearchableSelectProps } from '~components'
import { SearchableSelect } from '~components'

interface Props extends SearchableSelectProps {
    'data-merchant': string
    optionPredicate?: (option: Currency | undefined) => boolean
}

export function CurrencySelect({ loading: isLoadingProp, ...props }: Props) {
    const { options, isLoading: isLoadingOptions } = useCurrencyOptions()

    return (
        <SearchableSelect
            loading={isLoadingOptions || isLoadingProp}
            filterOption={(input, option) => option?.key?.toLowerCase().includes(input.toLowerCase())}
            options={options}
            size="large"
            {...props}
        />
    )
}
