import { TOTPStatus, type TOTP } from '~api/instances/cabinet-api'

export function processTotps(totps?: TOTP[]) {
    const unconfirmedTotps: TOTP[] = []
    const confirmedTotps: TOTP[] = []
    totps?.forEach(totp => {
        if (totp.status === TOTPStatus.Active) {
            confirmedTotps.push(totp)
        } else if (totp.status === TOTPStatus.Unconfirmed) {
            unconfirmedTotps.push(totp)
        }
    })

    return { unconfirmedTotps, confirmedTotps }
}
