/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NullableFeesPayer } from './NullableFeesPayer';
import {
    NullableFeesPayerFromJSON,
    NullableFeesPayerFromJSONTyped,
    NullableFeesPayerToJSON,
} from './NullableFeesPayer';
import type { PaymentSwapConfig } from './PaymentSwapConfig';
import {
    PaymentSwapConfigFromJSON,
    PaymentSwapConfigFromJSONTyped,
    PaymentSwapConfigToJSON,
} from './PaymentSwapConfig';

/**
 * 
 * @export
 * @interface UpdateCustomerAddressRequest
 */
export interface UpdateCustomerAddressRequest {
    /**
     * 
     * @type {PaymentSwapConfig}
     * @memberof UpdateCustomerAddressRequest
     */
    swap?: PaymentSwapConfig;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerAddressRequest
     */
    holdingCurrency?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerAddressRequest
     */
    customerId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerAddressRequest
     */
    customerEmail?: string | null;
    /**
     * Freehand metadata associated with the static address
     * @type {any}
     * @memberof UpdateCustomerAddressRequest
     */
    paymentData?: any | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerAddressRequest
     */
    nominalCurrency?: string | null;
    /**
     * 
     * @type {NullableFeesPayer}
     * @memberof UpdateCustomerAddressRequest
     */
    feesPayer?: NullableFeesPayer | null;
}

/**
 * Check if a given object implements the UpdateCustomerAddressRequest interface.
 */
// @ts-ignore
export function instanceOfUpdateCustomerAddressRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

// @ts-ignore
export function UpdateCustomerAddressRequestFromJSON(json: any): UpdateCustomerAddressRequest {
    return UpdateCustomerAddressRequestFromJSONTyped(json, false);
}

// @ts-ignore
export function UpdateCustomerAddressRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCustomerAddressRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'swap': !exists(json, 'swap') ? undefined : PaymentSwapConfigFromJSON(json['swap']),
        'holdingCurrency': !exists(json, 'holding_currency') ? undefined : json['holding_currency'],
        'customerId': !exists(json, 'customer_id') ? undefined : json['customer_id'],
        'customerEmail': !exists(json, 'customer_email') ? undefined : json['customer_email'],
        'paymentData': !exists(json, 'payment_data') ? undefined : json['payment_data'],
        'nominalCurrency': !exists(json, 'nominal_currency') ? undefined : json['nominal_currency'],
        'feesPayer': !exists(json, 'fees_payer') ? undefined : NullableFeesPayerFromJSON(json['fees_payer']),
    };
}

// @ts-ignore
export function UpdateCustomerAddressRequestToJSON(value?: UpdateCustomerAddressRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'swap': PaymentSwapConfigToJSON(value.swap),
        'holding_currency': value.holdingCurrency,
        'customer_id': value.customerId,
        'customer_email': value.customerEmail,
        'payment_data': value.paymentData,
        'nominal_currency': value.nominalCurrency,
        'fees_payer': NullableFeesPayerToJSON(value.feesPayer),
    };
}

