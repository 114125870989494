import { usePromise } from '@merchant/shared/hooks'
import { Button, Dropdown, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { integrationCommonButtonStyle } from '../../const'
import { lang } from '../../lang'
import type { CallbackResponse } from '~api'
import { SendTestCallbackType, projectApi, useProject } from '~api'

const { Text } = Typography

interface Props {
    onSuccess: (response: CallbackResponse) => void
}

export function TestCallbackButton({ onSuccess }: Props) {
    const { data } = useProject()
    const { send: sendTestCallback, isLoading: isSendingTestCallback } = usePromise(
        (type: SendTestCallbackType) =>
            projectApi.sendTestCallback({
                projectId: data?.id || '',
                type,
            }),
        { onSuccess }
    )

    const getCallbackHandler = (type: SendTestCallbackType) => () => sendTestCallback(type)

    return (
        <Dropdown
            trigger={['click']}
            placement="bottomRight"
            data-merchant="send-test-callback-dropdown"
            menu={{
                items: [
                    {
                        key: 'payment',
                        label: (
                            <Text data-merchant="send-payment-test-callback-button" style={{ fontSize: 12 }}>
                                <FormattedMessage {...lang.sendPaymentCallback} />
                            </Text>
                        ),
                        type: 'item',
                        onClick: getCallbackHandler(SendTestCallbackType.Payment),
                    },
                    {
                        type: 'divider',
                        style: { marginInline: -4 },
                    },
                    {
                        key: 'withdrawal',
                        label: (
                            <Text data-merchant="send-withdrawal-test-callback-button" style={{ fontSize: 12 }}>
                                <FormattedMessage {...lang.sendWithdrawalCallback} />
                            </Text>
                        ),
                        type: 'item',
                        onClick: getCallbackHandler(SendTestCallbackType.Withdrawal),
                    },
                ],
                'data-merchant': 'send-test-callback-dropdown-menu',
            }}
        >
            <Button
                data-merchant="send-test-callback-button"
                loading={isSendingTestCallback}
                style={integrationCommonButtonStyle}
            >
                <FormattedMessage {...lang.sendTestCallback} />
            </Button>
        </Dropdown>
    )
}
