import { lang } from './lang'
import type { GetProp, Badge } from '@merchant/ui-kit/ant-design'
import type { MessageDescriptor } from 'react-intl'
import { TransactionStatus } from '~api/instances/cabinet-api'

export const transactionStatusMessageDescriptorMap: Record<TransactionStatus, MessageDescriptor> = {
    [TransactionStatus.Processing]: lang.waitingStatus,
    [TransactionStatus.Finished]: lang.completedStatus,
    [TransactionStatus.Failed]: lang.failedStatus,
}

export const transactionStatusTagMap: Record<TransactionStatus, GetProp<typeof Badge, 'status'>> = {
    [TransactionStatus.Processing]: 'warning',
    [TransactionStatus.Finished]: 'success',
    [TransactionStatus.Failed]: 'error',
}
