import { useEffect, type Dispatch, type SetStateAction } from 'react'
import { useLocalStorage } from 'usehooks-ts'

interface UseLocalStorageOptions<T> {
    serializer?: (value: T) => string
    deserializer?: (value: string) => T
    initializeWithValue?: boolean
}

export function useInitializedLocalStorage<T>(
    key: string,
    initialValue: T | (() => T),
    options: UseLocalStorageOptions<T> = {}
): [T, Dispatch<SetStateAction<T>>] {
    const [state, setState] = useLocalStorage(key, initialValue, options)

    // Initialize the local storage if it doesn't exist
    useEffect(() => {
        if (!localStorage.getItem(key)) {
            localStorage.setItem(key, JSON.stringify(initialValue))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return [state, setState]
}
