import { CustomizedEmpty } from '@merchant/shared/components'
import { Button } from '@merchant/ui-kit/ant-design'
import { isEmpty } from 'lodash-es'
import { FormattedMessage } from 'react-intl'
import { WithdrawalsFilters, WithdrawalsTable } from './components'
import { showMoreButtonStyles } from './const'
import { lang } from './lang'
import { useData } from './useData'
import { useHandlers } from './useHandlers'
import { EmptyTable, PageContentContainer } from '~components'
import { PlaceholderViewWrapper } from '~features/PlaceholderViewWrapper'

export function WithdrawalsHistory() {
    const {
        withdrawals,
        isLoadingWithdrawals,
        isLoadingMoreWithdrawals,
        canFetchMoreWithdrawals,
        fetchMoreWithdrawals,
        onClearFilters,
        onSubmitFilters,
        activeFiltersCount,
        filters,
        setSortColumnKey,
        sortColumnKey,
        isPageDataEmpty,
    } = useData()

    const { isExportingCSV, onExportCSV } = useHandlers()

    const isFiltersEmpty = isEmpty(filters)
    const areFiltersShown = !isPageDataEmpty || !isFiltersEmpty
    const isDataEmpty = isPageDataEmpty && isFiltersEmpty

    return (
        <PlaceholderViewWrapper dataMerchantPrefix="withdrawals-history">
            <PageContentContainer>
                {areFiltersShown && (
                    <WithdrawalsFilters
                        initialValues={filters}
                        activeFiltersCount={activeFiltersCount}
                        onFormFinish={onSubmitFilters}
                        onFormReset={onClearFilters}
                        isLoading={isLoadingWithdrawals}
                        extra={
                            <Button
                                data-merchant="withdrawals-history-export-button"
                                loading={isExportingCSV}
                                onClick={onExportCSV(filters)}
                            >
                                <FormattedMessage {...lang.exportCSV} />
                            </Button>
                        }
                    />
                )}
                <WithdrawalsTable
                    showHeader={!isDataEmpty}
                    data={withdrawals}
                    sortColumnKey={sortColumnKey}
                    setSortColumnKey={setSortColumnKey}
                    loading={isLoadingWithdrawals}
                    data-merchant="withdrawals-history-table"
                    locale={{
                        emptyText: activeFiltersCount ? (
                            <CustomizedEmpty data-merchant="withdrawals-history-table-empty" usage="withdrawals" />
                        ) : (
                            <EmptyTable subtitleDescriptor={lang.noWithdrawalsYet} />
                        ),
                    }}
                />
                {canFetchMoreWithdrawals && (
                    <Button
                        data-merchant="withdrawals-history-show-more-button"
                        onClick={fetchMoreWithdrawals}
                        loading={isLoadingMoreWithdrawals}
                        style={showMoreButtonStyles}
                        size="large"
                    >
                        <FormattedMessage {...lang.showMore} />
                    </Button>
                )}
            </PageContentContainer>
        </PlaceholderViewWrapper>
    )
}
