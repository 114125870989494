import { Divider } from '@merchant/ui-kit/ant-design'
import { DescriptionItem, ExpirationTimeItem, FeesPayerItem, NominalAmountItem, TitleItem } from './components'
import type { NewPaymentFormData } from '../../../types'
import type { FormInstance } from '@merchant/ui-kit/ant-design'
import type { ConstructorTypes } from '~features/PaymentConstructor/types'
import { TypeSelectItem } from '~features/PaymentConstructor/components'

interface BasicSettingsProps {
    form: FormInstance<NewPaymentFormData>
    type: ConstructorTypes
}

export function BasicSettings({ form, type }: BasicSettingsProps) {
    return (
        <>
            <TypeSelectItem />
            <NominalAmountItem type={type} />
            <Divider style={{ marginBlock: '0 16px' }} />
            <TitleItem />
            <DescriptionItem />
            <ExpirationTimeItem form={form} />
            <FeesPayerItem />
        </>
    )
}
