/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConfirmPasswordResetRequest
 */
export interface ConfirmPasswordResetRequest {
    /**
     * 
     * @type {string}
     * @memberof ConfirmPasswordResetRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmPasswordResetRequest
     */
    newPassword: string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmPasswordResetRequest
     */
    verificationCode: string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmPasswordResetRequest
     */
    totpCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmPasswordResetRequest
     */
    totpBackup?: string;
}

/**
 * Check if a given object implements the ConfirmPasswordResetRequest interface.
 */
// @ts-ignore
export function instanceOfConfirmPasswordResetRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "newPassword" in value;
    isInstance = isInstance && "verificationCode" in value;

    return isInstance;
}

// @ts-ignore
export function ConfirmPasswordResetRequestFromJSON(json: any): ConfirmPasswordResetRequest {
    return ConfirmPasswordResetRequestFromJSONTyped(json, false);
}

// @ts-ignore
export function ConfirmPasswordResetRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfirmPasswordResetRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'newPassword': json['new_password'],
        'verificationCode': json['verification_code'],
        'totpCode': !exists(json, 'totp_code') ? undefined : json['totp_code'],
        'totpBackup': !exists(json, 'totp_backup') ? undefined : json['totp_backup'],
    };
}

// @ts-ignore
export function ConfirmPasswordResetRequestToJSON(value?: ConfirmPasswordResetRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'new_password': value.newPassword,
        'verification_code': value.verificationCode,
        'totp_code': value.totpCode,
        'totp_backup': value.totpBackup,
    };
}

