import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg, InlineLinkButton } from '@merchant/shared/components'
import { Alert, type ModalProps } from '@merchant/ui-kit/ant-design'
import { FormattedMessage, type MessageDescriptor } from 'react-intl'
import { useBoolean } from 'usehooks-ts'
import { ActionSuspendedModal } from './ActionSuspendedModal'
import { lang } from './lang'
import { SuspensionContext } from './types'
import type { CommonProps } from './types'

const alertMessageContextMap: Record<SuspensionContext, MessageDescriptor> = {
    [SuspensionContext.Integration]: lang.integrationsAlertMessage,
    [SuspensionContext.TeamManagement]: lang.teamManagementAlertMessage,
    [SuspensionContext.Withdrawal]: lang.withdrawalAlertMessage,
}

interface Props extends CommonProps {
    isModalOpen?: boolean
}

export function ActionSuspendedAlert({ isModalOpen: isModalOpenProp, onClose, ...rest }: Props) {
    const { value: isModalOpen, setTrue: openModal, setFalse: closeModal } = useBoolean()
    const alertMessage = alertMessageContextMap[rest.context]

    const handleModalClose: ModalProps['onClose'] = e => {
        closeModal()
        onClose?.(e)
    }

    return (
        <>
            <ActionSuspendedModal
                open={isModalOpen || isModalOpenProp}
                onClose={handleModalClose}
                {...rest}
                data-merchant={`suspended-action-${rest.context}-modal`}
            />
            <Alert
                type="error"
                message={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FormattedMessage {...alertMessage} />
                        &nbsp;
                        <InlineLinkButton
                            onClick={openModal}
                            data-merchant={`suspended-action-${rest.context}-more-info-button`}
                        >
                            <FormattedMessage {...lang.moreInfoButton} />
                        </InlineLinkButton>
                    </div>
                }
                showIcon
                icon={<AppSvg size={24} name={assets.alertCircle} />}
                style={{ padding: 16 }}
                data-merchant={`suspended-alert-${rest.context}`}
            />
        </>
    )
}
