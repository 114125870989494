/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GenerateVerificationCodeRequestDetails } from './GenerateVerificationCodeRequestDetails';
import {
    GenerateVerificationCodeRequestDetailsFromJSON,
    GenerateVerificationCodeRequestDetailsFromJSONTyped,
    GenerateVerificationCodeRequestDetailsToJSON,
} from './GenerateVerificationCodeRequestDetails';

/**
 * 
 * @export
 * @interface GenerateVerificationCodeRequest
 */
export interface GenerateVerificationCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof GenerateVerificationCodeRequest
     */
    purpose: GenerateVerificationCodeRequestPurpose;
    /**
     * 
     * @type {GenerateVerificationCodeRequestDetails}
     * @memberof GenerateVerificationCodeRequest
     */
    details?: GenerateVerificationCodeRequestDetails;
}

/**
* @export
* @enum {string}
*/
export enum GenerateVerificationCodeRequestPurpose {
    LoginConfirm = 'login_confirm',
    Confirm2fa = 'confirm_2fa',
    ChangePassword = 'change_password',
    Disable2fa = 'disable_2fa',
    DeleteAccount = 'delete_account',
    EnableWhitelist = 'enable_whitelist',
    DisableWhitelist = 'disable_whitelist',
    AddToWhitelist = 'add_to_whitelist',
    RemoveFromWhitelist = 'remove_from_whitelist',
    Withdraw = 'withdraw',
    AddProjectTeamMember = 'add_project_team_member'
}


/**
 * Check if a given object implements the GenerateVerificationCodeRequest interface.
 */
// @ts-ignore
export function instanceOfGenerateVerificationCodeRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "purpose" in value;

    return isInstance;
}

// @ts-ignore
export function GenerateVerificationCodeRequestFromJSON(json: any): GenerateVerificationCodeRequest {
    return GenerateVerificationCodeRequestFromJSONTyped(json, false);
}

// @ts-ignore
export function GenerateVerificationCodeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenerateVerificationCodeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'purpose': json['purpose'],
        'details': !exists(json, 'details') ? undefined : GenerateVerificationCodeRequestDetailsFromJSON(json['details']),
    };
}

// @ts-ignore
export function GenerateVerificationCodeRequestToJSON(value?: GenerateVerificationCodeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'purpose': value.purpose,
        'details': GenerateVerificationCodeRequestDetailsToJSON(value.details),
    };
}

