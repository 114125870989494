import { Button } from '@merchant/ui-kit/ant-design'
import { Link } from 'react-router-dom'
import type { ButtonProps } from '@merchant/ui-kit/ant-design'
import type { PropsWithChildren } from 'react'
import type { LinkProps } from 'react-router-dom'

interface Props extends PropsWithChildren, Pick<LinkProps, 'to'> {
    buttonProps: ButtonProps
}

export function LinkAsButton({ children, to, buttonProps }: Props) {
    return (
        <Link to={to}>
            <Button tabIndex={-1} role="presentation" {...buttonProps}>
                {children}
            </Button>
        </Link>
    )
}
