import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    ordersPageTitle: {
        id: 'payment-constructor.typography.title',
        defaultMessage: 'Orders',
    },
    createNewOrder: {
        id: 'payment-constructor.typography.create_new_order',
        defaultMessage: 'Create new order',
    },
})
