import { Button, Skeleton, Space, Typography, type SkeletonProps } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang as commonLang } from './../../lang'
import type { ReactNode } from 'react'

const { Text, Title } = Typography

const defaultSpaceStyles: React.CSSProperties = { width: '100%' }

const stepIndexSkeletonProps: Record<number, SkeletonProps> = {
    1: { paragraph: { rows: 1 } },
    2: { paragraph: { rows: 3 } },
    3: { paragraph: { rows: 1 } },
}

interface Props {
    stepItemIndex: number
    title: ReactNode
    titleMerchantData: string
    description: ReactNode
    goToNextStep: () => void
    buttonTitle?: ReactNode
    currentStep: number
    children?: ReactNode
    style?: React.CSSProperties
    isLoading: boolean
    isCurrencySettingsEditAllowed: boolean
}

export function StepItem({
    children,
    description,
    stepItemIndex: stepIndex,
    title,
    goToNextStep,
    buttonTitle,
    currentStep,
    titleMerchantData,
    style,
    isLoading,
    isCurrencySettingsEditAllowed = false,
}: Props) {
    const isStepActive = currentStep >= stepIndex
    const isTutorialDone = currentStep === -1
    const isStepVisible = isStepActive || isTutorialDone

    return (
        <Skeleton active loading={isLoading} {...stepIndexSkeletonProps[stepIndex]}>
            <Space direction="vertical" size={12} style={{ ...defaultSpaceStyles, ...style }}>
                <div>
                    <Title level={5} type={isStepVisible ? undefined : 'secondary'} data-merchant={titleMerchantData}>
                        {!isTutorialDone && <FormattedMessage {...commonLang.step} values={{ number: stepIndex }} />}{' '}
                        {title}
                    </Title>
                    {isStepVisible && (
                        <Text type="secondary" data-merchant={`${titleMerchantData}-description`}>
                            {description}
                        </Text>
                    )}
                </div>
                {isStepVisible && children}
                {currentStep === stepIndex && buttonTitle && (
                    <Button
                        disabled={!isCurrencySettingsEditAllowed}
                        type="primary"
                        size="large"
                        onClick={goToNextStep}
                        data-merchant={`currencies-settings-step-${stepIndex}-button`}
                    >
                        {buttonTitle}
                    </Button>
                )}
            </Space>
        </Skeleton>
    )
}
