import { ConfigProvider, Menu } from '@merchant/ui-kit/ant-design'
import { navMenuConfigTheme } from '../const'
import { useNavigationMenu } from './useNavigationMenu'
import type { NavigationMenuProps } from './types'

export function NavigationMenu({ usageContext }: NavigationMenuProps) {
    const { areDefaultsSet, menuProps } = useNavigationMenu(usageContext)

    if (!areDefaultsSet) {
        return null
    }

    return (
        <ConfigProvider theme={navMenuConfigTheme}>
            <Menu {...menuProps} />
        </ConfigProvider>
    )
}
