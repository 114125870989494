import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    walletAddresses: {
        id: 'whitelist-page.table.wallet_addresses',
        defaultMessage: 'Wallet addresses',
    },
    address: {
        id: 'whitelist-page.table.address',
        defaultMessage: 'Address',
    },
    addressTag: {
        id: 'whitelist-page.table.address_tag',
        defaultMessage: 'Tag',
    },
    currency: {
        id: 'whitelist-page.table.currency',
        defaultMessage: 'Currency',
    },
    name: {
        id: 'whitelist-page.table.name',
        defaultMessage: 'Name',
    },
    tableEmptyTitle: {
        id: 'whitelist-page.table.empty_title',
        defaultMessage: '👀',
    },
    tableEmptySubtitle: {
        id: 'whitelist-page.table.empty_subtitle',
        defaultMessage: 'No addresses yet',
    },
    searchPlaceholder: {
        id: 'whitelist-page.table.search_placeholder',
        defaultMessage: 'Search',
    },
    delete: {
        id: 'whitelist-page.list.delete',
        defaultMessage: 'Delete',
    },
    sortByPlaceholder: {
        id: 'whitelist-page.select.sort_by_placeholder',
        defaultMessage: 'Sort by',
    },
})
