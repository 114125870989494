/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BlocklistItem } from './BlocklistItem';
import {
    BlocklistItemFromJSON,
    BlocklistItemFromJSONTyped,
    BlocklistItemToJSON,
} from './BlocklistItem';

/**
 * 
 * @export
 * @interface Blocklist
 */
export interface Blocklist {
    /**
     * 
     * @type {boolean}
     * @memberof Blocklist
     */
    blocked: boolean;
    /**
     * 
     * @type {Array<BlocklistItem>}
     * @memberof Blocklist
     */
    blockItems: Array<BlocklistItem>;
}

/**
 * Check if a given object implements the Blocklist interface.
 */
// @ts-ignore
export function instanceOfBlocklist(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "blocked" in value;
    isInstance = isInstance && "blockItems" in value;

    return isInstance;
}

// @ts-ignore
export function BlocklistFromJSON(json: any): Blocklist {
    return BlocklistFromJSONTyped(json, false);
}

// @ts-ignore
export function BlocklistFromJSONTyped(json: any, ignoreDiscriminator: boolean): Blocklist {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'blocked': json['blocked'],
        'blockItems': ((json['block_items'] as Array<any>).map(BlocklistItemFromJSON)),
    };
}

// @ts-ignore
export function BlocklistToJSON(value?: Blocklist | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'blocked': value.blocked,
        'block_items': ((value.blockItems as Array<any>).map(BlocklistItemToJSON)),
    };
}

