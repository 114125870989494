import type { APIKey, CreateAPIKeyRequest } from '~api/instances/cabinet-api'
import type { FormApiKeyPermissions } from '~features/IntegrationsPage/views/Integrations/types'
import { ModalNames } from '~constants/modal'

export type CreateApiKeyFormData = Pick<APIKey, 'name'> & {
    permissions: FormApiKeyPermissions
}
export const steps = {
    [ModalNames.createApiKeyForm]: ModalNames.createApiKeyForm,
    [ModalNames.createApiKeyConfirmation]: ModalNames.createApiKeyConfirmation,
    [ModalNames.createApiKeyCreated]: ModalNames.createApiKeyCreated,
} as const

export interface NewApiKey extends APIKey {
    privateKey?: string
}

export type CreateApiKeyData = Pick<CreateAPIKeyRequest, 'name' | 'permissions'>
