import { useBreakpoint, usePromise } from '@merchant/shared/hooks'
import { Button, Flex, Row, Space, Typography } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { lang as commonLang } from '../../lang'
import { AppQR } from './AppQR'
import { StorePlatform } from './const'
import { lang } from './lang'
import { StoreButton } from './StoreButton'
import type { CreateTOTPResponse } from '~api/instances/cabinet-api'
import { authApi } from '~api'

const { Paragraph } = Typography

const storeButtonsColStyles: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
}

interface Props {
    onSuccess: (totp: CreateTOTPResponse) => void
}

export function InstallApp({ onSuccess }: Props) {
    const { send, isLoading } = usePromise(async () => await authApi.createTOTP(), { onSuccess })
    const breakpoints = useBreakpoint()

    return (
        <Space direction="vertical" size={24}>
            <Flex wrap="nowrap" vertical={!breakpoints.md} align={!breakpoints.md ? 'center' : 'flex-start'} gap={24}>
                <Flex style={storeButtonsColStyles} gap={24} align="center">
                    <Paragraph
                        data-merchant="add2fa-install-app-recommended"
                        type="secondary"
                        style={{ margin: 0, textAlign: 'center' }}
                    >
                        <FormattedMessage
                            {...lang.installAppTitle}
                            values={{
                                recommendedApp: <AppQR />,
                            }}
                        />
                    </Paragraph>
                    <Flex gap={8} justify="center">
                        <StoreButton
                            store={StorePlatform.Apple}
                            href="https://apps.apple.com/app/google-authenticator/id388497605"
                        />
                        <StoreButton
                            store={StorePlatform.Google}
                            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                        />
                    </Flex>
                </Flex>
            </Flex>
            <Row>
                <Button
                    data-merchant="add2fa-install-app-continue-button"
                    type="primary"
                    block
                    onClick={send}
                    loading={isLoading}
                    size="large"
                >
                    <FormattedMessage {...commonLang.continue} />
                </Button>
            </Row>
        </Space>
    )
}
