import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    staticAddressesShowMore: {
        id: 'static-addresses-table.button.show_more',
        defaultMessage: 'Show more',
    },
    staticAddressesCreated: {
        id: 'static-addresses-table.column.created',
        defaultMessage: 'Created',
    },
    staticAddressesIdAndSource: {
        id: 'static-addresses-table.column.id_source',
        defaultMessage: 'ID & Source',
    },
    staticAddressesClientIdAndEmail: {
        id: 'static-addresses-table.column.client_id_email',
        defaultMessage: 'Client ID & Email',
    },
    staticAddressesCurrency: {
        id: 'static-addresses-table.column.currency',
        defaultMessage: 'Currency',
    },
    staticAddressesNetwork: {
        id: 'static-addresses-table.column.network',
        defaultMessage: 'Network',
    },
    staticAddressesAddressAndTag: {
        id: 'static-addresses-table.column.address_and_tag',
        defaultMessage: 'Address & Tag',
    },
    staticAddressesFeesPayer: {
        id: 'static-addresses-table.column.fees_payer',
        defaultMessage: 'Fees Payer',
    },
    staticAddressesDefaultFeesPayer: {
        id: 'static-addresses-table.column.default_fees_payer',
        defaultMessage: 'Default',
    },
    staticAddressesMetaInfo: {
        id: 'static-addresses-table.column.meta_info',
        defaultMessage: 'Meta Info',
    },
    staticAddressesNoAddressesYet: {
        id: 'static-addresses-table.typography.empty_text',
        defaultMessage: 'No addresses yet',
    },
    depositAddressesCreated: {
        id: 'deposit-addresses-table.column.created',
        defaultMessage: 'Created',
    },
    depositAddressesId: {
        id: 'deposit-addresses-table.column.id',
        defaultMessage: 'ID',
    },
    depositAddressesCurrency: {
        id: 'deposit-addresses-table.column.currency',
        defaultMessage: 'Currency',
    },
    depositAddressesNetwork: {
        id: 'deposit-addresses-table.column.network',
        defaultMessage: 'Network',
    },
    depositAddressesAddressAndTag: {
        id: 'deposit-addresses-table.column.address_and_tag',
        defaultMessage: 'Address & Tag',
    },
    depositAddressesDepositButton: {
        id: 'deposit-addresses-table.column.deposit_button',
        defaultMessage: 'Deposit',
    },
    depositAddressesShowMore: {
        id: 'deposit-addresses-table.button.show_more',
        defaultMessage: 'Show more',
    },
    depositAddressesNoAddressesYet: {
        id: 'deposit-addresses-table.typography.empty_text',
        defaultMessage: 'No addresses yet',
    },
})
