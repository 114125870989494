import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    completedTimeline: {
        id: 'withdrawal.timeline.title.completed',
        defaultMessage: 'Withdrawal completed',
    },
    date: {
        id: 'withdrawal.timeline.completed.date',
        defaultMessage: 'Date',
    },
})
