import { defineMessages } from 'react-intl'

export const globalLang = defineMessages({
    close: {
        id: 'global.button.close',
        defaultMessage: 'Close',
    },
    cancel: {
        id: 'global.button.cancel',
        defaultMessage: 'Cancel',
    },
    transactions: {
        id: 'global.typography.transactions',
        defaultMessage: 'Transactions',
    },
    no: {
        id: 'global.typography.no',
        defaultMessage: 'No',
    },
    continue: {
        id: 'global.button.continue',
        defaultMessage: 'Continue',
    },
    confirm: {
        id: 'global.button.confirm',
        defaultMessage: 'Confirm',
    },
    ok: {
        id: 'global.button.ok',
        defaultMessage: 'OK',
    },
    delete: {
        id: 'global.button.delete',
        defaultMessage: 'Delete',
    },
    dontHaveDevice: {
        id: 'global.typography.do_not_have_device',
        defaultMessage: "Don't have your device?",
    },
    dontHaveBackupCode: {
        id: 'global.typography.do_not_have_backup_code',
        defaultMessage: "Don't have your backup code?",
    },
    contactSupport: {
        id: 'global.button.contact_support',
        defaultMessage: 'Contact the support',
    },
    returnTo: {
        id: 'global.typography.return_to',
        defaultMessage: 'Return to',
    },
    enterTwoFa: {
        id: 'global.button.enter_two_fa',
        defaultMessage: 'Enter 2FA',
    },
    useBackupCode: {
        id: 'global.button.use_backup_code',
        defaultMessage: 'Use backup code',
    },
    requiredFieldMessage: {
        id: 'global.form.required_field_message',
        defaultMessage: 'Field is required',
    },
    invalidEmailFormat: {
        id: 'global.input.invalid_email_format_message',
        defaultMessage: 'Invalid email format',
    },
    invalidUrlFormat: {
        id: 'global.input.invalid_url_format_message',
        defaultMessage: 'Invalid URL format',
    },
    invalidFormat: {
        id: 'global.input.invalid_format_message',
        defaultMessage: 'Invalid format',
    },
    enterCodeSentTo: {
        id: 'global.form.enter_code_sent_to',
        defaultMessage: 'Please enter the confirmation code sent to {email}',
    },
    save: {
        id: 'global.button.save',
        defaultMessage: 'Save',
    },
    insufficientFunds: {
        id: 'global.form.insufficient_funds',
        defaultMessage: 'Insufficient funds',
    },
    somethingWentWrong: {
        id: 'global.form.something_went_wrong',
        defaultMessage: 'Something went wrong',
    },
    tryAgain: {
        id: 'global.form.try_again',
        defaultMessage: 'Try again',
    },
    tryAgainLater: {
        id: 'global.form.try_again_later',
        defaultMessage: 'Please try again later or contact {SupportLink}',
    },
    brokerName: {
        id: 'global.broker_name',
        defaultMessage: 'Company name',
    },
    merchantSupportEmail: {
        id: 'global.typography.merchant_support_email',
        defaultMessage: 'support@company.domain.com',
    },
    docsUrl: {
        id: 'global.typography.docs_url',
        defaultMessage: 'https://company.domain.com/doc#',
    },
    callbackDocUrl: {
        id: 'global.typography.callback_doc_url',
        defaultMessage: 'https://company.domain.com/doc#callback',
    },
    passwordIsInvalid: {
        id: 'global.form.password_is_invalid',
        defaultMessage: 'Password is invalid',
    },
    invalidTwoFaCredentials: {
        id: 'global.form.invalid_two_fa_credentials',
        defaultMessage: 'Invalid 2FA credentials',
    },
    invalidCode: {
        id: 'global.form.invalid_code',
        defaultMessage: 'Invalid code',
    },
    insufficientFundsToWithdraw: {
        id: 'global.withdraw.insufficient_funds',
        defaultMessage: 'Insufficient Funds to withdraw',
    },
    insufficientFundsToConvert: {
        id: 'global.convert.insufficient_funds',
        defaultMessage: 'Insufficient Funds to convert',
    },
    insufficientFundsToConvertOrWithdraw: {
        id: 'global.actions.insufficient_funds_convert_or_withdraw',
        defaultMessage: 'Insufficient Funds to withdraw or convert',
    },
    missingProtocol: {
        id: 'global.typography.missing_protocol',
        defaultMessage: 'The URL must begin with http:// or https://',
    },
    refreshPage: {
        id: 'global.button.refresh_page',
        defaultMessage: 'Refresh page',
    },
    addressTag: {
        id: 'global.typography.address_tag',
        defaultMessage: 'Address tag',
    },
    address: {
        id: 'global.typography.address',
        defaultMessage: 'Address',
    },
    requestErrorOccurred: {
        id: 'global.error.request_error_occurred',
        defaultMessage: 'Request error occurred',
    },
    disabled: {
        id: 'global.typography.disabled',
        defaultMessage: 'Disabled',
    },
    enabled: {
        id: 'global.typography.enabled',
        defaultMessage: 'Enabled',
    },
    hash: {
        id: 'global.typography.hash',
        defaultMessage: 'Hash',
    },
    amountShouldBeGreaterThanZero: {
        id: 'global.form.amount_should_be_greater_than_zero',
        defaultMessage: 'Amount should be greater than 0',
    },
    crypto: {
        id: 'global.typography.crypto',
        defaultMessage: 'Crypto',
    },
    fiat: {
        id: 'global.typography.fiat',
        defaultMessage: 'Fiat',
    },
    fiatCurrencies: {
        id: 'global.typography.fiat_currencies',
        defaultMessage: 'Fiat currencies',
    },
    cryptoCurrencies: {
        id: 'global.typography.crypto_currencies',
        defaultMessage: 'Crypto currencies',
    },
    goBack: {
        id: 'global.button.go_back',
        defaultMessage: 'Go back',
    },
    colorMode: {
        id: 'global.theme.color_mode',
        defaultMessage: 'Color mode',
    },
    optional: {
        id: 'global.typography.optional',
        defaultMessage: 'Optional',
    },
    network: {
        id: 'global.typography.network',
        defaultMessage: 'Network',
    },
    verificationLevelTooLowError: {
        id: 'global.form.verification_level_too_low_error',
        defaultMessage: 'Your verification level is insufficient. Please contact support.',
    },
    maxAllowedLength: {
        id: 'global.form.max_allowed_length',
        defaultMessage: 'Maximum length: {length} characters',
    },
    unsavedChangesUniversal: {
        id: 'global.typography.unsaved_changes',
        defaultMessage:
            "Attention: You have unsaved changes / progress.  Click 'OK' to confirm that you agree to lose this data, or 'Cancel' to return to editing.",
    },
    custom: {
        id: 'global.typography.custom',
        defaultMessage: 'Custom',
    },
    default: {
        id: 'global.typography.default',
        defaultMessage: 'Default',
    },
    maximumAllowedLengthExceeded: {
        id: 'global.form.maximum_allowed_length_exceeded',
        defaultMessage: 'Maximum allowed length of {maxLength} exceeded',
    },
    ownerRole: {
        id: 'global.typography.owner_role',
        defaultMessage: 'Owner',
    },
    ownerRoleDescription: {
        id: 'global.typography.owner_role_description',
        defaultMessage:
            'Role can invite multiple users to join the platform under the company’s umbrella and give them permissions that allow executing specific functions, while limiting others',
    },
    networkTempUnavailable: {
        id: 'global.typography.network_temp_unavailable',
        defaultMessage: 'Withdrawals in this network are temporarily unavailable',
    },
    withdrawalsTempUnavailable: {
        id: 'global.typography.withdrawals_temp_unavailable',
        defaultMessage: 'Withdrawals are temporarily unavailable',
    },
    accountInactive: {
        id: 'global.form.account_inactive',
        defaultMessage: 'Account inactive. Please try to restart registration with this email',
    },
})
