import type { Breakpoint } from '@merchant/ui-kit/ant-design'
import type { CSSProperties } from 'react'

export const getHeaderStyles = (breakpoints: Partial<Record<Breakpoint, boolean>>): CSSProperties => ({
    paddingInline: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: !breakpoints.md ? 24 : 0,
    marginBottom: 24,
    borderBottom: '1px solid',
    height: breakpoints.xs ? 64 : 72,
})

export const logoLinkStyles: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    height: 30,
}

export const logoutModalTitleStyles: CSSProperties = {
    fontSize: 20,
    fontWeight: 500,
    textAlign: 'center',
}
