import { EmailSupportLink } from '@merchant/shared/components'
import { Button, Space, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import { globalLang } from '~globalLang'

const { Text } = Typography

const spaceStyle: React.CSSProperties = { width: '100%' }
const buttonStyle: React.CSSProperties = { marginTop: 24 }

interface Props {
    close: () => void
}

export function DeletionDisabled({ close }: Props) {
    return (
        <>
            <Space
                direction="vertical"
                style={spaceStyle}
                size={30}
                data-merchant="cannot-delete-last-2fa-modal-description"
            >
                <Text data-merchant={null}>
                    <FormattedMessage {...lang.deletionDisabledDescription} />
                </Text>
                <Text type="secondary" data-merchant={null}>
                    <FormattedMessage {...lang.ifHaveQuestions} values={{ support: <EmailSupportLink /> }} />
                </Text>
            </Space>
            <Button
                block
                type="primary"
                style={buttonStyle}
                size="large"
                onClick={close}
                data-merchant="cannot-delete-last-2fa-modal-button"
            >
                <FormattedMessage {...globalLang.ok} />
            </Button>
        </>
    )
}
