import type { useBreakpoint } from '@merchant/shared/hooks'
import type { useLocation } from 'react-router-dom'
import { routes as AppRoutes } from '~constants/routes'

export const getShouldShowProjectName = (
    breakpoints: ReturnType<typeof useBreakpoint>,
    location: ReturnType<typeof useLocation>,
    authToken: string
) => location.pathname !== AppRoutes.notFound && breakpoints.lg && breakpoints.xl && !!authToken

export const getShouldShowTotalBalance = (breakpoints: ReturnType<typeof useBreakpoint>, authToken: string) =>
    breakpoints.lg && !breakpoints.xl && !!authToken
