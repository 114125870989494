import { useBreakpoint } from '@merchant/shared/hooks'
import { Row, Col, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { useBoolean } from 'usehooks-ts'
import { pageTitleMap, dataMerchantAttributesMap } from './const'
import { CurrenciesSetModal } from './CurrenciesSetModal'
import { MerchantCreatedModal } from './MerchantCreatedModal'
import { SetupSteps } from './SetupSteps'
import { MerchantSettingsShowOptions } from './types'
import { useData } from './useData'
import { getMerchantPageOptions } from './utils'
import { CurrenciesSettings, General, PaymentsSettings } from './views'
import type { MerchantSettingsPageOption } from './types'
import { PageContentContainer } from '~components'
import { QueryParams } from '~constants/routes'
import { useUserPermissionContext } from '~contexts'
import { useURLSearchNavigation } from '~hooks'

const { Title } = Typography

export function MerchantSettings() {
    const { checkUserPermission } = useUserPermissionContext()
    const { value: isAlertModalOpen, setTrue: openAlertModal, setFalse: closeAlertModal } = useBoolean(false)
    const {
        value: currenciesSetModalOpen,
        setTrue: openCurrenciesSetModal,
        setFalse: closeCurrenciesSetModal,
    } = useBoolean(false)

    const { project, handleSaveProject, isProjectLoading, isSaveProjectLoading } = useData({
        openModal: openAlertModal,
    })
    const breakpoints = useBreakpoint()
    const options: MerchantSettingsPageOption[] = getMerchantPageOptions(project, isProjectLoading, checkUserPermission)
    const pages: MerchantSettingsShowOptions[] = options.filter(({ disabled }) => !disabled).map(({ value }) => value)
    const { changePage, page } = useURLSearchNavigation({
        key: QueryParams.show,
        pages,
    })

    const handleMerchantCreatedModalClose = () => {
        changePage(MerchantSettingsShowOptions.assets)
        closeAlertModal()
    }

    const handleCurrenciesSetModalClose = () => {
        closeCurrenciesSetModal()
        changePage(MerchantSettingsShowOptions.paymentsSettings)
    }

    const views = {
        [MerchantSettingsShowOptions.general]: (
            <General handleSaveProject={handleSaveProject} isSaveProjectLoading={isSaveProjectLoading} />
        ),
        [MerchantSettingsShowOptions.assets]: <CurrenciesSettings onSetupFinish={openCurrenciesSetModal} />,
        [MerchantSettingsShowOptions.paymentsSettings]: <PaymentsSettings />,
    } as const

    const view = views[page]
    const subtitle = <FormattedMessage {...pageTitleMap[page]} />
    const subtitleDataMerchant = dataMerchantAttributesMap[page]

    return (
        <>
            <MerchantCreatedModal
                isOpen={isAlertModalOpen}
                close={closeAlertModal}
                name={project?.name || ''}
                onSuccess={handleMerchantCreatedModalClose}
            />
            <CurrenciesSetModal
                open={currenciesSetModalOpen}
                onCancel={closeCurrenciesSetModal}
                onOk={handleCurrenciesSetModalClose}
            />
            <Title level={4} data-merchant={subtitleDataMerchant} style={{ marginBottom: 16 }}>
                {subtitle}
            </Title>
            <Row gutter={16} wrap={false}>
                <Col flex="auto">{view}</Col>
                {breakpoints.lg && (
                    <Col flex="40%">
                        <PageContentContainer style={{ marginTop: 0 }}>
                            <SetupSteps isLoadingProject={isProjectLoading} />
                        </PageContentContainer>
                    </Col>
                )}
            </Row>
        </>
    )
}
