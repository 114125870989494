import { InlineLinkButton } from '@merchant/shared/components'
import { isKeyOfObject } from '@merchant/shared/utils'
import { Flex, theme } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { idButtonStyles } from './const'
import { lang } from './lang'
import {
    PaymentSubtype,
    PaymentType,
    Permission,
    type Transaction,
    type TransactionPaymentDetails,
} from '~api/instances/cabinet-api'
import { useUserPermissionContext } from '~contexts'
import { getOriginText, getTypeTooltip } from '~features/TransactionsTable/utils'

interface Props {
    details: TransactionPaymentDetails
    origin: Transaction['origin']
    onIdClick: (orderId: string) => void
}

const viewsByType = {
    [PaymentType.Deposit]: {
        title: lang.paymentDepositOrder,
        tooltip: {
            API: lang.paymentDepositOrderApiTooltip,
            WEB: lang.paymentDepositOrderWebTooltip,
        },
    },
    [PaymentType.Invoice]: {
        title: lang.paymentInvoiceOrder,
        tooltip: {
            API: lang.paymentInvoiceOrderApiTooltip,
            WEB: lang.paymentInvoiceOrderWebTooltip,
        },
    },
}

const viewsBySubtype = {
    [PaymentSubtype.ProjectAddressDeposit]: {
        title: lang.paymentWalletDeposit,
        tooltip: {
            API: lang.paymentWalletDepositApiTooltip,
            WEB: lang.paymentWalletDepositWebTooltip,
        },
    },
    [PaymentSubtype.CustomerAddressDeposit]: {
        title: lang.paymentStaticAddressDeposit,
        tooltip: {
            API: lang.paymentStaticAddressDepositApiTooltip,
            WEB: lang.paymentStaticAddressDepositWebTooltip,
        },
    },
}

export function PaymentNotes({ origin, details, onIdClick }: Props) {
    const { paymentId } = details
    const { checkUserPermission } = useUserPermissionContext()
    const { useToken } = theme
    const { token } = useToken()

    let viewProps

    if (details.type === PaymentType.Invoice) {
        viewProps = viewsByType[PaymentType.Invoice]
    } else {
        if (details.subtype) {
            viewProps = viewsBySubtype[details.subtype]
        } else {
            viewProps = viewsByType[PaymentType.Deposit]
        }
    }

    const { title, tooltip } = viewProps

    return (
        <Flex vertical gap={3}>
            <Flex gap={4} align="center">
                <FormattedMessage {...title} />
                {getOriginText(origin)}
                {isKeyOfObject(origin, tooltip) && getTypeTooltip(tooltip[origin], token)}
            </Flex>
            {checkUserPermission(Permission.SeePaymentHistory) ? (
                <InlineLinkButton
                    onClick={() => onIdClick(paymentId)}
                    style={idButtonStyles}
                    data-merchant="order-id-link"
                >
                    #{paymentId}
                </InlineLinkButton>
            ) : (
                <span>#{paymentId}</span>
            )}
        </Flex>
    )
}
