import { ModalResponsive } from '@merchant/shared/components'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { TwoFaConfirmationForm } from '../../TwoFaConfirmationForm'
import { generateApiKeyPair } from '../../utils'
import { stepsModalTitleMap } from './const'
import { lang } from './lang'
import { steps } from './types'
import { ApiKeyForm, ApiKeyCreated } from './views'
import type { CreateApiKeyData, NewApiKey } from './types'
import type { FormInstance, ModalProps } from '@merchant/ui-kit/ant-design'
import type { APIKey, TOTPCredentials } from '~api/instances/cabinet-api'
import { projectApi, useProject } from '~api'
import { useCodeConfirmationErrorHandling } from '~features/FormItems/useCodeErrorHandling'
import { useDirtyFormCheckerControls } from '~hooks/useDirtyFormCheckerControls'

const PRIVATE_KEY_MASK_LENGTH = 6

const initialStep = steps.createApiKeyForm

interface Props extends Omit<ModalProps, 'title' | 'data-merchant'> {
    onCreateSuccess: (apiKey: APIKey) => void
    closeModal: () => void
}

export function CreateApiKeyModal({ onCreateSuccess, closeModal, onCancel, ...rest }: Props) {
    const { data } = useProject()
    const projectId = data?.id || ''
    const [step, setStep] = useState<keyof typeof steps>(initialStep)
    const [newApiKey, setNewApiKey] = useState<NewApiKey>()
    const [newKeyData, setNewKeyData] = useState<CreateApiKeyData>()
    const { handleError } = useCodeConfirmationErrorHandling({
        confirmationStrategy: { '2fa': 'code' },
    })
    const { handleCancel } = useDirtyFormCheckerControls(onCancel)

    const resetStates = () => {
        setStep(initialStep)
        setNewKeyData(undefined)
        setNewApiKey(undefined)
    }

    const views = {
        [steps.createApiKeyForm]: () => {
            const onSuccess = (data: CreateApiKeyData) => {
                setNewKeyData(data)
                setStep(steps.createApiKeyConfirmation)
            }

            return <ApiKeyForm onSuccess={onSuccess} />
        },
        [steps.createApiKeyConfirmation]: () => {
            const on2FaConfirmed = async (totp: TOTPCredentials, form: FormInstance) => {
                if (!newKeyData) {
                    return handleError({
                        form,
                        error: new Error('No data'),
                    })
                }
                const { privateKey, publicKey } = await generateApiKeyPair()
                const privateKeyMask = `${privateKey.slice(0, PRIVATE_KEY_MASK_LENGTH)}...${privateKey.slice(-PRIVATE_KEY_MASK_LENGTH)}`
                try {
                    const apiKeyResponse = await projectApi.createAPIKey({
                        projectId,
                        createAPIKeyRequest: {
                            name: newKeyData.name,
                            permissions: newKeyData.permissions,
                            publicKey,
                            privateKeyMask,
                            ...totp,
                        },
                    })
                    onCreateSuccess(apiKeyResponse)
                    setNewApiKey({ ...apiKeyResponse, privateKey })
                    setStep(steps.createApiKeyCreated)
                } catch (error) {
                    handleError({
                        form,
                        error,
                    })
                }
            }

            return (
                <TwoFaConfirmationForm
                    mode="create-api-key"
                    onSuccess={on2FaConfirmed}
                    submitButtonTitle={lang.createButton}
                />
            )
        },
        [steps.createApiKeyCreated]: () => {
            return <ApiKeyCreated apiKey={newApiKey} onSuccess={closeModal} />
        },
    }

    const modalTitle = <FormattedMessage {...stepsModalTitleMap[step]} />

    return (
        <ModalResponsive
            data-merchant="create-api-key-modal"
            onCancel={handleCancel}
            afterClose={resetStates}
            footer={null}
            destroyOnClose
            title={<span data-merchant="create-api-key-modal-title">{modalTitle}</span>}
            {...rest}
        >
            {views[step]()}
        </ModalResponsive>
    )
}
