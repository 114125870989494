import type { MessageDescriptor } from 'react-intl'
import type { ProjectRole } from '~api/instances/cabinet-api'
import { ProjectRoleColor } from '~api/instances/cabinet-api'
import { globalLang } from '~globalLang'

const OWNER_ROLE_ID = 'owner'

export const ownerRole: Omit<ProjectRole, 'name' | 'description'> & {
    name: MessageDescriptor
    description: MessageDescriptor
} = {
    id: OWNER_ROLE_ID,
    name: globalLang.ownerRole,
    color: ProjectRoleColor.Grey,
    description: globalLang.ownerRoleDescription,
    permissions: [],
    _default: true,
}

export const nonEditableRoles = [ownerRole.id]
