import { DatePicker as OriginalDatePicker } from 'antd'
import type { WithDataPointer } from './types'
import type { GetProps } from 'antd'
import type { Dayjs } from 'dayjs'

const getPanelRenderer =
    (
        renderPanelProp: GetProps<typeof DatePicker>['panelRender'],
        dataMerchant: string | null
    ): GetProps<typeof DatePicker>['panelRender'] =>
    panel => <div data-merchant={`${dataMerchant}-panel`}>{renderPanelProp?.(panel) ?? panel}</div>

function DatePicker<ValueType = Dayjs>({ ...props }: GetProps<typeof OriginalDatePicker<ValueType>> & WithDataPointer) {
    return (
        <OriginalDatePicker<ValueType>
            {...props}
            panelRender={getPanelRenderer(props.panelRender, props['data-merchant'])}
        />
    )
}

DatePicker.MonthPicker = ({ ...props }: GetProps<typeof OriginalDatePicker.MonthPicker> & WithDataPointer) => {
    return (
        <OriginalDatePicker.MonthPicker
            {...props}
            panelRender={getPanelRenderer(props.panelRender, props['data-merchant'])}
        />
    )
}

DatePicker.QuarterPicker = ({ ...props }: GetProps<typeof OriginalDatePicker.QuarterPicker> & WithDataPointer) => {
    return (
        <OriginalDatePicker.QuarterPicker
            {...props}
            panelRender={getPanelRenderer(props.panelRender, props['data-merchant'])}
        />
    )
}

DatePicker.RangePicker = ({ ...props }: GetProps<typeof OriginalDatePicker.RangePicker> & WithDataPointer) => {
    return (
        <OriginalDatePicker.RangePicker
            {...props}
            panelRender={getPanelRenderer(props.panelRender, props['data-merchant'])}
        />
    )
}

DatePicker.TimePicker = ({ ...props }: GetProps<typeof OriginalDatePicker.TimePicker> & WithDataPointer) => {
    return (
        <OriginalDatePicker.TimePicker
            {...props}
            panelRender={getPanelRenderer(props.panelRender, props['data-merchant'])}
        />
    )
}

DatePicker.WeekPicker = ({ ...props }: GetProps<typeof OriginalDatePicker.WeekPicker> & WithDataPointer) => {
    return (
        <OriginalDatePicker.WeekPicker
            {...props}
            panelRender={getPanelRenderer(props.panelRender, props['data-merchant'])}
        />
    )
}

DatePicker.YearPicker = ({ ...props }: GetProps<typeof OriginalDatePicker.YearPicker> & WithDataPointer) => {
    return (
        <OriginalDatePicker.YearPicker
            {...props}
            panelRender={getPanelRenderer(props.panelRender, props['data-merchant'])}
        />
    )
}

export default DatePicker
