import type { ConfigOptions } from '@merchant/ui-kit/ant-design/es/message/interface'
import type { NotificationConfig } from '@merchant/ui-kit/ant-design/es/notification/interface'
import { NOTIFICATIONS_MAX_COUNT } from '~constants'

export const notificationConfig: NotificationConfig = {
    stack: { threshold: NOTIFICATIONS_MAX_COUNT },
}

export const messageConfig: ConfigOptions = {
    maxCount: NOTIFICATIONS_MAX_COUNT,
}
