import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    noAccessToCurrencySettings: {
        id: 'payment-constructor.alert.unable-to-read-holding-currencies',
        defaultMessage:
            "You can't create an order because access to <text>Currency settings</text> is required to determine if the order can be configured with the desired parameters. Please contact your project owner or admin to request permission.",
    },
    notConfiguredCurrencySettings: {
        id: 'payment-constructor.alert.empty-holding-currencies',
        defaultMessage:
            "You can't create an order because your <settingsLink>Currency settings</settingsLink> haven't been configured. Please complete this step or ask the project owner if you don’t have permission to edit <settingsLink>Currency settings</settingsLink>.",
    },
})
