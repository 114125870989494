import { Flex, Skeleton } from '@merchant/ui-kit/ant-design'

export function LoadingSkeleton() {
    return (
        <Flex vertical>
            <Skeleton
                title={{ width: 300, style: { height: 23, marginBottom: 4 } }}
                paragraph={false}
                style={{ height: 16, marginTop: 0 }}
                active
            />
            <Skeleton.Input block size="large" style={{ marginBottom: 4 }} active />
            <Skeleton.Input block size="large" active />
        </Flex>
    )
}
