import { Card, type CardProps } from '@merchant/ui-kit/ant-design'
import React from 'react'

const cardContainerStyles: React.CSSProperties = {
    borderRadius: 20,
    border: 'none',
    width: '100%',
    padding: 0,
    marginTop: 16,
    boxShadow: '0px 15px 30px 0px rgba(0, 0, 0, 0.03)',
}

const cardContainerBodyStyles: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
}

export function PageContentContainer({ children, styles, style, ...rest }: Omit<CardProps, 'data-merchant'>) {
    const { body: bodyStyles, ...restStyles } = styles || {}

    return (
        <Card
            style={{ ...cardContainerStyles, ...style }}
            styles={{
                body: { ...cardContainerBodyStyles, ...bodyStyles },
                ...restStyles,
            }}
            data-merchant="page-content-container"
            {...rest}
        >
            {children}
        </Card>
    )
}
