import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    modalTitle: {
        id: 'team-management.modal.edit_member_title',
        defaultMessage: 'Edit member',
    },
    modalSubtitle: {
        id: 'team-management.modal.edit_member_subtitle',
        defaultMessage: 'at {projectName}',
    },
    emailLabel: {
        id: 'team-management.modal.edit_member_email_label',
        defaultMessage: 'Email',
    },
    nameLabel: {
        id: 'team-management.modal.edit_member_name_label',
        defaultMessage: 'Name',
    },
    roleLabel: {
        id: 'team-management.modal.edit_member_role_label',
        defaultMessage: 'Role',
    },
    saveChangesButton: {
        id: 'team-management.modal.edit_member_save_changes_button',
        defaultMessage: 'Save changes',
    },
})
