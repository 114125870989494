/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConfirmTOTPRequest
 */
export interface ConfirmTOTPRequest {
    /**
     * 
     * @type {string}
     * @memberof ConfirmTOTPRequest
     */
    verificationCode: string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmTOTPRequest
     */
    totpCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmTOTPRequest
     */
    totpBackup?: string;
}

/**
 * Check if a given object implements the ConfirmTOTPRequest interface.
 */
// @ts-ignore
export function instanceOfConfirmTOTPRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "verificationCode" in value;

    return isInstance;
}

// @ts-ignore
export function ConfirmTOTPRequestFromJSON(json: any): ConfirmTOTPRequest {
    return ConfirmTOTPRequestFromJSONTyped(json, false);
}

// @ts-ignore
export function ConfirmTOTPRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfirmTOTPRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'verificationCode': json['verification_code'],
        'totpCode': !exists(json, 'totp_code') ? undefined : json['totp_code'],
        'totpBackup': !exists(json, 'totp_backup') ? undefined : json['totp_backup'],
    };
}

// @ts-ignore
export function ConfirmTOTPRequestToJSON(value?: ConfirmTOTPRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'verification_code': value.verificationCode,
        'totp_code': value.totpCode,
        'totp_backup': value.totpBackup,
    };
}

