import { assets } from '@merchant/shared/assets/nameMap'
import { StyledQrCode, AppSvg, CopyButton } from '@merchant/shared/components'
import { Alert, Button, Col, Form, Input, Popover, Row } from '@merchant/ui-kit/ant-design'
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { formItemsKey } from './const'
import { lang } from './lang'
import type { NewApiKey } from '../../types'
import { ModalNames } from '~constants/modal'
import { useRegisterDirtyFormChecker } from '~hooks'

const topFormItemStyles: React.CSSProperties = { marginBottom: 12 }
const alertStyles: React.CSSProperties = { alignItems: 'center' }

interface Props {
    apiKey: NewApiKey | undefined
    onSuccess: () => void
}

// eslint-disable-next-line max-lines-per-function
export function ApiKeyCreated({ apiKey, onSuccess }: Props) {
    const [form] = Form.useForm()
    const { privateKey = '', publicKey = '' } = apiKey || {}
    useRegisterDirtyFormChecker(ModalNames.createApiKeyCreated)

    useEffect(() => {
        form.setFieldsValue(apiKey)
    }, [form, apiKey])

    return (
        <Form form={form} layout="vertical" size="large" initialValues={apiKey} onFinish={onSuccess}>
            <Form.Item label={<FormattedMessage {...lang.publicKeyLabel} />} style={topFormItemStyles}>
                <Row gutter={12}>
                    <Col flex={1}>
                        <Form.Item noStyle name={formItemsKey.publicKey}>
                            <Input
                                data-merchant="public-key-input"
                                readOnly
                                suffix={
                                    <CopyButton
                                        notificationContentSubject={lang.publicKeyLabel}
                                        data-merchant="public-key-copy-button"
                                        inputValue={publicKey}
                                    />
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Popover
                            trigger="click"
                            overlayStyle={{ textAlign: 'center' }}
                            title={<FormattedMessage {...lang.publicKeyLabel} />}
                            placement="bottom"
                            arrow={false}
                            content={
                                <div data-merchant="public-key-popover-qr-code">
                                    <StyledQrCode bordered value={publicKey} size={200} />
                                </div>
                            }
                            data-merchant="public-key-qr-popover"
                        >
                            <Button
                                data-merchant="public-key-show-qr-code-button"
                                icon={<AppSvg size={20} name={assets.viewQrCode} />}
                            />
                        </Popover>
                    </Col>
                </Row>
            </Form.Item>
            <Form.Item label={<FormattedMessage {...lang.privateKeyLabel} />}>
                <Row gutter={12}>
                    <Col flex={1}>
                        <Form.Item noStyle name={formItemsKey.privateKey}>
                            <Input
                                data-merchant="private-key-input"
                                readOnly
                                suffix={
                                    <CopyButton
                                        data-merchant="private-key-copy-button"
                                        inputValue={privateKey}
                                        notificationContentSubject={lang.privateKeyLabel}
                                    />
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Popover
                            trigger="click"
                            overlayStyle={{ textAlign: 'center' }}
                            title={<FormattedMessage {...lang.privateKeyLabel} />}
                            placement="bottom"
                            arrow={false}
                            content={
                                <div data-merchant="private-key-popover-qr-code">
                                    <StyledQrCode bordered value={privateKey} size={300} />
                                </div>
                            }
                            data-merchant="private-key-qr-popover"
                        >
                            <Button
                                data-merchant="private-key-show-qr-code-button"
                                icon={<AppSvg size={20} name={assets.viewQrCode} />}
                            />
                        </Popover>
                    </Col>
                </Row>
            </Form.Item>
            <Form.Item>
                <Alert
                    data-merchant="created-api-key-alert"
                    style={alertStyles}
                    type="warning"
                    icon={<AppSvg name={assets.alertCircle} />}
                    description={
                        <FormattedMessage
                            {...lang.alertText}
                            values={{
                                notice: <FormattedMessage {...lang.alertNotice} />,
                            }}
                        />
                    }
                    showIcon
                />
            </Form.Item>
            <Form.Item noStyle>
                <Button htmlType="submit" type="primary" block data-merchant="api-key-created-submit-button">
                    <FormattedMessage {...lang.submitButton} />
                </Button>
            </Form.Item>
        </Form>
    )
}
