// contextActions.ts
import type { HttpError } from './httpError'
import type { MiddlewareContext } from './types'

type ContextActionHandlers = {
    handleError: (err: HttpError) => void
}

export const contextActions: Partial<Record<MiddlewareContext, ContextActionHandlers>> = {
    cabinet: {
        handleError: (err: HttpError) => {
            err.sendCabinetErrorReport()
        },
    },
}
