import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    pageTitle: {
        id: 'balances-snapshots.typography.title',
        defaultMessage: 'Balances',
    },
    pageSubtitle: {
        id: 'balances-snapshots.typography.subtitle',
        defaultMessage: 'Balance snapshots',
    },
    cardTitle: {
        id: 'balances-snapshots.typography.card_title',
        defaultMessage: 'Daily snapshot',
    },
    cardSubtitle: {
        id: 'balances-snapshots.typography.card_subtitle',
        defaultMessage: 'Historical snapshots are created daily at 00:00 (UTC+00:00) ',
    },
    timezoneNote: {
        id: 'balances-snapshots.typography.timezone_note',
        defaultMessage:
            '<b>Time zone information:</b> This time is in UTC+00:00. In your local time zone {timezone}, this corresponds to {time} on {isSameDay, select, true {the same date.} other {the previous date.}}',
    },
})
