import { useBoolean } from 'usehooks-ts'
import type { TOTP } from '~api/instances/cabinet-api'
import { AddTwoFaModal, PlaceholderView } from '~features'
import { PlaceholderName } from '~features/PlaceholderView/types'

interface Props {
    onSuccess: (newTotp: TOTP) => void
    buttonDataMerchant: string
}

export function SetupTwoFa({ onSuccess, buttonDataMerchant }: Props) {
    const { value: isOpen, setTrue: openModal, setFalse: closeModal } = useBoolean(false)

    return (
        <>
            <AddTwoFaModal isOpen={isOpen} close={closeModal} isTwoFaEnabled={false} onModalSuccess={onSuccess} />
            <PlaceholderView
                buttonClickHandler={openModal}
                buttonDataMerchant={buttonDataMerchant}
                name={PlaceholderName.no2fa}
            />
        </>
    )
}
