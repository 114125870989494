import { StatisticFormattedNumber } from '@merchant/shared/components'
import { parseDateAndTime } from '@merchant/shared/utils'
import { Col, Row, Space, Typography } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import styles from '../../../style.module.css'
import { lang } from '../lang'
import type { Payment } from '~api/instances/cabinet-api'
import { NoValueColumnContent } from '~components'

const { Text } = Typography

interface Props {
    data: Payment
}
export function TimelineSummary({ data }: Props) {
    const created = parseDateAndTime(data.createdAt)

    return (
        <Space direction="vertical" size={16} style={{ width: '100%' }}>
            <Text data-merchant={null}>
                <FormattedMessage {...lang.orderCreated} />
            </Text>
            <Row justify="space-between">
                <Col>
                    <Text type="secondary" data-merchant={null}>
                        <FormattedMessage {...lang.date} />
                    </Text>
                </Col>
                <Col className={styles.middleCol} />
                <Col>
                    <Text data-merchant="order-timeline-summary-created-date">{created?.date}</Text>{' '}
                    <Text type="secondary" data-merchant="order-timeline-summary-created-time">
                        {created?.time}
                    </Text>
                </Col>
            </Row>
            <Row justify="space-between">
                <Col>
                    <Text type="secondary" data-merchant={null}>
                        <FormattedMessage {...lang.orderPrice} />
                    </Text>
                </Col>
                <Col className={styles.middleCol} />
                <Col>
                    {!data.nominalAmount && data.type === 'DEPOSIT' ? (
                        NoValueColumnContent
                    ) : (
                        <StatisticFormattedNumber value={data.nominalAmount} suffix={data.nominalCurrency} />
                    )}
                </Col>
            </Row>
        </Space>
    )
}
