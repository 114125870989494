import { assets } from '@merchant/shared/assets/nameMap'
import { AppCoin, AppSvg, ModalResponsive } from '@merchant/shared/components'
import { usePromise } from '@merchant/shared/hooks'
import { Button, Flex, Typography } from '@merchant/ui-kit/ant-design'
import { forwardRef, useImperativeHandle } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { useBoolean } from 'usehooks-ts'
import { lang } from './lang'
import type { CSSProperties } from 'react'
import { useCurrencies } from '~api'
import { QueryParams, routes } from '~constants/routes'
import { MerchantSettingsShowOptions } from '~features/MerchantPage/components/MerchantSettings/types'
import { generateQuery } from '~utils'

const { Text } = Typography

const textCommonStyle = { lineHeight: 1.2 }
const queryToSettings = `${routes.merchantSettings}${generateQuery({ [QueryParams.show]: MerchantSettingsShowOptions.assets })}`
const iconSizes = {
    default: 24,
    large: 44,
}

interface Props {
    currencyFrom: string
    currencyTo: string
    size?: 'default' | 'large'
    onConfirm: (() => Promise<unknown>) | null
    onReject: () => void
    dataMerchant: string
}

function ConversionNote({
    currencyFrom,
    currencyTo,
    size = 'default',
    dataMerchant,
}: Omit<Props, 'onConfirm' | 'onReject'>) {
    const { data: currencies } = useCurrencies()
    const currencyFromData = currencies?.[currencyFrom] || { code: currencyFrom, name: '' }
    const currencyToData = currencies?.[currencyTo] || { code: currencyTo, name: '' }

    const iconSize = iconSizes[size]
    const textSizeStyle: CSSProperties = size === 'large' ? { fontSize: undefined } : { fontSize: 12 }

    return (
        <Flex vertical align="center" gap={12} data-merchant={`${dataMerchant}-conversion-note`}>
            <Text style={textSizeStyle} data-merchant={`${dataMerchant}-conversion-note-text`}>
                <FormattedMessage
                    {...lang.note}
                    values={{
                        b: chunks => <b>{chunks}</b>,
                        settingsLink: chunks => <Link to={queryToSettings}>{chunks}</Link>,
                        currencyFromCode: currencyFromData.code,
                        currencyFromName: currencyFromData.name,
                        currencyToCode: currencyToData.code,
                        currencyToName: currencyToData.name,
                    }}
                />
            </Text>
            <Flex gap={12}>
                <Flex vertical align="center" data-merchant={`${dataMerchant}-conversion-note-from-currency`}>
                    <AppCoin currencyCode={currencyFrom} style={{ width: iconSize, height: iconSize }} />
                    <Text style={{ ...textCommonStyle, ...textSizeStyle, marginTop: 4 }} data-merchant={null}>
                        {currencyFromData.code}
                    </Text>
                    <Text style={{ ...textCommonStyle, ...textSizeStyle }} type="secondary" data-merchant={null}>
                        {currencyFromData.name}
                    </Text>
                </Flex>
                <AppSvg
                    name={assets.arrowNarrowLeft}
                    style={{ transform: 'rotate(180deg)' }}
                    color={token => token.colorTextPlaceholder}
                />
                <Flex vertical align="center" data-merchant={`${dataMerchant}-conversion-note-to-currency`}>
                    <AppCoin currencyCode={currencyTo} style={{ width: iconSize, height: iconSize }} />
                    <Text style={{ ...textCommonStyle, ...textSizeStyle, marginTop: 4 }} data-merchant={null}>
                        {currencyToData.code}
                    </Text>
                    <Text style={{ ...textCommonStyle, ...textSizeStyle }} type="secondary" data-merchant={null}>
                        {currencyToData.name}
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    )
}

export interface ConversionNoteModalRef {
    open: () => void
}

const ConversionNoteModal = forwardRef<ConversionNoteModalRef, Props>(function (
    { onConfirm, onReject, dataMerchant, ...props },
    ref
) {
    const onConfirmNormalized = onConfirm ?? (() => Promise.reject(new Error('onConfirm is not provided')))

    const { value: isOpen, setFalse: closeModal, setTrue: openModal } = useBoolean(false)
    const { send, isLoading } = usePromise(onConfirmNormalized, {
        onSuccess: closeModal,
        onError: console.error,
    })
    useImperativeHandle(ref, () => ({
        open: openModal,
    }))

    const onClose = () => {
        closeModal()
        onReject()
    }

    return (
        <ModalResponsive
            open={isOpen}
            data-merchant={`${dataMerchant}-modal`}
            title={
                <Text style={{ fontSize: 20 }} data-merchant={`${dataMerchant}-modal-title`}>
                    <FormattedMessage {...lang.modalTitle} values={{ currencyCode: props.currencyFrom }} />
                </Text>
            }
            onCancel={onClose}
            footer={() => (
                <Flex gap={12} style={{ marginTop: 20 }}>
                    <Button
                        style={{ width: '50%' }}
                        size="large"
                        onClick={onClose}
                        data-merchant={`${dataMerchant}-modal-close-button`}
                    >
                        <FormattedMessage {...lang.modalCancel} />
                    </Button>
                    <Button
                        style={{ width: '50%' }}
                        size="large"
                        type="primary"
                        loading={isLoading}
                        onClick={send}
                        data-merchant={`${dataMerchant}-modal-ok-button`}
                    >
                        <FormattedMessage {...lang.modalConfirm} />
                    </Button>
                </Flex>
            )}
        >
            <ConversionNote {...props} dataMerchant={`${dataMerchant}-modal`} size="large" />
        </ModalResponsive>
    )
})

ConversionNote.Modal = ConversionNoteModal

export { ConversionNote }
