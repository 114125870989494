/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TransactionType {
    Payment = 'PAYMENT',
    Swap = 'SWAP',
    Deposit = 'DEPOSIT',
    Withdrawal = 'WITHDRAWAL',
    FiatWithdrawal = 'FIAT_WITHDRAWAL'
}


// @ts-ignore
export function TransactionTypeFromJSON(json: any): TransactionType {
    return TransactionTypeFromJSONTyped(json, false);
}

// @ts-ignore
export function TransactionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionType {
    return json as TransactionType;
}

// @ts-ignore
export function TransactionTypeToJSON(value?: TransactionType | null): any {
    return value as any;
}

