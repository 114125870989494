import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    inProgressTimeline: {
        id: 'withdrawal.timeline.in_progress.title',
        defaultMessage: 'Withdrawal in progress',
    },
    date: {
        id: 'withdrawal.timeline.in_progress.date',
        defaultMessage: 'Date',
    },
    hash: {
        id: 'withdrawal.timeline.in_progress.hash',
        defaultMessage: 'Hash',
    },
})
