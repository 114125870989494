/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignupConfirmRequest
 */
export interface SignupConfirmRequest {
    /**
     * 
     * @type {string}
     * @memberof SignupConfirmRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SignupConfirmRequest
     */
    verificationCode: string;
    /**
     * 
     * @type {string}
     * @memberof SignupConfirmRequest
     */
    token: string;
}

/**
 * Check if a given object implements the SignupConfirmRequest interface.
 */
// @ts-ignore
export function instanceOfSignupConfirmRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "verificationCode" in value;
    isInstance = isInstance && "token" in value;

    return isInstance;
}

// @ts-ignore
export function SignupConfirmRequestFromJSON(json: any): SignupConfirmRequest {
    return SignupConfirmRequestFromJSONTyped(json, false);
}

// @ts-ignore
export function SignupConfirmRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignupConfirmRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'verificationCode': json['verification_code'],
        'token': json['token'],
    };
}

// @ts-ignore
export function SignupConfirmRequestToJSON(value?: SignupConfirmRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'verification_code': value.verificationCode,
        'token': value.token,
    };
}

