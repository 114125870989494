import { getConfigOrDefault } from '@merchant/shared/config'
import { EmailInputConfigEnum } from '@merchant/shared/config/types'
import { usePromise } from '@merchant/shared/hooks'
import { Button, Input } from '@merchant/ui-kit/ant-design'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useBoolean } from 'usehooks-ts'
import { CountdownInputSuffix } from './CountdownInputSuffix'
import { lang } from './lang'
import type { InputProps, InputRef } from '@merchant/ui-kit/ant-design'
import { useAutoFocus } from '~hooks'

const buttonStyles: React.CSSProperties = {
    height: 'auto',
    padding: '0 4px',
    position: 'absolute',
    right: 12,
}

interface Props extends InputProps {
    onResendClick: () => Promise<void>
    countdownSecondsAmount?: number
    shouldSendOnMount?: boolean
    isInitiallySent?: boolean
    shouldFocusOnMount?: boolean
}

const ONE_HOUR = 60 * 60
const RESEND_MAX_ATTEMPTS_COUNT = 2

export function ResendInput({
    onResendClick,
    countdownSecondsAmount: initCountdownSecondsAmount = 60,
    shouldSendOnMount = false,
    isInitiallySent,
    shouldFocusOnMount = false,
    ...rest
}: Props) {
    const ref = useAutoFocus<InputRef>(shouldFocusOnMount)
    const { value: isResendButtonShown, setValue: setIsResendButtonShown } = useBoolean(true)
    const [sendCount, setSendCount] = useState(0)

    const secondsAmount = getConfigOrDefault(EmailInputConfigEnum.CountdownSecondsAmount, initCountdownSecondsAmount)
    const [countdownSecondsAmount, setCountdownSecondsAmount] = useState(secondsAmount)

    const { send, isLoading } = usePromise(onResendClick, {
        onSuccess: () => {
            setSendCount(prev => prev + 1)
            const isAttemptsExceeded =
                sendCount >=
                getConfigOrDefault(EmailInputConfigEnum.ResendAttemptsBeforeDelay, RESEND_MAX_ATTEMPTS_COUNT)

            if (isAttemptsExceeded) {
                setSendCount(0)
                setCountdownSecondsAmount(ONE_HOUR)
            } else {
                setCountdownSecondsAmount(secondsAmount)
            }
            setIsResendButtonShown(false)
        },
        initialLoading: shouldSendOnMount,
    })

    const handleButtonClick = async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation()
        send()
    }

    const handleCountdownFinish = () => {
        setIsResendButtonShown(true)
    }

    useEffect(() => {
        if (isInitiallySent === true) {
            setSendCount(1)

            setCountdownSecondsAmount(secondsAmount)

            setIsResendButtonShown(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInitiallySent, setIsResendButtonShown])

    useEffect(() => {
        shouldSendOnMount && send()
    }, [send, shouldSendOnMount])

    return (
        <Input
            {...rest}
            ref={ref}
            autoComplete="off"
            suffix={
                isResendButtonShown ? (
                    <Button
                        data-merchant="resend-code-button"
                        style={buttonStyles}
                        onClick={handleButtonClick}
                        loading={isLoading}
                        type="link"
                    >
                        <FormattedMessage {...lang.resend} />
                    </Button>
                ) : (
                    <CountdownInputSuffix
                        handleCountdownFinish={handleCountdownFinish}
                        countdownSecondsAmount={countdownSecondsAmount}
                    />
                )
            }
        />
    )
}
