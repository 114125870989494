import { LoadingOutlined } from '@ant-design/icons'
import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { Flex, Spin, Typography, theme } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { NavLink } from 'react-router-dom'
import { lang } from '../../lang'
import type { GlobalToken } from '@merchant/ui-kit/ant-design'
import { SwapStatus } from '~api'
import { routes } from '~constants/routes'
import { globalLang } from '~globalLang'

const { useToken } = theme
const { Text } = Typography

const titleStyles: React.CSSProperties = { fontSize: 20, fontWeight: 500 }
const textStyles: React.CSSProperties = {
    fontSize: 16,
    lineHeight: '130%',
    display: 'inline-block',
    textAlign: 'center',
}

const progressStateContents = {
    [SwapStatus.Pending]: {
        title: lang.pendingTitle,
        text: lang.pendingText,
        visualIndicator: (token: GlobalToken) => (
            <Spin
                data-merchant="swap-status-pending-spin"
                size="large"
                indicator={<LoadingOutlined style={{ fontSize: 40, color: token.colorWarning }} />}
            />
        ),
    },
    [SwapStatus.Completed]: {
        title: lang.successTitle,
        text: lang.successText,
        visualIndicator: (token: GlobalToken) => {
            return <AppSvg size={48} name={assets.checkCircle} color={token.colorSuccess} />
        },
    },
    [SwapStatus.Failed]: {
        title: lang.failedTitle,
        text: lang.failedText,
        visualIndicator: (token: GlobalToken) => {
            return <AppSvg size={48} name={assets.xCircle} color={token.colorError} />
        },
    },
}

interface Props {
    state: SwapStatus | undefined
}

export function OperationState({ state }: Props) {
    const { token } = useToken()
    if (!state || state === SwapStatus.RolledBack) {
        return null
    }
    const stateContent = progressStateContents[state]

    return (
        <Flex vertical align="center" style={{ marginTop: state === SwapStatus.Failed ? 0 : 27 }} gap={8}>
            <div data-merchant={`operation-state-spinner-${state}`}>{stateContent.visualIndicator(token)}</div>
            <Text style={titleStyles} data-merchant="convert-state">
                <FormattedMessage {...stateContent.title} />
            </Text>
            <Text type="secondary" style={textStyles} data-merchant="convert-state-description">
                <FormattedMessage
                    {...stateContent.text}
                    values={{
                        TransactionsLink: (
                            <NavLink to={routes.transactions} style={textStyles}>
                                <FormattedMessage {...globalLang.transactions} />
                            </NavLink>
                        ),
                    }}
                />
            </Text>
        </Flex>
    )
}
