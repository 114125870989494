import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    successTestCallbackTitle: {
        id: 'test-callback-sent-modal.typography.success_title',
        defaultMessage: 'Test callback sent successfully',
    },
    successOrderCallbackTitle: {
        id: 'order-callback-sent-modal.typography.success_title',
        defaultMessage: 'The callback sent successfully',
    },
    successWithdrawalCallbackTitle: {
        id: 'withdrawal-callback-sent-modal.typography.success_title',
        defaultMessage: 'The callback sent successfully',
    },
    failureTestCallbackTitle: {
        id: 'test-callback-sent-modal.typography.failure_title',
        defaultMessage: 'Failed to send a test callback',
    },
    failureOrderCallbackTitle: {
        id: 'order-callback-sent-modal.typography.failure_title',
        defaultMessage: 'Failed to send callback',
    },
    failureWithdrawalCallbackTitle: {
        id: 'withdrawal-callback-sent-modal.typography.failure_title',
        defaultMessage: 'Failed to send callback',
    },
    subtitle: {
        id: 'test-callback-sent-modal.typography.subtitle',
        defaultMessage:
            'For more information on callback signatures and handling, visit our <link>Callback documentation</link>',
    },
    requestBody: {
        id: 'test-callback-sent-modal.typography.request_body',
        defaultMessage: 'Request body',
    },
    signature: {
        id: 'test-callback-sent-modal.typography.signature',
        defaultMessage: 'Signature',
    },
    url: {
        id: 'test-callback-sent-modal.typography.url',
        defaultMessage: 'URL',
    },
    statusCode: {
        id: 'test-callback-sent-modal.typography.response_status_code',
        defaultMessage: 'Response status code',
    },
    responseBody: {
        id: 'test-callback-sent-modal.typography.response_body',
        defaultMessage: 'Response body',
    },
    close: {
        id: 'test-callback-sent-modal.button.close',
        defaultMessage: 'Close',
    },
})
