import { reduce } from 'lodash-es'
import type { useBreakpoint } from '@merchant/shared/hooks'
import type { FlexProps } from '@merchant/ui-kit/ant-design'
import type { Balance, Currency } from '~api/instances/cabinet-api'
import type { Currencies } from '~api/types'

const getBalancesMap = (balancesList: Balance[]) => {
    return reduce(
        balancesList,
        (acc, curr) => {
            acc[curr.currency] = curr

            return acc
        },
        {} as Record<string, Balance>
    )
}

function filterCurrencies(currency: Currency | undefined): currency is Currency {
    return !!currency
}

export function getCurrencyOptions(balances: Balance[] = [], currencies: Currencies): (Currency & Partial<Balance>)[] {
    const balancesMap = getBalancesMap(balances)

    return Object.values(currencies)
        .filter(filterCurrencies)
        .map(currency => ({
            ...currency,
            ...balancesMap[currency.code || ''],
        }))
        .toSorted((a, b) => {
            const aEquivalent = Number(a.fiatEquivalentAmount ?? 0)
            const bEquivalent = Number(b.fiatEquivalentAmount ?? 0)
            if (aEquivalent !== bEquivalent) {
                return bEquivalent - aEquivalent
            }

            if (a.priority !== b.priority) {
                return (a.priority ?? 0) - (b.priority ?? 0)
            }

            return (a.name || '').localeCompare(b.name || '')
        })
}

export const getFlexWrapperProps: (
    breakpoints: ReturnType<typeof useBreakpoint>
) => Omit<FlexProps, 'children'> = breakpoints => ({
    justify: 'space-between',
    gap: breakpoints.md ? 16 : 0,
    vertical: !breakpoints.md,
    align: breakpoints.md ? 'flex-end' : undefined,
})
