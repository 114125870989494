import { IntlProvider } from 'react-intl'
import type { PropsWithChildren } from 'react'
import { useSupportedLocales, useLocaleMessages } from '~api'
import { useLocale } from '~hooks'

export function IntlWrapper({ children }: PropsWithChildren) {
    const { data: locales } = useSupportedLocales()
    const { locale } = useLocale(locales)
    const { data: messages } = useLocaleMessages(locale)

    // for the sake of surety, we should check if messages is null
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!messages) {
        return null
    }

    return (
        <IntlProvider locale={locale} messages={messages}>
            {children}
        </IntlProvider>
    )
}
