import { StatisticFormattedNumber } from '@merchant/shared/components'
import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import { ShortLabel } from './ShortLabel'
import type { LabelProps } from './types'

const { Text } = Typography

export function LongLabel(props: LabelProps) {
    return (
        <Flex align="center" justify="space-between" gap={4}>
            <ShortLabel showName {...props} />
            {props.data.amount && (
                <Text data-merchant={null} type="secondary">
                    <StatisticFormattedNumber value={props.data.amount} suffix={props.data.currency} />
                </Text>
            )}
        </Flex>
    )
}
