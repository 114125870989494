import { mutate, type SWRConfiguration } from 'swr'
import { HttpError } from '~api'

export const resetSwrCache = () => {
    return mutate(() => true, undefined, { revalidate: false })
}

export const onErrorRetry: SWRConfiguration['onErrorRetry'] = (...args) => {
    const [err, , config, revalidate, revalidateOpts] = args

    if (err instanceof HttpError && err.status && err.status >= 400 && err.status < 500) {
        return
    }

    if (revalidateOpts.retryCount >= (config.errorRetryCount || 5)) {
        return
    }

    setTimeout(() => revalidate(revalidateOpts), config.errorRetryInterval)
}

export function checkIsAuthError(error: unknown): boolean {
    return error instanceof HttpError && error.status === 401
}
