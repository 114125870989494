import { assets } from '@merchant/shared/assets/nameMap'
import { EllipsisMiddle, InlineLinkButton, AppSvg, CopyButton } from '@merchant/shared/components'
import { Button, Flex, Popconfirm, Space, Tooltip, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang as commonLang } from '../../lang'
import { callbackKeys } from './const'
import { lang } from './lang'
import type { Breakpoints } from '@merchant/shared/hooks'
import type { GlobalToken } from '@merchant/ui-kit/ant-design'
import type { ColumnsType } from '@merchant/ui-kit/ant-design/es/table'
import type { TextProps } from '@merchant/ui-kit/ant-design/es/typography/Text'
import type { Callback } from '~api/instances/cabinet-api'
import { NoValueColumnContent } from '~components'

const { Text, Paragraph } = Typography

type GetColumns = (arg: {
    onDeleteClick: () => void
    onEditClick: () => void
    onRegenerateClick: () => void
    token: GlobalToken
    breakpoints: Breakpoints
    isIntegrationEditAllowed: boolean
}) => ColumnsType<Callback>

// eslint-disable-next-line max-lines-per-function
export const getColumns: GetColumns = ({
    onDeleteClick,
    onEditClick,
    onRegenerateClick,
    token,
    breakpoints,
    isIntegrationEditAllowed,
}) => {
    const getUrlContent = (url: Callback['url']) =>
        url ? (
            <Paragraph
                data-merchant="callback-url"
                ellipsis={{
                    rows: 2,
                    tooltip: {
                        title: url,
                        placement: 'bottom',
                        'data-merchant': 'callback-url-tooltip',
                    },
                }}
                style={{ margin: 0 }}
            >
                {url}
            </Paragraph>
        ) : (
            NoValueColumnContent
        )
    const getPublicKeyContent = (publicKey: Callback['publicKey'], type?: TextProps['type']) => {
        const prefixCount = !breakpoints.md ? 7 : 16
        const suffixCount = !breakpoints.md ? 6 : 16

        return (
            <Flex align="center" gap={4}>
                {publicKey ? (
                    <Space align="center" size={4}>
                        <Tooltip
                            placement="bottom"
                            title={<span data-merchant="callback-public-key-tooltip-title">{publicKey}</span>}
                            overlayStyle={{ maxWidth: 420 }}
                            data-merchant="callback-public-key-tooltip"
                        >
                            <EllipsisMiddle
                                data-merchant={`${publicKey}-callback-public-key`}
                                type={type}
                                divider="."
                                prefixCount={prefixCount}
                                suffixCount={suffixCount}
                            >
                                {publicKey}
                            </EllipsisMiddle>
                        </Tooltip>
                        <CopyButton
                            style={{ width: undefined, height: undefined }}
                            type="text"
                            size="small"
                            data-merchant="callback-public-key-copy-button"
                            inputValue={publicKey}
                            notificationContentSubject={lang.callbackPublicKey}
                        />
                    </Space>
                ) : (
                    NoValueColumnContent
                )}
                <Popconfirm
                    data-merchant="callback-public-key-regenerate-confirm"
                    title={
                        <span data-merchant="callback-public-key-regenerate-confirm-title">
                            <FormattedMessage {...lang.regenerateCallbackKeyTitle} />
                        </span>
                    }
                    description={<FormattedMessage {...lang.regenerateCallbackKeyDescription} />}
                    onConfirm={onRegenerateClick}
                    okButtonProps={{
                        'data-merchant': 'callback-public-key-regenerate-confirm-ok',
                    }}
                    cancelButtonProps={{
                        'data-merchant': 'callback-public-key-regenerate-confirm-cancel',
                    }}
                >
                    <Button
                        disabled={!isIntegrationEditAllowed}
                        size="small"
                        data-merchant="callback-public-key-regenerate-button"
                        type="text"
                        icon={<AppSvg size={16} name={assets.refreshCw} color={token.colorTextPlaceholder} />}
                    />
                </Popconfirm>
            </Flex>
        )
    }

    return [
        {
            title: <FormattedMessage {...lang.callbackUrl} />,
            hidden: !breakpoints.lg,
            key: callbackKeys.url,
            dataIndex: callbackKeys.url,
            width: 455,
            render: getUrlContent,
        },
        {
            title: <FormattedMessage {...lang.callbackPublicKey} />,
            hidden: !breakpoints.lg,
            onCell: ({ url }) =>
                ({
                    'data-merchant': `${url}-callback-public-key`,
                }) as React.HTMLAttributes<HTMLElement>,
            key: callbackKeys.publicKey,
            dataIndex: callbackKeys.publicKey,
            render: value => getPublicKeyContent(value),
        },
        {
            title: (
                <>
                    <FormattedMessage {...lang.callbackUrl} /> & <FormattedMessage {...lang.callbackPublicKey} />
                </>
            ),
            hidden: breakpoints.lg,
            key: `${callbackKeys.url}&${callbackKeys.publicKey}`,
            render: (_, record) => (
                <Flex vertical>
                    {getUrlContent(record.url)}
                    <Text data-merchant={null} type="secondary">
                        {getPublicKeyContent(record.publicKey, 'secondary')}
                    </Text>
                </Flex>
            ),
        },
        {
            title: <FormattedMessage {...commonLang.actions} />,
            key: 'actions',
            render: () => (
                <Space styles={{ item: { width: 20 } }}>
                    <InlineLinkButton
                        disabled={!isIntegrationEditAllowed}
                        data-merchant="callback-url-edit-button"
                        onClick={onEditClick}
                        style={{ verticalAlign: 'middle' }}
                        icon={<AppSvg size={20} name={assets.edit} />}
                    />
                    <InlineLinkButton
                        disabled={!isIntegrationEditAllowed}
                        data-merchant="callback-url-delete-button"
                        onClick={onDeleteClick}
                        style={{ verticalAlign: 'middle' }}
                        icon={<AppSvg size={20} name={assets.trash} />}
                    />
                </Space>
            ),
            align: 'right',
        },
    ]
}
