import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    addMembersSubmitButton: {
        id: 'team-management.modal.add_members_submit_button',
        defaultMessage: 'Add',
    },
    deleteMemberSubmitButton: {
        id: 'team-management.modal.delete_member_submit_button',
        defaultMessage: 'Delete',
    },
    editMemberSubmitButton: {
        id: 'team-management.modal.edit_member_submit_button',
        defaultMessage: 'Edit',
    },
    editPermissionsSubmitButton: {
        id: 'team-management.modal.edit_permissions_submit_button',
        defaultMessage: 'Confirm',
    },
})
