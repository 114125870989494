import { formatFilterRequestDates } from '@merchant/shared/utils'
import dayjs from 'dayjs'
import { useState } from 'react'
import type { WithdrawalsFilterFormData, WithdrawalsFilterFormDataQuery } from './types'
import type { Dayjs } from 'dayjs'
import type { ListProjectWithdrawals200Response, ListProjectWithdrawalsRequest, WithdrawalThumb } from '~api'
import { WithdrawalsSorting, projectApi, useProject } from '~api'
import { apiRestKeys } from '~api/swr/keys'
import { useInfiniteFetcher } from '~api/swr/useInfiniteFetcher'
import { useQueryNavigation } from '~hooks'
import { getTruthyFields } from '~utils'

// eslint-disable-next-line max-lines-per-function
export function useData() {
    const [sortColumnKey, setSortColumnKey] = useState<WithdrawalsSorting>(WithdrawalsSorting.CreatedAt)
    const { data: project } = useProject()

    const { filters, onSubmit, onClear } = useQueryNavigation<
        Partial<WithdrawalsFilterFormData>,
        Partial<WithdrawalsFilterFormDataQuery>
    >({
        filters: [
            'state',
            'origin',
            'id',
            'creditCurrency',
            'receiveCurrency',
            'receiveNetwork',
            'address',
            'addressTag',
            'note',
            'orderId',
            'createAtFrom',
            'createAtTo',
            'updateAtFrom',
            'updateAtTo',
        ],
        beforeWrite: {
            // TODO: get types right later
            createdDate: date => {
                if (!Array.isArray(date)) {
                    return
                }

                const { dateFrom, dateTo } = formatFilterRequestDates(date)

                return {
                    createAtFrom: dateFrom,
                    createAtTo: dateTo,
                }
            },
            updatedDate: date => {
                if (!Array.isArray(date)) {
                    return
                }

                const { dateFrom, dateTo } = formatFilterRequestDates(date)

                return {
                    updateAtFrom: dateFrom,
                    updateAtTo: dateTo,
                }
            },
        },

        afterRead: ({
            createAtFrom = null,
            createAtTo = null,
            updateAtTo = null,
            updateAtFrom = null,
            ...restFilters
        }) => {
            const createdFrom = dayjs(createAtFrom)
            const createdTo = dayjs(createAtTo)
            const updatedFrom = dayjs(updateAtFrom)
            const updatedTo = dayjs(updateAtTo)
            const createdDate =
                createdFrom.isValid() || createdTo.isValid()
                    ? {
                          createdDate: [
                              createdFrom.isValid() ? createdFrom : null,
                              createdTo.isValid() ? createdTo : null,
                          ] satisfies [Dayjs | null, Dayjs | null],
                      }
                    : {}

            const updatedDate =
                createdFrom.isValid() || createdTo.isValid()
                    ? {
                          updatedDate: [
                              updatedFrom.isValid() ? updatedFrom : null,
                              updatedTo.isValid() ? updatedTo : null,
                          ] satisfies [Dayjs | null, Dayjs | null],
                      }
                    : {}

            return {
                ...restFilters,
                ...createdDate,
                ...updatedDate,
            }
        },
    })

    const { amount, truthy: filtersToApply } = getTruthyFields(filters)

    const {
        data = [],
        canFetchMore,
        fetchMore,
        isLoading,
        isLoadingMore,
        isPageDataEmpty,
    } = useInfiniteFetcher<ListProjectWithdrawals200Response, WithdrawalThumb[], ListProjectWithdrawalsRequest>(
        ({ previousPageData, limit, pageKey }) => ({
            params: {
                limit,
                cursor: previousPageData?.cursorNext,
                projectId: project!.id,
                sortBy: sortColumnKey,
                ...filtersToApply,
            },
            pageKey: project ? pageKey : null,
        }),
        projectApi.listProjectWithdrawals.bind(projectApi),
        {
            limit: 10,
            dataPath: 'withdrawals',
            revalidateIfStale: true,
            pageKey: apiRestKeys.getProjectWithdrawals,
        }
    )

    return {
        withdrawals: data,
        isLoadingWithdrawals: isLoading,
        isLoadingMoreWithdrawals: isLoadingMore,
        canFetchMoreWithdrawals: canFetchMore,
        fetchMoreWithdrawals: fetchMore,
        onSubmitFilters: onSubmit,
        onClearFilters: onClear,
        activeFiltersCount: amount,
        filters,
        sortColumnKey,
        setSortColumnKey,
        isPageDataEmpty,
    }
}
