import { formatNumber } from '@merchant/shared/utils'
import { Button } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { WithdrawalFiatInformation } from '../../components'
import { Context } from '../../components/WithdrawalFiatInformation/types'
import { lang } from './lang'
import type { WithdrawalFiatFormData } from '../../types'
import { ModalNames } from '~constants/modal'
import { useRegisterDirtyFormChecker } from '~hooks'

interface Props {
    onSuccess: () => void
    withdrawalFormData: WithdrawalFiatFormData
}

export function WithdrawalFiatSummary({ onSuccess, withdrawalFormData }: Props) {
    const { amount, currency } = withdrawalFormData
    useRegisterDirtyFormChecker(ModalNames.withdrawalFiatSummary)

    return (
        <>
            <WithdrawalFiatInformation
                withdrawalFormData={withdrawalFormData}
                contextDetails={{ context: Context.Summary }}
            />
            <Button
                block
                type="primary"
                onClick={onSuccess}
                size="large"
                style={{ marginTop: 24 }}
                data-merchant="withdrawal-fiat-summary-submit-button"
            >
                <FormattedMessage
                    {...lang.withdraw}
                    values={{
                        amount: formatNumber({
                            value: amount,
                            numberFormatOptions: { minimumFractionDigits: 2 },
                        }),
                        currency,
                    }}
                />
            </Button>
        </>
    )
}
