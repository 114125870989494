import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    name: {
        id: 'team-management.table.column_name',
        defaultMessage: 'Name',
    },
    email: {
        id: 'team-management.table.column_email',
        defaultMessage: 'Email',
    },
    role: {
        id: 'team-management.table.column_role',
        defaultMessage: 'Role',
    },
    created: {
        id: 'team-management.table.column_created',
        defaultMessage: 'Created',
    },
    actions: {
        id: 'team-management.table.column_actions',
        defaultMessage: 'Actions',
    },
    noMembersYet: {
        id: 'team-management.placeholder.no_members_yet',
        defaultMessage: 'No members yet',
    },
    noPermissionsToSeeMembers: {
        id: 'team-management.placeholder.no_permissions_to_see_members',
        defaultMessage: "You don't have permission to see team members",
    },
    addMemberButton: {
        id: 'team-management.button.add_member',
        defaultMessage: 'Add member',
    },
    membersTableTitle: {
        id: 'team-management.table.members_title',
        defaultMessage: 'Members',
    },
    membersTableDescription: {
        id: 'team-management.table.members_description',
        defaultMessage: 'Manage your team members',
    },
    ownerRoleName: {
        id: 'team-management.role.owner',
        defaultMessage: 'Owner',
    },
    ownerRoleDescription: {
        id: 'team-management.role.owner_description',
        defaultMessage:
            'Role can invite multiple users to join the platform under the company’s umbrella and give them permissions that allow executing specific functions, while limiting others',
    },
    adminRoleTitle: {
        id: 'team-management.role.admin',
        defaultMessage: 'Admin',
    },
    adminRoleDescription: {
        id: 'team-management.role.admin_description',
        defaultMessage:
            'Role embodies all other roles and can perform all the same functions as the owner (meaning everything), except for making changes to the user with owner permissions.',
    },
    accountingRoleTitle: {
        id: 'team-management.role.accounting',
        defaultMessage: 'Accounting',
    },
    accountingRoleDescription: {
        id: 'team-management.role.accounting_description',
        defaultMessage: 'Role allows accessing monthly invoices and view orders',
    },
    developerRoleTitle: {
        id: 'team-management.role.developer',
        defaultMessage: 'Developer',
    },
    developerRoleDescription: {
        id: 'team-management.role.developer_description',
        defaultMessage: 'Role allows to create, edit and make all the other changes to merchant tools',
    },
    supportRoleTitle: {
        id: 'team-management.role.support',
        defaultMessage: 'Support',
    },
    supportRoleDescription: {
        id: 'team-management.role.support_description',
        defaultMessage: 'Role allows to create and view support tickets, as well as view orders',
    },
    userNotRegistered: {
        id: 'team-management.table.user_not_registered',
        defaultMessage: 'The user hasn’t yet registered',
    },
    resendEmailAgain: {
        id: 'team-management.table.resend_email_again',
        defaultMessage: 'Resend the email again',
    },
    deleteClickConfirm: {
        id: 'team-management.table.delete_click_confirm',
        defaultMessage: 'Are you sure you want to delete this member?',
    },
    deleteClickConfirmOkButton: {
        id: 'team-management.table.delete_click_confirm_ok_button',
        defaultMessage: 'Confirm',
    },
    deleteClickConfirmCancelButton: {
        id: 'team-management.table.delete_click_confirm_cancel_button',
        defaultMessage: 'No',
    },
    ownerNotRemovable: {
        id: 'team-management.table.owner_not_removable',
        defaultMessage:
            'Owner cannot be removed. If you want to change the name, you can do it in the <link>General settings</link>.',
    },
    changesSavedNotification: {
        id: 'team-management.notification.changes_saved',
        defaultMessage: 'Changes have been saved',
    },
    projectIdNotDefined: {
        id: 'team-management.error.project_id_not_defined',
        defaultMessage: 'Project ID is not defined',
    },
    projectMemberNotDefined: {
        id: 'team-management.error.project_member_not_defined',
        defaultMessage: 'Project member is not defined',
    },
    editRolesButton: {
        id: 'team-management.button.edit_roles',
        defaultMessage: 'Edit roles',
    },
    showRolesButton: {
        id: 'team-management.button.show_roles',
        defaultMessage: 'Show roles',
    },
})
