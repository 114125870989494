import { Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import type { MenuItemType } from '@merchant/ui-kit/ant-design/es/menu/interface'
import type { TernaryDarkMode } from 'usehooks-ts'
import { assets } from '~assets/nameMap'
import { AppSvg } from '~components'

const { Text } = Typography

export const themesMap: Record<TernaryDarkMode, NonNullable<Omit<MenuItemType, 'key'> & { key: TernaryDarkMode }>> = {
    system: {
        key: 'system',
        icon: <AppSvg size={20} name={assets.themeAuto} />,
        label: (
            <Text data-merchant="color-mode-system">
                <FormattedMessage {...lang.system} />
            </Text>
        ),
    },
    light: {
        key: 'light',
        icon: <AppSvg size={20} name={assets.sun} />,
        label: (
            <Text data-merchant="color-mode-light">
                <FormattedMessage {...lang.light} />
            </Text>
        ),
    },
    dark: {
        key: 'dark',
        icon: <AppSvg size={20} name={assets.moon} />,
        label: (
            <Text data-merchant="color-mode-dark">
                <FormattedMessage {...lang.dark} />
            </Text>
        ),
    },
} as const
