import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    fiatNotPermittedText: {
        id: 'withdrawal-fiat-prohibited.typography.fiat_not_permitted_text',
        defaultMessage:
            'Action not permitted. Please contact <emailLink>support</emailLink> or your personal account manager for further assistance.',
    },
    contactSupport: {
        id: 'withdrawal-fiat-prohibited.button.contact_support',
        defaultMessage: 'Contact Support',
    },
    okUnderstood: {
        id: 'withdrawal-fiat-prohibited.button.ok_understood',
        defaultMessage: 'Ok, understood',
    },
})
