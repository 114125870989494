import { RestrictionAlertType } from './types'
import { useHoldingCurrencies } from '~api'
import { useUserPermissionContext } from '~contexts'

export const useRestrictionAlertType = () => {
    const { isUserPermissionInitialized } = useUserPermissionContext()
    const { data, isLoading } = useHoldingCurrencies()

    let restrictionType: RestrictionAlertType | undefined
    if (!isLoading && !data?.some(({ selected }) => selected)) {
        restrictionType = RestrictionAlertType.NotConfiguredCurrencySettings
    }

    return {
        type: restrictionType,
        isLoading: isLoading || !isUserPermissionInitialized,
    }
}
