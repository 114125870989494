import type { ActionModalType } from './types'
import type { KeyedMutator } from 'swr'
import type { ProjectTeamMember } from '~api/instances/cabinet-api'

export function processActionModalSuccess(
    mutateTeamManagement: KeyedMutator<ProjectTeamMember[]>,
    modalType: ActionModalType,
    member: ProjectTeamMember | undefined
) {
    mutateTeamManagement(
        prevData => {
            if (!prevData) {
                return prevData
            }

            switch (modalType) {
                case 'delete':
                    return prevData.filter(({ id }) => id !== member?.id)
                case 'edit':
                    return prevData.map(item => (item.id === member?.id ? member : item))
                default:
                    return prevData
            }
        },
        { revalidate: false }
    )
}
