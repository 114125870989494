import { useReadLocalStorage } from 'usehooks-ts'
import { getIsCurrentStepLoading } from './utils'
import { useApiKeys, useHoldingCurrencies, usePayments, useProject, useProjectCallback } from '~api'
import { paymentsSettingsPageIsVisitedKey } from '~constants/localStorage'

export const useCurrentStep = () => {
    const { data: project, isLoading: isLoadingProject } = useProject()
    const { data: apiKeys, isLoading: isLoadingApiKeys } = useApiKeys()
    const { data: callback, isLoading: isLoadingCallback } = useProjectCallback()
    const isPaymentSettingsVisited = useReadLocalStorage(paymentsSettingsPageIsVisitedKey)
    const { hasSelectedHoldingCurrency } = useHoldingCurrencies()

    const { data: payments, isLoading: isPaymentsLoading } = usePayments()

    const isLoadingStep = getIsCurrentStepLoading([
        isLoadingCallback,
        isLoadingApiKeys,
        isLoadingProject,
        isPaymentsLoading,
    ])

    if (payments?.payments.length) {
        return { step: 5, isLoadingStep }
    }

    if (callback !== undefined && apiKeys?.length) {
        return { step: 4, isLoadingStep }
    }

    if (isPaymentSettingsVisited && hasSelectedHoldingCurrency && project) {
        return { step: 2, isLoadingStep }
    }

    if (project) {
        return { step: 1, isLoadingStep }
    }

    return { step: 0, isLoadingStep }
}
