import { useDebouncedState } from '@merchant/shared/hooks'
import { filter, isEmpty, some } from 'lodash-es'
import { useState, useMemo } from 'react'
import { useBoolean } from 'usehooks-ts'
import type { WhitelistWithKey } from '~api/swr/rest/types'
import { useWhitelist, useCurrencies } from '~api'
import { Permission } from '~api/instances/cabinet-api'
import { useUserPermissionContext } from '~contexts'
import { useNetworks } from '~hooks'

export function useData() {
    const { checkUserPermission } = useUserPermissionContext()
    const { data: { addresses = [] } = {} } = useWhitelist()
    const { data: currencies } = useCurrencies()
    const { value: isAddModalOpen, setTrue: openAddModal, setFalse: closeAddModal } = useBoolean(false)
    const { value: isDeleteModalOpen, setTrue: openDeleteModal, setFalse: closeDeleteModal } = useBoolean(false)
    const [selectedWhitelist, setSelectedWhitelist] = useState<WhitelistWithKey | null>(null)
    const [searchValue, setSearchValue] = useDebouncedState('')

    const { networks: allNetworks } = useNetworks()

    const filteredData = useMemo(() => {
        const lowerSearchValue = searchValue.toLowerCase()

        return filter(addresses, address =>
            some(Object.values(address), value => value?.toString().toLowerCase().includes(lowerSearchValue))
        )
    }, [searchValue, addresses])

    const isEditWhitelistAllowed = checkUserPermission(Permission.EditWithdrawalWhitelist)

    const handleDeleteClick = (item: WhitelistWithKey) => {
        if (!isEditWhitelistAllowed) {
            return
        }

        setSelectedWhitelist(item)
        openDeleteModal()
    }

    return {
        addresses,
        currencies,
        filteredData,
        handleDeleteClick,
        isAddModalOpen,
        isDeleteModalOpen,
        openAddModal,
        selectedWhitelist,
        setSelectedWhitelist,
        setSearchValue,
        closeAddModal,
        closeDeleteModal,
        allNetworks,
        isEditWhitelistAllowed,
        isWhitelistEmpty: isEmpty(addresses),
    }
}
