import { Typography } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import type { EllipsisConfig } from '@merchant/ui-kit/ant-design/es/typography/Base'
import type { CurrenciesBase } from '~types'
import { globalLang } from '~globalLang'
import { getExplorerLink } from '~utils'

interface Props<C extends CurrenciesBase> extends React.HTMLAttributes<HTMLElement> {
    hash: string | undefined
    currencyCode: string | undefined
    networkCode?: string
    currencies: C | undefined
    ellipsis?: EllipsisConfig | boolean
    isLink?: boolean
    shouldShowPrefix?: boolean
}

export function TxHash<C extends CurrenciesBase>({
    currencies,
    currencyCode,
    hash,
    ellipsis,
    networkCode,
    isLink = true,
    shouldShowPrefix = false,
    ...rest
}: Props<C>) {
    const explorerLink = getExplorerLink({ currencies, currencyCode, hash, networkCode })

    const prefix = shouldShowPrefix ? (
        <>
            <FormattedMessage {...globalLang.hash} />
            :&nbsp;
        </>
    ) : null

    if (explorerLink && isLink) {
        return (
            <Typography.Link
                data-merchant={`${hash}-view-in-explorer-link`}
                underline
                ellipsis={Boolean(ellipsis)}
                href={explorerLink}
                target="_blank"
                {...rest}
            >
                {prefix}
                {hash}
            </Typography.Link>
        )
    }

    return (
        <Typography.Text data-merchant={`${hash}-view-in-explorer-text`} ellipsis={ellipsis} {...rest}>
            {prefix}
            <span style={{ wordBreak: 'break-all' }}>{hash}</span>
        </Typography.Text>
    )
}
