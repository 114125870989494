import { useDebouncedState } from '@merchant/shared/hooks'
import { getCurrencyMinPrecision, moveItemToBeginning } from '@merchant/shared/utils'
import { Form } from '@merchant/ui-kit/ant-design'
import Big from 'big.js'
import { isEmpty } from 'lodash-es'
import { usePostHog } from 'posthog-js/react'
import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useBoolean } from 'usehooks-ts'
import { convertCaptureEventParams, PosthogConvertEvents } from '../../posthog'
import {
    getAvailableCurrenciesToSwapTo,
    getCurrenciesSwitched,
    getDefaultSourceCurrency,
    getDefaultTargetCurrency,
    getOrderedBalancesAndBalancesMap,
    getUnorderedConvertCurrencies,
    processSwapRateResponse,
} from './utils'
import type { ConvertCurrenciesData, SwapModalPermissions } from './types'
import type { ConvertData } from '../../types'
import { useBalances, useCurrencies, useHoldingCurrencies, useMerchant, useProject, useSwapRate } from '~api'
import { Permission, type SwapRequest } from '~api/instances/cabinet-api'
import { QueryParams } from '~constants/routes'
import { useUserPermissionContext } from '~contexts'
import { getPrecisionCutValue, currenciesCommonSort } from '~utils'

const RATE_REFRESH_INTERVAL = 30_000

interface Params {
    swapRequest: (data: SwapRequest) => void
    initialSendAmount: string
}

// eslint-disable-next-line max-lines-per-function, complexity
export const useData = ({ swapRequest, initialSendAmount = '' }: Params) => {
    const { data: { id: projectId = '' } = {} } = useProject()
    const { data: { id: merchantId = '' } = {} } = useMerchant()
    const posthog = usePostHog()
    const { checkUserPermission } = useUserPermissionContext()
    const [searchParams, setSearchParams] = useSearchParams()
    const [sendAmount, setDebouncedSendAmount, setSendAmount, isWaitingSendAmountSetter] = useDebouncedState<
        string | undefined
    >(initialSendAmount)
    const { value: isConvertInitialized, setTrue: setConvertInitialized } = useBoolean(false)
    const [{ sourceCurrency, targetCurrency }, setCurrencies] = useState<{
        sourceCurrency: null | string
        targetCurrency: null | string
    }>({ sourceCurrency: null, targetCurrency: null })

    const swapModalPermissions: SwapModalPermissions = {
        [Permission.CommitSwaps]: checkUserPermission(Permission.CommitSwaps),
        [Permission.SeeBalance]: checkUserPermission(Permission.SeeBalance),
    }

    const [form] = Form.useForm()

    const { value: sourceValue }: ConvertData['send'] = Form.useWatch('send', form) || {}
    const { data: holdingCurrencies, isLoading: isLoadingHoldingCurrencies } = useHoldingCurrencies()

    const accumulationCurrency = holdingCurrencies?.[0]?.code

    const { data: balances = [], isLoading: isLoadingBalances } = useBalances()
    const { data: currenciesMap, isLoading: isLoadingCurrencies } = useCurrencies()

    const sendPrecision = getCurrencyMinPrecision(sourceCurrency, currenciesMap)
    const sendMinAmount = sourceCurrency
        ? new Big(currenciesMap?.[sourceCurrency]?.minimumSwapAmount || 0).round(sendPrecision, Big.roundUp).toString()
        : null

    const isSendAmountBelowMinimum = Number(sendAmount) ? Number(sendAmount) < Number(sendMinAmount) : false

    const { balanceAmountMap, balancesList } = useMemo<ConvertCurrenciesData>(() => {
        if (currenciesMap) {
            return swapModalPermissions.see_balance
                ? getOrderedBalancesAndBalancesMap(currenciesMap, balances, sourceCurrency)
                : getUnorderedConvertCurrencies(currenciesMap, sourceCurrency)
        }

        return {
            balanceAmountMap: {},
            balancesList: [],
        }
    }, [balances, currenciesMap, sourceCurrency, swapModalPermissions.see_balance])

    const handleSwapRateResponse = processSwapRateResponse({
        form,
        posthog,
        sendAmount,
        sourceCurrency,
        targetCurrency,
        merchantId,
        projectId,
    })

    const {
        data: swapRate,
        isLoading: isLoadingSwapRate,
        isValidating: isValidatingSwapRate,
        error: swapRateError,
    } = useSwapRate(
        {
            shouldFetch: Boolean(sourceCurrency && targetCurrency && Number(sendAmount) && !isSendAmountBelowMinimum),
            getKey: key => ({
                key,
                from: sourceCurrency,
                to: targetCurrency,
                amount_from: String(sendAmount),
            }),
            params: {
                from: sourceCurrency!,
                to: targetCurrency!,
                amountFrom: String(sendAmount),
            },
        },
        {
            revalidateIfStale: true,
            refreshInterval: RATE_REFRESH_INTERVAL,
            onError: error => handleSwapRateResponse(error),
            onSuccess: response => handleSwapRateResponse(response),
        }
    )

    const swapCurrencies = () => {
        if (sourceCurrency && targetCurrency && sendAmount && swapModalPermissions.commit_swaps) {
            posthog.capture(
                ...convertCaptureEventParams[PosthogConvertEvents.convertSubmit]({
                    amount_from: sendAmount,
                    amount_to: swapRate?.amountTo || '',
                    currency_from: sourceCurrency,
                    currency_to: targetCurrency,
                    merchant_id: merchantId,
                    project_id: projectId,
                })
            )

            return swapRequest({
                currencyFrom: sourceCurrency,
                currencyTo: targetCurrency,
                amountFrom: String(sendAmount),
            })
        }
    }

    const targetBalanceOptions = useMemo(() => {
        const options = getAvailableCurrenciesToSwapTo(currenciesMap, sourceCurrency, balancesList)

        const sorted = currenciesCommonSort(options, currenciesMap, balancesList)

        return moveItemToBeginning(sorted, b => b.currency === targetCurrency)
    }, [currenciesMap, sourceCurrency, balancesList, targetCurrency])

    const sourceBalanceAmount = getPrecisionCutValue({
        value: (sourceCurrency && balanceAmountMap[sourceCurrency]?.amount) || 0,
        precision: sendPrecision,
        roundingMode: Big.roundDown,
    })

    // eslint-disable-next-line complexity
    useEffect(() => {
        const isDataReadyForInitialization = !isEmpty(balancesList) && !isEmpty(currenciesMap) && accumulationCurrency
        if (isConvertInitialized || !isDataReadyForInitialization) {
            return
        }
        const sourceCurrencyFromQuery = searchParams.get(QueryParams.fromCurrency)
        const targetCurrencyFromQuery = searchParams.get(QueryParams.toCurrency)
        if (!isEmpty(balancesList) && !isEmpty(currenciesMap) && accumulationCurrency) {
            const isValidSourceCurrency =
                sourceCurrencyFromQuery !== null &&
                sourceCurrencyFromQuery in currenciesMap &&
                !isEmpty(currenciesMap[sourceCurrencyFromQuery]?.swappableTo)
            const initialSourceCurrencyCode = isValidSourceCurrency
                ? currenciesMap[sourceCurrencyFromQuery]?.code || ''
                : getDefaultSourceCurrency(balances, balancesList, accumulationCurrency).code || ''

            const initialTargetCurrencyCode = getDefaultTargetCurrency({
                currenciesMap,
                sourceCurrency: initialSourceCurrencyCode,
                accumulationCurrency,
                prevSelectedTarget: targetCurrencyFromQuery,
                sortedBalances: balancesList,
            })

            searchParams.set(QueryParams.fromCurrency, initialSourceCurrencyCode)
            if (initialTargetCurrencyCode) {
                searchParams.set(QueryParams.toCurrency, initialTargetCurrencyCode)
            } else {
                searchParams.delete(QueryParams.toCurrency)
            }
            setSearchParams(searchParams)
            setCurrencies({
                sourceCurrency: initialSourceCurrencyCode,
                targetCurrency: initialTargetCurrencyCode,
            })
            setConvertInitialized()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [balancesList, balances, accumulationCurrency])

    const switchSourceAndTarget = () => {
        const { source, target } = getCurrenciesSwitched(currenciesMap, sourceCurrency, targetCurrency)
        setCurrencies({ sourceCurrency: source, targetCurrency: target })
        source && searchParams.set(QueryParams.fromCurrency, source)
        target && searchParams.set(QueryParams.toCurrency, target)
        setSearchParams(searchParams, { replace: false })
    }

    const handleSourceCurrencyChange = (currency: string | null) => {
        if (!currency) {
            return
        }
        const currentReceiveCurrency = targetCurrency
        if (currentReceiveCurrency === currency) {
            return switchSourceAndTarget()
        }
        setCurrencies(prev => ({ ...prev, sourceCurrency: currency }))
        searchParams.set(QueryParams.fromCurrency, currency)

        const canConvertToCurrentCurrency =
            currentReceiveCurrency && currenciesMap?.[currency]?.swappableTo.includes(currentReceiveCurrency)
        if (!canConvertToCurrentCurrency) {
            const newReceiveCurrency = getDefaultTargetCurrency({
                currenciesMap,
                sourceCurrency: currency,
                accumulationCurrency,
            })
            setCurrencies(prev => ({
                ...prev,
                targetCurrency: newReceiveCurrency,
            }))
            if (newReceiveCurrency) {
                searchParams.set(QueryParams.toCurrency, newReceiveCurrency)
            } else {
                searchParams.delete(QueryParams.toCurrency)
            }
        }
        setSearchParams(searchParams)
    }

    const handleTargetCurrencyChange = (currency: string | null) => {
        const currentSourceCurrency = sourceCurrency

        if (!currency) {
            return
        }
        if (currentSourceCurrency === currency) {
            return switchSourceAndTarget()
        }

        setCurrencies(prev => ({ ...prev, targetCurrency: currency }))
        searchParams.set(QueryParams.toCurrency, currency)
        setSearchParams(searchParams)
    }

    const onAmountClick = (amount: string | number | null) => {
        if (Big(amount || '0').eq(sendAmount || '0')) {
            return
        }
        const formValue: ConvertData = form.getFieldsValue()
        const cutValue = getPrecisionCutValue({
            value: amount,
            precision: sendPrecision,
        })
        formValue.send.value = cutValue
        formValue.receive.value = ''
        form.setFieldsValue(formValue)
        setSendAmount(cutValue)
    }

    const handleBalanceButtonClick = () => onAmountClick(sourceBalanceAmount)
    const minimumAmountButtonClick = () => onAmountClick(sendMinAmount)

    useEffect(() => {
        if (sourceValue) {
            setDebouncedSendAmount(sourceValue)
        }
        if (Number(sourceValue) === 0) {
            form.setFieldValue(['receive', 'value'], '')
        }
    }, [form, setDebouncedSendAmount, sourceValue])

    const isInsufficientFunds = swapModalPermissions.see_balance && Number(sendAmount) > Number(sourceBalanceAmount)
    const isConvertible =
        !isLoadingCurrencies && !isLoadingBalances && sourceCurrency !== null
            ? !isEmpty(targetBalanceOptions)
            : undefined
    const isSelectedPairSupported =
        sourceCurrency && targetCurrency
            ? currenciesMap?.[sourceCurrency]?.swappableTo.includes(targetCurrency)
            : undefined

    return {
        currencies: currenciesMap,
        swapRate: {
            request: swapCurrencies,
            isLoading: isLoadingSwapRate || isValidatingSwapRate,
            data: swapRate,
            error: swapRateError,
        },
        canSubmitConvert:
            !isLoadingSwapRate &&
            !swapRateError &&
            !isInsufficientFunds &&
            !isWaitingSendAmountSetter &&
            !isSendAmountBelowMinimum,
        onSwap: swapCurrencies,
        form,
        sendData: {
            currency: sourceCurrency,
            amount: sendAmount,
            availableFunds: sourceBalanceAmount,
            onAmountChangeDebounced: setDebouncedSendAmount,
            onCurrencyChange: handleSourceCurrencyChange,
            precision: sendPrecision,
            minimumSwapAmountInfo: {
                amount: sendMinAmount,
                isBelowMinimum: isSendAmountBelowMinimum,
                onClick: minimumAmountButtonClick,
            },
            isConvertible,
        },
        receiveData: {
            onCurrencyChange: handleTargetCurrencyChange,
            currency: targetCurrency,
            precision: getCurrencyMinPrecision(targetCurrency, currenciesMap),
        },
        isInsufficientFunds,
        isLoadingCurrencies: isLoadingBalances || isLoadingCurrencies || isLoadingHoldingCurrencies,
        isSelectedPairSupported,
        sendOptions: balancesList,
        receiveOptions: targetBalanceOptions,
        onSwitchClick: switchSourceAndTarget,
        onBalanceClick: handleBalanceButtonClick,
        swapModalPermissions,
    }
}
