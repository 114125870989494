import { Typography } from '@merchant/ui-kit/ant-design'
import { useIntl } from 'react-intl'
import { StaticPageTemplate } from '../Template'
import { lang } from './lang'
import type { ReactNode } from 'react'

const { Text, Link } = Typography

export function TermsOfUsePage() {
    const intl = useIntl()

    return (
        <StaticPageTemplate
            title={lang.termsOfUseTitle}
            content={lang.termsOfUseContent}
            values={{
                ul: (chunks: ReactNode) => <ul style={{ marginBottom: 12 }}>{chunks}</ul>,
                li: (chunks: ReactNode) => (
                    <li style={{ marginBottom: 8 }}>
                        <Text type="secondary" data-merchant={null}>
                            {chunks}
                        </Text>
                    </li>
                ),
                websiteLink: (chunks: ReactNode) => (
                    <Link
                        href={intl.formatMessage(lang.websiteLink)}
                        data-merchant="privacy-website-link"
                        target="_blank"
                    >
                        {chunks}
                    </Link>
                ),
                privacyPolicyLink: (chunks: ReactNode) => (
                    <Link
                        href={intl.formatMessage(lang.privacyPolicyLink)}
                        data-merchant="privacy-policy-link"
                        target="_blank"
                    >
                        {chunks}
                    </Link>
                ),
            }}
        />
    )
}
