/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteTOTPRequest
 */
export interface DeleteTOTPRequest {
    /**
     * 
     * @type {string}
     * @memberof DeleteTOTPRequest
     */
    verificationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteTOTPRequest
     */
    totpCode?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteTOTPRequest
     */
    totpBackup?: string;
}

/**
 * Check if a given object implements the DeleteTOTPRequest interface.
 */
// @ts-ignore
export function instanceOfDeleteTOTPRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

// @ts-ignore
export function DeleteTOTPRequestFromJSON(json: any): DeleteTOTPRequest {
    return DeleteTOTPRequestFromJSONTyped(json, false);
}

// @ts-ignore
export function DeleteTOTPRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteTOTPRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'verificationCode': !exists(json, 'verification_code') ? undefined : json['verification_code'],
        'totpCode': !exists(json, 'totp_code') ? undefined : json['totp_code'],
        'totpBackup': !exists(json, 'totp_backup') ? undefined : json['totp_backup'],
    };
}

// @ts-ignore
export function DeleteTOTPRequestToJSON(value?: DeleteTOTPRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'verification_code': value.verificationCode,
        'totp_code': value.totpCode,
        'totp_backup': value.totpBackup,
    };
}

