import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    addMembersNotificationSuccessTitle: {
        id: 'add-member.notification.success_title',
        defaultMessage: '{membersCount, plural, one {Invitation has} other {Invitations have}} been sent',
    },
    addMembersNotificationSuccessDescription: {
        id: 'add-member.notification.success_description',
        defaultMessage: 'You have invited {membersCount, plural, one {# member} other {# members}}',
    },
})
