import { useSWRConfig } from 'swr'

export const useCacheInvalidation = () => {
    const { mutate, cache } = useSWRConfig()

    const invalidate = (key: string) => {
        for (const cachedKey of cache.keys()) {
            if (cachedKey.includes(key)) {
                mutate(cachedKey)
            }
        }
    }

    return {
        invalidate,
    }
}
