import { AppCoin } from '@merchant/shared/components'
import { Col, Flex, Row, Typography } from '@merchant/ui-kit/ant-design'
import cn from 'classnames'
import { BalanceActions } from './components/BalanceActions'
import { AvailableFunds } from './components/BalanceAvailableFunds'
import styles from './style.module.css'
import { assetCodeParagraphStyles, currencyNameStyles } from './styles'
import type { ColumnsType } from '@merchant/ui-kit/ant-design/es/table'
import type { Balance } from '~api/instances/cabinet-api'
import type { Currencies } from '~api/types'

const { Text, Paragraph } = Typography

type GetColumns = (params: {
    currencies?: Currencies
    equivalentCurrencyCode: string | undefined
    isConvertAvailable: boolean
    depositEligibleCurrencies: Record<string, boolean>
}) => ColumnsType<Balance>

export const getColumns: GetColumns = ({
    currencies,
    equivalentCurrencyCode,
    isConvertAvailable,
    depositEligibleCurrencies,
}) => [
    {
        key: 'asset',
        className: cn(styles.tableCell, styles.searchInputCell),
        render: (_, record) => (
            <Row wrap={false} align="middle" gutter={12}>
                <Col style={{ display: 'inline-flex' }}>
                    <AppCoin currencyCode={record.currency} />
                </Col>
                <Col>
                    <Paragraph data-merchant={null} style={assetCodeParagraphStyles}>
                        {record.currency}
                    </Paragraph>
                    <Text data-merchant={null} type="secondary" style={currencyNameStyles}>
                        {currencies?.[record.currency]?.name ?? record.currency}
                    </Text>
                </Col>
            </Row>
        ),
    },
    {
        key: 'balance-info-action',
        align: 'right',
        className: styles.tableCell,
        render: (_, record) => (
            <Flex justify="flex-end" align="center" gap={12}>
                <AvailableFunds
                    currenciesMap={currencies}
                    data={record}
                    equivalentCurrencyCode={equivalentCurrencyCode}
                />
                <BalanceActions
                    balance={record}
                    currency={currencies?.[record.currency]}
                    isConvertAvailable={isConvertAvailable}
                    isDepositAvailable={depositEligibleCurrencies[record.currency] || false}
                />
            </Flex>
        ),
    },
]
