import type { Palette } from '@merchant/shared/themes/palette/types'
import { ProjectRoleColor } from '~api/instances/cabinet-api'

type CustomProjectRolesColor = {
    [key in Exclude<
        ProjectRoleColor,
        ProjectRoleColor.Red | ProjectRoleColor.Green | ProjectRoleColor.Orange | ProjectRoleColor.Grey
    >]: string
}

export const generateRolesColor = (palette: Palette, colors: CustomProjectRolesColor) => ({
    [ProjectRoleColor.Red]: palette.elementsDanger,
    [ProjectRoleColor.Green]: palette.elementsSuccess,
    [ProjectRoleColor.Orange]: palette.elementsWarning,
    [ProjectRoleColor.Grey]: palette.elementsNeutral,
    ...colors,
})
