import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg, InlineLinkButton } from '@merchant/shared/components'
import { useBreakpoint } from '@merchant/shared/hooks'
import { Flex, List, Typography, type GetProps } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { useBoolean } from 'usehooks-ts'
import { lang } from '../../lang'
import { listTitleStyles, getCommonFlexProps } from '../../styles'
import { EditNameModal } from './EditNameModal'
import type { Merchant } from '~api/instances/cabinet-api'
import { NoValueColumnContent } from '~components'

const { Text } = Typography

interface Props extends Omit<GetProps<typeof List.Item>, 'onChange'> {
    isLoading: boolean
    value: Merchant['name']
    onChange: (name: Merchant['name']) => Promise<void | Merchant>
}

export function NameItem({ value, isLoading, onChange, ...rest }: Props) {
    const { value: isEditModalOpen, setTrue: openEditModal, setFalse: closeEditModal } = useBoolean()
    const breakpoints = useBreakpoint()
    const commonFlexProps = getCommonFlexProps(!breakpoints.md)

    const request = async (name: Merchant['name']) => {
        await onChange(name)
        closeEditModal()
    }

    const displayNameValue = value || NoValueColumnContent

    return (
        <>
            <EditNameModal initialValue={value} open={isEditModalOpen} request={request} onCancel={closeEditModal} />
            <List.Item {...rest}>
                <Flex {...commonFlexProps} style={{ minHeight: 40 }}>
                    <List.Item.Meta
                        data-merchant="settings-name-list-item-meta"
                        title={
                            <Text style={listTitleStyles} data-merchant={null}>
                                <FormattedMessage {...lang.name} />
                            </Text>
                        }
                    />
                    <Flex gap={8}>
                        {!isLoading && displayNameValue}
                        <InlineLinkButton
                            data-merchant="settings-name-edit-button"
                            onClick={openEditModal}
                            loading={isLoading}
                            icon={<AppSvg size={20} name={assets.edit} />}
                        />
                    </Flex>
                </Flex>
            </List.Item>
        </>
    )
}
