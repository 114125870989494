import { lang } from './lang'
import type { MessageDescriptor } from 'react-intl'

export const enum SettingsShowOptions {
    general = 'general',
    security = 'security',
}

export const pageTitleMap: Record<SettingsShowOptions, MessageDescriptor> = {
    [SettingsShowOptions.general]: lang.generalPageTitle,
    [SettingsShowOptions.security]: lang.securityPageTitle,
}

export const pages: SettingsShowOptions[] = [SettingsShowOptions.general, SettingsShowOptions.security]
