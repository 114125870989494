import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    modalTitle: {
        id: 'settings-page.modal.change_password_title',
        defaultMessage: 'Change password',
    },
    dontSharePassword: {
        id: 'settings-page.modal.dont_share_password_text',
        defaultMessage: 'Don’t keep your password in emails or cloud storages, and don’t share it with anyone.',
    },
    currentPasswordPlaceholder: {
        id: 'settings-page.input.current_password_placeholder',
        defaultMessage: 'Current password',
    },
    newPasswordPlaceholder: {
        id: 'settings-page.input.new_password_placeholder',
        defaultMessage: 'New password',
    },
    repeatNewPasswordPlaceholder: {
        id: 'settings-page.input.repeat_new_password_placeholder',
        defaultMessage: 'Repeat new password',
    },
    continue: {
        id: 'settings-page.button.continue',
        defaultMessage: 'Continue',
    },
    afterResetDisableDisclaimer: {
        id: 'settings-page.form.after_reset_disable_disclaimer',
        defaultMessage:
            'All withdrawals from your account will be suspended for 72 hours every time you change password.This is a security measure for the safety of your funds.',
    },
    passwordsAreDifferent: {
        id: 'settings-page.form.different_passwords_error',
        defaultMessage: 'Passwords are different',
    },
    passwordChanged: {
        id: 'settings-page.form.password_changed',
        defaultMessage: 'Password changed',
    },
    passwordChangedDescription: {
        id: 'settings-page.form.password_changed_description',
        defaultMessage: 'Your password has been changed successfully',
    },
})
