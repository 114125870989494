import { assets } from '@merchant/shared/assets/nameMap'
import {
    AppSvg,
    CopyButton,
    EllipsisMiddle,
    EmailSupportLink,
    LinkAsButton,
    ModalResponsive,
    StatisticFormattedNumber,
} from '@merchant/shared/components'
import { getAddressTagName, normalizeAddress } from '@merchant/shared/utils'
import {
    Divider,
    Flex,
    Tooltip,
    Typography,
    theme,
    type ModalProps,
    type GlobalToken,
    Card,
    Button,
    Skeleton,
} from '@merchant/ui-kit/ant-design'
import { capitalize, upperFirst } from 'lodash-es'
import { FormattedMessage, useIntl } from 'react-intl'
import styles from '../../style.module.css'
import { lang } from './lang'
import type { ReactNode } from 'react'
import type { MessageDescriptor } from 'react-intl'
import type { CreateCustomerAddressResponse, Network } from '~api/instances/cabinet-api'
import type { Currencies } from '~api/types'
import { useCurrencies, useHoldingCurrencies } from '~api'
import { ConversionNote, NoValueColumnContent } from '~components'
import { QueryParams, routes } from '~constants/routes'
import { AddressesShowOptions } from '~features/Addresses/const'
import { globalLang } from '~globalLang'
import { generateQuery } from '~utils'

const { Text } = Typography
const { useToken } = theme

type GetContentRows = (params: {
    data: ReturnType<typeof normalizeAddress<CreateCustomerAddressResponse>> | null
    currencies: Currencies | undefined
    network: Network | undefined
    token: GlobalToken
    convertedTo?: string
}) => {
    label: MessageDescriptor | string
    content: ReactNode
    shouldHide?: boolean
}[]

// eslint-disable-next-line max-lines-per-function
const getContentRows: GetContentRows = ({ data, currencies, network, token, convertedTo = '' }) => [
    {
        label: lang.address,
        content: (
            <Flex gap={4}>
                <EllipsisMiddle
                    data-merchant="address-created-modal-address"
                    divider="."
                    showTooltip
                    prefixCount={16}
                    suffixCount={16}
                >
                    {data?.address}
                </EllipsisMiddle>
                &nbsp;
                <CopyButton
                    data-merchant="address-created-modal-address-copy-button"
                    type="link"
                    inputValue={data?.address || ''}
                    notificationContentSubject={lang.address}
                />
            </Flex>
        ),
    },
    {
        label: upperFirst(getAddressTagName(currencies, data?.currency, data?.network)) || globalLang.addressTag,
        content: (
            <Flex gap={4}>
                <Text data-merchant="address-created-modal-address-tag">{data?.addressTag}</Text>
                <CopyButton
                    inputValue={data?.addressTag || ''}
                    data-merchant="address-created-modal-address-tag-copy-button"
                />
            </Flex>
        ),
        shouldHide: !data?.addressTag,
    },
    {
        label: lang.currency,
        content: (
            <Text style={{ fontWeight: 600 }} data-merchant={`address-created-modal-currency-${data?.currency}`}>
                {data?.currency && (
                    <span>
                        {currencies?.[data.currency]?.code}
                        &nbsp;
                        {currencies?.[data.currency]?.name}
                    </span>
                )}
            </Text>
        ),
    },
    {
        label: lang.network,
        content: network ? (
            <Text data-merchant={`address-created-modal-network-${data?.network}`}>
                {network.name} · {network.protocol}
            </Text>
        ) : (
            NoValueColumnContent
        ),
    },
    {
        label: lang.currentMinimumDeposit,
        content: (
            <Flex gap={4}>
                <StatisticFormattedNumber
                    value={data?.minimumPayinAmount}
                    suffix={data?.currency}
                    data-merchant="address-created-modal-minimum-payin-amount"
                />
                <Tooltip
                    title={
                        <FormattedMessage
                            {...lang.minimumPayinAmountTooltip}
                            values={{
                                supportLink: chunks => (
                                    <EmailSupportLink
                                        style={{
                                            fontSize: 12,
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        {chunks}
                                    </EmailSupportLink>
                                ),
                            }}
                        />
                    }
                    placement="bottom"
                    data-merchant="address-created-modal-minimum-payin-amount-tooltip"
                >
                    <Flex>
                        <AppSvg size={20} color={token.colorTextDescription} name={assets.questionMark} />
                    </Flex>
                </Tooltip>
            </Flex>
        ),
    },
    {
        label: lang.convertTo,
        content: (
            <>
                <Text data-merchant="address-created-modal-convert-to">
                    {currencies?.[convertedTo]?.code} {currencies?.[convertedTo]?.name}
                </Text>
                <Tooltip
                    placement="bottom"
                    data-merchant="address-created-modal-conversion-note-tooltip"
                    title={
                        <ConversionNote
                            currencyFrom={data?.currency || ''}
                            currencyTo={convertedTo}
                            dataMerchant="address-created"
                        />
                    }
                    overlayInnerStyle={{ width: 305, padding: '16px 16px 12px 16px' }}
                >
                    <Flex>
                        <AppSvg color={token.colorTextDescription} name={assets.questionMark} />
                    </Flex>
                </Tooltip>
            </>
        ),
        shouldHide: !convertedTo,
    },
    {
        label: lang.customerId,
        content: <Text data-merchant="address-created-modal-customer-id">{data?.customerId}</Text>,
    },
    {
        label: lang.customerEmail,
        content: (
            <Text data-merchant="address-created-modal-customer-email">
                {data?.customerEmail || NoValueColumnContent}
            </Text>
        ),
    },
    {
        label: lang.feePayer,
        content: (
            <Text data-merchant="address-created-modal-fees-payer">
                {data?.feesPayer ? capitalize(data.feesPayer) : <FormattedMessage {...lang.defaultFeesPayer} />}
            </Text>
        ),
    },
    {
        label: lang.updatedAt,
        content: (
            <Flex gap={4}>
                <Text type="secondary" data-merchant="address-created-modal-updated-time">
                    {data?.updatedAt.format('hh:mm:ss')}
                </Text>
                <Text data-merchant="address-created-modal-updated-date">{data?.updatedAt.format('DD/MM/YYYY')}</Text>
            </Flex>
        ),
        shouldHide: data?.newAddressGenerated,
    },
]

interface Props extends Omit<ModalProps, 'data-merchant'> {
    data: CreateCustomerAddressResponse | null
}

// eslint-disable-next-line max-lines-per-function, complexity
export function AddressCreatedModal({ data, onCancel, ...rest }: Props) {
    const { token } = useToken()
    const intl = useIntl()
    const { data: currencies, isLoading: isLoadingCurrencies } = useCurrencies()
    const { data: holdingCurrencies } = useHoldingCurrencies()
    const network = data?.currency
        ? currencies?.[data.currency]?.networks.find(({ code }) => data.network === code)
        : undefined
    const currency = currencies?.[data?.currency || ''] ?? { code: data?.currency, name: '' }

    const isNewAddress = data?.newAddressGenerated
    const normalizedData = data && normalizeAddress(data)
    const addressTagName =
        getAddressTagName(currencies, data?.currency, data?.network) || intl.formatMessage(globalLang.addressTag)
    const convertedToCode = holdingCurrencies?.some(({ code, selected }) => code === data?.currency && selected)
        ? undefined
        : holdingCurrencies?.[0]?.code
    const convertedToCurrency = currencies?.[convertedToCode || ''] ?? { code: convertedToCode, name: '' }

    const modalIcon = (
        <AppSvg
            size={48}
            name={isNewAddress ? assets.checkCircle : assets.alertCircle}
            color={isNewAddress ? token.colorPrimary : token.colorWarning}
        />
    )
    const modalTitle = isNewAddress ? lang.addressCreatedTitle : lang.addressExistsTitle
    const queryToStaticAddresses = `${routes.addresses}${generateQuery({ [QueryParams.show]: AddressesShowOptions.staticAddresses })}`

    return (
        <ModalResponsive
            onCancel={onCancel}
            styles={{ body: { marginTop: 24 }, footer: { marginTop: 0 } }}
            footer={
                <Flex gap={12}>
                    <LinkAsButton
                        to={queryToStaticAddresses}
                        buttonProps={{
                            block: true,
                            size: 'large',
                            'data-merchant': 'address-created-modal-all-addresses-button',
                        }}
                    >
                        <FormattedMessage {...lang.showAllAddresses} />
                    </LinkAsButton>
                    <Button
                        block
                        size="large"
                        type="primary"
                        onClick={onCancel}
                        data-merchant="address-created-modal-close-button"
                    >
                        <FormattedMessage {...lang.ok} />
                    </Button>
                </Flex>
            }
            data-merchant="address-created-modal"
            {...rest}
        >
            <Flex vertical align="center" gap={8}>
                {modalIcon}
                <Text style={{ fontWeight: 500, fontSize: 20 }} data-merchant="address-created-modal-title">
                    <FormattedMessage {...modalTitle} />
                </Text>
                <Text type="secondary" data-merchant="address-created-modal-created-at">
                    <FormattedMessage
                        {...lang.createdAt}
                        values={{
                            createdAt: (
                                <span style={{ fontWeight: 500 }}>
                                    {normalizedData?.createdAt.format('hh:mm:ss DD/MM/YYYY')}
                                </span>
                            ),
                        }}
                    />
                </Text>
            </Flex>
            <Divider style={{ marginTop: 16 }} />
            <Flex vertical gap={8}>
                <Skeleton active loading={isLoadingCurrencies} title={false} paragraph={{ rows: 8 }}>
                    {getContentRows({
                        data: normalizedData,
                        currencies,
                        network,
                        token,
                        convertedTo: convertedToCode,
                    }).map(
                        ({ label, content, shouldHide }, index) =>
                            !shouldHide && (
                                <Flex key={index} justify="space-between">
                                    <Text type="secondary" style={{ textWrap: 'nowrap' }} data-merchant={null}>
                                        {typeof label === 'string' ? label : <FormattedMessage {...label} />}
                                    </Text>
                                    <span className={styles.middleCol} />
                                    {content}
                                </Flex>
                            )
                    )}
                </Skeleton>
            </Flex>
            <Card
                style={{
                    margin: '24px 0',
                    backgroundColor: 'var(--color-row-hover)',
                }}
                styles={{ body: { padding: '16px 20px 20px' } }}
                data-merchant="address-created-modal-notes-card"
            >
                <Skeleton active loading={isLoadingCurrencies || !data} title={false} paragraph={{ rows: 5 }}>
                    <b>
                        <FormattedMessage {...lang.importantNotes} />
                    </b>
                    <ul style={{ marginBottom: 0, listStyle: 'outside' }} data-merchant="address-created-modal-notes">
                        {network && (
                            <li style={{ marginInline: 14 }}>
                                <FormattedMessage
                                    {...lang.networkNote}
                                    values={{
                                        currency: <b>{currency.name}</b>,
                                        network: <b>{network.name}</b>,
                                        protocol: <b>{network.protocol}</b>,
                                        b: chunks => <b>{chunks}</b>,
                                    }}
                                />
                            </li>
                        )}
                        {data?.addressTag && (
                            <li style={{ marginInline: 14 }}>
                                <FormattedMessage
                                    {...lang.destinationTagNote}
                                    values={{
                                        b: chunks => <b>{chunks}</b>,
                                        tagName: (
                                            <Text
                                                type="warning"
                                                style={{ fontWeight: 500 }}
                                                data-merchant="address-created-modal-address-tag-name"
                                            >
                                                {addressTagName}
                                            </Text>
                                        ),
                                    }}
                                />
                            </li>
                        )}
                        <li style={{ marginInline: 14 }}>
                            <FormattedMessage {...lang.unrecoverableTransferNote} />
                        </li>
                        {convertedToCode && (
                            <li style={{ marginInline: 14 }}>
                                <FormattedMessage
                                    {...lang.convertToNote}
                                    values={{
                                        b: chunks => <b>{chunks}</b>,
                                        highlight: chunks => (
                                            <Text type="warning" style={{ fontWeight: 500 }} data-merchant={null}>
                                                {chunks}
                                            </Text>
                                        ),
                                        currencyFromCode: currency.code,
                                        currencyFromName: currency.name,
                                        currencyToCode: convertedToCurrency.code,
                                        currencyToName: convertedToCurrency.name,
                                    }}
                                />
                            </li>
                        )}
                    </ul>
                </Skeleton>
            </Card>
        </ModalResponsive>
    )
}
