import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    twoFaCodePlaceholder: {
        id: 'twoFa.typography.form.two_fa_code_placeholder',
        defaultMessage: '2FA code',
    },
    backupCodePlaceholder: {
        id: 'twoFa.typography.form.backup_code_placeholder',
        defaultMessage: 'Backup code',
    },
    enterTwoFaCodeLabel: {
        id: 'twoFa.typography.enter_two_fa_code_label',
        defaultMessage: 'Enter the {currentMode} {postfix}',
    },
    twoFaCode: {
        id: 'twoFa.typography.two_fa_code',
        defaultMessage: '6-digit 2FA code',
    },
    fromTheAppPostfix: {
        id: 'twoFa.typography.from_the_app_postfix',
        defaultMessage: 'from the app',
    },
    backupCode: {
        id: 'twoFa.typography.backup_code',
        defaultMessage: 'backup code',
    },
})
