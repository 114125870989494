import { EmailSupportLink, NotificationMessage } from '@merchant/shared/components/index.ts'
import { useNotifications } from '@merchant/shared/contexts/index.ts'
import { usePromise } from '@merchant/shared/hooks'
import { Button, Collapse, Form, Typography, theme } from '@merchant/ui-kit/ant-design'
import { useMemo, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { AddressCreatedModal } from './components'
import { initFormValues, OrderSettingsType, staticAddressFormKeys } from './const.ts'
import { getCollapseItems } from './getCollapseItems.tsx'
import { lang } from './lang.ts'
import styles from './style.module.css'
import { useData } from './useData.ts'
import type { StaticAddressFormData } from './types.ts'
import type { ConversionNoteModalRef } from '~components'
import { projectApi } from '~api/endpoints.ts'
import { useHoldingCurrencies } from '~api/index.ts'
import { type CreateCustomerAddressResponse } from '~api/instances/cabinet-api/index.ts'
import { ConversionNote } from '~components'
import { RestrictionAlert, useRestrictionAlertType } from '~features/PaymentConstructor/components'
import { globalLang } from '~globalLang.ts'

const { Text } = Typography
const { useToken } = theme

// eslint-disable-next-line max-lines-per-function
export function StaticAddressConstructor() {
    const { token } = useToken()
    const { form, project } = useData()
    const { notification } = useNotifications()
    const [address, setAddress] = useState<CreateCustomerAddressResponse | null>(null)
    const [requestToBeSent, setRequestToBeSent] = useState<
        null | (() => Promise<void | React.SetStateAction<CreateCustomerAddressResponse | null>>)
    >(null)
    const { data: holdingCurrencies } = useHoldingCurrencies()
    const { type: restrictionType, isLoading: isLoadingRestrictionType } = useRestrictionAlertType()
    const conversionNoteModalRef = useRef<ConversionNoteModalRef>(null)
    const currency = Form.useWatch(staticAddressFormKeys.currency, form)

    const { selectedHoldingCurrencies, defaultConversionCurrency } = useMemo(() => {
        const selectedHoldingCurrencies = holdingCurrencies?.filter(({ selected }) => selected)

        return {
            selectedHoldingCurrencies,
            defaultConversionCurrency: selectedHoldingCurrencies?.[0]?.code,
        }
    }, [holdingCurrencies])

    const { send: createAddress, isLoading: isCreatingProject } = usePromise(
        ({ feesPayer, ...rest }: StaticAddressFormData) =>
            projectApi.createCustomerAddress({
                projectId: project?.id || '',
                createCustomerAddressRequest: {
                    ...rest,
                    ...(feesPayer === 'default' ? {} : { feesPayer }),
                },
            }),
        {
            onSuccess: data => {
                setRequestToBeSent(null)
                setAddress(data)
            },
        }
    )

    const onFinish = (values: StaticAddressFormData) => {
        if (restrictionType) {
            return notification.api.open({
                message: (
                    <NotificationMessage
                        type="error"
                        title={<FormattedMessage {...globalLang.somethingWentWrong} />}
                        description={
                            <FormattedMessage
                                {...globalLang.tryAgainLater}
                                values={{
                                    SupportLink: <EmailSupportLink />,
                                }}
                            />
                        }
                        dataMerchant="create-static-address-error-notification"
                    />
                ),
            })
        }
        if (selectedHoldingCurrencies?.some(({ code }) => code === values.currency)) {
            createAddress(values)
        } else {
            conversionNoteModalRef.current?.open()
            setRequestToBeSent(() => () => createAddress(values))
        }
    }

    return (
        <>
            <AddressCreatedModal open={!!address} data={address} onCancel={() => setAddress(null)} />
            {defaultConversionCurrency && (
                <ConversionNote.Modal
                    ref={conversionNoteModalRef}
                    currencyFrom={currency}
                    currencyTo={defaultConversionCurrency}
                    size="large"
                    onConfirm={requestToBeSent}
                    onReject={() => setRequestToBeSent(null)}
                    dataMerchant="create-static-address"
                />
            )}
            {/* temp solution until currency settings made public */}
            {!!restrictionType && !isLoadingRestrictionType && <RestrictionAlert type={restrictionType} />}
            <Form<StaticAddressFormData>
                scrollToFirstError={{ block: 'center', behavior: 'smooth' }}
                onFinish={onFinish}
                form={form}
                requiredMark={(label, { required }) => (
                    <div>
                        <Text data-merchant={null}>{label}</Text>
                        {!required && (
                            <Text type="secondary" style={{ opacity: 0.5 }} data-merchant={null}>
                                {' · '}
                                <FormattedMessage {...globalLang.optional} />
                            </Text>
                        )}
                    </div>
                )}
                initialValues={initFormValues}
                size="large"
                layout="vertical"
            >
                <Collapse
                    style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        borderRadius: 0,
                    }}
                    className={styles.collapse}
                    defaultActiveKey={[OrderSettingsType.BasicSettings]}
                    activeKey={OrderSettingsType.BasicSettings}
                    size="middle"
                    expandIcon={() => null}
                    accordion
                    items={getCollapseItems(token)}
                    data-merchant="static-address-constructor-collapse"
                />
                <Form.Item style={{ marginBlock: '24px 0' }}>
                    <Button
                        loading={isCreatingProject}
                        data-merchant="create-static-address-button"
                        block
                        type="primary"
                        htmlType="submit"
                        disabled={isLoadingRestrictionType || !!restrictionType}
                    >
                        <FormattedMessage {...lang.createAddress} />
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}
