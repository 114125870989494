import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    largeUnderpaymentTitle: {
        id: 'large-underpayment.modal.title',
        defaultMessage: 'Large underpayment',
    },
    largeUnderpaymentDescription: {
        id: 'large-underpayment.modal.description',
        defaultMessage:
            'You are about to set the allowed underpayment threshold to allow up to a not more than <b>{relativeAmount}% of order price</b>{absoluteValue}.',
    },
    absoluteValue: {
        id: 'large-underpayment.modal.absolute_value',
        defaultMessage: ' (and <b>max: {amount} {currency}</b>)',
    },
    largeUnderpaymentQuestion: {
        id: 'large-underpayment.modal.question',
        defaultMessage: 'This could significantly affect your revenues. Are you sure this is what you want?',
    },
    underpaymentCancelButton: {
        id: 'large-underpayment.modal.cancel',
        defaultMessage: 'No, review settings',
    },
    underpaymentConfirmButton: {
        id: 'large-underpayment.modal.confirm',
        defaultMessage: "Yes, I'm sure",
    },
})
