import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { Flex, Tooltip, Typography } from '@merchant/ui-kit/ant-design'
import dayjs from 'dayjs'
import { FormattedMessage } from 'react-intl'
import { SnapshotsTable } from './components/SnapshotsTable'
import { lang } from './lang'
import { useMerchant } from '~api'
import { PageContentContainer } from '~components'

const { Text } = Typography

export function Snapshots() {
    const { data: merchant } = useMerchant()

    const utcMidnight = dayjs.utc('00:00', 'HH:mm')
    const utcMidnightToMerchantTz = utcMidnight.tz(merchant?.timeZone)
    const isUserDaySameAsUTC = utcMidnightToMerchantTz.utcOffset() >= 0

    return (
        <PageContentContainer styles={{ body: { padding: '20px 24px' } }}>
            <Flex vertical>
                <Text data-merchant="snapshots-page-title" style={{ fontWeight: 500, fontSize: 16 }}>
                    <FormattedMessage {...lang.cardTitle} />
                </Text>
                <Flex align="center" gap={4}>
                    <Text data-merchant={null} type="secondary" style={{ fontSize: 12 }}>
                        <FormattedMessage {...lang.cardSubtitle} />
                    </Text>
                    <Tooltip
                        data-merchant="snapshots-page-timezone-tooltip"
                        overlayStyle={{ maxWidth: 360 }}
                        title={
                            <span data-merchant="snapshots-page-timezone-tooltip-text">
                                <FormattedMessage
                                    {...lang.timezoneNote}
                                    values={{
                                        time: utcMidnightToMerchantTz.format('HH:00'),
                                        timezone: merchant?.timeZone?.replaceAll('_', '\u00A0'),
                                        isSameDay: isUserDaySameAsUTC,
                                        b: chunk => <b>{chunk}</b>,
                                    }}
                                />
                            </span>
                        }
                        trigger={['click', 'hover']}
                        placement="bottom"
                    >
                        <Flex data-merchant="snapshots-page-timezone-tooltip-hint">
                            <AppSvg size={20} name={assets.questionMark} color={token => token.colorTextPlaceholder} />
                        </Flex>
                    </Tooltip>
                </Flex>
            </Flex>
            <SnapshotsTable />
        </PageContentContainer>
    )
}
