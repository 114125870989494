import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    currencyErrorMessage: {
        id: 'whitelist-page.form.currency_error_message',
        defaultMessage: 'Choose currency or set address as a universal',
    },
    universalAddressTooltip: {
        id: 'whitelist-page.form.universal_address_tooltip',
        defaultMessage:
            "This option allows using one address for all withdrawals on the same network, so you don't need to create separate ones for different currencies",
    },
    universalAddressSelectTooltip: {
        id: 'whitelist-page.form.universal_address_select_tooltip',
        defaultMessage:
            "You don't need to select a currency because you've chosen a universal address. This address automatically allows withdrawals for any coin on the selected network without specifying each currency.",
    },
    universalAddressCheckboxLabel: {
        id: 'whitelist-page.form.universal_address_checkbox_label',
        defaultMessage: 'Set as a universal address to withdraw all currencies matching its network',
    },
    networkPrefix: {
        id: 'whitelist-page.form.network_prefix',
        defaultMessage: 'Network:',
    },
    verifyNetworkAlert: {
        id: 'whitelist-page.alert.verify_network',
        defaultMessage: 'Please verify that you have chosen the correct network to avoid any potential loss of funds',
    },
    selectNetworkPlaceholder: {
        id: 'whitelist-page.form.select_network_placeholder',
        defaultMessage: 'Select a network',
    },
    selectCurrencyLabel: {
        id: 'whitelist-page.form.select_currency_label',
        defaultMessage: 'Currency',
    },
    selectCurrencyPlaceholder: {
        id: 'whitelist-page.form.select_currency_placeholder',
        defaultMessage: 'Select a currency',
    },
    selectCurrencyPlaceholderUniversal: {
        id: 'whitelist-page.form.select_currency_placeholder_universal',
        defaultMessage: 'Currency: all',
    },
    nameLabel: {
        id: 'whitelist-page.form.name_label',
        defaultMessage: 'Name',
    },
    namePlaceholder: {
        id: 'whitelist-page.form.name_placeholder',
        defaultMessage: 'Enter a name 4-40 characters, e.g. My USDT wallet',
    },
    nameLengthErrorMessage: {
        id: 'whitelist-page.form.name_length_error_message',
        defaultMessage: 'Name should be 4-40 characters',
    },
    addressLabel: {
        id: 'whitelist-page.form.wallet_address_label',
        defaultMessage: 'Wallet address',
    },
    addressPlaceholder: {
        id: 'whitelist-page.form.wallet_address_placeholder',
        defaultMessage: 'Enter address',
    },
    addAddress: {
        id: 'whitelist-page.form.add_address_button',
        defaultMessage: 'Add address',
    },
    tagNameErrorMessage: {
        id: 'whitelist-page.form.tag_name_error_message',
        defaultMessage: 'Enter {tagName} or skip',
    },
    tagNameSkipTooltip: {
        id: 'whitelist-page.form.tag_name_skip_tooltip',
        defaultMessage:
            "Only skip the {tagName} if you are certain it is not required by the receiving address. Skipping or Incorrectly filling out this field when necessary can result in the permanent loss of your asset. Note that some platforms could use different names for this identifier, such as 'Memo', 'Destination Tag', 'Digital ID', 'Label', or 'Notes'.",
    },
    skipTagNameCheckboxLabel: {
        id: 'whitelist-page.form.skip_tag_name_checkbox_label',
        defaultMessage: "Skip. I confirm that receiving address doesn't require a {tagName}",
    },
    tagNamePlaceholder: {
        id: 'whitelist-page.form.tag_name_placeholder',
        defaultMessage: 'Enter {tagName}',
    },
    addressAlreadyWhitelisted: {
        id: 'whitelist-page.form.address_whitelisted_error',
        defaultMessage: 'This address is already whitelisted',
    },
    networkSelectCurrencyTooltip: {
        id: 'whitelist-page.form.network_select_currency_tooltip',
        defaultMessage: 'To select a network, please first choose a currency or mark the address as universal.',
    },
    noNetworkForCurrencyTooltip: {
        id: 'whitelist-page.form.no_network_for_currency_tooltip',
        defaultMessage: 'You don’t need to select a network for the selected currency',
    },
    currencyAndNetwork: {
        id: 'whitelist-page.form.currency_and_network',
        defaultMessage: 'Currency & Network',
    },
})
