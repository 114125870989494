import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { Divider, Flex, Typography } from '@merchant/ui-kit/ant-design'
import { Context } from './types'
import { getContextInfo } from './utils'
import type { ContextDetails } from './types'
import type { WithdrawalFiatFormData } from '../../types'
import type { TextProps } from '@merchant/ui-kit/ant-design/es/typography/Text'

const { Text, Title } = Typography

interface WithdrawalFiatInformationHeaderProps {
    contextDetails: ContextDetails
    withdrawalFormData: WithdrawalFiatFormData
}

export function WithdrawalFiatInformationHeader({
    contextDetails,
    withdrawalFormData,
}: WithdrawalFiatInformationHeaderProps) {
    const contextInfo = getContextInfo(withdrawalFormData.currency, withdrawalFormData.amount)
    const { headerSubtitle, headerTitle, iconName } =
        contextDetails.context === Context.Summary
            ? contextInfo[Context.Summary]
            : contextInfo[Context.Finish][contextDetails.status]

    const iconColor: TextProps['type'] =
        contextDetails.context === Context.Summary || contextDetails.status === 'success' ? 'success' : 'danger'

    return (
        <>
            <Flex align="center" vertical style={{ textAlign: 'center' }} gap={12}>
                <Text data-merchant={null} type={iconColor} style={{ display: 'flex' }}>
                    <AppSvg name={assets[iconName]} size={48} />
                </Text>
                <Title data-merchant="withdrawal-fiat-information-title" level={4}>
                    {headerTitle}
                </Title>
                <Text data-merchant={null} type="secondary" style={{ fontSize: 16 }}>
                    {headerSubtitle}
                </Text>
            </Flex>
            <Divider style={{ margin: '16px 0' }} />
        </>
    )
}
