import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { Button, Skeleton } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import type { getWithdrawButtonActions } from './utils'
import { ActionButton } from '~components'
import { globalLang } from '~globalLang'
import { Actions } from '~types/actionModal'

const loadingSkeletonStyle: React.CSSProperties = { width: '100%', minWidth: 'initial' }
const loadingSkeletons = Array.from({ length: 3 }).map((_, index) => (
    <Skeleton.Button key={index} active style={loadingSkeletonStyle} block size="large" />
))

interface Props {
    loadingBalancesError: boolean
    withdrawButtonActions: ReturnType<typeof getWithdrawButtonActions>
    canSwap: boolean
    canDeposit: boolean
    isLoadingBalances: boolean
    mutateBalances: () => void
    isValidatingBalances: boolean
}

export function getActionButtons({
    loadingBalancesError,
    withdrawButtonActions,
    canSwap,
    canDeposit,
    isLoadingBalances,
    mutateBalances,
    isValidatingBalances,
}: Props) {
    if (isLoadingBalances) {
        return loadingSkeletons
    }

    if (loadingBalancesError) {
        return (
            <Button
                size="middle"
                style={{ paddingLeft: 44, paddingRight: 44 }}
                onClick={() => mutateBalances()}
                loading={isValidatingBalances}
                data-merchant="refresh-balances-button"
            >
                <FormattedMessage {...globalLang.refreshPage} />
            </Button>
        )
    }

    return (
        <>
            {canDeposit && (
                <ActionButton
                    label="deposit"
                    data-merchant="sider-deposit-button"
                    action={Actions.deposit}
                    icon={<AppSvg name={assets.arrowDownStraight} />}
                    context="menu"
                />
            )}
            {withdrawButtonActions && (
                <ActionButton
                    context="menu"
                    label="withdrawal"
                    data-merchant="sider-withdraw-button"
                    icon={<AppSvg size={16} name={assets.arrowUpStraight} />}
                    {...withdrawButtonActions}
                />
            )}
            {canSwap && (
                <ActionButton
                    context="menu"
                    label="convert"
                    data-merchant="sider-convert-button"
                    disabled={isLoadingBalances}
                    action={Actions.convert}
                    icon={<AppSvg size={16} name={assets.refreshCw} />}
                />
            )}
        </>
    )
}
