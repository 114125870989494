/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PaymentMethodType {
    DirectCrypto = 'DIRECT_CRYPTO',
    DirectFiat = 'DIRECT_FIAT',
    CryptoToCrypto = 'CRYPTO_TO_CRYPTO',
    CryptoToFiat = 'CRYPTO_TO_FIAT',
    FiatToCrypto = 'FIAT_TO_CRYPTO',
    FiatToFiat = 'FIAT_TO_FIAT'
}


// @ts-ignore
export function PaymentMethodTypeFromJSON(json: any): PaymentMethodType {
    return PaymentMethodTypeFromJSONTyped(json, false);
}

// @ts-ignore
export function PaymentMethodTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentMethodType {
    return json as PaymentMethodType;
}

// @ts-ignore
export function PaymentMethodTypeToJSON(value?: PaymentMethodType | null): any {
    return value as any;
}

