import { ModalResponsive } from '@merchant/shared/components'
import { FormattedMessage } from 'react-intl'
import { TwoFaConfirmationForm } from '../../TwoFaConfirmationForm'
import { lang } from './lang'
import type { FormInstance, ModalProps } from '@merchant/ui-kit/ant-design'
import type { APIKey, TOTPCredentials } from '~api/instances/cabinet-api'
import { projectApi, useProject } from '~api'
import { useCodeConfirmationErrorHandling } from '~features/FormItems/useCodeErrorHandling'
import { useDirtyFormCheckerControls } from '~hooks'

interface Props extends Omit<ModalProps, 'title' | 'data-merchant'> {
    onModalSuccess: () => void
    apiKeyId?: APIKey['id']
}

export function DeleteApiKeyModal({ onModalSuccess, onCancel, apiKeyId, ...rest }: Props) {
    const { data: projectData } = useProject()
    const { handleError } = useCodeConfirmationErrorHandling({
        confirmationStrategy: { '2fa': 'code' },
    })
    const { handleCancel } = useDirtyFormCheckerControls(onCancel)
    const projectId = projectData?.id || ''

    if (!apiKeyId) {
        return null
    }

    const handleSuccess = async (totps: TOTPCredentials, form: FormInstance) => {
        try {
            await projectApi.deleteAPIKey({
                apiKeyId,
                projectId,
                deleteAPIKeyRequest: totps,
            })
            onModalSuccess()
        } catch (error) {
            handleError({
                form,
                error,
            })
        }
    }

    return (
        <ModalResponsive
            data-merchant="delete-api-key-modal"
            destroyOnClose
            footer={null}
            onCancel={handleCancel}
            {...rest}
            title={
                <span data-merchant="delete-api-key-modal-title">
                    <FormattedMessage {...lang.modalTitle} />
                </span>
            }
        >
            <TwoFaConfirmationForm
                mode="delete-api-key"
                onSuccess={handleSuccess}
                labelPostfix={lang.labelPostfix}
                submitButtonTitle={lang.submitButton}
            />
        </ModalResponsive>
    )
}
