import { Flex, Skeleton } from '@merchant/ui-kit/ant-design'

export function LoadingSkeleton() {
    return (
        <Flex vertical gap={16}>
            <Flex vertical gap={4}>
                <Skeleton loading active title={{ style: { width: '30%', margin: '4px 0' } }} paragraph={false} />
                <Skeleton.Input size="large" style={{ width: '100%' }} />
            </Flex>
            <Flex vertical gap={4}>
                <Skeleton loading active title={{ style: { width: '40%', margin: '4px 0' } }} paragraph={false} />
                <Skeleton.Input size="large" style={{ width: '100%' }} />
            </Flex>
            <Flex vertical gap={4}>
                <Skeleton loading active title={{ style: { width: '60%', margin: '4px 0' } }} paragraph={false} />
                <Skeleton.Input style={{ width: '100%', height: 145 }} />
                <Skeleton paragraph={false} title={{ width: '80%', style: { margin: '2px 0' } }} />
            </Flex>
            <Flex vertical gap={4}>
                <Skeleton loading active title={{ style: { width: '30%', margin: '4px 0' } }} paragraph={false} />
                <Skeleton.Input size="large" style={{ width: '100%' }} />
                <Skeleton paragraph={false} title={{ width: '40%', style: { margin: '1.5px 0' } }} />
            </Flex>
            <Skeleton title={false} paragraph={{ rows: 2, width: '100%' }} />
            <Skeleton.Button size="large" style={{ width: '100%' }} />
        </Flex>
    )
}
