export const routes = {
    settings: '/settings',
    orders: '/orders',
    get ordersDetails() {
        return `${this.orders}/:orderID` as const
    },
    get ordersCreate() {
        return `${this.orders}/create` as const
    },
    balances: '/balances',
    get balancesSnapshots() {
        return `${this.balances}/snapshots` as const
    },
    transactions: '/transactions',
    merchant: '/merchant',
    get merchantSettings() {
        return `${this.merchant}/settings` as const
    },
    get merchantTeam() {
        return `${this.merchant}/team` as const
    },
    team: '/team',
    auth: '/auth',
    payment: '/payment',
    privacyPolicy: '/privacy-policy',
    termsOfUse: '/terms-of-use',
    amlkyc: '/aml-kyc',
    notFound: '/not-found',
    addresses: '/addresses',
    integrations: '/integrations',
    withdrawals: '/withdrawals',
    get withdrawalsDetails() {
        return `${this.withdrawals}/:withdrawalID` as const
    },
} as const

export enum QueryParams {
    action = 'action',
    fromCurrency = 'from_currency',
    toCurrency = 'to_currency',
    depositCurrency = 'deposit_currency',
    orderId = 'orderid',
    show = 'show',
    type = 'type',
    email = 'email',
    id = 'id',
}
