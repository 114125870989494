import { emailValidationRegex } from '@merchant/shared/constants'
import { Flex, Form, Input, Radio, Typography } from '@merchant/ui-kit/ant-design'
import { isEmpty } from 'lodash-es'
import { FormattedMessage, useIntl } from 'react-intl'
import { feesPayerOptions, staticAddressFormKeys } from '../../const'
import { CurrencySelect } from './components'
import { CUSTOMER_ID_MAX_LENGTH } from './const'
import { lang } from './lang'
import { useData } from './useData'
import { NetworkSelect } from '~components/NetworkSelect'
import { TypeSelectItem } from '~features/PaymentConstructor/components'
import { globalLang } from '~globalLang'

const { Text } = Typography

// eslint-disable-next-line max-lines-per-function
export function BasicSettings() {
    const intl = useIntl()
    const { isLoadingCurrencies, selectedCurrency } = useData()
    const form = Form.useFormInstance()

    return (
        <>
            <TypeSelectItem />
            <Form.Item
                name={staticAddressFormKeys.currency}
                required
                rules={[
                    {
                        required: true,
                        message: intl.formatMessage(globalLang.requiredFieldMessage),
                    },
                ]}
                label={<FormattedMessage {...lang.currency} />}
            >
                <CurrencySelect
                    style={{ width: '100%' }}
                    loading={isLoadingCurrencies}
                    placeholder={intl.formatMessage(lang.currencyPlaceholder)}
                    data-merchant="static-address-currency-select"
                    onChange={() => form.resetFields([staticAddressFormKeys.network])}
                />
            </Form.Item>
            <Form.Item
                name={staticAddressFormKeys.network}
                required
                dependencies={[staticAddressFormKeys.currency]}
                validateTrigger={['onChange', 'onBlur']}
                rules={[{ required: !isEmpty(selectedCurrency?.networks) }]}
                label={<FormattedMessage {...lang.network} />}
            >
                <NetworkSelect
                    disabled={!selectedCurrency}
                    currency={selectedCurrency}
                    placeholder={intl.formatMessage(lang.networkPlaceholder)}
                    disableLabel={lang.unavailableDeposits}
                    disablePredicate={network => !network.payinEnabled}
                    data-merchant="static-address-network-select"
                    showAlert
                    showSearch
                    getKey={network => network.code + network.name + network.protocol}
                    getLabel={network => {
                        const shouldShowProtocol = network.protocol && network.protocol !== network.name

                        return (
                            <Flex align="center">
                                <Text style={{ fontWeight: 500 }} data-merchant={null}>
                                    {network.code}
                                </Text>
                                &nbsp;
                                <Text data-merchant={null}>{network.name}</Text>
                                &nbsp;
                                {shouldShowProtocol && (
                                    <Text type="secondary" data-merchant={null}>
                                        ·&nbsp;{network.protocol}
                                    </Text>
                                )}
                            </Flex>
                        )
                    }}
                />
            </Form.Item>
            <Form.Item
                name={staticAddressFormKeys.customerId}
                required
                rules={[
                    { required: true },
                    {
                        max: CUSTOMER_ID_MAX_LENGTH,
                        message: (
                            <FormattedMessage
                                {...globalLang.maximumAllowedLengthExceeded}
                                values={{ maxLength: CUSTOMER_ID_MAX_LENGTH }}
                            />
                        ),
                    },
                ]}
                tooltip={{
                    placement: 'bottom',
                    title: intl.formatMessage(lang.customerIdTooltip),
                    'data-merchant': 'static-address-customer-id-tooltip',
                }}
                label={<FormattedMessage {...lang.customerId} />}
            >
                <Input
                    placeholder={intl.formatMessage(lang.customerId)}
                    data-merchant="static-address-customer-id-input"
                />
            </Form.Item>
            <Form.Item
                name={staticAddressFormKeys.customerEmail}
                label={<FormattedMessage {...lang.customerEmail} />}
                rules={[
                    {
                        pattern: emailValidationRegex,
                        message: intl.formatMessage(globalLang.invalidEmailFormat),
                    },
                ]}
                normalize={value => value || undefined}
            >
                <Input
                    placeholder={intl.formatMessage(lang.customerEmail)}
                    data-merchant="static-address-customer-email-input"
                />
            </Form.Item>
            <Form.Item
                name={staticAddressFormKeys.feesPayer}
                tooltip={{
                    placement: 'bottom',
                    title: intl.formatMessage(lang.feesPayerTooltip),
                    'data-merchant': 'static-address-fees-payer-tooltip',
                }}
                required
                label={<FormattedMessage {...lang.feesPayer} />}
            >
                <Radio.Group data-merchant="static-address-fees-payer-radio-group">
                    {feesPayerOptions.map(({ label, value }) => (
                        <Radio key={value} value={value} data-merchant={`static-address-fees-payer-${value}`}>
                            <FormattedMessage {...label} />
                        </Radio>
                    ))}
                </Radio.Group>
            </Form.Item>
        </>
    )
}
