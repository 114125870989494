import { Button, Flex } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { WithdrawalFiatInformation } from '../../components'
import { Context } from '../../components/WithdrawalFiatInformation/types'
import type { FinishStatuses, WithdrawalFiatFormData } from '../../types'
import { globalLang } from '~globalLang'

interface Props {
    onSuccess: () => void
    onTryAgain: () => void
    withdrawalFiatFormData: WithdrawalFiatFormData
    status: FinishStatuses
}

export function WithdrawalFiatFinish({ onSuccess, withdrawalFiatFormData, status }: Props) {
    const isFailed = status === 'failed'

    return (
        <div style={{ marginTop: 24 }}>
            <WithdrawalFiatInformation
                withdrawalFormData={withdrawalFiatFormData}
                contextDetails={{ context: Context.Finish, status: 'success' }}
            />
            <Flex gap={12} justify="space-between" style={{ marginTop: 24 }}>
                <Button
                    block
                    type={isFailed ? 'default' : 'primary'}
                    onClick={onSuccess}
                    size="large"
                    data-merchant="withdrawal-finished-close-button"
                >
                    <FormattedMessage {...globalLang.close} />
                </Button>
                {isFailed && (
                    <Button
                        block
                        type="primary"
                        onClick={onSuccess}
                        size="large"
                        data-merchant="withdrawal-failed-close-button"
                    >
                        <FormattedMessage {...globalLang.close} />
                    </Button>
                )}
            </Flex>
        </div>
    )
}
