/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetWithdrawalFeeResponse
 */
export interface GetWithdrawalFeeResponse {
    /**
     * 
     * @type {string}
     * @memberof GetWithdrawalFeeResponse
     */
    fee: string;
}

/**
 * Check if a given object implements the GetWithdrawalFeeResponse interface.
 */
// @ts-ignore
export function instanceOfGetWithdrawalFeeResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fee" in value;

    return isInstance;
}

// @ts-ignore
export function GetWithdrawalFeeResponseFromJSON(json: any): GetWithdrawalFeeResponse {
    return GetWithdrawalFeeResponseFromJSONTyped(json, false);
}

// @ts-ignore
export function GetWithdrawalFeeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetWithdrawalFeeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fee': json['fee'],
    };
}

// @ts-ignore
export function GetWithdrawalFeeResponseToJSON(value?: GetWithdrawalFeeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fee': value.fee,
    };
}

