import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    confirm: {
        id: 'withdrawal-modal.button.confirm',
        defaultMessage: 'Confirm',
    },
    goBackAndTryAgain: {
        id: 'withdrawal-modal.notification.go_back_and_try_again',
        defaultMessage: '<back>Go back</back> and try again',
    },
})
