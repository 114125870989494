import { CheckOutlined } from '@ant-design/icons'
import { Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import styles from './style.module.css'
import type { GlobalToken, MenuProps } from '@merchant/ui-kit/ant-design'
import type { ListProjectsResponse, Merchant, Project, ProjectThumb } from '~api/instances/cabinet-api'
import type { ProjectRolesMap } from '~api/types'
import { globalLang } from '~globalLang'

const { Text, Paragraph } = Typography

const commonDropdownItemStyles: React.CSSProperties = {
    fontSize: 12,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 4,
    marginInline: 0,
    padding: 12,
    height: 'auto',
}

function getProjectRolesDisplayValue(
    projectRoles: ProjectRolesMap | undefined,
    project: ProjectThumb,
    merchantId: Merchant['id'] | undefined
) {
    const isOwner = project.ownerId === merchantId

    if (isOwner) {
        return <FormattedMessage {...globalLang.ownerRole} />
    }

    return project.roleIds?.map(roleId => projectRoles?.[roleId]?.name || roleId).join(', ')
}

type ProjectDropdownItemsGenerator = ({
    projects,
    selectedProjectId,
    token,
    projectRoles,
    merchantId,
}: {
    projects: ProjectThumb[]
    selectedProjectId: Project['id'] | null
    token: GlobalToken
    projectRoles: ProjectRolesMap | undefined
    merchantId: Merchant['id'] | undefined
}) => MenuProps['items']

const getDropdownProjectsItems: ProjectDropdownItemsGenerator = ({
    projects,
    selectedProjectId,
    token,
    projectRoles,
    merchantId,
}) =>
    projects.map(project => {
        const isSelected = project.id === selectedProjectId

        return {
            isSelected,
            label: (
                <span>
                    <Paragraph
                        data-merchant={`project-picker-menu-item-name-${project.id}`}
                        style={{
                            margin: 0,
                            fontSize: 'inherit',
                            fontWeight: isSelected ? 600 : 400,
                        }}
                    >
                        {project.name}
                    </Paragraph>
                    <Text
                        data-merchant={`project-picker-menu-item-roles-${project.id}`}
                        type="secondary"
                        ellipsis
                        style={{ fontSize: 'inherit', display: 'block' }}
                    >
                        {getProjectRolesDisplayValue(projectRoles, project, merchantId)}
                    </Text>
                </span>
            ),
            'data-merchant': `project-picker-menu-item-${project.id}`,
            key: project.id,
            style: {
                ...commonDropdownItemStyles,
                outline: !isSelected ? `1px solid ${token.colorBorder}` : 'none',
            },
        }
    })

type GetMenuProps = ({
    projects,
    selectedProjectId,
    token,
    handleMenuItemSelect,
    closePicker,
    projectRoles,
    merchantId,
}: {
    projects: ListProjectsResponse | undefined
    selectedProjectId: string
    token: GlobalToken
    handleMenuItemSelect: MenuProps['onSelect']
    closePicker: () => void
    projectRoles: ProjectRolesMap | undefined
    merchantId: Merchant['id'] | undefined
}) => MenuProps

export const getMenuProps: GetMenuProps = ({
    projects,
    selectedProjectId,
    token,
    handleMenuItemSelect,
    closePicker: closeModal,
    projectRoles,
    merchantId,
}) => ({
    'data-merchant': 'project-picker-menu',
    className: styles.menu,
    style: { border: 'none', boxShadow: 'none', padding: 0 },
    selectedKeys: [selectedProjectId],
    defaultSelectedKeys: [selectedProjectId],
    onSelect: handleMenuItemSelect,
    items: getDropdownProjectsItems({
        projects: projects?.thumbs || [],
        selectedProjectId,
        token,
        projectRoles,
        merchantId,
    }),
    selectable: true,
    onClick: closeModal,
    itemIcon: ({ isSelected }) => isSelected && <CheckOutlined style={{ marginLeft: 8, color: token.colorPrimary }} />,
})
