import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    informationModalTitle: {
        id: 'settings-page.unconfirmed-two-fa.information_modal_title',
        defaultMessage: 'Unconfirmed 2FA method',
    },
    confirmationModalTitle: {
        id: 'settings-page.unconfirmed-two-fa.confirmation_modal_title',
        defaultMessage: 'Confirmation',
    },
})
