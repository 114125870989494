import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    callbackUrlConfiguration: {
        id: 'integrations.typography.callback_url_configuration',
        defaultMessage: 'Callback URL Configuration',
    },
    callbackUrlDescription: {
        id: 'integrations.typography.callback_url_description',
        defaultMessage: 'Setup Callback URL to receive transaction updates from the merchant solution server.',
    },
    setupCallback: {
        id: 'integrations.button.setup_callback',
        defaultMessage: 'Set up Callback URL',
    },
    callbackUrl: {
        id: 'integrations.table.callback_url',
        defaultMessage: 'Callback URL',
    },
    callbackPublicKey: {
        id: 'integrations.table.callback_public_key',
        defaultMessage: 'Callback public key',
    },
    regenerateCallbackKeyTitle: {
        id: 'integrations.popconfirm.regenerate_callback_key_title',
        defaultMessage: 'Regenerate callback key',
    },
    regenerateCallbackKeyDescription: {
        id: 'integrations.popconfirm.regenerate_callback_key_description',
        defaultMessage: 'Are you sure you want to regenerate callback public key?',
    },
    deletionSuccessModalTitle: {
        id: 'delete-success.modal.title',
        defaultMessage: 'Callback URL has been deleted successfully',
    },
    sendTestCallback: {
        id: 'integrations.button.send_test_callback',
        defaultMessage: 'Send a test callback',
    },
    sendPaymentCallback: {
        id: 'integrations.button.send_payment_callback',
        defaultMessage: 'Test payment callback',
    },
    sendWithdrawalCallback: {
        id: 'integrations.button.send_withdrawal_callback',
        defaultMessage: 'Test withdrawal callback',
    },
})
