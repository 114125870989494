import dayjs from 'dayjs'
import type { DatePickerProps } from '@merchant/ui-kit/ant-design'

export const disabledTime: DatePickerProps['disabledTime'] = selectedDate => {
    const currentDate = dayjs()

    return {
        disabledHours: () =>
            selectedDate.isAfter(currentDate, 'd')
                ? []
                : [...Array(24).keys()].filter(hour => hour < currentDate.hour()),
        disabledMinutes: hour =>
            selectedDate.isAfter(currentDate, 'd')
                ? []
                : [...Array(60).keys()].filter(minute => minute < currentDate.minute() && hour === currentDate.hour()),
    }
}

export const getDefaultPickerDate = () => dayjs().add(1, 'day')
