import React from 'react'
import { CryptoWithdrawalNotes } from './CryptoWithdrawalNotes'
import { DepositNotes } from './DepositNotes'
import { FiatWithdrawalNotes } from './FiatWithdrawalNotes'
import { PaymentNotes } from './PaymentNotes'
import { TransactionSwapDetails } from './TransactionSwapDetails'
import type { Transaction } from '~api/instances/cabinet-api'
import { TransactionType } from '~api/instances/cabinet-api'
import {
    isTransactionDepositDetails,
    isTransactionPaymentDetails,
    isTransactionSwapDetails,
    isTransactionWithdrawalDetails,
} from '~features/TransactionsTable/utils'

interface Props {
    data: Transaction
    onOrderIdClick: (id: string) => void
}

export function TransactionNotes({ data, onOrderIdClick }: Props) {
    const { type, details } = data

    const views: Record<TransactionType, () => JSX.Element | null> = {
        [TransactionType.Deposit]: () => {
            if (!isTransactionDepositDetails(details)) {
                return null
            }

            return <DepositNotes details={details} />
        },
        [TransactionType.Payment]: () => {
            if (!isTransactionPaymentDetails(details)) {
                return null
            }

            return <PaymentNotes onIdClick={onOrderIdClick} details={details} origin={data.origin} />
        },
        [TransactionType.FiatWithdrawal]: () => <FiatWithdrawalNotes />,
        [TransactionType.Withdrawal]: () => {
            if (!isTransactionWithdrawalDetails(details)) {
                return null
            }

            return <CryptoWithdrawalNotes address={details.address} origin={data.origin} />
        },
        [TransactionType.Swap]: () => {
            if (!isTransactionSwapDetails(details)) {
                return null
            }

            return <TransactionSwapDetails details={details} />
        },
    }

    return views[type]() || null
}
