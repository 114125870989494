import { EmailSupportLink } from '@merchant/shared/components'
import { Alert, Button, Flex, Space, Typography } from '@merchant/ui-kit/ant-design'
import dayjs from 'dayjs'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import { SuspensionContext } from './types'
import type { CommonProps } from './types'
import type { MessageDescriptor } from 'react-intl'
import { BlocklistItemReason } from '~api/instances/cabinet-api'

const { Text } = Typography

const blockItemReasonTitle: Record<BlocklistItemReason, MessageDescriptor> = {
    [BlocklistItemReason.WhitelistEnable]: lang.whitelistEnableBlockReason,
    [BlocklistItemReason.WhitelistDisable]: lang.whitelistDisableBlockReason,
    [BlocklistItemReason.PasswordChange]: lang.passwordChangeBlockReason,
    [BlocklistItemReason.PasswordReset]: lang.passwordResetBlockReason,
    [BlocklistItemReason.WhitelistAdd]: lang.whitelistAddBlockReason,
    [BlocklistItemReason.WhitelistRemove]: lang.whitelistRemoveBlockReason,
}

const blocklistDescriptionMap: Record<SuspensionContext, MessageDescriptor> = {
    [SuspensionContext.Integration]: lang.integrationsSuspensionDescription,
    [SuspensionContext.TeamManagement]: lang.teamManagementSuspensionDescription,
    [SuspensionContext.Withdrawal]: lang.withdrawalSuspensionDescription,
}

export function ActionSuspendedContent({ onClose, expiresAt, blockReason, context }: CommonProps) {
    const expiresAtDayjs = dayjs(expiresAt)
    const reason =
        blockReason && blockReason in blockItemReasonTitle ? (
            <FormattedMessage {...blockItemReasonTitle[blockReason]} />
        ) : null

    const description = context in blocklistDescriptionMap ? blocklistDescriptionMap[context] : null

    return (
        <Space direction="vertical" size={16}>
            <Alert
                style={{ padding: '20px 24px' }}
                type="error"
                data-merchant="withdrawal-suspension-alert"
                message={
                    <Flex vertical gap={8}>
                        {reason && (
                            <Flex justify="space-between">
                                <Text data-merchant={null} style={{ fontWeight: 500, opacity: 0.8 }}>
                                    <FormattedMessage {...lang.suspensionReason} />
                                </Text>
                                <Text style={{ fontWeight: 600 }} data-merchant={`${context}-suspension-reason`}>
                                    {reason}
                                </Text>
                            </Flex>
                        )}
                        <Flex justify="space-between">
                            <Text
                                data-merchant={null}
                                style={{
                                    margin: 0,
                                    fontWeight: 500,
                                    opacity: 0.8,
                                }}
                            >
                                <FormattedMessage {...lang.suspensionExpiresAt} />
                            </Text>
                            <span>
                                <Text data-merchant={null} type="secondary" style={{ fontWeight: 600 }}>
                                    {expiresAtDayjs.format('HH:mm')}
                                </Text>
                                &nbsp;
                                <Text style={{ fontWeight: 600 }} data-merchant={`${context}-suspension-expires-at`}>
                                    {expiresAtDayjs.format('DD.MM.YYYY')}
                                </Text>
                            </span>
                        </Flex>
                    </Flex>
                }
            />
            <Text type="secondary" data-merchant={`${context}-suspension-description`}>
                {description && <FormattedMessage {...description} />}
            </Text>
            <Text data-merchant={null} type="secondary">
                <FormattedMessage
                    {...lang.suspensionSupport}
                    values={{
                        SupportLink: <EmailSupportLink />,
                    }}
                />
            </Text>

            <Button
                type="primary"
                block
                onClick={onClose}
                size="large"
                data-merchant={`${context}-suspension-confirmation-button`}
            >
                <FormattedMessage {...lang.suspensionConfirmation} />
            </Button>
        </Space>
    )
}
