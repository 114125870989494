import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    installAppTitle: {
        id: 'add-two-fa-modal.typography.install_app_title',
        defaultMessage:
            'Please, install the authenticator app on your mobile device. We recommend using {recommendedApp}',
    },
    recommendedApp: {
        id: 'add-two-fa-modal.typography.recommended_two_fa_app',
        defaultMessage: 'Google Authenticator',
    },
    scanToDownload: {
        id: 'add-two-fa-modal.typography.scan_to_download',
        defaultMessage: 'Scan to download Google Authenticator',
    },
})
