import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    amount: {
        id: 'payment-constructor.form.amount',
        defaultMessage: 'Amount',
    },
    recommendedAmount: {
        id: 'payment-constructor.form.recommended_amount',
        defaultMessage: 'Recommended amount',
    },
    recommendedAmountTooltip: {
        id: 'payment-constructor.form.recommended_amount_tooltip',
        defaultMessage:
            '(Optional) This amount will be shown to the customer to encourage them to send enough funds to get this amount. However, technically the customer will be able to send any amount (above the possible minimum deposit for the chosen payment currency) to complete the transaction.',
    },
    amountPlaceholder: {
        id: 'payment-constructor.form.amount_placeholder',
        defaultMessage: 'Amount',
    },
    currency: {
        id: 'payment-constructor.form.currency',
        defaultMessage: 'Currency',
    },
    name: {
        id: 'payment-constructor.form.name',
        defaultMessage: 'Name',
    },
    namePlaceholder: {
        id: 'payment-constructor.form.name_placeholder',
        defaultMessage: 'Name',
    },
    description: {
        id: 'payment-constructor.form.description',
        defaultMessage: 'Description',
    },
    descriptionPlaceholder: {
        id: 'payment-constructor.form.description_placeholder',
        defaultMessage: 'Description',
    },
    feeIsPaidBy: {
        id: 'payment-constructor.form.fee_is_paid_by',
        defaultMessage: 'Fee is paid by',
    },
    feeIsPaidByTooltip: {
        id: 'payment-constructor.form.fee_is_paid_by_tooltip',
        defaultMessage: 'Who will cover payment fees: clients (buyers) or merchant (your shop)',
    },
    currencyPlaceholder: {
        id: 'payment-constructor.form.currency_placeholder',
        defaultMessage: 'Select currency',
    },
    depositAmountInputTooltip: {
        id: 'payment-constructor.input.deposit_input_tooltip',
        defaultMessage:
            'For "Deposit" payments, the client determines the amount. If a specific amount is required, please change the type to "Invoice".',
    },
    expirationTimeTooltip: {
        id: 'payment-constructor.form.expiration_time_tooltip',
        defaultMessage:
            'Sets the active period for the order link. If payment isn’t made by this time, the link expires.',
    },
    expirationTimeIsTooEarly: {
        id: 'payment-constructor.form.expiration_time_is_too_early',
        defaultMessage: 'Expiration time cannot be in the past',
    },
})
