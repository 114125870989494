import { useBreakpoint } from '@merchant/shared/hooks'
import { Button, Popover, Divider, Flex, Typography } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import { PopoverTagsList } from './PopoverTagsList'
import type { CurrencyExtended } from './../types'
import type { SetState } from '@merchant/shared/types'

const { Text } = Typography

interface Props {
    isLoading: boolean
    allItemsCount: number
    addedItems: CurrencyExtended[]
    removedItems: CurrencyExtended[]
    onButtonClick: () => void
    setSelectedCurrencies: SetState<CurrencyExtended[]>
}

export function ModalFooterSaveButton({
    allItemsCount,
    addedItems,
    removedItems,
    isLoading,
    onButtonClick,
    setSelectedCurrencies,
}: Props) {
    const breakpoints = useBreakpoint()

    const saveChangesButton = (
        <Button
            data-merchant="edit-currencies-save-changes-button"
            type="primary"
            block
            size="large"
            onClick={onButtonClick}
            loading={isLoading}
        >
            <FormattedMessage {...lang.saveChanges} />
        </Button>
    )

    if (!!addedItems.length || !!removedItems.length) {
        return (
            <Popover
                data-merchant="currencies-edit-popover"
                title={
                    <>
                        <Text style={{ fontSize: 16, fontWeight: 500 }} data-merchant={null}>
                            <FormattedMessage {...lang.changes} />
                        </Text>
                        <Divider style={{ marginBlock: 4 }} />
                    </>
                }
                autoAdjustOverflow
                overlayInnerStyle={{ maxWidth: 256, minWidth: 232 }}
                content={
                    <Flex vertical gap={16}>
                        <PopoverTagsList
                            items={addedItems}
                            allItemsCount={allItemsCount}
                            setSelectedCurrencies={setSelectedCurrencies}
                            type="activated"
                        />
                        <PopoverTagsList
                            items={removedItems}
                            allItemsCount={allItemsCount}
                            setSelectedCurrencies={setSelectedCurrencies}
                            type="deactivated"
                        />
                    </Flex>
                }
                placement={!breakpoints.xl ? 'bottom' : 'right'}
            >
                {saveChangesButton}
            </Popover>
        )
    }

    return saveChangesButton
}
