import { Spin } from '@merchant/ui-kit/ant-design'
import { PageContentContainer } from '~components'

export function Spinner() {
    return (
        <>
            <PageContentContainer style={{ height: 300, display: 'grid', placeItems: 'center' }}>
                <Spin size="large" data-merchant="balances-page-loading-upper-spin" />
            </PageContentContainer>
            <PageContentContainer style={{ height: 200, display: 'grid', placeItems: 'center' }}>
                <Spin size="large" data-merchant="balances-page-loading-lower-spin" />
            </PageContentContainer>
        </>
    )
}
