import { assets } from '@merchant/shared/assets/nameMap'
import { AppCoin, AppSvg } from '@merchant/shared/components'
import { Checkbox, Col, Row, Table, Tooltip, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang as commonLang } from '../lang'
import styles from './style.module.css'
import { cloneReactNodeWithDataAttributes } from './utils'
import type { CurrencyExtended } from './../types'
import type { SetState } from '@merchant/shared/types'
import type { ColumnsType } from '@merchant/ui-kit/ant-design/es/table'

const { Text } = Typography

const getColumns = (defaultCurrency: string): ColumnsType<CurrencyExtended> => [
    {
        key: 'coin',
        render: (_, { code, name }) => (
            <Row justify="space-between" align="middle">
                <Col>
                    <AppCoin style={{ verticalAlign: 'middle', marginRight: 10 }} currencyCode={code} size="small" />
                    <Text data-merchant={`edit-currencies-table-${code}`}>{code}</Text>{' '}
                    <Text type="secondary" data-merchant={`edit-currencies-table-${name}`}>
                        {name}
                    </Text>
                </Col>
                {code === defaultCurrency && (
                    <Tooltip
                        title={
                            <Text data-merchant={null}>
                                <span style={{ fontWeight: 700 }}>
                                    <FormattedMessage {...commonLang.disableConversionCurrencyTooltipPrefix} />
                                </span>{' '}
                                <FormattedMessage {...commonLang.disableConversionCurrencyTooltip} />
                            </Text>
                        }
                        data-merchant="disable-conversion-currency-tooltip"
                    >
                        <Text
                            type="secondary"
                            style={{ display: 'flex' }}
                            data-merchant="disable-conversion-currency-tooltip-target"
                        >
                            <AppSvg size={20} name={assets.lock} />
                        </Text>
                    </Tooltip>
                )}
            </Row>
        ),
    },
    Table.SELECTION_COLUMN,
]

interface Props {
    selectedCurrencies?: CurrencyExtended[]
    setSelectedCurrencies: SetState<CurrencyExtended[]>
    defaultConvertCurrency: string
    currencies?: CurrencyExtended[]
}

export function EditCurrenciesTable({
    selectedCurrencies,
    defaultConvertCurrency,
    currencies,
    setSelectedCurrencies,
}: Props) {
    const selectedRowKeys = selectedCurrencies?.map(({ code }) => code)

    const handleTableRowClick = (code: string) => {
        setSelectedCurrencies(prev => {
            const index = prev.findIndex(currency => currency.code === code)
            if (index === -1) {
                const currency = currencies?.find(currency => currency.code === code)

                return currency ? [...prev, currency] : prev
            }

            return [...prev.slice(0, index), ...prev.slice(index + 1)]
        })
    }

    return (
        <Table
            bordered
            scroll={{ y: 350 }}
            className={styles.editCurrenciesTableWrapper}
            size="small"
            pagination={false}
            dataSource={currencies}
            columns={getColumns(defaultConvertCurrency)}
            data-merchant="edit-currencies-table"
            rowSelection={{
                preserveSelectedRowKeys: true,
                defaultSelectedRowKeys: selectedRowKeys,
                selectedRowKeys,
                // eslint-disable-next-line max-params
                renderCell(_value, record, _index, originNode) {
                    if (record.code === defaultConvertCurrency) {
                        return (
                            <Tooltip
                                destroyTooltipOnHide
                                title={
                                    <Text data-merchant={null}>
                                        <span style={{ fontWeight: 700 }}>
                                            <FormattedMessage {...commonLang.disableConversionCurrencyTooltipPrefix} />
                                        </span>{' '}
                                        <FormattedMessage {...commonLang.disableConversionCurrencyTooltip} />
                                    </Text>
                                }
                                trigger="hover"
                                data-merchant="disable-conversion-currency-tooltip"
                            >
                                <Checkbox data-merchant={`${record.code}-default-holding-currency-checkbox`} checked />
                            </Tooltip>
                        )
                    }

                    return cloneReactNodeWithDataAttributes(originNode, {
                        'data-merchant': `${record.code}-selecting-holding-currency-checkbox`,
                    })
                },
                onChange: (_, selectedRows) => setSelectedCurrencies(selectedRows.filter(Boolean)),
            }}
            onRow={({ code }) => {
                return {
                    'data-merchant': `${code}-selecting-holding-currency`,
                    onClick: () => handleTableRowClick(code),
                } as object
            }}
            showHeader={false}
            rowKey={row => row.code}
        />
    )
}
