import { EllipsisMiddle } from '@merchant/shared/components'
import { Flex, Typography, theme } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import type { Transaction, TransactionWithdrawalDetails } from '~api/instances/cabinet-api'
import { NoValueColumnContent } from '~components'
import { getOriginText, getOriginTooltip } from '~features/TransactionsTable/utils'

const { Text } = Typography
const { useToken } = theme

interface Props {
    address: TransactionWithdrawalDetails['address']
    origin: Transaction['origin']
}

export function CryptoWithdrawalNotes({ address, origin }: Props) {
    const { token } = useToken()
    if (!address) {
        return NoValueColumnContent
    }

    return (
        <Flex vertical gap={4}>
            <Flex gap={4} align="center">
                <Text data-merchant={null}>
                    <FormattedMessage {...lang.withdrawalCrypto} />{' '}
                </Text>
                {getOriginText(origin)}
                {getOriginTooltip(origin, token)}
            </Flex>
            <Text type="secondary" data-merchant={null}>
                <FormattedMessage
                    {...lang.withdrawTo}
                    values={{
                        address: (
                            <EllipsisMiddle
                                type="secondary"
                                divider="."
                                suffixCount={4}
                                prefixCount={4}
                                copyableText={address}
                                data-merchant={`withdrawal-crypto-address-${address}`}
                            >
                                {address}
                            </EllipsisMiddle>
                        ),
                    }}
                />
            </Text>
        </Flex>
    )
}
