import { EllipsisMiddle, CopyButton, InlineLinkButton } from '@merchant/shared/components'
import { usePromise } from '@merchant/shared/hooks'
import { parseDateAndTime } from '@merchant/shared/utils'
import { Button, Flex, Tooltip, Typography } from '@merchant/ui-kit/ant-design'
import { isEmpty } from 'lodash-es'
import { useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { useBoolean } from 'usehooks-ts'
import { OrderCard } from './Card'
import { orderRowsKeys } from './const'
import { lang } from './lang'
import { MetaInfoModal } from './MetaInfoModal'
import { getHiddenRows, getLatestEvent } from './utils'
import type { DetailsItem } from './types'
import { projectApi, useProject, useProjectCallback } from '~api'
import { Permission, type CallbackResponse, type Payment } from '~api/instances/cabinet-api'
import { CallbackResultModal, NoValueColumnContent } from '~components'
import { routes } from '~constants/routes'
import { useUserPermissionContext } from '~contexts'
import { normalizeRelativeValueToDisplay } from '~utils/underpayment'

const { Title, Text } = Typography

// eslint-disable-next-line max-lines-per-function
const getOrderMetaInfo = (data: Payment | undefined, onShowMetaInfoModal: () => void): DetailsItem[] => {
    const createdDatetime = parseDateAndTime(data?.createdAt)

    const latestEvent = getLatestEvent(data)
    const updatedDatetime = parseDateAndTime(latestEvent)

    return [
        {
            key: orderRowsKeys.created,
            rowTitle: <FormattedMessage {...lang.created} />,
            rightCol: {
                content: (
                    <>
                        <Text data-merchant="order-created-date">{createdDatetime?.date}</Text>{' '}
                        <Text type="secondary" data-merchant="order-created-time">
                            {createdDatetime?.time}
                        </Text>
                    </>
                ),
            },
        },
        {
            key: orderRowsKeys.updatedAt,
            rowTitle: <FormattedMessage {...lang.lastUpdated} />,
            rightCol: {
                content: (
                    <>
                        <Text data-merchant="order-updated-date">{updatedDatetime?.date}</Text>{' '}
                        <Text type="secondary" data-merchant="order-updated-time">
                            {updatedDatetime?.time}
                        </Text>
                    </>
                ),
            },
        },
        {
            key: orderRowsKeys.orderId,
            rowTitle: <FormattedMessage {...lang.orderId} />,
            rightCol: {
                content: (
                    <Text style={{ display: 'flex', gap: 4 }} data-merchant={null}>
                        <EllipsisMiddle divider="." data-merchant="order-id">
                            {data?.id ?? ''}
                        </EllipsisMiddle>
                        <CopyButton
                            inputValue={data?.id || ''}
                            type="text"
                            notificationContentSubject={lang.orderId}
                            data-merchant="order-id-copy-button"
                        />
                    </Text>
                ),
            },
        },
        {
            key: orderRowsKeys.merchantOrderId,
            rowTitle: <FormattedMessage {...lang.merchantOrderId} />,
            rightCol: {
                content: data?.details.orderId ? (
                    <Text style={{ display: 'flex', gap: 4 }} data-merchant={null}>
                        <EllipsisMiddle divider="." data-merchant="order-orderId">
                            {data.details.orderId ?? ''}
                        </EllipsisMiddle>
                        <CopyButton
                            inputValue={data.details.orderId}
                            type="text"
                            notificationContentSubject={lang.merchantOrderId}
                            data-merchant="order-merchant-id-copy-button"
                        />
                    </Text>
                ) : (
                    NoValueColumnContent
                ),
            },
        },
        {
            key: orderRowsKeys.origin,
            rowTitle: <FormattedMessage {...lang.source} />,
            rightCol: {
                content: data?.origin || NoValueColumnContent,
            },
        },
        {
            key: orderRowsKeys.underpayment,
            rowTitle: <FormattedMessage {...lang.underpaymentThreshold} />,
            rightCol: {
                content: data?.lossConfig.relative ? (
                    <Flex vertical align="flex-end">
                        <Text data-merchant="order-underpayment-relative">
                            <FormattedMessage
                                {...lang.underpaymentRelativeValue}
                                values={{
                                    value: normalizeRelativeValueToDisplay(data.lossConfig.relative),
                                }}
                            />
                        </Text>
                        {data.lossConfig.absolute?.amount && (
                            <Text type="secondary" data-merchant="order-underpayment-absolute">
                                <FormattedMessage
                                    {...lang.underpaymentAbsoluteValue}
                                    values={{
                                        amount: data.lossConfig.absolute.amount,
                                        currency: data.lossConfig.absolute.currency,
                                    }}
                                />
                            </Text>
                        )}
                    </Flex>
                ) : (
                    NoValueColumnContent
                ),
            },
        },
        {
            key: orderRowsKeys.metaInfo,
            rowTitle: <FormattedMessage {...lang.metaInfo} />,
            rightCol: {
                content: data?.details.otherData ? (
                    <InlineLinkButton
                        onClick={onShowMetaInfoModal}
                        data-merchant="order-details-show-meta-button"
                        style={{ textDecoration: 'underline', fontWeight: 500 }}
                    >
                        <FormattedMessage {...lang.show} />
                    </InlineLinkButton>
                ) : (
                    NoValueColumnContent
                ),
            },
        },
    ]
}

type Props = {
    loading: boolean
    data?: Payment
}

export function OrderMetaCard({ loading, data }: Props) {
    const { checkUserPermission } = useUserPermissionContext()
    const { data: project } = useProject()
    const { data: callback } = useProjectCallback()
    const { value: isMetaInfoModalOpen, setTrue: openMetaInfoModal, setFalse: closeMetaInfoModal } = useBoolean(false)
    const [callbackResponse, setCallbackResponse] = useState<CallbackResponse | null>(null)

    const { send: sendStatusCallback, isLoading: isSendingStatusCallback } = usePromise(
        () =>
            projectApi.sendProjectPaymentCallback({
                paymentId: data?.id || '',
                projectId: project?.id || '',
            }),
        { onSuccess: setCallbackResponse }
    )
    const hiddenRows = getHiddenRows(data)

    const metaInfo = useMemo(() => {
        return getOrderMetaInfo(data, openMetaInfoModal).filter(
            row => row.type !== 'divider' && !hiddenRows?.find(hiddenRow => hiddenRow === row.key)
        )
    }, [data, openMetaInfoModal, hiddenRows])

    const hasSetupCallback = !isEmpty(callback)
    const callbackButtonTooltipTitle = !hasSetupCallback ? (
        <Text data-merchant="send-status-callback-tooltip">
            <FormattedMessage
                {...lang.sendStatusCallbackTooltip}
                values={{
                    link: chunks => <Link to={routes.integrations}>{chunks}</Link>,
                }}
            />
        </Text>
    ) : null

    return (
        <>
            <MetaInfoModal
                data={data?.details.otherData}
                open={isMetaInfoModalOpen}
                onCancel={closeMetaInfoModal}
                data-merchant="order-meta-info-modal"
            />
            <CallbackResultModal
                type="order"
                data={callbackResponse}
                onCancel={() => setCallbackResponse(null)}
                data-merchant="order-callback-response-modal"
            />
            <OrderCard
                stretch
                cardTitle={
                    <Title level={5} style={{ display: 'inline-block' }} data-merchant={null}>
                        <FormattedMessage {...lang.order} />
                    </Title>
                }
                rowsCount={5}
                loading={loading}
                detailsItems={metaInfo}
                extra={
                    !hiddenRows?.includes(orderRowsKeys.callbackButton) && (
                        <Tooltip
                            placement="bottom"
                            title={callbackButtonTooltipTitle}
                            data-merchant="send-status-callback-tooltip"
                        >
                            <Button
                                disabled={!checkUserPermission(Permission.SendPaymentCallback) || !hasSetupCallback}
                                block
                                size="large"
                                style={{ marginTop: 12 }}
                                onClick={sendStatusCallback}
                                loading={isSendingStatusCallback}
                                data-merchant="send-status-callback-button"
                            >
                                <FormattedMessage {...lang.sendStatusCallback} />
                            </Button>
                        </Tooltip>
                    )
                }
            />
        </>
    )
}
