import Big from 'big.js'
import type { Balance } from '~api/instances/cabinet-api'
import type { Currencies } from '~api/types'
import { Permission } from '~api/instances/cabinet-api'
import { globalLang } from '~globalLang'
import { Actions } from '~types/actionModal'
import { getIfHasWithdrawableFiatCurrencies } from '~utils'

export const calculateTotalBalance = (balances: Balance[]) =>
    balances.reduce((total, { fiatEquivalentAmount = 0 }) => total.plus(fiatEquivalentAmount), Big(0))

export const getWithdrawButtonActions = (
    currencies: Currencies | undefined,
    checkUserPermission: (permission: Permission) => boolean
) => {
    const isCryptoWithdrawalSeeAllowed = checkUserPermission(Permission.SeeCryptoWithdrawalFlow)
    const isFiatWithdrawalSeeAllowed = checkUserPermission(Permission.SeeFiatWithdrawalFlow)
    const hasWithdrawableFiatCurrency = getIfHasWithdrawableFiatCurrencies(currencies)

    if (hasWithdrawableFiatCurrency && isCryptoWithdrawalSeeAllowed && isFiatWithdrawalSeeAllowed) {
        return {
            subActions: [
                {
                    label: globalLang.crypto,
                    action: Actions.withdrawalCrypto,
                    'data-merchant': 'sider-withdrawal-crypto-dropdown-button',
                },
                {
                    label: globalLang.fiat,
                    action: Actions.withdrawalFiat,
                    'data-merchant': 'sider-withdrawal-fiat-dropdown-button',
                },
            ],
        }
    }

    if (isCryptoWithdrawalSeeAllowed) {
        return {
            action: Actions.withdrawalCrypto,
            'data-merchant': 'sider-withdrawal-crypto-button',
        }
    } else if (isFiatWithdrawalSeeAllowed) {
        return {
            action: Actions.withdrawalFiat,
            'data-merchant': 'sider-withdrawal-fiat-button',
        }
    }

    return null
}
