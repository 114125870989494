import { resetSwrCache } from '@merchant/shared/api/swr/utils'
import { authTokenKey, selectedProjectIdKey } from '@merchant/shared/constants/localStorage'
import { usePostHog } from 'posthog-js/react'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts'
import { paymentsSettingsPageIsVisitedKey } from '~constants/localStorage'
import { QueryParams, routes } from '~constants/routes'
import { AuthSteps } from '~constants/views'
import { generateQuery } from '~utils'

export const useLogout = () => {
    const navigate = useNavigate()
    const posthog = usePostHog()
    const [, , removeAuthToken] = useLocalStorage<string>(authTokenKey, '')
    const [, , removeSelectedProjectId] = useLocalStorage<string>(selectedProjectIdKey, '')
    const [, , removePaymentSettingsPageIsVisitedKey] = useLocalStorage<string>(paymentsSettingsPageIsVisitedKey, '')

    const onLogoutFunctions = [
        removeAuthToken,
        removeSelectedProjectId,
        removePaymentSettingsPageIsVisitedKey,
        posthog.reset.bind(posthog),
        () => navigate(`${routes.auth}${generateQuery({ [QueryParams.show]: AuthSteps.SignIn })}`, { replace: true }),
    ]

    const logout = () => {
        onLogoutFunctions.forEach(fn => fn())
        setTimeout(resetSwrCache)
    }

    return {
        logout,
    }
}
