import { EmailSupportLink } from '@merchant/shared/components'
import { Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import { globalLang } from '~globalLang'

const { Text } = Typography

export function Help() {
    return (
        <Text type="secondary" style={{ textAlign: 'center' }} data-merchant="deposit-modal-help">
            <FormattedMessage {...lang.needHelp} /> <EmailSupportLink message={globalLang.contactSupport} />
        </Text>
    )
}
