import { useEffect, useState } from 'react'
import { usePlaceholderView } from '../../hooks'
import { initWithdrawalCryptoData, placeholderViewSpaceProps, viewTitles, type StepsValue } from './const'
import { steps } from './types'
import { assembleWithdrawalTempRecord, isSomeDataLoading } from './utils'
import {
    LoadingSkeleton,
    WithdrawalConfirmation,
    WithdrawalInput,
    WithdrawalProgress,
    WithdrawalSummary,
} from './views'
import type { WithdrawalCryptoFormData } from './types'
import { useCurrencies, useWhitelist, useWithdrawal, useProjectBlocklist } from '~api'
import { ActionSuspendedContent } from '~components'
import { lang as suspensionLang } from '~components/ActionSuspended/lang'
import { SuspensionContext } from '~components/ActionSuspended/types'
import { useActionModalContext } from '~features/ActionModal/context'
import { PlaceholderView } from '~features/PlaceholderView'

// eslint-disable-next-line max-lines-per-function
export function WithdrawalCrypto() {
    const [step, setStep] = useState<StepsValue>(steps.withdrawalCryptoInput)
    const [withdrawalFormData, setWithdrawalFormData] = useState<WithdrawalCryptoFormData>(initWithdrawalCryptoData)
    const [txId, setTxId] = useState<string>()

    const {
        setTitle: setModalTitle,
        closeModal,
        setShowGoBack,
        setOnBackClickHandler,
        setShouldRevalidateDependentTables,
    } = useActionModalContext()

    const { isLoading: isWhitelistLoading } = useWhitelist()
    const { data: blocklistData, isLoading: isBlocklistLoading } = useProjectBlocklist()
    const { isLoading: isCurrenciesLoading } = useCurrencies()
    const { data: withdrawalData } = useWithdrawal({
        shouldFetch: !!txId,
        params: { withdrawalId: txId || '' },
    })
    const { name: placeholderName, isLoading: isLoadingPlaceholderView } = usePlaceholderView()

    useEffect(() => {
        setShouldRevalidateDependentTables(step === steps.withdrawalCryptoProgress)

        if (step === steps.withdrawalCryptoSummary) {
            setShowGoBack(true)
            setOnBackClickHandler(() => setStep(steps.withdrawalCryptoInput))
        } else {
            setShowGoBack(false)
            setOnBackClickHandler(undefined)
        }

        if (step === steps.withdrawalCryptoProgress) {
            setModalTitle(false)
        } else if (blocklistData?.blocked) {
            setModalTitle({
                text: suspensionLang.withdrawalSuspensionModalTitle,
                dataMerchant: 'withdrawal-suspension-modal-title',
            })
        } else {
            const titleProps =
                !placeholderName && viewTitles[step]
                    ? { text: viewTitles[step], dataMerchant: 'withdrawal-crypto-modal-title' }
                    : false
            setModalTitle(titleProps)
        }
    }, [
        step,
        blocklistData?.blocked,
        placeholderName,
        setModalTitle,
        setShouldRevalidateDependentTables,
        setShowGoBack,
        setOnBackClickHandler,
    ])

    const views = {
        [steps.withdrawalCryptoInput]: (
            <WithdrawalInput
                withdrawalFormData={withdrawalFormData}
                onSuccess={(withdrawalFormData: WithdrawalCryptoFormData) => {
                    setWithdrawalFormData(withdrawalFormData)
                    setStep(steps.withdrawalCryptoSummary)
                }}
            />
        ),
        [steps.withdrawalCryptoSummary]: (
            <WithdrawalSummary
                onCancel={closeModal}
                withdrawalFormData={withdrawalFormData}
                onConfirm={() => {
                    setStep(steps.withdrawalCryptoConfirmation)
                }}
            />
        ),
        [steps.withdrawalCryptoConfirmation]: (
            <WithdrawalConfirmation
                withdrawalFormData={withdrawalFormData}
                onSuccess={(txId: string) => {
                    setTxId(txId)
                    setStep(steps.withdrawalCryptoProgress)
                }}
                goBack={() => setStep(steps.withdrawalCryptoInput)}
            />
        ),
        [steps.withdrawalCryptoProgress]: (
            <WithdrawalProgress
                onTryAgain={() => setStep(steps.withdrawalCryptoInput)}
                onClose={closeModal}
                withdrawalData={
                    withdrawalData
                        ? { ...withdrawalData, whitelistKey: withdrawalFormData.whitelistKey || '' }
                        : assembleWithdrawalTempRecord({ withdrawalFormData, txId })
                }
            />
        ),
    }

    if (isSomeDataLoading(isWhitelistLoading, isBlocklistLoading, isCurrenciesLoading, isLoadingPlaceholderView)) {
        return <LoadingSkeleton />
    }

    if (blocklistData?.blocked) {
        return (
            <ActionSuspendedContent
                context={SuspensionContext.Withdrawal}
                blockReason={blocklistData.blockItems[0]?.reason}
                expiresAt={blocklistData.blockItems[0]?.expiresAt}
                onClose={closeModal}
            />
        )
    }

    if (placeholderName) {
        return (
            <PlaceholderView
                name={placeholderName}
                spaceProps={placeholderViewSpaceProps}
                buttonProps={{ block: true, 'data-merchant': 'withdrawal-crypto-placeholder-button' }}
            />
        )
    }

    if (step in views) {
        return <>{views[step]}</>
    }

    return null
}
