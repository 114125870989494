import { formatNumber } from '@merchant/shared/utils'
import { Button } from '@merchant/ui-kit/ant-design'
import styles from './style.module.css'
import type { ButtonProps } from '@merchant/ui-kit/ant-design'

interface Props extends ButtonProps {
    currencyCode?: string | null
    amount?: string | number
    formatter?: ((value: string | number) => string | number | undefined | null) | null
}

export function BalanceLinkButton({ currencyCode, amount, formatter = formatNumber, ...rest }: Props) {
    if (!amount || !currencyCode) {
        return null
    }

    return (
        <Button {...rest} type="link" className={styles.linkButton}>
            {amount === '' ? '-' : (formatter?.(amount) ?? amount)} {currencyCode}
        </Button>
    )
}
