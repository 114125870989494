import { Button } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { lang } from './lang'
import { globalLang } from '~globalLang'

export function GetApiDocsButton() {
    const intl = useIntl()

    return (
        <Button
            style={{ width: 150 }}
            target="_blank"
            href={intl.formatMessage(globalLang.docsUrl)}
            data-merchant="integrations-get-api-docs-button"
        >
            <FormattedMessage {...lang.getApiDocs} />
        </Button>
    )
}
