import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { isKeyOfObject } from '@merchant/shared/utils'
import { Tooltip, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang as commonLang } from '../lang'
import type { GlobalToken } from '@merchant/ui-kit/ant-design'
import type { MessageDescriptor } from 'react-intl'
import type {
    Project,
    Transaction,
    TransactionDepositDetails,
    TransactionDetails,
    TransactionPaymentDetails,
    TransactionSwapDetails,
    TransactionWithdrawalDetails,
} from '~api/instances/cabinet-api'
import type { TransactionFilterQueryData } from '~features/TransactionsPage/views/Transactions/types'
import {
    TransactionStatus,
    TransactionType,
    instanceOfTransactionDepositDetails,
    instanceOfTransactionPaymentDetails,
    instanceOfTransactionSwapDetails,
    instanceOfTransactionWithdrawalDetails,
} from '~api/instances/cabinet-api'

const { Text } = Typography

const originDescriptionsMap = {
    API: commonLang.apiInitiated,
    WEB: commonLang.dashboardInitiated,
}

export const getIfPositiveBalanceChange = (txnType: TransactionType) => {
    return [TransactionType.Deposit, TransactionType.Payment].includes(txnType)
}

export const getFiltersAsParams = (
    { dateFrom, dateTo, ...rest }: Omit<TransactionFilterQueryData, 'projectId'> | undefined = {},
    projectId: Project['id']
): TransactionFilterQueryData => ({
    dateFrom: dateFrom ? new Date(dateFrom) : undefined,
    dateTo: dateTo ? new Date(dateTo) : undefined,
    ...rest,
    projectId,
})

export function isTransactionPaymentDetails(details?: TransactionDetails): details is TransactionPaymentDetails {
    if (!details) {
        return false
    }

    return instanceOfTransactionPaymentDetails(details)
}

export function isTransactionDepositDetails(details?: TransactionDetails): details is TransactionDepositDetails {
    if (!details) {
        return false
    }

    return instanceOfTransactionDepositDetails(details)
}

export function isTransactionWithdrawalDetails(details?: TransactionDetails): details is TransactionWithdrawalDetails {
    if (!details) {
        return false
    }

    return instanceOfTransactionWithdrawalDetails(details)
}

export function isTransactionSwapDetails(details?: TransactionDetails): details is TransactionSwapDetails {
    if (!details) {
        return false
    }

    return instanceOfTransactionSwapDetails(details)
}

type WithdrawalTransactionWithId = Omit<Transaction, 'details'> & {
    details: Omit<TransactionWithdrawalDetails, 'withdrawalId'> &
        Required<Pick<TransactionWithdrawalDetails, 'withdrawalId'>>
}

export function isCancellableTransaction(data: Transaction): data is WithdrawalTransactionWithId {
    return (
        data.type === TransactionType.Withdrawal &&
        isTransactionWithdrawalDetails(data.details) &&
        !!data.details.withdrawalId &&
        !data.details.hash &&
        data.status === TransactionStatus.Processing
    )
}

export const getOriginTooltip = (origin: string | undefined, token: GlobalToken) => {
    return (
        <>
            {isKeyOfObject(origin, originDescriptionsMap) && (
                <Tooltip
                    trigger={['hover', 'click']}
                    title={
                        <Text style={{ fontSize: 12 }} data-merchant={null}>
                            <FormattedMessage {...originDescriptionsMap[origin]} />
                        </Text>
                    }
                    data-merchant="transaction-origin-tooltip"
                >
                    <div>
                        <AppSvg size={20} name={assets.questionMark} color={token.colorTextPlaceholder} />
                    </div>
                </Tooltip>
            )}
        </>
    )
}

export const getTypeTooltip = (title: MessageDescriptor, token: GlobalToken) => {
    return (
        <Tooltip
            trigger={['hover', 'click']}
            title={
                <Text style={{ fontSize: 12 }} data-merchant={null}>
                    <FormattedMessage {...title} />
                </Text>
            }
            data-merchant="transaction-type-tooltip"
        >
            <div>
                <AppSvg size={20} name={assets.questionMark} color={token.colorTextPlaceholder} />
            </div>
        </Tooltip>
    )
}

export const getOriginText = (origin: string | undefined) => {
    return (
        <Text type="secondary" data-merchant={`transaction-origin-${origin}-text`}>
            {origin}
        </Text>
    )
}
