import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    address: {
        id: 'withdrawal-modal.typography.address',
        defaultMessage: 'Address',
    },
    network: {
        id: 'withdrawal-modal.typography.network',
        defaultMessage: 'Network',
    },
    tag: {
        id: 'withdrawal-modal.typography.tag',
        defaultMessage: 'Tag',
    },
    selectWalletAddressPlaceholder: {
        id: 'withdrawal-modal.input.select_wallet_address_placeholder',
        defaultMessage: 'Select withdrawal wallet address',
    },
    addNewAddressButton: {
        id: 'withdrawal-modal.button.add_new_currency_address',
        defaultMessage: 'Add a new whitelisted {selectedCurrency} address',
    },
})
