import Big from 'big.js'
import { FormattedMessage } from 'react-intl'
import { withdrawalCryptoFormKeys } from '../../const'
import { lang } from './lang'
import type { CryptoWithdrawalModalPermissions } from './types'
import type { WithdrawalCryptoFormData } from '../../types'
import type { FormInstance, FormItemProps } from '@merchant/ui-kit/ant-design'
import type { GetWhitelistResponse, Network } from '~api/instances/cabinet-api'
import { globalLang } from '~globalLang'

export function getIfAmountIsLessThanFee(getFieldValue: FormInstance<WithdrawalCryptoFormData>['getFieldValue']) {
    const feeValue = getFieldValue(withdrawalCryptoFormKeys.fee)
    const withdrawalAmount = getFieldValue(withdrawalCryptoFormKeys.withdrawalAmount)
    if (!Number(feeValue) || !Number(withdrawalAmount)) {
        return false
    }

    return Big(withdrawalAmount).lte(feeValue)
}

export function getAmountFormItemRules(
    balanceAmount: string | number,
    cryptoWithdrawalPermissions: CryptoWithdrawalModalPermissions,
    isFormSubmitted: boolean
): FormItemProps['rules'] {
    return [
        ({ isFieldTouched }) => ({
            validator: (_, value: string = '') =>
                (isFormSubmitted || isFieldTouched(withdrawalCryptoFormKeys.amount)) && !Number(value)
                    ? Promise.reject()
                    : Promise.resolve(),
            message: (
                <span data-merchant="withdrawal-amount-required-field-error-message">
                    <FormattedMessage {...globalLang.requiredFieldMessage} />
                </span>
            ),
        }),
        ({ getFieldValue }) => ({
            validator: () => {
                const withdrawalAmount = getFieldValue(withdrawalCryptoFormKeys.withdrawalAmount)
                if (!cryptoWithdrawalPermissions.see_balance) {
                    return Promise.resolve()
                }

                return Number(withdrawalAmount) <= Number(balanceAmount) ? Promise.resolve() : Promise.reject()
            },
            message: (
                <span data-merchant="withdrawal-amount-insufficient-funds-error-message">
                    <FormattedMessage {...globalLang.insufficientFunds} />
                </span>
            ),
        }),
        ({ getFieldValue }) => ({
            validator: () => (getIfAmountIsLessThanFee(getFieldValue) ? Promise.reject() : Promise.resolve()),
            message: (
                <span data-merchant="withdrawal-amount-lower-than-fee-error-message">
                    <FormattedMessage {...lang.amountShouldBeGreater} />
                </span>
            ),
        }),
    ]
}

export function getAddressFormItemRules(
    whitelist: GetWhitelistResponse | undefined,
    selectedNetworkData: Network | undefined
): FormItemProps['rules'] {
    return [
        {
            validateTrigger: ['onChange', 'onBlur'],
            validator: (_, value: string = '') => (value ? Promise.resolve() : Promise.reject()),
            message: (
                <span data-merchant="withdrawal-address-required-field-error-message">
                    <FormattedMessage {...globalLang.requiredFieldMessage} />
                </span>
            ),
        },
        {
            pattern: whitelist?.enabled ? undefined : new RegExp(selectedNetworkData?.addressRegex || ''),
            message: (
                <span data-merchant="withdrawal-address-invalid-error-message">
                    <FormattedMessage {...lang.invalidAddress} />
                </span>
            ),
        },
    ]
}

export const validateAmount = (amount: WithdrawalCryptoFormData['amount']) =>
    ('credit' in amount && !!amount.credit) || ('receive' in amount && !!amount.receive)
