import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    titleLabel: {
        id: 'integrations-page.modal-create.enter_title_label',
        defaultMessage: 'Please enter a title for your new API Key',
    },
    titlePlaceholder: {
        id: 'integrations-page.modal-create.enter_title_placeholder',
        defaultMessage: 'Title',
    },
    continue: {
        id: 'integrations-page.modal-create.continue_button',
        defaultMessage: 'Continue',
    },
})
