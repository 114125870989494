/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentDetails
 */
export interface PaymentDetails {
    /**
     * 
     * @type {string}
     * @memberof PaymentDetails
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDetails
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDetails
     */
    successRedirectUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDetails
     */
    failureRedirectUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDetails
     */
    orderId?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDetails
     */
    customerId?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDetails
     */
    customerEmail?: string;
    /**
     * Freehand metadata associated with the payment
     * @type {{ [key: string]: any; }}
     * @memberof PaymentDetails
     */
    otherData?: { [key: string]: any; };
}

/**
 * Check if a given object implements the PaymentDetails interface.
 */
// @ts-ignore
export function instanceOfPaymentDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

// @ts-ignore
export function PaymentDetailsFromJSON(json: any): PaymentDetails {
    return PaymentDetailsFromJSONTyped(json, false);
}

// @ts-ignore
export function PaymentDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'successRedirectUrl': !exists(json, 'success_redirect_url') ? undefined : json['success_redirect_url'],
        'failureRedirectUrl': !exists(json, 'failure_redirect_url') ? undefined : json['failure_redirect_url'],
        'orderId': !exists(json, 'order_id') ? undefined : json['order_id'],
        'customerId': !exists(json, 'customer_id') ? undefined : json['customer_id'],
        'customerEmail': !exists(json, 'customer_email') ? undefined : json['customer_email'],
        'otherData': !exists(json, 'other_data') ? undefined : json['other_data'],
    };
}

// @ts-ignore
export function PaymentDetailsToJSON(value?: PaymentDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'description': value.description,
        'success_redirect_url': value.successRedirectUrl,
        'failure_redirect_url': value.failureRedirectUrl,
        'order_id': value.orderId,
        'customer_id': value.customerId,
        'customer_email': value.customerEmail,
        'other_data': value.otherData,
    };
}

