import { Button, Flex, List } from '@merchant/ui-kit/ant-design'
import { isEmpty } from 'lodash-es'
import { FormattedMessage } from 'react-intl'
import { lang } from '../lang'
import { ListItem } from './ListItem'
import type { CurrencyExtended } from './../types'
import type { ListProps } from '@merchant/ui-kit/ant-design'
import { useCurrencies } from '~api'

interface Props extends ListProps<CurrencyExtended> {
    defaultCurrency: string
    onButtonClick: () => void
    isCurrencySettingsEditAllowed: boolean
}

export function CurrenciesList({
    defaultCurrency,
    dataSource,
    onButtonClick,
    isCurrencySettingsEditAllowed,
    ...props
}: Props) {
    const { data: currencies } = useCurrencies()
    const sourceWithoutFiat = dataSource?.filter(c => {
        return currencies?.[c.code]?.crypto === true
    })

    return (
        <Flex vertical gap={12}>
            {!isEmpty(sourceWithoutFiat) && (
                <div
                    style={{
                        overflow: 'hidden',
                        borderRadius: 12,
                        width: '100%',
                    }}
                >
                    <List
                        itemLayout="vertical"
                        bordered
                        locale={{ emptyText: <div /> }}
                        size="small"
                        style={{ maxHeight: 350, overflowY: 'auto' }}
                        renderItem={currency => (
                            <ListItem data={currency} isHighlighted={defaultCurrency === currency.code} />
                        )}
                        dataSource={sourceWithoutFiat}
                        {...props}
                    />
                </div>
            )}
            <Button
                disabled={!isCurrencySettingsEditAllowed}
                data-merchant="edit-holding-currencies-btn"
                block
                size="large"
                onClick={onButtonClick}
            >
                <FormattedMessage
                    {...(isEmpty(sourceWithoutFiat) ? lang.chooseCurrencies : lang.editCurrenciesButton)}
                />
            </Button>
        </Flex>
    )
}
