import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    emailLabel: {
        id: 'add-member.form.email_label',
        defaultMessage: 'Email',
    },
    nameLabel: {
        id: 'add-member.form.name_label',
        defaultMessage: 'Name',
    },
    roleLabel: {
        id: 'add-member.form.role_label',
        defaultMessage: 'Role',
    },
    addMemberButton: {
        id: 'add-member.form.add_member_button',
        defaultMessage: 'Invite more people',
    },
    sendInvitationButton: {
        id: 'add-member.form.send_invitation_button',
        defaultMessage: 'Send invitation',
    },
})
