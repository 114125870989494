import { Col, Row } from '@merchant/ui-kit/ant-design'
import { useNavigate, useParams } from 'react-router-dom'
import {
    WithdrawalHeading,
    WithdrawalInfo,
    WithdrawalSkeleton,
    WithdrawalState,
    WithdrawalTimeline,
} from './components'
import { WithdrawalState as WithdrawalStateEnum, useWithdrawal } from '~api'
import { routes } from '~constants/routes'
import { PlaceholderViewWrapper } from '~features/PlaceholderViewWrapper'

const WITHDRAWAL_REFRESH_INTERVAL = 5000

export function WithdrawalDetailsPage() {
    const { withdrawalID } = useParams<'withdrawalID'>()
    const navigate = useNavigate()
    const { data: withdrawal, isLoading: isLoadingWithdrawal } = useWithdrawal(
        {
            params: { withdrawalId: withdrawalID || '' },
            shouldFetch: !!withdrawalID,
        },
        {
            onError: () => navigate(routes.withdrawals),
            refreshInterval: latestData =>
                latestData?.state === WithdrawalStateEnum.Pending ? WITHDRAWAL_REFRESH_INTERVAL : 0,
        }
    )

    if (isLoadingWithdrawal) {
        return <WithdrawalSkeleton id={withdrawalID} />
    }

    return (
        <PlaceholderViewWrapper dataMerchantPrefix="withdrawal">
            <Row wrap gutter={[16, 16]}>
                <WithdrawalHeading id={withdrawal?.id} />
                <Col span={24}>
                    <WithdrawalState
                        data-merchant={`withdrawal-state-${withdrawalID}`}
                        data={{
                            state: withdrawal?.state,
                            amount: withdrawal?.creditAmount,
                            currency: withdrawal?.creditCurrency,
                            updatedAt: withdrawal?.updatedAt,
                        }}
                    />
                </Col>
                <Col span={12} style={{ display: 'inline-flex', alignSelf: 'stretch' }}>
                    <WithdrawalInfo data-merchant={`withdrawal-info-${withdrawalID}`} data={withdrawal} />
                </Col>
                <Col span={12} style={{ display: 'inline-flex', alignSelf: 'stretch' }}>
                    <WithdrawalTimeline data={withdrawal} />
                </Col>
            </Row>
        </PlaceholderViewWrapper>
    )
}
