import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { Alert, Flex, Form, Tooltip, Typography } from '@merchant/ui-kit/ant-design'
import { isEmpty } from 'lodash-es'
import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { formFields } from '../../const'
import { useCurrencyOptions, useCurrencySelectionHandler } from './hooks'
import { lang } from './lang'
import { getTooltipTitles } from './utils'
import type { FormFields } from './types'
import type { FormProps } from '@merchant/ui-kit/ant-design'
import type { NewDepositAddressState } from '~features/ActionModal/views/Deposit/types'
import { SearchableSelect } from '~components'
import joinedFieldsStyle from '~styles/joined-fields.style.module.css'
import style from '~styles/select-groups.style.module.css'

const { Text } = Typography

function NetworkAlert() {
    return (
        <Alert
            data-merchant="deposit-network-alert"
            type="warning"
            style={{ padding: 12, margin: 12 }}
            message={
                <Flex gap={12}>
                    <AppSvg name={assets.alertCircle} color={token => token.colorWarning} />
                    <Text type="secondary" data-merchant="deposit-network-alert-message">
                        <FormattedMessage {...lang.networkAlert} />
                    </Text>
                </Flex>
            }
        />
    )
}

interface Props {
    onFinish?: (value: NewDepositAddressState) => void
    context: 'existing' | 'new'
    initialValues: FormFields | undefined
    isLoading: boolean
    onChange?: FormProps<FormFields>['onValuesChange']
}

export function Fieldset({ onFinish, onChange, context, initialValues, isLoading }: Props) {
    const [form] = Form.useForm()
    const currency = Form.useWatch(formFields.currency, form)
    const { options: currencies, isLoading: isLoadingCurrencies } = useCurrencyOptions()

    const networks = useCurrencySelectionHandler({
        currency,
        form,
        onFinish,
    })

    useEffect(() => {
        if (context === 'existing' && initialValues?.currency) {
            form.setFieldsValue(initialValues)
        }
    }, [context, form, initialValues])

    const { currencyTooltip, networkTooltip } = getTooltipTitles({
        context,
        canSelectNetwork: currency && !isEmpty(networks),
    })

    return (
        <Form form={form} onValuesChange={onChange} layout="vertical" size="large" initialValues={initialValues}>
            <Flex vertical gap={4}>
                <FormattedMessage {...lang.label} />
                <Tooltip data-merchant="deposit-currency-tooltip" placement="bottom" title={currencyTooltip}>
                    <Flex vertical>
                        <Form.Item name={formFields.currency} noStyle>
                            <SearchableSelect
                                data-merchant="deposit-currency-select"
                                loading={isLoadingCurrencies || (context === 'existing' && isLoading)}
                                size="large"
                                className={joinedFieldsStyle.selectCompactTopItem}
                                placeholder={<FormattedMessage {...lang.currencyPlaceholder} />}
                                options={currencies}
                                disabled={context === 'existing'}
                                optionFilterProp="key"
                                popupClassName={style.popup}
                            />
                        </Form.Item>
                    </Flex>
                </Tooltip>
                <Tooltip data-merchant="deposit-network-tooltip" placement="bottom" title={networkTooltip}>
                    <Flex vertical>
                        <Form.Item name={formFields.network} noStyle>
                            <SearchableSelect<string>
                                data-merchant="deposit-network-select"
                                options={networks}
                                disabled={!currency || isEmpty(networks) || context === 'existing'}
                                size="large"
                                loading={context === 'existing' && isLoading}
                                className={joinedFieldsStyle.selectCompactBottomItem}
                                placeholder={<FormattedMessage {...lang.networkPlaceholder} />}
                                optionLabelProp="selectedLabel"
                                onChange={value => {
                                    form.setFieldValue(formFields.network, value)
                                    onFinish?.({ currency, network: value })
                                }}
                                popupClassName={style.popup}
                                optionFilterProp="key"
                                dropdownRender={menu => (
                                    <>
                                        <NetworkAlert />
                                        {menu}
                                    </>
                                )}
                            />
                        </Form.Item>
                    </Flex>
                </Tooltip>
            </Flex>
        </Form>
    )
}
