import { Button, Card, Result, Typography, theme } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import { useBreakpoint } from '~hooks'

const { Title, Text } = Typography
const { useToken } = theme

interface Props {
    onBackClick?: () => void
    dataMerchantPrefix: string
}

export function NotFound({ onBackClick, dataMerchantPrefix }: Props) {
    const { xs } = useBreakpoint()
    const { token } = useToken()

    return (
        <Card
            data-merchant={`${dataMerchantPrefix}-not-found`}
            style={{ height: '100%' }}
            styles={{ body: { height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' } }}
        >
            <Result
                style={{ marginTop: 0, paddingTop: 0 }}
                title={
                    <Title
                        data-merchant={`${dataMerchantPrefix}-title`}
                        level={1}
                        style={{
                            fontFamily: 'Arial',
                            fontSize: xs ? 60 : 90,
                            fontWeight: 600,
                            color: 'transparent',
                            WebkitTextStroke: `1px ${token.colorLink}`,
                        }}
                    >
                        <FormattedMessage {...lang.notFoundTitle} />
                    </Title>
                }
                subTitle={
                    <>
                        <Title level={4} data-merchant={`${dataMerchantPrefix}-subtitle`}>
                            <FormattedMessage {...lang.notFoundSubtitle} />
                        </Title>
                        <Text type="secondary" data-merchant={`${dataMerchantPrefix}-text`}>
                            <FormattedMessage {...lang.notFoundText} />
                        </Text>
                    </>
                }
                icon={null}
                extra={
                    onBackClick ? (
                        <Button
                            size="large"
                            onClick={onBackClick}
                            type="primary"
                            data-merchant={`${dataMerchantPrefix}-not-found-back-button`}
                        >
                            <FormattedMessage {...lang.backToMainPage} />
                        </Button>
                    ) : null
                }
            />
        </Card>
    )
}
