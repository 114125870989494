import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg, CopyButton, EllipsisMiddle } from '@merchant/shared/components'
import { parseIfValidJson } from '@merchant/shared/utils'
import { Collapse, Flex, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage, type MessageDescriptor } from 'react-intl'
import { lang } from './lang'
import type { CallbackResultModalType } from './types'
import type { CollapseProps } from '@merchant/ui-kit/ant-design'
import type { CallbackResponse } from '~api/instances/cabinet-api'
import { NoValueColumnContent } from '~components/NoValueColumnContent'

const { Text } = Typography

const getCollapseArrow: CollapseProps['expandIcon'] = ({ isActive }) => (
    <AppSvg
        name={assets.chevronDown}
        color={token => token.colorTextDescription}
        size={24}
        style={{ transform: isActive ? 'rotate(180deg)' : 'rotate(0)', transition: 'transform 0.3s' }}
    />
)

const getCollapseItem = (key: string, label: MessageDescriptor, content: string | null) => ({
    showArrow: true,
    key,
    label: (
        <Text data-merchant={`test-callback-result-modal-${key}-toggle`}>
            <FormattedMessage {...label} />
        </Text>
    ),
    children: (
        <div data-merchant={`test-callback-result-modal-${key}`}>
            {content ? <pre style={{ overflowX: 'scroll', maxHeight: 400 }}>{content}</pre> : NoValueColumnContent}
        </div>
    ),
})

export function getCardBody(data: CallbackResponse) {
    const requestBodyContent = parseIfValidJson(data.request.body).isValid
        ? JSON.stringify(parseIfValidJson(data.request.body).parsed, null, 2)
        : data.request.body
    const responseBodyContent = parseIfValidJson(data.response.body).isValid
        ? JSON.stringify(parseIfValidJson(data.response.body).parsed, null, 2)
        : data.response.body

    return {
        requestBody: (
            <Collapse
                expandIconPosition="end"
                expandIcon={getCollapseArrow}
                ghost
                data-merchant="test-callback-result-modal-request-body-collapse"
                items={[getCollapseItem('request-body', lang.requestBody, requestBodyContent)]}
            />
        ),
        signature: (
            <Flex align="center" justify="space-between">
                <Flex vertical gap={2}>
                    <Text data-merchant={null} type="secondary">
                        <FormattedMessage {...lang.signature} />
                    </Text>
                    <EllipsisMiddle
                        prefixCount={16}
                        suffixCount={16}
                        divider="."
                        data-merchant={`test-callback-result-modal-signature-${data.request.signature}`}
                    >
                        {data.request.signature}
                    </EllipsisMiddle>
                </Flex>
                <CopyButton
                    inputValue={data.request.signature}
                    data-merchant="test-callback-result-modal-copy-signature-button"
                    type="text"
                />
            </Flex>
        ),
        url: (
            <Flex align="center" justify="space-between">
                <Flex vertical gap={2}>
                    <Text data-merchant={null} type="secondary">
                        <FormattedMessage {...lang.url} />
                    </Text>
                    <Text data-merchant="test-callback-result-modal-url">{data.request.url}</Text>
                </Flex>
                <CopyButton
                    inputValue={data.request.url}
                    data-merchant="test-callback-result-modal-copy-url-button"
                    type="text"
                />
            </Flex>
        ),
        statusCode: (
            <Flex vertical gap={2}>
                <Text data-merchant={null} type="secondary">
                    <FormattedMessage {...lang.statusCode} />
                </Text>
                <Text data-merchant="test-callback-result-modal-status-code">{data.response.code}</Text>
            </Flex>
        ),
        responseBody: (
            <Collapse
                expandIconPosition="end"
                expandIcon={getCollapseArrow}
                ghost
                data-merchant="test-callback-result-modal-response-body-collapse"
                items={[getCollapseItem('response-body', lang.responseBody, responseBodyContent)]}
            />
        ),
    }
}

const callbackModalTitles: Record<CallbackResultModalType, Record<'success' | 'failure', MessageDescriptor>> = {
    order: { success: lang.successOrderCallbackTitle, failure: lang.failureOrderCallbackTitle },
    test: { success: lang.successTestCallbackTitle, failure: lang.failureTestCallbackTitle },
    withdrawal: { success: lang.successWithdrawalCallbackTitle, failure: lang.failureWithdrawalCallbackTitle },
}

export const getCallbackResultModalTitle = (
    type: CallbackResultModalType,
    isCallbackSent: boolean
): MessageDescriptor => {
    const status = isCallbackSent ? 'success' : 'failure'

    return callbackModalTitles[type][status]
}
