import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    pageTitle: {
        id: 'withdrawal.page.title',
        defaultMessage: 'Withdrawals',
    },
    pageSubtitle: {
        id: 'withdrawal.page.subtitle',
        defaultMessage: 'Withdrawal details',
    },
})
