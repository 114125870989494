import { useBreakpoint } from '@merchant/shared/hooks'
import { Flex, List, Typography } from '@merchant/ui-kit/ant-design'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { lang } from '../../lang'
import { getCommonFlexProps, listTitleStyles, getSelectStyles } from '../../styles'
import { timezones } from './const'
import type { ListItemProps } from '@merchant/ui-kit/ant-design/es/list'
import type { Merchant } from '~api/instances/cabinet-api'
import { SearchableSelect } from '~components'

const { Text } = Typography

interface Props extends Omit<ListItemProps, 'onChange'> {
    isLoading: boolean
    value: string | undefined
    onChange: (timeZone: Merchant['timeZone']) => void
}

export function TimezoneItem({ isLoading, value, onChange, ...rest }: Props) {
    const [selectValue, setSelectValue] = useState(value)
    const breakpoints = useBreakpoint()
    const commonFlexProps = getCommonFlexProps(!breakpoints.md)

    useEffect(() => setSelectValue(value), [value])

    const handleSelectChange = (timeZone: string) => {
        setSelectValue(timeZone)
        onChange(timeZone)
    }

    return (
        <List.Item {...rest}>
            <Flex {...commonFlexProps}>
                <List.Item.Meta
                    data-merchant="settings-timezone-list-item-meta"
                    title={
                        <Text style={listTitleStyles} data-merchant={null}>
                            <FormattedMessage {...lang.timeZone} />
                        </Text>
                    }
                />
                <SearchableSelect
                    size="large"
                    style={getSelectStyles({ breakpoints })}
                    data-merchant="settings-timezone-select"
                    loading={isLoading}
                    onChange={handleSelectChange}
                    value={selectValue}
                    placement="bottomRight"
                    options={timezones}
                />
            </Flex>
        </List.Item>
    )
}
