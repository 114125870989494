import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { Select, Button, Divider, Flex, Input, List, Typography } from '@merchant/ui-kit/ant-design'
import { lang as commonLang } from 'features/WhitelistPage/lang'
import { isEmpty } from 'lodash-es'
import { FormattedMessage, useIntl } from 'react-intl'
import { addAddressButtonDataMerchant } from '../const'
import { lang } from '../lang'
import { useData } from '../useData'
import { sortByOptions } from './const'
import { ListItem } from './ListItem'
import { useListFilters } from './useListFilters'
import { AddWhitelistedAddressModal, DeleteWhitelistedAddressModal } from '~features/WhitelistPage/components'
import { globalLang } from '~globalLang'

const { Text } = Typography

// eslint-disable-next-line max-lines-per-function
export function ListView() {
    const intl = useIntl()
    const {
        filteredData,
        handleDeleteClick,
        closeAddModal,
        closeDeleteModal,
        isAddModalOpen,
        isDeleteModalOpen,
        setSelectedWhitelist,
        selectedWhitelist,
        setSearchValue,
        openAddModal,
        isEditWhitelistAllowed,
    } = useData()

    const {
        currencyFilterOptions,
        filteredListData,
        setSelectedCurrencyFilter,
        setSelectedNetworkFilter,
        networkFilterOptions,
        selectedCurrencyFilter,
        selectedNetworkFilter,
        fieldToSortBy,
        setFieldToSortBy,
    } = useListFilters({
        filteredData,
    })

    const isEmptyData = isEmpty(filteredData)

    return (
        <>
            <AddWhitelistedAddressModal
                close={closeAddModal}
                open={isAddModalOpen}
                data-merchant="add-whitelist-address-modal"
            />
            <DeleteWhitelistedAddressModal
                selectedWhitelist={selectedWhitelist}
                open={isDeleteModalOpen}
                afterClose={() => setSelectedWhitelist(null)}
                close={closeDeleteModal}
            />
            <Flex justify="space-between" align="center">
                <Text style={{ fontSize: 16 }} data-merchant={null}>
                    <FormattedMessage {...lang.walletAddresses} />
                </Text>
                <Button
                    disabled={!isEditWhitelistAllowed}
                    type="primary"
                    onClick={openAddModal}
                    data-merchant={addAddressButtonDataMerchant}
                >
                    <FormattedMessage {...commonLang.addNewAddress} />
                </Button>
            </Flex>

            {!isEmptyData && (
                <>
                    <Input
                        allowClear
                        style={{ marginTop: 12 }}
                        onChange={e => setSearchValue(e.target.value)}
                        prefix={<AppSvg name={assets.searchSm} />}
                        placeholder={intl.formatMessage(lang.searchPlaceholder)}
                        data-merchant="whitelist-search-input"
                    />
                    <Divider style={{ marginBlock: '12px 24px' }} />
                    <Select
                        allowClear
                        showSearch={false}
                        value={fieldToSortBy}
                        data-merchant="whitelist-sort-by"
                        onChange={setFieldToSortBy}
                        options={sortByOptions}
                        placeholder={intl.formatMessage(lang.sortByPlaceholder)}
                    />
                    <Flex style={{ marginTop: 12 }} justify="space-between" gap={12}>
                        <Select
                            allowClear
                            showSearch={false}
                            onChange={setSelectedCurrencyFilter}
                            value={selectedCurrencyFilter}
                            data-merchant="whitelist-currency-filter"
                            mode="tags"
                            maxTagTextLength={5}
                            options={currencyFilterOptions}
                            style={{ width: '100%' }}
                            placeholder={intl.formatMessage(lang.currency)}
                        />
                        <Select
                            allowClear
                            showSearch={false}
                            onChange={setSelectedNetworkFilter}
                            value={selectedNetworkFilter}
                            data-merchant="whitelist-network-filter"
                            options={networkFilterOptions}
                            mode="tags"
                            style={{ width: '100%' }}
                            placeholder={intl.formatMessage(globalLang.network)}
                        />
                    </Flex>
                </>
            )}
            <List
                style={{ marginTop: 12 }}
                dataSource={filteredListData}
                itemLayout="vertical"
                renderItem={item => <ListItem key={item.key} item={item} onDeleteClick={handleDeleteClick} />}
                rowKey={item => item.key}
                data-merchant="whitelist-list"
            />
        </>
    )
}
