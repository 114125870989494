import { Form } from '@merchant/ui-kit/ant-design'
import { useState, useEffect } from 'react'
import { useBoolean } from 'usehooks-ts'
import { paymentsSettingsFormItems } from '../../const'
import { RELATIVE_CUSTOM_OPTION, relativeDefaultOptions } from './const'
import { getIsLossConfigEnabled } from './utils'
import type { CheckboxProps } from '@merchant/ui-kit/ant-design'
import type { LossConfig } from '~api/instances/cabinet-api'
import { useCurrencies, useProject } from '~api'
import { getBaseCurrenciesOptions } from '~components/BaseCurrencySelect/utils'

interface Props {
    lossConfig: LossConfig | undefined
    isProjectLoading: boolean
}

export function useData({ isProjectLoading, lossConfig }: Props) {
    const { data: currencies } = useCurrencies()
    const { data: project } = useProject()
    const baseCurrenciesOptions = getBaseCurrenciesOptions(currencies)

    const form = Form.useFormInstance()
    const { value: isCustomSelected, setValue: setIsCustomSelected } = useBoolean(false)
    const { value: isRelativeCheckboxEnabled, setValue: setIsRelativeCheckboxEnabled } = useBoolean(false)
    const { value: isAbsoluteCheckboxEnabled, setValue: setIsAbsoluteCheckboxEnabled } = useBoolean(false)

    const [lastRelativeValue, setLastRelativeValue] = useState<number>()

    const handleAbsoluteCheckboxChange = (checked: boolean) => {
        setIsAbsoluteCheckboxEnabled(checked)
        const formValue = checked
            ? {
                  currency: lossConfig?.absolute?.currency ?? baseCurrenciesOptions?.[0]?.value,
                  amount: lossConfig?.absolute?.amount ?? '',
              }
            : undefined

        form.setFields([
            {
                name: paymentsSettingsFormItems.absolute,
                value: formValue,
                errors: [],
                touched: false,
            },
            {
                name: [paymentsSettingsFormItems.absolute, 'amount'],
                errors: [],
            },
        ])
    }

    const handleRelativeCheckboxChange: CheckboxProps['onChange'] = ({ target: { checked } }) => {
        setIsRelativeCheckboxEnabled(checked)
        if (!checked) {
            setIsAbsoluteCheckboxEnabled(false)
            handleAbsoluteCheckboxChange(false)
            setLastRelativeValue(form.getFieldValue(paymentsSettingsFormItems.relative))
        }
        form.setFields([
            {
                name: paymentsSettingsFormItems.relative,
                value: checked && !isCustomSelected ? (lastRelativeValue ?? relativeDefaultOptions[0]) : undefined,
                errors: [],
            },
        ])
    }

    const handleRelativeSelectChange = (value: string | number) => {
        const isCustom = value.toString().toLowerCase() === RELATIVE_CUSTOM_OPTION
        setIsCustomSelected(isCustom)
        form.setFieldValue(paymentsSettingsFormItems.relative, isCustom ? undefined : value)
    }

    useEffect(() => {
        if (isProjectLoading || !project) {
            return
        }

        if (!getIsLossConfigEnabled(lossConfig)) {
            setIsRelativeCheckboxEnabled(false)
            setIsAbsoluteCheckboxEnabled(false)
            setIsCustomSelected(false)
            setLastRelativeValue(undefined)
            setTimeout(() => {
                form.setFields([
                    {
                        name: paymentsSettingsFormItems.relative,
                        value: undefined,
                    },
                    {
                        name: paymentsSettingsFormItems.absolute,
                        value: undefined,
                    },
                ])
            })

            return
        }

        const { relative, absolute } = lossConfig || {}
        const stringRelative = relative?.toString()

        setIsRelativeCheckboxEnabled(true)
        if (relative) {
            setIsCustomSelected(!relativeDefaultOptions.some(option => option === stringRelative))
            form.setFieldValue(paymentsSettingsFormItems.relative, stringRelative)
        }

        if (absolute?.amount && absolute.currency) {
            form.setFieldValue(paymentsSettingsFormItems.absolute, {
                amount: absolute.amount,
                currency: absolute.currency,
            })
            setIsAbsoluteCheckboxEnabled(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isProjectLoading, project])

    return {
        isCustomSelected,
        isRelativeCheckboxEnabled,
        isAbsoluteCheckboxEnabled,
        baseCurrenciesOptions,
        handleAbsoluteCheckboxChange,
        handleRelativeCheckboxChange,
        handleRelativeSelectChange,
        lastRelativeValue,
    }
}
