import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    currencyColumnTitle: {
        id: 'balances-snapshots.table.currency_column_title',
        defaultMessage: 'Currency',
    },
    balanceColumnTitle: {
        id: 'balances-snapshots.table.balance_column_title',
        defaultMessage: 'Balance',
    },
    noRecords: {
        id: 'balances-snapshots.table.no_records',
        defaultMessage: 'Oops! No records found',
    },
})
