import { useBreakpoint } from '@merchant/shared/hooks'
import { Select, DatePicker, Flex } from '@merchant/ui-kit/ant-design'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useState } from 'react'
import { useBoolean } from 'usehooks-ts'
import { defaultExpirationTime, expirationTimeOptions, expirationTimeOptionsLabelMap } from './const'
import styles from './style.module.css'
import { disabledTime, getDefaultPickerDate } from './utils'
import type { DatePickerProps } from '@merchant/ui-kit/ant-design'
import { ResponsiveDropdown } from '~components'
import { lang } from '~features/PaymentConstructor/lang'

dayjs.extend(customParseFormat)

interface Props {
    setExpirationDateFieldValue: (date: Date | number) => void
}

export function ExpirationDate({ setExpirationDateFieldValue }: Props) {
    const { value: isCustomSelected, setValue: setIsCustomSelected } = useBoolean(false)
    const [expirationTime, setExpirationTime] = useState<dayjs.Dayjs>()
    const breakpoints = useBreakpoint()
    const handleSelectChange = (value: string | number) => {
        const isCustom = value.toString().toLowerCase() === 'custom'
        const numberValue = Number(value)
        if (isCustom) {
            setExpirationDateFieldValue(getDefaultPickerDate().toDate())
        } else if (!isNaN(numberValue)) {
            setExpirationDateFieldValue(numberValue)
        }
        setIsCustomSelected(isCustom)
    }

    const handleDatePickerChange = (date: dayjs.Dayjs) => {
        setExpirationTime(date)
        setExpirationDateFieldValue(date.toDate())
    }

    return (
        <Flex gap={20} vertical={!breakpoints.md}>
            <Select
                popupMatchSelectWidth
                defaultValue={defaultExpirationTime}
                style={{ flex: 1 }}
                options={expirationTimeOptions.map(item => ({
                    value: item.toLowerCase(),
                    label: expirationTimeOptionsLabelMap[item],
                    'data-merchant': `order-expiration-time-${item}`,
                }))}
                data-merchant="order-expiration-time-select"
                onChange={handleSelectChange}
            />
            {isCustomSelected && (
                <ResponsiveDropdown<DatePickerProps>
                    title={lang.expirationTime}
                    inputReadOnly
                    format="DD.MM.YYYY HH:mm"
                    allowClear={false}
                    onChange={handleDatePickerChange}
                    minDate={dayjs()}
                    disabledTime={disabledTime}
                    value={expirationTime}
                    defaultValue={dayjs()}
                    showTime
                    renderMobile={(props, commonProps) => (
                        <DatePicker
                            {...props}
                            style={{ width: '100%' }}
                            onOk={commonProps.closeMobileDropdown}
                            popupClassName={styles.datepicker}
                            open
                            data-merchant="order-expiration-custom-mobile-datepicker"
                        />
                    )}
                    data-merchant="order-expiration-custom-datepicker"
                    panelRender={panel => <div data-merchant="order-expiration-custom-datepicker-panel">{panel}</div>}
                    render={(props, commonProps) => (
                        <DatePicker
                            {...props}
                            onClick={commonProps.openMobileDropdown}
                            data-merchant="order-expiration-custom-desktop-datepicker"
                        />
                    )}
                />
            )}
        </Flex>
    )
}
