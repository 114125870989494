import { ModalResponsive } from '@merchant/shared/components'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { TwoFaConfirmationForm } from '../../TwoFaConfirmationForm'
import { Steps } from './const'
import { EditApiKeyForm } from './EditApiKeyForm'
import { lang } from './lang'
import type { EditApiKeyData } from './types'
import type { FormInstance, ModalProps } from '@merchant/ui-kit/ant-design'
import type { APIKey, TOTPCredentials } from '~api/instances/cabinet-api'
import { projectApi, useProject } from '~api'
import { useCodeConfirmationErrorHandling } from '~features/FormItems/useCodeErrorHandling'
import { useDirtyFormCheckerControls } from '~hooks'

const initStep = Steps.edit

interface Props extends Omit<ModalProps, 'title' | 'data-merchant'> {
    selectedApiKey: APIKey | null
    onDeleteClick: () => void
    onModalSuccess: (values: APIKey) => void
}

export function EditApiKeyModal({ selectedApiKey, onModalSuccess, onCancel, onDeleteClick, ...rest }: Props) {
    const { data: projectData } = useProject()
    const { handleError } = useCodeConfirmationErrorHandling({
        confirmationStrategy: { '2fa': 'code' },
    })
    const projectId = projectData?.id || ''
    const [step, setStep] = useState(initStep)
    const [patchingApiKey, setPatchingApiKey] = useState<APIKey>()

    const { handleCancel } = useDirtyFormCheckerControls(onCancel)

    const resetStates = () => {
        setStep(initStep)
        setPatchingApiKey(undefined)
    }

    const views: Record<Steps, () => JSX.Element> = {
        [Steps.edit]: () => {
            const onSuccess = ({ name, permissions }: EditApiKeyData) => {
                selectedApiKey && setPatchingApiKey({ ...selectedApiKey, name, permissions })
                setStep(Steps.twoFaConfirmation)
            }

            return (
                <EditApiKeyForm onSuccess={onSuccess} onDeleteClick={onDeleteClick} selectedApiKey={selectedApiKey} />
            )
        },
        [Steps.twoFaConfirmation]: () => {
            const onSuccess = async (totp: TOTPCredentials, form: FormInstance) => {
                const apiKeyId = selectedApiKey?.id || ''
                try {
                    const response = await projectApi.updateAPIKey({
                        apiKeyId,
                        projectId,
                        updateAPIKeyRequest: {
                            name: patchingApiKey?.name,
                            permissions: patchingApiKey?.permissions,
                            ...totp,
                        },
                    })
                    onModalSuccess(response)
                } catch (error) {
                    handleError({
                        form,
                        error,
                    })
                }
            }

            return (
                <TwoFaConfirmationForm
                    mode="edit-api-key"
                    onSuccess={onSuccess}
                    submitButtonTitle={lang.twoFaConfirmButtonTitle}
                />
            )
        },
    }

    return (
        <ModalResponsive
            data-merchant="edit-api-key-modal"
            onCancel={handleCancel}
            destroyOnClose
            afterClose={resetStates}
            footer={null}
            {...rest}
            title={
                <span data-merchant="edit-api-key-modal-title">
                    <FormattedMessage {...lang.modalTitle} />
                </span>
            }
        >
            {views[step]()}
        </ModalResponsive>
    )
}
