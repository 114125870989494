import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    youSend: {
        id: 'convert.modal.you_send',
        defaultMessage: 'You send',
    },
    youGet: {
        id: 'convert.modal.you_get',
        defaultMessage: 'You get',
    },
    exchangeRate: {
        id: 'convert.modal.exchange_rate',
        defaultMessage: 'Exchange rate',
    },
    convert: {
        id: 'convert.modal.convert',
        defaultMessage: 'Convert',
    },
    pendingTitle: {
        id: 'convert.modal.pending_title',
        defaultMessage: 'Convert in progress',
    },
    pendingText: {
        id: 'convert.modal.pending_text',
        defaultMessage: 'You can close this window. Details can be viewed in the {TransactionsLink} tab.',
    },
    successTitle: {
        id: 'convert.typography.success_title',
        defaultMessage: 'Convert completed',
    },
    successText: {
        id: 'convert.typography.success_text',
        defaultMessage:
            'Funds have been converted. Details are available in the {TransactionsLink} tab. You can close this window.',
    },
    failedTitle: {
        id: 'convert.typography.failed_title',
        defaultMessage: 'Convert failed',
    },
    failedText: {
        id: 'convert.typography.failed_text',
        defaultMessage:
            'Please check the payment details and try again. The attempt has been recorded in the {TransactionsLink} tab.',
    },
})
