import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    withdrawalSummaryTitle: {
        id: 'withdrawal-fiat.typography.withdrawal_summary_title',
        defaultMessage: 'Withdraw {amount} {currency}',
    },
    withdrawalSummarySubtitle: {
        id: 'withdrawal-fiat.typography.withdrawal_summary_subtitle',
        defaultMessage: 'It will be handled within 1-3 business days depending on your bank',
    },
    transactionId: {
        id: 'withdrawal-fiat.typography.transaction_id',
        defaultMessage: 'Transaction ID',
    },
    withdrawalAmount: {
        id: 'withdrawal-fiat.typography.withdrawal_amount',
        defaultMessage: 'Withdrawal amount',
    },
    withdrawalFinishSuccessTitle: {
        id: 'withdrawal-fiat.typography.withdrawal_finish_success_title',
        defaultMessage: 'Request sent',
    },
    withdrawalFinishSuccessSubtitle: {
        id: 'withdrawal-fiat.typography.withdrawal_finish_success_subtitle',
        defaultMessage:
            'Your withdrawal request was sent and it will be handled within 1-3 business days depending on your bank. In case of any questions, our team will contact you via {supportEmail}',
    },
    withdrawalFinishFailTitle: {
        id: 'withdrawal-fiat.typography.withdrawal_finish_fail_title',
        defaultMessage: 'Withdrawal failed',
    },
    withdrawalFinishFailSubtitle: {
        id: 'withdrawal-fiat.typography.withdrawal_finish_fail_subtitle',
        defaultMessage:
            'Please check the payment details and try again. The attempt has been recorded in the <link>Transactions</link> tab.',
    },
})
