import { EmailSupportLink } from '@merchant/shared/components'
import { Form, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage, useIntl } from 'react-intl'
import { EmailConfirmationFields } from './const'
import { lang } from './lang'
import type { FormItemProps } from '@merchant/ui-kit/ant-design'
import { ResendInput } from '~components'
import { globalLang } from '~globalLang'

const { Text } = Typography

const formItemExtraStyles: React.CSSProperties = { marginTop: 4 }

interface Props extends FormItemProps {
    email: string
    name?: EmailConfirmationFields
    onBlur?: () => void
    onResendClick: () => Promise<void>
    shouldSendOnMount?: boolean
    dataMerchant: string
    noExtra?: boolean
    isInitiallySent?: boolean
    shouldFocusOnMount?: boolean
}

export function EmailConfirmationItem({
    email,
    onBlur,
    onResendClick,
    name = EmailConfirmationFields.verificationCode,
    shouldSendOnMount = false,
    dataMerchant,
    noExtra = false,
    isInitiallySent,
    shouldFocusOnMount,
    ...rest
}: Props) {
    const intl = useIntl()

    const emailCodeInputPlaceholder = intl.formatMessage(lang.emailCodeInputPlaceholder)

    return (
        <Form.Item
            label={
                <span>
                    <Text data-merchant={`${dataMerchant}-email-text`}>
                        <FormattedMessage
                            {...globalLang.enterCodeSentTo}
                            values={{
                                email: (
                                    <Text data-merchant={`${dataMerchant}-email`} style={{ opacity: 0.7 }}>
                                        {email}
                                    </Text>
                                ),
                            }}
                        />
                    </Text>
                </span>
            }
            name={name}
            rules={[
                {
                    required: true,
                    message: (
                        <span data-merchant={`${dataMerchant}-required-error-message`}>
                            <FormattedMessage {...globalLang.requiredFieldMessage} />
                        </span>
                    ),
                },
            ]}
            extra={
                noExtra ? null : (
                    <div style={formItemExtraStyles}>
                        <FormattedMessage {...lang.noAccessEmail} />{' '}
                        <EmailSupportLink message={globalLang.contactSupport} />
                    </div>
                )
            }
            {...rest}
        >
            <ResendInput
                shouldFocusOnMount={shouldFocusOnMount}
                data-merchant={dataMerchant}
                shouldSendOnMount={shouldSendOnMount}
                onBlur={onBlur}
                placeholder={emailCodeInputPlaceholder}
                onResendClick={onResendClick}
                isInitiallySent={isInitiallySent}
            />
        </Form.Item>
    )
}
