import { LinkAsButton, NotificationMessage } from '@merchant/shared/components'
import { useNotifications } from '@merchant/shared/contexts'
import { parseDateAndTime } from '@merchant/shared/utils'
import { Flex, Table, Row, Col, Typography } from '@merchant/ui-kit/ant-design'
import { isEmpty } from 'lodash-es'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
    MemberName,
    MemberEmail,
    RoleTag,
    MemberActionButtons,
    DeleteMemberModal,
    EditMemberModal,
} from '../../components'
import { blockHeaderCommonButtonStyles } from '../../const'
import { lang } from '../../lang'
import { MerchantTeamShowOptions } from '../../types'
import styles from './style.module.css'
import { processActionModalSuccess } from './utils'
import type { ActionModalType, OnActionButtonClick } from './types'
import type { TableColumnProps, TableColumnsType } from '@merchant/ui-kit/ant-design'
import type { ProjectRole, ProjectTeamMember } from '~api/instances/cabinet-api'
import { useProject, useProjectRoles, useProjectTeamMembers } from '~api'
import { Permission } from '~api/instances/cabinet-api'
import { EmptyTable, NoValueColumnContent } from '~components'
import { ownerRole } from '~constants/roles'
import { useUserPermissionContext } from '~contexts'
import { generateQuery } from '~utils'

const { Title, Text, Paragraph } = Typography

const onColumnHeaderCell: TableColumnProps<ProjectTeamMember>['onHeaderCell'] = () => ({
    style: { paddingBlock: 20 },
    className: styles.headerCell,
})

const onCell: TableColumnProps<ProjectTeamMember>['onCell'] = () => ({
    style: { alignContent: 'center', wordBreak: 'break-word' },
})

const getColumns = (
    projectRoles: ProjectRole[] | undefined,
    onActionButtonClick: OnActionButtonClick,
    isEditMembersAllowed: boolean
): TableColumnsType<ProjectTeamMember> => {
    return [
        {
            width: 230,
            key: 'name',
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            title: <FormattedMessage {...lang.name} />,
            onHeaderCell: onColumnHeaderCell,
            render: (_, member) => <MemberName member={member} />,
            onCell,
        },
        {
            width: 300,
            key: 'email',
            dataIndex: 'email',
            sorter: (a, b) => a.email.localeCompare(b.email),
            title: <FormattedMessage {...lang.email} />,
            render: (_, member) => <MemberEmail member={member} />,
            onHeaderCell: onColumnHeaderCell,
            onCell,
        },
        {
            key: 'roles',
            width: 220,
            dataIndex: 'roles',
            filterMultiple: true,
            filterMode: 'menu',
            filters: projectRoles?.map(role => ({
                text: role.name || '',
                value: role.id || '',
            })),
            onFilter: (value, record) => record.roles.includes(String(value)),
            render: (roles: ProjectTeamMember['roles'], { id }) => (
                <Flex gap={4} wrap="wrap">
                    {roles.map(role => (
                        <RoleTag key={role} role={role} id={id} />
                    ))}
                </Flex>
            ),
            title: <FormattedMessage {...lang.role} />,
            onHeaderCell: onColumnHeaderCell,
            onCell,
        },
        {
            width: 130,
            key: 'createdAt',
            dataIndex: 'createdAt',
            sorter: (a, b) => a.createdAt.getTime() - b.createdAt.getTime(),
            render: (createdAt: ProjectTeamMember['createdAt'], record) => {
                const parsedDate = parseDateAndTime(createdAt)
                if (!parsedDate) {
                    return NoValueColumnContent
                }

                return (
                    <>
                        <Paragraph
                            style={{ marginBottom: 0, fontSize: 'inherit' }}
                            data-merchant={`team-management-${record.id}-created-date`}
                        >
                            {parsedDate.date}
                        </Paragraph>
                        {!!parsedDate.time && (
                            <Text
                                type="secondary"
                                style={{ fontSize: 'inherit' }}
                                data-merchant={`team-management-${record.id}-created-time`}
                            >
                                {parsedDate.time}
                            </Text>
                        )}
                    </>
                )
            },
            title: <FormattedMessage {...lang.created} />,
            onHeaderCell: onColumnHeaderCell,
            onCell,
        },
        {
            key: 'actions',
            title: <FormattedMessage {...lang.actions} />,
            onHeaderCell: onColumnHeaderCell,
            onCell,
            width: 100,
            align: 'end',
            render: (_, member) => (
                <MemberActionButtons
                    member={member}
                    onActionButtonClick={onActionButtonClick}
                    hidden={member.roles.includes(ownerRole.id) || !isEditMembersAllowed}
                />
            ),
        },
    ]
}

interface Props {
    resolveBlocklist: (cb?: () => void) => boolean
}

// eslint-disable-next-line max-lines-per-function
export function DataView({ resolveBlocklist }: Props) {
    const { checkUserPermission } = useUserPermissionContext()
    const { data: teamMembers, isLoading: isTeamMembersLoading, mutate: mutateTeamMembers } = useProjectTeamMembers()
    const { data: projectRoles } = useProjectRoles()
    const { data: project } = useProject()
    const [activeModal, setActiveModal] = useState<ActionModalType | null>(null)
    const [selectedMember, setSelectedMember] = useState<ProjectTeamMember>()
    const {
        notification: {
            api: { open: openNotification },
        },
    } = useNotifications()

    const resetModalData = () => {
        setActiveModal(null)
        setSelectedMember(undefined)
    }

    const handleActionButtonClick: OnActionButtonClick = (mode, member) => {
        resolveBlocklist(() => {
            setActiveModal(mode)
            setSelectedMember(member)
        })
    }

    const actionModalOnSuccess = (modalType: ActionModalType) => (member: ProjectTeamMember | undefined) => {
        processActionModalSuccess(mutateTeamMembers, modalType, member)
        resetModalData()
        openNotification({
            message: (
                <NotificationMessage
                    type="success"
                    title={<FormattedMessage {...lang.changesSavedNotification} />}
                    dataMerchant="team-management-changes-saved-success-notification"
                />
            ),
        })
    }

    const editRolesButtonMessageDescriptor = checkUserPermission(Permission.ChangeProjectRoles)
        ? lang.editRolesButton
        : lang.showRolesButton

    const emptyTableDescriptor = checkUserPermission(Permission.SeeProjectTeamMembers)
        ? lang.noMembersYet
        : lang.noPermissionsToSeeMembers

    const isEditMembersAllowed = checkUserPermission(Permission.ChangeProjectTeamMembers)

    const editRolesQuery = generateQuery({ show: MerchantTeamShowOptions.editRoles })
    const addMemberQuery = generateQuery({ show: MerchantTeamShowOptions.addMembers })

    return (
        <>
            <DeleteMemberModal
                projectId={project?.id}
                onCancel={resetModalData}
                open={activeModal === 'delete'}
                selectedMember={selectedMember}
                onSuccess={actionModalOnSuccess('delete')}
                data-merchant="team-management-delete-member-modal"
            />
            <EditMemberModal
                project={project}
                onCancel={resetModalData}
                open={activeModal === 'edit'}
                selectedMember={selectedMember}
                onSuccess={actionModalOnSuccess('edit')}
            />
            <Table<ProjectTeamMember>
                loading={isTeamMembersLoading}
                virtual
                locale={{
                    emptyText: (
                        <Title level={5} style={{ padding: 12 }} data-merchant="team-management-empty-title">
                            {!isTeamMembersLoading && <EmptyTable subtitleDescriptor={emptyTableDescriptor} />}
                        </Title>
                    ),
                }}
                data-merchant="team-management-table"
                pagination={false}
                tableLayout="fixed"
                rowKey="id"
                className={styles.table}
                dataSource={teamMembers}
                showHeader={!isTeamMembersLoading && !isEmpty(teamMembers)}
                title={() => (
                    <>
                        <Row justify="space-between" align="middle" wrap={false} gutter={20}>
                            <Col>
                                <Title level={5} data-merchant="team-management-table-title">
                                    <FormattedMessage {...lang.membersTableTitle} />
                                </Title>
                                <Text type="secondary" data-merchant={null}>
                                    <FormattedMessage {...lang.membersTableDescription} />
                                </Text>
                            </Col>
                            <Col>
                                <Flex gap={12}>
                                    {checkUserPermission(Permission.SeeProjectRoles) && (
                                        <LinkAsButton
                                            to={editRolesQuery}
                                            buttonProps={{
                                                style: blockHeaderCommonButtonStyles,
                                                'data-merchant': 'team-management-edit-roles-button',
                                                onClick: e => (resolveBlocklist() ? e.preventDefault() : undefined),
                                            }}
                                        >
                                            <FormattedMessage {...editRolesButtonMessageDescriptor} />
                                        </LinkAsButton>
                                    )}
                                    {isEditMembersAllowed && (
                                        <LinkAsButton
                                            to={addMemberQuery}
                                            buttonProps={{
                                                type: 'primary',
                                                style: blockHeaderCommonButtonStyles,
                                                'data-merchant': 'team-management-add-member-button',
                                                onClick: e => (resolveBlocklist() ? e.preventDefault() : undefined),
                                            }}
                                        >
                                            <FormattedMessage {...lang.addMemberButton} />
                                        </LinkAsButton>
                                    )}
                                </Flex>
                            </Col>
                        </Row>
                    </>
                )}
                onRow={({ id }) => ({ 'data-merchant': `${id}-team-management-table-row`, style: undefined })}
                scroll={{ y: 550 }}
                showSorterTooltip={false}
                columns={getColumns(projectRoles, handleActionButtonClick, isEditMembersAllowed)}
            />
        </>
    )
}
