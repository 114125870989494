/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Merchant
 */
export interface Merchant {
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    status: MerchantStatus;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    language?: string;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    timeZone?: string;
    /**
     * Deprecated and always returns true, see Project.fiat_withdrawal.
     * @type {boolean}
     * @memberof Merchant
     * @deprecated
     */
    fiatWithdrawal: boolean;
}

/**
* @export
* @enum {string}
*/
export enum MerchantStatus {
    New = 'NEW',
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
    Disabled = 'DISABLED'
}


/**
 * Check if a given object implements the Merchant interface.
 */
// @ts-ignore
export function instanceOfMerchant(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "fiatWithdrawal" in value;

    return isInstance;
}

// @ts-ignore
export function MerchantFromJSON(json: any): Merchant {
    return MerchantFromJSONTyped(json, false);
}

// @ts-ignore
export function MerchantFromJSONTyped(json: any, ignoreDiscriminator: boolean): Merchant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'status': json['status'],
        'email': json['email'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'timeZone': !exists(json, 'time_zone') ? undefined : json['time_zone'],
        'fiatWithdrawal': json['fiat_withdrawal'],
    };
}

// @ts-ignore
export function MerchantToJSON(value?: Merchant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'status': value.status,
        'email': value.email,
        'name': value.name,
        'currency': value.currency,
        'language': value.language,
        'time_zone': value.timeZone,
        'fiat_withdrawal': value.fiatWithdrawal,
    };
}

