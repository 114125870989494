import { Skeleton, Space } from '@merchant/ui-kit/ant-design'
import type { SkeletonProps } from '@merchant/ui-kit/ant-design'

const spaceStyles: React.CSSProperties = { width: '100%' }
const skeletonAvatar: SkeletonProps['avatar'] = {
    size: 10,
    style: { marginTop: 3 },
}
const titleStyles: React.CSSProperties = { padding: 0, margin: 0 }
const paragraphStyles: React.CSSProperties = { marginTop: 16 }

export function TimelineSkeletons() {
    return (
        <Space direction="vertical" size={20} style={spaceStyles}>
            <Skeleton
                active
                avatar={skeletonAvatar}
                title={{
                    width: '40%',
                    style: titleStyles,
                }}
                paragraph={{
                    rows: 2,
                    style: paragraphStyles,
                }}
            />
            <Skeleton
                active
                avatar={skeletonAvatar}
                title={{
                    width: '70%',
                    style: titleStyles,
                }}
                paragraph={{
                    rows: 3,
                    style: paragraphStyles,
                }}
            />
            <Skeleton
                active
                avatar={skeletonAvatar}
                title={{
                    width: '60%',
                    style: titleStyles,
                }}
                paragraph={{
                    rows: 3,
                    style: paragraphStyles,
                }}
            />
            <Skeleton
                active
                avatar={skeletonAvatar}
                title={{
                    width: '72%',
                    style: titleStyles,
                }}
                paragraph={{
                    rows: 4,
                    style: paragraphStyles,
                }}
            />
        </Space>
    )
}
