/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CustomerAddress } from './CustomerAddress';
import {
    CustomerAddressFromJSON,
    CustomerAddressFromJSONTyped,
    CustomerAddressToJSON,
} from './CustomerAddress';

/**
 * 
 * @export
 * @interface ListAddresses200Response
 */
export interface ListAddresses200Response {
    /**
     * 
     * @type {string}
     * @memberof ListAddresses200Response
     */
    cursorNext?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAddresses200Response
     */
    cursorPrev?: string;
    /**
     * 
     * @type {Array<CustomerAddress>}
     * @memberof ListAddresses200Response
     */
    addresses: Array<CustomerAddress>;
}

/**
 * Check if a given object implements the ListAddresses200Response interface.
 */
// @ts-ignore
export function instanceOfListAddresses200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "addresses" in value;

    return isInstance;
}

// @ts-ignore
export function ListAddresses200ResponseFromJSON(json: any): ListAddresses200Response {
    return ListAddresses200ResponseFromJSONTyped(json, false);
}

// @ts-ignore
export function ListAddresses200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListAddresses200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cursorNext': !exists(json, 'cursor_next') ? undefined : json['cursor_next'],
        'cursorPrev': !exists(json, 'cursor_prev') ? undefined : json['cursor_prev'],
        'addresses': ((json['addresses'] as Array<any>).map(CustomerAddressFromJSON)),
    };
}

// @ts-ignore
export function ListAddresses200ResponseToJSON(value?: ListAddresses200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cursor_next': value.cursorNext,
        'cursor_prev': value.cursorPrev,
        'addresses': ((value.addresses as Array<any>).map(CustomerAddressToJSON)),
    };
}

