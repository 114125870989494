import dayjs from 'dayjs'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import type { DefaultOptionType } from '@merchant/ui-kit/ant-design/es/select'
import type { Dayjs } from 'dayjs'

export const filterFormKeys = {
    currencies: 'currencies',
    date: 'date',
    dateType: 'dateType',
    showZeroes: 'showZeroes',
} as const

export const enum DateTypes {
    realtime,
    custom,
}

export const dateTypesOptions = [
    { label: <FormattedMessage {...lang.realtimeDate} />, value: DateTypes.realtime },
    { label: <FormattedMessage {...lang.customDate} />, value: DateTypes.custom },
]

export type FilterForm = {
    currencies: string[] | undefined
    date: Dayjs | undefined
    dateType: DateTypes
    showZeroes: 'hidden' | 'visible'
}

export const initialFormValues: FilterForm = {
    currencies: undefined,
    date: dayjs(),
    dateType: DateTypes.realtime,
    showZeroes: 'hidden',
}

export const zeroBalanceOptions: DefaultOptionType[] = [
    { label: <FormattedMessage {...lang.hideZeroBalances} />, value: 'hidden' },
    { label: <FormattedMessage {...lang.showZeroBalances} />, value: 'visible' },
]
