import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    fiatCurrencies: {
        id: 'convert-input.select.fiat_currencies',
        defaultMessage: 'Fiat currencies',
    },
    cryptoCurrencies: {
        id: 'convert-input.select.crypto_currencies',
        defaultMessage: 'Crypto currencies',
    },
})
