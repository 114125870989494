import { useFormValidation, usePromise } from '@merchant/shared/hooks'
import { Button, Divider, Form } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang as commonLang } from '../../lang'
import { lang } from './lang'
import type { ConfirmTOTPOperationRequest, ConfirmTOTPRequest, TOTP } from '~api/instances/cabinet-api'
import { authApi } from '~api'
import { GenerateVerificationCodeRequestPurpose as VerificationCodePurpose } from '~api/instances/cabinet-api'
import { ModalNames } from '~constants/modal'
import { EmailConfirmationItem, TwoFaConfirmationItem } from '~features/FormItems'
import { useRegisterDirtyFormChecker } from '~hooks'

interface Props {
    id: NonNullable<TOTP['id']>
    isTwoFaEnabled: boolean
    onSuccess: () => void
    email: string
}

export function Confirmation({ onSuccess, isTwoFaEnabled, id, email }: Props) {
    const [form] = Form.useForm()
    const { onBlur, onFinishFailed, validationMode } = useFormValidation(['verificationCode', 'totpCode'])
    useRegisterDirtyFormChecker(ModalNames.add2FaConfirmation, form)
    const { send: confirmSend, isLoading } = usePromise(
        async (data: ConfirmTOTPOperationRequest) => {
            await authApi.confirmTOTP(data)
        },
        { onSuccess }
    )

    const handleFormFinish = (values: ConfirmTOTPRequest) => confirmSend({ confirmTOTPRequest: values, id })

    const onResendClick = () =>
        authApi.generateVerificationCode({
            generateVerificationCodeRequest: {
                purpose: VerificationCodePurpose.Confirm2fa,
            },
        })

    return (
        <Form
            form={form}
            requiredMark={false}
            onFinish={handleFormFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            size="large"
        >
            <EmailConfirmationItem
                dataMerchant="add2fa-confirmation-item"
                validateTrigger={validationMode.verificationCode}
                email={email}
                onBlur={onBlur('verificationCode')}
                onResendClick={onResendClick}
                shouldSendOnMount
            />
            {isTwoFaEnabled && (
                <>
                    <Divider />
                    <TwoFaConfirmationItem
                        dataMerchant="add2fa-2fa-confirmation-item"
                        label={<FormattedMessage {...lang.previousTwoFaLabel} />}
                        onBlur={onBlur('totpCode')}
                    />
                </>
            )}
            <Form.Item noStyle>
                <Button
                    data-merchant="add2fa-confirm-continue-button"
                    type="primary"
                    htmlType="submit"
                    block
                    loading={isLoading}
                >
                    <FormattedMessage {...commonLang.continue} />
                </Button>
            </Form.Item>
        </Form>
    )
}
