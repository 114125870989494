/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WithdrawalAmountCredit
 */
export interface WithdrawalAmountCredit {
    /**
     * 
     * @type {string}
     * @memberof WithdrawalAmountCredit
     */
    credit: string;
}

/**
 * Check if a given object implements the WithdrawalAmountCredit interface.
 */
// @ts-ignore
export function instanceOfWithdrawalAmountCredit(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "credit" in value;

    return isInstance;
}

// @ts-ignore
export function WithdrawalAmountCreditFromJSON(json: any): WithdrawalAmountCredit {
    return WithdrawalAmountCreditFromJSONTyped(json, false);
}

// @ts-ignore
export function WithdrawalAmountCreditFromJSONTyped(json: any, ignoreDiscriminator: boolean): WithdrawalAmountCredit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'credit': json['credit'],
    };
}

// @ts-ignore
export function WithdrawalAmountCreditToJSON(value?: WithdrawalAmountCredit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'credit': value.credit,
    };
}

