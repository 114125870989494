import { Flex, Skeleton, Space, Typography } from '@merchant/ui-kit/ant-design'
import { isEmpty } from 'lodash-es'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { AmountWithSign } from './AmountWithSign'
import { getActionButtons } from './getActionButtons'
import { lang } from './lang'
import { getWithdrawButtonActions } from './utils'
import type { SpaceProps } from '@merchant/ui-kit/ant-design'
import { useBalances, useCurrencies } from '~api'
import { Permission } from '~api/instances/cabinet-api'
import { useUserPermissionContext } from '~contexts'
import { useSwapEnabled } from '~hooks'

const { Text } = Typography

const wrapperStyles: React.CSSProperties = { marginBottom: 12 }
const bottomSpaceStyles: React.CSSProperties = {
    marginTop: 16,
    display: 'flex',
}
const bottomSpaceItemsStyles: SpaceProps['styles'] = {
    item: { flex: '1 1 0px' },
}

interface Props {
    isInHeader?: boolean
}

export function TotalBalance({ isInHeader = false }: Props) {
    const { checkUserPermission } = useUserPermissionContext()
    const {
        data: balances = [],
        isLoading: isLoadingBalances,
        error: loadingBalancesError,
        mutate: mutateBalances,
        isValidating: isValidatingBalances,
    } = useBalances()
    const { data: currencies } = useCurrencies()

    const isSwapEnabled = useSwapEnabled()
    const isSwapAllowed = checkUserPermission(Permission.SeeSwapFlow)
    const canSwap = isSwapEnabled && isSwapAllowed
    const canDeposit = checkUserPermission(Permission.CreateDepositAddress)
    const withdrawButtonActions = getWithdrawButtonActions(currencies, checkUserPermission)

    const actionButtons = getActionButtons({
        loadingBalancesError,
        withdrawButtonActions,
        canSwap,
        canDeposit,
        isLoadingBalances,
        mutateBalances,
        isValidatingBalances,
    })

    return (
        <Flex vertical style={!isInHeader ? wrapperStyles : undefined}>
            {checkUserPermission(Permission.SeeBalance) && (
                <>
                    <Text
                        data-merchant="total-balance-label"
                        type="secondary"
                        style={{ fontSize: 12, marginBottom: 4 }}
                    >
                        <FormattedMessage {...lang.totalBalance} />
                    </Text>
                    <Skeleton
                        paragraph={false}
                        active
                        style={{ marginTop: 10, height: 20 }}
                        loading={isLoadingBalances && isEmpty(balances)}
                    >
                        {!loadingBalancesError ? (
                            <AmountWithSign isInHeader={isInHeader} />
                        ) : (
                            <Text
                                data-merchant="total-balance-error"
                                type="secondary"
                                style={{ fontSize: 16, opacity: 0.5 }}
                            >
                                <FormattedMessage {...lang.unavailable} />
                            </Text>
                        )}
                    </Skeleton>
                </>
            )}
            {!isInHeader && (!!withdrawButtonActions || canSwap) && (
                <Space style={bottomSpaceStyles} styles={bottomSpaceItemsStyles}>
                    {actionButtons}
                </Space>
            )}
        </Flex>
    )
}
