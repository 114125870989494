import { Button, Col, Form, Input, Row, Space, Typography } from '@merchant/ui-kit/ant-design'
import React, { useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocalStorage } from 'usehooks-ts'
import { useCurrencyOptions } from './hooks'
import { lang } from './lang'
import type { FilterFormData } from '../../types'
import { ActiveFilters, NetworkSelect, RangePickerResponsive, SearchableSelect, CollapseGapped } from '~components'
import { addressesFilterKey } from '~constants/localStorage'

const { Text } = Typography
const formItemStyles: React.CSSProperties = { marginBottom: 0 }
const rangePickerStyles: React.CSSProperties = { width: '100%' }

const collapseKey = 'orders'

interface Props {
    isLoading: boolean
    onFinish: (values: FilterFormData) => void
    onReset: () => Promise<void>
    extra?: React.ReactNode
    activeFiltersCount: number
    initialValues: Partial<FilterFormData>
}
// eslint-disable-next-line max-lines-per-function
export function Filter({ onFinish, onReset, isLoading, activeFiltersCount, extra, initialValues }: Props) {
    const [isOpen, setIsOpen] = useLocalStorage(addressesFilterKey, true)
    const { isLoading: isLoadingCurrencies, options } = useCurrencyOptions()
    const intl = useIntl()
    const [form] = Form.useForm()
    const handleClearClick = async () => {
        await onReset()
        form.resetFields()
    }

    useEffect(() => {
        form.setFieldsValue(initialValues)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValues])

    const hasFilteredActiveCount = !isOpen && activeFiltersCount > 0

    return (
        <CollapseGapped
            onChange={keys => setIsOpen(keys.includes(collapseKey))}
            bordered={false}
            defaultActiveKey={isOpen ? collapseKey : undefined}
            data-merchant="deposit-addresses-filters-collapse"
            items={[
                {
                    key: collapseKey,
                    extra,
                    label: (
                        <div data-merchant="deposit-addresses-filter-collapse">
                            <Space direction="horizontal">
                                <FormattedMessage {...lang.collapseTitle} />{' '}
                                {hasFilteredActiveCount && (
                                    <ActiveFilters
                                        dataMerchant="deposit-addresses"
                                        amount={activeFiltersCount}
                                        onClear={handleClearClick}
                                    />
                                )}
                            </Space>
                        </div>
                    ),
                    children: (
                        <Form form={form} onFinish={onFinish} initialValues={initialValues}>
                            <Row justify="start" gutter={[12, 10]} wrap>
                                <Col
                                    xl={{ span: 8 }}
                                    lg={{ span: 8 }}
                                    md={{ span: 24 }}
                                    sm={{ span: 24 }}
                                    xs={{ span: 24 }}
                                >
                                    <Form.Item
                                        style={formItemStyles}
                                        name="date"
                                        label={
                                            <Text type="secondary" data-merchant={null}>
                                                <FormattedMessage {...lang.createdAt} />
                                            </Text>
                                        }
                                    >
                                        <RangePickerResponsive
                                            id={{
                                                start: 'deposit-addresses-filter-created-start-input',
                                                end: 'deposit-addresses-filter-created-end-input',
                                            }}
                                            picker="date"
                                            style={rangePickerStyles}
                                            allowClear={false}
                                            allowEmpty={[true, true]}
                                            placement="bottomLeft"
                                            data-merchant="deposit-addresses-filter-created-range-picker"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8} lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                    <Form.Item style={formItemStyles} name="id">
                                        <Input
                                            data-merchant="deposit-addresses-filter-id-input"
                                            placeholder={intl.formatMessage(lang.id)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8} lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                    <Form.Item style={formItemStyles} name="currency">
                                        <SearchableSelect
                                            optionFilterProp="key"
                                            loading={isLoadingCurrencies}
                                            options={options}
                                            placeholder={intl.formatMessage(lang.currency)}
                                            data-merchant="deposit-addresses-filter-currency-select"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={7} lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                    <Form.Item style={formItemStyles} name="network">
                                        <NetworkSelect
                                            showSearch
                                            optionFilterProp="key"
                                            data-merchant="deposit-addresses-filter-network-select"
                                            placeholder={intl.formatMessage(lang.network)}
                                            sorter={networks =>
                                                networks.toSorted((a, b) => a.code.localeCompare(b.code))
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={7} lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                    <Form.Item style={formItemStyles} name="address">
                                        <Input
                                            autoComplete="off"
                                            data-merchant="deposit-addresses-filter-address-input"
                                            placeholder={intl.formatMessage(lang.address)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={7} lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                    <Form.Item style={formItemStyles} name="addressTag">
                                        <Input
                                            autoComplete="off"
                                            data-merchant="deposit-addresses-filter-tag-input"
                                            placeholder={intl.formatMessage(lang.tag)}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xl={{ span: 3 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }}>
                                    <Form.Item style={formItemStyles}>
                                        <Button
                                            data-merchant="deposit-addresses-filter-submit-button"
                                            htmlType="submit"
                                            type="primary"
                                            block
                                            loading={isLoading}
                                        >
                                            <FormattedMessage {...lang.apply} />
                                        </Button>
                                    </Form.Item>
                                </Col>
                                <Col xl={{ span: 3 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }}>
                                    <Form.Item style={formItemStyles}>
                                        <Button
                                            data-merchant="deposit-addresses-filter-clear-button"
                                            onClick={handleClearClick}
                                            block
                                        >
                                            <FormattedMessage {...lang.clear} />
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    ),
                },
            ]}
        />
    )
}
