import { ConfigProvider } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { ListView } from './ListView'
import { TableView } from './TableView'
import type { ThemeConfig } from '@merchant/ui-kit/ant-design'
import type { PaymentThumb } from '~api/instances/cabinet-api'
import { ResponsiveRenderer } from '~components/ResponsiveRenderer'

const configProviderTheme: ThemeConfig = {
    token: { fontSize: 12 },
    components: { Statistic: { contentFontSize: 12 } },
}

interface Props {
    orders: PaymentThumb[]
    showMoreButton: React.ReactNode
    isLoading: boolean
    isDataEmpty: boolean
}

export function DataRenderer({ orders, showMoreButton, isLoading, isDataEmpty }: Props) {
    return (
        <ConfigProvider theme={configProviderTheme}>
            <ResponsiveRenderer
                defaultNode={
                    <TableView
                        isLoading={isLoading}
                        orders={orders}
                        showMoreButton={showMoreButton}
                        isDataEmpty={isDataEmpty}
                    />
                }
                sm={<ListView orders={orders} showMoreButton={showMoreButton} />}
            />
        </ConfigProvider>
    )
}
