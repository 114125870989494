import type { FinishStatuses, WithdrawalFiatFormData } from '../../types'
import type { assets } from '@merchant/shared/assets/nameMap'
import type { ReactNode } from 'react'

export enum Context {
    Summary = 'summary',
    Finish = 'finish',
}

export type ContextDetails =
    | {
          context: Context.Summary
      }
    | {
          context: Context.Finish
          status: FinishStatuses
      }

type HeaderItems = {
    iconName: keyof typeof assets
    headerTitle: ReactNode
    headerSubtitle: ReactNode
}

export type GetContextInfo = (
    currency: WithdrawalFiatFormData['currency'],
    amount: WithdrawalFiatFormData['amount']
) => Record<Context.Summary, HeaderItems> & Record<Context.Finish, Record<FinishStatuses, HeaderItems>>
