/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Payin
 */
export interface Payin {
    /**
     * 
     * @type {string}
     * @memberof Payin
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof Payin
     */
    fee: string;
    /**
     * 
     * @type {string}
     * @memberof Payin
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof Payin
     */
    network: string;
    /**
     * 
     * @type {string}
     * @memberof Payin
     */
    txnHash: string;
    /**
     * 
     * @type {Date}
     * @memberof Payin
     */
    declaredAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Payin
     */
    confirmedAt?: Date;
}

/**
 * Check if a given object implements the Payin interface.
 */
// @ts-ignore
export function instanceOfPayin(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "fee" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "network" in value;
    isInstance = isInstance && "txnHash" in value;
    isInstance = isInstance && "declaredAt" in value;

    return isInstance;
}

// @ts-ignore
export function PayinFromJSON(json: any): Payin {
    return PayinFromJSONTyped(json, false);
}

// @ts-ignore
export function PayinFromJSONTyped(json: any, ignoreDiscriminator: boolean): Payin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'fee': json['fee'],
        'currency': json['currency'],
        'network': json['network'],
        'txnHash': json['txn_hash'],
        'declaredAt': (new Date(json['declared_at'])),
        'confirmedAt': !exists(json, 'confirmed_at') ? undefined : (new Date(json['confirmed_at'])),
    };
}

// @ts-ignore
export function PayinToJSON(value?: Payin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'fee': value.fee,
        'currency': value.currency,
        'network': value.network,
        'txn_hash': value.txnHash,
        'declared_at': (value.declaredAt.toISOString()),
        'confirmed_at': value.confirmedAt === undefined ? undefined : (value.confirmedAt.toISOString()),
    };
}

