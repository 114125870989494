import { AppCoin } from '@merchant/shared/components'
import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import type { Currencies } from '~api/types'

const { Text } = Typography

interface Props {
    data: Currencies[number]
    extension?: React.ReactNode
    dataMerchant: string
}

export function CurrencyOption({ data, extension, dataMerchant }: Props) {
    const { code = '', name } = data

    return (
        <Flex align="center" data-merchant={dataMerchant.toLowerCase()}>
            <AppCoin currencyCode={code} size="small" style={{ margin: '0 8px 0 2px' }} />
            <Text data-merchant={null} style={{ fontWeight: 700 }}>
                {code}
            </Text>{' '}
            &nbsp;
            <Text data-merchant={null} type="secondary" style={{ marginRight: 'auto' }} ellipsis>
                {name}
            </Text>
            {extension}
        </Flex>
    )
}
