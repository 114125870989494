import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    client: {
        id: 'payment-constructor.radio.client',
        defaultMessage: 'Client',
    },
    merchant: {
        id: 'payment-constructor.radio.merchant',
        defaultMessage: 'Merchant',
    },
    basicSettings: {
        id: 'payment-constructor.collapse.basic_settings',
        defaultMessage: 'Basic settings',
    },
    advancedSettings: {
        id: 'payment-constructor.collapse.advanced_settings',
        defaultMessage: 'Advanced settings',
    },
    invoice: {
        id: 'payment-constructor.select.invoice',
        defaultMessage: 'Invoice',
    },
    invoiceDescription: {
        id: 'payment-constructor.select.invoice_description',
        defaultMessage: 'For orders with specified price',
    },
    staticAddress: {
        id: 'payment-constructor.select.static_address',
        defaultMessage: 'Static address',
    },
    staticAddressDescription: {
        id: 'payment-constructor.select.static_address_description',
        defaultMessage: 'For a regular customer',
    },
    deposit: {
        id: 'payment-constructor.select.deposit',
        defaultMessage: 'Deposit',
    },
    depositDescription: {
        id: 'payment-constructor.select.deposit_description',
        defaultMessage: 'For orders when the amount is optional',
    },
    createOrder: {
        id: 'payment-constructor.button.create_order',
        defaultMessage: 'Create order',
    },
    expirationTime: {
        id: 'payment-constructor.form.expiration_time',
        defaultMessage: 'Expiration time',
    },
    orderId: {
        id: 'payment-constructor.form.order_id',
        defaultMessage: 'Order ID',
    },
    clientEmail: {
        id: 'payment-constructor.form.client_email',
        defaultMessage: 'Client email',
    },
    clientId: {
        id: 'payment-constructor.form.client_id',
        defaultMessage: 'Client ID',
    },
    successRedirect: {
        id: 'payment-constructor.form.success_redirect',
        defaultMessage: 'Success redirect',
    },
    failRedirect: {
        id: 'payment-constructor.form.fail_redirect',
        defaultMessage: 'Fail redirect',
    },
})
