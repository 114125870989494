import { LoadingOutlined } from '@ant-design/icons'
import { useCommonAppSettings } from '@merchant/shared/api'
import { IntlWrapper, ScrollToTop } from '@merchant/shared/components'
import { authTokenKey } from '@merchant/shared/constants/localStorage'
import { NotificationProvider } from '@merchant/shared/contexts'
import { useColorMode, useThemeVariables } from '@merchant/shared/hooks'
import { Themes, themeTokens } from '@merchant/shared/themes'
import { Spin } from '@merchant/ui-kit/ant-design'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { useReadLocalStorage } from 'usehooks-ts'
import { dynamicRoutes } from './const'
import { getDefaultNavigateTo } from './utils'
import { Main } from '.'
import { routes as AppRoutes } from '~constants/routes'
import { UIContextProvider, useUserPermissionContext } from '~contexts'
import '@merchant/shared/styles/style.css'
import '@merchant/cabinet/src/styles/styles.css'
import '@merchant/shared/styles/antd-override.style.css'
import { ActionModal, AuthorizationPage, ConfigProvider } from '~features'
import { PrivacyPolicyPage } from '~features/StaticPages'
import { AmlKycPage } from '~features/StaticPages/AmlKycPage'
import { Static } from '~features/StaticPages/Static'
import { TermsOfUsePage } from '~features/StaticPages/TermsOfUse'
import { withUserPermissionContextProvider } from '~hoc/withUserPermissionContextProvider'

Spin.setDefaultIndicator(<LoadingOutlined />)

function Component() {
    const location = useLocation()
    const { checkUserPermission, isUserPermissionInitialized } = useUserPermissionContext()
    const authToken = useReadLocalStorage<string>(authTokenKey)
    const { isDark } = useColorMode()
    const initialThemeKey = isDark ? Themes.MerchantNight : Themes.MerchantDay
    useCommonAppSettings()
    useThemeVariables(themeTokens[initialThemeKey].token)

    if (!isUserPermissionInitialized) {
        return null
    }

    const defaultNavigateTo = getDefaultNavigateTo(checkUserPermission, location.search)

    return (
        <IntlWrapper>
            <NotificationProvider>
                <UIContextProvider>
                    <ConfigProvider>
                        <ActionModal />
                        <ScrollToTop />
                        <Routes>
                            <Route path="/" element={<Main />}>
                                {authToken ? (
                                    <>
                                        {dynamicRoutes.map(route => {
                                            if (
                                                !route.oneOfRequiredPermissions ||
                                                checkUserPermission(route.oneOfRequiredPermissions, {
                                                    strategy: 'some',
                                                })
                                            ) {
                                                return <Route key={route.path} {...route} />
                                            }

                                            return null
                                        })}
                                        <Route path="*" element={<Navigate to={defaultNavigateTo} />} />
                                        <Route index element={<Navigate to={defaultNavigateTo} />} />
                                    </>
                                ) : (
                                    <>
                                        <Route path={AppRoutes.auth} element={<AuthorizationPage />} />
                                        <Route index element={<Navigate to={AppRoutes.auth} />} />
                                    </>
                                )}
                            </Route>
                            <Route path="/" element={<Static />}>
                                <Route path={AppRoutes.privacyPolicy} element={<PrivacyPolicyPage />} />
                                <Route path={AppRoutes.termsOfUse} element={<TermsOfUsePage />} />
                                <Route path={AppRoutes.amlkyc} element={<AmlKycPage />} />
                            </Route>
                            <Route
                                path="*"
                                element={<Navigate to={authToken ? AppRoutes.notFound : AppRoutes.auth} />}
                            />
                        </Routes>
                    </ConfigProvider>
                </UIContextProvider>
            </NotificationProvider>
        </IntlWrapper>
    )
}

export const Providers = withUserPermissionContextProvider(Component)
