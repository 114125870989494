import { lang } from './lang'
import type { MessageDescriptor } from 'react-intl'

export type Usage = 'orders' | 'payment' | 'default' | 'withdrawals'

export const descriptors: Record<
    Usage,
    { title: MessageDescriptor; subtitle: MessageDescriptor; description?: MessageDescriptor }
> = {
    orders: {
        title: lang.noMatchTitle,
        subtitle: lang.noOrdersSubtitle,
    },
    payment: {
        title: lang.noMatchTitle,
        subtitle: lang.noMatchPaymentSubtitle,
        description: lang.noMatchPaymentDescription,
    },
    withdrawals: {
        title: lang.noMatchTitle,
        subtitle: lang.noWithdrawalsSubtitle,
        description: lang.noWithdrawalsDescription,
    },
    default: {
        title: lang.noMatchTitle,
        subtitle: lang.noMatchPaymentSubtitle,
        description: lang.noMatchPaymentDescription,
    },
}
