import type { DeleteFormData } from './types'
import type { FormItemsKey } from '@merchant/shared/types'

export const initValues: DeleteFormData = {
    verificationCode: '',
    totpCode: '',
}

export const formItems: FormItemsKey<DeleteFormData> = {
    verificationCode: 'verificationCode',
    totpCode: 'totpCode',
    totpBackup: 'totpBackup',
}
