import cookies from 'js-cookie'
import { bucketPath } from './const'
import type { SWRConfiguration } from 'swr'
import { useCommonAppSettings } from '~api'
import { StrapiThemeFolders } from '~constants'
import { useColorMode } from '~hooks'

const config: SWRConfiguration = {
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    revalidateIfStale: true,
    revalidateOnMount: false,
    refreshInterval: 60 * 1000,
}

type UseAssetSource = (params: {
    name: string
    themed?: boolean
    fallbackFolder?: StrapiThemeFolders
    subPath: string
}) => string

export const useAssetSource: UseAssetSource = ({ themed, subPath, name, fallbackFolder }) => {
    const { isDark } = useColorMode()
    const { data } = useCommonAppSettings(config)
    const { lastMediaLibraryUpdateTimestamp } = data || {}
    const timestampOrCookieFallback = lastMediaLibraryUpdateTimestamp ?? cookies.get('lastMediaLibraryUpdateTimestamp')

    const currentThemeFolder = isDark ? StrapiThemeFolders.night : StrapiThemeFolders.day
    const trimmedLowercaseName = name.toLowerCase().trim()
    const themePath = themed ? currentThemeFolder : fallbackFolder
    const imagePath = [bucketPath, subPath, themePath, trimmedLowercaseName].filter(Boolean).join('/')

    if (timestampOrCookieFallback) {
        return `${imagePath}?_=${timestampOrCookieFallback}`
    }

    return imagePath
}
