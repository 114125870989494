import { assets } from '@merchant/shared/assets/nameMap'
import { StyledQrCode, AppSvg, CopyButton } from '@merchant/shared/components'
import { useFormValidation } from '@merchant/shared/hooks'
import { Button, Col, Form, Input, Popover, Row, Tooltip, Typography, type InputRef } from '@merchant/ui-kit/ant-design'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { API_KEY_TITLE_ALLOWED_LENGTH } from '../../const'
import { transformFormPermissionsToAPI } from '../../utils'
import { ApiKeyPermissionsItem } from '../ApiKeyPermissionsItem'
import { formItemsKey } from './const'
import { lang } from './lang'
import { getInitialEditData } from './utils'
import type { EditApiKeyData, EditApiKeyFormData } from './types'
import type { APIKey } from '~api/instances/cabinet-api'
import { useApiKeyPermissions } from '~api'
import { ModalNames } from '~constants/modal'
import { lang as commonLang } from '~features/IntegrationsPage/views/Integrations/lang'
import { globalLang } from '~globalLang'
import { useAutoFocus, useRegisterDirtyFormChecker } from '~hooks'

const { Text } = Typography

interface Props {
    selectedApiKey: APIKey | null
    onSuccess: (data: EditApiKeyData) => void
    onDeleteClick: () => void
}

// eslint-disable-next-line max-lines-per-function
export function EditApiKeyForm({ selectedApiKey, onSuccess, onDeleteClick }: Props) {
    const [initFormData] = useState(() => (selectedApiKey ? getInitialEditData(selectedApiKey) : {}))
    const { data: permissions = [] } = useApiKeyPermissions()
    const intl = useIntl()
    const [form] = Form.useForm()
    const { onBlur, onFinishFailed, validationMode } = useFormValidation(Object.values(formItemsKey))
    const qrValue = Form.useWatch(formItemsKey.publicKey, form)
    const titleInputRef = useAutoFocus<InputRef>()

    useRegisterDirtyFormChecker(ModalNames.edit2Fa, {
        current: form.getFieldsValue,
        initial: initFormData,
    })

    const handleFormFinish = async ({ name, permissions }: EditApiKeyFormData) => {
        const apiPermissions = transformFormPermissionsToAPI(permissions)
        onSuccess({ name, permissions: apiPermissions })
    }

    return (
        <Form
            form={form}
            layout="vertical"
            size="large"
            onFinishFailed={onFinishFailed}
            onFinish={handleFormFinish}
            initialValues={initFormData}
        >
            <Form.Item
                rules={[
                    {
                        required: true,
                        message: (
                            <span data-merchant="api-key-name-required-filed-error">
                                <FormattedMessage {...globalLang.requiredFieldMessage} />
                            </span>
                        ),
                    },
                    {
                        max: API_KEY_TITLE_ALLOWED_LENGTH,
                        message: (
                            <span data-merchant="api-key-name-max-length-error">
                                <FormattedMessage
                                    {...globalLang.maxAllowedLength}
                                    values={{ length: API_KEY_TITLE_ALLOWED_LENGTH }}
                                />
                            </span>
                        ),
                    },
                ]}
                validateTrigger={validationMode.name}
                name={formItemsKey.name}
                label={<FormattedMessage {...lang.titleLabel} />}
            >
                <Input
                    ref={titleInputRef}
                    maxLength={255}
                    autoComplete="off"
                    placeholder={intl.formatMessage(lang.titlePlaceholder)}
                    onBlur={onBlur(formItemsKey.name)}
                    data-merchant="edit-api-key-name-input"
                />
            </Form.Item>
            <Form.Item label={<FormattedMessage {...lang.publicKeyLabel} />}>
                <Row gutter={12}>
                    <Col flex={1}>
                        <Form.Item name={formItemsKey.publicKey} noStyle>
                            <Input
                                placeholder={intl.formatMessage(lang.publicKeyPlaceholder)}
                                disabled
                                data-merchant="public-key-readonly-input"
                                suffix={
                                    <CopyButton
                                        inputValue={selectedApiKey?.publicKey || ''}
                                        notificationContentSubject={lang.publicKeyLabel}
                                        data-merchant="public-key-readonly-input-copy-button"
                                    />
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Popover
                            overlayStyle={{ textAlign: 'center' }}
                            title={<FormattedMessage {...lang.publicKeyLabel} />}
                            trigger="click"
                            placement="bottom"
                            arrow={false}
                            content={<StyledQrCode bordered value={qrValue} size={200} />}
                            destroyTooltipOnHide
                            data-merchant="public-key-qr-popover"
                        >
                            <Button
                                icon={<AppSvg size={20} name={assets.viewQrCode} />}
                                data-merchant="public-key-qr-button"
                            />
                        </Popover>
                    </Col>
                </Row>
            </Form.Item>
            <Form.Item label={<FormattedMessage {...lang.privateKeyLabel} />}>
                <Row gutter={12}>
                    <Col flex={1}>
                        <Tooltip
                            placement="bottom"
                            title={
                                <Text type="secondary" data-merchant={null}>
                                    <FormattedMessage {...commonLang.privateKeyTooltip} />
                                </Text>
                            }
                            data-merchant="private-key-readonly-input-tooltip"
                        >
                            <div>
                                <Form.Item
                                    name={formItemsKey.privateKeyMask}
                                    getValueProps={value => ({ value: value || '••••••••' })}
                                    noStyle
                                >
                                    <Input disabled data-merchant="private-key-readonly-input" />
                                </Form.Item>
                            </div>
                        </Tooltip>
                    </Col>
                    <Col>
                        <Tooltip
                            placement="bottom"
                            title={
                                <Text type="secondary" data-merchant={null}>
                                    <FormattedMessage {...commonLang.privateKeyTooltip} />
                                </Text>
                            }
                            data-merchant="edit-api-key-private-key-tooltip"
                        >
                            <Button
                                disabled
                                icon={<AppSvg size={20} name={assets.viewQrCode} />}
                                data-merchant="edit-api-key-private-key-qr-button"
                            />
                        </Tooltip>
                    </Col>
                </Row>
            </Form.Item>
            <ApiKeyPermissionsItem permissions={permissions} />
            <Form.Item noStyle>
                <Row gutter={12}>
                    <Col span={12}>
                        <Button block onClick={onDeleteClick} data-merchant="api-key-delete-button">
                            <FormattedMessage {...lang.deleteApiKey} />
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button htmlType="submit" type="primary" block data-merchant="edit-api-key-submit-button">
                            <FormattedMessage {...lang.save} />
                        </Button>
                    </Col>
                </Row>
            </Form.Item>
        </Form>
    )
}
