import { EmailSupportLink, NotificationMessage } from '@merchant/shared/components'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import type { ChangePasswordFormData } from './types'
import type { FormItemsKey } from '@merchant/shared/types'
import { globalLang } from '~globalLang'

export const formItemsKeys: FormItemsKey<ChangePasswordFormData> = {
    newPassword: 'newPassword',
    newPasswordConfirm: 'newPasswordConfirm',
    oldPassword: 'oldPassword',
}

export const notificationMessage = {
    success: (
        <NotificationMessage
            type="success"
            title={<FormattedMessage {...lang.passwordChanged} />}
            description={<FormattedMessage {...lang.passwordChangedDescription} />}
            dataMerchant="password-change-success-notification"
        />
    ),
    error: (
        <NotificationMessage
            type="error"
            title={<FormattedMessage {...globalLang.somethingWentWrong} />}
            description={
                <FormattedMessage
                    {...globalLang.tryAgainLater}
                    values={{
                        SupportLink: <EmailSupportLink />,
                    }}
                />
            }
            dataMerchant="password-change-error-notification"
        />
    ),
}
