import styles from './style.module.css'
import type { ButtonProps } from '@merchant/ui-kit/ant-design'
import type { CSSProperties } from 'react'

const buttonStyles: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 36,
    height: 36,
}

export const headerButtonCommonProps: Omit<ButtonProps, 'data-merchant'> = {
    className: styles.headerButton,
    size: 'middle',
    style: buttonStyles,
    shape: 'circle',
    type: 'default',
}
