import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    pricePolicyTitle: {
        id: 'static-pages.privacy-policy.title',
        defaultMessage: 'Privacy policy',
    },
    privacyPolicyContent: {
        id: 'static-pages.privacy-policy.content',
        defaultMessage: '-',
    },
})
