import { Button, Popconfirm, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from '../lang'
import { globalLang } from '~globalLang'

const { Text } = Typography

interface Props {
    onCancel: () => void
    isCancelling: boolean
}

export function CancellationConfirm({ onCancel, isCancelling }: Props) {
    return (
        <Popconfirm
            placement="bottom"
            title={null}
            icon={null}
            okText={<FormattedMessage {...globalLang.confirm} />}
            cancelText={<FormattedMessage {...globalLang.no} />}
            onConfirm={onCancel}
            okButtonProps={{
                size: 'middle',
                style: { fontSize: 14 },
                loading: isCancelling,
                'data-merchant': 'withdrawal-progress-cancellation-ok-button',
            }}
            cancelButtonProps={{
                size: 'middle',
                style: { fontSize: 14 },
                'data-merchant': 'withdrawal-progress-cancellation-cancel-button',
            }}
            data-merchant="withdrawal-progress-cancellation-confirm"
            overlayStyle={{ maxWidth: 270 }}
            description={
                <Text data-merchant="withdrawal-progress-cancellation-description">
                    <FormattedMessage {...lang.cancellationText} />
                </Text>
            }
        >
            <Button
                block
                size="large"
                type="default"
                onClick={() => null}
                data-merchant="withdrawal-progress-cancel-button"
            >
                <FormattedMessage {...globalLang.cancel} />
            </Button>
        </Popconfirm>
    )
}
