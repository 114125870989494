import Big from 'big.js'
import { DEFAULT_NUMBER_FORMAT_LOCALES, DEFAULT_NUMBER_FORMAT_OPTIONS, defaultIntlNumberFormat } from '~constants'

Big.NE = -21
Big.PE = 42

type FormatNumberParamsValue = Big | string | number | undefined | null

interface FormatNumberParams {
    value: FormatNumberParamsValue
    precision?: number
    roundingMode?: Big.RoundingMode
    numberFormatOptions?: Intl.NumberFormatOptions
}

function normalizeParams(params: FormatNumberParams | FormatNumberParamsValue) {
    if (params instanceof Big || typeof params !== 'object' || !params) {
        return { value: params, roundingMode: Big.roundHalfUp }
    }

    return {
        value: params.value,
        precision: params.precision,
        roundingMode: params.roundingMode || Big.roundHalfUp,
        numberFormatOptions: params.numberFormatOptions,
    }
}

function roundValue(
    value: NonNullable<FormatNumberParamsValue>,
    precision: number,
    roundingMode: Big.RoundingMode
): Big {
    const bigValue = value instanceof Big ? value : new Big(value)

    return bigValue.round(precision, roundingMode)
}

function formatResult(value: Big, numberFormatOptions?: Intl.NumberFormatOptions): string {
    const intlNumberFormat = numberFormatOptions
        ? new Intl.NumberFormat(DEFAULT_NUMBER_FORMAT_LOCALES, {
              ...DEFAULT_NUMBER_FORMAT_OPTIONS,
              ...numberFormatOptions,
          })
        : defaultIntlNumberFormat

    return intlNumberFormat.format(value.toNumber())
}

export function formatNumber(params: FormatNumberParams | FormatNumberParamsValue) {
    const { value, precision, roundingMode, numberFormatOptions } = normalizeParams(params)
    if (!value || Number.isNaN(Number(value))) {
        return value instanceof Big ? value.toString() : value
    }

    try {
        const result = precision ? roundValue(value, precision, roundingMode) : new Big(value)

        return formatResult(result, numberFormatOptions)
    } catch {
        return ''
    }
}
