import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    pageTitle: {
        id: 'addresses-page.typography.title',
        defaultMessage: 'Addresses',
    },
    whitelistTitle: {
        id: 'addresses-page.typography.whitelist',
        defaultMessage: 'Withdrawal whitelist',
    },
    staticAddressesTitle: {
        id: 'addresses-page.typography.static_addresses',
        defaultMessage: 'Static addresses',
    },
    depositAddressesTitle: {
        id: 'addresses-page.typography.deposit_addresses',
        defaultMessage: 'Deposit addresses',
    },
    createDepositAddress: {
        id: 'addresses-page.button.create-deposit-address',
        defaultMessage: 'Create deposit address',
    },
    createStaticAddress: {
        id: 'addresses-page.button.create-address',
        defaultMessage: 'Create static address',
    },
    paymentData: {
        id: 'addresses-page.typography.payment_data',
        defaultMessage: 'Payment data',
    },
})
