import { Form } from '@merchant/ui-kit/ant-design'
import { filterFormKeys } from '../const'
import type { FilterForm } from '../const'
import { useProject } from '~api'

export function useData() {
    const { data: project } = useProject()
    const [form] = Form.useForm<FilterForm>()
    const selectedDateType = Form.useWatch(filterFormKeys.dateType, form)

    return {
        form,
        selectedDateType,
        project,
    }
}
