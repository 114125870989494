import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    pendingTitle: {
        id: 'withdrawal-crypto.typography.pending_title',
        defaultMessage: 'Withdrawal in progress',
    },
    successTitle: {
        id: 'withdrawal-crypto.typography.success_title',
        defaultMessage: 'Withdrawal completed',
    },
    failedTitle: {
        id: 'withdrawal-crypto.typography.failed_title',
        defaultMessage: 'Withdrawal failed',
    },
    pendingText: {
        id: 'withdrawal-crypto.typography.pending_text',
        defaultMessage:
            'You can close this window. Details are available in the <withdrawalLink>Withdrawal details</withdrawalLink>.',
    },
    successText: {
        id: 'withdrawal-crypto.typography.success_text',
        defaultMessage:
            'Funds have been sent. Details are available in the <withdrawalLink>Withdrawal details</withdrawalLink>. You can close this window.',
    },
    failedText: {
        id: 'withdrawal-crypto.typography.failed_text',
        defaultMessage:
            'Please check the payment details and try again. Details are available in the <withdrawalLink>Withdrawal details</withdrawalLink>.',
    },
    requestFailedText: {
        id: 'withdrawal-crypto.typography.request_failed',
        defaultMessage: 'Please try again later or contact {SupportLink}',
    },
    cancelledTitle: {
        id: 'withdrawal-crypto.typography.cancelled_title',
        defaultMessage: 'Withdrawal cancelled',
    },
    cancelledText: {
        id: 'withdrawal-crypto.typography.cancelled_text',
        defaultMessage:
            'The withdrawal attempt has been cancelled. Details can be viewed in the {TransactionsLink} tab.',
    },
    actionModalWithdrawalCryptoTitle: {
        id: 'withdrawal-crypto.modal.withdrawal_title',
        defaultMessage: 'Withdrawal crypto',
    },
    actionModalWithdrawalConfirmationTitle: {
        id: 'withdrawal-crypto.modal.withdrawal_confirmation_title',
        defaultMessage: 'Confirm transaction',
    },
    whitelistName: {
        id: 'withdrawal-crypto.typography.whitelist_name',
        defaultMessage: 'Whitelist name',
    },
    address: {
        id: 'withdrawal-crypto.typography.address',
        defaultMessage: 'Address',
    },
    note: {
        id: 'withdrawal-crypto.typography.note',
        defaultMessage: 'Note',
    },
    receiveCurrency: {
        id: 'withdrawal-crypto.typography.currency',
        defaultMessage: 'Receive currency',
    },
    receiveNetwork: {
        id: 'withdrawal-crypto.typography.network',
        defaultMessage: 'Receive network',
    },
    withdrawalAmount: {
        id: 'withdrawal-crypto.typography.withdrawal_amount',
        defaultMessage: 'Withdrawal amount',
    },
    withdrawalFee: {
        id: 'withdrawal-crypto.typography.withdrawal_fee',
        defaultMessage: 'Withdrawal fee ',
    },
    receiveAmount: {
        id: 'withdrawal-crypto.typography.receive_amount',
        defaultMessage: 'Receive amount',
    },
    withdrawalId: {
        id: 'withdrawal-crypto.typography.withdrawal_id',
        defaultMessage: 'Withdrawal ID',
    },
    withdrawalWhitelistAddress: {
        id: 'withdrawal-crypto.tooltip.withdrawal_whitelist_address',
        defaultMessage: 'Withdrawal whitelist address:',
    },
})
