import type { Breakpoints } from '@merchant/shared/hooks'
import type React from 'react'

export const getCardStyles = (breakpoints: Breakpoints): React.CSSProperties => ({
    maxWidth: 520,
    margin: !breakpoints.md ? '80px auto 0' : '50px auto 0',
})
export const getCardBodyStyles = (breakpoints: Breakpoints): React.CSSProperties => ({
    padding: breakpoints.xs ? '12px 12px' : '32px 40px',
})
export const goBackButtonStyles: React.CSSProperties = {
    height: 24,
    padding: 0,
    verticalAlign: 'top',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginRight: 10,
}

export const getFormStyles = (breakpoints: Breakpoints): React.CSSProperties => ({
    marginTop: breakpoints.xs ? 16 : 24,
})
