import { Form, Input } from '@merchant/ui-kit/ant-design'
import { FormattedMessage, useIntl } from 'react-intl'
import { lang } from '../lang'
import type { FormItemProps } from '@merchant/ui-kit/ant-design'
import { newOrderFormKeys } from '~features/PaymentConstructor/view/OrderConstructor/const'

export function TitleItem(props: FormItemProps) {
    const intl = useIntl()

    return (
        <Form.Item {...props} name={newOrderFormKeys.title} label={<FormattedMessage {...lang.name} />}>
            <Input.TextArea
                placeholder={intl.formatMessage(lang.namePlaceholder)}
                autoSize
                maxLength={255}
                data-merchant="order-name-input"
            />
        </Form.Item>
    )
}
