/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FeesPayer } from './FeesPayer';
import {
    FeesPayerFromJSON,
    FeesPayerFromJSONTyped,
    FeesPayerToJSON,
} from './FeesPayer';
import type { PaymentSwapConfig } from './PaymentSwapConfig';
import {
    PaymentSwapConfigFromJSON,
    PaymentSwapConfigFromJSONTyped,
    PaymentSwapConfigToJSON,
} from './PaymentSwapConfig';

/**
 * 
 * @export
 * @interface CustomerAddress
 */
export interface CustomerAddress {
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    network?: string;
    /**
     * 
     * @type {PaymentSwapConfig}
     * @memberof CustomerAddress
     */
    swap: PaymentSwapConfig;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    holdingCurrency?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    addressTag?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerAddress
     */
    payinsEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    minimumPayinAmount: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    nominalCurrency?: string;
    /**
     * 
     * @type {FeesPayer}
     * @memberof CustomerAddress
     */
    feesPayer?: FeesPayer;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    customerEmail?: string;
    /**
     * Freehand metadata associated with the static address
     * @type {any}
     * @memberof CustomerAddress
     */
    paymentData?: any | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    origin: string;
    /**
     * 
     * @type {Date}
     * @memberof CustomerAddress
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof CustomerAddress
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the CustomerAddress interface.
 */
// @ts-ignore
export function instanceOfCustomerAddress(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "swap" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "payinsEnabled" in value;
    isInstance = isInstance && "minimumPayinAmount" in value;
    isInstance = isInstance && "customerId" in value;
    isInstance = isInstance && "origin" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;

    return isInstance;
}

// @ts-ignore
export function CustomerAddressFromJSON(json: any): CustomerAddress {
    return CustomerAddressFromJSONTyped(json, false);
}

// @ts-ignore
export function CustomerAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'projectId': json['project_id'],
        'currency': json['currency'],
        'network': !exists(json, 'network') ? undefined : json['network'],
        'swap': PaymentSwapConfigFromJSON(json['swap']),
        'holdingCurrency': !exists(json, 'holding_currency') ? undefined : json['holding_currency'],
        'address': json['address'],
        'addressTag': !exists(json, 'address_tag') ? undefined : json['address_tag'],
        'payinsEnabled': json['payins_enabled'],
        'minimumPayinAmount': json['minimum_payin_amount'],
        'nominalCurrency': !exists(json, 'nominal_currency') ? undefined : json['nominal_currency'],
        'feesPayer': !exists(json, 'fees_payer') ? undefined : FeesPayerFromJSON(json['fees_payer']),
        'customerId': json['customer_id'],
        'customerEmail': !exists(json, 'customer_email') ? undefined : json['customer_email'],
        'paymentData': !exists(json, 'payment_data') ? undefined : json['payment_data'],
        'origin': json['origin'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
    };
}

// @ts-ignore
export function CustomerAddressToJSON(value?: CustomerAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'project_id': value.projectId,
        'currency': value.currency,
        'network': value.network,
        'swap': PaymentSwapConfigToJSON(value.swap),
        'holding_currency': value.holdingCurrency,
        'address': value.address,
        'address_tag': value.addressTag,
        'payins_enabled': value.payinsEnabled,
        'minimum_payin_amount': value.minimumPayinAmount,
        'nominal_currency': value.nominalCurrency,
        'fees_payer': FeesPayerToJSON(value.feesPayer),
        'customer_id': value.customerId,
        'customer_email': value.customerEmail,
        'payment_data': value.paymentData,
        'origin': value.origin,
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
    };
}

