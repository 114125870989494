import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { Alert, Flex, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { linkToCurrencySettings, messages } from './const'
import type { RestrictionAlertType } from './types'

const { Text } = Typography

interface Props {
    type: RestrictionAlertType
}

export function RestrictionAlert({ type }: Props) {
    const message = messages[type]

    return (
        <Alert
            type="warning"
            data-merchant={`order-creation-restriction-alert-${type}`}
            style={{ padding: 16, marginBottom: 20 }}
            message={
                <Flex align="center" gap={12}>
                    <AppSvg name={assets.alertCircle} size={20} color={token => token.colorWarning} />
                    <Text type="secondary" data-merchant={null}>
                        <FormattedMessage
                            {...message}
                            values={{
                                settingsLink: chunks => <Link to={linkToCurrencySettings}>{chunks}</Link>,
                                text: chunks => <Text data-merchant={null}>{chunks}</Text>,
                            }}
                        />
                    </Text>
                </Flex>
            }
        />
    )
}
