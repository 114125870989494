/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Balance
 */
export interface Balance {
    /**
     * 
     * @type {string}
     * @memberof Balance
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof Balance
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof Balance
     */
    fiatEquivalentCurrency?: string;
    /**
     * 
     * @type {string}
     * @memberof Balance
     */
    fiatEquivalentAmount?: string;
}

/**
 * Check if a given object implements the Balance interface.
 */
// @ts-ignore
export function instanceOfBalance(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "amount" in value;

    return isInstance;
}

// @ts-ignore
export function BalanceFromJSON(json: any): Balance {
    return BalanceFromJSONTyped(json, false);
}

// @ts-ignore
export function BalanceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Balance {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currency': json['currency'],
        'amount': json['amount'],
        'fiatEquivalentCurrency': !exists(json, 'fiat_equivalent_currency') ? undefined : json['fiat_equivalent_currency'],
        'fiatEquivalentAmount': !exists(json, 'fiat_equivalent_amount') ? undefined : json['fiat_equivalent_amount'],
    };
}

// @ts-ignore
export function BalanceToJSON(value?: Balance | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currency': value.currency,
        'amount': value.amount,
        'fiat_equivalent_currency': value.fiatEquivalentCurrency,
        'fiat_equivalent_amount': value.fiatEquivalentAmount,
    };
}

