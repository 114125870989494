import { getAddressTagName } from '@merchant/shared/utils'
import { Card, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import { LoadingSkeleton } from './LoadingSkeleton'
import type { ProjectAddress } from '~api'
import { useCurrencies } from '~api'
import { useNetworksMap } from '~hooks'

const { Text } = Typography

interface Props {
    address: ProjectAddress | undefined
    isLoading: boolean
}

// eslint-disable-next-line complexity
export function Notes({ address: depositAddress, isLoading }: Props) {
    const { data: currencies } = useCurrencies()
    const { networks } = useNetworksMap()
    const { currency, network } = depositAddress || {}
    const addressTagName = getAddressTagName(currencies, currency, network)
    const currencyData = currency ? currencies?.[currency] : null
    const networkData = network ? networks[network] : null

    let content = null
    if (isLoading || !depositAddress) {
        content = <LoadingSkeleton />
    } else {
        content = (
            <>
                <FormattedMessage {...lang.notesTitle} values={{ b: chunks => <b>{chunks}</b> }} />
                <FormattedMessage
                    {...lang.notesContent}
                    values={{
                        b: chunks => <b>{chunks}</b>,
                        ul: chunks => <ul style={{ listStyle: 'outside' }}>{chunks}</ul>,
                        li: chunks => <li style={{ marginInline: 14 }}>{chunks}</li>,
                        currencyCode: currency,
                        currencyName: currencyData?.name,
                        networkPostfix: (
                            <FormattedMessage
                                {...(networkData?.protocol === networkData?.code
                                    ? lang.networkPostfixWithoutProtocol
                                    : lang.networkPostfixWithProtocol)}
                                values={{
                                    name: networkData?.name ?? network,
                                    protocol: networkData?.protocol ?? '',
                                    b: chunks => <b>{chunks}</b>,
                                }}
                            />
                        ),
                        addressTagNoteItem: addressTagName && (
                            <li style={{ marginInline: 14 }}>
                                <FormattedMessage
                                    {...lang.addressTagNoteItem}
                                    values={{
                                        b: chunks => <b>{chunks}</b>,
                                        destinationTag: (
                                            <Text
                                                type="warning"
                                                data-merchant="deposit-notes-addressTagName"
                                                style={{ fontWeight: 500 }}
                                            >
                                                {addressTagName}
                                            </Text>
                                        ),
                                    }}
                                />
                            </li>
                        ),
                    }}
                />
            </>
        )
    }

    return (
        <Card
            styles={{
                body: { padding: '12px 20px 16px 20px', borderRadius: 12 },
            }}
            style={{ backgroundColor: 'var(--color-row-hover)', border: 'none' }}
            data-merchant="deposit-notes-card"
        >
            {content}
        </Card>
    )
}
