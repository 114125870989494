import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    pageTitle: {
        id: 'withdrawals-page.title',
        defaultMessage: 'Withdrawals',
    },
    historyPageTitle: {
        id: 'withdrawals-page.history-title',
        defaultMessage: 'History',
    },
    whitelistPageTitle: {
        id: 'withdrawals-page.whitelist-title',
        defaultMessage: 'Whitelist',
    },
    newWithdrawal: {
        id: 'withdrawals-page.new-withdrawal-button',
        defaultMessage: 'New withdrawal',
    },
})
