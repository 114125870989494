import { useBoolean } from 'usehooks-ts'
import { WhitelistContentKeys } from './types'
import { useWhitelist, useProjectBlocklist } from '~api'

export const useData = () => {
    const { data: blocklistData, mutate: mutateBlock } = useProjectBlocklist()
    const {
        data: whitelist,
        mutate: mutateWhitelist,
        isLoading: isWhitelistLoading,
        error: whitelistError,
    } = useWhitelist()
    const { value: isDisableModalOpen, setFalse: closeDisableModal, setTrue: openDisableModal } = useBoolean(false)
    const { value: isEnableModalOpen, setTrue: openEnableModal, setFalse: closeEnableModal } = useBoolean(false)

    const onWhitelistToggleSuccess = (closeModal: () => void) => {
        mutateWhitelist(prev => ({
            addresses: whitelist?.addresses || [],
            enabled: prev ? !prev.enabled : false,
        }))
        mutateBlock()
        closeModal()
    }

    let contentKey = WhitelistContentKeys.EnabledWhitelist

    if (!whitelist?.enabled) {
        contentKey = WhitelistContentKeys.DisabledWhitelist
    }

    return {
        contentKey,
        isDisableModalOpen,
        closeDisableModal,
        openDisableModal,
        isEnableModalOpen,
        openEnableModal,
        closeEnableModal,
        onWhitelistToggleSuccess,
        blocklistData,
        isWhitelistLoading,
        whitelistError,
    }
}
