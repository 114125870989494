export function addAlpha(color: string, opacity: number) {
    if (color.startsWith('#')) {
        const _opacity = Math.round(Math.min(Math.max(opacity, 0), 1) * 255)
        const alphaHex = _opacity.toString(16).padStart(2, '0').toUpperCase()

        return color + alphaHex
    } else if (color.startsWith('rgb')) {
        const rgbValues = color.match(/\d+/g)
        if (rgbValues && rgbValues.length >= 3) {
            const [r, g, b] = rgbValues

            return `rgba(${r}, ${g}, ${b}, ${opacity})`
        }
    }

    return color
}
