import { AppSvg } from '@merchant/shared/components'
import { Button, Dropdown, Tooltip } from '@merchant/ui-kit/ant-design'
import React, { memo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSearchParams } from 'react-router-dom'
import { useBoolean } from 'usehooks-ts'
import { icons, labels } from './const'
import { buttonStyles } from './style'
import type { Props } from './types'
import type { MenuProps } from '@merchant/ui-kit/ant-design'
import type { ReactNode } from 'react'
import type { Actions } from '~types/actionModal'
import { QueryParams } from '~constants/routes'
import { useConditionalTabOpen } from '~hooks'
import { generateQuery } from '~utils'

// eslint-disable-next-line max-lines-per-function
function ActionButtonComponent({
    currencyCode,
    onClick,
    context,
    action,
    style,
    subActions,
    label,
    queryParams,
    ...rest
}: Props) {
    const intl = useIntl()
    const { handleConditionalTabOpen } = useConditionalTabOpen()

    const { value: isDropdownOpen, setValue: setIsDropdownOpen } = useBoolean(false)
    const { value: isTooltipOpen, setValue: setIsTooltipOpen } = useBoolean(false)

    const [searchParams] = useSearchParams()
    const searchEntries = Object.fromEntries(searchParams.entries())

    const mergedStyles = { ...buttonStyles, ...style }

    const getQuery = (action: Actions) =>
        generateQuery({
            ...searchEntries,
            [QueryParams.fromCurrency]: currencyCode,
            [QueryParams.action]: action,
            ...queryParams,
        })

    const getActionClickHandler = (action: Actions) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        const query = getQuery(action)
        e.preventDefault()
        onClick?.(e)
        handleConditionalTabOpen([({ pathname }) => pathname + query, query])(e)
    }

    const handleSubActionClick: MenuProps['onClick'] = e => {
        const query = getQuery(e.key as Actions)
        handleConditionalTabOpen([({ pathname }) => pathname + query, query])(e.domEvent)
    }

    const wrapWithTooltip = (children: ReactNode) => (
        <Tooltip
            data-merchant="action-button-tooltip"
            title={intl.formatMessage(labels[label])}
            placement="bottom"
            open={!isDropdownOpen && isTooltipOpen}
            onOpenChange={setIsTooltipOpen}
            trigger="hover"
        >
            {children}
        </Tooltip>
    )

    const buttonSize = context === 'menu' ? 'large' : 'middle'
    const buttonIcon = icons[label]
    const buttonLabel =
        context === 'menu' ? null : (
            <span>
                <FormattedMessage {...labels[label]} />
            </span>
        )

    if (action) {
        const button = (
            <Button
                style={mergedStyles}
                icon={<AppSvg name={buttonIcon} />}
                {...{ ...rest, source: context }}
                onClick={getActionClickHandler(action)}
                size={buttonSize}
            >
                {buttonLabel}
            </Button>
        )

        return context === 'menu' ? wrapWithTooltip(button) : button
    }

    const menuItems: MenuProps['items'] = subActions.flatMap((subAction, i) => [
        i !== 0 ? { type: 'divider', style: { marginInline: -8 } } : null,
        {
            key: subAction.action,
            label: intl.formatMessage(subAction.label),
            'data-merchant': subAction['data-merchant'],
            style: { fontWeight: 500 },
        },
    ])

    const button = (
        <Button style={mergedStyles} icon={<AppSvg name={buttonIcon} />} size={buttonSize} {...rest}>
            {buttonLabel}
        </Button>
    )

    return (
        <Dropdown
            trigger={['click']}
            menu={{
                items: menuItems,
                onClick: handleSubActionClick,
                style: { overflow: 'hidden', textAlign: 'start' },
                'data-merchant': 'action-button-dropdown-menu',
            }}
            data-merchant="action-button-dropdown"
            getPopupContainer={node => node}
            placement="bottom"
            open={isDropdownOpen}
            onOpenChange={isOpen => {
                setIsDropdownOpen(isOpen)
                setIsTooltipOpen(false)
            }}
        >
            {context === 'menu' ? wrapWithTooltip(button) : button}
        </Dropdown>
    )
}

export const ActionButton = memo(ActionButtonComponent)
