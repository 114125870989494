import { ModalResponsive } from '@merchant/shared/components'
import { usePromise } from '@merchant/shared/hooks'
import { ConfigProvider } from '@merchant/ui-kit/ant-design'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { dataMerchantPrefix } from './const'
import { lang } from './lang'
import { PermissionsDiff } from './PermissionsDiff'
import type { ModalProps } from '@merchant/ui-kit/ant-design'
import type { ProjectRole, TOTPCredentials } from '~api/instances/cabinet-api'
import { projectApi, useProject } from '~api'
import { ConfirmationForm } from '~features/MerchantPage/components/TeamManagement/components'
import { withDirtyCheckingContextProvider } from '~hoc/withDirtyCheckingContextProvider'
import { useDirtyFormCheckerControls } from '~hooks'

const configProviderTheme = {
    components: { Collapse: { contentPadding: 0, paddingSM: 0 } },
}

enum Step {
    PermissionsDiff,
    Confirmation,
}

interface Props extends Omit<ModalProps, 'data-merchant'> {
    onSuccess: () => void
    initRolesPermissions: ProjectRole[]
    newRolesPermissions: ProjectRole[]
}

function Component({ onSuccess, onCancel, initRolesPermissions, newRolesPermissions, ...modalProps }: Props) {
    const { data: project } = useProject()
    const projectId = project?.id || ''
    const [step, setStep] = useState<Step>(Step.PermissionsDiff)
    const { skipCheckAndCancel, handleCancel } = useDirtyFormCheckerControls(onCancel, { checkForRouteChange: true })

    const { send: request } = usePromise(
        (params: TOTPCredentials) =>
            projectApi.updateProjectRoles({
                projectId,
                updateProjectRolesRequest: {
                    roles: newRolesPermissions,
                    ...params,
                },
            }),
        {
            showErrorNotification: false,
            onSuccess: () => {
                onSuccess()
                skipCheckAndCancel()
            },
            onError: error => {
                throw error
            },
        }
    )

    const views: Record<Step, JSX.Element> = {
        [Step.PermissionsDiff]: (
            <ConfigProvider theme={configProviderTheme}>
                <PermissionsDiff
                    onCancel={handleCancel}
                    onConfirm={() => setStep(Step.Confirmation)}
                    initRolesPermissions={initRolesPermissions}
                    newRolesPermissions={newRolesPermissions}
                />
            </ConfigProvider>
        ),
        [Step.Confirmation]: <ConfirmationForm mode="permissions-edit" request={request} />,
    }

    const resetAfterClose = () => {
        setStep(Step.PermissionsDiff)
    }

    const view = views[step]

    return (
        <ModalResponsive
            data-merchant={dataMerchantPrefix}
            destroyOnClose
            afterClose={resetAfterClose}
            title={
                <span data-merchant={`${dataMerchantPrefix}-title`}>
                    <FormattedMessage {...lang.modalTitle} />
                </span>
            }
            styles={{ header: { marginBottom: 20 } }}
            footer={null}
            onCancel={handleCancel}
            {...modalProps}
        >
            {view}
        </ModalResponsive>
    )
}

export const ConfirmPermissionsModal = withDirtyCheckingContextProvider(Component)
