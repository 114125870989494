/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LossConfigAbsolute
 */
export interface LossConfigAbsolute {
    /**
     * 
     * @type {string}
     * @memberof LossConfigAbsolute
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof LossConfigAbsolute
     */
    amount: string;
}

/**
 * Check if a given object implements the LossConfigAbsolute interface.
 */
// @ts-ignore
export function instanceOfLossConfigAbsolute(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "amount" in value;

    return isInstance;
}

// @ts-ignore
export function LossConfigAbsoluteFromJSON(json: any): LossConfigAbsolute {
    return LossConfigAbsoluteFromJSONTyped(json, false);
}

// @ts-ignore
export function LossConfigAbsoluteFromJSONTyped(json: any, ignoreDiscriminator: boolean): LossConfigAbsolute {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currency': json['currency'],
        'amount': json['amount'],
    };
}

// @ts-ignore
export function LossConfigAbsoluteToJSON(value?: LossConfigAbsolute | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currency': value.currency,
        'amount': value.amount,
    };
}

