import { usePromise } from '@merchant/shared/hooks'
import { Button, Form } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { confirmationMode2ModalName } from './const'
import type { ConfirmationFormMode } from './types'
import type { FormInstance, FormProps } from '@merchant/ui-kit/ant-design'
import type { MessageDescriptor } from 'react-intl'
import type { TOTPCredentials } from '~api/instances/cabinet-api'
import { TwoFaConfirmationItem } from '~features'
import { useRegisterDirtyFormChecker } from '~hooks'

interface Props extends Omit<FormProps, 'onFinish'> {
    onSuccess: (totp: TOTPCredentials, form: FormInstance) => Promise<void>
    submitButtonTitle: MessageDescriptor
    labelPostfix?: MessageDescriptor
    mode: ConfirmationFormMode
}

export function TwoFaConfirmationForm({ onSuccess, submitButtonTitle, labelPostfix, mode, ...rest }: Props) {
    const [form] = Form.useForm()
    useRegisterDirtyFormChecker(confirmationMode2ModalName[mode], form)

    const { send, isLoading } = usePromise((totp: TOTPCredentials) => onSuccess(totp, form))

    return (
        <Form form={form} layout="vertical" requiredMark={false} onFinish={send} size="large" {...rest}>
            <TwoFaConfirmationItem
                dataMerchant={`${mode}-2fa-input`}
                hasFocusOnMount
                postfixDescriptor={labelPostfix}
            />
            <Form.Item noStyle>
                <Button
                    htmlType="submit"
                    block
                    type="primary"
                    loading={isLoading}
                    data-merchant={`${mode}-submit-button`}
                >
                    <FormattedMessage {...submitButtonTitle} />
                </Button>
            </Form.Item>
        </Form>
    )
}
