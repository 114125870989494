import { emailValidationRegex } from '@merchant/shared/constants'
import { useFormValidation, usePromise, useBreakpoint } from '@merchant/shared/hooks'
import { Button, Form, Input } from '@merchant/ui-kit/ant-design'
import { usePostHog } from 'posthog-js/react'
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { lang } from '../../lang'
import { signInFormDataKeys } from './const'
import type { SignInFormData } from '~features/AuthorizationPage'
import { authApi } from '~api/endpoints'
import { type SigninResponse } from '~api/instances/cabinet-api'
import { PasswordWithTooltip } from '~features'
import { AuthHeader } from '~features/AuthorizationPage'
import { authCaptureEventParams, PosthogAuthEvents } from '~features/AuthorizationPage/posthog'
import { getFormStyles } from '~features/AuthorizationPage/style'
import { useCodeConfirmationErrorHandling } from '~features/FormItems/useCodeErrorHandling'
import { globalLang } from '~globalLang'

const forgotPasswordStyles: React.CSSProperties = {
    height: 'auto',
    paddingBlock: 0,
    fontSize: 16,
    fontWeight: 500,
}

interface Props {
    initialData?: SignInFormData
    onSuccess: (data: SigninResponse) => void
    onSignUpClick: (email: string) => void
    onForgotClick: (email: string) => void
}

// eslint-disable-next-line max-lines-per-function
export function SignIn({ initialData, onForgotClick, onSuccess, onSignUpClick }: Props) {
    const posthog = usePostHog()
    const [form] = Form.useForm()
    const breakpoints = useBreakpoint()
    const { onBlur, onFinishFailed, validationMode } = useFormValidation(Object.values(signInFormDataKeys))
    const { handleError } = useCodeConfirmationErrorHandling({ confirmationStrategy: { signIn: 'code' } })

    const handleToggleButtonClick = () => {
        const emailValue = form.getFieldValue(signInFormDataKeys.email)
        onSignUpClick(emailValue)
    }

    const { send: sendSignIn, isLoading: isSignInLoading } = usePromise(
        ({ email, password }: SignInFormData) =>
            authApi.signin({
                signinRequest: {
                    deviceToken: crypto.randomUUID(),
                    email,
                    password,
                },
            }),
        {
            onSuccess: data => {
                posthog.capture(...authCaptureEventParams[PosthogAuthEvents.loginAttempt]({ success: true }))
                onSuccess(data)
            },
            showErrorNotification: false,
            onError: error => {
                posthog.capture(...authCaptureEventParams[PosthogAuthEvents.loginAttempt]({ success: false }))
                handleError({ error, form })
            },
        }
    )

    useEffect(() => {
        posthog.capture(...authCaptureEventParams[PosthogAuthEvents.onSignInPage]())
    }, [posthog])

    const handleForgotPasswordClick = () => {
        const emailValue = form.getFieldValue(signInFormDataKeys.email)
        onForgotClick(emailValue)
    }

    return (
        <>
            <AuthHeader
                title={<FormattedMessage {...lang.signInTitle} />}
                subtitle={<FormattedMessage {...lang.signInSubtitle} />}
                buttonTitle={<FormattedMessage {...lang.createAccountButtonTitle} />}
                toggleMode={handleToggleButtonClick}
                buttonDataMerchant="signin-switch-mode-button"
                titleDataMerchant="signin-form-title"
                mode="signIn"
            />
            <Form
                style={getFormStyles(breakpoints)}
                form={form}
                size="large"
                layout="vertical"
                initialValues={initialData}
                onFinish={sendSignIn}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    data-merchant="signin-email-form-item"
                    name={signInFormDataKeys.email}
                    validateTrigger={validationMode.email}
                    rules={[
                        {
                            required: true,
                            message: <FormattedMessage {...globalLang.requiredFieldMessage} />,
                        },
                        {
                            pattern: emailValidationRegex,
                            message: <FormattedMessage {...globalLang.invalidEmailFormat} />,
                        },
                    ]}
                >
                    <Input
                        onBlur={onBlur(signInFormDataKeys.email)}
                        autoComplete="off"
                        data-merchant="signin-email-input"
                        placeholder="Email"
                    />
                </Form.Item>
                <PasswordWithTooltip
                    form={form}
                    name={signInFormDataKeys.password}
                    validateTrigger={validationMode.password}
                    inputPlaceholder="Password"
                    onBlur={onBlur(signInFormDataKeys.password)}
                    // keep this false otherwise user wont be able to click Forgot password
                    keepInvalidShown={!breakpoints.xs}
                    tooltipPlacement={!breakpoints.lg ? 'bottom' : 'right'}
                    inputDataMerchant="signin-password-input"
                    data-merchant="signin-password-form-item"
                />

                <Form.Item style={{ textAlign: 'center' }}>
                    <Button
                        data-merchant="signin-forgot-password-button"
                        type="link"
                        onClick={handleForgotPasswordClick}
                        style={forgotPasswordStyles}
                    >
                        <FormattedMessage {...lang.forgotPassword} />
                    </Button>
                </Form.Item>
                <Form.Item noStyle>
                    <Button
                        data-merchant="signin-submit-button"
                        htmlType="submit"
                        block
                        type="default"
                        loading={isSignInLoading}
                    >
                        <FormattedMessage {...lang.logInButtonTitle} />
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}
