import { QuestionCircleOutlined } from '@ant-design/icons'
import { useFormValidation, usePromise } from '@merchant/shared/hooks'
import { Button, Form, Input, Tooltip, Typography, theme } from '@merchant/ui-kit/ant-design'
import { useIntl, FormattedMessage } from 'react-intl'
import { createEditFormItemsKey } from './const'
import { lang } from './lang'
import type { CreateEditFormData } from '../../types'
import type { InputRef } from '@merchant/ui-kit/ant-design'
import type { Callback } from '~api/instances/cabinet-api'
import { ModalNames } from '~constants/modal'
import { TwoFaConfirmationItem } from '~features/FormItems'
import { useCodeConfirmationErrorHandling } from '~features/FormItems/useCodeErrorHandling'
import { globalLang } from '~globalLang'
import { useAutoFocus, useRegisterDirtyFormChecker } from '~hooks'

const { Text } = Typography
const { useToken } = theme

interface Props {
    callback?: Callback
    request: (values: CreateEditFormData) => Promise<Callback>
    onSuccess: () => void
    mode: 'create' | 'edit'
}

// eslint-disable-next-line max-lines-per-function
export function PatchingModalForm({ request, callback, onSuccess, mode }: Props) {
    const {
        token: { colorTextPlaceholder },
    } = useToken()
    const intl = useIntl()
    const { handleError } = useCodeConfirmationErrorHandling({
        confirmationStrategy: { '2fa': 'code' },
    })
    const [form] = Form.useForm()
    const { onBlur, onFinishFailed, validationMode } = useFormValidation(Object.values(createEditFormItemsKey))
    const { send, isLoading } = usePromise(request, {
        showErrorNotification: false,
        onSuccess,
        onError: error => {
            handleError({
                form,
                error,
            })
        },
    })
    const ref = useAutoFocus<InputRef>()
    useRegisterDirtyFormChecker(ModalNames.callbackUrlCreate, form)

    return (
        <Form
            form={form}
            onFinishFailed={onFinishFailed}
            size="large"
            onFinish={send}
            initialValues={callback}
            layout="vertical"
            requiredMark={false}
        >
            <Form.Item
                rules={[
                    {
                        required: true,
                        message: <FormattedMessage {...globalLang.requiredFieldMessage} />,
                    },
                    {
                        type: 'url',
                        message: <FormattedMessage {...lang.urlMaskError} />,
                    },
                ]}
                name={createEditFormItemsKey.url}
                validateTrigger={validationMode.url}
            >
                <Input
                    data-merchant={`${mode}-callback-url-input`}
                    autoComplete="off"
                    ref={ref}
                    onBlur={onBlur(createEditFormItemsKey.url)}
                    placeholder={intl.formatMessage(lang.urlPlaceholder)}
                    suffix={
                        <Tooltip
                            overlayInnerStyle={{ width: 330 }}
                            placement="bottom"
                            data-merchant="patch-callback-url-input-tooltip"
                            title={
                                <Text style={{ fontSize: 12 }} data-merchant={`${mode}-callback-tooltip-content`}>
                                    <FormattedMessage
                                        {...lang.tooltipText}
                                        values={{
                                            br: <br />,
                                            exampleLink: (
                                                <Text
                                                    data-merchant="patch-callback-url-input-tooltip-example-link"
                                                    type="secondary"
                                                    style={{
                                                        fontSize: 'inherit',
                                                    }}
                                                >
                                                    <FormattedMessage {...lang.exampleLink} />
                                                </Text>
                                            ),
                                        }}
                                    />
                                </Text>
                            }
                        >
                            <QuestionCircleOutlined
                                style={{ color: colorTextPlaceholder }}
                                data-merchant={`${mode}-callback-tooltip-hint`}
                            />
                        </Tooltip>
                    }
                />
            </Form.Item>
            <TwoFaConfirmationItem
                onBlur={onBlur(createEditFormItemsKey.totpCode)}
                dataMerchant={`${mode}-callback-2fa-input`}
            />
            <Form.Item noStyle>
                <Button
                    htmlType="submit"
                    type="primary"
                    block
                    loading={isLoading}
                    data-merchant={`${mode}-callback-submit-button`}
                >
                    <FormattedMessage {...lang.save} />
                </Button>
            </Form.Item>
        </Form>
    )
}
