import { CustomizedEmpty, EllipsisMiddle } from '@merchant/shared/components'
import { Flex, List, Typography } from '@merchant/ui-kit/ant-design'
import { isEmpty } from 'lodash-es'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import sharedStyles from '../../../style.module.css'
import { columnsMap } from './columns'
import type { TextProps } from '@merchant/ui-kit/ant-design/es/typography/Text'
import type { PaymentThumb } from '~api/instances/cabinet-api'
import type { Currencies } from '~api/types'
import { useCurrencies } from '~api'
import { NoValueColumnContent } from '~components'

const { Text } = Typography

const rowTitleStyles: React.CSSProperties = { fontSize: 12 }
const rowContentStyles: React.CSSProperties = {
    fontSize: 12,
    maxWidth: '65%',
    textAlign: 'right',
}

const rowTitleProps: TextProps = {
    type: 'secondary',
    style: rowTitleStyles,
    'data-merchant': null,
}

function Row({ title, content }: { title: JSX.Element; content: React.ReactNode }) {
    return (
        <Flex justify="space-between">
            <Text {...rowTitleProps}>{title}</Text>
            <div className={sharedStyles.middleCol} />
            <Text data-merchant={null} style={rowContentStyles}>
                {content}
            </Text>
        </Flex>
    )
}

function getRow(payment: PaymentThumb, currencies?: Currencies) {
    const rowDetails = [
        {
            key: 'created',
            title: columnsMap.created.title,
            content: columnsMap.created.render(payment.createdAt, 'horizontal'),
        },
        {
            key: 'updated',
            title: columnsMap.updated.title,
            content: columnsMap.updated.render(payment.updatedAt, 'horizontal'),
        },
        { key: 'status', title: columnsMap.status.title, content: columnsMap.status.render(payment.state) },
        { key: 'source', title: columnsMap.source.title, content: columnsMap.source.render(payment.origin) },
        {
            key: 'orderId',
            title: columnsMap.orderId.title,
            content: (
                <EllipsisMiddle data-merchant={null} divider="." prefixCount={8} suffixCount={10}>
                    {columnsMap.orderId.render(payment.id)}
                </EllipsisMiddle>
            ),
        },
        {
            key: 'merchantOrderId',
            title: columnsMap.merchantOrderId.title,
            content: (
                <EllipsisMiddle data-merchant={null} divider="." prefixCount={8} suffixCount={10}>
                    {columnsMap.merchantOrderId.render(payment.orderId)}
                </EllipsisMiddle>
            ),
        },
        {
            key: 'details',
            title: columnsMap.details.title,
            content: (
                <Text data-merchant={null} ellipsis>
                    {columnsMap.details.render(payment)}
                </Text>
            ),
        },
        {
            key: 'customerEmail',
            title: columnsMap.customerEmail.title,
            content: columnsMap.customerEmail.render(payment.customerEmail) || NoValueColumnContent,
        },
        {
            key: 'customerId',
            title: columnsMap.customerId.title,
            content: columnsMap.customerId.render(payment.customerId) || NoValueColumnContent,
        },
        { key: 'price', title: columnsMap.price.title, content: columnsMap.price.render(payment) },
        {
            key: 'payAmount',
            title: columnsMap.payAmount.title,
            content: columnsMap.payAmount.render(currencies)(payment),
        },
        {
            key: 'receivedAmount',
            title: columnsMap.receivedAmount.title,
            content: columnsMap.receivedAmount.render(payment),
        },
    ]

    return (
        <Flex vertical style={{ width: '100%' }} gap={8}>
            {rowDetails.map(row => (
                <Row key={row.key} title={row.title} content={row.content} />
            ))}
        </Flex>
    )
}

interface Props {
    orders: PaymentThumb[]
    showMoreButton: React.ReactNode
}
export function ListView({ orders, showMoreButton }: Props) {
    const navigate = useNavigate()
    const { data: currencies } = useCurrencies()

    if (isEmpty(orders)) {
        return <CustomizedEmpty usage="orders" data-merchant="empty-orders" />
    }

    return (
        <>
            <List data-merchant="orders-list">
                {orders.map(order => (
                    <List.Item
                        key={order.id}
                        onClick={() => navigate(order.id, { relative: 'path' })}
                        data-merchant={`${order.id}-order-list-item`}
                    >
                        {getRow(order, currencies)}
                    </List.Item>
                ))}
            </List>
            {showMoreButton}
        </>
    )
}
