import { EmailSupportLink, InlineLinkButton } from '@merchant/shared/components'
import { Flex, Form, Input, Typography } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useBoolean } from 'usehooks-ts'
import { TwoFaFields } from './const'
import { lang } from './lang'
import type { FormItemProps, InputRef } from '@merchant/ui-kit/ant-design'
import type { MessageDescriptor } from 'react-intl'
import { globalLang } from '~globalLang'
import { useAutoFocus } from '~hooks'

const { Paragraph, Text } = Typography

const backupParagraphStyles: React.CSSProperties = { marginBottom: 0 }
const extraBlockStyles: React.CSSProperties = { marginTop: 12 }

interface Props extends Omit<FormItemProps, 'name'> {
    onBlur?: () => void
    onToggle?: () => void
    postfixDescriptor?: MessageDescriptor
    isBackupCodeAllowed?: boolean
    dataMerchant: string
    hasFocusOnMount?: boolean
}

export function TwoFaConfirmationItem({
    onToggle,
    onBlur,
    postfixDescriptor,
    isBackupCodeAllowed = true,
    dataMerchant,
    hasFocusOnMount = false,
    ...rest
}: Props) {
    const inputRef = useAutoFocus<InputRef>(hasFocusOnMount)
    const { value: isConfirmWithBackupCode, setValue: setIsConfirmWithBackupCode } = useBoolean(false)

    const intl = useIntl()

    const placeholder = intl.formatMessage(
        isConfirmWithBackupCode ? lang.backupCodePlaceholder : lang.twoFaCodePlaceholder
    )

    const handleToggle = (isBackupValue: boolean) => {
        onToggle?.()
        setIsConfirmWithBackupCode(isBackupValue)
    }

    const currentModeLang = intl.formatMessage(isConfirmWithBackupCode ? lang.backupCode : lang.twoFaCode)
    let labelPostfix
    if (postfixDescriptor) {
        labelPostfix = intl.formatMessage(postfixDescriptor)
    } else {
        labelPostfix = isConfirmWithBackupCode ? '' : intl.formatMessage(lang.fromTheAppPostfix)
    }

    const extra = isBackupCodeAllowed ? (
        <div style={extraBlockStyles}>
            {isConfirmWithBackupCode ? (
                <>
                    <Paragraph data-merchant={null} type="secondary" style={backupParagraphStyles}>
                        <FormattedMessage {...globalLang.dontHaveBackupCode} />{' '}
                        <EmailSupportLink message={globalLang.contactSupport} />
                    </Paragraph>
                    <Text data-merchant={null} type="secondary">
                        <FormattedMessage {...globalLang.returnTo} />{' '}
                        <InlineLinkButton onClick={() => handleToggle(false)} data-merchant="enter-2fa-button">
                            <FormattedMessage {...globalLang.enterTwoFa} />
                        </InlineLinkButton>
                    </Text>
                </>
            ) : (
                <Flex align="center" gap={4}>
                    <Text data-merchant={null} type="secondary">
                        <FormattedMessage {...globalLang.dontHaveDevice} />
                    </Text>{' '}
                    <InlineLinkButton onClick={() => handleToggle(true)} data-merchant="use-backup-code-button">
                        <FormattedMessage {...globalLang.useBackupCode} />
                    </InlineLinkButton>
                </Flex>
            )}
        </div>
    ) : null

    const name = isConfirmWithBackupCode ? TwoFaFields.totpBackup : TwoFaFields.totpCode

    return (
        <Form.Item
            name={name}
            label={
                <FormattedMessage
                    {...lang.enterTwoFaCodeLabel}
                    values={{
                        postfix: labelPostfix,
                        currentMode: currentModeLang,
                    }}
                />
            }
            rules={[
                {
                    required: true,
                    message: (
                        <span data-merchant={`${dataMerchant}-error-message`}>
                            <FormattedMessage {...globalLang.requiredFieldMessage} />
                        </span>
                    ),
                },
            ]}
            extra={extra}
            {...rest}
        >
            <Input
                autoComplete="off"
                data-merchant={dataMerchant}
                ref={inputRef}
                placeholder={placeholder}
                onBlur={onBlur}
            />
        </Form.Item>
    )
}
