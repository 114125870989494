/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentThumb } from './PaymentThumb';
import {
    PaymentThumbFromJSON,
    PaymentThumbFromJSONTyped,
    PaymentThumbToJSON,
} from './PaymentThumb';

/**
 * 
 * @export
 * @interface ListPayments200Response
 */
export interface ListPayments200Response {
    /**
     * 
     * @type {string}
     * @memberof ListPayments200Response
     */
    cursorNext?: string;
    /**
     * 
     * @type {string}
     * @memberof ListPayments200Response
     */
    cursorPrev?: string;
    /**
     * 
     * @type {Array<PaymentThumb>}
     * @memberof ListPayments200Response
     */
    payments: Array<PaymentThumb>;
}

/**
 * Check if a given object implements the ListPayments200Response interface.
 */
// @ts-ignore
export function instanceOfListPayments200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "payments" in value;

    return isInstance;
}

// @ts-ignore
export function ListPayments200ResponseFromJSON(json: any): ListPayments200Response {
    return ListPayments200ResponseFromJSONTyped(json, false);
}

// @ts-ignore
export function ListPayments200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListPayments200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cursorNext': !exists(json, 'cursor_next') ? undefined : json['cursor_next'],
        'cursorPrev': !exists(json, 'cursor_prev') ? undefined : json['cursor_prev'],
        'payments': ((json['payments'] as Array<any>).map(PaymentThumbFromJSON)),
    };
}

// @ts-ignore
export function ListPayments200ResponseToJSON(value?: ListPayments200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cursor_next': value.cursorNext,
        'cursor_prev': value.cursorPrev,
        'payments': ((value.payments as Array<any>).map(PaymentThumbToJSON)),
    };
}

