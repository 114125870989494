import { useSpring } from '@react-spring/web'
import { useEffect, useRef, useState } from 'react'

export function useAnimateHeight(conditions: unknown[], context: 'existing' | 'new') {
    const ref = useRef<HTMLDivElement>(null)
    const [contentHeight, setContentHeight] = useState<number | 'auto'>(context === 'existing' ? 500 : 0)

    useEffect(() => {
        if (ref.current && ref.current.offsetHeight !== 0) {
            setContentHeight(ref.current.offsetHeight)
        }
    }, [conditions])

    const allowedHeight = context === 'existing' ? 500 : 0
    const maxHeight = conditions.some(Boolean) ? contentHeight : allowedHeight

    // TODO maybe rework this using controller from react-spring
    const style = useSpring({
        maxHeight,
        opacity: conditions.some(Boolean) ? 1 : 1,
        config: { duration: 150 },
    })

    return { ref, style }
}
