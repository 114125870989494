import { EllipsisMiddle, StyledQrCode, CopyButton } from '@merchant/shared/components'
import { Button, Descriptions, Divider, Typography } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { lang as commonLang } from '../../lang'
import { lang } from './lang'
import type { DescriptionsProps } from '@merchant/ui-kit/ant-design'
import type { CreateTOTPResponse } from '~api/instances/cabinet-api'
import { globalLang } from '~globalLang'

const { Text, Paragraph } = Typography

const wrapperStyles: React.CSSProperties = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 20,
}
const descriptionParagraphStyles: React.CSSProperties = {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}

interface Props {
    newTwoFa: CreateTOTPResponse
    onSuccess: () => void
}

export function ScanQrCode({ onSuccess, newTwoFa }: Props) {
    const intl = useIntl()
    const brokerName = intl.formatMessage(globalLang.brokerName)
    const descriptionItems: DescriptionsProps['items'] = [
        {
            key: '1',
            label: <FormattedMessage {...lang.accountName} />,
            children: (
                <Paragraph data-merchant={null} style={descriptionParagraphStyles}>
                    <Text data-merchant="add2fa-account-name">{newTwoFa.name}</Text>
                    <CopyButton
                        notificationContentSubject={lang.accountName}
                        inputValue={newTwoFa.name}
                        data-merchant="add2fa-scanqr-copy-name-button"
                    />
                </Paragraph>
            ),
            'data-merchant': 'add2fa-account-name-item',
        },
        {
            key: '2',
            label: <FormattedMessage {...lang.privateSecurityKey} />,
            children: (
                <Paragraph data-merchant={null} style={descriptionParagraphStyles}>
                    <EllipsisMiddle data-merchant={`add2fa-private-security-key-${newTwoFa.secret}`}>
                        {newTwoFa.secret}
                    </EllipsisMiddle>
                    <CopyButton
                        notificationContentSubject={lang.privateSecurityKey}
                        inputValue={newTwoFa.secret}
                        data-merchant="add2fa-scanqr-copy-key-button"
                    />
                </Paragraph>
            ),
            'data-merchant': 'add2fa-private-security-key-item',
        },
        {
            key: '3',
            label: <FormattedMessage {...lang.keyType} />,
            children: (
                <Paragraph data-merchant="add2fa-key-type">
                    <FormattedMessage {...lang.timeBased} />
                </Paragraph>
            ),
            'data-merchant': 'add2fa-key-item',
        },
    ]

    return (
        <div style={wrapperStyles}>
            <Text data-merchant="scan-qr-code-title">
                <FormattedMessage {...lang.scanQrCodeTitle} />
            </Text>
            <StyledQrCode
                bordered
                dataMerchant="add2fa-authenticator-app-qr-code"
                value={encodeURI(`otpauth://totp/${newTwoFa.name}?secret=${newTwoFa.secret}&issuer=${brokerName}`)}
            />
            <Divider plain>
                <Text data-merchant={null} type="secondary">
                    <FormattedMessage {...lang.scanQrCodeSubtitle} />
                </Text>
            </Divider>
            <Descriptions
                colon={false}
                column={1}
                layout="vertical"
                items={descriptionItems}
                data-merchant="add2fa-descriptions"
            />
            <Button data-merchant="add2fa-scanqr-continue-button" type="primary" block onClick={onSuccess} size="large">
                <FormattedMessage {...commonLang.continue} />
            </Button>
        </div>
    )
}
