import { useCallback, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { stepToGoBackQuery } from './const'
import type { StateData } from './types'
import type { AuthSteps } from '~constants/views'
import { QueryParams } from '~constants/routes'

interface Step {
    step: AuthSteps
    state: StateData | undefined
}

export const useStateMachine = (initialMode: AuthSteps) => {
    const [stack, setStack] = useState<Step[]>(() => [{ step: initialMode, state: undefined }])
    const [searchParams, setSearchParams] = useSearchParams()

    const replace = useCallback(
        <T extends AuthSteps>(step: T, state: StateData) => {
            setStack([{ step, state }])
        },
        [setStack]
    )

    const reset = useCallback(() => setStack([{ step: initialMode, state: undefined }]), [setStack, initialMode])

    const next = useCallback(
        <T extends AuthSteps>(
            step: T,
            state?: {
                current?: StateData
                next?: StateData
            }
        ) => {
            setStack(stack => {
                let newStack

                const currentStep = stack[stack.length - 1]
                if (state?.current && currentStep) {
                    newStack = stack.slice(0, -1)
                    newStack.push({ ...currentStep, state: state.current })
                } else {
                    newStack = stack.slice()
                }

                newStack.push({ step, state: state?.next })

                return newStack
            })
        },
        [setStack]
    )

    const back = useCallback(
        (step: AuthSteps) => {
            setStack(stack => {
                if (stack.length < 2) {
                    return stack
                }

                return stack.slice(0, -1)
            })

            const searchParamValue = stepToGoBackQuery[step]
            if (searchParamValue) {
                searchParams.set(QueryParams.show, searchParamValue)
                setSearchParams(searchParams)
            }
        },
        [setStack, searchParams, setSearchParams]
    )

    return {
        current: stack[stack.length - 1],
        canGoBack: stack.length > 1,
        stack,
        replace,
        next,
        back,
        reset,
    }
}
