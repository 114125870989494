import { parseDateAndTime } from '@merchant/shared/utils'
import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import type { CompletedWithdrawal } from '~features/WithdrawalsPage/views/Withdrawal/types'
import { getRow } from '~features/WithdrawalsPage/views/Withdrawal/utils'

const { Text } = Typography

interface Props {
    data: CompletedWithdrawal
}

const getRows = ({ data }: { data: CompletedWithdrawal }) => {
    const sentAt = parseDateAndTime(data.sentAt)

    return [
        getRow(
            lang.date,
            <>
                <Text type="secondary" data-merchant="withdrawal-timeline-completed-date">
                    {sentAt?.date}
                </Text>
                &nbsp;
                <Text data-merchant="withdrawal-timeline-completed-time">{sentAt?.time}</Text>
            </>
        ),
    ]
}

export function CompletedTimeline({ data }: Props) {
    return (
        <Flex vertical gap={16}>
            <Text data-merchant="withdrawal-timeline-completed-label">
                <FormattedMessage {...lang.completedTimeline} />
            </Text>
            {getRows({ data })}
        </Flex>
    )
}
