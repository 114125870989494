import type { ColorPalette } from './types'

export const colorPalette: ColorPalette = {
    day: {
        bgBack: '#FAFAFA',
        bgPrimary: '#FFFFFF',
        bgAdditional: '#FFFFFF',

        contentPrimary: '#1E2125',
        contentSecondary: '#85919D',

        buttonBgPrimary: '#000000',
        buttonBgSecondary: '#EBEDF0',
        buttonContentPrimary: '#FFFFFF',
        buttonContentSecondary: '#1E2225',
        buttonContentDisabled: 'rgba(30, 33, 37, 0.20)',

        elementsBg: '#EEF0F1',
        elementsBorder: '#DADEE1',

        elementsPrimary: '#615BF1',
        elementsPrimaryOpacity10: '#615BF11A',
        elementsSecondary: '#615BF1',
        elementsSecondary10L: '#8E8AF5',

        elementsSuccess: '#07B54C',
        elementsSuccessOpacity10: '#07B54C1A',
        elementsDanger: '#FF3B30',
        elementsDangerOpacity10: '#FF3B301A',

        elementsNeutral: '#94989E',
        elementsNeutralOpacity10: '#94989E1A',
        elementsWarning: '#F0A45E',
        elementsWarningOpacity10: '#F0A45E1A',

        elementsLight: '#FFFFFF',
        elementsDarkGrey: '#1E2125',
        elementsControl: '#FFFFFF',
        rowHover: '#1E1E2508',
        backgroundBlur: 'rgba(0, 0, 0, 0.4)',
        hoverRowMedium: 'rgba(30, 33, 37, 0.04)',
    },
    night: {
        bgBack: '#101415',
        bgPrimary: '#191C1F',
        bgAdditional: '#1E1E25',

        contentPrimary: '#FFFFFF',
        contentSecondary: '#868D9C',

        buttonBgPrimary: '#FFFFFF',
        buttonBgSecondary: '#272730',
        buttonContentPrimary: '#1E2225',
        buttonContentSecondary: '#FFFFFF',
        buttonContentDisabled: 'rgba(255, 255, 255, 0.20)',

        elementsBg: '#272730',
        elementsBorder: '#2E2E38',

        elementsPrimary: '#7772F3',
        elementsPrimaryOpacity10: '#615BF11A',
        elementsSecondary: '#7772F3',
        elementsSecondary10L: '#8E8AF5',

        elementsSuccess: '#07B54C',
        elementsSuccessOpacity10: '#07B54C1A',
        elementsDanger: '#FF3B30',
        elementsDangerOpacity10: '#FF3B301A',

        elementsNeutral: '#94989E',
        elementsNeutralOpacity10: '#94989E1A',
        elementsWarning: '#F0A45E',
        elementsWarningOpacity10: '#F0A45E1A',

        elementsLight: '#FFFFFF',
        elementsDarkGrey: '#1E1E25',
        elementsControl: '#191C1F',
        rowHover: '#FFFFFF05',
        backgroundBlur: 'rgba(0, 0, 0, 0.4)',
        hoverRowMedium: 'rgba(255, 255, 255, 0.04)',
    },
}
