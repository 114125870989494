import { AppCoin } from '@merchant/shared/components'
import { Flex, Typography, theme } from '@merchant/ui-kit/ant-design'
import { isEmpty } from 'lodash-es'
import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import styles from './style.module.css'
import type { SelectProps } from '@merchant/ui-kit/ant-design'
import type { DefaultOptionType } from '@merchant/ui-kit/ant-design/es/select'
import type { Currency } from '~api/instances/cabinet-api'
import { useBalances, useCurrencies } from '~api'
import { SearchableSelect } from '~components'
import { globalLang } from '~globalLang'
import { currenciesCommonSort } from '~utils'

const { Text } = Typography
const { useToken } = theme

interface Props<T> extends Omit<SelectProps, 'options'> {
    options: T[]
    'data-merchant': string
    optionDataMerchantPrefix: string
    optionPredicate?: (option: Currency | undefined) => boolean
}

interface BaseOption {
    code: string
    name: string
    fullName?: string
}

function Label<T extends BaseOption>({ data }: { data: T }) {
    return (
        <Flex align="center">
            <AppCoin currencyCode={data.code} style={{ verticalAlign: 'middle', marginRight: 10 }} size="small" />
            <Text data-merchant={null}>{data.code}</Text>
            &nbsp;
            <Text data-merchant={null} type="secondary" ellipsis style={{ marginRight: 'auto' }}>
                {data.fullName || data.name}
            </Text>
        </Flex>
    )
}

export function FiatCryptoSelect<T extends BaseOption>({
    options,
    optionDataMerchantPrefix,
    optionPredicate,
    style,
    ...props
}: Props<T>) {
    const { data: currencies } = useCurrencies()
    const { data: balances } = useBalances()
    const { token } = useToken()
    const { controlPaddingHorizontal } = token

    const optGroups = useMemo(() => {
        if (!currencies) {
            return []
        }

        const filteredOptions = optionPredicate
            ? options.filter(option => optionPredicate(currencies[option.code]))
            : options
        const sortedOptions = currenciesCommonSort(filteredOptions, currencies, balances)

        const { fiatCurrencies, cryptoCurrencies } = sortedOptions.reduce(
            (acc, c) => {
                const currency = currencies[c.code]
                if (!currency) {
                    return acc
                }

                const option: DefaultOptionType = {
                    key: [c.code, c.name, c.fullName].filter(Boolean).join(''),
                    label: <Label<typeof currency> data={currency} />,
                    value: c.code,
                    'data-merchant': `${optionDataMerchantPrefix}-${c.code.toLowerCase()}`,
                }

                if (currency.crypto) {
                    acc.cryptoCurrencies.push(option)
                } else {
                    acc.fiatCurrencies.push(option)
                }

                return acc
            },
            { fiatCurrencies: [], cryptoCurrencies: [] } as {
                fiatCurrencies: DefaultOptionType[]
                cryptoCurrencies: DefaultOptionType[]
            }
        )

        const groups = []

        if (!isEmpty(fiatCurrencies)) {
            groups.push({
                key: 'fiat',
                label: (
                    <Text data-merchant={null} type="secondary">
                        <FormattedMessage {...globalLang.fiatCurrencies} />
                    </Text>
                ),
                options: fiatCurrencies,
            })
        }

        if (!isEmpty(cryptoCurrencies)) {
            groups.push({
                key: 'crypto',
                label: (
                    <Text data-merchant={null} type="secondary">
                        <FormattedMessage {...globalLang.cryptoCurrencies} />
                    </Text>
                ),
                options: cryptoCurrencies,
            })
        }

        if (groups.length === 1) {
            return groups[0]?.options
        }

        return groups
    }, [balances, currencies, optionDataMerchantPrefix, optionPredicate, options])

    return (
        <SearchableSelect
            dropdownStyle={
                {
                    '--padding-horizontal': `${controlPaddingHorizontal}px`,
                } as React.CSSProperties
            }
            showSearch
            className={styles.groupedSelect}
            style={{ width: '100%', ...style }}
            filterOption={(input, option) => option?.key?.toLowerCase().includes(input.toLowerCase())}
            options={optGroups}
            size="large"
            getPopupContainer={node => node}
            {...props}
        />
    )
}
