import { Form, type FormInstance } from '@merchant/ui-kit/ant-design'
import { debounce, isEmpty } from 'lodash-es'
import { useCallback, useEffect, useRef } from 'react'
import { withdrawalCryptoFormKeys } from '../../const'
import { controllers } from './abortControllers'
import { validateAmount } from './utils'
import type { CryptoWithdrawalModalPermissions } from './types'
import type { WithdrawalAmountMode, WithdrawalCryptoFormData } from '../../types'
import type { SetState } from '@merchant/shared/types'
import type { CalcWithdrawalAmountsRequest } from '~api/instances/cabinet-api'
import type { WithdrawalCurrency } from '~features/ActionModal/types'
import { getPrecisionCutValue } from '~utils'

const FEE_CHECK_TRIGGER_FIELDS = [
    withdrawalCryptoFormKeys.amount,
    withdrawalCryptoFormKeys.currency,
    withdrawalCryptoFormKeys.network,
] as const

interface Props {
    form: FormInstance
    setIsWaitingDebounce: SetState<boolean>
    withdrawalCurrencies?: Record<string, WithdrawalCurrency>
    sendGetWithdrawalFee: (data: Omit<CalcWithdrawalAmountsRequest, 'projectId'>) => Promise<void>
    onSuccess: (withdrawalData: WithdrawalCryptoFormData) => void
    selectedCurrencyData?: WithdrawalCurrency
    withdrawalAmountPrecision: number
    onFinishFailed: () => void
    cryptoWithdrawalPermissions: CryptoWithdrawalModalPermissions
    setFormSubmitted: () => void
}

// eslint-disable-next-line max-lines-per-function
export function useHandlers({
    form,
    setIsWaitingDebounce,
    withdrawalCurrencies,
    sendGetWithdrawalFee,
    onSuccess,
    selectedCurrencyData,
    withdrawalAmountPrecision,
    onFinishFailed,
    cryptoWithdrawalPermissions,
    setFormSubmitted,
}: Props) {
    const withdrawalAmountValue = Form.useWatch(withdrawalCryptoFormKeys.amount, form)
    const onValuesChangeDebounced = useRef(
        debounce((data: WithdrawalCryptoFormData | null) => {
            if (!data) {
                setIsWaitingDebounce(false)

                return
            }
            const { currency = '', amount, network } = data
            const currencyObj = withdrawalCurrencies?.[currency || '']
            if (!currencyObj || (!isEmpty(currencyObj.networks) && !network) || !validateAmount(amount)) {
                return
            }

            sendGetWithdrawalFee({ amount, receiveCurrency: currency, receiveNetwork: network })
            setIsWaitingDebounce(false)
        }, 500)
    )

    const handleFormFinishFailed = () => {
        setFormSubmitted()
        form.validateFields()
        onFinishFailed()
    }

    const handleFormFinish = async (values: WithdrawalCryptoFormData) => {
        setFormSubmitted()
        if (!cryptoWithdrawalPermissions.commit_crypto_withdrawals) {
            return
        }

        const { address, whitelistKey, currency, amount, fee, receiveAmount, ...restValues } = values
        if ((!address && !whitelistKey) || !validateAmount(amount) || !currency || !Number(fee) || !receiveAmount) {
            handleFormFinishFailed()

            return form.validateFields()
        }

        onSuccess({
            address,
            whitelistKey,
            amount,
            currency,
            fee,
            receiveAmount,
            ...restValues,
        })
    }

    const handleValueChange = useCallback(
        (changedValues: Partial<WithdrawalCryptoFormData>, values: WithdrawalCryptoFormData) => {
            if (
                !Object.keys(changedValues).find(key =>
                    FEE_CHECK_TRIGGER_FIELDS.includes(key as (typeof FEE_CHECK_TRIGGER_FIELDS)[number])
                )
            ) {
                return
            }

            form.setFields([
                {
                    name: withdrawalCryptoFormKeys.receiveAmount,
                    errors: [],
                    value: '0',
                },
                { name: withdrawalCryptoFormKeys.fee, errors: [], value: '0' },
                {
                    name: withdrawalCryptoFormKeys.withdrawalAmount,
                    errors: [],
                    value: '0',
                },
            ])

            controllers.feeController.abort()
            const { amount, currency = '', network = '' } = values
            const currencyObj = withdrawalCurrencies?.[currency]

            const shouldSkipFeeRequest =
                !currencyObj || (!isEmpty(currencyObj.networks) && !network) || !validateAmount(amount)

            if (shouldSkipFeeRequest) {
                onValuesChangeDebounced.current(null)
                setIsWaitingDebounce(false)

                return
            }
            setIsWaitingDebounce(true)
            onValuesChangeDebounced.current(values)

            form.validateFields([Object.keys(changedValues)], { recursive: true })
        },
        [form, setIsWaitingDebounce, withdrawalCurrencies]
    )

    const handleBalanceClick = () => {
        const amountType: WithdrawalAmountMode = form.getFieldValue(withdrawalCryptoFormKeys.amountMode)
        const formAmount = form.getFieldValue([withdrawalCryptoFormKeys.amount, amountType])
        if (selectedCurrencyData?.amount === formAmount) {
            return
        }
        const cutBalanceAmount = getPrecisionCutValue({
            value: selectedCurrencyData?.amount,
            precision: withdrawalAmountPrecision,
        })
        form.setFields([
            {
                name: [withdrawalCryptoFormKeys.amount, amountType],
                value: cutBalanceAmount,
                errors: [],
            },
        ])
        const changedValues =
            amountType === 'credit'
                ? { amount: { credit: cutBalanceAmount } }
                : { amount: { receive: cutBalanceAmount } }
        handleValueChange(changedValues, form.getFieldsValue())
    }

    useEffect(() => {
        handleValueChange({ [withdrawalCryptoFormKeys.amount]: withdrawalAmountValue }, form.getFieldsValue())
    }, [withdrawalAmountValue, form, handleValueChange])

    return {
        handleValueChange,
        handleFormFinish,
        handleBalanceClick,
        handleFormFinishFailed,
    }
}
