import { useBreakpoint } from '@merchant/shared/hooks'
import { Layout } from '@merchant/ui-kit/ant-design'
import { Outlet } from 'react-router-dom'
import type { Breakpoint } from '@merchant/ui-kit/ant-design'
import { StaticPageHeader } from '~features/App'

export function Static() {
    const getLayoutStyles = (breakpoints: Partial<Record<Breakpoint, boolean>>): React.CSSProperties => {
        const styles: React.CSSProperties = {
            maxWidth: 1920,
            width: '100%',
            marginInline: 'auto',
            position: 'relative',
            minHeight: '100vh',
            padding: 0,
        }
        if (breakpoints.lg) {
            styles.padding = '0 108px'
        } else if (breakpoints.md) {
            styles.padding = '0 20px'
        }

        return styles
    }
    const breakpoints = useBreakpoint()

    return (
        <>
            <Layout style={getLayoutStyles(breakpoints)}>
                <StaticPageHeader />
                <Outlet />
            </Layout>
        </>
    )
}
