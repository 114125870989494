import { Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang as commonLang } from '../../lang'
import { lang } from './lang'
import { BalanceLinkButton } from '~components'

const { Text } = Typography

interface Props {
    minimumSwapAmountInfo: {
        amount: string | null
        isBelowMinimum: boolean
        onClick: () => void
    }
    sendCurrency: string | null
}

export function SendAmountLabel({ sendCurrency, minimumSwapAmountInfo }: Props) {
    return (
        <>
            <FormattedMessage {...commonLang.youSend} />
            {minimumSwapAmountInfo.amount && (
                <>
                    <Text data-merchant={null} type="secondary">
                        &nbsp; <FormattedMessage {...lang.delimiter} /> &nbsp;
                    </Text>
                    <Text
                        type={minimumSwapAmountInfo.isBelowMinimum ? 'danger' : 'secondary'}
                        data-merchant="convert-minimum-amount-text"
                    >
                        <FormattedMessage {...lang.minimum} />{' '}
                        <BalanceLinkButton
                            data-merchant="convert-minimum-amount-button"
                            onClick={minimumSwapAmountInfo.onClick}
                            amount={minimumSwapAmountInfo.amount}
                            currencyCode={sendCurrency}
                        />
                    </Text>
                </>
            )}
        </>
    )
}
