import { useBreakpoint } from '@merchant/shared/hooks'
import { theme } from '@merchant/ui-kit/ant-design'
import { isEmpty, throttle } from 'lodash-es'
import { usePostHog } from 'posthog-js/react'
import React, { useMemo, useState } from 'react'
import { PosthogConvertEvents } from '../../../posthog'
import { lang } from './lang'
import { LongLabel } from './LongLabel'
import { ShortLabel } from './ShortLabel'
import styles from './style.module.css'
import { createPosthogEventGetter, getSelectOptionGroup } from './utils'
import type { ConversionDirection } from './types'
import type { BalanceOption, SwapModalPermissions } from '../types'
import type { DefaultOptionType } from '@merchant/ui-kit/ant-design/es/select'
import type { SearchableSelectProps } from '~components'
import { useCurrencies, useMerchant, useProject } from '~api'
import { SearchableSelect } from '~components'

const { useToken } = theme

const THROTTLE_DELAY = 2000

const afterSelectStyles: React.CSSProperties = {
    maxWidth: 190,
    width: 190,
    textAlign: 'start',
    fontSize: 14,
}

interface Props extends SearchableSelectProps {
    balances: BalanceOption[]
    'data-merchant': string
    permissions: SwapModalPermissions
    conversionDirection: ConversionDirection
}

export function CurrencySelect({ balances: options, conversionDirection, style, permissions, ...rest }: Props) {
    const posthog = usePostHog()
    const { data: { id: projectId = '' } = {} } = useProject()
    const { data: { id: merchantId = '' } = {} } = useMerchant()
    const [searchValue, setSearchValue] = useState('')
    const {
        token: { controlPaddingHorizontal },
    } = useToken()
    const { data: currencies } = useCurrencies()
    const breakpoints = useBreakpoint()
    const getPosthogEventParams = createPosthogEventGetter({ merchantId, projectId, conversionDirection })

    const captureNoMatchEventThrottler = useMemo(
        () =>
            throttle(
                (input: string) =>
                    posthog.capture(...getPosthogEventParams(PosthogConvertEvents.currencySearchNoMatch, input)),
                THROTTLE_DELAY,
                { leading: true }
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [posthog]
    )

    const isNotXS = breakpoints.xs === false

    const selectOptions = useMemo(() => {
        const { cryptoCurrencies, fiatCurrencies } = options.reduce(
            (acc, item) => {
                const currency = currencies?.[item.currency]
                const { crypto, code = '', name = '' } = currency || {}
                const key = code + name
                if (searchValue && !key.toLowerCase().includes(searchValue.toLowerCase())) {
                    return acc
                }

                const optionLabel = permissions.see_balance ? (
                    <LongLabel data={item} />
                ) : (
                    <ShortLabel data={item} showName={breakpoints.md} />
                )

                const option: DefaultOptionType = {
                    key,
                    label: optionLabel,
                    value: code,
                    selectedLabel: <ShortLabel data={item} showName={breakpoints.md} />,
                    currency,
                }

                if (crypto) {
                    acc.cryptoCurrencies.push(option)
                } else {
                    acc.fiatCurrencies.push(option)
                }

                return acc
            },
            { fiatCurrencies: [], cryptoCurrencies: [] } as {
                fiatCurrencies: DefaultOptionType[]
                cryptoCurrencies: DefaultOptionType[]
            }
        )

        if (isEmpty(cryptoCurrencies) && isEmpty(fiatCurrencies)) {
            captureNoMatchEventThrottler(searchValue)

            return []
        }

        return [
            ...getSelectOptionGroup('fiat', lang.fiatCurrencies, fiatCurrencies),
            ...getSelectOptionGroup('crypto', lang.cryptoCurrencies, cryptoCurrencies),
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [breakpoints.md, currencies, options, searchValue])

    return (
        <SearchableSelect
            style={{ ...afterSelectStyles, ...style }}
            className={styles.afterSelect}
            // TODO: find a better solution for mobile padding issue
            popupClassName={breakpoints.md ? styles.popup : undefined}
            searchValue={searchValue}
            onSearch={setSearchValue}
            placement="bottomRight"
            onSelect={() =>
                posthog.capture(...getPosthogEventParams(PosthogConvertEvents.currencySelected, searchValue))
            }
            optionIdPostfix={option =>
                `${option.data.currency.crypto ? 'crypto' : 'fiat'}-${option.value?.toString().toLowerCase()}`
            }
            dropdownStyle={
                {
                    ...(isNotXS && { width: 472 }),
                    '--padding-horizontal': `${controlPaddingHorizontal}px`,
                } as React.CSSProperties
            }
            optionLabelProp="selectedLabel"
            options={selectOptions}
            {...rest}
        />
    )
}
