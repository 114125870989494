import { Typography } from '@merchant/ui-kit/ant-design'
import { isEmpty } from 'lodash-es'
import { FormattedMessage } from 'react-intl'
import { convertCaptureEventParams, type PosthogConvertEvents } from '../../../posthog'
import type { ConversionDirection } from './types'
import type { GetProps, Select } from '@merchant/ui-kit/ant-design'
import type { MessageDescriptor } from 'react-intl'

const { Text } = Typography

export const getSelectOptionGroup = (
    key: string,
    descriptor: MessageDescriptor,
    options: GetProps<typeof Select>['options'] = []
) =>
    !isEmpty(options)
        ? [
              {
                  key,
                  label: (
                      <Text data-merchant={null} type="secondary">
                          <FormattedMessage {...descriptor} />
                      </Text>
                  ),
                  options,
              },
          ]
        : []

export const createPosthogEventGetter =
    ({
        conversionDirection,
        merchantId,
        projectId,
    }: {
        merchantId: string
        projectId: string
        conversionDirection: ConversionDirection
    }) =>
    (event: PosthogConvertEvents.currencySearchNoMatch | PosthogConvertEvents.currencySelected, input: string) => {
        const [eventName, params] = convertCaptureEventParams[event]({
            merchant_id: merchantId,
            project_id: projectId,
            input,
            side: conversionDirection,
        })

        return [eventName, params] as const
    }
