import { ModalResponsive } from '@merchant/shared/components'
import { forwardRef, useImperativeHandle, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { lang as commonLang } from './lang'
import type { ModalProps } from '@merchant/ui-kit/ant-design'
import type { CustomerAddress } from '~api/instances/cabinet-api'

export interface PaymentDataModalRef {
    open: (data: CustomerAddress['paymentData']) => void
}

export const PaymentDataModal = forwardRef<PaymentDataModalRef, Omit<ModalProps, 'data-merchant'>>(
    function (props, ref) {
        const [state, setState] = useState<CustomerAddress['paymentData'] | null>(null)
        useImperativeHandle(ref, () => {
            return {
                open: setState,
            }
        })

        const onCancel = () => setState(null)

        return (
            <ModalResponsive
                title={<FormattedMessage {...commonLang.paymentData} />}
                open={!!state}
                onCancel={onCancel}
                footer={(_, { OkBtn }) => <OkBtn />}
                okButtonProps={{
                    block: true,
                    size: 'large',
                    'data-merchant': 'addresses-table-payment-data-modal-ok-button',
                }}
                onOk={onCancel}
                data-merchant="addresses-table-payment-data-modal"
                {...props}
            >
                <div data-merchant="addresses-table-payment-data">
                    <pre style={{ overflowX: 'scroll' }}>{JSON.stringify(state, null, 2)}</pre>
                </div>
            </ModalResponsive>
        )
    }
)
