import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    modalTitle: {
        id: 'integrations.modal.delete_title',
        defaultMessage: 'Delete API Key',
    },
    submitButton: {
        id: 'integrations.button.submit',
        defaultMessage: 'Delete',
    },
    labelPostfix: {
        id: 'integrations.label.postfix',
        defaultMessage: ' to permanently delete this API key',
    },
})
