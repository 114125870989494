import { useState } from 'react'

const createInitialValues = <T extends string>(fields: T[]) => {
    return fields.reduce(
        (acc, cur) => {
            acc[cur] = 'onBlur'

            return acc
        },
        {} as { [key in T]: string }
    )
}

export const useFormValidation = <T extends string>(fields: T[]) => {
    const [validationMode, setValidationMode] = useState(createInitialValues(fields))

    const changeValidationMode = (fieldName: string) => {
        setValidationMode(prevState => ({ ...prevState, [fieldName]: 'onChange' }))
    }

    const onFinishFailed = () => {
        const newValidationMode = Object.keys(validationMode).reduce(
            (obj, curr) => ({ ...obj, [curr]: 'onChange' }),
            {} as { [key in T]: string }
        )
        setValidationMode(newValidationMode)
    }

    const onBlur = (fieldName: T) => () => {
        changeValidationMode(fieldName)
    }

    return {
        validationMode,
        onFinishFailed,
        onBlur,
    }
}
