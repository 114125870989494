import { Spin, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { PlaceholderViewKeys } from './const'
import { usePlaceholderViewData } from './hooks'
import { lang } from './lang'
import type { PropsWithChildren } from 'react'
import type { TOTP } from '~api/instances/cabinet-api'
import { PageContentContainer } from '~components'
import { QueryParams, routes } from '~constants/routes'
import { MerchantSettingsShowOptions } from '~features/MerchantPage/components/MerchantSettings/types'
import { PlaceholderView } from '~features/PlaceholderView'
import { PlaceholderName } from '~features/PlaceholderView/types'
import { SetupTwoFa } from '~features/SetupTwoFa'
import { generateQuery } from '~utils'

const { Text } = Typography

interface Props extends PropsWithChildren {
    dataMerchantPrefix: string
    excludedViews?: PlaceholderViewKeys[]
    isLoading?: boolean
    isError?: boolean
}

export function PlaceholderViewWrapper({ children, excludedViews, dataMerchantPrefix, isLoading, isError }: Props) {
    const { contentKey, mutateTotps } = usePlaceholderViewData()

    const views: Record<PlaceholderViewKeys, () => JSX.Element> = {
        [PlaceholderViewKeys.NoProject]: () => (
            <PageContentContainer>
                <PlaceholderView buttonDataMerchant={`${dataMerchantPrefix}-page-no-merchant`} />
            </PageContentContainer>
        ),
        [PlaceholderViewKeys.NoHoldingCurrencies]: () => (
            <PageContentContainer>
                <PlaceholderView
                    name={PlaceholderName.noCurrency}
                    buttonClickHandler={navigate =>
                        navigate(
                            `${routes.merchantSettings}${generateQuery({ [QueryParams.show]: MerchantSettingsShowOptions.assets })}`
                        )
                    }
                    buttonDataMerchant={`${dataMerchantPrefix}-page-no-holding-currency`}
                />
            </PageContentContainer>
        ),
        [PlaceholderViewKeys.No2Fa]: () => {
            const onSuccess = (newTotp: TOTP) => {
                mutateTotps((prev = []) => [...prev, newTotp])
            }

            return (
                <PageContentContainer>
                    <SetupTwoFa onSuccess={onSuccess} buttonDataMerchant={`${dataMerchantPrefix}-page-no-totp`} />
                </PageContentContainer>
            )
        },
        [PlaceholderViewKeys.Loading]: () => (
            <PageContentContainer style={{ height: 300, display: 'grid', placeItems: 'center' }}>
                <Spin size="large" data-merchant="placeholder-view-loading-spin" />
            </PageContentContainer>
        ),
        [PlaceholderViewKeys.Error]: () => (
            <PageContentContainer>
                <Text data-merchant="placeholder-view-error">
                    <FormattedMessage {...lang.loadingError} />
                </Text>
            </PageContentContainer>
        ),
    }

    if (contentKey && !excludedViews?.includes(contentKey)) {
        return views[contentKey]()
    }

    if (isLoading) {
        return views[PlaceholderViewKeys.Loading]()
    }

    if (isError) {
        views[PlaceholderViewKeys.Error]()
    }

    return children
}
