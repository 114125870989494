import { lang } from './lang'
import type { FormCheckers, ModalType } from './types'
import type { MessageDescriptor } from 'react-intl'
import { ModalNames } from '~constants/modal'

interface ConfirmationFormData {
    submitButton: MessageDescriptor
    shouldRenderEmailItem: boolean
    dataMerchantPrefix: string
}

export const confirmationFormData: Record<ModalType, ConfirmationFormData> = {
    add: {
        submitButton: lang.addMembersSubmitButton,
        shouldRenderEmailItem: true,
        dataMerchantPrefix: 'add-members-confirm-modal',
    },
    delete: {
        submitButton: lang.deleteMemberSubmitButton,
        shouldRenderEmailItem: false,
        dataMerchantPrefix: 'delete-members-confirm-modal',
    },
    edit: {
        submitButton: lang.editMemberSubmitButton,
        shouldRenderEmailItem: false,
        dataMerchantPrefix: 'edit-members-confirm-modal',
    },
    'permissions-edit': {
        submitButton: lang.editPermissionsSubmitButton,
        shouldRenderEmailItem: false,
        dataMerchantPrefix: 'edit-permissions-confirm-modal',
    },
}

export const confirmationModeMap: Record<ModalType, FormCheckers> = {
    add: ModalNames.teamMemberConfirmationDefault,
    edit: ModalNames.teamMemberConfirmationDefault,
    delete: ModalNames.deleteTeamMemberConfirmation,
    'permissions-edit': ModalNames.teamMemberConfirmationDefault,
}
