import type { AppSettings, LocaleMessagesMap } from '~api/instances/host-api/types'

export enum EmailInputConfigEnum {
    CountdownSecondsAmount = 'resendInputCountdownSecondsAmount',
    ResendAttemptsBeforeDelay = 'resendInputMaxResendAttemptsBeforeLongDelay',
}

interface EmailInputResend {
    [EmailInputConfigEnum.CountdownSecondsAmount]: number
    [EmailInputConfigEnum.ResendAttemptsBeforeDelay]: number
}

export type AppConfig = EmailInputResend

declare global {
    interface Window {
        appConfig?: AppConfig
        __APP_SETTINGS_INITIAL__: AppSettings | undefined
        __APP_MESSAGES_INITIAL__: LocaleMessagesMap | undefined
        __APP_SUPPORTED_LOCALES_INITIAL__: string[] | undefined
    }
}
