import { ColorModeSelect } from '@merchant/shared/components'
import { useBreakpoint } from '@merchant/shared/hooks'
import { Flex, List, Typography, type GetProps } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { getCommonFlexProps, listTitleStyles } from '../../styles'
import { globalLang } from '~globalLang'

const { Text } = Typography

type Props = Omit<GetProps<(typeof List)['Item']>, 'onChange'>

export function ColorModeSelectItem(props: Props) {
    const breakpoints = useBreakpoint()
    const commonFlexProps = getCommonFlexProps(!breakpoints.md)

    return (
        <List.Item {...props}>
            <Flex {...commonFlexProps}>
                <List.Item.Meta
                    title={
                        <Text style={listTitleStyles} data-merchant={null}>
                            <FormattedMessage {...globalLang.colorMode} />
                        </Text>
                    }
                    data-merchant="settings-color-mode-list-item-meta"
                />
                <ColorModeSelect />
            </Flex>
        </List.Item>
    )
}
