import { CopyButton } from '@merchant/shared/components'
import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from '../lang'
import { columnsMap } from './columns'
import type { PaymentThumb } from '~api/instances/cabinet-api'

const { Text } = Typography

interface Props {
    record: PaymentThumb
}

export function CustomerTooltipTitle({ record: { customerEmail, customerId, id } }: Props) {
    return (
        <Flex gap={14} onClick={e => e.stopPropagation()} vertical>
            {customerEmail && (
                <Flex gap={14} justify="space-between" align="flex-start">
                    <Text data-merchant={null} type="secondary" style={{ textWrap: 'nowrap' }}>
                        <FormattedMessage {...lang.clientEmail} />
                    </Text>
                    <Flex gap={4}>
                        <Text data-merchant={null}>{columnsMap.customerEmail.render(customerEmail)}</Text>
                        <CopyButton
                            data-merchant={`orders-customer-email-copy-button-${id}`}
                            style={{ height: '100%' }}
                            notificationContentSubject={lang.clientEmail}
                            inputValue={customerEmail}
                        />
                    </Flex>
                </Flex>
            )}
            {customerId && (
                <Flex gap={14} justify="space-between">
                    <Text data-merchant={null} style={{ textWrap: 'nowrap' }} type="secondary">
                        <FormattedMessage {...lang.clientId} />
                    </Text>
                    <Flex gap={4}>
                        <Text data-merchant={null} style={{ textAlign: 'right' }}>
                            {columnsMap.customerId.render(customerId)}
                        </Text>
                        <CopyButton
                            data-merchant={`orders-customer-id-copy-button-${id}`}
                            style={{ height: '100%' }}
                            notificationContentSubject={lang.clientId}
                            inputValue={customerId}
                        />
                    </Flex>
                </Flex>
            )}
        </Flex>
    )
}
