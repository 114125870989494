import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    passwordMinLength: {
        id: 'password.input.password_min_length',
        defaultMessage: 'At least {minLength} characters',
    },
    passwordOneNumber: {
        id: 'password.input.password_one_number_at_least',
        defaultMessage: 'At least one number',
    },
    passwordOneNonNumeric: {
        id: 'password.input.password_one_non_numeric_at_least',
        defaultMessage: 'At least one non-numeric character (a letter or a symbol)',
    },
    passwordDefaultPlaceholder: {
        id: 'password.input.placeholder',
        defaultMessage: 'Password',
    },
})
