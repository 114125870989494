import React from 'react'

export const createGenericContext = <T>(): readonly [() => T, React.Provider<T | undefined>] => {
    const genericContext = React.createContext<T | undefined>(undefined)

    const useGenericContext = () => {
        const context = React.useContext(genericContext)

        if (!context) {
            throw new Error('useGenericContext must be used within a Provider')
        }

        return context
    }

    return [useGenericContext, genericContext.Provider]
}
