import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    filters: {
        id: 'withdrawals.filters.title',
        defaultMessage: 'Filters',
    },
    status: {
        id: 'withdrawals.filters.field.status',
        defaultMessage: 'Status',
    },
    source: {
        id: 'withdrawals.filters.field.source',
        defaultMessage: 'Source',
    },
    id: {
        id: 'withdrawals.filters.field.id',
        defaultMessage: 'ID',
    },
    orderId: {
        id: 'withdrawals.filters.field.order_id',
        defaultMessage: 'Merch. Withdrawal ID',
    },
    creditCurrency: {
        id: 'withdrawals.filters.field.credit_currency',
        defaultMessage: 'Credit currency',
    },
    receiveCurrency: {
        id: 'withdrawals.filters.field.receive_currency',
        defaultMessage: 'Receive currency',
    },
    receiveNetwork: {
        id: 'withdrawals.filters.field.receive_network',
        defaultMessage: 'Receive network',
    },
    address: {
        id: 'withdrawals.filters.field.address',
        defaultMessage: 'Address',
    },
    addressTag: {
        id: 'withdrawals.filters.field.address_tag',
        defaultMessage: 'Tag',
    },
    note: {
        id: 'withdrawals.filters.field.note',
        defaultMessage: 'Note',
    },
    createdAt: {
        id: 'withdrawals.filters.field.created_at',
        defaultMessage: 'Created at:',
    },
    updatedAt: {
        id: 'withdrawals.filters.field.updated_at',
        defaultMessage: 'Updated at:',
    },
    apply: {
        id: 'withdrawals.filters.button.apply',
        defaultMessage: 'Apply',
    },
    clearAll: {
        id: 'withdrawals.filters.button.clear_all',
        defaultMessage: 'Clear all',
    },
})
