import type { QueryParams } from '~constants/routes'
import type { Actions } from '~types/actionModal'

interface Props {
    [QueryParams.action]?: Actions
    [QueryParams.fromCurrency]?: string
    [QueryParams.toCurrency]?: string
    [QueryParams.show]?: string
    [QueryParams.orderId]?: string
    [QueryParams.type]?: string
    [QueryParams.id]?: string
    withQuestionMark?: boolean
}

export const generateQuery = ({ withQuestionMark = true, ...rest }: Props) => {
    const params = new URLSearchParams()
    Object.entries(rest).forEach(([param, value]) => value && params.set(param, value))

    const queryString = params.toString()

    if (withQuestionMark && queryString) {
        return `?${queryString}`
    }

    return queryString
}
