export interface WhitelistInnerObject {
    currency: string
    name: string
    address: string
    network: string
    confirmed?: boolean
}

export enum WhitelistContentKeys {
    DisabledWhitelist,
    EnabledWhitelist,
}
