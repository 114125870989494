import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    amount: {
        id: 'withdrawal-fiat.form.amount',
        defaultMessage: 'Amount',
    },
    currency: {
        id: 'withdrawal-fiat.form.currency',
        defaultMessage: 'Currency',
    },
    selectCurrencyPlaceholder: {
        id: 'withdrawal-fiat.form.select_currency_placeholder',
        defaultMessage: 'Select currency',
    },
    paymentMethod: {
        id: 'withdrawal-fiat.form.payment_method',
        defaultMessage: 'Payment method',
    },
    selectMethodPlaceholder: {
        id: 'withdrawal-fiat.form.select_method_placeholder',
        defaultMessage: 'Select method',
    },
    bankAccountDetailsPlaceholder: {
        id: 'withdrawal-fiat.form.bank_account_details_placeholder',
        defaultMessage: 'Bank account details',
    },
    bankAccountDetailsExtra: {
        id: 'withdrawal-fiat.form.bank_account_details_extra',
        defaultMessage: 'Required: Account number, IBAN, SWIFT BIC, Bank name, Bank address',
    },
    fee: {
        id: 'withdrawal-fiat.form.fee',
        defaultMessage: 'Fee',
    },
    amountLowerThanFee: {
        id: 'withdrawal-fiat.form.amount_lower_than_fee',
        defaultMessage: 'Amount should be greater than withdrawal fee',
    },
    previousRequisitesPopoverTitle: {
        id: 'withdrawal-fiat.form.previous_requisites_popover_title',
        defaultMessage: 'For this method last time you used this bank account details:',
    },
    withdrawToThis: {
        id: 'withdrawal-fiat.button.withdraw_to_this',
        defaultMessage: 'Withdraw to <strong>this</strong> account',
    },
    withdrawToNew: {
        id: 'withdrawal-fiat.button.withdraw_to_new',
        defaultMessage: 'Withdraw to <strong>new</strong> account',
    },
})
