import { Card, Col, Row, Skeleton } from '@merchant/ui-kit/ant-design'
import { WithdrawalHeading } from '../WithdrawalHeading'
import { PlaceholderViewWrapper } from '~features/PlaceholderViewWrapper'

interface Props {
    id: string | undefined
}

export function WithdrawalSkeleton({ id }: Props) {
    return (
        <PlaceholderViewWrapper dataMerchantPrefix="withdrawal">
            <WithdrawalHeading id={id} />
            <Row wrap gutter={[16, 16]}>
                <Col span={24}>
                    <Card data-merchant={null}>
                        <Skeleton
                            active
                            avatar={{
                                shape: 'circle',
                                size: 64,
                                style: { marginLeft: '16px' },
                            }}
                            title={false}
                            paragraph={{
                                rows: 2,
                                style: { width: '20%', marginInline: 'auto' },
                                width: '80%',
                            }}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: 20,
                            }}
                        />
                    </Card>
                </Col>
                <Col span={12} style={{ display: 'inline-flex', alignSelf: 'stretch' }}>
                    <Card data-merchant={null} style={{ width: '100%' }}>
                        <Skeleton active loading paragraph={{ rows: 10 }} />
                    </Card>
                </Col>
                <Col span={12} style={{ display: 'inline-flex', alignSelf: 'stretch' }}>
                    <Card data-merchant={null} style={{ width: '100%' }}>
                        <Skeleton active loading paragraph={{ rows: 10 }} />
                    </Card>
                </Col>
            </Row>
        </PlaceholderViewWrapper>
    )
}
