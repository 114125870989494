import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    withdrawalUnavailableTitle: {
        id: 'currencies-settings.modal.withdrawal_unavailable',
        defaultMessage: 'Withdrawal in {currency} is unavailable',
    },
    fiatWithdrawalUnavailableDescription: {
        id: 'currencies-settings.modal.fiat_withdrawal_unavailable',
        defaultMessage: `
            You have selected {currency} as your default conversion currency. Incoming payments will be converted 
            to {currency} and accumulated in your balance. However, it's important to note that <bold>withdrawals in {currency} 
            are currently disabled for your account</bold>. 
            <break></break>
            <break></break>
            To withdraw funds, you will need to convert your balance to a permitted currency. For direct {currency} 
            withdrawal options, please reach out to our <supportEmail>support team</supportEmail> or your personal account manager for assistance.
        `,
    },
    attentionRequiredTitle: {
        id: 'currencies-settings.modal.attention_required',
        defaultMessage: 'Attention required',
    },
    attentionRequiredDescription: {
        id: 'currencies-settings.modal.attention_required_description',
        defaultMessage: `
            You have selected {currency} as your default conversion currency, and <bold>{currency} has been added 
            to the Direct Accumulation list</bold>. Incoming payments in currencies not listed in the 
            Direct Accumulation section will be converted to {currency} and accumulated in your balance.
        `,
    },
    cryptoWithdrawalUnavailableDescription: {
        id: 'currencies-settings.modal.crypto_withdrawal_unavailable',
        defaultMessage: `
            You have selected {currency} as your default conversion currency, and {currency} has been added to the 
            Direct Accumulation list. Incoming payments in currencies not listed in the Direct 
            Accumulation section will be converted to {currency} and accumulated in your balance. However, it's 
            important to note that <bold>withdrawals in {currency} are disabled</bold>.
            <break></break>
            <break></break>
            You may need to convert your {currency} currency back before withdrawal.
        `,
    },
    contactSupportButton: {
        id: 'currencies-settings.modal.button.contact_support',
        defaultMessage: 'Contact Support',
    },
    okButton: {
        id: 'currencies-settings.modal.button.ok',
        defaultMessage: 'Ok, understood',
    },
})
