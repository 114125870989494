import { lang } from './lang'
import { steps } from './types'
import type { CreateApiKeyFormData } from './types'
import type { FormItemsKey } from '@merchant/shared/types/form'

export const formItemsKey: FormItemsKey<CreateApiKeyFormData> = {
    name: 'name',
    permissions: 'permissions',
}
export const stepsModalTitleMap = {
    [steps.createApiKeyForm]: lang.modalTitleCreate,
    [steps.createApiKeyConfirmation]: lang.modalTitleCreate,
    [steps.createApiKeyCreated]: lang.modalTitleSuccess,
}
