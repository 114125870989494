import { AppCoin } from '@merchant/shared/components'
import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import { useMemo } from 'react'
import { useCurrencies } from '~api'
import { getAlphabeticallySortedCryptoCurrencies } from '~utils'

const { Text } = Typography

export function useCurrencyOptions() {
    const { data: currencies, isLoading } = useCurrencies()

    const options = useMemo(() => {
        const alphabeticallyOrdered = getAlphabeticallySortedCryptoCurrencies({ currencies })

        return alphabeticallyOrdered.map(currency => {
            const { code = '', name = '' } = currency

            return {
                key: code + name,
                label: (
                    <Flex align="center" gap={4}>
                        <AppCoin currencyCode={code} size="small" />
                        <Text data-merchant={null}>{code}</Text>
                        <Text data-merchant={null} type="secondary">
                            {name}
                        </Text>
                    </Flex>
                ),
                value: code,
            }
        })
    }, [currencies])

    return {
        options,
        isLoading,
    }
}
