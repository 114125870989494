import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg, InlineLinkButton, ModalResponsive } from '@merchant/shared/components'
import { usePromise } from '@merchant/shared/hooks'
import { Flex, Typography, type ModalProps } from '@merchant/ui-kit/ant-design'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ConfirmationForm } from '../ConfirmationForm'
import { lang as commonLang } from './../../lang'
import { Step, dataMerchantPrefix, initialStep } from './const'
import { EditView } from './EditView'
import { lang } from './lang'
import type { Project, ProjectTeamMember, TOTPCredentials } from '~api/instances/cabinet-api'
import { projectApi } from '~api'
import { useDirtyFormCheckerControls } from '~hooks'

const { Text } = Typography

interface Props extends Omit<ModalProps, 'data-merchant'> {
    onSuccess: (selectedMember: ProjectTeamMember | undefined) => void
    selectedMember: ProjectTeamMember | undefined
    project: Project | undefined
}

export function EditMemberModal({
    project,
    onSuccess,
    selectedMember,
    afterClose: afterCloseProp,
    onCancel,
    ...props
}: Props) {
    const intl = useIntl()
    const [step, setStep] = useState<Step>(Step.EditView)
    const [editedMember, setEditedMember] = useState<ProjectTeamMember>()
    const { id: projectId, name: projectName } = project || {}
    const { handleCancel } = useDirtyFormCheckerControls(onCancel)

    const { send: request } = usePromise(
        (params: TOTPCredentials) => {
            if (!editedMember) {
                throw new Error(intl.formatMessage(commonLang.projectMemberNotDefined))
            }
            if (!projectId) {
                throw new Error(intl.formatMessage(commonLang.projectIdNotDefined))
            }

            return projectApi.updateProjectTeamMember({
                projectId,
                teamMemberId: editedMember.id,
                updateProjectTeamMemberRequest: {
                    name: editedMember.name,
                    roles: editedMember.roles,
                    ...params,
                },
            })
        },
        {
            onSuccess: teamMember => onSuccess(teamMember),
            onError: error => {
                throw error
            },
            showErrorNotification: false,
        }
    )

    const views: Record<Step, () => JSX.Element> = {
        [Step.EditView]: () => {
            const onFinish = (memberNewData: ProjectTeamMember) => {
                setEditedMember({ ...selectedMember, ...memberNewData })
                setStep(Step.ConfirmView)
            }

            return (
                <EditView
                    editedMember={editedMember || selectedMember}
                    initialSelectedMember={selectedMember}
                    onFinish={onFinish}
                />
            )
        },
        [Step.ConfirmView]: () => <ConfirmationForm mode="edit" request={request} />,
    }

    const handleGoBackClick = () => setStep(prev => --prev)

    const afterClose = () => {
        afterCloseProp?.()
        setStep(initialStep)
        setEditedMember(undefined)
    }

    return (
        <ModalResponsive
            data-merchant={dataMerchantPrefix}
            afterClose={afterClose}
            onCancel={handleCancel}
            destroyOnClose
            title={
                <Flex vertical>
                    <Flex gap={4}>
                        {step > 0 && (
                            <InlineLinkButton
                                data-merchant={`${dataMerchantPrefix}-back-button`}
                                icon={<AppSvg size={24} name={assets.arrowNarrowLeft} />}
                                onClick={handleGoBackClick}
                            />
                        )}
                        <span data-merchant={`${dataMerchantPrefix}-title`}>
                            <FormattedMessage {...lang.modalTitle} />
                        </span>
                    </Flex>
                    <Text type="secondary" data-merchant="edit-member-modal-project-name">
                        <FormattedMessage
                            {...lang.modalSubtitle}
                            values={{
                                projectName: (
                                    <span data-merchant={`${dataMerchantPrefix}-project-name`}>{projectName}</span>
                                ),
                            }}
                        />
                    </Text>
                </Flex>
            }
            footer={null}
            {...props}
        >
            {views[step]()}
        </ModalResponsive>
    )
}
