import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from '../../lang'
import type { ProjectTeamMember } from '~api/instances/cabinet-api'

const { Text } = Typography

interface Props {
    member: ProjectTeamMember
}

export function MemberEmail({ member }: Props) {
    const { joined, email, id } = member

    // TODO: Uncomment when backend adds the limit for resending email
    /* const { send: resendInvitation, isLoading: isResendInvitationLoading } = usePromise(() => {
        if (!projectId) {
            const projectIdErrorMessage = intl.formatMessage(lang.projectIdNotDefined)

            notification.api.open({
                message: (
                    <NotificationMessage
                        type="error"
                        title={<FormattedMessage {...globalLang.somethingWentWrong} />}
                        description={projectIdErrorMessage}
                    />
                ),
            })

            return Promise.reject()
        }

        return projectApi.resendProjectTeamMemberInvitation({ projectId: projectId || '', teamMemberId: member.id })
    }) */

    return (
        <Flex vertical>
            <Flex gap={4}>
                <span data-merchant={`team-management-email-${id}`}>{email}</span>
                {/* TODO: Uncomment when backend adds the limit for resending email
                {!joined && (
                    <Tooltip
                        title={
                            <span data-merchant={`team-management-resend-email-tooltip-content-${id}`}>
                                <FormattedMessage {...lang.resendEmailAgain} />
                            </span>
                        }
                        placement="bottom"
                    >
                        <InlineLinkButton
                            onClick={resendInvitation}
                            loading={isResendInvitationLoading}
                            data-merchant={`team-management-resend-email-button-${id}`}
                            icon={<AppSvg size={20}  name={assets.refreshCw} />}
                        />
                    </Tooltip>
                )} */}
            </Flex>
            {!joined && (
                <Text type="warning" style={{ fontSize: 'inherit' }} data-merchant={`team-management-not-joined-${id}`}>
                    <FormattedMessage {...lang.userNotRegistered} />
                </Text>
            )}
        </Flex>
    )
}
