import { MenuOutlined } from '@ant-design/icons'
import { assets } from '@merchant/shared/assets/nameMap'
import { AppImg, AppSvg, ModalResponsive, ColorModeDropdown } from '@merchant/shared/components'
import { authTokenKey } from '@merchant/shared/constants/localStorage'
import { useBreakpoint } from '@merchant/shared/hooks'
import { headerButtonCommonProps } from '@merchant/shared/props'
import { Button, Flex, Layout, Space, Typography, theme } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { useBoolean, useLocalStorage } from 'usehooks-ts'
import { ProjectPickerDropdown } from '../ProjectPicker'
import { TotalBalance } from '../TotalBalance'
import { lang } from './lang'
import { getHeaderStyles, logoLinkStyles, logoutModalTitleStyles } from './style'
import styles from './style.module.css'
import { getShouldShowProjectName, getShouldShowTotalBalance } from './utils'
import { useProject } from '~api'
import { routes as AppRoutes, QueryParams } from '~constants/routes'
import { useUIContext } from '~contexts'
import { MerchantSettingsShowOptions } from '~features/MerchantPage/components/MerchantSettings/types'
import { globalLang } from '~globalLang'
import { useLogout } from '~hooks/useLogout'
import { generateQuery } from '~utils'

const { Text, Paragraph } = Typography
const { Header: AntHeader } = Layout
const { useToken } = theme

export function Header() {
    const { data: project } = useProject()
    const [authToken] = useLocalStorage<string>(authTokenKey, '')
    const { token } = useToken()
    const { logout } = useLogout()
    const { toggleSideMenu: toggleSider } = useUIContext()
    const location = useLocation()
    const breakpoints = useBreakpoint()

    const {
        value: isLogoutConfirmationOpen,
        setTrue: openLogoutConfirmation,
        setFalse: closeLogoutConfirmation,
    } = useBoolean(false)

    const {
        token: { colorWarning },
    } = useToken()

    const themeButton = <ColorModeDropdown />

    const mobileMenuButton = (
        <Button onClick={toggleSider} {...headerButtonCommonProps} data-merchant="mobile-menu-toggle-button">
            <MenuOutlined style={{ color: token.colorText, fontSize: 17 }} />
        </Button>
    )

    const onLogoutConfirm = () => {
        logout()
        closeLogoutConfirmation()
    }

    const getMobileHeaderButton = () => (authToken ? mobileMenuButton : themeButton)

    return (
        <>
            <ModalResponsive
                open={isLogoutConfirmationOpen}
                onCancel={closeLogoutConfirmation}
                footer={null}
                data-merchant="logout-confirm-modal"
            >
                <Paragraph data-merchant="logout-confirm-modal-text" style={logoutModalTitleStyles}>
                    <Space direction="vertical">
                        <AppSvg name={assets.alertCircle} size={48} color={colorWarning} />
                        <FormattedMessage {...lang.logoutConfirmationText} />
                    </Space>
                </Paragraph>
                <Space direction="horizontal" style={{ width: '100%' }} styles={{ item: { flexGrow: 1 } }} size={12}>
                    <Button
                        data-merchant="logout-confirm-cancel-button"
                        block
                        onClick={closeLogoutConfirmation}
                        size="large"
                    >
                        <FormattedMessage {...globalLang.close} />
                    </Button>
                    <Button
                        type="primary"
                        data-merchant="logout-confirm-button"
                        block
                        onClick={onLogoutConfirm}
                        size="large"
                    >
                        <FormattedMessage {...globalLang.confirm} />
                    </Button>
                </Space>
            </ModalResponsive>
            <AntHeader style={getHeaderStyles(breakpoints)}>
                <NavLink
                    to={authToken ? AppRoutes.orders : `${AppRoutes.auth}`}
                    style={logoLinkStyles}
                    data-merchant="logo-image-link"
                >
                    <AppImg height="100%" themed name={assets.logo} alt="Logo" />
                </NavLink>
                {breakpoints.lg ? (
                    <Space size={26} align="center">
                        {getShouldShowTotalBalance(breakpoints, authToken) && <TotalBalance isInHeader />}
                        {getShouldShowProjectName(breakpoints, location, authToken) && (
                            <Link
                                data-merchant="header-project-name-link"
                                to={`${AppRoutes.merchantSettings}${generateQuery({ [QueryParams.show]: MerchantSettingsShowOptions.general })}`}
                            >
                                <Text
                                    data-merchant="header-project-name"
                                    className={styles.projectNameBtn}
                                    type="secondary"
                                >
                                    {project?.name}
                                </Text>
                            </Link>
                        )}
                        <Flex align="middle" gap={8}>
                            {authToken && <ProjectPickerDropdown onLogoutButtonClick={openLogoutConfirmation} />}
                            {themeButton}
                        </Flex>
                    </Space>
                ) : (
                    getMobileHeaderButton()
                )}
            </AntHeader>
        </>
    )
}
