import { usePromise } from '@merchant/shared/hooks'
import dayjs from 'dayjs'
import { useState } from 'react'
import { OrderSettingsType } from './const.tsx'
import { determineCollapseErrorKey, normalizeLossConfig, normalizeRecommendedAmount } from './utils.tsx'
import type { NewPaymentFormData } from './types.ts'
import type { Breakpoints } from '@merchant/shared/hooks'
import type { FormProps } from '@merchant/ui-kit/ant-design'
import type { ModalFuncWithPromise } from '@merchant/ui-kit/ant-design/es/modal/useModal'
import type { CreatePaymentRequest, Payment, PaymentType, Project } from '~api/instances/cabinet-api'
import { projectApi } from '~api'
import { getModalConfirmProps, isUnderpaymentLarge } from '~utils/underpayment/index.tsx'

interface Props {
    project: Project | undefined
    onCreatePaymentSuccess: (data: Payment) => void
    modalConfirm: ModalFuncWithPromise
    breakpoints: Breakpoints
    type: PaymentType
}

export function useHandlers({ project, onCreatePaymentSuccess, modalConfirm, breakpoints, type }: Props) {
    const [collapseActiveKey, setCollapseActiveKey] = useState<OrderSettingsType[]>([OrderSettingsType.BasicSettings])

    const { send: createPayment, isLoading: isCreatePaymentLoading } = usePromise(
        (createPaymentRequest: CreatePaymentRequest) =>
            projectApi.createPayment({
                createPaymentRequest,
                projectId: project?.id || '',
            }),
        { onSuccess: onCreatePaymentSuccess }
    )

    const onCreatePayment = async (values: NewPaymentFormData) => {
        if (values.lossConfig && isUnderpaymentLarge(values.lossConfig)) {
            const isConfirmed = await modalConfirm(getModalConfirmProps(values.lossConfig, breakpoints))

            if (!isConfirmed) {
                return
            }
        }

        let { pendingDeadlineAt } = values
        if (typeof pendingDeadlineAt === 'number') {
            pendingDeadlineAt = dayjs().add(pendingDeadlineAt, 'hours').toDate()
        }

        const createPaymentParams: CreatePaymentRequest = {
            ...values,
            type,
            pendingDeadlineAt,
            lossConfig: normalizeLossConfig(values.lossConfig),
            recommendedAmount: normalizeRecommendedAmount(values.recommendedAmount),
        }

        createPayment(createPaymentParams)
    }

    const onFormFinishFailure: FormProps['onFinishFailed'] = errorInfo => {
        const firstErrorField = errorInfo.errorFields[0]?.name[0]

        const collapseErrorKey = determineCollapseErrorKey(firstErrorField as keyof NewPaymentFormData)

        if (collapseErrorKey) {
            setCollapseActiveKey([collapseErrorKey])
        }
    }

    return {
        onCreatePayment,
        isCreatePaymentLoading,
        onFormFinishFailure,
        collapseActiveKey,
        setCollapseActiveKey,
    }
}
