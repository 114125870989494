import { Col, Flex, Row, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import styles from './style.module.css'
import type { ReactNode } from 'react'
import type { MessageDescriptor } from 'react-intl'

const { Text } = Typography

export const getRow = (label: MessageDescriptor | string, value: ReactNode, extra?: ReactNode) => (
    <Row>
        <Col>
            <Flex gap={4} align="center">
                <Text data-merchant={null} type="secondary">
                    {typeof label === 'string' ? label : <FormattedMessage {...label} />}
                </Text>
                {extra}
            </Flex>
        </Col>
        <Col className={styles.middleCol} />
        <Col>{value}</Col>
    </Row>
)
