import { useCallback, useEffect, useRef } from 'react'
import type { DependencyList } from 'react'

export function useCallbackRef<T extends (...args: never[]) => unknown>(
    callback: T | undefined,
    deps: DependencyList = []
) {
    const callbackRef = useRef(callback)

    useEffect(() => {
        callbackRef.current = callback
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback(((...args) => callbackRef.current?.(...args)) as T, deps)
}
