import { Form, Input } from '@merchant/ui-kit/ant-design'
import { FormattedMessage, useIntl } from 'react-intl'
import { newOrderFormKeys } from '../../../../const'
import { lang } from '../lang'
import type { FormItemProps } from '@merchant/ui-kit/ant-design'

export function DescriptionItem(props: FormItemProps) {
    const intl = useIntl()

    return (
        <Form.Item {...props} name={newOrderFormKeys.description} label={<FormattedMessage {...lang.description} />}>
            <Input.TextArea
                placeholder={intl.formatMessage(lang.descriptionPlaceholder)}
                autoSize
                maxLength={4096}
                data-merchant="order-description-input"
            />
        </Form.Item>
    )
}
