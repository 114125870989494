import { Statistic, Typography } from '@merchant/ui-kit/ant-design'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'

const { Paragraph } = Typography
const { Countdown } = Statistic

const paragraphStyles: React.CSSProperties = {
    marginBottom: 0,
    display: 'inline-flex',
    alignItems: 'center',
    gap: 4,
    opacity: 0.5,
}
const countdownValueStyles: React.CSSProperties = {
    color: 'inherit',
    fontSize: 'inherit',
}
const countdownStyles: React.CSSProperties = { color: 'inherit' }

interface Props {
    handleCountdownFinish: () => void
    countdownSecondsAmount?: number
}

export function CountdownInputSuffix({ handleCountdownFinish, countdownSecondsAmount = 60 }: Props) {
    const [deadline, setDeadline] = useState<number | null>(null)

    useEffect(() => {
        setDeadline(
            dayjs()
                .add(1000 * countdownSecondsAmount)
                .valueOf()
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Paragraph data-merchant={null} style={paragraphStyles}>
            <FormattedMessage {...lang.resendIn} />
            <Countdown
                valueStyle={countdownValueStyles}
                style={countdownStyles}
                value={deadline || 0}
                onFinish={handleCountdownFinish}
                format={countdownSecondsAmount <= 60 ? 'ss' : 'mm:ss'}
            />
            {countdownSecondsAmount <= 60 && 'sec'}
        </Paragraph>
    )
}
