import { parseDateAndTime } from '@merchant/shared/utils'
import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import type { ConvertedWithdrawal } from '~features/WithdrawalsPage/views/Withdrawal/types'
import { getRow } from '~features/WithdrawalsPage/views/Withdrawal/utils'

const { Text } = Typography

interface Props {
    data: ConvertedWithdrawal
}

const getRows = ({ data }: { data: ConvertedWithdrawal }) => {
    const swappedAt = parseDateAndTime(data.swappedAt)

    return [
        getRow(
            lang.date,
            <>
                <Text type="secondary" data-merchant="withdrawal-timeline-swap-date">
                    {swappedAt?.date}
                </Text>
                &nbsp;
                <Text data-merchant="withdrawal-timeline-swap-time">{swappedAt?.time}</Text>
            </>
        ),
        getRow(
            lang.currencyFrom,
            <Text data-merchant="withdrawal-timeline-swap-creditCurrency">{data.creditCurrency}</Text>
        ),
        getRow(
            lang.exchangeRate,
            <Text data-merchant="withdrawal-timeline-swapRate">
                1 {data.creditCurrency} = {data.swapRate} {data.receiveCurrency}
            </Text>
        ),
        getRow(
            lang.amountRequested,
            <Text data-merchant="withdrawal-timeline-swap-creditAmount">
                {data.creditAmount} {data.creditCurrency}
            </Text>
        ),
    ]
}

export function ConvertTimeline({ data }: Props) {
    return (
        <Flex vertical gap={16}>
            <Text data-merchant="withdrawal-timeline-convert-label">
                <FormattedMessage {...lang.convertTimeline} />
            </Text>
            {getRows({ data })}
        </Flex>
    )
}
