import { Col, Row, Typography } from '@merchant/ui-kit/ant-design'
import styles from '../../../style.module.css'
import type { Payment } from '~api/instances/cabinet-api'

const { Text } = Typography

export const getIfExchangeRatesShown = (data: Payment, exchangeRate: string | number | undefined) => {
    return (
        Boolean(exchangeRate) &&
        data.selectedPaymentMethod?.payinCurrency !== data.nominalCurrency &&
        exchangeRate?.toString() !== '1'
    )
}

export const getRow = (label: JSX.Element | string, content: JSX.Element) => (
    <Row justify="space-between">
        <Col>
            <Text type="secondary" data-merchant={null}>
                {label}
            </Text>
        </Col>
        <Col className={styles.middleCol} />
        <Col>{content}</Col>
    </Row>
)
