import { useCommonAppSettings } from '@merchant/shared/api/swr/rest'
import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { useFormValidation } from '@merchant/shared/hooks'
import { getAddressRegexp, getAddressTagName, getNetworksOrderedByCMS } from '@merchant/shared/utils'
import { Alert, Button, Flex, Form, Input, Typography } from '@merchant/ui-kit/ant-design'
import { isEmpty, upperFirst } from 'lodash-es'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useBoolean } from 'usehooks-ts'
import { initWhitelist } from '../../const'
import { formKeys } from './const'
import { FormItemExtraCheckbox } from './FormItemExtraCheckbox'
import { lang } from './lang'
import { SelectWithTooltip } from './SelectWithTooltip'
import { getNetworkSelectProperties } from './utils'
import type { CheckboxChangeEvent } from '@merchant/ui-kit/ant-design/es/checkbox'
import type { WhitelistAddress } from '~api/instances/cabinet-api'
import type { WithdrawalCurrency } from '~features/ActionModal/types'
import { projectApi, useBalances, useCurrencies, useProject } from '~api'
import { CurrencyOption } from '~components'
import { ModalNames } from '~constants/modal'
import { FormattedMessageWithBoldText } from '~features/WhitelistPage/components/FormattedMessageWithBoldText'
import { lang as commonLang } from '~features/WhitelistPage/lang'
import { globalLang } from '~globalLang'
import { useNetworks, useRegisterDirtyFormChecker } from '~hooks'
import compatSelectStyle from '~styles/compact.style.module.css'
import selectGroupsStyle from '~styles/select-groups.style.module.css'
import {
    getWithdrawalCurrencies,
    getWithdrawalCurrencySelectOption,
    getWithdrawalOptionGroups,
    mapNetworksToOptions,
} from '~utils'

const { Text } = Typography

const alertStyles: React.CSSProperties = {
    alignItems: 'center',
    textAlign: 'left',
}

interface Props {
    onSuccess: (newItem: WhitelistAddress) => void
}

// eslint-disable-next-line max-lines-per-function, complexity
export function InputView({ onSuccess }: Props) {
    const { data: { id: projectId = '' } = {} } = useProject()
    const { data: currencies, isLoading: isCurrenciesLoading } = useCurrencies()
    const { data: balances, isLoading: isBalancesLoading } = useBalances()
    const { data: siteSettings } = useCommonAppSettings()
    const { value: isUniversalAddress, toggle: toggleIsUniversalAddress } = useBoolean(false)
    const { value: isTagNameSkipped, toggle: toggleIsTagNameSkipped } = useBoolean(false)
    const { value: isValidationLoading, setValue: setIsValidationLoading } = useBoolean(false)
    const { validationMode, onBlur } = useFormValidation(Object.values(formKeys))
    const intl = useIntl()
    const [form] = Form.useForm<WhitelistAddress>()
    const selectedCurrency = Form.useWatch(formKeys.currency, form)
    const selectedNetwork = Form.useWatch(formKeys.network, form)
    useRegisterDirtyFormChecker(ModalNames.whitelistAddInput, form)
    const { networksOrder } = siteSettings || {}
    const tagName = getAddressTagName(currencies, selectedCurrency, selectedNetwork) || ''
    const addressRegexp = getAddressRegexp(currencies, selectedCurrency, selectedNetwork)

    const { networks: allNetworks } = useNetworks()
    const selectedCurrencyNetworks = selectedCurrency ? currencies?.[selectedCurrency]?.networks : null

    const withdrawableCurrencies = useMemo(() => {
        if (isBalancesLoading || isCurrenciesLoading) {
            return {}
        }

        const cryptoCurrencies = Object.fromEntries(
            Object.entries(currencies || {}).filter(([_, { crypto } = { crypto: undefined }]) => crypto)
        )

        return getWithdrawalCurrencies({ balances, currencies: cryptoCurrencies, isWithdrawableOnly: false })
    }, [balances, currencies, isBalancesLoading, isCurrenciesLoading])

    const handleIsSetUniversalAddressChange = ({ target: { checked } }: CheckboxChangeEvent) => {
        toggleIsUniversalAddress()
        const fieldsToReset: (keyof typeof formKeys)[] = [formKeys.currency]
        !checked && fieldsToReset.push(formKeys.network)
        form.resetFields(fieldsToReset)
    }

    const handleIsTagNameSkipped = () => {
        toggleIsTagNameSkipped()
        form.resetFields([formKeys.addressTag])
    }

    const handleFormFinish = async (whitelist: WhitelistAddress) => {
        setIsValidationLoading(true)
        try {
            const { valid: isValid } = await projectApi.validateProjectWhitelistAddress({
                validateWhitelistAddressRequest: {
                    address: whitelist.address,
                    network: whitelist.network,
                    currency: whitelist.currency,
                },
                projectId,
            })
            if (!isValid) {
                return form.setFields([
                    {
                        name: formKeys.address,
                        errors: [intl.formatMessage(lang.addressAlreadyWhitelisted)],
                    },
                ])
            }

            onSuccess(whitelist)
        } catch {
            form.setFields([
                {
                    name: formKeys.address,
                    errors: [intl.formatMessage(globalLang.somethingWentWrong)],
                },
            ])
        } finally {
            setIsValidationLoading(false)
        }
    }

    const networksOrderedByCMS = getNetworksOrderedByCMS(selectedCurrencyNetworks || allNetworks, networksOrder)
    const networkSelectStatus = getNetworkSelectProperties({
        intl,
        isUniversalAddress,
        selectedCurrency,
        selectedCurrencyNetworks,
    })

    return (
        <Form
            form={form}
            onFinish={handleFormFinish}
            initialValues={initWhitelist}
            size="large"
            preserve={false}
            layout="vertical"
        >
            <Form.Item<WhitelistAddress>
                label={<FormattedMessage {...lang.nameLabel} />}
                validateTrigger={validationMode.name}
                name={formKeys.name}
                rules={[
                    {
                        required: true,
                        message: <FormattedMessage {...globalLang.requiredFieldMessage} />,
                    },
                    {
                        max: 40,
                        min: 4,
                        message: <FormattedMessage {...lang.nameLengthErrorMessage} />,
                    },
                ]}
            >
                <Input
                    maxLength={40}
                    onBlur={onBlur('name')}
                    autoComplete="off"
                    data-merchant="add-whitelist-name-input"
                    placeholder={intl.formatMessage(lang.namePlaceholder)}
                />
            </Form.Item>
            <Form.Item<WhitelistAddress>
                name={formKeys.address}
                extra={
                    <FormItemExtraCheckbox
                        checked={isUniversalAddress}
                        onChange={handleIsSetUniversalAddressChange}
                        data-merchant="whitelist-universal-address-checkbox"
                        id="universal-address-checkbox"
                        checkboxMessage={<FormattedMessage {...lang.universalAddressCheckboxLabel} />}
                        tooltipMessage={<FormattedMessage {...lang.universalAddressTooltip} />}
                    />
                }
                label={<FormattedMessage {...lang.addressLabel} />}
                rules={[
                    {
                        required: true,
                        message: <FormattedMessage {...globalLang.requiredFieldMessage} />,
                    },
                    {
                        pattern: addressRegexp,
                        message: <FormattedMessage {...globalLang.invalidFormat} />,
                    },
                ]}
                hasFeedback={{
                    icons: () => ({
                        error: <div />,
                        success: (
                            <div style={{ display: 'flex' }}>
                                <AppSvg size={18} name={assets.check} />
                            </div>
                        ),
                    }),
                }}
            >
                <Input
                    autoComplete="off"
                    data-merchant="add-whitelist-address-input"
                    placeholder={intl.formatMessage(lang.addressPlaceholder)}
                />
            </Form.Item>
            <Form.Item label={<FormattedMessage {...lang.currencyAndNetwork} />}>
                <Flex vertical gap={4}>
                    <Form.Item<WhitelistAddress>
                        noStyle
                        rules={[
                            {
                                required: !isUniversalAddress,
                                message: <FormattedMessage {...lang.currencyErrorMessage} />,
                            },
                        ]}
                        name={formKeys.currency}
                    >
                        <SelectWithTooltip
                            className={compatSelectStyle.selectCompactTopItem}
                            showSearch
                            tooltipProps={{
                                'data-merchant': null,
                                title: isUniversalAddress && (
                                    <Text
                                        data-merchant="universal-address-select-tooltip"
                                        type="secondary"
                                        style={{ fontSize: 12 }}
                                    >
                                        <FormattedMessage {...lang.universalAddressSelectTooltip} />
                                    </Text>
                                ),
                            }}
                            optionFilterProp="key"
                            popupClassName={selectGroupsStyle.popup}
                            disabled={isUniversalAddress}
                            data-merchant="add-whitelist-currency-select"
                            placeholder={intl.formatMessage(
                                isUniversalAddress
                                    ? lang.selectCurrencyPlaceholderUniversal
                                    : lang.selectCurrencyPlaceholder
                            )}
                            loading={isCurrenciesLoading || isBalancesLoading}
                            onChange={() => form.resetFields([formKeys.network])}
                            options={getWithdrawalOptionGroups(
                                getWithdrawalCurrencySelectOption(withdrawableCurrencies)
                            )}
                            fieldNames={{ value: 'code' }}
                            labelRender={props => {
                                const currency = currencies?.[props.value]

                                return currency ? (
                                    <CurrencyOption
                                        data={currency}
                                        dataMerchant={`add-whitelist-currency-${currency.code}`}
                                    />
                                ) : (
                                    props.label
                                )
                            }}
                            optionRender={option => (
                                <CurrencyOption
                                    // FIXME: TS type casting
                                    data={option.data as WithdrawalCurrency}
                                    extension={
                                        option.data.disabled && (
                                            <Text data-merchant={null} type="secondary" ellipsis>
                                                <FormattedMessage {...globalLang.withdrawalsTempUnavailable} />
                                            </Text>
                                        )
                                    }
                                    dataMerchant={`add-whitelist-currency-${option.data.code}`}
                                />
                            )}
                        />
                    </Form.Item>
                    <Form.Item<WhitelistAddress>
                        noStyle
                        name={formKeys.network}
                        rules={[
                            {
                                required: isUniversalAddress || !isEmpty(selectedCurrencyNetworks),
                                message: <FormattedMessage {...globalLang.requiredFieldMessage} />,
                            },
                        ]}
                    >
                        <SelectWithTooltip
                            className={compatSelectStyle.selectCompactBottomItem}
                            tooltipProps={{
                                'data-merchant': null,
                                title: networkSelectStatus.tooltipTitle && (
                                    <Text
                                        data-merchant="network-select-tooltip"
                                        type="secondary"
                                        style={{ fontSize: 12 }}
                                    >
                                        {networkSelectStatus.tooltipTitle}
                                    </Text>
                                ),
                            }}
                            disabled={networkSelectStatus.disabled}
                            labelRender={({ label }) => (
                                <Flex align="center">
                                    <Text data-merchant={null} type="secondary">
                                        <FormattedMessage {...lang.networkPrefix} />
                                        &nbsp;
                                    </Text>
                                    {label}
                                </Flex>
                            )}
                            data-merchant="add-whitelist-network-select"
                            dropdownRender={menu => (
                                <>
                                    <Alert
                                        data-merchant="network-select-alert"
                                        type="warning"
                                        showIcon
                                        icon={<AppSvg name={assets.alertCircle} size={20} />}
                                        message={
                                            <Text data-merchant={null} type="secondary" style={{ fontSize: 12 }}>
                                                <FormattedMessage {...lang.verifyNetworkAlert} />
                                            </Text>
                                        }
                                        style={{ margin: '8px 12px' }}
                                    />
                                    {menu}
                                </>
                            )}
                            placeholder={intl.formatMessage(lang.selectNetworkPlaceholder)}
                            options={mapNetworksToOptions({
                                networks: networksOrderedByCMS,
                                dataMerchantPrefix: 'add-whitelist',
                            })}
                        />
                    </Form.Item>
                </Flex>
            </Form.Item>
            {tagName && (
                <Form.Item<WhitelistAddress>
                    name={formKeys.addressTag}
                    label={upperFirst(tagName)}
                    rules={[
                        {
                            required: !isTagNameSkipped,
                            message: <FormattedMessage {...lang.tagNameErrorMessage} values={{ tagName }} />,
                        },
                    ]}
                    extra={
                        <FormItemExtraCheckbox
                            checked={isTagNameSkipped}
                            onChange={handleIsTagNameSkipped}
                            data-merchant="whitelist-tagname-skip-checkbox"
                            id="tagname-skip-checkbox"
                            checkboxMessage={
                                <FormattedMessage {...lang.skipTagNameCheckboxLabel} values={{ tagName }} />
                            }
                            tooltipMessage={<FormattedMessage {...lang.tagNameSkipTooltip} values={{ tagName }} />}
                        />
                    }
                >
                    <Input
                        disabled={isTagNameSkipped}
                        placeholder={intl.formatMessage(lang.tagNamePlaceholder, { tagName })}
                        data-merchant="whitelist-tagname-input"
                    />
                </Form.Item>
            )}
            <Form.Item>
                <Alert
                    style={alertStyles}
                    type="warning"
                    icon={<AppSvg size={24} name={assets.alertCircle} />}
                    showIcon
                    data-merchant="add-whitelist-alert"
                    description={<FormattedMessageWithBoldText message={commonLang.newAddressAlertDescription} />}
                />
            </Form.Item>
            <Form.Item noStyle>
                <Button
                    data-merchant="add-whitelist-submit-button"
                    block
                    type="primary"
                    htmlType="submit"
                    loading={isValidationLoading}
                >
                    <FormattedMessage {...lang.addAddress} />
                </Button>
            </Form.Item>
        </Form>
    )
}
