import { QuestionCircleOutlined } from '@ant-design/icons'
import { EmailSupportLink } from '@merchant/shared/components'
import { emailValidationRegex } from '@merchant/shared/constants'
import { protocolValidator } from '@merchant/shared/utils'
import { Select } from '@merchant/ui-kit/ant-design'
import { Input, Typography, type FormItemProps, Tooltip } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { projectGeneralFormItems } from './const'
import { lang } from './lang'
import type { DefaultOptionType } from '@merchant/ui-kit/ant-design/es/select'
import type { IntlShape } from 'react-intl'
import type { Project } from '~api/instances/cabinet-api'
import { ProjectStatus } from '~api/instances/cabinet-api'
import { globalLang } from '~globalLang'

const { Text } = Typography

interface TypedOption extends DefaultOptionType {
    value: Project['status']
}

const statusOptions: TypedOption[] = [
    {
        value: ProjectStatus.Active,
        'data-merchant': 'merchant-page-status-dropdown-active',
        label: (
            <Text type="success" data-merchant={null}>
                <FormattedMessage {...lang.active} />
            </Text>
        ),
    },
    {
        value: ProjectStatus.Inactive,
        'data-merchant': 'merchant-page-status-dropdown-inactive',
        label: (
            <Text type="danger" data-merchant={null}>
                <FormattedMessage {...lang.inactive} />
            </Text>
        ),
    },
]

type FormItemsType = Array<Omit<FormItemProps, 'name'> & Required<{ name: keyof typeof projectGeneralFormItems }>>

type GetFormItems = (params: {
    intl: IntlShape
    onBlur: (name: keyof typeof projectGeneralFormItems) => () => void
    isChangeAllowed: boolean
    project?: Project
    isMerchantDisabled?: boolean
    isProjectDisabled?: boolean
}) => FormItemsType

// eslint-disable-next-line max-lines-per-function
export const getFormItems: GetFormItems = ({
    intl,
    onBlur,
    isChangeAllowed,
    project,
    isMerchantDisabled,
    isProjectDisabled,
}) => [
    {
        style: { marginBottom: 12 },
        name: projectGeneralFormItems.name,
        label: (
            <Text data-merchant={null}>
                <FormattedMessage {...lang.nameLabel} />
            </Text>
        ),
        children: (
            <Input
                disabled={!isChangeAllowed}
                autoComplete="off"
                data-merchant="merchant-page-name-input"
                onBlur={onBlur(projectGeneralFormItems.name)}
                placeholder={intl.formatMessage(lang.namePlaceholder)}
            />
        ),
        rules: [
            {
                required: true,
                message: <FormattedMessage {...globalLang.requiredFieldMessage} />,
            },
        ],
        tooltip: {
            title: intl.formatMessage(lang.nameAndDescriptionTooltip),
            icon: <QuestionCircleOutlined />,
            'data-merchant': 'merchant-page-name-tooltip',
        },
    },
    {
        style: { marginBottom: 12 },
        name: projectGeneralFormItems.description,
        label: (
            <Text data-merchant={null}>
                <FormattedMessage {...lang.descriptionLabel} />
            </Text>
        ),
        children: (
            <Input
                disabled={!isChangeAllowed}
                autoComplete="off"
                data-merchant="merchant-page-description-input"
                onBlur={onBlur(projectGeneralFormItems.description)}
                placeholder={intl.formatMessage(lang.descriptionPlaceholder)}
            />
        ),
    },
    {
        style: { marginBottom: 12 },
        label: (
            <Text data-merchant={null}>
                <FormattedMessage {...lang.linkLabel} />
            </Text>
        ),
        name: projectGeneralFormItems.link,
        children: (
            <Input
                disabled={!isChangeAllowed}
                autoComplete="off"
                data-merchant="merchant-page-link-input"
                onBlur={onBlur(projectGeneralFormItems.link)}
                placeholder={intl.formatMessage(lang.linkPlaceholder)}
            />
        ),
        rules: [
            {
                required: true,
                message: <FormattedMessage {...globalLang.requiredFieldMessage} />,
            },
            {
                type: 'url',
                message: <FormattedMessage {...globalLang.invalidUrlFormat} />,
            },
            {
                message: <FormattedMessage {...globalLang.missingProtocol} />,
                validator: protocolValidator,
            },
        ],
        tooltip: {
            title: (
                <Text data-merchant={null}>
                    <FormattedMessage
                        {...lang.websiteLinkTooltip}
                        values={{
                            url: (
                                <Text type="secondary" data-merchant={null}>
                                    <FormattedMessage {...lang.websiteLinkTooltipUrl} />
                                </Text>
                            ),
                        }}
                    />
                </Text>
            ),
            placement: 'bottom',
            icon: <QuestionCircleOutlined />,
            overlayInnerStyle: { width: 350 },
            'data-merchant': 'merchant-page-link-tooltip',
        },
    },
    {
        style: { marginBottom: 12 },
        label: (
            <Text data-merchant={null}>
                <FormattedMessage {...lang.supportEmailLabel} />
            </Text>
        ),
        name: projectGeneralFormItems.supportEmail,
        children: (
            <Input
                disabled={!isChangeAllowed}
                autoComplete="off"
                data-merchant="merchant-page-support-email-input"
                onBlur={onBlur(projectGeneralFormItems.supportEmail)}
                placeholder={intl.formatMessage(lang.emailPlaceholder)}
            />
        ),
        rules: [
            {
                required: true,
                message: <FormattedMessage {...globalLang.requiredFieldMessage} />,
            },
            {
                pattern: emailValidationRegex,
                message: <FormattedMessage {...globalLang.invalidEmailFormat} />,
            },
        ],
        tooltip: {
            title: intl.formatMessage(lang.supportEmailTooltip),
            icon: <QuestionCircleOutlined />,
            'data-merchant': 'merchant-page-support-email-tooltip',
        },
    },
    {
        style: { marginBottom: 12 },
        label: (
            <Text data-merchant={null}>
                <FormattedMessage {...lang.statusLabel} />
            </Text>
        ),
        hidden: !project,
        name: projectGeneralFormItems.status,
        children:
            isMerchantDisabled || isProjectDisabled ? (
                <Tooltip
                    title={
                        <FormattedMessage
                            {...lang.statusCannotBeChanged}
                            values={{
                                support: chunks => <EmailSupportLink>{chunks}</EmailSupportLink>,
                            }}
                        />
                    }
                    placement="bottom"
                    data-merchant="merchant-page-status-disabled-tooltip"
                >
                    <Select
                        placeholder={intl.formatMessage(globalLang.disabled)}
                        disabled
                        onBlur={onBlur(projectGeneralFormItems.status)}
                        data-merchant="merchant-page-status-select"
                    />
                </Tooltip>
            ) : (
                <Select
                    disabled={!isChangeAllowed}
                    data-merchant="merchant-page-status-select"
                    onBlur={onBlur(projectGeneralFormItems.status)}
                    options={statusOptions}
                />
            ),
        tooltip: {
            title: intl.formatMessage(lang.statusTooltip),
            icon: <QuestionCircleOutlined />,
            'data-merchant': 'merchant-page-status-tooltip',
        },
    },
]
