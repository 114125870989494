import type { ProjectGeneralFormData } from '../../types'
import type { FormItemsKey } from '@merchant/shared/types'

export const projectGeneralFormItems: Required<FormItemsKey<ProjectGeneralFormData>> = {
    link: 'link',
    name: 'name',
    status: 'status',
    description: 'description',
    supportEmail: 'supportEmail',
} as const
