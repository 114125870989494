import { last, get, isEmpty, first } from 'lodash-es'
import { useMemo } from 'react'
import useSWRInfinite from 'swr/infinite'
import type { apiRestKeys } from './keys'
import type { Fetcher } from 'swr'
import type { SWRInfiniteConfiguration } from 'swr/infinite'

type PageKeyMaker<Page, Payload> = (payload: {
    pageKey: apiRestKeys
    index: number
    previousPageData?: Page | null
    limit: number
}) => {
    pageKey: apiRestKeys | null
    params: Payload
}

interface UseInfiniteFetcherConfig<Page> extends SWRInfiniteConfiguration<Page> {
    limit?: number
    dataPath: keyof Page | string[]
    pageKey: apiRestKeys
}

export const useInfiniteFetcher = <Page, Data, Payload extends { limit?: number; cursor?: string }>(
    key: PageKeyMaker<Page, Payload>,
    fetcher: Fetcher<Page, Payload>,
    { limit = 20, dataPath: path, pageKey, ...options }: UseInfiniteFetcherConfig<Page>
) => {
    const dataPath = Array.isArray(path) ? path.join('.') : path

    const { data, error, isValidating, mutate, size, setSize, isLoading } = useSWRInfinite<Page>(
        (index, previousPageData) => key({ pageKey, index, previousPageData, limit }),
        a => fetcher(a.params || {}),
        options
    )
    const isLoadingMore = isLoading || (!error && size > 0 && data && typeof data[size - 1] === 'undefined')
    const firstPageData = get(first(data), dataPath)
    const lastPageData = get(last(data), dataPath)
    const canFetchMore = lastPageData?.length === limit
    const isLoadingInitialData = !data && !error
    const isRefreshing = isValidating && data?.length === size
    const isPageDataEmpty = isEmpty(firstPageData)

    const fetchMore = () => {
        setSize(size => size + 1)
    }

    const flat = useMemo(
        () =>
            data
                ?.map(page => get(page, dataPath) as Data)
                .flat(1)
                .filter(Boolean),
        [data, dataPath]
    )

    return {
        data: flat,
        pages: data,
        error,
        isValidating,
        mutate,
        fetchMore,
        isRefreshing,
        isPageDataEmpty,
        isLoadingInitialData,
        isLoading,
        size,
        canFetchMore,
        isLoadingMore: isLoadingMore || false,
    }
}
