import { useRef, useEffect } from 'react'

interface Focusable {
    focus: () => void
}

export function useAutoFocus<T extends Focusable>(shouldFocus: boolean = true) {
    const inputRef = useRef<T | null>(null)

    useEffect(() => {
        if (inputRef.current && shouldFocus) {
            inputRef.current.focus()
        }
    }, [shouldFocus])

    return inputRef
}
