import { Select, Button, Col, Form, Input, Row, Space, Typography } from '@merchant/ui-kit/ant-design'
import { useEffect, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocalStorage } from 'usehooks-ts'
import { lang } from './lang'
import { getCurrencyOptions, getNetworkOptions, getStateOptions } from './utils'
import type { WithdrawalsFilterFormData } from '../../types'
import type { SelectProps } from '@merchant/ui-kit/ant-design'
import { useCurrencies } from '~api'
import { ActiveFilters, RangePickerResponsive, SearchableSelect, CollapseGapped } from '~components'
import { withdrawalsFilterKey } from '~constants/localStorage'
import { useNetworks } from '~hooks'

const { Text } = Typography

const inputItemStyles: React.CSSProperties = { marginBottom: 0 }
const rangePickerStyles: React.CSSProperties = { width: '100%' }

const collapseKey = 'withdrawals'

interface Props {
    extra: React.ReactNode
    isLoading: boolean
    onFormFinish: (values: WithdrawalsFilterFormData) => void
    onFormReset?: () => Promise<void>
    initialValues: Partial<WithdrawalsFilterFormData>
    activeFiltersCount: number
}

// eslint-disable-next-line max-lines-per-function
export function WithdrawalsFilters({
    onFormFinish,
    onFormReset,
    extra,
    activeFiltersCount,
    initialValues,
    isLoading,
}: Props) {
    const [areFiltersOpen, setAreFiltersOpen] = useLocalStorage(withdrawalsFilterKey, true)

    const [form] = Form.useForm()
    const intl = useIntl()

    const { data: currencies } = useCurrencies()
    const { networks } = useNetworks()

    const handleResetAll = async () => {
        await onFormReset?.()
        form.resetFields()
    }

    useEffect(() => {
        form.setFieldsValue(initialValues)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValues])

    const onFilter: SelectProps['filterOption'] = (input, option) =>
        option?.key?.toLowerCase().includes(input.toLowerCase())

    const currencyOptions = useMemo(() => getCurrencyOptions(currencies), [currencies])
    const networkOptions = getNetworkOptions(networks)
    const stateOptions = getStateOptions()

    return (
        <CollapseGapped
            data-merchant="withdrawals-filters-collapse-wrapper"
            bordered={false}
            onChange={keys => setAreFiltersOpen(keys.includes(collapseKey))}
            defaultActiveKey={areFiltersOpen ? collapseKey : undefined}
            items={[
                {
                    key: collapseKey,
                    extra,
                    label: (
                        <Space direction="horizontal" data-merchant="withdrawals-filters-collapse">
                            <FormattedMessage {...lang.filters} />
                            {!areFiltersOpen && activeFiltersCount > 0 && (
                                <ActiveFilters
                                    dataMerchant="withdrawals"
                                    amount={activeFiltersCount}
                                    onClear={handleResetAll}
                                />
                            )}
                        </Space>
                    ),
                    children: (
                        <Form form={form} onFinish={onFormFinish} initialValues={initialValues}>
                            <Row justify="start" gutter={[12, 10]}>
                                <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                                    <Form.Item style={inputItemStyles} name="state">
                                        <Select
                                            allowClear
                                            data-merchant="withdrawals-filters-state-select"
                                            placeholder={intl.formatMessage(lang.status)}
                                            options={stateOptions}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                                    <Form.Item style={inputItemStyles} name="id">
                                        <Input
                                            allowClear
                                            autoComplete="off"
                                            data-merchant="withdrawals-filters-id-input"
                                            placeholder={intl.formatMessage(lang.id)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                                    <Form.Item style={inputItemStyles} name="orderId">
                                        <Input
                                            allowClear
                                            autoComplete="off"
                                            data-merchant="withdrawals-filters-orderId-input"
                                            placeholder={intl.formatMessage(lang.orderId)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                                    <Form.Item style={inputItemStyles} name="creditCurrency">
                                        <SearchableSelect
                                            allowClear
                                            data-merchant="withdrawals-filters-creditCurrency-select"
                                            placeholder={intl.formatMessage(lang.creditCurrency)}
                                            filterOption={onFilter}
                                            options={currencyOptions}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                                    <Form.Item style={inputItemStyles} name="receiveCurrency">
                                        <SearchableSelect
                                            allowClear
                                            data-merchant="withdrawals-filters-receiveCurrency-select"
                                            placeholder={intl.formatMessage(lang.receiveCurrency)}
                                            filterOption={onFilter}
                                            options={currencyOptions}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                                    <Form.Item style={inputItemStyles} name="receiveNetwork">
                                        <SearchableSelect
                                            allowClear
                                            data-merchant="withdrawals-filters-receiveNetwork-select"
                                            placeholder={intl.formatMessage(lang.receiveNetwork)}
                                            filterOption={onFilter}
                                            options={networkOptions}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                                    <Form.Item style={inputItemStyles} name="address">
                                        <Input
                                            allowClear
                                            autoComplete="off"
                                            data-merchant="withdrawals-filters-address-input"
                                            placeholder={intl.formatMessage(lang.address)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                                    <Form.Item style={inputItemStyles} name="addressTag">
                                        <Input
                                            allowClear
                                            autoComplete="off"
                                            data-merchant="withdrawals-filters-addressTag-input"
                                            placeholder={intl.formatMessage(lang.addressTag)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                                    <Form.Item style={inputItemStyles} name="note">
                                        <Input
                                            allowClear
                                            autoComplete="off"
                                            data-merchant="withdrawals-filters-note-input"
                                            placeholder={intl.formatMessage(lang.note)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                                    <Form.Item
                                        style={inputItemStyles}
                                        label={
                                            <Text data-merchant="withdrawals-filters-created-label" type="secondary">
                                                <FormattedMessage {...lang.createdAt} />
                                            </Text>
                                        }
                                        name="createdDate"
                                    >
                                        <RangePickerResponsive
                                            data-merchant="withdrawals-filters-created"
                                            allowEmpty={[true, true]}
                                            id={{
                                                start: 'withdrawals-filters-created-start-input',
                                                end: 'withdrawals-filters-created-end-input',
                                            }}
                                            picker="date"
                                            style={rangePickerStyles}
                                            allowClear
                                            placement="bottomLeft"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                                    <Form.Item
                                        style={inputItemStyles}
                                        label={
                                            <Text type="secondary" data-merchant="withdrawals-filters-updated-label">
                                                <FormattedMessage {...lang.updatedAt} />
                                            </Text>
                                        }
                                        name="updatedDate"
                                    >
                                        <RangePickerResponsive
                                            data-merchant="withdrawals-filters-updated"
                                            allowEmpty={[true, true]}
                                            id={{
                                                start: 'withdrawals-filters-updated-start-input',
                                                end: 'withdrawals-filters-updated-end-input',
                                            }}
                                            picker="date"
                                            style={rangePickerStyles}
                                            allowClear
                                            placement="bottomLeft"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg={{ span: 4 }} xs={{ span: 12 }} flex={1}>
                                    <Form.Item noStyle>
                                        <Button
                                            data-merchant="withdrawals-filters-submit-button"
                                            htmlType="submit"
                                            type="primary"
                                            loading={isLoading}
                                            block
                                        >
                                            <FormattedMessage {...lang.apply} />
                                        </Button>
                                    </Form.Item>
                                </Col>
                                <Col lg={{ span: 4 }} xs={{ span: 12 }} flex={1}>
                                    <Form.Item noStyle>
                                        <Button
                                            data-merchant="withdrawals-filters-clear-button"
                                            block
                                            onClick={handleResetAll}
                                        >
                                            <FormattedMessage {...lang.clearAll} />
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    ),
                },
            ]}
        />
    )
}
