import { Flex, Skeleton } from '@merchant/ui-kit/ant-design'

export function LoadingSkeleton() {
    return (
        <Flex vertical align="center" gap={16}>
            <Flex vertical align="center" style={{ width: '100%' }} gap={4}>
                <Skeleton paragraph={false} title={{ width: '40%', style: { margin: '4px 0' } }} />
                <Skeleton.Input active block size="large" />
                <Skeleton paragraph={false} title={{ width: '30%', style: { margin: '4px 0' } }} />
            </Flex>
            <Flex vertical align="center" style={{ width: '100%' }} gap={4}>
                <Skeleton paragraph={false} title={{ width: '25%', style: { margin: '4px 0' } }} />
                <Skeleton.Input active block size="large" />
            </Flex>
            <Skeleton
                paragraph={false}
                title={{ width: '30%', style: { margin: '4px 0', height: 19 } }}
                loading
                active
            />
            <Skeleton.Button active block size="large" />
        </Flex>
    )
}
