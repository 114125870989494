import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    generateApiKey: {
        id: 'integrations.button.generate_api_key',
        defaultMessage: 'Generate API Key',
    },
    apiKeys: {
        id: 'integrations.typography.api_keys',
        defaultMessage: 'API Keys',
    },
    emptyApiKeysTitle: {
        id: 'integrations.table.empty_title',
        defaultMessage: '🔑',
    },
    emptyApiKeysSubtitle: {
        id: 'integrations.table.empty_subtitle',
        defaultMessage: 'No API Keys yet',
    },
    privateKeyTooltip: {
        id: 'integrations.tooltip.private_key_text',
        defaultMessage: 'Private key is hidden and cannot be retrieved. If this key lost, create a new API key.',
    },
    status: {
        id: 'integrations.table.status',
        defaultMessage: 'Status',
    },
    title: {
        id: 'integrations.table.title',
        defaultMessage: 'Title',
    },
    publicKey: {
        id: 'integrations.table.public_key',
        defaultMessage: 'Public key',
    },
    privateKey: {
        id: 'integrations.table.private_key',
        defaultMessage: 'Private key',
    },
    privateKeyValue: {
        id: 'integrations.table.private_key_value',
        defaultMessage: '••••••••',
    },
    created: {
        id: 'integrations.table.created',
        defaultMessage: 'Created',
    },
    createdCompact: {
        id: 'integrations.table.created_compact',
        defaultMessage: 'Created: {date}',
    },
    actions: {
        id: 'integrations.table.actions',
        defaultMessage: 'Actions',
    },
    key: {
        id: 'integrations.table.key',
        defaultMessage: 'Key',
    },
    lastUse: {
        id: 'integrations.table.last_use',
        defaultMessage: 'Last use',
    },
    lastUseCompact: {
        id: 'integrations.table.last_use_compact',
        defaultMessage: 'Last use: {date}',
    },
    permissions: {
        id: 'integrations.table.permissions',
        defaultMessage: 'Permissions',
    },
    permissionsCompact: {
        id: 'integrations.table.permissions_compact',
        defaultMessage: 'Permissions:',
    },
    createdBy: {
        id: 'integrations.table.created_by',
        defaultMessage: 'Created by',
    },
    createdByCompact: {
        id: 'integrations.table.created_by_compact',
        defaultMessage: 'Created by: {name}',
    },
    name: {
        id: 'integrations.table.name',
        defaultMessage: 'Name',
    },
    keyTooltipAlert: {
        id: 'integrations.table.key_tooltip_alert',
        defaultMessage:
            '<strong>Notice:</strong> Private key is hidden and cannot be retrieved. If this key lost, create a new API key.',
    },
    editSuccessMessage: {
        id: 'integrations.notification.edit_success',
        defaultMessage: 'Changes were successfully saved',
    },
    deleteSuccessMessage: {
        id: 'integrations.notification.delete_success',
        defaultMessage: 'API Key was successfully deleted',
    },
})
