export function dropZeroes(value: string) {
    return value.replace(/(\.\d*?[1-9])0+$|\.0*$/, '$1')
}

export function shortenRate(input: number | string | undefined | null) {
    if (!Number(input)) {
        return input || ''
    }

    const strategies = {
        large: (value: string) => {
            const integerLength = value.split('.')[0]?.length || 0

            return integerLength >= 12 ? dropZeroes(value) : dropZeroes(value.slice(0, 13))
        },
        medium: (value: string) => {
            const integerPart = value.split('.')[0] || ''
            const decimalPart = value.split('.')[1] || ''
            if (decimalPart && decimalPart.length <= 8) {
                return dropZeroes(value)
            } else {
                const shortened = `${integerPart}.${decimalPart.slice(0, 8)}`

                return dropZeroes(shortened)
            }
        },
        tiny: (value: string) => {
            const decimalPart = value.split('.')[1]
            const shortened = `0.${decimalPart?.slice(0, 11)}`

            return Number(shortened) === 0 ? dropZeroes(value) : dropZeroes(shortened)
        },
    }

    const stringValue = String(input)

    if (Number(input) < 0.00000001) {
        return strategies.tiny(stringValue)
    }

    if (Number(input) >= 1000) {
        return strategies.large(stringValue)
    }

    if (Number(input) < 1000) {
        return strategies.medium(stringValue)
    }

    return dropZeroes(stringValue)
}
