import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    convertTimeline: {
        id: 'withdrawal.timeline.convert.title',
        defaultMessage: 'Convert',
    },
    date: {
        id: 'withdrawal.timeline.convert.date',
        defaultMessage: 'Date',
    },
    currencyFrom: {
        id: 'withdrawal.timeline.convert.currency_from',
        defaultMessage: 'Chosen currency from',
    },
    exchangeRate: {
        id: 'withdrawal.timeline.convert.exchange_rate',
        defaultMessage: 'Exchange rate',
    },
    amountRequested: {
        id: 'withdrawal.timeline.convert.amount_requested',
        defaultMessage: 'Amount requested',
    },
})
