import { useMemo } from 'react'
import { useBalances, useCurrencies } from '~api'
import { getWithdrawalCurrencies } from '~utils'

export function useFiatWithdrawableCurrencies() {
    const { data: currencies, isLoading: isCurrenciesLoading } = useCurrencies()
    const { data: balances, isLoading: isBalancesLoading } = useBalances()

    const fiatWithdrawableCurrencies = useMemo(() => {
        if (!currencies) {
            return {}
        }
        const fiatCurrencies = Object.fromEntries(
            Object.entries(currencies).filter(([_, currency]) => !currency.crypto)
        )

        return getWithdrawalCurrencies({ balances, currencies: fiatCurrencies })
    }, [balances, currencies])

    return {
        fiatWithdrawableCurrencies,
        isLoading: isCurrenciesLoading || isBalancesLoading,
    }
}
