import { ModalResponsive } from '@merchant/shared/components'
import { Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import { SuspensionContext } from './types'
import type { CommonProps } from './types'
import type { ModalProps } from '@merchant/ui-kit/ant-design'
import type { MessageDescriptor } from 'react-intl'
import { ActionSuspendedContent } from '~components'

const { Title } = Typography
interface Props extends ModalProps, CommonProps {}

const contextTitleMap: Record<SuspensionContext, MessageDescriptor> = {
    [SuspensionContext.Integration]: lang.integrationsSuspensionModalTitle,
    [SuspensionContext.TeamManagement]: lang.teamManagementSuspensionModalTitle,
    [SuspensionContext.Withdrawal]: lang.withdrawalSuspensionModalTitle,
}

export function ActionSuspendedModal({ blockReason, context, expiresAt, onClose, ...modalProps }: Props) {
    const actionSuspendedContentProps = {
        blockReason,
        context,
        expiresAt,
        onClose,
    }
    const modalTitle = context in contextTitleMap ? contextTitleMap[context] : null

    return (
        <ModalResponsive
            onCancel={onClose}
            footer={null}
            title={
                <Title data-merchant={`${modalProps['data-merchant']}-title`} level={4} style={{ marginRight: 28 }}>
                    <FormattedMessage {...modalTitle} />
                </Title>
            }
            {...modalProps}
        >
            <ActionSuspendedContent {...actionSuspendedContentProps} />
        </ModalResponsive>
    )
}
