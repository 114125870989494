import type { FormItemsKey } from '@merchant/shared/types'
import type { RowProps, ColProps, GetProps, Typography } from '@merchant/ui-kit/ant-design'
import type { ProjectTeamMember } from '~api/instances/cabinet-api'

export const row: { props: RowProps; cols: { props: ColProps }[] } = {
    props: { gutter: 12, style: { paddingInline: 12 } },
    cols: [{ props: { flex: '32%' } }, { props: { flex: '32%' } }, { props: { flex: 1 } }],
}

export const formKeys: FormItemsKey<Pick<ProjectTeamMember, 'email' | 'name' | 'roles'>> = {
    email: 'email',
    name: 'name',
    roles: 'roles',
}

export const getFormListHeaderTextProps: (
    dataMerchantPostfix: string
) => GetProps<typeof Typography.Text> = dataMerchantPostfix => ({
    style: { fontSize: 12 },
    'data-merchant': `add-members-header-${dataMerchantPostfix}`,
    type: 'secondary',
})
