import { Typography } from '@merchant/ui-kit/ant-design'
import { useMemo } from 'react'
import { useBoolean } from 'usehooks-ts'
import type { ParagraphProps } from '@merchant/ui-kit/ant-design/es/typography/Paragraph'
import type { TextProps } from '@merchant/ui-kit/ant-design/es/typography/Text'
import type { TitleProps } from '@merchant/ui-kit/ant-design/es/typography/Title'

const componentMap = {
    Title: Typography.Title,
    Text: Typography.Text,
    Link: Typography.Link,
    Paragraph: Typography.Paragraph,
}

type Props =
    | ({
          component: 'Title'
      } & TitleProps)
    | ({ component: 'Text' } & TextProps)
    | ({ component: 'Paragraph' } & ParagraphProps)

export function TooltipTypography({ component, ellipsis, children, ...rest }: Props) {
    const { value: isTruncated, setValue: setIsTruncated } = useBoolean(false)

    const Component = useMemo(() => componentMap[component], [component])

    const componentEllipsis = typeof ellipsis === 'object' ? ellipsis : {}

    return (
        <Component
            {...rest}
            ellipsis={{
                ...componentEllipsis,
                onEllipsis: setIsTruncated,
                tooltip: isTruncated ? children : undefined,
            }}
        >
            <>{children}</>
        </Component>
    )
}
