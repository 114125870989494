import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    completedStatus: {
        id: 'transactions-page.tag.completed_status',
        defaultMessage: 'Completed',
    },
    waitingStatus: {
        id: 'transactions-page.tag.waiting_status',
        defaultMessage: 'Waiting',
    },
    failedStatus: {
        id: 'transactions-page.tag.failed_status',
        defaultMessage: 'Failed',
    },
    created: {
        id: 'transactions-page.table.created',
        defaultMessage: 'Created',
    },
    updated: {
        id: 'transactions-page.table.updated',
        defaultMessage: 'Updated',
    },
    status: {
        id: 'transactions-page.table.status',
        defaultMessage: 'Status',
    },
    id: {
        id: 'transactions-page.table.id',
        defaultMessage: 'ID',
    },
    totalAmount: {
        id: 'transactions-page.table.total_amount',
        defaultMessage: 'Total amount',
    },
    totalFee: {
        id: 'transactions-page.table.total_fee',
        defaultMessage: 'Total fee',
    },
    remainderBalance: {
        id: 'transactions-page.table.remainder_balance',
        defaultMessage: 'Remainder balance',
    },
    notes: {
        id: 'transactions-page.table.notes',
        defaultMessage: 'Notes',
    },
    client: {
        id: 'transactions-page.typography.client',
        defaultMessage: 'client',
    },
    merchant: {
        id: 'transactions-page.typography.merchant',
        defaultMessage: 'merchant',
    },
    feePaidBy: {
        id: 'transactions-page.typography.fee_paid_by',
        defaultMessage: 'by {subject}',
    },
    showMore: {
        id: 'transactions-page.button.show_more',
        defaultMessage: 'Show more',
    },
    noTransactionsSubtitle: {
        id: 'transactions-page.empty_table.no_transactions_subtitle',
        defaultMessage: 'No transactions yet',
    },
    transactionId: {
        id: 'transactions-page.typography.transaction_id',
        defaultMessage: 'Transaction ID',
    },
    order: {
        id: 'transactions-page.table.order',
        defaultMessage: 'Order',
    },
    withdrawal: {
        id: 'transactions-page.table.withdrawal',
        defaultMessage: 'Withdrawal',
    },
    withdrawalFiat: {
        id: 'transactions-page.table.fiat_withdrawal',
        defaultMessage: 'Fiat withdrawal',
    },
    deposit: {
        id: 'transactions-page.table.deposit',
        defaultMessage: 'Deposit',
    },
    internalExchange: {
        id: 'transactions-page.table.internal_exchange',
        defaultMessage: 'Internal Exchange',
    },
    apiInitiated: {
        id: 'transactions-page.tooltip.origin_api_initiated',
        defaultMessage: 'Initiated via API interface',
    },
    dashboardInitiated: {
        id: 'transactions-page.tooltip.origin_web_initiated',
        defaultMessage: 'Initiated from user dashboard',
    },
})
