import { List, type GetProps } from '@merchant/ui-kit/ant-design'
import { useListItemDetails } from './useListItemDetails'
import type { WhitelistWithKey } from '~api/swr/rest/types'

interface Props extends Omit<GetProps<typeof List.Item>, 'data-merchant'> {
    item: WhitelistWithKey
    onDeleteClick: (item: WhitelistWithKey) => void
}

export function ListItem({ item, onDeleteClick, ...rest }: Props) {
    const listItemDetails = useListItemDetails(item, onDeleteClick)

    return (
        <List.Item style={{ paddingInline: 0 }} data-merchant={`${item.key}-whitelist-item`} {...rest}>
            {listItemDetails}
        </List.Item>
    )
}
