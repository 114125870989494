import { useEffect } from 'react'
import { useBoolean } from 'usehooks-ts'
import { useFiatWithdrawalFee } from '~api'
import { FiatWithdrawalFeeType, type FiatWithdrawalMethod } from '~api/instances/cabinet-api'

interface Props {
    selectedCurrency: string
    selectedMethod: FiatWithdrawalMethod | undefined
    amount: string | undefined
    setFormFeeValue: (fee: string) => void
    isDebouncedAmountWaiting: boolean
}

interface UseFiatMethodFeeResult {
    isLoading: boolean
    fiatWithdrawalFee: string | undefined
}

export function useFiatMethodFee({
    selectedCurrency,
    selectedMethod,
    amount = '',
    setFormFeeValue,
    isDebouncedAmountWaiting,
}: Props): UseFiatMethodFeeResult {
    const { data: fiatWithdrawalFee, isLoading: isFiatWithdrawalFeeLoading } = useFiatWithdrawalFee({
        params: {
            currency: selectedCurrency,
            method: selectedMethod?.name,
            amount,
        },
        shouldFetch: selectedMethod?.feeType !== FiatWithdrawalFeeType.Absolute,
    })

    const { value: isLoading, setValue: setIsLoading } = useBoolean(isDebouncedAmountWaiting)

    useEffect(() => {
        setIsLoading(isFiatWithdrawalFeeLoading || isDebouncedAmountWaiting)
    }, [isDebouncedAmountWaiting, isFiatWithdrawalFeeLoading, setIsLoading])

    let result: UseFiatMethodFeeResult = {
        isLoading: false,
        fiatWithdrawalFee: undefined,
    }

    if (!selectedCurrency || !selectedMethod || !amount) {
        result = { isLoading: false, fiatWithdrawalFee: '0' }
    } else if (selectedMethod.feeType === FiatWithdrawalFeeType.Absolute) {
        result = { isLoading: false, fiatWithdrawalFee: selectedMethod.fee }
    } else {
        result = {
            isLoading,
            fiatWithdrawalFee: fiatWithdrawalFee?.fee,
        }
    }

    useEffect(() => {
        if (result.fiatWithdrawalFee) {
            setFormFeeValue(result.fiatWithdrawalFee)
        }
    }, [result.fiatWithdrawalFee, setFormFeeValue])

    return result
}
