import type { FormItemsKey } from '@merchant/shared/types'
import type { WhitelistAddress } from '~api/instances/cabinet-api'

export const formKeys: FormItemsKey<WhitelistAddress> = {
    address: 'address',
    addressTag: 'addressTag',
    currency: 'currency',
    name: 'name',
    network: 'network',
}
