import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import type { ReactNode } from 'react'
import type { MessageDescriptor } from 'react-intl'

const { Title, Text } = Typography

interface Props {
    titleDescriptor?: MessageDescriptor
    subtitleDescriptor?: MessageDescriptor
    renderAction?: () => ReactNode
}

export function EmptyTable({
    titleDescriptor: title = lang.noRowsTitle,
    subtitleDescriptor: subtitle = lang.noRowsSubtitle,
    renderAction,
}: Props) {
    return (
        <Flex vertical style={{ paddingBlock: 100 }} gap={16} align="center">
            <Flex vertical align="center">
                <Title data-merchant={null} level={2}>
                    <FormattedMessage {...title} />
                </Title>
                <Text data-merchant={null} style={{ fontSize: 16 }}>
                    <FormattedMessage {...subtitle} />
                </Text>
            </Flex>
            {renderAction?.()}
        </Flex>
    )
}
