import { Button, Dropdown } from '@merchant/ui-kit/ant-design'
import { useIntl } from 'react-intl'
import { themesMap } from '../const'
import type { MenuProps } from '@merchant/ui-kit/ant-design'
import type { ItemType } from '@merchant/ui-kit/ant-design/es/menu/interface'
import { globalLang } from '~globalLang'
import { useColorMode } from '~hooks'
import { headerButtonCommonProps } from '~props'
import { isKeyOfObject } from '~utils'

const dropdownAlignConfig = { offset: [-1, 0] }

export function ColorModeDropdown() {
    const { colorMode, setColorMode } = useColorMode()
    const intl = useIntl()

    const onItemClick: MenuProps['onClick'] = e => {
        if (isKeyOfObject(e.key, themesMap)) {
            setColorMode(e.key)
        }
    }

    const { icon } = themesMap[colorMode]

    const menuItems: ItemType[] = [
        {
            key: 'group_title',
            type: 'group',
            label: intl.formatMessage(globalLang.colorMode),
        },
        ...Object.values(themesMap),
    ]

    return (
        <Dropdown
            menu={{
                items: menuItems,
                onClick: onItemClick,
                selectable: true,
                defaultSelectedKeys: [colorMode],
                'data-merchant': 'header-color-mode-dropdown-menu',
            }}
            data-merchant="header-color-mode-dropdown"
            align={dropdownAlignConfig}
            placement="bottom"
            trigger={['click']}
        >
            <Button data-merchant="header-theme-toggler" icon={icon} {...headerButtonCommonProps} />
        </Dropdown>
    )
}
