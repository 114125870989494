import { useBreakpoint } from '@merchant/shared/hooks'
import { Flex, List, Skeleton, Space, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from '../../lang'
import { getCommonFlexProps, listTitleStyles, getEmailSpaceStyles, emailDescriptionStyles } from '../../styles'
import type { ListItemProps } from '@merchant/ui-kit/ant-design/es/list'

const { Text } = Typography

interface Props extends Omit<ListItemProps, 'onChange'> {
    isLoading: boolean
    value: string | undefined
}

export function EmailItem({ isLoading, value, ...rest }: Props) {
    const breakpoints = useBreakpoint()
    const commonFlexProps = getCommonFlexProps(!breakpoints.md)

    return (
        <List.Item style={{ paddingTop: 0 }} {...rest}>
            <Flex {...commonFlexProps}>
                <List.Item.Meta
                    title={
                        <Text style={listTitleStyles} data-merchant={null}>
                            <FormattedMessage {...lang.email} />
                        </Text>
                    }
                    data-merchant="settings-email-list-item-meta"
                />
                {!isLoading && value && (
                    <Space.Compact direction="vertical" style={getEmailSpaceStyles(breakpoints)}>
                        <Text data-merchant="settings-user-email">{value}</Text>
                        <Text type="secondary" style={emailDescriptionStyles} data-merchant={null}>
                            <FormattedMessage {...lang.ifYouWantChangeEmailText} />
                        </Text>
                    </Space.Compact>
                )}

                <Skeleton
                    loading={isLoading}
                    active
                    style={{ width: '60%' }}
                    paragraph={{ rows: 1, width: '100%' }}
                    title={{ style: { float: 'right' }, width: '60%' }}
                />
            </Flex>
        </List.Item>
    )
}
