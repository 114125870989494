import { NotificationMessage } from '@merchant/shared/components'
import { authTokenKey } from '@merchant/shared/constants/localStorage'
import { useNotifications } from '@merchant/shared/contexts'
import { isKeyOfObject } from '@merchant/shared/utils'
import { useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSearchParams } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts'
import { lang } from './lang'
import { useStateMachine } from './useStateMachine'
import { getInitialData } from './utils'
import { PasswordRecovery, PasswordRecoverySetNew, SignIn, SignUpEmailVerification, VerifyTwoFa, SignUp } from './views'
import type { SignUpSuccess } from '.'
import type { SigninResponse } from '~api/instances/cabinet-api'
import { QueryParams } from '~constants/routes'
import { authFlows, AuthSteps } from '~constants/views'

// eslint-disable-next-line max-lines-per-function
export const useData = () => {
    const isFirstRender = useRef(true)
    const [searchParams, setSearchParams] = useSearchParams()
    const currentQuery = searchParams.get(QueryParams.show)
    const initialEmail = searchParams.get(QueryParams.email)
    const currentView =
        currentQuery !== null && isKeyOfObject(currentQuery, authFlows) ? authFlows[currentQuery] : authFlows.sign_in

    const [, setAuthToken] = useLocalStorage(authTokenKey, '')
    const { current, ...state } = useStateMachine(currentView)
    const { notification } = useNotifications()
    const email = state.stack[0]?.state?.email ?? ''
    const token = state.stack[0]?.state?.token ?? ''

    const { step, state: stepData } = current || { step: AuthSteps.SignIn }

    const goToView = (view: AuthSteps) => (email?: string) => {
        searchParams.set(QueryParams.show, view)
        setSearchParams(searchParams)
        state.replace(view, { email: email || state.stack[0]?.state?.email })
    }

    useEffect(() => {
        goToView(currentView)()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentView])

    const onSuccessAuth = (token: string) => {
        setAuthToken(token)
        setSearchParams(new URLSearchParams())
    }

    const views: Record<AuthSteps, () => JSX.Element> = {
        [AuthSteps.SignUp]: () => {
            const goNext = (data: SignUpSuccess) => {
                state.next(AuthSteps.VerifyEmail, { current: data })
            }

            const initialData = getInitialData(stepData, isFirstRender, initialEmail)

            return <SignUp onSuccess={goNext} initialData={initialData} onSignInClick={goToView(AuthSteps.SignIn)} />
        },
        [AuthSteps.VerifyEmail]: () => {
            state.canGoBack = false

            const onSuccess = () => onSuccessAuth(token)

            return <SignUpEmailVerification email={email} token={token} onSuccess={onSuccess} />
        },
        [AuthSteps.SignIn]: () => {
            state.canGoBack = false

            const goToRecovery = (email: string) => {
                searchParams.set(QueryParams.show, AuthSteps.PasswordRecovery)
                setSearchParams(searchParams)
                state.next(AuthSteps.PasswordRecovery, { current: { email } })
            }

            const onSuccess = (data: SigninResponse) => {
                if (data['_2faRequired']) {
                    state.next(AuthSteps.VerifyTwoFa, {
                        next: { token: data.token },
                    })
                } else {
                    onSuccessAuth(data.token)
                }
            }

            const initialData = getInitialData(stepData, isFirstRender, initialEmail)

            return (
                <SignIn
                    initialData={initialData}
                    onSuccess={onSuccess}
                    onForgotClick={goToRecovery}
                    onSignUpClick={goToView(AuthSteps.SignUp)}
                />
            )
        },
        [AuthSteps.VerifyTwoFa]: () => {
            const onSuccess = () => onSuccessAuth(current?.state?.token || '')

            return <VerifyTwoFa token={current?.state?.token || ''} onSuccess={onSuccess} />
        },
        [AuthSteps.PasswordRecovery]: () => {
            state.canGoBack = true

            const goNext = (email: string) => {
                state.next(AuthSteps.PasswordRecoverySetNew, {
                    current: { email },
                })
            }

            return <PasswordRecovery initData={{ email }} onSuccess={goNext} />
        },
        [AuthSteps.PasswordRecoverySetNew]: () => {
            state.canGoBack = false
            const email = state.stack[1]?.state?.email ?? state.stack[0]?.state?.email ?? ''
            const onSuccess = () => {
                notification.api.open({
                    message: (
                        <NotificationMessage
                            type="success"
                            title={<FormattedMessage {...lang.passwordUpdatedNotificationMessage} />}
                            description={<FormattedMessage {...lang.passwordUpdatedNotificationDescription} />}
                            dataMerchant="password-updated-success-notification"
                        />
                    ),
                })

                state.next(AuthSteps.SignIn, { next: { email } })
            }

            return <PasswordRecoverySetNew onSuccess={onSuccess} email={email} />
        },
    }

    return {
        view: views[step](),
        canGoBack: state.canGoBack,
        goBack: () => state.back(step),
    }
}
