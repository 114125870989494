import { NotificationMessage } from '@merchant/shared/components'
import { Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import type { GetProjectFiatWithdrawalFeeRequest } from '~api/instances/cabinet-api'

const { Text } = Typography

type ProjectWithdrawalFiatFeeRequest = Omit<GetProjectFiatWithdrawalFeeRequest, 'projectId'>

export function isFiatWithdrawalFeeRequest(
    params: Partial<ProjectWithdrawalFiatFeeRequest>
): params is ProjectWithdrawalFiatFeeRequest {
    return !!params.amount && !!params.currency && !!params.method
}

export const getNotFoundProjectNotification = (projectId: string) => (
    <NotificationMessage
        title={
            <FormattedMessage
                {...lang.projectNotFoundError}
                values={{
                    projectId,
                    highlight: chunks => (
                        <Text code data-merchant="not-found-project-error-notification">
                            {chunks}
                        </Text>
                    ),
                }}
            />
        }
        key={projectId}
        type="error"
    />
)
