import { assets } from '@merchant/shared/assets/nameMap'
import { lang } from './lang'
import type { MessageDescriptor } from 'react-intl'
import { PaymentState } from '~api/instances/cabinet-api'

export const orderStateResultIconMap: Record<PaymentState, string> = {
    [PaymentState.Completed]: assets.checkCircle,
    [PaymentState.Failed]: assets.xCircle,
    [PaymentState.Waiting]: assets.clock,
    [PaymentState.Canceled]: assets.xCircle,
    [PaymentState.Created]: assets.clock,
}

export const orderStateResultTitleMap: Record<PaymentState, MessageDescriptor> = {
    [PaymentState.Completed]: lang.orderCompleted,
    [PaymentState.Failed]: lang.orderFailed,
    [PaymentState.Waiting]: lang.orderWaiting,
    [PaymentState.Canceled]: lang.orderCancelled,
    [PaymentState.Created]: lang.orderCreated,
}

export const ORDER_TERMINAL_STATES: readonly PaymentState[] = [
    PaymentState.Completed,
    PaymentState.Failed,
    PaymentState.Canceled,
] as const

export const orderRowsKeys = {
    created: 'created',
    updatedAt: 'updatedAt',
    orderId: 'orderId',
    merchantOrderId: 'merchantOrderId',
    origin: 'origin',
    underpayment: 'underpayment',
    metaInfo: 'metaInfo',
    url: 'url',
    customerEmail: 'customerEmail',
    customerId: 'customerId',
    callbackButton: 'callbackButton',
} as const
