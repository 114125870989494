import { colorModeKey } from '@merchant/shared/constants/localStorage'
import { getUserPreferredTheme, parseIfValidJson } from '@merchant/shared/utils'
import { PosthogAuthEvents } from './types'
import type { AuthEventDataMap, CommonAuthEventData } from '.'
import type { PosthogEventTypeMapping } from '~types/posthog'

const getAuthCommonPayload = (): CommonAuthEventData => {
    const { parsed: parsedColorMode, isValid } = parseIfValidJson(localStorage.getItem(colorModeKey) || '')

    return {
        system_theme: getUserPreferredTheme(),
        web_theme: isValid ? parsedColorMode : 'system',
    }
}

export const authCaptureEventParams: PosthogEventTypeMapping<PosthogAuthEvents, AuthEventDataMap, CommonAuthEventData> =
    {
        [PosthogAuthEvents.onSignUpPage]: () => [PosthogAuthEvents.onSignUpPage, getAuthCommonPayload()],
        [PosthogAuthEvents.createAccount]: ({ success }) => [
            PosthogAuthEvents.createAccount,
            { ...getAuthCommonPayload(), success },
        ],
        [PosthogAuthEvents.createAccountConfirmation]: ({ code, success }) => [
            PosthogAuthEvents.createAccountConfirmation,
            { success, code },
        ],
        [PosthogAuthEvents.goToSignInPage]: () => [PosthogAuthEvents.goToSignInPage, getAuthCommonPayload()],
        [PosthogAuthEvents.onSignInPage]: () => [PosthogAuthEvents.onSignInPage, getAuthCommonPayload()],
        [PosthogAuthEvents.loginAttempt]: ({ success }) => [
            PosthogAuthEvents.loginAttempt,
            { ...getAuthCommonPayload(), success },
        ],
        [PosthogAuthEvents.goToSignUpPage]: () => [PosthogAuthEvents.goToSignUpPage, getAuthCommonPayload()],
        [PosthogAuthEvents.forgotPasswordRequest]: () => [
            PosthogAuthEvents.forgotPasswordRequest,
            getAuthCommonPayload(),
        ],
        [PosthogAuthEvents.resetPasswordRequest]: ({ code, success }) => [
            PosthogAuthEvents.resetPasswordRequest,
            { ...getAuthCommonPayload(), code, success },
        ],
    } as const
