import type { Breakpoints } from '@merchant/shared/hooks'
import type { ModalProps } from '@merchant/ui-kit/ant-design'
import { Permission } from '~api/instances/cabinet-api'
import { QueryParams } from '~constants/routes'
import { Actions } from '~types/actionModal'

export const actionPermissionMap: Record<Actions, Permission> = {
    [Actions.convert]: Permission.SeeSwapFlow,
    [Actions.withdrawalCrypto]: Permission.SeeCryptoWithdrawalFlow,
    [Actions.withdrawalFiat]: Permission.SeeFiatWithdrawalFlow,
    [Actions.withdrawal]: Permission.SeeCryptoWithdrawalFlow,
    [Actions.deposit]: Permission.CreateDepositAddress,
}

export const getModalProps = (action: Actions, bp: Breakpoints) => {
    const props: Record<Partial<Actions>, Omit<ModalProps, 'data-merchant'>> = {
        [Actions.deposit]: {
            ...(bp.sm ? { style: { top: 148 } } : { centered: true }),
        },
        [Actions.withdrawal]: {
            centered: true,
        },
        [Actions.withdrawalCrypto]: {
            centered: true,
        },
        [Actions.withdrawalFiat]: {
            centered: true,
        },
        [Actions.convert]: {
            centered: true,
        },
    }

    return props[action]
}

export const paramsToDeleteOnModalClose = [
    QueryParams.action,
    QueryParams.fromCurrency,
    QueryParams.toCurrency,
    QueryParams.id,
    QueryParams.depositCurrency,
]
