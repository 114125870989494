import { UserPermissionProvider } from '~contexts'

export function withUserPermissionContextProvider<P extends object>(
    WrappedComponent: React.ComponentType<P>
): React.FC<P> {
    // eslint-disable-next-line react/function-component-definition
    const WithContext: React.FC<P> = ({ ...props }: P) => (
        <UserPermissionProvider>
            <WrappedComponent {...props} />
        </UserPermissionProvider>
    )

    return WithContext
}
