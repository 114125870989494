import { modes } from '../../const'
import { lang } from './lang'
import type { CreateEditFormData, DeleteFormData, RegenerateFormData } from '../../types'
import type { FormItemsKey } from '@merchant/shared/types'

export const createEditFormItemsKey: Required<FormItemsKey<CreateEditFormData>> = {
    url: 'url',
    totpBackup: 'totpBackup',
    totpCode: 'totpCode',
}

export const deleteFormItemsKey: FormItemsKey<DeleteFormData> = {
    totpCode: 'totpCode',
    totpBackup: 'totpBackup',
}

export const regenerateFormItemsKey: FormItemsKey<RegenerateFormData> = {
    totpCode: 'totpCode',
    totpBackup: 'totpBackup',
}

export const modalTitleModeMap = {
    [modes.callbackUrlCreate]: lang.createCallbackModalTitle,
    [modes.callbackUrlEdit]: lang.editCallbackModalTitle,
    [modes.callbackUrlDelete]: lang.deleteCallbackModalTitle,
    [modes.callbackUrlRegenerate]: lang.regenerateCallbackModalTitle,
}
