export const getJoined = <T extends number | string>(data: T[], separator: string = ', ', lastDelimiter?: string) => {
    return data.reduce((a, c, index) => {
        if (index === 0) {
            a = a + c.toString()
        } else if (lastDelimiter && isLast(data, index)) {
            a = a + `${lastDelimiter}${c}`
        } else {
            a = a + `${separator}${c}`
        }

        return a
    }, '')
}

export const sortWithUndefinedAtBottom = <T>({
    data,
    field,
    direction = 'desc',
    compareFn,
}: {
    data: T[]
    field: keyof T
    direction: 'asc' | 'desc'
    compareFn?: (a: T, b: T) => number
}): T[] => {
    const directionMultiplier = direction === 'desc' ? 1 : -1

    const { withGivenField, withoutGivenField } = data.reduce(
        (a, c) => {
            if (c[field] !== undefined) {
                a.withGivenField.push(c)
            } else {
                a.withoutGivenField.push(c)
            }

            return a
        },
        { withGivenField: [], withoutGivenField: [] } as { withGivenField: T[]; withoutGivenField: T[] }
    )

    const sorted = withGivenField.sort((a, b) => {
        const difference = compareFn ? compareFn(a, b) : Number(b[field] || 0) - Number(a[field] || 0)

        return difference * directionMultiplier
    })

    return [...sorted, ...withoutGivenField]
}
export function isLast<T>(array: T[], index: number) {
    return index === array.length - 1
}

export function moveItemToBeginning<T>(array: T[], predicateFn: (a: T) => boolean) {
    const index = array.findIndex(predicateFn)
    const copyArray = [...array]
    if (index === -1) {
        return copyArray
    }

    const item = copyArray.splice(index, 1)[0]
    if (!item) {
        return copyArray
    }

    copyArray.unshift(item)

    return copyArray
}

export const toArray = <T>(keys: T | readonly T[]): T[] => (Array.isArray(keys) ? [...keys] : [keys])
