import { mobileModalWrapperStyle, modalMobileContentStyles, modalMobileStyles } from './style'
import type { ModalProps } from '@merchant/ui-kit/ant-design'
import type { useBreakpoint } from '~hooks'

export function getModalInnerStyles(
    styles: ModalProps['styles'],
    breakpoints: ReturnType<typeof useBreakpoint>
): ModalProps['styles'] {
    const { content: contentStyles, ...stylesRest } = styles || {}

    return {
        ...stylesRest,
        content: {
            ...(breakpoints.md ? undefined : modalMobileContentStyles),
            ...contentStyles,
        },
    }
}
export function getModalStyle(style: ModalProps['style'], breakpoints: ReturnType<typeof useBreakpoint>) {
    return {
        ...(breakpoints.md ? undefined : modalMobileStyles),
        ...style,
    }
}

export function getWrapperProps({
    wrapProps,
    breakpoints,
    open,
    isWrapperDisplayed,
}: {
    wrapProps: ModalProps['wrapProps']
    breakpoints: ReturnType<typeof useBreakpoint>
    open: boolean
    isWrapperDisplayed: boolean
}) {
    const { style: wrapperStyleProp = {}, ...restWrapProps } = wrapProps || {}
    const wrapperStyle = { ...(breakpoints.md ? {} : mobileModalWrapperStyle), ...wrapperStyleProp }

    return {
        style: open || isWrapperDisplayed ? wrapperStyle : { ...wrapperStyle, display: 'none' },
        ...restWrapProps,
    }
}
