import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { emailValidationRegex } from '@merchant/shared/constants'
import { useBreakpoint } from '@merchant/shared/hooks'
import { Select, Flex, Form, Input, theme, Tooltip, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage, useIntl } from 'react-intl'
import { newOrderFormKeys } from '../../../const'
import { DEFAULT_OPTION_VALUE, urlRules } from './const'
import { lang } from './lang'
import { useData } from './useData'
import { getUnderpaymentOptions, normalizeEmptyString } from './utils'
import type { ConstructorTypes } from '~features/PaymentConstructor/types'
import { PaymentType } from '~api/instances/cabinet-api'
import { AbsoluteLossItem, CustomRelativeLossItem } from '~components'
import { lang as commonLang } from '~features/PaymentConstructor/lang'
import { getFormItemTooltip } from '~features/PaymentConstructor/utils.tsx'
import { globalLang } from '~globalLang'
import compactStyles from '~styles/compact.style.module.css'

const { Text } = Typography
const { useToken } = theme

interface Props {
    type: ConstructorTypes
}

// eslint-disable-next-line max-lines-per-function
export function AdvancedSettings({ type }: Props) {
    const intl = useIntl()
    const breakpoints = useBreakpoint()
    const { token } = useToken()
    const {
        baseCurrenciesOptions,
        isAbsoluteCheckboxEnabled,
        isCustomSelected,
        handleCheckboxChange,
        isProjectLoading,
        lossConfig,
        handleUnderpaymentSelectChange,
    } = useData()

    return (
        <>
            <Form.Item
                normalize={normalizeEmptyString}
                style={{ marginTop: 16 }}
                required
                name={newOrderFormKeys.orderId}
                label={<FormattedMessage {...commonLang.orderId} />}
                tooltip={getFormItemTooltip(lang.orderIdTooltip)}
            >
                <Input placeholder={intl.formatMessage(commonLang.orderId)} data-merchant="order-id-input" />
            </Form.Item>
            <Flex justify="space-between" gap={breakpoints.md ? 16 : 0} vertical={!breakpoints.md}>
                <Form.Item
                    normalize={normalizeEmptyString}
                    style={{ flex: 1 }}
                    required
                    name={newOrderFormKeys.customerEmail}
                    label={<FormattedMessage {...commonLang.clientEmail} />}
                    tooltip={getFormItemTooltip(lang.clientEmailTooltip)}
                    rules={[
                        {
                            pattern: emailValidationRegex,
                            message: (
                                <span data-merchant="order-invalid-email-format-error">
                                    <FormattedMessage {...globalLang.invalidEmailFormat} />
                                </span>
                            ),
                        },
                    ]}
                >
                    <Input
                        placeholder={intl.formatMessage(commonLang.clientEmail)}
                        data-merchant="client-email-input"
                    />
                </Form.Item>
                <Form.Item
                    normalize={normalizeEmptyString}
                    style={{ flex: 1 }}
                    required
                    name={newOrderFormKeys.customerId}
                    label={<FormattedMessage {...commonLang.clientId} />}
                    tooltip={getFormItemTooltip(lang.clientIdTooltip)}
                >
                    <Input placeholder={intl.formatMessage(commonLang.clientId)} data-merchant="client-id-input" />
                </Form.Item>
            </Flex>
            <Form.Item
                normalize={normalizeEmptyString}
                required
                rules={urlRules}
                name={newOrderFormKeys.successRedirectUrl}
                label={<FormattedMessage {...commonLang.successRedirect} />}
                tooltip={getFormItemTooltip(lang.successRedirectTooltip)}
            >
                <Input
                    placeholder={intl.formatMessage(commonLang.successRedirect)}
                    data-merchant="order-success-redirect-input"
                />
            </Form.Item>
            <Form.Item
                normalize={normalizeEmptyString}
                required
                rules={urlRules}
                name={newOrderFormKeys.failureRedirectUrl}
                label={<FormattedMessage {...commonLang.failRedirect} />}
                tooltip={getFormItemTooltip(lang.failRedirectTooltip)}
            >
                <Input
                    placeholder={intl.formatMessage(commonLang.failRedirect)}
                    data-merchant="order-failure-redirect-input"
                />
            </Form.Item>
            {type === PaymentType.Invoice && (
                <div style={{ marginBottom: 16 }}>
                    <Flex vertical>
                        <Flex gap={4}>
                            <Text data-merchant={null}>
                                <FormattedMessage {...lang.underpaymentLabel} />
                            </Text>
                            <Tooltip
                                title={<FormattedMessage {...lang.underpaymentTooltip} />}
                                placement="bottom"
                                data-merchant="payment-constructor-underpayment-tooltip"
                            >
                                <Flex>
                                    <AppSvg size={20} color={token.colorTextDescription} name={assets.questionMark} />
                                </Flex>
                            </Tooltip>
                        </Flex>
                        <Text type="secondary" data-merchant={null}>
                            <FormattedMessage {...lang.underpaymentDescription} />
                        </Text>
                        <Select
                            data-merchant="payment-constructor-underpayment-select"
                            defaultValue={DEFAULT_OPTION_VALUE}
                            onChange={handleUnderpaymentSelectChange}
                            options={getUnderpaymentOptions(lossConfig)}
                            className={isCustomSelected ? compactStyles.selectCompactTopItem : undefined}
                        />
                    </Flex>
                    {isCustomSelected && (
                        <>
                            <Form.Item>
                                <CustomRelativeLossItem
                                    usageContext="payment-constructor"
                                    name={[newOrderFormKeys.lossConfig, 'relative']}
                                    required
                                    hidden={!isCustomSelected}
                                    isLossEnabled
                                />
                            </Form.Item>
                            <AbsoluteLossItem
                                usageContext="payment-constructor"
                                formItemNamePrefix={[newOrderFormKeys.lossConfig]}
                                isLoading={isProjectLoading}
                                isAbsoluteLossEnabled={isAbsoluteCheckboxEnabled}
                                isRelativeLossEnabled={isCustomSelected}
                                baseCurrenciesOptions={baseCurrenciesOptions}
                                lossConfig={lossConfig}
                                onCheckboxChange={handleCheckboxChange}
                            />
                        </>
                    )}
                </div>
            )}
        </>
    )
}
