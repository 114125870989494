import { Button, Checkbox, Col, Form, Row, Space, Typography, theme } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import type { FormProps } from '@merchant/ui-kit/ant-design'
import { FormattedMessageWithBoldText } from '~features/WhitelistPage/components/FormattedMessageWithBoldText'

const { Text } = Typography
const { useToken } = theme

const checkboxTextWrapperStyles: React.CSSProperties = { cursor: 'pointer' }

interface Props extends FormProps {
    onSuccess: () => void
    onCancel: () => void
}

export function EnableWhitelistForm({ onSuccess, onCancel }: Props) {
    const {
        token: { Form: FormToken },
    } = useToken()
    const { itemMarginBottom } = FormToken || {}
    const [form] = Form.useForm()

    return (
        <Form form={form} onFinish={onSuccess}>
            <div style={{ marginBottom: itemMarginBottom }}>
                <Text data-merchant={null}>
                    <FormattedMessage {...lang.areYouSure} />
                </Text>
            </div>
            <Form.Item
                name="enabled"
                rules={[
                    {
                        validator: (_, value) => (value ? Promise.resolve() : Promise.reject()),
                        message: (
                            <Text
                                type="danger"
                                style={{ marginInline: 25 }}
                                data-merchant="agreement-required-error-message"
                            >
                                <FormattedMessage {...lang.requiredAgreement} />
                            </Text>
                        ),
                    },
                ]}
                style={{ marginBottom: 34 }}
                valuePropName="checked"
            >
                <Space align="start">
                    <Checkbox autoFocus data-merchant="whitelist-enable-modal-checkbox" id="enable-checkbox" />
                    <label htmlFor="enable-checkbox" style={checkboxTextWrapperStyles}>
                        <Text data-merchant={null}>
                            <FormattedMessageWithBoldText message={lang.checkboxText} />
                        </Text>
                    </label>
                </Space>
            </Form.Item>
            <Form.Item noStyle>
                <Row gutter={12} justify="space-between">
                    <Col flex="50%">
                        <Button
                            block
                            size="large"
                            data-merchant="whitelist-enable-modal-close-button"
                            onClick={onCancel}
                        >
                            <FormattedMessage {...lang.cancel} />
                        </Button>
                    </Col>
                    <Col flex="50%">
                        <Button
                            data-merchant="whitelist-enable-modal-submit-button"
                            type="primary"
                            block
                            size="large"
                            htmlType="submit"
                        >
                            <FormattedMessage {...lang.enableWhitelist} />
                        </Button>
                    </Col>
                </Row>
            </Form.Item>
        </Form>
    )
}
