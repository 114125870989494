import { EmailSupportLink } from '@merchant/shared/components'
import { useBreakpoint } from '@merchant/shared/hooks'
import { Button, Flex, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage, useIntl } from 'react-intl'
import { lang } from './lang'
import { useActionModalContext } from '~features/ActionModal/context'
import { globalLang } from '~globalLang'

const { Text } = Typography

export function WithdrawalFiatProhibited() {
    const intl = useIntl()
    const breakpoints = useBreakpoint()
    const merchantSupportEmail = intl.formatMessage(globalLang.merchantSupportEmail)
    const { closeModal } = useActionModalContext()

    return (
        <Flex vertical gap={24}>
            <Text data-merchant="prohibited-fiat-withdrawal-text">
                <FormattedMessage
                    {...lang.fiatNotPermittedText}
                    values={{
                        emailLink: chunks => <EmailSupportLink>{chunks}</EmailSupportLink>,
                    }}
                />
            </Text>
            <Flex justify="space-between" vertical={!breakpoints.sm} gap={12}>
                <Button
                    block
                    size="large"
                    href={`mailto:${merchantSupportEmail}`}
                    data-merchant="prohibited-fiat-withdrawal-support-button"
                >
                    <FormattedMessage {...lang.contactSupport} />
                </Button>
                <Button
                    block
                    size="large"
                    type="primary"
                    onClick={closeModal}
                    data-merchant="prohibited-fiat-withdrawal-ok-button"
                >
                    <FormattedMessage {...lang.okUnderstood} />
                </Button>
            </Flex>
        </Flex>
    )
}
