import { useEffect, useState } from 'react'
import { lang as commonLang } from './../../lang'
import { steps, initWithdrawalFiatData, viewTitles } from './const'
import type { WithdrawalFiatFormData } from './types'
import { useProject, useProjectBlocklist } from '~api'
import { lang as suspensionLang } from '~components/ActionSuspended/lang'
import { useActionModalContext } from '~features/ActionModal/context'

export const useData = () => {
    const { data: project, isLoading: isProjectLoading } = useProject()
    const { setTitle, setShowGoBack, setOnBackClickHandler, setShouldRevalidateDependentTables, closeModal } =
        useActionModalContext()
    const [step, setStep] = useState<(typeof steps)[keyof typeof steps]>(steps.withdrawalFiatInput)
    const [withdrawalData, setWithdrawalData] = useState<Partial<WithdrawalFiatFormData> | WithdrawalFiatFormData>(
        initWithdrawalFiatData
    )
    const isFiatWithdrawalAllowed = project?.fiatWithdrawal
    const { data: blocklistData, isLoading: isBlockDataLoading } = useProjectBlocklist()

    useEffect(() => {
        setTitle(viewTitles[step] ? { text: viewTitles[step], dataMerchant: 'withdrawal-fiat-modal-title' } : false)

        setShouldRevalidateDependentTables(step === steps.withdrawalFiatFinish)

        if (step === steps.withdrawalFiatSummary) {
            setShowGoBack(true)
            setOnBackClickHandler(() => setStep(steps.withdrawalFiatInput))
        } else {
            setShowGoBack(false)
            setOnBackClickHandler(undefined)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step])

    useEffect(() => {
        if (isProjectLoading || isBlockDataLoading) {
            return
        }
        if (!isFiatWithdrawalAllowed) {
            setTitle({ text: commonLang.notPermittedTitle, dataMerchant: 'withdrawal-fiat-not-allowed-modal-title' })
        } else if (blocklistData?.blocked) {
            setTitle({
                text: suspensionLang.withdrawalSuspensionModalTitle,
                dataMerchant: 'withdrawal-suspension-modal-title',
            })
        }
    }, [isBlockDataLoading, isFiatWithdrawalAllowed, setTitle, blocklistData?.blocked, isProjectLoading])

    return {
        step,
        setStep,
        closeModal,
        isDataLoading: isProjectLoading || isBlockDataLoading,
        blocklistData,
        isFiatWithdrawalAllowed,
        withdrawalData,
        setWithdrawalData,
    }
}
