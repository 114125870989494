/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CallbackResponseRequest } from './CallbackResponseRequest';
import {
    CallbackResponseRequestFromJSON,
    CallbackResponseRequestFromJSONTyped,
    CallbackResponseRequestToJSON,
} from './CallbackResponseRequest';
import type { CallbackResponseResponse } from './CallbackResponseResponse';
import {
    CallbackResponseResponseFromJSON,
    CallbackResponseResponseFromJSONTyped,
    CallbackResponseResponseToJSON,
} from './CallbackResponseResponse';

/**
 * 
 * @export
 * @interface CallbackResponse
 */
export interface CallbackResponse {
    /**
     * 
     * @type {CallbackResponseRequest}
     * @memberof CallbackResponse
     */
    request: CallbackResponseRequest;
    /**
     * 
     * @type {CallbackResponseResponse}
     * @memberof CallbackResponse
     */
    response: CallbackResponseResponse;
}

/**
 * Check if a given object implements the CallbackResponse interface.
 */
// @ts-ignore
export function instanceOfCallbackResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "request" in value;
    isInstance = isInstance && "response" in value;

    return isInstance;
}

// @ts-ignore
export function CallbackResponseFromJSON(json: any): CallbackResponse {
    return CallbackResponseFromJSONTyped(json, false);
}

// @ts-ignore
export function CallbackResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CallbackResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'request': CallbackResponseRequestFromJSON(json['request']),
        'response': CallbackResponseResponseFromJSON(json['response']),
    };
}

// @ts-ignore
export function CallbackResponseToJSON(value?: CallbackResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'request': CallbackResponseRequestToJSON(value.request),
        'response': CallbackResponseResponseToJSON(value.response),
    };
}

