import { isEmpty } from 'lodash-es'
import { useEffect, useMemo, useState } from 'react'
import { stepKeys } from './const'
import { getDefaultSwrConfig, getIfSetupIsFinished, sortCurrenciesSkippingFirst } from './utils'
import type { CurrencyExtended } from './types'
import type { SetState } from '@merchant/shared/types'
import type { Currencies } from '~api/types'
import { useCurrencies, useHoldingCurrencies } from '~api'

export const useSetupSteps = () => {
    const [step, setStep] = useState(-1)
    const { data: holdingCurrencies } = useHoldingCurrencies()
    const hasCompletedSetup = getIfSetupIsFinished(holdingCurrencies)

    const setSetupDone = () => setStep(-1)
    const toNextStep = () => setStep(prev => prev + 1)

    useEffect(() => {
        if (holdingCurrencies && !hasCompletedSetup) {
            setStep(1)
        } else if (hasCompletedSetup) {
            setSetupDone()
        }
    }, [hasCompletedSetup, holdingCurrencies])

    return { setupStep: step, toNextStep, setSetupDone }
}

type UseSelectedCurrencies = (params: {
    holdingCurrencies: CurrencyExtended[] | undefined
    currenciesMap: Currencies | undefined
    defaultCurrency: string
}) => {
    selectedCurrencies: CurrencyExtended[]
    setSelectedCurrencies: SetState<CurrencyExtended[]>
}

export const useSelectedCurrencies: UseSelectedCurrencies = ({ holdingCurrencies, currenciesMap, defaultCurrency }) => {
    const [selectedCurrencies, setSelectedCurrencies] = useState<CurrencyExtended[]>([])

    useEffect(() => {
        const options = sortCurrenciesSkippingFirst(holdingCurrencies)
        const selectedHoldingCurrencies = options.filter(c => c.selected)
        if (!isEmpty(selectedHoldingCurrencies)) {
            setSelectedCurrencies(selectedHoldingCurrencies)
        }
    }, [holdingCurrencies, currenciesMap, defaultCurrency])

    return { selectedCurrencies, setSelectedCurrencies }
}

export const useDefaultCurrency = (defaultConfigConversionCurrencyCode: string) => {
    const [defaultCurrency, setDefaultCurrency] = useState('')
    const { data: holdingCurrencies } = useHoldingCurrencies()

    useEffect(() => {
        if (holdingCurrencies) {
            if (holdingCurrencies[0]?.selected === true) {
                setDefaultCurrency(holdingCurrencies[0].code)
            } else {
                setDefaultCurrency(defaultConfigConversionCurrencyCode)
            }
        }
    }, [defaultConfigConversionCurrencyCode, holdingCurrencies])

    return { defaultCurrency, setDefaultCurrency }
}

export const useExtendedHoldingCurrencies = () => {
    const { data: currencies } = useCurrencies()
    const { data: holdingCurrencies, ...rest } = useHoldingCurrencies(getDefaultSwrConfig(stepKeys[0]))

    const extended = useMemo(
        () =>
            holdingCurrencies?.map(c => ({
                ...c,
                name: currencies?.[c.code]?.name,
                payoutEnabled: currencies?.[c.code]?.payoutEnabled,
                crypto: currencies?.[c.code]?.crypto,
                swappableTo: currencies?.[c.code]?.swappableTo,
            })) as CurrencyExtended[] | undefined,
        [holdingCurrencies, currencies]
    )

    return { data: extended, ...rest }
}
