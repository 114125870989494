/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SwapStatus } from './SwapStatus';
import {
    SwapStatusFromJSON,
    SwapStatusFromJSONTyped,
    SwapStatusToJSON,
} from './SwapStatus';

/**
 * 
 * @export
 * @interface Swap
 */
export interface Swap {
    /**
     * 
     * @type {string}
     * @memberof Swap
     */
    currencyFrom: string;
    /**
     * 
     * @type {string}
     * @memberof Swap
     */
    currencyTo: string;
    /**
     * 
     * @type {string}
     * @memberof Swap
     */
    amountFrom: string;
    /**
     * 
     * @type {string}
     * @memberof Swap
     */
    amountTo: string;
    /**
     * 
     * @type {string}
     * @memberof Swap
     */
    rate: string;
    /**
     * 
     * @type {string}
     * @memberof Swap
     */
    txnId: string;
    /**
     * 
     * @type {SwapStatus}
     * @memberof Swap
     */
    status: SwapStatus;
}

/**
 * Check if a given object implements the Swap interface.
 */
// @ts-ignore
export function instanceOfSwap(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "currencyFrom" in value;
    isInstance = isInstance && "currencyTo" in value;
    isInstance = isInstance && "amountFrom" in value;
    isInstance = isInstance && "amountTo" in value;
    isInstance = isInstance && "rate" in value;
    isInstance = isInstance && "txnId" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

// @ts-ignore
export function SwapFromJSON(json: any): Swap {
    return SwapFromJSONTyped(json, false);
}

// @ts-ignore
export function SwapFromJSONTyped(json: any, ignoreDiscriminator: boolean): Swap {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currencyFrom': json['currency_from'],
        'currencyTo': json['currency_to'],
        'amountFrom': json['amount_from'],
        'amountTo': json['amount_to'],
        'rate': json['rate'],
        'txnId': json['txn_id'],
        'status': SwapStatusFromJSON(json['status']),
    };
}

// @ts-ignore
export function SwapToJSON(value?: Swap | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currency_from': value.currencyFrom,
        'currency_to': value.currencyTo,
        'amount_from': value.amountFrom,
        'amount_to': value.amountTo,
        'rate': value.rate,
        'txn_id': value.txnId,
        'status': SwapStatusToJSON(value.status),
    };
}

