import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    blockTitle: {
        id: 'edit-roles.typography.block_title',
        defaultMessage: 'Roles permissions',
    },
    blockDescription: {
        id: 'edit-roles.typography.block_description',
        defaultMessage: `The <b>Owner</b> role (not shown in the table) has full access to all permissions and cannot be edited.
            <break></break>It is automatically assigned to the creator of project.`,
    },
    saveButton: {
        id: 'edit-roles.button.save',
        defaultMessage: 'Save',
    },
})
