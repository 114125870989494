import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    actionButtonConvert: {
        id: 'action-button.button.convert',
        defaultMessage: 'Convert',
    },
    actionButtonWithdrawal: {
        id: 'action-button.button.withdrawal',
        defaultMessage: 'Withdraw',
    },
    actionButtonDeposit: {
        id: 'action-button.button.deposit',
        defaultMessage: 'Deposit',
    },
})
