import { Button, Col, Row, Typography } from '@merchant/ui-kit/ant-design'
import { usePostHog } from 'posthog-js/react'
import React from 'react'
import { authCaptureEventParams, PosthogAuthEvents } from './posthog'
import type { ReactNode } from 'react'

const { Title, Text } = Typography

const buttonStyles: React.CSSProperties = { height: 'auto', padding: 0 }
const titleStyles: React.CSSProperties = { letterSpacing: -0.48 }
const textStyles: React.CSSProperties = { letterSpacing: -0.28 }

interface Props {
    title: ReactNode
    subtitle: ReactNode
    buttonTitle: ReactNode
    toggleMode: () => void
    buttonDataMerchant: string
    titleDataMerchant: string
    mode: 'signUp' | 'signIn'
}

export function AuthHeader({
    title,
    subtitle,
    buttonTitle,
    toggleMode,
    buttonDataMerchant,
    titleDataMerchant,
    mode,
}: Props) {
    const posthog = usePostHog()

    const handleToggleButtonClick = () => {
        toggleMode()
        const captureFn =
            mode === 'signUp'
                ? authCaptureEventParams[PosthogAuthEvents.goToSignInPage]
                : authCaptureEventParams[PosthogAuthEvents.goToSignUpPage]

        posthog.capture(...captureFn())
    }

    return (
        <Row justify="space-between" style={{ alignItems: 'baseline' }}>
            <Col>
                <Title level={3} style={titleStyles} data-merchant={titleDataMerchant}>
                    {title}
                </Title>
            </Col>
            <Col>
                <Text data-merchant={null} type="secondary" style={textStyles}>
                    {subtitle}{' '}
                    <Button
                        data-merchant={buttonDataMerchant}
                        type="link"
                        onClick={handleToggleButtonClick}
                        style={buttonStyles}
                        size="large"
                    >
                        {buttonTitle}
                    </Button>
                </Text>
            </Col>
        </Row>
    )
}
