import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    pageTitle: {
        id: 'merchant-page.typography.title',
        defaultMessage: 'My merchant',
    },
    generalPageTitle: {
        id: 'merchant-page.typography.general_info',
        defaultMessage: 'General info',
    },
    currenciesSettingsPageTitle: {
        id: 'merchant-page.typography.currencies_settings',
        defaultMessage: 'Currencies settings',
    },
    paymentsSettingsPageTitle: {
        id: 'merchant-page.typography.payments_settings',
        defaultMessage: 'Payments settings',
    },
    teamPageTitle: {
        id: 'merchant-page.typography.team',
        defaultMessage: 'Team',
    },
    addMembersPageTitle: {
        id: 'merchant-page.typography.add_members',
        defaultMessage: 'Add members',
    },
    editRolesPageTitle: {
        id: 'merchant-page.typography.edit_roles',
        defaultMessage: 'Edit roles',
    },
})
