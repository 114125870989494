import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { Alert, Button, Space, Typography } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Permission } from '~api/instances/cabinet-api'
import { useUserPermissionContext } from '~contexts'
import { FormattedMessageWithBoldText } from '~features/WhitelistPage/components'
import { lang as commonLang } from '~features/WhitelistPage/lang'

const { Title, Text } = Typography

const wrapperSpaceStyles: React.CSSProperties = {
    maxWidth: 550,
    margin: 'auto',
    textAlign: 'center',
}
const alertStyles: React.CSSProperties = {
    alignItems: 'center',
    textAlign: 'left',
}

interface Props {
    onButtonClick: () => void
}

export function Welcome({ onButtonClick }: Props) {
    const { checkUserPermission } = useUserPermissionContext()

    return (
        <Space direction="vertical" align="center" style={wrapperSpaceStyles} size={16}>
            <Title level={2} data-merchant={null}>
                <FormattedMessage {...commonLang.enableWhitelistTitle} />
            </Title>
            <Title level={4} data-merchant="whitelist-welcome-header">
                <FormattedMessage {...commonLang.enableWhitelistSubtitle} />
            </Title>
            <Text type="secondary" data-merchant={null}>
                <FormattedMessage {...commonLang.whitelistDescription} />
            </Text>
            <Alert
                data-merchant="whitelist-welcome-alert"
                style={alertStyles}
                type="warning"
                icon={<AppSvg size={24} name={assets.alertCircle} />}
                showIcon
                description={<FormattedMessageWithBoldText message={commonLang.newAddressAlertDescription} />}
            />
            <Button
                disabled={!checkUserPermission(Permission.EditWithdrawalWhitelist)}
                data-merchant="whitelist-welcome-button"
                type="primary"
                size="large"
                onClick={onButtonClick}
            >
                <FormattedMessage {...commonLang.enableWhitelist} />
            </Button>
        </Space>
    )
}
