import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg, ColorModeIcon } from '@merchant/shared/components'
import { isKeyOfObject } from '@merchant/shared/utils'
import { ConfigProvider, Menu, Typography } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { navMenuConfigTheme } from '../const'
import styles from '../style.module.css'
import { lang } from './lang'
import type { MenuProps } from '@merchant/ui-kit/ant-design'
import { useUIContext } from '~contexts'
import { globalLang } from '~globalLang'

const { Text } = Typography

const menuStyles: React.CSSProperties = { border: 'none' }
const menuItemStyles: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
}

enum MenuItemKeys {
    colorMode = 'colorMode',
    projectPicker = 'projectPicker',
}

const menuItems: MenuProps['items'] = [
    {
        key: MenuItemKeys.projectPicker,
        icon: <AppSvg size={20} name={assets.user} />,
        label: (
            <Text data-merchant="sidemenu-project-picker-button" type="secondary">
                <FormattedMessage {...lang.projectPickerButton} />
            </Text>
        ),
        style: menuItemStyles,
        className: styles.menuItem,
    },
    {
        key: MenuItemKeys.colorMode,
        icon: <ColorModeIcon />,
        label: (
            <Text data-merchant="sidemenu-theme-button" type="secondary">
                <FormattedMessage {...globalLang.colorMode} />
            </Text>
        ),
        style: menuItemStyles,
        className: styles.menuItem,
    },
]

interface Props {
    openProjectPicker: () => void
    openThemeModal: () => void
}

export function ControlsBlock({ openProjectPicker, openThemeModal }: Props) {
    const { closeSideMenu } = useUIContext()
    const map: Record<MenuItemKeys, () => void> = {
        colorMode: openThemeModal,
        projectPicker: openProjectPicker,
    }

    const clickHandler: MenuProps['onClick'] = e => {
        if (isKeyOfObject(e.key, MenuItemKeys)) {
            map[e.key]()
            closeSideMenu()
        }
    }

    return (
        <ConfigProvider theme={navMenuConfigTheme}>
            <Menu
                items={menuItems}
                className={styles.menu}
                selectable={false}
                style={menuStyles}
                onClick={clickHandler}
                data-merchant="sidemenu-controls-menu"
            />
        </ConfigProvider>
    )
}
