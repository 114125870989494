import { HttpError } from '@merchant/shared/api'
import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { useNotifications } from '@merchant/shared/contexts'
import { useFormValidation, usePromise } from '@merchant/shared/hooks'
import { Alert, Button, Form, Input, Typography } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { formItemsKeys, notificationMessage } from './const'
import { lang } from './lang'
import type { ChangePasswordFormData } from './types'
import { authApi } from '~api'
import { ModalNames } from '~constants/modal'
import { PasswordWithTooltip } from '~features'
import { globalLang } from '~globalLang'
import { useRegisterDirtyFormChecker } from '~hooks'

const { Paragraph } = Typography

const headerTextStyles: React.CSSProperties = { marginBottom: 24 }
const alertStyles: React.CSSProperties = { alignItems: 'center' }

interface Props {
    onSuccess: () => void
}

// eslint-disable-next-line max-lines-per-function
export function ChangePassword({ onSuccess }: Props) {
    const {
        notification: { api: notificationApi },
    } = useNotifications()
    const intl = useIntl()
    const [form] = Form.useForm<ChangePasswordFormData>()
    const { onBlur, onFinishFailed, validationMode } = useFormValidation(Object.values(formItemsKeys))
    useRegisterDirtyFormChecker(ModalNames.changePassword, form)
    const { send: handleFormFinish, isLoading } = usePromise(
        async ({ newPassword, oldPassword }: ChangePasswordFormData) =>
            authApi.changePassword({
                passwordChangeRequest: { newPassword, oldPassword },
            }),
        {
            onSuccess: () => {
                notificationApi.open({
                    message: notificationMessage.success,
                })
                onSuccess()
            },
            onError: e => {
                if (HttpError.isInstance(e)) {
                    if (e.status === 400) {
                        form.setFields([
                            {
                                name: formItemsKeys.oldPassword,
                                errors: [intl.formatMessage(globalLang.passwordIsInvalid)],
                            },
                        ])
                    } else {
                        notificationApi.open({
                            message: notificationMessage.error,
                        })
                    }
                }
            },
            showErrorNotification: false,
        }
    )

    return (
        <>
            <Paragraph type="secondary" style={headerTextStyles} data-merchant={null}>
                <FormattedMessage {...lang.dontSharePassword} />
            </Paragraph>
            <Form
                form={form}
                onFinish={handleFormFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                size="large"
            >
                <Form.Item
                    validateTrigger={validationMode.oldPassword}
                    name={formItemsKeys.oldPassword}
                    rules={[
                        {
                            required: true,
                            message: <FormattedMessage {...globalLang.requiredFieldMessage} />,
                        },
                    ]}
                >
                    <Input.Password
                        data-merchant="change-password-current-password-input"
                        onBlur={onBlur(formItemsKeys.oldPassword)}
                        placeholder={intl.formatMessage(lang.currentPasswordPlaceholder)}
                    />
                </Form.Item>
                <PasswordWithTooltip
                    validateTrigger={validationMode.newPassword}
                    form={form}
                    onBlur={onBlur(formItemsKeys.newPassword)}
                    keepInvalidShown
                    name={formItemsKeys.newPassword}
                    inputPlaceholder={intl.formatMessage(lang.newPasswordPlaceholder)}
                    inputDataMerchant="change-password-new-password-input"
                />
                <Form.Item
                    validateTrigger={validationMode.newPasswordConfirm}
                    name={formItemsKeys.newPasswordConfirm}
                    dependencies={[formItemsKeys.newPassword]}
                    rules={[
                        {
                            required: true,
                            message: <FormattedMessage {...globalLang.requiredFieldMessage} />,
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue(formItemsKeys.newPassword) === value) {
                                    return Promise.resolve()
                                }

                                return Promise.reject(new Error(intl.formatMessage(lang.passwordsAreDifferent)))
                            },
                        }),
                    ]}
                >
                    <Input.Password
                        data-merchant="change-password-new-password-confirm-input"
                        placeholder={intl.formatMessage(lang.repeatNewPasswordPlaceholder)}
                        onBlur={onBlur(formItemsKeys.newPasswordConfirm)}
                    />
                </Form.Item>
                <Form.Item>
                    <Alert
                        style={alertStyles}
                        type="warning"
                        data-merchant="change-password-suspension-alert"
                        icon={<AppSvg size={20} name={assets.alertCircle} />}
                        description={<FormattedMessage {...lang.afterResetDisableDisclaimer} />}
                        showIcon
                    />
                </Form.Item>
                <Form.Item noStyle>
                    <Button
                        data-merchant="change-password-submit-button"
                        htmlType="submit"
                        type="primary"
                        block
                        loading={isLoading}
                    >
                        <FormattedMessage {...lang.continue} />
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}
