/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TransactionSwapDetails
 */
export interface TransactionSwapDetails {
    /**
     * 
     * @type {string}
     * @memberof TransactionSwapDetails
     */
    amountFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionSwapDetails
     */
    currencyFrom: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionSwapDetails
     */
    amountTo?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionSwapDetails
     */
    currencyTo: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionSwapDetails
     */
    rate: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionSwapDetails
     */
    swapTxnId?: string;
}

/**
 * Check if a given object implements the TransactionSwapDetails interface.
 */
// @ts-ignore
export function instanceOfTransactionSwapDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "currencyFrom" in value;
    isInstance = isInstance && "currencyTo" in value;
    isInstance = isInstance && "rate" in value;

    return isInstance;
}

// @ts-ignore
export function TransactionSwapDetailsFromJSON(json: any): TransactionSwapDetails {
    return TransactionSwapDetailsFromJSONTyped(json, false);
}

// @ts-ignore
export function TransactionSwapDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionSwapDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amountFrom': !exists(json, 'amount_from') ? undefined : json['amount_from'],
        'currencyFrom': json['currency_from'],
        'amountTo': !exists(json, 'amount_to') ? undefined : json['amount_to'],
        'currencyTo': json['currency_to'],
        'rate': json['rate'],
        'swapTxnId': !exists(json, 'swap_txn_id') ? undefined : json['swap_txn_id'],
    };
}

// @ts-ignore
export function TransactionSwapDetailsToJSON(value?: TransactionSwapDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount_from': value.amountFrom,
        'currency_from': value.currencyFrom,
        'amount_to': value.amountTo,
        'currency_to': value.currencyTo,
        'rate': value.rate,
        'swap_txn_id': value.swapTxnId,
    };
}

