import type { CurrenciesBase } from '~types'

interface Props {
    currencies?: CurrenciesBase
    currencyCode?: string
    networkCode?: string
    hash?: string
}

export const getExplorerLink = ({ currencies, currencyCode, networkCode, hash }: Props) => {
    if (!currencies || !currencyCode || !hash) {
        return
    }

    const currency = currencies[currencyCode]

    if (!currency) {
        return
    }
    const network = currency.networks.find(network => network.code === networkCode)

    const explorerLink = network?.cryptoExplorer || currency.cryptoExplorer

    if (!explorerLink?.includes('{tx}')) {
        return
    }

    return explorerLink.replace('{tx}', hash)
}
