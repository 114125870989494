import { normalizeAddress, formatFilterRequestDates } from '@merchant/shared/utils'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash-es'
import { useMemo } from 'react'
import { Filter } from './components'
import type { FilterFormData, FilterFormDataQuery } from './types'
import type { CustomerAddress, ListAddresses200Response, ListAddressesRequest } from '~api/instances/cabinet-api'
import { projectApi, useProject } from '~api'
import { apiRestKeys } from '~api/swr/keys'
import { useInfiniteFetcher } from '~api/swr/useInfiniteFetcher'
import { AddressesTable } from '~features/Addresses/components'
import { TableType } from '~features/Addresses/components/AddressesTable/const'
import { useQueryNavigation } from '~hooks'
import { getTruthyFields } from '~utils/filters'

export function StaticAddresses() {
    const { data: project } = useProject()

    const { filters, onSubmit, onClear } = useQueryNavigation<Partial<FilterFormData>, Partial<FilterFormDataQuery>>({
        filters: ['address', 'addressTag', 'currency', 'customerId', 'network', 'createdAtFrom', 'createdAtTo'],
        beforeWrite: {
            // TODO: get types right later
            date: date => {
                if (!Array.isArray(date)) {
                    return
                }

                const { dateFrom, dateTo } = formatFilterRequestDates(date)

                return {
                    createdAtFrom: dateFrom,
                    createdAtTo: dateTo,
                }
            },
        },
        afterRead: ({ createdAtFrom = null, createdAtTo = null, ...restFilters }) => {
            const from = dayjs(createdAtFrom)
            const to = dayjs(createdAtTo)

            if (!from.isValid() && !to.isValid()) {
                return restFilters
            }

            return {
                ...restFilters,
                ...formatFilterRequestDates([from.isValid() ? from : null, to.isValid() ? to : null]),
                date: [from.isValid() ? from : null, to.isValid() ? to : null],
            }
        },
    })

    const { amount, truthy: filtersToApply } = getTruthyFields(filters)

    const {
        data: addresses = [],
        canFetchMore,
        fetchMore: handleFetchMore,
        isLoading,
        isLoadingMore,
        isPageDataEmpty,
    } = useInfiniteFetcher<ListAddresses200Response, CustomerAddress[], ListAddressesRequest>(
        ({ previousPageData, limit, pageKey }) => ({
            params: {
                limit,
                cursor: previousPageData?.cursorNext,
                projectId: project!.id,
                ...filtersToApply,
            },
            pageKey: project ? pageKey : null,
        }),
        projectApi.listCustomerAddresses.bind(projectApi),
        {
            limit: 10,
            dataPath: 'addresses',
            revalidateIfStale: true,
            pageKey: apiRestKeys.getStaticAddresses,
        }
    )

    const shouldRenderShowMoreButton = !isPageDataEmpty && canFetchMore

    const normalizedAddresses = useMemo(() => addresses.map(normalizeAddress), [addresses])
    const isFiltersEmpty = isEmpty(filters)
    const areFiltersShown = !isPageDataEmpty || !isFiltersEmpty
    const isDataEmpty = isPageDataEmpty && isFiltersEmpty

    return (
        <AddressesTable
            context={TableType.StaticAddresses}
            data-merchant="static-addresses"
            filter={
                areFiltersShown && (
                    <Filter
                        activeFiltersCount={amount}
                        initialValues={filters}
                        onFinish={onSubmit}
                        onReset={onClear}
                        isLoading={isLoading}
                    />
                )
            }
            tableProps={{
                showHeader: !isDataEmpty,
                dataSource: normalizedAddresses,
                loading: isLoading,
            }}
            moreButtonProps={{
                isVisible: shouldRenderShowMoreButton,
                onClick: handleFetchMore,
                loading: isLoadingMore,
            }}
        />
    )
}
