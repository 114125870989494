import { usePromise } from '@merchant/shared/hooks'
import type { WithdrawalsFilterFormDataQuery } from './types'
import type { WithdrawalFiatFormData } from '~features/ActionModal/views/WithdrawalFiat/types'
import { projectApi, useProject } from '~api'
import { downloadMedia } from '~utils'

type Filters = Partial<WithdrawalFiatFormData> | Partial<WithdrawalsFilterFormDataQuery>

export function useHandlers() {
    const { data: project } = useProject()
    const { send: exportAndDownloadCSV, isLoading: isExportingCSV } = usePromise(
        (filters: Filters) =>
            projectApi.exportProjectWithdrawals({
                ...filters,
                projectId: project?.id || '',
            }),
        {
            onSuccess: data =>
                downloadMedia({
                    data,
                    type: 'text/csv',
                    filename: 'withdrawals.csv',
                }),
        }
    )

    const onExport = (filters: Filters) => async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation()
        exportAndDownloadCSV(filters)
    }

    return {
        onExportCSV: onExport,
        isExportingCSV,
    }
}
