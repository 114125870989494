import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    willBeActivated: {
        id: 'currencies-settings.popover.will_be_activated',
        defaultMessage: 'Will be <bold>activated</bold>',
    },
    willBeDeactivated: {
        id: 'currencies-settings.popover.will_be_deactivated',
        defaultMessage: 'Will be <bold>deactivated</bold>',
    },
    resetDisclaimer: {
        id: 'currencies-settings.popconfirm.reset_disclaimer',
        defaultMessage:
            "After Reset, you'll have only the initial default currencies activated (only {defaultResetCurrency}). All other currencies will be deactivated. This won't affect existing balances or past transactions, only future accumulations. Are you sure?",
    },
    resetConfirmButton: {
        id: 'currencies-settings.popconfirm.confirm_button',
        defaultMessage: 'Confirm',
    },
    resetCancelButton: {
        id: 'currencies-settings.popconfirm.cancel_button',
        defaultMessage: 'No',
    },
    restoreDefaultButton: {
        id: 'currencies-settings.button.restore_default_settings',
        defaultMessage: 'Restore default settings',
    },
    saveChanges: {
        id: 'currencies-settings.button.save_changes',
        defaultMessage: 'Save changes',
    },
    changes: {
        id: 'currencies-settings.popover.changes',
        defaultMessage: 'Changes',
    },
    editCurrenciesModalTitle: {
        id: 'currencies-settings.modal.edit_accumulation_currencies_title',
        defaultMessage: 'Edit accumulation currencies',
    },
    checkAll: {
        id: 'currencies-settings.checkbox.check_all',
        defaultMessage: 'Check all',
    },
    searchInputPlaceholder: {
        id: 'currencies-settings.input.search_placeholder',
        defaultMessage: 'Search currency',
    },
    supportedCurrenciesMessage: {
        id: 'currencies-settings.modal.alert.supported_currencies_text',
        defaultMessage:
            '<tag>Attention:</tag> You can accumulate any selected cryptocurrencies, but you can only withdraw in {Currencies}. Please adjust your accumulation currencies accordingly.',
    },
    supportedCurrenciesLongMessage: {
        id: 'currencies-settings.modal.alert.supported_currencies_text_long',
        defaultMessage:
            '<tag>Attention:</tag> You can accumulate any selected cryptocurrencies, but you can only withdraw in {Currencies} and {ExceedingPart} currencies. Please adjust your accumulation currencies accordingly.',
    },
    currenciesCountPlural: {
        id: 'currencies-settings.modal.currencies_count_plural',
        defaultMessage: '{count} currencies',
    },
    currenciesCountSingular: {
        id: 'currencies-settings.modal.currencies_count_singular',
        defaultMessage: '{count} currency',
    },
    currenciesCountAll: {
        id: 'currencies-settings.modal.currencies_count_all',
        defaultMessage: 'All currencies',
    },
})
