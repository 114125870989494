/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectRoleUpdate } from './ProjectRoleUpdate';
import {
    ProjectRoleUpdateFromJSON,
    ProjectRoleUpdateFromJSONTyped,
    ProjectRoleUpdateToJSON,
} from './ProjectRoleUpdate';

/**
 * 
 * @export
 * @interface UpdateProjectRolesRequest
 */
export interface UpdateProjectRolesRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRolesRequest
     */
    totpCode?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRolesRequest
     */
    totpBackup?: string;
    /**
     * 
     * @type {Array<ProjectRoleUpdate>}
     * @memberof UpdateProjectRolesRequest
     */
    roles: Array<ProjectRoleUpdate>;
}

/**
 * Check if a given object implements the UpdateProjectRolesRequest interface.
 */
// @ts-ignore
export function instanceOfUpdateProjectRolesRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "roles" in value;

    return isInstance;
}

// @ts-ignore
export function UpdateProjectRolesRequestFromJSON(json: any): UpdateProjectRolesRequest {
    return UpdateProjectRolesRequestFromJSONTyped(json, false);
}

// @ts-ignore
export function UpdateProjectRolesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateProjectRolesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totpCode': !exists(json, 'totp_code') ? undefined : json['totp_code'],
        'totpBackup': !exists(json, 'totp_backup') ? undefined : json['totp_backup'],
        'roles': ((json['roles'] as Array<any>).map(ProjectRoleUpdateFromJSON)),
    };
}

// @ts-ignore
export function UpdateProjectRolesRequestToJSON(value?: UpdateProjectRolesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totp_code': value.totpCode,
        'totp_backup': value.totpBackup,
        'roles': ((value.roles as Array<any>).map(ProjectRoleUpdateToJSON)),
    };
}

