import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    deletionDisabledDescription: {
        id: 'settings.security.deletion_disabled.description',
        defaultMessage:
            "For your security, we do not allow the removal of the last active 2FA code. Please add a new 2FA first, and then you'll be able to remove the current one.",
    },
    ifHaveQuestions: {
        id: 'settings.security.deletion_disabled.if_have_questions',
        defaultMessage:
            'If you have any questions, please contact your personal account manager or our {support} team.',
    },
})
