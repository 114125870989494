import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { Button, Typography, theme } from '@merchant/ui-kit/ant-design'
import { isEmpty } from 'lodash-es'
import React, { useState } from 'react'
import { useBoolean } from 'usehooks-ts'
import { UnconfirmedTotpModal } from './UnconfirmedTotpModal'
import type { TOTP } from '~api/instances/cabinet-api'
import { withDirtyCheckingContextProvider } from '~hoc/withDirtyCheckingContextProvider'

const { Text } = Typography
const { useToken } = theme

const twoFaItemStyles: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    gap: 8,
}
const totpButtonStyles: React.CSSProperties = {
    width: 'auto',
    paddingBlock: 0,
    height: 'auto',
    display: 'flex',
}

interface Props {
    totps: TOTP[]
    onDelete: (id: NonNullable<TOTP['id']>) => Promise<void>
    onConfirm: (id: TOTP['id']) => void
    isTwoFaEnabled?: boolean
}

function Component({ totps, onDelete, onConfirm, isTwoFaEnabled }: Props) {
    const [selectedTotp, setSelectedTotp] = useState<TOTP | null>(null)
    const {
        value: isUnconfirmedTotpModalOpen,
        setFalse: closeUnconfirmedTotpModal,
        setTrue: openUnconfirmedTotpModal,
    } = useBoolean(false)
    const { token } = useToken()
    if (isEmpty(totps)) {
        return null
    }

    const resetState = () => {
        setSelectedTotp(null)
        closeUnconfirmedTotpModal()
    }

    const handleDeleteClick = async (totp: TOTP) => {
        setSelectedTotp(totp)
        await onDelete(totp.id!)
        resetState()
    }

    const handleConfirmClick = (totp: TOTP) => {
        setSelectedTotp(totp)
        openUnconfirmedTotpModal()
    }

    const handleConfirmSuccess = (id: TOTP['id']) => {
        onConfirm(id)
        resetState()
    }

    return (
        <>
            <UnconfirmedTotpModal
                open={isUnconfirmedTotpModalOpen}
                selectedTotp={selectedTotp}
                isTwoFaEnabled={isTwoFaEnabled}
                onModalSuccess={handleConfirmSuccess}
                afterClose={resetState}
                onCancel={resetState}
            />
            {totps.map(totp => (
                <div key={totp.id} style={twoFaItemStyles} data-merchant={`unconfirmed-totps-id-${totp.id}`}>
                    <Text type="secondary" data-merchant={`totp-name-${totp.name}`}>
                        {totp.name}
                    </Text>
                    <Button
                        data-merchant="confirm-twofa-button"
                        onClick={() => handleConfirmClick(totp)}
                        icon={<AppSvg size={16} name={assets.infoCircle} />}
                        style={{
                            ...totpButtonStyles,
                            color: token.colorWarning,
                        }}
                        type="link"
                    />
                    <Button
                        loading={selectedTotp?.id === totp.id}
                        data-merchant="delete-twofa-button"
                        icon={<AppSvg size={20} name={assets.trash} />}
                        type="link"
                        style={totpButtonStyles}
                        onClick={() => handleDeleteClick(totp)}
                    />
                </div>
            ))}
        </>
    )
}

export const UnconfirmedTotps = withDirtyCheckingContextProvider(Component)
