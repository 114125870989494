import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    blurTooltipText: {
        id: 'blur.tooltip.text',
        defaultMessage: 'Click to reveal',
    },
    accountName: {
        id: 'add-two-fa-modal.typography.account_name',
        defaultMessage: 'Account name',
    },
    privateSecurityKey: {
        id: 'add-two-fa-modal.typography.private_security_key',
        defaultMessage: 'Private security key',
    },
    keyType: {
        id: 'add-two-fa-modal.typography.key_type',
        defaultMessage: 'Key type',
    },
    timeBased: {
        id: 'add-two-fa-modal.typography.time_based',
        defaultMessage: 'time-based',
    },
    scanQrCodeTitle: {
        id: 'add-two-fa-modal.typography.scan_qr_code_title',
        defaultMessage: 'Open the authenticator app and scan this QR code',
    },
    scanQrCodeSubtitle: {
        id: 'add-two-fa-modal.typography.scan_qr_code_subtitle',
        defaultMessage: 'Or enter this info on your app',
    },
})
