import { memo } from 'react'
import { useAssetSource } from '../useAssetSrc'
import { StrapiThemeFolders } from '~constants'

interface Props extends React.ComponentProps<'img'> {
    name: string
    themed?: boolean
    subPath?: string
}

function Component({ name, themed = false, subPath = 'assets', ...rest }: Props) {
    const src = useAssetSource({ name, themed, subPath, fallbackFolder: StrapiThemeFolders.universal })

    return <img {...rest} src={src} />
}

export const AppImg = memo(Component)
