/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Permission } from './Permission';
import {
    PermissionFromJSON,
    PermissionFromJSONTyped,
    PermissionToJSON,
} from './Permission';

/**
 * 
 * @export
 * @interface ProjectRoleUpdate
 */
export interface ProjectRoleUpdate {
    /**
     * 
     * @type {string}
     * @memberof ProjectRoleUpdate
     */
    id: string;
    /**
     * 
     * @type {Array<Permission>}
     * @memberof ProjectRoleUpdate
     */
    permissions?: Array<Permission>;
}

/**
 * Check if a given object implements the ProjectRoleUpdate interface.
 */
// @ts-ignore
export function instanceOfProjectRoleUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

// @ts-ignore
export function ProjectRoleUpdateFromJSON(json: any): ProjectRoleUpdate {
    return ProjectRoleUpdateFromJSONTyped(json, false);
}

// @ts-ignore
export function ProjectRoleUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectRoleUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'permissions': !exists(json, 'permissions') ? undefined : ((json['permissions'] as Array<any>).map(PermissionFromJSON)),
    };
}

// @ts-ignore
export function ProjectRoleUpdateToJSON(value?: ProjectRoleUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'permissions': value.permissions === undefined ? undefined : ((value.permissions as Array<any>).map(PermissionToJSON)),
    };
}

