import { assets } from '@merchant/shared/assets/nameMap'
import { CopyButton, AppSvg, EllipsisMiddle, NotificationMessage } from '@merchant/shared/components'
import { getEntries, isKeyOfObject, parseDateAndTime } from '@merchant/shared/utils'
import { Popover, Flex, Row, Col, Alert, Typography } from '@merchant/ui-kit/ant-design'
import { capitalize } from 'lodash-es'
import { FormattedMessage } from 'react-intl'
import { apiKeyPermissionsLang } from './apiKeyPermissionsLang'
import { ApiKeyMode, mode2NotificationTitle, Modes } from './const'
import { lang } from './lang'
import type { NotificationModes } from './const'
import type { FormApiKeyPermissions } from './types'
import type { Breakpoints } from '@merchant/shared/hooks'
import type { APIKey, ProjectAPIKeyPermissionID } from '~api'
import { NoValueColumnContent } from '~components'

const arrayBufferToBase64 = (arrayBuffer: ArrayBuffer) =>
    btoa(new Uint8Array(arrayBuffer).reduce((acc, byte) => acc + String.fromCharCode(byte), ''))

export async function generateApiKeyPair() {
    const keyPair = await window.crypto.subtle.generateKey(
        {
            name: 'RSASSA-PKCS1-v1_5',
            modulusLength: 2048,
            publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
            hash: { name: 'SHA-256' },
        },
        true,
        ['sign', 'verify']
    )

    const [publicKey, privateKey] = await Promise.all([
        window.crypto.subtle.exportKey('spki', keyPair.publicKey),
        window.crypto.subtle.exportKey('pkcs8', keyPair.privateKey),
    ])

    return {
        publicKey: arrayBufferToBase64(publicKey),
        privateKey: arrayBufferToBase64(privateKey),
    }
}

export function transformFormPermissionsToAPI(permissions: Partial<FormApiKeyPermissions>): APIKey['permissions'] {
    return getEntries(permissions)
        .filter(([, mode]) => !!mode)
        .map(([permission, mode]) => ({
            id: permission,
            write: mode === ApiKeyMode.Write,
        }))
}

const { Text, Paragraph } = Typography

const getNameContent = ({ active, name, id }: APIKey, breakpoints: Breakpoints) => (
    <Paragraph
        data-merchant={`${id}-name`}
        ellipsis={{
            rows: 1,
        }}
        style={{
            margin: 0,
            fontWeight: 600,
            marginRight: 10,
            maxWidth: 200,
            fontSize: breakpoints.md ? 14 : 16,
        }}
        type={active ? undefined : 'secondary'}
    >
        {name}
    </Paragraph>
)

const getPublicKeyContent = ({ publicKey, active, id, breakpoints }: APIKey & { breakpoints: Breakpoints }) => {
    const prefixCount = breakpoints.md ? 10 : 6
    let suffixCount = 6
    if (breakpoints.lg) {
        suffixCount = 30
    } else if (breakpoints.md) {
        suffixCount = 15
    }

    return (
        <div style={{ maxWidth: '450px', display: 'inline-block', textWrap: 'nowrap' }}>
            <EllipsisMiddle
                data-merchant={`${id}-public-key`}
                prefixCount={prefixCount}
                suffixCount={suffixCount}
                divider="."
                style={{
                    textWrap: 'nowrap',
                }}
                type={active ? undefined : 'secondary'}
            >
                {publicKey}
            </EllipsisMiddle>
            <CopyButton
                type="text"
                style={{ width: undefined, height: undefined, marginLeft: 4 }}
                data-merchant={`${id}-public-key-copy-button`}
                inputValue={publicKey}
                notificationContentSubject={lang.publicKey}
            />
        </div>
    )
}

export const getDateContent = (date: Date | undefined, config?: { isActive: boolean; isCompact?: boolean }) => {
    const { isActive, isCompact = false } = config || {}
    const parsedDate = date && parseDateAndTime(date)
    if (!parsedDate) {
        return NoValueColumnContent
    }

    const content = (
        <>
            <Text
                data-merchant={null}
                type={isActive || isCompact ? undefined : 'secondary'}
                style={{
                    textWrap: 'nowrap',
                }}
            >
                {parsedDate.date}
            </Text>{' '}
            {!!parsedDate.time && (
                <Text type="secondary" data-merchant={null} style={{ textWrap: 'nowrap' }}>
                    {parsedDate.time}
                </Text>
            )}
        </>
    )

    if (isCompact) {
        return content
    }

    return <Flex vertical>{content}</Flex>
}

// eslint-disable-next-line max-lines-per-function
export const getKeyContent = (apiKey: APIKey, breakpoints: Breakpoints) => {
    const items = [
        {
            key: 'name',
            label: <FormattedMessage {...lang.name} />,
            children: (
                <Paragraph
                    style={{ marginBottom: 0 }}
                    ellipsis={{ tooltip: true, rows: 2 }}
                    strong
                    data-merchant={`${apiKey.id}-api-key-popover-name`}
                >
                    {apiKey.name}
                </Paragraph>
            ),
        },
        {
            key: 'publicKey',
            label: <FormattedMessage {...lang.publicKey} />,
            children: (
                <span style={{ textAlign: 'right' }} data-merchant={`${apiKey.id}-api-key-popover-public-key`}>
                    {apiKey.publicKey}
                </span>
            ),
            extra: (
                <CopyButton
                    inputValue={apiKey.publicKey}
                    notificationContentSubject={lang.publicKey}
                    data-merchant={`${apiKey.id}-api-key-popover-public-key-copy-button`}
                />
            ),
        },
        {
            key: 'privateKey',
            label: <FormattedMessage {...lang.privateKey} />,
            children: (
                <Text type="secondary" data-merchant={`${apiKey.id}-api-key-popover-private-key`}>
                    {apiKey.privateKeyMask || '••••••••'}
                </Text>
            ),
        },
    ]

    return (
        <>
            <Popover
                data-merchant={null}
                placement="bottom"
                overlayStyle={{ maxWidth: 500 }}
                content={
                    breakpoints.md && (
                        <Flex vertical gap={10} data-merchant={`${apiKey.id}-api-key-popover`}>
                            {items.map(({ key, children, label, extra }) => (
                                <Row key={key} wrap={false} justify="end" align="top" gutter={4}>
                                    <Col span={4} style={{ minWidth: 'unset' }} flex={1}>
                                        <Text
                                            data-merchant={`${apiKey.id}-api-key-popover-${key}`}
                                            style={{ fontSize: 12 }}
                                            type="secondary"
                                        >
                                            {label}
                                        </Text>
                                    </Col>
                                    <Col
                                        span={19}
                                        style={{
                                            overflowWrap: 'anywhere',
                                            textAlign: 'right',
                                            paddingLeft: 20,
                                            fontSize: 12,
                                        }}
                                    >
                                        {children}
                                    </Col>
                                    <Col span={1}>{extra}</Col>
                                </Row>
                            ))}
                            <Alert
                                data-merchant={`${apiKey.id}-api-key-popover-alert`}
                                showIcon
                                icon={<AppSvg size={20} name={assets.alertCircle} />}
                                type="warning"
                                style={{ fontSize: 12 }}
                                message={
                                    <FormattedMessage
                                        {...lang.keyTooltipAlert}
                                        values={{
                                            strong: chunks => (
                                                <Text data-merchant={null} style={{ fontSize: 'inherit' }} strong>
                                                    {chunks}
                                                </Text>
                                            ),
                                        }}
                                    />
                                }
                            />
                        </Flex>
                    )
                }
            >
                <div style={{ width: 'fit-content' }}>
                    {getNameContent(apiKey, breakpoints)}
                    {getPublicKeyContent({ ...apiKey, breakpoints })}
                </div>
            </Popover>
        </>
    )
}

export const getSuccessNotificationMessage = (mode: Modes.delete | Modes.edit) => (
    <NotificationMessage
        title={<FormattedMessage {...mode2NotificationTitle[mode]} />}
        type="success"
        dataMerchant={`${mode}-api-key-success-notification`}
    />
)

export const isModeNotifiable = (mode: Modes | null): mode is NotificationModes =>
    mode ? mode === Modes.edit || mode === Modes.delete : false

export function getPermissionTitle(id: ProjectAPIKeyPermissionID) {
    return isKeyOfObject(id, apiKeyPermissionsLang) ? (
        <FormattedMessage {...apiKeyPermissionsLang[id]} />
    ) : (
        capitalize(id)
    )
}
