import { ModalResponsive } from '@merchant/shared/components'
import { usePromise } from '@merchant/shared/hooks'
import { FormattedMessage, useIntl } from 'react-intl'
import { ConfirmationForm } from '../ConfirmationForm'
import { lang as commonLang } from './../../lang'
import { dataMerchantPrefix } from './const'
import { lang } from './lang'
import type { ModalProps } from '@merchant/ui-kit/ant-design'
import type { Project, ProjectTeamMember, TOTPCredentials } from '~api/instances/cabinet-api'
import { projectApi } from '~api'
import { useDirtyFormCheckerControls } from '~hooks'

interface Props extends Omit<ModalProps, 'data-merchant'> {
    onSuccess: (selectedMember: ProjectTeamMember | undefined) => void
    selectedMember: ProjectTeamMember | undefined
    projectId: Project['id'] | undefined
}

export function DeleteMemberModal({ onSuccess, selectedMember, onCancel, projectId, ...props }: Props) {
    const intl = useIntl()
    const { handleCancel } = useDirtyFormCheckerControls(onCancel)
    const { send: request } = usePromise(
        (tOTPCredentials: TOTPCredentials) => {
            if (!selectedMember) {
                throw new Error(intl.formatMessage(commonLang.projectMemberNotDefined))
            }
            if (!projectId) {
                throw new Error(intl.formatMessage(commonLang.projectIdNotDefined))
            }

            return projectApi.removeProjectTeamMember({
                projectId,
                teamMemberId: selectedMember.id,
                tOTPCredentials,
            })
        },
        {
            onError: error => {
                throw error
            },
            onSuccess: () => onSuccess(selectedMember),
        }
    )

    return (
        <ModalResponsive
            data-merchant={dataMerchantPrefix}
            destroyOnClose
            title={
                <span data-merchant={`${dataMerchantPrefix}-title`}>
                    <FormattedMessage {...lang.modalTitle} />
                </span>
            }
            footer={null}
            onCancel={handleCancel}
            {...props}
        >
            <ConfirmationForm mode="delete" request={request} />
        </ModalResponsive>
    )
}
