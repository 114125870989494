import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg, EmailSupportLink } from '@merchant/shared/components'
import { Alert, Flex, Typography } from '@merchant/ui-kit/ant-design'
import { capitalize } from 'lodash-es'
import { FormattedMessage } from 'react-intl'
import { lang } from '../lang'
import type { Withdrawal } from '~api'

const { Text } = Typography

const dataMerchant = 'withdrawal-failed-alert'

export function FailureAlert({ error }: Pick<Withdrawal, 'error'>) {
    return (
        <Alert
            data-merchant={dataMerchant}
            type="error"
            style={{ marginTop: 24, padding: 16 }}
            message={
                <Flex gap={16} align="center">
                    <AppSvg name={assets.xCircle} size={20} color={token => token.colorError} />
                    <Flex vertical>
                        {error?.message && (
                            <Text data-merchant={`${dataMerchant}-error`}>{capitalize(error.message)}</Text>
                        )}
                        <Text type="secondary" data-merchant={`${dataMerchant}-support-text`}>
                            <FormattedMessage
                                {...lang.support}
                                values={{
                                    support: chunks => <EmailSupportLink>{chunks}</EmailSupportLink>,
                                }}
                            />
                        </Text>
                    </Flex>
                </Flex>
            }
        />
    )
}
