import type {
    CanceledWithdrawal,
    CompletedWithdrawal,
    ConvertedWithdrawal,
    FailedWithdrawal,
} from '~features/WithdrawalsPage/views/Withdrawal/types'
import { WithdrawalState, type Withdrawal } from '~api'

export const isCompleted = (data: Withdrawal): data is CompletedWithdrawal => {
    return data.state === WithdrawalState.Success
}

export const isFailed = (data: Withdrawal): data is FailedWithdrawal => {
    return data.state === WithdrawalState.Failed
}

export const isCancelled = (data: Withdrawal): data is CanceledWithdrawal => {
    return data.state === WithdrawalState.Canceled && !!data.canceledAt && !!data.canceledBy
}

export const isConverted = (data: Withdrawal): data is ConvertedWithdrawal => {
    return !!data.swappedAt && !!data.swapRate && !!data.swapTxnId
}
