import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    underpaymentAbsoluteCheckboxTitle: {
        id: 'absolute-underpayment.form.underpayment_amount_checkbox_title',
        defaultMessage: 'And do not recognize the invoice as completed if underpayment exceeds:',
    },
    invalidAbsoluteMinValue: {
        id: 'absolute-underpayment.form.invalid_min_value',
        defaultMessage: 'Invalid value: must be greater than 0',
    },
})
