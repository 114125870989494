import { InlineLinkButton, NotificationMessage } from '@merchant/shared/components'
import { useNotifications } from '@merchant/shared/contexts'
import { useFormValidation, usePromise } from '@merchant/shared/hooks'
import { Button, Divider, Form } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { formInitValues, formItemsKeys } from './const'
import { lang } from './lang'
import { getWithdrawalCryptoPayload } from './utils'
import type { ConfirmationFormItems } from './types'
import type { WithdrawalCryptoFormData } from '../../types'
import type { CreateWithdrawalRequest } from '~api/instances/cabinet-api'
import { authApi, projectApi, useMerchant, useProject, useWhitelist } from '~api'
import {
    RequiredError,
    GenerateVerificationCodeRequestPurpose as VerificationCodePurposes,
} from '~api/instances/cabinet-api'
import { ModalNames } from '~constants/modal'
import { EmailConfirmationItem, TwoFaConfirmationItem } from '~features/FormItems'
import { useCodeConfirmationErrorHandling } from '~features/FormItems/useCodeErrorHandling'
import { useRegisterDirtyFormChecker } from '~hooks'

const NOTIFICATION_KEY = 'withdrawalError'

interface Props {
    onSuccess: (txId: string) => void
    withdrawalFormData: WithdrawalCryptoFormData
    goBack: () => void
}

// eslint-disable-next-line max-lines-per-function
export function WithdrawalConfirmation({ onSuccess, withdrawalFormData, goBack }: Props) {
    const { data: whitelist } = useWhitelist()
    const { data: merchant } = useMerchant()
    const { notification } = useNotifications()
    const { data: { id: projectId = '' } = {} } = useProject()
    const [form] = Form.useForm()
    const { onBlur, onFinishFailed, validationMode } = useFormValidation(Object.values(formItemsKeys))
    const { handleError } = useCodeConfirmationErrorHandling({
        confirmationStrategy: { email: 'code', '2fa': 'code' },
    })

    const { send: confirmWithdrawal, isLoading } = usePromise(
        (payload: CreateWithdrawalRequest) => {
            return projectApi.createProjectWithdrawal({
                createWithdrawalRequest: payload,
                projectId,
            })
        },
        {
            onSuccess: ({ id }) => onSuccess(id),
            onError: error => handleError({ error, form }),
        }
    )
    useRegisterDirtyFormChecker(ModalNames.withdrawalCryptoConfirmation)

    const handleGoBackClick = () => {
        notification.api.destroy(NOTIFICATION_KEY)
        goBack()
    }

    const handleConfirmation = (confirmationFields: ConfirmationFormItems) => {
        try {
            const withdrawalData = getWithdrawalCryptoPayload(withdrawalFormData, confirmationFields)
            if (withdrawalData) {
                confirmWithdrawal(withdrawalData)
            }
        } catch (err) {
            if (err instanceof RequiredError) {
                notification.api.open({
                    key: NOTIFICATION_KEY,
                    message: (
                        <NotificationMessage
                            type="error"
                            title={err.message}
                            description={
                                <FormattedMessage
                                    {...lang.goBackAndTryAgain}
                                    values={{
                                        back: chunks => (
                                            <InlineLinkButton
                                                onClick={handleGoBackClick}
                                                data-merchant="withdrawal-confirmation-error-notification-back"
                                            >
                                                {chunks}
                                            </InlineLinkButton>
                                        ),
                                    }}
                                />
                            }
                            dataMerchant="withdrawal-confirmation-error-notification"
                        />
                    ),
                })
            }
        }
    }

    const handleResendClick = () =>
        authApi.generateVerificationCode({
            generateVerificationCodeRequest: {
                purpose: VerificationCodePurposes.Withdraw,
            },
        })

    return (
        <Form
            data-merchant="withdrawal-confirmation-form"
            form={form}
            layout="vertical"
            size="large"
            initialValues={formInitValues}
            onFinish={handleConfirmation}
            onFinishFailed={onFinishFailed}
        >
            {!whitelist?.enabled && (
                <>
                    <EmailConfirmationItem
                        dataMerchant="withdrawal-modal-email-confirmation-item"
                        email={merchant?.email || 'unknown email'}
                        onBlur={onBlur(formItemsKeys.verificationCode)}
                        onResendClick={handleResendClick}
                        validateTrigger={validationMode.verificationCode}
                        shouldSendOnMount
                    />
                    <Divider />
                </>
            )}
            <TwoFaConfirmationItem
                dataMerchant="withdrawal-modal-2fa-confirmation-item"
                onBlur={onBlur(formItemsKeys.totpCode)}
                validateTrigger={validationMode.totpCode}
            />
            <Form.Item noStyle>
                <Button
                    data-merchant="withdrawal-confirmation-button"
                    htmlType="submit"
                    type="primary"
                    size="large"
                    block
                    loading={isLoading}
                >
                    <FormattedMessage {...lang.confirm} />
                </Button>
            </Form.Item>
        </Form>
    )
}
