/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ListAllPermissions200ResponseGroupsInner } from './ListAllPermissions200ResponseGroupsInner';
import {
    ListAllPermissions200ResponseGroupsInnerFromJSON,
    ListAllPermissions200ResponseGroupsInnerFromJSONTyped,
    ListAllPermissions200ResponseGroupsInnerToJSON,
} from './ListAllPermissions200ResponseGroupsInner';

/**
 * 
 * @export
 * @interface ListAllPermissions200Response
 */
export interface ListAllPermissions200Response {
    /**
     * 
     * @type {Array<ListAllPermissions200ResponseGroupsInner>}
     * @memberof ListAllPermissions200Response
     */
    groups: Array<ListAllPermissions200ResponseGroupsInner>;
}

/**
 * Check if a given object implements the ListAllPermissions200Response interface.
 */
// @ts-ignore
export function instanceOfListAllPermissions200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "groups" in value;

    return isInstance;
}

// @ts-ignore
export function ListAllPermissions200ResponseFromJSON(json: any): ListAllPermissions200Response {
    return ListAllPermissions200ResponseFromJSONTyped(json, false);
}

// @ts-ignore
export function ListAllPermissions200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListAllPermissions200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groups': ((json['groups'] as Array<any>).map(ListAllPermissions200ResponseGroupsInnerFromJSON)),
    };
}

// @ts-ignore
export function ListAllPermissions200ResponseToJSON(value?: ListAllPermissions200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'groups': ((value.groups as Array<any>).map(ListAllPermissions200ResponseGroupsInnerToJSON)),
    };
}

