import type { FormItemsKey } from '@merchant/shared/types'
import type { Callback } from '~api/instances/cabinet-api'
import { ModalNames } from '~constants/modal'

export const modes = {
    [ModalNames.callbackUrlCreate]: ModalNames.callbackUrlCreate,
    [ModalNames.callbackUrlEdit]: ModalNames.callbackUrlEdit,
    [ModalNames.callbackUrlDelete]: ModalNames.callbackUrlDelete,
    [ModalNames.callbackUrlRegenerate]: ModalNames.callbackUrlRegenerate,
} as const

export const modeDataMerchantMap: Record<keyof typeof modes, string> = {
    [ModalNames.callbackUrlCreate]: 'callback-create',
    [ModalNames.callbackUrlEdit]: 'callback-edit',
    [ModalNames.callbackUrlDelete]: 'callback-delete',
    [ModalNames.callbackUrlRegenerate]: 'callback-regenerate',
}

export const callbackKeys: FormItemsKey<Callback> = {
    publicKey: 'publicKey',
    url: 'url',
    version: 'version',
}

export const integrationCommonButtonStyle: React.CSSProperties = { minWidth: 180 }
