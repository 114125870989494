import { useBreakpoint } from '@merchant/shared/hooks'
import { Tag, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'

const { Text } = Typography

export function AllCurrenciesTag() {
    const breakpoint = useBreakpoint()

    return (
        <Tag
            bordered={false}
            style={{
                textAlign: 'center',
                marginRight: 0,
                paddingBlock: breakpoint.md ? 8 : 4,
            }}
        >
            <Text
                data-merchant={null}
                style={{
                    fontWeight: 500,
                    fontSize: !breakpoint.md ? 12 : undefined,
                }}
            >
                <FormattedMessage {...lang.allCurrencies} />
            </Text>
        </Tag>
    )
}
