export const modalMobileStyles: React.CSSProperties = {
    position: 'relative',
    top: 'unset',
    padding: '10px 0',
    margin: '0 auto',
}

export const modalMobileContentStyles: React.CSSProperties = {
    boxShadow: 'none',
    padding: 16,
}

export const mobileModalWrapperStyle: React.CSSProperties = {
    display: 'grid',
    alignItems: 'flex-end',
}
