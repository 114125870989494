/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LossConfigAbsolute } from './LossConfigAbsolute';
import {
    LossConfigAbsoluteFromJSON,
    LossConfigAbsoluteFromJSONTyped,
    LossConfigAbsoluteToJSON,
} from './LossConfigAbsolute';

/**
 * 
 * @export
 * @interface LossConfig
 */
export interface LossConfig {
    /**
     * 
     * @type {number}
     * @memberof LossConfig
     */
    relative?: number;
    /**
     * 
     * @type {LossConfigAbsolute}
     * @memberof LossConfig
     */
    absolute?: LossConfigAbsolute;
}

/**
 * Check if a given object implements the LossConfig interface.
 */
// @ts-ignore
export function instanceOfLossConfig(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

// @ts-ignore
export function LossConfigFromJSON(json: any): LossConfig {
    return LossConfigFromJSONTyped(json, false);
}

// @ts-ignore
export function LossConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): LossConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'relative': !exists(json, 'relative') ? undefined : json['relative'],
        'absolute': !exists(json, 'absolute') ? undefined : LossConfigAbsoluteFromJSON(json['absolute']),
    };
}

// @ts-ignore
export function LossConfigToJSON(value?: LossConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'relative': value.relative,
        'absolute': LossConfigAbsoluteToJSON(value.absolute),
    };
}

