export enum PaymentSteps {
    Error = 'Error',
    Loading = 'Loading',
    CustomerDetails = 'CustomerDetails',
    SelectCurrency = 'SelectCurrency',
    SendingFunds = 'SendingFunds',
    PaymentProcessing = 'PaymentProcessing',
    PaymentResults = 'PaymentResults',
    NotFound = 'NotFound',
}
