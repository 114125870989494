import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    successChangeNotification: {
        id: 'merchant-page.notification.success_change',
        defaultMessage: 'Merchant has been successfully changed',
    },
    merchantCreatedModalHeader: {
        id: 'merchant-page.modal.merchant_created_header',
        defaultMessage: 'Merchant created',
    },
    merchantCreatedModalTitle: {
        id: 'merchant-page.modal.merchant_created_title',
        defaultMessage: '🥳',
    },
    merchantCreatedModalSubtitle: {
        id: 'merchant-page.modal.merchant_created_subtitle',
        defaultMessage: 'Congratulations',
    },
    merchantCreatedModalText: {
        id: 'merchant-page.modal.merchant_created_text',
        defaultMessage:
            'Your new merchant, {merchantName}, has been set up. To finalize your payment settings, choose your preferred cryptocurrencies and set a default currency for conversions.',
    },
    merchantCreatedModalButton: {
        id: 'merchant-page.modal.merchant_created_button',
        defaultMessage: 'Setup currencies settings',
    },
    step: {
        id: 'merchant-page.steps.step',
        defaultMessage: 'Step {number}:',
    },
    saveChanges: {
        id: 'merchant-page.button.save_changes',
        defaultMessage: 'Save changes',
    },
    currenciesSetupModalButton: {
        id: 'merchant-page.modal.currencies_setup_button',
        defaultMessage: 'Set up payments settings',
    },
    currenciesSetupModalText: {
        id: 'merchant-page.modal.currencies_setup_text',
        defaultMessage: 'Currency settings are completed. You can change them on this page at any time.',
    },

    stepOneTitle: {
        id: 'merchant-page.steps.step_1_title',
        defaultMessage: 'Create your merchant',
    },
    stepOneDescription: {
        id: 'merchant-page.steps.step_1_description',
        defaultMessage:
            'Start by creating your merchant profile. Fill out essential details such as your shop name, contact links, and decide who pays the transaction fee.',
    },

    stepTwoTitle: {
        id: 'merchant-page.steps.step_2_title',
        defaultMessage: 'Configure currencies',
    },
    stepTwoDescription: {
        id: 'merchant-page.steps.step_2_description',
        defaultMessage:
            'Set up your currency preferences in one go. Choose which cryptocurrencies to accept, define a default for auto-conversion, and select the ones for accumulation. Confirm these settings to activate.',
    },

    stepThreeTitle: {
        id: 'merchant-page.steps.step_3_title',
        defaultMessage: 'Configure payments settings',
    },
    stepThreeDescription: {
        id: 'merchant-page.steps.step_3_description',
        defaultMessage:
            'Then do not forget to select setting for who will cover payment fees. Set up redirect links optionally. And configure the percentage or absolute value of the underpayment, for more flexibility in your payments',
    },

    stepFourTitle: {
        id: 'merchant-page.steps.step_4_title',
        defaultMessage: 'Set up API and callback URL',
    },
    stepFourDescription: {
        id: 'merchant-page.steps.step_4_description',
        defaultMessage:
            'Generate API keys and set up the callback URL for secure and efficient transaction processing. This ensures seamless integration with your systems and is vital for real-time payment updates. Familiarize yourself with our platform by reading the <tag>API docs</tag>.',
    },

    stepFiveTitle: {
        id: 'merchant-page.steps.step_5_title',
        defaultMessage: 'Initiate a Payment',
    },
    stepFiveDescription: {
        id: 'merchant-page.steps.step_5_description',
        defaultMessage:
            'Process your first payment to test the setup. Successful transaction completion confirms that your merchant is fully operational.',
    },
    congratulationModalContentTitle: {
        id: 'currencies-settings.modal.content_title',
        defaultMessage: '🎉',
    },
    congratulationModalContentSubtitle: {
        id: 'currencies-settings.modal.content_subtitle',
        defaultMessage: 'Congratulations',
    },
    congratulationModalContentText: {
        id: 'currencies-settings.modal.content_text',
        defaultMessage: 'Currency settings are completed. You can change settings on this page at any time.',
    },
    congratulationModalButton: {
        id: 'currencies-settings.modal.ok_button',
        defaultMessage: 'Got it',
    },
    noChanges: {
        id: 'merchant-page.notification.no_changes',
        defaultMessage: 'No changes have been made',
    },
})
