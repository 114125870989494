import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    writeDownBackupTitle: {
        id: 'add-two-fa-modal.typography.write_down_backup_code_title',
        defaultMessage: 'It will be the only way to sign in to your account in case you lose your device.',
    },
    backupCode: {
        id: 'add-two-fa-modal.typography.backup_code',
        defaultMessage: 'Backup code',
    },
    haveWrittenBackup: {
        id: 'add-two-fa-modal.checkbox.i_have_written_backup_code',
        defaultMessage: 'I have written down the backup code',
    },
})
