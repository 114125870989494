import { Col, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { useBoolean } from 'usehooks-ts'
import { lang } from './lang'
import { getEllipsisTooltip, getPaymentTitle } from './utils'
import type { PaymentBase } from './types'
import type { MouseEventHandler } from 'react'
import type { Breakpoints } from '~hooks'
import { InlineLinkButton } from '~components/InlineLinkButton'

const { Title, Paragraph } = Typography

interface Props {
    dataMerchantPrefix: string
    breakpoints: Breakpoints
    itemTitle: string | undefined
    itemDescription: string | undefined
    handleReadMoreClick: MouseEventHandler<HTMLButtonElement>
    paymentData: PaymentBase
    amountTextValue: string | JSX.Element | undefined
}

export function LeftCol({
    dataMerchantPrefix,
    breakpoints,
    itemTitle,
    itemDescription,
    handleReadMoreClick,
    paymentData,
    amountTextValue,
}: Props) {
    const { value: isDescriptionTruncated, setValue: setIsDescriptionTruncated } = useBoolean(false)
    const leftColItemTitle = itemTitle ? getPaymentTitle(paymentData) : amountTextValue

    return (
        <Col>
            <Title
                data-merchant={`${dataMerchantPrefix}-payment-title`}
                level={4}
                ellipsis={{
                    rows: 2,
                    tooltip: getEllipsisTooltip(breakpoints, leftColItemTitle, `${dataMerchantPrefix}-title-ellipsis`),
                }}
            >
                {leftColItemTitle}
            </Title>
            <Paragraph
                data-merchant={`${dataMerchantPrefix}-payment-description`}
                style={{ marginBlock: 0 }}
                type="secondary"
                ellipsis={{
                    rows: 4,
                    onEllipsis: setIsDescriptionTruncated,
                    tooltip: getEllipsisTooltip(
                        breakpoints,
                        itemDescription,
                        `${dataMerchantPrefix}-payment-description-ellipsis`
                    ),
                    expandable: 'collapsible',
                    symbol: breakpoints.xs && isDescriptionTruncated && (
                        <InlineLinkButton
                            data-merchant={`${dataMerchantPrefix}-read-more-button`}
                            onClick={handleReadMoreClick}
                            style={{ verticalAlign: 'unset', textDecoration: 'underline' }}
                            key="read more"
                        >
                            <FormattedMessage {...lang.readMore} />
                        </InlineLinkButton>
                    ),
                }}
            >
                {itemDescription}
            </Paragraph>
        </Col>
    )
}
