import { Typography } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'

const { Text } = Typography

export function FiatWithdrawalNotes() {
    return (
        <Text data-merchant={null}>
            <FormattedMessage {...lang.withdrawalFiat} />
        </Text>
    )
}
