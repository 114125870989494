import { CopyButton, EllipsisMiddle, TxHash } from '@merchant/shared/components'
import { parseDateAndTime } from '@merchant/shared/utils'
import { Flex, Table, Tag, Typography } from '@merchant/ui-kit/ant-design'
import { capitalize } from 'lodash-es'
import { FormattedMessage } from 'react-intl'
import { withdrawalStateTagMap } from '../../const'
import { lang } from '../../lang'
import { commonFontStyles } from './const'
import styles from './style.module.css'
import type { TableProps } from '@merchant/ui-kit/ant-design'
import type { ColumnsType } from '@merchant/ui-kit/ant-design/es/table'
import type { Currencies } from '~api/types'
import { WithdrawalsSorting, type Network, useCurrencies, type WithdrawalThumb } from '~api'
import { NoValueColumnContent } from '~components'
import { globalLang } from '~globalLang'
import { useConditionalTabOpen, useNetworksMap } from '~hooks'

const tableRowStyle: React.CSSProperties = { cursor: 'pointer' }

const { Text } = Typography

type GetColumns = (params: {
    currencies: Currencies | undefined
    sortColumnKey: WithdrawalsSorting
    networks: Record<string, Network>
}) => ColumnsType<WithdrawalThumb>

// eslint-disable-next-line max-lines-per-function
const getColumns: GetColumns = ({ currencies, sortColumnKey, networks }) => {
    return [
        {
            title: <FormattedMessage {...lang.createdAt} />,
            key: WithdrawalsSorting.CreatedAt,
            dataIndex: 'createdAt',
            render: (date: WithdrawalThumb['createdAt']) => {
                const parsedDate = parseDateAndTime(date)

                return (
                    <Flex vertical>
                        <Text style={{ ...commonFontStyles }} data-merchant="withdrawals-table-created-date">
                            {parsedDate?.date}
                        </Text>
                        <Text
                            style={{ ...commonFontStyles }}
                            type="secondary"
                            data-merchant="withdrawals-table-created-time"
                        >
                            {parsedDate?.time}
                        </Text>
                    </Flex>
                )
            },
            width: 70,
            sorter: true,
            sortDirections: ['descend'],
            sortOrder: sortColumnKey === WithdrawalsSorting.CreatedAt ? 'descend' : undefined,
        },
        {
            title: <FormattedMessage {...lang.updatedAt} />,
            key: WithdrawalsSorting.UpdatedAt,
            dataIndex: 'updatedAt',
            render: (date: WithdrawalThumb['updatedAt']) => {
                const parsedDate = parseDateAndTime(date)

                return (
                    <Flex vertical>
                        <Text style={{ ...commonFontStyles }} data-merchant="withdrawals-table-updated-date">
                            {parsedDate?.date}
                        </Text>
                        <Text
                            style={{ ...commonFontStyles }}
                            type="secondary"
                            data-merchant="withdrawals-table-updated-time"
                        >
                            {parsedDate?.time}
                        </Text>
                    </Flex>
                )
            },
            width: 70,
            sorter: true,
            sortDirections: ['descend'],
            sortOrder: sortColumnKey === WithdrawalsSorting.UpdatedAt ? 'descend' : undefined,
        },
        {
            title: <FormattedMessage {...lang.status} />,
            dataIndex: 'state',
            render: (state: WithdrawalThumb['state']) => (
                <Tag bordered={false} style={{ padding: '5px 6px', margin: 0 }} color={withdrawalStateTagMap[state]}>
                    {capitalize(state)}
                </Tag>
            ),
            width: 70,
        },
        {
            title: <FormattedMessage {...lang.id} />,
            dataIndex: 'hash',
            render: (_, { id, hash, receiveCurrency, receiveNetwork }) => (
                <Flex vertical>
                    <Text
                        style={{ ...commonFontStyles }}
                        data-merchant="withdrawals-table-id"
                        ellipsis={{
                            tooltip: (
                                <>
                                    <Text data-merchant={null}>{id}</Text>
                                    &nbsp;
                                    <CopyButton
                                        data-merchant={`withdrawals-table-copy-hash-button-${id}`}
                                        inputValue={id}
                                    />
                                </>
                            ),
                        }}
                    >
                        {id}
                    </Text>
                    {hash && (
                        <Flex align="center">
                            <TxHash
                                style={commonFontStyles}
                                hash={hash}
                                currencyCode={receiveCurrency}
                                networkCode={receiveNetwork}
                                currencies={currencies}
                                shouldShowPrefix
                                ellipsis={{ rows: 1 }}
                                data-merchant="withdrawals-table-hash"
                            />
                            <CopyButton
                                data-merchant="transaction-hash-copy-button"
                                inputValue={hash}
                                notificationContentSubject={globalLang.hash}
                            />
                        </Flex>
                    )}
                </Flex>
            ),
            width: 70,
        },
        {
            title: <FormattedMessage {...lang.orderId} />,
            dataIndex: 'orderId',
            render: (id: WithdrawalThumb['orderId']) => (
                <Flex vertical>
                    <Text
                        style={{ ...commonFontStyles }}
                        data-merchant="withdrawals-table-orderId"
                        ellipsis={{
                            tooltip: (
                                <>
                                    <Text data-merchant={null}>{id}</Text>
                                    &nbsp;
                                    <CopyButton
                                        data-merchant={`withdrawals-table-copy-id-button-${id}`}
                                        inputValue={id || ''}
                                    />
                                </>
                            ),
                        }}
                    >
                        {id || NoValueColumnContent}
                    </Text>
                </Flex>
            ),
            width: 70,
        },
        {
            title: <FormattedMessage {...lang.creditAmount} />,
            dataIndex: 'creditAmount',
            render: (amount: WithdrawalThumb['creditAmount'], { creditCurrency }) => (
                <Flex vertical>
                    <Text style={{ ...commonFontStyles }} data-merchant="withdrawals-table-creditAmount">
                        -{amount} {creditCurrency}
                    </Text>
                </Flex>
            ),
            width: 70,
        },
        {
            title: <FormattedMessage {...lang.receiveAmount} />,
            dataIndex: 'receiveAmount',
            render: (_, { receiveAmount, receiveCurrency, receiveNetwork }) => (
                <Flex vertical>
                    <Text style={{ ...commonFontStyles }} data-merchant="withdrawals-table-receiveAmount">
                        {receiveAmount} {receiveCurrency}
                    </Text>
                    {receiveNetwork && (
                        <Text
                            style={{ ...commonFontStyles }}
                            type="secondary"
                            data-merchant="withdrawals-table-receiveNetwork"
                        >
                            {networks[receiveNetwork]
                                ? `${networks[receiveNetwork].name} ${networks[receiveNetwork].protocol}`
                                : receiveNetwork}
                        </Text>
                    )}
                </Flex>
            ),
            width: 70,
        },
        {
            title: <FormattedMessage {...lang.addressAndTag} />,
            dataIndex: 'address',
            render: (_, { address, addressTag }) => (
                <Flex vertical>
                    <EllipsisMiddle
                        prefixCount={4}
                        suffixCount={4}
                        divider="."
                        style={{ ...commonFontStyles }}
                        data-merchant="withdrawals-table-address"
                        showTooltip
                        copyableText={address}
                    >
                        {address}
                    </EllipsisMiddle>
                    {addressTag && (
                        <EllipsisMiddle
                            style={{ ...commonFontStyles }}
                            type="secondary"
                            prefixCount={4}
                            suffixCount={4}
                            data-merchant="withdrawals-table-addressTag"
                            showTooltip
                            copyableText={addressTag}
                        >
                            {addressTag}
                        </EllipsisMiddle>
                    )}
                </Flex>
            ),
            width: 70,
        },
    ]
}

interface Props extends TableProps<WithdrawalThumb> {
    data: WithdrawalThumb[]
    sortColumnKey: WithdrawalsSorting
    setSortColumnKey: (key: WithdrawalsSorting) => void
}

export function WithdrawalsTable({ data, setSortColumnKey, sortColumnKey, ...rest }: Props) {
    const { data: currencies } = useCurrencies()
    const { networks } = useNetworksMap()
    const { handleConditionalTabOpen } = useConditionalTabOpen()

    const onTableChange: TableProps<WithdrawalThumb>['onChange'] = (_, __, sorter) => {
        if (!Array.isArray(sorter)) {
            if (
                typeof sorter.columnKey === 'string' &&
                sorter.order &&
                Object.values<string>(WithdrawalsSorting).includes(sorter.columnKey)
            ) {
                setSortColumnKey(sorter.columnKey as WithdrawalsSorting)
            }
        }
    }

    return (
        <Table<WithdrawalThumb>
            className={styles.table}
            showSorterTooltip={false}
            pagination={false}
            tableLayout="fixed"
            dataSource={data}
            onChange={onTableChange}
            columns={getColumns({ currencies, sortColumnKey, networks })}
            onRow={({ id }) => {
                const clickHandler = handleConditionalTabOpen([({ pathname }) => `${pathname}/${id}`, id])

                return {
                    style: tableRowStyle,
                    'data-merchant': `${id}-withdrawals-table-row`,
                    onClick: clickHandler,
                    onAuxClick: clickHandler,
                }
            }}
            {...rest}
        />
    )
}
