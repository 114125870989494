import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg, CopyButton, InlineLinkButton, StatisticFormattedNumber } from '@merchant/shared/components'
import { Col, Row, Typography } from '@merchant/ui-kit/ant-design'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { OrderCard } from './Card'
import { orderRowsKeys } from './const'
import { lang } from './lang'
import { getHiddenRows } from './utils'
import type { DetailsItem } from './types'
import type { Payment } from '~api/instances/cabinet-api'
import { NoValueColumnContent, TooltipTypography } from '~components'

const { Title, Text } = Typography

const getOrderDetails = (data: Payment | undefined): DetailsItem[] => [
    { type: 'divider' },
    {
        key: orderRowsKeys.url,
        rowTitle: (
            <Text style={{ display: 'flex', alignItems: 'center', gap: 6 }} data-merchant="order-url">
                <FormattedMessage {...lang.url} />
                {data?.paymentUrl && (
                    <InlineLinkButton
                        href={data.paymentUrl}
                        target="_blank"
                        data-merchant="order-details-open-url-button"
                        icon={<AppSvg size={16} name={assets.share} />}
                    />
                )}
            </Text>
        ),
        rightCol: {
            span: 18,
            content: data?.paymentUrl ? (
                <div style={{ display: 'flex', gap: 4 }}>
                    <Text ellipsis data-merchant="order-details-url-link">
                        {data.paymentUrl}
                    </Text>
                    <CopyButton
                        notificationContentSubject={lang.url}
                        inputValue={data.paymentUrl}
                        type="text"
                        data-merchant="order-details-copy-url-button"
                    />
                </div>
            ) : (
                NoValueColumnContent
            ),
        },
    },
    {
        key: orderRowsKeys.customerEmail,
        rowTitle: <FormattedMessage {...lang.clientEmail} />,
        rightCol: {
            content: data?.details.customerEmail,
        },
    },
    {
        key: orderRowsKeys.customerId,
        rowTitle: <FormattedMessage {...lang.clientId} />,
        rightCol: {
            content: data?.details.customerId,
        },
    },
]

type Props = {
    isLoading: boolean
    data?: Payment
}

export function OrderDetailsCard({ isLoading, data }: Props) {
    const intl = useIntl()

    const orderDetails = useMemo(() => {
        const hiddenRows = getHiddenRows(data)

        return getOrderDetails(data).filter(
            row => row.type !== 'divider' && !hiddenRows?.find(hiddenRow => hiddenRow === row.key)
        )
    }, [data])

    const isDeposit = data?.type === 'DEPOSIT'
    const { title, description } = data?.details || {}

    const orderTitle = title || intl.formatMessage(lang.noOrderTitle)
    const orderDescription = description || intl.formatMessage(lang.noOrderDescription)

    return (
        <OrderCard
            isTitleLoading={isLoading}
            cardTitle={
                <Row justify="space-between" wrap={false} style={{ marginBottom: '-8px' }}>
                    <Col flex="auto">
                        <Title
                            level={4}
                            type={title ? undefined : 'secondary'}
                            style={{ opacity: !title ? 0.5 : 1 }}
                            data-merchant="order-title"
                        >
                            {orderTitle}
                        </Title>
                        <TooltipTypography
                            style={{
                                marginBottom: 0,
                                opacity: !description ? 0.5 : 1,
                            }}
                            component="Paragraph"
                            ellipsis={{ rows: 3 }}
                            type="secondary"
                            data-merchant="order-description"
                        >
                            {orderDescription}
                        </TooltipTypography>
                    </Col>
                    <Col flex="none">
                        {isDeposit ? (
                            <Title level={3} data-merchant="order-deposit-to-currency">
                                <FormattedMessage {...lang.depositTo} values={{ currency: data.nominalCurrency }} />
                            </Title>
                        ) : (
                            <>
                                <StatisticFormattedNumber
                                    value={data?.nominalAmount}
                                    suffix={data?.nominalCurrency}
                                    valueStyle={{ fontSize: 24 }}
                                />
                            </>
                        )}
                    </Col>
                </Row>
            }
            rowsCount={3}
            loading={isLoading}
            detailsItems={orderDetails}
        />
    )
}
