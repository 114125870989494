import { uniqBy } from 'lodash-es'
import type { ColumnFilterItem } from '@merchant/ui-kit/ant-design/es/table/interface'
import type { ReactNode } from 'react'
import type { WhitelistAddress } from '~api/instances/cabinet-api'

export function defaultStringSorter(field: keyof WhitelistAddress) {
    return (a: WhitelistAddress, b: WhitelistAddress) => {
        const aField = a[field] || ''
        const bField = b[field] || ''

        return aField.localeCompare(bField)
    }
}

export const getFilterData = <T extends object>(
    data: T[],
    field: keyof T,
    renderText: (item: T) => ReactNode
): ColumnFilterItem[] | undefined =>
    uniqBy(
        data
            .filter(item => item[field])
            .map(item => ({
                text: renderText(item),
                value: String(item[field]),
            })),
        item => item.value
    )
