import axios from 'axios'
import type { GetCommonAppSettings, GetLocale, LocaleMessagesMap, AppSettings } from './types'

const hostUrl = import.meta.env.VITE_APPLICATION_URL || ''
const timeoutBeforeAbortion = 5000 // 5 sec

const instance = axios.create({
    baseURL: `${hostUrl}/api/1`,
})

const requestWrapper = async <T>(request: () => Promise<T>) => {
    // We are not running our node server locally yet. this leads to all requests failing
    // If you find yourself in need to use the server locally, please comment out the following block
    if (import.meta.env.MODE === 'development') {
        throw new Error('Server is not running locally. Please start the server and try again.')
    }

    return request()
}

export const getLocaleMessages: GetLocale = async locale => {
    try {
        // we don't want to cancel this request after timeout because user would see texts with placeholders
        // which is not ideal
        const response = await requestWrapper<LocaleMessagesMap>(
            async () => (await instance.get<LocaleMessagesMap>(`/locale?locale=${locale}`)).data
        )

        return response
    } catch {
        return {}
    }
}

export const getCommonAppSettings: GetCommonAppSettings = async () => {
    try {
        // we want request to fail and use fallback if response is not received in specified amount of time
        // because long hanging request would prevent app from bootstrapping
        const response = await requestWrapper<AppSettings>(
            async () => (await instance.get<AppSettings>('/settings', { timeout: timeoutBeforeAbortion })).data
        )

        return response
    } catch {
        return
    }
}

export const getSupportedLocales = async ({ context }: { context: 'checkout' | 'cabinet' }): Promise<string[]> => {
    // locales are only available in checkout yet, thus we dont need to fetch supported locales on any other route
    if (context === 'cabinet') {
        return []
    }

    try {
        const response = await requestWrapper(
            async () => (await instance.get<string[]>('/locale/supported-locales')).data
        )

        return response
    } catch {
        return []
    }
}
