import { useCommonAppSettings } from '@merchant/shared/api'
import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { getNetworksOrderedByCMS } from '@merchant/shared/utils'
import { Alert, Divider, Flex, Select, Tooltip, Typography, type SelectProps } from '@merchant/ui-kit/ant-design'
import { isEmpty } from 'lodash-es'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import { getSelectNetworkTooltipTitle, splitNetworksByPayout } from './utils'
import type { WithdrawalCurrency } from '~features/ActionModal/types'
import style from '~styles/select-groups.style.module.css'
import { getNetworkLabel, mapNetworksToOptions } from '~utils'

const { Text } = Typography

interface NetworkSelectProps extends SelectProps {
    selectedCurrencyData?: WithdrawalCurrency
}

export function NetworkSelect({ selectedCurrencyData, disabled, ...rest }: NetworkSelectProps) {
    const { data: siteSettings, isLoading: isLoadingNetworksOrder } = useCommonAppSettings()

    const { availableNetworks, disabledNetworks } = splitNetworksByPayout(selectedCurrencyData)

    const { networksOrder } = siteSettings || {}
    const availableNetworksOrderedByCMS = getNetworksOrderedByCMS(availableNetworks, networksOrder)

    const networks = [
        {
            options: mapNetworksToOptions({
                networks: availableNetworksOrderedByCMS,
                dataMerchantPrefix: 'withdrawal',
            }),
        },
        {
            disabled: true,
            options: mapNetworksToOptions({
                networks: disabledNetworks,
                dataMerchantPrefix: 'withdrawal',
                config: { isDisabledPredicate: () => true },
            }),
            label: isEmpty(disabledNetworks) ? null : <Divider style={{ marginBlock: 4 }} />,
        },
    ]

    const isNetworkRequired = !!selectedCurrencyData && !isEmpty(availableNetworks)

    return (
        <Tooltip
            data-merchant={null}
            mouseEnterDelay={0.2}
            title={getSelectNetworkTooltipTitle({ isCurrencySelected: !!selectedCurrencyData, isNetworkRequired })}
        >
            <Select
                popupClassName={style.popup}
                disabled={isEmpty(availableNetworksOrderedByCMS) || disabled || !isNetworkRequired}
                loading={isLoadingNetworksOrder}
                labelRender={({ value }) => (
                    <Flex align="center">
                        <Text data-merchant={null} type="secondary">
                            <FormattedMessage {...lang.networkPrefix} />
                            &nbsp;
                        </Text>
                        {getNetworkLabel({
                            network: selectedCurrencyData?.networks.find(({ code }) => code === value),
                            isListItem: false,
                            isDisabled: false,
                        })}
                    </Flex>
                )}
                dropdownRender={menu => (
                    <>
                        <Alert
                            data-merchant="withdrawal-network-select-alert"
                            type="warning"
                            showIcon
                            icon={<AppSvg name={assets.alertCircle} size={20} />}
                            message={
                                <Text data-merchant={null} type="secondary" style={{ fontSize: 12 }}>
                                    <FormattedMessage {...lang.verifyNetworkAlert} />
                                </Text>
                            }
                            style={{ margin: '8px 12px' }}
                        />
                        {menu}
                    </>
                )}
                allowClear
                options={networks}
                {...rest}
            />
        </Tooltip>
    )
}
