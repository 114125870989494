import { Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from '../../lang.ts'
import { BasicSettings } from './components'
import { OrderSettingsType } from './const.ts'
import styles from './style.module.css'
import type { CollapseProps, GlobalToken } from '@merchant/ui-kit/ant-design'

const { Text } = Typography

const collapseItemStepStyle: React.CSSProperties = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid',
    color: '#0f0',
    borderRadius: '50%',
    width: 24,
    height: 24,
    marginRight: 12,
}

export const getCollapseItems = (token: GlobalToken): CollapseProps['items'] => {
    return [
        {
            key: OrderSettingsType.BasicSettings,
            headerClass: styles.topHeaderClass,
            label: (
                <>
                    <Text
                        type="secondary"
                        style={{
                            ...collapseItemStepStyle,
                            color: token.colorPrimary,
                        }}
                        data-merchant="order-settings-basic"
                    >
                        {OrderSettingsType.BasicSettings}
                    </Text>
                    <Text
                        style={{ fontSize: 16, fontWeight: 500 }}
                        data-merchant="static-address-settings-collapse-expander"
                    >
                        <FormattedMessage {...lang.basicSettings} />
                    </Text>
                </>
            ),
            children: <BasicSettings />,
        },
    ]
}
