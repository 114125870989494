import { Skeleton, Steps, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { useCurrentStep } from './hooks/useCurrentStep'
import { lang } from './lang'
import { getStepItemDataMerchant } from './utils'
import type { StepProps } from '@merchant/ui-kit/ant-design'
import type { IntlShape } from 'react-intl'
import { globalLang } from '~globalLang'

const { Text } = Typography

// eslint-disable-next-line max-lines-per-function
const getStepsItems = ({
    step,
    intl,
}: {
    step: number
    intl: IntlShape
}): (StepProps & { 'data-merchant': string })[] => {
    const hasFinishedOnboarding = step === 5

    return [
        {
            title: (
                <Text data-merchant={null}>
                    {!hasFinishedOnboarding && (
                        <>
                            <FormattedMessage {...lang.step} values={{ number: 1 }} />{' '}
                        </>
                    )}
                    <FormattedMessage {...lang.stepOneTitle} />
                </Text>
            ),
            description: <FormattedMessage {...lang.stepOneDescription} />,
            'data-merchant': getStepItemDataMerchant(1, step),
        },
        {
            title: (
                <Text data-merchant={null}>
                    {!hasFinishedOnboarding && (
                        <>
                            <FormattedMessage {...lang.step} values={{ number: 2 }} />{' '}
                        </>
                    )}
                    <FormattedMessage {...lang.stepTwoTitle} />
                </Text>
            ),
            description: <FormattedMessage {...lang.stepTwoDescription} />,
            'data-merchant': getStepItemDataMerchant(2, step),
        },
        {
            title: (
                <Text data-merchant={null}>
                    {!hasFinishedOnboarding && (
                        <>
                            <FormattedMessage {...lang.step} values={{ number: 3 }} />{' '}
                        </>
                    )}
                    <FormattedMessage {...lang.stepThreeTitle} />
                </Text>
            ),
            description: (
                <FormattedMessage
                    {...lang.stepThreeDescription}
                    values={{
                        tag: chunks => (
                            <Link target="_blank" to={intl.formatMessage(globalLang.docsUrl)}>
                                {chunks}
                            </Link>
                        ),
                    }}
                />
            ),
            'data-merchant': getStepItemDataMerchant(3, step),
        },
        {
            title: (
                <Text data-merchant={null}>
                    {!hasFinishedOnboarding && (
                        <>
                            <FormattedMessage {...lang.step} values={{ number: 4 }} />{' '}
                        </>
                    )}
                    <FormattedMessage {...lang.stepFourTitle} />
                </Text>
            ),
            description: (
                <FormattedMessage
                    {...lang.stepFourDescription}
                    values={{
                        tag: chunks => (
                            <Link target="_blank" to={intl.formatMessage(globalLang.docsUrl)}>
                                {chunks}
                            </Link>
                        ),
                    }}
                />
            ),
            'data-merchant': getStepItemDataMerchant(4, step),
        },
        {
            title: (
                <Text data-merchant={null}>
                    {!hasFinishedOnboarding && (
                        <>
                            <FormattedMessage {...lang.step} values={{ number: 5 }} />{' '}
                        </>
                    )}
                    <FormattedMessage {...lang.stepFiveTitle} />
                </Text>
            ),
            description: <FormattedMessage {...lang.stepFiveDescription} />,
            'data-merchant': getStepItemDataMerchant(5, step),
        },
    ]
}

interface Props {
    isLoadingProject: boolean
}

export function SetupSteps({ isLoadingProject }: Props) {
    const intl = useIntl()
    const { step, isLoadingStep } = useCurrentStep()
    const stepsItems = getStepsItems({ step, intl })

    if (isLoadingProject || isLoadingStep) {
        return (
            <>
                {Array.from({ length: stepsItems.length }).map((_, index) => (
                    <Skeleton
                        style={{ marginBottom: 10 }}
                        loading
                        active
                        avatar
                        paragraph={{ style: { marginTop: 10 } }}
                        key={index}
                    />
                ))}
            </>
        )
    }

    return <Steps data-merchant="merchant-steps-block" items={stepsItems} direction="vertical" current={step} />
}
