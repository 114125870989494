import { lang } from './lang'
import type { MessageDescriptor } from 'react-intl'

export const enum Steps {
    install,
    scanQrCode,
    newTwoFaCode,
    writeDown,
    confirmation,
}

export const modalLangTitleStepMap: Record<Steps, MessageDescriptor> = {
    [Steps.install]: lang.installAppTitle,
    [Steps.scanQrCode]: lang.scanQrCodeTitle,
    [Steps.newTwoFaCode]: lang.newTwoFaCodeTitle,
    [Steps.writeDown]: lang.writeBackupCodeTitle,
    [Steps.confirmation]: lang.confirmationTitle,
}
