import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    noRowsTitle: {
        id: 'empty-table.no_rows_title',
        defaultMessage: '👀',
    },
    noRowsSubtitle: {
        id: 'empty-table.no_rows_subtitle',
        defaultMessage: 'No data',
    },
})
