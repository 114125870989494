import { CopyButton, StatisticFormattedNumber } from '@merchant/shared/components'
import { parseDateAndTime } from '@merchant/shared/utils'
import { Badge, Flex, Tag, Typography } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { lang as commonLang } from '../../../lang'
import { lang } from '../lang'
import { OrderDetailsColumnValue } from '../OrderDetailsColumnValue'
import type { PaymentState, PaymentThumb } from '~api/instances/cabinet-api'
import type { Currencies } from '~api/types'
import { NoValueColumnContent } from '~components'
import { orderStatusMessageDescriptor, orderStatusTagMap } from '~features/OrdersPage/const'
import { getCurrencyNetworkIfHasMultiple } from '~features/OrdersPage/utils'

const { Text, Paragraph } = Typography

const tagStyles: React.CSSProperties = { padding: '2px 6px', marginRight: 0 }
const paragraphStyles: React.CSSProperties = { marginBottom: 0 }
const columnContentStyles: React.CSSProperties = { fontSize: 12 }

export const columnsMap = {
    created: {
        title: <FormattedMessage {...lang.created} />,
        render: (value: PaymentThumb['createdAt'], direction: 'vertical' | 'horizontal' = 'vertical') => {
            const parsedDate = parseDateAndTime(value)
            if (!parsedDate) {
                return NoValueColumnContent
            }

            return (
                <Flex vertical={direction === 'vertical'} gap={2}>
                    <Paragraph
                        style={{ ...paragraphStyles, ...columnContentStyles }}
                        data-merchant="orders-table-created-date"
                    >
                        {parsedDate.date}
                    </Paragraph>
                    {!!parsedDate.time && (
                        <Text type="secondary" style={columnContentStyles} data-merchant="orders-table-created-time">
                            {parsedDate.time}
                        </Text>
                    )}
                </Flex>
            )
        },
    },
    updated: {
        title: <FormattedMessage {...lang.lastUpdated} />,
        render: (value: PaymentThumb['updatedAt'], direction: 'vertical' | 'horizontal' = 'vertical') => {
            const parsedDate = parseDateAndTime(value)
            if (!parsedDate) {
                return NoValueColumnContent
            }

            return (
                <Flex vertical={direction === 'vertical'} gap={2}>
                    <Paragraph
                        style={{ ...paragraphStyles, ...columnContentStyles }}
                        data-merchant="orders-table-updated-date"
                    >
                        {parsedDate.date}
                    </Paragraph>
                    {!!parsedDate.time && (
                        <Text type="secondary" style={columnContentStyles} data-merchant="orders-table-updated-time">
                            {parsedDate.time}
                        </Text>
                    )}
                </Flex>
            )
        },
    },
    statusShort: {
        title: null as string | null, // Assign a value to the title property
        render: (state: PaymentState) => (
            <Badge
                status={orderStatusTagMap[state]}
                dot
                styles={{ indicator: { width: 10, height: 10 } }}
                data-merchant="order-state-badge"
            />
        ),
    },
    status: {
        title: <FormattedMessage {...lang.status} />,
        render: (state: PaymentState) => {
            return (
                <Tag style={tagStyles} color={orderStatusTagMap[state]} bordered={false}>
                    <FormattedMessage {...orderStatusMessageDescriptor[state]} />
                </Tag>
            )
        },
    },
    source: {
        title: <FormattedMessage {...commonLang.source} />,
        render: (origin: PaymentThumb['origin']) => origin || NoValueColumnContent,
    },
    orderId: {
        title: <FormattedMessage {...lang.orderId} />,
        render: (id: PaymentThumb['orderId']) =>
            id ? (
                <Text
                    data-merchant={`orders-table-orderId-${id}`}
                    ellipsis={{
                        tooltip: {
                            placement: 'bottom',
                            overlayInnerStyle: { padding: 0 },
                            title: (
                                <Flex align="center" onClick={e => e.stopPropagation()} style={{ padding: 12 }}>
                                    {id}
                                    <CopyButton
                                        data-merchant="order-id-copy-button"
                                        inputValue={id}
                                        notificationContentSubject={lang.orderId}
                                    />
                                </Flex>
                            ),
                            overlayStyle: { fontSize: 12 },
                            'data-merchant': 'order-id-tooltip',
                        },
                    }}
                >
                    {id}
                </Text>
            ) : (
                NoValueColumnContent
            ),
    },
    merchantOrderId: {
        title: <FormattedMessage {...lang.merchantOrderId} />,
        render: (orderId: PaymentThumb['orderId']) => {
            return orderId || NoValueColumnContent
        },
    },
    details: {
        title: <FormattedMessage {...lang.orderDetails} />,
        render: (payment: PaymentThumb, { isTruncated }: { isTruncated: boolean } = { isTruncated: true }) => {
            if (isTruncated) {
                return <OrderDetailsColumnValue data={payment} />
            }

            if (payment.title || payment.description) {
                return (
                    <>
                        {payment.title}
                        <br />
                        <Text data-merchant={null} type="secondary">
                            {payment.description}
                        </Text>
                    </>
                )
            }

            return NoValueColumnContent
        },
    },
    customerEmail: {
        title: <FormattedMessage {...lang.clientEmail} />,
        render: (email: PaymentThumb['customerEmail']) => {
            return email
        },
    },
    customerId: {
        title: <FormattedMessage {...lang.clientId} />,
        render: (customerId: PaymentThumb['customerId']) => {
            return customerId
        },
    },
    price: {
        title: <FormattedMessage {...lang.price} />,
        render: (payment: PaymentThumb) => {
            if (!Number(payment.nominalAmount)) {
                return NoValueColumnContent
            }

            return <StatisticFormattedNumber value={payment.nominalAmount} suffix={payment.nominalCurrency} />
        },
    },
    payAmount: {
        title: <FormattedMessage {...lang.payAmount} />,
        render: (currencies: Currencies | undefined) => (payment: PaymentThumb) => {
            if (!Number(payment.paidAmount)) {
                return NoValueColumnContent
            }

            const paidNetwork = getCurrencyNetworkIfHasMultiple(payment.paidCurrency, payment.paidNetwork, currencies)

            return (
                <Flex vertical>
                    <StatisticFormattedNumber value={payment.paidAmount} suffix={payment.paidCurrency} />
                    {paidNetwork && (
                        <Text type="secondary" style={{ fontSize: 12 }} data-merchant="orders-table-pay-network">
                            {paidNetwork.name} {paidNetwork.protocol}
                        </Text>
                    )}
                </Flex>
            )
        },
    },
    receivedAmount: {
        title: <FormattedMessage {...lang.received} />,
        render: (payment: PaymentThumb) => {
            if (!Number(payment.receivedAmount)) {
                return NoValueColumnContent
            }

            return (
                <Text type="success" data-merchant="orders-table-receive-amount">
                    <StatisticFormattedNumber value={payment.receivedAmount} suffix={payment.holdingCurrency} />
                </Text>
            )
        },
    },
}
