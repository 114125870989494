import { useEffect, useTransition } from 'react'
import { useSearchParams } from 'react-router-dom'

interface Props<T> {
    key: string
    pages: T
    persistentSearchParams?: boolean
}

export function useURLSearchNavigation<T extends string[]>({
    key,
    pages,
    persistentSearchParams = false,
}: Props<T>): {
    page: T[number]
    changePage: (page: T[number]) => void
    isPending: boolean
} {
    const [isPending, startTransition] = useTransition()
    const [searchParams, setSearchParams] = useSearchParams()
    const pageNameFromQuery = searchParams.get(key) ?? ''

    const changePage = (page: string) => {
        setTimeout(() =>
            startTransition(() => {
                if (persistentSearchParams === true) {
                    searchParams.set(key, page)
                } else {
                    searchParams.forEach((_, key) => searchParams.delete(key))
                    searchParams.set(key, page)
                }
                setSearchParams(searchParams)
            })
        )
    }

    // Default to the first page if no query param is set or fount in the pages list
    const page: T[number] = pages.includes(pageNameFromQuery) ? pageNameFromQuery : (pages[0] ?? '')

    useEffect(() => {
        // If false it means that the ?{key}= is not set or to the page that does not exist
        // and forcing us to apply the default page
        if (page !== pageNameFromQuery) {
            changePage(page)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, pageNameFromQuery])

    return {
        page,
        changePage,
        isPending,
    }
}
