import { appConfig } from './const'
import type { AppConfig } from './types'

const { VITE_ENVIRONMENT, MODE } = import.meta.env

export function getIsDemo() {
    return VITE_ENVIRONMENT === 'demo' || MODE === 'development'
}

export function initializeAppConfig() {
    window.appConfig = appConfig
}

export function getConfigOrDefault<K extends keyof AppConfig>(key: K, defaultValue: AppConfig[K]): AppConfig[K] {
    const isDemo = getIsDemo()
    if (!isDemo) {
        return defaultValue
    }

    return window.appConfig?.[key] || defaultValue
}
