import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(duration)

export const getTimezoneOffset = (timeZone: string, date: dayjs.Dayjs, formatStr = 'H:mm') => {
    const offsetMilliseconds = date.tz(timeZone).utcOffset() * 60 * 1000
    const offset = dayjs.duration(Math.abs(offsetMilliseconds)).format(formatStr)

    const sign = offsetMilliseconds >= 0 ? '+' : '-'

    return `${sign}${offset}`
}
