import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    createdTimeline: {
        id: 'withdrawal.timeline.created.title.created',
        defaultMessage: 'Withdrawal created',
    },
    date: {
        id: 'withdrawal.timeline.created.date',
        defaultMessage: 'Date',
    },
    creditAmount: {
        id: 'withdrawal.timeline.created.credit_amount',
        defaultMessage: 'Credit amount',
    },
    receiveAmount: {
        id: 'withdrawal.timeline.created.receive_amount',
        defaultMessage: 'Receive amount',
    },
    receiveNetwork: {
        id: 'withdrawal.timeline.created.receive_network',
        defaultMessage: 'Receive network',
    },
    nominalReceive: {
        id: 'withdrawal.timeline.created.nominal_receive',
        defaultMessage: 'Nominal receive',
    },
    address: {
        id: 'withdrawal.timeline.created.address',
        defaultMessage: 'Address',
    },
    addressTag: {
        id: 'withdrawal.timeline.created.address_tag',
        defaultMessage: 'Tag',
    },
    initiator: {
        id: 'withdrawal.timeline.created.initiator',
        defaultMessage: 'By ({origin})',
    },
    apiKeyCreatedAt: {
        id: 'withdrawal.timeline.created.initiator.apiKey_created',
        defaultMessage: 'created at:',
    },
})
