import { EmailSupportLink } from '@merchant/shared/components'
import { Button, Space, Typography } from '@merchant/ui-kit/ant-design'
import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { lang as commonLang } from './../../lang'
import { lang } from './lang'
import { useActionModalContext } from '~features/ActionModal/context'
import { globalLang } from '~globalLang'

const { Text } = Typography

export function NotPermitted() {
    const { setTitle, closeModal } = useActionModalContext()

    useEffect(() => {
        setTitle({ text: commonLang.notPermittedTitle, dataMerchant: 'action-not-permitted-modal-title' })
    }, [setTitle])

    return (
        <Space direction="vertical" style={{ width: '100%' }} size={24}>
            <Text data-merchant="not-permitted-action-label">
                <FormattedMessage {...lang.notPermittedMessage} values={{ support: <EmailSupportLink /> }} />
            </Text>
            <Button
                block
                type="primary"
                size="large"
                onClick={closeModal}
                data-merchant="not-permitted-action-ok-button"
            >
                <FormattedMessage {...globalLang.ok} />
            </Button>
        </Space>
    )
}
