import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    termsAndPolicy: {
        id: 'auth.sign-up.form.terms_of_use_and_policy',
        defaultMessage: 'By creating an account, you agree to our {termsOfUse} and {privacyPolicy}',
    },
    termsOfUse: {
        id: 'auth.sign-up.form.terms_of_use',
        defaultMessage: 'Terms of Use',
    },
    privacyPolicy: {
        id: 'auth.sign-up.form.privacy_policy',
        defaultMessage: 'Privacy Policy',
    },
    createAnAccount: {
        id: 'auth.sign-up.form.submit_button_title',
        defaultMessage: 'Create an account',
    },
    emailExists: {
        id: 'auth.sign-up.form.email-already-exists',
        defaultMessage: 'This email already exists',
    },
    signUpTitle: {
        id: 'auth.sign-up.form.title',
        defaultMessage: 'Create an account',
    },
    signUpSubtitle: {
        id: 'auth.sign-up.form.subtitle',
        defaultMessage: 'Already have an account?',
    },
    logInButtonTitle: {
        id: 'auth.sign-up.form.log_in_button_title',
        defaultMessage: 'Log in',
    },
    signInTitle: {
        id: 'auth.sign-in.form.title',
        defaultMessage: 'Log in',
    },
    signInSubtitle: {
        id: 'auth.sign-in.form.subtitle',
        defaultMessage: 'New user?',
    },
    createAccountButtonTitle: {
        id: 'auth.sign-in.form.create_account_button_title',
        defaultMessage: 'Create an account',
    },
    forgotPassword: {
        id: 'auth.sign-in.form.forgot_password',
        defaultMessage: 'Forgot password',
    },
    confirmation: {
        id: 'auth.sign-up.form.confirmation',
        defaultMessage: 'Confirmation',
    },
    emailCode: {
        id: 'auth.sign-up.form.email_code',
        defaultMessage: 'Email code',
    },
    enterTwoFaCode: {
        id: 'auth.sign-in.form.enter_two_fa_code',
        defaultMessage: 'Please enter your 2FA code',
    },
    enterBackupCode: {
        id: 'auth.sign-in.form.enter_backup_code',
        defaultMessage: 'Please enter your backup code',
    },
    twoFaCodePlaceholder: {
        id: 'auth.sign-in.form.two_fa_code_placeholder',
        defaultMessage: '2FA code',
    },
    backupCodePlaceholder: {
        id: 'auth.sign-in.form.backup_code_placeholder',
        defaultMessage: 'Backup code',
    },
    emailPlaceholder: {
        id: 'auth.sign-in.form.email_placeholder',
        defaultMessage: 'Email',
    },
    resetPassword: {
        id: 'auth.sign-in.form.reset_password',
        defaultMessage: 'Reset password',
    },
    resetPasswordText: {
        id: 'auth.sign-in.form.reset_password_text',
        defaultMessage:
            'Enter email and click Confirm. If the email address matches an account in our system, you will receive a reset code.',
    },
    newPassword: {
        id: 'auth.sign-in.form.new_password',
        defaultMessage: 'New password',
    },
    newPasswordPlaceholder: {
        id: 'auth.sign-in.form.new_password_placeholder',
        defaultMessage: 'New password',
    },
    repeatNewPasswordPlaceholder: {
        id: 'auth.sign-in.form.repeat_new_password_placeholder',
        defaultMessage: 'Repeat new password',
    },
    afterResetDisableDisclaimer: {
        id: 'auth.sign-in.form.after_reset_disable_disclaimer',
        defaultMessage:
            'After successfully resetting your password, the withdrawal  will be disabled for 72 hours as a security measure to protect your account.',
    },
    passwordsAreDifferent: {
        id: 'auth.sign-in.form.passwords_are_different',
        defaultMessage: 'Passwords are different',
    },
    passwordUpdatedNotificationMessage: {
        id: 'auth.sign-in.notification.password_updated_message',
        defaultMessage: 'Your password has been updated',
    },
    passwordUpdatedNotificationDescription: {
        id: 'auth.sign-in.notification.password_updated_description',
        defaultMessage:
            'You have successfully updated your password. Please ensure that you keep it confidential and secure.',
    },
    enterNewPasswordsLabel: {
        id: 'auth.sign-in.form.enter_new_passwords_label',
        defaultMessage: 'Enter your new password in the field below',
    },
})
