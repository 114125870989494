import { parseDateAndTime } from '@merchant/shared/utils'
import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import type { Withdrawal } from '~api'

const { Text } = Typography

interface Props {
    data: Withdrawal
}

export function WithdrawalInitiator({ data }: Props) {
    const { createdBy } = data
    const { projectMember, apiKey } = createdBy || {}

    if (apiKey) {
        const { name, createdAt } = apiKey
        const parsedApiKeyCreatedAt = parseDateAndTime(createdAt)

        return (
            <Flex vertical align="flex-end">
                <Text data-merchant="withdrawal-timeline-initiator-apiKey-name">{name}</Text>
                {parsedApiKeyCreatedAt && (
                    <Text type="secondary" data-merchant="withdrawal-timeline-initiator-apiKey-createdAt">
                        <FormattedMessage {...lang.apiKeyCreatedAt} />
                        &nbsp;
                        {parsedApiKeyCreatedAt.date} {parsedApiKeyCreatedAt.time}
                    </Text>
                )}
            </Flex>
        )
    }

    if (projectMember) {
        const { email, roles } = projectMember

        return (
            <Flex vertical align="flex-end">
                <Text data-merchant="withdrawal-timeline-initiator-email">{email}</Text>
                {roles && (
                    <Text type="secondary" data-merchant="withdrawal-timeline-initiator-roles">
                        {roles.join(', ')}
                    </Text>
                )}
            </Flex>
        )
    }

    return null
}
