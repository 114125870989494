import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    notFoundTitle: {
        id: 'shared.payment.not_found_title',
        defaultMessage: '404',
    },
    notFoundSubtitle: {
        id: 'shared.payment.not_found_subtitle',
        defaultMessage: "Ooops... Don't panic",
    },
    notFoundText: {
        id: 'shared.payment.not_found_text',
        defaultMessage: "We can't seem to find the page you're looking for",
    },
    backToMainPage: {
        id: 'shared.payment.button.back_to_main_page',
        defaultMessage: 'Back to main page',
    },
})
