import { Statistic } from '@merchant/ui-kit/ant-design'
import type { StatisticProps } from '@merchant/ui-kit/ant-design'
import { formatNumber } from '~utils'

interface Props extends StatisticProps {
    hidden?: boolean
    mask?: string
}

export function StatisticFormattedNumber({ style: styleProp, hidden, value, mask = '***', ...rest }: Props) {
    const style: React.CSSProperties = {
        display: 'inline-flex',
        ...styleProp,
    }

    return <Statistic style={style} formatter={formatNumber} value={hidden ? mask : value} {...rest} />
}
