import { useCommonAppSettings } from '@merchant/shared/api'
import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { getNetworksOrderedByCMS } from '@merchant/shared/utils'
import { Select, Flex, Tooltip, Typography, Alert } from '@merchant/ui-kit/ant-design'
import { isEmpty } from 'lodash-es'
import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import type { SelectProps } from '@merchant/ui-kit/ant-design'
import type { DefaultOptionType } from '@merchant/ui-kit/ant-design/es/select'
import type { MessageDescriptor } from 'react-intl'
import type { Currency, Network } from '~api/instances/cabinet-api'
import { SearchableSelect } from '~components'
import { useNetworks } from '~hooks'

const { Text } = Typography

function NetworkAlert(props: Pick<NetworkSelectProps, 'data-merchant'>) {
    return (
        <Alert
            type="warning"
            message={
                <Flex align="center" gap={12}>
                    <AppSvg name={assets.alertCircle} color={token => token.colorWarning} />
                    <Text
                        type="secondary"
                        style={{ fontSize: 12 }}
                        data-merchant={`${props['data-merchant']}-alert-text`}
                    >
                        <FormattedMessage {...lang.networkAlert} />
                    </Text>
                </Flex>
            }
            data-merchant={`${props['data-merchant']}-alert`}
        />
    )
}

interface NetworkSelectProps extends SelectProps {
    currency?: Currency
    disablePredicate?: (network: Network) => boolean
    disableLabel?: MessageDescriptor
    sorter?: (a: Network[]) => Network[]
    showAlert?: boolean
    getKey?: (network: Network) => string
    getLabel?: (network: Network) => React.ReactNode
    'data-merchant': string
}

export function NetworkSelect({
    currency,
    disabled,
    disablePredicate,
    disableLabel,
    sorter,
    showSearch,
    showAlert,
    getKey,
    getLabel,
    ...rest
}: NetworkSelectProps) {
    const { data: siteSettings, isLoading: isLoadingNetworksOrder } = useCommonAppSettings()
    const { networksOrder } = siteSettings || {}
    const { networks: allNetworks } = useNetworks()

    const options = useMemo(() => {
        const networks = currency?.networks ?? allNetworks
        const networksOrderedByCMS = getNetworksOrderedByCMS(networks, networksOrder)
        const sorted = sorter ? sorter(networksOrderedByCMS) : networksOrderedByCMS
        const { enabled, disabled } = sorted.reduce(
            (acc, network) => {
                const isDisabled = disablePredicate?.(network)
                const label = getLabel?.(network) ?? (
                    <Flex align="center" gap={8} style={{ opacity: !isDisabled ? 1 : 0.5 }}>
                        <Text data-merchant={null}>
                            {network.code}
                            &nbsp;
                            <Text type="secondary" data-merchant={null}>
                                {network.name}
                            </Text>
                        </Text>
                        {isDisabled && disableLabel && (
                            <Text type="secondary" style={{ marginLeft: 'auto' }} data-merchant={null}>
                                <FormattedMessage {...disableLabel} />
                            </Text>
                        )}
                    </Flex>
                )
                const option: DefaultOptionType = {
                    key: getKey?.(network) ?? network.code + network.name,
                    label,
                    network,
                    disabled: isDisabled,
                    value: network.code,
                    'data-merchant': `static-address-constructor-network-${network.code.toLowerCase()}`,
                }

                if (!isDisabled) {
                    acc.enabled.push(option)
                } else {
                    acc.disabled.push(option)
                }

                return acc
            },
            { enabled: [], disabled: [] } as {
                enabled: DefaultOptionType[]
                disabled: DefaultOptionType[]
            }
        )

        return [...enabled, ...disabled]
    }, [currency?.networks, allNetworks, networksOrder, sorter, disablePredicate, getLabel, disableLabel, getKey])

    const isNetworkOptional = currency && isEmpty(currency.networks)
    const dataMerchant = `${rest['data-merchant']}-dropdown`

    const SelectableComponent = showSearch ? SearchableSelect : Select

    return (
        <Tooltip
            mouseEnterDelay={0.2}
            title={isNetworkOptional && <FormattedMessage {...lang.networkNotRequired} />}
            data-merchant={`${rest['data-merchant']}-tooltip`}
        >
            <SelectableComponent
                disabled={isEmpty(options) || disabled || isNetworkOptional}
                loading={isLoadingNetworksOrder}
                options={options}
                filterOption={(input, option) => option?.key?.toLowerCase().includes(input.toLowerCase())}
                dropdownRender={menu => (
                    <Flex vertical gap={2} data-merchant={dataMerchant}>
                        {showAlert && <NetworkAlert data-merchant={dataMerchant} />}
                        {menu}
                    </Flex>
                )}
                {...rest}
            />
        </Tooltip>
    )
}
