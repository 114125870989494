import type { Network } from '~api/instances/cabinet-api'
import type { Currencies } from '~api/types'

export const getCurrencyNetworkIfHasMultiple = (
    currencyCode: string | undefined,
    networkCode: string | undefined,
    currencies: Currencies | undefined
): Network | null => {
    if (!currencyCode || !currencies) {
        return null
    }

    const currencyNetworks = currencies[currencyCode]?.networks ?? []
    const hasMultipleNetworks = currencyNetworks.length > 1

    return hasMultipleNetworks ? (currencyNetworks.find(n => n.code === networkCode) ?? null) : null
}
