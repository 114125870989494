import { assets } from '@merchant/shared/assets/nameMap'
import { EmailSupportLink, AppSvg } from '@merchant/shared/components'
import { Badge, Space, Tooltip, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { menuKeys } from './const'
import { lang } from './lang'
import { createMenuItem } from './utils'
import type { CommonParams, MenuItemExtendedType } from './types'
import type { GlobalToken } from '@merchant/ui-kit/ant-design'
import type { IntlShape, MessageDescriptor } from 'react-intl'
import { Permission } from '~api/instances/cabinet-api'
import { lang as merchantLang } from '~features/MerchantPage/lang'
import { lang as settingsLang } from '~features/SettingsPage/lang'
import { globalLang } from '~globalLang'

const { Text, Link } = Typography

export const getOrdersMenuItem = ({ isLayoutSiderCollapsed, checkUserPermission }: CommonParams) =>
    createMenuItem({
        shouldAddIconPlaceholder: !isLayoutSiderCollapsed,
        key: menuKeys.ordersParent,
        icon: <AppSvg size={20} name={assets.receiptCheck} />,
        label: <FormattedMessage {...lang.orders} />,
        dataMerchant: 'nav-menu-orders-parent',
        isGrouped: isLayoutSiderCollapsed,
        subItems: [
            {
                key: menuKeys.ordersHistory,
                label: <FormattedMessage {...lang.ordersHistory} />,
                dataMerchant: 'nav-menu-orders-history',
                checkUserPermission: () => checkUserPermission(Permission.SeePaymentHistory),
            },
            {
                key: menuKeys.createOrder,
                label: <FormattedMessage {...lang.createOrder} />,
                dataMerchant: 'nav-menu-orders-new-order',
                checkUserPermission: () =>
                    checkUserPermission(Permission.CreateDepositAddress) ||
                    checkUserPermission(Permission.CreatePayment),
            },
        ],
    })

export const getBalancesMenuItem = ({ checkUserPermission, isLayoutSiderCollapsed }: CommonParams) =>
    createMenuItem({
        shouldAddIconPlaceholder: !isLayoutSiderCollapsed,
        key: menuKeys.balances,
        icon: <AppSvg size={20} name={assets.coinsStacked} />,
        label: <FormattedMessage {...lang.balances} />,
        dataMerchant: 'nav-menu-balances',
        checkUserPermission: () => checkUserPermission(Permission.SeeBalance),
    })

export const getTransactionMenuItem = ({ checkUserPermission, isLayoutSiderCollapsed }: CommonParams) =>
    createMenuItem({
        shouldAddIconPlaceholder: !isLayoutSiderCollapsed,
        key: menuKeys.transactions,
        icon: <AppSvg size={20} name={assets.switchVertical} />,
        label: <FormattedMessage {...lang.transactions} />,
        dataMerchant: 'nav-menu-transactions',
        checkUserPermission: () => checkUserPermission(Permission.SeeTransactions),
    })

export const getIntegrationsMenuItem = ({ checkUserPermission, isLayoutSiderCollapsed }: CommonParams) =>
    createMenuItem({
        shouldAddIconPlaceholder: !isLayoutSiderCollapsed,
        key: menuKeys.integrations,
        icon: <AppSvg size={20} name={assets.tool} />,
        label: <FormattedMessage {...lang.integrations} />,
        dataMerchant: 'nav-menu-integrations',
        checkUserPermission: () => checkUserPermission(Permission.SeeIntegration),
    })

export const getWithdrawalsMenuItem = ({ checkUserPermission, isLayoutSiderCollapsed }: CommonParams) =>
    createMenuItem({
        shouldAddIconPlaceholder: !isLayoutSiderCollapsed,
        key: menuKeys.withdrawalsParent,
        icon: <AppSvg size={20} name={assets.arrowUpRight} />,
        label: <FormattedMessage {...lang.withdrawals} />,
        dataMerchant: 'nav-menu-withdrawals',
        isGrouped: isLayoutSiderCollapsed,
        subItems: [
            {
                key: menuKeys.withdrawalsHistory,
                label: <FormattedMessage {...lang.withdrawalsHistory} />,
                dataMerchant: 'nav-menu-withdrawals-list',
                checkUserPermission: () => checkUserPermission(Permission.SeeWithdrawals),
            },
            {
                key: menuKeys.withdrawalsWhitelist,
                label: <FormattedMessage {...lang.withdrawalsWhitelist} />,
                dataMerchant: 'nav-menu-withdrawals-whitelist',
                checkUserPermission: () => checkUserPermission(Permission.SeeWithdrawalWhitelist),
            },
        ],
    })

export const getAddressesMenuItem = ({ checkUserPermission, isLayoutSiderCollapsed }: CommonParams) =>
    createMenuItem({
        shouldAddIconPlaceholder: !isLayoutSiderCollapsed,
        key: menuKeys.addresses,
        icon: <AppSvg size={20} name={assets.list} />,
        label: <FormattedMessage {...lang.addresses} />,
        dataMerchant: 'nav-menu-addresses',
        isGrouped: isLayoutSiderCollapsed,
        subItems: [
            {
                key: menuKeys.depositAddresses,
                label: <FormattedMessage {...lang.depositAddresses} />,
                dataMerchant: 'nav-menu-deposit-addresses',
                checkUserPermission: () => checkUserPermission(Permission.SeeDepositAddresses),
            },
            {
                key: menuKeys.staticAddresses,
                label: <FormattedMessage {...lang.staticAddresses} />,
                dataMerchant: 'nav-menu-static-addresses',
                checkUserPermission: () => checkUserPermission(Permission.SeeDepositAddresses),
            },
        ],
    })

export const getSettingsMenuItem = ({ isLayoutSiderCollapsed }: CommonParams) =>
    createMenuItem({
        shouldAddIconPlaceholder: !isLayoutSiderCollapsed,
        key: menuKeys.settingsParent,
        icon: <AppSvg size={20} name={assets.settings} />,
        label: <FormattedMessage {...settingsLang.pageTitle} />,
        dataMerchant: 'nav-menu-settings-parent',
        isGrouped: isLayoutSiderCollapsed,
        subItems: [
            {
                key: menuKeys.settingsGeneral,
                label: <FormattedMessage {...settingsLang.generalPageTitle} />,
                dataMerchant: 'nav-menu-settings-general',
            },
            {
                key: menuKeys.settingsSecurity,
                label: <FormattedMessage {...settingsLang.securityPageTitle} />,
                dataMerchant: 'nav-menu-settings-security',
            },
        ],
    })

interface MerchantMenuItemProps extends CommonParams {
    isMerchantDisabled: boolean
    isProjectDisabled: boolean
    token: GlobalToken
    isCurrencySettingDisabled: boolean
    isLoadingData: boolean
}

export const getMerchantMenuItem = ({
    isMerchantDisabled,
    isProjectDisabled,
    token,
    isCurrencySettingDisabled,
    isLayoutSiderCollapsed,
    isLoadingData,
    checkUserPermission,
}: MerchantMenuItemProps): MenuItemExtendedType => {
    const createLabel = (message: MessageDescriptor, showAlert = false) => (
        <Space align="center" styles={{ item: { display: 'inline-flex' } }}>
            <FormattedMessage {...message} />
            {showAlert && <AppSvg size={20} name={assets.alertCircle} color={token.colorError} />}
        </Space>
    )

    const getTooltipTitle = (condition: boolean, message: MessageDescriptor) =>
        condition &&
        !isLoadingData && (
            <Text data-merchant={null} type="secondary" style={{ fontSize: 12 }}>
                <FormattedMessage {...message} />
            </Text>
        )

    const getIsDisabled = (disabledCondition: boolean) => isLoadingData || disabledCondition

    return createMenuItem({
        isGrouped: isLayoutSiderCollapsed,
        shouldAddIconPlaceholder: !isLayoutSiderCollapsed,
        key: menuKeys.merchantParent,
        icon: (
            <Badge
                status="error"
                dot={(isProjectDisabled || isMerchantDisabled) && isLayoutSiderCollapsed}
                offset={[-3, 5]}
                style={{ boxShadow: `0 0 0 1.5px ${token.colorBgBase}` }}
                data-merchant="merchant-settings-badge"
            >
                <AppSvg size={20} name={assets.homeSmile} />
            </Badge>
        ),
        label: createLabel(merchantLang.pageTitle, isMerchantDisabled || isProjectDisabled),
        dataMerchant: 'nav-menu-merchant-parent',
        subItems: [
            {
                key: menuKeys.merchantGeneral,
                label: createLabel(merchantLang.generalPageTitle, isMerchantDisabled || isProjectDisabled),
                dataMerchant: 'nav-menu-merchant-general',
            },
            {
                key: menuKeys.merchantAssets,
                label: (
                    <Tooltip
                        title={getTooltipTitle(isCurrencySettingDisabled, lang.disabledCurrencySettingsNoMerchant)}
                        data-merchant="assets-settings-tooltip"
                    >
                        <div>
                            <FormattedMessage {...merchantLang.currenciesSettingsPageTitle} />
                        </div>
                    </Tooltip>
                ),
                dataMerchant: 'nav-menu-merchant-assets',
                disabled: getIsDisabled(isCurrencySettingDisabled),
            },
            {
                key: menuKeys.merchantPaymentSettings,
                label: (
                    <Tooltip
                        title={getTooltipTitle(isCurrencySettingDisabled, lang.disabledPaymentsSettingsNoMerchant)}
                        data-merchant="payments-settings-tooltip"
                    >
                        <div>
                            <FormattedMessage {...merchantLang.paymentsSettingsPageTitle} />
                        </div>
                    </Tooltip>
                ),
                dataMerchant: 'nav-menu-merchant-payments-settings',
                disabled: getIsDisabled(isCurrencySettingDisabled),
                checkUserPermission: () => checkUserPermission(Permission.SeePaymentSettings),
            },
            {
                key: menuKeys.merchantTeamManagement,
                label: (
                    <Tooltip
                        placement="topLeft"
                        title={getTooltipTitle(isCurrencySettingDisabled, lang.disabledTeamManagementNoMerchant)}
                        data-merchant="team-management-tooltip"
                    >
                        <div>
                            <FormattedMessage {...merchantLang.teamPageTitle} />
                        </div>
                    </Tooltip>
                ),
                dataMerchant: 'nav-menu-merchant-team',
                disabled: getIsDisabled(isCurrencySettingDisabled),
                checkUserPermission: () =>
                    checkUserPermission(Permission.SeeProjectTeamMembers) ||
                    checkUserPermission(Permission.SeeProjectRoles),
            },
        ],
    })
}

export const getSupportMenuItems = (intl: IntlShape) => [
    createMenuItem({
        key: 'telegram',
        icon: (
            <EmailSupportLink onClick={event => event.stopPropagation()} data-merchant="support-button">
                <AppSvg size={20} name={assets.telegram} />
            </EmailSupportLink>
        ),
        label: <FormattedMessage {...lang.support} />,
        dataMerchant: 'nav-menu-support',
    }),
    createMenuItem({
        key: 'documentation',
        icon: (
            <Link
                onClick={event => event.stopPropagation()}
                href={intl.formatMessage(globalLang.docsUrl)}
                target="_blank"
                data-merchant="documentation-button"
            >
                <AppSvg size={20} name={assets.folderCode} />
            </Link>
        ),
        label: <FormattedMessage {...lang.documentation} />,
        dataMerchant: 'nav-menu-documentation',
    }),
]
