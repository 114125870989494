export const currencyModalStyles: React.CSSProperties = {
    marginTop: 0,
    marginBottom: 0,
    top: 0,
    bottom: 0,
    padding: '8px 0',
    height: '100vh',
    maxWidth: undefined,
    width: '100vw',
}
export const currencyModalContentStyles: React.CSSProperties = {
    height: '100%',
    padding: '16px 12px',
    maxWidth: undefined,
}
