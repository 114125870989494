import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { Button, Space, theme } from '@merchant/ui-kit/ant-design'
import React from 'react'

const { useToken } = theme

interface Props {
    amount: number
    onClear: () => void
    dataMerchant: string
}

export function ActiveFilters({ amount, onClear, dataMerchant }: Props) {
    const { token } = useToken()

    const labelStyles: React.CSSProperties = {
        fontSize: 10,
        height: 20,
        width: 20,
        color: token.colorTextBase,
        fontWeight: 500,
        backgroundColor: token.colorPrimary,
        display: 'grid',
        placeItems: 'center',
        placeContent: 'center',
        borderRadius: '50%',
    }
    const trashIconStyles: React.CSSProperties = { color: token.colorPrimary }

    const onClearClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        onClear()
    }

    return (
        <Space size={8}>
            <div style={labelStyles}>{amount}</div>
            <Button
                data-merchant={`${dataMerchant}-filters-clear-button`}
                type="text"
                style={{ padding: 0, display: 'grid', placeItems: 'center' }}
                onClick={onClearClick}
            >
                <AppSvg size={20} name={assets.trash} style={trashIconStyles} />
            </Button>
        </Space>
    )
}
