import { uniqBy } from 'lodash-es'
import { useMemo } from 'react'
import type { Network } from '~api/instances/cabinet-api'
import { useCurrencies } from '~api'

type Props = {
    payoutEnabledOnly?: boolean
}

export function useNetworks({ payoutEnabledOnly = false }: Props = {}) {
    const { data: currencies, isLoading } = useCurrencies()

    const networks = useMemo(() => {
        if (!currencies) {
            return []
        }

        const allNetworks = Object.values(currencies).reduce<Network[]>((acc, currency) => {
            const { networks = [], payoutEnabled } = currency

            if (!payoutEnabledOnly) {
                return [...acc, ...networks]
            }

            if (!payoutEnabled) {
                return acc
            }

            return [...acc, ...networks.filter(network => network.payoutEnabled)]
        }, [])

        return uniqBy(allNetworks, 'code' satisfies keyof Network)
    }, [currencies, payoutEnabledOnly])

    return {
        networks,
        isLoading,
    }
}

export function useNetworksMap() {
    const { networks: allNetworks, isLoading } = useNetworks()

    const networks = useMemo(() => {
        return allNetworks.reduce<Record<string, Network>>((acc, network) => {
            acc[network.code] = network

            return acc
        }, {})
    }, [allNetworks])

    return {
        networks,
        isLoading,
    }
}
