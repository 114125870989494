import { ModalResponsive } from '@merchant/shared/components'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { TwoFaConfirmationForm } from '../../TwoFaConfirmationForm'
import { lang } from './lang'
import type { FormInstance, ModalProps } from '@merchant/ui-kit/ant-design'
import type { APIKey, TOTPCredentials } from '~api/instances/cabinet-api'
import { projectApi, useProject } from '~api'
import { useCodeConfirmationErrorHandling } from '~features/FormItems/useCodeErrorHandling'
import { useDirtyFormCheckerControls } from '~hooks'

interface Props extends Omit<ModalProps, 'data-merchant'> {
    selectedApiKey: APIKey | null
    onModalSuccess: (apiKey: APIKey) => void
}

export function ToggleApiKeyStatusModal({ onModalSuccess, selectedApiKey, onCancel, ...rest }: Props) {
    const { data: projectData } = useProject()
    const { handleError } = useCodeConfirmationErrorHandling({
        confirmationStrategy: { '2fa': 'code' },
    })
    const { handleCancel } = useDirtyFormCheckerControls(onCancel)
    const projectId = projectData?.id || ''
    const handleConfirmationSuccess = async (totp: TOTPCredentials, form: FormInstance) => {
        try {
            const patchedApiKey = await projectApi.updateAPIKey({
                projectId,
                apiKeyId: selectedApiKey?.id || '',
                updateAPIKeyRequest: {
                    active: !selectedApiKey?.active,
                    ...totp,
                },
            })
            onModalSuccess(patchedApiKey)
        } catch (error) {
            handleError({
                form,
                error,
            })
        }
    }

    return (
        <ModalResponsive
            data-merchant="toggle-api-key-modal"
            destroyOnClose
            onCancel={handleCancel}
            footer={null}
            {...rest}
            title={
                <span data-merchant="toggle-api-key-modal-title">
                    <FormattedMessage {...lang.modalTitle} />
                </span>
            }
        >
            <TwoFaConfirmationForm
                mode="toggle-api-key"
                submitButtonTitle={lang.submitButton}
                onSuccess={handleConfirmationSuccess}
            />
        </ModalResponsive>
    )
}
