import { Form } from '@merchant/ui-kit/ant-design'
import type { StaticAddressFormData } from './types'
import { useHoldingCurrencies, useProject } from '~api'

export function useData() {
    const { data: project, isLoading: isProjectLoading } = useProject()
    const { isLoading: isHoldingCurrenciesLoading, hasSelectedHoldingCurrency } = useHoldingCurrencies()
    const [form] = Form.useForm<StaticAddressFormData>()

    return {
        form,
        project,
        isProjectLoading,
        hasSelectedHoldingCurrency,
        isHoldingCurrenciesLoading,
    }
}
