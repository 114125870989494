import { usePromise } from '@merchant/shared/hooks'
import { formatFilterRequestDates } from '@merchant/shared/utils'
import { Button } from '@merchant/ui-kit/ant-design'
import dayjs from 'dayjs'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import { TransactionsFiltersForm } from './TransactionsFiltersForm'
import type { TransactionFilterQueryData, TransactionsFilterFormData } from './types'
import { projectApi, useProject } from '~api'
import { TransactionsTable } from '~features'
import { getFiltersAsParams } from '~features/TransactionsTable/utils'
import { useQueryNavigation } from '~hooks'
import { getTruthyFields, downloadMedia } from '~utils'

export function Transactions() {
    const { data: { id: projectId = '' } = {} } = useProject()
    const { filters, onSubmit, onClear } = useQueryNavigation<
        Partial<TransactionsFilterFormData>,
        Partial<TransactionFilterQueryData>
    >({
        filters: ['dateFrom', 'dateTo', 'id', 'status', 'type'],
        beforeWrite: {
            date: date => {
                if (!Array.isArray(date)) {
                    return
                }

                return formatFilterRequestDates(date)
            },
        },
        afterRead: filters => {
            const from = dayjs(filters.dateFrom || '')
            const to = dayjs(filters.dateTo || '')

            if (!from.isValid() && !to.isValid()) {
                return filters
            }

            return {
                ...filters,
                date: [from.isValid() ? from : null, to.isValid() ? to : null],
            }
        },
    })

    const { send: exportAndDownloadCSV, isLoading: isExportingCSV } = usePromise(
        (filters: Partial<TransactionsFilterFormData> | Partial<TransactionFilterQueryData>) =>
            projectApi.exportProjectTransactions({
                ...getFiltersAsParams(filters, projectId),
            }),
        {
            onSuccess: data =>
                downloadMedia({
                    data,
                    type: 'text/csv',
                    filename: 'transactions.csv',
                }),
        }
    )

    const onExport = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation()
        exportAndDownloadCSV(filters)
    }

    const { amount: activeFiltersCount } = getTruthyFields(filters)

    return (
        <TransactionsTable
            renderFilters={isLoadingTransactions => (
                <TransactionsFiltersForm
                    isLoading={isLoadingTransactions}
                    onFormReset={onClear}
                    initialValues={filters}
                    onFormFinish={onSubmit}
                    activeFiltersCount={activeFiltersCount}
                    extra={
                        <Button
                            data-merchant="transactions-export-csv-button"
                            onClick={onExport}
                            loading={isExportingCSV}
                        >
                            <FormattedMessage {...lang.exportCsv} />
                        </Button>
                    }
                />
            )}
            filters={filters}
            searchId={filters.id}
        />
    )
}
