import { assets } from '@merchant/shared/assets/nameMap'
import { AppCoin, AppSvg } from '@merchant/shared/components'
import { List, Tooltip, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from '../lang'
import type { CurrencyExtended } from './../types'

const { Text } = Typography

interface Props {
    data: CurrencyExtended
    isHighlighted: boolean
}

export function ListItem({ data, isHighlighted }: Props) {
    return (
        <List.Item
            style={{
                height: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
            }}
            data-merchant={`${data.code}-chosen-holding-currency`}
        >
            <AppCoin style={{ verticalAlign: 'middle', marginRight: 10 }} currencyCode={data.code} size="small" />
            <Text data-merchant={`${data.code}-chosen-holding-currency`}>{data.code}</Text>
            &nbsp;
            <Text type="secondary" data-merchant={`${data.name}-chosen-holding-currency`}>
                {data.name}
            </Text>
            {isHighlighted && (
                <Tooltip
                    destroyTooltipOnHide
                    title={
                        <Text data-merchant={null}>
                            <span style={{ fontWeight: 700 }}>
                                <FormattedMessage {...lang.disableConversionCurrencyTooltipPrefix} />
                            </span>{' '}
                            <FormattedMessage {...lang.disableConversionCurrencyTooltip} />
                        </Text>
                    }
                    data-merchant="disable-conversion-currency-tooltip"
                >
                    <Text
                        type="secondary"
                        style={{ marginLeft: 'auto', display: 'inline-flex' }}
                        data-merchant="disable-conversion-currency-tooltip-target"
                    >
                        <AppSvg size={20} name={assets.lock} />
                    </Text>
                </Tooltip>
            )}
        </List.Item>
    )
}
