/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ProjectAPIKeyPermissionID {
    Full = 'full',
    Addresses = 'addresses',
    Balances = 'balances',
    Swaps = 'swaps',
    Currencies = 'currencies',
    Payments = 'payments',
    Withdrawals = 'withdrawals'
}


// @ts-ignore
export function ProjectAPIKeyPermissionIDFromJSON(json: any): ProjectAPIKeyPermissionID {
    return ProjectAPIKeyPermissionIDFromJSONTyped(json, false);
}

// @ts-ignore
export function ProjectAPIKeyPermissionIDFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectAPIKeyPermissionID {
    return json as ProjectAPIKeyPermissionID;
}

// @ts-ignore
export function ProjectAPIKeyPermissionIDToJSON(value?: ProjectAPIKeyPermissionID | null): any {
    return value as any;
}

