import posthog from 'posthog-js'
import { selectedProjectIdKey } from '../../constants/localStorage'
import { parseIfValidJson } from './../../utils'
import { CustomErrorCodes } from './const'
import { PosthogHttpErrorEvents, type APIErrorResponseBase, type HttpErrorEventDataMap } from './types'

interface HttpErrorInterface<T extends APIErrorResponseBase = APIErrorResponseBase> {
    response?: Response
    responseData?: T
    message?: string
    isNetworkError?: boolean
}

export class HttpError<T extends APIErrorResponseBase = APIErrorResponseBase> extends TypeError {
    public status?: number
    public url?: string
    public responseData: HttpErrorInterface<T>['responseData']
    public isNetworkError: HttpErrorInterface<T>['isNetworkError']

    private static statusCodeMap: Record<number, string> = {
        100: 'Continue',
        101: 'SwitchingProtocols',
        200: 'OK',
        201: 'Created',
        202: 'Accepted',
        203: 'NonAuthoritativeInformation',
        204: 'NoContent',
        205: 'ResetContent',
        206: 'PartialContent',
        300: 'MultipleChoices',
        301: 'MovedPermanently',
        302: 'Found',
        303: 'SeeOther',
        304: 'NotModified',
        305: 'UseProxy',
        307: 'TemporaryRedirect',
        400: 'BadRequest',
        401: 'Unauthorized',
        402: 'PaymentRequired',
        403: 'Forbidden',
        404: 'NotFound',
        405: 'MethodNotAllowed',
        406: 'NotAcceptable',
        407: 'ProxyAuthenticationRequired',
        408: 'RequestTimeout',
        409: 'Conflict',
        410: 'Gone',
        411: 'LengthRequired',
        412: 'PreconditionFailed',
        413: 'PayloadTooLarge',
        414: 'URITooLong',
        415: 'UnsupportedMediaType',
        416: 'RangeNotSatisfiable',
        417: 'ExpectationFailed',
        418: 'ImATeapot',
        422: 'UnprocessableEntity',
        425: 'TooEarly',
        426: 'UpgradeRequired',
        428: 'PreconditionRequired',
        429: 'TooManyRequests',
        431: 'RequestHeaderFieldsTooLarge',
        451: 'UnavailableForLegalReasons',
        500: 'InternalServerError',
        501: 'NotImplemented',
        502: 'BadGateway',
        503: 'ServiceUnavailable',
        504: 'GatewayTimeout',
        505: 'HTTPVersionNotSupported',
        511: 'NetworkAuthenticationRequired',
    } as const

    constructor({ response, responseData, message, isNetworkError }: HttpErrorInterface<T>) {
        super(message)
        this.status = response?.status
        this.url = response?.url
        this.isNetworkError = isNetworkError || false
        this.responseData = responseData
    }

    public checkForErrorCode<T extends string>(code: T) {
        return this.responseData?.errors?.some(e => e.code === code)
    }

    static isInstance(error: unknown): error is HttpError {
        return error instanceof HttpError
    }

    public get errorCode() {
        if (this.isNetworkError) {
            return CustomErrorCodes.ConnectionError
        }
        if (this.name) {
            return this.name
        }

        return this.getCodeFromStatus(this.status)
    }

    public sendCabinetErrorReport() {
        const { parsed: parsedProjectId } = parseIfValidJson(localStorage.getItem(selectedProjectIdKey) || '')
        posthog.capture(PosthogHttpErrorEvents.cabinetHttpError, {
            message: this.getErrorResponseDataMessage || this.message || this.name,
            request_url: this.url || '',
            screen_url: window.location.href,
            project_id: parsedProjectId,
        } satisfies HttpErrorEventDataMap[PosthogHttpErrorEvents.cabinetHttpError])
    }

    get getErrorResponseDataMessage() {
        return this.responseData?.errors?.map(({ message }) => message).join(', ')
    }

    private getCodeFromStatus(status?: number): string {
        return status ? HttpError.statusCodeMap[status] || 'UnknownError' : 'UnknownError'
    }
}
