import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg, CompactCardsWrapper, ModalResponsive } from '@merchant/shared/components'
import { ConfigProvider, Flex, Typography, theme, type ModalProps, type ThemeConfig } from '@merchant/ui-kit/ant-design'
import { FormattedMessage, useIntl } from 'react-intl'
import { lang } from './lang'
import { getCallbackResultModalTitle, getCardBody } from './utils'
import type { CallbackResultModalType } from './types'
import type { CallbackResponse } from '~api/instances/cabinet-api'
import { globalLang } from '~globalLang'

const { Text } = Typography
const { useToken } = theme

const collapseConfigProviderTheme: ThemeConfig = {
    components: {
        Collapse: { headerPadding: '4px 0', contentPadding: 0, paddingSM: 10 },
    },
}

interface Props extends Omit<ModalProps, 'data-merchant'> {
    data: CallbackResponse | null
    type: CallbackResultModalType
}

export function CallbackResultModal({ data, type, ...rest }: Props) {
    const { token } = useToken()
    const intl = useIntl()
    const bodyItems = data ? Object.values(getCardBody(data)) : []
    const isCallbackSent = !!data?.response.code && data.response.code >= 200 && data.response.code < 300

    const iconProps = isCallbackSent
        ? { name: assets.checkCircle, color: token.colorSuccess }
        : { name: assets.xCircle, color: token.colorError }

    const modalTitle = getCallbackResultModalTitle(type, isCallbackSent)

    return (
        <ConfigProvider theme={collapseConfigProviderTheme}>
            <ModalResponsive
                open={!!data}
                footer={(_, { CancelBtn }) => <CancelBtn />}
                cancelText={<FormattedMessage {...lang.close} />}
                cancelButtonProps={{
                    size: 'large',
                    type: 'primary',
                    block: true,
                    'data-merchant': 'callback-result-modal-close-button',
                }}
                styles={{ body: { paddingTop: 24 } }}
                data-merchant="callback-result-modal"
                {...rest}
            >
                <Flex vertical align="center" gap={4}>
                    <AppSvg size={48} {...iconProps} />
                    <Text
                        style={{
                            fontWeight: 500,
                            fontSize: 20,
                            textAlign: 'center',
                        }}
                        data-merchant="callback-result-modal-title"
                    >
                        <FormattedMessage {...modalTitle} />
                    </Text>
                    <Text
                        type="secondary"
                        style={{ fontSize: 16, textAlign: 'center' }}
                        data-merchant="callback-result-modal-subtitle"
                    >
                        <FormattedMessage
                            {...lang.subtitle}
                            values={{
                                link: chunks => (
                                    <a
                                        href={intl.formatMessage(globalLang.callbackDocUrl)}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {chunks}
                                    </a>
                                ),
                            }}
                        />
                    </Text>
                </Flex>
                <div style={{ margin: '24px 0' }}>
                    <CompactCardsWrapper
                        gap={4}
                        compactCardStyles={{ body: { padding: '12px 16px' } }}
                        dataMerchantPrefix="callback-result"
                    >
                        {bodyItems}
                    </CompactCardsWrapper>
                </div>
            </ModalResponsive>
        </ConfigProvider>
    )
}
