import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg, EllipsisMiddle } from '@merchant/shared/components'
import { Flex, Popover, Typography, theme } from '@merchant/ui-kit/ant-design'
import { useBoolean } from 'usehooks-ts'
import { PayinDisabledContent, PayingEnabledContent } from './components'
import type { TableType } from '../../const'
import type { UnavailableCurrencyModalRef } from '../UnavailableCurrencyModal'
import type { Currency, Network } from '~api/instances/cabinet-api'
import { isCurrencyDepositAvailable } from '~utils'

const { useToken } = theme
const { Text } = Typography

interface Props {
    context: TableType
    address: string
    addressTag?: string
    currency: Currency | undefined
    network: Network | undefined
    openUnavailableCurrencyModal: UnavailableCurrencyModalRef['open'] | undefined
    getConvertToCurrency: (currencyCode: string | undefined) => string | undefined
}

const dataMerchant = 'addresses-table-address'

export function AddressCell({
    context,
    address,
    currency,
    network,
    openUnavailableCurrencyModal,
    addressTag,
    getConvertToCurrency,
}: Props) {
    const { token } = useToken()
    const { value: isPopoverOpen, setValue: setIsPopoverOpen } = useBoolean(false)
    const addressPayinEnabled = isCurrencyDepositAvailable(currency)

    const popoverContent = addressPayinEnabled ? (
        <PayingEnabledContent
            context={context}
            currency={currency}
            network={network}
            address={address}
            addressTag={addressTag}
            dataMerchant={dataMerchant}
            getConvertToCurrency={getConvertToCurrency}
        />
    ) : (
        <PayinDisabledContent
            closePopover={() => setIsPopoverOpen(false)}
            openUnavailableCurrencyModal={openUnavailableCurrencyModal}
            currency={currency}
            network={network}
            dataMerchant={dataMerchant}
        />
    )

    return (
        <Popover
            title={popoverContent}
            placement="bottom"
            open={isPopoverOpen}
            overlayInnerStyle={{ padding: '8px 12px', maxWidth: 358 }}
            onOpenChange={setIsPopoverOpen}
            data-merchant="address-popover"
        >
            <Flex align="center" gap={4}>
                <Flex vertical style={{ maxWidth: 115 }}>
                    <EllipsisMiddle
                        prefixCount={6}
                        suffixCount={6}
                        divider="."
                        style={{ fontSize: 12 }}
                        data-merchant="address-popover-hint"
                    >
                        {address}
                    </EllipsisMiddle>
                    <Text type="secondary" ellipsis style={{ fontSize: 12 }} data-merchant="addresses-table-addressTag">
                        {addressTag}
                    </Text>
                </Flex>
                {!addressPayinEnabled && (
                    <Flex>
                        <AppSvg name={assets.alertCircle} size={16} color={token.colorError} />
                    </Flex>
                )}
            </Flex>
        </Popover>
    )
}
