/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CalcWithdrawalAmountsRequestAmount } from './CalcWithdrawalAmountsRequestAmount';
import {
    CalcWithdrawalAmountsRequestAmountFromJSON,
    CalcWithdrawalAmountsRequestAmountFromJSONTyped,
    CalcWithdrawalAmountsRequestAmountToJSON,
} from './CalcWithdrawalAmountsRequestAmount';

/**
 * 
 * @export
 * @interface CreateWithdrawalRequest
 */
export interface CreateWithdrawalRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateWithdrawalRequest
     */
    totpCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWithdrawalRequest
     */
    totpBackup?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWithdrawalRequest
     */
    creditCurrency?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWithdrawalRequest
     */
    receiveCurrency: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWithdrawalRequest
     */
    nominalReceiveCurrency?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWithdrawalRequest
     */
    receiveNetwork?: string;
    /**
     * 
     * @type {CalcWithdrawalAmountsRequestAmount}
     * @memberof CreateWithdrawalRequest
     */
    amount: CalcWithdrawalAmountsRequestAmount;
    /**
     * 
     * @type {string}
     * @memberof CreateWithdrawalRequest
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWithdrawalRequest
     */
    addressTag?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWithdrawalRequest
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWithdrawalRequest
     */
    orderId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWithdrawalRequest
     */
    idempotencyKey?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWithdrawalRequest
     */
    verificationCode?: string;
}

/**
 * Check if a given object implements the CreateWithdrawalRequest interface.
 */
// @ts-ignore
export function instanceOfCreateWithdrawalRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "receiveCurrency" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "address" in value;

    return isInstance;
}

// @ts-ignore
export function CreateWithdrawalRequestFromJSON(json: any): CreateWithdrawalRequest {
    return CreateWithdrawalRequestFromJSONTyped(json, false);
}

// @ts-ignore
export function CreateWithdrawalRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateWithdrawalRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totpCode': !exists(json, 'totp_code') ? undefined : json['totp_code'],
        'totpBackup': !exists(json, 'totp_backup') ? undefined : json['totp_backup'],
        'creditCurrency': !exists(json, 'credit_currency') ? undefined : json['credit_currency'],
        'receiveCurrency': json['receive_currency'],
        'nominalReceiveCurrency': !exists(json, 'nominal_receive_currency') ? undefined : json['nominal_receive_currency'],
        'receiveNetwork': !exists(json, 'receive_network') ? undefined : json['receive_network'],
        'amount': CalcWithdrawalAmountsRequestAmountFromJSON(json['amount']),
        'address': json['address'],
        'addressTag': !exists(json, 'address_tag') ? undefined : json['address_tag'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'orderId': !exists(json, 'order_id') ? undefined : json['order_id'],
        'idempotencyKey': !exists(json, 'idempotency_key') ? undefined : json['idempotency_key'],
        'verificationCode': !exists(json, 'verification_code') ? undefined : json['verification_code'],
    };
}

// @ts-ignore
export function CreateWithdrawalRequestToJSON(value?: CreateWithdrawalRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totp_code': value.totpCode,
        'totp_backup': value.totpBackup,
        'credit_currency': value.creditCurrency,
        'receive_currency': value.receiveCurrency,
        'nominal_receive_currency': value.nominalReceiveCurrency,
        'receive_network': value.receiveNetwork,
        'amount': CalcWithdrawalAmountsRequestAmountToJSON(value.amount),
        'address': value.address,
        'address_tag': value.addressTag,
        'note': value.note,
        'order_id': value.orderId,
        'idempotency_key': value.idempotencyKey,
        'verification_code': value.verificationCode,
    };
}

