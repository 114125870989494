import { ModalResponsive } from '@merchant/shared/components'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import { DeleteForm, DeletionDisabled } from './views'
import type { TOTP } from '~api/instances/cabinet-api'

interface Props {
    id?: TOTP['id']
    isOpen: boolean
    close: () => void
    onDeleteSuccess: () => void
    isLastTwoFa: boolean
}

export function DeleteTwoFaModal({ close, isOpen, onDeleteSuccess, id, isLastTwoFa }: Props) {
    let content: React.ReactNode | undefined
    let modalDataMerchant: string | undefined

    if (isLastTwoFa) {
        content = <DeletionDisabled close={close} />
        modalDataMerchant = 'cannot-delete-last-2fa-modal'
    } else {
        content = <DeleteForm id={id} onDeleteSuccess={onDeleteSuccess} />
        modalDataMerchant = 'delete-2fa-confirmation-modal'
    }

    return (
        <ModalResponsive
            title={<FormattedMessage {...(isLastTwoFa ? lang.cannotRemoveTitle : lang.deleteTitle)} />}
            onCancel={close}
            open={isOpen}
            destroyOnClose
            footer={null}
            data-merchant={modalDataMerchant}
            centered
        >
            {content}
        </ModalResponsive>
    )
}
