import { AppCoin, CustomizedEmpty } from '@merchant/shared/components'
import { Divider, Flex, Spin, Table, Typography } from '@merchant/ui-kit/ant-design'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash-es'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { SnapshotsFilters } from '../SnapshotsFilters'
import { lang } from './lang'
import styles from './style.module.css'
import type { FilterForm } from '../SnapshotsFilters/const'
import type { ColumnsType } from '@merchant/ui-kit/ant-design/es/table'
import type { BalanceSnapshot } from '~api'
import type { Currencies } from '~api/types'
import { useBalanceSnapshots, useCurrencies } from '~api'
import { EmptyTable } from '~components'

const { Text } = Typography

type BalanceSnapshotItem = BalanceSnapshot['balances'][number]

const getColumns = (currencies: Currencies): ColumnsType<BalanceSnapshotItem> => {
    return [
        {
            title: (
                <span style={{ padding: '16px 8px' }}>
                    <FormattedMessage {...lang.currencyColumnTitle} />
                </span>
            ),
            dataIndex: 'currency',
            width: '50%',
            onHeaderCell: () => ({ className: styles.headerCell }),
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => b.currency.localeCompare(a.currency),
            render: (value: BalanceSnapshotItem['currency']) => {
                const currency = currencies[value]

                return (
                    <Flex gap={12} align="center">
                        <AppCoin currencyCode={value} />
                        <Flex vertical>
                            <Text data-merchant={`balances-snapshot-table-currency-code-${currency?.code}`}>
                                {currency?.code}
                            </Text>
                            <Text
                                data-merchant={`balances-snapshot-table-currency-name-${currency?.code}`}
                                type="secondary"
                                style={{ fontSize: 12 }}
                            >
                                {currency?.name}
                            </Text>
                        </Flex>
                    </Flex>
                )
            },
        },
        {
            title: (
                <span style={{ padding: '16px 8px' }}>
                    <FormattedMessage {...lang.balanceColumnTitle} />
                </span>
            ),
            dataIndex: 'amount',
            width: '50%',
            onHeaderCell: () => ({ className: styles.headerCell }),
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => Number(a.amount) - Number(b.amount),
            render: (value: BalanceSnapshotItem['amount'], record) => {
                return (
                    <Text data-merchant={`balances-snapshot-table-amount-${record.currency}`}>
                        {value} {record.currency}
                    </Text>
                )
            },
        },
    ]
}

export function SnapshotsTable() {
    const [filters, setFilters] = useState<Omit<FilterForm, 'dateType'>>({
        currencies: undefined,
        date: undefined,
        showZeroes: 'hidden',
    })

    const { data: currencies, isLoading: isLoadingCurrencies } = useCurrencies()
    const { data: snapshots, isLoading: isLoadingSnapshots } = useBalanceSnapshots({
        date: (filters.date ?? dayjs()).utc().startOf('day').toDate(),
        includeZeroes: filters.showZeroes === 'visible',
        currencies: filters.currencies || undefined,
    })

    return (
        <>
            <SnapshotsFilters onFinish={setFilters} filters={filters} isLoading={isLoadingSnapshots} />
            <Divider style={{ margin: 0 }} />
            {isLoadingCurrencies || isLoadingSnapshots ? (
                <Flex style={{ height: 300, width: '100%' }} align="center" justify="center">
                    <Spin size="large" data-merchant="balances-snapshots-loading-spin" />
                </Flex>
            ) : (
                <Table<BalanceSnapshotItem>
                    className={styles.table}
                    columns={getColumns(currencies || {})}
                    showSorterTooltip={false}
                    locale={{
                        emptyText: isEmpty(filters.currencies) ? (
                            <EmptyTable subtitleDescriptor={lang.noRecords} />
                        ) : (
                            <CustomizedEmpty data-merchant="balances-snapshot-table-empty" />
                        ),
                    }}
                    dataSource={snapshots?.balances}
                    rowKey={record => record.currency}
                    pagination={false}
                    scroll={{ y: 650 }}
                    data-merchant="balances-snapshot-table"
                />
            )}
        </>
    )
}
