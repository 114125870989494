import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)

function parseDateWithTz(value?: string, format: string = 'YYYY.MM.DD HH:mm:ss', tz?: string) {
    return dayjs.utc(value, format).tz(tz)
}

export function parseDateAndTime<T extends string | Date>(value?: T, format = 'YYYY.MM.DD HH:mm:ss') {
    try {
        const stringValue = value && value instanceof Date ? value.toISOString() : value?.toString()
        const parsedDate = parseDateWithTz(stringValue, format)
        if (!parsedDate.isValid()) {
            return undefined
        }

        const date = parsedDate.format('DD/MM/YY')
        const time = parsedDate.format('HH:mm:ss')

        return {
            date,
            time,
        }
    } catch {
        return undefined
    }
}

export function formatFilterRequestDates(dates: [dayjs.Dayjs | null, dayjs.Dayjs | null]) {
    const dateFromDayjsUTC = dates[0]?.set('m', 0).set('h', 0).set('s', 0).utc()
    const dateFrom = dateFromDayjsUTC?.toDate()

    const dateToDayjsUTC = dates[1]?.set('m', 59).set('h', 23).set('s', 59).utc()
    const dateTo = dateToDayjsUTC?.toDate()

    return { dateFrom, dateTo }
}
