import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    unavailableDeposits: {
        id: 'addresses-table.popup.unavailable_deposits',
        defaultMessage: 'Deposits in {currency}({network}) are temporarily unavailable!',
    },
    unavailableDepositsPrompt: {
        id: 'addresses-table.popup.unavailable_deposits_prompt',
        defaultMessage: 'What to do if {currency}({network}) has been recently sent to you?',
    },
    address: {
        id: 'addresses-table.popover.address',
        defaultMessage: 'Address',
    },
    addressTagNote: {
        id: 'addresses-table.popover.address_tag_note',
        defaultMessage:
            'Please make sure to enter the <b>{addressTagName}</b> before sending the deposit. Without the <b>{addressTagName}</b>, the deposit will be lost.',
    },
})
