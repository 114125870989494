import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    paymentCreatedModalTitle: {
        id: 'payment-constructor.modal.created_successfully_title',
        defaultMessage: 'The order has been created',
    },
    link: {
        id: 'payment-constructor.modal.link',
        defaultMessage: 'Link',
    },
    orderDetails: {
        id: 'payment-constructor.modal.order_details',
        defaultMessage: 'Order details',
    },
    feeIsPaidBy: {
        id: 'payment-constructor.modal.fee_is_paid_by',
        defaultMessage: 'Fee is paid by',
    },
    underpaymentSetting: {
        id: 'payment-constructor.modal.underpayment_setting',
        defaultMessage:
            'Underpayment setting: to allow up to a <b>{relative}%</b> shortfall from the requested payment amount{absoluteValue}',
    },
    underpaymentSettingAbsolute: {
        id: 'payment-constructor.modal.underpayment_setting_absolute',
        defaultMessage: ' (but no more than <b>{amount} {currency}</b>)',
    },
})
