/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Payment swap behaviour:
 *   * `auto` - Payment's holding currency is chosen automatically based
 * on the project's holding currencies config when the payment method is selected.
 *   * `fixed` - Payment's holding currency is explicitly specified.
 *   * `no` - Payment's holding currency is the same as the selected
 * payment method's payin currency.
 * 
 * @export
 * @enum {string}
 */
export enum PaymentSwapConfig {
    Auto = 'auto',
    Fixed = 'fixed',
    No = 'no'
}


// @ts-ignore
export function PaymentSwapConfigFromJSON(json: any): PaymentSwapConfig {
    return PaymentSwapConfigFromJSONTyped(json, false);
}

// @ts-ignore
export function PaymentSwapConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentSwapConfig {
    return json as PaymentSwapConfig;
}

// @ts-ignore
export function PaymentSwapConfigToJSON(value?: PaymentSwapConfig | null): any {
    return value as any;
}

