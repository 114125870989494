import { usePromise } from '@merchant/shared/hooks'
import { formatFilterRequestDates } from '@merchant/shared/utils'
import { Button } from '@merchant/ui-kit/ant-design'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash-es'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { DataRenderer } from './DataView'
import { lang } from './lang'
import { OrdersFiltersForm } from './OrdersFilters'
import type { FilterFormData, FilterFormDataQuery } from '../../types'
import type { ListPayments200Response, ListProjectPaymentsRequest, PaymentThumb } from '~api/instances/cabinet-api'
import { projectApi, useProject } from '~api'
import { apiRestKeys } from '~api/swr/keys'
import { useInfiniteFetcher } from '~api/swr/useInfiniteFetcher'
import { useQueryNavigation } from '~hooks'
import { getTruthyFields, downloadMedia } from '~utils'

// eslint-disable-next-line max-lines-per-function
export function Orders() {
    const { data: { id: projectId = '' } = {} } = useProject()
    const { filters, onSubmit, onClear } = useQueryNavigation<Partial<FilterFormData>, Partial<FilterFormDataQuery>>({
        filters: [
            'id',
            'state',
            'orderId',
            'customerEmail',
            'customerId',
            'payinAddress',
            'txnHash',
            'dateFrom',
            'dateTo',
        ],
        beforeWrite: {
            // TODO: get types right later
            date: date => {
                if (!Array.isArray(date)) {
                    return { dateFrom: undefined, dateTo: undefined }
                }

                return formatFilterRequestDates(date)
            },
        },
        afterRead: ({ dateFrom = null, dateTo = null, ...restFilters }) => {
            const from = dayjs(dateFrom)
            const to = dayjs(dateTo)

            if (!from.isValid() && !to.isValid()) {
                return restFilters
            }

            return {
                ...restFilters,
                ...formatFilterRequestDates([from.isValid() ? from : null, to.isValid() ? to : null]),
                date: [from.isValid() ? from : null, to.isValid() ? to : null],
            }
        },
    })
    const { truthy: filtersToApply, amount } = getTruthyFields(filters)

    const { send: exportAndDownloadCSV, isLoading: isExportingAndDownloadingCSV } = usePromise(
        (filters: ReturnType<typeof getTruthyFields>['truthy']) =>
            projectApi.exportProjectPayments({ ...filters, projectId }),
        {
            onSuccess: data =>
                downloadMedia({
                    data,
                    type: 'text/csv',
                    filename: 'orders.csv',
                }),
        }
    )

    const onExport = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation()
        exportAndDownloadCSV(filtersToApply)
    }

    const {
        data: orders = [],
        canFetchMore,
        error,
        fetchMore: handleFetchMore,
        isLoading,
        isLoadingMore,
        isPageDataEmpty,
    } = useInfiniteFetcher<ListPayments200Response, PaymentThumb[], ListProjectPaymentsRequest>(
        ({ previousPageData, limit, pageKey }) => ({
            params: {
                ...getTruthyFields(filters).truthy,
                limit,
                cursor: previousPageData?.cursorNext,
                projectId,
            },
            pageKey,
        }),
        projectApi.listProjectPayments.bind(projectApi),
        {
            limit: 10,
            dataPath: 'payments',
            revalidateIfStale: true,
            pageKey: apiRestKeys.getPayments,
        }
    )

    if (error) {
        // TODO: show appropriate error message
    }

    const isFiltersEmpty = isEmpty(filters)
    // filters are shown when either there are orders or there are set filters (meaning user tried to filter but got empty array)
    const areFiltersShown = !isPageDataEmpty || !isFiltersEmpty
    const isDataEmpty = isPageDataEmpty && isFiltersEmpty
    const shouldRenderShowMoreButton = !isPageDataEmpty && canFetchMore

    return (
        <>
            {areFiltersShown && (
                <OrdersFiltersForm
                    initialValues={filters}
                    isLoading={isLoading}
                    onFinish={onSubmit}
                    onReset={onClear}
                    extra={
                        <Button
                            data-merchant="orders-export-csv-button"
                            onClick={onExport}
                            loading={isExportingAndDownloadingCSV}
                        >
                            <FormattedMessage {...lang.exportCsv} />
                        </Button>
                    }
                    activeFiltersCount={amount}
                />
            )}
            <DataRenderer
                isDataEmpty={isDataEmpty}
                orders={orders}
                isLoading={isLoading}
                showMoreButton={
                    shouldRenderShowMoreButton && (
                        <Button
                            size="large"
                            block
                            onClick={handleFetchMore}
                            loading={isLoadingMore}
                            data-merchant="orders-show-more-button"
                        >
                            <FormattedMessage {...lang.showMore} />
                        </Button>
                    )
                }
            />
        </>
    )
}
