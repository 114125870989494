import { formatNumber, shortenRate } from '@merchant/shared/utils'
import { Skeleton, Space, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import type { SwapRate as SwapRateType } from '~api/instances/cabinet-api'
import { globalLang } from '~globalLang'

const { Text, Paragraph } = Typography

const rateErrorParagraphStyles: React.CSSProperties = {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 0,
}

interface Props {
    data?: SwapRateType
    error?: unknown
    isLoading: boolean
}

export function SwapRate({ data, error, isLoading }: Props) {
    if (error) {
        return (
            <Paragraph data-merchant="convert-error" type="danger" style={rateErrorParagraphStyles}>
                <FormattedMessage {...globalLang.somethingWentWrong} />
            </Paragraph>
        )
    }

    const rateText = data
        ? `1 ${data.currencyFrom} ≈ ${shortenRate(formatNumber(data.rate))} ${data.currencyTo}`
        : '...'

    return (
        <Skeleton
            active
            loading={isLoading}
            style={{ width: '65%' }}
            title={{ style: { height: 21 } }}
            paragraph={false}
        >
            <Space styles={{ item: { display: 'flex' } }}>
                <Text data-merchant="convert-exchange-rate">
                    <FormattedMessage {...lang.exchangeRate} />:{' '}
                    <Text data-merchant={null} type={isLoading ? 'secondary' : undefined}>
                        {rateText}
                    </Text>
                </Text>
            </Space>
        </Skeleton>
    )
}
