/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CallbackVersion } from './CallbackVersion';
import {
    CallbackVersionFromJSON,
    CallbackVersionFromJSONTyped,
    CallbackVersionToJSON,
} from './CallbackVersion';

/**
 * 
 * @export
 * @interface UpdateCallbackRequest
 */
export interface UpdateCallbackRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateCallbackRequest
     */
    totpCode?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCallbackRequest
     */
    totpBackup?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCallbackRequest
     */
    url?: string;
    /**
     * Whether to issue a new signing key.
     * @type {boolean}
     * @memberof UpdateCallbackRequest
     */
    publicKey?: boolean;
    /**
     * 
     * @type {CallbackVersion}
     * @memberof UpdateCallbackRequest
     */
    version?: CallbackVersion;
}

/**
 * Check if a given object implements the UpdateCallbackRequest interface.
 */
// @ts-ignore
export function instanceOfUpdateCallbackRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

// @ts-ignore
export function UpdateCallbackRequestFromJSON(json: any): UpdateCallbackRequest {
    return UpdateCallbackRequestFromJSONTyped(json, false);
}

// @ts-ignore
export function UpdateCallbackRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCallbackRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totpCode': !exists(json, 'totp_code') ? undefined : json['totp_code'],
        'totpBackup': !exists(json, 'totp_backup') ? undefined : json['totp_backup'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'publicKey': !exists(json, 'public_key') ? undefined : json['public_key'],
        'version': !exists(json, 'version') ? undefined : CallbackVersionFromJSON(json['version']),
    };
}

// @ts-ignore
export function UpdateCallbackRequestToJSON(value?: UpdateCallbackRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totp_code': value.totpCode,
        'totp_backup': value.totpBackup,
        'url': value.url,
        'public_key': value.publicKey,
        'version': CallbackVersionToJSON(value.version),
    };
}

