import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { useBreakpoint } from '@merchant/shared/hooks'
import { Button, Divider, Flex, Input, Table, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage, useIntl } from 'react-intl'
import { addAddressButtonDataMerchant } from '../const'
import { lang } from '../lang'
import { useData } from '../useData'
import { getColumns } from './getColumns'
import styles from './style.module.css'
import { TableEmpty } from './TableEmpty'
import type { GetProps } from '@merchant/ui-kit/ant-design'
import type { WhitelistInnerObject } from '~features/WhitelistPage/types'
import { AddWhitelistedAddressModal, DeleteWhitelistedAddressModal } from '~features/WhitelistPage/components'
import { lang as commonLang } from '~features/WhitelistPage/lang'

const { Text } = Typography

const tableScroll: GetProps<typeof Table<WhitelistInnerObject>>['scroll'] = {
    y: 500,
}

export function TableView() {
    const breakpoint = useBreakpoint()
    const intl = useIntl()
    const {
        currencies,
        filteredData,
        handleDeleteClick,
        isAddModalOpen,
        isDeleteModalOpen,
        openAddModal,
        selectedWhitelist,
        setSearchValue,
        closeAddModal,
        closeDeleteModal,
        allNetworks,
        setSelectedWhitelist,
        isEditWhitelistAllowed,
        isWhitelistEmpty,
    } = useData()

    return (
        <>
            <AddWhitelistedAddressModal close={closeAddModal} open={isAddModalOpen} />
            <DeleteWhitelistedAddressModal
                selectedWhitelist={selectedWhitelist}
                open={isDeleteModalOpen}
                afterClose={() => setSelectedWhitelist(null)}
                close={closeDeleteModal}
            />
            <Flex justify="space-between">
                <Text style={{ fontSize: 16 }} data-merchant={null}>
                    <FormattedMessage {...lang.walletAddresses} />
                </Text>
                <Flex gap={12}>
                    {!isWhitelistEmpty && (
                        <Input
                            allowClear
                            onChange={e => setSearchValue(e.target.value)}
                            style={{ maxWidth: 180 }}
                            prefix={<AppSvg name={assets.searchSm} />}
                            placeholder={intl.formatMessage(lang.searchPlaceholder)}
                            data-merchant="whitelist-search-input"
                        />
                    )}
                    <Button
                        disabled={!isEditWhitelistAllowed}
                        type="primary"
                        onClick={openAddModal}
                        style={{ width: 170 }}
                        data-merchant={addAddressButtonDataMerchant}
                    >
                        <FormattedMessage {...commonLang.addNewAddress} />
                    </Button>
                </Flex>
            </Flex>
            <Divider style={{ marginBlock: '20px 0' }} />
            <Table
                locale={{ emptyText: <TableEmpty /> }}
                size="large"
                tableLayout="fixed"
                scroll={tableScroll}
                pagination={false}
                showHeader={!isWhitelistEmpty}
                columns={getColumns({
                    data: filteredData,
                    handleDeleteClick,
                    breakpoint,
                    isEditWhitelistAllowed,
                    currencies,
                    allNetworks,
                })}
                dataSource={filteredData}
                rowKey={whitelistedAddress => whitelistedAddress.key}
                className={styles.table}
                onRow={record => ({
                    'data-merchant': `${record.key}-whitelist-item`,
                    style: undefined,
                })}
                data-merchant="whitelist-table"
            />
        </>
    )
}
