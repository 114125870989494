import { isEmpty } from 'lodash-es'
import { lang } from './lang'
import type { IntlShape } from 'react-intl'
import type { Network } from '~api/instances/cabinet-api'

type GetNetworkSelectProperties = (params: {
    intl: IntlShape
    isUniversalAddress: boolean
    selectedCurrency?: string
    selectedCurrencyNetworks?: Network[] | null
}) => { disabled: boolean; tooltipTitle: string | undefined }

export const getNetworkSelectProperties: GetNetworkSelectProperties = ({
    intl,
    isUniversalAddress,
    selectedCurrency,
    selectedCurrencyNetworks,
}) => {
    if (!isUniversalAddress && !selectedCurrency) {
        return {
            disabled: true,
            tooltipTitle: intl.formatMessage(lang.networkSelectCurrencyTooltip),
        }
    } else if (!!selectedCurrency && isEmpty(selectedCurrencyNetworks)) {
        return {
            disabled: true,
            tooltipTitle: intl.formatMessage(lang.noNetworkForCurrencyTooltip),
        }
    }

    return {
        disabled: false,
        tooltipTitle: undefined,
    }
}
