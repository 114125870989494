import { createGenericContext } from '@merchant/shared/utils'
import { useBoolean } from 'usehooks-ts'
import type { PropsWithChildren } from 'react'

interface Context {
    isSideMenuOpen: boolean
    toggleSideMenu: () => void
    closeSideMenu: () => void
}

const [useUIContext, Provider] = createGenericContext<Context>()

function UIContextProvider({ children }: PropsWithChildren) {
    const { value: isSideMenuOpen, toggle: toggleSideMenu, setFalse: closeSideMenu } = useBoolean(false)

    return (
        <Provider
            value={{
                isSideMenuOpen,
                toggleSideMenu,
                closeSideMenu,
            }}
        >
            {children}
        </Provider>
    )
}

export { useUIContext, UIContextProvider }
