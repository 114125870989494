export const inputNumberStyles: React.CSSProperties = { width: '100%' }
export const switchBtnWrapperStyles: React.CSSProperties = { position: 'relative', width: '100%', height: 0 }
export const switchBtnStyles: React.CSSProperties = {
    height: 'auto',
    width: 'auto',
    padding: 0,
    position: 'absolute',
    right: 0,
    bottom: -10,
}
