import { isKeyOfObject } from '@merchant/shared/utils'
import { Flex, Form, Typography, Select } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import type { FormItemProps } from '@merchant/ui-kit/ant-design'
import type { OrderTypeSelectOption } from '~features/PaymentConstructor/types'
import { PaymentType } from '~api/instances/cabinet-api'
import { constructorTypesLabel, typeDescriptionMap } from '~features/PaymentConstructor/const'
import { useOrderType } from '~features/PaymentConstructor/hooks'
import { globalLang } from '~globalLang'

const { Text } = Typography

export function TypeSelectItem(props: FormItemProps) {
    const { orderType, onTypeChange, permittedTypes } = useOrderType()
    const form = Form.useFormInstance()

    const orderTypeSelectOptions: OrderTypeSelectOption[] = permittedTypes.map(type => ({
        value: type,
        label: <FormattedMessage {...constructorTypesLabel[type]} />,
    }))

    return (
        <Form.Item
            {...props}
            rules={[
                {
                    required: true,
                    message: (
                        <span data-merchant="order-type-required-error">
                            <FormattedMessage {...globalLang.requiredFieldMessage} />
                        </span>
                    ),
                },
            ]}
            required
            label={<FormattedMessage {...lang.type} />}
            style={{ marginTop: 16 }}
        >
            <Select
                options={orderTypeSelectOptions}
                style={{ width: '100%' }}
                data-merchant="constructor-type-select"
                onChange={value => {
                    onTypeChange(value)
                    if (value === PaymentType.Deposit) {
                        form.setFieldsValue({ nominalAmount: '' })
                    }
                }}
                optionRender={data => (
                    <Flex vertical>
                        {data.label}
                        <Text type="secondary" data-merchant={null}>
                            {isKeyOfObject(data.value, typeDescriptionMap) && (
                                <FormattedMessage {...typeDescriptionMap[data.value]} />
                            )}
                        </Text>
                    </Flex>
                )}
                defaultActiveFirstOption
                value={orderType}
            />
        </Form.Item>
    )
}
