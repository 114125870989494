/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    NominalOrPayinAmountOneOf,
    instanceOfNominalOrPayinAmountOneOf,
    NominalOrPayinAmountOneOfFromJSON,
    NominalOrPayinAmountOneOfFromJSONTyped,
    NominalOrPayinAmountOneOfToJSON,
} from './NominalOrPayinAmountOneOf';
import {
    NominalOrPayinAmountOneOf1,
    instanceOfNominalOrPayinAmountOneOf1,
    NominalOrPayinAmountOneOf1FromJSON,
    NominalOrPayinAmountOneOf1FromJSONTyped,
    NominalOrPayinAmountOneOf1ToJSON,
} from './NominalOrPayinAmountOneOf1';

/**
 * @type NominalOrPayinAmount
 * 
 * @export
 */
export type NominalOrPayinAmount = NominalOrPayinAmountOneOf | NominalOrPayinAmountOneOf1;

// @ts-ignore
export function NominalOrPayinAmountFromJSON(json: any): NominalOrPayinAmount {
    return NominalOrPayinAmountFromJSONTyped(json, false);
}

// @ts-ignore
export function NominalOrPayinAmountFromJSONTyped(json: any, ignoreDiscriminator: boolean): NominalOrPayinAmount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...NominalOrPayinAmountOneOfFromJSONTyped(json, true), ...NominalOrPayinAmountOneOf1FromJSONTyped(json, true) };
}

// @ts-ignore
export function NominalOrPayinAmountToJSON(value?: NominalOrPayinAmount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfNominalOrPayinAmountOneOf(value)) {
        return NominalOrPayinAmountOneOfToJSON(value as NominalOrPayinAmountOneOf);
    }
    if (instanceOfNominalOrPayinAmountOneOf1(value)) {
        return NominalOrPayinAmountOneOf1ToJSON(value as NominalOrPayinAmountOneOf1);
    }

    return {};
}

