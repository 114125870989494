import type { Props } from '.'
import type { GlobalToken } from '@merchant/ui-kit/ant-design'
import { assets } from '~assets/nameMap'

export const defaultIcons: Record<Props['type'], string> = {
    error: assets.xCircle,
    success: assets.checkCircle,
    warning: assets.warning,
}

export const typeIconColorMap: Record<
    Props['type'],
    Extract<keyof GlobalToken, 'colorError' | 'colorSuccess' | 'colorWarning'>
> = {
    error: 'colorError',
    success: 'colorSuccess',
    warning: 'colorWarning',
}
