import { Flex, Typography, type SelectProps } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import type { Network } from '~api'
import { globalLang } from '~globalLang'

const { Text } = Typography

export const mapNetworksToOptions = ({
    networks,
    dataMerchantPrefix,
    config = {},
}: {
    networks: Network[]
    dataMerchantPrefix: string
    config?: { isDisabledPredicate?: (network: Network) => boolean }
}): SelectProps['options'] =>
    networks.map(network => {
        const isDisabled = config.isDisabledPredicate?.(network)

        return {
            label: getNetworkLabel({ network, isDisabled, isListItem: true }),
            value: network.code,
            disabled: isDisabled,
            'data-merchant': `${dataMerchantPrefix}-network-item-${network.code}`.toLowerCase(),
        }
    })

export function getNetworkLabel({
    network,
    isDisabled = false,
    isListItem,
}: {
    network: Network | undefined
    isDisabled?: boolean
    isListItem: boolean
}) {
    if (!network) {
        return null
    }

    return (
        <Flex align="center" justify="space-between">
            <Text data-merchant={null} ellipsis={{ tooltip: true }}>
                <Text data-merchant={null} style={{ fontWeight: 700 }}>
                    {network.code}
                </Text>
                &nbsp;
                <Text data-merchant={null}>{network.name}</Text>
                {network.protocol !== network.name && (
                    <Text type={isListItem ? 'secondary' : undefined} data-merchant={null}>
                        &nbsp;·&nbsp;{network.protocol}
                    </Text>
                )}
            </Text>
            {isDisabled && (
                <Text data-merchant={null} type="secondary" style={{ marginLeft: 4 }}>
                    <FormattedMessage {...globalLang.networkTempUnavailable} />
                </Text>
            )}
        </Flex>
    )
}
