/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectTeamMember
 */
export interface ProjectTeamMember {
    /**
     * 
     * @type {string}
     * @memberof ProjectTeamMember
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTeamMember
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTeamMember
     */
    email: string;
    /**
     * Whether the user has accepted the invitation to join the project.
     * @type {boolean}
     * @memberof ProjectTeamMember
     */
    joined: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectTeamMember
     */
    roles: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectTeamMember
     * @deprecated
     */
    _2faEnabled: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ProjectTeamMember
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProjectTeamMember
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the ProjectTeamMember interface.
 */
// @ts-ignore
export function instanceOfProjectTeamMember(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "joined" in value;
    isInstance = isInstance && "roles" in value;
    isInstance = isInstance && "_2faEnabled" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;

    return isInstance;
}

// @ts-ignore
export function ProjectTeamMemberFromJSON(json: any): ProjectTeamMember {
    return ProjectTeamMemberFromJSONTyped(json, false);
}

// @ts-ignore
export function ProjectTeamMemberFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectTeamMember {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'email': json['email'],
        'joined': json['joined'],
        'roles': json['roles'],
        '_2faEnabled': json['2fa_enabled'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
    };
}

// @ts-ignore
export function ProjectTeamMemberToJSON(value?: ProjectTeamMember | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'email': value.email,
        'joined': value.joined,
        'roles': value.roles,
        '2fa_enabled': value._2faEnabled,
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
    };
}

