import { defineMessages, type MessageDescriptor } from 'react-intl'
import { PermissionGroup } from '~api/instances/cabinet-api'

export const permissionGroupsLang = defineMessages({
    [PermissionGroup.OrdersAndTransactions]: {
        id: 'permission-group.orders_and_transactions',
        defaultMessage: 'Orders & Transactions',
    },
    [PermissionGroup.Swaps]: {
        id: 'permission-group.swaps',
        defaultMessage: 'Convert',
    },
    [PermissionGroup.Withdrawals]: {
        id: 'permission-group.withdrawals',
        defaultMessage: 'Withdraw',
    },
    [PermissionGroup.GeneralInfo]: {
        id: 'permission-group.merchant_general_info',
        defaultMessage: 'Merchant: General info',
    },
    [PermissionGroup.CurrencySettings]: {
        id: 'permission-group.merchant_currency_settings',
        defaultMessage: 'My merchant: Currency settings',
    },
    [PermissionGroup.PaymentSettings]: {
        id: 'permission-group.merchant_payment_settings',
        defaultMessage: 'My merchant: Payment settings',
    },
    [PermissionGroup.TeamManagement]: {
        id: 'permission-group.merchant_team_management',
        defaultMessage: 'My merchant: Team',
    },
    [PermissionGroup.Integrations]: {
        id: 'permission-group.integrations',
        defaultMessage: 'Integrations',
    },
} satisfies Record<PermissionGroup, MessageDescriptor>)
