import { ConfigProvider, Flex } from '@merchant/ui-kit/ant-design'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { AddWhitelistAddressButton } from './AddWhitelistAddressButton'
import { lang } from './lang'
import { getWhitelistDetails, getWhitelistSelectData } from './utils'
import { WhitelistedAddressSelectOption } from './WhitelistedAddressSelectOption'
import type { WhitelistOptionType } from './types'
import type { SelectProps } from '@merchant/ui-kit/ant-design'
import type { BaseOptionType } from '@merchant/ui-kit/ant-design/es/select'
import type { WithdrawalCurrency } from '~features/ActionModal/types'
import type { WithdrawalCryptoFormData } from '~features/ActionModal/views/WithdrawalCrypto/types'
import { useWhitelist } from '~api'
import { SearchableSelect } from '~components'
import { useNetworks } from '~hooks'

interface Props extends Omit<SelectProps, 'data-merchant'> {
    selectedCurrencyData: WithdrawalCurrency | undefined
    afterSelect: (values: Pick<WithdrawalCryptoFormData, 'address' | 'addressTag' | 'currency' | 'network'>) => void
}

export function WhitelistedAddressSelect({ selectedCurrencyData, afterSelect, value, ...props }: Props) {
    const intl = useIntl()
    const { data: whitelist, isLoading } = useWhitelist()
    const { networks } = useNetworks()

    const selectedWhitelist = whitelist?.addresses.find(({ key }) => value === key)

    const { isFilteredWhitelistEmpty, options } = useMemo(
        () => getWhitelistSelectData(whitelist, selectedCurrencyData),
        [selectedCurrencyData, whitelist]
    )

    const hasWhitelistedAddresses = !isLoading && !isFilteredWhitelistEmpty

    const handleSelect = (_: string, option: WhitelistOptionType) => {
        afterSelect({
            address: option.address,
            addressTag: option.addressTag,
            currency: option.currency,
            network: option.network,
        })
    }

    return (
        <ConfigProvider theme={{ components: { Select: { optionPadding: '8px 12px 0' } } }}>
            <div style={{ position: 'relative' }}>
                <SearchableSelect<string, WhitelistOptionType>
                    data-merchant="withdrawal-whitelisted-address-select"
                    allowClear
                    notFoundContent={null}
                    onSelect={handleSelect}
                    dropdownRender={menu => (
                        <>
                            {menu}
                            <AddWhitelistAddressButton
                                selectedCurrencyCode={selectedCurrencyData?.code}
                                data-merchant="withdrawal-whitelisted-address-select-add-button"
                            />
                        </>
                    )}
                    filterOption={(input, option) =>
                        Boolean(option?.key?.toLowerCase().includes(input.replace(/ /g, '').toLowerCase()))
                    }
                    loading={isLoading}
                    options={options}
                    optionFilterProp="value"
                    showSearch
                    disabled={!isLoading && isFilteredWhitelistEmpty}
                    optionRender={(option: BaseOptionType) => <WhitelistedAddressSelectOption data={option.data} />}
                    optionIdPostfix={option => option.data.address}
                    placeholder={
                        isLoading || hasWhitelistedAddresses
                            ? intl.formatMessage(lang.selectWalletAddressPlaceholder)
                            : undefined
                    }
                    value={value}
                    {...props}
                />
                {!isLoading && !hasWhitelistedAddresses && (
                    <AddWhitelistAddressButton
                        style={{ position: 'absolute', left: 0, top: 0 }}
                        selectedCurrencyCode={selectedCurrencyData?.code}
                        data-merchant="withdrawal-whitelisted-address-add-button"
                    />
                )}
                {selectedWhitelist && (
                    <Flex
                        vertical
                        gap={8}
                        style={{ marginTop: 12 }}
                        data-merchant="withdrawal-whitelisted-address-selected-text"
                    >
                        {getWhitelistDetails(selectedWhitelist, networks, {
                            showAddressTooltip: true,
                        })}
                    </Flex>
                )}
            </div>
        </ConfigProvider>
    )
}
