import { Button, Flex, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { steps } from './const'
import { useData } from './useData'
import { isFiatWithdrawalDataFulfilled } from './utils'
import {
    LoadingSkeleton,
    WithdrawalFiatConfirmation,
    WithdrawalFiatFinish,
    WithdrawalFiatInput,
    WithdrawalFiatProhibited,
    WithdrawalFiatSummary,
} from './views'
import type { WithdrawalFiatFormData } from './types'
import { ActionSuspendedContent } from '~components/ActionSuspended'
import { SuspensionContext } from '~components/ActionSuspended/types'
import { globalLang } from '~globalLang'

const { Text } = Typography

export function WithdrawalFiat() {
    const {
        step,
        isDataLoading,
        blocklistData,
        isFiatWithdrawalAllowed,
        withdrawalData,
        setWithdrawalData,
        closeModal,
        setStep,
    } = useData()

    if (isDataLoading) {
        return <LoadingSkeleton />
    }

    if (!isFiatWithdrawalAllowed) {
        return <WithdrawalFiatProhibited />
    }

    if (blocklistData?.blocked) {
        return (
            <ActionSuspendedContent
                context={SuspensionContext.Withdrawal}
                blockReason={blocklistData.blockItems[0]?.reason}
                expiresAt={blocklistData.blockItems[0]?.expiresAt}
                onClose={closeModal}
            />
        )
    }

    if (step === steps.withdrawalFiatInput) {
        return (
            <WithdrawalFiatInput
                onSuccess={withdrawalFiatData => {
                    setWithdrawalData(withdrawalFiatData)
                    setStep(steps.withdrawalFiatSummary)
                }}
                withdrawalFiatData={withdrawalData}
            />
        )
    }

    const views = {
        [steps.withdrawalFiatSummary]: (data: WithdrawalFiatFormData) => (
            <WithdrawalFiatSummary
                onSuccess={() => setStep(steps.withdrawalFiatConfirmation)}
                withdrawalFormData={data}
            />
        ),
        [steps.withdrawalFiatConfirmation]: (data: WithdrawalFiatFormData) => (
            <WithdrawalFiatConfirmation
                onSuccess={() => {
                    setStep(steps.withdrawalFiatFinish)
                }}
                withdrawalFormData={data}
            />
        ),
        [steps.withdrawalFiatFinish]: (data: WithdrawalFiatFormData) => (
            <WithdrawalFiatFinish
                onSuccess={closeModal}
                // TODO: backend returns requestId, but we need txId
                // decided to get rid of txId(request ID) for now
                // txId={txId}
                withdrawalFiatFormData={data}
                /* it doesn't seem like we will have status other than success
                 * but we do have failed state in design,
                 * so in case we do, we should handle it here
                 */
                status="success"
                onTryAgain={() => {
                    setStep(steps.withdrawalFiatInput)
                }}
            />
        ),
    }

    const view = views[step]

    if (!isFiatWithdrawalDataFulfilled(withdrawalData)) {
        return (
            <Flex vertical align="center" gap={12}>
                <Text data-merchant="withdrawal-fiat-failed-text" style={{ fontSize: 16 }}>
                    <FormattedMessage {...globalLang.somethingWentWrong} />
                </Text>
                <Button
                    block
                    onClick={() => setStep(steps.withdrawalFiatInput)}
                    size="large"
                    type="primary"
                    data-merchant="withdrawal-fiat-failed-try-again-button"
                >
                    <FormattedMessage {...globalLang.goBack} />
                </Button>
            </Flex>
        )
    }

    return view(withdrawalData)
}
