import { AmountInput } from '@merchant/shared/components'
import { useBreakpoint } from '@merchant/shared/hooks'
import { Flex, Form, Tooltip } from '@merchant/ui-kit/ant-design'
import Big from 'big.js'
import { isEmpty } from 'lodash-es'
import { useEffect, useState, type ComponentProps } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { lang } from '../lang'
import { getCurrencyOptions, getFlexWrapperProps } from './utils'
import type { FormItemProps } from '@merchant/ui-kit/ant-design'
import type { Balance, Currency } from '~api/instances/cabinet-api'
import type { ConstructorTypes } from '~features/PaymentConstructor/types'
import { useBalances, useCurrencies } from '~api'
import { PaymentType } from '~api/instances/cabinet-api'
import { FiatCryptoSelect } from '~components'
import { useCurrencyPredicates } from '~features/PaymentConstructor/hooks'
import { newOrderFormKeys } from '~features/PaymentConstructor/view/OrderConstructor/const'
import { globalLang } from '~globalLang'

function TooltipAmountInput(props: ComponentProps<typeof AmountInput>) {
    const tooltipDataMerchant = props['data-merchant'] ? `${props['data-merchant']}-tooltip` : ''

    return (
        <Tooltip
            data-merchant={tooltipDataMerchant}
            overlayInnerStyle={{ fontSize: 12 }}
            title={props.disabled ? <FormattedMessage {...lang.depositAmountInputTooltip} /> : null}
            placement="bottom"
        >
            <AmountInput style={{ width: '100%' }} {...props} />
        </Tooltip>
    )
}

interface Props extends FormItemProps {
    type: ConstructorTypes
}

export function NominalAmountItem({ type, ...rest }: Props) {
    const formInstance = Form.useFormInstance()
    const intl = useIntl()
    const breakpoints = useBreakpoint()
    const { data: currencies, isLoading: isCurrenciesLoading } = useCurrencies()
    const { data: balances = [], isLoading: isBalancesLoading } = useBalances()
    const [currencyOptions, setCurrencyOptions] = useState<(Currency & Partial<Balance>)[]>([])
    const { predicates, isLoading: isLoadingPredicates } = useCurrencyPredicates()

    useEffect(() => {
        if (!currencies || !isEmpty(currencyOptions)) {
            return
        }

        setCurrencyOptions(getCurrencyOptions(balances, currencies))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currencies, balances])

    const handleDropdownVisibleChange = (isOpen: boolean) => {
        if (isOpen && currencies) {
            setCurrencyOptions(getCurrencyOptions(balances, currencies))
        }
    }

    const isDataLoading = isCurrenciesLoading || isBalancesLoading || isLoadingPredicates
    const isDeposit = type === PaymentType.Deposit

    useEffect(() => {
        formInstance.setFields([
            { name: [newOrderFormKeys.recommendedAmount, 'nominal'], value: undefined },
            { name: newOrderFormKeys.nominalAmount, value: undefined },
        ])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDeposit])

    return (
        <Flex {...getFlexWrapperProps(breakpoints)}>
            <Form.Item
                {...rest}
                name={isDeposit ? [newOrderFormKeys.recommendedAmount, 'nominal'] : newOrderFormKeys.nominalAmount}
                normalize={value => (isEmpty(value) || !Number(value) ? undefined : value)}
                required={!isDeposit}
                validateFirst
                tooltip={isDeposit && <FormattedMessage {...lang.recommendedAmountTooltip} />}
                rules={[
                    {
                        required: !isDeposit,
                        message: (
                            <span data-merchant="order-amount-required-error">
                                <FormattedMessage {...globalLang.requiredFieldMessage} />
                            </span>
                        ),
                    },
                    {
                        validator(_, value) {
                            if (isDeposit) {
                                return Promise.resolve()
                            }

                            return Big(value || '0').lte(0) ? Promise.reject() : Promise.resolve()
                        },
                        message: (
                            <span data-merchant="order-amount-too-low-error">
                                <FormattedMessage {...globalLang.amountShouldBeGreaterThanZero} />
                            </span>
                        ),
                    },
                ]}
                style={{ flex: 1, marginBottom: breakpoints.md ? 24 : 16 }}
                label={<FormattedMessage {...(isDeposit ? lang.recommendedAmount : lang.amount)} />}
                dependencies={!isDeposit ? [newOrderFormKeys.type] : []}
            >
                <TooltipAmountInput
                    data-merchant="order-amount-input"
                    placeholder={intl.formatMessage(lang.amountPlaceholder)}
                />
            </Form.Item>
            <Form.Item
                name={newOrderFormKeys.nominalCurrency}
                required
                rules={[
                    {
                        required: true,
                        message: (
                            <span data-merchant="order-currency-required-error">
                                <FormattedMessage {...globalLang.requiredFieldMessage} />
                            </span>
                        ),
                    },
                ]}
                style={{ flex: 1, marginBottom: breakpoints.md ? 24 : 16 }}
                label={<FormattedMessage {...lang.currency} />}
            >
                <FiatCryptoSelect
                    style={{ width: '100%' }}
                    onDropdownVisibleChange={handleDropdownVisibleChange}
                    placeholder={intl.formatMessage(lang.currencyPlaceholder)}
                    options={currencyOptions}
                    data-merchant="order-currency-select"
                    optionDataMerchantPrefix="order-currency"
                    loading={isDataLoading}
                    optionPredicate={currency => predicates.every(predicate => predicate(currency))}
                />
            </Form.Item>
        </Flex>
    )
}
