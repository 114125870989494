/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddProjectTeamMemberRequest } from './AddProjectTeamMemberRequest';
import {
    AddProjectTeamMemberRequestFromJSON,
    AddProjectTeamMemberRequestFromJSONTyped,
    AddProjectTeamMemberRequestToJSON,
} from './AddProjectTeamMemberRequest';

/**
 * 
 * @export
 * @interface AddProjectTeamMembersRequest
 */
export interface AddProjectTeamMembersRequest {
    /**
     * 
     * @type {string}
     * @memberof AddProjectTeamMembersRequest
     */
    verificationCode: string;
    /**
     * 
     * @type {string}
     * @memberof AddProjectTeamMembersRequest
     */
    totpCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AddProjectTeamMembersRequest
     */
    totpBackup?: string;
    /**
     * 
     * @type {Array<AddProjectTeamMemberRequest>}
     * @memberof AddProjectTeamMembersRequest
     */
    teamMembers: Array<AddProjectTeamMemberRequest>;
}

/**
 * Check if a given object implements the AddProjectTeamMembersRequest interface.
 */
// @ts-ignore
export function instanceOfAddProjectTeamMembersRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "verificationCode" in value;
    isInstance = isInstance && "teamMembers" in value;

    return isInstance;
}

// @ts-ignore
export function AddProjectTeamMembersRequestFromJSON(json: any): AddProjectTeamMembersRequest {
    return AddProjectTeamMembersRequestFromJSONTyped(json, false);
}

// @ts-ignore
export function AddProjectTeamMembersRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddProjectTeamMembersRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'verificationCode': json['verification_code'],
        'totpCode': !exists(json, 'totp_code') ? undefined : json['totp_code'],
        'totpBackup': !exists(json, 'totp_backup') ? undefined : json['totp_backup'],
        'teamMembers': ((json['team_members'] as Array<any>).map(AddProjectTeamMemberRequestFromJSON)),
    };
}

// @ts-ignore
export function AddProjectTeamMembersRequestToJSON(value?: AddProjectTeamMembersRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'verification_code': value.verificationCode,
        'totp_code': value.totpCode,
        'totp_backup': value.totpBackup,
        'team_members': ((value.teamMembers as Array<any>).map(AddProjectTeamMemberRequestToJSON)),
    };
}

