import { assets } from '@merchant/shared/assets/nameMap'
import { InlineLinkButton, AppSvg } from '@merchant/shared/components'
import { Popconfirm, Space } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from '../../lang'
import type { OnActionButtonClick } from '../../views/DataView/types'
import type { ProjectTeamMember } from '~api/instances/cabinet-api'

interface Props {
    member: ProjectTeamMember
    onActionButtonClick: OnActionButtonClick
    hidden: boolean
}

export function MemberActionButtons({ member, onActionButtonClick, hidden }: Props) {
    if (hidden) {
        return null
    }

    const { id: memberId } = member

    return (
        <Space wrap={false} align="center" size={8}>
            <InlineLinkButton
                icon={<AppSvg size={20} name={assets.edit} />}
                data-merchant={`team-management-edit-button-${memberId}`}
                onClick={() => onActionButtonClick('edit', member)}
            />
            <Popconfirm
                align={{ offset: [-1, 0] }}
                title={<FormattedMessage {...lang.deleteClickConfirm} />}
                icon={null}
                placement="bottom"
                okButtonProps={{
                    type: 'primary',
                    size: 'middle',
                    'data-merchant': `team-management-delete-confirm-ok-button-${memberId}`,
                }}
                okText={<FormattedMessage {...lang.deleteClickConfirmOkButton} />}
                cancelButtonProps={{
                    type: 'default',
                    size: 'middle',
                    'data-merchant': `team-management-delete-confirm-cancel-button-${memberId}`,
                }}
                cancelText={<FormattedMessage {...lang.deleteClickConfirmCancelButton} />}
                onConfirm={() => onActionButtonClick('delete', member)}
                data-merchant="add-members-table-delete-confirm"
            >
                <InlineLinkButton
                    icon={<AppSvg size={20} name={assets.trash} />}
                    data-merchant={`team-management-delete-button-${memberId}`}
                />
            </Popconfirm>
        </Space>
    )
}
