import { Flex, Skeleton, Space, Typography, theme } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import { PageContentContainer } from '~components'

const { Text, Title } = Typography
const { useToken } = theme

interface Props {
    isLoading: boolean
    fullWidth?: boolean
}

export function InfoBlock({ isLoading, fullWidth }: Props) {
    const {
        token: { colorPrimary },
    } = useToken()

    return (
        <PageContentContainer
            styles={{
                body: { maxWidth: fullWidth ? undefined : 400, minWidth: 400 },
            }}
        >
            <Skeleton loading={isLoading} paragraph={{ rows: 2, width: '30%', style: { marginTop: 14 } }}>
                <Title level={5} data-merchant={null}>
                    <FormattedMessage {...lang.infoTitle} />
                </Title>
                <Text type="secondary" data-merchant={null}>
                    <FormattedMessage {...lang.infoDescription} />
                </Text>
            </Skeleton>
            <Space
                direction="vertical"
                style={{ marginTop: 24 }}
                size={16}
                styles={{
                    item: {
                        paddingLeft: 16,
                        borderLeft: `2px solid ${colorPrimary}`,
                    },
                }}
            >
                <Skeleton loading={isLoading} paragraph={{ rows: 3, style: { marginTop: 12 } }}>
                    <Flex vertical gap={4}>
                        <Text style={{ fontWeight: 500 }} data-merchant={null}>
                            <FormattedMessage {...lang.infoItemOneTitle} />
                        </Text>
                        <Text style={{ opacity: 0.7 }} data-merchant={null}>
                            <FormattedMessage {...lang.infoItemOneDescription} />
                        </Text>
                    </Flex>
                </Skeleton>
                <Skeleton loading={isLoading} paragraph={{ rows: 3, style: { marginTop: 12 } }}>
                    <Flex vertical gap={4}>
                        <Text style={{ fontWeight: 500 }} data-merchant={null}>
                            <FormattedMessage {...lang.infoItemTwoTitle} />
                        </Text>
                        <Text style={{ opacity: 0.7 }} data-merchant={null}>
                            <FormattedMessage {...lang.infoItemTwoDescription} />
                        </Text>
                    </Flex>
                </Skeleton>
            </Space>
        </PageContentContainer>
    )
}
