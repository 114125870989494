import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg, CompactCardsWrapper } from '@merchant/shared/components'
import { useBreakpoint } from '@merchant/shared/hooks'
import { Form, Segmented, Tooltip, Typography } from '@merchant/ui-kit/ant-design'
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { formItemsKey } from '../CreateApiKeyModal/views/ApiKeyForm/const'
import { permissionFullSegmentedOptions } from './const'
import { lang } from './lang'
import { PermissionCollapse } from './PermissionCollapse'
import type { ApiKeyMode } from '../../const'
import { ProjectAPIKeyPermissionID, type ProjectAPIKeyPermissionDetails } from '~api'

const { Paragraph, Text } = Typography

type SegmentedValueType = ApiKeyMode | null

interface Props {
    permissions: ProjectAPIKeyPermissionDetails[]
}

export function ApiKeyPermissionsItem({ permissions }: Props) {
    const breakpoints = useBreakpoint()
    const form = Form.useFormInstance()

    const formPermissions = Form.useWatch([formItemsKey.permissions], form)

    useEffect(() => {
        form.setFields([{ name: formItemsKey.permissions, errors: [] }])
    }, [form, formPermissions])

    return (
        <Form.Item
            name={formItemsKey.permissions}
            rules={[
                {
                    validator: (_, value) =>
                        Object.values(value).some(mode => !!mode) ? Promise.resolve() : Promise.reject(),
                    message: (
                        <span data-merchant="api-key-permissions-required-error-message">
                            <FormattedMessage {...lang.permissionRequiredErrorMessage} />
                        </span>
                    ),
                },
            ]}
        >
            <CompactCardsWrapper
                dataMerchantPrefix="api-key-permissions"
                compactCardStyles={{ body: { padding: breakpoints.xs ? '16px 16px 16px 8px' : 16 } }}
                gap={4}
            >
                <div style={{ paddingLeft: breakpoints.xs ? 8 : 0 }}>
                    <Paragraph data-merchant={null} style={{ fontWeight: 600, fontSize: 16, marginBottom: 0 }}>
                        <FormattedMessage {...lang.permissions} />
                    </Paragraph>
                    <Text data-merchant={null} type="secondary">
                        <FormattedMessage {...lang.howPermissionsWork} />
                        <Tooltip
                            data-merchant={null}
                            placement="bottom"
                            overlayStyle={{ maxWidth: 350 }}
                            overlayInnerStyle={{ fontSize: 12 }}
                            title={
                                <Text
                                    type="secondary"
                                    style={{ fontSize: 12 }}
                                    data-merchant="api-key-permissions-tooltip"
                                >
                                    <FormattedMessage
                                        {...lang.howPermissionsWorkTooltip}
                                        values={{
                                            break: () => <br />,
                                            strong: chunks => <span style={{ fontWeight: 700 }}>{chunks}</span>,
                                        }}
                                    />
                                </Text>
                            }
                        >
                            <span
                                style={{ verticalAlign: 'middle', marginLeft: 4 }}
                                data-merchant="api-key-permissions-hoverbox"
                            >
                                <AppSvg size={20} name={assets.questionMark} />
                            </span>
                        </Tooltip>
                    </Text>
                    <Form.Item style={{ marginTop: 12 }} name={['permissions', ProjectAPIKeyPermissionID.Full]}>
                        <Segmented<SegmentedValueType>
                            data-merchant="api-key-permissions-segmented"
                            options={permissionFullSegmentedOptions}
                        />
                    </Form.Item>
                </div>
                {!formPermissions?.[ProjectAPIKeyPermissionID.Full] &&
                    permissions.map(permission => <PermissionCollapse key={permission.id} permission={permission} />)}
            </CompactCardsWrapper>
        </Form.Item>
    )
}
