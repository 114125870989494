import { usePromise } from '@merchant/shared/hooks'
import { Button, Divider, Form } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { confirmationFormData, confirmationModeMap } from './const'
import type { ModalType, OnFinishParams } from './types'
import { authApi, useMerchant, useProject } from '~api'
import { GenerateVerificationCodeRequestPurpose } from '~api/instances/cabinet-api'
import { EmailConfirmationItem, TwoFaConfirmationItem } from '~features/FormItems'
import { useCodeConfirmationErrorHandling } from '~features/FormItems/useCodeErrorHandling'
import { useRegisterDirtyFormChecker } from '~hooks'

interface Props<T extends ModalType> {
    mode: T
    request: (params: OnFinishParams<T>) => Promise<unknown>
}

export function ConfirmationForm<T extends ModalType>({ mode, request }: Props<T>) {
    const { data: merchant } = useMerchant()
    const { data: project } = useProject()
    const [form] = Form.useForm()
    const { shouldRenderEmailItem, submitButton, dataMerchantPrefix } = confirmationFormData[mode]
    const { handleError } = useCodeConfirmationErrorHandling({
        confirmationStrategy: { '2fa': 'code', email: 'code' },
    })

    useRegisterDirtyFormChecker(confirmationModeMap[mode], form)

    const { send: sendOnFinish, isLoading: isFormFinishLoading } = usePromise(request, {
        onError: error => handleError({ error, form }),
        showErrorNotification: false,
    })

    const { send: emailResend } = usePromise(() =>
        authApi.generateVerificationCode({
            generateVerificationCodeRequest: {
                purpose: GenerateVerificationCodeRequestPurpose.AddProjectTeamMember,
                details: { projectName: project?.name || '' },
            },
        })
    )

    return (
        <Form form={form} onFinish={sendOnFinish} size="large" layout="vertical">
            {shouldRenderEmailItem && (
                <>
                    <EmailConfirmationItem
                        email={merchant?.email || 'unknown email'}
                        dataMerchant={`${dataMerchantPrefix}-email-input`}
                        onResendClick={emailResend}
                        shouldSendOnMount
                        shouldFocusOnMount
                    />
                    <Divider />
                </>
            )}
            <TwoFaConfirmationItem
                hasFocusOnMount={!shouldRenderEmailItem}
                style={{ marginBottom: 24 }}
                dataMerchant={`${dataMerchantPrefix}-2fa-input`}
            />
            <Button
                loading={isFormFinishLoading}
                htmlType="submit"
                type="primary"
                block
                data-merchant={`${dataMerchantPrefix}-submit-button`}
            >
                <FormattedMessage {...submitButton} />
            </Button>
        </Form>
    )
}
