import { lang } from './lang'
import type { StaticAddressFormData } from './types'
import type { FormItemsKey } from '@merchant/shared/types'
import { FeesPayer } from '~api/instances/cabinet-api'

export enum OrderSettingsType {
    BasicSettings = '1',
}

export const staticAddressFormKeys = {
    currency: 'currency',
    nominalCurrency: 'nominalCurrency',
    customerEmail: 'customerEmail',
    customerId: 'customerId',
    network: 'network',
    feesPayer: 'feesPayer',
} satisfies FormItemsKey<StaticAddressFormData>

export const initFormValues: Partial<StaticAddressFormData> = {
    currency: undefined,
    network: undefined,
    nominalCurrency: undefined,
    customerEmail: undefined,
    customerId: undefined,
    feesPayer: 'default',
}

export const feesPayerOptions = [
    {
        label: lang.feePayerDefault,
        value: 'default',
    },
    {
        label: lang.feePayerMerchant,
        value: FeesPayer.Merchant,
    },
    {
        label: lang.feePayerClient,
        value: FeesPayer.Customer,
    },
]
