import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    enableWhitelist: {
        id: 'whitelist-page.button.enable_whitelist',
        defaultMessage: 'Enable Whitelist',
    },
    cancel: {
        id: 'whitelist-page.button.cancel',
        defaultMessage: 'Cancel',
    },
    areYouSure: {
        id: 'whitelist-page.modal.are_you_sure',
        defaultMessage: 'Are you sure you want to enable Whitelist?',
    },
    checkboxText: {
        id: 'whitelist-page.checkbox.text',
        defaultMessage:
            'I understand that once the Whitelist is enabled, for security reasons <boldText>all withdrawals on my account will be suspended for 72 hours.</boldText>',
    },
    requiredAgreement: {
        id: 'whitelist-page.checkbox.error',
        defaultMessage: 'Your agreement is required',
    },
})
