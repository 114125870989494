import { EllipsisMiddle, CopyButton, StatisticFormattedNumber } from '@merchant/shared/components'
import { parseDateAndTime, shortenRate } from '@merchant/shared/utils'
import { Col, Row, Space, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import styles from '../../../style.module.css'
import { lang } from '../lang'
import type { Payment } from '~api/instances/cabinet-api'
import { PaymentState, FeesPayer } from '~api/instances/cabinet-api'
import { NoValueColumnContent } from '~components'

const { Text } = Typography

interface Props {
    data: Payment
}
// eslint-disable-next-line max-lines-per-function
export function CreditedFunds({ data }: Props) {
    const completed = parseDateAndTime(data.completedAt)

    const { holdingCurrency, payinCurrency, holdingTransferTxnId, payinHoldingSwap, receivedAmount, paymentFee } =
        data.selectedPaymentMethod || {}

    const isDifferentCurrency = payinCurrency !== holdingCurrency

    return (
        <Space direction="vertical" size={16} style={{ width: '100%' }}>
            <Text data-merchant={null}>
                <FormattedMessage {...lang.fundsCredited} />
            </Text>
            {data.state === PaymentState.Completed ? (
                <>
                    <Row justify="space-between">
                        <Col>
                            <Text type="secondary" data-merchant={null}>
                                <FormattedMessage {...lang.date} />
                            </Text>
                        </Col>
                        <Col className={styles.middleCol} />
                        <Col>
                            <Text data-merchant="order-timeline-credited-funds-completed-date">{completed?.date}</Text>{' '}
                            <Text type="secondary" data-merchant="order-timeline-credited-funds-completed-time">
                                {completed?.time}
                            </Text>
                        </Col>
                    </Row>
                    <Row justify="space-between">
                        <Col>
                            <Text type="secondary" data-merchant={null}>
                                <FormattedMessage {...lang.id} />
                            </Text>
                        </Col>
                        <Col className={styles.middleCol} />
                        <Col>
                            {holdingTransferTxnId ? (
                                <Text style={{ display: 'flex', gap: 4 }} data-merchant={null}>
                                    <EllipsisMiddle divider="." data-merchant="order-timeline-credited-funds-txnId">
                                        {holdingTransferTxnId}
                                    </EllipsisMiddle>
                                    <CopyButton
                                        notificationContentSubject={lang.id}
                                        inputValue={holdingTransferTxnId}
                                        type="text"
                                        data-merchant="credited-funds-id-copy-button"
                                    />
                                </Text>
                            ) : (
                                NoValueColumnContent
                            )}
                        </Col>
                    </Row>
                    <Row justify="space-between">
                        <Col>
                            <Text type="secondary" data-merchant={null}>
                                <FormattedMessage {...lang.accumulationCurrency} />
                            </Text>
                        </Col>
                        <Col className={styles.middleCol} />
                        <Col>
                            <Text data-merchant="order-timeline-credited-funds-holdingCurrency">{holdingCurrency}</Text>
                        </Col>
                    </Row>
                    {isDifferentCurrency && (
                        <Row justify="space-between">
                            <Col>
                                <Text type="secondary" data-merchant={null}>
                                    <FormattedMessage
                                        {...lang.exchangeRates}
                                        values={{
                                            fromCurrency: payinCurrency,
                                            toCurrency: holdingCurrency,
                                        }}
                                    />
                                </Text>
                            </Col>
                            <Col className={styles.middleCol} />
                            <Col>
                                <Text data-merchant="order-timeline-credited-funds-swap-rate">
                                    <StatisticFormattedNumber value={1} suffix={payinCurrency} />
                                    &nbsp;=&nbsp;
                                    <StatisticFormattedNumber
                                        value={shortenRate(payinHoldingSwap?.rate)}
                                        suffix={holdingCurrency}
                                    />
                                </Text>
                            </Col>
                        </Row>
                    )}
                    <Row justify="space-between">
                        <Col>
                            <Text type="secondary" data-merchant="order-timeline-credited-funds-feesPayer">
                                <FormattedMessage
                                    {...lang.orderFee}
                                    values={{
                                        who: (
                                            <FormattedMessage
                                                {...(data.feesPayer === FeesPayer.Merchant
                                                    ? lang.merchant
                                                    : lang.client)}
                                            />
                                        ),
                                    }}
                                />
                            </Text>
                        </Col>
                        <Col className={styles.middleCol} />
                        <Col>
                            <Text data-merchant="order-timeline-credited-funds-paymentFee">
                                -{paymentFee} {holdingCurrency}
                            </Text>
                        </Col>
                    </Row>
                    <Row justify="space-between">
                        <Col>
                            <Text type="secondary" data-merchant={null}>
                                <FormattedMessage {...lang.receivedAmount} />
                            </Text>
                        </Col>
                        <Col className={styles.middleCol} />
                        <Col>
                            <Text type="success" data-merchant="order-timeline-credited-funds-receivedAmount">
                                {receivedAmount} {holdingCurrency}
                            </Text>
                        </Col>
                    </Row>
                </>
            ) : null}
        </Space>
    )
}
