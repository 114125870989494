import type { ConfirmationFormItems } from './types'
import type { FormItemsKey } from '@merchant/shared/types'

export const formItemsKeys: FormItemsKey<ConfirmationFormItems> = {
    verificationCode: 'verificationCode',
    totpCode: 'totpCode',
    totpBackup: 'totpBackup',
}

export const formInitValues = {
    emailCode: undefined,
    totpCode: undefined,
}
