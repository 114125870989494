import { CloseOutlined } from '@ant-design/icons'
import { ColorModeModal } from '@merchant/shared/components'
import { useBreakpoint } from '@merchant/shared/hooks'
import { Button, theme } from '@merchant/ui-kit/ant-design'
import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useBoolean, useOnClickOutside, useScrollLock } from 'usehooks-ts'
import { ControlsBlock } from '../ControlsBlock'
import { NavigationMenu } from '../NavigationMenu'
import { ProjectPickerModal } from '../ProjectPicker'
import { TotalBalance } from '../TotalBalance'
import type { GlobalToken } from '@merchant/ui-kit/ant-design'
import { useUIContext } from '~contexts'

const { useToken } = theme
const getSideMenuStyles = (isOpen: boolean, token: GlobalToken): React.CSSProperties => ({
    position: 'fixed',
    zIndex: 10,
    backgroundColor: token.colorBgLayout,
    padding: '20px 32px 24px 32px',
    width: 264,
    height: '100dvh',
    top: 0,
    right: 0,
    transform: `translateX(${isOpen ? '0' : '100%'})`,
    transition: '0.2s',
    boxShadow: isOpen ? '-10px 15px 30px 0px rgba(0, 0, 0, 0.10)' : undefined,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    overflowX: 'hidden',
})

export function SideMenu() {
    const { token } = useToken()
    const breakpoints = useBreakpoint()
    const { isSideMenuOpen, toggleSideMenu, closeSideMenu } = useUIContext()
    const { value: isProjectPickerOpen, setFalse: closeProjectPicker, setTrue: openProjectPicker } = useBoolean()
    const { value: isThemeModalOpen, setFalse: closeThemeModal, setTrue: openThemeModal } = useBoolean()
    const location = useLocation()
    const menuWrapperRef = useRef<HTMLDivElement>(null)

    useOnClickOutside(menuWrapperRef, closeSideMenu)

    useEffect(() => {
        if (isSideMenuOpen) {
            closeSideMenu()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closeSideMenu, location.key])

    useEffect(() => {
        if (isSideMenuOpen && breakpoints.xxl) {
            closeSideMenu()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [breakpoints.xxl])

    useScrollLock({ autoLock: isSideMenuOpen })

    return (
        <>
            <ColorModeModal open={isThemeModalOpen} onCancel={closeThemeModal} />
            <ProjectPickerModal
                open={isProjectPickerOpen}
                onCancel={closeProjectPicker}
                closeModal={closeProjectPicker}
            />
            <div ref={menuWrapperRef} style={getSideMenuStyles(isSideMenuOpen, token)}>
                {isSideMenuOpen && (
                    <>
                        <div style={{ marginLeft: 'auto' }}>
                            <Button
                                style={{ width: 'auto' }}
                                styles={{ icon: { display: 'inline-flex' } }}
                                type="text"
                                onClick={toggleSideMenu}
                                icon={
                                    <CloseOutlined
                                        style={{
                                            fontSize: 24,
                                            color: token.colorText,
                                        }}
                                    />
                                }
                                data-merchant="side-menu-close-button"
                            />
                        </div>
                        <TotalBalance />
                        <NavigationMenu usageContext="sideMenu" />
                        <div style={{ marginTop: 'auto' }} />
                        <ControlsBlock openProjectPicker={openProjectPicker} openThemeModal={openThemeModal} />
                    </>
                )}
            </div>
        </>
    )
}
