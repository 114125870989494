import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    networkNotRequired: {
        id: 'network-select.form.not_required_network',
        defaultMessage: 'You don’t need to select a network for the selected currency',
    },
    networkAlert: {
        id: 'network-select.form.network_alert',
        defaultMessage: 'Please verify that you have chosen the correct network to avoid any potential loss of funds',
    },
})
