import { ModalResponsive } from '@merchant/shared/components'
import { InfoBlock } from './InfoBlock'
import type { ModalProps } from '@merchant/ui-kit/ant-design'

interface Props extends Omit<ModalProps, 'data-merchant'> {
    isLoading: boolean
}

export function InfoBlockModal({ isLoading, ...props }: Props) {
    return (
        <ModalResponsive
            {...props}
            footer={null}
            styles={{
                content: { padding: '0', width: '100%' },
                footer: { display: 'flex' },
            }}
            cancelButtonProps={{
                size: 'large',
                style: { width: 300, margin: '0 auto' },
                'data-merchant': 'payment-constructor-info-block-modal-cancel-button',
            }}
            data-merchant="payment-constructor-info-block-modal"
        >
            <InfoBlock fullWidth isLoading={isLoading} />
        </ModalResponsive>
    )
}
