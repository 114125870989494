import { assets } from '@merchant/shared/assets/nameMap'
import { AppCoin, AppSvg, CopyButton, EllipsisMiddle, InlineLinkButton } from '@merchant/shared/components'
import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { lang } from '../lang'
import styles from './style.module.css'
import type { ReactNode } from 'react'
import type { WhitelistWithKey } from '~api/swr/rest/types'
import { useCurrencies } from '~api'
import { Permission, type Currency, type Network } from '~api/instances/cabinet-api'
import { AllCurrenciesTag, NoValueColumnContent } from '~components'
import { useUserPermissionContext } from '~contexts'
import { globalLang } from '~globalLang'
import { useNetworks } from '~hooks'

const { Text } = Typography

interface ListItemDetails {
    key: string
    label: ReactNode
    value: ReactNode
}

type GetListItemDetails = (params: {
    data: WhitelistWithKey
    onDeleteClick: (item: WhitelistWithKey) => void
    network: Network | undefined
    currency: Currency | undefined
    isEditWhitelistAllowed: boolean
}) => JSX.Element[]

// eslint-disable-next-line max-lines-per-function
const getListItemDetails: GetListItemDetails = ({ data, onDeleteClick, network, currency, isEditWhitelistAllowed }) => {
    const details: ListItemDetails[] = [
        {
            key: 'name',
            label: (
                <Text type="secondary" style={{ fontSize: 12, fontWeight: 700 }} data-merchant={null}>
                    <FormattedMessage {...lang.name} />
                </Text>
            ),
            value: (
                <Text
                    ellipsis
                    style={{
                        fontSize: 12,
                        textAlign: 'right',
                        fontWeight: 700,
                    }}
                    data-merchant={`whitelist-name-${data.name}`}
                >
                    {data.name}
                </Text>
            ),
        },
        {
            key: 'currency',
            label: (
                <Text type="secondary" style={{ fontSize: 12 }} data-merchant={null}>
                    <FormattedMessage {...lang.currency} />
                </Text>
            ),
            value: currency ? (
                <Flex gap={8} align="center">
                    <span>
                        <Text
                            type="secondary"
                            style={{ fontSize: 12, textAlign: 'right' }}
                            data-merchant={`whitelist-currency-name-${currency.name}`}
                        >
                            {currency.name}
                        </Text>
                        &nbsp;
                        <Text
                            style={{ fontSize: 12, textAlign: 'right' }}
                            data-merchant={`whitelist-currency-code-${currency.code}`}
                        >
                            {currency.code}
                        </Text>
                    </span>
                    <AppCoin currencyCode={currency.code || ''} size="small" />
                </Flex>
            ) : (
                <AllCurrenciesTag />
            ),
        },
        {
            key: 'network',
            label: (
                <Text type="secondary" style={{ fontSize: 12 }} data-merchant={null}>
                    <FormattedMessage {...globalLang.network} />
                </Text>
            ),
            value: data.network ? (
                <span>
                    <Text
                        type="secondary"
                        style={{ fontSize: 12, textAlign: 'right' }}
                        data-merchant={`whitelist-network-protocol-${network?.protocol}`}
                    >
                        {network?.protocol}
                    </Text>
                    &nbsp;
                    <Text
                        style={{ fontSize: 12, textAlign: 'right' }}
                        data-merchant={`whitelist-network-name-${network?.code}`}
                    >
                        {network?.name}
                    </Text>
                </span>
            ) : (
                NoValueColumnContent
            ),
        },
        {
            key: 'address',
            label: (
                <Text type="secondary" style={{ fontSize: 12 }} data-merchant={null}>
                    <FormattedMessage {...lang.address} />
                </Text>
            ),
            value: (
                <span>
                    <EllipsisMiddle
                        style={{ fontSize: 12 }}
                        suffixCount={6}
                        prefixCount={6}
                        divider="."
                        showTooltip
                        copyableText={data.address}
                        data-merchant={`whitelist-address-${data.address}`}
                    >
                        {data.address}
                    </EllipsisMiddle>
                    <CopyButton
                        style={{ marginLeft: 8 }}
                        inputValue={data.address}
                        type="text"
                        notificationContentSubject={lang.address}
                        data-merchant="whitelist-address-copy-button"
                    />
                </span>
            ),
        },
        {
            key: 'addressTag',
            label: (
                <Text type="secondary" style={{ fontSize: 12 }} data-merchant={null}>
                    <FormattedMessage {...lang.addressTag} />
                </Text>
            ),
            value: data.addressTag ? (
                <Flex gap={4} align="center">
                    <EllipsisMiddle
                        style={{ fontSize: 12 }}
                        suffixCount={6}
                        prefixCount={6}
                        divider="."
                        showTooltip
                        copyableText={data.addressTag}
                        data-merchant={`whitelist-address-tag-${data.addressTag}`}
                    >
                        {data.addressTag}
                    </EllipsisMiddle>
                    <CopyButton
                        style={{ marginLeft: 8 }}
                        type="text"
                        inputValue={data.addressTag}
                        notificationContentSubject={lang.addressTag}
                        data-merchant="whitelist-address-tag-copy-button"
                    />
                </Flex>
            ) : (
                NoValueColumnContent
            ),
        },
        {
            key: 'delete',
            label: (
                <Text type="secondary" style={{ fontSize: 12 }} data-merchant={null}>
                    <FormattedMessage {...lang.delete} />
                </Text>
            ),
            value: (
                <InlineLinkButton
                    disabled={!isEditWhitelistAllowed}
                    data-merchant={`${data.key}-delete-whitelist-item-button`}
                    onClick={() => onDeleteClick(data)}
                    icon={<AppSvg name={assets.trash} size={20} />}
                />
            ),
        },
    ]

    return details.map(item => (
        <Flex key={item.key} justify="space-between" wrap="nowrap" align="baseline" style={{ marginBottom: 4 }}>
            <>{item.label}</>
            <span className={styles.middleStrokeSeparator} />
            <>{item.value}</>
        </Flex>
    ))
}

export function useListItemDetails(item: WhitelistWithKey, onDeleteClick: (item: WhitelistWithKey) => void) {
    const { checkUserPermission } = useUserPermissionContext()
    const { data: currencies } = useCurrencies()
    const { networks: allNetworks } = useNetworks()
    const whitelistCurrencyData = currencies?.[item.currency || '']
    const isEditWhitelistAllowed = checkUserPermission(Permission.EditWithdrawalWhitelist)

    const network = allNetworks.find(network => network.code === item.network)

    return getListItemDetails({
        data: item,
        onDeleteClick,
        network,
        currency: whitelistCurrencyData,
        isEditWhitelistAllowed,
    })
}
