import { Form, Radio } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from '../lang'
import type { FormItemProps } from '@merchant/ui-kit/ant-design'
import { FeesPayer } from '~api/instances/cabinet-api'
import { lang as commonLang } from '~features/PaymentConstructor/lang'
import { newOrderFormKeys } from '~features/PaymentConstructor/view/OrderConstructor/const'
import { globalLang } from '~globalLang'

export function FeesPayerItem(props: FormItemProps) {
    return (
        <Form.Item
            {...props}
            required
            rules={[
                {
                    required: true,
                    message: (
                        <span data-merchant="order-fee-payer-required-error">
                            <FormattedMessage {...globalLang.requiredFieldMessage} />
                        </span>
                    ),
                },
            ]}
            name={newOrderFormKeys.feesPayer}
            tooltip={<FormattedMessage {...lang.feeIsPaidByTooltip} />}
            label={<FormattedMessage {...lang.feeIsPaidBy} />}
        >
            <Radio.Group data-merchant="order-fee-payer-radio-group">
                <Radio value={FeesPayer.Merchant} data-merchant="order-fee-payer-merchant-radio">
                    <FormattedMessage {...commonLang.merchant} />
                </Radio>
                <Radio value={FeesPayer.Customer} data-merchant="order-fee-payer-client-radio">
                    <FormattedMessage {...commonLang.client} />
                </Radio>
            </Radio.Group>
        </Form.Item>
    )
}
