import { assets } from '@merchant/shared/assets/nameMap'
import { AppImg, ColorModeDropdown } from '@merchant/shared/components'
import { useBreakpoint } from '@merchant/shared/hooks'
import { Layout, Row, Space } from '@merchant/ui-kit/ant-design'
import { NavLink } from 'react-router-dom'
import { getHeaderStyles, logoLinkStyles } from './style'
import { routes as AppRoutes } from '~constants/routes'

const { Header: AntHeader } = Layout

export function StaticPageHeader() {
    const breakpoints = useBreakpoint()

    return (
        <AntHeader style={getHeaderStyles(breakpoints)}>
            {!breakpoints.xs && (
                <NavLink to={AppRoutes.orders} style={logoLinkStyles}>
                    <AppImg height="100%" name={assets.logo} themed alt="Logo" />
                </NavLink>
            )}
            <Space size={24} align="center">
                <Row align="middle">
                    <ColorModeDropdown />
                </Row>
            </Space>
        </AntHeader>
    )
}
