import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    withdrawTo: {
        id: 'transactions-page.table.withdraw_to',
        defaultMessage: 'to {address}',
    },
    withdrawalCrypto: {
        id: 'transactions-page.table.withdrawal_crypto',
        defaultMessage: 'Withdrawal crypto',
    },
    withdrawalFiat: {
        id: 'transactions-page.table.withdrawal_fiat',
        defaultMessage: 'Withdrawal fiat',
    },
    paymentWalletDeposit: {
        id: 'transactions-page.table.payment_wallet_deposit',
        defaultMessage: 'Wallet deposit',
    },
    paymentWalletDepositApiTooltip: {
        id: 'transactions-page.table.payment_wallet_deposit_tooltip',
        defaultMessage: 'Balance top-up from a deposit-type order initiated via the API',
    },
    paymentWalletDepositWebTooltip: {
        id: 'transactions-page.table.payment_wallet_deposit_tooltip',
        defaultMessage: 'Balance top-up from a deposit-type order initiated through this dashboard',
    },
    paymentStaticAddressDeposit: {
        id: 'transactions-page.table.payment_static_address_deposit',
        defaultMessage: 'Static address order',
    },
    paymentStaticAddressDepositApiTooltip: {
        id: 'transactions-page.table.payment_static_address_deposit_api_tooltip',
        defaultMessage: 'Balance top-up received at a static address initiated via the API',
    },
    paymentStaticAddressDepositWebTooltip: {
        id: 'transactions-page.table.payment_static_address_deposit_web_tooltip',
        defaultMessage: 'Balance top-up received at a static address created through this dashboard',
    },
    paymentDepositOrder: {
        id: 'transactions-page.table.payment_deposit_order',
        defaultMessage: 'Deposit order',
    },
    paymentDepositOrderWebTooltip: {
        id: 'transactions-page.table.payment_deposit_order_web_tooltip',
        defaultMessage: 'Balance top-up from a deposit-type order initiated through this dashboard',
    },
    paymentDepositOrderApiTooltip: {
        id: 'transactions-page.table.payment_deposit_order_api_tooltip',
        defaultMessage: 'Balance top-up from a deposit-type order initiated via the API',
    },
    paymentInvoiceOrder: {
        id: 'transactions-page.table.payment_invoice_order',
        defaultMessage: 'Invoice order',
    },
    paymentInvoiceOrderWebTooltip: {
        id: 'transactions-page.table.payment_invoice_order_web_tooltip',
        defaultMessage: 'Balance top-up from an invoice-type order initiated through this dashboard',
    },
    paymentInvoiceOrderApiTooltip: {
        id: 'transactions-page.table.payment_invoice_order_api_tooltip',
        defaultMessage: 'Balance top-up from an invoice-type order initiated via the API',
    },
    unknownDeposit: {
        id: 'transactions-page.table.unknown_deposit',
        defaultMessage: 'Deposit',
    },
    unknownDepositTooltip: {
        id: 'transactions-page.table.unknown_deposit_tooltip',
        defaultMessage: 'Unknown deposit',
    },
    exchangeDeposit: {
        id: 'transactions-page.table.exchange_deposit',
        defaultMessage: 'Exchange deposit',
    },
    exchangeDepositTooltip: {
        id: 'transactions-page.table.exchange_deposit_tooltip',
        defaultMessage: 'Balance top-up as part of an exchange operation',
    },
    manualRefundDeposit: {
        id: 'transactions-page.table.manual_refund_deposit',
        defaultMessage: 'Manual refund deposit',
    },
    manualRefundDepositTooltip: {
        id: 'transactions-page.table.manual_refund_deposit_tooltip',
        defaultMessage: 'Balance top-up as part of a refund operation',
    },
    internalExchange: {
        id: 'transactions-page.table.internal_exchange',
        defaultMessage: 'Internal Exchange',
    },
    fromTo: {
        id: 'transactions-page.table.from_to',
        defaultMessage: '{from} to {to}',
    },
    amountFrom: {
        id: 'transactions-page.table.from',
        defaultMessage: 'Amount from',
    },
    amountTo: {
        id: 'transactions-page.table.to',
        defaultMessage: 'Amount to',
    },
    rate: {
        id: 'transactions-page.table.rate',
        defaultMessage: 'Rate',
    },
})
