import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    infoTitle: {
        id: 'payment-constructor.form.info_title',
        defaultMessage: 'Create invoice, deposit or static address',
    },
    infoDescription: {
        id: 'payment-constructor.form.info_description',
        defaultMessage: 'Use Type field to define needed format',
    },
    infoItemOneTitle: {
        id: 'payment-constructor.form.info_item_one_title',
        defaultMessage: 'Fill in the details',
    },
    infoItemOneDescription: {
        id: 'payment-constructor.form.info_item_one_description',
        defaultMessage:
            'To initiate an order, simply choose the type, select the currency, input the amount (if required), enter a name, and provide a description if you wish. Then, click "Create order"',
    },
    infoItemTwoTitle: {
        id: 'payment-constructor.form.info_item_two_title',
        defaultMessage: 'Share the link with a client',
    },
    infoItemTwoDescription: {
        id: 'payment-constructor.form.info_item_two_description',
        defaultMessage:
            'After the order is generated, we will provide you with a payment link. This link can be shared with your client to facilitate the payment process.',
    },
})
