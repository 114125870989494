import { CopyButton } from '@merchant/shared/components'
import { Flex, Popover, Typography } from '@merchant/ui-kit/ant-design'
import type { PopoverProps } from '@merchant/ui-kit/ant-design'

const { Text } = Typography

interface Props extends PopoverProps {
    value: string
}

export function CopyablePopover({ value, placement = 'bottom', children, ...rest }: Props) {
    return (
        <Popover
            title={
                <Flex align="center" gap={4}>
                    <Text data-merchant={null} style={{ fontSize: 12 }}>
                        {value}
                    </Text>{' '}
                    <CopyButton inputValue={value} data-merchant={`${rest['data-merchant']}-copy-button`} />
                </Flex>
            }
            placement={placement}
            style={{ fontSize: 12 }}
            {...rest}
        >
            {children}
        </Popover>
    )
}
