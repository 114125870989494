import { HttpError } from '@merchant/shared/api'
import { EmailSupportLink } from '@merchant/shared/components'
import { moveItemToBeginning } from '@merchant/shared/utils'
import { isEmpty, reduce } from 'lodash-es'
import { convertCaptureEventParams, PosthogConvertEvents } from '../../posthog'
import { lang } from './lang'
import type { BalanceOption, ConvertCurrenciesData } from './types'
import type { FormInstance } from '@merchant/ui-kit/ant-design'
import type { PostHog } from 'posthog-js'
import type { ComponentProps } from 'react'
import type { FormattedMessage } from 'react-intl'
import type { Currencies } from '~api/types'
import { instanceOfSwapRate, type Balance, type SwapRate } from '~api/instances/cabinet-api'
import { currenciesCommonSort } from '~utils'

export const getOrderedBalancesAndBalancesMap = (
    currenciesMap: Currencies,
    balances: Balance[],
    sourceCurrency: string | null
) => {
    const { balancesList, balanceAmountMap } = reduce(
        balances,
        (acc: ConvertCurrenciesData, current) => {
            const currency = currenciesMap[current.currency]
            const hasSwappableCurrencies = !isEmpty(currency?.swappableTo)
            if (!hasSwappableCurrencies || !currency) {
                return acc
            }

            const option = {
                code: currency.code,
                currency: currency.code,
                name: currency.name,
                amount: current.amount,
                fiatEquivalentAmount: current.fiatEquivalentAmount,
            }
            acc.balancesList.push(option)
            acc.balanceAmountMap[currency.code] = {
                amount: current.amount,
                fiatEquivalentAmount: current.fiatEquivalentAmount,
            }

            return acc
        },
        { balancesList: [], balanceAmountMap: {} }
    )

    const balancesOrdered = currenciesCommonSort(balancesList, currenciesMap, balances)
    const withSelectedCurrencyAtTop = moveItemToBeginning(balancesOrdered, b => b.code === sourceCurrency)

    return {
        balancesList: withSelectedCurrencyAtTop,
        balanceAmountMap,
    }
}

export const getUnorderedConvertCurrencies = (currenciesMap: Currencies = {}, sourceCurrency: string | null) => {
    const { balanceAmountMap, balancesList } = reduce(
        Object.values(currenciesMap),
        (acc: ConvertCurrenciesData, currency) => {
            const hasSwappableCurrencies = !isEmpty(currency.swappableTo)
            if (!hasSwappableCurrencies) {
                return acc
            }

            const option = {
                code: currency.code,
                currency: currency.code,
                name: currency.name,
                amount: '0',
                fiatEquivalentAmount: '0',
            }
            acc.balancesList.push(option)
            acc.balanceAmountMap[currency.code] = {
                amount: '0',
                fiatEquivalentAmount: '0',
            }

            return acc
        },
        { balanceAmountMap: {}, balancesList: [] }
    )
    const withSelectedCurrencyAtTop = moveItemToBeginning(balancesList, b => b.currency === sourceCurrency)

    return {
        balancesList: withSelectedCurrencyAtTop,
        balanceAmountMap,
    }
}

export const getAvailableCurrenciesToSwapTo = (
    currenciesMap: Currencies = {},
    sourceCurrency: string | null,
    balancesListOrdered: BalanceOption[]
) => {
    const swappableToCodes = (sourceCurrency && currenciesMap[sourceCurrency]?.swappableTo) || []

    if (sourceCurrency && !swappableToCodes.includes(sourceCurrency) && !isEmpty(swappableToCodes)) {
        swappableToCodes.push(sourceCurrency)
    }

    return balancesListOrdered.filter(b => swappableToCodes.includes(b.currency))
}

export const getCurrenciesSwitched = (currenciesMap: Currencies = {}, source: string | null, target: string | null) => {
    const canBeSwitched = target && source && currenciesMap[target]?.swappableTo.includes(source)

    return {
        source: target,
        target: canBeSwitched ? source : null,
    }
}

export const getDefaultSourceCurrency = (
    balances: Balance[],
    balancesListOrdered: BalanceOption[],
    accumulationCurrency: string
) => {
    const balancesWithFunds = balances
        .filter(a => Number(a.fiatEquivalentAmount) !== 0)
        .sort((a, b) => Number(b.fiatEquivalentAmount) - Number(a.fiatEquivalentAmount))

    if (balancesWithFunds.length === 0) {
        // now we want to select first balance currency if all balances have no funds
        return {
            code: balancesListOrdered[0]?.currency,
            isAccumulation: balancesListOrdered[0]?.currency === accumulationCurrency,
        }
    }

    if (balancesWithFunds.length === 1) {
        return {
            code: balancesWithFunds[0]?.currency,
            isAccumulation: balancesWithFunds[0]?.currency === accumulationCurrency,
        }
    }

    const nonAccumulationWithMostFunds = balancesWithFunds.find(b => b.currency !== accumulationCurrency)

    if (nonAccumulationWithMostFunds === undefined) {
        // something is probably broken on API side if came here
        return {
            code: balancesListOrdered[0]?.currency,
            isAccumulation: balancesListOrdered[0]?.currency === accumulationCurrency,
        }
    }

    return {
        code: nonAccumulationWithMostFunds.currency,
        isAccumulation: nonAccumulationWithMostFunds.currency === accumulationCurrency,
    }
}

export const getDefaultTargetCurrency = ({
    currenciesMap = {},
    sourceCurrency,
    accumulationCurrency,
    prevSelectedTarget,
    sortedBalances,
}: {
    currenciesMap?: Currencies
    sourceCurrency: string
    accumulationCurrency?: string
    prevSelectedTarget?: string | null
    sortedBalances?: BalanceOption[]
}) => {
    if (prevSelectedTarget && currenciesMap[sourceCurrency]?.swappableTo.includes(prevSelectedTarget)) {
        return prevSelectedTarget
    }

    const { swappableTo } = currenciesMap[sourceCurrency] ?? {}

    const defaultAccumulationCurrency = swappableTo?.find(c => c === accumulationCurrency)

    if (defaultAccumulationCurrency && defaultAccumulationCurrency !== sourceCurrency) {
        return defaultAccumulationCurrency
    }

    const swappableToSorted = sortedBalances?.filter(c => swappableTo?.includes(c.code)).map(c => c.code) || swappableTo

    const firstWithdrawableCurrency = swappableToSorted?.find(
        c => currenciesMap[c]?.payoutEnabled && c !== sourceCurrency
    )

    return firstWithdrawableCurrency ?? swappableTo?.[0] ?? ''
}

export function getAlertMessageProps(
    currencies: Currencies,
    receiveCurrency: string
): ComponentProps<typeof FormattedMessage> {
    const alertMessageDescriptor =
        receiveCurrency && currencies[receiveCurrency]?.crypto
            ? lang.currencyNotSupportedToWithdrawal
            : lang.fiatCurrencyNotSupportedToWithdrawal

    const alertValues: ComponentProps<typeof FormattedMessage>['values'] = {
        Currency: receiveCurrency,
        tag: chunks => <strong>{chunks}</strong>,
        support: chunks => <EmailSupportLink>{chunks}</EmailSupportLink>,
    }

    return { ...alertMessageDescriptor, values: alertValues }
}

export function getConvertButtonLabel(isInsufficientFunds: boolean, isAmountBelowMinimum: boolean) {
    if (isInsufficientFunds) {
        return lang.notEnoughCoins
    }
    if (isAmountBelowMinimum) {
        return lang.amountBelowMinimum
    }

    return lang.convert
}

const isInstanceOfSwapRate = (response: SwapRate | Error): response is SwapRate => instanceOfSwapRate(response)

export const processSwapRateResponse =
    ({
        form,
        posthog,
        sendAmount,
        sourceCurrency,
        targetCurrency,
        merchantId,
        projectId,
    }: {
        form: FormInstance
        posthog: PostHog
        sendAmount: string | undefined
        sourceCurrency: string | null
        targetCurrency: string | null
        merchantId: string
        projectId: string
    }) =>
    (response: SwapRate | Error) => {
        const isError = !isInstanceOfSwapRate(response)
        let responseField: string = ''

        if (isError) {
            responseField = response.message || response.name
            if (response instanceof HttpError) {
                responseField = response.getErrorResponseDataMessage || response.message || response.name
            }
        } else {
            responseField = response.rate
            form.setFieldValue(['receive', 'value'], response.amountTo)
        }

        posthog.capture(
            ...convertCaptureEventParams[PosthogConvertEvents.getConvertAmount]({
                amount_from: String(sendAmount),
                amount_to: isError ? '' : response.amountTo,
                currency_from: sourceCurrency || '',
                currency_to: targetCurrency || '',
                response: responseField,
                merchant_id: merchantId,
                project_id: projectId,
            })
        )
    }

export const shouldDisplayError = ({
    isConvertible,
    isLoadingCurrencies,
    isSelectedPairSupported,
}: {
    isLoadingCurrencies: boolean
    isSelectedPairSupported: boolean | undefined
    isConvertible: boolean | undefined
}): boolean => !isLoadingCurrencies && (!isSelectedPairSupported || !isConvertible)

export const isCurrencyPairSelected = (sendCurrency: string | null, receiveCurrency: string | null) =>
    !!sendCurrency && !!receiveCurrency
