import { assets } from '@merchant/shared/assets/nameMap'
import { ModalResponsive, AppSvg } from '@merchant/shared/components'
import { selectedProjectIdKey } from '@merchant/shared/constants/localStorage'
import { Button, Flex, Typography, theme, Menu } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { useLocalStorage } from 'usehooks-ts'
import { lang as commonLang } from '../lang'
import { getMenuProps } from '../utils'
import { lang } from './lang'
import type { MenuProps, ModalProps } from '@merchant/ui-kit/ant-design'
import { useMerchant, useProjects } from '~api'
import { useProjectRolesMap } from '~hooks'
import { useLogout } from '~hooks/useLogout'

const { Text } = Typography
const { useToken } = theme

interface Props extends Omit<ModalProps, 'data-merchant'> {
    closeModal: () => void
}

export function ProjectPickerModal({ closeModal, ...modalProps }: Props) {
    const { data: projectRoles } = useProjectRolesMap()
    const { logout } = useLogout()
    const [selectedProjectId, setSelectedProjectId] = useLocalStorage<string>(selectedProjectIdKey, '')
    const { data: projects } = useProjects()
    const { data: merchant } = useMerchant()
    const { token } = useToken()

    const handleMenuItemSelect: MenuProps['onSelect'] = ({ key }) => {
        setSelectedProjectId(key)
        closeModal()
    }

    const handleLogoutClick = () => {
        logout()
        closeModal()
    }

    return (
        <ModalResponsive
            data-merchant="project-picker-modal"
            title={<FormattedMessage {...lang.modalTitle} />}
            footer={null}
            {...modalProps}
        >
            <Flex vertical gap={20}>
                <Text data-merchant="project-picker-modal-merchant-email" style={{ fontWeight: 600 }}>
                    {merchant?.email}
                </Text>
                <Menu
                    {...getMenuProps({
                        projects,
                        selectedProjectId,
                        token,
                        handleMenuItemSelect,
                        closePicker: closeModal,
                        projectRoles,
                        merchantId: merchant?.id,
                    })}
                />
                <Button
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 4,
                    }}
                    data-merchant="header-logout-button"
                    onClick={handleLogoutClick}
                    size="large"
                    block
                    icon={<AppSvg size={18} name={assets.logOut} />}
                >
                    <FormattedMessage {...commonLang.logoutButton} />
                </Button>
            </Flex>
        </ModalResponsive>
    )
}
