import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { WithdrawalsShowOptions, dataMerchantAttributesMap, pagePermissionMap, pageTitleMap, pages } from './const'
import { lang } from './lang'
import { WhitelistPage, WithdrawalsHistory } from './views'
import { QueryParams } from '~constants/routes'
import { useUserPermissionContext } from '~contexts'
import { useURLSearchNavigation } from '~hooks'

const { Title, Text } = Typography

const views = {
    [WithdrawalsShowOptions.history]: <WithdrawalsHistory />,
    [WithdrawalsShowOptions.whitelist]: <WhitelistPage />,
} as const

export function WithdrawalsPage() {
    const { checkUserPermission } = useUserPermissionContext()
    const permittedPages = pages.filter(page => checkUserPermission(pagePermissionMap[page]))
    const { page } = useURLSearchNavigation({
        key: QueryParams.show,
        pages: permittedPages,
    })
    const view = views[page]
    const dataMerchant = dataMerchantAttributesMap[page]

    const pageSubtitle = <FormattedMessage {...pageTitleMap[page]} />

    return (
        <>
            <Flex vertical>
                <Text data-merchant={null} style={{ opacity: 0.4 }}>
                    <FormattedMessage {...lang.pageTitle} />
                </Text>
                <Title level={4} data-merchant={dataMerchant}>
                    {pageSubtitle}
                </Title>
            </Flex>
            {view}
        </>
    )
}
