import { useSearchParams } from 'react-router-dom'
import { Convert, Deposit, NotPermitted, WithdrawalCrypto, WithdrawalFiat } from './views'
import { useMerchant, useProject } from '~api'
import { QueryParams } from '~constants/routes'
import { Actions } from '~types/actionModal'

const views: Record<Actions, () => JSX.Element | null | undefined> = {
    [Actions.convert]: Convert,
    [Actions.withdrawalCrypto]: WithdrawalCrypto,
    [Actions.withdrawal]: WithdrawalCrypto,
    [Actions.withdrawalFiat]: WithdrawalFiat,
    [Actions.deposit]: Deposit,
} as const

export function Content() {
    const { isDisabled: isMerchantDisabled } = useMerchant()
    const { isDisabled: isProjectDisabled } = useProject()
    const [searchParams] = useSearchParams()
    const action = searchParams.get(QueryParams.action) as Actions | null

    if (!action || !(action in views)) {
        return null
    }

    if (isMerchantDisabled || isProjectDisabled) {
        return <NotPermitted />
    }

    const View = views[action]

    return <View />
}
