import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    privacyPolicy: {
        id: 'app.footer.privacyPolicy',
        defaultMessage: 'Privacy Policy',
    },
    amlKyc: {
        id: 'app.footer.amlKyc',
        defaultMessage: 'AML/KYC',
    },
    termsOfService: {
        id: 'app.footer.termsOfService',
        defaultMessage: 'Terms of Service',
    },
    copyRight: {
        id: 'app.footer.copyRight',
        defaultMessage: '© Company Name {currentYear}',
    },
})
