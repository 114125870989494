import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useEffect } from 'react'
import { useMerchant } from '~api'

dayjs.extend(utc)
dayjs.extend(timezone)

export function useDefaultTimezone() {
    const { data: merchant } = useMerchant()

    useEffect(() => {
        if (merchant?.timeZone) {
            dayjs.tz.setDefault(merchant.timeZone)
        }
    }, [merchant?.timeZone])
}
