import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { emailValidationRegex } from '@merchant/shared/constants'
import { useBreakpoint } from '@merchant/shared/hooks'
import { Button, Col, Divider, Flex, Form, Input, Row, Typography } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { FORM_LIST_NAME } from '../../const'
import { formKeys, getFormListHeaderTextProps, row } from './const'
import { lang } from './lang'
import { RoleSelectWrapper } from './RoleSelectWrapper'
import { useEditableProjectRoles } from '~api'
import { globalLang } from '~globalLang'

const { Text } = Typography

/* eslint-disable max-lines-per-function */
export function AddMembersFormList() {
    const intl = useIntl()
    const breakpoints = useBreakpoint()
    const { data: projectRoles } = useEditableProjectRoles()

    const emailLabel = intl.formatMessage(lang.emailLabel)
    const nameLabel = intl.formatMessage(lang.nameLabel)
    const roleLabel = intl.formatMessage(lang.roleLabel)

    return (
        <Form.List name={FORM_LIST_NAME}>
            {(fields, { add, remove }) => (
                <>
                    <Row {...row.props}>
                        <Col {...row.cols[0]?.props}>
                            <Text {...getFormListHeaderTextProps('email')}>{emailLabel}</Text>
                        </Col>
                        <Col {...row.cols[1]?.props}>
                            <Text {...getFormListHeaderTextProps('name')}>{nameLabel}</Text>
                        </Col>
                        <Col {...row.cols[2]?.props}>
                            <Text {...getFormListHeaderTextProps('role')}>{roleLabel}</Text>
                        </Col>
                    </Row>
                    <Divider style={{ marginBlock: '12px 20px' }} />
                    {fields.map(({ key, name, ...restField }) => (
                        <React.Fragment key={key}>
                            <Row {...row.props}>
                                <Col {...row.cols[0]?.props}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, formKeys.email]}
                                        rules={[
                                            {
                                                validateTrigger: 'onSubmit',
                                                pattern: emailValidationRegex,
                                                message: <FormattedMessage {...globalLang.invalidEmailFormat} />,
                                            },
                                            {
                                                required: true,
                                                message: <FormattedMessage {...globalLang.requiredFieldMessage} />,
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder={emailLabel}
                                            data-merchant={`add-members-email-input-${name}`}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col {...row.cols[1]?.props}>
                                    <Form.Item {...restField} name={[name, formKeys.name]}>
                                        <Input
                                            placeholder={nameLabel}
                                            suffix={
                                                <Text type="secondary" data-merchant={null}>
                                                    <FormattedMessage {...globalLang.optional} />
                                                </Text>
                                            }
                                            data-merchant={`add-members-name-input-${name}`}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col {...row.cols[2]?.props}>
                                    <Form.Item
                                        {...restField}
                                        initialValue={[]}
                                        name={[name, formKeys.roles]}
                                        rules={[
                                            {
                                                required: true,
                                                message: <FormattedMessage {...globalLang.requiredFieldMessage} />,
                                            },
                                        ]}
                                    >
                                        <RoleSelectWrapper
                                            options={projectRoles}
                                            roleLabel={roleLabel}
                                            name={name}
                                            isTheOnlyListItem={fields.length === 1}
                                            remove={remove}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider dashed style={{ marginTop: 12 }} />
                        </React.Fragment>
                    ))}
                    <Flex gap={12} justify="center" wrap="wrap" style={{ width: '100%' }}>
                        <Button
                            data-merchant="add-members-add-item-button"
                            onClick={() => add()}
                            style={{
                                maxWidth: breakpoints.md ? 180 : undefined,
                                paddingInline: 0,
                            }}
                            block
                            styles={{
                                icon: {
                                    verticalAlign: 'text-top',
                                    marginRight: 4,
                                    display: 'inline-flex',
                                },
                            }}
                            icon={<AppSvg size={16} name={assets.plus} />}
                        >
                            <FormattedMessage {...lang.addMemberButton} />
                        </Button>
                        <Button
                            data-merchant="add-members-submit-button"
                            htmlType="submit"
                            type="primary"
                            style={{
                                maxWidth: breakpoints.md ? 180 : undefined,
                            }}
                            block
                        >
                            <FormattedMessage {...lang.sendInvitationButton} />
                        </Button>
                    </Flex>
                </>
            )}
        </Form.List>
    )
}
