import { EllipsisMiddle, CopyButton, StatisticFormattedNumber } from '@merchant/shared/components'
import { parseDateAndTime, shortenRate } from '@merchant/shared/utils'
import { Space, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage, useIntl } from 'react-intl'
import { lang } from '../lang'
import { getIfExchangeRatesShown, getRow } from './utils'
import type { Payment } from '~api/instances/cabinet-api'
import { useCurrencies } from '~api'
import { getCurrencyNetworkIfHasMultiple } from '~features/OrdersPage/utils'

const { Text } = Typography

interface Props {
    data: Payment
    addressTagName?: string
}

// eslint-disable-next-line complexity, max-lines-per-function
export function PaymentMethod({ data, addressTagName }: Props) {
    const intl = useIntl()
    const { data: currencies } = useCurrencies()
    const isDeposit = data.type === 'DEPOSIT'

    const {
        payinCurrency,
        nominalPayinSwap,
        payinNetwork,
        selectedAt,
        expectedPayinAmount,
        payinAddress,
        payinAddressTag,
    } = data.selectedPaymentMethod || {}

    const selected = parseDateAndTime(selectedAt)
    const exchangeCurrencies = isDeposit ? [payinCurrency, data.nominalCurrency] : [data.nominalCurrency, payinCurrency]
    const exchangeRate = isDeposit ? 1 / Number(nominalPayinSwap?.rate) : nominalPayinSwap?.rate
    const networkData = getCurrencyNetworkIfHasMultiple(payinCurrency, payinNetwork, currencies)

    const formattedAddressTag = addressTagName
        ? addressTagName
        : intl.formatMessage(lang.addressTag, {
              currency: payinCurrency,
          })
    const formattedPaymentAddress = intl.formatMessage(lang.payinAddress, {
        currency: payinCurrency,
    })

    return (
        <Space direction="vertical" size={16} style={{ width: '100%' }}>
            <Text data-merchant={null}>
                <FormattedMessage {...lang.chosenCurrencyByClient} />
            </Text>
            {data.selectedPaymentMethod && (
                <>
                    {getRow(
                        intl.formatMessage(lang.date),
                        <>
                            <Text data-merchant="order-timeline-payment-method-date">{selected?.date}</Text>{' '}
                            <Text type="secondary" data-merchant="order-timeline-payment-method-time">
                                {selected?.time}
                            </Text>
                        </>
                    )}
                    {getRow(
                        intl.formatMessage(lang.chosenCurrencyForPayment),
                        <Text data-merchant="order-timeline-payment-method-payinCurrency">{payinCurrency}</Text>
                    )}
                    {networkData &&
                        getRow(
                            intl.formatMessage(lang.chosenNetworkForPayment),
                            <Text data-merchant="order-timeline-payment-method-network">
                                {networkData.name} {networkData.protocol}
                            </Text>
                        )}
                    {getIfExchangeRatesShown(data, exchangeRate) &&
                        getRow(
                            intl.formatMessage(lang.exchangeRates, {
                                fromCurrency: exchangeCurrencies[0],
                                toCurrency: exchangeCurrencies[1],
                            }),
                            <Text data-merchant="order-timeline-payment-method-swap-rate">
                                <StatisticFormattedNumber value={1} suffix={exchangeCurrencies[0]} />
                                &nbsp;=&nbsp;
                                <StatisticFormattedNumber
                                    value={shortenRate(exchangeRate)}
                                    suffix={exchangeCurrencies[1]}
                                />
                            </Text>
                        )}
                    {!isDeposit &&
                        getRow(
                            intl.formatMessage(lang.amountRequested),
                            <StatisticFormattedNumber value={expectedPayinAmount} suffix={payinCurrency} />
                        )}
                    {getRow(
                        formattedPaymentAddress,
                        <Text style={{ display: 'flex', gap: 4 }} data-merchant={null}>
                            <EllipsisMiddle
                                divider="."
                                data-merchant={`order-timeline-payment-method-payinAddress-${payinAddress}`}
                            >
                                {payinAddress}
                            </EllipsisMiddle>
                            <CopyButton
                                notificationContentSubject={formattedPaymentAddress}
                                inputValue={payinAddress || ''}
                                type="text"
                                data-merchant="order-timeline-address-copy-button"
                            />
                        </Text>
                    )}
                    {payinAddressTag &&
                        getRow(
                            formattedAddressTag,
                            <Text
                                style={{ display: 'flex', gap: 4 }}
                                data-merchant="order-timeline-payment-method-payin-address-tag"
                            >
                                {payinAddressTag}
                                <CopyButton
                                    notificationContentSubject={formattedAddressTag}
                                    inputValue={payinAddressTag}
                                    type="text"
                                    data-merchant="order-timeline-address-tag-copy-button"
                                />
                            </Text>
                        )}
                </>
            )}
        </Space>
    )
}
