import { usePromise } from '@merchant/shared/hooks'
import { Button, Divider, Form } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { postGenerateVerificationCodeRequest } from './const'
import type { WithdrawalFiatFormData } from '../../types'
import type { TOTPCredentials, VerificationCode } from '~api/instances/cabinet-api'
import { projectApi, useMerchant, useProject } from '~api'
import { ModalNames } from '~constants/modal'
import { EmailConfirmationItem, TwoFaConfirmationItem } from '~features/FormItems'
import { useCodeConfirmationErrorHandling } from '~features/FormItems/useCodeErrorHandling'
import { globalLang } from '~globalLang'
import { useRegisterDirtyFormChecker } from '~hooks'

interface Props {
    onSuccess: () => void
    withdrawalFormData: WithdrawalFiatFormData
}

export function WithdrawalFiatConfirmation({ onSuccess, withdrawalFormData }: Props) {
    const [form] = Form.useForm()
    const { data: merchant } = useMerchant()
    const { data: { id: projectId = '' } = {} } = useProject()

    const { handleError } = useCodeConfirmationErrorHandling({
        confirmationStrategy: { '2fa': 'code', email: 'code' },
    })
    useRegisterDirtyFormChecker(ModalNames.withdrawalFiatConfirmation)
    const { send: confirmFiatWithdrawal, isLoading: isConfirmationLoading } = usePromise(
        (values: TOTPCredentials & VerificationCode) => {
            return projectApi.createProjectFiatWithdrawal({
                createFiatWithdrawalRequest: {
                    ...withdrawalFormData,
                    ...values,
                },
                projectId,
            })
        },
        {
            onSuccess: () => onSuccess(),
            onError: error => handleError({ error, form }),
            showErrorNotification: false,
        }
    )

    const { send: generateCode } = usePromise(postGenerateVerificationCodeRequest)

    return (
        <Form
            data-merchant="withdrawal-fiat-confirmation-form"
            form={form}
            layout="vertical"
            size="large"
            onFinish={confirmFiatWithdrawal}
        >
            <EmailConfirmationItem
                dataMerchant="withdrawal-fiat-modal-email-confirmation-item"
                email={merchant?.email || 'unknown email'}
                onResendClick={generateCode}
                shouldSendOnMount
                shouldFocusOnMount
            />
            <Divider />
            <TwoFaConfirmationItem dataMerchant="withdrawal-fiat-modal-2fa-confirmation-item" />
            <Form.Item noStyle>
                <Button
                    htmlType="submit"
                    data-merchant="withdrawal-fiat-confirmation-button"
                    type="primary"
                    size="large"
                    block
                    loading={isConfirmationLoading}
                >
                    <FormattedMessage {...globalLang.confirm} />
                </Button>
            </Form.Item>
        </Form>
    )
}
