import { StatisticFormattedNumber } from '@merchant/shared/components'
import { Typography, type ButtonProps } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import type { WithdrawalCurrency } from '~features/ActionModal/types'
import type { WithdrawalAmountMode } from '~features/ActionModal/views/WithdrawalCrypto/types'
import { BalanceLinkButton } from '~components'

const { Text } = Typography

const getAvailableFundsStyles = (isError?: boolean): React.CSSProperties => ({
    fontSize: 12,
    opacity: isError ? 1 : 0.5,
})

interface Props extends ButtonProps {
    isError?: boolean | (() => boolean)
    selectedCurrencyData: WithdrawalCurrency | undefined
    selectedAmountType?: WithdrawalAmountMode
}

export function AvailableAmountExtra({ selectedAmountType = 'credit', selectedCurrencyData, isError, ...rest }: Props) {
    const isErrorValue = typeof isError === 'function' ? isError() : isError
    const amountContent =
        selectedAmountType === 'receive' ? (
            <StatisticFormattedNumber
                valueStyle={{ fontSize: 12, paddingInline: 1 }}
                value={selectedCurrencyData?.amount}
                suffix={selectedCurrencyData?.code}
            />
        ) : (
            <BalanceLinkButton
                amount={selectedCurrencyData?.amount}
                currencyCode={selectedCurrencyData?.code}
                {...rest}
            />
        )

    return (
        <Text
            data-merchant={null}
            type={isErrorValue ? 'danger' : 'secondary'}
            style={getAvailableFundsStyles(isErrorValue)}
        >
            <FormattedMessage {...lang.available} /> {selectedCurrencyData?.code ? amountContent : '—'}
        </Text>
    )
}
