import { Select, Button, Col, Empty, Form, Input, Row, Space } from '@merchant/ui-kit/ant-design'
import { capitalize, lowerCase } from 'lodash-es'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocalStorage } from 'usehooks-ts'
import { lang } from './lang'
import { TransactionStatusMapped, TransactionTypeMapped, type TransactionsFilterFormData } from './types'
import { ActiveFilters, RangePickerResponsive, CollapseGapped } from '~components'
import { FeatureFlags } from '~constants/featureFlag'
import { transactionsFilterKey } from '~constants/localStorage'

const inputItemStyles: React.CSSProperties = { marginBottom: 0 }
const rangePickerStyles: React.CSSProperties = { width: '100%' }

const collapseKey = 'transactions'

const getTypeOptions = (isFiatEnabled: boolean | undefined, dataMerchantPrefix: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { FIAT_WITHDRAWAL: _, ...withoutFiat } = TransactionTypeMapped
    const types = isFiatEnabled ? TransactionTypeMapped : withoutFiat

    return Object.entries(types).map(([key, value]) => {
        return (
            <Select.Option key={key} data-merchant={`${dataMerchantPrefix}-option-${lowerCase(key)}`}>
                {capitalize(lowerCase(value))}
            </Select.Option>
        )
    })
}

const getStatusOptions = (dataMerchantPrefix: string) =>
    Object.entries(TransactionStatusMapped).map(([key, value]) => (
        <Select.Option key={key} data-merchant={`${dataMerchantPrefix}-option-${lowerCase(key)}`}>
            {capitalize(lowerCase(value))}
        </Select.Option>
    ))

interface Props {
    extra: React.ReactNode
    isLoading: boolean
    onFormFinish: (values: TransactionsFilterFormData) => void
    onFormReset?: () => Promise<void>
    initialValues: Partial<TransactionsFilterFormData>
    activeFiltersCount: number
}

// eslint-disable-next-line max-lines-per-function
export function TransactionsFiltersForm({
    onFormFinish,
    onFormReset,
    extra,
    activeFiltersCount,
    initialValues,
    isLoading,
}: Props) {
    const [areFiltersOpen, setAreFiltersOpen] = useLocalStorage(transactionsFilterKey, true)
    const isFiatEnabled = useFeatureFlagEnabled(FeatureFlags.fiatEnabled)

    const [form] = Form.useForm()
    const intl = useIntl()

    const handleResetAll = async () => {
        await onFormReset?.()
        form.resetFields()
    }

    useEffect(() => {
        form.setFieldsValue(initialValues)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValues])

    return (
        <CollapseGapped
            bordered={false}
            onChange={keys => setAreFiltersOpen(keys.includes(collapseKey))}
            defaultActiveKey={areFiltersOpen ? collapseKey : undefined}
            data-merchant="transactions-filters-collapse"
            items={[
                {
                    key: collapseKey,
                    extra,
                    label: (
                        <Space direction="horizontal" data-merchant="transactions-filters-collapse">
                            <FormattedMessage {...lang.filters} />
                            {!areFiltersOpen && activeFiltersCount > 0 && (
                                <ActiveFilters
                                    dataMerchant="transations "
                                    amount={activeFiltersCount}
                                    onClear={handleResetAll}
                                />
                            )}
                        </Space>
                    ),
                    children: (
                        <Form form={form} onFinish={onFormFinish} initialValues={initialValues}>
                            <Row justify="start" gutter={[12, 10]}>
                                <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                                    <Form.Item style={inputItemStyles} name="type">
                                        <Select
                                            allowClear
                                            data-merchant="transactions-filter-type-select"
                                            notFoundContent={
                                                <Empty data-merchant="transactions-filter-type-select-empty" />
                                            }
                                            placeholder={intl.formatMessage(lang.transactionType)}
                                        >
                                            {getTypeOptions(isFiatEnabled, 'transactions-filter-type-select')}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                                    <Form.Item style={inputItemStyles} name="status">
                                        <Select
                                            allowClear
                                            data-merchant="transactions-filter-status-select"
                                            notFoundContent={
                                                <Empty data-merchant="transactions-filter-type-status-empty" />
                                            }
                                            placeholder={intl.formatMessage(lang.status)}
                                        >
                                            {getStatusOptions('transactions-filter-status-select')}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                                    <Form.Item style={inputItemStyles} name="id">
                                        <Input
                                            allowClear
                                            autoComplete="off"
                                            data-merchant="transactions-filter-id-input"
                                            placeholder={intl.formatMessage(lang.id)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                                    <Form.Item style={inputItemStyles} name="date">
                                        <RangePickerResponsive
                                            allowClear
                                            allowEmpty={[true, true]}
                                            id={{
                                                start: 'transactions-filter-created-start-input',
                                                end: 'transactions-filter-created-end-input',
                                            }}
                                            picker="date"
                                            style={rangePickerStyles}
                                            placement="bottomLeft"
                                            data-merchant="transactions-filter-created-range-picker"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg={{ span: 4 }} xs={{ span: 12 }} flex={1}>
                                    <Form.Item noStyle>
                                        <Button
                                            data-merchant="transactions-filters-submit-button"
                                            htmlType="submit"
                                            type="primary"
                                            loading={isLoading}
                                            block
                                        >
                                            <FormattedMessage {...lang.apply} />
                                        </Button>
                                    </Form.Item>
                                </Col>
                                <Col lg={{ span: 4 }} xs={{ span: 12 }} flex={1}>
                                    <Form.Item noStyle>
                                        <Button
                                            data-merchant="transactions-filters-clear-button"
                                            block
                                            onClick={handleResetAll}
                                        >
                                            <FormattedMessage {...lang.clearAll} />
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    ),
                },
            ]}
        />
    )
}
