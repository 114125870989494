/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    WithdrawalAmountCredit,
    instanceOfWithdrawalAmountCredit,
    WithdrawalAmountCreditFromJSON,
    WithdrawalAmountCreditFromJSONTyped,
    WithdrawalAmountCreditToJSON,
} from './WithdrawalAmountCredit';
import {
    WithdrawalAmountNominalReceive,
    instanceOfWithdrawalAmountNominalReceive,
    WithdrawalAmountNominalReceiveFromJSON,
    WithdrawalAmountNominalReceiveFromJSONTyped,
    WithdrawalAmountNominalReceiveToJSON,
} from './WithdrawalAmountNominalReceive';
import {
    WithdrawalAmountReceive,
    instanceOfWithdrawalAmountReceive,
    WithdrawalAmountReceiveFromJSON,
    WithdrawalAmountReceiveFromJSONTyped,
    WithdrawalAmountReceiveToJSON,
} from './WithdrawalAmountReceive';

/**
 * @type CalcWithdrawalAmountsRequestAmount
 * 
 * @export
 */
export type CalcWithdrawalAmountsRequestAmount = WithdrawalAmountCredit | WithdrawalAmountNominalReceive | WithdrawalAmountReceive;

// @ts-ignore
export function CalcWithdrawalAmountsRequestAmountFromJSON(json: any): CalcWithdrawalAmountsRequestAmount {
    return CalcWithdrawalAmountsRequestAmountFromJSONTyped(json, false);
}

// @ts-ignore
export function CalcWithdrawalAmountsRequestAmountFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalcWithdrawalAmountsRequestAmount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...WithdrawalAmountCreditFromJSONTyped(json, true), ...WithdrawalAmountNominalReceiveFromJSONTyped(json, true), ...WithdrawalAmountReceiveFromJSONTyped(json, true) };
}

// @ts-ignore
export function CalcWithdrawalAmountsRequestAmountToJSON(value?: CalcWithdrawalAmountsRequestAmount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfWithdrawalAmountCredit(value)) {
        return WithdrawalAmountCreditToJSON(value as WithdrawalAmountCredit);
    }
    if (instanceOfWithdrawalAmountNominalReceive(value)) {
        return WithdrawalAmountNominalReceiveToJSON(value as WithdrawalAmountNominalReceive);
    }
    if (instanceOfWithdrawalAmountReceive(value)) {
        return WithdrawalAmountReceiveToJSON(value as WithdrawalAmountReceive);
    }

    return {};
}

