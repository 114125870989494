export enum apiRestKeys {
    getMerchant = 'getMerchant',
    getTotps = 'getTotps',
    getApiKey = 'getApiKey',
    getProject = 'getProject',
    getCallback = 'getCallback',
    getBalances = 'getBalances',
    getProjects = 'getProjects',
    getHoldingCurrencies = 'getHoldingCurrencies',
    getExtendedHoldingCurrencies = 'getExtendedHoldingCurrencies',
    getPayments = 'getPayments',
    getPayment = 'getPayment',
    getTransactions = 'getTransactions',
    getWhitelist = 'getWhitelist',
    getCurrencies = 'getCurrencies',
    getSwapRate = 'getSwapRate',
    getSwapData = 'getSwapData',
    getProjectBlocklist = 'getProjectBlocklist',
    getWithdrawal = 'getWithdrawal',
    getFiatWithdrawalMethods = 'getFiatWithdrawalMethods',
    getFiatMethodFee = 'getFiatMethodFee',
    getStaticAddresses = 'getStaticAddresses',
    getDepositAddresses = 'getDepositAddresses',
    getDepositAddress = 'getDepositAddress',
    getProjectTeamMembers = 'getProjectTeamMembers',
    getProjectRoles = 'getProjectRoles',
    getProjectPermissions = 'getProjectPermissions',
    getBalanceSnapshots = 'getBalanceSnapshots',
    getProjectWithdrawals = 'getProjectWithdrawals',
    getApiKeyPermissions = 'getApiKeyPermissions',
}
