/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WithdrawalAmounts
 */
export interface WithdrawalAmounts {
    /**
     * 
     * @type {string}
     * @memberof WithdrawalAmounts
     */
    creditAmount: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalAmounts
     */
    swapRate?: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalAmounts
     */
    nominalReceiveAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalAmounts
     */
    receiveAmount: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalAmounts
     */
    networkFee: string;
}

/**
 * Check if a given object implements the WithdrawalAmounts interface.
 */
// @ts-ignore
export function instanceOfWithdrawalAmounts(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "creditAmount" in value;
    isInstance = isInstance && "receiveAmount" in value;
    isInstance = isInstance && "networkFee" in value;

    return isInstance;
}

// @ts-ignore
export function WithdrawalAmountsFromJSON(json: any): WithdrawalAmounts {
    return WithdrawalAmountsFromJSONTyped(json, false);
}

// @ts-ignore
export function WithdrawalAmountsFromJSONTyped(json: any, ignoreDiscriminator: boolean): WithdrawalAmounts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creditAmount': json['credit_amount'],
        'swapRate': !exists(json, 'swap_rate') ? undefined : json['swap_rate'],
        'nominalReceiveAmount': !exists(json, 'nominal_receive_amount') ? undefined : json['nominal_receive_amount'],
        'receiveAmount': json['receive_amount'],
        'networkFee': json['network_fee'],
    };
}

// @ts-ignore
export function WithdrawalAmountsToJSON(value?: WithdrawalAmounts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'credit_amount': value.creditAmount,
        'swap_rate': value.swapRate,
        'nominal_receive_amount': value.nominalReceiveAmount,
        'receive_amount': value.receiveAmount,
        'network_fee': value.networkFee,
    };
}

