import { assets } from '@merchant/shared/assets/nameMap'
import { lang } from './lang'
import type { MessageDescriptor } from 'react-intl'
import type { Labels } from '~types/actionModal'

export const labels: Record<Labels, MessageDescriptor> = {
    convert: lang.actionButtonConvert,
    withdrawal: lang.actionButtonWithdrawal,
    deposit: lang.actionButtonDeposit,
}

export const icons: Record<Labels, string> = {
    convert: assets.refreshCw,
    withdrawal: assets.arrowUpStraight,
    deposit: assets.arrowDownStraight,
}
