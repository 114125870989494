import { EmailSupportLink, ModalResponsive } from '@merchant/shared/components'
import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import { forwardRef, useImperativeHandle, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { lang as commonLang } from './lang'
import type { ModalProps } from '@merchant/ui-kit/ant-design'

const { Text } = Typography

export interface UnavailableCurrencyModalRef {
    open: (params: { currency: string; network: string }) => void
}

export const UnavailableCurrencyModal = forwardRef<UnavailableCurrencyModalRef, Omit<ModalProps, 'data-merchant'>>(
    function ({ ...rest }, ref) {
        const [state, setState] = useState<{ currency: string; network: string } | null>(null)

        useImperativeHandle(ref, () => {
            return {
                open: ({ currency, network }) => setState({ currency, network }),
            }
        })

        return (
            <ModalResponsive
                open={!!state}
                footer={(_, { OkBtn }) => <OkBtn />}
                okButtonProps={{
                    block: true,
                    size: 'large',
                    type: 'primary',
                    'data-merchant': 'addresses-page-unavailable-currency-modal-ok-button',
                }}
                onOk={() => setState(null)}
                okText={<FormattedMessage {...commonLang.okButton} />}
                styles={{
                    body: { marginBottom: 20 },
                    header: { paddingRight: 20, marginBottom: 10 },
                }}
                title={
                    <Text
                        data-merchant="addresses-page-unavailable-currency-modal-title"
                        style={{ fontSize: 20, fontWeight: 500 }}
                    >
                        <FormattedMessage
                            {...commonLang.unavailableDepositsPrompt}
                            values={{ currency: state?.currency, network: state?.network }}
                        />
                    </Text>
                }
                onCancel={() => setState(null)}
                data-merchant="addresses-page-unavailable-currency-modal"
                {...rest}
            >
                <Flex vertical gap={20}>
                    <Text data-merchant={null}>
                        <Text data-merchant={null} style={{ fontSize: 16, fontWeight: 500 }}>
                            <FormattedMessage {...commonLang.unavailableDepositsModalListTitle} />
                        </Text>
                        <FormattedMessage
                            {...commonLang.unavailableDepositsModalList}
                            values={{
                                ul: chunk => (
                                    <ul
                                        style={{
                                            marginBottom: 0,
                                            listStyle: 'outside',
                                        }}
                                    >
                                        {chunk}
                                    </ul>
                                ),
                                li: chunk => <li style={{ marginInline: 14 }}>{chunk}</li>,
                            }}
                        />
                    </Text>
                    <Text data-merchant="addresses-page-unavailable-currency-modal-support">
                        <FormattedMessage
                            {...commonLang.support}
                            values={{
                                support: chunks => <EmailSupportLink>{chunks}</EmailSupportLink>,
                            }}
                        />
                    </Text>
                </Flex>
            </ModalResponsive>
        )
    }
)
