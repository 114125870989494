import { getCommonMiddlewares } from '@merchant/shared/api/const'
import { AuthApi, Configuration, MerchantApi, ProjectApi } from './instances'
import { getAuthToken } from './utils'

const cabinetURL = `${import.meta.env.VITE_API_URL}/api/cabinet/v1` || ''

const cabinetConfig = new Configuration({
    basePath: cabinetURL,
    accessToken: getAuthToken,
    apiKey(name) {
        const options: { [key: string]: (() => string) | undefined } = {
            token: getAuthToken,
        }

        return options[name]?.() ?? ''
    },
    middleware: getCommonMiddlewares('cabinet'),
})
export const merchantApi = new MerchantApi(cabinetConfig)
export const authApi = new AuthApi(cabinetConfig)
export const projectApi = new ProjectApi(cabinetConfig)
