import { validateAmount } from '../WithdrawalInput/utils'
import type { ConfirmationFormItems } from './types'
import type { WithdrawalCryptoFormData } from '../../types'
import { RequiredError, type CreateWithdrawalRequest } from '~api'

export const getWithdrawalCryptoPayload = (
    data: WithdrawalCryptoFormData,
    confirmationFields: ConfirmationFormItems
) => {
    const { currency, address, amount, addressTag, network, note } = data
    const requiredFields = { currency, address, ...amount }

    if (!currency || !address || !validateAmount(amount)) {
        return Object.entries(requiredFields).forEach(([key, value]) => {
            if (!value) {
                throw new RequiredError(key)
            }
        })
    }

    return {
        receiveCurrency: currency,
        address,
        amount,
        receiveNetwork: network,
        addressTag,
        note,
        ...confirmationFields,
    } satisfies CreateWithdrawalRequest
}
