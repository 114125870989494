import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    close: {
        id: 'convert-modal.button.close',
        defaultMessage: 'Close',
    },
    id: {
        id: 'convert-modal.typography.id',
        defaultMessage: 'ID',
    },
    created: {
        id: 'convert-modal.steps.created',
        defaultMessage: 'Created',
    },
    processing: {
        id: 'convert-modal.steps.processing',
        defaultMessage: 'Processing',
    },
    completed: {
        id: 'convert-modal.steps.completed',
        defaultMessage: 'Completed',
    },
    pending: {
        id: 'convert-modal.typography.pending',
        defaultMessage: 'Pending',
    },
})
