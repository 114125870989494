/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentSwapConfig } from './PaymentSwapConfig';
import {
    PaymentSwapConfigFromJSON,
    PaymentSwapConfigFromJSONTyped,
    PaymentSwapConfigToJSON,
} from './PaymentSwapConfig';

/**
 * 
 * @export
 * @interface CreateProjectAddressRequest
 */
export interface CreateProjectAddressRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateProjectAddressRequest
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectAddressRequest
     */
    network?: string;
    /**
     * 
     * @type {PaymentSwapConfig}
     * @memberof CreateProjectAddressRequest
     */
    swap?: PaymentSwapConfig;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectAddressRequest
     */
    holdingCurrency?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectAddressRequest
     */
    comment?: string;
}

/**
 * Check if a given object implements the CreateProjectAddressRequest interface.
 */
// @ts-ignore
export function instanceOfCreateProjectAddressRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "currency" in value;

    return isInstance;
}

// @ts-ignore
export function CreateProjectAddressRequestFromJSON(json: any): CreateProjectAddressRequest {
    return CreateProjectAddressRequestFromJSONTyped(json, false);
}

// @ts-ignore
export function CreateProjectAddressRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateProjectAddressRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currency': json['currency'],
        'network': !exists(json, 'network') ? undefined : json['network'],
        'swap': !exists(json, 'swap') ? undefined : PaymentSwapConfigFromJSON(json['swap']),
        'holdingCurrency': !exists(json, 'holding_currency') ? undefined : json['holding_currency'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

// @ts-ignore
export function CreateProjectAddressRequestToJSON(value?: CreateProjectAddressRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currency': value.currency,
        'network': value.network,
        'swap': PaymentSwapConfigToJSON(value.swap),
        'holding_currency': value.holdingCurrency,
        'comment': value.comment,
    };
}

