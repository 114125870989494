import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg, InlineButton } from '@merchant/shared/components'
import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { excludedPlaceholderViews } from '../const'
import { lang } from './lang'
import { Snapshots } from './Snapshots'
import { useProject } from '~api'
import { routes } from '~constants/routes'
import { PlaceholderViewWrapper } from '~features/PlaceholderViewWrapper'

const { Title } = Typography

export function SnapshotsPage() {
    const { isLoading: isProjectLoading } = useProject()
    const navigate = useNavigate()

    return (
        <>
            <Flex vertical justify="space-between">
                <Title
                    level={5}
                    style={{ fontSize: 14, fontWeight: 400 }}
                    type="secondary"
                    data-merchant="balances-snapshots-page-title"
                >
                    <FormattedMessage {...lang.pageTitle} />
                </Title>
                <Flex align="center" gap={4}>
                    <InlineButton
                        size="large"
                        type="link"
                        shape="round"
                        onClick={() => navigate(routes.balances)}
                        data-merchant="snapshots-page-back-button"
                        icon={<AppSvg size={20} name={assets.arrowNarrowLeft} />}
                    />
                    <Title level={4} data-merchant="balances-snapshots-page-subtitle">
                        <FormattedMessage {...lang.pageSubtitle} />
                    </Title>
                </Flex>
            </Flex>
            <PlaceholderViewWrapper
                excludedViews={excludedPlaceholderViews}
                dataMerchantPrefix="balances-snapshots"
                isLoading={isProjectLoading}
            >
                <Snapshots />
            </PlaceholderViewWrapper>
        </>
    )
}
