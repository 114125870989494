/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TransactionDetails } from './TransactionDetails';
import {
    TransactionDetailsFromJSON,
    TransactionDetailsFromJSONTyped,
    TransactionDetailsToJSON,
} from './TransactionDetails';
import type { TransactionStatus } from './TransactionStatus';
import {
    TransactionStatusFromJSON,
    TransactionStatusFromJSONTyped,
    TransactionStatusToJSON,
} from './TransactionStatus';
import type { TransactionType } from './TransactionType';
import {
    TransactionTypeFromJSON,
    TransactionTypeFromJSONTyped,
    TransactionTypeToJSON,
} from './TransactionType';

/**
 * 
 * @export
 * @interface Transaction
 */
export interface Transaction {
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    txId: string;
    /**
     * 
     * @type {Date}
     * @memberof Transaction
     */
    date: Date;
    /**
     * 
     * @type {TransactionType}
     * @memberof Transaction
     */
    type: TransactionType;
    /**
     * 
     * @type {TransactionStatus}
     * @memberof Transaction
     */
    status: TransactionStatus;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    origin?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    network?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    balance?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    operationId?: string;
    /**
     * 
     * @type {Date}
     * @memberof Transaction
     */
    updatedAt: Date;
    /**
     * 
     * @type {TransactionDetails}
     * @memberof Transaction
     */
    details?: TransactionDetails;
}

/**
 * Check if a given object implements the Transaction interface.
 */
// @ts-ignore
export function instanceOfTransaction(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "txId" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "updatedAt" in value;

    return isInstance;
}

// @ts-ignore
export function TransactionFromJSON(json: any): Transaction {
    return TransactionFromJSONTyped(json, false);
}

// @ts-ignore
export function TransactionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Transaction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'txId': json['tx_id'],
        'date': (new Date(json['date'])),
        'type': TransactionTypeFromJSON(json['type']),
        'status': TransactionStatusFromJSON(json['status']),
        'origin': !exists(json, 'origin') ? undefined : json['origin'],
        'currency': json['currency'],
        'network': !exists(json, 'network') ? undefined : json['network'],
        'amount': json['amount'],
        'balance': !exists(json, 'balance') ? undefined : json['balance'],
        'operationId': !exists(json, 'operation_id') ? undefined : json['operation_id'],
        'updatedAt': (new Date(json['updated_at'])),
        'details': !exists(json, 'details') ? undefined : TransactionDetailsFromJSON(json['details']),
    };
}

// @ts-ignore
export function TransactionToJSON(value?: Transaction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'tx_id': value.txId,
        'date': (value.date.toISOString()),
        'type': TransactionTypeToJSON(value.type),
        'status': TransactionStatusToJSON(value.status),
        'origin': value.origin,
        'currency': value.currency,
        'network': value.network,
        'amount': value.amount,
        'balance': value.balance,
        'operation_id': value.operationId,
        'updated_at': (value.updatedAt.toISOString()),
        'details': TransactionDetailsToJSON(value.details),
    };
}

