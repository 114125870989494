import { AmountInput, AppCoin, StatisticFormattedNumber } from '@merchant/shared/components'
import { useDebouncedState } from '@merchant/shared/hooks'
import { getCurrencyMinPrecision, formatStringToParagraphs } from '@merchant/shared/utils'
import {
    Select,
    Button,
    Col,
    Divider,
    Flex,
    Form,
    Input,
    Popover,
    Row,
    Skeleton,
    Typography,
    theme,
} from '@merchant/ui-kit/ant-design'
import Big from 'big.js'
import { useEffect, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useBoolean } from 'usehooks-ts'
import { lang as commonLang } from './../../lang'
import { lang } from './lang'
import { useFiatWithdrawableCurrencies } from './useFiatCurrencies'
import { useFiatMethodFee } from './useFiatMethodFee'
import { getWithdrawalFiatCurrencySelectData, getWithdrawalFiatMethodSelectData } from './utils'
import type { FiatWithdrawalModalPermissions } from './types'
import type { WithdrawalFiatFormData } from '../../types'
import type { FormItemsKey } from '@merchant/shared/types'
import { useFiatWithdrawalMethods } from '~api'
import { FiatWithdrawalFeeType, Permission } from '~api/instances/cabinet-api'
import { AvailableAmountExtra } from '~components'
import { ModalNames } from '~constants/modal'
import { useUserPermissionContext } from '~contexts'
import { AmountFormItem } from '~features/FormItems'
import { globalLang } from '~globalLang'
import { useRegisterDirtyFormChecker } from '~hooks'
import { getPrecisionCutValue } from '~utils'

const { Text, Paragraph } = Typography
const { useToken } = theme

const formKeys: FormItemsKey<Omit<WithdrawalFiatFormData, 'totpCode' | 'totpBackup' | 'verificationCode'>> = {
    amount: 'amount',
    currency: 'currency',
    method: 'method',
    requisites: 'requisites',
    fee: 'fee',
}

interface Props {
    withdrawalFiatData: Partial<WithdrawalFiatFormData>
    onSuccess: (withdrawalFiatFormData: WithdrawalFiatFormData) => void
}

// TODO: Refactor this component
// eslint-disable-next-line max-lines-per-function, complexity
export function WithdrawalFiatInput({ onSuccess, withdrawalFiatData }: Props) {
    const { checkUserPermission } = useUserPermissionContext()
    const { token } = useToken()
    const intl = useIntl()
    const [form] = Form.useForm<WithdrawalFiatFormData>()
    const selectedCurrencyCode = Form.useWatch(formKeys.currency, form)
    const withdrawalMethod = Form.useWatch(formKeys.method, form)
    const formInnerWrapperRef = useRef<HTMLDivElement>(null)
    const {
        value: isPreviousRequisitesOpen,
        setTrue: openPreviousRequisites,
        setFalse: closePreviousRequisites,
    } = useBoolean()

    const { fiatWithdrawableCurrencies, isLoading: isFiatCurrenciesLoading } = useFiatWithdrawableCurrencies()
    const { data: fiatWithdrawalMethods, isLoading: isFiatWithdrawalMethodsLoading } = useFiatWithdrawalMethods({
        params: { currency: selectedCurrencyCode },
        shouldFetch: !!selectedCurrencyCode,
    })

    const withdrawalAmount = Form.useWatch(formKeys.amount, form)
    const selectedFiatWithdrawalMethod = fiatWithdrawalMethods?.find(method => method.name === withdrawalMethod)
    const selectedCurrencyPrecision = getCurrencyMinPrecision(selectedCurrencyCode, fiatWithdrawableCurrencies)
    const permissions: FiatWithdrawalModalPermissions = {
        [Permission.SeeBalance]: checkUserPermission(Permission.SeeBalance),
        [Permission.CommitFiatWithdrawals]: checkUserPermission(Permission.CommitFiatWithdrawals),
    }

    const [debouncedAmount, setDebouncedAmount, setDebouncedAmountImmediately, isDebouncedAmountWaiting] =
        useDebouncedState(withdrawalAmount, 300)

    useRegisterDirtyFormChecker(ModalNames.withdrawalFiatInput, form)

    useEffect(() => {
        if (selectedFiatWithdrawalMethod?.feeType === FiatWithdrawalFeeType.Absolute) {
            return setDebouncedAmountImmediately(withdrawalAmount)
        } else {
            setDebouncedAmount(withdrawalAmount)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [withdrawalAmount, selectedFiatWithdrawalMethod?.feeType])

    const selectedCurrencyData = fiatWithdrawableCurrencies[selectedCurrencyCode]

    const { fiatWithdrawalFee, isLoading: isFiatWithdrawalFeeLoading } = useFiatMethodFee({
        amount: debouncedAmount,
        selectedCurrency: selectedCurrencyCode,
        selectedMethod: selectedFiatWithdrawalMethod,
        setFormFeeValue: (fee: string) => form.setFieldValue(formKeys.fee, fee),
        isDebouncedAmountWaiting,
    })

    const handleAvailableBalanceClick = () => {
        const formAmount = form.getFieldValue(formKeys.amount)
        if (selectedCurrencyData?.amount === formAmount) {
            return
        }
        const cutBalanceAmount = getPrecisionCutValue({
            value: selectedCurrencyData?.amount,
            precision: selectedCurrencyPrecision,
        })
        form.setFieldValue(formKeys.amount, cutBalanceAmount)
        form.validateFields([formKeys.amount])
    }

    const handleAcceptRequisites = () => {
        form.setFieldsValue({
            [formKeys.requisites]: selectedFiatWithdrawalMethod?.requisites,
        })
        closePreviousRequisites()
    }

    const handleSelectMethodChange = (selectedMethod: string) => {
        const selectedFiatWithdrawalMethod = fiatWithdrawalMethods?.find(method => method.name === selectedMethod)
        if (selectedFiatWithdrawalMethod?.requisites) {
            openPreviousRequisites()
        }
    }

    const handleFormFinish = (values: WithdrawalFiatFormData) => {
        if (!permissions.commit_fiat_withdrawals) {
            return
        }

        onSuccess(values)
    }

    useEffect(() => {
        const formCurrency = form.getFieldValue(formKeys.currency)
        if (!formCurrency && !isFiatCurrenciesLoading) {
            const firstCurrency = Object.keys(fiatWithdrawableCurrencies)[0]
            form.setFields([{ name: formKeys.currency, value: firstCurrency }])
        }
    }, [fiatWithdrawableCurrencies, form, isFiatCurrenciesLoading])

    const selectedCurrencyCodeSuffix = selectedCurrencyData?.code || <span />

    const receiveAmount = Big(debouncedAmount || 0)
        .minus(Big(fiatWithdrawalFee || 0))
        .toString()

    const isDataLoading = isFiatCurrenciesLoading || isFiatWithdrawalMethodsLoading || isFiatWithdrawalFeeLoading

    return (
        <Form layout="vertical" size="large" form={form} onFinish={handleFormFinish} initialValues={withdrawalFiatData}>
            <div ref={formInnerWrapperRef}>
                <Form.Item
                    name={formKeys.currency}
                    label={<FormattedMessage {...lang.currency} />}
                    rules={[
                        {
                            required: true,
                            message: (
                                <span data-merchant="withdrawal-fiat-currency-required-error-message">
                                    <FormattedMessage {...globalLang.requiredFieldMessage} />
                                </span>
                            ),
                        },
                    ]}
                >
                    <Select
                        placeholder={intl.formatMessage(lang.selectCurrencyPlaceholder)}
                        data-merchant="withdrawal-fiat-currency-select"
                        loading={isFiatCurrenciesLoading}
                        options={getWithdrawalFiatCurrencySelectData(fiatWithdrawableCurrencies)}
                        fieldNames={{ value: 'code' }}
                        optionRender={({ data }) => (
                            <Flex key={data.code} gap={8} align="center">
                                <AppCoin currencyCode={data.code || ''} size="small" />
                                <div style={{ marginRight: 'auto' }}>
                                    <Text data-merchant={null}>{data.code}</Text>&nbsp;
                                    <Text data-merchant={null} type="secondary">
                                        {data.name}
                                    </Text>
                                </div>
                                {permissions.see_balance && (
                                    <Text
                                        data-merchant={null}
                                        type="secondary"
                                        style={{
                                            opacity: data.amount === '0' ? 0.5 : 1,
                                        }}
                                    >
                                        <StatisticFormattedNumber
                                            precision={2}
                                            value={data.amount}
                                            suffix={data.code}
                                        />
                                    </Text>
                                )}
                            </Flex>
                        )}
                    />
                </Form.Item>
                <Popover
                    open={isPreviousRequisitesOpen}
                    title={
                        <Paragraph data-merchant="withdrawal-fiat-requisites-popover-text">
                            <FormattedMessage {...lang.previousRequisitesPopoverTitle} />
                        </Paragraph>
                    }
                    data-merchant="withdrawal-fiat-requisites-popover"
                    content={
                        <Flex vertical style={{ width: '100%' }} gap={12}>
                            <Text data-merchant={null} style={{ fontWeight: 600 }}>
                                {formatStringToParagraphs(
                                    selectedFiatWithdrawalMethod?.requisites,
                                    'withdrawal-fiat-requsites'
                                )}
                            </Text>
                            <Row gutter={[8, 8]} wrap>
                                <Col flex={1}>
                                    <Button
                                        block
                                        size="middle"
                                        onClick={handleAcceptRequisites}
                                        data-merchant="withdrawal-fiat-accept-requisites-button"
                                    >
                                        <FormattedMessage
                                            {...lang.withdrawToThis}
                                            values={{
                                                strong: chunks => (
                                                    <span
                                                        style={{
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        {chunks}
                                                    </span>
                                                ),
                                            }}
                                        />
                                    </Button>
                                </Col>
                                <Col flex={1}>
                                    <Button
                                        block
                                        type="primary"
                                        size="middle"
                                        onClick={closePreviousRequisites}
                                        data-merchant="withdrawal-fiat-withdraw-to-new-button"
                                    >
                                        <FormattedMessage
                                            {...lang.withdrawToNew}
                                            values={{
                                                strong: chunks => (
                                                    <span
                                                        style={{
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        {chunks}
                                                    </span>
                                                ),
                                            }}
                                        />
                                    </Button>
                                </Col>
                            </Row>
                        </Flex>
                    }
                    getPopupContainer={() => formInnerWrapperRef.current || document.body}
                    placement="bottom"
                    overlayStyle={{
                        marginInline: 24,
                        maxWidth: 'calc(100% - 48px)',
                    }}
                    overlayInnerStyle={{ padding: 16 }}
                >
                    <Form.Item
                        name={formKeys.method}
                        label={<FormattedMessage {...lang.paymentMethod} />}
                        rules={[
                            {
                                required: true,
                                message: (
                                    <span data-merchant="withdrawal-fiat-method-required-error-message">
                                        <FormattedMessage {...globalLang.requiredFieldMessage} />
                                    </span>
                                ),
                            },
                        ]}
                    >
                        <Select
                            loading={isFiatWithdrawalMethodsLoading || isFiatCurrenciesLoading}
                            options={getWithdrawalFiatMethodSelectData(fiatWithdrawalMethods)}
                            onChange={handleSelectMethodChange}
                            dropdownRender={menu => (
                                <>
                                    <Flex
                                        style={{
                                            padding: token.Select?.optionPadding,
                                            paddingBlock: '5px 8px',
                                        }}
                                        justify="space-between"
                                    >
                                        <Text data-merchant={null} type="secondary">
                                            <FormattedMessage {...lang.paymentMethod} />
                                        </Text>
                                        <Text data-merchant={null} type="secondary">
                                            <FormattedMessage {...lang.fee} />
                                        </Text>
                                    </Flex>
                                    <Divider style={{ margin: '0 0 4px' }} />
                                    {menu}
                                </>
                            )}
                            optionRender={({ data, label }) => (
                                <Flex key={data.key} gap={8} align="center">
                                    <div style={{ marginRight: 'auto' }}>
                                        <Text data-merchant={null}>{label}</Text>
                                    </div>
                                    <Text data-merchant={null}>
                                        <StatisticFormattedNumber
                                            precision={2}
                                            value={data.fee}
                                            suffix={selectedCurrencyCode}
                                        />
                                    </Text>
                                </Flex>
                            )}
                            data-merchant="withdrawal-fiat-method-select"
                            placeholder={intl.formatMessage(lang.selectMethodPlaceholder)}
                        />
                    </Form.Item>
                </Popover>
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: (
                                <span data-merchant="withdrawal-fiat-requisites-required-error-message">
                                    <FormattedMessage {...globalLang.requiredFieldMessage} />
                                </span>
                            ),
                        },
                    ]}
                    name={formKeys.requisites}
                    label={<FormattedMessage {...commonLang.bankAccountDetails} />}
                    extra={
                        <Text
                            data-merchant={null}
                            type="secondary"
                            style={{
                                fontSize: 12,
                                opacity: 0.5,
                                fontWeight: 500,
                            }}
                        >
                            <FormattedMessage {...lang.bankAccountDetailsExtra} />
                        </Text>
                    }
                >
                    <Input.TextArea
                        data-merchant="withdrawal-fiat-requisites-textarea"
                        placeholder={intl.formatMessage(lang.bankAccountDetailsPlaceholder)}
                        rows={6}
                    />
                </Form.Item>
                <AmountFormItem
                    validateFirst
                    rules={[
                        {
                            required: true,
                            message: (
                                <span data-merchant="withdrawal-fiat-amount-required-error-message">
                                    <FormattedMessage {...globalLang.requiredFieldMessage} />
                                </span>
                            ),
                        },
                        {
                            validator(_, value) {
                                if (Big(value || 0).lte(fiatWithdrawalFee || '0')) {
                                    return Promise.reject()
                                }

                                return Promise.resolve()
                            },
                            message: (
                                <span data-merchant="withdrawal-fiat-amount-lower-than-fee-error-message">
                                    <FormattedMessage {...lang.amountLowerThanFee} />
                                </span>
                            ),
                        },
                        {
                            validator(_, value) {
                                if (Big(value || 0).gt(selectedCurrencyData?.amount || 0)) {
                                    return Promise.reject()
                                }

                                return Promise.resolve()
                            },
                            message: (
                                <span data-merchant="withdrawal-fiat-amount-lower-than-balance-error-message">
                                    <FormattedMessage {...globalLang.insufficientFunds} />
                                </span>
                            ),
                        },
                    ]}
                    form={form}
                    precision={selectedCurrencyPrecision}
                    name={formKeys.amount}
                    label={<FormattedMessage {...lang.amount} />}
                    dependencies={[formKeys.currency, formKeys.method]}
                    extra={
                        <AvailableAmountExtra
                            selectedCurrencyData={selectedCurrencyData}
                            data-merchant="withdrawal-fiat-available-balance-button"
                            onClick={handleAvailableBalanceClick}
                        />
                    }
                >
                    <AmountInput
                        placeholder="0"
                        precision={selectedCurrencyPrecision}
                        data-merchant="withdrawal-fiat-amount-input"
                        style={{ width: '100%' }}
                        suffix={selectedCurrencyCodeSuffix}
                    />
                </AmountFormItem>
                <Form.Item>
                    <Form.Item name={formKeys.fee} noStyle>
                        <Row justify="space-between" align="middle">
                            <Col flex={1}>
                                <Text data-merchant={null} type="secondary">
                                    <FormattedMessage {...commonLang.withdrawalFee} />
                                </Text>
                            </Col>
                            <Col>
                                <Flex justify="flex-end">
                                    <Skeleton
                                        loading={isDataLoading}
                                        active
                                        paragraph={false}
                                        title={{
                                            width: '15%',
                                            style: { minWidth: 65 },
                                        }}
                                    >
                                        <Text
                                            data-merchant={`withdrawal-fiat-fee-${selectedCurrencyCode}`}
                                            type={Big(fiatWithdrawalFee || 0).lte(0) ? 'secondary' : undefined}
                                        >
                                            <StatisticFormattedNumber
                                                precision={2}
                                                value={fiatWithdrawalFee}
                                                suffix={selectedCurrencyCode}
                                            />
                                        </Text>
                                    </Skeleton>
                                </Flex>
                            </Col>
                        </Row>
                    </Form.Item>
                    <Row justify="space-between" align="middle">
                        <Col flex={1}>
                            <Text data-merchant={null} type="secondary">
                                <FormattedMessage {...commonLang.receiveAmount} />
                            </Text>
                        </Col>
                        <Col>
                            <Flex justify="flex-end">
                                <Skeleton
                                    loading={isDataLoading}
                                    active
                                    paragraph={false}
                                    title={{
                                        width: '20%',
                                        style: {
                                            marginLeft: 'auto',
                                            minWidth: 85,
                                        },
                                    }}
                                >
                                    <Text
                                        data-merchant={`withdrawal-fiat-receive-amount-${selectedCurrencyCode}`}
                                        type={Big(receiveAmount || 0).lte(0) ? 'secondary' : undefined}
                                    >
                                        <StatisticFormattedNumber
                                            precision={2}
                                            value={receiveAmount}
                                            suffix={selectedCurrencyCode}
                                        />
                                    </Text>
                                </Skeleton>
                            </Flex>
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item noStyle>
                    <Button
                        disabled={!permissions.commit_fiat_withdrawals}
                        block
                        type="primary"
                        htmlType="submit"
                        data-merchant="withdrawal-fiat-submit-button"
                        loading={isDataLoading}
                    >
                        <FormattedMessage {...globalLang.continue} />
                    </Button>
                </Form.Item>
            </div>
        </Form>
    )
}
