import { useCommonAppSettings } from '@merchant/shared/api'
import { AppCoin } from '@merchant/shared/components'
import { Divider, Flex, Typography } from '@merchant/ui-kit/ant-design'
import { isEmpty } from 'lodash-es'
import { useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { formFields } from '../../const'
import { lang } from './lang'
import { getNetworkOptions } from './utils'
import type { FormInstance } from '@merchant/ui-kit/ant-design'
import type { DefaultOptionType } from '@merchant/ui-kit/ant-design/es/select'
import type { NewDepositAddressState } from '~features/ActionModal/views/Deposit/types'
import { useBalances, useCurrencies } from '~api'
import { useDepositEligibleCurrencies } from '~hooks/useDepositEligibleCurrencies'
import { currenciesCommonSort } from '~utils'

const { Text } = Typography

export const useCurrencyOptions = () => {
    const { data: currencies, isLoading: isLoadingCurrencies } = useCurrencies()
    const { data: balances, isLoading: isLoadingBalances } = useBalances()
    const depositEligibleCurrencies = useDepositEligibleCurrencies()

    const options = useMemo(() => {
        const currenciesSorted = currenciesCommonSort(Object.values(currencies || {}), currencies, balances)

        const balancesMap = balances?.reduce<Record<string, string>>((acc, balance) => {
            acc[balance.currency] = balance.amount

            return acc
        }, {})

        const { disabled, enabled } = currenciesSorted.reduce<{
            enabled: DefaultOptionType[]
            disabled: DefaultOptionType[]
        }>(
            (acc, { code, name, fullName, crypto }) => {
                if (!crypto) {
                    return acc
                }
                const enabled = depositEligibleCurrencies[code] ?? false
                const option: DefaultOptionType = {
                    label: (
                        <Flex gap={4} style={{ opacity: enabled ? 1 : 0.5 }}>
                            <AppCoin currencyCode={code} size="small" />
                            <Text data-merchant={null}>{code}</Text>
                            <Text type="secondary" ellipsis data-merchant={null}>
                                {name}
                            </Text>
                            <Text style={{ marginLeft: 'auto' }} type="secondary" data-merchant={null}>
                                {enabled ? (
                                    `${balancesMap?.[code] ?? 0} ${code}`
                                ) : (
                                    <FormattedMessage {...lang.unavailableDeposit} />
                                )}
                            </Text>
                        </Flex>
                    ),
                    disabled: !enabled,
                    value: code,
                    key: `${code} ${name} ${fullName ?? ''}`,
                }

                if (enabled) {
                    acc.enabled.push(option)
                } else {
                    acc.disabled.push(option)
                }

                return acc
            },
            { enabled: [], disabled: [] }
        )

        return [
            { options: Object.values(enabled), label: null },
            {
                options: Object.values(disabled),
                label: isEmpty(disabled) ? null : <Divider style={{ marginBlock: 4 }} />,
                disabled: true,
            },
        ]
    }, [balances, currencies, depositEligibleCurrencies])

    return {
        options,
        isLoading: isLoadingCurrencies || isLoadingBalances,
    }
}

type UseCurrencySelectionHandler = (params: {
    currency: string
    form: FormInstance
    onFinish?: (value: NewDepositAddressState) => void
}) => DefaultOptionType[]

export const useCurrencySelectionHandler: UseCurrencySelectionHandler = ({ currency, form, onFinish }) => {
    const [networks, setNetworks] = useState<DefaultOptionType[]>([])
    const { data: currenciesMap } = useCurrencies()
    const { data: appSettings } = useCommonAppSettings()

    useEffect(() => {
        const { enabled, disabled } = getNetworkOptions(currency, currenciesMap, appSettings?.networksOrder)
        setNetworks([enabled, disabled])
        form.setFieldValue(formFields.network, undefined)
        if (enabled.options.length === 1) {
            onFinish?.({ currency, network: enabled.options[0]?.value as string })
            form.setFieldValue(formFields.network, enabled.options[0]?.value as string)
        }
        if (enabled.options.length === 0) {
            onFinish?.({ currency })
        }
        if (enabled.options.length > 1) {
            onFinish?.(null)
        }
        // Do not add form and onFinish to dependencies, you will get an infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appSettings?.networksOrder, currenciesMap, currency])

    return networks
}
