import { DirtyCheckingContextProvider } from '~contexts/dirtyChecking.context'

export function withDirtyCheckingContextProvider<P extends object>(
    WrappedComponent: React.ComponentType<P>
): React.FC<P> {
    // eslint-disable-next-line react/function-component-definition
    const WithContext: React.FC<P> = ({ ...props }: P) => (
        <DirtyCheckingContextProvider>
            <WrappedComponent {...props} />
        </DirtyCheckingContextProvider>
    )

    return WithContext
}
