import { FormattedMessage } from 'react-intl'
import type { MessageDescriptor } from 'react-intl'

export function getNotificationSubject(notificationContentSubject: string | MessageDescriptor) {
    return typeof notificationContentSubject === 'string' ? (
        notificationContentSubject
    ) : (
        <FormattedMessage {...notificationContentSubject} />
    )
}
