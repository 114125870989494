import { parseDateAndTime } from '@merchant/shared/utils'
import { Divider, Flex, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang as commonLang } from '../../lang'
import { lang } from './lang'
import styles from './style.module.css'
import type { ReactNode } from 'react'
import type { MessageDescriptor } from 'react-intl'
import type { Payment } from '~api/instances/cabinet-api'
import { FeesPayer } from '~api/instances/cabinet-api'
import { NoValueColumnContent } from '~components'

const { Text } = Typography

const feesPayerMap: Record<FeesPayer, MessageDescriptor> = {
    [FeesPayer.Customer]: commonLang.client,
    [FeesPayer.Merchant]: commonLang.merchant,
}

interface InfoItemProps {
    labelDescriptor: MessageDescriptor
    value: ReactNode | undefined
    dataMerchant: string
}

interface Props {
    payment: Payment
}

export function PaymentDetails({ payment }: Props) {
    const parsedDate = parseDateAndTime(payment.deadlineAt || '')

    const commonInfoItems: InfoItemProps[] = [
        {
            labelDescriptor: lang.feeIsPaidBy,
            value: <FormattedMessage {...feesPayerMap[payment.feesPayer]} />,
            dataMerchant: 'order-details-fee-payer-text',
        },
        {
            labelDescriptor: commonLang.orderId,
            value: payment.details.orderId,
            dataMerchant: 'order-details-id-text',
        },
        {
            labelDescriptor: commonLang.clientEmail,
            value: payment.details.customerEmail,
            dataMerchant: 'order-details-client-email-text',
        },
        {
            labelDescriptor: commonLang.clientId,
            value: payment.details.customerId,
            dataMerchant: 'order-details-client-id-text',
        },
        {
            labelDescriptor: commonLang.successRedirect,
            value: payment.details.successRedirectUrl,
            dataMerchant: 'order-details-success-redirect-text',
        },
        {
            labelDescriptor: commonLang.failRedirect,
            value: payment.details.failureRedirectUrl,
            dataMerchant: 'order-details-failure-redirect-text',
        },
    ]

    return (
        <Flex vertical wrap="nowrap" style={{ textWrap: 'nowrap' }} gap={8}>
            <Divider style={{ marginBlock: '8px 12px' }} />
            <Flex justify="space-between" wrap="nowrap" align="baseline">
                <Text type="secondary" data-merchant={null}>
                    <FormattedMessage {...commonLang.expirationTime} />
                </Text>
                <span className={styles.middleStrokeSeparator} />
                <span>
                    {parsedDate ? (
                        <>
                            <Text
                                type="secondary"
                                style={{ fontSize: 16 }}
                                data-merchant="order-details-expiration-time-text"
                            >
                                {parsedDate.time}
                            </Text>{' '}
                            <Text style={{ fontSize: 16 }} data-merchant="order-details-expiration-date-text">
                                {parsedDate.date}
                            </Text>
                        </>
                    ) : (
                        NoValueColumnContent
                    )}
                </span>
            </Flex>
            {commonInfoItems.map(({ labelDescriptor, value, dataMerchant }, i) => (
                <Flex key={i} justify="space-between" wrap="nowrap" align="baseline">
                    <Text type="secondary" data-merchant={null}>
                        <FormattedMessage {...labelDescriptor} />
                    </Text>
                    <span className={styles.middleStrokeSeparator} />
                    {value ? (
                        <Text ellipsis style={{ fontSize: 16, textAlign: 'right' }} data-merchant={dataMerchant}>
                            {value}
                        </Text>
                    ) : (
                        NoValueColumnContent
                    )}
                </Flex>
            ))}
        </Flex>
    )
}
