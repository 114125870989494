import { Button, Form, Input } from '@merchant/ui-kit/ant-design'
import { FormattedMessage, useIntl } from 'react-intl'
import { ApiKeyPermissionsItem } from '../../..'
import { formItemsKey, initialValues } from './const'
import { lang } from './lang'
import type { CreateApiKeyData, CreateApiKeyFormData } from '../../types'
import type { InputRef } from '@merchant/ui-kit/ant-design'
import { useApiKeyPermissions } from '~api'
import { ModalNames } from '~constants/modal'
import { API_KEY_TITLE_ALLOWED_LENGTH } from '~features/IntegrationsPage/views/Integrations/const'
import { transformFormPermissionsToAPI } from '~features/IntegrationsPage/views/Integrations/utils'
import { globalLang } from '~globalLang'
import { useAutoFocus, useRegisterDirtyFormChecker } from '~hooks'

interface Props {
    onSuccess: (data: CreateApiKeyData) => void
}

export function ApiKeyForm({ onSuccess }: Props) {
    const { data: apiKeyPermissions = [] } = useApiKeyPermissions()
    const nameInputRef = useAutoFocus<InputRef>()
    const intl = useIntl()
    const [form] = Form.useForm<CreateApiKeyFormData>()

    const handleFormFinish = ({ name, permissions }: CreateApiKeyFormData) => {
        const apiPermissions = transformFormPermissionsToAPI(permissions)

        onSuccess({ name, permissions: apiPermissions })
    }

    useRegisterDirtyFormChecker(ModalNames.createApiKeyForm, {
        initial: initialValues,
        current: form.getFieldsValue,
    })

    return (
        <Form<CreateApiKeyFormData>
            form={form}
            onFinish={handleFormFinish}
            layout="vertical"
            initialValues={initialValues}
            requiredMark={false}
            size="large"
        >
            <Form.Item
                label={<FormattedMessage {...lang.titleLabel} />}
                name={formItemsKey.name}
                rules={[
                    {
                        max: API_KEY_TITLE_ALLOWED_LENGTH,
                        message: (
                            <span data-merchant="api-key-title-exceeds-length-error-message">
                                <FormattedMessage
                                    {...globalLang.maxAllowedLength}
                                    values={{
                                        length: API_KEY_TITLE_ALLOWED_LENGTH,
                                    }}
                                />
                            </span>
                        ),
                    },
                    {
                        required: true,
                        message: (
                            <span data-merchant="api-key-title-required-error-message">
                                <FormattedMessage {...globalLang.requiredFieldMessage} />
                            </span>
                        ),
                    },
                ]}
            >
                <Input
                    autoComplete="off"
                    data-merchant="api-key-title-input"
                    ref={nameInputRef}
                    placeholder={intl.formatMessage(lang.titlePlaceholder)}
                />
            </Form.Item>
            <ApiKeyPermissionsItem permissions={apiKeyPermissions} />
            <Form.Item noStyle>
                <Button htmlType="submit" data-merchant="api-key-title-submit" block type="primary">
                    <FormattedMessage {...lang.continue} />
                </Button>
            </Form.Item>
        </Form>
    )
}
