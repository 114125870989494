/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { APIKeyCreatedBy } from './APIKeyCreatedBy';
import {
    APIKeyCreatedByFromJSON,
    APIKeyCreatedByFromJSONTyped,
    APIKeyCreatedByToJSON,
} from './APIKeyCreatedBy';
import type { ProjectAPIKeyPermission } from './ProjectAPIKeyPermission';
import {
    ProjectAPIKeyPermissionFromJSON,
    ProjectAPIKeyPermissionFromJSONTyped,
    ProjectAPIKeyPermissionToJSON,
} from './ProjectAPIKeyPermission';

/**
 * 
 * @export
 * @interface APIKey
 */
export interface APIKey {
    /**
     * 
     * @type {string}
     * @memberof APIKey
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof APIKey
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof APIKey
     */
    publicKey: string;
    /**
     * 
     * @type {string}
     * @memberof APIKey
     */
    privateKeyMask: string;
    /**
     * 
     * @type {boolean}
     * @memberof APIKey
     */
    active: boolean;
    /**
     * 
     * @type {Array<ProjectAPIKeyPermission>}
     * @memberof APIKey
     */
    permissions: Array<ProjectAPIKeyPermission>;
    /**
     * 
     * @type {APIKeyCreatedBy}
     * @memberof APIKey
     */
    createdBy?: APIKeyCreatedBy;
    /**
     * 
     * @type {Date}
     * @memberof APIKey
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof APIKey
     */
    lastUsedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof APIKey
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the APIKey interface.
 */
// @ts-ignore
export function instanceOfAPIKey(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "publicKey" in value;
    isInstance = isInstance && "privateKeyMask" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "permissions" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;

    return isInstance;
}

// @ts-ignore
export function APIKeyFromJSON(json: any): APIKey {
    return APIKeyFromJSONTyped(json, false);
}

// @ts-ignore
export function APIKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): APIKey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'publicKey': json['public_key'],
        'privateKeyMask': json['private_key_mask'],
        'active': json['active'],
        'permissions': ((json['permissions'] as Array<any>).map(ProjectAPIKeyPermissionFromJSON)),
        'createdBy': !exists(json, 'created_by') ? undefined : APIKeyCreatedByFromJSON(json['created_by']),
        'createdAt': (new Date(json['created_at'])),
        'lastUsedAt': !exists(json, 'last_used_at') ? undefined : (new Date(json['last_used_at'])),
        'updatedAt': (new Date(json['updated_at'])),
    };
}

// @ts-ignore
export function APIKeyToJSON(value?: APIKey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'public_key': value.publicKey,
        'private_key_mask': value.privateKeyMask,
        'active': value.active,
        'permissions': ((value.permissions as Array<any>).map(ProjectAPIKeyPermissionToJSON)),
        'created_by': APIKeyCreatedByToJSON(value.createdBy),
        'created_at': (value.createdAt.toISOString()),
        'last_used_at': value.lastUsedAt === undefined ? undefined : (value.lastUsedAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
    };
}

