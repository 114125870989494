import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { usePlaceholderView } from '../../hooks'
import { lang } from './lang'
import { LoadingSkeleton, ExistingAddressCard, NewAddressCard } from './views'
import { useProjectBlocklist } from '~api'
import { QueryParams } from '~constants/routes'
import { useActionModalContext } from '~features/ActionModal/context'
import { PlaceholderView } from '~features/PlaceholderView'

export function Deposit() {
    const { setTitle: setModalTitle } = useActionModalContext()
    const [searchParams] = useSearchParams()
    const addressId = searchParams.get(QueryParams.id) || ''

    const { data: blocklistData, isLoading: isBlocklistLoading } = useProjectBlocklist()
    const { name: placeholderName, isLoading: isLoadingPlaceholder } = usePlaceholderView()

    const isLoadingData = isLoadingPlaceholder || isBlocklistLoading

    useEffect(() => {
        if (isLoadingData) {
            return
        }
        if (placeholderName) {
            setModalTitle(false)
        } else {
            setModalTitle({ text: lang.depositTitle, dataMerchant: 'deposit-modal-title' })
        }
    }, [blocklistData, isLoadingData, placeholderName, setModalTitle])

    if (isLoadingData) {
        return <LoadingSkeleton />
    }

    if (placeholderName) {
        return (
            <PlaceholderView
                name={placeholderName}
                spaceProps={{
                    style: {
                        padding: 0,
                        width: '100%',
                        justifyContent: 'center',
                    },
                    styles: {
                        item: {
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                        },
                    },
                }}
                buttonProps={{ block: true, 'data-merchant': 'deposit-placeholder-button' }}
            />
        )
    }

    if (addressId) {
        return <ExistingAddressCard id={addressId} />
    } else {
        return <NewAddressCard />
    }
}
