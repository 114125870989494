import { AppCoin } from '@merchant/shared/components'
import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import type { SelectProps } from '@merchant/ui-kit/ant-design'
import type { Currency } from '~api/instances/cabinet-api'
import type { Currencies } from '~api/types'

const { Text } = Typography

function currencyWithEquivalentPredicate(
    currency: Currencies[number]
): currency is Currency & { equivalentPriority: number } {
    return typeof currency.equivalentPriority === 'number'
}

type GetOptions = (currencies: Currencies | undefined) => SelectProps['options']

export const getBaseCurrenciesOptions: GetOptions = currencies => {
    const currenciesList = Object.values(currencies || {})
    const enabledCurrencies = currenciesList.filter(currencyWithEquivalentPredicate)

    const sorted = enabledCurrencies.toSorted((a, b) => a.equivalentPriority - b.equivalentPriority)

    return sorted.map(c => ({
        key: [c.code, c.name, c.fullName].filter(Boolean).join(''),
        value: c.code,
        label: (
            <Flex align="center">
                <AppCoin style={{ marginRight: 8, flexShrink: 0 }} currencyCode={c.code} size="small" />
                <Text data-merchant={null}>{c.code}</Text>
                &nbsp;
                <Text data-merchant={null} type="secondary" ellipsis>
                    {c.fullName}
                </Text>
                &nbsp;
                {c.sign && (
                    <Text data-merchant={null} type="secondary">
                        ∙ {c.sign}
                    </Text>
                )}
            </Flex>
        ),
    }))
}
