/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Actor } from './Actor';
import {
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import type { NestedError } from './NestedError';
import {
    NestedErrorFromJSON,
    NestedErrorFromJSONTyped,
    NestedErrorToJSON,
} from './NestedError';
import type { WithdrawalState } from './WithdrawalState';
import {
    WithdrawalStateFromJSON,
    WithdrawalStateFromJSONTyped,
    WithdrawalStateToJSON,
} from './WithdrawalState';

/**
 * 
 * @export
 * @interface Withdrawal
 */
export interface Withdrawal {
    /**
     * 
     * @type {string}
     * @memberof Withdrawal
     */
    creditAmount: string;
    /**
     * 
     * @type {string}
     * @memberof Withdrawal
     */
    swapRate?: string;
    /**
     * 
     * @type {string}
     * @memberof Withdrawal
     */
    nominalReceiveAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof Withdrawal
     */
    receiveAmount: string;
    /**
     * 
     * @type {string}
     * @memberof Withdrawal
     */
    networkFee: string;
    /**
     * 
     * @type {string}
     * @memberof Withdrawal
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Withdrawal
     */
    idempotencyKey?: string;
    /**
     * 
     * @type {WithdrawalState}
     * @memberof Withdrawal
     */
    state: WithdrawalState;
    /**
     * 
     * @type {string}
     * @memberof Withdrawal
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof Withdrawal
     */
    orderId?: string;
    /**
     * 
     * @type {string}
     * @memberof Withdrawal
     */
    origin?: string;
    /**
     * 
     * @type {string}
     * @memberof Withdrawal
     */
    creditCurrency: string;
    /**
     * 
     * @type {string}
     * @memberof Withdrawal
     */
    swapTxnId?: string;
    /**
     * 
     * @type {Date}
     * @memberof Withdrawal
     */
    swappedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Withdrawal
     */
    nominalReceiveCurrency?: string;
    /**
     * 
     * @type {string}
     * @memberof Withdrawal
     */
    receiveCurrency: string;
    /**
     * 
     * @type {string}
     * @memberof Withdrawal
     */
    receiveNetwork?: string;
    /**
     * 
     * @type {string}
     * @memberof Withdrawal
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof Withdrawal
     */
    addressTag?: string;
    /**
     * 
     * @type {string}
     * @memberof Withdrawal
     */
    payoutTxnId?: string;
    /**
     * 
     * @type {Date}
     * @memberof Withdrawal
     */
    committedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Withdrawal
     */
    hash?: string;
    /**
     * 
     * @type {Date}
     * @memberof Withdrawal
     */
    sentAt?: Date;
    /**
     * 
     * @type {NestedError}
     * @memberof Withdrawal
     */
    error?: NestedError;
    /**
     * 
     * @type {Date}
     * @memberof Withdrawal
     */
    canceledAt?: Date;
    /**
     * 
     * @type {Actor}
     * @memberof Withdrawal
     */
    canceledBy?: Actor;
    /**
     * 
     * @type {Date}
     * @memberof Withdrawal
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Withdrawal
     */
    updatedAt: Date;
    /**
     * 
     * @type {Actor}
     * @memberof Withdrawal
     */
    createdBy?: Actor;
}

/**
 * Check if a given object implements the Withdrawal interface.
 */
// @ts-ignore
export function instanceOfWithdrawal(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "creditAmount" in value;
    isInstance = isInstance && "receiveAmount" in value;
    isInstance = isInstance && "networkFee" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "creditCurrency" in value;
    isInstance = isInstance && "receiveCurrency" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;

    return isInstance;
}

// @ts-ignore
export function WithdrawalFromJSON(json: any): Withdrawal {
    return WithdrawalFromJSONTyped(json, false);
}

// @ts-ignore
export function WithdrawalFromJSONTyped(json: any, ignoreDiscriminator: boolean): Withdrawal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creditAmount': json['credit_amount'],
        'swapRate': !exists(json, 'swap_rate') ? undefined : json['swap_rate'],
        'nominalReceiveAmount': !exists(json, 'nominal_receive_amount') ? undefined : json['nominal_receive_amount'],
        'receiveAmount': json['receive_amount'],
        'networkFee': json['network_fee'],
        'id': json['id'],
        'idempotencyKey': !exists(json, 'idempotency_key') ? undefined : json['idempotency_key'],
        'state': WithdrawalStateFromJSON(json['state']),
        'note': !exists(json, 'note') ? undefined : json['note'],
        'orderId': !exists(json, 'order_id') ? undefined : json['order_id'],
        'origin': !exists(json, 'origin') ? undefined : json['origin'],
        'creditCurrency': json['credit_currency'],
        'swapTxnId': !exists(json, 'swap_txn_id') ? undefined : json['swap_txn_id'],
        'swappedAt': !exists(json, 'swapped_at') ? undefined : (new Date(json['swapped_at'])),
        'nominalReceiveCurrency': !exists(json, 'nominal_receive_currency') ? undefined : json['nominal_receive_currency'],
        'receiveCurrency': json['receive_currency'],
        'receiveNetwork': !exists(json, 'receive_network') ? undefined : json['receive_network'],
        'address': json['address'],
        'addressTag': !exists(json, 'address_tag') ? undefined : json['address_tag'],
        'payoutTxnId': !exists(json, 'payout_txn_id') ? undefined : json['payout_txn_id'],
        'committedAt': !exists(json, 'committed_at') ? undefined : (new Date(json['committed_at'])),
        'hash': !exists(json, 'hash') ? undefined : json['hash'],
        'sentAt': !exists(json, 'sent_at') ? undefined : (new Date(json['sent_at'])),
        'error': !exists(json, 'error') ? undefined : NestedErrorFromJSON(json['error']),
        'canceledAt': !exists(json, 'canceled_at') ? undefined : (new Date(json['canceled_at'])),
        'canceledBy': !exists(json, 'canceled_by') ? undefined : ActorFromJSON(json['canceled_by']),
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'createdBy': !exists(json, 'created_by') ? undefined : ActorFromJSON(json['created_by']),
    };
}

// @ts-ignore
export function WithdrawalToJSON(value?: Withdrawal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'credit_amount': value.creditAmount,
        'swap_rate': value.swapRate,
        'nominal_receive_amount': value.nominalReceiveAmount,
        'receive_amount': value.receiveAmount,
        'network_fee': value.networkFee,
        'id': value.id,
        'idempotency_key': value.idempotencyKey,
        'state': WithdrawalStateToJSON(value.state),
        'note': value.note,
        'order_id': value.orderId,
        'origin': value.origin,
        'credit_currency': value.creditCurrency,
        'swap_txn_id': value.swapTxnId,
        'swapped_at': value.swappedAt === undefined ? undefined : (value.swappedAt.toISOString()),
        'nominal_receive_currency': value.nominalReceiveCurrency,
        'receive_currency': value.receiveCurrency,
        'receive_network': value.receiveNetwork,
        'address': value.address,
        'address_tag': value.addressTag,
        'payout_txn_id': value.payoutTxnId,
        'committed_at': value.committedAt === undefined ? undefined : (value.committedAt.toISOString()),
        'hash': value.hash,
        'sent_at': value.sentAt === undefined ? undefined : (value.sentAt.toISOString()),
        'error': NestedErrorToJSON(value.error),
        'canceled_at': value.canceledAt === undefined ? undefined : (value.canceledAt.toISOString()),
        'canceled_by': ActorToJSON(value.canceledBy),
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'created_by': ActorToJSON(value.createdBy),
    };
}

