import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    publicKeyLabel: {
        id: 'integrations-page.modal-create.public_key_label',
        defaultMessage: 'Public key',
    },
    privateKeyLabel: {
        id: 'integrations-page.modal-create.private_key_label',
        defaultMessage: 'Private key',
    },
    submitButton: {
        id: 'integrations-page.modal-create.submit_button',
        defaultMessage: 'I saved Private Key, continue',
    },
    alertText: {
        id: 'integrations-page.modal-create.alert_text',
        defaultMessage:
            '{notice} Your Private Key is displayed only once. Save it securely. It cannot be retrieved later after you click the button below.',
    },
    alertNotice: {
        id: 'integrations-page.modal-create.alert_notice',
        defaultMessage: 'Notice:',
    },
})
