/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TransactionWithdrawalDetails
 */
export interface TransactionWithdrawalDetails {
    /**
     * 
     * @type {string}
     * @memberof TransactionWithdrawalDetails
     */
    withdrawalId?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionWithdrawalDetails
     */
    projectId?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionWithdrawalDetails
     */
    hash: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionWithdrawalDetails
     */
    address?: string;
}

/**
 * Check if a given object implements the TransactionWithdrawalDetails interface.
 */
// @ts-ignore
export function instanceOfTransactionWithdrawalDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "hash" in value;

    return isInstance;
}

// @ts-ignore
export function TransactionWithdrawalDetailsFromJSON(json: any): TransactionWithdrawalDetails {
    return TransactionWithdrawalDetailsFromJSONTyped(json, false);
}

// @ts-ignore
export function TransactionWithdrawalDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionWithdrawalDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'withdrawalId': !exists(json, 'withdrawal_id') ? undefined : json['withdrawal_id'],
        'projectId': !exists(json, 'project_id') ? undefined : json['project_id'],
        'hash': json['hash'],
        'address': !exists(json, 'address') ? undefined : json['address'],
    };
}

// @ts-ignore
export function TransactionWithdrawalDetailsToJSON(value?: TransactionWithdrawalDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'withdrawal_id': value.withdrawalId,
        'project_id': value.projectId,
        'hash': value.hash,
        'address': value.address,
    };
}

