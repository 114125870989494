import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { usePromise } from '@merchant/shared/hooks'
import { Alert, Button, Divider, Form } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { useSWRConfig } from 'swr'
import type { ConfirmationItems } from './types'
import type { FormProps } from '@merchant/ui-kit/ant-design'
import type { ReactNode } from 'react'
import type { MessageDescriptor } from 'react-intl'
import type { WhitelistAddress } from '~api/instances/cabinet-api'
import { authApi, useMerchant } from '~api'
import { GenerateVerificationCodeRequestPurpose } from '~api/instances/cabinet-api'
import { apiRestKeys } from '~api/swr/keys'
import { ModalNames } from '~constants/modal'
import { EmailConfirmationItem, TwoFaConfirmationItem } from '~features/FormItems'
import { useCodeConfirmationErrorHandling } from '~features/FormItems/useCodeErrorHandling'
import { useRegisterDirtyFormChecker } from '~hooks'

const alertStyles: React.CSSProperties = { alignItems: 'center' }

const purposeNameMap = {
    [GenerateVerificationCodeRequestPurpose.EnableWhitelist]: ModalNames.whitelistEnableConfirmation,
    [GenerateVerificationCodeRequestPurpose.AddToWhitelist]: ModalNames.whitelistAddConfirmation,
    [GenerateVerificationCodeRequestPurpose.RemoveFromWhitelist]: ModalNames.whitelistDelete,
    [GenerateVerificationCodeRequestPurpose.DisableWhitelist]: ModalNames.whitelistDisable,
}

interface Props extends FormProps {
    request: (values: ConfirmationItems) => Promise<void>
    onSuccess: () => void
    buttonTitle: MessageDescriptor
    alertMessage: ReactNode
    whitelist?: WhitelistAddress
    purpose:
        | GenerateVerificationCodeRequestPurpose.AddToWhitelist
        | GenerateVerificationCodeRequestPurpose.EnableWhitelist
        | GenerateVerificationCodeRequestPurpose.RemoveFromWhitelist
        | GenerateVerificationCodeRequestPurpose.DisableWhitelist
}

export function ConfirmationView({
    request,
    buttonTitle,
    alertMessage,
    onSuccess,
    purpose,
    whitelist,
    ...rest
}: Props) {
    const { mutate } = useSWRConfig()
    const [form] = Form.useForm()
    const { data: { email: merchantEmail } = {} } = useMerchant()
    const { handleError } = useCodeConfirmationErrorHandling({
        confirmationStrategy: { '2fa': 'code', email: 'code' },
    })
    const { send: sendConfirmationRequest, isLoading: isConfirmationLoading } = usePromise(request, {
        onError: error => handleError({ error, form }),
        onSuccess: () => {
            mutate(apiRestKeys.getProjectBlocklist)
            onSuccess()
        },
        showErrorNotification: false,
    })

    useRegisterDirtyFormChecker(purposeNameMap[purpose], form)

    const { send: generateVerificationCode } = usePromise(() =>
        authApi.generateVerificationCode({
            generateVerificationCodeRequest: {
                purpose,
                details: whitelist && {
                    address: whitelist.address,
                    currency: whitelist.currency,
                    network: whitelist.network,
                    name: whitelist.name || '',
                },
            },
        })
    )

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            form={form}
            onFinish={sendConfirmationRequest}
            size="large"
            {...rest}
        >
            <EmailConfirmationItem
                dataMerchant="whitelist-email-confirmation-input"
                email={merchantEmail || 'email'}
                onResendClick={generateVerificationCode}
                shouldFocusOnMount
                shouldSendOnMount
            />
            <Divider />
            <TwoFaConfirmationItem
                dataMerchant="whitelist-twofa-confirmation-input"
                onToggle={() => form.resetFields(['totpCode'])}
            />
            <Form.Item>
                <Alert
                    data-merchant="whitelist-confirmation-alert"
                    style={alertStyles}
                    type="warning"
                    icon={<AppSvg size={24} name={assets.alertCircle} />}
                    showIcon
                    description={alertMessage}
                />
            </Form.Item>
            <Form.Item noStyle>
                <Button
                    data-merchant="whitelist-twofa-confirmation-submit"
                    htmlType="submit"
                    block
                    type="primary"
                    loading={isConfirmationLoading}
                >
                    <FormattedMessage {...buttonTitle} />
                </Button>
            </Form.Item>
        </Form>
    )
}
