import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { Form, Input, Space, Tooltip, Typography } from '@merchant/ui-kit/ant-design'
import React, { useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useBoolean } from 'usehooks-ts'
import { validationStatusTypeMap } from './const'
import { lang } from './lang'
import styles from './style.module.css'
import { getPasswordValidations, getValidationStatus, passwordFormItemValidator } from './utils'
import type { FormInstance, FormItemProps, InputProps, TooltipProps } from '@merchant/ui-kit/ant-design'
import { globalLang } from '~globalLang'

const { Paragraph } = Typography

const paragraphStyles: React.CSSProperties = { marginBottom: 0 }

interface PropsInput extends InputProps {
    onBlur?: () => void
    keepInvalidShown: boolean
    tooltipPlacement: TooltipProps['placement']
}

function InputWithTooltip({ onBlur, tooltipPlacement, keepInvalidShown, ...rest }: PropsInput) {
    const intl = useIntl()
    const passwordValidations = getPasswordValidations(intl, rest.value?.toString())

    const { value: isTooltipShown, setFalse: closeTooltip, setTrue: openTooltip } = useBoolean(false)
    const { value: isInputBlurred, setValue: setIsInputBlurred } = useBoolean(false)
    const { value: isInputFocused, setValue: setIsInputFocused } = useBoolean(false)

    const isFailingValidation = Object.values(passwordValidations).some(i => !i.isValid)
    const isInvalid = isInputBlurred && isFailingValidation

    const handleBlur = () => {
        onBlur?.()
        setIsInputBlurred(true)
    }

    useEffect(() => {
        if (isInputFocused && isFailingValidation) {
            openTooltip()
        } else if (!keepInvalidShown || !isFailingValidation) {
            closeTooltip()
        }
    }, [closeTooltip, isInputFocused, isFailingValidation, openTooltip, keepInvalidShown])

    const placeholder = rest.placeholder ?? intl.formatMessage(lang.passwordDefaultPlaceholder)

    return (
        <Tooltip
            trigger={undefined}
            open={isTooltipShown}
            placement={tooltipPlacement}
            title={
                <Space direction="vertical" size={6} data-merchant="password-validation-tooltip">
                    {Object.entries(passwordValidations).map(([key, validation]) => {
                        const validationStatus = getValidationStatus(validation, isInputBlurred)

                        return (
                            <Paragraph
                                key={key}
                                style={paragraphStyles}
                                type={validationStatusTypeMap[validationStatus]}
                                data-merchant={`password-validation-${validation.name}-${validationStatus}`}
                            >
                                {validation.message}
                            </Paragraph>
                        )
                    })}
                </Space>
            }
            data-merchant="password-validation-tooltip"
        >
            <Input.Password
                className={styles.input}
                onFocus={() => setIsInputFocused(true)}
                onBlur={handleBlur}
                iconRender={visible => (
                    /* TODO: Find out why class does not set without Tooltip wrapping */
                    <Tooltip open={false} data-merchant="password-visibility-tooltip">
                        {visible ? (
                            <EyeOutlined
                                className={isInvalid ? styles.isInvalid : styles.visibleIcon}
                                data-merchant="password-visibility-icon-hidden"
                            />
                        ) : (
                            <EyeInvisibleOutlined data-merchant="password-visibility-icon-visible" />
                        )}
                    </Tooltip>
                )}
                placeholder={placeholder}
                {...rest}
            />
        </Tooltip>
    )
}

interface Props extends FormItemProps {
    form: FormInstance
    tooltipPlacement?: TooltipProps['placement']
    keepInvalidShown?: boolean
    onBlur?: () => void
    inputPlaceholder?: string
    inputDataMerchant: string
}

export function PasswordWithTooltip({
    tooltipPlacement = 'right',
    keepInvalidShown = false,
    onBlur,
    form,
    inputPlaceholder,
    inputDataMerchant,
    ...rest
}: Props) {
    const intl = useIntl()
    const passwordValue = Form.useWatch(rest.name, form)

    return (
        <Form.Item
            rules={[
                {
                    required: true,
                    message: (
                        <span data-merchant={`${inputDataMerchant}-required-error-message`}>
                            <FormattedMessage {...globalLang.requiredFieldMessage} />
                        </span>
                    ),
                },
                () => ({
                    validator: passwordFormItemValidator(intl, passwordValue),
                }),
            ]}
            {...rest}
        >
            <InputWithTooltip
                onBlur={onBlur}
                keepInvalidShown={keepInvalidShown}
                tooltipPlacement={tooltipPlacement}
                placeholder={inputPlaceholder}
                data-merchant={inputDataMerchant}
            />
        </Form.Item>
    )
}
