import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import Big from 'big.js'
import { FormattedMessage } from 'react-intl'
import { DEFAULT_OPTION_VALUE, CUSTOM_OPTION_VALUE } from './const'
import { lang } from './lang'
import type { LossConfig } from '~api/instances/cabinet-api'
import { globalLang } from '~globalLang'

const { Text } = Typography

export const normalizeEmptyString = (value: unknown) => value?.toString().trim() || undefined

export function getUnderpaymentOptions(projectLossData: LossConfig | undefined) {
    const relativePercentage = Big(projectLossData?.relative || 0)
        .mul(100)
        .toString()

    return [
        {
            label: (
                <Flex justify="space-between" align="center">
                    <span>
                        <Text data-merchant="underpayment-relative-percentage">{relativePercentage}%&nbsp;</Text>
                        {projectLossData?.absolute?.amount && (
                            <Text data-merchant="underpayment-absolute-default">
                                <FormattedMessage
                                    {...lang.underpaymentAbsoluteDefault}
                                    values={{
                                        amount: projectLossData.absolute.amount,
                                        currency: projectLossData.absolute.currency,
                                    }}
                                />
                            </Text>
                        )}
                    </span>
                    <Text type="secondary" data-merchant={null}>
                        <FormattedMessage {...globalLang.default} />
                    </Text>
                </Flex>
            ),
            value: DEFAULT_OPTION_VALUE,
        },
        {
            label: (
                <Flex justify="space-between">
                    <Text data-merchant={null}>
                        <FormattedMessage {...globalLang.custom} />
                    </Text>
                </Flex>
            ),
            value: CUSTOM_OPTION_VALUE,
        },
    ]
}
