import { useBreakpoint } from '@merchant/shared/hooks'
import { Card, Col, Divider, Row, Skeleton, Space, Typography } from '@merchant/ui-kit/ant-design'
import styles from '../../style.module.css'
import type { DetailsItem } from './types'
import type { ReactNode } from 'react'
import { NoValueColumnContent } from '~components'

const { Text } = Typography

function DetailRow({ item, index }: { item: DetailsItem; index: number }) {
    if (item.type === 'divider') {
        return <Divider style={{ marginBlock: 4 }} key={`divider-${index}`} />
    }
    const { key, rightCol, rowTitle } = item

    return (
        <Row key={key} justify="space-between" wrap={false}>
            <Col>
                <Text type="secondary" data-merchant={null}>
                    {rowTitle}
                </Text>
            </Col>
            <Col className={styles.middleCol} />
            <Col flex="none" span={rightCol.span}>
                {rightCol.content || NoValueColumnContent}
            </Col>
        </Row>
    )
}

interface Props {
    loading: boolean
    cardTitle: ReactNode
    rowsCount: number
    detailsItems: DetailsItem[]
    isTitleLoading?: boolean
    stretch?: boolean
    extra?: ReactNode
}

export function OrderCard({
    loading,
    detailsItems,
    rowsCount,
    cardTitle,
    isTitleLoading = false,
    stretch = false,
    extra,
}: Props) {
    const breakpoints = useBreakpoint()

    return (
        <Card
            styles={{
                body: { padding: breakpoints.md ? '16px 20px 20px' : 12 },
            }}
            style={{ flexGrow: stretch ? 1 : 0 }}
            data-merchant="order-card"
        >
            {!isTitleLoading && <div style={{ marginBottom: 16 }}>{cardTitle}</div>}
            {loading ? (
                <Skeleton active title={isTitleLoading ? { width: '60%' } : false} paragraph={{ rows: rowsCount }} />
            ) : (
                <Space direction="vertical" size={12} style={{ width: '100%' }}>
                    {detailsItems.map((item, index) => (
                        <DetailRow item={item} index={index} key={item.type === 'item' ? item.key : index} />
                    ))}
                </Space>
            )}
            {extra}
        </Card>
    )
}
