/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  APIErrorResponse,
  ConfirmPasswordResetRequest,
  ConfirmSigninRequest,
  ConfirmTOTPRequest,
  CreateTOTPResponse,
  DeleteTOTPRequest,
  GenerateVerificationCodeRequest,
  PasswordChangeRequest,
  PasswordResetRequest,
  SendVerificationCodeRequest,
  SigninRequest,
  SigninResponse,
  SignupConfirmRequest,
  SignupRequest,
  SignupResponse,
  TOTP,
  ValidateTOTPRequest,
} from '../models/index';
import {
    APIErrorResponseFromJSON,
    APIErrorResponseToJSON,
    ConfirmPasswordResetRequestFromJSON,
    ConfirmPasswordResetRequestToJSON,
    ConfirmSigninRequestFromJSON,
    ConfirmSigninRequestToJSON,
    ConfirmTOTPRequestFromJSON,
    ConfirmTOTPRequestToJSON,
    CreateTOTPResponseFromJSON,
    CreateTOTPResponseToJSON,
    DeleteTOTPRequestFromJSON,
    DeleteTOTPRequestToJSON,
    GenerateVerificationCodeRequestFromJSON,
    GenerateVerificationCodeRequestToJSON,
    PasswordChangeRequestFromJSON,
    PasswordChangeRequestToJSON,
    PasswordResetRequestFromJSON,
    PasswordResetRequestToJSON,
    SendVerificationCodeRequestFromJSON,
    SendVerificationCodeRequestToJSON,
    SigninRequestFromJSON,
    SigninRequestToJSON,
    SigninResponseFromJSON,
    SigninResponseToJSON,
    SignupConfirmRequestFromJSON,
    SignupConfirmRequestToJSON,
    SignupRequestFromJSON,
    SignupRequestToJSON,
    SignupResponseFromJSON,
    SignupResponseToJSON,
    TOTPFromJSON,
    TOTPToJSON,
    ValidateTOTPRequestFromJSON,
    ValidateTOTPRequestToJSON,
} from '../models/index';

export interface ChangePasswordRequest {
    passwordChangeRequest: PasswordChangeRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface ConfirmResetPasswordRequest {
    confirmPasswordResetRequest: ConfirmPasswordResetRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface ConfirmSigninOperationRequest {
    confirmSigninRequest: ConfirmSigninRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface ConfirmTOTPOperationRequest {
    id: number;
    confirmTOTPRequest: ConfirmTOTPRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface CreateTOTPRequest {
    xSpanId?: string;
    xTraceId?: string;
}

export interface DeleteTOTPOperationRequest {
    id: number;
    deleteTOTPRequest: DeleteTOTPRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface GenerateVerificationCodeOperationRequest {
    generateVerificationCodeRequest: GenerateVerificationCodeRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface ListTOTPsRequest {
    xSpanId?: string;
    xTraceId?: string;
}

export interface ResetPasswordRequest {
    passwordResetRequest: PasswordResetRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface SendVerificationCodeOperationRequest {
    sendVerificationCodeRequest: SendVerificationCodeRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface SigninOperationRequest {
    signinRequest: SigninRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface SignupOperationRequest {
    signupRequest: SignupRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface SignupConfirmOperationRequest {
    signupConfirmRequest: SignupConfirmRequest;
    xSpanId?: string;
    xTraceId?: string;
}

export interface ValidateTOTPOperationRequest {
    id: number;
    validateTOTPRequest: ValidateTOTPRequest;
    xSpanId?: string;
    xTraceId?: string;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     * Change password
     */
    async changePasswordRaw(requestParameters: ChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.passwordChangeRequest === null || requestParameters.passwordChangeRequest === undefined) {
            throw new runtime.RequiredError('passwordChangeRequest','Required parameter requestParameters.passwordChangeRequest was null or undefined when calling changePassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/password/change`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordChangeRequestToJSON(requestParameters.passwordChangeRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change password
     */
    async changePassword(requestParameters: ChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changePasswordRaw(requestParameters, initOverrides);
    }

    /**
     * Confirm Reset password
     */
    async confirmResetPasswordRaw(requestParameters: ConfirmResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.confirmPasswordResetRequest === null || requestParameters.confirmPasswordResetRequest === undefined) {
            throw new runtime.RequiredError('confirmPasswordResetRequest','Required parameter requestParameters.confirmPasswordResetRequest was null or undefined when calling confirmResetPassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        const response = await this.request({
            path: `/password/reset/confirm`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConfirmPasswordResetRequestToJSON(requestParameters.confirmPasswordResetRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Confirm Reset password
     */
    async confirmResetPassword(requestParameters: ConfirmResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.confirmResetPasswordRaw(requestParameters, initOverrides);
    }

    /**
     * Confirm sign in via otp
     * Confirm sign in via otp
     */
    async confirmSigninRaw(requestParameters: ConfirmSigninOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.confirmSigninRequest === null || requestParameters.confirmSigninRequest === undefined) {
            throw new runtime.RequiredError('confirmSigninRequest','Required parameter requestParameters.confirmSigninRequest was null or undefined when calling confirmSignin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/signin/confirm`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConfirmSigninRequestToJSON(requestParameters.confirmSigninRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Confirm sign in via otp
     * Confirm sign in via otp
     */
    async confirmSignin(requestParameters: ConfirmSigninOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.confirmSigninRaw(requestParameters, initOverrides);
    }

    /**
     * Confirms a new TOTP-based two-authentication method with its own code and verification code from email.
     * Confirm TOTP
     */
    async confirmTOTPRaw(requestParameters: ConfirmTOTPOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling confirmTOTP.');
        }

        if (requestParameters.confirmTOTPRequest === null || requestParameters.confirmTOTPRequest === undefined) {
            throw new runtime.RequiredError('confirmTOTPRequest','Required parameter requestParameters.confirmTOTPRequest was null or undefined when calling confirmTOTP.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/totps/{id}/confirm`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConfirmTOTPRequestToJSON(requestParameters.confirmTOTPRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Confirms a new TOTP-based two-authentication method with its own code and verification code from email.
     * Confirm TOTP
     */
    async confirmTOTP(requestParameters: ConfirmTOTPOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.confirmTOTPRaw(requestParameters, initOverrides);
    }

    /**
     * Adds a new TOTP-based two-factor authentication method.
     * Create TOTP
     */
    async createTOTPRaw(requestParameters: CreateTOTPRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateTOTPResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/totps`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateTOTPResponseFromJSON(jsonValue));
    }

    /**
     * Adds a new TOTP-based two-factor authentication method.
     * Create TOTP
     */
    async createTOTP(requestParameters: CreateTOTPRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateTOTPResponse> {
        const response = await this.createTOTPRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes a TOTP-based two-factor authentication method given its id, code and verification code from email.
     * Delete TOTP
     */
    async deleteTOTPRaw(requestParameters: DeleteTOTPOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteTOTP.');
        }

        if (requestParameters.deleteTOTPRequest === null || requestParameters.deleteTOTPRequest === undefined) {
            throw new runtime.RequiredError('deleteTOTPRequest','Required parameter requestParameters.deleteTOTPRequest was null or undefined when calling deleteTOTP.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/totps/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteTOTPRequestToJSON(requestParameters.deleteTOTPRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes a TOTP-based two-factor authentication method given its id, code and verification code from email.
     * Delete TOTP
     */
    async deleteTOTP(requestParameters: DeleteTOTPOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTOTPRaw(requestParameters, initOverrides);
    }

    /**
     * Generate verification code to merchants email.
     * Generate verification code
     */
    async generateVerificationCodeRaw(requestParameters: GenerateVerificationCodeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.generateVerificationCodeRequest === null || requestParameters.generateVerificationCodeRequest === undefined) {
            throw new runtime.RequiredError('generateVerificationCodeRequest','Required parameter requestParameters.generateVerificationCodeRequest was null or undefined when calling generateVerificationCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/verification_codes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GenerateVerificationCodeRequestToJSON(requestParameters.generateVerificationCodeRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Generate verification code to merchants email.
     * Generate verification code
     */
    async generateVerificationCode(requestParameters: GenerateVerificationCodeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.generateVerificationCodeRaw(requestParameters, initOverrides);
    }

    /**
     * Returns a list of TOTP-based two-factor authentication methods.
     * List TOTPs
     */
    async listTOTPsRaw(requestParameters: ListTOTPsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TOTP>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/totps`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TOTPFromJSON));
    }

    /**
     * Returns a list of TOTP-based two-factor authentication methods.
     * List TOTPs
     */
    async listTOTPs(requestParameters: ListTOTPsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TOTP>> {
        const response = await this.listTOTPsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reset password
     */
    async resetPasswordRaw(requestParameters: ResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.passwordResetRequest === null || requestParameters.passwordResetRequest === undefined) {
            throw new runtime.RequiredError('passwordResetRequest','Required parameter requestParameters.passwordResetRequest was null or undefined when calling resetPassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        const response = await this.request({
            path: `/password/reset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordResetRequestToJSON(requestParameters.passwordResetRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reset password
     */
    async resetPassword(requestParameters: ResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.resetPasswordRaw(requestParameters, initOverrides);
    }

    /**
     * Send verification code to merchants email.
     * Send verification code
     */
    async sendVerificationCodeRaw(requestParameters: SendVerificationCodeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sendVerificationCodeRequest === null || requestParameters.sendVerificationCodeRequest === undefined) {
            throw new runtime.RequiredError('sendVerificationCodeRequest','Required parameter requestParameters.sendVerificationCodeRequest was null or undefined when calling sendVerificationCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        const response = await this.request({
            path: `/verification_code`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendVerificationCodeRequestToJSON(requestParameters.sendVerificationCodeRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send verification code to merchants email.
     * Send verification code
     */
    async sendVerificationCode(requestParameters: SendVerificationCodeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendVerificationCodeRaw(requestParameters, initOverrides);
    }

    /**
     * Sign in merchant\'s account
     * Sign in merchant\'s account
     */
    async signinRaw(requestParameters: SigninOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SigninResponse>> {
        if (requestParameters.signinRequest === null || requestParameters.signinRequest === undefined) {
            throw new runtime.RequiredError('signinRequest','Required parameter requestParameters.signinRequest was null or undefined when calling signin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        const response = await this.request({
            path: `/signin`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SigninRequestToJSON(requestParameters.signinRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SigninResponseFromJSON(jsonValue));
    }

    /**
     * Sign in merchant\'s account
     * Sign in merchant\'s account
     */
    async signin(requestParameters: SigninOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SigninResponse> {
        const response = await this.signinRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Register new merchant account. If account is created successfully, user will receive confirmation code via email. 
     * Register merchant
     */
    async signupRaw(requestParameters: SignupOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignupResponse>> {
        if (requestParameters.signupRequest === null || requestParameters.signupRequest === undefined) {
            throw new runtime.RequiredError('signupRequest','Required parameter requestParameters.signupRequest was null or undefined when calling signup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        const response = await this.request({
            path: `/signup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignupRequestToJSON(requestParameters.signupRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignupResponseFromJSON(jsonValue));
    }

    /**
     * Register new merchant account. If account is created successfully, user will receive confirmation code via email. 
     * Register merchant
     */
    async signup(requestParameters: SignupOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignupResponse> {
        const response = await this.signupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Confirm merchant\'s account registration with a code received via email.
     * Confirm registration
     */
    async signupConfirmRaw(requestParameters: SignupConfirmOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.signupConfirmRequest === null || requestParameters.signupConfirmRequest === undefined) {
            throw new runtime.RequiredError('signupConfirmRequest','Required parameter requestParameters.signupConfirmRequest was null or undefined when calling signupConfirm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        const response = await this.request({
            path: `/signup/confirm`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignupConfirmRequestToJSON(requestParameters.signupConfirmRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Confirm merchant\'s account registration with a code received via email.
     * Confirm registration
     */
    async signupConfirm(requestParameters: SignupConfirmOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.signupConfirmRaw(requestParameters, initOverrides);
    }

    /**
     * Validates a new TOTP-based two-authentication method with its own code.
     * Validate TOTP
     */
    async validateTOTPRaw(requestParameters: ValidateTOTPOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling validateTOTP.');
        }

        if (requestParameters.validateTOTPRequest === null || requestParameters.validateTOTPRequest === undefined) {
            throw new runtime.RequiredError('validateTOTPRequest','Required parameter requestParameters.validateTOTPRequest was null or undefined when calling validateTOTP.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xSpanId !== undefined && requestParameters.xSpanId !== null) {
            headerParameters['X-Span-Id'] = String(requestParameters.xSpanId);
        }

        if (requestParameters.xTraceId !== undefined && requestParameters.xTraceId !== null) {
            headerParameters['X-Trace-Id'] = String(requestParameters.xTraceId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("MerchantToken", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/totps/{id}/validate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ValidateTOTPRequestToJSON(requestParameters.validateTOTPRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validates a new TOTP-based two-authentication method with its own code.
     * Validate TOTP
     */
    async validateTOTP(requestParameters: ValidateTOTPOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.validateTOTPRaw(requestParameters, initOverrides);
    }

}
