import { Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import type { FormItemProps } from '@merchant/ui-kit/ant-design'
import type { MessageDescriptor } from 'react-intl'

const { Text } = Typography

export function getFormItemTooltip(titleText: MessageDescriptor): FormItemProps['tooltip'] {
    return {
        placement: 'bottom',
        title: (
            <Text type="secondary" style={{ fontSize: 12 }} data-merchant={null}>
                <FormattedMessage {...titleText} />
            </Text>
        ),
        'data-merchant': 'payment-constructor-form-item-tooltip',
    }
}
