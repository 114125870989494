import { useBreakpoint } from '@merchant/shared/hooks'
import { Button, Col, Flex, Popconfirm, Row, Tag, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { WhitelistDataView } from '..'
import { lang } from '../../lang'
import { Permission, type Blocklist } from '~api/instances/cabinet-api'
import { PageContentContainer } from '~components'
import { ActionSuspendedAlert } from '~components/ActionSuspended/ActionSuspendedAlert'
import { SuspensionContext } from '~components/ActionSuspended/types'
import { useUserPermissionContext } from '~contexts'
import { globalLang } from '~globalLang'

const { Text } = Typography

interface Props {
    blocklistData: Blocklist | undefined
    openDisableModal: () => void
}

export function WhitelistEnabled({ openDisableModal, blocklistData }: Props) {
    const { checkUserPermission } = useUserPermissionContext()
    const breakpoints = useBreakpoint()

    return (
        <>
            <PageContentContainer>
                <Flex gap={12} vertical>
                    <Row align="middle" justify="space-between" gutter={[0, 12]}>
                        <Col md={{ order: 1, span: 24 }}>
                            <Flex gap={8} align="center">
                                <Text style={{ fontSize: 16 }} data-merchant={null}>
                                    <FormattedMessage {...lang.whitelist} />
                                </Text>{' '}
                                <Tag
                                    data-merchant="whitelist-page-enabled-tag"
                                    color="success"
                                    bordered={false}
                                    style={{ padding: '4px 12px' }}
                                >
                                    <FormattedMessage {...lang.enabledTag} />
                                </Tag>
                            </Flex>
                        </Col>
                        <Col md={{ order: 2, span: 18 }} order={3}>
                            <Text type="secondary" data-merchant={null}>
                                <FormattedMessage {...lang.whitelistDescription} />
                            </Text>
                        </Col>
                        <Col md={{ order: 3 }} order={2}>
                            <Popconfirm
                                title={<FormattedMessage {...lang.disableConfirmMessage} />}
                                onConfirm={openDisableModal}
                                icon={null}
                                placement="bottom"
                                cancelText={<FormattedMessage {...globalLang.no} />}
                                okText={<FormattedMessage {...globalLang.confirm} />}
                                data-merchant="whitelist-page-disable-popconfirm"
                                okButtonProps={{
                                    size: 'middle',
                                    'data-merchant': 'whitelist-page-disable-popconfirm-ok-button',
                                }}
                                cancelButtonProps={{
                                    size: 'middle',
                                    'data-merchant': 'whitelist-page-disable-popconfirm-cancel-button',
                                }}
                            >
                                <Button
                                    disabled={!checkUserPermission(Permission.EditWithdrawalWhitelist)}
                                    data-merchant="whitelist-page-disable-button"
                                    style={{
                                        width: breakpoints.md ? 170 : undefined,
                                    }}
                                >
                                    <FormattedMessage {...lang.disableWhitelistButton} />
                                </Button>
                            </Popconfirm>
                        </Col>
                    </Row>
                    {blocklistData?.blocked && (
                        <ActionSuspendedAlert
                            blockReason={blocklistData.blockItems[0]?.reason}
                            context={SuspensionContext.Withdrawal}
                            expiresAt={blocklistData.blockItems[0]?.expiresAt}
                        />
                    )}
                </Flex>
            </PageContentContainer>
            <PageContentContainer data-merchant="whitelist-page-addresses-view">
                <WhitelistDataView />
            </PageContentContainer>
        </>
    )
}
