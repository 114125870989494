/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum APIErrorCode {
    InvalidRequestParameters = 'INVALID_REQUEST_PARAMETERS',
    InvalidRequestBody = 'INVALID_REQUEST_BODY',
    InvalidRequest = 'INVALID_REQUEST',
    RequestTimedOut = 'REQUEST_TIMED_OUT',
    TooManyRequests = 'TOO_MANY_REQUESTS',
    AmountTooLow = 'AMOUNT_TOO_LOW',
    AmountTooHigh = 'AMOUNT_TOO_HIGH',
    AccountIsDisabled = 'ACCOUNT_IS_DISABLED',
    AccountNotActive = 'ACCOUNT_NOT_ACTIVE',
    InvalidPassword = 'INVALID_PASSWORD',
    WeakPassword = 'WEAK_PASSWORD',
    InvalidOtp = 'INVALID_OTP',
    ExpiredOtp = 'EXPIRED_OTP',
    InvalidTotp = 'INVALID_TOTP',
    InvalidEmail = 'INVALID_EMAIL',
    OtpRequired = 'OTP_REQUIRED',
    EmptyDeviceToken = 'EMPTY_DEVICE_TOKEN',
    BankAccountNotReady = 'BANK_ACCOUNT_NOT_READY',
    NoHoldingCurrencies = 'NO_HOLDING_CURRENCIES',
    OperationTemporarilyBlocked = 'OPERATION_TEMPORARILY_BLOCKED',
    VerificationLevelTooLow = 'VERIFICATION_LEVEL_TOO_LOW',
    InvalidLink = 'INVALID_LINK',
    InvalidCursor = 'INVALID_CURSOR',
    NominalAmountInvalid = 'NOMINAL_AMOUNT_INVALID',
    PaymentMethodNotAllowed = 'PAYMENT_METHOD_NOT_ALLOWED',
    PaymentMethodNotSelected = 'PAYMENT_METHOD_NOT_SELECTED',
    NoFreeAddresses = 'NO_FREE_ADDRESSES',
    AddressAlreadyExists = 'ADDRESS_ALREADY_EXISTS',
    PayinDisabled = 'PAYIN_DISABLED',
    PayoutDisabled = 'PAYOUT_DISABLED',
    InsufficientBalance = 'INSUFFICIENT_BALANCE',
    WithdrawalNotCancellable = 'WITHDRAWAL_NOT_CANCELLABLE'
}


// @ts-ignore
export function APIErrorCodeFromJSON(json: any): APIErrorCode {
    return APIErrorCodeFromJSONTyped(json, false);
}

// @ts-ignore
export function APIErrorCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): APIErrorCode {
    return json as APIErrorCode;
}

// @ts-ignore
export function APIErrorCodeToJSON(value?: APIErrorCode | null): any {
    return value as any;
}

