import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg, CopyButton, InlineLinkButton, StyledQrCode } from '@merchant/shared/components'
import { useAssetSource } from '@merchant/shared/components/Assets/useAssetSrc'
import { getAddressTagName } from '@merchant/shared/utils'
import { Card, Flex, Popover, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage, useIntl } from 'react-intl'
import { lang } from './lang'
import { LoadingSkeleton } from './LoadingSkeleton'
import type { ProjectAddress } from '~api'
import { useCurrencies } from '~api'
import { globalLang } from '~globalLang'

const { Text } = Typography

interface Props {
    address: ProjectAddress | undefined
    isLoading: boolean
}

export function Address({ address: depositAddress, isLoading }: Props) {
    const intl = useIntl()
    const { data: currencies } = useCurrencies()
    const coinSrc = useAssetSource({ name: `${depositAddress?.currency}.svg`, themed: false, subPath: 'assets/coins' })

    if (isLoading || !depositAddress) {
        return <LoadingSkeleton />
    }

    const { address, addressTag = '', currency, network } = depositAddress
    const addressTagName = getAddressTagName(currencies, currency, network) ?? intl.formatMessage(globalLang.addressTag)

    return (
        <Flex vertical align="center">
            <Card
                styles={{ body: { padding: 14, borderRadius: 24 } }}
                style={{ marginTop: 8, marginBottom: 16 }}
                data-merchant="deposit-qr-code-card"
            >
                <StyledQrCode value={address} icon={coinSrc} />
            </Card>
            <Flex vertical align="center">
                <Text type="secondary" data-merchant={null}>
                    <FormattedMessage {...lang.addressLabel} />
                </Text>
                <Flex gap={4}>
                    <Text style={{ fontWeight: 500, fontSize: 16 }} data-merchant="deposit-address">
                        {address}
                    </Text>
                    <CopyButton
                        inputValue={address}
                        notificationContentSubject={globalLang.address}
                        data-merchant="deposit-address-copy-button"
                    />
                </Flex>
            </Flex>
            {addressTag && (
                <Flex vertical align="center">
                    <Text type="secondary" data-merchant="deposit-addressTagName">
                        {addressTagName}
                    </Text>
                    <Flex gap={4}>
                        <Text style={{ fontWeight: 500, fontSize: 16 }} data-merchant="deposit-addressTag">
                            {addressTag}
                        </Text>
                        <CopyButton
                            data-merchant="deposit-addressTag-copy-button"
                            inputValue={addressTag}
                            notificationContentSubject={addressTagName}
                        />
                        <Popover
                            trigger="click"
                            overlayInnerStyle={{ padding: '24px 36px' }}
                            placement="bottom"
                            data-merchant="deposit-addressTag-qr-code-popover"
                            content={
                                <Flex vertical align="center" gap={12}>
                                    <Text
                                        type="secondary"
                                        data-merchant="deposit-addressTag-qr-code-popover-addressTagName"
                                    >
                                        {addressTagName}
                                    </Text>
                                    <StyledQrCode value={addressTag} />
                                </Flex>
                            }
                        >
                            <InlineLinkButton
                                icon={<AppSvg name={assets.viewQrCode} />}
                                data-merchant="deposit-addressTag-qr-code-button"
                            />
                        </Popover>
                    </Flex>
                </Flex>
            )}
        </Flex>
    )
}
