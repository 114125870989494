import { CopyButton } from '@merchant/shared/components'
import { Button, Card, Checkbox, Col, Row, Space, Typography, theme } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { useBoolean } from 'usehooks-ts'
import { lang as commonLang } from '../../lang'
import { lang } from './lang'
import type { CreateTOTPResponse } from '~api/instances/cabinet-api'
import { ModalNames } from '~constants/modal'
import { useRegisterDirtyFormChecker } from '~hooks'

const { Text } = Typography
const { useToken } = theme

interface Props {
    backup: NonNullable<CreateTOTPResponse['backup']>
    onSuccess: () => void | Promise<void>
}

export function WriteDownBackupCode({ onSuccess, backup }: Props) {
    const {
        token: { colorBorder },
    } = useToken()
    const { value: checked, toggle: toggleChecked } = useBoolean(false)
    const { value: isLoading, setValue: setIsLoading } = useBoolean(false)
    useRegisterDirtyFormChecker(ModalNames.add2FaWriteDown)

    const handleButtonClick = async () => {
        setIsLoading(true)
        await onSuccess()
        setIsLoading(false)
    }

    return (
        <Space direction="vertical" size={28}>
            <Text data-merchant={null}>
                <FormattedMessage {...lang.writeDownBackupTitle} />
            </Text>
            <Card
                type="inner"
                size="small"
                bordered={false}
                style={{ borderRadius: 16, backgroundColor: colorBorder }}
                data-merchant="backup-code-card"
            >
                <Row wrap={false} align="middle" justify="space-between">
                    <Col>
                        <Space.Compact data-merchant="add2fa-backup-code" direction="vertical">
                            <Text data-merchant={null} type="secondary">
                                <FormattedMessage {...lang.backupCode} />
                            </Text>
                            {backup}
                        </Space.Compact>
                    </Col>
                    <Col>
                        <CopyButton
                            data-merchant="add2fa-backup-copy-code-button"
                            inputValue={backup}
                            notificationContentSubject={lang.backupCode}
                        />
                    </Col>
                </Row>
            </Card>
            <Checkbox
                checked={checked}
                onChange={toggleChecked}
                data-merchant="add2fa-backup-code-writing-down-confirmation-checkbox"
            >
                <FormattedMessage {...lang.haveWrittenBackup} />
            </Checkbox>
            <Button
                data-merchant="add2fa-backup-continue-button"
                block
                disabled={!checked}
                type="primary"
                onClick={handleButtonClick}
                size="large"
                loading={isLoading}
            >
                <FormattedMessage {...commonLang.continue} />
            </Button>
        </Space>
    )
}
