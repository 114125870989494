import { EmailSupportLink, NotificationMessage } from '@merchant/shared/components'
import { usePromise } from '@merchant/shared/hooks'
import { FormattedMessage } from 'react-intl'
import { steps, type Steps } from './types'
import type { useNotifications } from '@merchant/shared/contexts'
import type { SetState } from '@merchant/shared/types'
import type { SwapRequest, Project } from '~api/instances/cabinet-api'
import { projectApi } from '~api'
import { globalLang } from '~globalLang'

interface Props {
    projectId: Project['id']
    setStep: SetState<Steps>
    notification: ReturnType<typeof useNotifications>['notification']
}

export function useSwapCurrencies({ projectId, setStep, notification }: Props) {
    return usePromise((swapRequest: SwapRequest) => projectApi.projectSwap({ swapRequest, projectId }), {
        onSuccess: () => {
            setStep(steps.convertProgress)
        },
        showErrorNotification: false,
        onError: () => {
            notification.api.open({
                message: (
                    <NotificationMessage
                        type="error"
                        title={<FormattedMessage {...globalLang.somethingWentWrong} />}
                        description={
                            <FormattedMessage
                                {...globalLang.tryAgainLater}
                                values={{
                                    SupportLink: <EmailSupportLink />,
                                }}
                            />
                        }
                        dataMerchant="convert-error-notification"
                    />
                ),
            })
        },
    })
}
