import { getObjectKeys } from '@merchant/shared/utils'
import { FormattedMessage } from 'react-intl'
import { globalLang } from '~globalLang'

export const RELATIVE_CUSTOM_OPTION = 'custom'

export const relativeOptionsLabelMap = {
    '0.005': 0.005,
    '0.01': 0.01,
    '0.015': 0.015,
    '0.02': 0.02,
    '0.025': 0.025,
    [RELATIVE_CUSTOM_OPTION]: <FormattedMessage {...globalLang.custom} />,
} as const

export const relativeDefaultOptions = getObjectKeys(relativeOptionsLabelMap)
