import type { Badge, GetProp } from '@merchant/ui-kit/ant-design'
import { WithdrawalState } from '~api'

export const withdrawalStateTagMap: Record<WithdrawalState, GetProp<typeof Badge, 'status'>> = {
    [WithdrawalState.Success]: 'success',
    [WithdrawalState.Pending]: 'warning',
    [WithdrawalState.Failed]: 'error',
    [WithdrawalState.Canceled]: 'default',
}

export const showMoreButtonStyles: React.CSSProperties = { marginTop: 24 }
