import { AmountInput } from '@merchant/shared/components'
import { Form, Typography } from '@merchant/ui-kit/ant-design'
import { getRelativeLossRules } from './utils'
import type { FormItemProps } from '@merchant/ui-kit/ant-design'
import type { NumberFormatBaseProps } from 'react-number-format'
import compactStyles from '~styles/compact.style.module.css'
import { normalizeRelativeValueToDisplay, normalizeRelativeValueToStore } from '~utils/underpayment'

const { Text } = Typography

interface Props extends FormItemProps {
    isInputDisabled?: boolean
    isLossEnabled?: boolean
    usageContext: 'payments-settings' | 'payment-constructor'
}

export function CustomRelativeLossItem({
    isInputDisabled = false,
    isLossEnabled = false,
    usageContext,
    ...formItemProps
}: Props) {
    const isAllowed: NumberFormatBaseProps['isAllowed'] = ({ floatValue }) => {
        if (!floatValue) {
            return true
        }
        const decimalPart = floatValue % 1
        const integerPart = Math.floor(floatValue)

        return decimalPart < 100 && integerPart < 100
    }

    return (
        <Form.Item
            required
            getValueProps={value => ({
                value: normalizeRelativeValueToDisplay(value),
            })}
            getValueFromEvent={value => normalizeRelativeValueToStore(value)}
            rules={getRelativeLossRules(isLossEnabled)}
            style={{ marginTop: 4, marginBottom: 0 }}
            {...formItemProps}
        >
            <AmountInput
                data-merchant={`${usageContext}-underpayment-relative-input`}
                valueIsNumericString={false}
                precision={2}
                disabled={isInputDisabled}
                className={compactStyles.inputCompactBottomItem}
                suffix={
                    <Text data-merchant={null} type="secondary">
                        %
                    </Text>
                }
                isAllowed={isAllowed}
            />
        </Form.Item>
    )
}
