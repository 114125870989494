import { Tooltip, Typography } from '@merchant/ui-kit/ant-design'
import type { ButtonProps, TooltipProps } from '@merchant/ui-kit/ant-design'
import type { TextProps } from '@merchant/ui-kit/ant-design/es/typography/Text'
import type { ReactNode } from 'react'
import { CopyButton } from '~components'

const { Text } = Typography

const copyButtonStyle: React.CSSProperties = { verticalAlign: 'text-bottom', marginLeft: 4 }
const copyButtonStyles: ButtonProps['styles'] = { icon: { width: 16, height: 16 } }

interface Props extends Omit<TextProps, 'ellipsis'> {
    prefixCount?: number
    suffixCount?: number
    divider?: ReactNode
    dividerTextType?: TextProps['type']
    copyableText?: string
    showTooltip?: boolean
}

export function EllipsisMiddle({
    prefixCount = 6,
    suffixCount = 9,
    divider = '•',
    dividerTextType,
    copyableText,
    showTooltip = false,
    children,
    ...rest
}: Props): ReactNode {
    if (typeof children !== 'string') {
        return children
    }

    const getTextValue = () => {
        const start = children.slice(0, prefixCount).trim()
        const suffix = children.slice(children.length - suffixCount).trim()

        return (
            <span>
                {start}
                <Text type={dividerTextType || rest.type} data-merchant={`${rest['data-merchant']}-divider`}>
                    {Array(3).fill(divider)}
                </Text>
                {suffix}
            </span>
        )
    }

    let textValue: ReactNode = children
    const tooltipProps: TooltipProps = { 'data-merchant': 'ellipsis-middle-tooltip' }

    if (children.length > prefixCount + suffixCount) {
        textValue = getTextValue()
        if (showTooltip) {
            tooltipProps.placement = 'bottom'
            tooltipProps.title = (
                <>
                    {children}
                    {!!copyableText && (
                        <CopyButton
                            type="link"
                            style={copyButtonStyle}
                            styles={copyButtonStyles}
                            inputValue={copyableText}
                            data-merchant="ellipsis-middle-tooltip-copy-button"
                        />
                    )}
                </>
            )
        }
    }

    return (
        <Tooltip {...tooltipProps} overlayInnerStyle={{ fontSize: 14 }}>
            <Text {...rest}>
                {textValue}
                {!!copyableText && !showTooltip && (
                    <CopyButton
                        type="link"
                        style={copyButtonStyle}
                        styles={copyButtonStyles}
                        inputValue={copyableText}
                        data-merchant="ellipsis-middle-copy-button"
                    />
                )}
            </Text>
        </Tooltip>
    )
}
