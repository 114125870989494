import { useFormValidation, usePromise } from '@merchant/shared/hooks'
import { Button, Form } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { deleteFormItemsKey } from './const'
import { lang } from './lang'
import type { DeleteFormData } from '../../types'
import { ModalNames } from '~constants/modal'
import { TwoFaConfirmationItem } from '~features/FormItems'
import { useCodeConfirmationErrorHandling } from '~features/FormItems/useCodeErrorHandling'
import { globalLang } from '~globalLang'
import { useRegisterDirtyFormChecker } from '~hooks'

interface Props {
    request: (values: DeleteFormData) => Promise<void>
    onSuccess: () => void
}

const initialValues: DeleteFormData = {
    totpCode: '',
}

export function DeletionModalForm({ request, onSuccess }: Props) {
    const [form] = Form.useForm()
    const { handleError } = useCodeConfirmationErrorHandling({
        confirmationStrategy: { '2fa': 'code' },
    })
    const { onBlur, onFinishFailed } = useFormValidation(Object.values(deleteFormItemsKey))
    useRegisterDirtyFormChecker(ModalNames.callbackUrlDelete, form)
    const { send, isLoading } = usePromise(request, {
        showErrorNotification: false,
        onSuccess,
        onError: error => {
            handleError({
                form,
                error,
            })
        },
    })

    return (
        <Form
            form={form}
            onFinishFailed={onFinishFailed}
            size="large"
            onFinish={send}
            initialValues={initialValues}
            layout="vertical"
            requiredMark={false}
        >
            <TwoFaConfirmationItem
                hasFocusOnMount
                dataMerchant="delete-callback-2fa-input"
                onBlur={onBlur('totpCode')}
                postfixDescriptor={lang.permanentlyDeleteLabelPostfix}
            />
            <Form.Item noStyle>
                <Button
                    data-merchant="delete-callback-submit-button"
                    htmlType="submit"
                    type="primary"
                    block
                    loading={isLoading}
                >
                    <FormattedMessage {...globalLang.delete} />
                </Button>
            </Form.Item>
        </Form>
    )
}
