import { type MessageDescriptor } from 'react-intl'
import { type NavigateFunction } from 'react-router-dom'
import { lang } from './lang'
import { PlaceholderName } from './types'
import { routes as ApiRoutes, QueryParams } from '~constants/routes'
import { MerchantSettingsShowOptions } from '~features/MerchantPage/components/MerchantSettings/types'
import { generateQuery } from '~utils'

export const placeholderViewProps: Record<
    PlaceholderName,
    Required<{
        title?: MessageDescriptor
        subtitle?: MessageDescriptor
        text?: MessageDescriptor
        buttonText?: MessageDescriptor
        buttonClickHandler?: (navigate: NavigateFunction) => void
        buttonDataMerchant?: string
    }>
> = {
    [PlaceholderName.noMerchant]: {
        title: lang.noMerchantTitle,
        subtitle: lang.noMerchantSubtitle,
        text: lang.noMerchantText,
        buttonText: lang.noMerchantButton,
        buttonClickHandler: navigate => navigate(ApiRoutes.merchant),
        buttonDataMerchant: 'no-merchant-button',
    },
    [PlaceholderName.noCurrency]: {
        title: lang.noHoldingCurrenciesTitle,
        subtitle: lang.noHoldingCurrenciesSubtitle,
        text: lang.noHoldingCurrenciesText,
        buttonText: lang.noHoldingCurrenciesButton,
        buttonClickHandler: navigate =>
            navigate(
                `${ApiRoutes.merchantSettings}${generateQuery({ [QueryParams.show]: MerchantSettingsShowOptions.assets })}`
            ),
        buttonDataMerchant: 'no-merchant-button',
    },
    [PlaceholderName.no2fa]: {
        title: lang.setupTwoFaTitle,
        subtitle: lang.setupTwoFaSubtitle,
        text: lang.setupTwoFaText,
        buttonText: lang.setupTwoFaButton,
        buttonClickHandler: navigate => navigate(ApiRoutes.integrations),
        buttonDataMerchant: 'no-merchant-button',
    },
}
