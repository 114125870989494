import { EllipsisMiddle, CopyButton } from '@merchant/shared/components'
import { parseDateAndTime } from '@merchant/shared/utils'
import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import { getRow } from '../../utils'
import type { MessageDescriptor } from 'react-intl'
import { NoValueColumnContent } from '~components'

const { Text } = Typography

interface RowParams {
    label: MessageDescriptor
    dataMerchant: string
}

interface RowDateParams extends RowParams {
    value: Date
}

export const getDateRow = ({ label, value, dataMerchant }: RowDateParams) => {
    const { date, time } = parseDateAndTime(value) || {}

    return getRow(
        label,
        <>
            <Text data-merchant={`${dataMerchant}-date`}>{date}</Text>
            &nbsp;
            <Text type="secondary" data-merchant={`${dataMerchant}-time`}>
                {time}
            </Text>
        </>
    )
}

interface RowCopyableParams extends RowParams {
    value?: string
}

export const getCopyableRow = ({ label, value, dataMerchant }: RowCopyableParams) =>
    getRow(
        label,
        value ? (
            <Flex>
                <EllipsisMiddle prefixCount={6} suffixCount={10} divider="." data-merchant={dataMerchant}>
                    {value}
                </EllipsisMiddle>
                &nbsp;
                <CopyButton inputValue={value} type="text" data-merchant={`${dataMerchant}-copy-button`} />
            </Flex>
        ) : (
            NoValueColumnContent
        )
    )
