import { emailValidationRegex } from '@merchant/shared/constants'
import { useBreakpoint, useFormValidation, usePromise } from '@merchant/shared/hooks'
import { Button, Checkbox, Form, Input, Typography } from '@merchant/ui-kit/ant-design'
import { usePostHog } from 'posthog-js/react'
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { lang } from '../../lang'
import type { FormItemsKey } from '@merchant/shared/types'
import type { SignUpFormData, SignUpSuccess } from '~features/AuthorizationPage'
import { authApi } from '~api'
import { routes } from '~constants/routes'
import { PasswordWithTooltip } from '~features'
import { AuthHeader } from '~features/AuthorizationPage'
import { authCaptureEventParams } from '~features/AuthorizationPage/posthog'
import { PosthogAuthEvents } from '~features/AuthorizationPage/posthog/types'
import { getFormStyles } from '~features/AuthorizationPage/style'
import { globalLang } from '~globalLang'

const { Text } = Typography

const formDataKeys: FormItemsKey<SignUpFormData> = {
    email: 'email',
    password: 'password',
    policy: 'policy',
} as const

interface Props {
    onSignInClick: (email?: string) => void
    onSuccess: (data: SignUpSuccess) => void
    initialData: SignUpFormData
}

// eslint-disable-next-line max-lines-per-function
export function SignUp({ initialData, onSignInClick, onSuccess }: Props) {
    const posthog = usePostHog()
    const [form] = Form.useForm()
    const breakpoints = useBreakpoint()
    const { onBlur, onFinishFailed, validationMode } = useFormValidation(Object.values(formDataKeys))

    const { send: onSignUp, isLoading } = usePromise(
        async ({ email, password }: SignUpFormData) => {
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

            const response = await authApi.signup({
                signupRequest: {
                    email,
                    password,
                    currency: 'USD',
                    deviceToken: crypto.randomUUID(),
                    language: 'en_US',
                    timezone,
                },
            })

            return { ...response, email }
        },
        {
            onSuccess: data => {
                posthog.capture(...authCaptureEventParams[PosthogAuthEvents.createAccount]({ success: true }))
                onSuccess(data)
            },
            onError: () => {
                posthog.capture(...authCaptureEventParams[PosthogAuthEvents.createAccount]({ success: false }))
            },
        }
    )

    const handleLogInButtonClick = () => {
        const email = form.getFieldValue(formDataKeys.email)
        onSignInClick(email)
    }

    useEffect(() => {
        posthog.capture(...authCaptureEventParams[PosthogAuthEvents.onSignUpPage]())
    }, [posthog])

    return (
        <>
            <AuthHeader
                title={<FormattedMessage {...lang.signUpTitle} />}
                subtitle={<FormattedMessage {...lang.signUpSubtitle} />}
                buttonTitle={<FormattedMessage {...lang.logInButtonTitle} />}
                toggleMode={handleLogInButtonClick}
                buttonDataMerchant="signup-switch-mode-button"
                titleDataMerchant="signup-form-title"
                mode="signUp"
            />
            <Form
                style={getFormStyles(breakpoints)}
                form={form}
                size="large"
                layout="vertical"
                initialValues={initialData}
                onFinish={onSignUp}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    data-merchant="signup-email-form-item"
                    name={formDataKeys.email}
                    validateTrigger={validationMode.email}
                    rules={[
                        {
                            required: true,
                            message: <FormattedMessage {...globalLang.requiredFieldMessage} />,
                        },
                        {
                            pattern: emailValidationRegex,
                            message: <FormattedMessage {...globalLang.invalidEmailFormat} />,
                        },
                    ]}
                >
                    <Input
                        autoComplete="off"
                        data-merchant="signup-email-input"
                        placeholder="Email"
                        onBlur={onBlur(formDataKeys.email)}
                    />
                </Form.Item>
                <PasswordWithTooltip
                    form={form}
                    name={formDataKeys.password}
                    validateTrigger={validationMode.password}
                    onBlur={onBlur(formDataKeys.password)}
                    keepInvalidShown={!breakpoints.xs}
                    tooltipPlacement="bottom"
                    inputPlaceholder="Password"
                    data-merchant="signup-password-form-item"
                    inputDataMerchant="signup-password-input"
                />
                <Form.Item
                    style={{ height: 43 }}
                    name={formDataKeys.policy}
                    valuePropName="checked"
                    rules={[
                        {
                            type: 'enum',
                            enum: [true],
                            message: <FormattedMessage {...globalLang.requiredFieldMessage} />,
                        },
                    ]}
                >
                    <Checkbox data-merchant="signup-policy-checkbox">
                        <Text data-merchant="signup-policy-block" type="secondary">
                            <FormattedMessage
                                {...lang.termsAndPolicy}
                                values={{
                                    termsOfUse: (
                                        <Typography.Link
                                            href={routes.termsOfUse}
                                            target="_blank"
                                            data-merchant="signup-terms-of-use-link"
                                        >
                                            <FormattedMessage {...lang.termsOfUse} />
                                        </Typography.Link>
                                    ),
                                    privacyPolicy: (
                                        <Typography.Link
                                            href={routes.privacyPolicy}
                                            target="_blank"
                                            data-merchant="signup-privacy-policy-link"
                                        >
                                            <FormattedMessage {...lang.privacyPolicy} />
                                        </Typography.Link>
                                    ),
                                }}
                            />
                        </Text>
                    </Checkbox>
                </Form.Item>
                <Form.Item noStyle>
                    <Button
                        data-merchant="signup-submit-button"
                        htmlType="submit"
                        type="primary"
                        block
                        loading={isLoading}
                    >
                        <FormattedMessage {...lang.createAnAccount} />
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}
