import { emailValidationRegex } from '@merchant/shared/constants'
import { useBreakpoint, usePromise } from '@merchant/shared/hooks'
import { Button, Form, Input, Typography } from '@merchant/ui-kit/ant-design'
import { usePostHog } from 'posthog-js/react'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { lang } from '../../lang'
import type { Breakpoints } from '@merchant/shared/hooks'
import type { FormItemsKey } from '@merchant/shared/types'
import type { PasswordResetRequest } from '~api/instances/cabinet-api'
import { authApi } from '~api'
import { authCaptureEventParams, PosthogAuthEvents } from '~features/AuthorizationPage/posthog'
import { globalLang } from '~globalLang'

const { Title, Paragraph } = Typography

const titleStyles: React.CSSProperties = { display: 'inline-block' }
const getResetPasswordTextStyles = (breakpoints: Breakpoints): React.CSSProperties => ({
    marginTop: breakpoints.xs ? 16 : 24,
    marginBottom: 16,
})

type FormData = Pick<PasswordResetRequest, 'email'>

const formDataKeys: FormItemsKey<FormData> = {
    email: 'email',
} as const

interface Props {
    initData: FormData
    onSuccess: (email: string) => void
}

export function PasswordRecovery({ initData, onSuccess }: Props) {
    const posthog = usePostHog()
    const [form] = Form.useForm()
    const breakpoints = useBreakpoint()
    const intl = useIntl()
    const inputPlaceholder = intl.formatMessage(lang.emailPlaceholder)

    const { send: requestConfirmationCode, isLoading: isLoadingConfirmationCodeRequest } = usePromise(
        async ({ email }: FormData) => {
            posthog.capture(...authCaptureEventParams[PosthogAuthEvents.forgotPasswordRequest]())
            await authApi.resetPassword({ passwordResetRequest: { email } })
            onSuccess(email)
        }
    )

    return (
        <>
            <Title level={5} style={titleStyles} data-merchant="reset-password-title">
                <FormattedMessage {...lang.resetPassword} />
            </Title>
            <Paragraph style={getResetPasswordTextStyles(breakpoints)} data-merchant={null}>
                <FormattedMessage {...lang.resetPasswordText} />
            </Paragraph>
            <Form
                form={form}
                size="large"
                layout="vertical"
                initialValues={initData}
                onFinish={requestConfirmationCode}
            >
                <Form.Item
                    name={formDataKeys.email}
                    rules={[
                        {
                            required: true,
                            message: (
                                <span data-merchant="password-recovery-required-email-error-message">
                                    <FormattedMessage {...globalLang.requiredFieldMessage} />
                                </span>
                            ),
                        },
                        {
                            pattern: emailValidationRegex,
                            message: <FormattedMessage {...globalLang.invalidEmailFormat} />,
                        },
                    ]}
                >
                    <Input
                        autoComplete="off"
                        data-merchant="password-recovery-email-input"
                        placeholder={inputPlaceholder}
                    />
                </Form.Item>
                <Form.Item noStyle>
                    <Button
                        data-merchant="password-recovery-submit-button"
                        loading={isLoadingConfirmationCodeRequest}
                        htmlType="submit"
                        block
                        type="primary"
                    >
                        <FormattedMessage {...globalLang.confirm} />
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}
