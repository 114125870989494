import { Select } from '@merchant/ui-kit/ant-design'
import { useRef } from 'react'
import { useIntl } from 'react-intl'
import { useBoolean } from 'usehooks-ts'
import { lang } from './lang'
import type { RefSelectProps } from '@merchant/ui-kit/ant-design'
import type { BaseOptionType, DefaultOptionType } from '@merchant/ui-kit/ant-design/es/select'
import type { SelectProps } from '@merchant/ui-kit/ant-design/select'
import type { MessageDescriptor } from 'react-intl'

export interface SearchableSelectProps<
    ValueType = unknown,
    OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
> extends Omit<SelectProps<ValueType, OptionType>, 'showSearch'> {
    searchPlaceholder?: MessageDescriptor
}

export function SearchableSelect<
    ValueType = unknown,
    OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>({
    placeholder,
    onSelect,
    onFocus,
    onBlur,
    onDropdownVisibleChange,
    value,
    searchPlaceholder = lang.placeholder,
    ...props
}: SearchableSelectProps<ValueType, OptionType>) {
    const { value: isFocused, setTrue: focus, setFalse: defocus } = useBoolean(false)
    const ref = useRef<RefSelectProps>(null)
    const intl = useIntl()

    return (
        <Select<ValueType, OptionType>
            selectRef={ref}
            showSearch
            onFocus={(...args) => {
                focus()
                onFocus?.(...args)
            }}
            onBlur={(...args) => {
                defocus()
                onBlur?.(...args)
            }}
            onDropdownVisibleChange={open => {
                onDropdownVisibleChange?.(open)
                !open && ref.current?.blur()
            }}
            value={isFocused ? undefined : value}
            placeholder={isFocused ? intl.formatMessage(searchPlaceholder) : placeholder}
            onSelect={(...args) => {
                onSelect?.(...args)
                ref.current?.blur()
                defocus()
            }}
            {...props}
        />
    )
}
