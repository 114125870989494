import { InlineLinkButton } from '@merchant/shared/components'
import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang as commonLang } from './lang'
import type { UnavailableCurrencyModalRef } from '../../UnavailableCurrencyModal'
import type { Currency, Network } from '~api'

const { Text } = Typography

interface Props {
    currency: Currency | undefined
    network: Network | undefined
    openUnavailableCurrencyModal: UnavailableCurrencyModalRef['open'] | undefined
    closePopover: () => void
    dataMerchant: string
}

export function PayinDisabledContent({
    currency,
    network,
    openUnavailableCurrencyModal,
    closePopover,
    dataMerchant,
}: Props) {
    const networkOrCurrencyCode = network?.code ?? currency?.code

    return (
        <Flex vertical gap={8} style={{ padding: 8 }}>
            <Text
                style={{ fontSize: 12, fontWeight: 500 }}
                type="danger"
                data-merchant={`${dataMerchant}-unavailable-deposits-${currency?.code}`}
            >
                <FormattedMessage
                    {...commonLang.unavailableDeposits}
                    values={{ currency: currency?.code, network: networkOrCurrencyCode }}
                />
            </Text>
            {currency?.code && networkOrCurrencyCode && (
                <InlineLinkButton
                    style={{
                        fontSize: 12,
                        textWrap: 'wrap',
                        justifyContent: 'flex-start',
                        textAlign: 'left',
                        textDecoration: 'underline',
                    }}
                    onClick={() => {
                        openUnavailableCurrencyModal?.({
                            currency: currency.code,
                            network: networkOrCurrencyCode,
                        })
                        closePopover()
                    }}
                    data-merchant={`${dataMerchant}-unavailable-deposits-${currency.code}-open-modal-button`}
                >
                    <FormattedMessage
                        {...commonLang.unavailableDepositsPrompt}
                        values={{ currency: currency.code, network: networkOrCurrencyCode }}
                    />
                </InlineLinkButton>
            )}
        </Flex>
    )
}
