import { assets } from '@merchant/shared/assets/nameMap'
import {
    AppSvg,
    CompactCardsWrapper,
    CopyButton,
    ModalResponsive,
    PaymentDescription,
} from '@merchant/shared/components'
import { useBreakpoint } from '@merchant/shared/hooks'
import {
    Col,
    Collapse,
    ConfigProvider,
    Divider,
    Flex,
    Result,
    Row,
    Typography,
    theme,
} from '@merchant/ui-kit/ant-design'
import { isEmpty } from 'lodash-es'
import { FormattedMessage } from 'react-intl'
import { useBoolean } from 'usehooks-ts'
import { lang } from './lang'
import { PaymentDetails } from './PaymentDetails'
import type { ModalProps } from '@merchant/ui-kit/ant-design'
import { PaymentType, type Payment } from '~api/instances/cabinet-api'
import { globalLang } from '~globalLang'
import { normalizeRelativeValueToDisplay } from '~utils/underpayment'

const { Text, Title, Link } = Typography
const { useToken } = theme

interface Props extends Omit<ModalProps, 'data-merchant'> {
    createdPayment: Payment | undefined
}

// eslint-disable-next-line max-lines-per-function
export function PaymentCreatedModal({ createdPayment, ...props }: Props) {
    const { value: isCollapseOpen, setValue: setIsCollapseOpen } = useBoolean(false)
    const breakpoints = useBreakpoint()
    const {
        token: { colorSuccess },
    } = useToken()

    if (!createdPayment) {
        return null
    }

    const shouldShowLossConfigValue = createdPayment.type === PaymentType.Invoice && createdPayment.lossConfig.relative

    return (
        <ModalResponsive
            title={
                <Result
                    style={{ paddingBottom: 0 }}
                    icon={<AppSvg size={48} name={assets.checkCircle} color={colorSuccess} />}
                    title={
                        <Title level={4} data-merchant="order-created-modal-title">
                            <FormattedMessage {...lang.paymentCreatedModalTitle} />
                        </Title>
                    }
                />
            }
            styles={{
                body: { marginBlock: 24 },
                content: { padding: !breakpoints.md ? '24px 12px' : undefined },
                header: { marginBottom: 0 },
            }}
            open={!!createdPayment}
            footer={(_, { CancelBtn }) => <CancelBtn />}
            cancelButtonProps={{
                block: true,
                size: 'large',
                type: 'primary',
                'data-merchant': 'order-create-modal-close-button',
            }}
            cancelText={<FormattedMessage {...globalLang.close} />}
            data-merchant="order-created-modal"
            {...props}
        >
            <Divider style={{ marginBlock: '0 24px' }} />
            <PaymentDescription context="constructor" paymentData={createdPayment} style={{ padding: '0 12px 12px' }} />
            <CompactCardsWrapper
                gap={4}
                compactCardStyles={{ body: { padding: 16 } }}
                dataMerchantPrefix="order-created"
            >
                <Row justify="space-between" align="middle" gutter={12} wrap={false}>
                    <Col>
                        <Flex vertical>
                            <Text type="secondary" data-merchant={null}>
                                <FormattedMessage {...lang.link} />
                            </Text>
                            <Link
                                style={{ fontSize: 16 }}
                                href={createdPayment.paymentUrl}
                                target="_blank"
                                data-merchant="order-created-modal-order-url-link"
                            >
                                {createdPayment.paymentUrl}
                            </Link>
                        </Flex>
                    </Col>
                    <Col>
                        <CopyButton
                            notificationContentSubject={lang.link}
                            inputValue={createdPayment.paymentUrl}
                            data-merchant="order-created-modal-copy-url-button"
                        />
                    </Col>
                </Row>
                {shouldShowLossConfigValue && (
                    <Text data-merchant={null}>
                        <FormattedMessage
                            {...lang.underpaymentSetting}
                            values={{
                                b: chunks => <span style={{ fontWeight: 700 }}>{chunks}</span>,
                                relative: normalizeRelativeValueToDisplay(createdPayment.lossConfig.relative),
                                absoluteValue: createdPayment.lossConfig.absolute ? (
                                    <FormattedMessage
                                        {...lang.underpaymentSettingAbsolute}
                                        values={{
                                            amount: createdPayment.lossConfig.absolute.amount,
                                            currency: createdPayment.lossConfig.absolute.currency,
                                            b: chunks => <span style={{ fontWeight: 700 }}>{chunks}</span>,
                                        }}
                                    />
                                ) : (
                                    ''
                                ),
                            }}
                        />
                    </Text>
                )}
                <ConfigProvider
                    theme={{
                        components: {
                            Collapse: { headerPadding: 0, contentPadding: 0 },
                        },
                    }}
                >
                    <Collapse
                        ghost
                        onChange={keys => {
                            setIsCollapseOpen(!isEmpty(keys))
                        }}
                        items={[
                            {
                                label: (
                                    <Text
                                        type={!isCollapseOpen ? 'secondary' : undefined}
                                        data-merchant="order-created-modal-details-collapse-expander"
                                    >
                                        <FormattedMessage {...lang.orderDetails} />
                                    </Text>
                                ),
                                children: <PaymentDetails payment={createdPayment} />,
                            },
                        ]}
                        expandIconPosition="end"
                        data-merchant="order-created-modal-details-collapse"
                    />
                </ConfigProvider>
            </CompactCardsWrapper>
        </ModalResponsive>
    )
}
