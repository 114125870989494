import { useLocation } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts'
import { type LocaleCode, defaultLocale, supportedLocalesMap } from '~constants'
import { localeKey, routes } from '~constants'
import { isKeyOfObject, getBrowserLocale } from '~utils'

type UseLocale = (supportedLocales: string[] | undefined) => {
    locale: LocaleCode
    setLocale?: (locale: LocaleCode) => void
    isLocaleSwitchingAvailable: boolean
}

export const useLocale: UseLocale = (supportedLocales = []) => {
    const browserLocale = getBrowserLocale()
    const [storedLocale, setStoredLocale] = useLocalStorage(localeKey, browserLocale)
    const { pathname } = useLocation()

    const localeMatchedInPhrase = supportedLocales.find(l => browserLocale.includes(l))
    const browserLocaleMatchesDefault = browserLocale.includes(defaultLocale)

    // i18n is supported only for checkout page and only if users locale differs from default set by us('en')
    const isLocaleIgnored = !pathname.includes(routes.payment) || !localeMatchedInPhrase || browserLocaleMatchesDefault

    if (isLocaleIgnored) {
        return {
            locale: defaultLocale,
            isLocaleSwitchingAvailable: false,
        }
    }

    const browserLocaleNormalized = isKeyOfObject(localeMatchedInPhrase, supportedLocalesMap)
        ? localeMatchedInPhrase
        : defaultLocale

    const normalizedLocale = supportedLocales.includes(storedLocale)
        ? (storedLocale as LocaleCode)
        : browserLocaleNormalized

    return {
        locale: normalizedLocale,
        setLocale: setStoredLocale,
        isLocaleSwitchingAvailable: !isLocaleIgnored,
    }
}
