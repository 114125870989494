import type { Permission } from '~api'
import type { Currencies } from '~api/types'
import { fiatRelatedPermissions } from '~features/MerchantPage/components/TeamManagement/const'
import { getIfHasWithdrawableFiatCurrencies } from '~utils'

export const includePermissionPredicate = (currencies: Currencies | undefined) => (permission: Permission) => {
    const shouldShowFiatPermissions = getIfHasWithdrawableFiatCurrencies(currencies)

    return shouldShowFiatPermissions || !fiatRelatedPermissions.includes(permission)
}
