import { Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from '../../lang.ts'
import { AdvancedSettings, BasicSettings } from './components/index.ts'
import { OrderSettingsType } from './const.tsx'
import styles from './style.module.css'
import type { NewPaymentFormData } from './types.ts'
import type { CollapseProps, FormInstance, GlobalToken } from '@merchant/ui-kit/ant-design'
import type { ConstructorTypes } from '~features/PaymentConstructor/types.ts'
import { globalLang } from '~globalLang.ts'

const { Text } = Typography

const collapseItemStepStyle: React.CSSProperties = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid',
    color: '#0f0',
    borderRadius: '50%',
    width: 24,
    height: 24,
    marginRight: 12,
}

type GetCollapseItems = (params: {
    token: GlobalToken
    collapseActiveKey: OrderSettingsType[]
    type: ConstructorTypes
    form: FormInstance<NewPaymentFormData>
}) => CollapseProps['items']

export const getCollapseItems: GetCollapseItems = ({ token, collapseActiveKey, type, form }) => {
    return [
        {
            key: OrderSettingsType.BasicSettings,
            headerClass: styles.topHeaderClass,
            label: (
                <>
                    <Text
                        type="secondary"
                        style={{
                            ...collapseItemStepStyle,
                            color:
                                collapseActiveKey[0] === OrderSettingsType.BasicSettings
                                    ? token.colorPrimary
                                    : undefined,
                        }}
                        data-merchant="order-settings-basic"
                    >
                        {OrderSettingsType.BasicSettings}
                    </Text>
                    <Text style={{ fontSize: 16, fontWeight: 500 }} data-merchant="collapse-basic-settings-expander">
                        <FormattedMessage {...lang.basicSettings} />
                    </Text>
                </>
            ),
            children: <BasicSettings type={type} form={form} />,
        },
        {
            key: OrderSettingsType.AdvancedSettings,
            extra: (
                <Text type="secondary" data-merchant={null}>
                    <FormattedMessage {...globalLang.optional} />
                </Text>
            ),
            style: { borderRadius: 0 },
            label: (
                <>
                    <Text
                        type="secondary"
                        style={{
                            ...collapseItemStepStyle,
                            color:
                                collapseActiveKey[0] === OrderSettingsType.AdvancedSettings
                                    ? token.colorPrimary
                                    : undefined,
                        }}
                        data-merchant="order-settings-advanced"
                    >
                        {OrderSettingsType.AdvancedSettings}
                    </Text>
                    <Text style={{ fontSize: 16, fontWeight: 500 }} data-merchant="collapse-advanced-settings-expander">
                        <FormattedMessage {...lang.advancedSettings} />
                    </Text>
                </>
            ),
            children: <AdvancedSettings type={type} />,
        },
    ]
}
