import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import { useLocation, useNavigationType, matchRoutes, createRoutesFromChildren } from 'react-router-dom'
import { BUILD_OUT_DIR } from '../constants'
import type { SentryVitePluginOptions } from '@sentry/vite-plugin'

export function initSentry() {
    const isDevelopment = import.meta.env.MODE === 'development'
    const dsn = import.meta.env.VITE_SENTRY_DSN
    if (!dsn || isDevelopment) {
        return
    }
    Sentry.init({
        dsn,
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                matchRoutes,
                createRoutesFromChildren,
            }),
        ],
        tracesSampleRate: 0.5,
    })
}

type PackageName = 'cabinet' | 'checkout'

const sentryProjectMap: Record<PackageName, string> = {
    cabinet: 'ms-cabinet',
    checkout: 'ms-checkout',
} as const

export const getSentryVitePluginConfig = (packageName: PackageName, brokerName: string): SentryVitePluginOptions => ({
    release: {
        name: `${packageName}_v${process.env.npm_package_version}_${brokerName}`,
        uploadLegacySourcemaps: [`./${BUILD_OUT_DIR}`],
    },
    project: sentryProjectMap[packageName],
    /* Specify your Sentry
        - organization,
        - authToken,
        - url
        in .env file */

    // authToken: process.env.SENTRY_AUTH_TOKEN,
    // org: '',
    // url: '',
})
