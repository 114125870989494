/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FeesPayer } from './FeesPayer';
import {
    FeesPayerFromJSON,
    FeesPayerFromJSONTyped,
    FeesPayerToJSON,
} from './FeesPayer';
import type { PaymentSwapConfig } from './PaymentSwapConfig';
import {
    PaymentSwapConfigFromJSON,
    PaymentSwapConfigFromJSONTyped,
    PaymentSwapConfigToJSON,
} from './PaymentSwapConfig';

/**
 * 
 * @export
 * @interface CreateCustomerAddressRequest
 */
export interface CreateCustomerAddressRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerAddressRequest
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerAddressRequest
     */
    network?: string;
    /**
     * 
     * @type {PaymentSwapConfig}
     * @memberof CreateCustomerAddressRequest
     */
    swap?: PaymentSwapConfig;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerAddressRequest
     */
    holdingCurrency?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerAddressRequest
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerAddressRequest
     */
    customerEmail?: string;
    /**
     * Freehand metadata associated with the static address
     * @type {any}
     * @memberof CreateCustomerAddressRequest
     */
    paymentData?: any | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerAddressRequest
     */
    nominalCurrency?: string;
    /**
     * 
     * @type {FeesPayer}
     * @memberof CreateCustomerAddressRequest
     */
    feesPayer?: FeesPayer;
}

/**
 * Check if a given object implements the CreateCustomerAddressRequest interface.
 */
// @ts-ignore
export function instanceOfCreateCustomerAddressRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "customerId" in value;

    return isInstance;
}

// @ts-ignore
export function CreateCustomerAddressRequestFromJSON(json: any): CreateCustomerAddressRequest {
    return CreateCustomerAddressRequestFromJSONTyped(json, false);
}

// @ts-ignore
export function CreateCustomerAddressRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCustomerAddressRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currency': json['currency'],
        'network': !exists(json, 'network') ? undefined : json['network'],
        'swap': !exists(json, 'swap') ? undefined : PaymentSwapConfigFromJSON(json['swap']),
        'holdingCurrency': !exists(json, 'holding_currency') ? undefined : json['holding_currency'],
        'customerId': json['customer_id'],
        'customerEmail': !exists(json, 'customer_email') ? undefined : json['customer_email'],
        'paymentData': !exists(json, 'payment_data') ? undefined : json['payment_data'],
        'nominalCurrency': !exists(json, 'nominal_currency') ? undefined : json['nominal_currency'],
        'feesPayer': !exists(json, 'fees_payer') ? undefined : FeesPayerFromJSON(json['fees_payer']),
    };
}

// @ts-ignore
export function CreateCustomerAddressRequestToJSON(value?: CreateCustomerAddressRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currency': value.currency,
        'network': value.network,
        'swap': PaymentSwapConfigToJSON(value.swap),
        'holding_currency': value.holdingCurrency,
        'customer_id': value.customerId,
        'customer_email': value.customerEmail,
        'payment_data': value.paymentData,
        'nominal_currency': value.nominalCurrency,
        'fees_payer': FeesPayerToJSON(value.feesPayer),
    };
}

