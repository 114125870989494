import { ModalResponsive } from '@merchant/shared/components'
import { Flex, Space, Typography } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import type { ModalProps } from '@merchant/ui-kit/ant-design'

const { Text, Title } = Typography

const modalBodyStyles: React.CSSProperties = {
    textAlign: 'center',
    marginTop: 18,
}
const modalButtonStyles: React.CSSProperties = { marginTop: 24 }

export function CurrenciesSetModal(props: Omit<ModalProps, 'data-merchant'>) {
    return (
        <ModalResponsive
            centered
            styles={{ body: modalBodyStyles }}
            okButtonProps={{
                type: 'primary',
                style: modalButtonStyles,
                block: true,
                size: 'large',
                'data-merchant': 'currency-settings-congratulation-modal-button',
            }}
            okText={<FormattedMessage {...lang.congratulationModalButton} />}
            footer={(_, { OkBtn }) => <OkBtn />}
            data-merchant="currency-settings-congratulation-modal"
            {...props}
        >
            <Space direction="vertical" size={12}>
                <Title level={1} data-merchant={null}>
                    <FormattedMessage {...lang.congratulationModalContentTitle} />
                </Title>
                <Flex gap={8} vertical>
                    <Title level={4} data-merchant={null}>
                        <FormattedMessage {...lang.congratulationModalContentSubtitle} />
                    </Title>
                    <Text type="secondary" data-merchant={null}>
                        <FormattedMessage {...lang.congratulationModalContentText} />
                    </Text>
                </Flex>
            </Space>
        </ModalResponsive>
    )
}
