import { formatStringToParagraphs } from '@merchant/shared/utils'
import { Divider, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang as commonLang } from './../../lang'
import { getFiatWithdrawalDetails } from './utils'
import { WithdrawalFiatInformationHeader } from './WithdrawalFiatInformationHeader'
import type { ContextDetails } from './types'
import type { WithdrawalFiatFormData } from '../../types'

const { Paragraph } = Typography

interface Props {
    withdrawalFormData: WithdrawalFiatFormData
    contextDetails: ContextDetails
}

export function WithdrawalFiatInformation({ withdrawalFormData, contextDetails }: Props) {
    return (
        <>
            <WithdrawalFiatInformationHeader contextDetails={contextDetails} withdrawalFormData={withdrawalFormData} />
            <Paragraph data-merchant={null} type="secondary" style={{ marginBlock: '0 4px' }}>
                <FormattedMessage {...commonLang.bankAccountDetails} />
            </Paragraph>
            <Paragraph data-merchant={null}>
                {formatStringToParagraphs(withdrawalFormData.requisites, 'withdrawal-fiat-requisites')}
            </Paragraph>
            <Divider style={{ marginBlock: 16 }} />
            {getFiatWithdrawalDetails(withdrawalFormData)}
        </>
    )
}
