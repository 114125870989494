import { Divider, Flex, Typography } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { getWhitelistDetails } from './utils'
import type { WhitelistWithKey } from '~api/swr/rest/types'
import { useNetworks } from '~hooks'

const { Text } = Typography

interface Props {
    data: WhitelistWithKey
}

export function WhitelistedAddressSelectOption({ data }: Props) {
    const { networks } = useNetworks()
    const { name } = data

    return (
        <>
            <Flex vertical gap={4}>
                <Text data-merchant={null} strong>
                    {name}
                </Text>
                <Flex gap={4} vertical>
                    {getWhitelistDetails(data, networks, {
                        showAddressTooltip: false,
                    })}
                </Flex>
            </Flex>
            <Divider style={{ marginBlock: '8px 0' }} />
        </>
    )
}
