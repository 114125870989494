import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg, StatisticFormattedNumber } from '@merchant/shared/components'
import { useBreakpoint, usePromise } from '@merchant/shared/hooks'
import { parseDateAndTime } from '@merchant/shared/utils'
import { Badge, Button, ConfigProvider, Flex, Spin, Table, Typography } from '@merchant/ui-kit/ant-design'
import { capitalize, filter, isEmpty, omit, pick, startsWith } from 'lodash-es'
import React, { useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { TransactionId, TransactionNotes, TransactionStatus } from './components'
import { TransactionDetailsModal } from './components/TransactionDetailsModal'
import { lang } from './lang'
import styles from './style.module.css'
import { transactionStatusTagMap } from './types'
import {
    getFiltersAsParams,
    getIfPositiveBalanceChange,
    isCancellableTransaction,
    isTransactionWithdrawalDetails,
} from './utils'
import type { TableProps, ThemeConfig } from '@merchant/ui-kit/ant-design'
import type { ColumnsType, ColumnType } from '@merchant/ui-kit/ant-design/es/table'
import type {
    CancelProjectWithdrawalRequest,
    ListProjectTransactionsRequest,
    ListTransactions200Response,
    Transaction,
} from '~api/instances/cabinet-api'
import { projectApi, useProject } from '~api'
import { Permission, TransactionsSorting, TransactionStatus as TransactionStatusEnum } from '~api/instances/cabinet-api'
import { apiRestKeys } from '~api/swr/keys'
import { useInfiniteFetcher } from '~api/swr/useInfiniteFetcher'
import { EmptyTable, NoValueColumnContent } from '~components'
import { routes as ApiRoutes } from '~constants/routes'
import { useUserPermissionContext } from '~contexts'
import {
    TransactionTypeMapped,
    type TransactionFilterQueryData,
    type TransactionsFilterFormData,
} from '~features/TransactionsPage/views/Transactions/types'

const { Text, Paragraph } = Typography

const tableConfigProviderTheme: ThemeConfig = {
    token: { fontSize: 12 },
    components: { Statistic: { contentFontSize: 12 } },
}

const tableStyles: React.CSSProperties = { marginTop: 12 }
const showMoreStyles: React.CSSProperties = { marginTop: 24 }
const paragraphStyles: React.CSSProperties = { marginBottom: 0 }

enum ColumnKeys {
    statusBadge = 'statusBadge',
    date = 'date',
    updatedAt = 'updatedAt',
    status = 'status',
    id = 'id',
    amount = 'amount',
    amountWithDate = 'amountWithDate',
    remainderBalance = 'remainderBalance',
    notes = 'notes',
    infoButton = 'infoButton',
    updatedDateAndOrderType = 'updatedDateAndOrderType',
}

type GetColumns = (params: {
    onWithdrawalCancel: (params: Omit<CancelProjectWithdrawalRequest, 'projectId'>) => Promise<void>
    onOrderIdClick: (id: string) => void
    isMobile: boolean
    onExpandClick: (id: number) => void
    sortColumnKey: TransactionsSorting
    canSeeBalances: boolean
}) => ColumnsType<Transaction>

// eslint-disable-next-line max-lines-per-function
const getColumns: GetColumns = ({
    onWithdrawalCancel,
    onOrderIdClick,
    isMobile: mobile,
    onExpandClick,
    sortColumnKey,
    canSeeBalances,
}) => {
    const columns: Record<ColumnKeys, ColumnType<Transaction>> = {
        statusBadge: {
            dataIndex: 'status',
            key: 'statusBadge',
            render: (status: Transaction['status']) => {
                return (
                    <Badge
                        className={styles.badge}
                        dot
                        size="small"
                        style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        status={transactionStatusTagMap[status]}
                        data-merchant="transactions-table-status-badge"
                    />
                )
            },
            width: 23,
        },
        date: {
            title: <FormattedMessage {...lang.created} />,
            dataIndex: 'date',
            key: TransactionsSorting.Date,
            render: (value: Transaction['date'], { id }) => {
                const parsedDate = parseDateAndTime(value)
                if (!parsedDate) {
                    return NoValueColumnContent
                }

                return (
                    <>
                        <Paragraph style={paragraphStyles} data-merchant={`transactions-table-${id}-updated-date`}>
                            {parsedDate.date}
                        </Paragraph>
                        {!!parsedDate.time && (
                            <Text type="secondary" data-merchant={`transactions-table-${id}-updated-time`}>
                                {parsedDate.time}
                            </Text>
                        )}
                    </>
                )
            },
            width: 80,
            sorter: true,
            sortDirections: ['descend'],
            sortOrder: sortColumnKey === TransactionsSorting.Date ? 'descend' : undefined,
        },
        updatedAt: {
            title: <FormattedMessage {...lang.updated} />,
            dataIndex: 'updatedAt',
            key: TransactionsSorting.UpdatedAt,
            render: (value: Transaction['updatedAt'], { id }) => {
                const parsedDate = parseDateAndTime(value)
                if (!parsedDate) {
                    return NoValueColumnContent
                }

                return (
                    <>
                        <Paragraph style={paragraphStyles} data-merchant={`transactions-table-${id}-updated-date`}>
                            {parsedDate.date}
                        </Paragraph>
                        {!!parsedDate.time && (
                            <Text type="secondary" data-merchant={`transactions-table-${id}-updated-time`}>
                                {parsedDate.time}
                            </Text>
                        )}
                    </>
                )
            },
            width: 80,
            sorter: true,
            sortDirections: ['descend'],
            sortOrder: sortColumnKey === TransactionsSorting.UpdatedAt ? 'descend' : undefined,
        },
        updatedDateAndOrderType: {
            render: (_, { updatedAt, type, id }) => {
                const parsedDate = parseDateAndTime(updatedAt)

                return (
                    <Flex vertical>
                        <Text data-merchant={`transactions-table-${id}-type`}>
                            {capitalize(TransactionTypeMapped[type])}
                        </Text>
                        {!parsedDate ? (
                            NoValueColumnContent
                        ) : (
                            <Text type="secondary" data-merchant={`transactions-table-${id}-updated`}>
                                {parsedDate.date} {!!parsedDate.time && parsedDate.time}
                            </Text>
                        )}
                    </Flex>
                )
            },
        },
        status: {
            title: <FormattedMessage {...lang.status} />,
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => {
                const isCancelableTransaction = isCancellableTransaction(record)

                return (
                    <TransactionStatus
                        status={record.status}
                        type={record.type}
                        onCancelClick={
                            isCancelableTransaction
                                ? () => onWithdrawalCancel({ id: record.details.withdrawalId })
                                : () => Promise.reject(new Error('Cannot cancel transaction'))
                        }
                        isCancelable={isCancelableTransaction}
                    />
                )
            },
            width: 110,
        },
        id: {
            title: <FormattedMessage {...lang.id} />,
            key: 'id',
            render: (_, { details, txId, currency, network }) => {
                const hash = isTransactionWithdrawalDetails(details) ? details.hash : undefined

                return <TransactionId txId={txId} hash={hash} currency={currency} network={network} />
            },
            width: 120,
        },
        amount: {
            title: <FormattedMessage {...lang.totalAmount} />,
            key: 'amount',
            dataIndex: 'amount',
            render: (_, { type, amount, currency, id }) => (
                <Text
                    type={getIfPositiveBalanceChange(type) ? 'success' : 'danger'}
                    data-merchant={`transactions-table-${id}-amount`}
                >
                    <StatisticFormattedNumber
                        valueStyle={{ fontSize: 12 }}
                        value={amount}
                        valueRender={value => (
                            <>
                                {getIfPositiveBalanceChange(type) ? '+' : '-'}
                                {value}
                            </>
                        )}
                        precision={2}
                        suffix={currency}
                    />
                </Text>
            ),
            width: 130,
        },
        amountWithDate: {
            render: (_, { date, type, amount, currency, id }) => {
                const parsedDate = parseDateAndTime(date)

                return (
                    <Flex vertical>
                        <Text
                            type={getIfPositiveBalanceChange(type) ? 'success' : 'danger'}
                            data-merchant={`transactions-table-${id}-amount`}
                        >
                            <StatisticFormattedNumber
                                valueStyle={{ fontSize: 12 }}
                                value={amount}
                                valueRender={value => (
                                    <>
                                        {getIfPositiveBalanceChange(type) ? '+' : '-'}
                                        {value}
                                    </>
                                )}
                                precision={2}
                                suffix={currency}
                            />
                        </Text>
                        {!parsedDate ? (
                            NoValueColumnContent
                        ) : (
                            <Text type="secondary" data-merchant={`transactions-table-${id}-date`}>
                                {parsedDate.date} {!!parsedDate.time && parsedDate.time}
                            </Text>
                        )}
                    </Flex>
                )
            },
            align: 'end',
        },
        remainderBalance: {
            title: <FormattedMessage {...lang.remainderBalance} />,
            key: 'balance,currency',
            dataIndex: 'balance',
            render: (balance: Transaction['balance'], { currency }) => (
                <StatisticFormattedNumber value={balance} suffix={currency} hidden={!canSeeBalances} />
            ),
            width: 130,
        },
        notes: {
            title: <FormattedMessage {...lang.notes} />,
            key: 'notes',
            dataIndex: 'notes',
            render: (_, record) => <TransactionNotes data={record} onOrderIdClick={onOrderIdClick} />,
            width: 150,
        },
        infoButton: {
            key: 'info-button',
            render: (_, record) => {
                return (
                    <Button
                        onClick={() => onExpandClick(record.id)}
                        style={{
                            padding: 8,
                            display: 'grid',
                            height: 'auto',
                            borderRadius: '50%',
                        }}
                        data-merchant={`transactions-table-${record.id}-info-button`}
                    >
                        <AppSvg size={20} name={assets.infoCircle} />
                    </Button>
                )
            },
            align: 'end',
            width: 50,
        },
    }

    const mobileColumns = pick(columns, [
        ColumnKeys.statusBadge,
        ColumnKeys.updatedDateAndOrderType,
        ColumnKeys.amountWithDate,
        ColumnKeys.infoButton,
    ])

    const desktopColumns = omit(columns, [
        ColumnKeys.statusBadge,
        ColumnKeys.amountWithDate,
        ColumnKeys.updatedDateAndOrderType,
        ColumnKeys.infoButton,
    ])

    return mobile ? Object.values(mobileColumns) : Object.values(desktopColumns)
}

interface Props {
    context?: 'transactions' | 'balances'
    searchId?: Transaction['txId']
    filters?: Partial<TransactionsFilterFormData> | Partial<TransactionFilterQueryData>
    renderFilters?: (isLoadingTransactions: boolean) => React.ReactNode
}

// eslint-disable-next-line max-lines-per-function, complexity
export function TransactionsTable({ searchId, renderFilters, filters, context = 'transactions' }: Props) {
    const { data: { id: projectId = '' } = {} } = useProject()
    const { checkUserPermission } = useUserPermissionContext()
    const [expandedTxId, setExpandedTxId] = useState<number | null>(null)
    const [sortColumnKey, setSortColumnKey] = useState<TransactionsSorting>(TransactionsSorting.UpdatedAt)
    const breakpoints = useBreakpoint()
    const isMobile = !breakpoints.md
    const isBalancesContext = context === 'balances'

    const {
        data: transactions,
        isLoading,
        canFetchMore,
        error,
        fetchMore,
        isValidating,
        mutate,
        isLoadingMore,
        isPageDataEmpty,
    } = useInfiniteFetcher<ListTransactions200Response, Transaction[], ListProjectTransactionsRequest>(
        a => {
            const result = {
                pageKey: a.pageKey,
                params: {
                    limit: a.limit,
                    cursor: a.previousPageData?.cursorNext,
                    sort: sortColumnKey,
                    ...getFiltersAsParams(filters, projectId),
                },
            }

            return result
        },
        projectApi.listProjectTransactions.bind(projectApi),
        {
            limit: isBalancesContext ? 5 : 10,
            dataPath: 'transactions',
            revalidateIfStale: true,
            keepPreviousData: true,
            pageKey: apiRestKeys.getTransactions,
        }
    )

    if (error) {
        // TODO: show appropriate error message
    }

    const navigate = useNavigate()

    const transactionsProcessed = useMemo(() => {
        if (transactions) {
            const txsFiltered = filter(transactions, tx => startsWith(tx.txId, searchId ?? ''))

            return txsFiltered
        }

        return []
    }, [transactions, searchId])

    const onOrderIdClick = (id: string) => {
        navigate(`${ApiRoutes.orders}/${id}`)
    }

    const { send: cancelWithdrawal } = usePromise(async (params: Omit<CancelProjectWithdrawalRequest, 'projectId'>) => {
        await projectApi.cancelProjectWithdrawal({ ...params, projectId })
        mutate(
            transactions =>
                transactions?.map(page => ({
                    ...page,
                    transactions: page.transactions.map(item =>
                        isTransactionWithdrawalDetails(item.details) && item.details.withdrawalId === params.id
                            ? { ...item, status: TransactionStatusEnum.Failed }
                            : item
                    ),
                })),
            { revalidate: false }
        )
    })

    const onExpandTransactionClick = (id: number) => {
        setExpandedTxId(id)
    }
    const onTableChange: TableProps<Transaction>['onChange'] = (_, __, sorter) => {
        if (!Array.isArray(sorter)) {
            if (
                typeof sorter.columnKey === 'string' &&
                sorter.order &&
                Object.values<string>(TransactionsSorting).includes(sorter.columnKey)
            ) {
                setSortColumnKey(sorter.columnKey as TransactionsSorting)
            }
        }
    }

    const expandedTransaction = useMemo(() => {
        if (!expandedTxId) {
            return
        }

        return transactions?.find(tx => tx.id === expandedTxId)
    }, [expandedTxId, transactions])

    const shouldRenderShowMoreButton = context === 'transactions' && !isEmpty(transactions) && canFetchMore
    const canSeeBalances = checkUserPermission(Permission.SeeBalance)

    const isFiltersEmpty = isEmpty(filters)
    const isDataEmpty = isFiltersEmpty && isPageDataEmpty
    // filters are shown when either there are transactions or there are set filters (meaning user tried to filter but got empty array)
    const areFiltersShown = !isDataEmpty || !isFiltersEmpty

    return (
        <>
            {!breakpoints.md && (
                <TransactionDetailsModal
                    data={expandedTransaction}
                    cancelWithdrawal={cancelWithdrawal}
                    onCancel={() => setExpandedTxId(null)}
                    onOrderIdClick={onOrderIdClick}
                    data-merchant="transaction-details-modal"
                />
            )}
            {areFiltersShown && renderFilters?.(isLoading)}
            <ConfigProvider theme={tableConfigProviderTheme}>
                <Table<Transaction>
                    locale={{
                        emptyText: <EmptyTable subtitleDescriptor={lang.noTransactionsSubtitle} />,
                    }}
                    data-merchant="transactions-table"
                    className={styles.table}
                    style={tableStyles}
                    loading={{
                        'data-merchant': 'transactions-table-loading',
                        spinning: isLoading || isValidating,
                        indicator: <Spin data-merchant="transactions-table-spin" />,
                    }}
                    onChange={onTableChange}
                    columns={getColumns({
                        onWithdrawalCancel: cancelWithdrawal,
                        onOrderIdClick,
                        isMobile,
                        onExpandClick: onExpandTransactionClick,
                        sortColumnKey,
                        canSeeBalances,
                    })}
                    showSorterTooltip={false}
                    dataSource={transactionsProcessed}
                    tableLayout="fixed"
                    rowKey={({ id }) => id}
                    pagination={false}
                    showHeader={(!isMobile && !isDataEmpty) || isLoading}
                    scroll={{
                        y: isBalancesContext ? 'max-content' : 740,
                        x: isMobile ? 'max-content' : 900,
                    }}
                />
                {shouldRenderShowMoreButton && (
                    <Button
                        data-merchant="transactions-table-show-more-button"
                        size="large"
                        block
                        onClick={fetchMore}
                        loading={isLoadingMore}
                        style={showMoreStyles}
                    >
                        <FormattedMessage {...lang.showMore} />
                    </Button>
                )}
            </ConfigProvider>
        </>
    )
}
