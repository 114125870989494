import { lang } from './lang'
import { ModalNames } from '~constants/modal'

export const steps = {
    [ModalNames.whitelistEnableAcknowledgement]: ModalNames.whitelistEnableAcknowledgement,
    [ModalNames.whitelistEnableConfirmation]: ModalNames.whitelistEnableConfirmation,
} as const

export type StepsValue = (typeof steps)[keyof typeof steps]

export const modalTitleStepMap = {
    [steps.whitelistEnableAcknowledgement]: lang.enableFormModalTitle,
    [steps.whitelistEnableConfirmation]: lang.confirmationModalTitle,
}
