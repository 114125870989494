/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentSubtype } from './PaymentSubtype';
import {
    PaymentSubtypeFromJSON,
    PaymentSubtypeFromJSONTyped,
    PaymentSubtypeToJSON,
} from './PaymentSubtype';
import type { PaymentType } from './PaymentType';
import {
    PaymentTypeFromJSON,
    PaymentTypeFromJSONTyped,
    PaymentTypeToJSON,
} from './PaymentType';

/**
 * 
 * @export
 * @interface TransactionPaymentDetails
 */
export interface TransactionPaymentDetails {
    /**
     * 
     * @type {string}
     * @memberof TransactionPaymentDetails
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionPaymentDetails
     */
    paymentId: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionPaymentDetails
     */
    origin: string;
    /**
     * 
     * @type {PaymentType}
     * @memberof TransactionPaymentDetails
     */
    type: PaymentType;
    /**
     * 
     * @type {PaymentSubtype}
     * @memberof TransactionPaymentDetails
     */
    subtype?: PaymentSubtype;
}

/**
 * Check if a given object implements the TransactionPaymentDetails interface.
 */
// @ts-ignore
export function instanceOfTransactionPaymentDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "paymentId" in value;
    isInstance = isInstance && "origin" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

// @ts-ignore
export function TransactionPaymentDetailsFromJSON(json: any): TransactionPaymentDetails {
    return TransactionPaymentDetailsFromJSONTyped(json, false);
}

// @ts-ignore
export function TransactionPaymentDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionPaymentDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectId': json['project_id'],
        'paymentId': json['payment_id'],
        'origin': json['origin'],
        'type': PaymentTypeFromJSON(json['type']),
        'subtype': !exists(json, 'subtype') ? undefined : PaymentSubtypeFromJSON(json['subtype']),
    };
}

// @ts-ignore
export function TransactionPaymentDetailsToJSON(value?: TransactionPaymentDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'project_id': value.projectId,
        'payment_id': value.paymentId,
        'origin': value.origin,
        'type': PaymentTypeToJSON(value.type),
        'subtype': PaymentSubtypeToJSON(value.subtype),
    };
}

