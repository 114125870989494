import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    loadingError: {
        id: 'placeholder-view.typography.loading_error',
        defaultMessage: 'An error occurred. Please try again later',
    },
    noHoldingCurrenciesTitle: {
        id: 'placeholder-view.typography.no_holding_currencies_title',
        defaultMessage: '💰',
    },
    noHoldingCurrenciesSubtitle: {
        id: 'placeholder-view.typography.no_holding_currencies_subtitle',
        defaultMessage: 'Finish setting up',
    },
    noHoldingCurrenciesText: {
        id: 'placeholder-view.typography.no_holding_currencies_text',
        defaultMessage: 'To start creating orders set up your currencies',
    },
    noHoldingCurrenciesButton: {
        id: 'placeholder-view.typography.no_holding_currencies_button',
        defaultMessage: 'Set up currencies settings',
    },
})
