import { useCommonAppSettings } from '@merchant/shared/api'
import { Button, Popconfirm, Space } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { useBoolean } from 'usehooks-ts'
import { lang } from './lang'
import { ModalFooterSaveButton } from './ModalFooterSaveButton'
import type { CurrencyExtended } from './../types'
import type { SetState } from '@merchant/shared/types'
import { useCurrencies } from '~api'
import { DEFAULT_CONVERSION_CURRENCY_FALLBACK } from '~config/const'

interface Props {
    selectedCurrencies: CurrencyExtended[]
    selectedUncommittedCurrencies: CurrencyExtended[]
    setSelectedCurrencies: SetState<CurrencyExtended[]>
    onSaveConfirm: () => Promise<void>
    closeModal: () => void
    onRestoreDefault: () => void
    currencies: CurrencyExtended[]
}

export function ModalFooter({
    selectedCurrencies,
    setSelectedCurrencies,
    selectedUncommittedCurrencies,
    onSaveConfirm,
    closeModal,
    onRestoreDefault,
    currencies: currencyOptions,
}: Props) {
    const { value: isSaving, setValue: setIsSaving } = useBoolean(false)
    const { data } = useCommonAppSettings()
    const { data: currencies } = useCurrencies()
    const { defaultConversionCurrency: cmsSetDefaultCurrency } = data || {}
    const defaultConversionCurrencyCode = cmsSetDefaultCurrency ?? DEFAULT_CONVERSION_CURRENCY_FALLBACK

    const handleSaveCurrenciesClick = async () => {
        setIsSaving(true)
        await onSaveConfirm()
        setIsSaving(false)
        closeModal()
    }

    const handleResetButtonClick = async () => {
        onRestoreDefault()
    }

    const currenciesChanges = () => {
        const removedItems = selectedCurrencies.filter(item => {
            return !selectedUncommittedCurrencies.includes(item) && currencies?.[item.code]?.crypto
        })
        const addedItems = selectedUncommittedCurrencies.filter(item => !selectedCurrencies.includes(item))

        return { removedItems, addedItems }
    }

    const { addedItems, removedItems } = currenciesChanges()

    return (
        <Space direction="vertical" style={{ width: '100%' }} styles={{ item: { flexBasis: '50%' } }} size={12}>
            <Popconfirm
                icon={null}
                overlayInnerStyle={{ maxWidth: 300 }}
                placement="bottom"
                title={undefined}
                description={
                    <FormattedMessage
                        {...lang.resetDisclaimer}
                        values={{
                            defaultResetCurrency: defaultConversionCurrencyCode,
                        }}
                    />
                }
                okButtonProps={{
                    size: 'middle',
                    'data-merchant': 'restore-default-currencies-confirm',
                }}
                onConfirm={handleResetButtonClick}
                okText={<FormattedMessage {...lang.resetConfirmButton} />}
                cancelButtonProps={{
                    size: 'middle',
                    'data-merchant': 'restore-default-currencies-cancel',
                }}
                cancelText={<FormattedMessage {...lang.resetCancelButton} />}
                data-merchant="restore-default-currencies-popconfirm"
            >
                <Button block size="large" data-merchant="restore-default-currencies-button">
                    <FormattedMessage {...lang.restoreDefaultButton} />
                </Button>
            </Popconfirm>
            <ModalFooterSaveButton
                isLoading={isSaving}
                allItemsCount={currencyOptions.length}
                addedItems={addedItems}
                removedItems={removedItems}
                onButtonClick={handleSaveCurrenciesClick}
                setSelectedCurrencies={setSelectedCurrencies}
            />
        </Space>
    )
}
