import { Input } from '@merchant/ui-kit/ant-design'
import { useRef } from 'react'
import { NumberFormatBase, useNumericFormat } from 'react-number-format'
import type { InputRef, InputProps } from '@merchant/ui-kit/ant-design'
import type { NumericFormatProps } from 'react-number-format'
import { DEFAULT_PRECISION } from '~constants'

type OmitNecessaryProps<T> = Omit<T, 'size' | 'suffix' | 'prefix' | 'onChange'>
type NumericFormatPropsWithoutIntersect = OmitNecessaryProps<NumericFormatProps>
export interface AmountInputProps
    extends Omit<InputProps, keyof NumericFormatPropsWithoutIntersect | 'onChange'>,
        NumericFormatPropsWithoutIntersect {
    precision?: number
    onChange?: (value: string) => void
}

export function AmountInput({ value, onChange, precision = DEFAULT_PRECISION, ...props }: AmountInputProps) {
    const { suffix, prefix, size, ...restProps } = props
    const inputRef = useRef<InputRef>(null)

    const handleOnValueChange: NumericFormatProps['onValueChange'] = ({ value }, { event }) => {
        if (event) {
            requestAnimationFrame(() => {
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                const { selectionStart, selectionEnd } = event.currentTarget || event.target
                inputRef.current?.setSelectionRange(selectionStart!, selectionEnd!)
            })
        }
        onChange?.(value)
    }

    const numericFormatProps = useNumericFormat({
        thousandsGroupStyle: 'thousand',
        thousandSeparator: ',',
        decimalScale: precision,
        inputMode: 'decimal',
        allowedDecimalSeparators: ['.', ','],
        decimalSeparator: '.',
        allowNegative: false,
        allowLeadingZeros: false,
        valueIsNumericString: true,
        getInputRef: inputRef,
        onValueChange: handleOnValueChange,
        value,
        ...restProps,
        customInput: Input,
    })

    return <NumberFormatBase {...numericFormatProps} suffix={suffix} prefix={prefix} size={size} />
}
