/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignupResponse
 */
export interface SignupResponse {
    /**
     * 
     * @type {string}
     * @memberof SignupResponse
     */
    token: string;
}

/**
 * Check if a given object implements the SignupResponse interface.
 */
// @ts-ignore
export function instanceOfSignupResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "token" in value;

    return isInstance;
}

// @ts-ignore
export function SignupResponseFromJSON(json: any): SignupResponse {
    return SignupResponseFromJSONTyped(json, false);
}

// @ts-ignore
export function SignupResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignupResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
    };
}

// @ts-ignore
export function SignupResponseToJSON(value?: SignupResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
    };
}

