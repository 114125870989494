import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import type { RefObject } from 'react'

type Props = {
    element?: RefObject<HTMLElement>
    updateOn?: unknown
}

export function ScrollToTop({ element, updateOn }: Props) {
    const { pathname } = useLocation()
    const currentEl = element?.current

    useEffect(() => {
        const el = element ? currentEl : window
        el?.scrollTo(0, 0)
    }, [pathname, currentEl, element, updateOn])

    return null
}
