import { message, notification } from '@merchant/ui-kit/ant-design'
import React, { createContext, useContext } from 'react'
import { messageConfig, notificationConfig } from './const'
import type { NotificationApi } from './types'
import type { MessageInstance } from '@merchant/ui-kit/ant-design/es/message/interface'
import type { ReactNode } from 'react'

interface NotificationContextValue {
    notification: {
        api: NotificationApi
        contextHolder: React.ReactElement<unknown, string | React.JSXElementConstructor<unknown>> | null
    }
    message: {
        api: MessageInstance
        contextHolder: React.ReactElement<unknown, string | React.JSXElementConstructor<unknown>> | null
    }
}

const NotificationContext = createContext<NotificationContextValue>({
    notification: {
        api: {
            open: () => undefined,
            destroy: () => undefined,
        },
        contextHolder: null,
    },
    message: {
        api: {
            loading: message.loading,
            open: message.open,
            destroy: message.destroy,
            success: message.success,
            info: message.info,
            warning: message.warning,
            error: message.error,
        },
        contextHolder: null,
    },
})

export interface Props {
    children: ReactNode
}

export function NotificationProvider({ children }: Props) {
    const [notificationApi, notificationContextHolder] = notification.useNotification(notificationConfig)
    const [messageApi, messageContextHolder] = message.useMessage(messageConfig)

    const value: NotificationContextValue = {
        notification: {
            api: { open: notificationApi.open, destroy: notificationApi.destroy },
            contextHolder: notificationContextHolder,
        },
        message: {
            api: messageApi,
            contextHolder: messageContextHolder,
        },
    }

    return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>
}

export const useNotifications = () => {
    const context = useContext(NotificationContext)

    return context
}
