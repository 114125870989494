import { createGenericContext } from '@merchant/shared/utils'
import { useState } from 'react'
import type { SetState } from '@merchant/shared/types'
import type { PropsWithChildren } from 'react'
import type { MessageDescriptor } from 'react-intl'

type DirtyChecker = () => { isDirty: boolean; dirtyPromptMessageDescriptor: MessageDescriptor } | undefined

interface Context {
    dirtyCheckCb: DirtyChecker | null
    setDirtyCheckCb: SetState<DirtyChecker | null>
}

const [useDirtyCheckingContext, Provider] = createGenericContext<Context>()

function DirtyCheckingContextProvider({ children }: PropsWithChildren) {
    const [dirtyCheckCb, setDirtyCheckCb] = useState<DirtyChecker | null>(null)

    return <Provider value={{ dirtyCheckCb, setDirtyCheckCb }}>{children}</Provider>
}

export { useDirtyCheckingContext, DirtyCheckingContextProvider }
