import { useBreakpoint, usePromise } from '@merchant/shared/hooks'
import { List } from '@merchant/ui-kit/ant-design'
import { CurrencyItem, EmailItem, LanguageItem, TimezoneItem, ColorModeSelectItem, NameItem } from './components'
import type { UpdateMerchantRequest } from '~api/instances/cabinet-api'
import { merchantApi, useBalances, useMerchant } from '~api'
import { PageContentContainer } from '~components'

export function General() {
    const { data, isLoading, isValidating, mutate: mutateMerchant } = useMerchant()
    const { mutate: mutateBalances } = useBalances()
    const breakpoints = useBreakpoint()

    const { send: updateMerchant, isLoading: isUpdateMerchantLoading } = usePromise(
        (updateMerchantRequest: Partial<UpdateMerchantRequest>) =>
            merchantApi.updateMerchant({ updateMerchantRequest }),
        {
            onSuccess: () => {
                mutateMerchant()
                // setting balances.data to undefined beforehand lets us to differentiate balances update by interval from forced
                // update triggered from here in TotalBalance.tsx component
                mutateBalances(undefined, { revalidate: true })
            },
        }
    )

    return (
        <PageContentContainer styles={{ body: { padding: !breakpoints.md ? 12 : undefined } }}>
            <List data-merchant="settings-items">
                <EmailItem value={data?.email} isLoading={isLoading} data-merchant="settings-email-item" />
                <NameItem
                    value={data?.name}
                    isLoading={isLoading}
                    onChange={name => updateMerchant({ name })}
                    data-merchant="settings-name-item"
                />
                <ColorModeSelectItem data-merchant="settings-color-mode-item" />
                <CurrencyItem
                    value={data?.currency}
                    isLoading={isValidating}
                    onChange={currency => updateMerchant({ currency })}
                    data-merchant="settings-currency-item"
                />
                <LanguageItem value={data?.language} isLoading={isLoading} data-merchant="settings-language-item" />
                <TimezoneItem
                    style={{ paddingBottom: 0 }}
                    value={data?.timeZone}
                    isLoading={isLoading || isUpdateMerchantLoading}
                    onChange={timeZone => updateMerchant({ timeZone })}
                    data-merchant="settings-timezone-item"
                />
            </List>
        </PageContentContainer>
    )
}
