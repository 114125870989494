/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WhitelistAddressEmail
 */
export interface WhitelistAddressEmail {
    /**
     * 
     * @type {string}
     * @memberof WhitelistAddressEmail
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof WhitelistAddressEmail
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof WhitelistAddressEmail
     */
    network?: string;
    /**
     * 
     * @type {string}
     * @memberof WhitelistAddressEmail
     */
    address: string;
}

/**
 * Check if a given object implements the WhitelistAddressEmail interface.
 */
// @ts-ignore
export function instanceOfWhitelistAddressEmail(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "address" in value;

    return isInstance;
}

// @ts-ignore
export function WhitelistAddressEmailFromJSON(json: any): WhitelistAddressEmail {
    return WhitelistAddressEmailFromJSONTyped(json, false);
}

// @ts-ignore
export function WhitelistAddressEmailFromJSONTyped(json: any, ignoreDiscriminator: boolean): WhitelistAddressEmail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'network': !exists(json, 'network') ? undefined : json['network'],
        'address': json['address'],
    };
}

// @ts-ignore
export function WhitelistAddressEmailToJSON(value?: WhitelistAddressEmail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'currency': value.currency,
        'network': value.network,
        'address': value.address,
    };
}

