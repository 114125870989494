import { AppCoin } from '@merchant/shared/components'
import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import { isEmpty } from 'lodash-es'
import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import type { DefaultOptionType } from '@merchant/ui-kit/ant-design/es/select'
import { useBalances, useCurrencies } from '~api'
import { useCurrencyPredicates } from '~features/PaymentConstructor/hooks'

const { Text } = Typography

export function useCurrencyOptions() {
    const { data: currencies, isLoading: isLoadingCurrencies } = useCurrencies()
    const { data: balances = [], isLoading: isLoadingBalances } = useBalances()
    const { predicates, isLoading: isLoadingPredicates } = useCurrencyPredicates()

    const sortingPriorities = useMemo(
        () =>
            balances.reduce(
                (acc, balance, index) => {
                    acc[balance.currency] = index + 1

                    return acc
                },
                {} as Record<string, number>
            ),
        [balances]
    )

    const options = useMemo(() => {
        const cryptoCurrencies = Object.values(currencies || {}).filter(
            c => c.crypto && predicates.every(predicate => predicate(c))
        )
        if (isEmpty(cryptoCurrencies)) {
            return []
        }

        const sortedOptions = cryptoCurrencies.sort((a, b) => {
            const { code: aCode = '', equivalentPriority: aEquivalentPriority = 0 } = a
            const { code: bCode = '', equivalentPriority: bEquivalentPriority = 0 } = b

            return (sortingPriorities[aCode] ?? aEquivalentPriority) - (sortingPriorities[bCode] ?? bEquivalentPriority)
        })

        const { enabled, disabled } = sortedOptions.reduce(
            (acc, currency) => {
                const option: DefaultOptionType = {
                    key: currency.code + currency.name,
                    label: (
                        <Flex align="center" gap={8} style={{ opacity: currency.payinEnabled ? 1 : 0.4 }}>
                            <AppCoin currencyCode={currency.code} size="small" />
                            <Text data-merchant={null}>
                                {currency.code}
                                &nbsp;
                                <Text
                                    type="secondary"
                                    data-merchant={`static-address-constructor-currency-name-${currency.name}`}
                                >
                                    {currency.name}
                                </Text>
                            </Text>
                            {!currency.payinEnabled && (
                                <Text type="secondary" style={{ marginLeft: 'auto' }} data-merchant={null}>
                                    <FormattedMessage {...lang.unavailableDeposits} />
                                </Text>
                            )}
                        </Flex>
                    ),
                    disabled: !currency.payinEnabled,
                    value: currency.code,
                    'data-merchant': `static-address-constructor-currency-${currency.code.toLowerCase()}`,
                }

                if (currency.payinEnabled) {
                    acc.enabled.push(option)
                } else {
                    acc.disabled.push(option)
                }

                return acc
            },
            { enabled: [], disabled: [] } as {
                enabled: DefaultOptionType[]
                disabled: DefaultOptionType[]
            }
        )

        return [...enabled, ...disabled]
    }, [currencies, predicates, sortingPriorities])

    return {
        options,
        isLoading: isLoadingCurrencies || isLoadingBalances || isLoadingPredicates,
    }
}
