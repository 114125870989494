import { ModalResponsive } from '@merchant/shared/components'
import { useBreakpoint, useDebouncedState } from '@merchant/shared/hooks'
import { FormattedMessage } from 'react-intl'
import { useBoolean } from 'usehooks-ts'
import { currencyModalContentStyles, currencyModalStyles } from './style'
import styles from './style.module.css'
import type { Breakpoints } from '@merchant/shared/hooks'
import type { MessageDescriptor } from 'react-intl'

type WithDataMerchant = { 'data-merchant': string }

type Props<T> = Omit<T, 'title'> & {
    title: MessageDescriptor
    renderMobile: (
        props: T & WithDataMerchant,
        commonProps: { listHeight?: number; closeMobileDropdown: () => void },
        breakpoints: Breakpoints
    ) => JSX.Element
    render: (
        props: T & WithDataMerchant,
        commonProps: { openMobileDropdown: () => void },
        breakpoints: Breakpoints
    ) => JSX.Element
    'data-merchant': string
}
export function ResponsiveDropdown<T>({ title, renderMobile, render, ...props }: Props<T>) {
    const [isModalOpen, setIsModalOpen] = useDebouncedState(false, 100)
    const { value: isDropdownOpen, setValue: setIsDropdownOpen } = useBoolean(true)
    const breakpoints = useBreakpoint()
    const isMobile = !breakpoints.md
    const dropdownHeight = isMobile ? window.innerHeight - 130 : undefined

    const closeModal = () => {
        setIsDropdownOpen(false)
        setIsModalOpen(false)
    }

    return (
        <>
            {isMobile && (
                <ModalResponsive
                    title={<FormattedMessage {...title} />}
                    afterClose={() => setIsDropdownOpen(true)}
                    open={isModalOpen}
                    onCancel={closeModal}
                    destroyOnClose
                    mask={false}
                    footer={null}
                    className={styles.wrapper}
                    style={currencyModalStyles}
                    styles={{
                        content: currencyModalContentStyles,
                    }}
                    data-merchant={`${props['data-merchant']}-modal`}
                >
                    {renderMobile(
                        { ...props, open: isDropdownOpen } as unknown as T & WithDataMerchant,
                        {
                            listHeight: dropdownHeight,
                            closeMobileDropdown: () => closeModal(),
                        },
                        breakpoints
                    )}
                </ModalResponsive>
            )}
            {render(
                {
                    ...props,
                    open: !isMobile ? undefined : false,
                } as unknown as T & WithDataMerchant,
                {
                    openMobileDropdown: () => setIsModalOpen(true),
                },
                breakpoints
            )}
        </>
    )
}
