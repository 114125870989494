const objectToString = Object.prototype.toString

const isError = (value: unknown) => objectToString.call(value) === '[object Error]'

const errorMessages = new Set([
    'network error', // Chrome
    'Failed to fetch', // Chrome
    'NetworkError when attempting to fetch resource.', // Firefox
    'The Internet connection appears to be offline.', // Safari 16
    'Load failed', // Safari 17+
    'Network request failed', // `cross-fetch`
    'fetch failed', // Undici (Node.js)
])

export function getIsNetworkError(error: Error | TypeError) {
    const isValid =
        isError(error) &&
        (error.name === 'TypeError' || Object.getPrototypeOf(error).name === 'TypeError') &&
        typeof error.message === 'string'

    if (!isValid) {
        return false
    }

    // We do an extra check for Safari 17+ as it has a very generic error message.
    // Network errors in Safari have no stack.
    if (error.message === 'Load failed') {
        return error.stack === undefined
    }

    return errorMessages.has(error.message)
}

export async function initResponseData(response?: Response) {
    if (!response) {
        return undefined
    }

    const responseText = await response.text()
    if (responseText) {
        try {
            return JSON.parse(responseText)
        } catch {
            return responseText
        }
    }
}
