import { lang } from './lang'
import type { IntlShape } from 'react-intl'

interface PasswordValidation {
    name: string
    isValid: boolean
    message: string
}

const PASSWORD_MIN_LENGTH = 8

const numbersReg = new RegExp(/\d/)
const nonNumericReg = new RegExp(/\D/)

export const getPasswordValidations = (
    intl: IntlShape,
    passwordValue?: string
): { [key: string]: PasswordValidation } => ({
    minLength: {
        name: 'min-length',
        isValid: !!passwordValue?.length && passwordValue.length >= PASSWORD_MIN_LENGTH,
        message: intl.formatMessage(lang.passwordMinLength, {
            minLength: PASSWORD_MIN_LENGTH,
        }),
    },
    hasNumber: {
        name: 'contains-digit',
        isValid: numbersReg.test(passwordValue || ''),
        message: intl.formatMessage(lang.passwordOneNumber),
    },
    hasNonNumeric: {
        name: 'contains-non-digit',
        isValid: nonNumericReg.test(passwordValue || ''),
        message: intl.formatMessage(lang.passwordOneNonNumeric),
    },
})

export const passwordFormItemValidator = (intl: IntlShape, passwordValue?: string) => (): Promise<void> => {
    const passwordRules = getPasswordValidations(intl, passwordValue)

    for (const { isValid } of Object.values(passwordRules)) {
        if (!isValid) {
            return Promise.reject(new Error())
        }
    }

    return Promise.resolve()
}

export function getValidationStatus(validation: PasswordValidation, isTouched: boolean) {
    if (validation.isValid) {
        return 'success'
    }

    if (isTouched) {
        return 'failure'
    }

    return 'unverified'
}
