import type { WhitelistWithKey } from '~api/swr/rest/types'
import { ModalNames } from '~constants/modal'

export const steps = {
    [ModalNames.whitelistAddInput]: ModalNames.whitelistAddInput,
    [ModalNames.whitelistAddConfirmation]: ModalNames.whitelistAddConfirmation,
} as const

export const initWhitelist: WhitelistWithKey = {
    address: '',
    currency: undefined,
    name: undefined,
    network: undefined,
    addressTag: undefined,
    key: '',
}
