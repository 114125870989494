import type { NewTwoFaFormData } from './types'
import type { FormItemsKey } from '@merchant/shared/types'

export const initValues: NewTwoFaFormData = {
    totpCode: '',
}

export const newTwoFaFormDataItems: FormItemsKey<NewTwoFaFormData> = {
    totpCode: 'totpCode',
}
