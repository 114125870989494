import type { ModalProps } from '@merchant/ui-kit/ant-design'
import type { BlocklistItem } from '~api/instances/cabinet-api'

export enum SuspensionContext {
    Withdrawal = 'withdrawal',
    TeamManagement = 'team-management',
    Integration = 'integration',
}

export interface CommonProps extends Pick<ModalProps, 'onClose'> {
    expiresAt: BlocklistItem['expiresAt'] | undefined
    blockReason: BlocklistItem['reason'] | undefined
    context: SuspensionContext
}
