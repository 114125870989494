import { getEntries } from '@merchant/shared/utils'
import { fiatRelatedPermissions } from '../../const'
import { permissionsLang } from '../../permissionsLang'
import { permissionGroupsLang } from './permissionGroupsLang'
import type { FormValues, PermissionRecord } from './types'
import type { MessageDescriptor } from 'react-intl'
import type {
    ListAllPermissions200ResponseGroupsInner,
    Permission,
    PermissionGroup,
    ProjectRole,
} from '~api/instances/cabinet-api'

const filterPermissions = (permissions: PermissionRecord) =>
    getEntries(permissions)
        .filter(([_, value]) => !!value)
        .map(([key]) => key)

export const normalizeFinishFormValues = (values: FormValues) =>
    Object.values(values).map(role => ({
        ...role,
        id: role.id,
        permissions: filterPermissions(role.permissions),
    }))

const checkIsPermissionGroup = (permission: Permission | PermissionGroup | string): permission is PermissionGroup =>
    permission in permissionGroupsLang

export const checkIsPermission = (permission: Permission | PermissionGroup | string): permission is Permission =>
    permission in permissionsLang

export const getPermissionMessageDescriptor = (
    permission: Permission | PermissionGroup | string
): MessageDescriptor | undefined => {
    if (checkIsPermissionGroup(permission)) {
        return permissionGroupsLang[permission]
    } else if (checkIsPermission(permission)) {
        return permissionsLang[permission]
    }

    return undefined
}

export const getNormalizedFormInitData = (projectRoles: ProjectRole[]) =>
    projectRoles.reduce<FormValues>(
        (acc, role) => ({
            [role.id]: {
                ...role,
                permissions: role.permissions.reduce((acc, permission) => ({ ...acc, [permission]: true }), {}),
            },
            ...acc,
        }),
        {}
    )

export const getAllPermissions = (projectPermissions: ListAllPermissions200ResponseGroupsInner[] = []) => {
    return projectPermissions.flatMap(group => [
        { permission: group.id, isGroup: true },
        ...group.permissions.map(permission => ({ permission })),
    ])
}

export const getFiatlessPermissions = (projectPermissions: ListAllPermissions200ResponseGroupsInner[] = []) => {
    return projectPermissions.flatMap(group => [
        { permission: group.id, isGroup: true },
        ...group.permissions.reduce<{ permission: Permission }[]>((acc, permission) => {
            if (!fiatRelatedPermissions.includes(permission)) {
                acc.push({ permission })
            }

            return acc
        }, []),
    ])
}
