import { useTernaryDarkMode } from 'usehooks-ts'
import { useInitializedLocalStorage } from './useInitializedLocalStorage'
import { colorModeKey } from '~constants'
import { getUserPreferredTheme } from '~utils'

export type ColorMode = ReturnType<typeof useTernaryDarkMode>['ternaryDarkMode']

export const useColorMode = () => {
    const [themeKey] = useInitializedLocalStorage(colorModeKey, getUserPreferredTheme())
    const { isDarkMode, setTernaryDarkMode, ternaryDarkMode, toggleTernaryDarkMode } = useTernaryDarkMode({
        localStorageKey: colorModeKey,
        initializeWithValue: true,
        defaultValue: themeKey,
    })

    return {
        isDark: isDarkMode,
        setColorMode: setTernaryDarkMode,
        colorMode: ternaryDarkMode,
        toggleColorMode: toggleTernaryDarkMode,
    }
}
