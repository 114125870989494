import { useBreakpoint } from '@merchant/shared/hooks'
import { Flex, List, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from '../../lang'
import { getCommonFlexProps, listTitleStyles, getSelectStyles } from '../../styles'
import type { ListItemProps } from '@merchant/ui-kit/ant-design/es/list'
import { BaseCurrencySelect } from '~components'

const { Text } = Typography

interface Props extends Omit<ListItemProps, 'onChange'> {
    isLoading: boolean
    value: string | undefined
    onChange: (currency: string) => void
    disabled?: boolean
}

export function CurrencyItem({ isLoading, value, onChange, disabled = false, ...rest }: Props) {
    const breakpoints = useBreakpoint()
    const commonFlexProps = getCommonFlexProps(!breakpoints.md)

    return (
        <List.Item {...rest}>
            <Flex {...commonFlexProps}>
                <List.Item.Meta
                    title={
                        <Text style={listTitleStyles} data-merchant={null}>
                            <FormattedMessage {...lang.currency} />
                        </Text>
                    }
                    data-merchant="settings-currency-list-item-meta"
                />
                <BaseCurrencySelect
                    style={getSelectStyles({ breakpoints, disabled })}
                    data-merchant="settings-currency-select"
                    loading={isLoading}
                    value={value}
                    onSelect={onChange}
                />
            </Flex>
        </List.Item>
    )
}
