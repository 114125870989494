/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PermissionGroup {
    OrdersAndTransactions = 'orders_and_transactions',
    Withdrawals = 'withdrawals',
    Swaps = 'swaps',
    GeneralInfo = 'general_info',
    CurrencySettings = 'currency_settings',
    PaymentSettings = 'payment_settings',
    TeamManagement = 'team_management',
    Integrations = 'integrations'
}


// @ts-ignore
export function PermissionGroupFromJSON(json: any): PermissionGroup {
    return PermissionGroupFromJSONTyped(json, false);
}

// @ts-ignore
export function PermissionGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionGroup {
    return json as PermissionGroup;
}

// @ts-ignore
export function PermissionGroupToJSON(value?: PermissionGroup | null): any {
    return value as any;
}

