import { EmailSupportLink, ModalResponsive } from '@merchant/shared/components'
import { Button, Flex, type ModalProps } from '@merchant/ui-kit/ant-design'
import { FormattedMessage, useIntl } from 'react-intl'
import { lang } from './lang'
import type { NotificationModalName } from '../types'
import type { ReactNode } from 'react'
import type { IntlShape, MessageDescriptor } from 'react-intl'
import { globalLang } from '~globalLang'

interface Props extends Omit<ModalProps, 'data-merchant'> {
    currency: string
    name: NotificationModalName
}

const modalContentMap: Record<
    NonNullable<Props['name']>,
    {
        getFooter: (onCancel: ModalProps['onCancel'], intl?: IntlShape) => ReactNode
        titleDescriptor: MessageDescriptor
        contentDescriptor: MessageDescriptor
        dataMerchant: string
    }
> = {
    fiat_unavailable: {
        titleDescriptor: lang.withdrawalUnavailableTitle,
        contentDescriptor: lang.fiatWithdrawalUnavailableDescription,
        getFooter: (onCancel, intl) => {
            return (
                <Flex gap={12}>
                    <Button
                        block
                        size="large"
                        type="default"
                        href={`mailto:${intl?.formatMessage(globalLang.merchantSupportEmail)}`}
                        data-merchant="currency-settings-fiat-unavailable-modal-support-button"
                    >
                        <FormattedMessage {...lang.contactSupportButton} />
                    </Button>
                    <Button
                        block
                        size="large"
                        type="primary"
                        onClick={onCancel}
                        data-merchant="currency-settings-fiat-unavailable-modal-ok-button"
                    >
                        <FormattedMessage {...lang.okButton} />
                    </Button>
                </Flex>
            )
        },
        dataMerchant: 'currency-settings-fiat-unavailable-modal',
    },
    crypto_unavailable: {
        titleDescriptor: lang.withdrawalUnavailableTitle,
        contentDescriptor: lang.cryptoWithdrawalUnavailableDescription,
        getFooter: onCancel => {
            return (
                <Button
                    block
                    size="large"
                    onClick={onCancel}
                    data-merchant="currency-settings-crypto-unavailable-modal-ok-button"
                >
                    <FormattedMessage {...lang.okButton} />
                </Button>
            )
        },
        dataMerchant: 'currency-settings-crypto-unavailable-modal',
    },
    crypto_available: {
        titleDescriptor: lang.attentionRequiredTitle,
        contentDescriptor: lang.attentionRequiredDescription,
        getFooter: onCancel => {
            return (
                <Button
                    block
                    size="large"
                    type="primary"
                    onClick={onCancel}
                    data-merchant="currency-settings-crypto-available-modal-ok-button"
                >
                    <FormattedMessage {...lang.okButton} />
                </Button>
            )
        },
        dataMerchant: 'currency-settings-crypto-available-modal',
    },
}

export function NotificationModal({ name, currency, onCancel, ...props }: Props) {
    const intl = useIntl()

    if (!name) {
        return null
    }

    const content = modalContentMap[name]

    return (
        <ModalResponsive
            {...props}
            onCancel={onCancel}
            title={
                <FormattedMessage
                    {...content.titleDescriptor}
                    values={{
                        currency,
                    }}
                />
            }
            footer={content.getFooter(onCancel, intl)}
            data-merchant={content.dataMerchant}
        >
            <FormattedMessage
                {...content.contentDescriptor}
                values={{
                    currency,
                    bold: chunks => <b>{chunks}</b>,
                    break: () => <br />,
                    supportEmail: chunks => (
                        <EmailSupportLink style={{ fontSize: 'inherit' }}>{chunks}</EmailSupportLink>
                    ),
                }}
            />
        </ModalResponsive>
    )
}
