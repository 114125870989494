import { defineMessages, type MessageDescriptor } from 'react-intl'
import { ProjectAPIKeyPermissionID } from '~api'

export const apiKeyPermissionsDescriptionLang: Record<ProjectAPIKeyPermissionID, MessageDescriptor> = defineMessages({
    [ProjectAPIKeyPermissionID.Balances]: {
        id: 'api-key.permissions-description.balances',
        defaultMessage:
            'Fetch account balances for all available currencies, including swaps and currency-specific balances',
    },
    [ProjectAPIKeyPermissionID.Currencies]: {
        id: 'api-key.permissions-description.currencies',
        defaultMessage: 'Retrieve supported currencies and their network details',
    },
    [ProjectAPIKeyPermissionID.Swaps]: {
        id: 'api-key.permissions-description.swap',
        defaultMessage: 'Initiate currency conversions between supported pairs',
    },
    [ProjectAPIKeyPermissionID.Full]: {
        id: 'api-key.permissions-description.full',
        defaultMessage: 'Full',
    },
    [ProjectAPIKeyPermissionID.Addresses]: {
        id: 'api-key.permissions-description.addresses',
        defaultMessage:
            'Allocate, update, and retrieve static deposit addresses for customers based on currency and network',
    },
    [ProjectAPIKeyPermissionID.Payments]: {
        id: 'api-key.permissions-description.payments',
        defaultMessage:
            'Manage payment orders, methods, expected amounts, and trigger callbacks for payment processing',
    },
    [ProjectAPIKeyPermissionID.Withdrawals]: {
        id: 'api-key.permissions-description.withdrawals',
        defaultMessage:
            'Calculate withdrawal amounts, create withdrawals, fetch withdrawal status, and manage withdrawal callbacks',
    },
} as const)
