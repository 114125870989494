export function parseIfValidJson(json: string) {
    try {
        const parsed = JSON.parse(json)

        return {
            isValid: true,
            parsed,
        }
    } catch {
        return {
            isValid: false,
            parsed: null,
        }
    }
}
