import { useHoldingCurrencies, useProject, useTotps } from '~api'
import { PlaceholderName } from '~features/PlaceholderView/types'

export const usePlaceholderView = () => {
    const { data: project, isLoading: isLoadingProject } = useProject()
    const { hasActiveTotp, isLoading: isLoadingTotps } = useTotps()
    const { data: holdingCurrencies, isLoading: isLoadingHoldingCurrencies } = useHoldingCurrencies()

    let placeholderName: PlaceholderName | undefined

    if (!isLoadingProject && !isLoadingHoldingCurrencies && !isLoadingTotps) {
        if (!project) {
            placeholderName = PlaceholderName.noMerchant
        } else if (!holdingCurrencies?.[0]?.selected) {
            placeholderName = PlaceholderName.noCurrency
        } else if (!hasActiveTotp) {
            placeholderName = PlaceholderName.no2fa
        }
    }

    return {
        name: placeholderName,
        isLoading: isLoadingProject || isLoadingTotps,
    }
}
