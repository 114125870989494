import dayjs from 'dayjs'
import type { NewPaymentFormData } from './types'
import type { FormItemsKey } from '@merchant/shared/types'
import { FeesPayer } from '~api/instances/cabinet-api'
import { constructorTypes } from '~features/PaymentConstructor/types'

export enum OrderSettingsType {
    BasicSettings = '1',
    AdvancedSettings = '2',
}

export const collapseFieldsMap: Record<OrderSettingsType, (keyof NewPaymentFormData)[]> = {
    [OrderSettingsType.BasicSettings]: [
        'type',
        'nominalAmount',
        'nominalCurrency',
        'title',
        'description',
        'pendingDeadlineAt',
        'feesPayer',
    ],
    [OrderSettingsType.AdvancedSettings]: [
        'orderId',
        'customerEmail',
        'customerId',
        'successRedirectUrl',
        'failureRedirectUrl',
        'lossConfig',
    ],
} as const

export const newOrderFormKeys = {
    type: 'type',
    nominalAmount: 'nominalAmount',
    nominalCurrency: 'nominalCurrency',
    title: 'title',
    description: 'description',
    pendingDeadlineAt: 'pendingDeadlineAt',
    feesPayer: 'feesPayer',
    customerEmail: 'customerEmail',
    customerId: 'customerId',
    failureRedirectUrl: 'failureRedirectUrl',
    successRedirectUrl: 'successRedirectUrl',
    orderId: 'orderId',
    otherData: 'otherData',
    lossConfig: 'lossConfig',
    recommendedAmount: 'recommendedAmount',
} satisfies FormItemsKey<NewPaymentFormData>

export const initFormValues: Partial<NewPaymentFormData> = {
    type: constructorTypes.Invoice,
    nominalAmount: '',
    feesPayer: FeesPayer.Customer,
    pendingDeadlineAt: dayjs().add(24, 'hours').toDate(),
    lossConfig: undefined,
}
