import { isEmpty } from 'lodash-es'
import { useMemo } from 'react'
import { useBalances, useCurrencies } from '~api'

export function useDepositEligibleCurrencies() {
    const { data: currencies } = useCurrencies()
    const { data: balances } = useBalances()

    return useMemo(() => {
        return (balances || []).reduce<Record<string, boolean>>((acc, balance) => {
            const currency = currencies?.[balance.currency]
            if (!currency?.crypto) {
                acc[balance.currency] = false

                return acc
            }
            if (!currency.payinEnabled) {
                acc[balance.currency] = false

                return acc
            } else if (isEmpty(currency.networks)) {
                acc[balance.currency] = true

                return acc
            }

            const hasPayinEnabledNetworks = currency.networks.some(network => network.payinEnabled)
            acc[balance.currency] = hasPayinEnabledNetworks

            return acc
        }, {})
    }, [balances, currencies])
}
