/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SigninRequest
 */
export interface SigninRequest {
    /**
     * 
     * @type {string}
     * @memberof SigninRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof SigninRequest
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof SigninRequest
     */
    deviceToken?: string;
}

/**
 * Check if a given object implements the SigninRequest interface.
 */
// @ts-ignore
export function instanceOfSigninRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

// @ts-ignore
export function SigninRequestFromJSON(json: any): SigninRequest {
    return SigninRequestFromJSONTyped(json, false);
}

// @ts-ignore
export function SigninRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SigninRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'deviceToken': !exists(json, 'device_token') ? undefined : json['device_token'],
    };
}

// @ts-ignore
export function SigninRequestToJSON(value?: SigninRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'password': value.password,
        'device_token': value.deviceToken,
    };
}

