import { AppCoin } from '@merchant/shared/components'
import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import { isEmpty } from 'lodash-es'
import { useMemo } from 'react'
import type { DefaultOptionType } from '@merchant/ui-kit/ant-design/es/select'
import type { Currency } from '~api'
import { useCurrencies } from '~api'

const { Text } = Typography

const getOption = (currency: Currency) => ({
    label: (
        <Flex gap={4}>
            <AppCoin currencyCode={currency.code || ''} size="small" />
            <Text data-merchant={null}>{currency.code}</Text>
            <Text data-merchant={null} type="secondary">
                {currency.name}
            </Text>
        </Flex>
    ),
    selectedLabel: (
        <Flex gap={4}>
            <AppCoin currencyCode={currency.code || ''} size="small" />
            <Text data-merchant={null}>{currency.code}</Text>
        </Flex>
    ),
    value: currency.code,
})

export function useCurrencyOptions() {
    const { data: currencies } = useCurrencies()

    const options: DefaultOptionType[] = useMemo(() => {
        const alphabeticallySortedCurrencies = Object.values(currencies || {}).toSorted((a, b) =>
            a.code.localeCompare(b.code)
        )

        return alphabeticallySortedCurrencies.reduce<DefaultOptionType[]>((acc, currency) => {
            if (currency.crypto === false && isEmpty(currency.swappableTo)) {
                return acc
            }

            acc.push(getOption(currency))

            return acc
        }, [])
    }, [currencies])

    return { options }
}
