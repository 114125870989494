import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    deleteTitle: {
        id: 'delete-two-fa.modal.delete_title',
        defaultMessage: 'Delete 2FA',
    },
    cannotRemoveTitle: {
        id: 'delete-two-fa.modal.cannot_remove_title',
        defaultMessage: 'Cannot Remove 2FA',
    },
})
