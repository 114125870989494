import { CompactCardsWrapper } from '@merchant/shared/components'
import { ErrorHandler } from '@merchant/shared/utils'
import { Button, Card, Tooltip, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { compactCardsWrapperStyles } from '../../const'
import { stepKeys } from './const'
import { CurrenciesList } from './CurrenciesList'
import { EditCurrenciesModal } from './EditCurrenciesModal'
import { lang } from './lang'
import { NotificationModal } from './NotificationModal'
import { StepItem } from './StepItem'
import { useData } from './useData'
import { FiatCryptoSelect } from '~components'

const { Text } = Typography

export const errorHandler = new ErrorHandler()
const ErrorBoundary = errorHandler.render

interface Props {
    onSetupFinish: () => void
}

// eslint-disable-next-line max-lines-per-function
export function CurrenciesSettings({ onSetupFinish }: Props) {
    const {
        setupStep,
        currencyOptions,
        currenciesMap,
        selectedCurrencies,
        toNextStep,
        onFinishSetup,
        onDefaultCurrencyConfirm,
        onDefaultCurrencyChange,
        openCurrencySettingsModal,
        onRestoreDefault,
        onSave,
        closeCurrencySettingsModal,
        defaultCurrency,
        isCurrencySettingsModalOpen,
        isValidatingHoldingCurrencies,
        isSavingCurrencies,
        isLoadingData,
        notificationModalName,
        closeNotificationModal,
        isCurrencySettingsEditAllowed,
    } = useData({ onSetupFinish })

    return (
        <>
            <NotificationModal
                open={!!notificationModalName}
                onCancel={closeNotificationModal}
                currency={defaultCurrency}
                name={notificationModalName}
            />
            {defaultCurrency && (
                <EditCurrenciesModal
                    currenciesMap={currenciesMap}
                    onRestoreDefault={onRestoreDefault}
                    open={isCurrencySettingsModalOpen}
                    onClose={closeCurrencySettingsModal}
                    currencies={currencyOptions}
                    committedSelectedCurrencies={selectedCurrencies}
                    onSave={onSave}
                    defaultConvertCurrency={defaultCurrency}
                    isSaveLoading={isSavingCurrencies}
                />
            )}
            <ErrorBoundary
                errorKeys={stepKeys}
                fallbackCb={(_, fb) => (
                    <Card data-merchant="currencies-settings-steps-error" styles={{ body: { padding: '22px 18px' } }}>
                        {fb()}
                    </Card>
                )}
            >
                <CompactCardsWrapper
                    compactCardStyles={compactCardsWrapperStyles}
                    dataMerchantPrefix="currencies-settings-steps"
                >
                    {/* Step 1 */}
                    <StepItem
                        titleMerchantData="currency-settings-introduction"
                        isCurrencySettingsEditAllowed={isCurrencySettingsEditAllowed}
                        isLoading={isLoadingData}
                        title={<FormattedMessage {...lang.stepOneTitle} />}
                        stepItemIndex={1}
                        description={<FormattedMessage {...lang.stepOneDescription} />}
                        buttonTitle={<FormattedMessage {...lang.stepOneButton} />}
                        goToNextStep={toNextStep}
                        currentStep={setupStep}
                    />
                    {/* Step 2 */}
                    <StepItem
                        titleMerchantData="accumilation-currency"
                        isCurrencySettingsEditAllowed={isCurrencySettingsEditAllowed}
                        isLoading={isLoadingData}
                        title={<FormattedMessage {...lang.stepTwoTitle} />}
                        stepItemIndex={2}
                        description={<FormattedMessage {...lang.stepTwoDescription} />}
                        buttonTitle={<FormattedMessage {...lang.stepTwoButton} />}
                        currentStep={setupStep}
                        goToNextStep={onDefaultCurrencyConfirm}
                    >
                        <FiatCryptoSelect
                            disabled={!isCurrencySettingsEditAllowed}
                            style={{ width: '100%', maxWidth: 480 }}
                            options={currencyOptions}
                            value={defaultCurrency}
                            loading={isLoadingData || isSavingCurrencies}
                            onChange={onDefaultCurrencyChange}
                            data-merchant="currency-settings-select"
                            optionDataMerchantPrefix="currency-settings"
                        />
                    </StepItem>
                    {/* Step 3 */}
                    <StepItem
                        isCurrencySettingsEditAllowed={isCurrencySettingsEditAllowed}
                        isLoading={isLoadingData}
                        title={
                            <FormattedMessage
                                {...lang.stepThreeTitle}
                                values={{
                                    tag: chunks => (
                                        <Text
                                            data-merchant="direct-accumulation-title-tag"
                                            type="secondary"
                                            style={{ fontWeight: 400 }}
                                        >
                                            {chunks}
                                        </Text>
                                    ),
                                }}
                            />
                        }
                        style={{ gap: 12 }}
                        stepItemIndex={3}
                        description={<FormattedMessage {...lang.stepThreeDescription} />}
                        currentStep={setupStep}
                        goToNextStep={toNextStep}
                        titleMerchantData="direct-accumulation"
                    >
                        <CurrenciesList
                            isCurrencySettingsEditAllowed={isCurrencySettingsEditAllowed}
                            defaultCurrency={defaultCurrency}
                            loading={isLoadingData || isValidatingHoldingCurrencies}
                            dataSource={selectedCurrencies}
                            onButtonClick={openCurrencySettingsModal}
                            data-merchant="currencies-settings-currencies-list"
                        />
                        {setupStep === 3 && (
                            <Tooltip
                                title={<FormattedMessage {...lang.stepThreeButtonTooltip} />}
                                placement="bottom"
                                data-merchant="currencies-setup-finish-button"
                            >
                                <Button
                                    disabled={!isCurrencySettingsEditAllowed}
                                    style={{ width: '100%' }}
                                    type="primary"
                                    block
                                    size="large"
                                    onClick={onFinishSetup}
                                    data-merchant={`currencies-settings-step-${setupStep}-button`}
                                >
                                    <FormattedMessage {...lang.stepThreeButton} />
                                </Button>
                            </Tooltip>
                        )}
                    </StepItem>
                </CompactCardsWrapper>
            </ErrorBoundary>
        </>
    )
}
