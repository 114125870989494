import { type FormInstance } from '@merchant/ui-kit/ant-design'
import { useCallback, useEffect } from 'react'
import type { FormNames } from '~constants/form'
import type { ModalNames } from '~constants/modal'
import { formConfig } from '~config/form'
import { useDirtyCheckingContext } from '~contexts/dirtyChecking.context'

function checkIsFormInstance(form: object): form is FormInstance {
    return Object.prototype.hasOwnProperty.call(form, 'getFieldsValue')
}

export const useRegisterDirtyFormChecker = (
    name: ModalNames | FormNames,
    form?: FormInstance | { initial: unknown; current: unknown }
) => {
    const { setDirtyCheckCb } = useDirtyCheckingContext()

    const checkFormDirtiness = useCallback(() => {
        if (!form) {
            return formConfig[name]?.getDirtyCheckResult({})
        }
        if (checkIsFormInstance(form)) {
            return formConfig[name]?.getDirtyCheckResult({ form })
        }

        return formConfig[name]?.getDirtyCheckResult({
            customFields: form,
        })
    }, [form, name])

    useEffect(() => {
        setDirtyCheckCb(() => checkFormDirtiness)

        return () => setDirtyCheckCb(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setDirtyCheckCb, name])
}
