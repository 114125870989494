import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    permissions: {
        id: 'integrations-page.modal-create.permissions',
        defaultMessage: 'Permissions',
    },
    howPermissionsWork: {
        id: 'integrations-page.modal-create.see_how_permissions_work',
        defaultMessage: 'See how permissions work',
    },
    howPermissionsWorkTooltip: {
        id: 'integrations-page.modal-create.see_how_permissions_work_tooltip',
        defaultMessage: `<strong>All:</strong> Full access (Read + Write) to all current and future (if appear later) endpoints.
        <break></break>
        <strong>Custom:</strong> Set permissions manually for each bundle of endpoints. Write permission includes Read permission where applicable.
        <break></break>
        <strong>Read Only:</strong> Read-only access to all current and future endpoints.`,
    },
    all: {
        id: 'integrations-page.modal-create.all',
        defaultMessage: 'All',
    },
    custom: {
        id: 'integrations-page.modal-create.custom',
        defaultMessage: 'Custom',
    },
    readOnly: {
        id: 'integrations-page.modal-create.read_only',
        defaultMessage: 'Read only',
    },
    endpointsCount: {
        id: 'integrations-page.modal-create.endpoints_count',
        defaultMessage: '{count, plural, one {# endpoint} other {# endpoints}}',
    },
    none: {
        id: 'integrations-page.modal-create.none',
        defaultMessage: 'None',
    },
    read: {
        id: 'integrations-page.modal-create.read',
        defaultMessage: 'Read',
    },
    write: {
        id: 'integrations-page.modal-create.write',
        defaultMessage: 'Write',
    },
    permissionRequiredErrorMessage: {
        id: 'integrations-page.modal.permission_required_error_message',
        defaultMessage: 'Please select at least one permission',
    },
})
