import { usePromise, useBreakpoint } from '@merchant/shared/hooks'
import { Button, Form, Typography } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useBoolean } from 'usehooks-ts'
import { lang } from '../../lang'
import type { ConfirmSigninRequest } from '~api/instances/cabinet-api'
import { authApi } from '~api'
import { TwoFaConfirmationItem } from '~features'
import { getFormStyles } from '~features/AuthorizationPage/style'
import { useCodeConfirmationErrorHandling } from '~features/FormItems/useCodeErrorHandling'
import { globalLang } from '~globalLang'

const { Title } = Typography

const titleStyles: React.CSSProperties = { display: 'inline-block' }

type TwoFaVerificationFormData = Omit<ConfirmSigninRequest, 'verificationCode'>

const formInitData: TwoFaVerificationFormData = {
    totpCode: '',
    totpBackup: '',
}

interface Props {
    onSuccess: () => void
    token: string
}

export function VerifyTwoFa({ onSuccess, token }: Props) {
    const [form] = Form.useForm()
    const breakpoints = useBreakpoint()
    const { value: isBackupCodeUsed, toggle: toggleIsBackupCodeUsed } = useBoolean(false)
    const { handleError } = useCodeConfirmationErrorHandling({ confirmationStrategy: { '2fa': 'code' } })

    const intl = useIntl()

    const pageTitle = intl.formatMessage(isBackupCodeUsed ? lang.enterBackupCode : lang.enterTwoFaCode)
    const { send, isLoading } = usePromise(
        async (values: Omit<ConfirmSigninRequest, 'verificationCode'>) =>
            authApi.confirmSignin({ confirmSigninRequest: values }, ({ init }) =>
                Promise.resolve({
                    ...init,
                    headers: {
                        ...init.headers,
                        Authorization: `Bearer ${token}`,
                    },
                })
            ),
        { showErrorNotification: false, onSuccess, onError: error => handleError({ error, form }) }
    )

    const handleFormFinish = (values: TwoFaVerificationFormData) => {
        send(values)
    }

    return (
        <>
            <Title level={5} style={titleStyles} data-merchant="auth-verify-2fa-title">
                {pageTitle}
            </Title>
            <Form
                requiredMark={false}
                style={getFormStyles(breakpoints)}
                form={form}
                size="large"
                layout="vertical"
                initialValues={formInitData}
                onFinish={handleFormFinish}
            >
                <TwoFaConfirmationItem
                    dataMerchant="signin-2fa-verify-input"
                    onToggle={toggleIsBackupCodeUsed}
                    hasFocusOnMount
                />
                <Form.Item noStyle>
                    <Button
                        data-merchant="signin-2fa-verify-submit-button"
                        htmlType="submit"
                        block
                        type="primary"
                        loading={isLoading}
                    >
                        <FormattedMessage {...globalLang.confirm} />
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}
