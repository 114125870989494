/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum Permission {
    SeeBalance = 'see_balance',
    SeePaymentHistory = 'see_payment_history',
    SeeTransactions = 'see_transactions',
    SeeDepositAddresses = 'see_deposit_addresses',
    CreatePayment = 'create_payment',
    CreateDepositAddress = 'create_deposit_address',
    SeeWithdrawals = 'see_withdrawals',
    SeeCryptoWithdrawalFlow = 'see_crypto_withdrawal_flow',
    CommitCryptoWithdrawals = 'commit_crypto_withdrawals',
    CancelCryptoWithdrawals = 'cancel_crypto_withdrawals',
    SeeFiatWithdrawalFlow = 'see_fiat_withdrawal_flow',
    CommitFiatWithdrawals = 'commit_fiat_withdrawals',
    SeeWithdrawalWhitelist = 'see_withdrawal_whitelist',
    EditWithdrawalWhitelist = 'edit_withdrawal_whitelist',
    SeeSwapFlow = 'see_swap_flow',
    CommitSwaps = 'commit_swaps',
    ChangeGeneralInfo = 'change_general_info',
    ChangeCurrencySettings = 'change_currency_settings',
    SeePaymentSettings = 'see_payment_settings',
    ChangePaymentSettingsFeesPayer = 'change_payment_settings_fees_payer',
    ChangePaymentSettingsRedirectLinks = 'change_payment_settings_redirect_links',
    ChangePaymentSettingsLossConfig = 'change_payment_settings_loss_config',
    SeeProjectTeamMembers = 'see_project_team_members',
    ChangeProjectTeamMembers = 'change_project_team_members',
    SeeProjectRoles = 'see_project_roles',
    ChangeProjectRoles = 'change_project_roles',
    SeeIntegration = 'see_integration',
    ChangeIntegration = 'change_integration',
    SendPaymentCallback = 'send_payment_callback',
    SendWithdrawalCallback = 'send_withdrawal_callback'
}


// @ts-ignore
export function PermissionFromJSON(json: any): Permission {
    return PermissionFromJSONTyped(json, false);
}

// @ts-ignore
export function PermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Permission {
    return json as Permission;
}

// @ts-ignore
export function PermissionToJSON(value?: Permission | null): any {
    return value as any;
}

