import { createGenericContext } from '@merchant/shared/utils'
import type { MessageDescriptor } from 'react-intl'

export interface Context {
    closeModal: () => void
    setShouldRevalidateDependentTables: (v: boolean) => void
    setTitle: (params: { text: MessageDescriptor; dataMerchant: string } | false) => void
    setOnBackClickHandler: (cb: (() => void) | undefined) => void
    setShowGoBack: (show: boolean) => void
}

const [useActionModalContext, ActionModalContextProvider] = createGenericContext<Context>()

export { useActionModalContext, ActionModalContextProvider }
