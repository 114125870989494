import { Typography } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { SettingsShowOptions, pageTitleMap, pages } from './const'
import { lang } from './lang'
import { General, Security } from './views'
import { QueryParams } from '~constants/routes'
import { useURLSearchNavigation } from '~hooks'

const { Title, Text } = Typography

const views = {
    [SettingsShowOptions.general]: <General />,
    [SettingsShowOptions.security]: <Security />,
} as const

const dataMerchantAttributesMap = {
    [SettingsShowOptions.general]: 'settings-general-title',
    [SettingsShowOptions.security]: 'settings-security-title',
}

export function SettingsPage() {
    const { page } = useURLSearchNavigation({ key: QueryParams.show, pages })
    const view = views[page]
    const dataMerchant = dataMerchantAttributesMap[page]

    const pageTitle = <FormattedMessage {...pageTitleMap[page]} />

    return (
        <>
            <Text style={{ opacity: 0.4 }} data-merchant="setting-page-title">
                <FormattedMessage {...lang.pageTitle} />
            </Text>
            <Title level={4} data-merchant={dataMerchant}>
                {pageTitle}
            </Title>
            {view}
        </>
    )
}
