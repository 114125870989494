import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    currency: {
        id: 'withdrawal-modal.typography.currency',
        defaultMessage: 'Currency',
    },
    amount: {
        id: 'withdrawal-modal.typography.amount',
        defaultMessage: 'Withdrawal amount',
    },
    withdrawalAmount: {
        id: 'withdrawal-modal.typography.withdrawal_Amount',
        defaultMessage: 'Withdrawal amount',
    },
    withdrawalFee: {
        id: 'withdrawal-modal.typography.withdrawal_fee',
        defaultMessage: 'Withdrawal fee ',
    },
    receiveAmount: {
        id: 'withdrawal-modal.typography.receive_amount',
        defaultMessage: 'Receive amount',
    },
    confirm: {
        id: 'withdrawal-summary-modal.button.confirm',
        defaultMessage: 'Withdraw',
    },
    notificationAddressCopied: {
        id: 'withdrawal-modal.notification.address_copied',
        defaultMessage: 'Address copied to clipboard',
    },
    noWithdrawalData: {
        id: 'withdrawal-modal.notification.no_withdrawal_data',
        defaultMessage: 'No withdrawal data',
    },
    decline: {
        id: 'withdrawal-modal.typography.decline',
        defaultMessage: 'Decline',
    },
})
