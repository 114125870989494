/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ActorAPIKey } from './ActorAPIKey';
import {
    ActorAPIKeyFromJSON,
    ActorAPIKeyFromJSONTyped,
    ActorAPIKeyToJSON,
} from './ActorAPIKey';
import type { ActorWeb } from './ActorWeb';
import {
    ActorWebFromJSON,
    ActorWebFromJSONTyped,
    ActorWebToJSON,
} from './ActorWeb';

/**
 * 
 * @export
 * @interface Actor
 */
export interface Actor {
    /**
     * 
     * @type {ActorWeb}
     * @memberof Actor
     */
    projectMember?: ActorWeb;
    /**
     * 
     * @type {ActorAPIKey}
     * @memberof Actor
     */
    apiKey?: ActorAPIKey;
    /**
     * 
     * @type {string}
     * @memberof Actor
     * @deprecated
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Actor
     * @deprecated
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Actor
     * @deprecated
     */
    email?: string;
}

/**
 * Check if a given object implements the Actor interface.
 */
// @ts-ignore
export function instanceOfActor(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

// @ts-ignore
export function ActorFromJSON(json: any): Actor {
    return ActorFromJSONTyped(json, false);
}

// @ts-ignore
export function ActorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Actor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectMember': !exists(json, 'project_member') ? undefined : ActorWebFromJSON(json['project_member']),
        'apiKey': !exists(json, 'api_key') ? undefined : ActorAPIKeyFromJSON(json['api_key']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
    };
}

// @ts-ignore
export function ActorToJSON(value?: Actor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'project_member': ActorWebToJSON(value.projectMember),
        'api_key': ActorAPIKeyToJSON(value.apiKey),
        'id': value.id,
        'name': value.name,
        'email': value.email,
    };
}

