import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { Flex, Tooltip, Typography } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import { type TransactionDepositDetails, TransactionDepositType } from '~api'

const { Text } = Typography

const noteTypeProps = {
    [TransactionDepositType.Unknown]: {
        label: lang.unknownDeposit,
        tooltip: lang.unknownDepositTooltip,
    },
    [TransactionDepositType.ExchangeDeposit]: {
        label: lang.exchangeDeposit,
        tooltip: lang.exchangeDepositTooltip,
    },
    [TransactionDepositType.RefundDeposit]: {
        label: lang.manualRefundDeposit,
        tooltip: lang.manualRefundDepositTooltip,
    },
}

interface Props {
    details: TransactionDepositDetails
}

export function DepositNotes({ details }: Props) {
    const { label, tooltip } = noteTypeProps[details.type]

    return (
        <Flex align="center" gap={4}>
            <Text data-merchant={null}>
                <FormattedMessage {...label} />
            </Text>
            <Tooltip
                data-merchant={`deposit-notes-${details.type}`}
                title={
                    <Text style={{ fontSize: 12 }} data-merchant={null}>
                        <FormattedMessage {...tooltip} />
                    </Text>
                }
            >
                <Flex>
                    <AppSvg name={assets.questionMark} color={token => token.colorTextPlaceholder} />
                </Flex>
            </Tooltip>
        </Flex>
    )
}
