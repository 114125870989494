import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import type { FormRule } from '@merchant/ui-kit/ant-design'

export const getRelativeLossRules = (isLossEnabled: boolean): FormRule[] => [
    {
        validator: (_, value = 0) => (isLossEnabled && value <= 0 ? Promise.reject() : Promise.resolve()),
        message: (
            <span data-merchant="underpayment-relative-min-value-error">
                <FormattedMessage {...lang.invalidMinValue} />
            </span>
        ),
    },
    {
        validator: (_, value = 0) => (isLossEnabled && value > 1 ? Promise.reject() : Promise.resolve()),
        message: (
            <span data-merchant="underpayment-relative-max-value-error">
                <FormattedMessage {...lang.invalidMaxValue} />
            </span>
        ),
    },
]
