import { ModalResponsive } from '@merchant/shared/components'
import { FormattedMessage } from 'react-intl'
import { ChangePassword } from './ChangePassword'
import { lang } from './lang'
import { withDirtyCheckingContextProvider } from '~hoc/withDirtyCheckingContextProvider'
import { useDirtyFormCheckerControls } from '~hooks/useDirtyFormCheckerControls'

interface Props {
    open: boolean
    close: () => void
}

function Component({ open, close }: Props) {
    const { handleCancel } = useDirtyFormCheckerControls(close)

    return (
        <ModalResponsive
            open={open}
            centered
            footer={null}
            title={
                <div data-merchant="change-password-title">
                    <FormattedMessage {...lang.modalTitle} />
                </div>
            }
            destroyOnClose
            onCancel={handleCancel}
            data-merchant="change-password-modal"
        >
            <ChangePassword onSuccess={close} />
        </ModalResponsive>
    )
}

export const ChangePasswordModal = withDirtyCheckingContextProvider(Component)
