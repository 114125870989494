import { theme } from '@merchant/ui-kit/ant-design'
import { FormattedMessage, type MessageDescriptor } from 'react-intl'
import { getNotificationSubject } from './utils'
import type { ButtonProps } from '@merchant/ui-kit/ant-design'
import { assets } from '~assets/nameMap'
import { InlineButton, AppSvg } from '~components'
import { globalLang } from '~globalLang'
import { useCopy } from '~hooks'

const { useToken } = theme

interface Props extends Omit<ButtonProps, 'type'> {
    inputValue: string | number
    showNotification?: boolean
    notificationContentSubject?: string | MessageDescriptor
    type?: Extract<ButtonProps['type'], 'text' | 'link'>
    'data-merchant': string
}

export function CopyButton({
    inputValue,
    notificationContentSubject,
    showNotification = true,
    onClick,
    type = 'link',
    styles,
    ...rest
}: Props) {
    const copy = useCopy(showNotification)
    const {
        token: { colorTextDescription },
    } = useToken()

    const iconColor = type === 'text' ? colorTextDescription : undefined

    return (
        <InlineButton
            size="small"
            type={type}
            styles={{ icon: { color: iconColor, ...styles?.icon } }}
            icon={
                <AppSvg
                    name={assets.copy}
                    size={{ height: '100%', width: '100%' }}
                    style={{ verticalAlign: 'middle' }}
                />
            }
            onClick={e => {
                e.stopPropagation()
                copy({
                    inputValue: String(inputValue),
                    notificationContent: notificationContentSubject && (
                        <FormattedMessage
                            {...globalLang.subjectCopiedToClipboard}
                            values={{
                                subject: getNotificationSubject(notificationContentSubject),
                            }}
                        />
                    ),
                })
                onClick?.(e)
            }}
            {...rest}
        />
    )
}
