import { Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import type { MessageDescriptor } from 'react-intl'
import { PageContentContainer } from '~components'

const { Text, Link } = Typography

interface Props {
    title: MessageDescriptor
    content: MessageDescriptor
    values?: Record<string, unknown>
}

export function StaticPageTemplate({ content, values, title }: Props) {
    return (
        <>
            <Text style={{ fontSize: 20, fontWeight: 500 }} data-merchant={null}>
                <FormattedMessage {...title} />
            </Text>
            <PageContentContainer>
                <FormattedMessage
                    {...content}
                    values={{
                        title: chunks => (
                            <Text style={{ fontSize: 16, marginBottom: 8 }} data-merchant={null}>
                                {chunks}
                            </Text>
                        ),
                        content: chunks => (
                            <Text type="secondary" style={{ marginBottom: 12 }} data-merchant={null}>
                                {chunks}
                            </Text>
                        ),
                        ol: chunks => <ol>{chunks}</ol>,
                        ul: chunks => <ul>{chunks}</ul>,
                        li: chunks => <li>{chunks}</li>,
                        break: () => <br />,
                        SupportLink: chunks => (
                            <Link href={`mailto:${chunks}`} data-merchant="support-link">
                                {chunks}
                            </Link>
                        ),
                        ...values,
                    }}
                />
            </PageContentContainer>
        </>
    )
}
