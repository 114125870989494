import { useBreakpoint } from '@merchant/shared/hooks'
import { isEmpty } from 'lodash-es'
import type { Breakpoint } from '@merchant/ui-kit/ant-design'
import type { ReactNode } from 'react'

const priority: Breakpoint[] = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl']

interface Props {
    defaultNode: ReactNode
    xl?: ReactNode
    lg?: ReactNode
    md?: ReactNode
    sm?: ReactNode
    xs?: ReactNode
}

export function ResponsiveRenderer(props: Props) {
    const { defaultNode, ...rest } = props
    const screens = { ...rest, xxl: defaultNode }
    const breakpoints = useBreakpoint()

    // useBreakpoint returns an empty object on initial render
    if (isEmpty(breakpoints)) {
        return null
    }

    const defaultKeyIndex = priority.findIndex((bp, index) => {
        const breakpoint = priority[index + 1]
        if (!breakpoint && !!screens[bp] && !breakpoints[priority[index + 1]!]) {
            return true
        }

        return !!screens[bp] && !breakpoints[priority[index + 1]!]
    })

    for (const [index, breakpoint] of priority.entries()) {
        if (breakpoints[breakpoint] && screens[breakpoint] && index > defaultKeyIndex) {
            return screens[breakpoint]
        }
    }

    const defaultScreen = screens.xs ?? null

    const defaultKeyIndexBreakpoint = priority[defaultKeyIndex]

    return defaultKeyIndex && defaultKeyIndexBreakpoint ? screens[defaultKeyIndexBreakpoint] : defaultScreen
}
