import type { Project, ProjectStatusUpdate } from '~api/instances/cabinet-api'

export enum MerchantSettingsShowOptions {
    general = 'general',
    assets = 'assets',
    paymentsSettings = 'payments-settings',
}

export interface MerchantSettingsPageOption {
    value: MerchantSettingsShowOptions
    disabled?: boolean
}

export type ProjectGeneralFormData = Pick<Project, 'name' | 'description' | 'link' | 'supportEmail'> & {
    status: ProjectStatusUpdate
}
