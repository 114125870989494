import type { SwapStatus } from '~api'

export enum PosthogConvertEvents {
    onConvertScreen = 'ms_lk_convert_screen',
    getConvertAmount = 'ms_lk_convert_amount',
    currencySearchNoMatch = 'ms_lk_convert_search_no_match',
    currencySelected = 'ms_lk_convert_currency_choose',
    convertSubmit = 'ms_lk_convert_enter',
    convertStatusChange = 'ms_lk_convert_status',
}

type CommonConvertEventData = {
    merchant_id: string
    project_id: string
}

type ConvertEventData = {
    currency_from: string
    amount_from: string
    currency_to: string
    amount_to: string
}

export interface ConvertEventDataMap {
    [PosthogConvertEvents.onConvertScreen]: CommonConvertEventData & { source: string }
    [PosthogConvertEvents.getConvertAmount]: CommonConvertEventData & ConvertEventData & { response: string }
    [PosthogConvertEvents.currencySearchNoMatch]: CommonConvertEventData & {
        side: 'currency_to' | 'currency_from'
        input: string
    }
    [PosthogConvertEvents.currencySelected]: CommonConvertEventData & {
        side: 'currency_to' | 'currency_from'
        input: string
    }
    [PosthogConvertEvents.convertSubmit]: CommonConvertEventData & ConvertEventData
    [PosthogConvertEvents.convertStatusChange]: CommonConvertEventData & ConvertEventData & { status: SwapStatus }
}
