import { contextActions } from './contextActions'
import { HttpError } from './httpError'
import { getIsNetworkError, initResponseData } from './httpError/utils'
import type { MiddlewareContext } from './types'

// FIXME: this needs to be fixed
type Middleware = {
    post: ({ response }: { response: Response }) => Promise<Response>
    onError: (context: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        fetch: any
        url: string
        init: RequestInit
        error: unknown
        response?: Response
    }) => Promise<Response>
}

export const getCommonMiddlewares = (context: MiddlewareContext): Middleware[] => [
    {
        async post({ response }) {
            if (!response.ok) {
                const responseData = await initResponseData(response)
                const err = new HttpError({ response, responseData })
                contextActions[context]?.handleError(err)

                return Promise.reject(err)
            }

            return Promise.resolve(response)
        },
        async onError({ error, response }) {
            let message: string | undefined,
                isNetworkError = false
            if (error instanceof TypeError || error instanceof Error) {
                message = error.message
                isNetworkError = getIsNetworkError(error)
            }

            const responseData = await initResponseData(response)
            const err = new HttpError({ response, message, isNetworkError, responseData })
            contextActions[context]?.handleError(err)

            return Promise.reject(err)
        },
    },
]
