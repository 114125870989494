import { cmsRestKeys } from '@merchant/shared/api/swr/keys'
import { onErrorRetry } from '@merchant/shared/api/swr/utils'
import { getIsDemo, initializeAppConfig } from '@merchant/shared/config'
import { getPosthogConfig } from '@merchant/shared/config/posthog'
import { initSentry } from '@merchant/shared/tools'
import Big from 'big.js'
import { PostHogProvider } from 'posthog-js/react'
import { createRoot } from 'react-dom/client'
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom'
import { SWRConfig } from 'swr'
import { routes as AppRoutes } from '~constants/routes'
import { appSettingsFallback } from '~constants/settings'
import { Providers } from '~features/App'

Big.PE = 40

if (getIsDemo()) {
    initializeAppConfig()
}

const mainRoutes = [
    {
        path: '/*',
        element: <Providers />,
    },
    {
        path: '*',
        element: <Navigate to={AppRoutes.orders} />,
    },
]

const container = document.getElementById('root')
const root = container && createRoot(container)
const loader = document.getElementById('loader-container')

async function bootstrapApplication() {
    const router = createBrowserRouter(mainRoutes)
    initSentry()
    loader?.remove()
    root?.render(
        <SWRConfig
            value={{
                fallback: {
                    [cmsRestKeys.getCommonSettings]: appSettingsFallback,
                },
                onErrorRetry,
                shouldRetryOnError(err) {
                    return [401, 403].includes(err.status)
                },
            }}
        >
            <PostHogProvider {...getPosthogConfig()}>
                <RouterProvider router={router} />
            </PostHogProvider>
        </SWRConfig>
    )
}

bootstrapApplication()
