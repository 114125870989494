import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg, StatisticFormattedNumber } from '@merchant/shared/components'
import { useBreakpoint } from '@merchant/shared/hooks'
import { shortenRate } from '@merchant/shared/utils'
import { Flex, theme, Tooltip, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang as commonLang } from '../../lang'
import { lang } from './lang'
import type { TransactionSwapDetails } from '~api/instances/cabinet-api'

const { Text } = Typography
const { useToken } = theme

function DesktopTransactionSwapDetails({ details }: Props) {
    const { token } = useToken()
    const { currencyFrom, currencyTo, amountFrom, amountTo, rate } = details

    const tooltipTitle = (
        <Flex gap={6} vertical data-merchant="transaction-additional-info-tooltip">
            <Flex justify="space-between">
                <Text type="secondary" data-merchant={null}>
                    <FormattedMessage {...lang.amountFrom} />
                </Text>
                &nbsp;
                <StatisticFormattedNumber value={amountFrom} suffix={currencyFrom} />
            </Flex>
            <Flex justify="space-between">
                <Text type="secondary" data-merchant={null}>
                    <FormattedMessage {...lang.amountTo} />
                </Text>
                &nbsp;
                <StatisticFormattedNumber value={amountTo} suffix={currencyTo} />
            </Flex>
        </Flex>
    )

    return (
        <Flex vertical>
            <Text data-merchant={null}>
                <FormattedMessage {...commonLang.internalExchange} />
            </Text>
            <Text data-merchant="swap-from-to-currencies">
                <FormattedMessage
                    {...lang.fromTo}
                    values={{
                        from: currencyFrom,
                        to: currencyTo,
                    }}
                />
            </Text>
            <Flex gap={4} align="center">
                <Text type="secondary" data-merchant="swap-rate">
                    <StatisticFormattedNumber value={1} suffix={currencyFrom} />
                    &nbsp;=&nbsp;
                    <StatisticFormattedNumber value={shortenRate(rate)} suffix={currencyTo} />
                </Text>
                <Tooltip
                    placement="bottom"
                    overlayStyle={{ minWidth: 221 }}
                    title={tooltipTitle}
                    data-merchant="transaction-swap-tooltip"
                >
                    <Flex>
                        <AppSvg
                            size={20}
                            name={assets.questionMark}
                            color={token.colorTextPlaceholder}
                            data-merchant="transaction-additional-info-hint"
                        />
                    </Flex>
                </Tooltip>
            </Flex>
        </Flex>
    )
}

function MobileTransactionSwapDetails({ details }: Props) {
    const { currencyFrom, currencyTo, amountFrom, amountTo, rate } = details

    return (
        <div style={{ marginTop: 4 }}>
            <Text data-merchant="swap-from-to-currencies">
                <FormattedMessage {...commonLang.internalExchange} />
                &nbsp;
                <FormattedMessage
                    {...lang.fromTo}
                    values={{
                        from: currencyFrom,
                        to: currencyTo,
                    }}
                />
                :
            </Text>
            <Flex vertical gap={8} style={{ marginTop: 4 }}>
                <Flex gap={4} justify="space-between">
                    <Text type="secondary" data-merchant={null}>
                        <FormattedMessage {...lang.rate} />
                    </Text>
                    <Text style={{ textAlign: 'right' }} data-merchant="swap-rate">
                        <StatisticFormattedNumber value={1} suffix={currencyFrom} />
                        &nbsp;=&nbsp;
                        <StatisticFormattedNumber value={shortenRate(rate)} suffix={currencyTo} />
                    </Text>
                </Flex>
                <Flex gap={4} justify="space-between">
                    <Text type="secondary" data-merchant={null}>
                        <FormattedMessage {...lang.amountFrom} />
                    </Text>
                    <Text style={{ textAlign: 'right' }} data-merchant="swap-from-amount">
                        <StatisticFormattedNumber value={amountFrom} suffix={currencyFrom} />
                    </Text>
                </Flex>
                <Flex gap={4} justify="space-between">
                    <Text type="secondary" data-merchant={null}>
                        <FormattedMessage {...lang.amountTo} />
                    </Text>
                    <Text style={{ textAlign: 'right' }} data-merchant="swap-to-amount">
                        <StatisticFormattedNumber value={amountTo} suffix={currencyTo} />
                    </Text>
                </Flex>
            </Flex>
        </div>
    )
}

interface Props {
    details: TransactionSwapDetails
}

export function TransactionSwapDetails({ details }: Props) {
    const breakpoints = useBreakpoint()

    if (breakpoints.md) {
        return <DesktopTransactionSwapDetails details={details} />
    } else {
        return <MobileTransactionSwapDetails details={details} />
    }
}
