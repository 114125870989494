import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    save: {
        id: 'settings-page.button.general_save',
        defaultMessage: 'Save',
    },
    timeZone: {
        id: 'settings-page.typography.general_timezone',
        defaultMessage: 'Time zone',
    },
    language: {
        id: 'settings-page.typography.general_language',
        defaultMessage: 'Language',
    },
    currency: {
        id: 'settings-page.typography.general_currency',
        defaultMessage: 'Currency',
    },
    name: {
        id: 'settings-page.typography.general_name',
        defaultMessage: 'Name',
    },
    email: {
        id: 'settings-page.typography.general_email',
        defaultMessage: 'Email',
    },
    ifYouWantChangeEmailText: {
        id: 'settings-page.typography.if_you_want_change_email_text',
        defaultMessage: 'if you want to change your email, please contact support',
    },
    languageTooltip: {
        id: 'settings-page.tooltip.general_language',
        defaultMessage:
            "Currently, only English is available. We're working on adding more languages later. If you have a language preference, please let your personal account manager or {support} team know.",
    },
})
