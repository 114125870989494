import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    privacyPolicyLink: {
        id: 'static-pages.terms-of-use.privacy_policy_link',
        defaultMessage: 'https://company.domain.com/privacy-policy',
    },
    websiteLink: {
        id: 'static-pages.terms-of-use.website-lint',
        defaultMessage: 'https://company.domain.com ',
    },
    termsOfUseTitle: {
        id: 'static-pages.terms-of-use.title',
        defaultMessage: 'Terms of Use',
    },
    termsOfUseContent: {
        id: 'static-pages.terms-of-use.content',
        defaultMessage: '-',
    },
})
