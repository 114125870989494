import { theme } from '@merchant/ui-kit/ant-design'
import cn from 'classnames'
import { memo } from 'react'
import { ReactSVG } from 'react-svg'
import { useAssetSource } from '../useAssetSrc'
import { getSizeStyleProps } from '../utils'
import styles from './styles.module.css'
import type { AssetSize } from '../types'
import type { GlobalToken } from '@merchant/ui-kit/ant-design'
import type { CSSProperties } from 'react'
import { StrapiThemeFolders } from '~constants'

const { useToken } = theme

interface Props {
    name: string
    themed?: boolean
    subPath?: string
    color?: string | ((token: GlobalToken) => string)
    size?: AssetSize
    style?: CSSProperties
    className?: string
    'data-merchant'?: string
}

export function Component({
    name,
    themed = false,
    subPath = 'assets',
    color,
    size,
    style,
    className,
    'data-merchant': dataMerchant,
    ...rest
}: Props) {
    const { token } = useToken()
    const src = useAssetSource({ name, themed, subPath, fallbackFolder: StrapiThemeFolders.universal })

    const sizeStyles = getSizeStyleProps(size, {
        '--app-svg-width': d => `${d.width}px`,
        '--app-svg-height': d => `${d.height}px`,
    })

    const svgColor = typeof color === 'function' ? color(token) : color

    return (
        <ReactSVG
            data-merchant={dataMerchant}
            src={src}
            className={cn(styles.container, className)}
            style={{ ...style, ...sizeStyles, color: svgColor }}
            {...rest}
        />
    )
}

export const AppSvg = memo(Component)
