import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    note: {
        id: 'conversion.note.text',
        defaultMessage:
            "<b>NOTE:</b> Right now you don't directly accumulate {currencyFromCode} {currencyFromName} in your account. It means that if you send {currencyFromCode} to this address it will be automatically <b>converted to {currencyToCode} {currencyToName}.</b> For details see <settingsLink>Currency settings</settingsLink>.",
    },
    modalConfirm: {
        id: 'conversion.note.modal.confirm',
        defaultMessage: 'Automatically conversion is ok',
    },
    modalCancel: {
        id: 'conversion.note.modal.cancel',
        defaultMessage: 'Cancel',
    },
    modalTitle: {
        id: 'conversion.note.modal.title',
        defaultMessage: "You don't directly accumulate {currencyCode}",
    },
})
