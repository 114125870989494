export const getObjectKeys = Object.keys as <T>(
    obj: T
) => (keyof T extends infer U ? (U extends string ? U : U extends number ? `${U}` : never) : never)[]

type Entries<T> = [keyof T, T[keyof T]][]

export const getEntries = <T extends object>(obj: T) => Object.entries(obj) as Entries<T>

export function isKeyOfObject<T extends object>(
    key?: string | number | symbol | null | undefined,
    obj?: T
): key is keyof T {
    if (!key || !obj) {
        return false
    }

    return key in obj
}

export const isValueOfObject = <T extends object>(value: unknown, obj: T): value is T[keyof T] =>
    Object.values(obj).includes(value)
