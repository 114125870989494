/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PaymentState {
    Created = 'CREATED',
    Canceled = 'CANCELED',
    Waiting = 'WAITING',
    Failed = 'FAILED',
    Completed = 'COMPLETED'
}


// @ts-ignore
export function PaymentStateFromJSON(json: any): PaymentState {
    return PaymentStateFromJSONTyped(json, false);
}

// @ts-ignore
export function PaymentStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentState {
    return json as PaymentState;
}

// @ts-ignore
export function PaymentStateToJSON(value?: PaymentState | null): any {
    return value as any;
}

