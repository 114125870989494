import { lang } from './lang'
import { RestrictionAlertType } from './types'
import { routes } from '~constants/routes'
import { MerchantSettingsShowOptions } from '~features/MerchantPage/components/MerchantSettings/types'
import { generateQuery } from '~utils'

export const messages = {
    [RestrictionAlertType.NoAccessToCurrencySettings]: lang.noAccessToCurrencySettings,
    [RestrictionAlertType.NotConfiguredCurrencySettings]: lang.notConfiguredCurrencySettings,
}

export const linkToCurrencySettings = `${routes.merchantSettings}${generateQuery({ show: MerchantSettingsShowOptions.assets })}`
