import { useBreakpoint } from '@merchant/shared/hooks'
import { checkIsDeviceMobile } from '@merchant/shared/utils'
import cn from 'classnames'
import { useLocation } from 'react-router-dom'
import styles from '../style.module.css'
import { getIsSiderCollapsed } from '../utils'
import { useMenuItems } from './useMenuItems'
import { useMenuKeys } from './useMenuKeys'
import { getIsOpenKeysHandled } from './utils'
import type { NavigationMenuProps } from './types'
import type { MenuProps } from '@merchant/ui-kit/ant-design'
import { useMerchant } from '~api'
import { useConditionalTabOpen } from '~hooks'

const INLINE_INDENT = 12

const getMenuStyles = (isCollapsed: boolean) => ({
    width: isCollapsed ? '100%' : `calc(100% + ${INLINE_INDENT * 2}px)`,
    marginLeft: isCollapsed ? 0 : Math.abs(INLINE_INDENT) * -1,
    border: 'none',
})

export function useNavigationMenu(usageContext: NavigationMenuProps['usageContext']) {
    const breakpoint = useBreakpoint()
    const location = useLocation()
    const { isDisabled: isMerchantDisabled } = useMerchant()
    const isLayoutSiderCollapsed = usageContext === 'layoutSider' && getIsSiderCollapsed(breakpoint)
    const menuItems = useMenuItems({
        isMerchantDisabled,
        isLayoutSiderCollapsed,
    })
    const { areDefaultsSet, expandedParent, selectedKey, openKeys, setOpenKeys } = useMenuKeys({ menuItems })
    const { handleConditionalTabOpen } = useConditionalTabOpen()

    const handleMenuItemClick: MenuProps['onClick'] = e => {
        handleConditionalTabOpen([({ origin }) => origin + e.key, e.key])(e.domEvent)
    }

    const isOpenKeyHandled = getIsOpenKeysHandled(isLayoutSiderCollapsed, usageContext)

    const menuProps: MenuProps = {
        triggerSubMenuAction: isLayoutSiderCollapsed && checkIsDeviceMobile() ? 'click' : 'hover',
        style: getMenuStyles(isLayoutSiderCollapsed),
        mode: 'inline',
        selectedKeys: [selectedKey || location.pathname],
        defaultOpenKeys: expandedParent && !isLayoutSiderCollapsed ? [expandedParent] : undefined,
        className: cn(styles.menu, {
            [styles.collapsedMenu!]: isLayoutSiderCollapsed,
        }),
        defaultSelectedKeys: [location.pathname],
        items: menuItems,
        onClick: handleMenuItemClick,
        inlineIndent: INLINE_INDENT,
        openKeys: isOpenKeyHandled ? undefined : openKeys,
        onOpenChange: isOpenKeyHandled ? undefined : setOpenKeys,
        'data-merchant': 'navigation-menu',
    }

    return { areDefaultsSet, menuProps }
}
