import type { AppSettings } from '@merchant/shared/api/instances/host-api/types'
import { DEFAULT_CONVERSION_CURRENCY_FALLBACK } from '~config/const'

export const appSettingsFallback: AppSettings = {
    defaultConversionCurrency: DEFAULT_CONVERSION_CURRENCY_FALLBACK,
    lastMediaLibraryUpdateTimestamp: Date.now(),
    networksOrder: {},
    hiddenNetworks: {},
}

export const maxProjectNameCharsInCSV = 50
