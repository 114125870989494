import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    applyButton: {
        id: 'balances-snapshots.button.apply',
        defaultMessage: 'Apply',
    },
    exportCSVButton: {
        id: 'balances-snapshots.button.export_csv',
        defaultMessage: 'Export .csv',
    },
    dateLabel: {
        id: 'balances-snapshots.form.date_label',
        defaultMessage: 'Date:',
    },
    realtimeDate: {
        id: 'balances-snapshots.form.realtime_date',
        defaultMessage: 'Realtime',
    },
    customDate: {
        id: 'balances-snapshots.form.custom_date',
        defaultMessage: 'Custom date',
    },
    currencySelectPlaceholder: {
        id: 'balances-snapshots.form.currency_select_placeholder',
        defaultMessage: 'All currencies',
    },
    showZeroBalances: {
        id: 'balances-snapshots.form.show_zero_balances',
        defaultMessage: 'Show zero balances',
    },
    hideZeroBalances: {
        id: 'balances-snapshots.form.hide_zero_balances',
        defaultMessage: 'Hide zero balances',
    },
})
