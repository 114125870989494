import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg, ModalResponsive } from '@merchant/shared/components'
import { useBreakpoint, useDebouncedState } from '@merchant/shared/hooks'
import { getJoined } from '@merchant/shared/utils'
import { Checkbox, Col, Input, Row, Space, Tooltip, Typography } from '@merchant/ui-kit/ant-design'
import { join, reduce, slice } from 'lodash-es'
import React, { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { EditCurrenciesTable } from './EditCurrenciesTable'
import { lang } from './lang'
import { ModalFooter } from './ModalFooter'
import { cloneReactNodeWithDataAttributes } from './utils'
import type { CurrencyExtended } from './../types'
import type { CheckboxChangeEvent } from '@merchant/ui-kit/ant-design/es/checkbox'
import type { Currencies } from '~api/types'
import { WithdrawalNotPermittedAlert } from '~components'
import { ModalNames } from '~constants/modal'
import { withDirtyCheckingContextProvider } from '~hoc/withDirtyCheckingContextProvider'
import { useRegisterDirtyFormChecker, useDirtyFormCheckerControls } from '~hooks'

const { Text } = Typography

function WithdrawalNotPermittedMessage({ data }: { data: string[] }) {
    const isEllipsis = data.length >= 4

    if (isEllipsis) {
        const beginningSlice = slice(data, 0, 2)
        const endingSlice = slice(data, 2)
        const joinedBeginning = join(beginningSlice, ', ')

        return (
            <FormattedMessage
                {...lang.supportedCurrenciesLongMessage}
                values={{
                    tag: chunks => <strong>{chunks}</strong>,
                    Currencies: joinedBeginning,
                    ExceedingPart: (
                        <Tooltip
                            title={join(endingSlice, ', ')}
                            placement="bottom"
                            data-merchant="supported-currencies-text-tooltip"
                        >
                            <Text data-merchant="supported-currencies-text" type="success">
                                {' '}
                                {endingSlice.length} more
                            </Text>
                        </Tooltip>
                    ),
                }}
            />
        )
    } else {
        return (
            <FormattedMessage
                {...lang.supportedCurrenciesMessage}
                values={{
                    tag: chunks => <strong>{chunks}</strong>,
                    Currencies: getJoined(data, ', ', ' and '),
                }}
            />
        )
    }
}

interface Props {
    currencies: CurrencyExtended[]
    currenciesMap?: Currencies
    defaultConvertCurrency: string
    committedSelectedCurrencies: CurrencyExtended[]
    onSave: (list: CurrencyExtended[]) => Promise<void>
    isSaveLoading: boolean
    open: boolean
    onClose: () => void
    onRestoreDefault: () => void
}

// eslint-disable-next-line max-lines-per-function
function Component({
    open,
    onClose,
    onSave,
    currencies,
    currenciesMap,
    defaultConvertCurrency,
    committedSelectedCurrencies = [],
    onRestoreDefault,
}: Props) {
    const intl = useIntl()
    const breakpoints = useBreakpoint()
    const [modalSearchValue, setModalSearchValue] = useDebouncedState('')
    const [selectedCurrenciesTemp, setSelectedCurrenciesTemp] =
        useState<CurrencyExtended[]>(committedSelectedCurrencies)

    const { handleCancel } = useDirtyFormCheckerControls(onClose)

    const memoizedCurrenciesState = useMemo(() => {
        return {
            initial: committedSelectedCurrencies,
            current: selectedCurrenciesTemp,
        }
    }, [committedSelectedCurrencies, selectedCurrenciesTemp])

    useRegisterDirtyFormChecker(ModalNames.merchantCurrenciesSettings, memoizedCurrenciesState)

    useEffect(() => {
        setSelectedCurrenciesTemp(committedSelectedCurrencies)
    }, [committedSelectedCurrencies])

    const selectedCurrencies = useMemo(
        () =>
            currencies.reduce((acc, curr) => {
                if (curr.selected) {
                    acc.push(curr)
                }

                return acc
            }, [] as CurrencyExtended[]),
        [currencies]
    )

    const sortedCurrencies = useMemo(() => {
        let defaultCurrency: CurrencyExtended | undefined
        const { notSelected, selected } = reduce(
            currencies,
            (acc, curr) => {
                const isSelected = committedSelectedCurrencies.find(c => c.code === curr.code)
                if (curr.code === defaultConvertCurrency) {
                    defaultCurrency = curr
                } else if (isSelected) {
                    acc.selected.push(curr)
                } else {
                    acc.notSelected.push(curr)
                }

                return acc
            },
            { selected: [], notSelected: [] } as {
                selected: CurrencyExtended[]
                notSelected: CurrencyExtended[]
            }
        )

        const mergedSorted = [...selected, ...notSelected]

        if (defaultCurrency) {
            mergedSorted.unshift(defaultCurrency)
        }

        return mergedSorted
    }, [currencies, defaultConvertCurrency, committedSelectedCurrencies])

    const afterClose = () => {
        setModalSearchValue('')
        setSelectedCurrenciesTemp(committedSelectedCurrencies)
    }

    const filteredCurrencies = useMemo(() => {
        return sortedCurrencies.filter(
            ({ code, name, crypto }) =>
                `${code} ${name || ''}`.toLowerCase().includes(modalSearchValue.toLowerCase().trim()) && crypto
        )
    }, [sortedCurrencies, modalSearchValue])

    const handleCheckAllChange = (event: CheckboxChangeEvent) => {
        const defaultCurrency = currencies.find(currency => currency.code === defaultConvertCurrency)
        const fallback = defaultCurrency ? [defaultCurrency] : []
        const newSelectedCurrenciesValue = event.target.checked ? filteredCurrencies : fallback
        setSelectedCurrenciesTemp(newSelectedCurrenciesValue)
    }

    const handleSaveConfirm = async () => {
        await onSave(selectedCurrenciesTemp)
        setModalSearchValue('')
    }

    const areAllCurrenciesChecked =
        selectedCurrenciesTemp.filter(c => currenciesMap?.[c.code]?.crypto).length === filteredCurrencies.length

    const searchInputPlaceholder = intl.formatMessage(lang.searchInputPlaceholder)

    return (
        <ModalResponsive
            onCancel={handleCancel}
            afterClose={afterClose}
            destroyOnClose
            styles={{ content: { padding: !breakpoints.sm ? 12 : undefined } }}
            modalRender={node =>
                cloneReactNodeWithDataAttributes(node, {
                    'data-merchant': 'edit-holding-currencies-modal',
                })
            }
            title={
                <span data-merchant="edit-holding-currencies-modal-title">
                    <FormattedMessage {...lang.editCurrenciesModalTitle} />
                </span>
            }
            footer={
                <ModalFooter
                    onRestoreDefault={onRestoreDefault}
                    onSaveConfirm={handleSaveConfirm}
                    selectedCurrencies={selectedCurrencies}
                    currencies={filteredCurrencies}
                    selectedUncommittedCurrencies={selectedCurrenciesTemp}
                    setSelectedCurrencies={setSelectedCurrenciesTemp}
                    closeModal={onClose}
                />
            }
            open={open}
            data-merchant="edit-holding-currencies-modal"
        >
            <Space direction="vertical" size={12}>
                <Row justify="space-between" align="middle" style={{ paddingRight: 20 }}>
                    <Col>
                        <Input
                            data-merchant="currencies-search-input"
                            allowClear
                            onChange={e => setModalSearchValue(e.target.value)}
                            prefix={
                                <Text
                                    data-merchant="currencies-search-input-prefix-icon"
                                    type="secondary"
                                    style={{ display: 'flex' }}
                                >
                                    <AppSvg size={16} name={assets.searchSm} />
                                </Text>
                            }
                            style={{ width: breakpoints.sm ? 222 : 174 }}
                            placeholder={searchInputPlaceholder}
                        />
                    </Col>
                    <Col>
                        <Checkbox
                            data-merchant="currencies-check-all"
                            style={{ flexDirection: 'row-reverse' }}
                            checked={areAllCurrenciesChecked}
                            onChange={handleCheckAllChange}
                        >
                            <Text data-merchant="currencies-check-all-text" type="secondary">
                                <FormattedMessage {...lang.checkAll} />
                            </Text>
                        </Checkbox>
                    </Col>
                </Row>
                <EditCurrenciesTable
                    defaultConvertCurrency={defaultConvertCurrency}
                    currencies={filteredCurrencies}
                    selectedCurrencies={selectedCurrenciesTemp}
                    setSelectedCurrencies={setSelectedCurrenciesTemp}
                />
                {currenciesMap && (
                    <WithdrawalNotPermittedAlert
                        currencies={currenciesMap}
                        selectedCurrencies={selectedCurrenciesTemp.map(c => c.code)}
                        renderMessage={({ getEnabledCurrenciesCode }) => (
                            <WithdrawalNotPermittedMessage data={getEnabledCurrenciesCode()} />
                        )}
                        data-merchant="withdrawal-not-permitted-alert"
                    />
                )}
            </Space>
        </ModalResponsive>
    )
}

export const EditCurrenciesModal = withDirtyCheckingContextProvider(Component)
