import { EmailSupportLink } from '@merchant/shared/components'
import { useColorMode } from '@merchant/shared/hooks'
import { Card, Space, Timeline, Typography, theme } from '@merchant/ui-kit/ant-design'
import { isEmpty } from 'lodash-es'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import { CreditedFunds } from './OrderTimeline/CreditedFunds'
import { Payin } from './OrderTimeline/Payin'
import { PaymentMethod } from './OrderTimeline/PaymentMethod'
import { TimelineSummary } from './OrderTimeline/TimelineSummary'
import { TimelineSkeletons } from './TimelineSkeletons'
import type { GlobalToken, TimelineItemProps } from '@merchant/ui-kit/ant-design'
import type { Payment } from '~api/instances/cabinet-api'
import { PaymentState } from '~api/instances/cabinet-api'

const { useToken } = theme
const { Title, Text } = Typography

const getActiveCircleColor = (token: GlobalToken, isNight: boolean | undefined) =>
    isNight ? token.colorWhite : token.colorTextBase

const unsuccessfulOrderTimelines = {
    [PaymentState.Canceled]: (token: GlobalToken) => ({
        color: token.colorTextPlaceholder,
        children: (
            <Space direction="vertical">
                <Text type="secondary" data-merchant={null}>
                    <FormattedMessage {...lang.orderCancelled} />
                </Text>
                {/* TODO: move somewhere depending on the description */}
                <Text type="secondary" style={{ opacity: 0.5 }} data-merchant={null}>
                    <FormattedMessage {...lang.orderCancellationDescription} />
                </Text>
            </Space>
        ),
        'data-merchant': 'timeline-order-cancelled',
    }),
    [PaymentState.Failed]: (token: GlobalToken) => ({
        color: token.colorError,
        children: (
            <Space direction="vertical">
                <Text type="secondary" data-merchant={null}>
                    <FormattedMessage {...lang.orderFailed} />
                </Text>
                <Text type="secondary" style={{ opacity: 0.5 }} data-merchant={null}>
                    <FormattedMessage
                        {...lang.orderFailureDescription}
                        values={{
                            tag: chunks => <EmailSupportLink>{chunks}</EmailSupportLink>,
                        }}
                    />
                </Text>
            </Space>
        ),
        'data-merchant': 'timeline-order-failed',
    }),
}

const getPayins = (data: Payment, token: GlobalToken, isNight: boolean | undefined) => {
    if (isEmpty(data.selectedPaymentMethod?.payins)) {
        return [
            {
                canBeDropped: true,
                color:
                    // eslint-disable-next-line no-nested-ternary
                    data.selectedPaymentMethod && data.state === PaymentState.Completed
                        ? token.colorSuccess
                        : data.selectedPaymentMethod
                          ? getActiveCircleColor(token, isNight)
                          : token.colorTextPlaceholder,
                children: (
                    <Space direction="vertical" size={16} style={{ width: '100%' }}>
                        <Text data-merchant={null}>
                            <FormattedMessage {...lang.fundsFromClient} />{' '}
                        </Text>
                    </Space>
                ),
                'data-merchant': 'timeline-payin-empty',
            },
        ]
    } else {
        return data.selectedPaymentMethod?.payins?.map((payin, index) => {
            return {
                canBeDropped: false,
                color: token.colorSuccess,
                children: (
                    <Payin
                        data={payin}
                        isOnlyPayin={data.selectedPaymentMethod?.payins?.length === 1}
                        payinOrdinalNumber={index + 1}
                        feesPayer={data.feesPayer}
                    />
                ),
                'data-merchant': `timeline-payin-${payin.txnHash}`,
            }
        })
    }
}

const getTimeline = ({
    data,
    themeToken,
    isDark,
    addressTagName,
}: {
    data: Payment
    themeToken: GlobalToken
    isDark: boolean | undefined
    addressTagName?: string
}) => {
    const timeline = [
        {
            canBeDropped: false,
            color: themeToken.colorSuccess,
            children: <TimelineSummary data={data} />,
            'data-merchant': 'timeline-summary',
        },
        {
            canBeDropped: !data.selectedPaymentMethod,
            color: !data.selectedPaymentMethod ? getActiveCircleColor(themeToken, isDark) : themeToken.colorSuccess,
            children: <PaymentMethod data={data} addressTagName={addressTagName} />,
            'data-merchant': 'timeline-payment-method',
        },
        ...(getPayins(data, themeToken, isDark) || []),
        {
            canBeDropped: data.state !== PaymentState.Completed,
            color: data.state === PaymentState.Completed ? themeToken.colorSuccess : themeToken.colorTextPlaceholder,
            children: <CreditedFunds data={data} />,
            'data-merchant': 'timeline-credited-funds',
        },
    ]

    if (data.state === PaymentState.Canceled || data.state === PaymentState.Failed) {
        return [
            ...timeline.reduce((acc, curr) => {
                if (curr.canBeDropped) {
                    return acc
                }
                acc.push({
                    color: curr.color,
                    children: curr.children,
                    'data-merchant': curr['data-merchant'],
                })

                return acc
            }, [] as TimelineItemProps[]),
            unsuccessfulOrderTimelines[data.state](themeToken),
        ]
    }

    return timeline.map(({ color, children, ...rest }) => ({ color, children, 'data-merchant': rest['data-merchant'] }))
}

interface Props {
    loading: boolean
    data?: Payment
    addressTagName?: string
}

export function OrderTimelineCard({ loading, data, addressTagName }: Props) {
    const { isDark } = useColorMode()
    const { token } = useToken()

    const isLoadingData = loading || !data

    return (
        <Card
            styles={{ body: { padding: '16px 20px 20px' } }}
            style={{ height: '100%' }}
            data-merchant="order-timeline-card"
        >
            <Space direction="vertical" size={16} style={{ width: '100%' }}>
                <Title level={5} data-merchant={null}>
                    <FormattedMessage {...lang.updatesTimeline} />
                </Title>
                {isLoadingData ? (
                    <TimelineSkeletons />
                ) : (
                    <Timeline
                        data-merchant="order-timeline"
                        items={getTimeline({
                            data,
                            themeToken: token,
                            isDark,
                            addressTagName,
                        })}
                    />
                )}
            </Space>
        </Card>
    )
}
