import type { AssetSize } from './types'
import type { CSSProperties } from 'react'

type SizeTuple = CSSProperties['width'] | CSSProperties['height']

type GetSizeStyleProps = (
    size?: AssetSize,
    customPropertyGetters?: Record<string, (dimensions: { width: SizeTuple; height: SizeTuple }) => SizeTuple>
) => CSSProperties

export const getSizeStyleProps: GetSizeStyleProps = (size, customPropertyGetters) => {
    const width = typeof size === 'object' ? size.width : size
    const height = typeof size === 'object' ? size.height : size

    const dimensions = {
        width,
        height,
    }

    if (customPropertyGetters && size) {
        const additionalStyles: Record<string, SizeTuple> = {}
        Object.entries(customPropertyGetters).forEach(([key, fn]) => {
            additionalStyles[key] = fn(dimensions)
        })

        return { ...dimensions, ...additionalStyles }
    }

    return dimensions
}
