import { ModalResponsive } from '@merchant/shared/components'
import { usePromise } from '@merchant/shared/hooks'
import { FormattedMessage, useIntl } from 'react-intl'
import { dataMerchantPrefix } from './const'
import { lang } from './lang'
import type { AwaitedReturnType } from '@merchant/shared/api/types'
import type { ModalProps } from '@merchant/ui-kit/ant-design'
import type { AddProjectTeamMembersRequest, TOTPCredentials } from '~api/instances/cabinet-api'
import { projectApi, useProject } from '~api'
import { ConfirmationForm } from '~features/MerchantPage/components/TeamManagement/components'
import { lang as teamManagementLang } from '~features/MerchantPage/components/TeamManagement/lang'
import { withDirtyCheckingContextProvider } from '~hoc/withDirtyCheckingContextProvider'
import { useDirtyFormCheckerControls } from '~hooks'

type TOTPVerification = TOTPCredentials & { verificationCode: string }

interface Props extends Omit<ModalProps, 'data-merchant'> {
    onSuccess: (newMembers: AwaitedReturnType<typeof projectApi.addProjectTeamMembers>) => void
    getNewTeamMembers: () => Pick<AddProjectTeamMembersRequest, 'teamMembers'>
}

function Component({ onSuccess, getNewTeamMembers, onCancel, ...props }: Props) {
    const { data: project } = useProject()
    const intl = useIntl()
    const { handleCancel, skipCheckAndCancel } = useDirtyFormCheckerControls(onCancel)
    const { send: request } = usePromise(
        (params: TOTPVerification) => {
            if (!project?.id) {
                throw new Error(intl.formatMessage(teamManagementLang.projectIdNotDefined))
            }

            return projectApi.addProjectTeamMembers({
                projectId: project.id,
                addProjectTeamMembersRequest: {
                    ...params,
                    verificationCode: params.verificationCode,
                    ...getNewTeamMembers(),
                },
            })
        },
        {
            onSuccess: newMembers => {
                skipCheckAndCancel()
                onSuccess(newMembers)
            },
            onError: error => {
                throw error
            },
            showErrorNotification: false,
        }
    )

    return (
        <ModalResponsive
            destroyOnClose
            onCancel={handleCancel}
            data-merchant={dataMerchantPrefix}
            title={
                <span data-merchant={`${dataMerchantPrefix}-title`}>
                    <FormattedMessage {...lang.modalTitle} />
                </span>
            }
            footer={null}
            {...props}
        >
            <ConfirmationForm mode="add" request={request} />
        </ModalResponsive>
    )
}

export const AddMembersConfirmModal = withDirtyCheckingContextProvider(Component)
