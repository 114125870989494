import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import type { FormRule } from '@merchant/ui-kit/ant-design'
import { globalLang } from '~globalLang'

const MAX_LENGTH = 36

export const getAbsoluteLossInputRules = (isAbsoluteCheckboxEnabled: boolean): FormRule[] => [
    {
        validator: (_, value = 0) => (isAbsoluteCheckboxEnabled && value <= 0 ? Promise.reject() : Promise.resolve()),
        required: isAbsoluteCheckboxEnabled,
        message: (
            <span data-merchant="underpayment-absolute-min-value-error">
                <FormattedMessage {...lang.invalidAbsoluteMinValue} />
            </span>
        ),
    },
    {
        max: MAX_LENGTH,
        message: (
            <span data-merchant="underpayment-absolute-max-value-error">
                <FormattedMessage {...globalLang.maximumAllowedLengthExceeded} values={{ maxLength: MAX_LENGTH }} />
            </span>
        ),
    },
]
