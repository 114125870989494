import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    modalTitle: {
        id: 'integrations.api-key-edit-modal.title',
        defaultMessage: 'Edit API Key',
    },
    twoFaConfirmButtonTitle: {
        id: 'integrations.api-key-edit-modal.two_fa_confirm_button_title',
        defaultMessage: 'Confirm',
    },
    save: {
        id: 'integrations.api-key-edit-modal.save',
        defaultMessage: 'Save',
    },
    deleteApiKey: {
        id: 'integrations.api-key-edit-modal.delete_api_key',
        defaultMessage: 'Delete API Key',
    },
    titleLabel: {
        id: 'integrations.api-key-edit-modal.edit_title_label',
        defaultMessage: 'Title',
    },
    titlePlaceholder: {
        id: 'integrations.api-key-edit-modal.edit_title_placeholder',
        defaultMessage: 'Title',
    },
    publicKeyLabel: {
        id: 'integrations.api-key-edit-modal.edit_public_key_label',
        defaultMessage: 'Public key',
    },
    publicKeyPlaceholder: {
        id: 'integrations.api-key-edit-modal.edit_public_key_placeholder',
        defaultMessage: 'Public key',
    },
    privateKeyLabel: {
        id: 'integrations.api-key-edit-modal.edit_private_key_label',
        defaultMessage: 'Private key',
    },
})
