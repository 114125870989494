import { ModalResponsive } from '@merchant/shared/components'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import type { ModalProps } from '@merchant/ui-kit/ant-design'
import type { Payment } from '~api/instances/cabinet-api'
import { globalLang } from '~globalLang'

interface Props extends ModalProps {
    data?: Payment['details']['otherData']
}

export function MetaInfoModal({ data, onCancel, ...rest }: Props) {
    return (
        <ModalResponsive
            onCancel={onCancel}
            title={
                <span data-merchant="meta-info-modal-title">
                    <FormattedMessage {...lang.metaInfo} />
                </span>
            }
            cancelButtonProps={{
                block: true,
                size: 'large',
                type: 'primary',
                'data-merchant': 'meta-info-modal-ok-button',
            }}
            cancelText={<FormattedMessage {...globalLang.ok} />}
            footer={(_, { CancelBtn }) => <CancelBtn />}
            {...rest}
        >
            <pre data-merchant="meta-info-modal-content" style={{ width: 455, maxHeight: 420, overflow: 'scroll' }}>
                {JSON.stringify(data, null, 2)}
            </pre>
        </ModalResponsive>
    )
}
