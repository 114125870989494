export const DEFAULT_NUMBER_FORMAT_OPTIONS: Intl.NumberFormatOptions = {
    useGrouping: true,
    style: 'decimal',
    maximumFractionDigits: 18,
}
export const DEFAULT_NUMBER_FORMAT_LOCALES = 'en'

export const defaultIntlNumberFormat = new Intl.NumberFormat(
    DEFAULT_NUMBER_FORMAT_LOCALES,
    DEFAULT_NUMBER_FORMAT_OPTIONS
)
