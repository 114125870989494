import { defineMessages } from 'react-intl'

export const globalLang = defineMessages({
    subjectCopiedToClipboard: {
        id: 'global.notification.subject_is_copied_to_clipboard',
        defaultMessage: '{subject} is copied to clipboard',
    },
    hash: {
        id: 'global.typography.hash',
        defaultMessage: 'Hash',
    },
    somethingWentWrong: {
        id: 'global.form.something_went_wrong',
        defaultMessage: 'Something went wrong',
    },
    tryAgainLater: {
        id: 'global.form.try_again_later',
        defaultMessage: 'Please try again later or contact {SupportLink}',
    },
    colorMode: {
        id: 'global.theme.color_mode',
        defaultMessage: 'Color mode',
    },
    close: {
        id: 'global.button.close',
        defaultMessage: 'Close',
    },
    requiredFieldMessage: {
        id: 'global.form.required_field_message',
        defaultMessage: 'Field is required',
    },
    invalidEmailFormat: {
        id: 'global.input.invalid_email_format_message',
        defaultMessage: 'Invalid email format',
    },
    invalidUrlFormat: {
        id: 'global.input.invalid_url_format_message',
        defaultMessage: 'Invalid URL format',
    },
    merchantSupportEmail: {
        id: 'global.typography.merchant_support_email',
        defaultMessage: 'support@company.domain.com',
    },
    support: {
        id: 'global.typography.support',
        defaultMessage: 'support',
    },
})
