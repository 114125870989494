import { HttpError } from '@merchant/shared/api'
import { usePromise } from '@merchant/shared/hooks'
import { useState } from 'react'
import { mutate, useSWRConfig } from 'swr'
import { controllers } from '../../abortControllers'
import { AddressCard } from '../components'
import type { NewDepositAddressState } from '../../types'
import type { CreateProjectAddressRequest, ProjectAddress } from '~api'
import { PaymentSwapConfig, projectApi, useProject } from '~api'
import { apiRestKeys } from '~api/swr/keys'

export function NewAddressCard() {
    const { cache } = useSWRConfig()
    const [state, setState] = useState<NewDepositAddressState>(null)
    const [address, setAddress] = useState<ProjectAddress | undefined>(undefined)
    const { data: project } = useProject()
    const projectId = project?.id || ''

    const {
        send: createAddress,
        isLoading: isLoadingCreateAddress,
        error: createAddressError,
    } = usePromise(async (payload: CreateProjectAddressRequest) => {
        setAddress(undefined)
        controllers.createDepositAddressController.abort()
        controllers.createDepositAddressController = new AbortController()
        try {
            const response = await projectApi.createProjectAddress(
                { projectId, createProjectAddressRequest: { ...payload, swap: PaymentSwapConfig.No } },
                { signal: controllers.createDepositAddressController.signal }
            )

            setAddress(response)
            const key = [...cache.keys()].find(key => key.includes(apiRestKeys.getDepositAddresses))
            mutate(key)
        } catch (error) {
            if (HttpError.isInstance(error) && !error.message.includes('signal is aborted without reason')) {
                throw error
            } else {
                /**swallow */
            }
        }
    })

    const handleChange = (values: NewDepositAddressState) => {
        controllers.createDepositAddressController.abort()
        setAddress(undefined)
        setState(values)
        if (values?.currency) {
            createAddress({ currency: values.currency, network: values.network })
        }
    }

    return (
        <AddressCard
            address={address}
            isLoading={isLoadingCreateAddress}
            onChange={handleChange}
            state={state}
            context="new"
            error={createAddressError}
        />
    )
}
