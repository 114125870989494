import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    orders: {
        id: 'navigation-menu.item.orders',
        defaultMessage: 'Orders',
    },
    balances: {
        id: 'navigation-menu.item.balances',
        defaultMessage: 'Balances',
    },
    transactions: {
        id: 'navigation-menu.item.transactions',
        defaultMessage: 'Transactions',
    },
    addresses: {
        id: 'navigation-menu.item.addresses',
        defaultMessage: 'Addresses',
    },
    addressesParent: {
        id: 'navigation-menu.item.addresses',
        defaultMessage: 'Addresses',
    },
    staticAddresses: {
        id: 'navigation-menu.item.static_addresses',
        defaultMessage: 'Static addresses',
    },
    depositAddresses: {
        id: 'navigation-menu.item.deposit_addresses',
        defaultMessage: 'Deposit addresses',
    },
    whitelist: {
        id: 'navigation-menu.item.whitelist',
        defaultMessage: 'Withdrawal whitelist',
    },
    integrations: {
        id: 'navigation-menu.item.integrations',
        defaultMessage: 'Integrations',
    },
    withdrawals: {
        id: 'navigation-menu.item.withdrawals',
        defaultMessage: 'Withdrawals',
    },
    withdrawalsHistory: {
        id: 'navigation-menu.item.withdrawals_history',
        defaultMessage: 'History',
    },
    withdrawalsWhitelist: {
        id: 'navigation-menu.item.withdrawals_whitelist',
        defaultMessage: 'Withdrawal whitelist',
    },
    disabledCurrencySettingsNoMerchant: {
        id: 'navigation-menu.item.disabled_currency_settings',
        defaultMessage: 'To change currency settings, please first create a Merchant',
    },
    disabledPaymentsSettingsNoMerchant: {
        id: 'navigation-menu.item.disabled_payments_settings_no_merchant',
        defaultMessage: 'To change payments settings, please first create a Merchant',
    },
    disabledPaymentsSettingsNoHoldingCurrency: {
        id: 'navigation-menu.item.disabled_payments_settings_no_holding_currency',
        defaultMessage: 'To change payments settings, please first set up your currencies',
    },

    disabledTeamManagementNoMerchant: {
        id: 'navigation-menu.item.disabled_team_management_no_merchant',
        defaultMessage: 'To change your team, please first create a Merchant',
    },
    disabledTeamManagementNoHoldingCurrency: {
        id: 'navigation-menu.item.disabled_team_management_no_holding_currency',
        defaultMessage: 'To change your team, please first set up your currencies',
    },
    ordersHistory: {
        id: 'navigation-menu.item.orders_history',
        defaultMessage: 'History',
    },
    createOrder: {
        id: 'navigation-menu.item.create_new_order',
        defaultMessage: 'Create new order',
    },
    support: {
        id: 'navigation-menu.item.support',
        defaultMessage: 'Support',
    },
    documentation: {
        id: 'navigation-menu.item.documentation',
        defaultMessage: 'Documentation',
    },
})
