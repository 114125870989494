import { Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'

const { Text, Title } = Typography

export function PageTitle() {
    return (
        <>
            <Text style={{ opacity: 0.4 }} data-merchant={null}>
                <FormattedMessage {...lang.ordersPageTitle} />
            </Text>
            <Title level={4} data-merchant="constructor-title">
                <FormattedMessage {...lang.createNewOrder} />
            </Title>
        </>
    )
}
