import { Flex, Layout, Typography, theme } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { lang } from './lang'
import styles from './style.module.css'
import { routes as AppRoutes } from '~constants/routes'

const { useToken } = theme
const { Footer: AntdFooter } = Layout
const { Link, Text } = Typography

const footerStyles: React.CSSProperties = { marginTop: 30 }

const currentYear = new Date().getFullYear()

export function Footer() {
    const {
        token: { colorTextPlaceholder },
    } = useToken()
    const location = useLocation()
    const isPayment = location.pathname.includes(AppRoutes.payment)
    if (isPayment) {
        return null
    }

    return (
        <AntdFooter style={footerStyles}>
            <Flex
                gap={12}
                wrap="wrap"
                style={{ height: '100%', color: colorTextPlaceholder }}
                justify="center"
                align="center"
            >
                <Link
                    href={AppRoutes.privacyPolicy}
                    target="_blank"
                    className={styles.footerTypography}
                    data-merchant="footer-privacy-policy-link"
                >
                    <FormattedMessage {...lang.privacyPolicy} />
                </Link>
                ·
                <Link
                    href={AppRoutes.termsOfUse}
                    target="_blank"
                    className={styles.footerTypography}
                    data-merchant="footer-terms-of-service-link"
                >
                    <FormattedMessage {...lang.termsOfService} />
                </Link>
                ·
                <Link
                    href={AppRoutes.amlkyc}
                    target="_blank"
                    className={styles.footerTypography}
                    data-merchant="footer-amlKyc-link"
                >
                    <FormattedMessage {...lang.amlKyc} />
                </Link>
                ·
                <Text type="secondary" className={styles.footerTypography} data-merchant="footer-copyright">
                    <FormattedMessage {...lang.copyRight} values={{ currentYear }} />
                </Text>
            </Flex>
        </AntdFooter>
    )
}
