import { usePostHog } from 'posthog-js/react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const usePosthogPageview = () => {
    const posthog = usePostHog()
    const location = useLocation()

    useEffect(() => {
        posthog.capture('$pageview', { channel: 'app_web' })
    }, [location, posthog])
}
