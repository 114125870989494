import { useNotifications } from '@merchant/shared/contexts'
import { usePostHog } from 'posthog-js/react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { usePlaceholderView } from '../../hooks'
import { lang } from './lang'
import { convertCaptureEventParams, PosthogConvertEvents } from './posthog'
import { steps, type Steps } from './types'
import type { MessageDescriptor } from 'react-intl'
import type { ModalNames } from '~constants/modal'
import type { Context } from '~features/ActionModal/context'
import { useProject, useMerchant } from '~api'

const viewTitles: Partial<Record<ModalNames, MessageDescriptor | null>> = {
    [steps.convertInput]: lang.convert,
    [steps.convertProgress]: null,
} as const

interface Props {
    step: Steps
    setModalTitle: Context['setTitle']
    setShouldRevalidateDependentTables: Context['setShouldRevalidateDependentTables']
}

export function useData({ step, setModalTitle, setShouldRevalidateDependentTables }: Props) {
    const posthog = usePostHog()
    const location = useLocation()
    const { notification } = useNotifications()
    const { data: project, isLoading: isLoadingProject } = useProject()
    const { data: { id: merchantId = '' } = {} } = useMerchant()
    const { name: placeholderName, isLoading: isLoadingPlaceholder } = usePlaceholderView()
    const projectId = project?.id || ''

    useEffect(() => {
        const viewTitleDescriptor = viewTitles[step]
        const viewTitle =
            !isLoadingPlaceholder && !placeholderName && viewTitleDescriptor
                ? { text: viewTitleDescriptor, dataMerchant: 'convert-modal-title' }
                : false
        setModalTitle(viewTitle)

        if (!isLoadingPlaceholder && placeholderName) {
            setModalTitle(false)
        }
        setShouldRevalidateDependentTables(step === steps.convertProgress)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step, isLoadingPlaceholder, placeholderName])

    useEffect(() => {
        const source: string = location.state?.context || 'query'
        posthog.capture(
            ...convertCaptureEventParams[PosthogConvertEvents.onConvertScreen]({
                merchant_id: merchantId,
                project_id: projectId,
                source,
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    return {
        notification,
        projectId,
        isLoadingProject,
        isLoadingPlaceholder,
        placeholderName,
    }
}
