import { assets } from '@merchant/shared/assets/nameMap'
import { EmailSupportLink, AppSvg } from '@merchant/shared/components'
import { useBreakpoint, useFormValidation } from '@merchant/shared/hooks'
import { Alert, Divider, Form, Skeleton, Space, Spin, Typography } from '@merchant/ui-kit/ant-design'
import React, { useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { projectGeneralFormItems } from './const'
import { getFormItems } from './getFormItems'
import { lang } from './lang'
import { ProjectSubmit } from './MerchantSubmit'
import type { ProjectGeneralFormData } from '../../types'
import type { Project } from '~api/instances/cabinet-api'
import { useMerchant, useProject } from '~api'
import { Permission, ProjectStatusUpdate } from '~api/instances/cabinet-api'
import { PageContentContainer } from '~components'
import { useUserPermissionContext } from '~contexts'

const { Title, Text } = Typography

const initialValues: ProjectGeneralFormData = {
    name: '',
    status: ProjectStatusUpdate.Active,
    link: '',
    supportEmail: '',
    description: '',
}

interface Props {
    isSaveProjectLoading: boolean
    handleSaveProject: (data: ProjectGeneralFormData) => Promise<void | Project>
}

// eslint-disable-next-line complexity
export function General({ handleSaveProject, isSaveProjectLoading }: Props) {
    const { checkUserPermission } = useUserPermissionContext()
    const breakpoints = useBreakpoint()
    const { data: project, isDisabled: isProjectDisabled, isLoading: isProjectLoading } = useProject()
    const { isDisabled: isMerchantDisabled } = useMerchant()
    const intl = useIntl()
    const [form] = Form.useForm()
    const { onBlur, onFinishFailed, validationMode } = useFormValidation(Object.values(projectGeneralFormItems))

    useEffect(() => {
        form.setFieldsValue(project ?? initialValues)
    }, [form, project])

    const formInitialValues = project || initialValues

    const isChangeAllowed = checkUserPermission(Permission.ChangeGeneralInfo)

    return (
        <PageContentContainer styles={{ body: { padding: !breakpoints.md ? 12 : undefined } }} style={{ marginTop: 0 }}>
            <Skeleton
                active
                loading={isProjectLoading}
                paragraph={{ rows: !breakpoints.md ? 1 : 0 }}
                style={{ marginBottom: 16 }}
                title={{ width: 300 }}
            >
                <Space
                    align="baseline"
                    direction={breakpoints.md ? 'horizontal' : 'vertical'}
                    size={8}
                    style={{ marginBottom: 20 }}
                >
                    <Title level={5} data-merchant="merchant-page-merchant-name">
                        {project?.name ?? <FormattedMessage {...lang.createNewMerchant} />}
                    </Title>
                    {project && (
                        <Text type="secondary" data-merchant="merchant-page-merchant-id">
                            ID {project.id}
                        </Text>
                    )}
                </Space>
            </Skeleton>
            {!breakpoints.md && <Divider style={{ margin: '0 0 16px 0' }} />}
            {(isMerchantDisabled || isProjectDisabled) && (
                <Alert
                    data-merchant="merchant-disabled-alert"
                    style={{ alignItems: 'center', marginBottom: 12 }}
                    icon={<AppSvg name={assets.alertCircle} />}
                    type="error"
                    showIcon
                    description={
                        <FormattedMessage {...lang.merchantDisabledAlert} values={{ support: <EmailSupportLink /> }} />
                    }
                />
            )}
            <Spin spinning={isProjectLoading} size="large" data-merchant="merchant-settings-loading-spin">
                <Form
                    onFinishFailed={onFinishFailed}
                    disabled={isProjectLoading}
                    form={form}
                    layout="vertical"
                    onFinish={handleSaveProject}
                    requiredMark={false}
                    size="large"
                    initialValues={formInitialValues}
                >
                    {getFormItems({
                        intl,
                        onBlur,
                        isChangeAllowed,
                        project,
                        isMerchantDisabled,
                        isProjectDisabled,
                    }).map(formItem => (
                        <Form.Item
                            key={formItem.name}
                            validateTrigger={validationMode[formItem.name]}
                            {...formItem}
                            rules={isChangeAllowed ? formItem.rules : undefined}
                        />
                    ))}
                    {isChangeAllowed && (
                        <Form.Item style={{ marginTop: 20, marginBottom: 0 }}>
                            <ProjectSubmit isProjectCreation={!project} loading={isSaveProjectLoading} />
                        </Form.Item>
                    )}
                </Form>
            </Spin>
        </PageContentContainer>
    )
}
