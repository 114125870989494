import { HttpError } from '@merchant/shared/api'
import { NotificationMessage } from '@merchant/shared/components'
import { useNotifications } from '@merchant/shared/contexts'
import { usePromise } from '@merchant/shared/hooks'
import { Button, Divider, Flex } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { mutate } from 'swr'
import { WithdrawalInformation } from '../../WithdrawalInformation'
import { OperationState } from '../WithdrawalState'
import { CancellationConfirm, FailureAlert } from './components'
import { lang } from './lang'
import type { WithdrawalCryptoFormData } from '../../types'
import type { CancelProjectWithdrawalRequest, Withdrawal } from '~api/instances/cabinet-api'
import { projectApi, useProject } from '~api'
import { APIErrorCode, Permission, WithdrawalState } from '~api/instances/cabinet-api'
import { apiRestKeys } from '~api/swr/keys'
import { useUserPermissionContext } from '~contexts'
import { globalLang } from '~globalLang'

interface Props {
    withdrawalData: Withdrawal & { whitelistKey?: WithdrawalCryptoFormData['whitelistKey'] }
    onClose: () => void
    onTryAgain: () => void
}

export function WithdrawalProgress({ withdrawalData, onClose, onTryAgain }: Props) {
    const { checkUserPermission } = useUserPermissionContext()
    const { notification } = useNotifications()
    const { data: { id: projectId = '' } = {} } = useProject()

    const { send: sendCancelTransaction, isLoading: isCancelTransactionLoading } = usePromise(
        (data: Omit<CancelProjectWithdrawalRequest, 'projectId'>) =>
            projectApi.cancelProjectWithdrawal({ ...data, projectId }),
        {
            onSuccess: () =>
                mutate<Withdrawal | undefined>(
                    apiRestKeys.getWithdrawal,
                    prev => (prev ? { ...prev, status: WithdrawalState.Failed } : undefined),
                    { revalidate: false }
                ),
            onError: error => {
                if (!HttpError.isInstance(error)) {
                    return
                }

                const isWithdrawalNotCancellableError = error.checkForErrorCode(APIErrorCode.WithdrawalNotCancellable)
                if (isWithdrawalNotCancellableError) {
                    notification.api.open({
                        message: (
                            <NotificationMessage
                                type="error"
                                title={<FormattedMessage {...lang.notCancellableWithdrawalTitle} />}
                                description={<FormattedMessage {...lang.notCancellableWithdrawalDescription} />}
                                dataMerchant="withdrawal-not-cancellable-notification"
                            />
                        ),
                    })
                }
            },
            showErrorNotification: false,
        }
    )

    const { hash, state = WithdrawalState.Pending, id } = withdrawalData

    const handleSendCancelTransaction = () => {
        if (!id) {
            notification.api.open({
                message: (
                    <NotificationMessage
                        type="error"
                        title={<FormattedMessage {...globalLang.somethingWentWrong} />}
                        description={<FormattedMessage {...lang.noTransactionId} />}
                        dataMerchant="withdrawal-progress-no-transaction-id"
                    />
                ),
            })
        } else {
            sendCancelTransaction({ id })
        }
    }

    const isCancelable =
        !hash && state === WithdrawalState.Pending && checkUserPermission(Permission.CancelCryptoWithdrawals)

    return (
        <>
            <OperationState id={id} state={state} />
            {withdrawalData.state === WithdrawalState.Failed && <FailureAlert error={withdrawalData.error} />}
            <Divider style={{ marginBottom: 16 }} />
            <WithdrawalInformation hash={hash} context="progress" data={withdrawalData} />
            <Flex gap={12} style={{ marginTop: 24 }}>
                {isCancelable && (
                    <CancellationConfirm
                        onCancel={handleSendCancelTransaction}
                        isCancelling={isCancelTransactionLoading}
                    />
                )}
                <Button
                    block
                    size="large"
                    type={state === WithdrawalState.Failed ? 'default' : 'primary'}
                    onClick={onClose}
                    data-merchant="withdrawal-progress-close-button"
                >
                    <FormattedMessage {...globalLang.close} />
                </Button>
                {state === WithdrawalState.Failed && (
                    <Button
                        block
                        size="large"
                        type="primary"
                        onClick={onTryAgain}
                        data-merchant="withdrawal-progress-try-again-button"
                    >
                        <FormattedMessage {...globalLang.tryAgain} />
                    </Button>
                )}
            </Flex>
        </>
    )
}
