export const assets = {
    homeSmile: 'home-smile.svg',
    logOut: 'log-out.svg',
    moon: 'moon.svg',
    settings: 'settings.svg',
    sun: 'sun.svg',
    telegram: 'telegram.svg',
    getAppStore: 'get_app_store.svg',
    getGooglePay: 'get_google_play.svg',
    alertCircle: 'alert-circle.svg',
    arrowLeft: 'arrow-left.svg',
    arrowNarrowLeft: 'arrow-narrow-left.svg',
    arrowNarrowUpRight: 'arrow-narrow-up-right.svg',
    checkCircle: 'check-circle.svg',
    warning: 'warning.svg',
    check: 'check.svg',
    chevronDown: 'chevron-down.svg',
    clock: 'clock.svg',
    close: 'close.svg',
    coinsStacked: 'coins-stacked.svg',
    copy: 'copy.svg',
    fileShield: 'file-shield.svg',
    folderCode: 'folder-code.svg',
    infoCircle: 'info-circle.svg',
    lock: 'lock.svg',
    edit: 'edit.svg',
    receiptCheck: 'receipt-check.svg',
    refreshCw: 'refresh-cw.svg',
    searchMd: 'search-md.svg',
    searchSm: 'search-sm.svg',
    switchVertical: 'switch-vertical.svg',
    tool: 'tool.svg',
    trash: 'trash.svg',
    viewQrCode: 'view-qr-code.svg',
    xCircle: 'x-circle.svg',
    share: 'share.svg',
    calculator: 'calculator.svg',
    onMark: 'on-mark.svg',
    questionMark: 'question-mark.svg',
    logo: 'logo.png',
    logoBw: 'logo_bw.png',
    arrowInCircle: 'arrow-in-circle.svg',
    themeAuto: 'theme-auto.svg',
    list: 'list.svg',
    plus: 'plus.svg',
    user: 'user.svg',
    arrowUpRight: 'arrow-up-right.svg',
    arrowUpStraight: 'arrow-up-straight.svg',
    arrowDownStraight: 'arrow-down-straight.svg',
} as const
