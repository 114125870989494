import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    installAppTitle: {
        id: 'add-two-fa-modal.modal.install_app_title',
        defaultMessage: 'Install the authenticator app',
    },
    scanQrCodeTitle: {
        id: 'add-two-fa-modal.modal.scan_qr_code_title',
        defaultMessage: 'Scan QR-code',
    },
    newTwoFaCodeTitle: {
        id: 'add-two-fa-modal.modal.new_two_fa_code_title',
        defaultMessage: 'New 2FA code',
    },
    writeBackupCodeTitle: {
        id: 'add-two-fa-modal.modal.write_backup_code_title',
        defaultMessage: 'Write down backup code',
    },
    confirmationTitle: {
        id: 'add-two-fa-modal.modal.confirmation_title',
        defaultMessage: 'Confirmation',
    },
    verifyEmailAndTwoFaTitle: {
        id: 'add-two-fa-modal.modal.verify_email_and_two_fa_title',
        defaultMessage: 'Verify Email & Existing 2FA',
    },
    continue: {
        id: 'add-two-fa-modal.button.continue',
        defaultMessage: 'Continue',
    },
    successNotificationMessage: {
        id: 'add-two-fa-modal.notification.success_notification_message',
        defaultMessage: '2FA Activated!',
    },
    firstSuccessNotificationDescription: {
        id: 'add-two-fa-modal.notification.first_success_notification_description',
        defaultMessage: 'Your 2FA method has been set up successfully',
    },
    additionalSuccessNotificationDescription: {
        id: 'add-two-fa-modal.notification.additional_success_notification_description',
        defaultMessage: 'Your additional 2FA method has been set up successfully.',
    },
})
