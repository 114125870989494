/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ActorAPIKey
 */
export interface ActorAPIKey {
    /**
     * 
     * @type {string}
     * @memberof ActorAPIKey
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof ActorAPIKey
     */
    createdAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ActorAPIKey
     */
    deleted?: boolean;
}

/**
 * Check if a given object implements the ActorAPIKey interface.
 */
// @ts-ignore
export function instanceOfActorAPIKey(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

// @ts-ignore
export function ActorAPIKeyFromJSON(json: any): ActorAPIKey {
    return ActorAPIKeyFromJSONTyped(json, false);
}

// @ts-ignore
export function ActorAPIKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActorAPIKey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
    };
}

// @ts-ignore
export function ActorAPIKeyToJSON(value?: ActorAPIKey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'deleted': value.deleted,
    };
}

