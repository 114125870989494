import type { PaymentSettingsFormData } from './types'
import type { Project } from '~api/instances/cabinet-api'

export const getFormInitValues = (
    emptyFormValues: PaymentSettingsFormData,
    project: Project | undefined
): PaymentSettingsFormData => {
    return {
        ...emptyFormValues,
        ...project,
        redirectUrlFailure: project?.redirectUrlFailure || null,
        redirectUrlSuccess: project?.redirectUrlSuccess || null,
    }
}
