/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateMerchantRequest
 */
export interface UpdateMerchantRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateMerchantRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMerchantRequest
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMerchantRequest
     */
    language?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMerchantRequest
     */
    timeZone?: string;
}

/**
 * Check if a given object implements the UpdateMerchantRequest interface.
 */
// @ts-ignore
export function instanceOfUpdateMerchantRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

// @ts-ignore
export function UpdateMerchantRequestFromJSON(json: any): UpdateMerchantRequest {
    return UpdateMerchantRequestFromJSONTyped(json, false);
}

// @ts-ignore
export function UpdateMerchantRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateMerchantRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'timeZone': !exists(json, 'time_zone') ? undefined : json['time_zone'],
    };
}

// @ts-ignore
export function UpdateMerchantRequestToJSON(value?: UpdateMerchantRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'currency': value.currency,
        'language': value.language,
        'time_zone': value.timeZone,
    };
}

