import { Skeleton, Table } from '@merchant/ui-kit/ant-design'
import type { SkeletonProps, TableColumnsType, TableProps } from '@merchant/ui-kit/ant-design'

type SkeletonTableProps<T> = SkeletonProps & {
    columns: TableColumnsType<T>
    rowCount?: number
    dataMerchant: string
    tableProps?: TableProps
}

export function SkeletonTable<T>({
    loading = false,
    active = false,
    rowCount = 5,
    columns,
    children,
    className,
    dataMerchant,
    tableProps,
    ...rest
}: SkeletonTableProps<T>): JSX.Element {
    return loading ? (
        <Table
            {...tableProps}
            data-merchant={dataMerchant}
            rowKey="key"
            pagination={false}
            dataSource={[...Array(rowCount)].map((_, index) => ({
                key: `key${index}`,
            }))}
            columns={columns.map(column => {
                return {
                    ...column,
                    render: () => (
                        <Skeleton
                            key={column.key}
                            title
                            active={active}
                            paragraph={false}
                            className={className}
                            {...rest}
                        />
                    ),
                } as TableColumnsType[number]
            })}
        />
    ) : (
        <>{children}</>
    )
}
