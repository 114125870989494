import { Tooltip, Typography } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { useBoolean } from 'usehooks-ts'
import type { PaymentThumb } from '~api/instances/cabinet-api'
import { NoValueColumnContent } from '~components'

const { Text } = Typography

const orderDetailsWrapperStyles: React.CSSProperties = {
    width: '100%',
    height: 100,
}

interface Props {
    data: PaymentThumb
}

export function OrderDetailsColumnValue({ data }: Props) {
    const { value: isTruncated, setValue: setIsTruncated } = useBoolean(false)
    const { title, description } = data

    const productNameContent = title ? (
        <Text ellipsis={{ onEllipsis: setIsTruncated }} style={{ fontSize: 12 }} data-merchant="order-details-title">
            {title}
        </Text>
    ) : (
        NoValueColumnContent
    )
    const productDescriptionContent = description && (
        <Text
            ellipsis={{ onEllipsis: setIsTruncated }}
            style={{ fontSize: 12 }}
            type="secondary"
            data-merchant="order-details-description"
        >
            {description}
        </Text>
    )

    return (
        <Tooltip
            placement="bottom"
            style={orderDetailsWrapperStyles}
            title={
                isTruncated && (
                    <div onClick={e => e.stopPropagation()}>
                        {title}
                        <br />
                        <Text data-merchant="order-details-tooltip-description" type="secondary">
                            {description}
                        </Text>
                    </div>
                )
            }
            data-merchant="order-details-tooltip"
        >
            {productNameContent}
            <br />
            {productDescriptionContent}
        </Tooltip>
    )
}
