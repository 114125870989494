import { AppSvg, StatisticFormattedNumber } from '@merchant/shared/components'
import { useBreakpoint } from '@merchant/shared/hooks'
import { parseDateAndTime } from '@merchant/shared/utils'
import { Card, ConfigProvider, Flex, Result, Skeleton, Typography, theme } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { orderStateResultIconMap, orderStateResultTitleMap } from './const'
import { getLatestEvent } from './utils'
import type { GlobalToken } from '@merchant/ui-kit/ant-design'
import type { Payment } from '~api/instances/cabinet-api'
import { PaymentState } from '~api/instances/cabinet-api'

const { Text, Title } = Typography
const { useToken } = theme

const getIconsColorMap = (token: GlobalToken) => ({
    [PaymentState.Canceled]: token.colorTextPlaceholder,
    [PaymentState.Failed]: token.colorError,
    [PaymentState.Completed]: token.colorSuccess,
    [PaymentState.Created]: token.colorWarning,
    [PaymentState.Waiting]: token.colorWarning,
})

interface Props {
    isLoading: boolean
    data?: Payment
}

export function OrderSummaryCard({ isLoading, data }: Props) {
    const { token } = useToken()
    const breakpoints = useBreakpoint()

    if (isLoading || !data) {
        return (
            <Card data-merchant="order-summary-skeleton-card">
                <Skeleton
                    active
                    avatar={{
                        shape: 'circle',
                        size: 64,
                        style: { marginLeft: '16px' },
                    }}
                    title={false}
                    paragraph={{
                        rows: 2,
                        style: { width: '20%', marginInline: 'auto' },
                        width: '80%',
                    }}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 20,
                    }}
                />
            </Card>
        )
    }

    const latestEvent = getLatestEvent(data)

    const iconColorsMap = getIconsColorMap(token)

    const iconName = orderStateResultIconMap[data.state]

    const parsedDate = parseDateAndTime(latestEvent)
    const dateTime = (
        <Text style={{ opacity: 0.8 }} data-merchant="order-summary-latest-update-date">
            {parsedDate?.date}{' '}
            <Text type="secondary" data-merchant={null}>
                {parsedDate?.time}
            </Text>
        </Text>
    )

    const subtitle =
        data.state === PaymentState.Completed ? (
            <Text type="success" data-merchant="order-summary-receivedAmonut">
                <StatisticFormattedNumber
                    valueStyle={{ fontSize: 20 }}
                    prefix="+"
                    value={data.selectedPaymentMethod?.receivedAmount}
                    suffix={data.selectedPaymentMethod?.holdingCurrency}
                />
            </Text>
        ) : null

    return (
        <Card styles={{ body: { paddingBlock: !breakpoints.md ? 22.5 : 38 } }} data-merchant="order-summary-card">
            <ConfigProvider
                theme={{
                    components: { Result: { paddingLG: 0, marginXS: 8 } },
                }}
            >
                <Result
                    icon={
                        <Flex justify="center">
                            <AppSvg size={48} name={iconName} color={iconColorsMap[data.state]} />
                        </Flex>
                    }
                    style={{ padding: 0, margin: 0 }}
                    title={
                        <Title level={4} data-merchant="order-summary-state">
                            <FormattedMessage {...orderStateResultTitleMap[data.state]} />
                        </Title>
                    }
                    subTitle={subtitle}
                    extra={dateTime}
                />
            </ConfigProvider>
        </Card>
    )
}
