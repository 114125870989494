import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg, CopyButton, EllipsisMiddle, InlineLinkButton } from '@merchant/shared/components'
import { getExplorerLink, parseDateAndTime } from '@merchant/shared/utils'
import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import type { Withdrawal } from '~api'
import type { Currencies } from '~api/types'
import { useCurrencies } from '~api'
import { NoValueColumnContent } from '~components'
import { getRow } from '~features/WithdrawalsPage/views/Withdrawal/utils'

const { Text } = Typography

interface Props {
    data: Withdrawal
}

const getRows = ({ data, currencies }: { data: Withdrawal; currencies: Currencies | undefined }) => {
    const explorerLink = getExplorerLink({
        currencies,
        hash: data.hash,
        currencyCode: data.receiveCurrency,
        networkCode: data.receiveNetwork,
    })

    const progressStartedAt = parseDateAndTime(data.sentAt)

    if (!data.sentAt && !data.hash) {
        return []
    }

    return [
        getRow(
            lang.date,
            <>
                <Text type="secondary" data-merchant="withdrawal-timeline-inProgress-date">
                    {progressStartedAt?.date}
                </Text>
                &nbsp;
                <Text data-merchant="withdrawal-timeline-inProgress-time">{progressStartedAt?.time}</Text>
            </>
        ),
        getRow(
            lang.hash,
            data.hash ? (
                <Flex gap={4} align="center">
                    <EllipsisMiddle
                        data-merchant="withdrawal-timeline-inProgress-hash"
                        prefixCount={6}
                        suffixCount={6}
                        divider="."
                    >
                        {data.hash}
                    </EllipsisMiddle>
                    <CopyButton
                        data-merchant="withdrawal-timeline-inProgress-hash-copy-button"
                        inputValue={data.hash}
                        type="text"
                        notificationContentSubject={lang.hash}
                    />
                </Flex>
            ) : (
                NoValueColumnContent
            ),
            data.hash ? (
                <InlineLinkButton
                    target="_blank"
                    href={explorerLink}
                    icon={<AppSvg size={16} name={assets.share} />}
                    data-merchant="withdrawal-timeline-inProgress-explorer-link"
                />
            ) : (
                NoValueColumnContent
            )
        ),
    ]
}

export function InProgressTimeline({ data }: Props) {
    const { data: currencies } = useCurrencies()

    return (
        <Flex vertical gap={16}>
            <Text data-merchant="withdrawal-timeline-inProgress-label">
                <FormattedMessage {...lang.inProgressTimeline} />
            </Text>
            {getRows({ data, currencies })}
        </Flex>
    )
}
