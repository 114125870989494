import { QRCode, theme } from '@merchant/ui-kit/ant-design'
import cn from 'classnames'
import React from 'react'
import { getImageComponentWrapperStyle } from './style'
import styles from './style.module.css'
import type { GlobalToken, QRCodeProps } from '@merchant/ui-kit/ant-design'

const { useToken } = theme

const DEFAULT_ICON_SIZE = 40

type Props = Omit<QRCodeProps, 'bgColor' | 'ColorText'> & {
    dataMerchant?: string
    imgComponent?: React.ReactNode
}

// The purpose of this QRCode component is to be able to use theming variables
// TODO: Remove and use default antd component as soon as they provide QRCode theming configuration
// TODO qr was not working, dropping icon was the quickest solution
export function StyledQrCode({
    size = 190,
    style,
    dataMerchant,
    errorLevel,
    imgComponent,
    iconSize = DEFAULT_ICON_SIZE,
    ...rest
}: Props) {
    const { token } = useToken()
    const { colorBgContainer, colorText, boxShadow } = token.QRCode as GlobalToken
    const componentStyles: React.CSSProperties = {
        boxShadow,
        border: 'none',
        ...style,
    }

    return (
        <div data-merchant={dataMerchant} style={{ position: 'relative' }}>
            <QRCode
                className={cn({ [styles.QrSvgImageHidden!]: !!imgComponent })}
                bgColor={colorBgContainer}
                color={colorText}
                style={componentStyles}
                iconSize={iconSize}
                type="svg"
                size={size}
                bordered={false}
                errorLevel={errorLevel}
                {...rest}
            />
            {imgComponent && <div style={getImageComponentWrapperStyle(iconSize)}>{imgComponent}</div>}
        </div>
    )
}
