import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    inputModalTitle: {
        id: 'withdrawal-fiat.typography.input_modal_title',
        defaultMessage: 'Withdrawal fiat',
    },
    confirmationModalTitle: {
        id: 'withdrawal-fiat.typography.confirmation_modal_title',
        defaultMessage: 'Confirm transaction',
    },
    bankAccountDetails: {
        id: 'withdrawal-fiat.form.bank_account_details',
        defaultMessage: 'Bank account details',
    },
    withdrawalFee: {
        id: 'withdrawal-fiat.form.withdrawal_fee',
        defaultMessage: 'Withdrawal fee',
    },
    receiveAmount: {
        id: 'withdrawal-fiat.form.receive_amount',
        defaultMessage: 'Receive amount',
    },
})
