import { lang } from './lang'
import { Permission } from '~api/instances/cabinet-api'
import { QueryParams, routes } from '~constants/routes'
import { constructorTypes } from '~features/PaymentConstructor/types'
import { Actions } from '~types/actionModal'
import { generateQuery } from '~utils'

export const enum AddressesShowOptions {
    staticAddresses = 'static-addresses',
    depositAddresses = 'deposit-addresses',
}

export const pageSubtitlesMap = {
    [AddressesShowOptions.staticAddresses]: lang.staticAddressesTitle,
    [AddressesShowOptions.depositAddresses]: lang.depositAddressesTitle,
} as const

export const pages: AddressesShowOptions[] = [
    AddressesShowOptions.staticAddresses,
    AddressesShowOptions.depositAddresses,
]

export const dataMerchantAttributesMap = {
    [AddressesShowOptions.staticAddresses]: 'addresses-static-addresses-title',
    [AddressesShowOptions.depositAddresses]: 'addresses-deposit-addresses-title',
} as const

export const pagePermissionMap: Record<AddressesShowOptions, Permission> = {
    [AddressesShowOptions.staticAddresses]: Permission.SeeDepositAddresses,
    [AddressesShowOptions.depositAddresses]: Permission.SeeDepositAddresses,
}

export const createAddressButtonsProps = {
    [AddressesShowOptions.staticAddresses]: {
        label: lang.createStaticAddress,
        getTo: () => `${routes.ordersCreate}${generateQuery({ [QueryParams.type]: constructorTypes.StaticAddress })}`,
    },
    [AddressesShowOptions.depositAddresses]: {
        label: lang.createDepositAddress,
        getTo: (params: URLSearchParams) =>
            `${generateQuery({ ...Object.fromEntries(params.entries()), [QueryParams.action]: Actions.deposit })}`,
    },
} as const
