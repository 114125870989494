import { CloseOutlined } from '@ant-design/icons'
import { useBreakpoint } from '@merchant/shared/hooks'
import { Button, Flex } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { WithdrawalInformation } from '../../WithdrawalInformation'
import { lang } from './lang'
import type { WithdrawalCryptoFormData } from '../../types'
import { ModalNames } from '~constants/modal'
import { useRegisterDirtyFormChecker } from '~hooks'

interface Props {
    onConfirm: () => void
    withdrawalFormData: WithdrawalCryptoFormData
    onCancel: () => void
}

export function WithdrawalSummary({ onConfirm, withdrawalFormData, onCancel }: Props) {
    const { md } = useBreakpoint()
    useRegisterDirtyFormChecker(ModalNames.withdrawalCryptoSummary)

    return (
        <>
            <WithdrawalInformation
                context="summary"
                data={{
                    address: withdrawalFormData.address,
                    addressTag: withdrawalFormData.addressTag,
                    receiveCurrency: withdrawalFormData.currency || '',
                    receiveNetwork: withdrawalFormData.network,
                    networkFee: withdrawalFormData.fee || '',
                    creditAmount: withdrawalFormData.withdrawalAmount || '',
                    whitelistKey: withdrawalFormData.whitelistKey || '',
                    receiveAmount: withdrawalFormData.receiveAmount || '',
                    note: withdrawalFormData.note || '',
                }}
            />
            <Flex style={{ marginTop: 24 }} gap={12} wrap={!md}>
                <Button
                    block
                    size="large"
                    onClick={onCancel}
                    style={{ gap: 4 }}
                    styles={{ icon: { display: 'inline-flex', alignItems: 'center', margin: 0 } }}
                    icon={<CloseOutlined />}
                    data-merchant="withdrawal-summary-decline-button"
                >
                    <Flex align="center">
                        <FormattedMessage {...lang.decline} />
                    </Flex>
                </Button>
                <Button
                    block
                    type="primary"
                    onClick={onConfirm}
                    size="large"
                    data-merchant="withdrawal-summary-confirm-button"
                >
                    <span>
                        <FormattedMessage {...lang.confirm} /> {withdrawalFormData.withdrawalAmount}{' '}
                        {withdrawalFormData.currency}
                    </span>
                </Button>
            </Flex>
        </>
    )
}
