import { Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { Navigate, Route, Routes } from 'react-router-dom'
import { MerchantSettings, TeamManagement } from './components'
import { lang } from './lang'
import { Permission } from '~api/instances/cabinet-api'
import { routes } from '~constants/routes'
import { useUserPermissionContext } from '~contexts'

const { Text } = Typography

export function MerchantPage() {
    const { checkUserPermission, isUserPermissionInitialized } = useUserPermissionContext()

    const isTeamManagementAllowed =
        checkUserPermission(Permission.SeeProjectTeamMembers) || checkUserPermission(Permission.SeeProjectRoles)

    if (!isUserPermissionInitialized) {
        return null
    }

    return (
        <>
            <Text data-merchant={null} style={{ opacity: 0.4 }}>
                <FormattedMessage {...lang.pageTitle} />
            </Text>
            <Routes>
                <Route path={routes.settings} element={<MerchantSettings />} />
                {isTeamManagementAllowed && <Route path={routes.team} element={<TeamManagement />} />}
                <Route path="*" index element={<Navigate to={routes.merchantSettings} />} />
            </Routes>
        </>
    )
}
