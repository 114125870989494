import { forEach, isString, reduce } from 'lodash-es'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

interface UseQueryNavigationArgs<T, S> {
    beforeWrite: Partial<Record<keyof T, (value: T[keyof T]) => S | undefined>>
    afterRead: (filters: S) => T
    filters: (keyof S)[]
}

interface UseQueryNavigationArgsOpt<S> {
    filters: (keyof S)[]
}

interface UseQueryNavigationReturn<T, S> {
    onSubmit: (filters: T) => void
    filters: T | S
    onClear: () => Promise<void>
}

interface UseQueryNavigationReturnOpt<T> {
    onSubmit: (filters: T) => void
    filters: T
    onClear: () => Promise<void>
}

export function useQueryNavigation<T extends object, S extends object>({
    beforeWrite,
    afterRead,
    filters,
}: UseQueryNavigationArgs<T, S>): UseQueryNavigationReturn<T, S>
export function useQueryNavigation<T extends object>({
    filters,
}: UseQueryNavigationArgsOpt<T>): UseQueryNavigationReturnOpt<T>
export function useQueryNavigation<T extends object, S extends object>(
    args: UseQueryNavigationArgsOpt<S> | UseQueryNavigationArgs<T, S>
): UseQueryNavigationReturn<T, S> {
    const [searchParams, setSearchParams] = useSearchParams()
    const [filtersState, setFiltersState] = useState<T | S>(() => {
        const result = {} as S
        for (const [key, value] of searchParams) {
            if (args.filters.includes(key as keyof S)) {
                result[key as keyof S] = value as S[keyof S]
            }
        }

        return 'afterRead' in args ? args.afterRead(result) : result
    })

    const reducer = (acc: T, [key, value]: [string, T[keyof T]]) => {
        if (isString(value) && !value.trim()) {
            return { ...acc, [key]: undefined }
        }
        if ('beforeWrite' in args) {
            if (key in args.beforeWrite) {
                const modifiedFilterItem = args.beforeWrite[key as keyof T]?.(value)
                acc = { ...acc, ...modifiedFilterItem }
            } else {
                acc[key as keyof T] = isString(value) ? (value.trim() as T[keyof T]) : value
            }
        }

        return acc
    }

    const onSubmit = (filters: T) => {
        const processedFilters = reduce(Object.entries(filters), reducer, {} as T)
        forEach(Object.entries(processedFilters), ([key, value]) => {
            if (value) {
                const paramValue = value instanceof Date ? value.toISOString() : value
                searchParams.set(key, paramValue)
            } else {
                searchParams.delete(key)
            }
        })

        setSearchParams(searchParams)
        setFiltersState(processedFilters)
    }

    const onClear = async () => {
        setFiltersState({} as T)
        forEach(args.filters, key => {
            searchParams.delete(key as string)
        })
        setSearchParams(searchParams)
    }

    return {
        filters: filtersState,
        onSubmit,
        onClear,
    }
}
