import { Button, Space, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import type { CSSProperties } from 'react'
import type { MessageDescriptor } from 'react-intl'
import { globalLang } from '~globalLang'

const { Text } = Typography

interface Props {
    titleDescriptor: MessageDescriptor
    subtitleDescriptor?: MessageDescriptor
    onRetry?: () => void
    isLoading?: boolean
}

const buttonStyles: CSSProperties = {
    fontSize: 14,
    paddingLeft: 32,
    paddingRight: 32,
    marginTop: 8,
}

const spaceStyles: CSSProperties = {
    padding: '48px 0 24px 0',
}

export function TableError({
    titleDescriptor,
    subtitleDescriptor = lang.checkInternetConnection,
    onRetry,
    isLoading,
}: Props) {
    return (
        <Space direction="vertical" align="center" style={spaceStyles}>
            <Text data-merchant={null} style={{ fontSize: 35 }}>
                <FormattedMessage {...lang.tools} />
            </Text>
            <Text data-merchant={null} style={{ fontSize: 16, fontWeight: 400 }}>
                <FormattedMessage {...titleDescriptor} />
            </Text>
            <Text data-merchant={null} style={{ fontWeight: 400 }} type="secondary">
                <FormattedMessage {...subtitleDescriptor} />
            </Text>
            {onRetry && (
                <Button
                    onClick={() => onRetry()}
                    type="primary"
                    loading={isLoading}
                    style={buttonStyles}
                    data-merchant="table-error-try-again-button"
                >
                    <FormattedMessage {...globalLang.tryAgain} />
                </Button>
            )}
        </Space>
    )
}
