import { LinkAsButton } from '@merchant/shared/components'
import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSearchParams } from 'react-router-dom'
import {
    dataMerchantAttributesMap,
    pageSubtitlesMap,
    AddressesShowOptions,
    createAddressButtonsProps,
    pagePermissionMap,
    pages,
} from './const'
import { lang } from './lang'
import { StaticAddresses, DepositAddresses } from './views'
import { Permission } from '~api'
import { QueryParams } from '~constants/routes'
import { useUserPermissionContext } from '~contexts'
import { usePlaceholderViewData } from '~features/PlaceholderViewWrapper/hooks'
import { useURLSearchNavigation } from '~hooks'

const { Title, Text } = Typography

const views = {
    [AddressesShowOptions.staticAddresses]: <StaticAddresses />,
    [AddressesShowOptions.depositAddresses]: <DepositAddresses />,
} as const

export function AddressesPage() {
    const { contentKey } = usePlaceholderViewData()
    const { checkUserPermission } = useUserPermissionContext()
    const [searchParams] = useSearchParams()

    const permittedPages = pages.filter(page => checkUserPermission(pagePermissionMap[page]))

    const { page } = useURLSearchNavigation({
        key: QueryParams.show,
        pages: permittedPages,
    })

    const dataMerchant = dataMerchantAttributesMap[page]
    const pageSubtitle = <FormattedMessage {...pageSubtitlesMap[page]} />

    return (
        <>
            <Flex justify="space-between" align="center">
                <Flex vertical>
                    <Text data-merchant="addresses-page-title" style={{ opacity: 0.4 }}>
                        <FormattedMessage {...lang.pageTitle} />
                    </Text>
                    <Title level={4} data-merchant={dataMerchant}>
                        {pageSubtitle}
                    </Title>
                </Flex>
                {contentKey === null && checkUserPermission(Permission.CreateDepositAddress) && (
                    <LinkAsButton
                        to={createAddressButtonsProps[page].getTo(searchParams)}
                        buttonProps={{
                            type: 'primary',
                            size: 'large',
                            'data-merchant': 'addresses-create-address-button',
                        }}
                    >
                        <FormattedMessage {...createAddressButtonsProps[page].label} />
                    </LinkAsButton>
                )}
            </Flex>
            {views[page]}
        </>
    )
}
