import { lang } from './lang'
import type { ConfirmationFormMode } from './types'
import type { FormItemsKey } from '@merchant/shared/types'
import type { MessageDescriptor } from 'react-intl'
import type { APIKey } from '~api/instances/cabinet-api'
import { ModalNames } from '~constants/modal'

export enum Modes {
    create = 'create',
    delete = 'delete',
    edit = 'edit',
    toggleStatus = 'toggle-status',
}

export const deactivatedStyles: React.CSSProperties = { opacity: 0.6 }

export const columnDataIndexes: FormItemsKey<APIKey> = {
    name: 'name',
    active: 'active',
    publicKey: 'publicKey',
    createdAt: 'createdAt',
    id: 'id',
    updatedAt: 'updatedAt',
    permissions: 'permissions',
    lastUsedAt: 'lastUsedAt',
    createdBy: 'createdBy',
    privateKeyMask: 'privateKeyMask',
}

export const API_KEY_TITLE_ALLOWED_LENGTH = 255

export enum ApiKeyMode {
    Read = 'read',
    Write = 'write',
}

export const confirmationMode2ModalName: Record<ConfirmationFormMode, ModalNames> = {
    'create-api-key': ModalNames.createApiKeyConfirmation,
    'edit-api-key': ModalNames.createApiKeyConfirmation,
    'delete-api-key': ModalNames.delete2Fa,
    'toggle-api-key': ModalNames.toggle2Fa,
}

export type NotificationModes = Modes.delete | Modes.edit

export const mode2NotificationTitle: Record<NotificationModes, MessageDescriptor> = {
    [Modes.delete]: lang.deleteSuccessMessage,
    [Modes.edit]: lang.editSuccessMessage,
} as const
