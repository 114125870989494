import { parseDateAndTime } from '@merchant/shared/utils'
import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import { capitalize } from 'lodash-es'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import type { CanceledWithdrawal } from '~features/WithdrawalsPage/views/Withdrawal/types'
import { useProjectTeamMembers } from '~api'
import { getRow } from '~features/WithdrawalsPage/views/Withdrawal/utils'

const { Text } = Typography

interface Props {
    data: CanceledWithdrawal
}

const getRows = ({ data, cancelersRole }: { data: CanceledWithdrawal; cancelersRole: string | undefined }) => {
    const canceledAt = parseDateAndTime(data.canceledAt)

    return [
        getRow(
            lang.date,
            <>
                <Text type="secondary" data-merchant="withdrawal-timeline-cancel-date">
                    {canceledAt?.date}
                </Text>
                &nbsp;
                <Text data-merchant="withdrawal-timeline-cancel-time">{canceledAt?.time}</Text>
            </>
        ),
        getRow(
            lang.by,
            <Flex vertical>
                <Text data-merchant="withdrawal-timeline-canceled-by-email">{data.canceledBy.email}</Text>
                <Text type="secondary" data-merchant="withdrawal-timeline-canceled-by-role">
                    {capitalize(cancelersRole)}
                </Text>
            </Flex>
        ),
    ]
}

export function CanceledTimeline({ data }: Props) {
    const { data: teamMembers } = useProjectTeamMembers()
    // It was decided to use first role of the user if he has multiple roles, may be temp solution
    const cancelersRole = teamMembers?.find(member => member.id === data.canceledBy.id)?.roles[0]

    return (
        <Flex vertical gap={16}>
            <Text data-merchant="withdrawal-timeline-cancelled-label">
                <FormattedMessage {...lang.canceledTimeline} />
            </Text>
            {getRows({ data, cancelersRole })}
        </Flex>
    )
}
