/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FeesPayer } from './FeesPayer';
import {
    FeesPayerFromJSON,
    FeesPayerFromJSONTyped,
    FeesPayerToJSON,
} from './FeesPayer';
import type { LossConfig } from './LossConfig';
import {
    LossConfigFromJSON,
    LossConfigFromJSONTyped,
    LossConfigToJSON,
} from './LossConfig';
import type { NestedError } from './NestedError';
import {
    NestedErrorFromJSON,
    NestedErrorFromJSONTyped,
    NestedErrorToJSON,
} from './NestedError';
import type { NominalOrPayinAmount } from './NominalOrPayinAmount';
import {
    NominalOrPayinAmountFromJSON,
    NominalOrPayinAmountFromJSONTyped,
    NominalOrPayinAmountToJSON,
} from './NominalOrPayinAmount';
import type { PaymentDetails } from './PaymentDetails';
import {
    PaymentDetailsFromJSON,
    PaymentDetailsFromJSONTyped,
    PaymentDetailsToJSON,
} from './PaymentDetails';
import type { PaymentState } from './PaymentState';
import {
    PaymentStateFromJSON,
    PaymentStateFromJSONTyped,
    PaymentStateToJSON,
} from './PaymentState';
import type { PaymentSubtype } from './PaymentSubtype';
import {
    PaymentSubtypeFromJSON,
    PaymentSubtypeFromJSONTyped,
    PaymentSubtypeToJSON,
} from './PaymentSubtype';
import type { PaymentType } from './PaymentType';
import {
    PaymentTypeFromJSON,
    PaymentTypeFromJSONTyped,
    PaymentTypeToJSON,
} from './PaymentType';
import type { SelectedPaymentMethod } from './SelectedPaymentMethod';
import {
    SelectedPaymentMethodFromJSON,
    SelectedPaymentMethodFromJSONTyped,
    SelectedPaymentMethodToJSON,
} from './SelectedPaymentMethod';

/**
 * 
 * @export
 * @interface Payment
 */
export interface Payment {
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    origin: string;
    /**
     * 
     * @type {PaymentType}
     * @memberof Payment
     */
    type: PaymentType;
    /**
     * 
     * @type {PaymentSubtype}
     * @memberof Payment
     */
    subtype?: PaymentSubtype;
    /**
     * 
     * @type {PaymentState}
     * @memberof Payment
     */
    state: PaymentState;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    nominalCurrency: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    nominalAmount?: string;
    /**
     * 
     * @type {NominalOrPayinAmount}
     * @memberof Payment
     */
    recommendedAmount?: NominalOrPayinAmount;
    /**
     * 
     * @type {FeesPayer}
     * @memberof Payment
     */
    feesPayer: FeesPayer;
    /**
     * 
     * @type {LossConfig}
     * @memberof Payment
     */
    lossConfig: LossConfig;
    /**
     * 
     * @type {PaymentDetails}
     * @memberof Payment
     */
    details: PaymentDetails;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    paymentUrl: string;
    /**
     * 
     * @type {SelectedPaymentMethod}
     * @memberof Payment
     */
    selectedPaymentMethod?: SelectedPaymentMethod;
    /**
     * 
     * @type {NestedError}
     * @memberof Payment
     */
    error?: NestedError;
    /**
     * 
     * @type {Date}
     * @memberof Payment
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Payment
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Payment
     */
    deadlineAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Payment
     */
    completedAt?: Date;
}

/**
 * Check if a given object implements the Payment interface.
 */
// @ts-ignore
export function instanceOfPayment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "origin" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "nominalCurrency" in value;
    isInstance = isInstance && "feesPayer" in value;
    isInstance = isInstance && "lossConfig" in value;
    isInstance = isInstance && "details" in value;
    isInstance = isInstance && "paymentUrl" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;

    return isInstance;
}

// @ts-ignore
export function PaymentFromJSON(json: any): Payment {
    return PaymentFromJSONTyped(json, false);
}

// @ts-ignore
export function PaymentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Payment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'projectId': json['project_id'],
        'origin': json['origin'],
        'type': PaymentTypeFromJSON(json['type']),
        'subtype': !exists(json, 'subtype') ? undefined : PaymentSubtypeFromJSON(json['subtype']),
        'state': PaymentStateFromJSON(json['state']),
        'nominalCurrency': json['nominal_currency'],
        'nominalAmount': !exists(json, 'nominal_amount') ? undefined : json['nominal_amount'],
        'recommendedAmount': !exists(json, 'recommended_amount') ? undefined : NominalOrPayinAmountFromJSON(json['recommended_amount']),
        'feesPayer': FeesPayerFromJSON(json['fees_payer']),
        'lossConfig': LossConfigFromJSON(json['loss_config']),
        'details': PaymentDetailsFromJSON(json['details']),
        'paymentUrl': json['payment_url'],
        'selectedPaymentMethod': !exists(json, 'selected_payment_method') ? undefined : SelectedPaymentMethodFromJSON(json['selected_payment_method']),
        'error': !exists(json, 'error') ? undefined : NestedErrorFromJSON(json['error']),
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'deadlineAt': !exists(json, 'deadline_at') ? undefined : (new Date(json['deadline_at'])),
        'completedAt': !exists(json, 'completed_at') ? undefined : (new Date(json['completed_at'])),
    };
}

// @ts-ignore
export function PaymentToJSON(value?: Payment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'project_id': value.projectId,
        'origin': value.origin,
        'type': PaymentTypeToJSON(value.type),
        'subtype': PaymentSubtypeToJSON(value.subtype),
        'state': PaymentStateToJSON(value.state),
        'nominal_currency': value.nominalCurrency,
        'nominal_amount': value.nominalAmount,
        'recommended_amount': NominalOrPayinAmountToJSON(value.recommendedAmount),
        'fees_payer': FeesPayerToJSON(value.feesPayer),
        'loss_config': LossConfigToJSON(value.lossConfig),
        'details': PaymentDetailsToJSON(value.details),
        'payment_url': value.paymentUrl,
        'selected_payment_method': SelectedPaymentMethodToJSON(value.selectedPaymentMethod),
        'error': NestedErrorToJSON(value.error),
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'deadline_at': value.deadlineAt === undefined ? undefined : (value.deadlineAt.toISOString()),
        'completed_at': value.completedAt === undefined ? undefined : (value.completedAt.toISOString()),
    };
}

