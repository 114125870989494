/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { APIErrorCode } from './APIErrorCode';
import {
    APIErrorCodeFromJSON,
    APIErrorCodeFromJSONTyped,
    APIErrorCodeToJSON,
} from './APIErrorCode';

/**
 * 
 * @export
 * @interface APIErrorResponseErrorsInner
 */
export interface APIErrorResponseErrorsInner {
    /**
     * 
     * @type {string}
     * @memberof APIErrorResponseErrorsInner
     */
    type: APIErrorResponseErrorsInnerType;
    /**
     * 
     * @type {APIErrorCode}
     * @memberof APIErrorResponseErrorsInner
     */
    code?: APIErrorCode;
    /**
     * 
     * @type {string}
     * @memberof APIErrorResponseErrorsInner
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof APIErrorResponseErrorsInner
     */
    param?: string;
}

/**
* @export
* @enum {string}
*/
export enum APIErrorResponseErrorsInnerType {
    BadRequest = 'BAD_REQUEST',
    ApiError = 'API_ERROR'
}


/**
 * Check if a given object implements the APIErrorResponseErrorsInner interface.
 */
// @ts-ignore
export function instanceOfAPIErrorResponseErrorsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "message" in value;

    return isInstance;
}

// @ts-ignore
export function APIErrorResponseErrorsInnerFromJSON(json: any): APIErrorResponseErrorsInner {
    return APIErrorResponseErrorsInnerFromJSONTyped(json, false);
}

// @ts-ignore
export function APIErrorResponseErrorsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): APIErrorResponseErrorsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'code': !exists(json, 'code') ? undefined : APIErrorCodeFromJSON(json['code']),
        'message': json['message'],
        'param': !exists(json, 'param') ? undefined : json['param'],
    };
}

// @ts-ignore
export function APIErrorResponseErrorsInnerToJSON(value?: APIErrorResponseErrorsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'code': APIErrorCodeToJSON(value.code),
        'message': value.message,
        'param': value.param,
    };
}

