import { useEffect } from 'react'
import { useLocalStorage } from 'usehooks-ts'

export function useRegisterPageVisit(localStorageKey: string) {
    const [visited, setVisited] = useLocalStorage(localStorageKey, false)

    useEffect(() => {
        if (!visited) {
            setVisited(true)
        }
    }, [setVisited, visited])
}
