import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    modalTitle: {
        id: 'edit-roles.modal.title',
        defaultMessage: 'Confirm permissions changes',
    },
    cancelButton: {
        id: 'edit-roles.modal.button.cancel',
        defaultMessage: 'Back',
    },
    okButton: {
        id: 'edit-roles.modal.button.ok',
        defaultMessage: 'Confirm',
    },
    noChanges: {
        id: 'edit-roles.modal.no_changes',
        defaultMessage: 'No changes',
    },
    willBeChanged: {
        id: 'edit-roles.modal.will_be_changed',
        defaultMessage: 'Will be changed <b>{count, plural, one {# permission} other {# permissions}}</b>',
    },
    willBeActivated: {
        id: 'edit-roles.modal.will_be_activated',
        defaultMessage: 'Will be <highlight>activated</highlight>',
    },
    willBeDeactivated: {
        id: 'edit-roles.modal.will_be_deactivated',
        defaultMessage: 'Will be <highlight>deactivated</highlight>',
    },
    countPermissions: {
        id: 'edit-roles.modal.count_permissions',
        defaultMessage: '{count, plural, one {# permission} other {# permissions}}',
    },
})
