import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    cardTitle: {
        id: 'withdrawal.info.title',
        defaultMessage: 'Withdrawal',
    },
    createdAt: {
        id: 'withdrawal.info.created_at',
        defaultMessage: 'Created',
    },
    lastUpdatedAt: {
        id: 'withdrawal.info.last_updated_at',
        defaultMessage: 'Last updated',
    },
    id: {
        id: 'withdrawal.info.id',
        defaultMessage: 'ID',
    },
    orderId: {
        id: 'withdrawal.info.order_id',
        defaultMessage: 'Merchant Withdrawal ID',
    },
    address: {
        id: 'withdrawal.info.address',
        defaultMessage: 'Address',
    },
    addressTag: {
        id: 'withdrawal.info.address_tag',
        defaultMessage: 'Tag',
    },
    note: {
        id: 'withdrawal.info.note',
        defaultMessage: 'Note',
    },
    source: {
        id: 'withdrawal.info.source',
        defaultMessage: 'Source',
    },
    creditAmount: {
        id: 'withdrawal.info.credit_amount',
        defaultMessage: 'Credit amount',
    },
    fee: {
        id: 'withdrawal.info.fee',
        defaultMessage: 'Network fee',
    },
    receiveAmount: {
        id: 'withdrawal.info.receive_amount',
        defaultMessage: 'Receive amount',
    },
    receiveNetwork: {
        id: 'withdrawal.info.receive_network',
        defaultMessage: 'Receive network',
    },
    nominalAmount: {
        id: 'withdrawal.info.nominal_amount',
        defaultMessage: 'Nominal amount',
    },
    metaInfo: {
        id: 'withdrawal.info.meta_info',
        defaultMessage: 'Meta info',
    },
    sendStatusCallback: {
        id: 'withdrawal.info.button.send_status_callback',
        defaultMessage: 'Send status callback',
    },
    cancel: {
        id: 'withdrawal.info.button.cancel',
        defaultMessage: 'Cancel',
    },
    sendStatusCallbackTooltip: {
        id: 'withdrawal-details.button.send_status_callback_tooltip',
        defaultMessage: 'To send a callback, first setup Callback URL in the <link>Integrations</link> section',
    },
})
