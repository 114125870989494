import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    whitelist: {
        id: 'whitelist-page.typography.whitelist-page-title',
        defaultMessage: 'Whitelist',
    },
    newAddressAlertDescription: {
        id: 'whitelist-page.alert.description',
        defaultMessage:
            'All withdrawals from your account will be suspended for <boldText>72 hours</boldText> every time you add a new withdrawal address. This is a security measure for the safety of your funds.',
    },
    enableWhitelistTitle: {
        id: 'whitelist-page.typography.enable_whitelist_title',
        defaultMessage: '🔐',
    },
    enableWhitelistSubtitle: {
        id: 'whitelist-page.typography.enable_whitelist_subtitle',
        defaultMessage: 'Protect Your Withdrawals',
    },
    enableWhitelist: {
        id: 'whitelist-page.button.enable_whitelist',
        defaultMessage: 'Enable Whitelist',
    },
    whitelistDescription: {
        id: 'whitelist-page.typography.whitelist_description',
        defaultMessage:
            'Whitelist is an additional security feature designed to protect your funds. When you enable Whitelist, your Account can only withdraw cryptocurrencies to the wallet addresses on the whitelist (works both for web and API).',
    },
    addNewAddress: {
        id: 'whitelist-page.button.add_new_address',
        defaultMessage: 'Add new address',
    },
    disableWhitelistButton: {
        id: 'whitelist-page.button.disable_whitelist_button',
        defaultMessage: 'Disable whitelist',
    },
    enabledTag: {
        id: 'whitelist-page.tag.enabled',
        defaultMessage: 'Enabled',
    },
    withdrawalSuspended: {
        id: 'whitelist-page.alert.withdrawal_suspended',
        defaultMessage:
            'All withdrawals from your account are temporarily suspended. <inlineButton>More info</inlineButton>',
    },
    suspendedModalTitle: {
        id: 'whitelist-page.modal.suspended_title',
        defaultMessage: 'Withdrawals are temporarily suspended',
    },
    loadingError: {
        id: 'whitelist-page.modal.loading_error',
        defaultMessage: 'An error occurred. Please try again later',
    },
    disableConfirmMessage: {
        id: 'whitelist-page.modal.disable_confirm_message',
        defaultMessage: 'Are you sure you want to disable whitelist?',
    },
})
