import { getIfErrorHasSubstring, processErrorResponse } from '@merchant/shared/hooks'
import { isKeyOfObject } from '@merchant/shared/utils'
import { isEmpty } from 'lodash-es'
import { useIntl } from 'react-intl'
import { confirmations } from './const'
import { getErrorMessage } from './utils'
import type { FormInstance } from '@merchant/ui-kit/ant-design'
import type { APIErrorResponseErrorsInner } from '~api'
import { globalLang } from '~globalLang'

type ConfirmationStrategy = Partial<Record<keyof typeof confirmations, keyof APIErrorResponseErrorsInner>>

type UseCodeConfirmationErrorHandling = (params: { confirmationStrategy: ConfirmationStrategy }) => {
    handleError: HandleError
}
type HandleError = (params: { error: unknown; form: FormInstance }) => void

type FieldData = {
    name: string | number | (string | number)[]
    value?: unknown
    touched?: boolean
    validating?: boolean
    errors?: string[]
}

export const useCodeConfirmationErrorHandling: UseCodeConfirmationErrorHandling = ({ confirmationStrategy }) => {
    const intl = useIntl()
    const handleError: HandleError = ({ error, form }) => {
        const fieldsToSet: FieldData[] = []
        const generalErrorFields: FieldData[] = []
        Object.entries(confirmationStrategy).forEach(([strategy, field = 'code']) => {
            const currentStrategy = isKeyOfObject(strategy, confirmations) ? confirmations[strategy] : undefined
            if (!currentStrategy) {
                return
            }
            const isInvalidCodeError = currentStrategy.substrings.some(str => getIfErrorHasSubstring(error, str, field))

            if (isInvalidCodeError) {
                fieldsToSet.push(
                    ...currentStrategy.fields.map(field => ({
                        name: field,
                        errors: getErrorMessage(intl, error),
                    }))
                )
            } else {
                const parsedErrorMessage = processErrorResponse(error)
                generalErrorFields.push(
                    ...currentStrategy.fields.map(field => ({
                        name: field,
                        errors: [parsedErrorMessage || intl.formatMessage(globalLang.somethingWentWrong)],
                    }))
                )
            }
        })

        form.setFields(isEmpty(fieldsToSet) ? generalErrorFields : fieldsToSet)
    }

    return {
        handleError,
    }
}
