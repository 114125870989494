import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    walletAddressPlaceholder: {
        id: 'withdrawal-modal.input.wallet_address_placeholder',
        defaultMessage: 'Enter wallet address',
    },
    walletAddressLabel: {
        id: 'withdrawal-modal.input.wallet_address_label',
        defaultMessage: 'Wallet address',
    },
    amountPlaceholder: {
        id: 'withdrawal-modal.input.amount_placeholder',
        defaultMessage: 'Amount',
    },
    amountLabel: {
        id: 'withdrawal-modal.input.amount_label',
        defaultMessage: 'Withdrawal amount',
    },
    fee: {
        id: 'withdrawal-modal.typography.fee',
        defaultMessage: 'Withdrawal fee',
    },
    feeLabel: {
        id: 'withdrawal-modal.typography.fee_label',
        defaultMessage: 'Withdrawal fee',
    },
    receiveAmountLabel: {
        id: 'withdrawal-modal.typography.receive_amount_label',
        defaultMessage: 'Receive amount',
    },
    addNewAddressButton: {
        id: 'withdrawal-modal.button.add_new_address',
        defaultMessage: 'Add a new whitelisted address',
    },
    addNewAddressWithCurrency: {
        id: 'withdrawal-modal.button.add_new_address_with_currency',
        defaultMessage: 'Add new {CurrencyCode} address',
    },
    enterTagName: {
        id: 'withdrawal-modal.input.enter_tag_name',
        defaultMessage: 'Enter {tagName}',
    },
    invalidAddress: {
        id: 'withdrawal-modal.input.invalid_address',
        defaultMessage: 'Invalid address',
    },
    invalidSubject: {
        id: 'withdrawal-modal.input.invalid_subject',
        defaultMessage: 'Invalid {subject}',
    },
    tagAlertMessage: {
        id: 'withdrawal-modal.alert.tag_alert_message',
        defaultMessage:
            'Please make sure that you double-check the {tagName} before entering it, as any errors may result in a failed transaction or lost funds.',
    },
    amountShouldBeGreater: {
        id: 'withdrawal-modal.alert.amount_should_be_greater',
        defaultMessage: 'Amount should be greater than withdrawal fee',
    },
    whitelistEnabledWarning: {
        id: 'withdrawal-modal.alert.whitelist_enabled',
        defaultMessage: 'Whitelist enabled. Crypto withdrawals can only be made to whitelisted addresses.',
    },
    noteLabel: {
        id: 'withdrawal-modal.input.note_label',
        defaultMessage: 'Note',
    },
    notePlaceholder: {
        id: 'withdrawal-modal.input.note_placeholder',
        defaultMessage: 'Add comment...',
    },
    noteTooltip: {
        id: 'withdrawal-modal.tooltip.note',
        defaultMessage:
            '(Optional) It will be shown only for you in your withdrawals history. You can add any useful comment here, e.g., purpose of withdrawal: supplier payment, office rent, testing.',
    },
    withdrawalAmount: {
        id: 'withdrawal-modal.typography.withdrawal_amount',
        defaultMessage: 'Withdrawal amount',
    },
    withdrawalAmountDescription: {
        id: 'withdrawal-modal.typography.withdrawal_amount_description',
        defaultMessage: 'Specify how much will be deducted from your balance',
    },

    receiveAmount: {
        id: 'withdrawal-modal.typography.receive_amount',
        defaultMessage: 'Receive amount',
    },
    receiveAmountDescription: {
        id: 'withdrawal-modal.typography.receive_amount_description',
        defaultMessage: 'Specify how much the recipient (wallet address) will receive',
    },
    withdrawalAmountLabel: {
        id: 'withdrawal-modal.typography.withdrawal_amount_label',
        defaultMessage: 'Withdrawal amount',
    },
    withdrawalAmountTooltip: {
        id: 'withdrawal-modal.typography.withdrawal_amount_tooltip',
        defaultMessage: `Amount that will be deducted from your account balance
            <break></break>
            <break></break>
        You can choose which amount to specify: Withdrawal or Receive - by using the dropdown menu above the amount input field`,
    },
    receiveAmountTooltip: {
        id: 'withdrawal-modal.typography.withdrawal_amount_tooltip',
        defaultMessage: `Amount the recipient will receive after accounting for all applicable fees
            <break></break>
            <break></break>
        You can choose which amount to specify: Withdrawal or Receive - by using the dropdown menu above the amount input field`,
    },
    feeTooltip: {
        id: 'withdrawal-modal.typography.withdrawal_amount_tooltip',
        defaultMessage:
            'This is the fee for the withdrawal. It may change over time based on network conditions in the selected currency and network. If you have questions, please contact your personal account manager or our <supportLink>support team</supportLink>',
    },
})
