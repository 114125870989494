import React from 'react'

type DataAttribute = { [K in `data-${string}`]?: string }

function isElementWithDataAttribute(
    node: React.ReactNode
): node is React.ReactElement<React.HTMLAttributes<HTMLElement> & DataAttribute> {
    return React.isValidElement(node)
}

export function cloneReactNodeWithDataAttributes(node: React.ReactNode, dataAttribute: DataAttribute) {
    if (!isElementWithDataAttribute(node)) {
        return
    }

    const newNode = React.cloneElement(node, {
        ...dataAttribute,
    })

    return newNode
}
