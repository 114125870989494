/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SwapInfo
 */
export interface SwapInfo {
    /**
     * 
     * @type {string}
     * @memberof SwapInfo
     */
    rate: string;
    /**
     * 
     * @type {Date}
     * @memberof SwapInfo
     */
    time: Date;
    /**
     * 
     * @type {string}
     * @memberof SwapInfo
     */
    txnId?: string;
}

/**
 * Check if a given object implements the SwapInfo interface.
 */
// @ts-ignore
export function instanceOfSwapInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "rate" in value;
    isInstance = isInstance && "time" in value;

    return isInstance;
}

// @ts-ignore
export function SwapInfoFromJSON(json: any): SwapInfo {
    return SwapInfoFromJSONTyped(json, false);
}

// @ts-ignore
export function SwapInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwapInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rate': json['rate'],
        'time': (new Date(json['time'])),
        'txnId': !exists(json, 'txn_id') ? undefined : json['txn_id'],
    };
}

// @ts-ignore
export function SwapInfoToJSON(value?: SwapInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rate': value.rate,
        'time': (value.time.toISOString()),
        'txn_id': value.txnId,
    };
}

