import { getEntries } from '@merchant/shared/utils'
import { some } from 'lodash-es'
import { collapseFieldsMap } from './const'
import type { OrderSettingsType } from './const'
import type { NewPaymentFormData } from './types'
import type { CreatePaymentRequest, LossConfig } from '~api/instances/cabinet-api'
import { normalizeAbsoluteUnderpaymentValue } from '~utils/underpayment'

export function determineCollapseErrorKey(fieldName: keyof NewPaymentFormData): OrderSettingsType | undefined {
    return getEntries(collapseFieldsMap).find(([_, fields]) => fields.includes(fieldName))?.[0]
}

export function normalizeLossConfig(lossConfig?: LossConfig): LossConfig | undefined {
    if (!lossConfig) {
        return undefined
    }

    return {
        ...lossConfig,
        absolute: normalizeAbsoluteUnderpaymentValue(lossConfig.absolute),
    }
}

export function normalizeRecommendedAmount(
    recommendedAmount: CreatePaymentRequest['recommendedAmount']
): CreatePaymentRequest['recommendedAmount'] | undefined {
    if (!recommendedAmount || !some(recommendedAmount, Boolean)) {
        return undefined
    }

    return recommendedAmount
}
