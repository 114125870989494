import { Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage, useIntl } from 'react-intl'
import type { Props } from './types'
import { globalLang } from '~globalLang'

const { Link } = Typography

export function EmailSupportLink({ email, message = globalLang.support, children, ...rest }: Props) {
    const intl = useIntl()
    const emailStr = email || intl.formatMessage(globalLang.merchantSupportEmail)

    const linkText = children || <FormattedMessage {...message} />

    return (
        <Link data-merchant="contact-support-button" href={`mailto:${emailStr}`} {...rest}>
            {linkText}
        </Link>
    )
}
