import { useNotifications } from '@merchant/shared/contexts'
import { useState } from 'react'
import { useBoolean } from 'usehooks-ts'
import { Modes, type NotificationModes } from './const'
import { getSuccessNotificationMessage, isModeNotifiable } from './utils'
import { useApiKeys, useProjectBlocklist } from '~api'
import { Permission, type APIKey } from '~api/instances/cabinet-api'
import { useUserPermissionContext } from '~contexts'

export function useData() {
    const { value: isSuspensionModalOpen, setTrue: openSuspensionModal, setFalse: closeSuspensionModal } = useBoolean()
    const { data: projectBlocklist } = useProjectBlocklist()
    const isBlocked = projectBlocklist?.blocked
    const { checkUserPermission } = useUserPermissionContext()
    const { data: apiKeys, mutate: mutateApiKeys, isLoading: isApiKeysLoading } = useApiKeys()
    const [selectedApiKey, setSelectedApiKey] = useState<APIKey | null>(null)
    const [mode, setMode] = useState<Modes | null>(null)
    const {
        notification: { api: notificationApi },
    } = useNotifications()

    const resolveBlocklist = (cb: () => void) => (isBlocked ? openSuspensionModal() : cb())

    const resetModals = () => {
        setMode(null)
        setSelectedApiKey(null)
    }

    const showSuccessNotification = (mode: NotificationModes) => {
        notificationApi.open({ message: getSuccessNotificationMessage(mode) })
    }

    const withSuccessNotification = <T>(handler: (...args: T[]) => void) => {
        return (...args: T[]) => {
            handler(...args)
            isModeNotifiable(mode) && showSuccessNotification(mode)
        }
    }

    const handleTableActionClick = async (apiKey: APIKey, mode: Modes) => {
        resolveBlocklist(() => {
            setSelectedApiKey(apiKey)
            setMode(mode)
        })
    }

    const handleDeleteSuccess = withSuccessNotification(() => {
        if (!selectedApiKey) {
            return
        }
        mutateApiKeys(prev => prev?.filter(key => key.id !== selectedApiKey.id), { revalidate: false })
        resetModals()
    })

    const handleEditSuccess = withSuccessNotification((editedApiKey: APIKey) => {
        mutateApiKeys((prev = []) => prev.map(apiKey => (apiKey.id === editedApiKey.id ? editedApiKey : apiKey)), {
            revalidate: false,
        })
        resetModals()
    })

    const handleCreateSuccess = withSuccessNotification((apiKey: APIKey) => {
        mutateApiKeys((prev = []) => [...prev, apiKey], { revalidate: false })
    })

    const handleStatusToggleSuccess = withSuccessNotification((editedApiKey: APIKey) => {
        mutateApiKeys((prev = []) =>
            prev.map(apiKey => (apiKey.id === editedApiKey.id ? editedApiKey : apiKey), {
                revalidate: false,
            })
        )
        resetModals()
    })

    const handleGenerateApiKeyClick = () => {
        resolveBlocklist(() => setMode(Modes.create))
    }

    return {
        apiKeys,
        isApiKeysLoading,
        mode,
        selectedApiKey,
        handleTableActionClick,
        handleDeleteSuccess,
        handleEditSuccess,
        handleCreateSuccess,
        handleStatusToggleSuccess,
        handleGenerateApiKeyClick,
        resetModals,
        setMode,
        isIntegrationEditAllowed: checkUserPermission(Permission.ChangeIntegration),
        isSuspensionModalOpen,
        closeSuspensionModal,
        projectBlocklist,
        resolveBlocklist,
    }
}
