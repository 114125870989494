import { defineMessages, type MessageDescriptor } from 'react-intl'
import { ProjectAPIKeyPermissionID } from '~api'

export const apiKeyPermissionsLang: Record<ProjectAPIKeyPermissionID, MessageDescriptor> = defineMessages({
    [ProjectAPIKeyPermissionID.Balances]: {
        id: 'api-key.permissions.balances',
        defaultMessage: 'Balances',
    },
    [ProjectAPIKeyPermissionID.Swaps]: {
        id: 'api-key.permissions.swap',
        defaultMessage: 'Swap',
    },
    [ProjectAPIKeyPermissionID.Currencies]: {
        id: 'api-key.permissions.currencies',
        defaultMessage: 'Currencies',
    },
    [ProjectAPIKeyPermissionID.Full]: {
        id: 'api-key.permissions.full',
        defaultMessage: 'Full',
    },
    [ProjectAPIKeyPermissionID.Addresses]: {
        id: 'api-key.permissions.addresses',
        defaultMessage: 'Addresses',
    },
    [ProjectAPIKeyPermissionID.Payments]: {
        id: 'api-key.permissions.payments',
        defaultMessage: 'Payments',
    },
    [ProjectAPIKeyPermissionID.Withdrawals]: {
        id: 'api-key.permissions.withdrawals',
        defaultMessage: 'Withdrawals',
    },
} as const)
