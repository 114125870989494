import { NotificationMessage, ModalResponsive } from '@merchant/shared/components'
import { useNotifications } from '@merchant/shared/contexts'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { dataMerchantsMap } from './const'
import { lang } from './lang'
import { Steps, modalLangTitleStepMap } from './types'
import { Confirmation, InstallApp, NewTwoFaCode, ScanQrCode, WriteDownBackupCode } from './views'
import type { ReactNode } from 'react'
import type { CreateTOTPResponse, TOTP } from '~api/instances/cabinet-api'
import { useMerchant } from '~api'
import { TOTPStatus } from '~api/instances/cabinet-api'
import { withDirtyCheckingContextProvider } from '~hoc/withDirtyCheckingContextProvider'
import { useDirtyFormCheckerControls } from '~hooks'

interface Props {
    isOpen: boolean
    close: () => void
    isTwoFaEnabled?: boolean
    onModalSuccess: (newTwoFa: TOTP) => void
}

// eslint-disable-next-line complexity
function Component({ isOpen, close, isTwoFaEnabled, onModalSuccess }: Props) {
    const {
        notification: { api: notificationApi },
    } = useNotifications()
    const { data: merchant } = useMerchant()
    const intl = useIntl()
    const [step, setStep] = useState<Steps>(Steps.install)
    const [newTwoFa, setNewTwoFa] = useState<CreateTOTPResponse>()

    const resetData = () => {
        setStep(Steps.install)
    }

    const { handleCancel, skipCheckAndCancel } = useDirtyFormCheckerControls(() => {
        resetData()
        close()
    })

    const onCreateTwoFaSuccess = () => {
        const descriptionLangObject = !isTwoFaEnabled
            ? lang.firstSuccessNotificationDescription
            : lang.additionalSuccessNotificationDescription
        notificationApi.open({
            message: (
                <NotificationMessage
                    title={<FormattedMessage {...lang.successNotificationMessage} />}
                    type="success"
                    description={<FormattedMessage {...descriptionLangObject} />}
                    dataMerchant="add-two-fa-success-notification"
                />
            ),
        })
    }

    const onAddTwoFaFinish = async () => {
        onCreateTwoFaSuccess()
        newTwoFa && onModalSuccess({ ...newTwoFa, status: TOTPStatus.Active })
        resetData()
        skipCheckAndCancel()
    }

    let view: ReactNode = null

    if (step === Steps.install) {
        const onSuccess = (totp: CreateTOTPResponse) => {
            setNewTwoFa(totp)
            setStep(Steps.scanQrCode)
        }
        view = <InstallApp onSuccess={onSuccess} />
    } else if (step === Steps.scanQrCode && newTwoFa) {
        view = <ScanQrCode onSuccess={() => setStep(Steps.newTwoFaCode)} newTwoFa={newTwoFa} />
    } else if (step === Steps.newTwoFaCode && newTwoFa?.id) {
        view = <NewTwoFaCode onSuccess={() => setStep(Steps.writeDown)} id={newTwoFa.id} />
    } else if (step === Steps.writeDown && newTwoFa?.backup) {
        view = <WriteDownBackupCode onSuccess={() => setStep(Steps.confirmation)} backup={newTwoFa.backup} />
    } else if (step === Steps.confirmation && newTwoFa?.id) {
        view = (
            <Confirmation
                onSuccess={onAddTwoFaFinish}
                isTwoFaEnabled={isTwoFaEnabled || false}
                id={newTwoFa.id}
                email={merchant?.email || 'unknown email'}
            />
        )
    }

    const titleLang = modalLangTitleStepMap[step]
    const modalTitle = intl.formatMessage(titleLang)

    return (
        <ModalResponsive
            data-merchant={dataMerchantsMap[step]}
            keyboard={false}
            open={isOpen}
            footer={null}
            title={modalTitle}
            destroyOnClose
            onCancel={handleCancel}
            width={550}
        >
            {view}
        </ModalResponsive>
    )
}

export const AddTwoFaModal = withDirtyCheckingContextProvider(Component)
