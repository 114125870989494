import { isEmpty } from 'lodash-es'
import styles from '../style.module.css'
import { INLINE_INDENT, menuItemsPredicate, type menuKeys } from './const'
import type { CreateMenuItem, MenuItemExtendedType } from './types'
import type { ValuesAsKeys } from '@merchant/shared/types'
import type { MenuDividerType, MenuItemGroupType } from '@merchant/ui-kit/ant-design/es/menu/interface'
import type { Key } from 'react'
import { QueryParams } from '~constants/routes'

function getGroupSubItems({
    dataMerchant,
    label,
    key,
}: Pick<CreateMenuItem, 'label' | 'dataMerchant' | 'key'>): MenuItemExtendedType[] {
    const group: MenuItemGroupType & { 'data-merchant': string; key: Key } = {
        type: 'group',
        label,
        key: `${key}-group`,
        'data-merchant': `${dataMerchant}-group`,
        style: { paddingInline: 0 },
    }
    const divider: MenuDividerType & { 'data-merchant': string; key: Key } = {
        type: 'divider',
        key: `${key}-divider`,
        'data-merchant': `${dataMerchant}-divider`,
    }

    return [group, divider]
}

export function createMenuItem({
    key,
    dataMerchant,
    subItems,
    shouldAddIconPlaceholder,
    label,
    isGrouped,
    checkUserPermission,
    ...restProps
}: CreateMenuItem): MenuItemExtendedType {
    const childrenSubItems = subItems
        ?.filter(item => (item.checkUserPermission ? item.checkUserPermission() : true))
        .map(({ dataMerchant: subItemDataMerchant, checkUserPermission: _, ...restChild }) => ({
            ...restChild,
            icon: shouldAddIconPlaceholder && <div className={styles.iconPlaceholder} />,
            style: { paddingLeft: INLINE_INDENT },
            'data-merchant': subItemDataMerchant,
        }))

    if (!isEmpty(subItems) && isEmpty(childrenSubItems)) {
        return null
    }

    let menuChildren: MenuItemExtendedType[] | undefined = childrenSubItems

    if (isGrouped && menuChildren && !isEmpty(menuChildren)) {
        const groupSubItems = getGroupSubItems({ dataMerchant, key, label })

        menuChildren = [...groupSubItems, ...menuChildren]
    }

    const menuItem = {
        ...restProps,
        key,
        label,
        className: styles.menuItem,
        'data-merchant': dataMerchant,
        popupClassName: styles.subMenu,
        children: menuChildren,
    }

    if (!checkUserPermission) {
        return menuItem
    }

    return checkUserPermission() ? menuItem : null
}

export function shouldSkipMenuItemCheck(locationPath: string): boolean {
    return locationPath.includes(`${QueryParams.action}=`)
}

export const selectedMenuItemPredicate = (item: ValuesAsKeys<typeof menuKeys>, locationPath: string) =>
    menuItemsPredicate[item]?.(locationPath) ?? item === locationPath

export const getIsOpenKeysHandled = (
    isLayoutSiderCollapsed: boolean,
    navMenuUsageContext: 'layoutSider' | 'sideMenu'
) => isLayoutSiderCollapsed || navMenuUsageContext === 'sideMenu'
