import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { useFormValidation, usePromise } from '@merchant/shared/hooks'
import { Alert, Button, Form } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { regenerateFormItemsKey } from './const'
import { lang } from './lang'
import type { RegenerateFormData } from '../../types'
import type { Callback, TOTPCredentials } from '~api/instances/cabinet-api'
import { ModalNames } from '~constants/modal'
import { TwoFaConfirmationItem } from '~features/FormItems'
import { useCodeConfirmationErrorHandling } from '~features/FormItems/useCodeErrorHandling'
import { useRegisterDirtyFormChecker } from '~hooks'

interface Props {
    request: (totps: TOTPCredentials) => Promise<Callback>
    onSuccess: (c: Callback) => void
}

const initialValues: RegenerateFormData = {
    totpCode: '',
}

export function RegenerationModalForm({ request, onSuccess }: Props) {
    const [form] = Form.useForm()
    const { handleError } = useCodeConfirmationErrorHandling({
        confirmationStrategy: { '2fa': 'code' },
    })
    const { onBlur, onFinishFailed } = useFormValidation(Object.values(regenerateFormItemsKey))
    useRegisterDirtyFormChecker(ModalNames.callbackUrlRegenerate, form)
    const { send, isLoading } = usePromise(request, {
        showErrorNotification: false,
        onSuccess,
        onError: error => {
            handleError({
                form,
                error,
            })
        },
    })

    return (
        <Form
            form={form}
            onFinishFailed={onFinishFailed}
            size="large"
            onFinish={send}
            initialValues={initialValues}
            layout="vertical"
            requiredMark={false}
        >
            <TwoFaConfirmationItem
                hasFocusOnMount
                dataMerchant="regenerate-callback-2fa-input"
                onBlur={onBlur('totpCode')}
            />
            <Form.Item>
                <Alert
                    data-merchant="regenerate-callback-alert"
                    icon={<AppSvg name={assets.alertCircle} />}
                    type="warning"
                    showIcon
                    style={{ alignItems: 'center' }}
                    description={<FormattedMessage {...lang.regeneratePublicKeyAlert} />}
                />
            </Form.Item>
            <Form.Item noStyle>
                <Button
                    data-merchant="regenerate-callback-submit-button"
                    htmlType="submit"
                    type="primary"
                    block
                    loading={isLoading}
                >
                    <FormattedMessage {...lang.regenerateButton} />
                </Button>
            </Form.Item>
        </Form>
    )
}
