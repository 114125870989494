import { CompactCardsWrapper } from '@merchant/shared/components'
import { useNotifications } from '@merchant/shared/contexts/index.ts'
import { useBreakpoint, usePromise } from '@merchant/shared/hooks'
import { getObjectKeys } from '@merchant/shared/utils'
import { Button, Flex, Form, Input, Modal, Radio, Spin, Typography } from '@merchant/ui-kit/ant-design'
import { isEqual, pick } from 'lodash-es'
import { useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { compactCardsWrapperStyles } from '../../const.ts'
import { lang as commonLang } from './../../lang'
import { UnderpaymentFieldset } from './components'
import { initFormValues, paymentsSettingsFormItems, urlRules } from './const.tsx'
import { lang } from './lang.ts'
import { getFormInitValues } from './utils.ts'
import type { PaymentSettingsFormData, PaymentsSettingsPermissions } from './types.ts'
import type { UpdateProjectRequest } from '~api/instances/cabinet-api'
import { projectApi, useProject } from '~api'
import { FeesPayer, Permission } from '~api/instances/cabinet-api'
import { paymentsSettingsPageIsVisitedKey } from '~constants/localStorage.ts'
import { useUserPermissionContext } from '~contexts/userPermission.context.tsx'
import { PlaceholderViewWrapper } from '~features/PlaceholderViewWrapper'
import { PlaceholderViewKeys } from '~features/PlaceholderViewWrapper/const.ts'
import { globalLang } from '~globalLang'
import { useRegisterPageVisit } from '~hooks'
import { getModalConfirmProps, isUnderpaymentLarge, normalizeAbsoluteUnderpaymentValue } from '~utils/underpayment'

const { Text } = Typography

// eslint-disable-next-line max-lines-per-function
export function PaymentsSettings() {
    const { checkUserPermission } = useUserPermissionContext()
    const {
        message: { api: messageApi },
    } = useNotifications()
    const breakpoint = useBreakpoint()
    const { data: project, mutate: mutateProject, isLoading: isProjectLoading } = useProject()
    const [modal, contextHolder] = Modal.useModal()
    const intl = useIntl()
    const [form] = Form.useForm()
    useRegisterPageVisit(paymentsSettingsPageIsVisitedKey)
    const paymentsSettingsPermissions: PaymentsSettingsPermissions = {
        [Permission.ChangePaymentSettingsFeesPayer]: checkUserPermission(Permission.ChangePaymentSettingsFeesPayer),
        [Permission.ChangePaymentSettingsLossConfig]: checkUserPermission(Permission.ChangePaymentSettingsLossConfig),
        [Permission.ChangePaymentSettingsRedirectLinks]: checkUserPermission(
            Permission.ChangePaymentSettingsRedirectLinks
        ),
    }

    const { send: updatePaymentsSettings, isLoading: isPaymentsSettingsUpdating } = usePromise(
        (updateProjectRequest: UpdateProjectRequest) => {
            const projectId = project?.id || ''

            return projectApi.updateProject({
                projectId,
                updateProjectRequest,
            })
        },
        {
            onSuccess: projectResult => {
                messageApi.success({
                    props: {
                        'data-merchant': 'payments-settings-update-success',
                    },
                    content: intl.formatMessage(lang.settingsSuccessChangeMessage),
                })
                mutateProject(projectResult, { revalidate: false })
            },
        }
    )

    const initValues: PaymentSettingsFormData = getFormInitValues(initFormValues, project)

    const handleFormFinish = async (values: PaymentSettingsFormData) => {
        const { lossConfig, ...restValues } = values
        if (isUnderpaymentLarge(lossConfig)) {
            const isConfirmed = await modal.confirm(getModalConfirmProps(lossConfig, breakpoint))

            if (!isConfirmed) {
                return
            }
        }

        const normalizedValues: PaymentSettingsFormData = {
            ...restValues,
            lossConfig: {
                relative: lossConfig?.relative ? Number(lossConfig.relative) : undefined,
                absolute: normalizeAbsoluteUnderpaymentValue(lossConfig?.absolute),
            },
        }
        const changedValues = pick(
            normalizedValues,
            getObjectKeys(normalizedValues).filter(key => !isEqual(normalizedValues[key], initValues[key]))
        )

        if (!Object.keys(changedValues).length) {
            messageApi.warning({
                props: { 'data-merchant': 'payments-settings-no-changes' },
                content: intl.formatMessage(commonLang.noChanges),
            })

            return
        }

        updatePaymentsSettings(changedValues)
    }

    useEffect(() => {
        if (isProjectLoading || !project) {
            return
        }
        form.resetFields()
    }, [form, isProjectLoading, project])

    return (
        <PlaceholderViewWrapper
            dataMerchantPrefix="merchant-payments-settings"
            excludedViews={[PlaceholderViewKeys.No2Fa]}
        >
            <Form form={form} layout="vertical" size="large" initialValues={initValues} onFinish={handleFormFinish}>
                {contextHolder}
                <Spin
                    size="large"
                    style={{ maxHeight: '100%' }}
                    spinning={isProjectLoading}
                    data-merchant="payment-settings-loading-spin"
                >
                    <CompactCardsWrapper
                        compactCardStyles={compactCardsWrapperStyles}
                        dataMerchantPrefix="payment-settings"
                    >
                        <>
                            <Flex vertical>
                                <Text style={{ fontSize: 16, fontWeight: 600 }} data-merchant={null}>
                                    <FormattedMessage {...lang.feeIsPaidBy} />
                                </Text>
                                <Text type="secondary" data-merchant={null}>
                                    <FormattedMessage {...lang.feeIsPaidByDescription} />
                                </Text>
                            </Flex>
                            <Form.Item
                                name={paymentsSettingsFormItems.feesPayer}
                                style={{ marginBottom: 0, marginTop: 12 }}
                            >
                                <Radio.Group
                                    disabled={!paymentsSettingsPermissions.change_payment_settings_fees_payer}
                                    data-merchant="payments-settings-fee-payer-radio-group"
                                >
                                    <Radio
                                        value={FeesPayer.Merchant}
                                        data-merchant={`payments-settings-fee-payer-radio-${FeesPayer.Merchant.toLowerCase()}`}
                                    >
                                        <FormattedMessage {...lang.merchant} />
                                    </Radio>
                                    <Radio
                                        value={FeesPayer.Customer}
                                        data-merchant={`payments-settings-fee-payer-radio-${FeesPayer.Customer.toLowerCase()}`}
                                    >
                                        <FormattedMessage {...lang.client} />
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>
                        </>
                        <>
                            <Flex vertical style={{ marginBottom: 16 }}>
                                <Text style={{ fontSize: 16, fontWeight: 600 }} data-merchant={null}>
                                    <FormattedMessage {...lang.redirectLinks} />
                                </Text>
                                <Text type="secondary" data-merchant={null}>
                                    <FormattedMessage {...lang.redirectLinksDescription} />
                                </Text>
                            </Flex>
                            <Form.Item
                                normalize={value => (value ? value.trim() : null)}
                                rules={urlRules}
                                tooltip={{
                                    title: (
                                        <Text data-merchant={null}>
                                            <FormattedMessage
                                                {...lang.successLinkTooltip}
                                                values={{
                                                    url: (
                                                        <Text type="secondary" data-merchant={null}>
                                                            <FormattedMessage {...lang.successLinkTooltipUrl} />
                                                        </Text>
                                                    ),
                                                }}
                                            />
                                        </Text>
                                    ),
                                    placement: 'bottom',
                                    overlayInnerStyle: { width: 350 },
                                    'data-merchant': 'payments-settings-success-url-tooltip',
                                }}
                                name={paymentsSettingsFormItems.redirectUrlSuccess}
                                label={
                                    <Text data-merchant={null}>
                                        <FormattedMessage {...lang.successUrl} />
                                    </Text>
                                }
                            >
                                <Input
                                    disabled={!paymentsSettingsPermissions.change_payment_settings_redirect_links}
                                    autoComplete="off"
                                    data-merchant="payments-settings-success-url-input"
                                    placeholder={intl.formatMessage(lang.successUrl)}
                                    suffix={
                                        <Text type="secondary" data-merchant={null}>
                                            <FormattedMessage {...globalLang.optional} />
                                        </Text>
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                normalize={value => (value ? value.trim() : null)}
                                style={{ marginBottom: 0 }}
                                tooltip={{
                                    title: (
                                        <Text data-merchant={null}>
                                            <FormattedMessage
                                                {...lang.failLinkTooltip}
                                                values={{
                                                    url: (
                                                        <Text type="secondary" data-merchant={null}>
                                                            <FormattedMessage {...lang.failLinkTooltipUrl} />
                                                        </Text>
                                                    ),
                                                }}
                                            />
                                        </Text>
                                    ),
                                    placement: 'bottom',
                                    overlayInnerStyle: { width: 350 },
                                    'data-merchant': 'payments-settings-failure-url-tooltip',
                                }}
                                rules={urlRules}
                                name={paymentsSettingsFormItems.redirectUrlFailure}
                                label={
                                    <Text data-merchant={null}>
                                        <FormattedMessage {...lang.failureUrl} />
                                    </Text>
                                }
                            >
                                <Input
                                    disabled={!paymentsSettingsPermissions.change_payment_settings_redirect_links}
                                    autoComplete="off"
                                    data-merchant="payments-settings-failed-url-input"
                                    placeholder={intl.formatMessage(lang.failureUrl)}
                                    suffix={
                                        <Text type="secondary" data-merchant={null}>
                                            <FormattedMessage {...globalLang.optional} />
                                        </Text>
                                    }
                                />
                            </Form.Item>
                        </>
                        <UnderpaymentFieldset
                            isUnderpaymentEditAllowed={paymentsSettingsPermissions.change_payment_settings_loss_config}
                            lossConfig={project?.lossConfig}
                            isProjectLoading={isProjectLoading}
                        />
                        <Button
                            block
                            type="primary"
                            htmlType="submit"
                            loading={isPaymentsSettingsUpdating}
                            data-merchant="payments-settings-submit-button"
                        >
                            <FormattedMessage {...commonLang.saveChanges} />
                        </Button>
                    </CompactCardsWrapper>
                </Spin>
            </Form>
        </PlaceholderViewWrapper>
    )
}
