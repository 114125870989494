import { lang } from './lang'
import type { MessageDescriptor } from 'react-intl'

export enum Steps {
    information,
    confirmation,
}

export const modalTitleStepMap: Record<Steps, MessageDescriptor> = {
    [Steps.information]: lang.informationModalTitle,
    [Steps.confirmation]: lang.confirmationModalTitle,
}
