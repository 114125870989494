import { ModalNames } from '~constants/modal'

export interface ConvertData {
    send: {
        value: string
        currency: string | null
    }
    receive: {
        value: string
        currency: string | null
    }
}

export type Steps = ModalNames.convertInput | ModalNames.convertProgress

export const steps: Record<Steps, Steps> = {
    [ModalNames.convertInput]: ModalNames.convertInput,
    [ModalNames.convertProgress]: ModalNames.convertProgress,
} as const
