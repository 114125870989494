import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { Checkbox, Space, Tooltip, Typography } from '@merchant/ui-kit/ant-design'
import type { CheckboxProps } from '@merchant/ui-kit/ant-design'
import type { ReactNode } from 'react'

const { Text } = Typography

interface Props extends CheckboxProps {
    tooltipMessage: ReactNode
    checkboxMessage: ReactNode
}

export function FormItemExtraCheckbox({ id, tooltipMessage, checkboxMessage, ...checkboxProps }: Props) {
    return (
        <Space align="start" style={{ marginTop: 8 }}>
            <Checkbox {...checkboxProps} id={id} />
            <label htmlFor={id} style={{ cursor: 'pointer' }}>
                <Text data-merchant={`${checkboxProps['data-merchant']}-message`}>{checkboxMessage}</Text>
                <Tooltip
                    overlayStyle={{ maxWidth: 350 }}
                    title={
                        <Text
                            type="secondary"
                            style={{ fontSize: 12 }}
                            data-merchant={`${checkboxProps['data-merchant']}-tooltip-title`}
                        >
                            {tooltipMessage}
                        </Text>
                    }
                    placement="bottom"
                    data-merchant={`${checkboxProps['data-merchant']}-tooltip`}
                >
                    <span>
                        <AppSvg
                            size={20}
                            style={{
                                display: 'inline-flex',
                                verticalAlign: 'middle',
                                marginLeft: 2,
                            }}
                            name={assets.questionMark}
                        />
                    </span>
                </Tooltip>
            </label>
        </Space>
    )
}
