import { useIntl, type IntlShape } from 'react-intl'
import type { SWRConfiguration } from 'swr'
import type { ProjectRole } from '~api/instances/cabinet-api'
import type { ProjectRolesMap } from '~api/types'
import { useProjectRoles } from '~api'
import { ownerRole } from '~constants/roles'

function getProjectRolesMap(intl: IntlShape, projectRoles: ProjectRole[] | undefined): ProjectRolesMap {
    const ownerRoleObject: ProjectRole = {
        ...ownerRole,
        name: intl.formatMessage(ownerRole.name),
        description: intl.formatMessage(ownerRole.description),
    }

    return [ownerRoleObject, ...(projectRoles || [])].reduce((acc, role) => {
        acc[role.id] = role

        return acc
    }, {} as ProjectRolesMap)
}

export function useProjectRolesMap(config?: SWRConfiguration) {
    const intl = useIntl()
    const { data: projectRoles, ...restSwr } = useProjectRoles(config)

    const projectRolesMap = getProjectRolesMap(intl, projectRoles)

    return { data: projectRolesMap, ...restSwr }
}
