import { useCommonAppSettings } from '@merchant/shared/api'
import { usePromise } from '@merchant/shared/hooks'
import { negate } from 'lodash-es'
import { useMemo, useState } from 'react'
import { useSWRConfig } from 'swr'
import { useBoolean } from 'usehooks-ts'
import { stepKeys } from './const'
import { useDefaultCurrency, useExtendedHoldingCurrencies, useSelectedCurrencies, useSetupSteps } from './hooks'
import {
    getDefaultSwrConfig,
    getIfSetupIsFinished,
    prepareCurrenciesForSaving,
    sortCurrenciesSkippingFirst,
} from './utils'
import type { CurrencyExtended, NotificationModalName } from './types'
import { projectApi, useCurrencies, useProject } from '~api'
import { Permission, type SetProjectHoldingCurrenciesRequest } from '~api/instances/cabinet-api'
import { apiRestKeys } from '~api/swr/keys'
import { DEFAULT_CONVERSION_CURRENCY_FALLBACK } from '~config/const'
import { useUserPermissionContext } from '~contexts'
import { getIfCurrencyWithdrawable, isFakeFiat } from '~utils'

// eslint-disable-next-line max-lines-per-function
export const useData = ({ onSetupFinish }: { onSetupFinish: () => void }) => {
    const { checkUserPermission } = useUserPermissionContext()
    const [openModalName, setOpenModalName] = useState<NotificationModalName>(null)
    const {
        data: holdingCurrencies,
        isLoading: isLoadingHoldingCurrencies,
        isValidating: isValidatingHoldingCurrencies,
        mutate: mutateHoldingCurrencies,
    } = useExtendedHoldingCurrencies()
    const { mutate } = useSWRConfig()
    const { data: project, isLoading: isLoadingProject } = useProject({
        config: getDefaultSwrConfig(stepKeys[1]),
    })
    const { data: currenciesMap, isLoading: isCurrenciesLoading } = useCurrencies(
        undefined,
        getDefaultSwrConfig(stepKeys[2])
    )
    const { data: siteSettings, isLoading: isCMSConfigLoading } = useCommonAppSettings()
    const { defaultConversionCurrency: cmsSetDefaultCurrencyCode } = siteSettings || {}

    const defaultConfigConversionCurrencyCode = cmsSetDefaultCurrencyCode ?? DEFAULT_CONVERSION_CURRENCY_FALLBACK
    const holdingCurrenciesWithoutFakeFiat = useMemo(
        () => holdingCurrencies?.filter(negate(isFakeFiat)),
        [holdingCurrencies]
    )
    const hasCompletedSetup = getIfSetupIsFinished(holdingCurrenciesWithoutFakeFiat)

    const { defaultCurrency, setDefaultCurrency } = useDefaultCurrency(defaultConfigConversionCurrencyCode)

    const closeNotificationModal = () => setOpenModalName(null)

    const {
        value: isCurrencySettingsModalOpen,
        setTrue: openCurrencySettingsModal,
        setFalse: closeCurrencySettingsModal,
    } = useBoolean(false)

    const { setupStep, toNextStep, setSetupDone } = useSetupSteps()

    const options = sortCurrenciesSkippingFirst(holdingCurrenciesWithoutFakeFiat)

    const { selectedCurrencies, setSelectedCurrencies } = useSelectedCurrencies({
        holdingCurrencies: holdingCurrenciesWithoutFakeFiat,
        currenciesMap,
        defaultCurrency,
    })

    const onRemoteSaveCurrenciesSuccess = () => {
        mutateHoldingCurrencies()
        mutate(apiRestKeys.getHoldingCurrencies, undefined, true)
        if (!hasCompletedSetup) {
            onSetupFinish()
        }
        setSetupDone()
    }

    const { send: remoteSaveCurrencies, isLoading: isSavingCurrencies } = usePromise(
        ({ requestBody, projectId }: SetProjectHoldingCurrenciesRequest) =>
            projectApi.setProjectHoldingCurrencies({
                projectId,
                requestBody,
            }),
        {
            onSuccess: onRemoteSaveCurrenciesSuccess,
            onError: () => {
                // TODO: show appropriate error message
            },
        }
    )

    const onFinishSetup = () => {
        if (!project) {
            return
        }
        const currencyCodes = prepareCurrenciesForSaving(selectedCurrencies, defaultCurrency, currenciesMap)
        remoteSaveCurrencies({
            projectId: project.id,
            requestBody: currencyCodes,
        })
    }

    const onSave = async (currencies?: CurrencyExtended[], newDefaultCurrencyCode?: string) => {
        const currencyCodes = prepareCurrenciesForSaving(
            currencies ?? selectedCurrencies,
            newDefaultCurrencyCode ?? defaultCurrency,
            currenciesMap
        )

        if (project && hasCompletedSetup) {
            await remoteSaveCurrencies({
                projectId: project.id,
                requestBody: currencyCodes,
            })
        } else {
            const currenciesObjects = currencyCodes
                .map(c => options.find(o => o.code === c))
                .filter((o): o is CurrencyExtended => o !== undefined)

            setSelectedCurrencies(currenciesObjects)
            setDefaultCurrency(newDefaultCurrencyCode ?? defaultCurrency)
        }
    }

    const onDefaultCurrencyConfirm = () => {
        const defaultCurrencyObject = options.find(c => c.code === defaultCurrency)
        defaultCurrencyObject && setSelectedCurrencies([defaultCurrencyObject])
        toNextStep()
    }

    const onRestoreDefault = () => {
        const defaultConfigCurrencyObject = options.find(c => c.code === defaultConfigConversionCurrencyCode)
        if (project && defaultConfigCurrencyObject) {
            onSave([defaultConfigCurrencyObject], defaultConfigCurrencyObject.code)
            closeCurrencySettingsModal()
        }
    }

    const onDefaultCurrencyChange = (code: string) => {
        setDefaultCurrency(code)

        const openModalNameSetter = () => {
            const currency = currenciesMap?.[code]
            if (!currency?.crypto && (!project?.fiatWithdrawal || !getIfCurrencyWithdrawable(currency))) {
                return setOpenModalName('fiat_unavailable')
            }

            if (currency?.crypto && !currency.payoutEnabled) {
                return setOpenModalName('crypto_unavailable')
            }

            if (currency?.crypto && currency.payoutEnabled) {
                const isAlreadyInSelectedCurrencies = holdingCurrenciesWithoutFakeFiat?.some(
                    c => c.code === code && c.selected
                )

                return setOpenModalName(isAlreadyInSelectedCurrencies ? null : 'crypto_available')
            }
        }

        openModalNameSetter()
        onSave(undefined, code)
    }

    const isLoadingData = isLoadingHoldingCurrencies || isLoadingProject || isCMSConfigLoading || isCurrenciesLoading

    return {
        isLoadingData,
        isCurrencySettingsModalOpen,
        isSavingCurrencies,
        isValidatingHoldingCurrencies,
        currencyOptions: options,
        currenciesMap,
        selectedCurrencies,
        defaultCurrency,
        onDefaultCurrencyChange,
        openCurrencySettingsModal,
        setupStep,
        toNextStep,
        onFinishSetup,
        onDefaultCurrencyConfirm,
        onRestoreDefault,
        onSave,
        closeCurrencySettingsModal,
        notificationModalName: openModalName,
        closeNotificationModal,
        isCurrencySettingsEditAllowed: checkUserPermission(Permission.ChangeCurrencySettings),
    }
}
