import { Flex } from '@merchant/ui-kit/ant-design'
import { animated } from '@react-spring/web'
import { useSearchParams } from 'react-router-dom'
import { Address, Fieldset, Help, Notes } from './components'
import type { NewDepositAddressState } from '../../../types'
import type { ProjectAddress } from '~api'
import { QueryParams } from '~constants/routes'
import { useAnimateHeight } from '~hooks'

interface Props {
    address: ProjectAddress | undefined
    isLoading: boolean
    state: NewDepositAddressState
    onChange?: (args: NewDepositAddressState) => void
    context: 'existing' | 'new'
    error: unknown
}

export function AddressCard({ address, isLoading, state, onChange, context, error }: Props) {
    const [searchParams, setSearchParams] = useSearchParams()
    const queryCurrency = searchParams.get(QueryParams.depositCurrency)

    const { ref, style } = useAnimateHeight(
        context === 'existing' ? [address] : [state?.currency, state?.network, address, error],
        context
    )

    const newAddressInitialValues = queryCurrency ? { currency: queryCurrency } : undefined
    const existingAddressInitialValues = { currency: address?.currency, network: address?.network }
    const initialFieldsetValues = context === 'existing' ? existingAddressInitialValues : newAddressInitialValues

    return (
        <Flex vertical>
            <Fieldset
                onFinish={onChange}
                context={context}
                isLoading={isLoading}
                initialValues={initialFieldsetValues}
                onChange={changed => {
                    if (changed.currency) {
                        searchParams.set(QueryParams.depositCurrency, changed.currency)
                        setSearchParams(searchParams)
                    }
                }}
            />
            <animated.div style={{ overflow: 'hidden', ...style }}>
                <Flex vertical gap={20} ref={ref} style={{ paddingTop: 20 }}>
                    {!error && (
                        <>
                            <Address address={address} isLoading={isLoading} />
                            <Notes address={address} isLoading={isLoading} />
                            <Help />
                        </>
                    )}
                </Flex>
            </animated.div>
        </Flex>
    )
}
