import { Flex, Skeleton } from '@merchant/ui-kit/ant-design'

export function LoadingSkeleton() {
    return (
        <Flex vertical data-merchant="withdrawal-crypto-loading" gap={16}>
            <Flex vertical gap={4}>
                <Skeleton loading active title={{ style: { width: '40%', margin: '4px 0' } }} paragraph={false} />
                <Skeleton.Input
                    active
                    size="large"
                    style={{ width: '100%', borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                />
                <Skeleton.Input
                    active
                    size="large"
                    style={{ width: '100%', borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
                />
            </Flex>
            <Flex vertical gap={4}>
                <Skeleton loading active title={{ style: { width: '40%', margin: '4px 0' } }} paragraph={false} />
                <Skeleton.Input active size="large" style={{ width: '100%' }} />
            </Flex>
            <Flex vertical gap={4}>
                <Skeleton loading active title={{ style: { width: '40%', margin: '4px 0' } }} paragraph={false} />
                <Skeleton.Input active size="large" style={{ width: '100%' }} />
                <Skeleton loading active title={{ width: '30%', style: { margin: '2px 0' } }} paragraph={false} />
            </Flex>
            <Flex vertical gap={2}>
                <Skeleton loading active title={{ style: { margin: '5px 0' } }} paragraph={false} />
                <Skeleton loading active title={{ style: { margin: '4px 0' } }} paragraph={false} />
                <Skeleton loading active title={{ style: { margin: '4px 0' } }} paragraph={false} />
            </Flex>

            <Flex vertical gap={4}>
                <Skeleton loading active title={{ style: { width: '30%' } }} paragraph={false} />
                <Skeleton.Input active size="large" style={{ width: '100%', margin: '2px 0' }} />
            </Flex>
            <Skeleton.Button size="large" style={{ width: '100%' }} />
        </Flex>
    )
}
