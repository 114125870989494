import { useBreakpoint } from '@merchant/shared/hooks'
import { Button, Collapse, Form, Modal, Typography, theme } from '@merchant/ui-kit/ant-design'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useBoolean } from 'usehooks-ts'
import { PaymentCreatedModal } from './components'
import { initFormValues, OrderSettingsType } from './const.tsx'
import { getCollapseItems } from './getCollapseItems.tsx'
import { lang } from './lang.ts'
import { useData } from './useData.ts'
import { useHandlers } from './useHandlers.ts'
import type { NewPaymentFormData } from './types.ts'
import type { Payment, PaymentType } from '~api/instances/cabinet-api'
import { RestrictionAlert, useRestrictionAlertType } from '~features/PaymentConstructor/components'
import { globalLang } from '~globalLang.ts'

const { Text } = Typography
const { useToken } = theme

interface Props {
    type: PaymentType
}

export function OrderConstructor({ type }: Props) {
    const [{ confirm: modalConfirm }, contextHolder] = Modal.useModal()
    const [createdPayment, setCreatedPayment] = useState<Payment>()
    const { token } = useToken()
    const { form, project } = useData()
    const {
        value: isCreatedPaymentModalOpen,
        setFalse: closeCreatedPaymentModal,
        setTrue: openCreatedPaymentModal,
    } = useBoolean(false)
    const { type: restrictionType, isLoading: isLoadingRestrictionType } = useRestrictionAlertType()
    const breakpoints = useBreakpoint()

    const { collapseActiveKey, setCollapseActiveKey, isCreatePaymentLoading, onCreatePayment, onFormFinishFailure } =
        useHandlers({
            project,
            onCreatePaymentSuccess: responsePayment => {
                setCreatedPayment(responsePayment)
                openCreatedPaymentModal()
            },
            modalConfirm,
            breakpoints,
            type,
        })

    return (
        <>
            {contextHolder}
            <PaymentCreatedModal
                open={isCreatedPaymentModalOpen}
                createdPayment={createdPayment}
                afterClose={() => setCreatedPayment(undefined)}
                onCancel={closeCreatedPaymentModal}
            />
            {/* temp solution until currency settings made public */}
            {restrictionType && !isLoadingRestrictionType && <RestrictionAlert type={restrictionType} />}
            <Form<NewPaymentFormData>
                scrollToFirstError={{ block: 'center', behavior: 'smooth' }}
                onFinish={onCreatePayment}
                onFinishFailed={onFormFinishFailure}
                form={form}
                requiredMark={(label, { required }) => (
                    <div>
                        <Text data-merchant={null}>{label}</Text>
                        {!required && (
                            <Text type="secondary" style={{ opacity: 0.5 }} data-merchant={null}>
                                {' · '}
                                <FormattedMessage {...globalLang.optional} />
                            </Text>
                        )}
                    </div>
                )}
                initialValues={initFormValues}
                size="large"
                layout="vertical"
            >
                <Collapse
                    style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        borderRadius: 0,
                    }}
                    defaultActiveKey={[OrderSettingsType.BasicSettings]}
                    onChange={key => setCollapseActiveKey(key as OrderSettingsType[])}
                    activeKey={collapseActiveKey}
                    size="middle"
                    expandIconPosition="end"
                    accordion
                    items={getCollapseItems({
                        token,
                        collapseActiveKey,
                        type,
                        form,
                    })}
                    data-merchant="order-constructor-collapse"
                />
                <Form.Item style={{ marginBlock: '24px 0' }}>
                    <Button
                        loading={isCreatePaymentLoading}
                        data-merchant="create-order-button"
                        block
                        type="primary"
                        htmlType="submit"
                        disabled={isLoadingRestrictionType || !!restrictionType}
                    >
                        <FormattedMessage {...lang.createOrder} />
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}
