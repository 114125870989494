import { lang } from './lang'
import { constructorTypes } from './types'
import type { ConstructorTypes } from './types'
import type { ConfigProviderProps } from '@merchant/ui-kit/ant-design'
import type { MessageDescriptor } from 'react-intl'

export const configProviderTheme: ConfigProviderProps['theme'] = {
    components: {
        Form: { itemMarginBottom: 16 },
        Collapse: {
            contentPadding: 0,
            headerPadding: '20px 0',
        },
        Tooltip: {
            paddingSM: 16,
            paddingXS: 12,
            fontSize: 12,
        },
    },
}

export const typeDescriptionMap: Record<ConstructorTypes, MessageDescriptor> = {
    [constructorTypes.Invoice]: lang.invoiceDescription,
    [constructorTypes.Deposit]: lang.depositDescription,
    [constructorTypes.StaticAddress]: lang.staticAddressDescription,
}

export const constructorTypesLabel: Record<ConstructorTypes, MessageDescriptor> = {
    [constructorTypes.Invoice]: lang.invoice,
    [constructorTypes.Deposit]: lang.deposit,
    [constructorTypes.StaticAddress]: lang.staticAddress,
}
