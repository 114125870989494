import type { Breakpoints } from '@merchant/shared/hooks'
import type { FlexProps } from '@merchant/ui-kit/ant-design'
import type React from 'react'

export const listTitleStyles: React.CSSProperties = { fontWeight: 400 }
export const getEmailSpaceStyles = (breakpoints: Breakpoints): React.CSSProperties => ({
    textAlign: !breakpoints.md ? 'left' : 'right',
})
export const emailDescriptionStyles: React.CSSProperties = { fontSize: 12 }
export const getSelectStyles = ({
    breakpoints,
    disabled = false,
}: {
    breakpoints: Breakpoints
    disabled?: boolean
}) => ({
    maxWidth: !breakpoints.md ? 228 : 260,
    opacity: disabled ? 0.5 : 1,
    flex: 1,
})
export const getCommonFlexProps = (isVerticalAlignment: boolean): Omit<FlexProps, 'children'> => ({
    vertical: isVerticalAlignment,
    flex: 1,
    align: isVerticalAlignment ? 'normal' : 'center',
    style: { minHeight: 40 },
})
