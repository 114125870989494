import type { RuleObject } from '@merchant/ui-kit/ant-design/es/form'

export const protocolValidator = (_: RuleObject, url: string = '') => {
    if (!url || url.startsWith('https://') || url.startsWith('http://')) {
        return Promise.resolve()
    }

    return Promise.reject()
}

export function isTruthy<T>(value: T | undefined | null | false): value is T {
    return Boolean(value)
}
