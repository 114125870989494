import { AmountInput } from '@merchant/shared/components'
import { Checkbox, Form, Space } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import { getAbsoluteLossInputRules } from './utils'
import type { FormItemProps } from '@merchant/ui-kit/ant-design'
import type { DefaultOptionType } from '@merchant/ui-kit/ant-design/es/select'
import { Permission, type LossConfig, type LossConfigAbsolute } from '~api/instances/cabinet-api'
import { BaseCurrencySelect } from '~components'
import { useUserPermissionContext } from '~contexts'

const absoluteLossNames = {
    amount: ['lossConfig', 'absolute', 'amount'],
    currency: ['lossConfig', 'absolute', 'currency'],
} satisfies Record<
    keyof LossConfigAbsolute,
    ['lossConfig', Extract<keyof LossConfig, 'absolute'>, keyof LossConfigAbsolute]
>

interface Props extends FormItemProps {
    isRelativeLossEnabled: boolean
    isAbsoluteLossEnabled: boolean
    onCheckboxChange: (checked: boolean) => void
    isLoading: boolean
    lossConfig: LossConfig | undefined
    baseCurrenciesOptions: DefaultOptionType[] | undefined
    formItemNamePrefix?: string[]
    usageContext: 'payment-constructor' | 'payments-settings'
}

export function AbsoluteLossItem({
    isRelativeLossEnabled,
    isAbsoluteLossEnabled,
    onCheckboxChange,
    isLoading,
    lossConfig,
    baseCurrenciesOptions,
    usageContext,
    ...rest
}: Props) {
    const { checkUserPermission } = useUserPermissionContext()
    const isEditAllowed = checkUserPermission(Permission.ChangePaymentSettingsLossConfig)

    return (
        <Form.Item noStyle {...rest}>
            <Checkbox
                data-merchant={`${usageContext}-underpayment-absolute-checkbox`}
                disabled={!isRelativeLossEnabled || !isEditAllowed}
                style={{ marginBottom: 8 }}
                checked={isAbsoluteLossEnabled}
                onChange={({ target: { checked } }) => onCheckboxChange(checked)}
            >
                <FormattedMessage {...lang.underpaymentAbsoluteCheckboxTitle} />
            </Checkbox>
            <Space.Compact style={{ width: '100%' }}>
                <Form.Item
                    getValueProps={value => ({
                        value: !isAbsoluteLossEnabled ? lossConfig?.absolute?.amount || '' : value,
                    })}
                    style={{ width: '100%', marginBottom: 0 }}
                    name={absoluteLossNames.amount}
                    rules={getAbsoluteLossInputRules(isAbsoluteLossEnabled)}
                >
                    <AmountInput
                        placeholder="0"
                        data-merchant={`${usageContext}-underpayment-absolute-input`}
                        precision={2}
                        disabled={!isAbsoluteLossEnabled || !isEditAllowed}
                    />
                </Form.Item>
                <Form.Item
                    style={{ width: '50%', marginBottom: 0 }}
                    name={absoluteLossNames.currency}
                    rules={[{ required: isAbsoluteLossEnabled }]}
                    getValueProps={value => ({
                        value: !isAbsoluteLossEnabled
                            ? (lossConfig?.absolute?.currency ?? baseCurrenciesOptions?.[0]?.value)
                            : value,
                    })}
                >
                    <BaseCurrencySelect
                        data-merchant={`${usageContext}-underpayment-absolute-currency-select`}
                        loading={isLoading}
                        style={{ width: '100%' }}
                        popupMatchSelectWidth={false}
                        disabled={!isAbsoluteLossEnabled || !isEditAllowed}
                    />
                </Form.Item>
            </Space.Compact>
        </Form.Item>
    )
}
