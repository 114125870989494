import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    editCallbackModalTitle: {
        id: 'edit-callback.modal.title',
        defaultMessage: 'Edit callback URL',
    },
    createCallbackModalTitle: {
        id: 'create-callback.modal.title',
        defaultMessage: 'Set up Callback URL',
    },
    deleteCallbackModalTitle: {
        id: 'delete-callback.modal.title',
        defaultMessage: 'Delete callback URL',
    },
    regenerateCallbackModalTitle: {
        id: 'regenerate-callback.modal.title',
        defaultMessage: 'Regenerate callback public key',
    },
    save: {
        id: 'setup-callback.modal.save_button',
        defaultMessage: 'Save',
    },
    urlPlaceholder: {
        id: 'setup-callback.input.url_placeholder',
        defaultMessage: 'https://...',
    },
    tooltipText: {
        id: 'setup-callback.tooltip.text',
        defaultMessage:
            'Enter the URL where event notifications will be sent. Essential for real-time transaction status tracking.{br}Example: {exampleLink}',
    },
    exampleLink: {
        id: 'setup-callback.tooltip.example_link',
        defaultMessage: 'https://yourSite.com/notificationHandler',
    },
    urlMaskError: {
        id: 'setup-callback.input.url_mask_error',
        defaultMessage: 'Please provide a valid URL',
    },
    permanentlyDeleteLabelPostfix: {
        id: 'delete-callback.input.label',
        defaultMessage: 'to permanently delete callback URL',
    },
    regeneratePublicKeyAlert: {
        id: 'regenerate-callback.alert',
        defaultMessage:
            'Please note, regenerating your public key will deactivate the previous key. Ensure that you update the key in all integrated systems to prevent service interruptions.',
    },
    regenerateButton: {
        id: 'regenerate-callback.button',
        defaultMessage: 'Regenerate',
    },
})
