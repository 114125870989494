import { CloseOutlined } from '@ant-design/icons'
import { Select } from '@merchant/ui-kit/ant-design'
import { type SelectProps, Form, Tag, Typography, type FormListFieldData } from '@merchant/ui-kit/ant-design'
import { FORM_LIST_NAME } from '../../views/AddMembers/const'
import { selectFieldNames } from './const'
import type { ProjectRole } from '~api'

const { Text } = Typography

const getRenderProps = (
    dataMerchant: string,
    selectedRoles: Array<ProjectRole['id']> | undefined
): Pick<SelectProps, 'tagRender' | 'optionRender' | 'dropdownRender'> => ({
    optionRender: option => {
        const optionStringValue = String(option.value ?? '')
        const baseDataMerchant = `${dataMerchant}-dropdown-${optionStringValue}`

        const optionDataMerchant = (selectedRoles || []).includes(optionStringValue)
            ? `${baseDataMerchant}-selected`
            : baseDataMerchant

        return <div data-merchant={optionDataMerchant}>{option.label}</div>
    },
    tagRender: ({ label, closable, value, onClose }) => (
        <Tag
            closable={
                closable && {
                    closeIcon: (
                        <span
                            className="ant-select-selection-item-remove"
                            aria-hidden="true"
                            style={{ userSelect: 'none' }}
                        >
                            <CloseOutlined
                                data-merchant={`${dataMerchant}-tag-close-${value}`}
                                onMouseDown={e => e.stopPropagation()}
                            />
                        </span>
                    ),
                }
            }
            onClose={onClose}
            className="ant-select-selection-item"
            data-merchant={`${dataMerchant}-tag-${value}`}
        >
            <Text data-merchant={null} style={{ alignSelf: 'center' }}>
                {label}
            </Text>
        </Tag>
    ),
})

interface Props extends SelectProps {
    context: 'add' | 'edit'
    roleLabel: string
    name?: FormListFieldData['name']
}

export function RolesSelect({ context, name, roleLabel, 'data-merchant': dataMerchantProp, ...selectProps }: Props) {
    const form = Form.useFormInstance()
    const selectedRoles: Array<ProjectRole['id']> | undefined = Form.useWatch(
        context === 'add' ? [FORM_LIST_NAME, name, 'roles'] : ['roles'],
        form
    )
    const dataMerchant = dataMerchantProp + (isNaN(Number(name)) ? '' : `-${name}`)

    return (
        <Select
            fieldNames={selectFieldNames}
            maxTagCount="responsive"
            mode="multiple"
            showSearch={false}
            placeholder={roleLabel}
            data-merchant={`${dataMerchant}-select`}
            {...getRenderProps(dataMerchant, selectedRoles)}
            {...selectProps}
        />
    )
}
