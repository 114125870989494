import { EllipsisMiddle } from '@merchant/shared/components'
import { useBreakpoint } from '@merchant/shared/hooks'
import { Flex, Table, Typography } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { lang } from '../lang'
import styles from '../style.module.css'
import { columnsMap } from './columns'
import { CustomerTooltipTitle } from './CustomerTooltipTitle'
import type { Breakpoints } from '@merchant/shared/hooks'
import type { ColumnsType } from '@merchant/ui-kit/ant-design/es/table'
import type { PaymentThumb } from '~api/instances/cabinet-api'
import type { Currencies } from '~api/types'
import { useCurrencies } from '~api'
import { EmptyTable, NoValueColumnContent } from '~components'
import { useConditionalTabOpen } from '~hooks'

const { Text, Paragraph } = Typography

const tableStyles: React.CSSProperties = { marginTop: 12 }
const tableRowStyles: React.CSSProperties = { cursor: 'pointer' }
const showMoreStyles: React.CSSProperties = { marginTop: 24 }

const columnGetters = {
    getCreated: () => ({
        title: <FormattedMessage {...lang.created} />,
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (created: PaymentThumb['createdAt']) => columnsMap.created.render(created),
        width: 70,
    }),
    getUpdated: () => ({
        title: <FormattedMessage {...lang.lastUpdated} />,
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        render: (updated: PaymentThumb['updatedAt']) => columnsMap.updated.render(updated),
        width: 70,
    }),
    getStatus: ({ short = false } = {}) => {
        if (short) {
            return {
                dataIndex: 'state',
                key: 'state',
                render: columnsMap.statusShort.render,
                width: 22,
            }
        }

        return {
            title: <FormattedMessage {...lang.status} />,
            dataIndex: 'state',
            key: 'state',
            render: columnsMap.status.render,
            width: 90,
        }
    },
    getOrderAndMerchantId: (breakpoints: Breakpoints) => {
        if (!breakpoints.lg) {
            return [
                {
                    title: (
                        <>
                            <FormattedMessage {...lang.orderId} /> & <FormattedMessage {...lang.merchantOrderId} />
                        </>
                    ),
                    key: 'orderId&MerchantId',
                    render: (_: undefined, record: PaymentThumb) => (
                        <Flex vertical>
                            <EllipsisMiddle
                                prefixCount={11}
                                suffixCount={0}
                                divider="."
                                data-merchant={`orders-table-id-${record.id}`}
                            >
                                {columnsMap.orderId.render(record.id)}
                            </EllipsisMiddle>
                            <Text type="secondary" data-merchant="orders-table-origin">
                                {columnsMap.source.render(record.origin)}
                            </Text>
                            <EllipsisMiddle
                                prefixCount={11}
                                suffixCount={0}
                                divider="."
                                data-merchant={`orders-table-orderId-${record.orderId}`}
                            >
                                {columnsMap.merchantOrderId.render(record.orderId)}
                            </EllipsisMiddle>
                        </Flex>
                    ),
                    ellipsis: true,
                    width: 90,
                },
            ]
        }

        return [
            {
                title: <FormattedMessage {...lang.orderId} />,
                key: 'id',
                width: 85,
                render: (_: undefined, record: PaymentThumb) => (
                    <Flex vertical>
                        <Text ellipsis data-merchant="orders-table-id">
                            {columnsMap.orderId.render(record.id)}
                        </Text>
                        <Text type="secondary" data-merchant="orders-table-origin">
                            {columnsMap.source.render(record.origin)}
                        </Text>
                    </Flex>
                ),
            },
            {
                title: <FormattedMessage {...lang.merchantOrderId} />,
                dataIndex: 'orderId',
                key: 'orderId',
                width: 110,
                ellipsis: true,
                render: columnsMap.merchantOrderId.render,
            },
        ]
    },
    getDetailsAndCustomer: (breakpoints: Breakpoints) => {
        if (!breakpoints.lg) {
            return [
                {
                    title: (
                        <>
                            <FormattedMessage {...lang.orderDetails} /> & <FormattedMessage {...lang.client} />
                        </>
                    ),
                    key: 'details&customer',
                    render: (_: undefined, record: PaymentThumb) =>
                        !record.customerEmail && !record.customerId && !record.description && !record.title ? (
                            NoValueColumnContent
                        ) : (
                            <Paragraph
                                data-merchant={`orders-table-details-${record.id}`}
                                ellipsis={{
                                    tooltip: {
                                        'data-merchant': `orders-table-details-tooltip-${record.id}`,
                                        placement: 'bottom',
                                        showArrow: true,
                                        overlayInnerStyle: { minWidth: 280 },
                                        title: (
                                            <Flex vertical gap={12} onClick={e => e.stopPropagation()}>
                                                <div>{columnsMap.details.render(record, { isTruncated: false })}</div>
                                                <CustomerTooltipTitle record={record} />
                                            </Flex>
                                        ),
                                    },
                                    rows: 1,
                                }}
                                style={{ border: 'none', marginBottom: 0 }}
                            >
                                <div>{columnsMap.details.render(record, { isTruncated: false })}</div>
                                <div>{columnsMap.customerEmail.render(record.customerEmail)}</div>
                                <div>{columnsMap.customerId.render(record.customerId)}</div>
                            </Paragraph>
                        ),
                    width: 100,
                },
            ]
        }

        return [
            {
                title: <FormattedMessage {...lang.orderDetails} />,
                key: 'details',
                render: (_: unknown, record: PaymentThumb) => columnsMap.details.render(record),
                width: 100,
            },
            {
                title: <FormattedMessage {...lang.client} />,
                key: 'customer',
                width: 100,
                render: (_: undefined, record: PaymentThumb) => {
                    if (!record.customerEmail && !record.customerId) {
                        return NoValueColumnContent
                    }

                    const customerEmailElement = columnsMap.customerEmail.render(record.customerEmail)
                    const customerIdElement = columnsMap.customerId.render(record.customerId)

                    return (
                        <Paragraph
                            data-merchant={`orders-table-customer-${record.id}`}
                            style={{ marginBottom: 0 }}
                            ellipsis={{
                                tooltip: {
                                    'data-merchant': `orders-table-customer-tooltip-${record.id}`,
                                    placement: 'bottom',
                                    overlayInnerStyle: { minWidth: 280 },
                                    title: <CustomerTooltipTitle record={record} />,
                                },
                            }}
                        >
                            <Text data-merchant={null}>{customerEmailElement}</Text>
                            <br />
                            <Text data-merchant={null} type="secondary">
                                {customerIdElement}
                            </Text>
                        </Paragraph>
                    )
                },
                ellipsis: true,
            },
        ]
    },
    getPriceAndPayAmount: (currencies: Currencies, breakpoints: Breakpoints) => {
        if (!breakpoints.lg) {
            return [
                {
                    title: (
                        <>
                            <FormattedMessage {...lang.price} /> & <FormattedMessage {...lang.payAmount} />
                        </>
                    ),
                    key: 'price&payAmount',
                    render: (_: undefined, record: PaymentThumb) => (
                        <Flex vertical>
                            {columnsMap.price.render(record)}
                            {columnsMap.payAmount.render(currencies)(record)}
                        </Flex>
                    ),
                    width: 110,
                },
            ]
        }

        return [
            {
                title: <FormattedMessage {...lang.price} />,
                key: 'price',
                render: columnsMap.price.render,
                width: 110,
            },
            {
                title: <FormattedMessage {...lang.payAmount} />,
                key: 'payAmount',
                render: columnsMap.payAmount.render(currencies),
                width: 110,
            },
        ]
    },
    getReceived: () => ({
        title: <FormattedMessage {...lang.received} />,
        key: 'paidAmount',
        align: 'right',
        render: columnsMap.receivedAmount.render,
        width: 120,
    }),
}

const getColumns = (currencies: Currencies | undefined, breakpoints: Breakpoints): ColumnsType<PaymentThumb> => {
    if (!currencies) {
        return []
    }

    if (!breakpoints.xl) {
        return [
            columnGetters.getStatus({ short: true }),
            columnGetters.getCreated(),
            columnGetters.getUpdated(),
            ...columnGetters.getOrderAndMerchantId(breakpoints),
            ...columnGetters.getDetailsAndCustomer(breakpoints),
            ...columnGetters.getPriceAndPayAmount(currencies, breakpoints),
            columnGetters.getReceived(),
        ]
    } else {
        return [
            columnGetters.getCreated(),
            columnGetters.getUpdated(),
            columnGetters.getStatus(),
            ...columnGetters.getOrderAndMerchantId(breakpoints),
            ...columnGetters.getDetailsAndCustomer(breakpoints),
            ...columnGetters.getPriceAndPayAmount(currencies, breakpoints),
            columnGetters.getReceived(),
        ]
    }
}

interface Props {
    orders: PaymentThumb[]
    showMoreButton: React.ReactNode
    isLoading: boolean
    isDataEmpty: boolean
}

export function TableView({ orders, showMoreButton, isLoading, isDataEmpty }: Props) {
    const breakpoints = useBreakpoint()
    const { data: currencies } = useCurrencies()
    const { handleConditionalTabOpen } = useConditionalTabOpen()

    return (
        <>
            <Table
                locale={{
                    emptyText: <EmptyTable subtitleDescriptor={lang.noOrdersSubtitle} />,
                }}
                data-merchant="orders-table"
                className={styles.table}
                style={tableStyles}
                showHeader={!isDataEmpty}
                columns={getColumns(currencies, breakpoints)}
                dataSource={orders}
                tableLayout="fixed"
                rowKey={record => record.id}
                pagination={false}
                scroll={{ y: 740, x: 200 }}
                loading={isLoading}
                onRow={({ id }) => {
                    const handleClick = handleConditionalTabOpen([({ pathname }) => `${pathname}/${id}`, id])

                    return {
                        'data-merchant': `${id}-orders-table-row`,
                        onClick: handleClick,
                        onAuxClick: handleClick,
                        style: tableRowStyles,
                    }
                }}
            />
            <div style={showMoreStyles}>{showMoreButton}</div>
        </>
    )
}
