import { ModalResponsive } from '@merchant/shared/components'
import { usePromise } from '@merchant/shared/hooks'
import { Button, Form, Input, type ModalProps } from '@merchant/ui-kit/ant-design'
import { FormattedMessage, useIntl } from 'react-intl'
import { lang } from './lang'
import type { Merchant } from '~api/instances/cabinet-api'
import { globalLang } from '~globalLang'

interface EditNameForm {
    name: Merchant['name']
}

interface Props extends Omit<ModalProps, 'data-merchant'> {
    request: (name: Merchant['name']) => Promise<void>
    initialValue: Merchant['name']
}

export function EditNameModal({ request, initialValue, ...rest }: Props) {
    const intl = useIntl()
    const { send, isLoading } = usePromise((values: EditNameForm) => request(values.name))

    const nameLabel = intl.formatMessage(lang.name)

    return (
        <ModalResponsive
            destroyOnClose
            title={<FormattedMessage {...lang.modalTitle} />}
            footer={null}
            data-merchant="settings-name-modal"
            {...rest}
        >
            <Form<EditNameForm> onFinish={send} size="large" layout="vertical" initialValues={{ name: initialValue }}>
                <Form.Item
                    name="name"
                    label={nameLabel}
                    rules={[
                        {
                            required: true,
                            message: (
                                <span data-merchant="settings-name-modal-name-required-error">
                                    <FormattedMessage {...globalLang.requiredFieldMessage} />
                                </span>
                            ),
                        },
                    ]}
                >
                    <Input placeholder={nameLabel} data-merchant="settings-name-modal-name-input" />
                </Form.Item>
                <Form.Item noStyle>
                    <Button
                        type="primary"
                        block
                        htmlType="submit"
                        loading={isLoading}
                        data-merchant="settings-name-modal-submit-button"
                    >
                        <FormattedMessage {...lang.modalSubmitButton} />
                    </Button>
                </Form.Item>
            </Form>
        </ModalResponsive>
    )
}
