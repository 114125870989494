import type { ReactNode } from 'react'
import type { WithdrawalAmountReceive, WithdrawalAmountCredit } from '~api'
import { ModalNames } from '~constants/modal'

export type WithdrawalAmountMode = keyof (WithdrawalAmountCredit & WithdrawalAmountReceive)

export type WithdrawalCryptoFormData = {
    amount: WithdrawalAmountCredit | WithdrawalAmountReceive
    amountMode: WithdrawalAmountMode
    fee?: string
    currency?: string
    receiveAmount?: string
    withdrawalAmount?: string
    network?: string
    whitelistKey?: string
    address?: string
    addressTag?: string
    note?: string
}

export const steps = {
    [ModalNames.withdrawalCryptoInput]: ModalNames.withdrawalCryptoInput,
    [ModalNames.withdrawalCryptoSummary]: ModalNames.withdrawalCryptoSummary,
    [ModalNames.withdrawalCryptoConfirmation]: ModalNames.withdrawalCryptoConfirmation,
    [ModalNames.withdrawalCryptoProgress]: ModalNames.withdrawalCryptoProgress,
} as const
type ContentRowWithDivider = {
    type: 'DIVIDER'
    label?: never
    content?: never
}
type ContentRowWithContent = {
    type?: never
    label: ReactNode
    content: ReactNode
}

export type ContentRow = (ContentRowWithDivider | ContentRowWithContent) & {
    shouldSkip?: boolean
}
