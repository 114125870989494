import { useBreakpoint } from '@merchant/shared/hooks'
import { Spin, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import { Orders } from './views'
import { useProject } from '~api'
import { PageContentContainer } from '~components'
import { PlaceholderView } from '~features'

const { Title, Text } = Typography

export function OrdersPage() {
    const { data: project, isLoading } = useProject()
    const breakpoints = useBreakpoint()
    const commonContainerStyles = {
        body: { padding: breakpoints.md ? undefined : 12 },
    }

    let content = null

    if (isLoading) {
        content = (
            <PageContentContainer style={{ height: 300, display: 'grid', placeItems: 'center' }}>
                <Spin size="large" data-merchant="order-loading-spin" />
            </PageContentContainer>
        )
    } else if (!project) {
        content = (
            <PageContentContainer styles={commonContainerStyles}>
                <PlaceholderView buttonDataMerchant="orders-page-no-merchant" />
            </PageContentContainer>
        )
    } else {
        content = (
            <PageContentContainer styles={commonContainerStyles}>
                <Orders />
            </PageContentContainer>
        )
    }

    return (
        <>
            <Text style={{ opacity: 0.4 }} data-merchant={null}>
                <FormattedMessage {...lang.orders} />
            </Text>
            <Title level={4} data-merchant={null}>
                <FormattedMessage {...lang.pageTitle} />
            </Title>
            {content}
        </>
    )
}
