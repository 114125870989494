import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    waitingForApproval: {
        id: 'withdrawal.state.waiting_for_approval',
        defaultMessage: 'Waiting for approval',
    },
    inProgress: {
        id: 'withdrawal.state.in_progress',
        defaultMessage: 'Withdrawal in progress',
    },
    success: {
        id: 'withdrawal.state.success',
        defaultMessage: 'Withdrawal completed',
    },
    canceled: {
        id: 'withdrawal.state.canceled',
        defaultMessage: 'Withdrawal canceled',
    },
    declined: {
        id: 'withdrawal.state.declined',
        defaultMessage: 'Withdrawal declined',
    },
    failed: {
        id: 'withdrawal.state.failed',
        defaultMessage: 'Withdrawal failed',
    },
})
