import { AppSvg } from '@merchant/shared/components'
import { parseDateAndTime } from '@merchant/shared/utils'
import { Card, Flex, theme, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { withdrawalStatesMap } from './const'
import type { CardProps } from '@merchant/ui-kit/ant-design'
import type { Withdrawal } from '~api'

const { Text } = Typography
const { useToken } = theme

interface Props extends CardProps {
    data: {
        state: Withdrawal['state'] | undefined
        amount: Withdrawal['creditAmount'] | undefined
        currency: Withdrawal['creditCurrency'] | undefined
        updatedAt: Withdrawal['updatedAt'] | undefined
    }
}

export function WithdrawalState({ data: { state, amount, currency, updatedAt }, ...rest }: Props) {
    const { token } = useToken()
    if (!state || !amount || !currency || !updatedAt) {
        return null
    }

    const { color, icon, title } = withdrawalStatesMap[state]
    const { date: updatedDate, time: updatedTime } = parseDateAndTime(updatedAt) || {}

    return (
        <Card {...rest}>
            <Flex vertical align="center" gap={8}>
                <AppSvg size={48} name={icon} color={token[color]} />
                <Text style={{ fontSize: 20, fontWeight: 500 }} data-merchant="withdrawal-state">
                    <FormattedMessage {...title} />
                </Text>
                <Text style={{ fontSize: 20, fontWeight: 500 }} data-merchant="withdrawal-amount">
                    -{amount} {currency}
                </Text>

                <Flex>
                    <Text data-merchant="withdrawal-updated-date">{updatedDate}</Text>
                    &nbsp;
                    <Text data-merchant="withdrawal-updated-time" type="secondary">
                        {updatedTime}
                    </Text>
                </Flex>
            </Flex>
        </Card>
    )
}
