import { Button, type ButtonProps } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { lang } from './lang'
import type { Currency } from '~api/instances/cabinet-api'
import { routes } from '~constants/routes'
import { WithdrawalsShowOptions } from '~features/WithdrawalsPage/const'
import { generateQuery } from '~utils'

interface Props extends ButtonProps {
    asOption?: boolean
    selectedCurrencyCode: Currency['code'] | undefined
}

export function AddWhitelistAddressButton({ asOption = true, selectedCurrencyCode, style, ...rest }: Props) {
    const navigate = useNavigate()
    const handleClick = () => {
        const to = `${routes.withdrawals}${generateQuery({ show: WithdrawalsShowOptions.whitelist })}`
        navigate(to)
    }

    return (
        <Button
            block
            type="link"
            style={{
                padding: `0 ${asOption ? '12px' : '0px'}`,
                textAlign: 'left',
                ...style,
            }}
            onClick={handleClick}
            {...rest}
        >
            +&nbsp;
            <FormattedMessage {...lang.addNewAddressButton} values={{ selectedCurrency: selectedCurrencyCode }} />
        </Button>
    )
}
