/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreatePaymentRequestAllOfPaymentMethod } from './CreatePaymentRequestAllOfPaymentMethod';
import {
    CreatePaymentRequestAllOfPaymentMethodFromJSON,
    CreatePaymentRequestAllOfPaymentMethodFromJSONTyped,
    CreatePaymentRequestAllOfPaymentMethodToJSON,
} from './CreatePaymentRequestAllOfPaymentMethod';
import type { FeesPayer } from './FeesPayer';
import {
    FeesPayerFromJSON,
    FeesPayerFromJSONTyped,
    FeesPayerToJSON,
} from './FeesPayer';
import type { LossConfig } from './LossConfig';
import {
    LossConfigFromJSON,
    LossConfigFromJSONTyped,
    LossConfigToJSON,
} from './LossConfig';
import type { NominalOrPayinAmount } from './NominalOrPayinAmount';
import {
    NominalOrPayinAmountFromJSON,
    NominalOrPayinAmountFromJSONTyped,
    NominalOrPayinAmountToJSON,
} from './NominalOrPayinAmount';
import type { PaymentType } from './PaymentType';
import {
    PaymentTypeFromJSON,
    PaymentTypeFromJSONTyped,
    PaymentTypeToJSON,
} from './PaymentType';

/**
 * 
 * @export
 * @interface CreatePaymentRequest
 */
export interface CreatePaymentRequest {
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentRequest
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentRequest
     */
    successRedirectUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentRequest
     */
    failureRedirectUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentRequest
     */
    orderId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentRequest
     */
    customerId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentRequest
     */
    customerEmail?: string;
    /**
     * Freehand metadata associated with the payment
     * @type {{ [key: string]: any; }}
     * @memberof CreatePaymentRequest
     */
    otherData?: { [key: string]: any; };
    /**
     * 
     * @type {PaymentType}
     * @memberof CreatePaymentRequest
     */
    type: PaymentType;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentRequest
     */
    nominalCurrency: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentRequest
     */
    nominalAmount?: string;
    /**
     * 
     * @type {NominalOrPayinAmount}
     * @memberof CreatePaymentRequest
     */
    recommendedAmount?: NominalOrPayinAmount;
    /**
     * 
     * @type {FeesPayer}
     * @memberof CreatePaymentRequest
     */
    feesPayer?: FeesPayer;
    /**
     * 
     * @type {LossConfig}
     * @memberof CreatePaymentRequest
     */
    lossConfig?: LossConfig;
    /**
     * 
     * @type {Date}
     * @memberof CreatePaymentRequest
     */
    pendingDeadlineAt?: Date;
    /**
     * 
     * @type {CreatePaymentRequestAllOfPaymentMethod}
     * @memberof CreatePaymentRequest
     */
    paymentMethod?: CreatePaymentRequestAllOfPaymentMethod;
}

/**
 * Check if a given object implements the CreatePaymentRequest interface.
 */
// @ts-ignore
export function instanceOfCreatePaymentRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "nominalCurrency" in value;

    return isInstance;
}

// @ts-ignore
export function CreatePaymentRequestFromJSON(json: any): CreatePaymentRequest {
    return CreatePaymentRequestFromJSONTyped(json, false);
}

// @ts-ignore
export function CreatePaymentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePaymentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'successRedirectUrl': !exists(json, 'success_redirect_url') ? undefined : json['success_redirect_url'],
        'failureRedirectUrl': !exists(json, 'failure_redirect_url') ? undefined : json['failure_redirect_url'],
        'orderId': !exists(json, 'order_id') ? undefined : json['order_id'],
        'customerId': !exists(json, 'customer_id') ? undefined : json['customer_id'],
        'customerEmail': !exists(json, 'customer_email') ? undefined : json['customer_email'],
        'otherData': !exists(json, 'other_data') ? undefined : json['other_data'],
        'type': PaymentTypeFromJSON(json['type']),
        'nominalCurrency': json['nominal_currency'],
        'nominalAmount': !exists(json, 'nominal_amount') ? undefined : json['nominal_amount'],
        'recommendedAmount': !exists(json, 'recommended_amount') ? undefined : NominalOrPayinAmountFromJSON(json['recommended_amount']),
        'feesPayer': !exists(json, 'fees_payer') ? undefined : FeesPayerFromJSON(json['fees_payer']),
        'lossConfig': !exists(json, 'loss_config') ? undefined : LossConfigFromJSON(json['loss_config']),
        'pendingDeadlineAt': !exists(json, 'pending_deadline_at') ? undefined : (new Date(json['pending_deadline_at'])),
        'paymentMethod': !exists(json, 'payment_method') ? undefined : CreatePaymentRequestAllOfPaymentMethodFromJSON(json['payment_method']),
    };
}

// @ts-ignore
export function CreatePaymentRequestToJSON(value?: CreatePaymentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'description': value.description,
        'success_redirect_url': value.successRedirectUrl,
        'failure_redirect_url': value.failureRedirectUrl,
        'order_id': value.orderId,
        'customer_id': value.customerId,
        'customer_email': value.customerEmail,
        'other_data': value.otherData,
        'type': PaymentTypeToJSON(value.type),
        'nominal_currency': value.nominalCurrency,
        'nominal_amount': value.nominalAmount,
        'recommended_amount': NominalOrPayinAmountToJSON(value.recommendedAmount),
        'fees_payer': FeesPayerToJSON(value.feesPayer),
        'loss_config': LossConfigToJSON(value.lossConfig),
        'pending_deadline_at': value.pendingDeadlineAt === undefined ? undefined : (value.pendingDeadlineAt.toISOString()),
        'payment_method': CreatePaymentRequestAllOfPaymentMethodToJSON(value.paymentMethod),
    };
}

