import type { WithdrawalFiatFormData } from './types'

export function isFiatWithdrawalDataFulfilled(data: Partial<WithdrawalFiatFormData>): data is WithdrawalFiatFormData {
    return (
        data.amount !== undefined &&
        data.currency !== undefined &&
        data.method !== undefined &&
        data.requisites !== undefined &&
        data.fee !== undefined
    )
}
