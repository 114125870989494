import { selectedProjectIdKey } from '@merchant/shared/constants/localStorage'
import { useNotifications } from '@merchant/shared/contexts'
import { usePromise } from '@merchant/shared/hooks'
import { useIntl } from 'react-intl'
import { useSWRConfig } from 'swr'
import { useLocalStorage } from 'usehooks-ts'
import { lang } from './lang'
import type { ProjectGeneralFormData } from './types'
import type { ListProjectsResponse } from '~api/instances/cabinet-api'
import { projectApi, useProject } from '~api'
import { FeesPayer, ProjectStatus, ProjectStatusUpdate } from '~api/instances/cabinet-api'
import { apiRestKeys } from '~api/swr/keys'

const DEFAULT_FEES_PAYER = FeesPayer.Merchant as const

const projectStatusMap: Record<ProjectStatusUpdate, ProjectStatus> = {
    [ProjectStatusUpdate.Active]: ProjectStatus.Active,
    [ProjectStatusUpdate.Inactive]: ProjectStatus.Inactive,
}

interface Props {
    openModal: () => void
}

export function useData({ openModal }: Props) {
    const [, setSelectedProjectId] = useLocalStorage(selectedProjectIdKey, '')
    const intl = useIntl()
    const { mutate: configMutate } = useSWRConfig()
    const { data: project, mutate: mutateProject, isLoading: isProjectLoading } = useProject()
    const {
        message: { api: messageApi },
    } = useNotifications()

    const { send: handleSaveProject, isLoading: isSaveProjectLoading } = usePromise(
        async (data: ProjectGeneralFormData) => {
            if (project) {
                return projectApi.updateProject({
                    projectId: project.id || '',
                    updateProjectRequest: data,
                })
            } else {
                return projectApi.createProject({
                    createProjectRequest: {
                        feesPayer: DEFAULT_FEES_PAYER,
                        link: data.link || '',
                        name: data.name || '',
                        status: projectStatusMap[data.status],
                        description: data.description,
                        supportEmail: data.supportEmail,
                    },
                })
            }
        },
        {
            onSuccess: result => {
                setSelectedProjectId(result.id)
                mutateProject(result)
                configMutate(apiRestKeys.getProjects, (prev: ListProjectsResponse | undefined) => prev)
                if (!project) {
                    openModal()
                } else {
                    messageApi.success({
                        props: {
                            'data-merchant': 'merchant-page-success-change-message',
                        },
                        content: intl.formatMessage(lang.successChangeNotification),
                    })
                }
            },
        }
    )

    return {
        project,
        isProjectLoading,
        handleSaveProject,
        isSaveProjectLoading,
    }
}
