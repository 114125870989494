import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    exportCsv: {
        id: 'transactions-page.button.export_csv',
        defaultMessage: 'Export .csv',
    },
    searchInputPlaceholder: {
        id: 'transactions-page.input.search_input_placeholder',
        defaultMessage: 'Search ID',
    },
    allTransactions: {
        id: 'transactions-page.select.all_transactions',
        defaultMessage: 'All transactions',
    },
    filters: {
        id: 'transactions-page.typography.filters',
        defaultMessage: 'Filters',
    },
    transactionType: {
        id: 'transactions-page.select.transaction_type',
        defaultMessage: 'Transaction type',
    },
    status: {
        id: 'transactions-page.select.status',
        defaultMessage: 'Status',
    },
    id: {
        id: 'transactions-page.input.id',
        defaultMessage: 'ID',
    },
    apply: {
        id: 'transactions-page.button.apply',
        defaultMessage: 'Apply',
    },
    clearAll: {
        id: 'transactions-page.button.clear_all',
        defaultMessage: 'Clear all',
    },
})
