import { Card, Space } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { stylesMap, commonStyle } from './const'
import type { Placement } from './types'
import type { CardProps, SpaceProps } from '@merchant/ui-kit/ant-design'

interface CompactCardProps {
    placement: Placement
    children: React.ReactNode
    compactCardStyles?: CardProps['styles']
    dataMerchantPrefix: string
}

function CompactCard({ placement, children, compactCardStyles, dataMerchantPrefix }: CompactCardProps) {
    const { style = {}, styles = {} } = stylesMap[placement]

    return (
        <Card
            bordered={false}
            style={{ ...commonStyle, ...style }}
            styles={{ ...styles, ...compactCardStyles }}
            data-merchant={`${dataMerchantPrefix}-card`}
        >
            {children}
        </Card>
    )
}

const spaceWrapperStyles: React.CSSProperties = { display: 'flex' }

interface Props {
    gap?: SpaceProps['size']
    children: Array<React.ReactNode> | React.ReactNode
    compactCardStyles?: CardProps['styles']
    dataMerchantPrefix: string
}

export function CompactCardsWrapper({ gap, children, compactCardStyles, dataMerchantPrefix }: Props) {
    const nonEmptyChildren = Array.isArray(children) ? children.filter(Boolean).flat(1) : [children]

    if (nonEmptyChildren.length === 1) {
        return (
            <Card
                bordered={false}
                style={commonStyle}
                styles={compactCardStyles ? compactCardStyles : { body: { paddingInline: 20 } }}
                data-merchant={`${dataMerchantPrefix}-cards`}
            >
                {nonEmptyChildren[0]}
            </Card>
        )
    }

    return (
        <Space direction="vertical" style={spaceWrapperStyles} size={gap}>
            {nonEmptyChildren.map((content, index) => {
                let placement: Placement = 'middle'
                if (index === 0) {
                    placement = 'top'
                } else if (index === nonEmptyChildren.length - 1) {
                    placement = 'bottom'
                }

                return (
                    <CompactCard
                        placement={placement}
                        key={index}
                        compactCardStyles={compactCardStyles}
                        dataMerchantPrefix={`${dataMerchantPrefix}-${index}`}
                    >
                        {content}
                    </CompactCard>
                )
            })}
        </Space>
    )
}
