import { sortWithUndefinedAtBottom } from './array'
import { isKeyOfObject } from '.'
import type { NetworksOrder } from '~api/instances/host-api/types'
import type { CurrenciesBase } from '~types'

export function getNetworksOrderedByCMS<N extends { code: string }>(
    networks: N[] = [],
    order: NetworksOrder | undefined
) {
    if (!order) {
        return networks
    }

    const withOrderField = networks.map(network => {
        return {
            ...network,
            order: order[network.code],
        }
    })

    return sortWithUndefinedAtBottom({ data: withOrderField, field: 'order', direction: 'asc' })
}

export function getNetworkData<T extends CurrenciesBase>(
    currency: string | undefined,
    network: string | undefined,
    currencies: T | undefined
): NonNullable<NonNullable<T>[string]>['networks'][number] | undefined {
    if (!currencies || !isKeyOfObject(currency, currencies) || !network || !currency) {
        return undefined
    }

    return currencies[currency]?.networks.find(n => n.code === network)
}
