import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import type { ReactNode } from 'react'

const hoursNumberFormat = new Intl.NumberFormat('en', {
    style: 'unit',
    unit: 'hour',
    compactDisplay: 'long',
    unitDisplay: 'long',
})

export const expirationTimeOptions = ['1', '2', '3', '6', '12', '24', 'custom'] as const

export const expirationTimeOptionsLabelMap: Record<(typeof expirationTimeOptions)[number], ReactNode> = {
    '1': hoursNumberFormat.format(1),
    '2': hoursNumberFormat.format(2),
    '3': hoursNumberFormat.format(3),
    '6': hoursNumberFormat.format(6),
    '12': hoursNumberFormat.format(12),
    '24': hoursNumberFormat.format(24),
    custom: <FormattedMessage {...lang.custom} />,
}

export const defaultExpirationTime: (typeof expirationTimeOptions)[number] = '24'
