import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import type { NotificationMessage } from '@merchant/shared/components'
import type { ComponentProps } from 'react'

type NotificationMessageType = 'delete' | 'confirm'

export const notificationMessageProps: Record<NotificationMessageType, ComponentProps<typeof NotificationMessage>> = {
    delete: {
        type: 'success',
        title: <FormattedMessage {...lang.deleteTwoFaNotificationSuccessMessage} />,
        description: <FormattedMessage {...lang.deleteTwoFaNotificationSuccessDescription} />,
        dataMerchant: 'delete-2fa-success-notification',
    },
    confirm: {
        type: 'success',
        title: <FormattedMessage {...lang.confirmTwoFaNotificationSuccessMessage} />,
        description: <FormattedMessage {...lang.confirmTwoFaNotificationSuccessDescription} />,
        dataMerchant: 'confirm-2fa-success-notification',
    },
}
