import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    confirmationRequiredFor: {
        id: 'settings-page.unconfirmed-two-fa.confirmation_required_for',
        defaultMessage: 'Confirmation required for {totpName}',
    },
    buttonTitle: {
        id: 'settings-page.unconfirmed-two-fa.button_title',
        defaultMessage: 'Confirm',
    },
})
