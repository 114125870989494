import { MerchantSettingsShowOptions, type MerchantSettingsPageOption } from './types'
import type { useUserPermissionContext } from '~contexts'
import { Permission, type Project } from '~api/instances/cabinet-api'

const getStepStatusLabel = (stepItemValue: number, currentStepValue: number) => {
    if (stepItemValue < currentStepValue) {
        return 'finish'
    } else if (stepItemValue === currentStepValue) {
        return 'active'
    }

    return 'wait'
}

export const getStepItemDataMerchant = (stepItemValue: number, currentStepValue: number) =>
    // currentStepValue + 1 is used because the current step is 0-based
    `merchant-step-${stepItemValue}-${getStepStatusLabel(stepItemValue, currentStepValue + 1)}`

export const getMerchantPageOptions = (
    project: Project | undefined,
    isProjectLoading: boolean,
    checkUserPermission: ReturnType<typeof useUserPermissionContext>['checkUserPermission']
): MerchantSettingsPageOption[] => [
    { value: MerchantSettingsShowOptions.general },
    {
        value: MerchantSettingsShowOptions.assets,
        disabled: !project && !isProjectLoading,
    },
    {
        value: MerchantSettingsShowOptions.paymentsSettings,
        disabled: (!project && !isProjectLoading) || !checkUserPermission(Permission.SeePaymentSettings),
    },
]
