/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FiatWithdrawalFeeType } from './FiatWithdrawalFeeType';
import {
    FiatWithdrawalFeeTypeFromJSON,
    FiatWithdrawalFeeTypeFromJSONTyped,
    FiatWithdrawalFeeTypeToJSON,
} from './FiatWithdrawalFeeType';

/**
 * 
 * @export
 * @interface FiatWithdrawalMethod
 */
export interface FiatWithdrawalMethod {
    /**
     * 
     * @type {string}
     * @memberof FiatWithdrawalMethod
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FiatWithdrawalMethod
     */
    fee: string;
    /**
     * 
     * @type {string}
     * @memberof FiatWithdrawalMethod
     */
    requisites: string;
    /**
     * 
     * @type {FiatWithdrawalFeeType}
     * @memberof FiatWithdrawalMethod
     */
    feeType: FiatWithdrawalFeeType;
}

/**
 * Check if a given object implements the FiatWithdrawalMethod interface.
 */
// @ts-ignore
export function instanceOfFiatWithdrawalMethod(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "fee" in value;
    isInstance = isInstance && "requisites" in value;
    isInstance = isInstance && "feeType" in value;

    return isInstance;
}

// @ts-ignore
export function FiatWithdrawalMethodFromJSON(json: any): FiatWithdrawalMethod {
    return FiatWithdrawalMethodFromJSONTyped(json, false);
}

// @ts-ignore
export function FiatWithdrawalMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): FiatWithdrawalMethod {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'fee': json['fee'],
        'requisites': json['requisites'],
        'feeType': FiatWithdrawalFeeTypeFromJSON(json['fee_type']),
    };
}

// @ts-ignore
export function FiatWithdrawalMethodToJSON(value?: FiatWithdrawalMethod | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'fee': value.fee,
        'requisites': value.requisites,
        'fee_type': FiatWithdrawalFeeTypeToJSON(value.feeType),
    };
}

