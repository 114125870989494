import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    emailCodePlaceholder: {
        id: 'add-two-fa-modal.input.email_code_placeholder',
        defaultMessage: 'Email code',
    },
    previousTwoFaLabel: {
        id: 'add-two-fa-modal.input.previous_two_fa_label',
        defaultMessage: 'Enter a previous 6-digit 2FA code from the app',
    },
})
