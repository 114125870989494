import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    pageTitle: {
        id: 'integrations-page.typography.page_title',
        defaultMessage: 'Integrations',
    },
    apiKeysPageView: {
        id: 'integrations-page.typography.api_keys_page_view',
        defaultMessage: 'API Keys',
    },
    configureUrlsPageView: {
        id: 'integrations-page.typography.configure_urls_page_view',
        defaultMessage: 'Configure URLs',
    },
    getApiDocs: {
        id: 'integrations-page.button.get_api_docs',
        defaultMessage: 'Get API docs',
    },
})
