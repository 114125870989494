import { Flex, Skeleton } from '@merchant/ui-kit/ant-design'

export function LoadingSkeleton() {
    return (
        <Flex vertical align="center">
            <Skeleton.Node active style={{ height: 200, width: 200, borderRadius: 24 }}>
                <></>
            </Skeleton.Node>
            <Skeleton
                active
                title={{ width: '40%', style: { margin: '0 auto', height: 21 } }}
                paragraph={false}
                style={{ width: '70%', marginTop: 16 }}
            />
            <Skeleton
                active
                title={{ width: '90%', style: { margin: '0 auto', height: 21 } }}
                paragraph={false}
                style={{ width: '70%', marginTop: 4 }}
            />
        </Flex>
    )
}
