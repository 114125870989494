import { Col, Typography } from '@merchant/ui-kit/ant-design'

const { Title } = Typography

interface Props {
    dataMerchantPrefix: string
    itemTitle: string | undefined
    amountTextValue: string | JSX.Element | undefined
}

export function RightCol({ dataMerchantPrefix, itemTitle, amountTextValue }: Props) {
    return (
        Boolean(itemTitle && amountTextValue) && (
            <Col flex="none">
                <Title level={4} data-merchant={`${dataMerchantPrefix}-payment-amount`}>
                    {amountTextValue}
                </Title>
            </Col>
        )
    )
}
