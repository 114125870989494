import type { WithdrawalCryptoFormData } from '../../types'

const enum FormOrderKeys {
    default = 'default',
    whitelistEnabled = 'whitelistEnabled',
}

export type WithdrawalCryptoElements =
    | keyof Omit<WithdrawalCryptoFormData, 'currency' | 'network'>
    | 'whitelistAlert'
    | 'divider'
    | 'currencyWithNetwork'

export const formOrders: Record<FormOrderKeys, WithdrawalCryptoElements[]> = {
    [FormOrderKeys.default]: [
        'currencyWithNetwork',
        'address',
        'addressTag',
        'amount',
        'amountMode',
        'withdrawalAmount',
        'fee',
        'receiveAmount',
        'note',
    ],
    [FormOrderKeys.whitelistEnabled]: [
        'currencyWithNetwork',
        'divider',
        'whitelistKey',
        'address',
        'whitelistAlert',
        'divider',
        'amount',
        'addressTag',
        'withdrawalAmount',
        'fee',
        'receiveAmount',
        'amountMode',
        'divider',
        'note',
    ],
} as const
