import type { Dayjs } from 'dayjs'
import type { ListProjectTransactionsRequest, Transaction } from '~api/instances/cabinet-api'
import { TransactionStatus, TransactionType } from '~api/instances/cabinet-api'

export interface TransactionsFilterFormData {
    type?: Transaction['type']
    status?: Transaction['status']
    id?: Transaction['txId']
    date?: [Dayjs | null, Dayjs | null]
}

export type TransactionFilterQueryData = Omit<
    ListProjectTransactionsRequest,
    'xSpanId' | 'xTraceId' | 'limit' | 'cursor'
>

export const TransactionTypeMapped: Record<TransactionType, string> = {
    [TransactionType.Payment]: 'ORDER',
    [TransactionType.Swap]: 'INTERNAL EXCHANGE',
    [TransactionType.Withdrawal]: 'WITHDRAWAL CRYPTO',
    [TransactionType.FiatWithdrawal]: 'WITHDRAWAL FIAT',
    [TransactionType.Deposit]: 'DEPOSIT',
} as const

export const TransactionStatusMapped: Record<TransactionStatus, string> = {
    [TransactionStatus.Processing]: 'WAITING',
    [TransactionStatus.Finished]: 'COMPLETED',
    [TransactionStatus.Failed]: 'FAILED',
} as const
