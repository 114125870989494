import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    selectCurrencyPlaceholder: {
        id: 'withdrawal-modal.select.select_currency_placeholder',
        defaultMessage: 'Select currency',
    },
    selectCurrencyLabel: {
        id: 'withdrawal-modal.select.select_currency_label',
        defaultMessage: 'Currency',
    },
    selectNetworkPlaceholder: {
        id: 'withdrawal-modal.select.select_network_placeholder',
        defaultMessage: 'Select network',
    },
    networkIsNotRequired: {
        id: 'withdrawal-modal.alert.network_is_not_required',
        defaultMessage: 'You don’t need to select a network for the selected currency',
    },
    pleaseSelectCurrencyTooltip: {
        id: 'withdrawal-modal.tooltip.please_select_currency',
        defaultMessage: 'Please select a currency',
    },
    currencyAndNetwork: {
        id: 'withdrawal-modal.select.currency_and_network',
        defaultMessage: 'Currency & Network',
    },
    verifyNetworkAlert: {
        id: 'withdrawal-modal.alert.verify_network',
        defaultMessage: 'Please verify that you have chosen the correct network to avoid any potential loss of funds',
    },
    networkPrefix: {
        id: 'withdrawal-modal.select.network_prefix',
        defaultMessage: 'Network:',
    },
})
