import { Button } from '@merchant/ui-kit/ant-design'
import { merge } from 'lodash-es'
import React from 'react'
import styles from './style.module.css'
import type { ButtonProps } from '@merchant/ui-kit/ant-design'

const buttonStyles: React.CSSProperties = {
    display: 'inline-flex',
    width: 'auto',
    padding: 0,
    height: 'auto',
    border: 'none',
    verticalAlign: 'middle',
}
const buttonComponentsStyles: Record<'icon', React.CSSProperties> = { icon: { marginInlineEnd: 0 } }
const innerSpanStyles: React.CSSProperties = { overflow: 'inherit', textOverflow: 'inherit' }

export function InlineButton({ children, ...rest }: ButtonProps) {
    const mergedStyle = rest.style ? { ...buttonStyles, ...rest.style } : buttonStyles
    const mergedInnerStyles: ButtonProps['styles'] = merge(rest.styles, buttonComponentsStyles)

    return (
        <Button size="large" {...rest} className={styles.button} style={mergedStyle} styles={mergedInnerStyles}>
            {!!children && <span style={innerSpanStyles}>{children}</span>}
        </Button>
    )
}
