import { PosthogConvertEvents } from './types'
import type { ConvertEventDataMap } from '.'
import type { PosthogEventTypeMapping } from '~types/posthog'

export const convertCaptureEventParams: PosthogEventTypeMapping<PosthogConvertEvents, ConvertEventDataMap> = {
    [PosthogConvertEvents.onConvertScreen]: payload => [PosthogConvertEvents.onConvertScreen, payload],
    [PosthogConvertEvents.getConvertAmount]: payload => [PosthogConvertEvents.getConvertAmount, payload],
    [PosthogConvertEvents.currencySearchNoMatch]: payload => [PosthogConvertEvents.currencySearchNoMatch, payload],
    [PosthogConvertEvents.currencySelected]: payload => [PosthogConvertEvents.currencySelected, payload],
    [PosthogConvertEvents.convertSubmit]: payload => [PosthogConvertEvents.convertSubmit, payload],
    [PosthogConvertEvents.convertStatusChange]: payload => [PosthogConvertEvents.convertStatusChange, payload],
} as const
