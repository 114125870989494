import type { Breakpoints } from '@merchant/shared/hooks'

export const currencyNameStyles: React.CSSProperties = { fontSize: 12 }
export const balanceEquivalentStyles: React.CSSProperties = { fontSize: 12 }
export const assetCodeParagraphStyles: React.CSSProperties = {
    marginBottom: 0,
}
export const getContainerBodyStyles = (breakpoints: Breakpoints): React.CSSProperties => ({
    paddingTop: 0,
    padding: breakpoints.sm ? undefined : '12px',
})
export const modalStyles: React.CSSProperties = {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
    top: 'unset',
    transformOrigin: 'center',
    padding: 0,
    margin: '0px auto',
}
