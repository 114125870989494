/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    TransactionDepositDetails,
    instanceOfTransactionDepositDetails,
    TransactionDepositDetailsFromJSON,
    TransactionDepositDetailsFromJSONTyped,
    TransactionDepositDetailsToJSON,
} from './TransactionDepositDetails';
import {
    TransactionPaymentDetails,
    instanceOfTransactionPaymentDetails,
    TransactionPaymentDetailsFromJSON,
    TransactionPaymentDetailsFromJSONTyped,
    TransactionPaymentDetailsToJSON,
} from './TransactionPaymentDetails';
import {
    TransactionSwapDetails,
    instanceOfTransactionSwapDetails,
    TransactionSwapDetailsFromJSON,
    TransactionSwapDetailsFromJSONTyped,
    TransactionSwapDetailsToJSON,
} from './TransactionSwapDetails';
import {
    TransactionWithdrawalDetails,
    instanceOfTransactionWithdrawalDetails,
    TransactionWithdrawalDetailsFromJSON,
    TransactionWithdrawalDetailsFromJSONTyped,
    TransactionWithdrawalDetailsToJSON,
} from './TransactionWithdrawalDetails';

/**
 * @type TransactionDetails
 * 
 * @export
 */
export type TransactionDetails = TransactionDepositDetails | TransactionPaymentDetails | TransactionSwapDetails | TransactionWithdrawalDetails;

// @ts-ignore
export function TransactionDetailsFromJSON(json: any): TransactionDetails {
    return TransactionDetailsFromJSONTyped(json, false);
}

// @ts-ignore
export function TransactionDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...TransactionDepositDetailsFromJSONTyped(json, true), ...TransactionPaymentDetailsFromJSONTyped(json, true), ...TransactionSwapDetailsFromJSONTyped(json, true), ...TransactionWithdrawalDetailsFromJSONTyped(json, true) };
}

// @ts-ignore
export function TransactionDetailsToJSON(value?: TransactionDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfTransactionDepositDetails(value)) {
        return TransactionDepositDetailsToJSON(value as TransactionDepositDetails);
    }
    if (instanceOfTransactionPaymentDetails(value)) {
        return TransactionPaymentDetailsToJSON(value as TransactionPaymentDetails);
    }
    if (instanceOfTransactionSwapDetails(value)) {
        return TransactionSwapDetailsToJSON(value as TransactionSwapDetails);
    }
    if (instanceOfTransactionWithdrawalDetails(value)) {
        return TransactionWithdrawalDetailsToJSON(value as TransactionWithdrawalDetails);
    }

    return {};
}

