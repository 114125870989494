import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    emailCodeInputPlaceholder: {
        id: 'email-code.input.placeholder',
        defaultMessage: 'Email code',
    },
    noAccessEmail: {
        id: 'email-code.input.no_access_to_email',
        defaultMessage: 'No access to the listed email?',
    },
})
