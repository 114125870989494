import { lang } from './lang'
import type { MessageDescriptor } from 'react-intl'
import { Permission } from '~api'

export const enum WithdrawalsShowOptions {
    history = 'history',
    whitelist = 'whitelist',
}

export const pageTitleMap: Record<WithdrawalsShowOptions, MessageDescriptor> = {
    [WithdrawalsShowOptions.history]: lang.historyPageTitle,
    [WithdrawalsShowOptions.whitelist]: lang.whitelistPageTitle,
}

export const pages: WithdrawalsShowOptions[] = [WithdrawalsShowOptions.history, WithdrawalsShowOptions.whitelist]

export const dataMerchantAttributesMap: Record<WithdrawalsShowOptions, string> = {
    [WithdrawalsShowOptions.history]: 'withdrawals-history-title',
    [WithdrawalsShowOptions.whitelist]: 'withdrawals-whitelist-title',
}

export const pagePermissionMap: Record<WithdrawalsShowOptions, Permission> = {
    [WithdrawalsShowOptions.history]: Permission.SeeWithdrawals,
    [WithdrawalsShowOptions.whitelist]: Permission.SeeWithdrawalWhitelist,
}
