import { Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import type { SwapModalPermissions } from './types'
import { BalanceLinkButton } from '~components'

const { Text } = Typography

interface Props {
    swapModalPermissions: SwapModalPermissions
    hasError: boolean
    isInsufficientFunds: boolean
    onBalanceClick: () => void
    availableFunds: string
    sendCurrency: string | null
}

export function SendAmountInputExtra({
    swapModalPermissions,
    hasError,
    isInsufficientFunds,
    onBalanceClick,
    availableFunds,
    sendCurrency,
}: Props) {
    if (swapModalPermissions.see_balance && !hasError) {
        return (
            <Text type={isInsufficientFunds ? 'danger' : 'secondary'} data-merchant="convert-available-balance-text">
                <FormattedMessage {...lang.balance} />:{' '}
                <BalanceLinkButton
                    data-merchant="convert-available-balance-button"
                    onClick={onBalanceClick}
                    amount={availableFunds}
                    currencyCode={sendCurrency}
                />
            </Text>
        )
    }

    return <span />
}
