import { assets } from '@merchant/shared/assets/nameMap'
import { AppCoin, AppSvg, CopyButton, EllipsisMiddle, InlineLinkButton } from '@merchant/shared/components'
import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from '../lang'
import { WithdrawalTableKeys } from './const'
import styles from './style.module.css'
import { defaultStringSorter, getFilterData } from './utils'
import type { useBreakpoint } from '@merchant/shared/hooks'
import type { ColumnsType } from '@merchant/ui-kit/ant-design/es/table'
import type { Network } from '~api/instances/cabinet-api'
import type { WhitelistWithKey } from '~api/swr/rest/types'
import type { Currencies } from '~api/types'
import { AllCurrenciesTag, NoValueColumnContent } from '~components'
import { globalLang } from '~globalLang'

const { Text, Paragraph } = Typography

const renderCurrency = (currency: WhitelistWithKey['currency'], currencies?: Currencies) =>
    currency ? (
        <Flex align="center" gap={12}>
            <div>
                <AppCoin currencyCode={currency} />
            </div>
            <Flex vertical>
                <Text data-merchant={`whitelist-currency-${currency}`}>{currency.toUpperCase()}</Text>
                <Text type="secondary" data-merchant="whitelist-currency-name">
                    {currencies?.[currency]?.name}
                </Text>
            </Flex>
        </Flex>
    ) : (
        <AllCurrenciesTag />
    )

const renderNetwork = (network: WhitelistWithKey['network'], allNetworks: Network[] = []) =>
    network ? (
        <Flex vertical>
            <Text data-merchant={`whitelist-network-${network}`}>{network}</Text>
            <Text type="secondary" data-merchant="whitelist-network-name">
                {allNetworks.find(item => item.code === network)?.name}
            </Text>
        </Flex>
    ) : (
        NoValueColumnContent
    )

type GetColumns = (params: {
    data: WhitelistWithKey[] | undefined
    handleDeleteClick: (item: WhitelistWithKey) => void
    breakpoint: ReturnType<typeof useBreakpoint>
    isEditWhitelistAllowed: boolean
    currencies?: Currencies
    allNetworks: Network[]
}) => ColumnsType<WhitelistWithKey>

// eslint-disable-next-line max-lines-per-function
export const getColumns: GetColumns = ({
    data = [],
    handleDeleteClick,
    breakpoint,
    isEditWhitelistAllowed,
    currencies,
    allNetworks = [],
}) => [
    {
        key: WithdrawalTableKeys.name,
        dataIndex: WithdrawalTableKeys.name,
        title: <FormattedMessage {...lang.name} />,
        sortDirections: ['ascend', 'descend'],
        sorter: defaultStringSorter('name'),
        render: name => (
            <Paragraph
                ellipsis={{ rows: 2 }}
                style={{ fontWeight: 700, margin: 0 }}
                data-merchant={`whitelist-${name}`}
            >
                {name}
            </Paragraph>
        ),
        width: breakpoint.lg ? 200 : 'auto',
        showSorterTooltip: false,
    },
    {
        key: WithdrawalTableKeys.currency,
        dataIndex: WithdrawalTableKeys.currency,
        title: <FormattedMessage {...lang.currency} />,
        render: currency => renderCurrency(currency, currencies),
        width: 150,
        sortDirections: ['ascend', 'descend'],
        sorter: defaultStringSorter('currency'),
        filterMode: 'menu',
        onFilter: (value, record) => record.currency === String(value),
        filters: getFilterData(data, 'currency', item => (
            <>
                <Text data-merchant={`whitelist-filter-currency-${item.currency}`}>{item.currency}</Text>{' '}
                <Text type="secondary" data-merchant="whitelist-filter-currency-name">
                    {currencies?.[item.currency as keyof typeof currencies]?.name}
                </Text>
            </>
        )),
        showSorterTooltip: false,
    },
    {
        dataIndex: 'network',
        key: WithdrawalTableKeys.network,
        title: <FormattedMessage {...globalLang.network} />,
        render: network => renderNetwork(network, allNetworks),
        width: breakpoint.lg ? 100 : 'auto',
        sortDirections: ['ascend', 'descend'],
        sorter: defaultStringSorter('network'),
        showSorterTooltip: false,
        filterMode: 'menu',
        onFilter: (value, record) => record.network === String(value),
        filters: getFilterData(data, 'network', item => (
            <>
                <Text data-merchant={`whitelist-filter-network-${item.network}`}>{item.network}</Text>{' '}
            </>
        )),
    },
    {
        key: WithdrawalTableKeys.address,
        dataIndex: WithdrawalTableKeys.address,
        title: <FormattedMessage {...lang.address} />,
        render: address => (
            <Flex gap={4} align="center">
                <EllipsisMiddle
                    suffixCount={6}
                    prefixCount={6}
                    divider="."
                    dividerTextType="secondary"
                    showTooltip
                    copyableText={address}
                    data-merchant={`whitelist-address-${address}`}
                >
                    {address}
                </EllipsisMiddle>
                <CopyButton
                    type="link"
                    inputValue={address}
                    notificationContentSubject={lang.address}
                    className={styles.cellCopyIcon}
                    data-merchant="whitelist-address-copy-button"
                />
            </Flex>
        ),
        width: breakpoint.lg ? 200 : 150,
    },
    {
        key: WithdrawalTableKeys.addressTag,
        dataIndex: WithdrawalTableKeys.addressTag,
        title: <FormattedMessage {...lang.addressTag} />,
        width: 100,
        render: addressTagValue =>
            addressTagValue ? (
                <Flex gap={4} align="center">
                    <Paragraph
                        data-merchant={`whitelist-address-tag-${addressTagValue}`}
                        style={{ margin: 0 }}
                        ellipsis={{
                            rows: 2,
                            tooltip: {
                                title: (
                                    <div>
                                        {addressTagValue}{' '}
                                        <CopyButton
                                            type="link"
                                            inputValue={addressTagValue}
                                            notificationContentSubject={lang.addressTag}
                                            data-merchant="whitelist-address-tag-tooltip-copy-button"
                                        />
                                    </div>
                                ),
                                placement: 'bottom',
                                'data-merchant': 'whitelist-address-tag-tooltip-copy-button',
                            },
                        }}
                    >
                        {addressTagValue}
                    </Paragraph>
                    <CopyButton
                        type="link"
                        inputValue={addressTagValue}
                        notificationContentSubject={lang.addressTag}
                        className={styles.cellCopyIcon}
                        data-merchant="whitelist-address-tag-copy-button"
                    />
                </Flex>
            ) : (
                NoValueColumnContent
            ),
    },
    {
        key: WithdrawalTableKeys.actions,
        dataIndex: WithdrawalTableKeys.actions,
        render: (_, record) => (
            <InlineLinkButton
                disabled={!isEditWhitelistAllowed}
                data-merchant={`${record.key}-delete-whitelist-item-button`}
                onClick={() => handleDeleteClick(record)}
                icon={<AppSvg name={assets.trash} size={20} />}
            />
        ),
        width: 50,
    },
]
