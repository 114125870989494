import { lang } from './lang'
import type { WithdrawalFiatFormData } from './types'
import { ModalNames } from '~constants/modal'

export const steps = {
    [ModalNames.withdrawalFiatInput]: ModalNames.withdrawalFiatInput,
    [ModalNames.withdrawalFiatSummary]: ModalNames.withdrawalFiatSummary,
    [ModalNames.withdrawalFiatConfirmation]: ModalNames.withdrawalFiatConfirmation,
    [ModalNames.withdrawalFiatFinish]: ModalNames.withdrawalFiatFinish,
} as const

export const viewTitles = {
    [steps.withdrawalFiatInput]: lang.inputModalTitle,
    [steps.withdrawalFiatSummary]: lang.confirmationModalTitle,
    [steps.withdrawalFiatConfirmation]: lang.confirmationModalTitle,
    [steps.withdrawalFiatFinish]: null,
}

export const initWithdrawalFiatData: Partial<WithdrawalFiatFormData> = {
    amount: undefined,
    currency: undefined,
    method: undefined,
    requisites: undefined,
}
