import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    createAddress: {
        id: 'static-address-constructor.button.create-address',
        defaultMessage: 'Create new static address',
    },
    feePayerClient: {
        id: 'static-address-constructor.form.fee-payer-customer',
        defaultMessage: 'Client',
    },
    feePayerMerchant: {
        id: 'static-address-constructor.form.fee-payer-merchant',
        defaultMessage: 'Merchant',
    },
    feePayerDefault: {
        id: 'static-address-constructor.form.fee-payer-default',
        defaultMessage: 'Default',
    },
})
