import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    pageTitle: {
        id: 'orders-page.details.page_title',
        defaultMessage: 'Order details',
    },
    date: {
        id: 'orders-page.details.date',
        defaultMessage: 'Date',
    },
    client: {
        id: 'orders-page.details.client',
        defaultMessage: 'client',
    },
    merchant: {
        id: 'orders-page.details.merchant',
        defaultMessage: 'merchant',
    },

    created: {
        id: 'orders-page.details.created',
        defaultMessage: 'Created',
    },
    lastUpdated: {
        id: 'orders-page.details.last_updated',
        defaultMessage: 'Last updated',
    },
    orderId: {
        id: 'orders-page.details.order_id',
        defaultMessage: 'Order ID',
    },
    merchantOrderId: {
        id: 'orders-page.details.merchant_order_id',
        defaultMessage: 'Merchant order ID',
    },
    orderType: {
        id: 'orders-page.details.order_type',
        defaultMessage: 'Order type',
    },
    deposit: {
        id: 'orders-page.details.deposit',
        defaultMessage: 'Deposit',
    },
    status: {
        id: 'orders-page.details.status',
        defaultMessage: 'Status',
    },
    url: {
        id: 'orders-page.details.url',
        defaultMessage: 'URL',
    },

    orderName: {
        id: 'orders-page.details.order_name',
        defaultMessage: 'Order name',
    },
    orderDescription: {
        id: 'orders-page.details.order_description',
        defaultMessage: 'Order description',
    },
    orderPrice: {
        id: 'orders-page.details.order_price',
        defaultMessage: 'Order price',
    },

    clientEmail: {
        id: 'orders-page.details.client_email',
        defaultMessage: 'Client email',
    },
    clientName: {
        id: 'orders-page.details.client_name',
        defaultMessage: 'Client name',
    },
    clientPhone: {
        id: 'orders-page.details.client_phone',
        defaultMessage: 'Client phone',
    },
    clientId: {
        id: 'orders-page.details.client_id',
        defaultMessage: 'Client ID',
    },

    id: {
        id: 'orders-page.details.id',
        defaultMessage: 'ID',
    },
    currencyAddress: {
        id: 'orders-page.details.currency_address',
        defaultMessage: '{currency} address',
    },
    payinAddress: {
        id: 'orders-page.details.payin_address',
        defaultMessage: '{currency} address',
    },
    addressTag: {
        id: 'orders-page.details.address_tag',
        defaultMessage: '{currency} memo',
    },
    amount: {
        id: 'orders-page.details.amount',
        defaultMessage: 'Amount',
    },
    balanceBefore: {
        id: 'orders-page.details.balance_before',
        defaultMessage: 'Balance before',
    },
    balanceAfter: {
        id: 'orders-page.details.balance_after',
        defaultMessage: 'Balance after',
    },

    order: {
        id: 'orders-page.details.order_card',
        defaultMessage: 'Order',
    },
    productInformation: {
        id: 'orders-page.details.product_information_card',
        defaultMessage: 'Product information',
    },
    clientInformation: {
        id: 'orders-page.details.client_information_card',
        defaultMessage: 'Client information',
    },
    transactionInfo: {
        id: 'orders-page.details.transaction_info_card',
        defaultMessage: 'Transaction info',
    },
    updatesTimeline: {
        id: 'orders-page.details.updates_timeline_card',
        defaultMessage: 'Updates timeline',
    },
    chosenCurrencyByClient: {
        id: 'orders-page.details.chosen_currency_by_client_timeline_card',
        defaultMessage: 'Payment Currency Chosen by Client',
    },
    chosenCurrencyForPayment: {
        id: 'orders-page.details.chosen_currency_for_payment_timeline_card',
        defaultMessage: 'Chosen Currency for Payment',
    },
    chosenNetworkForPayment: {
        id: 'orders-page.details.chosen_network_for_payment_timeline_card',
        defaultMessage: 'Chosen Network for Payment',
    },
    exchangeRates: {
        id: 'orders-page.details.exchange_rates_timeline_card',
        defaultMessage: 'Exchange rate {fromCurrency} to {toCurrency}',
    },
    fundsFromClient: {
        id: 'orders-page.details.funds_from_client_timeline_card',
        defaultMessage: 'Funds Received from Client',
    },
    amountFromClient: {
        id: 'orders-page.details.amount_from_client_timeline_card',
        defaultMessage: 'Amount from Client',
    },
    amountRequested: {
        id: 'orders-page.details.amount_requested_timeline_card',
        defaultMessage: 'Amount requested',
    },
    depositFee: {
        id: 'orders-page.details.deposit_fee_timeline_card',
        defaultMessage: 'Deposit fee (paid by {who})',
    },
    subtotalAmount: {
        id: 'orders-page.details.subtotal_received_amount',
        defaultMessage: 'Subtotal received amount',
    },
    orderFee: {
        id: 'orders-page.details.order_fee_timeline_card',
        defaultMessage: 'Order fee (paid by {who})',
    },
    fundsCredited: {
        id: 'orders-page.details.funds_credited_to_merch_timeline_card',
        defaultMessage: 'Funds Credited to Merchant balance',
    },
    accumulationCurrency: {
        id: 'orders-page.details.accumulation_currency_timeline_card',
        defaultMessage: 'Accumulation currency',
    },
    receivedAmount: {
        id: 'orders-page.details.received_amount_timeline_card',
        defaultMessage: 'Received amount',
    },
    metaInfo: {
        id: 'orders-page.details.meta_info_card',
        defaultMessage: 'Meta info',
    },
    show: {
        id: 'orders-page.details.meta_show',
        defaultMessage: 'Show',
    },
    orderCreated: {
        id: 'orders-page.details.order_created_timeline_card',
        defaultMessage: 'Order created',
    },
    orderWaiting: {
        id: 'orders-page.details.order_waiting_title',
        defaultMessage: 'Order waiting',
    },
    orderCancelled: {
        id: 'orders-page.details.order_cancelled_title',
        defaultMessage: 'Order cancelled',
    },
    orderFailed: {
        id: 'orders-page.details.order_failed_title',
        defaultMessage: 'Order failed',
    },
    orderCompleted: {
        id: 'orders-page.details.order_completed_title',
        defaultMessage: 'Order completed',
    },
    transactionNumber: {
        id: 'orders-page.details.order_payin.number',
        defaultMessage: '(transaction {number})',
    },
    orderFailureDescription: {
        id: 'orders-page.details.order_failure.description',
        defaultMessage: 'For assistance, contact our <tag>support team</tag>',
    },
    orderCancellationDescription: {
        id: 'orders-page.details.order_cancellation.description',
        defaultMessage: "Description: Didn't receive enough funds within the required time.",
    },

    depositTo: {
        id: 'orders-page.details.deposit_to',
        defaultMessage: 'Deposit to {currency}',
    },

    noOrderTitle: {
        id: 'orders-page.details.no_order_title',
        defaultMessage: 'No order title',
    },
    noOrderDescription: {
        id: 'orders-page.details.no_order_description',
        defaultMessage: 'No order description',
    },
    source: {
        id: 'orders-page.details.source',
        defaultMessage: 'Source',
    },
    underpaymentThreshold: {
        id: 'orders-page.details.underpayment_threshold',
        defaultMessage: 'Underpayment threshold',
    },
    underpaymentRelativeValue: {
        id: 'orders-page.details.underpayment_relative_value',
        defaultMessage: '{value}% of the order price',
    },
    underpaymentAbsoluteValue: {
        id: 'orders-page.details.underpayment_absolute_value',
        defaultMessage: 'max: {amount} {currency}',
    },
    sendStatusCallback: {
        id: 'orders-page.button.send_status_callback',
        defaultMessage: 'Send status Callback',
    },
    sendStatusCallbackTooltip: {
        id: 'orders-page.button.send_status_callback_tooltip',
        defaultMessage: 'To send a callback, first setup Callback URL in the <link>Integrations</link> section',
    },
})
