import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    submitButton: {
        id: 'integrations-page.toggle-api-key-status.modal_button_title',
        defaultMessage: 'Confirm',
    },
    modalTitle: {
        id: 'integrations-page.toggle-api-key-status.modal_title',
        defaultMessage: 'Toggle API Key status',
    },
})
