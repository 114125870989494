import { Button, Tooltip } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang as commonLang } from './../../lang'
import { lang } from './lang'
import type { ButtonProps } from '@merchant/ui-kit/ant-design'

interface Props extends Omit<ButtonProps, 'type' | 'htmlType' | 'block' | 'form'> {
    isProjectCreation?: boolean
}

export function ProjectSubmit({ isProjectCreation = false, ...rest }: Props) {
    const content = (
        <Button data-merchant="submit-merchant-button" htmlType="submit" type="primary" block {...rest}>
            <FormattedMessage {...(isProjectCreation ? lang.createMerchant : commonLang.saveChanges)} />
        </Button>
    )

    if (isProjectCreation) {
        return (
            <Tooltip
                destroyTooltipOnHide
                placement="bottom"
                title={<FormattedMessage {...lang.submitButtonTooltip} />}
                data-merchant="merchant-settings-submit-button-tooltip"
            >
                {content}
            </Tooltip>
        )
    }

    return content
}
