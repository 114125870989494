import { useColorMode } from '@merchant/shared/hooks'
import { Themes, themeTokens } from '@merchant/shared/themes'
import { ConfigProvider as ConfigProviderOriginal } from '@merchant/ui-kit/ant-design'
import { useIntl } from 'react-intl'
import { getConfigProviderProps } from './utils'
import type { ConfigProviderProps } from '@merchant/ui-kit/ant-design'

export function ConfigProvider({ children, ...props }: ConfigProviderProps) {
    const intl = useIntl()
    const { isDark } = useColorMode()
    const initialThemeKey = isDark ? Themes.MerchantNight : Themes.MerchantDay

    return (
        <ConfigProviderOriginal {...getConfigProviderProps(intl, themeTokens[initialThemeKey])} {...props}>
            {children}
        </ConfigProviderOriginal>
    )
}
