import { protocolValidator } from '@merchant/shared/utils'
import { FormattedMessage } from 'react-intl'
import type { PaymentSettingsFormData } from './types'
import type { FormItemsKey } from '@merchant/shared/types'
import type { FormRule } from '@merchant/ui-kit/ant-design'
import type { LossConfig } from '~api/instances/cabinet-api'
import { globalLang } from '~globalLang'

export const paymentsSettingsFormItems: FormItemsKey<Omit<PaymentSettingsFormData, 'lossConfig'>> &
    Record<keyof LossConfig, ['lossConfig', keyof LossConfig]> = {
    feesPayer: 'feesPayer',
    redirectUrlFailure: 'redirectUrlFailure',
    redirectUrlSuccess: 'redirectUrlSuccess',
    relative: ['lossConfig', 'relative'],
    absolute: ['lossConfig', 'absolute'],
}

export const urlRules: FormRule[] = [
    {
        type: 'url',
        message: <FormattedMessage {...globalLang.invalidUrlFormat} />,
    },
    {
        message: <FormattedMessage {...globalLang.missingProtocol} />,
        validator: protocolValidator,
    },
]

export const initFormValues: Partial<PaymentSettingsFormData> = {
    feesPayer: undefined,
    redirectUrlSuccess: '',
    redirectUrlFailure: '',
    lossConfig: {
        relative: undefined,
        absolute: undefined,
    },
}
