import { useBreakpoint } from '@merchant/shared/hooks'
import { Button, Divider, Flex, Table, Typography, theme } from '@merchant/ui-kit/ant-design'
import { isEmpty, noop, uniqueId } from 'lodash-es'
import { useState, type ClassAttributes, type TableHTMLAttributes } from 'react'
import { FormattedMessage } from 'react-intl'
import { CallbackModal } from './components'
import { DeletionModalForm } from './components/CallbackModal/DeletionModalForm'
import { PatchingModalForm } from './components/CallbackModal/PatchingModalForm'
import { RegenerationModalForm } from './components/CallbackModal/RegenerationModalForm'
import { TestCallbackButton } from './components/TestCallbackButton'
import { modes } from './const'
import { lang } from './lang'
import { useData } from './useData'
import { getColumns } from './utils'
import type { CallbackResponse } from '~api/instances/cabinet-api'
import { PageContentContainer, SkeletonTable, CallbackResultModal } from '~components'
import { withDirtyCheckingContextProvider } from '~hoc/withDirtyCheckingContextProvider'

const { Text } = Typography
const { useToken } = theme

interface Props {
    isIntegrationEditAllowed: boolean
    resolveBlocklist: (cb: () => void) => void
}

// eslint-disable-next-line max-lines-per-function
function Component({ isIntegrationEditAllowed, resolveBlocklist }: Props) {
    const { token } = useToken()
    const breakpoints = useBreakpoint()
    const [testCallbackResponse, setTestCallbackResponse] = useState<CallbackResponse | null>(null)

    const { callback, isDataLoading, deletion, patching, regeneration, modalMode, resetModal, getClickHandler } =
        useData({
            onTestCallbackSent: setTestCallbackResponse,
            resolveBlocklist,
        })

    const isCallbackEmpty = isEmpty(callback)

    const modalContents = {
        [modes.callbackUrlDelete]: <DeletionModalForm request={deletion.send} onSuccess={deletion.onSuccess} />,
        [modes.callbackUrlCreate]: (
            <PatchingModalForm
                request={patching.send}
                callback={callback}
                onSuccess={patching.onSuccess}
                mode="create"
            />
        ),
        [modes.callbackUrlEdit]: (
            <PatchingModalForm request={patching.send} callback={callback} onSuccess={patching.onSuccess} mode="edit" />
        ),
        [modes.callbackUrlRegenerate]: (
            <RegenerationModalForm request={regeneration.send} onSuccess={regeneration.onSuccess} />
        ),
    }

    return (
        <PageContentContainer styles={{ body: { padding: !breakpoints.md ? 12 : undefined } }}>
            <CallbackModal open={!!modalMode} mode={modalMode} onCancel={resetModal}>
                {modalMode && modalContents[modalMode]}
            </CallbackModal>
            <CallbackResultModal
                type="test"
                data={testCallbackResponse}
                onCancel={() => setTestCallbackResponse(null)}
            />
            <SkeletonTable
                dataMerchant="callback-url-skeleton-table"
                columns={getColumns({
                    onDeleteClick: noop,
                    onEditClick: noop,
                    onRegenerateClick: noop,
                    token,
                    breakpoints,
                    isIntegrationEditAllowed,
                })}
                active={isDataLoading}
                loading={isDataLoading}
                rowCount={1}
            >
                <Flex
                    wrap="nowrap"
                    gap={12}
                    justify="space-between"
                    align={breakpoints.md ? 'center' : 'flex-start'}
                    vertical={!breakpoints.md}
                >
                    <Flex vertical style={{ width: '100%' }}>
                        <Flex justify="space-between" align="center" style={{ marginBottom: 12 }}>
                            <Text style={{ fontSize: 16 }} data-merchant="callback-url-title">
                                <FormattedMessage {...lang.callbackUrlConfiguration} />
                            </Text>
                            {!isCallbackEmpty && <TestCallbackButton onSuccess={setTestCallbackResponse} />}
                        </Flex>
                        {isCallbackEmpty ? (
                            <Text data-merchant="callback-empty-description" type="secondary">
                                <FormattedMessage {...lang.callbackUrlDescription} />
                            </Text>
                        ) : (
                            <>
                                <Divider style={{ marginBlock: 0 }} />
                                <Table
                                    components={{
                                        table: (
                                            props: JSX.IntrinsicAttributes &
                                                ClassAttributes<HTMLTableElement> &
                                                TableHTMLAttributes<HTMLTableElement>
                                        ) => <table data-merchant="callback-url-table" {...props} />,
                                    }}
                                    columns={getColumns({
                                        onDeleteClick: getClickHandler(modes.callbackUrlDelete),
                                        onEditClick: getClickHandler(modes.callbackUrlEdit),
                                        onRegenerateClick: getClickHandler(modes.callbackUrlRegenerate),
                                        token,
                                        breakpoints,
                                        isIntegrationEditAllowed,
                                    })}
                                    data-merchant="callback-urls-table"
                                    dataSource={[callback]}
                                    // not clear if we have mandatory fields on callback
                                    // and since we have only one callback
                                    rowKey={() => uniqueId()}
                                    onRow={() => ({
                                        'data-merchant': 'callback-url-table-row',
                                        style: undefined,
                                    })}
                                    pagination={false}
                                />
                            </>
                        )}
                    </Flex>
                    {isCallbackEmpty && (
                        <Button
                            disabled={!isIntegrationEditAllowed}
                            data-merchant="setup-callback-button"
                            type="primary"
                            onClick={getClickHandler(modes.callbackUrlCreate)}
                        >
                            <FormattedMessage {...lang.setupCallback} />
                        </Button>
                    )}
                </Flex>
            </SkeletonTable>
        </PageContentContainer>
    )
}

export const CallbackUrls = withDirtyCheckingContextProvider(Component)
