import { AppCoin } from '@merchant/shared/components'
import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import { capitalize } from 'lodash-es'
import type { SelectProps } from '@merchant/ui-kit/ant-design'
import type { Network } from '~api'
import type { Currencies } from '~api/types'
import { WithdrawalState } from '~api'
import { getAlphabeticallySortedCryptoCurrencies } from '~utils'

const { Text } = Typography

export const getStateOptions = () => {
    return Object.values(WithdrawalState).map(value => ({
        label: capitalize(value),
        key: value,
        value,
    }))
}

export const getCurrencyOptions = (currencies: Currencies | undefined): SelectProps['options'] => {
    const alphabeticallyOrdered = getAlphabeticallySortedCryptoCurrencies({ currencies })
    const options = alphabeticallyOrdered.map(item => {
        const { code = '', name = '', fullName = '' } = item

        return {
            label: (
                <Flex align="center" gap={4}>
                    <AppCoin currencyCode={code} size="small" />
                    <Text data-merchant={null}>{code}</Text>
                    <Text data-merchant={null} type="secondary">
                        {name}
                    </Text>
                </Flex>
            ),
            key: [code, name, fullName].filter(Boolean).join(''),
            value: code,
            code,
        }
    })

    return options
}

export const getNetworkOptions = (networks: Network[] | undefined) => {
    const alphabeticallyOrdered = (networks || []).toSorted((a, b) => a.name.localeCompare(b.name))

    return alphabeticallyOrdered.map(value => {
        return {
            label: (
                <>
                    <Text data-merchant={null}>{value.name}</Text>&nbsp;
                    <Text data-merchant={null} type="secondary">
                        {value.code}
                    </Text>
                </>
            ),
            key: [value.code, value.name].filter(Boolean).join(''),
            value: value.code,
        }
    })
}
