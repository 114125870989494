import { assets } from '@merchant/shared/assets/nameMap'
import { AmountInput, AppSvg } from '@merchant/shared/components'
import { Button, ConfigProvider, Form, Space } from '@merchant/ui-kit/ant-design'
import cn from 'classnames'
import { FormattedMessage } from 'react-intl'
import { initConvertData } from '../../const'
import { lang as commonLang } from '../../lang'
import { CurrencySelect } from './CurrencySelect'
import { ErrorMessage } from './ErrorMessage'
import { LoadingSkeleton } from './LoadingSkeleton'
import { SendAmountInputExtra } from './SendAmountInputExtra'
import { SendAmountLabel } from './SendAmountLabel'
import { inputNumberStyles, switchBtnWrapperStyles, switchBtnStyles } from './style'
import styles from './styles.module.css'
import { SwapRate } from './SwapRate'
import { useData } from './useData'
import { getAlertMessageProps, getConvertButtonLabel, isCurrencyPairSelected, shouldDisplayError } from './utils'
import type { ConvertData } from '../../types'
import type { SelectProps } from '@merchant/ui-kit/ant-design'
import { type SwapRequest } from '~api/instances/cabinet-api'
import { WithdrawalNotPermittedAlert, ResponsiveDropdown } from '~components'
import { AmountFormItem } from '~features/FormItems'
import { globalLang } from '~globalLang'

interface Props {
    isSwapping: boolean
    handleSwap: (data: SwapRequest) => void
    initialData?: ConvertData
}

// eslint-disable-next-line max-lines-per-function
export function ConvertInput({ handleSwap, isSwapping, initialData = initConvertData }: Props) {
    const {
        form,
        onSwap: swap,
        currencies,
        swapRate,
        sendData: send,
        receiveData: receive,
        canSubmitConvert,
        onBalanceClick,
        onSwitchClick,
        receiveOptions,
        sendOptions,
        isLoadingCurrencies,
        isInsufficientFunds,
        isSelectedPairSupported,
        swapModalPermissions,
    } = useData({
        swapRequest: handleSwap,
        initialSendAmount: initialData.send.value,
    })

    const actionButtonContent = getConvertButtonLabel(isInsufficientFunds, send.minimumSwapAmountInfo.isBelowMinimum)
    const isSourceCurrencySelected = Boolean(send.currency)
    const hasError = shouldDisplayError({
        isLoadingCurrencies,
        isSelectedPairSupported,
        isConvertible: send.isConvertible,
    })

    if (isLoadingCurrencies) {
        return <LoadingSkeleton />
    }

    return (
        <ConfigProvider theme={{ components: { Form: { marginLG: 20 } } }}>
            <Form
                data-merchant="convert-input-form"
                form={form}
                layout="vertical"
                onFinish={swap}
                initialValues={initialData}
            >
                <Form.Item
                    label={
                        <SendAmountLabel
                            sendCurrency={send.currency}
                            minimumSwapAmountInfo={send.minimumSwapAmountInfo}
                        />
                    }
                    extra={
                        <SendAmountInputExtra
                            swapModalPermissions={swapModalPermissions}
                            hasError={hasError}
                            isInsufficientFunds={isInsufficientFunds}
                            onBalanceClick={onBalanceClick}
                            availableFunds={send.availableFunds}
                            sendCurrency={send.currency}
                        />
                    }
                >
                    <Space.Compact block size="large">
                        <AmountFormItem
                            form={form}
                            precision={send.precision}
                            name={['send', 'value']}
                            noStyle
                            validateFirst
                            rules={[
                                {
                                    validator: (_, value) =>
                                        value && Number(value) > 0 ? Promise.resolve() : Promise.reject(),
                                    message: <FormattedMessage {...globalLang.requiredFieldMessage} />,
                                },
                            ]}
                        >
                            <AmountInput
                                precision={send.precision}
                                data-merchant="convert-send-amount-input"
                                style={inputNumberStyles}
                                placeholder="0"
                                readOnly={isLoadingCurrencies}
                                onChange={send.onAmountChangeDebounced}
                                decimalSeparator="."
                                className={cn({
                                    [styles.amountFrom!]: isInsufficientFunds,
                                })}
                                addonAfter={
                                    <ResponsiveDropdown<SelectProps>
                                        title={commonLang.youSend}
                                        onChange={send.onCurrencyChange}
                                        value={send.currency}
                                        loading={isLoadingCurrencies}
                                        data-merchant="convert-send-currency-select"
                                        renderMobile={(props, commonProps) => (
                                            <CurrencySelect
                                                conversionDirection="currency_from"
                                                permissions={swapModalPermissions}
                                                style={{
                                                    maxWidth: undefined,
                                                    width: '100%',
                                                }}
                                                dropdownStyle={{
                                                    overflow: 'auto',
                                                }}
                                                size="large"
                                                balances={sendOptions}
                                                listHeight={commonProps.listHeight}
                                                onSelect={commonProps.closeMobileDropdown}
                                                {...props}
                                            />
                                        )}
                                        render={(props, commonProps, breakpoints) => (
                                            <CurrencySelect
                                                conversionDirection="currency_from"
                                                permissions={swapModalPermissions}
                                                balances={sendOptions}
                                                style={!breakpoints.md ? { width: 150 } : undefined}
                                                showSearch={breakpoints.md}
                                                onClick={commonProps.openMobileDropdown}
                                                {...props}
                                            />
                                        )}
                                    />
                                }
                            />
                        </AmountFormItem>
                    </Space.Compact>
                </Form.Item>
                {hasError && (
                    <ErrorMessage
                        isSelectedPairSupported={isSelectedPairSupported}
                        isCurrencyPairSelected={isCurrencyPairSelected(send.currency, receive.currency)}
                        isConvertible={send.isConvertible}
                        isSourceCurrencySelected={isSourceCurrencySelected}
                    />
                )}
                <div style={switchBtnWrapperStyles}>
                    <Button
                        disabled={!(send.currency && receive.currency)}
                        data-merchant="convert-switch-button"
                        onClick={onSwitchClick}
                        type="link"
                        icon={<AppSvg size={24} name={assets.switchVertical} />}
                        style={switchBtnStyles}
                    />
                </div>
                <Form.Item label={<FormattedMessage {...commonLang.youGet} />}>
                    <Space.Compact block size="large">
                        <AmountFormItem form={form} precision={receive.precision} name={['receive', 'value']} noStyle>
                            <AmountInput
                                precision={receive.precision}
                                data-merchant="convert-receive-amount-input"
                                style={inputNumberStyles}
                                placeholder="0"
                                prefix={swapRate.data ? '≈' : ''}
                                readOnly
                                decimalSeparator="."
                                className={cn(styles.amountTo)}
                                addonAfter={
                                    <ResponsiveDropdown<SelectProps>
                                        title={commonLang.youGet}
                                        loading={isLoadingCurrencies}
                                        value={receive.currency}
                                        onChange={receive.onCurrencyChange}
                                        data-merchant="convert-receive-currency-select"
                                        renderMobile={(props, commonProps) => (
                                            <CurrencySelect
                                                conversionDirection="currency_to"
                                                permissions={swapModalPermissions}
                                                balances={receiveOptions}
                                                style={{
                                                    maxWidth: undefined,
                                                    width: '100%',
                                                }}
                                                dropdownStyle={{
                                                    overflow: 'auto',
                                                }}
                                                size="large"
                                                onSelect={commonProps.closeMobileDropdown}
                                                {...props}
                                            />
                                        )}
                                        render={(props, commonProps, breakpoints) => (
                                            <CurrencySelect
                                                conversionDirection="currency_to"
                                                permissions={swapModalPermissions}
                                                balances={receiveOptions}
                                                style={!breakpoints.md ? { width: 150 } : undefined}
                                                onClick={commonProps.openMobileDropdown}
                                                {...props}
                                            />
                                        )}
                                    />
                                }
                            />
                        </AmountFormItem>
                    </Space.Compact>
                </Form.Item>
                <Form.Item noStyle={!swapModalPermissions.commit_swaps}>
                    <SwapRate data={swapRate.data} error={swapRate.error} isLoading={swapRate.isLoading} />
                </Form.Item>
                {currencies && receive.currency && (
                    <WithdrawalNotPermittedAlert
                        selectedCurrencies={receive.currency}
                        currencies={currencies}
                        style={{
                            marginBottom: swapModalPermissions.commit_swaps ? 20 : 0,
                        }}
                        data-merchant="convert-withdrawal-not-permitted-alert"
                        renderMessage={() => (
                            <FormattedMessage {...getAlertMessageProps(currencies, receive.currency || '')} />
                        )}
                    />
                )}
                <Form.Item noStyle>
                    <Button
                        htmlType="submit"
                        disabled={!canSubmitConvert || !swapModalPermissions.commit_swaps}
                        data-merchant="convert-submit-button"
                        loading={isSwapping}
                        size="large"
                        block
                        type="primary"
                    >
                        <FormattedMessage {...actionButtonContent} values={{ Currency: send.currency }} />
                    </Button>
                </Form.Item>
            </Form>
        </ConfigProvider>
    )
}
