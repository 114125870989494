import { NotificationMessage } from '@merchant/shared/components'
import { useNotifications } from '@merchant/shared/contexts'
import { Flex, Form } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { useBoolean } from 'usehooks-ts'
import { AddMembersConfirmModal, AddMembersFormList } from './components'
import { initialFormValues, type FORM_LIST_NAME } from './const'
import { lang } from './lang'
import type { AwaitedReturnType } from '@merchant/shared/api/types'
import type { projectApi } from '~api'
import type { AddProjectTeamMembersRequest, ProjectTeamMember } from '~api/instances/cabinet-api'
import { FormNames } from '~constants/form'
import { PlaceholderViewWrapper } from '~features/PlaceholderViewWrapper'
import { useDirtyFormCheckerControls, useRegisterDirtyFormChecker } from '~hooks'

interface Props {
    onSuccess: (newMembers: ProjectTeamMember[]) => void
    onGoBack: () => void
}

export function AddMembers({ onSuccess, onGoBack }: Props) {
    const { value: isConfirmModalOpen, setTrue: openConfirmModal, setFalse: closeConfirmModal } = useBoolean()
    const { value: shouldRenderConfirmModal, setValue: setShouldRenderConfirmModal } = useBoolean()
    const [form] = Form.useForm<Pick<AddProjectTeamMembersRequest, typeof FORM_LIST_NAME>>()
    const {
        notification: { api: notificationApi },
    } = useNotifications()
    const { skipCheckAndCancel } = useDirtyFormCheckerControls(onGoBack, {
        checkForRouteChange: true,
    })
    useRegisterDirtyFormChecker(FormNames.addTeamMembers, form)

    const onConfirmModalSuccess = (newMembers: AwaitedReturnType<typeof projectApi.addProjectTeamMembers>) => {
        notificationApi.open({
            message: (
                <NotificationMessage
                    title={
                        <FormattedMessage
                            {...lang.addMembersNotificationSuccessTitle}
                            values={{ membersCount: newMembers.length }}
                        />
                    }
                    type="success"
                    description={
                        <FormattedMessage
                            {...lang.addMembersNotificationSuccessDescription}
                            values={{ membersCount: newMembers.length }}
                        />
                    }
                    dataMerchant="add-members-success-notification"
                />
            ),
        })
        closeConfirmModal()
        onSuccess(newMembers)
        skipCheckAndCancel()
    }

    const handleFormFinish = () => {
        openConfirmModal()
        setShouldRenderConfirmModal(true)
    }

    return (
        <PlaceholderViewWrapper dataMerchantPrefix="merchant-add-members">
            {shouldRenderConfirmModal && (
                <AddMembersConfirmModal
                    getNewTeamMembers={form.getFieldsValue}
                    onCancel={closeConfirmModal}
                    open={isConfirmModalOpen}
                    onSuccess={onConfirmModalSuccess}
                    afterClose={() => setShouldRenderConfirmModal(false)}
                    data-merchant="add-members-confirm-modal"
                />
            )}
            <Flex vertical gap={16}>
                <Form
                    data-merchant="team-management-add-members-form"
                    layout="vertical"
                    form={form}
                    size="large"
                    onFinish={handleFormFinish}
                    initialValues={initialFormValues}
                >
                    <AddMembersFormList />
                </Form>
            </Flex>
        </PlaceholderViewWrapper>
    )
}
