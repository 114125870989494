import { Typography } from '@merchant/ui-kit/ant-design'
import dayjs from 'dayjs'
import { getTimezoneOffset } from '~features/SettingsPage/utils'

const { Text } = Typography
const dayjsDate = dayjs()

export const timezones = Intl.supportedValuesOf('timeZone').map(tz => {
    const offset = getTimezoneOffset(tz, dayjsDate)
    const timezone = tz.split('/').at(-1)?.replaceAll('_', ' ')

    return {
        value: tz,
        offset,
        label: (
            <>
                <Text data-merchant={`timezone-${timezone}`}>{timezone}</Text>{' '}
                <Text type="secondary" data-merchant={`timezone-offset-${offset}`}>
                    ∙ UTC {offset}
                </Text>
            </>
        ),
    }
})
