/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Payin } from './Payin';
import {
    PayinFromJSON,
    PayinFromJSONTyped,
    PayinToJSON,
} from './Payin';
import type { PaymentMethodType } from './PaymentMethodType';
import {
    PaymentMethodTypeFromJSON,
    PaymentMethodTypeFromJSONTyped,
    PaymentMethodTypeToJSON,
} from './PaymentMethodType';
import type { SwapInfo } from './SwapInfo';
import {
    SwapInfoFromJSON,
    SwapInfoFromJSONTyped,
    SwapInfoToJSON,
} from './SwapInfo';
import type { SwapInfoTxn } from './SwapInfoTxn';
import {
    SwapInfoTxnFromJSON,
    SwapInfoTxnFromJSONTyped,
    SwapInfoTxnToJSON,
} from './SwapInfoTxn';

/**
 * 
 * @export
 * @interface SelectedPaymentMethod
 */
export interface SelectedPaymentMethod {
    /**
     * 
     * @type {PaymentMethodType}
     * @memberof SelectedPaymentMethod
     */
    type: PaymentMethodType;
    /**
     * 
     * @type {Date}
     * @memberof SelectedPaymentMethod
     */
    selectedAt: Date;
    /**
     * 
     * @type {SwapInfo}
     * @memberof SelectedPaymentMethod
     */
    nominalHoldingSwap?: SwapInfo;
    /**
     * 
     * @type {SwapInfo}
     * @memberof SelectedPaymentMethod
     */
    nominalPayinSwap?: SwapInfo;
    /**
     * 
     * @type {string}
     * @memberof SelectedPaymentMethod
     */
    holdingAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof SelectedPaymentMethod
     */
    paymentFee?: string;
    /**
     * 
     * @type {string}
     * @memberof SelectedPaymentMethod
     */
    receivedAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof SelectedPaymentMethod
     */
    holdingCurrency: string;
    /**
     * 
     * @type {string}
     * @memberof SelectedPaymentMethod
     */
    holdingTransferTxnId?: string;
    /**
     * 
     * @type {SwapInfoTxn}
     * @memberof SelectedPaymentMethod
     */
    payinHoldingSwap?: SwapInfoTxn;
    /**
     * 
     * @type {string}
     * @memberof SelectedPaymentMethod
     */
    expectedPayinAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof SelectedPaymentMethod
     */
    remainingPayinAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof SelectedPaymentMethod
     */
    payinCurrency: string;
    /**
     * 
     * @type {string}
     * @memberof SelectedPaymentMethod
     */
    payinNetwork?: string;
    /**
     * 
     * @type {string}
     * @memberof SelectedPaymentMethod
     */
    payinAddress: string;
    /**
     * 
     * @type {string}
     * @memberof SelectedPaymentMethod
     */
    payinAddressTag?: string;
    /**
     * 
     * @type {Array<Payin>}
     * @memberof SelectedPaymentMethod
     */
    payins?: Array<Payin>;
}

/**
 * Check if a given object implements the SelectedPaymentMethod interface.
 */
// @ts-ignore
export function instanceOfSelectedPaymentMethod(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "selectedAt" in value;
    isInstance = isInstance && "holdingCurrency" in value;
    isInstance = isInstance && "payinCurrency" in value;
    isInstance = isInstance && "payinAddress" in value;

    return isInstance;
}

// @ts-ignore
export function SelectedPaymentMethodFromJSON(json: any): SelectedPaymentMethod {
    return SelectedPaymentMethodFromJSONTyped(json, false);
}

// @ts-ignore
export function SelectedPaymentMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectedPaymentMethod {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': PaymentMethodTypeFromJSON(json['type']),
        'selectedAt': (new Date(json['selected_at'])),
        'nominalHoldingSwap': !exists(json, 'nominal_holding_swap') ? undefined : SwapInfoFromJSON(json['nominal_holding_swap']),
        'nominalPayinSwap': !exists(json, 'nominal_payin_swap') ? undefined : SwapInfoFromJSON(json['nominal_payin_swap']),
        'holdingAmount': !exists(json, 'holding_amount') ? undefined : json['holding_amount'],
        'paymentFee': !exists(json, 'payment_fee') ? undefined : json['payment_fee'],
        'receivedAmount': !exists(json, 'received_amount') ? undefined : json['received_amount'],
        'holdingCurrency': json['holding_currency'],
        'holdingTransferTxnId': !exists(json, 'holding_transfer_txn_id') ? undefined : json['holding_transfer_txn_id'],
        'payinHoldingSwap': !exists(json, 'payin_holding_swap') ? undefined : SwapInfoTxnFromJSON(json['payin_holding_swap']),
        'expectedPayinAmount': !exists(json, 'expected_payin_amount') ? undefined : json['expected_payin_amount'],
        'remainingPayinAmount': !exists(json, 'remaining_payin_amount') ? undefined : json['remaining_payin_amount'],
        'payinCurrency': json['payin_currency'],
        'payinNetwork': !exists(json, 'payin_network') ? undefined : json['payin_network'],
        'payinAddress': json['payin_address'],
        'payinAddressTag': !exists(json, 'payin_address_tag') ? undefined : json['payin_address_tag'],
        'payins': !exists(json, 'payins') ? undefined : ((json['payins'] as Array<any>).map(PayinFromJSON)),
    };
}

// @ts-ignore
export function SelectedPaymentMethodToJSON(value?: SelectedPaymentMethod | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': PaymentMethodTypeToJSON(value.type),
        'selected_at': (value.selectedAt.toISOString()),
        'nominal_holding_swap': SwapInfoToJSON(value.nominalHoldingSwap),
        'nominal_payin_swap': SwapInfoToJSON(value.nominalPayinSwap),
        'holding_amount': value.holdingAmount,
        'payment_fee': value.paymentFee,
        'received_amount': value.receivedAmount,
        'holding_currency': value.holdingCurrency,
        'holding_transfer_txn_id': value.holdingTransferTxnId,
        'payin_holding_swap': SwapInfoTxnToJSON(value.payinHoldingSwap),
        'expected_payin_amount': value.expectedPayinAmount,
        'remaining_payin_amount': value.remainingPayinAmount,
        'payin_currency': value.payinCurrency,
        'payin_network': value.payinNetwork,
        'payin_address': value.payinAddress,
        'payin_address_tag': value.payinAddressTag,
        'payins': value.payins === undefined ? undefined : ((value.payins as Array<any>).map(PayinToJSON)),
    };
}

