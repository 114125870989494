import { HttpError } from '@merchant/shared/api'
import { isKeyOfObject } from '@merchant/shared/utils'
import { isEmpty } from 'lodash-es'
import { lang } from './lang'
import type { IntlShape, MessageDescriptor } from 'react-intl'
import { APIErrorCode } from '~api/instances/cabinet-api'
import { globalLang } from '~globalLang'

const errorsCodesMap: Record<APIErrorCode.InvalidTotp, MessageDescriptor> = {
    [APIErrorCode.InvalidTotp]: lang.invalidTotpCode,
} as const

export const processErrorResponse = (error: unknown, intl: IntlShape): string[] => {
    const errors: string[] = []
    if (HttpError.isInstance(error)) {
        error.responseData?.errors?.forEach(e => {
            const errorMessage = e.code && isKeyOfObject(e.code, errorsCodesMap) ? errorsCodesMap[e.code] : null

            if (errorMessage) {
                const formattedErrorMessage = intl.formatMessage(errorMessage)
                errors.push(formattedErrorMessage)
            }
        })
    }

    return !isEmpty(errors) ? errors : [intl.formatMessage(globalLang.somethingWentWrong)]
}
