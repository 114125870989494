import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    orderIdTooltip: {
        id: 'payment-constructor.form.order_id_tooltip',
        defaultMessage: 'Order ID in your system, example: "Order_123"',
    },
    clientEmailTooltip: {
        id: 'payment-constructor.form.client_email_tooltip',
        defaultMessage:
            "(Optional) Enter the client's email address as registered in your system, e.g., someone@email.com. If provided here, the client will not be required to enter it again on the payment page.",
    },
    clientIdTooltip: {
        id: 'payment-constructor.form.client_id_tooltip',
        defaultMessage: "(Optional) Enter the client's ID as it appears in your system, e.g., User_ID_123.",
    },
    successRedirectTooltip: {
        id: 'payment-constructor.form.success_redirect_tooltip',
        defaultMessage: '(Optional) Direct users here after successful payments, e.g., https://yourwebsite.com/success',
    },
    failRedirectTooltip: {
        id: 'payment-constructor.form.fail_redirect_tooltip',
        defaultMessage: '(Optional) Direct users here after failed payments, e.g., https://yourwebsite.com/fail',
    },
    underpaymentLabel: {
        id: 'payment-constructor.form.underpayment_label',
        defaultMessage: 'Allowed underpayment threshold',
    },
    underpaymentDescription: {
        id: 'payment-constructor.form.underpayment_description',
        defaultMessage: 'Invoice is accepted as completed if percent of underpayment is not more than:',
    },
    underpaymentTooltip: {
        id: 'payment-constructor.form.underpayment_tooltip',
        defaultMessage: 'The percentage of the order amount that the client can underpay',
    },
    underpaymentAbsoluteDefault: {
        id: 'payment-constructor.form.underpayment_absolute_default',
        defaultMessage: '(max: {amount} {currency})',
    },
})
