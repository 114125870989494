import type { SetURLSearchParams } from 'react-router-dom'
import { QueryParams } from '~constants/routes'
import { Actions } from '~types/actionModal'

// Normalize old values of action query param to new ones (ex. Actions.withdrawal -> Actions.withdrawalCrypto)
// and remove invalid values
export function normalizeQueryAction(
    action: string | null,
    searchParams: URLSearchParams,
    setSearchParams: SetURLSearchParams
) {
    if (action === Actions.withdrawal) {
        searchParams.set(QueryParams.action, Actions.withdrawalCrypto)
        setSearchParams(searchParams)
    }

    if (!Object.values(Actions).includes(action as Actions)) {
        searchParams.delete(QueryParams.action)
        setSearchParams(searchParams)
    }
}
