import { ModalResponsive, NotificationMessage } from '@merchant/shared/components'
import { useNotifications } from '@merchant/shared/contexts'
import { FormattedMessage } from 'react-intl'
import { FormattedMessageWithBoldText, ConfirmationView } from '..'
import { lang } from './lang'
import type { ConfirmationItems } from '../ConfirmationView/types'
import type { ModalProps } from '@merchant/ui-kit/ant-design'
import type { WhitelistWithKey } from '~api/swr/rest/types'
import { projectApi, useProject, useWhitelist } from '~api'
import { GenerateVerificationCodeRequestPurpose } from '~api/instances/cabinet-api'
import { withDirtyCheckingContextProvider } from '~hoc/withDirtyCheckingContextProvider'
import { useDirtyFormCheckerControls } from '~hooks/useDirtyFormCheckerControls'

interface Props extends Omit<ModalProps, 'data-merchant'> {
    selectedWhitelist: WhitelistWithKey | null
    close: () => void
}

function Component({ close, selectedWhitelist, ...rest }: Props) {
    const { mutate: mutateWhitelist } = useWhitelist()
    const { notification } = useNotifications()
    const { handleCancel } = useDirtyFormCheckerControls(close)
    const { data: { id: projectId = '' } = {} } = useProject()
    if (!selectedWhitelist) {
        return null
    }

    const confirmationRequest = (values: ConfirmationItems) =>
        projectApi.deleteAddressFromProjectWhitelist({
            whitelistAddressUpdateRequest: { ...selectedWhitelist, ...values },
            projectId,
        })

    const handleConfirmationSuccess = () => {
        mutateWhitelist((prev = { addresses: [], enabled: false }) => ({
            enabled: prev.enabled,
            addresses: prev.addresses.filter(address => address.key !== selectedWhitelist.key),
        }))
        notification.api.open({
            message: (
                <NotificationMessage
                    type="success"
                    title={<FormattedMessage {...lang.successNotificationTitle} />}
                    description={<FormattedMessage {...lang.successNotificationDescription} />}
                    dataMerchant="delete-whitelist-success-notification"
                />
            ),
        })
        close()
    }

    return (
        <ModalResponsive
            data-merchant="whitelist-delete-address-modal"
            onCancel={handleCancel}
            destroyOnClose
            title={
                <span data-merchant="whitelist-delete-address-modal-title">
                    <FormattedMessage {...lang.modalTitle} />
                </span>
            }
            footer={null}
            {...rest}
        >
            <ConfirmationView
                alertMessage={<FormattedMessageWithBoldText message={lang.deleteWhitelistAlertDescription} />}
                request={confirmationRequest}
                onSuccess={handleConfirmationSuccess}
                buttonTitle={lang.submitButton}
                purpose={GenerateVerificationCodeRequestPurpose.RemoveFromWhitelist}
                whitelist={selectedWhitelist}
            />
        </ModalResponsive>
    )
}

export const DeleteWhitelistedAddressModal = withDirtyCheckingContextProvider(Component)
