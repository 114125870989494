import { useFormValidation, usePromise } from '@merchant/shared/hooks'
import { Form, Divider, Button } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { formItems, initValues } from './const'
import type { DeleteFormData } from './types'
import { authApi, useMerchant } from '~api'
import { GenerateVerificationCodeRequestPurpose, type TOTP } from '~api/instances/cabinet-api'
import { EmailConfirmationItem, TwoFaConfirmationItem } from '~features/FormItems'
import { globalLang } from '~globalLang'

interface Props {
    id?: TOTP['id']
    onDeleteSuccess: () => void
}

export function DeleteForm({ onDeleteSuccess, id }: Props) {
    const { data: merchant } = useMerchant()
    const [form] = Form.useForm()
    const { onBlur, onFinishFailed, validationMode } = useFormValidation(Object.values(formItems))

    const { send, isLoading } = usePromise(authApi.deleteTOTP.bind(authApi), {
        onSuccess: onDeleteSuccess,
    })

    const handleResendClick = () =>
        authApi.generateVerificationCode({
            generateVerificationCodeRequest: {
                purpose: GenerateVerificationCodeRequestPurpose.Disable2fa,
            },
        })

    const handleFormFinish = (values: DeleteFormData) => {
        if (id) {
            send({ id, deleteTOTPRequest: { ...values } })
        }
    }

    return (
        <Form
            requiredMark={false}
            layout="vertical"
            form={form}
            size="large"
            initialValues={initValues}
            onFinish={handleFormFinish}
            onFinishFailed={onFinishFailed}
        >
            <EmailConfirmationItem
                email={merchant?.email || 'unknown email'}
                onBlur={onBlur(formItems.verificationCode)}
                onResendClick={handleResendClick}
                validateTrigger={validationMode.verificationCode}
                shouldSendOnMount
                dataMerchant="delete-twofa-email-confirmation-input"
            />
            <Divider />
            <TwoFaConfirmationItem
                dataMerchant="delete-twofa-twofa-confirmation-input"
                onToggle={() => form.resetFields([formItems.totpBackup, formItems.totpCode])}
                onBlur={onBlur('totpCode')}
                validateTrigger={validationMode.totpCode}
            />
            <Form.Item noStyle>
                <Button
                    data-merchant="delete-twofa-submit-button"
                    loading={isLoading}
                    htmlType="submit"
                    block
                    type="primary"
                >
                    <FormattedMessage {...globalLang.confirm} />
                </Button>
            </Form.Item>
        </Form>
    )
}
