import { TxHash, CopyButton } from '@merchant/shared/components'
import { useBreakpoint } from '@merchant/shared/hooks'
import { Typography, Row, Col, Popover } from '@merchant/ui-kit/ant-design'
import { lang } from './../../lang'
import styles from './../../style.module.css'
import { getContentColFlex } from './utils'
import type { Transaction } from '~api/instances/cabinet-api'
import { useCurrencies } from '~api'
import { globalLang } from '~globalLang'

const { Text } = Typography

interface Props extends Pick<Transaction, 'txId' | 'currency' | 'network'> {
    hash?: string
}

export function TransactionId({ txId, hash, currency, network }: Props) {
    const { data: currencies } = useCurrencies()
    const breakpoints = useBreakpoint()

    const renderContent = ({ isPopoverContent }: { isPopoverContent: boolean }) => (
        <>
            <Row wrap={false} gutter={isPopoverContent ? 4 : 0}>
                <Col flex={getContentColFlex(isPopoverContent, breakpoints)}>
                    <Text ellipsis={!isPopoverContent} data-merchant={`transaction-id-${txId}`}>
                        {txId}
                    </Text>
                </Col>
                <Col className={!isPopoverContent ? styles.idCopyIcon : ''}>
                    <CopyButton
                        data-merchant="transaction-id-copy-button"
                        inputValue={txId}
                        notificationContentSubject={lang.id}
                    />
                </Col>
            </Row>

            {!!hash && (
                <Row wrap={false} gutter={isPopoverContent ? 4 : 0}>
                    <Col flex={getContentColFlex(isPopoverContent, breakpoints)}>
                        <TxHash
                            shouldShowPrefix
                            currencies={currencies}
                            currencyCode={currency}
                            hash={hash}
                            ellipsis={!isPopoverContent}
                            networkCode={network}
                        />
                    </Col>
                    <Col className={!isPopoverContent ? styles.idCopyIcon : ''}>
                        <CopyButton
                            data-merchant="transaction-hash-copy-button"
                            inputValue={hash}
                            notificationContentSubject={globalLang.hash}
                        />
                    </Col>
                </Row>
            )}
        </>
    )

    return (
        <Popover
            content={renderContent({ isPopoverContent: true })}
            placement={breakpoints.md ? 'bottom' : 'top'}
            overlayStyle={{ maxWidth: 'calc(100vw - 18px)' }}
            data-merchant="transaction-id-popover"
        >
            {renderContent({ isPopoverContent: false })}
        </Popover>
    )
}
