import { assets } from '@merchant/shared/assets/nameMap'
import { NotFound, AppSvg, CopyButton, InlineLinkButton } from '@merchant/shared/components'
import { getAddressTagName } from '@merchant/shared/utils'
import { Col, Flex, Row, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ORDER_TERMINAL_STATES } from './const'
import { lang } from './lang'
import { OrderDetailsCard } from './OrderDetailsCard'
import { OrderMetaCard } from './OrderMetaCard'
import { OrderSummaryCard } from './OrderSummaryCard'
import { OrderTimelineCard } from './OrderTimelineCard'
import type { ReactNode } from 'react'
import { useCurrencies, usePayment } from '~api'
import { routes } from '~constants/routes'

const { Title } = Typography

const REFRESH_INTERVAL = 5000

export function OrderDetailsPage() {
    const { orderID: orderIdParam } = useParams()
    const navigate = useNavigate()
    const location = useLocation()

    const {
        data,
        isLoading: isOrderLoading,
        error,
    } = usePayment(
        { orderId: orderIdParam as string },
        {
            refreshInterval: result =>
                result?.state && ORDER_TERMINAL_STATES.includes(result.state) ? 0 : REFRESH_INTERVAL,
            shouldRetryOnError: false,
        }
    )
    const { data: currencies } = useCurrencies()

    const addressTagName = getAddressTagName(
        currencies,
        data?.selectedPaymentMethod?.payinCurrency,
        data?.selectedPaymentMethod?.payinNetwork
    )

    const orderId = data?.id || orderIdParam

    const handleBackClick = () => {
        if (location.key === 'default') {
            return navigate(routes.orders)
        }
        navigate(-1)
    }

    let content: ReactNode

    if (error) {
        content = <NotFound onBackClick={void navigate(routes.orders)} dataMerchantPrefix="orders-page" />
    } else {
        content = (
            <>
                <OrderSummaryCard isLoading={isOrderLoading} data={data} />
                <Row gutter={[16, 16]} wrap>
                    <Col xs={24} lg={12} style={{ display: 'inline-flex', alignSelf: 'stretch' }}>
                        <Flex gap={16} vertical style={{ width: '100%' }}>
                            <OrderDetailsCard isLoading={isOrderLoading} data={data} />
                            <OrderMetaCard loading={isOrderLoading} data={data} />
                        </Flex>
                    </Col>
                    <Col xs={24} lg={12}>
                        <OrderTimelineCard loading={isOrderLoading} data={data} addressTagName={addressTagName} />
                    </Col>
                </Row>
            </>
        )
    }

    return (
        <Flex vertical gap={16} style={{ height: '100%' }}>
            <Flex align="center" gap={4}>
                <InlineLinkButton
                    size="large"
                    shape="round"
                    data-merchant="order-details-back-button"
                    icon={<AppSvg size={20} name={assets.arrowNarrowLeft} />}
                    onClick={handleBackClick}
                />
                <Flex gap={4}>
                    <Title level={4} style={{ display: 'inline' }} data-merchant={null}>
                        <FormattedMessage {...lang.pageTitle} />
                    </Title>{' '}
                    {orderId && (
                        <Title
                            data-merchant="order-details-order-id"
                            level={4}
                            style={{ display: 'inline' }}
                            type="secondary"
                        >
                            #{orderId}
                        </Title>
                    )}
                </Flex>
                <CopyButton
                    data-merchant="order-details-order-id-copy-button"
                    inputValue={orderId || ''}
                    notificationContentSubject={lang.orderId}
                />
            </Flex>
            {content}
        </Flex>
    )
}
