import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    noMatchTitle: {
        id: 'shared.empty.no_match_title',
        defaultMessage: '👀',
    },
    noMatchPaymentSubtitle: {
        id: 'shared.payment.empty.no_match_subtitle',
        defaultMessage: 'Oops! No Match',
    },
    noMatchPaymentDescription: {
        id: 'shared.payment.empty.no_match_description',
        defaultMessage: "Can't find that currency. Double-check your input or try another.",
    },
    noOrdersSubtitle: {
        id: 'shared.orders.empty.no_orders_subtitle',
        defaultMessage: 'No orders yet',
    },
    noWithdrawalsSubtitle: {
        id: 'shared.withdrawals.empty.no_withdrawals_subtitle',
        defaultMessage: 'Oops! No Match',
    },
    noWithdrawalsDescription: {
        id: 'shared.payment.empty.no_withdrawals_description',
        defaultMessage: 'Double-check your filters or try another.',
    },
})
