/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WhitelistAddressUpdateRequest
 */
export interface WhitelistAddressUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof WhitelistAddressUpdateRequest
     */
    totpCode?: string;
    /**
     * 
     * @type {string}
     * @memberof WhitelistAddressUpdateRequest
     */
    totpBackup?: string;
    /**
     * 
     * @type {string}
     * @memberof WhitelistAddressUpdateRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof WhitelistAddressUpdateRequest
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof WhitelistAddressUpdateRequest
     */
    network?: string;
    /**
     * 
     * @type {string}
     * @memberof WhitelistAddressUpdateRequest
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof WhitelistAddressUpdateRequest
     */
    addressTag?: string;
    /**
     * 
     * @type {string}
     * @memberof WhitelistAddressUpdateRequest
     */
    verificationCode: string;
}

/**
 * Check if a given object implements the WhitelistAddressUpdateRequest interface.
 */
// @ts-ignore
export function instanceOfWhitelistAddressUpdateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "verificationCode" in value;

    return isInstance;
}

// @ts-ignore
export function WhitelistAddressUpdateRequestFromJSON(json: any): WhitelistAddressUpdateRequest {
    return WhitelistAddressUpdateRequestFromJSONTyped(json, false);
}

// @ts-ignore
export function WhitelistAddressUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): WhitelistAddressUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totpCode': !exists(json, 'totp_code') ? undefined : json['totp_code'],
        'totpBackup': !exists(json, 'totp_backup') ? undefined : json['totp_backup'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'network': !exists(json, 'network') ? undefined : json['network'],
        'address': json['address'],
        'addressTag': !exists(json, 'address_tag') ? undefined : json['address_tag'],
        'verificationCode': json['verification_code'],
    };
}

// @ts-ignore
export function WhitelistAddressUpdateRequestToJSON(value?: WhitelistAddressUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totp_code': value.totpCode,
        'totp_backup': value.totpBackup,
        'name': value.name,
        'currency': value.currency,
        'network': value.network,
        'address': value.address,
        'address_tag': value.addressTag,
        'verification_code': value.verificationCode,
    };
}

