import { SearchOutlined } from '@ant-design/icons'
import { useBreakpoint, useDebouncedState } from '@merchant/shared/hooks'
import { Button, Col, Flex, Input, Row, Switch, Table, Typography } from '@merchant/ui-kit/ant-design'
import { filter } from 'lodash-es'
import React, { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { NavLink } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts'
import { lang } from './lang'
import styles from './style.module.css'
import { getContainerBodyStyles } from './styles'
import { getColumns } from './utils'
import { useBalances, useCurrencies, useMerchant } from '~api'
import { Permission } from '~api/instances/cabinet-api'
import { EmptyTable, PageContentContainer } from '~components'
import { TableError } from '~components/TableError'
import { showZeroBalances } from '~constants/localStorage'
import { routes as ApiRoutes } from '~constants/routes'
import { useUserPermissionContext } from '~contexts'
import { TransactionsTable } from '~features'
import { useSwapEnabled } from '~hooks'
import { useDepositEligibleCurrencies } from '~hooks/useDepositEligibleCurrencies'

const { Title, Text } = Typography

function EmptyBalances({ isLoading, onAction }: { isLoading: boolean; onAction: () => void }) {
    if (isLoading) {
        return <div style={{ height: 100 }} />
    }

    return (
        <EmptyTable
            subtitleDescriptor={lang.noBalancesSubtitle}
            renderAction={() => (
                <Button size="large" onClick={onAction} data-merchant="show-zero-balances-button">
                    <FormattedMessage {...lang.showZeroBalances} />
                </Button>
            )}
        />
    )
}

// eslint-disable-next-line max-lines-per-function
export function Content() {
    const { checkUserPermission } = useUserPermissionContext()
    const intl = useIntl()
    const breakpoints = useBreakpoint()
    const [searchValue, setSearchValue, setSearchValueImmediately] = useDebouncedState('', 200)
    const [areZeroBalancesShown, setZeroBalancesShown] = useLocalStorage(showZeroBalances, false)

    const searchCurrencyPlaceholder = intl.formatMessage(lang.searchCurrencyPlaceholder)
    const { data: merchant, isLoading: isLoadingMerchant } = useMerchant()
    const { data: balances, isLoading: isLoadingBalances, error: balancesError, mutate: mutateBalances } = useBalances()
    const { data: currencies, isLoading: isLoadingCurrencies } = useCurrencies()
    const depositEligibleCurrencies = useDepositEligibleCurrencies()

    const balancesSortedWithName = useMemo(() => {
        if (!balances || !currencies) {
            return []
        }

        const withName = balances.map(b => ({
            ...b,
            name: currencies[b.currency]?.name,
        }))

        return withName.sort((a, b) => {
            const aEquivalent = Number(a.fiatEquivalentAmount)
            const bEquivalent = Number(b.fiatEquivalentAmount)
            if (bEquivalent !== aEquivalent) {
                return bEquivalent - aEquivalent
            }

            const aValue = currencies[a.currency]?.crypto ? 1 : 0
            const bValue = currencies[b.currency]?.crypto ? 1 : 0

            return aValue - bValue
        })
    }, [balances, currencies])

    const filteredData = useMemo(() => {
        return filter(balancesSortedWithName, ({ currency, name, amount }) => {
            const matchesSearch = `${currency} ${name || ''}`.toLowerCase().includes(searchValue.toLowerCase().trim())

            return matchesSearch && (areZeroBalancesShown ? true : Number(amount) > 0)
        })
    }, [areZeroBalancesShown, balancesSortedWithName, searchValue])

    const isLoadingTableContent = isLoadingBalances || isLoadingCurrencies || isLoadingMerchant
    const isSwapEnabled = useSwapEnabled()

    return (
        <>
            <PageContentContainer
                styles={{ body: getContainerBodyStyles(breakpoints) }}
                data-merchant="balances-container"
            >
                {balancesError ? (
                    <TableError titleDescriptor={lang.loadingBalancesFailed} onRetry={mutateBalances} />
                ) : (
                    <Table
                        data-merchant="balances-page-table"
                        tableLayout="auto"
                        className={styles.table}
                        locale={{
                            emptyText: (
                                <EmptyBalances
                                    isLoading={isLoadingTableContent}
                                    onAction={() => setZeroBalancesShown(true)}
                                />
                            ),
                        }}
                        title={() => (
                            <Row justify="space-between" wrap gutter={[12, 12]}>
                                <Col sm={{ span: 24 }} xs={{ span: 24 }} md={{ span: 8, flex: 1 }}>
                                    <Input
                                        allowClear
                                        onClear={() => setSearchValueImmediately('')}
                                        style={{
                                            maxWidth: breakpoints.md ? 200 : 'unset',
                                        }}
                                        autoComplete="off"
                                        data-merchant="balances-table-search-input"
                                        className={styles.searchInput}
                                        onChange={e => setSearchValue(e.target.value)}
                                        placeholder={searchCurrencyPlaceholder}
                                        prefix={<SearchOutlined />}
                                    />
                                </Col>
                                <Col sm={{ flex: 1 }} xs={{ flex: 1 }} md={{ flex: 0 }} flex={0}>
                                    <Flex
                                        justify={breakpoints.md ? 'flex-end' : 'space-between'}
                                        gap={12}
                                        align="center"
                                        wrap="wrap-reverse"
                                    >
                                        <Text
                                            style={{ fontSize: 14 }}
                                            type="secondary"
                                            data-merchant="balances-page-zero-balances-text"
                                        >
                                            <FormattedMessage {...lang.showZeroBalances} />
                                        </Text>
                                        <Switch
                                            checked={areZeroBalancesShown}
                                            onChange={setZeroBalancesShown}
                                            data-merchant="balances-page-zero-balances-switch"
                                        />
                                    </Flex>
                                </Col>
                            </Row>
                        )}
                        dataSource={filteredData}
                        columns={getColumns({
                            currencies,
                            equivalentCurrencyCode: merchant?.currency,
                            isConvertAvailable: isSwapEnabled,
                            depositEligibleCurrencies,
                        })}
                        loading={isLoadingBalances || isLoadingCurrencies}
                        rowKey={record => record.currency}
                        scroll={{ y: 650 }}
                        pagination={false}
                    />
                )}
            </PageContentContainer>
            {checkUserPermission(Permission.SeeTransactions) && (
                <PageContentContainer data-merchant="transactions-container">
                    <Row justify="space-between" align="middle">
                        <Col>
                            <Title data-merchant="balances-page-recent-transactions" level={5}>
                                <FormattedMessage {...lang.recentTransactions} />
                            </Title>
                        </Col>
                        <Col>
                            <NavLink to={ApiRoutes.transactions} data-merchant="balances-page-view-transactions-link">
                                <FormattedMessage {...lang.viewAll} />
                            </NavLink>
                        </Col>
                    </Row>
                    <TransactionsTable context="balances" />
                </PageContentContainer>
            )}
        </>
    )
}
