import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useBlocker } from 'react-router-dom'
import { useDirtyCheckingContext } from '~contexts/dirtyChecking.context'

// FIXME: :remove any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDirtyFormCheckerControls = (onCancel?: (e?: any) => void, { checkForRouteChange = false } = {}) => {
    const { dirtyCheckCb, setDirtyCheckCb } = useDirtyCheckingContext()
    const intl = useIntl()

    const callConfirmDialogOrProceed = <T>(proceedCb: () => T, abortCb: () => T) => {
        const dirtyCheckResult = dirtyCheckCb?.()
        if (dirtyCheckResult?.isDirty) {
            if (window.confirm(intl.formatMessage(dirtyCheckResult.dirtyPromptMessageDescriptor))) {
                setDirtyCheckCb(null)

                return proceedCb()
            }

            return abortCb()
        }

        setDirtyCheckCb(null)

        return proceedCb()
    }

    const handleCancel = () => {
        callConfirmDialogOrProceed(
            () => onCancel?.(),
            () => null
        )
    }

    const skipCheckAndCancel = () => {
        onCancel?.()
        setDirtyCheckCb(null)
    }

    useEffect(() => {
        const listener = (e: BeforeUnloadEvent) => {
            const dirtyCheckResult = dirtyCheckCb?.()
            if (!dirtyCheckResult?.isDirty) {
                return null
            }
            // Recommended
            e.preventDefault()

            // Included for legacy support, e.g. Chrome/Edge < 119
            e.returnValue = true

            return ''
        }
        window.addEventListener('beforeunload', listener)

        return () => window.removeEventListener('beforeunload', listener)
    }, [dirtyCheckCb])

    useBlocker(() =>
        checkForRouteChange
            ? callConfirmDialogOrProceed(
                  () => false,
                  () => true
              )
            : false
    )

    return { handleCancel, skipCheckAndCancel }
}
