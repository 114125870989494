import { Select, Tooltip } from '@merchant/ui-kit/ant-design'
import type { SelectProps, TooltipProps } from '@merchant/ui-kit/ant-design'
import { SearchableSelect, type SearchableSelectProps } from '~components'

interface SelectWithTooltipProps extends SelectProps, Pick<SearchableSelectProps, 'searchPlaceholder'> {
    tooltipProps?: TooltipProps
    'data-merchant': string
}

export function SelectWithTooltip({ tooltipProps, children, showSearch, ...rest }: SelectWithTooltipProps) {
    const SelectableComponent = showSearch ? SearchableSelect : Select

    return (
        <Tooltip
            overlayStyle={{ maxWidth: 400 }}
            {...tooltipProps}
            data-merchant={tooltipProps ? `${tooltipProps['data-merchant']}` : `${rest['data-merchant']}-tooltip`}
        >
            <SelectableComponent {...rest}>{children}</SelectableComponent>
        </Tooltip>
    )
}
