import type { Breakpoint } from '@merchant/ui-kit/ant-design'
import { Permission } from '~api/instances/cabinet-api'
import { routes } from '~constants/routes'

export const getIsSiderCollapsed = (breakpoints: Partial<Record<Breakpoint, boolean>>) =>
    (!breakpoints.xl && breakpoints.lg) || false

export function getDefaultNavigateTo(
    checkUserPermission: (permission: Permission | Permission[]) => boolean,
    locationSearch: Location['search']
) {
    const targetPath = checkUserPermission(Permission.SeePaymentHistory) ? routes.orders : routes.merchantSettings

    return `${targetPath}${locationSearch}`
}
