import { Tooltip, Typography } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { NavLink, useNavigate } from 'react-router-dom'
import { lang } from './lang'
import { calculateTotalBalance } from './utils'
import { useBalances, useCurrencies, useMerchant } from '~api'
import { routes } from '~constants/routes'
import { getEquivalentAmountWithSignAndPrecision } from '~utils'

const { Text, Title } = Typography

const equalTextStyles: React.CSSProperties = {
    verticalAlign: 'middle',
    fontSize: 16,
}

interface Props {
    isInHeader?: boolean
}

export function AmountWithSign({ isInHeader }: Props) {
    const navigate = useNavigate()
    const { data: merchant } = useMerchant()
    const { data: balances = [] } = useBalances()
    const { data: currencies } = useCurrencies()

    const totalEquivalentAmount = calculateTotalBalance(balances)
    const {
        amount: equivalentAmountWithPrecision,
        placement,
        sign,
    } = getEquivalentAmountWithSignAndPrecision(merchant?.currency, totalEquivalentAmount, currencies)
    const [integerPart, decimalPart] = equivalentAmountWithPrecision.split('.')

    const totalBalanceWithSignPlacement =
        placement === 'left' ? (
            <>
                {totalEquivalentAmount.gt(0) && (
                    <Text type="secondary" style={equalTextStyles} data-merchant={null}>
                        ≈
                    </Text>
                )}{' '}
                {merchant?.currency && currencies?.[merchant.currency]?.sign}
                {integerPart}
                <Text
                    type="secondary"
                    style={{ fontSize: isInHeader ? 12 : undefined }}
                    data-merchant="user-total-balance-decimals"
                >
                    .{decimalPart}
                </Text>
            </>
        ) : (
            <>
                <Text style={{ fontSize: 20 }} data-merchant="user-total-balance-amount">
                    {totalEquivalentAmount.gt(0) && (
                        <Text type="secondary" style={equalTextStyles} data-merchant={null}>
                            ≈
                        </Text>
                    )}{' '}
                    {equivalentAmountWithPrecision}
                    {sign}
                </Text>
            </>
        )

    return (
        <Tooltip
            placement="bottom"
            title={
                merchant && (
                    <Text data-merchant={null}>
                        <FormattedMessage
                            {...lang.totalBalanceTooltip}
                            values={{
                                currency: merchant.currency,
                                bold: chunks => <strong>{chunks}</strong>,
                                link: chunks => <NavLink to={routes.settings}>{chunks}</NavLink>,
                            }}
                        />
                    </Text>
                )
            }
            data-merchant="total-balance-tooltip"
        >
            <Title
                level={isInHeader ? 5 : 3}
                data-merchant="user-total-balance"
                onClick={() => navigate(routes.balances)}
                style={{ cursor: 'pointer', lineHeight: 1.25, wordSpacing: -2 }}
            >
                {totalBalanceWithSignPlacement}
            </Title>
        </Tooltip>
    )
}
