import { Space, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { descriptors, type Usage } from './const'

const { Title, Text } = Typography

const emptyWrapperStyles: React.CSSProperties = {
    display: 'flex',
    textAlign: 'center',
    maxWidth: 250,
    marginInline: 'auto',
    marginBlock: 50,
}

interface Props {
    usage?: Usage
    'data-merchant': string
}

export function CustomizedEmpty({ usage = 'default', ...props }: Props) {
    const { title, subtitle, description } = descriptors[usage]

    return (
        <Space style={emptyWrapperStyles} direction="vertical" size={8}>
            <Title level={3} data-merchant={`${props['data-merchant']}-title`}>
                <FormattedMessage {...title} />
            </Title>
            <Title level={5} data-merchant={`${props['data-merchant']}-subtitle`}>
                <FormattedMessage {...subtitle} />
            </Title>
            {description && (
                <Text type="secondary" data-merchant={`${props['data-merchant']}-description`}>
                    <FormattedMessage {...description} />
                </Text>
            )}
        </Space>
    )
}
