/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WithdrawalState } from './WithdrawalState';
import {
    WithdrawalStateFromJSON,
    WithdrawalStateFromJSONTyped,
    WithdrawalStateToJSON,
} from './WithdrawalState';

/**
 * 
 * @export
 * @interface WithdrawalThumb
 */
export interface WithdrawalThumb {
    /**
     * 
     * @type {string}
     * @memberof WithdrawalThumb
     */
    id: string;
    /**
     * 
     * @type {WithdrawalState}
     * @memberof WithdrawalThumb
     */
    state: WithdrawalState;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalThumb
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalThumb
     */
    orderId?: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalThumb
     */
    origin?: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalThumb
     */
    merchantId: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalThumb
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalThumb
     */
    creditCurrency: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalThumb
     */
    creditAmount: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalThumb
     */
    swapRate?: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalThumb
     */
    swapTxnId?: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalThumb
     */
    nominalReceiveCurrency?: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalThumb
     */
    nominalReceiveAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalThumb
     */
    receiveCurrency: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalThumb
     */
    receiveNetwork?: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalThumb
     */
    receiveAmount: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalThumb
     */
    networkFee: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalThumb
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalThumb
     */
    addressTag?: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalThumb
     */
    payoutTxnId?: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalThumb
     */
    hash?: string;
    /**
     * 
     * @type {Date}
     * @memberof WithdrawalThumb
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof WithdrawalThumb
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the WithdrawalThumb interface.
 */
// @ts-ignore
export function instanceOfWithdrawalThumb(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "merchantId" in value;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "creditCurrency" in value;
    isInstance = isInstance && "creditAmount" in value;
    isInstance = isInstance && "receiveCurrency" in value;
    isInstance = isInstance && "receiveAmount" in value;
    isInstance = isInstance && "networkFee" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;

    return isInstance;
}

// @ts-ignore
export function WithdrawalThumbFromJSON(json: any): WithdrawalThumb {
    return WithdrawalThumbFromJSONTyped(json, false);
}

// @ts-ignore
export function WithdrawalThumbFromJSONTyped(json: any, ignoreDiscriminator: boolean): WithdrawalThumb {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'state': WithdrawalStateFromJSON(json['state']),
        'note': !exists(json, 'note') ? undefined : json['note'],
        'orderId': !exists(json, 'order_id') ? undefined : json['order_id'],
        'origin': !exists(json, 'origin') ? undefined : json['origin'],
        'merchantId': json['merchant_id'],
        'projectId': json['project_id'],
        'creditCurrency': json['credit_currency'],
        'creditAmount': json['credit_amount'],
        'swapRate': !exists(json, 'swap_rate') ? undefined : json['swap_rate'],
        'swapTxnId': !exists(json, 'swap_txn_id') ? undefined : json['swap_txn_id'],
        'nominalReceiveCurrency': !exists(json, 'nominal_receive_currency') ? undefined : json['nominal_receive_currency'],
        'nominalReceiveAmount': !exists(json, 'nominal_receive_amount') ? undefined : json['nominal_receive_amount'],
        'receiveCurrency': json['receive_currency'],
        'receiveNetwork': !exists(json, 'receive_network') ? undefined : json['receive_network'],
        'receiveAmount': json['receive_amount'],
        'networkFee': json['network_fee'],
        'address': json['address'],
        'addressTag': !exists(json, 'address_tag') ? undefined : json['address_tag'],
        'payoutTxnId': !exists(json, 'payout_txn_id') ? undefined : json['payout_txn_id'],
        'hash': !exists(json, 'hash') ? undefined : json['hash'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
    };
}

// @ts-ignore
export function WithdrawalThumbToJSON(value?: WithdrawalThumb | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'state': WithdrawalStateToJSON(value.state),
        'note': value.note,
        'order_id': value.orderId,
        'origin': value.origin,
        'merchant_id': value.merchantId,
        'project_id': value.projectId,
        'credit_currency': value.creditCurrency,
        'credit_amount': value.creditAmount,
        'swap_rate': value.swapRate,
        'swap_txn_id': value.swapTxnId,
        'nominal_receive_currency': value.nominalReceiveCurrency,
        'nominal_receive_amount': value.nominalReceiveAmount,
        'receive_currency': value.receiveCurrency,
        'receive_network': value.receiveNetwork,
        'receive_amount': value.receiveAmount,
        'network_fee': value.networkFee,
        'address': value.address,
        'address_tag': value.addressTag,
        'payout_txn_id': value.payoutTxnId,
        'hash': value.hash,
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
    };
}

