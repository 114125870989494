export const enum AuthSteps {
    SignUp = 'sign_up',
    VerifyEmail = 'verify-email',
    VerifyTwoFa = 'verify-two-fa',
    SignIn = 'sign_in',
    PasswordRecovery = 'recovery',
    PasswordRecoverySetNew = 'password-recovery-new-password',
}

export const authFlows = {
    sign_in: AuthSteps.SignIn,
    sign_up: AuthSteps.SignUp,
    recovery: AuthSteps.PasswordRecovery,
}
