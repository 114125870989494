import { EmailSupportLink, StatisticFormattedNumber } from '@merchant/shared/components'
import { formatNumber, isLast } from '@merchant/shared/utils'
import { Col, Divider, Row, Typography } from '@merchant/ui-kit/ant-design'
import Big from 'big.js'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { NavLink } from 'react-router-dom'
import { lang as commonLang } from './../../lang'
import { lang } from './lang'
import { Context } from './types'
import type { GetContextInfo } from './types'
import type { WithdrawalFiatFormData } from '../../types'
import { routes } from '~constants/routes'
import { globalLang } from '~globalLang'

const { Text } = Typography

export function getFiatWithdrawalDetails(withdrawalFormData: WithdrawalFiatFormData) {
    const receiveAmount = Big(withdrawalFormData.amount)
        .minus(withdrawalFormData.fee || 0)
        .toString()

    const details = [
        [
            {
                id: 'withdrawalAmount',
                title: lang.withdrawalAmount,
                value: (
                    <StatisticFormattedNumber
                        value={withdrawalFormData.amount}
                        suffix={withdrawalFormData.currency}
                        precision={2}
                    />
                ),
                shouldShow: !!withdrawalFormData.amount,
            },
            {
                id: 'withdrawalFee',
                title: commonLang.withdrawalFee,
                value: (
                    <StatisticFormattedNumber
                        value={withdrawalFormData.fee}
                        suffix={withdrawalFormData.currency}
                        precision={2}
                    />
                ),
                shouldShow: !!withdrawalFormData.fee,
            },
        ],
        [
            {
                id: 'receiveAmount',
                title: commonLang.receiveAmount,
                value: (
                    <StatisticFormattedNumber
                        value={receiveAmount}
                        suffix={withdrawalFormData.currency}
                        precision={2}
                    />
                ),
                shouldShow: !!withdrawalFormData.fee && !!withdrawalFormData.amount,
            },
        ],
    ]

    return details.map((detailGroup, detailsGroupIndex) => {
        const filteredDetails = detailGroup.filter(detail => detail.shouldShow)

        return (
            <React.Fragment key={detailsGroupIndex}>
                {filteredDetails.map(detail => (
                    <Row justify="space-between" align="middle" key={detail.id}>
                        <Col>
                            <Text data-merchant={`withdrawal-fiat-details-title-${detail.id}`} type="secondary">
                                <FormattedMessage {...detail.title} />
                            </Text>
                        </Col>
                        <Col>
                            <Text
                                data-merchant={`withdrawal-fiat-details-value-${detail.id}`}
                                style={{
                                    fontWeight: 500,
                                    verticalAlign: 'middle',
                                }}
                            >
                                {detail.value}
                            </Text>
                        </Col>
                    </Row>
                ))}
                {filteredDetails.length > 0 && isLast(filteredDetails, detailsGroupIndex) && (
                    <Divider style={{ marginBlock: 16 }} />
                )}
            </React.Fragment>
        )
    })
}

export const getContextInfo: GetContextInfo = (currency, amount) => {
    return {
        [Context.Summary]: {
            iconName: 'arrowInCircle',
            headerTitle: (
                <FormattedMessage
                    {...lang.withdrawalSummaryTitle}
                    values={{
                        amount: formatNumber({
                            value: amount,
                            numberFormatOptions: { minimumFractionDigits: 2 },
                        }),
                        currency,
                    }}
                />
            ),
            headerSubtitle: <FormattedMessage {...lang.withdrawalSummarySubtitle} />,
        },
        [Context.Finish]: {
            success: {
                iconName: 'checkCircle',
                headerTitle: <FormattedMessage {...lang.withdrawalFinishSuccessTitle} />,
                headerSubtitle: (
                    <FormattedMessage
                        {...lang.withdrawalFinishSuccessSubtitle}
                        values={{
                            supportEmail: (
                                <EmailSupportLink style={{ fontSize: 'inherit' }}>
                                    <Text data-merchant={null} style={{ fontSize: 'inherit' }}>
                                        <FormattedMessage {...globalLang.merchantSupportEmail} />
                                    </Text>
                                </EmailSupportLink>
                            ),
                        }}
                    />
                ),
            },
            failed: {
                iconName: 'xCircle',
                headerTitle: <FormattedMessage {...lang.withdrawalFinishFailTitle} />,
                headerSubtitle: (
                    <FormattedMessage
                        {...lang.withdrawalFinishFailSubtitle}
                        values={{
                            ling: chunks => <NavLink to={routes.transactions}>{chunks}</NavLink>,
                        }}
                    />
                ),
            },
        },
    }
}
