/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    TeamMembersEmail,
    instanceOfTeamMembersEmail,
    TeamMembersEmailFromJSON,
    TeamMembersEmailFromJSONTyped,
    TeamMembersEmailToJSON,
} from './TeamMembersEmail';
import {
    WhitelistAddressEmail,
    instanceOfWhitelistAddressEmail,
    WhitelistAddressEmailFromJSON,
    WhitelistAddressEmailFromJSONTyped,
    WhitelistAddressEmailToJSON,
} from './WhitelistAddressEmail';

/**
 * @type GenerateVerificationCodeRequestDetails
 * 
 * @export
 */
export type GenerateVerificationCodeRequestDetails = TeamMembersEmail | WhitelistAddressEmail;

// @ts-ignore
export function GenerateVerificationCodeRequestDetailsFromJSON(json: any): GenerateVerificationCodeRequestDetails {
    return GenerateVerificationCodeRequestDetailsFromJSONTyped(json, false);
}

// @ts-ignore
export function GenerateVerificationCodeRequestDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenerateVerificationCodeRequestDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...TeamMembersEmailFromJSONTyped(json, true), ...WhitelistAddressEmailFromJSONTyped(json, true) };
}

// @ts-ignore
export function GenerateVerificationCodeRequestDetailsToJSON(value?: GenerateVerificationCodeRequestDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfTeamMembersEmail(value)) {
        return TeamMembersEmailToJSON(value as TeamMembersEmail);
    }
    if (instanceOfWhitelistAddressEmail(value)) {
        return WhitelistAddressEmailToJSON(value as WhitelistAddressEmail);
    }

    return {};
}

