/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FeesPayer } from './FeesPayer';
import {
    FeesPayerFromJSON,
    FeesPayerFromJSONTyped,
    FeesPayerToJSON,
} from './FeesPayer';
import type { LossConfig } from './LossConfig';
import {
    LossConfigFromJSON,
    LossConfigFromJSONTyped,
    LossConfigToJSON,
} from './LossConfig';
import type { ProjectStatusUpdate } from './ProjectStatusUpdate';
import {
    ProjectStatusUpdateFromJSON,
    ProjectStatusUpdateFromJSONTyped,
    ProjectStatusUpdateToJSON,
} from './ProjectStatusUpdate';

/**
 * 
 * @export
 * @interface UpdateProjectRequest
 */
export interface UpdateProjectRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequest
     */
    link?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequest
     */
    supportEmail?: string;
    /**
     * 
     * @type {ProjectStatusUpdate}
     * @memberof UpdateProjectRequest
     */
    status?: ProjectStatusUpdate;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequest
     */
    redirectUrlSuccess?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequest
     */
    redirectUrlFailure?: string | null;
    /**
     * 
     * @type {FeesPayer}
     * @memberof UpdateProjectRequest
     */
    feesPayer?: FeesPayer;
    /**
     * 
     * @type {LossConfig}
     * @memberof UpdateProjectRequest
     */
    lossConfig?: LossConfig;
}

/**
 * Check if a given object implements the UpdateProjectRequest interface.
 */
// @ts-ignore
export function instanceOfUpdateProjectRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

// @ts-ignore
export function UpdateProjectRequestFromJSON(json: any): UpdateProjectRequest {
    return UpdateProjectRequestFromJSONTyped(json, false);
}

// @ts-ignore
export function UpdateProjectRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateProjectRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'link': !exists(json, 'link') ? undefined : json['link'],
        'supportEmail': !exists(json, 'support_email') ? undefined : json['support_email'],
        'status': !exists(json, 'status') ? undefined : ProjectStatusUpdateFromJSON(json['status']),
        'redirectUrlSuccess': !exists(json, 'redirect_url_success') ? undefined : json['redirect_url_success'],
        'redirectUrlFailure': !exists(json, 'redirect_url_failure') ? undefined : json['redirect_url_failure'],
        'feesPayer': !exists(json, 'fees_payer') ? undefined : FeesPayerFromJSON(json['fees_payer']),
        'lossConfig': !exists(json, 'loss_config') ? undefined : LossConfigFromJSON(json['loss_config']),
    };
}

// @ts-ignore
export function UpdateProjectRequestToJSON(value?: UpdateProjectRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'link': value.link,
        'support_email': value.supportEmail,
        'status': ProjectStatusUpdateToJSON(value.status),
        'redirect_url_success': value.redirectUrlSuccess,
        'redirect_url_failure': value.redirectUrlFailure,
        'fees_payer': FeesPayerToJSON(value.feesPayer),
        'loss_config': LossConfigToJSON(value.lossConfig),
    };
}

