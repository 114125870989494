import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    invalidMinValue: {
        id: 'underpayment-relative.input.invalid_min_value',
        defaultMessage: 'Invalid value: must be greater than 0',
    },
    invalidMaxValue: {
        id: 'underpayment-relative.input.invalid_max_value',
        defaultMessage: 'Invalid value: maximum 99.99%',
    },
})
