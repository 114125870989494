import { assets } from '@merchant/shared/assets/nameMap'
import { LinkAsButton, AppSvg } from '@merchant/shared/components'
import { useBreakpoint } from '@merchant/shared/hooks'
import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import { Suspense } from 'react'
import { FormattedMessage } from 'react-intl'
import { excludedPlaceholderViews } from '../const'
import { Content } from './Content'
import { lang } from './lang'
import { Spinner } from './Spinner'
import { useBalances, useCurrencies, useProject } from '~api'
import { QueryParams, routes } from '~constants/routes'
import { MerchantSettingsShowOptions } from '~features/MerchantPage/components/MerchantSettings/types'
import { PlaceholderViewWrapper } from '~features/PlaceholderViewWrapper'
import { generateQuery } from '~utils'

const { Title } = Typography

export function BalancesPage() {
    const { data: project, isLoading: isProjectLoading } = useProject()
    const { isLoading: isLoadingBalances } = useBalances()
    const { isLoading: isLoadingCurrencies } = useCurrencies()
    const breakpoints = useBreakpoint()

    const isPageLoading = isProjectLoading || isLoadingBalances || isLoadingCurrencies

    return (
        <>
            <Flex justify="space-between" vertical={breakpoints.xs} gap={8}>
                <Title level={4} data-merchant="balances-page-title">
                    <FormattedMessage {...lang.pageTitle} />
                </Title>
                {!!project && (
                    <Flex gap={12}>
                        <LinkAsButton
                            to={routes.balancesSnapshots}
                            buttonProps={{
                                'data-merchant': 'balance-snapshots-button',
                                size: 'large',
                            }}
                        >
                            <FormattedMessage {...lang.snapshotsButton} />
                        </LinkAsButton>
                        <LinkAsButton
                            to={`${routes.merchantSettings}${generateQuery({ [QueryParams.show]: MerchantSettingsShowOptions.assets })}`}
                            buttonProps={{
                                'data-merchant': 'currencies-settings-button',
                                size: 'large',
                                type: 'primary',
                                icon: <AppSvg name={assets.settings} />,
                                style: { paddingLeft: 14, paddingRight: 14 },
                            }}
                        >
                            <FormattedMessage {...lang.currenciesSettingsButton} />
                        </LinkAsButton>
                    </Flex>
                )}
            </Flex>
            <PlaceholderViewWrapper
                dataMerchantPrefix="balances"
                isLoading={isPageLoading}
                excludedViews={excludedPlaceholderViews}
            >
                <Suspense fallback={<Spinner />}>
                    <Content />
                </Suspense>
            </PlaceholderViewWrapper>
        </>
    )
}
