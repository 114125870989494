import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    notesTitle: {
        id: 'deposit.card.notes.title',
        defaultMessage: '<b>Important notes</b>',
    },
    notesContent: {
        id: 'deposit.card.notes.content',
        defaultMessage: `
            <ul>
                <li>Only send <b>{currencyCode} {currencyName}</b> to this address from {networkPostfix}</li>
                {addressTagNoteItem}
                <li>Transfer of another currency is unrecoverable</li>
            </ul>
        `,
    },
    addressTagNoteItem: {
        id: 'deposit.card.notes.address_tag_note',
        defaultMessage:
            'Please make sure that you <b>double-check</b> the {destinationTag} before entering it, as any errors may result in a failed transaction or lost funds',
    },
    networkPostfixWithProtocol: {
        id: 'payment-page.important-notes.networkPostfix',
        defaultMessage: '<b>{name} network ({protocol})</b>',
    },
    networkPostfixWithoutProtocol: {
        id: 'payment-page.important-notes.networkPostfix_without_protocol',
        defaultMessage: '<b>{name} network</b>',
    },
})
