import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    listPasswordTitle: {
        id: 'settings-page.list.password_title',
        defaultMessage: 'Password',
    },
    listPasswordDescription: {
        id: 'settings-page.list.password_description',
        defaultMessage: 'We recommend changing password every 3 months',
    },
    listPasswordButton: {
        id: 'settings-page.list.password_button',
        defaultMessage: 'Change',
    },
    listTwoFaTitle: {
        id: 'settings-page.list.two_fa_title',
        defaultMessage: 'Two-factor authentication',
    },
    listTwoFaDescription: {
        id: 'settings-page.list.two_fa_description',
        defaultMessage: "Every time you sign in to your account, you'll need entering your password and a 2FA code",
    },
    accountNotProtectedText: {
        id: 'settings-page.list.two_fa_account_not_protected',
        defaultMessage: 'Account is not protected! Add 2FA method?',
    },
    add: {
        id: 'settings-page.button.add_two_fa',
        defaultMessage: 'Add',
    },
    addNew: {
        id: 'settings-page.button.add_new_two_fa',
        defaultMessage: 'Add new',
    },

    deleteTwoFaNotificationSuccessMessage: {
        id: 'settings-page.notification.delete_two_fa_success_message',
        defaultMessage: '2FA deleted!',
    },
    deleteTwoFaNotificationSuccessDescription: {
        id: 'settings-page.notification.delete_two_fa_success_description',
        defaultMessage: 'Your 2FA method has been removed successfully',
    },
    confirmTwoFaNotificationSuccessMessage: {
        id: 'settings-page.notification.confirm_two_fa_success_message',
        defaultMessage: '2FA confirmation!',
    },
    confirmTwoFaNotificationSuccessDescription: {
        id: 'settings-page.notification.confirm_two_fa_success_description',
        defaultMessage: 'Your 2FA method has been confirmed successfully',
    },
    addTwoFaNotificationSuccessMessage: {
        id: 'settings-page.notification.add_two_fa_success_message',
        defaultMessage: '2FA Activated!',
    },
})
