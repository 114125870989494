import copy from 'copy-to-clipboard'
import { useIntl } from 'react-intl'
import { lang } from './lang'
import type { CopyProps } from '.'
import { useNotifications } from '~contexts'

let counter = 1

/**
 * useCopy hook
 */
export function useCopy(showMessage = true) {
    const { message } = useNotifications()
    const intl = useIntl()
    const defaultMessage = intl.formatMessage(lang.useCopyDefaultContent)

    return ({ inputValue, notificationContent }: CopyProps) => {
        copy(inputValue)
        counter += 1
        showMessage &&
            message.api.open({
                type: 'info',
                onClose: () => {
                    counter -= 1
                },
                content: <span data-merchant={`copy-message-${counter}`}>{notificationContent ?? defaultMessage}</span>,
            })
    }
}
