import { useColorMode } from '@merchant/shared/hooks'
import { addAlpha } from '@merchant/shared/utils'
import { Tag } from '@merchant/ui-kit/ant-design'
import type { ProjectRole, ProjectTeamMember } from '~api/instances/cabinet-api'
import { rolesColor } from '~config/color'
import { useProjectRolesMap } from '~hooks'

interface Props {
    role: ProjectRole['id']
    id: ProjectTeamMember['id']
}

export function RoleTag({ role, id }: Props) {
    const { data: projectRolesMap } = useProjectRolesMap()
    const { color, name = role } = projectRolesMap[role] || {}
    const { isDark } = useColorMode()
    const rolesColorPalette = isDark ? rolesColor.night : rolesColor.day
    const roleColor = color ? rolesColorPalette[color] : rolesColorPalette['grey']

    return (
        <Tag
            data-merchant={`${id}-team-management-role-tag-${role}`}
            style={{
                marginInlineEnd: 0,
                backgroundColor: 'transparent',
                color: addAlpha(roleColor, 0.8),
                borderColor: addAlpha(roleColor, 0.5),
            }}
        >
            {name}
        </Tag>
    )
}
