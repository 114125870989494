import { Form } from '@merchant/ui-kit/ant-design'
import { staticAddressFormKeys } from '../../const'
import { useCurrencies } from '~api'

export function useData() {
    const { data: currencies, isLoading: isLoadingCurrencies } = useCurrencies()
    const form = Form.useFormInstance()
    const selectedCurrencyCode = Form.useWatch(staticAddressFormKeys.currency, form)
    const selectedCurrency = selectedCurrencyCode ? currencies?.[selectedCurrencyCode] : undefined

    return {
        isLoadingCurrencies,
        selectedCurrency,
    }
}
