import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    modalTitle: {
        id: 'settings-page.modal.edit_name_title',
        defaultMessage: 'Edit name',
    },
    name: {
        id: 'settings-page.modal.edit_name_label',
        defaultMessage: 'Name',
    },
    modalSubmitButton: {
        id: 'settings-page.modal.edit_name_submit_button',
        defaultMessage: 'Save',
    },
})
