import { LoadingOutlined } from '@ant-design/icons'
import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { Flex, Spin, Typography, theme } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { NavLink } from 'react-router-dom'
import { lang } from '../../lang'
import type { GlobalToken } from '@merchant/ui-kit/ant-design'
import type { Withdrawal } from '~api'
import { WithdrawalState } from '~api'
import { routes } from '~constants/routes'

const { useToken } = theme
const { Text } = Typography

const titleStyles: React.CSSProperties = { fontSize: 20, fontWeight: 500 }
const textStyles: React.CSSProperties = {
    fontSize: 16,
    lineHeight: '130%',
    display: 'inline-block',
    textAlign: 'center',
}

const progressStateContents = {
    [WithdrawalState.Pending]: {
        title: lang.pendingTitle,
        text: lang.pendingText,
        visualIndicator: (token: GlobalToken) => (
            <Spin
                size="large"
                indicator={<LoadingOutlined style={{ fontSize: 40, color: token.colorWarning }} />}
                data-merchant="action-modal-pending-spin"
            />
        ),
    },
    [WithdrawalState.Success]: {
        title: lang.successTitle,
        text: lang.successText,
        visualIndicator: () => {
            return <AppSvg size={48} name={assets.checkCircle} color={token => token.colorSuccess} />
        },
    },
    [WithdrawalState.Failed]: {
        title: lang.failedTitle,
        text: lang.failedText,
        visualIndicator: () => {
            return <AppSvg size={48} name={assets.xCircle} color={token => token.colorError} />
        },
    },
    [WithdrawalState.Canceled]: {
        title: lang.cancelledTitle,
        text: lang.cancelledText,
        visualIndicator: () => {
            return <AppSvg size={48} name={assets.xCircle} color={token => token.colorTextPlaceholder} />
        },
    },
}

interface Props {
    id: Withdrawal['id']
    state: WithdrawalState
}

export function OperationState({ id, state }: Props) {
    const stateContent = progressStateContents[state]

    const { token } = useToken()

    return (
        <Flex vertical align="center" style={{ marginTop: state === WithdrawalState.Failed ? 0 : 27 }} gap={8}>
            <div data-merchant={`operation-state-spinner-${state}`}>{stateContent.visualIndicator(token)}</div>
            <Text data-merchant={`operation-state-title-${state}`} style={titleStyles}>
                <FormattedMessage {...stateContent.title} />
            </Text>
            <Text data-merchant={`operation-state-text-${state}`} type="secondary" style={textStyles}>
                <FormattedMessage
                    {...stateContent.text}
                    values={{
                        withdrawalLink: chunks => (
                            <NavLink to={`${routes.withdrawals}/${id}`} target="_blank" style={textStyles}>
                                {chunks}
                            </NavLink>
                        ),
                    }}
                />
            </Text>
        </Flex>
    )
}
