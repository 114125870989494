import { Button, Flex, Space, Spin, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { GetApiDocsButton } from './GetApiDocsButton'
import { lang } from './lang'
import { Integrations } from './views'
import type { TOTP } from '~api/instances/cabinet-api'
import { useProject, useTotps } from '~api'
import { PageContentContainer } from '~components'
import { SetupTwoFa } from '~features'
import { PlaceholderView } from '~features/PlaceholderView'
import { globalLang } from '~globalLang'

const { Title } = Typography

export function IntegrationsPage() {
    const { data: project, isLoading: isProjectLoading, mutate: mutateProject, error: projectError } = useProject()
    const { hasActiveTotp, isLoading: isTotpsLoading, mutate: mutateTotps, error: totpsError } = useTotps()
    let content

    const onTryAgainClick = () => {
        projectError && mutateProject(undefined, { revalidate: true })
        totpsError && mutateTotps(undefined, { revalidate: true })
    }

    if (isTotpsLoading || isProjectLoading) {
        content = (
            <PageContentContainer
                style={{
                    height: 300,
                    display: 'grid',
                    placeItems: 'center',
                    paddingBlock: 20,
                }}
            >
                <Spin size="large" data-merchant="integrations-loading-spin" />
            </PageContentContainer>
        )
    } else {
        if (totpsError || projectError) {
            content = (
                <PageContentContainer style={{ paddingBlock: 20 }}>
                    <Flex vertical style={{ textAlign: 'center' }} align="center" justify="center" gap={12}>
                        <Title data-merchant={null} level={3}>
                            <FormattedMessage {...globalLang.requestErrorOccurred} />
                        </Title>
                        <Button
                            type="primary"
                            size="large"
                            onClick={onTryAgainClick}
                            data-merchant="integrations-try-again-button"
                        >
                            <FormattedMessage {...globalLang.tryAgain} />
                        </Button>
                    </Flex>
                </PageContentContainer>
            )
        } else if (!project) {
            content = (
                <PageContentContainer>
                    <PlaceholderView buttonDataMerchant="integrations-page-no-merchant" />
                </PageContentContainer>
            )
        } else if (!hasActiveTotp) {
            const onSuccess = (newTotp: TOTP) => {
                return mutateTotps((prev = []) => [...prev, newTotp])
            }
            content = (
                <PageContentContainer>
                    <SetupTwoFa onSuccess={onSuccess} buttonDataMerchant="integrations-page-no-totp" />
                </PageContentContainer>
            )
        } else {
            content = <Integrations />
        }
    }

    return (
        <>
            <Space size={12}>
                <Title level={4} data-merchant="integrations-page-title">
                    <FormattedMessage {...lang.pageTitle} />
                </Title>
                <GetApiDocsButton />
            </Space>
            {content}
        </>
    )
}
