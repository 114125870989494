import { StatisticFormattedNumber } from '@merchant/shared/components'
import { Space, Typography } from '@merchant/ui-kit/ant-design'
import Big from 'big.js'
import { balanceEquivalentStyles } from '../styles'
import type { Balance } from '~api/instances/cabinet-api'
import type { Currencies } from '~api/types'
import { NoValueColumnContent } from '~components'
import { getEquivalentAmountWithSignAndPrecision } from '~utils'

const { Text } = Typography

interface Props {
    data: Balance
    currenciesMap: Currencies | undefined
    equivalentCurrencyCode: string | undefined
}

export function AvailableFunds({ data, equivalentCurrencyCode, currenciesMap }: Props) {
    const { fiatEquivalentAmount, amount, currency } = data
    const {
        amount: equivalentAmountWithPrecision,
        placement,
        sign,
    } = getEquivalentAmountWithSignAndPrecision(equivalentCurrencyCode, Big(fiatEquivalentAmount || 0), currenciesMap)

    const amountWithSignPlacement =
        placement === 'left' ? (
            <>
                {sign}
                {equivalentAmountWithPrecision}
            </>
        ) : (
            <>
                {equivalentAmountWithPrecision}
                {sign}
            </>
        )

    return (
        <Space direction="vertical" wrap={false} size={2}>
            <Text data-merchant="balances-available-amount" type={!amount ? 'secondary' : undefined}>
                <StatisticFormattedNumber
                    value={amount}
                    suffix={
                        <Text data-merchant={null} type="secondary">
                            {currency}
                        </Text>
                    }
                />
            </Text>
            {fiatEquivalentAmount ? (
                <Text
                    data-merchant="balances-equivalent-amount-text"
                    type="secondary"
                    style={
                        !fiatEquivalentAmount ? { ...balanceEquivalentStyles, opacity: 0.5 } : balanceEquivalentStyles
                    }
                >
                    {amountWithSignPlacement}
                </Text>
            ) : (
                NoValueColumnContent
            )}
        </Space>
    )
}
