import { getEntries } from '@merchant/shared/utils'
import { PROJECT_BLOCKED_PERMITTED_PAGES, pagePermissionMap } from './const'
import type { Blocklist } from '~api/instances/cabinet-api'
import type { useUserPermissionContext } from '~contexts'

export function getProjectBlocklistPermittedPages(
    checkPermission: ReturnType<typeof useUserPermissionContext>['checkUserPermission'],
    isProjectBlocklistLoading: boolean,
    projectBlocklist: Blocklist | undefined
) {
    if (!isProjectBlocklistLoading && projectBlocklist?.blocked) {
        return PROJECT_BLOCKED_PERMITTED_PAGES
    }

    return getEntries(pagePermissionMap)
        .filter(([_, permission]) => checkPermission(permission))
        .map(([page]) => page)
}
