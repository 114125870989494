import { ModalResponsive } from '@merchant/shared/components'
import { Button, Flex, Typography } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import type { ModalProps } from '@merchant/ui-kit/ant-design'

const { Title, Text } = Typography

const modalBodyStyles: React.CSSProperties = { textAlign: 'center' }

interface Props extends Omit<ModalProps, 'data-merchant'> {
    isOpen: boolean
    close: () => void
    name: string
    onSuccess: () => void
}

export function MerchantCreatedModal({ isOpen, close, name, onSuccess, ...rest }: Props) {
    return (
        <ModalResponsive
            title={<FormattedMessage {...lang.merchantCreatedModalHeader} />}
            open={isOpen}
            footer={null}
            destroyOnClose
            onCancel={close}
            styles={{ body: modalBodyStyles }}
            centered
            data-merchant="merchant-created-modal"
            {...rest}
        >
            <Flex vertical gap={8}>
                <Title level={1} style={{ fontSize: 35 }} data-merchant={null}>
                    <FormattedMessage {...lang.merchantCreatedModalTitle} />
                </Title>
                <Title level={4} data-merchant={null}>
                    <FormattedMessage {...lang.merchantCreatedModalSubtitle} />
                </Title>
                <Text type="secondary" data-merchant="merchant-created-modal-text">
                    <FormattedMessage
                        {...lang.merchantCreatedModalText}
                        values={{
                            merchantName: <strong>&quot;{name}&quot;</strong>,
                        }}
                    />
                </Text>
            </Flex>
            <Button
                style={{ marginTop: 24 }}
                data-merchant="merchant-created-modal-button"
                type="primary"
                block
                onClick={onSuccess}
                size="large"
            >
                <FormattedMessage {...lang.merchantCreatedModalButton} />
            </Button>
        </ModalResponsive>
    )
}
