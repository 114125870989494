import { LoadingOutlined } from '@ant-design/icons'
import { Tag, Popconfirm, Button, Flex } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { useBoolean } from 'usehooks-ts'
import { transactionStatusMessageDescriptorMap, transactionStatusTagMap } from '../../types'
import { lang } from './lang'
import { SuccessFiatWithdrawalTooltip } from './SuccessFiatWithdrawalTooltip'
import { TransactionType, TransactionStatus as TransactionStatuses, Permission } from '~api/instances/cabinet-api'
import { useUserPermissionContext } from '~contexts'

const tagStyles: React.CSSProperties = { padding: '5px 6px', margin: 0 }

interface Props {
    onCancelClick: () => Promise<void>
    status: TransactionStatuses
    isCancelable?: boolean
    style?: React.CSSProperties
    type: TransactionType
}

export function TransactionStatus({ status, onCancelClick, isCancelable, type, style: styleProps }: Props) {
    const { checkUserPermission } = useUserPermissionContext()
    const { value: isCancelLoading, setValue: setIsCancelLoading } = useBoolean(false)

    const handleCancelClick = async () => {
        setIsCancelLoading(true)
        await onCancelClick()
    }

    const shouldShowSuccessFiatWithdrawalTooltip =
        type === TransactionType.FiatWithdrawal && status === TransactionStatuses.Finished

    return (
        <Flex vertical gap={8} align="start">
            <Flex align="center" gap={4}>
                <Tag
                    style={{ ...tagStyles, ...styleProps }}
                    color={transactionStatusTagMap[status]}
                    bordered={false}
                    icon={status === TransactionStatuses.Processing ? <LoadingOutlined spin /> : undefined}
                >
                    <FormattedMessage {...transactionStatusMessageDescriptorMap[status]} />
                </Tag>
                {shouldShowSuccessFiatWithdrawalTooltip && <SuccessFiatWithdrawalTooltip />}
            </Flex>
            {isCancelable && checkUserPermission(Permission.CancelCryptoWithdrawals) && (
                <Popconfirm
                    overlayStyle={{ maxWidth: 270 }}
                    title={<FormattedMessage {...lang.areYouSureToCancel} />}
                    icon={false}
                    onConfirm={handleCancelClick}
                    okText={<FormattedMessage {...lang.confirm} />}
                    cancelText={<FormattedMessage {...lang.no} />}
                    okButtonProps={{
                        loading: isCancelLoading,
                        'data-merchant': 'cancel-transaction-popconfirm-ok-button',
                    }}
                    placement="bottom"
                    data-merchant="cancel-transaction-popconfirm"
                >
                    <Button size="small" data-merchant="cancel-transaction-close-button">
                        <FormattedMessage {...lang.cancel} />
                    </Button>
                </Popconfirm>
            )}
        </Flex>
    )
}
