import { modalMobileContentStyles, modalMobileStyles } from '@merchant/shared/components/ModalResponsive/style'
import { Flex, Typography, type ModalFuncProps } from '@merchant/ui-kit/ant-design'
import Big from 'big.js'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import type { Breakpoints } from '@merchant/shared/hooks'
import type { LossConfig, LossConfigAbsolute } from '~api/instances/cabinet-api'

const { Text } = Typography

export function getModalConfirmProps(values: LossConfig | undefined, breakpoint: Breakpoints): ModalFuncProps {
    return {
        icon: null,
        closable: true,
        maskClosable: true,
        width: 500,
        okText: <FormattedMessage {...lang.underpaymentConfirmButton} />,
        cancelText: <FormattedMessage {...lang.underpaymentCancelButton} />,
        style: !breakpoint.md ? modalMobileStyles : undefined,
        styles: { content: !breakpoint.md ? modalMobileContentStyles : undefined },
        title: (
            <Text style={{ fontSize: 20 }} data-merchant={null}>
                <FormattedMessage {...lang.largeUnderpaymentTitle} />
            </Text>
        ),
        content: (
            <Flex vertical gap={8}>
                <Text data-merchant="underpayment-description-text">
                    <FormattedMessage
                        {...lang.largeUnderpaymentDescription}
                        values={{
                            b: chunks => <span style={{ fontWeight: 700 }}>{chunks}</span>,
                            relativeAmount: Big(values?.relative || '0')
                                .mul(100)
                                .toString(),
                            absoluteValue: values?.absolute?.amount ? (
                                <FormattedMessage
                                    {...lang.absoluteValue}
                                    values={{
                                        b: chunks => <span style={{ fontWeight: 700 }}>{chunks}</span>,
                                        amount: values.absolute.amount,
                                        currency: values.absolute.currency,
                                    }}
                                />
                            ) : (
                                ''
                            ),
                        }}
                    />
                </Text>
                <Text data-merchant={null}>
                    <FormattedMessage {...lang.largeUnderpaymentQuestion} />
                </Text>
            </Flex>
        ),
        footer: (_, { OkBtn, CancelBtn }) => (
            <div style={{ display: 'flex', gap: 12, marginTop: 24 }}>
                <CancelBtn /> <OkBtn />
            </div>
        ),
        okButtonProps: {
            size: 'large',
            style: { minWidth: 'fit-content', paddingInline: 0, flex: 1, marginInline: 0 },
            'data-merchant': 'underpayment-modal-ok-button',
        },
        cancelButtonProps: {
            size: 'large',
            style: { minWidth: 'fit-content', paddingInline: 0, flex: 1 },
            'data-merchant': 'underpayment-modal-cancel-button',
        },
        okType: 'primary',
    }
}

export function isUnderpaymentLarge(values: LossConfig | undefined): boolean {
    if (!values) {
        return false
    }

    return (values.absolute?.amount && Number(values.absolute.amount) > 10) || Number(values.relative) > 0.1
}

export function normalizeAbsoluteUnderpaymentValue(value?: LossConfigAbsolute): LossConfigAbsolute | undefined {
    return value?.amount && value.currency ? value : undefined
}

export function normalizeRelativeValueToDisplay(value: string | number | undefined) {
    return !value || Number.isNaN(Number(value)) ? '' : Big(value).mul(100).toNumber()
}

export function normalizeRelativeValueToStore(value: string | number) {
    return !value || Number.isNaN(Number(value)) ? '' : Big(value).div(100).toNumber()
}
