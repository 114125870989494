/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WhitelistAddress } from './WhitelistAddress';
import {
    WhitelistAddressFromJSON,
    WhitelistAddressFromJSONTyped,
    WhitelistAddressToJSON,
} from './WhitelistAddress';

/**
 * 
 * @export
 * @interface GetWhitelistResponse
 */
export interface GetWhitelistResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetWhitelistResponse
     */
    enabled: boolean;
    /**
     * 
     * @type {Array<WhitelistAddress>}
     * @memberof GetWhitelistResponse
     */
    addresses: Array<WhitelistAddress>;
}

/**
 * Check if a given object implements the GetWhitelistResponse interface.
 */
// @ts-ignore
export function instanceOfGetWhitelistResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "enabled" in value;
    isInstance = isInstance && "addresses" in value;

    return isInstance;
}

// @ts-ignore
export function GetWhitelistResponseFromJSON(json: any): GetWhitelistResponse {
    return GetWhitelistResponseFromJSONTyped(json, false);
}

// @ts-ignore
export function GetWhitelistResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetWhitelistResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabled': json['enabled'],
        'addresses': ((json['addresses'] as Array<any>).map(WhitelistAddressFromJSON)),
    };
}

// @ts-ignore
export function GetWhitelistResponseToJSON(value?: GetWhitelistResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabled': value.enabled,
        'addresses': ((value.addresses as Array<any>).map(WhitelistAddressToJSON)),
    };
}

