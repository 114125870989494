import { getNetworksOrderedByCMS } from '@merchant/shared/utils'
import { Divider, Typography } from '@merchant/ui-kit/ant-design'
import { isEmpty } from 'lodash-es'
import { FormattedMessage } from 'react-intl'
import { NetworkOption } from './components'
import { lang } from './lang'
import type { AppSettings } from '@merchant/shared/api/instances/host-api/types'
import type { DefaultOptionType } from '@merchant/ui-kit/ant-design/es/select'
import type { Currencies } from '~api/types'
import { routes } from '~constants/routes'

const { Text, Link } = Typography

const commonTextStyles = {
    fontSize: '12px',
}

export const getTooltipTitles = ({
    context,
    canSelectNetwork,
}: {
    context: 'existing' | 'new'
    canSelectNetwork: boolean
}) => {
    const existingAddressTooltip =
        context === 'existing' ? (
            <Text data-merchant={null} type="secondary" style={commonTextStyles}>
                <FormattedMessage
                    {...lang.existingAddressTooltip}
                    values={{
                        balancesLink: chunks => (
                            <Link
                                data-merchant="existing-address-balances-link"
                                style={{ fontSize: 'inherit' }}
                                href={routes.balances}
                                target="_blank"
                            >
                                {chunks}
                            </Link>
                        ),
                    }}
                />
            </Text>
        ) : null
    const notRequiredNetworkTooltip = !canSelectNetwork ? (
        <Text data-merchant={null} type="secondary" style={commonTextStyles}>
            <FormattedMessage {...lang.notRequiredNetwork} />
        </Text>
    ) : null

    return {
        currencyTooltip: existingAddressTooltip,
        networkTooltip: existingAddressTooltip ?? notRequiredNetworkTooltip,
    }
}

export const getNetworkOptions = (
    currency: string,
    currencies?: Currencies,
    appSettings?: AppSettings['networksOrder']
) => {
    const networks = currency ? (currencies?.[currency]?.networks ?? []) : []
    const sortedNetworks = getNetworksOrderedByCMS(networks, appSettings)

    const { enabled, disabled } = sortedNetworks.reduce<{
        enabled: DefaultOptionType[]
        disabled: DefaultOptionType[]
    }>(
        (acc, network) => {
            const { protocol, name, code, payinEnabled } = network
            const option: DefaultOptionType = {
                value: code,
                selectedLabel: <NetworkOption {...network} isLabel />,
                label: <NetworkOption {...network} />,
                disabled: !payinEnabled,
                key: `${code} ${name} ${protocol}`,
            }
            if (payinEnabled) {
                acc.enabled.push(option)
            } else {
                acc.disabled.push(option)
            }

            return acc
        },
        {
            enabled: [],
            disabled: [],
        }
    )

    return {
        enabled: { options: Object.values(enabled), label: null },
        disabled: {
            options: Object.values(disabled),
            label: isEmpty(disabled) ? null : <Divider style={{ marginBlock: 4 }} />,
            disabled: true,
        },
    }
}
