import { DEFAULT_PRECISION } from '@merchant/shared/constants/currency'
import { formatNumber, getCurrencyMinPrecision } from '@merchant/shared/utils'
import { Divider } from '@merchant/ui-kit/ant-design'
import Big from 'big.js'
import { isEmpty } from 'lodash-es'
import type { Balance, Currency } from '~api/instances/cabinet-api'
import type { Currencies } from '~api/types'
import type { WithdrawalCurrency } from '~features/ActionModal/types'
import type { CurrencySelectOptionData, CurrencySelectOptions } from '~types/currency'
import { instanceOfBalance } from '~api/instances/cabinet-api'

const DEFAULT_PRIORITY = 99_999

const compareByPriority = (a: Currency | undefined, b: Currency | undefined) => {
    if (a?.crypto !== b?.crypto) {
        return a?.crypto ? 1 : -1
    }

    const priorityA = a?.equivalentPriority ?? a?.priority ?? DEFAULT_PRIORITY
    const priorityB = b?.equivalentPriority ?? b?.priority ?? DEFAULT_PRIORITY

    return priorityA - priorityB
}

export const currenciesCommonSort = <T extends { code: string }>(
    options: T[],
    currencies: Currencies | undefined,
    balances: Partial<Balance>[] | undefined = []
) => {
    if (!currencies) {
        return options
    }

    const fiatEquivalentAmounts = balances.reduce<Record<string, number>>((acc, balance) => {
        acc[balance.currency || ''] = Number(balance.fiatEquivalentAmount || '0')

        return acc
    }, {})

    return options.toSorted((a, b) => {
        const equivalentAmountA = fiatEquivalentAmounts[a.code] ?? 0
        const equivalentAmountB = fiatEquivalentAmounts[b.code] ?? 0
        if (equivalentAmountA !== equivalentAmountB) {
            return equivalentAmountB - equivalentAmountA
        }

        return compareByPriority(currencies[a.code], currencies[b.code])
    })
}

export function getIfCurrencyWithdrawable(currency?: Currency) {
    if (!currency) {
        return false
    }

    if (currency.payoutEnabled) {
        if (isEmpty(currency.networks)) {
            return true
        } else {
            return currency.networks.some(network => network.payoutEnabled === true)
        }
    } else {
        return false
    }
}

function getIsBalances(balances: Balance[] | Currencies): balances is Balance[] {
    if (Array.isArray(balances) && !isEmpty(balances)) {
        const balanceObject = balances[0]

        return !!balanceObject && instanceOfBalance(balanceObject)
    }

    return false
}

const getDefaultCurrency = (code: Balance['currency']): Currency => ({
    enabled: true,
    code,
    crypto: true,
    name: code,
    payoutEnabled: false,
    precision: DEFAULT_PRECISION,
    sign: '',
    networks: [],
    payinEnabled: false,
    rateableTo: [],
    swappableTo: [],
})

export function getWithdrawalCurrencies({
    balances = [],
    currencies,
    isWithdrawableOnly = true,
}: {
    balances?: Balance[]
    currencies?: Currencies
    isWithdrawableOnly?: boolean
}) {
    if (!currencies) {
        return {}
    }

    let optionsArray: (Balance & Currency)[]

    if (getIsBalances(balances)) {
        optionsArray = balances.map(balance => {
            const currency: Currency = currencies[balance.currency] || getDefaultCurrency(balance.currency)

            return {
                ...balance,
                ...currency,
            }
        })
    } else {
        optionsArray = Object.values(currencies).map(currency => {
            return {
                currency: currency.code || '',
                amount: '0',
                fiatEquivalentAmount: '0',
                fiatEquivalentCurrency: undefined,
                ...currency,
            }
        })
    }
    const withdrawalCurrencies = isWithdrawableOnly ? optionsArray.filter(getIfCurrencyWithdrawable) : optionsArray

    return currenciesCommonSort(withdrawalCurrencies, currencies, balances).reduce<Record<string, WithdrawalCurrency>>(
        (acc, { currency: _, fiatEquivalentAmount: __, fiatEquivalentCurrency: ___, ...keep }) => {
            return {
                ...acc,
                [keep.code]: keep,
            }
        },
        {}
    )
}

export function getPrecisionCutValue({
    value,
    precision,
    roundingMode = Big.roundDown,
}: {
    value?: string | number | Big | null
    precision: number
    roundingMode?: Big.RoundingMode
}) {
    if (!value) {
        return ''
    }
    const valueBig = value instanceof Big ? value : Big(value)

    return valueBig.round(precision, roundingMode).toString()
}

type GetEquivalentAmountWithSignAndPrecision = (
    currencyCode: string | undefined,
    equivalentAmount: Big,
    currenciesMap: Currencies | undefined
) => {
    amount: string
    sign: string | undefined
    placement: 'left' | 'right'
}

export const getEquivalentAmountWithSignAndPrecision: GetEquivalentAmountWithSignAndPrecision = (
    currencyCode,
    equivalentAmount,
    currenciesMap
) => {
    const equivalentCurrency = currencyCode ? currenciesMap?.[currencyCode] : undefined
    const equivalentPrecision = equivalentCurrency?.crypto
        ? getCurrencyMinPrecision(equivalentCurrency.code, currenciesMap)
        : 2

    const equivalentAmountValue = formatNumber({
        value: equivalentAmount,
        precision: equivalentPrecision,
        numberFormatOptions: { minimumFractionDigits: 2 },
    })

    return {
        amount: equivalentAmountValue?.toString() || '0',
        sign: equivalentCurrency?.sign,
        placement: equivalentCurrency?.crypto ? 'right' : 'left',
    }
}

export function getAlphabeticallySortedCryptoCurrencies({ currencies }: { currencies: Currencies | undefined }) {
    const options = Object.values(currencies || {})
        .filter(currency => currency.crypto)
        .toSorted((a, b) => a.code.localeCompare(b.code))

    return options
}

export const getIfHasWithdrawableFiatCurrencies = (currencies: Currencies | undefined) =>
    Object.values(currencies || {}).some(currency => !currency.crypto && !!currency.payoutEnabled)

export const isFakeFiat = <T extends Pick<Currency, 'swappableTo' | 'crypto'>>(currency: T) =>
    currency.crypto === false && isEmpty(currency.swappableTo)

export const getWithdrawalCurrencySelectOption = (withdrawalCurrencies: Record<string, WithdrawalCurrency>) => {
    return Object.values(withdrawalCurrencies).reduce<CurrencySelectOptions>(
        (acc, currency) => {
            const key = `${currency.code} ${currency.name}`.toLowerCase()
            const isWithdrawable = getIfCurrencyWithdrawable(currency)
            const payoutCurrencyCategory: keyof CurrencySelectOptions = isWithdrawable
                ? 'payoutEnabledCurrencies'
                : 'payoutDisabledCurrencies'

            return {
                ...acc,
                [payoutCurrencyCategory]: {
                    ...acc[payoutCurrencyCategory],
                    [currency.code]: { ...currency, disabled: !isWithdrawable, key },
                },
            }
        },
        { payoutEnabledCurrencies: {}, payoutDisabledCurrencies: {} }
    )
}

export function getWithdrawalOptionGroups(
    { payoutEnabledCurrencies, payoutDisabledCurrencies }: CurrencySelectOptions = {
        payoutEnabledCurrencies: {},
        payoutDisabledCurrencies: {},
    }
): {
    options: CurrencySelectOptionData[]
    label: JSX.Element | null
    disabled?: boolean
}[] {
    return [
        { options: Object.values(payoutEnabledCurrencies), label: <span /> },
        {
            options: Object.values(payoutDisabledCurrencies),
            label: isEmpty(payoutDisabledCurrencies) ? null : <Divider style={{ marginBlock: 4 }} />,
            disabled: true,
        },
    ]
}

export const isSwappablePredicate = <T extends Pick<Currency, 'swappableTo'>>(currency?: T) =>
    !isEmpty(currency?.swappableTo)

export const isRateablePredicate = <T extends Pick<Currency, 'rateableTo' | 'code'>>(currency: T | undefined) =>
    !isEmpty(currency?.rateableTo)

export const isHoldingCurrencyPredicate =
    <K extends { code: string }>(holdingCurrencies: K[] | undefined) =>
    <T extends Pick<Currency, 'rateableTo' | 'code'>>(currency: T | undefined) =>
        (currency && holdingCurrencies?.some(holdingCurrency => holdingCurrency.code === currency.code)) ?? false

export const isCurrencyDepositAvailable = <T extends Pick<Currency, 'payinEnabled' | 'networks'>>(
    currency: T | undefined
) => {
    if (!currency || !currency.payinEnabled) {
        return false
    }
    if (isEmpty(currency.networks)) {
        return true
    }

    return currency.networks.some(network => network.payinEnabled)
}
