import { ModalResponsive } from '@merchant/shared/components'
import { useIntl } from 'react-intl'
import { modeDataMerchantMap, type modes } from '../../const'
import { modalTitleModeMap } from './const'
import type { ModalProps } from '@merchant/ui-kit/ant-design'
import { useDirtyFormCheckerControls } from '~hooks/useDirtyFormCheckerControls'

interface Props extends Omit<ModalProps, 'title' | 'data-merchant'> {
    mode: keyof typeof modes | null
}

export function CallbackModal({ mode, children, onCancel, ...props }: Props) {
    const intl = useIntl()
    const descriptor = mode ? modalTitleModeMap[mode] : null
    const dataMerchant = mode ? `${modeDataMerchantMap[mode]}-modal` : ''
    const title = descriptor ? intl.formatMessage(descriptor) : null
    const { handleCancel } = useDirtyFormCheckerControls(onCancel)

    return (
        <ModalResponsive
            data-merchant={dataMerchant}
            destroyOnClose
            footer={null}
            title={<span data-merchant={`${dataMerchant}-title`}>{title}</span>}
            onCancel={handleCancel}
            {...props}
        >
            {children}
        </ModalResponsive>
    )
}
