/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentState } from './PaymentState';
import {
    PaymentStateFromJSON,
    PaymentStateFromJSONTyped,
    PaymentStateToJSON,
} from './PaymentState';
import type { PaymentSubtype } from './PaymentSubtype';
import {
    PaymentSubtypeFromJSON,
    PaymentSubtypeFromJSONTyped,
    PaymentSubtypeToJSON,
} from './PaymentSubtype';
import type { PaymentType } from './PaymentType';
import {
    PaymentTypeFromJSON,
    PaymentTypeFromJSONTyped,
    PaymentTypeToJSON,
} from './PaymentType';

/**
 * 
 * @export
 * @interface PaymentThumb
 */
export interface PaymentThumb {
    /**
     * 
     * @type {string}
     * @memberof PaymentThumb
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentThumb
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentThumb
     */
    origin: string;
    /**
     * 
     * @type {PaymentType}
     * @memberof PaymentThumb
     */
    type: PaymentType;
    /**
     * 
     * @type {PaymentSubtype}
     * @memberof PaymentThumb
     */
    subtype?: PaymentSubtype;
    /**
     * 
     * @type {PaymentState}
     * @memberof PaymentThumb
     */
    state: PaymentState;
    /**
     * 
     * @type {string}
     * @memberof PaymentThumb
     */
    nominalCurrency: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentThumb
     */
    nominalAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentThumb
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentThumb
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentThumb
     */
    orderId?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentThumb
     */
    customerEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentThumb
     */
    customerId?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentThumb
     */
    paidAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentThumb
     */
    paidCurrency?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentThumb
     */
    paidNetwork?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentThumb
     */
    holdingAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentThumb
     */
    holdingCurrency?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentThumb
     */
    paymentFee?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentThumb
     */
    receivedAmount?: string;
    /**
     * 
     * @type {Date}
     * @memberof PaymentThumb
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof PaymentThumb
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the PaymentThumb interface.
 */
// @ts-ignore
export function instanceOfPaymentThumb(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "origin" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "nominalCurrency" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;

    return isInstance;
}

// @ts-ignore
export function PaymentThumbFromJSON(json: any): PaymentThumb {
    return PaymentThumbFromJSONTyped(json, false);
}

// @ts-ignore
export function PaymentThumbFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentThumb {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'projectId': json['project_id'],
        'origin': json['origin'],
        'type': PaymentTypeFromJSON(json['type']),
        'subtype': !exists(json, 'subtype') ? undefined : PaymentSubtypeFromJSON(json['subtype']),
        'state': PaymentStateFromJSON(json['state']),
        'nominalCurrency': json['nominal_currency'],
        'nominalAmount': !exists(json, 'nominal_amount') ? undefined : json['nominal_amount'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'orderId': !exists(json, 'order_id') ? undefined : json['order_id'],
        'customerEmail': !exists(json, 'customer_email') ? undefined : json['customer_email'],
        'customerId': !exists(json, 'customer_id') ? undefined : json['customer_id'],
        'paidAmount': !exists(json, 'paid_amount') ? undefined : json['paid_amount'],
        'paidCurrency': !exists(json, 'paid_currency') ? undefined : json['paid_currency'],
        'paidNetwork': !exists(json, 'paid_network') ? undefined : json['paid_network'],
        'holdingAmount': !exists(json, 'holding_amount') ? undefined : json['holding_amount'],
        'holdingCurrency': !exists(json, 'holding_currency') ? undefined : json['holding_currency'],
        'paymentFee': !exists(json, 'payment_fee') ? undefined : json['payment_fee'],
        'receivedAmount': !exists(json, 'received_amount') ? undefined : json['received_amount'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
    };
}

// @ts-ignore
export function PaymentThumbToJSON(value?: PaymentThumb | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'project_id': value.projectId,
        'origin': value.origin,
        'type': PaymentTypeToJSON(value.type),
        'subtype': PaymentSubtypeToJSON(value.subtype),
        'state': PaymentStateToJSON(value.state),
        'nominal_currency': value.nominalCurrency,
        'nominal_amount': value.nominalAmount,
        'title': value.title,
        'description': value.description,
        'order_id': value.orderId,
        'customer_email': value.customerEmail,
        'customer_id': value.customerId,
        'paid_amount': value.paidAmount,
        'paid_currency': value.paidCurrency,
        'paid_network': value.paidNetwork,
        'holding_amount': value.holdingAmount,
        'holding_currency': value.holdingCurrency,
        'payment_fee': value.paymentFee,
        'received_amount': value.receivedAmount,
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
    };
}

