import { useEffect, useLayoutEffect } from 'react'
import type { ThemeConfig } from '@merchant/ui-kit/ant-design'
import { useColorMode } from '~hooks/useColorMode'
import { colorPalette } from '~themes/palette/currentBroker'
import { addAlpha } from '~utils'

const useIsomorphicLayoutEffect = typeof window.document.createElement !== 'undefined' ? useLayoutEffect : useEffect

const getCurrentPalette = (isNight: boolean) => colorPalette[isNight ? 'night' : 'day']

export function useThemeVariables(token: ThemeConfig['token']) {
    const { isDark } = useColorMode()
    const currentPalette = getCurrentPalette(isDark)
    const tokenVariables = {
        '--color-border-secondary': token?.colorBorderSecondary,
        '--color-primary': token?.colorPrimary,
        '--color-secondary': token?.colorTextPlaceholder,
        '--color-text': token?.colorText,
        '--color-text-soft': addAlpha(token?.colorText || '', 0.7),
        '--color-bg-layout': token?.colorBgLayout,
        '--control-item-bg-hover': token?.controlItemBgHover,
        '--scrollbar-thumb': addAlpha(token?.colorTextPlaceholder || '', 0.5),
        '--scrollbar-track': addAlpha(token?.colorBorderSecondary || '', 0.5),
        '--color-elements-border': token?.colorBorder,
        '--color-row-hover': currentPalette.rowHover,
        '--color-bg-primary': token?.colorBgBase,
        '--color-element-control': currentPalette.elementsControl,
        '--color-danger': currentPalette.elementsDanger,
        '--elements-primary-10-fill': currentPalette.elementsPrimaryOpacity10,
        '--elements-neutral': currentPalette.elementsNeutral,
        '--elements-light': currentPalette.elementsLight,
        '--content-secondary': currentPalette.contentSecondary,
    }
    const tokenVariablesArray = Object.entries(tokenVariables).map(([key, value]) => `${key}: ${value}`)
    const joinedTokenVariables = tokenVariablesArray.join(';')

    useIsomorphicLayoutEffect(() => {
        const styleNode = document.head.querySelector('#tokenVariables') || document.createElement('style')
        styleNode.id = 'tokenVariables'
        styleNode.innerHTML = `.tokenVariables { ${joinedTokenVariables} }`
        document.getElementsByTagName('head')[0]?.appendChild(styleNode)

        document.body.className = 'tokenVariables'
    }, [isDark])
}
