import type { DefaultOptionType } from '@merchant/ui-kit/ant-design/es/select'
import { PaymentType } from '~api/instances/cabinet-api'

export type OrderTypeSelectOption = DefaultOptionType & { value: ConstructorTypes }

export const constructorTypes = {
    ...PaymentType,
    StaticAddress: 'STATIC-ADDRESS',
} as const

export type ConstructorTypes = (typeof constructorTypes)[keyof typeof constructorTypes]
