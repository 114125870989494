import { ApiKeyMode } from '../../const'
import type { EditApiKeyFormData } from './types'
import { ProjectAPIKeyPermissionID, type APIKey } from '~api'

const emptyPermissions = Object.fromEntries(
    Object.values(ProjectAPIKeyPermissionID).map(permission => [permission, null])
)

export const getInitialEditData = (selectedApiKey: APIKey): EditApiKeyFormData => ({
    ...selectedApiKey,
    permissions: {
        ...emptyPermissions,
        [ProjectAPIKeyPermissionID.Full]: null,

        ...selectedApiKey.permissions.reduce(
            (acc, permission) => ({
                ...acc,
                [permission.id]: permission.write ? ApiKeyMode.Write : ApiKeyMode.Read,
            }),
            {}
        ),
    },
})
