import { usePromise } from '@merchant/shared/hooks'
import { Button, Form, Typography } from '@merchant/ui-kit/ant-design'
import { usePostHog } from 'posthog-js/react'
import { FormattedMessage } from 'react-intl'
import { lang } from '../../lang'
import type { SignupConfirmRequest } from '~api/instances/cabinet-api'
import type { SignUpConfirmFormData } from '~features/AuthorizationPage'
import { authApi } from '~api'
import { authCaptureEventParams, PosthogAuthEvents } from '~features/AuthorizationPage/posthog'
import { EmailConfirmationItem } from '~features/FormItems'
import { EmailConfirmationFields } from '~features/FormItems/EmailConfirmationItem/const'
import { useCodeConfirmationErrorHandling } from '~features/FormItems/useCodeErrorHandling'
import { globalLang } from '~globalLang'

const { Title } = Typography

const formDataKeys = {
    verificationCode: EmailConfirmationFields.verificationCode,
}

interface Props extends Pick<SignupConfirmRequest, 'email' | 'token'> {
    onSuccess: () => void
}

const formInitData: SignUpConfirmFormData = { verificationCode: '' }

export function SignUpEmailVerification({ email, token, onSuccess }: Props) {
    const posthog = usePostHog()
    const [form] = Form.useForm()
    const { handleError } = useCodeConfirmationErrorHandling({
        confirmationStrategy: { email: 'code' },
    })

    const handleResendClick = async () => {
        await authApi.sendVerificationCode({
            sendVerificationCodeRequest: { email },
        })
    }

    const { send, isLoading } = usePromise(
        async ({ verificationCode }: SignUpConfirmFormData) => {
            await authApi.signupConfirm({
                signupConfirmRequest: { email, token, verificationCode },
            })
            posthog.capture(
                ...authCaptureEventParams[PosthogAuthEvents.createAccountConfirmation]({
                    success: true,
                    code: verificationCode,
                })
            )
        },
        {
            onSuccess,
            onError: (error, { verificationCode }) => {
                posthog.capture(
                    ...authCaptureEventParams[PosthogAuthEvents.createAccountConfirmation]({
                        success: false,
                        code: verificationCode,
                    })
                )
                handleError({ error, form })
            },
            showErrorNotification: false,
        }
    )

    const handleFormFinish = ({ verificationCode }: SignUpConfirmFormData) => {
        send({ verificationCode })
    }

    return (
        <>
            <Title level={3} data-merchant="confirmation-form-title">
                <FormattedMessage {...lang.confirmation} />
            </Title>
            <Form
                requiredMark={false}
                layout="vertical"
                form={form}
                size="large"
                onFinish={handleFormFinish}
                initialValues={formInitData}
            >
                <EmailConfirmationItem
                    dataMerchant="signup-email-confirmation-input"
                    name={formDataKeys.verificationCode}
                    email={email}
                    onResendClick={handleResendClick}
                    isInitiallySent
                    noExtra
                    shouldFocusOnMount
                />
                <Form.Item noStyle>
                    <Button
                        data-merchant="signup-email-confirmation-submit"
                        htmlType="submit"
                        block
                        type="primary"
                        loading={isLoading}
                    >
                        <FormattedMessage {...globalLang.confirm} />
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}
