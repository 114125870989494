import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { useBreakpoint } from '@merchant/shared/hooks'
import { isKeyOfObject } from '@merchant/shared/utils'
import { Collapse, Flex, Tooltip, Divider, Segmented, Form, Typography } from '@merchant/ui-kit/ant-design'
import { isEmpty } from 'lodash-es'
import { FormattedMessage } from 'react-intl'
import { ApiKeyMode } from '../../const'
import { getPermissionTitle } from '../../utils'
import { formItemsKey } from '../CreateApiKeyModal/const'
import { apiKeyPermissionsDescriptionLang } from './apiKeyPermissionsDescriptionLang'
import { lang } from './lang'
import styles from './style.module.css'
import type { SegmentedOptions } from '@merchant/ui-kit/ant-design/es/segmented'
import type { ProjectAPIKeyPermissionDetails } from '~api'

const { Text } = Typography

interface Props {
    permission: ProjectAPIKeyPermissionDetails
}

// eslint-disable-next-line max-lines-per-function
export function PermissionCollapse({ permission }: Props) {
    const breakpoints = useBreakpoint()
    const form = Form.useFormInstance()
    const { id, endpoints } = permission
    const permissionMode = Form.useWatch([formItemsKey.permissions, id], form)

    const hasEndpoints = !isEmpty(endpoints)

    const permissions: SegmentedOptions<ApiKeyMode.Write | null | ApiKeyMode.Read> = [
        {
            label: (
                <span data-merchant={`${id}-api-key-permission-none`}>
                    <FormattedMessage {...lang.none} />
                </span>
            ),
            value: null,
        },
        {
            label: (
                <span data-merchant={`${id}-api-key-permission-read`}>
                    <FormattedMessage {...lang.read} />
                </span>
            ),
            value: ApiKeyMode.Read,
        },
    ]
    if (endpoints.some(({ write }) => write)) {
        permissions.push({
            label: (
                <span data-merchant={`${id}-api-key-permission-write`}>
                    <FormattedMessage {...lang.write} />
                </span>
            ),
            value: ApiKeyMode.Write,
        })
    }

    // assuming that character takes approx 1.3ch, based on that find the longest method name
    const longestMethodNameLength = `${Math.max(...endpoints.map(({ method }) => method.length)) * 1.3}ch`

    return (
        <Collapse
            data-merchant={`${id}-api-key-permissions-collapse`}
            key={id}
            bordered={false}
            ghost
            size="small"
            style={{ padding: 0 }}
            className={styles.permissionsCollapse}
            items={[
                {
                    forceRender: true,
                    headerClass: styles.permissionsCollapseHeader,
                    label: (
                        <>
                            <Flex align="center" gap={4} data-merchant={`${id}-api-key-permissions-collapse-header`}>
                                <Text
                                    ellipsis
                                    data-merchant={`${id}-api-key-permissions-collapse-header-title`}
                                    type={!permissionMode ? 'secondary' : undefined}
                                    style={{ fontSize: breakpoints.xs ? 12 : undefined }}
                                >
                                    {getPermissionTitle(id)}
                                </Text>
                                {isKeyOfObject(id, apiKeyPermissionsDescriptionLang) && (
                                    <Tooltip
                                        data-merchant={`${id}-api-key-permissions-description-tooltip`}
                                        placement="bottom"
                                        title={
                                            <Text
                                                data-merchant={`${id}-api-key-permissions-description`}
                                                type="secondary"
                                                style={{ fontSize: 12 }}
                                            >
                                                <FormattedMessage {...apiKeyPermissionsDescriptionLang[id]} />
                                            </Text>
                                        }
                                    >
                                        <Flex>
                                            <AppSvg
                                                size={20}
                                                style={{ opacity: 0.5, verticalAlign: 'middle' }}
                                                name={assets.questionMark}
                                            />
                                        </Flex>
                                    </Tooltip>
                                )}
                            </Flex>
                            {hasEndpoints && (
                                <Text
                                    data-merchant={`${id}-api-key-permissions-endpoints-count-${endpoints.length}`}
                                    type="secondary"
                                    style={{ fontSize: 12, opacity: 0.8 }}
                                >
                                    <FormattedMessage {...lang.endpointsCount} values={{ count: endpoints.length }} />
                                </Text>
                            )}
                        </>
                    ),
                    style: { padding: 0, overflow: 'hidden' },
                    destroyInactivePanel: false,
                    children: hasEndpoints && (
                        <Flex vertical gap={2} style={{ paddingLeft: breakpoints.xs ? 8 : 0 }}>
                            <Divider style={{ marginBlock: 12 }} />
                            {endpoints.map(({ path, method }) => (
                                <Flex
                                    style={{ opacity: 0.6, fontSize: breakpoints.xs ? 12 : undefined }}
                                    key={`${id}${method}${path}`}
                                >
                                    <span style={{ width: longestMethodNameLength }}>{method}</span>&nbsp;
                                    <Text
                                        style={{
                                            direction: 'rtl',
                                            marginBlock: 0,
                                            width: '100%',
                                            textAlign: 'left',
                                            fontSize: 'inherit',
                                        }}
                                        data-merchant={`${id}-api-key-permissions-${method}-${path}`}
                                        ellipsis={{
                                            tooltip: {
                                                'data-merchant': `${id}-api-key-permissions-${method}-${path}-tooltip`,
                                                placement: 'bottom',
                                                title: (
                                                    <div>
                                                        {method}&nbsp;{path}
                                                    </div>
                                                ),
                                            },
                                        }}
                                    >
                                        &lrm;{path}
                                    </Text>
                                </Flex>
                            ))}
                        </Flex>
                    ),
                    extra: (
                        <Form.Item preserve={false} name={[formItemsKey.permissions, id]}>
                            <Segmented
                                size={breakpoints.xs ? 'small' : 'middle'}
                                onClick={e => e.stopPropagation()}
                                options={permissions}
                                data-merchant={`${id}-api-key-permissions-segmented`}
                            />
                        </Form.Item>
                    ),
                },
            ]}
        />
    )
}
