import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    label: {
        id: 'deposit.form.label',
        defaultMessage: 'Currency & Network',
    },
    currencyPlaceholder: {
        id: 'deposit.form.currency.placeholder',
        defaultMessage: 'Select currency',
    },
    networkPlaceholder: {
        id: 'deposit.form.network.placeholder',
        defaultMessage: 'Select network',
    },
    unavailableDeposit: {
        id: 'deposit.form.unavailable',
        defaultMessage: 'Deposits are temporarily unavailable',
    },
    networkAlert: {
        id: 'deposit.form.network.alert',
        defaultMessage: 'Please verify that you have chosen the correct network to avoid any potential loss of funds',
    },
    network: {
        id: 'deposit.form.network_label',
        defaultMessage: 'Network:',
    },
    notRequiredNetwork: {
        id: 'deposit.form.network_not_required',
        defaultMessage: "You don't need to select a network for the selected currency",
    },
    existingAddressTooltip: {
        id: 'deposit.form.existing_address_tooltip',
        defaultMessage:
            'You can’t change the currency or network for an already generated address. To deposit another currency or network, use the Deposit button in the <balancesLink>Balances</balancesLink> section.',
    },
})
