import { lang } from './lang'
import { steps } from './types'
import type { WithdrawalCryptoFormData } from './types'
import type { FormItemsKey } from '@merchant/shared/types'
import type { MessageDescriptor } from 'react-intl'

export type StepsValue = (typeof steps)[keyof typeof steps]

export const viewTitles: Record<StepsValue, MessageDescriptor | null> = {
    [steps.withdrawalCryptoInput]: lang.actionModalWithdrawalCryptoTitle,
    [steps.withdrawalCryptoSummary]: lang.actionModalWithdrawalConfirmationTitle,
    [steps.withdrawalCryptoConfirmation]: lang.actionModalWithdrawalConfirmationTitle,
    [steps.withdrawalCryptoProgress]: null,
} as const

export const initWithdrawalCryptoData: WithdrawalCryptoFormData = {
    address: undefined,
    currency: undefined,
    network: undefined,
    fee: '0',
    withdrawalAmount: '0',
    receiveAmount: '0',
    addressTag: undefined,
    amountMode: 'credit',
    amount: {
        credit: '',
    },
}

export const withdrawalCryptoFormKeys: FormItemsKey<WithdrawalCryptoFormData> = {
    address: 'address',
    amount: 'amount',
    currency: 'currency',
    fee: 'fee',
    network: 'network',
    receiveAmount: 'receiveAmount',
    withdrawalAmount: 'withdrawalAmount',
    addressTag: 'addressTag',
    whitelistKey: 'whitelistKey',
    note: 'note',
    amountMode: 'amountMode',
}

export const placeholderViewSpaceProps = {
    style: {
        padding: 0,
        width: '100%',
        justifyContent: 'center',
    },
    styles: {
        item: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        },
    },
}
