import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    pageTitle: {
        id: 'settings-page.typography.title',
        defaultMessage: 'Settings',
    },
    generalPageTitle: {
        id: 'settings-page.typography.general_page_title',
        defaultMessage: 'General',
    },
    securityPageTitle: {
        id: 'settings-page.typography.security_page_title',
        defaultMessage: 'Security',
    },
})
