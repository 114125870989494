import { lang } from './lang'
import type { GetProp, Badge } from '@merchant/ui-kit/ant-design'
import type { MessageDescriptor } from 'react-intl'
import { PaymentState } from '~api/instances/cabinet-api'

export const orderStatusMessageDescriptor: Record<PaymentState, MessageDescriptor> = {
    [PaymentState.Completed]: lang.completedStatus,
    [PaymentState.Failed]: lang.failedStatus,
    [PaymentState.Canceled]: lang.cancelledStatus,
    [PaymentState.Waiting]: lang.waitingStatus,
    [PaymentState.Created]: lang.createdStatus,
}

export const orderStatusTagMap: Record<PaymentState, GetProp<typeof Badge, 'status'>> = {
    [PaymentState.Completed]: 'success',
    [PaymentState.Failed]: 'error',
    [PaymentState.Canceled]: 'default',
    [PaymentState.Waiting]: 'warning',
    [PaymentState.Created]: 'warning',
}
