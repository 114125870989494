import { defineMessages, type MessageDescriptor } from 'react-intl'
import { Permission } from '~api/instances/cabinet-api'

export const permissionsLang: Record<Permission, MessageDescriptor> = defineMessages({
    [Permission.SeePaymentHistory]: {
        id: 'permissions.see_payment_history',
        defaultMessage: 'See payment history',
    },
    [Permission.SeeBalance]: {
        id: 'permissions.see_balance',
        defaultMessage: 'See balance',
    },
    [Permission.SeeTransactions]: {
        id: 'permissions.see_transactions',
        defaultMessage: 'See transactions',
    },
    [Permission.SeeDepositAddresses]: {
        id: 'permissions.see_deposit_addresses',
        defaultMessage: 'See wallet & static addresses',
    },
    [Permission.CreatePayment]: {
        id: 'permissions.create_payment',
        defaultMessage: 'Create new order',
    },
    [Permission.SeeSwapFlow]: {
        id: 'permissions.see_swap_flow',
        defaultMessage: 'See convert flow',
    },
    [Permission.CommitSwaps]: {
        id: 'permissions.commit_swaps',
        defaultMessage: 'Commit convert',
    },
    [Permission.SeeCryptoWithdrawalFlow]: {
        id: 'permissions.see_crypto_withdrawal_flow',
        defaultMessage: 'See crypto withdrawal flow',
    },
    [Permission.SeeFiatWithdrawalFlow]: {
        id: 'permissions.see_fiat_withdrawal_flow',
        defaultMessage: 'See fiat withdrawal flow',
    },
    [Permission.CommitFiatWithdrawals]: {
        id: 'permissions.commit_fiat_withdrawal',
        defaultMessage: 'Commit fiat withdrawal',
    },
    [Permission.CommitCryptoWithdrawals]: {
        id: 'permissions.commit_crypto_withdrawal',
        defaultMessage: 'Commit crypto withdrawal',
    },
    [Permission.CancelCryptoWithdrawals]: {
        id: 'permissions.cancel_crypto_withdrawals',
        defaultMessage: 'Cancel crypto withdrawals',
    },
    [Permission.SeeWithdrawalWhitelist]: {
        id: 'permissions.see_withdrawal_whitelist',
        defaultMessage: 'See withdrawal whitelist',
    },
    [Permission.EditWithdrawalWhitelist]: {
        id: 'permissions.edit_withdrawal_whitelists',
        defaultMessage: 'Edit withdrawal whitelists',
    },
    [Permission.ChangeGeneralInfo]: {
        id: 'permissions.change_general_info',
        defaultMessage: 'Edit general info',
    },
    [Permission.ChangeCurrencySettings]: {
        id: 'permissions.change_currency_settings',
        defaultMessage: 'Edit currency settings',
    },
    [Permission.SeePaymentSettings]: {
        id: 'permissions.see_payment_settings',
        defaultMessage: 'See payment settings',
    },
    [Permission.ChangePaymentSettingsFeesPayer]: {
        id: 'permissions.change_payment_settings_fee_is_paid',
        defaultMessage: 'Edit: Fee is paid',
    },
    [Permission.ChangePaymentSettingsRedirectLinks]: {
        id: 'permissions.change_payment_settings_redirect_links',
        defaultMessage: 'Edit: Redirect links',
    },
    [Permission.ChangePaymentSettingsLossConfig]: {
        id: 'permissions.change_payment_settings_underpayment_threshold',
        defaultMessage: 'Edit: Underpayment threshold',
    },
    [Permission.SeeProjectTeamMembers]: {
        id: 'permissions.team_management_see_members',
        defaultMessage: 'See members',
    },
    [Permission.ChangeProjectTeamMembers]: {
        id: 'permissions.team_management_edit_members',
        defaultMessage: 'Edit members',
    },
    [Permission.SeeProjectRoles]: {
        id: 'permissions.team_management_see_permissions',
        defaultMessage: 'See members permissions',
    },
    [Permission.ChangeProjectRoles]: {
        id: 'permissions.team_management_edit_permissions',
        defaultMessage: 'Edit members permissions',
    },
    [Permission.SeeIntegration]: {
        id: 'permissions.see_integrations',
        defaultMessage: 'See integrations',
    },
    [Permission.ChangeIntegration]: {
        id: 'permissions.change_integrations',
        defaultMessage: 'Edit integrations',
    },
    [Permission.SendPaymentCallback]: {
        id: 'permissions.send_payment_callback',
        defaultMessage: 'Send order callback',
    },
    [Permission.CreateDepositAddress]: {
        id: 'permissions.create_deposit_address',
        defaultMessage: 'Create new wallet & static addresses',
    },
    [Permission.SendWithdrawalCallback]: {
        id: 'permissions.send_withdrawal_callback',
        defaultMessage: 'Send withdrawal callback',
    },
    [Permission.SeeWithdrawals]: {
        id: 'permissions.see_withdrawals',
        defaultMessage: 'See withdrawals',
    },
})
