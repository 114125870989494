import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg, CopyButton } from '@merchant/shared/components'
import { getAddressTagName } from '@merchant/shared/utils'
import { Alert, Flex, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage, useIntl } from 'react-intl'
import { TableType } from '../../../const'
import { lang as commonLang } from './lang'
import { useCurrencies, type Currency, type Network } from '~api'
import { ConversionNote } from '~components/ConversionNote'
import { globalLang } from '~globalLang'

const { Text } = Typography

interface Props {
    context: TableType
    address: string
    addressTag?: string
    currency: Currency | undefined
    network: Network | undefined
    getConvertToCurrency: (currencyCode: string | undefined) => string | undefined
    dataMerchant: string
}

export function PayingEnabledContent({
    context,
    currency,
    network,
    address,
    addressTag,
    getConvertToCurrency,
    dataMerchant,
}: Props) {
    const intl = useIntl()
    const { data: currencies } = useCurrencies()
    const convertedTo = getConvertToCurrency(currency?.code)
    const addressTagName =
        getAddressTagName(currencies, currency?.code, network?.code) || intl.formatMessage(globalLang.addressTag)
    const isConversionNoteShown = currency?.code && context !== TableType.DepositAddresses && convertedTo

    return (
        <Flex vertical gap={8}>
            {isConversionNoteShown && (
                <ConversionNote
                    currencyFrom={currency.code}
                    currencyTo={convertedTo}
                    dataMerchant="addresses-table-popover"
                />
            )}
            <Flex justify="space-between" gap={4}>
                <Text type="secondary" style={{ fontSize: 12, textWrap: 'nowrap' }} data-merchant={null}>
                    <FormattedMessage {...commonLang.address} />
                </Text>
                <Flex gap={4} align="start">
                    <Text style={{ fontSize: 12, textAlign: 'right' }} data-merchant={`${dataMerchant}-address`}>
                        {address}
                    </Text>
                    <CopyButton
                        inputValue={address}
                        data-merchant={`${dataMerchant}-address-copy-button`}
                        notificationContentSubject={globalLang.address}
                    />
                </Flex>
            </Flex>
            {addressTag && (
                <Flex justify="space-between" gap={4}>
                    <Text
                        type="secondary"
                        style={{ fontSize: 12, textWrap: 'nowrap' }}
                        data-merchant={`${dataMerchant}-addressTagName`}
                    >
                        {addressTagName}
                    </Text>
                    <Flex gap={4} align="start">
                        <Text style={{ fontSize: 12, textAlign: 'right' }} data-merchant={`${dataMerchant}-addressTag`}>
                            {addressTag}
                        </Text>
                        <CopyButton
                            inputValue={addressTag}
                            data-merchant={`${dataMerchant}-addressTag-copy-button`}
                            notificationContentSubject={addressTagName}
                        />
                    </Flex>
                </Flex>
            )}
            {addressTag && (
                <Alert
                    data-merchant={`${dataMerchant}-addressTag-alert`}
                    type="warning"
                    message={
                        <Flex align="center" gap={8}>
                            <AppSvg name={assets.alertCircle} size={20} color={token => token.colorWarning} />
                            <Text
                                style={{ fontSize: 12 }}
                                type="secondary"
                                data-merchant={`${dataMerchant}-addressTag-alert-text`}
                            >
                                <FormattedMessage
                                    {...commonLang.addressTagNote}
                                    values={{
                                        addressTagName,
                                        b: chunks => <b>{chunks}</b>,
                                    }}
                                />
                            </Text>
                        </Flex>
                    }
                />
            )}
        </Flex>
    )
}
