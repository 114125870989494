import { EllipsisMiddle } from '@merchant/shared/components'
import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import { isEmpty } from 'lodash-es'
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import type { WhitelistOptionType } from './types'
import type { SelectProps } from '@merchant/ui-kit/ant-design'
import type { Network } from '~api/instances/cabinet-api'
import type { ExtendedWhitelistData, WhitelistWithKey } from '~api/swr/rest/types'
import type { WithdrawalCurrency } from '~features/ActionModal/types'

const { Text } = Typography

function getNetworkMatch(whitelistedAddress: WhitelistWithKey, selectedCurrency: WithdrawalCurrency | undefined) {
    if (!selectedCurrency) {
        return true
    }

    return isEmpty(selectedCurrency.networks)
        ? whitelistedAddress.currency === selectedCurrency.code
        : selectedCurrency.networks.some(currencyNetwork => currencyNetwork.code === whitelistedAddress.network)
}

function getFilteredWhitelistedAddresses(
    whitelist: ExtendedWhitelistData | undefined,
    selectedCurrency: WithdrawalCurrency | undefined
): WhitelistWithKey[] | undefined {
    return whitelist?.addresses.filter(addressItem => {
        const allCurrenciesWhitelist = !addressItem.currency
        const networkMatch = getNetworkMatch(addressItem, selectedCurrency)
        const currencyMatch = !selectedCurrency || addressItem.currency === selectedCurrency.code

        return (allCurrenciesWhitelist || currencyMatch) && networkMatch
    })
}

export function getWhitelistSelectData(
    whitelist: ExtendedWhitelistData | undefined,
    selectedCurrencyData: WithdrawalCurrency | undefined
): {
    isFilteredWhitelistEmpty: boolean
    options: WhitelistOptionType[] | undefined
} {
    const filteredWhitelist = getFilteredWhitelistedAddresses(whitelist, selectedCurrencyData)

    const options: SelectProps<WhitelistWithKey, WhitelistOptionType>['options'] = filteredWhitelist?.map(whitelist => {
        const { key, currency, network, name, address } = whitelist
        const searchableKey = [key, currency, network, name, address, selectedCurrencyData?.name]
            .filter(Boolean)
            .join('')

        return {
            ...whitelist,
            key: searchableKey,
            value: key,
            label: [name],
        }
    })

    return { isFilteredWhitelistEmpty: isEmpty(filteredWhitelist), options }
}

export const getWhitelistDetails = (
    { address, network, addressTag }: WhitelistWithKey,
    networks: Network[],
    { showAddressTooltip }: { showAddressTooltip: boolean }
) => {
    const whitelistNetwork = networks.find(item => item.code === network)

    return [
        whitelistNetwork && (
            <Flex justify="space-between">
                <Text data-merchant={null} type="secondary">
                    <FormattedMessage {...lang.network} />
                </Text>
                <Text data-merchant="withdrawal-whitelisted-address-network" type="secondary">
                    {whitelistNetwork.protocol} <Text data-merchant={null}>{whitelistNetwork.name}</Text>
                </Text>
            </Flex>
        ),
        address && (
            <Flex justify="space-between" gap={12} wrap="nowrap">
                <Text data-merchant={null} type="secondary" style={{ textWrap: 'nowrap' }}>
                    <FormattedMessage {...lang.address} />
                </Text>
                <EllipsisMiddle
                    data-merchant="withdrawal-whitelisted-address-address"
                    prefixCount={16}
                    suffixCount={16}
                    divider="."
                    showTooltip={showAddressTooltip}
                    copyableText={showAddressTooltip ? address : undefined}
                >
                    {address}
                </EllipsisMiddle>
            </Flex>
        ),
        addressTag && (
            <Flex justify="space-between" gap={12} wrap="nowrap">
                <Text data-merchant={null} type="secondary" style={{ textWrap: 'nowrap' }}>
                    <FormattedMessage {...lang.tag} />
                </Text>
                <EllipsisMiddle
                    data-merchant="withdrawal-whitelisted-address-address-tag"
                    prefixCount={16}
                    suffixCount={16}
                    divider="."
                    showTooltip={showAddressTooltip}
                    copyableText={showAddressTooltip ? addressTag : undefined}
                >
                    {addressTag}
                </EllipsisMiddle>
            </Flex>
        ),
    ]
        .filter(Boolean)
        .map((n, index) => <Fragment key={index}>{n}</Fragment>)
}
