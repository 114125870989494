import { useMemo } from 'react'
import { useCurrencies } from '~api'
import { isSwappablePredicate } from '~utils'

export function useSwapEnabled() {
    const { data: currencies } = useCurrencies()

    return useMemo(() => {
        if (!currencies) {
            return false
        }

        return Object.values(currencies).some(isSwappablePredicate)
    }, [currencies])
}
