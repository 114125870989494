import { ModalResponsive } from '@merchant/shared/components'
import { FormattedMessage } from 'react-intl'
import { ConfirmationView, FormattedMessageWithBoldText } from '..'
import { lang } from './lang'
import type { ConfirmationItems } from '../ConfirmationView/types'
import type { ModalProps } from '@merchant/ui-kit/ant-design'
import { projectApi, useProject } from '~api'
import { GenerateVerificationCodeRequestPurpose } from '~api/instances/cabinet-api'
import { globalLang } from '~globalLang'
import { withDirtyCheckingContextProvider } from '~hoc/withDirtyCheckingContextProvider'
import { useDirtyFormCheckerControls } from '~hooks/useDirtyFormCheckerControls'

interface Props extends Omit<ModalProps, 'data-merchant'> {
    onModalSuccess: () => void
    open: boolean
}
function Component({ onModalSuccess, onCancel, ...rest }: Props) {
    const { handleCancel } = useDirtyFormCheckerControls(onCancel)
    const { data: { id: projectId = '' } = {} } = useProject()

    const confirmationRequest = (values: ConfirmationItems) =>
        projectApi.updateProjectWhitelist({
            whitelistUpdateRequest: { enabled: false, ...values },
            projectId,
        })

    return (
        <ModalResponsive
            data-merchant="whitelist-disable-modal"
            destroyOnClose
            footer={null}
            title={
                <span data-merchant="whitelist-disable-modal-title">
                    <FormattedMessage {...lang.modalTitle} />
                </span>
            }
            onCancel={handleCancel}
            {...rest}
        >
            <ConfirmationView
                alertMessage={<FormattedMessageWithBoldText message={lang.disableAlertDescription} />}
                request={confirmationRequest}
                onSuccess={onModalSuccess}
                buttonTitle={globalLang.confirm}
                purpose={GenerateVerificationCodeRequestPurpose.DisableWhitelist}
            />
        </ModalResponsive>
    )
}

export const DisableWhitelistModal = withDirtyCheckingContextProvider(Component)
