import { getCurrencyMinPrecision } from '@merchant/shared/utils'
import { Form } from '@merchant/ui-kit/ant-design'
import { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { withdrawalCryptoFormKeys } from '../../const'
import type { CryptoWithdrawalModalPermissions } from './types'
import type { WithdrawalCryptoFormData } from '../../types'
import { useBalances, useCurrencies, useWhitelist } from '~api'
import { Permission } from '~api/instances/cabinet-api'
import { QueryParams } from '~constants/routes'
import { useUserPermissionContext } from '~contexts'
import { getIfCurrencyWithdrawable, getWithdrawalCurrencies, getWithdrawalCurrencySelectOption } from '~utils'

export function useData() {
    const { checkUserPermission } = useUserPermissionContext()
    const [searchParams, setSearchParams] = useSearchParams()
    const {
        data: currenciesData,
        isLoading: isCurrenciesLoading,
        isValidating: isCurrenciesValidating,
    } = useCurrencies()
    const { data: whitelist } = useWhitelist()
    const { data: balances, isLoading: isBalancesLoading, isValidating: isBalancesValidating } = useBalances()
    const cryptoWithdrawalPermissions: CryptoWithdrawalModalPermissions = {
        [Permission.CommitCryptoWithdrawals]: checkUserPermission(Permission.CommitCryptoWithdrawals),
        [Permission.SeeBalance]: checkUserPermission(Permission.SeeBalance),
    }

    const [form] = Form.useForm<WithdrawalCryptoFormData>()
    const selectedCurrencyCode: string | undefined = Form.useWatch(withdrawalCryptoFormKeys.currency, form)
    const selectedNetworkCode: string | undefined = Form.useWatch(withdrawalCryptoFormKeys.network, form)
    // conditional watch to avoid unnecessary re-renders when whitelist is not enabled
    const whitelistKey = Form.useWatch(withdrawalCryptoFormKeys.whitelistKey, whitelist?.enabled ? form : undefined)
    const selectedWhitelist = whitelist?.enabled
        ? whitelist.addresses.find(item => item.key === whitelistKey)
        : undefined

    const withdrawalCurrencies = useMemo(() => {
        const cryptoCurrencies = Object.fromEntries(
            Object.entries(currenciesData || {}).filter(([_, currency]) => currency.crypto)
        )
        const withdrawableCurrencies = getWithdrawalCurrencies({
            balances,
            currencies: cryptoCurrencies,
            isWithdrawableOnly: false,
        })

        if (!whitelist?.enabled || selectedWhitelist) {
            return withdrawableCurrencies
        } else if (selectedNetworkCode) {
            return Object.fromEntries(
                Object.entries(withdrawableCurrencies).filter(([_, { networks } = { networks: [] }]) =>
                    networks.some(({ code }) => code === selectedNetworkCode)
                )
            )
        }

        return withdrawableCurrencies
    }, [balances, currenciesData, selectedNetworkCode, selectedWhitelist, whitelist?.enabled])

    const onCurrencyChange = (code: string) => {
        if (code) {
            searchParams.set(QueryParams.fromCurrency, code)
        } else {
            searchParams.delete(QueryParams.fromCurrency)
        }
        setSearchParams(searchParams)
    }

    const selectedCurrencyData = withdrawalCurrencies[selectedCurrencyCode || '']
    const selectedNetworkData = selectedCurrencyData?.networks.find(({ code }) => code === selectedNetworkCode)
    const balanceAmount = Number(selectedCurrencyData?.amount || 0)
    const addressTag = selectedNetworkData?.addressTagName || selectedCurrencyData?.addressTagName

    useEffect(() => {
        const fromCurrency = searchParams.get(QueryParams.fromCurrency)
        if (fromCurrency && withdrawalCurrencies[fromCurrency]) {
            return form.setFieldValue(withdrawalCryptoFormKeys.currency, fromCurrency)
        }

        if (whitelist?.enabled) {
            const currencyCodeWithMostFunds = Object.values(withdrawalCurrencies).find(getIfCurrencyWithdrawable)?.code
            if (currencyCodeWithMostFunds) {
                searchParams.set(QueryParams.fromCurrency, currencyCodeWithMostFunds)
                setSearchParams(searchParams)
                form.setFieldValue(withdrawalCryptoFormKeys.currency, currencyCodeWithMostFunds)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, searchParams])

    return {
        withdrawalCurrencies,
        isLoading: isCurrenciesLoading || isBalancesLoading,
        isValidating: isCurrenciesValidating || isBalancesValidating,
        form,
        balanceAmount,
        addressTag,
        selectedCurrencyData,
        selectedNetworkData,
        withdrawalAmountPrecision: getCurrencyMinPrecision(selectedCurrencyCode, currenciesData),
        whitelist,
        selectedWhitelist,
        selectedNetworkCode,
        onCurrencyChange,
        cryptoWithdrawalPermissions,
        currencySelectOptions: getWithdrawalCurrencySelectOption(withdrawalCurrencies),
    }
}
