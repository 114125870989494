import { Alert, Flex } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { assets } from './../assets/nameMap'
import { AppSvg, EmailSupportLink } from './../components'
import { globalLang } from './../globalLang'
import { toArray } from './array'
import type { PropsWithChildren, ReactNode } from 'react'

type Keys = symbol | readonly symbol[]

interface ErrorBoundaryProps extends PropsWithChildren {
    fallbackCb?: (error: unknown, defaultFallback: () => JSX.Element) => ReactNode
    errorKeys: Keys
}

export class ErrorHandler {
    static defaultFallback = () => (
        <Alert
            data-merchant={null}
            style={{ paddingBlock: 24, fontSize: 14 }}
            type="error"
            message={
                <Flex vertical>
                    <span>
                        <FormattedMessage {...globalLang.somethingWentWrong} />
                    </span>
                    <span>
                        <FormattedMessage
                            {...globalLang.tryAgainLater}
                            values={{ SupportLink: <EmailSupportLink style={{ fontSize: 'inherit' }} /> }}
                        />
                    </span>
                </Flex>
            }
            showIcon
            icon={<AppSvg size={32} name={assets.alertCircle} />}
        />
    )

    private errors: Record<symbol, unknown> = {}

    get render() {
        return ({ children, errorKeys, fallbackCb }: ErrorBoundaryProps) => {
            const errorKeysArray = toArray(errorKeys)
            const error = errorKeysArray.find(key => this.errors[key])
            if (error) {
                return fallbackCb ? fallbackCb(error, ErrorHandler.defaultFallback) : ErrorHandler.defaultFallback()
            }

            return <>{children}</>
        }
    }

    addError(keys: Keys, error: unknown) {
        const keyArray = toArray(keys)
        keyArray.forEach(key => (this.errors[key] = error))
    }

    removeError(keys: Keys) {
        const keyArray = toArray(keys)
        keyArray.forEach(key => delete this.errors[key])
    }

    getHandlers(keys: Keys) {
        return {
            addError: (error: unknown) => this.addError(keys, error),
            removeError: () => this.removeError(keys),
        }
    }
}
