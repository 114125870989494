import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import type { PaymentBase, PaymentDescriptionContext } from './types'
import type { TooltipProps } from '@merchant/ui-kit/ant-design'
import type { Breakpoints } from '~hooks'
import { PaymentSteps } from '~constants'
import { formatNumber } from '~utils'

export const getEllipsisTooltip = (
    breakpoints: Breakpoints,
    title: TooltipProps['title'],
    dataMerchant: string
): TooltipProps | undefined =>
    !breakpoints.xs
        ? {
              mouseEnterDelay: 0.3,
              title,
              placement: 'bottom',
              overlayStyle: { maxWidth: '50%' },
              'data-merchant': dataMerchant,
          }
        : undefined

export const getRowStyle = (breakpoints: Breakpoints): React.CSSProperties => ({
    padding: '20px 20px 12px',
    marginBottom: 4,
    gap: breakpoints.xs ? 30 : 50,
})

export function getPaymentTitle(paymentData: PaymentBase | undefined) {
    if (!paymentData) {
        return ''
    }

    const {
        nominalAmount,
        nominalCurrency,
        recommendedAmount,
        details: { title },
    } = paymentData
    const paymentNominalAmount = recommendedAmount?.nominal ?? nominalAmount

    if (title) {
        return title
    }

    if (Number(paymentNominalAmount)) {
        return `${formatNumber(paymentNominalAmount)} ${nominalCurrency}`
    }

    return ''
}

export function getFormattedAmount({
    context,
    paymentData,
    step,
}: {
    step: PaymentSteps | undefined
    paymentData: PaymentBase | undefined
    context: PaymentDescriptionContext
}) {
    const { recommendedAmount, nominalCurrency, nominalAmount } = paymentData || {}
    const isDeposit = !nominalAmount
    const nominalAmountValue = recommendedAmount?.nominal ?? nominalAmount
    if (!nominalAmountValue) {
        return undefined
    }

    if (isDeposit) {
        if (step === PaymentSteps.PaymentProcessing) {
            return undefined
        } else if (context === 'constructor') {
            return (
                <FormattedMessage
                    {...lang.deposit}
                    values={{ amount: formatNumber(nominalAmountValue), currency: nominalCurrency }}
                />
            )
        }
    }

    return `${formatNumber(nominalAmountValue)} ${nominalCurrency}`
}
