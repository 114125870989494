/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectAPIKeyPermission } from './ProjectAPIKeyPermission';
import {
    ProjectAPIKeyPermissionFromJSON,
    ProjectAPIKeyPermissionFromJSONTyped,
    ProjectAPIKeyPermissionToJSON,
} from './ProjectAPIKeyPermission';

/**
 * 
 * @export
 * @interface CreateAPIKeyRequest
 */
export interface CreateAPIKeyRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAPIKeyRequest
     */
    totpCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAPIKeyRequest
     */
    totpBackup?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAPIKeyRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAPIKeyRequest
     */
    publicKey: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAPIKeyRequest
     */
    privateKeyMask: string;
    /**
     * 
     * @type {Array<ProjectAPIKeyPermission>}
     * @memberof CreateAPIKeyRequest
     */
    permissions?: Array<ProjectAPIKeyPermission>;
}

/**
 * Check if a given object implements the CreateAPIKeyRequest interface.
 */
// @ts-ignore
export function instanceOfCreateAPIKeyRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "publicKey" in value;
    isInstance = isInstance && "privateKeyMask" in value;

    return isInstance;
}

// @ts-ignore
export function CreateAPIKeyRequestFromJSON(json: any): CreateAPIKeyRequest {
    return CreateAPIKeyRequestFromJSONTyped(json, false);
}

// @ts-ignore
export function CreateAPIKeyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAPIKeyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totpCode': !exists(json, 'totp_code') ? undefined : json['totp_code'],
        'totpBackup': !exists(json, 'totp_backup') ? undefined : json['totp_backup'],
        'name': json['name'],
        'publicKey': json['public_key'],
        'privateKeyMask': json['private_key_mask'],
        'permissions': !exists(json, 'permissions') ? undefined : ((json['permissions'] as Array<any>).map(ProjectAPIKeyPermissionFromJSON)),
    };
}

// @ts-ignore
export function CreateAPIKeyRequestToJSON(value?: CreateAPIKeyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totp_code': value.totpCode,
        'totp_backup': value.totpBackup,
        'name': value.name,
        'public_key': value.publicKey,
        'private_key_mask': value.privateKeyMask,
        'permissions': value.permissions === undefined ? undefined : ((value.permissions as Array<any>).map(ProjectAPIKeyPermissionToJSON)),
    };
}

