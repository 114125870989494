import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from '../lang'

const { Title } = Typography

const flexStyles: React.CSSProperties = { marginBlock: 20, border: 'none' }

export function TableEmpty() {
    return (
        <Flex vertical gap={4} style={flexStyles} align="center" data-merchant="whitelist-page-no-addresses">
            <Title level={3} data-merchant={null}>
                <FormattedMessage {...lang.tableEmptyTitle} />
            </Title>
            <Title level={5} data-merchant={null}>
                <FormattedMessage {...lang.tableEmptySubtitle} />
            </Title>
        </Flex>
    )
}
