import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { Flex, Space, Tag, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { ItemsCount } from './ItemsCount'
import { lang } from './lang'
import type { CurrencyExtended } from './../types'
import type { SetState } from '@merchant/shared/types'
import type { MessageDescriptor } from 'react-intl'

const { Text } = Typography

const POPOVER_MAX_TAG_COUNT = 10

const descriptorsMap: Record<Props['type'], MessageDescriptor> = {
    activated: lang.willBeActivated,
    deactivated: lang.willBeDeactivated,
}

interface Props {
    items: CurrencyExtended[]
    allItemsCount: number
    setSelectedCurrencies: SetState<CurrencyExtended[]>
    type: 'activated' | 'deactivated'
}

export function PopoverTagsList({ items, allItemsCount, setSelectedCurrencies, type }: Props) {
    const handleTagClose = (currency: CurrencyExtended, isActivatedList: boolean) => {
        setSelectedCurrencies(prev => {
            if (isActivatedList) {
                return prev.filter(item => item.code !== currency.code)
            } else {
                return [...prev, currency]
            }
        })
    }

    return (
        <Flex vertical>
            <Flex justify="space-between">
                <Text data-merchant={null}>
                    <FormattedMessage {...descriptorsMap[type]} values={{ bold: chunks => <b>{chunks}</b> }} />
                </Text>
                <ItemsCount items={items} allItemsCount={allItemsCount} type={type} />
            </Flex>
            {items.length < POPOVER_MAX_TAG_COUNT && (
                <Space wrap size={4}>
                    {items.map(item => (
                        <Tag
                            onClose={() => handleTagClose(item, type === 'activated')}
                            key={item.code}
                            style={{ margin: 0 }}
                            closable
                            color={type === 'deactivated' ? 'error' : 'success'}
                            bordered={false}
                            closeIcon={
                                <Text
                                    style={{ display: 'inline-flex' }}
                                    data-merchant={`currencies-settings-${item.code}-tag-close`}
                                >
                                    <AppSvg size={16} name={assets.close} />
                                </Text>
                            }
                        >
                            <Text
                                style={{ verticalAlign: 'text-bottom' }}
                                data-merchant={`currencies-settings-${item.code}-tag`}
                            >
                                {item.code}
                            </Text>
                        </Tag>
                    ))}
                </Space>
            )}
        </Flex>
    )
}
