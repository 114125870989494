import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from '../lang'
import type { Network } from '~api'

const { Text } = Typography

interface Props extends Pick<Network, 'code' | 'name' | 'protocol' | 'payinEnabled'> {
    isLabel?: boolean
}

export function NetworkOption({ code, name, protocol, payinEnabled, isLabel }: Props) {
    return (
        <Flex gap={4} style={{ opacity: payinEnabled ? 1 : 0.5 }}>
            {isLabel && (
                <Text type="secondary" data-merchant={null}>
                    <FormattedMessage {...lang.network} />
                </Text>
            )}
            <Text style={{ fontWeight: 700 }} data-merchant={null}>
                {code}
            </Text>
            <Text data-merchant={null}>{name}</Text>
            <Text type={isLabel ? undefined : 'secondary'} ellipsis data-merchant={null}>
                · {protocol}
            </Text>
            {!payinEnabled && (
                <Text type="secondary" style={{ marginLeft: 'auto' }} data-merchant={null}>
                    <FormattedMessage {...lang.unavailableDeposit} />
                </Text>
            )}
        </Flex>
    )
}
