import { usePostHog } from 'posthog-js/react'
import { useEffect } from 'react'
import { useMerchant } from '~api'

export const usePosthogIdentify = () => {
    const { data: merchant } = useMerchant()
    const posthog = usePostHog()

    useEffect(() => {
        if (merchant) {
            posthog.identify(merchant.id, { merchant_id: merchant.id })
        }
    }, [posthog, merchant])
}
