import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    unavailableDeposits: {
        id: 'addresses-table.modal.unavailable_deposits',
        defaultMessage: 'Deposits in {currency}({network}) are temporarily unavailable!',
    },
    unavailableDepositsPrompt: {
        id: 'addresses-table.modal.unavailable_deposits_prompt',
        defaultMessage: 'What to do if {currency}({network}) has been recently sent to you?',
    },
    unavailableDepositsModalListTitle: {
        id: 'addresses-table.modal.unavailable_deposits_list_title',
        defaultMessage: "Don't worry!",
    },
    unavailableDepositsModalList: {
        id: 'addresses-table.modal.unavailable_deposits_list',
        defaultMessage: `
          <ul>
            <li>Automatic Processing: If there's a temporary outage of blockchain deposits, your deposits will usually be processed automatically once blockchain operations are restored.</li>
            <li>Manual Handling: In rare cases, deposits may need to be handled manually, such as when the blockchain has to be pruned and there's no way to automatically process missed blocks within a reasonable time and cost. </li>
          </ul>
        `,
    },
    support: {
        id: 'addresses-table.modal.unavailable_deposits_list.support',
        defaultMessage:
            'For any questions, feel free to contact our <support>support team</support> or your account manager.',
    },
    okButton: {
        id: 'addresses-table.modal.unavailable_deposits_list.ok_button',
        defaultMessage: 'I understand',
    },
})
