import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import type { Network } from '~api'
import type { WithdrawalCurrency } from '~features/ActionModal/types'

export function getSelectNetworkTooltipTitle({
    isCurrencySelected,
    isNetworkRequired,
}: {
    isCurrencySelected: boolean
    isNetworkRequired: boolean
}) {
    if (!isCurrencySelected) {
        return (
            <span data-merchant="withdrawal-network-select-choose-currency-tooltip">
                <FormattedMessage {...lang.pleaseSelectCurrencyTooltip} />
            </span>
        )
    }
    if (!isNetworkRequired) {
        return (
            <span data-merchant="withdrawal-network-select-network-not-required-tooltip">
                <FormattedMessage {...lang.networkIsNotRequired} />
            </span>
        )
    }

    return undefined
}

type NetworkAvailability = {
    availableNetworks: Network[]
    disabledNetworks: Network[]
}

export function splitNetworksByPayout(selectedCurrencyData: WithdrawalCurrency | undefined): NetworkAvailability {
    return (selectedCurrencyData?.networks || []).reduce<NetworkAvailability>(
        (acc, network) => {
            if (network.payoutEnabled) {
                acc.availableNetworks.push(network)
            } else {
                acc.disabledNetworks.push(network)
            }

            return acc
        },
        { availableNetworks: [], disabledNetworks: [] }
    )
}
