import { AppCoin } from '@merchant/shared/components'
import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import type { LabelProps } from './types'

const { Text } = Typography

interface Props extends LabelProps {
    showName?: boolean
}

export function ShortLabel({ data, showName }: Props) {
    return (
        <Flex align="center">
            <AppCoin style={{ marginRight: '10px', flexShrink: 0 }} currencyCode={data.currency} size="small" />
            <Text data-merchant={null}>{data.currency}</Text>&nbsp;
            {showName && (
                <Text data-merchant={null} ellipsis type="secondary">
                    {data.name}
                </Text>
            )}
        </Flex>
    )
}
