import { usePromise } from '@merchant/shared/hooks'
import { useIntl } from 'react-intl'
import { withdrawalCryptoFormKeys } from '../../const'
import { controllers } from './abortControllers'
import type { FormInstance } from '@merchant/ui-kit/ant-design'
import type { CalcWithdrawalAmountsRequest } from '~api'
import type { WithdrawalCurrency } from '~features/ActionModal/types'
import { projectApi, useProject } from '~api'
import { globalLang } from '~globalLang'

interface Props {
    form: FormInstance
    withdrawalCurrencies?: Record<string, WithdrawalCurrency>
}

export function useRequests({ form }: Props) {
    const intl = useIntl()
    const { data: { id: projectId = '' } = {} } = useProject()

    const {
        send: sendGetWithdrawalFee,
        isLoading: isWithdrawalFeeLoading,
        error: withdrawalFeeError,
    } = usePromise(
        async (data: Omit<CalcWithdrawalAmountsRequest, 'projectId'>) => {
            controllers.feeController.abort()
            controllers.feeController = new AbortController()

            try {
                const response = await projectApi.calcWithdrawalAmounts(
                    { projectId, calcWithdrawalAmountsRequest: data },
                    { signal: controllers.feeController.signal }
                )

                const parsedReceiveAmount = Number(response.receiveAmount)
                form.setFields([
                    {
                        name: withdrawalCryptoFormKeys.fee,
                        value: response.networkFee || '0',
                        errors: [],
                    },
                    {
                        name: withdrawalCryptoFormKeys.receiveAmount,
                        value: isNaN(parsedReceiveAmount) || parsedReceiveAmount < 0 ? '0' : response.receiveAmount,
                        errors: [],
                    },
                    {
                        name: withdrawalCryptoFormKeys.withdrawalAmount,
                        value: response.creditAmount || '0',
                        errors: [],
                    },
                ])
                form.validateFields()
            } catch {
                /* empty */
            }
        },
        {
            showErrorNotification: false,
            onError: () => {
                form.setFields([
                    {
                        name: withdrawalCryptoFormKeys.fee,
                        value: '0',
                        errors: [intl.formatMessage(globalLang.somethingWentWrong)],
                        touched: true,
                        validated: true,
                    },
                ])
            },
        }
    )

    return {
        sendGetWithdrawalFee,
        isWithdrawalFeeLoading,
        withdrawalFeeError,
    }
}
