import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    orders: {
        id: 'orders-page.typography.orders',
        defaultMessage: 'Orders',
    },
    completedStatus: {
        id: 'orders-page.status.completed',
        defaultMessage: 'Completed',
    },
    cancelledStatus: {
        id: 'orders-page.status.cancelled',
        defaultMessage: 'Cancelled',
    },
    waitingStatus: {
        id: 'orders-page.status.waiting',
        defaultMessage: 'Waiting',
    },
    createdStatus: {
        id: 'orders-page.status.created',
        defaultMessage: 'Created',
    },
    failedStatus: {
        id: 'orders-page.status.failed',
        defaultMessage: 'Failed',
    },
    orderCompleted: {
        id: 'orders-page.result.order_completed',
        defaultMessage: 'Order completed',
    },
    orderFailed: {
        id: 'orders-page.result.order_failed',
        defaultMessage: 'Order failed',
    },
    orderExpired: {
        id: 'orders-page.result.order_expired',
        defaultMessage: 'Order expired',
    },
    orderWaiting: {
        id: 'orders-page.result.order_waiting',
        defaultMessage: 'Order waiting',
    },
    orderCreated: {
        id: 'orders-page.result.order_created',
        defaultMessage: 'Order created',
    },
    pageTitle: {
        id: 'orders-page.typography.page_title',
        defaultMessage: 'History',
    },
    source: {
        id: 'orders-page.typography.source',
        defaultMessage: 'Source',
    },
})
