import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    canceledTimeline: {
        id: 'withdrawal.timeline.cancel.title',
        defaultMessage: 'Withdrawal Canceled',
    },
    date: {
        id: 'withdrawal.timeline.cancel.date',
        defaultMessage: 'Date',
    },
    by: {
        id: 'withdrawal.timeline.cancel.canceled_by',
        defaultMessage: 'By',
    },
})
