import cn from 'classnames'
import styles from './styles.module.css'

type GetIsNetworkShown = (params: {
    currencyCode: string
    networkCode: string | undefined
    networkPath: string | undefined
    isNetwork?: boolean
}) => string | false | undefined

export const getIsNetworkShown: GetIsNetworkShown = ({ currencyCode, networkCode, networkPath, isNetwork }) =>
    networkCode && currencyCode !== networkCode && !isNetwork && networkPath

export const getWrapperClassName = (isNetwork: boolean, isFallback: boolean, isImgLoading: boolean) =>
    cn(isNetwork ? styles.networkWrapper : styles.currencyWrapper, {
        [styles.networkFilled!]: isNetwork && (isFallback || isImgLoading),
    })
