import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { selectedProjectIdKey } from '@merchant/shared/constants/localStorage'
import { headerButtonCommonProps } from '@merchant/shared/props'
import { Button, Divider, Dropdown, Flex, Typography, theme } from '@merchant/ui-kit/ant-design'
import React, { useRef, type RefObject } from 'react'
import { FormattedMessage } from 'react-intl'
import { useBoolean, useLocalStorage, useOnClickOutside } from 'usehooks-ts'
import { lang } from '../lang'
import { getMenuProps } from '../utils'
import type { MenuProps } from '@merchant/ui-kit/ant-design'
import { useMerchant, useProjects } from '~api'
import { useProjectRolesMap } from '~hooks'

const { Text } = Typography
const { useToken } = theme

interface ProjectPickerProps {
    onLogoutButtonClick: () => void
}

export function ProjectPickerDropdown({ onLogoutButtonClick }: ProjectPickerProps): React.ReactNode {
    const { data: projectRoles } = useProjectRolesMap()
    const dropdownWrapperRef = useRef<HTMLDivElement>(null)
    const triggerButtonRef = useRef<HTMLButtonElement | HTMLAnchorElement>(null)
    const { value: isProjectPickerOpen, setFalse: closeProjectPicker, toggle: toggleProjectPicker } = useBoolean(false)
    const [selectedProjectId, setSelectedProjectId] = useLocalStorage<string>(selectedProjectIdKey, '')
    const { data: projects } = useProjects()
    const { token } = useToken()
    const { data: merchant } = useMerchant()
    useOnClickOutside([dropdownWrapperRef, triggerButtonRef] as RefObject<HTMLElement>[], closeProjectPicker)

    const wrapperContentStyle: React.CSSProperties = {
        padding: 12,
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadiusLG,
        boxShadow: token.boxShadowSecondary,
    }

    const handleMenuItemSelect: MenuProps['onSelect'] = ({ key }) => {
        setSelectedProjectId(key)
        closeProjectPicker()
    }

    const handleLogoutButtonClick = () => {
        onLogoutButtonClick()
        closeProjectPicker()
    }

    return (
        <Dropdown
            open={isProjectPickerOpen}
            trigger={['click']}
            menu={getMenuProps({
                projects,
                selectedProjectId,
                token,
                handleMenuItemSelect,
                closePicker: closeProjectPicker,
                projectRoles,
                merchantId: merchant?.id,
            })}
            data-merchant="project-picker-dropdown"
            overlayStyle={{ minWidth: 200, maxWidth: 350 }}
            dropdownRender={menu => (
                <div
                    data-merchant="header-project-picker-dropdown"
                    style={wrapperContentStyle}
                    ref={dropdownWrapperRef}
                >
                    <Flex vertical>
                        <Text style={{ fontWeight: 600 }} data-merchant="header-project-picker-dropdown-merchant-email">
                            {merchant?.email}
                        </Text>
                        <Divider style={{ marginBlock: 8 }} />
                    </Flex>
                    {menu}
                    <Button
                        style={{
                            marginTop: 12,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 4,
                        }}
                        data-merchant="header-logout-button"
                        onClick={handleLogoutButtonClick}
                        size="large"
                        block
                        icon={<AppSvg size={18} name={assets.logOut} />}
                    >
                        <FormattedMessage {...lang.logoutButton} />
                    </Button>
                </div>
            )}
            placement="bottomRight"
            align={{ offset: [4, 8] }}
        >
            <Button
                {...headerButtonCommonProps}
                ref={triggerButtonRef}
                data-merchant="header-project-picker-button"
                onClick={toggleProjectPicker}
                icon={<AppSvg size={24} name={assets.user} />}
            />
        </Dropdown>
    )
}
