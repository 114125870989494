import { ModalResponsive, CopyButton, TxHash, StatisticFormattedNumber } from '@merchant/shared/components'
import { parseDateAndTime } from '@merchant/shared/utils'
import { Flex, Typography, theme } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang as commonLang } from '../../lang'
import { TransactionNotes } from '../TransactionNotes'
import { TransactionStatus } from '../TransactionStatus'
import type { ModalProps } from '@merchant/ui-kit/ant-design'
import type { CancelProjectWithdrawalRequest, Transaction } from '~api/instances/cabinet-api'
import { Permission, useCurrencies, useProject } from '~api'
import { NoValueColumnContent } from '~components'
import { useUserPermissionContext } from '~contexts'
import {
    getIfPositiveBalanceChange,
    getOriginText,
    getOriginTooltip,
    isCancellableTransaction,
    isTransactionWithdrawalDetails,
} from '~features/TransactionsTable/utils'
import { globalLang } from '~globalLang'

const { Text } = Typography
const { useToken } = theme

interface Props extends ModalProps {
    data?: Transaction
    cancelWithdrawal: (a: CancelProjectWithdrawalRequest) => Promise<void>
    onOrderIdClick: (id: string) => void
}

// eslint-disable-next-line max-lines-per-function
export function TransactionDetailsModal({ data, cancelWithdrawal, onCancel, onOrderIdClick, ...rest }: Props) {
    const { data: { id: projectId = '' } = {} } = useProject()
    const { checkUserPermission } = useUserPermissionContext()
    const { data: currencies } = useCurrencies()
    const { token } = useToken()

    // eslint-disable-next-line max-lines-per-function
    const getContent = () => {
        if (!data) {
            return null
        } else {
            const parsedDate = parseDateAndTime(data.date)
            const hash = isTransactionWithdrawalDetails(data.details) ? data.details.hash : undefined

            const isCancellable = isCancellableTransaction(data)

            return (
                <Flex gap={8} vertical>
                    <Flex vertical>
                        <Text type="secondary" data-merchant={null}>
                            <FormattedMessage {...commonLang.created} />
                        </Text>
                        <Flex>
                            {parsedDate ? (
                                <>
                                    <Text data-merchant="transaction-date">{parsedDate.date}</Text>&nbsp;
                                    <Text type="secondary" data-merchant="transaction-time">
                                        {parsedDate.time}
                                    </Text>
                                </>
                            ) : (
                                NoValueColumnContent
                            )}
                        </Flex>
                    </Flex>
                    <Flex vertical>
                        <Text type="secondary" data-merchant={null}>
                            <FormattedMessage {...commonLang.status} />
                        </Text>
                        <TransactionStatus
                            type={data.type}
                            status={data.status}
                            onCancelClick={
                                isCancellable
                                    ? () => cancelWithdrawal({ id: data.details.withdrawalId, projectId })
                                    : () => Promise.reject(new Error('Cannot cancel transaction'))
                            }
                            isCancelable={isCancellable}
                            style={{ padding: '2px 6px' }}
                        />
                    </Flex>
                    <Flex vertical>
                        <Text type="secondary" data-merchant={null}>
                            <FormattedMessage {...commonLang.transactionId} />
                        </Text>
                        <Flex gap={8} align="center">
                            <Text data-merchant={`transaction-details-modal-txId-${data.txId}`}>{data.txId}</Text>
                            <CopyButton
                                data-merchant="transaction-id-copy-button"
                                inputValue={data.id}
                                notificationContentSubject={commonLang.transactionId}
                            />
                        </Flex>
                    </Flex>
                    <Flex vertical>
                        <Text type="secondary" data-merchant={null}>
                            <FormattedMessage {...globalLang.hash} />
                        </Text>
                        <Flex gap={8} align="center">
                            {hash ? (
                                <>
                                    <TxHash
                                        hash={hash}
                                        currencies={currencies}
                                        currencyCode={data.currency}
                                        networkCode={data.network}
                                    />
                                    <CopyButton
                                        data-merchant="transaction-hash-copy-button"
                                        inputValue={hash}
                                        notificationContentSubject={globalLang.hash}
                                    />
                                </>
                            ) : (
                                NoValueColumnContent
                            )}
                        </Flex>
                    </Flex>
                    <Flex vertical>
                        <Text type="secondary" data-merchant={null}>
                            <FormattedMessage {...commonLang.totalAmount} />
                        </Text>
                        <Text
                            type={getIfPositiveBalanceChange(data.type) ? 'success' : 'danger'}
                            data-merchant="transaction-amount"
                        >
                            <StatisticFormattedNumber
                                value={data.amount}
                                valueRender={value => (
                                    <>
                                        {getIfPositiveBalanceChange(data.type) ? '+' : '-'}
                                        {value}
                                    </>
                                )}
                                suffix={data.currency}
                                precision={2}
                            />
                        </Text>
                    </Flex>
                    <Flex vertical>
                        <Text type="secondary" data-merchant={null}>
                            <FormattedMessage {...commonLang.remainderBalance} />
                        </Text>
                        <StatisticFormattedNumber
                            value={data.balance}
                            suffix={data.currency}
                            precision={2}
                            hidden={!checkUserPermission(Permission.SeeBalance)}
                        />
                    </Flex>
                    <Flex vertical>
                        <Text type="secondary" data-merchant={null}>
                            <FormattedMessage {...commonLang.notes} />
                        </Text>
                        <Flex gap={4} vertical>
                            <TransactionNotes data={data} onOrderIdClick={onOrderIdClick} />
                            {data.origin && (
                                <>
                                    {getOriginText(data.origin)}
                                    {getOriginTooltip(data.origin, token)}
                                </>
                            )}
                        </Flex>
                    </Flex>
                </Flex>
            )
        }
    }

    return (
        <ModalResponsive
            footer={(_, { OkBtn }) => <OkBtn />}
            okButtonProps={{ block: true, size: 'large', 'data-merchant': 'transaction-details-modal-ok-button' }}
            styles={{ content: { paddingTop: 32 }, footer: { marginTop: 24 } }}
            onCancel={onCancel}
            onOk={onCancel}
            {...rest}
            open={Boolean(data)}
        >
            {getContent()}
        </ModalResponsive>
    )
}
