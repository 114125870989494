import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    areYouSureToCancel: {
        id: 'transactions-page.popconfirm.are_you_sure_to_cancel',
        defaultMessage: 'Are you sure you want to cancel this transaction?',
    },
    confirm: {
        id: 'transactions-page.popconfirm.confirm_button',
        defaultMessage: 'Confirm',
    },
    no: {
        id: 'transactions-page.popconfirm.no_button',
        defaultMessage: 'No',
    },
    cancel: {
        id: 'transactions-page.button.cancel',
        defaultMessage: 'Cancel',
    },
    successFiatWithdrawalTooltip: {
        id: 'transactions-page.tooltip.success_fiat_withdrawal',
        defaultMessage:
            "Completed status doesn't guarantee immediate receipt of funds. Bank transfers may take 1-3 days",
    },
})
