import type { SignUpFormData, StateData } from './'

export function getInitialData(
    stepData: StateData | undefined,
    isFirstRender: React.MutableRefObject<boolean>,
    initialEmail: string | null
) {
    let email = stepData?.email || ''
    if (isFirstRender.current) {
        email = initialEmail || ''
        isFirstRender.current = false
    }

    const initialData: SignUpFormData = {
        email,
        password: stepData?.password || '',
        policy: stepData?.policy || false,
    }

    return initialData
}
