import dayjs from 'dayjs'
import { orderRowsKeys } from './const'
import { PaymentMethodType, PaymentSubtype, PaymentType, type Payment } from '~api/instances/cabinet-api'

export const getLatestEvent = (data?: Payment) => {
    const eventstime = [
        data?.completedAt,
        data?.selectedPaymentMethod?.payins?.at(-1)?.declaredAt,
        data?.createdAt,
        data?.selectedPaymentMethod?.selectedAt,
    ].filter(Boolean)

    return eventstime
        .sort((a, b) => {
            const dateA = dayjs(a)
            const dateB = dayjs(b)

            return dateA.isBefore(dateB) ? 1 : -1
        })
        .at(0)
}

export const getHiddenRows = (data: Payment | undefined) => {
    const methods = [PaymentMethodType.CryptoToCrypto, PaymentMethodType.DirectCrypto, PaymentMethodType.CryptoToFiat]

    if (data?.type !== PaymentType.Deposit) {
        return
    }

    const selectedPaymentMethodType = data.selectedPaymentMethod?.type
    if (selectedPaymentMethodType && methods.includes(selectedPaymentMethodType)) {
        if (data.subtype === PaymentSubtype.ProjectAddressDeposit) {
            return [
                orderRowsKeys.customerEmail,
                orderRowsKeys.customerId,
                orderRowsKeys.merchantOrderId,
                orderRowsKeys.origin,
                orderRowsKeys.underpayment,
                orderRowsKeys.metaInfo,
                orderRowsKeys.callbackButton,
            ]
        }
        if (data.subtype === PaymentSubtype.CustomerAddressDeposit) {
            return [orderRowsKeys.underpayment]
        }
    }

    return
}
