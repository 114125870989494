import type { Placement } from './types'
import type { CardProps } from '@merchant/ui-kit/ant-design'
import type React from 'react'

const topCardStyles: React.CSSProperties = { borderEndStartRadius: 4, borderEndEndRadius: 4 }
const bottomCardStyles: React.CSSProperties = { borderTopLeftRadius: 4, borderTopRightRadius: 4 }
const topCardBodyStyles: React.CSSProperties = { padding: 24 }
const bottomCardBodyStyles: React.CSSProperties = { padding: '16px 20px 20px' }

export const stylesMap: Record<Placement, Pick<CardProps, 'styles' | 'style'>> = {
    top: {
        style: topCardStyles,
        styles: { body: topCardBodyStyles },
    },
    bottom: {
        style: bottomCardStyles,
        styles: { body: bottomCardBodyStyles },
    },
    middle: {
        style: { ...topCardStyles, ...bottomCardStyles },
        styles: undefined,
    },
}

export const commonStyle: React.CSSProperties = { boxShadow: '0px 15px 30px 0px rgba(0, 0, 0, 0.03)' }
