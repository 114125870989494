/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CallbackResponseRequest
 */
export interface CallbackResponseRequest {
    /**
     * 
     * @type {string}
     * @memberof CallbackResponseRequest
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof CallbackResponseRequest
     */
    body: string;
    /**
     * 
     * @type {string}
     * @memberof CallbackResponseRequest
     */
    signature: string;
}

/**
 * Check if a given object implements the CallbackResponseRequest interface.
 */
// @ts-ignore
export function instanceOfCallbackResponseRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "body" in value;
    isInstance = isInstance && "signature" in value;

    return isInstance;
}

// @ts-ignore
export function CallbackResponseRequestFromJSON(json: any): CallbackResponseRequest {
    return CallbackResponseRequestFromJSONTyped(json, false);
}

// @ts-ignore
export function CallbackResponseRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CallbackResponseRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': json['url'],
        'body': json['body'],
        'signature': json['signature'],
    };
}

// @ts-ignore
export function CallbackResponseRequestToJSON(value?: CallbackResponseRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.url,
        'body': value.body,
        'signature': value.signature,
    };
}

