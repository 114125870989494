import { Skeleton } from '@merchant/ui-kit/ant-design'

const sharedProps = {
    paragraph: false,
    active: true,
    loading: true,
    title: { style: { height: 14, margin: '1px 0' } },
}

const row = <Skeleton {...sharedProps} style={{ marginTop: 4 }} />

export function LoadingSkeleton() {
    return (
        <>
            <Skeleton {...sharedProps} style={{ width: '40%' }} />
            {row}
            {row}
            {row}
            {row}
        </>
    )
}
