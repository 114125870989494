import { Row, Typography } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useBoolean } from 'usehooks-ts'
import { context2DataMerchantPrefixMap } from './const'
import { LeftCol } from './LeftCol'
import { RightCol } from './RightCol'
import { getRowStyle, getFormattedAmount } from './utils'
import type { PaymentBase, PaymentDescriptionContext } from './types'
import type { RowProps } from '@merchant/ui-kit/ant-design'
import { ModalResponsive } from '~components'
import { PaymentSteps } from '~constants'
import { globalLang } from '~globalLang'
import { useBreakpoint } from '~hooks'

const { Paragraph, Text } = Typography

function getShouldHideDescription(step?: PaymentSteps) {
    if (!step) {
        return false
    }

    return [PaymentSteps.Error, PaymentSteps.Loading, PaymentSteps.NotFound, PaymentSteps.PaymentResults].includes(step)
}

export interface PaymentDescriptionProps<T extends PaymentBase> extends RowProps {
    step?: PaymentSteps
    paymentData: T | undefined
    context: PaymentDescriptionContext
}

export function PaymentDescription<T extends PaymentBase>({
    paymentData,
    step,
    style,
    context,
    ...rest
}: PaymentDescriptionProps<T>) {
    const dataMerchantPrefix = context2DataMerchantPrefixMap[context]
    const breakpoints = useBreakpoint()
    const {
        value: isDescriptionModalOpen,
        setTrue: openDescriptionModal,
        setFalse: closeDescriptionModal,
    } = useBoolean(false)

    if (!paymentData || getShouldHideDescription(step)) {
        return null
    }

    const { title: itemTitle, description: itemDescription } = paymentData.details
    const amountTextValue = getFormattedAmount({ paymentData, step, context })

    const handleReadMoreClick: React.MouseEventHandler<HTMLButtonElement> = event => {
        event.stopPropagation()
        openDescriptionModal()
    }

    if (!itemTitle && !itemDescription) {
        return null
    }

    return (
        <>
            <ModalResponsive
                data-merchant={`${dataMerchantPrefix}-read-more-modal`}
                open={isDescriptionModalOpen && breakpoints.xs}
                afterClose={closeDescriptionModal}
                footer={(_, { OkBtn }) => <OkBtn />}
                okButtonProps={{
                    block: true,
                    type: 'default',
                    size: 'large',
                    'data-merchant': `${dataMerchantPrefix}-read-more-modal-close-button`,
                }}
                closeIcon={null}
                onOk={closeDescriptionModal}
                onCancel={closeDescriptionModal}
                okText={<FormattedMessage {...globalLang.close} />}
                title={
                    <Text style={{ fontSize: 16, fontWeight: 500 }} data-merchant={`${dataMerchantPrefix}-modal-title`}>
                        {itemTitle}
                    </Text>
                }
            >
                <Paragraph data-merchant={`${dataMerchantPrefix}-modal-description`}>{itemDescription}</Paragraph>
            </ModalResponsive>
            <Row
                wrap={false}
                justify="space-between"
                align="top"
                style={{ ...getRowStyle(breakpoints), ...style }}
                {...rest}
            >
                <LeftCol
                    dataMerchantPrefix={dataMerchantPrefix}
                    breakpoints={breakpoints}
                    itemTitle={itemTitle}
                    itemDescription={itemDescription}
                    paymentData={paymentData}
                    amountTextValue={amountTextValue}
                    handleReadMoreClick={handleReadMoreClick}
                />
                <RightCol
                    dataMerchantPrefix={dataMerchantPrefix}
                    itemTitle={itemTitle}
                    amountTextValue={amountTextValue}
                />
            </Row>
        </>
    )
}
