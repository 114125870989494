import { Button, Space, Typography } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { placeholderViewProps } from './const'
import { PlaceholderName } from './types'
import type { ComponentProps } from 'react'
import type { NavigateFunction } from 'react-router-dom'

const { Title, Paragraph } = Typography

const spaceStyles: React.CSSProperties = { paddingBlock: 120 }

interface Props {
    name?: PlaceholderName
    buttonDataMerchant?: string
    buttonClickHandler?: (navigate: NavigateFunction) => void
    buttonProps?: ComponentProps<typeof Button>
    spaceProps?: ComponentProps<typeof Space>
}

export function PlaceholderView({
    name = PlaceholderName.noMerchant,
    buttonClickHandler,
    buttonDataMerchant = 'no-merchant-button',
    ...props
}: Props) {
    const navigate = useNavigate()

    const {
        title,
        subtitle,
        text,
        buttonText,
        buttonClickHandler: buttonClickHandlerDefault,
        buttonDataMerchant: buttonDataMerchantDefault,
    } = placeholderViewProps[name]

    const handleButtonClick = () => {
        const handler = buttonClickHandler || buttonClickHandlerDefault
        handler(navigate)
    }

    const mergedSpaceStyles = { ...spaceStyles, ...props.spaceProps?.style }

    return (
        <Space direction="vertical" align="center" {...props.spaceProps} style={mergedSpaceStyles}>
            <Title data-merchant={null}>
                <FormattedMessage {...title} />
            </Title>
            <Space direction="vertical" align="center" size={8} style={{ maxWidth: 250, textAlign: 'center' }}>
                <Title level={3} style={{ textAlign: 'center' }} data-merchant={null}>
                    <FormattedMessage {...subtitle} />
                </Title>
                <Paragraph type="secondary" style={{ textAlign: 'center' }} data-merchant={null}>
                    <FormattedMessage {...text} />
                </Paragraph>
            </Space>
            <Button
                data-merchant={buttonDataMerchant || buttonDataMerchantDefault}
                type="primary"
                size="large"
                onClick={handleButtonClick}
                {...props.buttonProps}
            >
                <FormattedMessage {...buttonText} />
            </Button>
        </Space>
    )
}
