import { useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { processErrorResponse } from '.'
import { NotificationMessage, EmailSupportLink } from '~components'
import { useNotifications } from '~contexts'
import { globalLang } from '~globalLang'
import { useCallbackRef } from '~hooks'

interface Params<R, T extends (...args: never[]) => Promise<R | void>> {
    onSuccess?: Awaited<R> extends void ? () => void : (result: R) => void
    notificationProps?: React.ComponentProps<typeof NotificationMessage>
    showErrorNotification?: boolean
    onError?: (error?: unknown, ...args: Parameters<T>) => void
    initialLoading?: boolean
    supportEmail?: string
}

type PromiseResult<T extends Promise<unknown | void>> = T extends Promise<infer R> ? R : never

export const usePromise = <T extends (...args: never[]) => Promise<R | void>, R = PromiseResult<ReturnType<T>>>(
    cb: T,
    params?: Params<R, T>
) => {
    const {
        onSuccess,
        notificationProps,
        showErrorNotification = true,
        onError,
        initialLoading = false,
        supportEmail,
    } = params || {}
    const [isLoading, setLoading] = useState(initialLoading)
    const [data, setData] = useState<R | undefined>()
    const [error, setError] = useState<unknown>()
    const {
        notification: { api: notificationApi },
    } = useNotifications()

    const callIdRef = useRef(0)

    const send = useCallbackRef(async (...args: Parameters<T>) => {
        const currentId = ++callIdRef.current

        setLoading(true)

        try {
            const result = await cb(...args)
            if (currentId !== callIdRef.current) {
                return
            }
            notificationProps &&
                notificationApi.open({
                    message: <NotificationMessage dataMerchant="request-notification" {...notificationProps} />,
                })
            onSuccess?.(result as R)
            setData(result || undefined)
            setError(undefined)
            setLoading(false)

            return result
        } catch (err) {
            if (currentId !== callIdRef.current) {
                return
            }
            if (showErrorNotification && err) {
                const processedError = processErrorResponse(err)
                notificationApi.open({
                    message: (
                        <NotificationMessage
                            dataMerchant="request-error-notification"
                            type="error"
                            title={<FormattedMessage {...globalLang.somethingWentWrong} />}
                            description={
                                processedError ? (
                                    processedError
                                ) : (
                                    <FormattedMessage
                                        {...globalLang.tryAgainLater}
                                        values={{
                                            SupportLink: (
                                                <EmailSupportLink
                                                    data-merchant="request-error-support-email-link"
                                                    email={supportEmail}
                                                />
                                            ),
                                        }}
                                    />
                                )
                            }
                        />
                    ),
                })
            }
            onError?.(err, ...args)
            setData(undefined)
            setError(err)
            setLoading(false)
        }
    })

    const reset = useCallbackRef(() => {
        ++callIdRef.current

        setLoading(false)
        setData(undefined)
        setError(undefined)
    })

    return { isLoading, data, error, send, reset }
}
