import { defineMessages } from 'react-intl'

const localeLabels = defineMessages({
    russian: {
        id: 'shared.locale.russian',
        defaultMessage: 'Русский',
    },
    english: {
        id: 'shared.locale.english',
        defaultMessage: 'English',
    },
    deutsch: {
        id: 'shared.locale.deutsch',
        defaultMessage: 'Deutsch',
    },
    spanish: {
        id: 'shared.locale.spanish',
        defaultMessage: 'Español',
    },
    portuguese: {
        id: 'shared.locale.portuguese',
        defaultMessage: 'Português',
    },
    turkish: {
        id: 'shared.locale.turkish',
        defaultMessage: 'Türkçe',
    },
    korean: {
        id: 'shared.locale.korean',
        defaultMessage: '한국어',
    },
    kazakh: {
        id: 'shared.locale.kazakh',
        defaultMessage: 'Қазақша',
    },
    chinese: {
        id: 'shared.locale.chinese',
        defaultMessage: '中文',
    },
    japanese: {
        id: 'shared.locale.japanese',
        defaultMessage: '日本語',
    },
    french: {
        id: 'shared.locale.french',
        defaultMessage: 'Français',
    },
    ukrainian: {
        id: 'shared.locale.ukrainian',
        defaultMessage: 'Український',
    },
})

export const supportedLocalesMap = {
    en: {
        label: localeLabels.english,
        countryCode: 'gb',
    },
    ru: {
        label: localeLabels.russian,
        countryCode: 'ru',
    },
    de: {
        label: localeLabels.deutsch,
        countryCode: 'de',
    },
    es: {
        label: localeLabels.spanish,
        countryCode: 'es',
    },
    pt: {
        label: localeLabels.portuguese,
        countryCode: 'pt',
    },
    tr: {
        label: localeLabels.turkish,
        countryCode: 'tr',
    },
    ko: {
        label: localeLabels.korean,
        countryCode: 'kr',
    },
    kk: {
        label: localeLabels.kazakh,
        countryCode: 'kz',
    },
    zh: {
        label: localeLabels.chinese,
        countryCode: 'cn',
    },
    ja: {
        label: localeLabels.japanese,
        countryCode: 'jp',
    },
    fr: {
        label: localeLabels.french,
        countryCode: 'fr',
    },
    ua: {
        label: localeLabels.ukrainian,
        countryCode: 'ua',
    },
} as const

export type LocaleCode = keyof typeof supportedLocalesMap

export const defaultLocale: LocaleCode = 'en'
