import { Select, Checkbox, Flex, Form, Skeleton, Tag, Typography } from '@merchant/ui-kit/ant-design'
import cn from 'classnames'
import { FormattedMessage } from 'react-intl'
import { paymentsSettingsFormItems } from '../../const.tsx'
import { RELATIVE_CUSTOM_OPTION, relativeDefaultOptions, relativeOptionsLabelMap } from './const.tsx'
import { lang } from './lang.ts'
import { useData } from './useData.ts'
import { getIsLossConfigEnabled, getTagProps } from './utils.tsx'
import type { LossConfig } from '~api/instances/cabinet-api'
import { AbsoluteLossItem, CustomRelativeLossItem } from '~components'
import underpaymentStyles from '~styles/joined-fields.style.module.css'

const { Text } = Typography

interface Props {
    isUnderpaymentEditAllowed: boolean
    lossConfig: LossConfig | undefined
    isProjectLoading: boolean
}

export function UnderpaymentFieldset({ isUnderpaymentEditAllowed, isProjectLoading, lossConfig }: Props) {
    const {
        baseCurrenciesOptions,
        handleAbsoluteCheckboxChange,
        handleRelativeCheckboxChange,
        handleRelativeSelectChange,
        isAbsoluteCheckboxEnabled,
        isCustomSelected,
        isRelativeCheckboxEnabled,
        lastRelativeValue,
    } = useData({ isProjectLoading, lossConfig })

    return (
        <>
            <Flex vertical style={{ marginBottom: 16 }}>
                <Flex align="center" gap={12}>
                    <Text style={{ fontSize: 16, fontWeight: 600 }} data-merchant={null}>
                        <FormattedMessage {...lang.underpaymentLabel} />
                    </Text>
                    {isProjectLoading ? (
                        <Skeleton.Button active />
                    ) : (
                        <Tag
                            data-merchant="payment-settings-underpayment-tag"
                            {...getTagProps(getIsLossConfigEnabled(lossConfig))}
                        />
                    )}
                </Flex>
                <Text type="secondary" data-merchant={null}>
                    <FormattedMessage {...lang.underpaymentDescription} />
                </Text>
            </Flex>
            <Checkbox
                disabled={!isUnderpaymentEditAllowed}
                data-merchant="payment-settings-underpayment-relative-checkbox"
                checked={isRelativeCheckboxEnabled}
                onChange={handleRelativeCheckboxChange}
                style={{ marginBottom: 8 }}
            >
                <FormattedMessage {...lang.underpaymentRelativeCheckboxTitle} />
            </Checkbox>
            <Flex vertical style={{ marginBottom: 20 }}>
                <Form.Item
                    noStyle
                    name={isCustomSelected ? undefined : paymentsSettingsFormItems.relative}
                    getValueProps={value => ({
                        value: String(value ?? lastRelativeValue ?? relativeDefaultOptions[0]),
                    })}
                >
                    <Select
                        data-merchant="payment-settings-underpayment-relative-select"
                        value={isCustomSelected ? RELATIVE_CUSTOM_OPTION : null}
                        onChange={handleRelativeSelectChange}
                        options={relativeDefaultOptions.map(value => {
                            const label = relativeOptionsLabelMap[value]

                            return {
                                value,
                                label: typeof label === 'number' ? `${label * 100}%` : label,
                            }
                        })}
                        disabled={!isRelativeCheckboxEnabled || !isUnderpaymentEditAllowed}
                        className={cn({
                            [underpaymentStyles.selectCompactTopItem!]: isCustomSelected,
                        })}
                    />
                </Form.Item>
                <CustomRelativeLossItem
                    usageContext="payments-settings"
                    isInputDisabled={!isRelativeCheckboxEnabled || !isUnderpaymentEditAllowed}
                    name={paymentsSettingsFormItems.relative}
                    hidden={!isCustomSelected}
                    isLossEnabled={isRelativeCheckboxEnabled}
                />
            </Flex>
            <AbsoluteLossItem
                usageContext="payments-settings"
                isRelativeLossEnabled={isRelativeCheckboxEnabled}
                isAbsoluteLossEnabled={isAbsoluteCheckboxEnabled}
                onCheckboxChange={handleAbsoluteCheckboxChange}
                baseCurrenciesOptions={baseCurrenciesOptions}
                isLoading={isProjectLoading}
                lossConfig={lossConfig}
            />
        </>
    )
}
