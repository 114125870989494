/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Permission } from './Permission';
import {
    PermissionFromJSON,
    PermissionFromJSONTyped,
    PermissionToJSON,
} from './Permission';
import type { ProjectRoleColor } from './ProjectRoleColor';
import {
    ProjectRoleColorFromJSON,
    ProjectRoleColorFromJSONTyped,
    ProjectRoleColorToJSON,
} from './ProjectRoleColor';

/**
 * 
 * @export
 * @interface ProjectRole
 */
export interface ProjectRole {
    /**
     * 
     * @type {string}
     * @memberof ProjectRole
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRole
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRole
     */
    description: string;
    /**
     * 
     * @type {ProjectRoleColor}
     * @memberof ProjectRole
     */
    color: ProjectRoleColor;
    /**
     * 
     * @type {Array<Permission>}
     * @memberof ProjectRole
     */
    permissions: Array<Permission>;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectRole
     */
    _default: boolean;
}

/**
 * Check if a given object implements the ProjectRole interface.
 */
// @ts-ignore
export function instanceOfProjectRole(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "color" in value;
    isInstance = isInstance && "permissions" in value;
    isInstance = isInstance && "_default" in value;

    return isInstance;
}

// @ts-ignore
export function ProjectRoleFromJSON(json: any): ProjectRole {
    return ProjectRoleFromJSONTyped(json, false);
}

// @ts-ignore
export function ProjectRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectRole {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'color': ProjectRoleColorFromJSON(json['color']),
        'permissions': ((json['permissions'] as Array<any>).map(PermissionFromJSON)),
        '_default': json['default'],
    };
}

// @ts-ignore
export function ProjectRoleToJSON(value?: ProjectRole | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'color': ProjectRoleColorToJSON(value.color),
        'permissions': ((value.permissions as Array<any>).map(PermissionToJSON)),
        'default': value._default,
    };
}

