/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectAPIKeyPermissionDetailsEndpointsInner } from './ProjectAPIKeyPermissionDetailsEndpointsInner';
import {
    ProjectAPIKeyPermissionDetailsEndpointsInnerFromJSON,
    ProjectAPIKeyPermissionDetailsEndpointsInnerFromJSONTyped,
    ProjectAPIKeyPermissionDetailsEndpointsInnerToJSON,
} from './ProjectAPIKeyPermissionDetailsEndpointsInner';
import type { ProjectAPIKeyPermissionID } from './ProjectAPIKeyPermissionID';
import {
    ProjectAPIKeyPermissionIDFromJSON,
    ProjectAPIKeyPermissionIDFromJSONTyped,
    ProjectAPIKeyPermissionIDToJSON,
} from './ProjectAPIKeyPermissionID';

/**
 * 
 * @export
 * @interface ProjectAPIKeyPermissionDetails
 */
export interface ProjectAPIKeyPermissionDetails {
    /**
     * 
     * @type {ProjectAPIKeyPermissionID}
     * @memberof ProjectAPIKeyPermissionDetails
     */
    id: ProjectAPIKeyPermissionID;
    /**
     * 
     * @type {Array<ProjectAPIKeyPermissionDetailsEndpointsInner>}
     * @memberof ProjectAPIKeyPermissionDetails
     */
    endpoints: Array<ProjectAPIKeyPermissionDetailsEndpointsInner>;
}

/**
 * Check if a given object implements the ProjectAPIKeyPermissionDetails interface.
 */
// @ts-ignore
export function instanceOfProjectAPIKeyPermissionDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "endpoints" in value;

    return isInstance;
}

// @ts-ignore
export function ProjectAPIKeyPermissionDetailsFromJSON(json: any): ProjectAPIKeyPermissionDetails {
    return ProjectAPIKeyPermissionDetailsFromJSONTyped(json, false);
}

// @ts-ignore
export function ProjectAPIKeyPermissionDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectAPIKeyPermissionDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': ProjectAPIKeyPermissionIDFromJSON(json['id']),
        'endpoints': ((json['endpoints'] as Array<any>).map(ProjectAPIKeyPermissionDetailsEndpointsInnerFromJSON)),
    };
}

// @ts-ignore
export function ProjectAPIKeyPermissionDetailsToJSON(value?: ProjectAPIKeyPermissionDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': ProjectAPIKeyPermissionIDToJSON(value.id),
        'endpoints': ((value.endpoints as Array<any>).map(ProjectAPIKeyPermissionDetailsEndpointsInnerToJSON)),
    };
}

