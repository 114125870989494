import { getEntries } from '@merchant/shared/utils'
import { initial, isEmpty, isEqual } from 'lodash-es'
import type { FormInstance } from '@merchant/ui-kit/ant-design'
import type { MessageDescriptor } from 'react-intl'
import type { APIKey } from '~api'
import type { EditApiKeyFormData } from '~features/IntegrationsPage/views/Integrations/components/EditApiKeyModal/types'
import type { FormValues } from '~features/MerchantPage/components/TeamManagement/views/EditRoles/types'
import { FormNames } from '~constants/form'
import { ModalNames } from '~constants/modal'
import { FORM_LIST_NAME as ADD_MEMBERS_FORM_LIST_NAME } from '~features/MerchantPage/components/TeamManagement/views/AddMembers/const'
import { globalLang } from '~globalLang'

function resolve(value: unknown | (() => unknown)) {
    return typeof value === 'function' ? value() : value
}

const checker = <T = string>(
    values: object,
    fields: string[] | undefined,
    predicate: (value: T) => boolean = value => !!value
) => {
    return Object.entries(values).some(([key, value]) => fields?.includes(key) && predicate(value))
}

type GetDirtyCheckResult = (payload: {
    form?: FormInstance
    customFields?: {
        initial: unknown | (() => unknown)
        current: unknown | (() => unknown)
    }
}) => {
    isDirty: boolean
    dirtyPromptMessageDescriptor: MessageDescriptor
}

type FormConfig = Partial<
    Record<
        ModalNames | FormNames,
        {
            fields?: string[]
            getDirtyCheckResult: GetDirtyCheckResult
        }
    >
>

export const formConfig: FormConfig = {
    // convert
    [ModalNames.convertInput]: {
        fields: ['send', 'receive'],
        getDirtyCheckResult({ form }) {
            const values = form?.getFieldsValue()

            return {
                isDirty: checker<{ value: string }>(values, this.fields, v => !!v.value),
                dirtyPromptMessageDescriptor: globalLang.unsavedChangesUniversal,
            }
        },
    },
    // crypto withdrawal
    [ModalNames.withdrawalCryptoInput]: {
        fields: ['network', 'address', 'amount', 'currency', 'note', 'address', 'addressTag'] as const,
        getDirtyCheckResult({ form }) {
            this.fields
            const formValues = form?.getFieldsValue()
            const values = {
                ...formValues,
                amount: typeof formValues.amount === 'object' ? Object.values(formValues.amount)[0] : formValues.amount,
            }

            return {
                isDirty: checker(values, this.fields),
                dirtyPromptMessageDescriptor: globalLang.unsavedChangesUniversal,
            }
        },
    },
    [ModalNames.withdrawalCryptoSummary]: {
        getDirtyCheckResult() {
            return {
                isDirty: true,
                dirtyPromptMessageDescriptor: globalLang.unsavedChangesUniversal,
            }
        },
    },
    [ModalNames.withdrawalCryptoConfirmation]: {
        getDirtyCheckResult() {
            return {
                isDirty: true,
                dirtyPromptMessageDescriptor: globalLang.unsavedChangesUniversal,
            }
        },
    },
    // fiat withdrawals
    [ModalNames.withdrawalFiatInput]: {
        fields: ['requisites', 'amount'],
        getDirtyCheckResult({ form }) {
            const values = form?.getFieldsValue()

            return {
                isDirty: checker(values, this.fields),
                dirtyPromptMessageDescriptor: globalLang.unsavedChangesUniversal,
            }
        },
    },
    [ModalNames.withdrawalFiatSummary]: {
        getDirtyCheckResult() {
            return {
                isDirty: true,
                dirtyPromptMessageDescriptor: globalLang.unsavedChangesUniversal,
            }
        },
    },
    [ModalNames.withdrawalFiatConfirmation]: {
        getDirtyCheckResult() {
            return {
                isDirty: true,
                dirtyPromptMessageDescriptor: globalLang.unsavedChangesUniversal,
            }
        },
    },

    // integrations - api key
    [ModalNames.createApiKeyForm]: {
        getDirtyCheckResult({ customFields: { current, initial } = {} }) {
            const currentValues = resolve(current)
            const initialValues = resolve(initial)

            return {
                isDirty: !isEqual(currentValues, initialValues),
                dirtyPromptMessageDescriptor: globalLang.unsavedChangesUniversal,
            }
        },
    },
    [ModalNames.createApiKeyConfirmation]: {
        getDirtyCheckResult() {
            return {
                isDirty: true,
                dirtyPromptMessageDescriptor: globalLang.unsavedChangesUniversal,
            }
        },
    },
    [ModalNames.createApiKeyCreated]: {
        getDirtyCheckResult() {
            return {
                isDirty: true,
                dirtyPromptMessageDescriptor: globalLang.unsavedChangesUniversal,
            }
        },
    },
    [ModalNames.edit2Fa]: {
        getDirtyCheckResult({ customFields: { current, initial } = {} }) {
            const currentValues: EditApiKeyFormData = resolve(current)
            const initialValues: APIKey = resolve(initial)

            return {
                isDirty: getEntries(currentValues).some(
                    ([key, value]) => key in initialValues && !isEqual(value, initialValues[key])
                ),
                dirtyPromptMessageDescriptor: globalLang.unsavedChangesUniversal,
            }
        },
    },
    [ModalNames.toggle2Fa]: {
        fields: ['totpCode', 'totpBackup'],
        getDirtyCheckResult({ form }) {
            const values = form?.getFieldsValue()

            return {
                isDirty: checker(values, this.fields),
                dirtyPromptMessageDescriptor: globalLang.unsavedChangesUniversal,
            }
        },
    },
    [ModalNames.delete2Fa]: {
        fields: ['totpCode', 'totpBackup'],
        getDirtyCheckResult({ form }) {
            const values = form?.getFieldsValue()

            return {
                isDirty: checker(values, this.fields),
                dirtyPromptMessageDescriptor: globalLang.unsavedChangesUniversal,
            }
        },
    },

    // integrations - callback url
    [ModalNames.callbackUrlCreate]: {
        fields: ['url', 'totpCode', 'totpBackup'],
        getDirtyCheckResult({ form }) {
            const values = form?.getFieldsValue()

            return {
                isDirty: checker(values, this.fields),
                dirtyPromptMessageDescriptor: globalLang.unsavedChangesUniversal,
            }
        },
    },
    [ModalNames.callbackUrlRegenerate]: {
        fields: ['totpCode', 'totpBackup'],
        getDirtyCheckResult({ form }) {
            const values = form?.getFieldsValue()

            return {
                isDirty: checker(values, this.fields),
                dirtyPromptMessageDescriptor: globalLang.unsavedChangesUniversal,
            }
        },
    },
    [ModalNames.callbackUrlDelete]: {
        fields: ['totpCode', 'totpBackup'],
        getDirtyCheckResult({ form }) {
            const values = form?.getFieldsValue()

            return {
                isDirty: checker(values, this.fields),
                dirtyPromptMessageDescriptor: globalLang.unsavedChangesUniversal,
            }
        },
    },
    // settings
    [ModalNames.changePassword]: {
        fields: ['oldPassword', 'newPassword', 'newPasswordConfirm'],
        getDirtyCheckResult({ form }) {
            const values = form?.getFieldsValue()

            return {
                isDirty: checker(values, this.fields),
                dirtyPromptMessageDescriptor: globalLang.unsavedChangesUniversal,
            }
        },
    },
    [ModalNames.whitelistAddInput]: {
        fields: ['name', 'address', 'network'],
        getDirtyCheckResult({ form }) {
            const values = form?.getFieldsValue()

            return {
                isDirty: checker(values, this.fields),
                dirtyPromptMessageDescriptor: globalLang.unsavedChangesUniversal,
            }
        },
    },
    [ModalNames.whitelistAddConfirmation]: {
        getDirtyCheckResult() {
            return {
                isDirty: true,
                dirtyPromptMessageDescriptor: globalLang.unsavedChangesUniversal,
            }
        },
    },
    [ModalNames.whitelistEnableConfirmation]: {
        fields: ['verificationCode', 'totpCode', 'totpBackup'],
        getDirtyCheckResult({ form }) {
            const values = form?.getFieldsValue()

            return {
                isDirty: checker(values, this.fields),
                dirtyPromptMessageDescriptor: globalLang.unsavedChangesUniversal,
            }
        },
    },
    [ModalNames.whitelistDelete]: {
        fields: ['verificationCode', 'totpCode', 'totpBackup'],
        getDirtyCheckResult({ form }) {
            const values = form?.getFieldsValue()

            return {
                isDirty: checker(values, this.fields),
                dirtyPromptMessageDescriptor: globalLang.unsavedChangesUniversal,
            }
        },
    },
    [ModalNames.whitelistDisable]: {
        fields: ['verificationCode', 'totpCode', 'totpBackup'],
        getDirtyCheckResult({ form }) {
            const values = form?.getFieldsValue()

            return {
                isDirty: checker(values, this.fields),
                dirtyPromptMessageDescriptor: globalLang.unsavedChangesUniversal,
            }
        },
    },
    [ModalNames.add2Fa]: {
        fields: ['totpCode', 'verificationCode'],
        getDirtyCheckResult({ form }) {
            const values = form?.getFieldsValue()

            return {
                isDirty: checker(values, this.fields, v => !!v),
                dirtyPromptMessageDescriptor: globalLang.unsavedChangesUniversal,
            }
        },
    },
    [ModalNames.add2FaConfirmation]: {
        getDirtyCheckResult() {
            return {
                isDirty: true,
                dirtyPromptMessageDescriptor: globalLang.unsavedChangesUniversal,
            }
        },
    },
    [ModalNames.add2FaWriteDown]: {
        getDirtyCheckResult() {
            return {
                isDirty: true,
                dirtyPromptMessageDescriptor: globalLang.unsavedChangesUniversal,
            }
        },
    },

    // merchant settings
    [ModalNames.merchantCurrenciesSettings]: {
        getDirtyCheckResult({ customFields }) {
            return {
                isDirty: !isEqual(resolve(customFields?.current), customFields?.initial),
                dirtyPromptMessageDescriptor: globalLang.unsavedChangesUniversal,
            }
        },
    },

    // team members
    [FormNames.editProjectRoles]: {
        getDirtyCheckResult: ({ customFields }) => {
            const initialValues: FormValues = resolve(customFields?.initial)
            const currentValues: FormValues = resolve(customFields?.current)
            const isDirty = Object.entries(initialValues).some(
                ([key, { permissions }]) => !isEqual(permissions, currentValues[key]?.permissions)
            )

            return {
                isDirty,
                dirtyPromptMessageDescriptor: globalLang.unsavedChangesUniversal,
            }
        },
    },
    [FormNames.addTeamMembers]: {
        getDirtyCheckResult: ({ form }) => {
            const values = form?.getFieldsValue()

            return {
                isDirty: values?.[ADD_MEMBERS_FORM_LIST_NAME]?.some((member: object) =>
                    Object.values(member).some(value => !isEmpty(value))
                ),
                dirtyPromptMessageDescriptor: globalLang.unsavedChangesUniversal,
            }
        },
    },
    [ModalNames.editTeamMember]: {
        getDirtyCheckResult: ({ customFields }) => ({
            isDirty: !isEqual(resolve(customFields?.current), resolve(customFields?.initial)),
            dirtyPromptMessageDescriptor: globalLang.unsavedChangesUniversal,
        }),
    },
    [ModalNames.teamMemberConfirmationDefault]: {
        getDirtyCheckResult: () => ({
            isDirty: true,
            dirtyPromptMessageDescriptor: globalLang.unsavedChangesUniversal,
        }),
    },
    [ModalNames.deleteTeamMemberConfirmation]: {
        getDirtyCheckResult: ({ form }) => ({
            isDirty: checker(form?.getFieldsValue(), ['totpCode', 'totpBackup']),
            dirtyPromptMessageDescriptor: globalLang.unsavedChangesUniversal,
        }),
    },

    // // payment constructor
    // [FormNames.paymentConstructor]: {
    //     fields: [
    //         'nominalAmount',
    //         'description',
    //         'customerEmail',
    //         'customerId',
    //         'failureRedirectUrl',
    //         'successRedirectUrl',
    //         'title',
    //         'orderId',
    //     ],
    //     getDirtyCheckResult({ form }) {
    //         const values = form?.getFieldsValue()

    //         return {
    //             isDirty: checker(values, this.fields, v => !!v),
    //             dirtyPromptMessageDescriptor: globalLang.unsavedChangesUniversal,
    //         }
    //     },
    // },
}
