import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    addressCreatedTitle: {
        id: 'static-address-constructor.modal.address_created',
        defaultMessage: 'The address has been successfully generated',
    },
    addressExistsTitle: {
        id: 'static-address-constructor.modal.address_exists',
        defaultMessage: 'This static address already exists',
    },
    createdAt: {
        id: 'static-address-constructor.modal.created_at',
        defaultMessage: 'Created at {createdAt}',
    },
    updatedAt: {
        id: 'static-address-constructor.modal.updated_at',
        defaultMessage: 'Updated at',
    },
    address: {
        id: 'static-address-constructor.modal.address',
        defaultMessage: 'Address',
    },
    destinationTag: {
        id: 'static-address-constructor.modal.destination_tag',
        defaultMessage: 'Destination tag',
    },
    currency: {
        id: 'static-address-constructor.modal.currency',
        defaultMessage: 'Currency',
    },
    network: {
        id: 'static-address-constructor.modal.network',
        defaultMessage: 'Network',
    },
    feePayer: {
        id: 'static-address-constructor.modal.fee_payer',
        defaultMessage: 'Fee is paid by',
    },
    currentMinimumDeposit: {
        id: 'static-address-constructor.modal.current_minimum_deposit',
        defaultMessage: 'Current minimum deposit',
    },
    convertTo: {
        id: 'static-address-constructor.modal.convert_to',
        defaultMessage: 'Convert to',
    },
    customerId: {
        id: 'static-address-constructor.modal.customer_id',
        defaultMessage: 'Customer ID',
    },
    customerEmail: {
        id: 'static-address-constructor.modal.customer_email',
        defaultMessage: 'Customer email',
    },
    showAllAddresses: {
        id: 'static-address-constructor.modal.button.show_all_addresses',
        defaultMessage: 'Show all static addresses',
    },
    ok: {
        id: 'static-address-constructor.modal.button.ok',
        defaultMessage: 'OK',
    },
    importantNotes: {
        id: 'static-address-constructor.modal.important_notes',
        defaultMessage: 'Important notes:',
    },
    networkNote: {
        id: 'static-address-constructor.modal.network_note',
        defaultMessage: 'Only send {currency} to this address from {network} <b>network</b> ({protocol})',
    },
    convertToNote: {
        id: 'static-address-constructor.modal.convert_to_note',
        defaultMessage:
            "Right now you don't directly accumulate {currencyFromCode} {currencyFromName} in your account. It means that if you send {currencyFromCode} to this address it will be <b>automatically</b> <highlight>converted to {currencyToCode} {currencyToName}</highlight>",
    },
    destinationTagNote: {
        id: 'static-address-constructor.modal.destination_tag_note',
        defaultMessage:
            'Please make sure that you <b>double-check the</b> {tagName} before entering it, as any errors may result in a failed transaction or lost funds',
    },
    unrecoverableTransferNote: {
        id: 'static-address-constructor.modal.unrecoverable_transfer_note',
        defaultMessage: 'Transfer of another currency is unrecoverable',
    },
    minimumPayinAmountTooltip: {
        id: 'static-address-constructor.modal.minimum_payin_amount_tooltip',
        defaultMessage:
            "This value is valid at the moment but may change over time. It depends on network conditions, fees, and the merchant's settings. If you have any questions, please contact your personal account manager or <supportLink>support team</supportLink>",
    },
    defaultFeesPayer: {
        id: 'static-address-constructor.modal.default_fees_payer',
        defaultMessage: 'Default',
    },
})
