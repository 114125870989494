/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ProjectRoleColor {
    Red = 'red',
    Pink = 'pink',
    Purple = 'purple',
    Blue = 'blue',
    LightBlue = 'light-blue',
    Cyan = 'cyan',
    Green = 'green',
    LightGreen = 'light-green',
    Yellow = 'yellow',
    Orange = 'orange',
    DeepOrange = 'deep-orange',
    Brown = 'brown',
    Grey = 'grey'
}


// @ts-ignore
export function ProjectRoleColorFromJSON(json: any): ProjectRoleColor {
    return ProjectRoleColorFromJSONTyped(json, false);
}

// @ts-ignore
export function ProjectRoleColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectRoleColor {
    return json as ProjectRoleColor;
}

// @ts-ignore
export function ProjectRoleColorToJSON(value?: ProjectRoleColor | null): any {
    return value as any;
}

