import { Flex, Typography, theme } from '@merchant/ui-kit/ant-design'
import { useEffect, type ReactNode } from 'react'
import { defaultIcons, typeIconColorMap } from './const'
import { AppSvg } from '~components'

const { Text, Title } = Typography
const { useToken } = theme

let counter = 1

export interface Props {
    type: 'error' | 'success' | 'warning'
    icon?: ReactNode
    title: ReactNode
    description?: ReactNode
    extra?: ReactNode
    dataMerchant?: string
}

export function NotificationMessage({ type, description, icon, title, extra, dataMerchant }: Props) {
    const { token } = useToken()
    const iconName = defaultIcons[type]
    const defaultIconColor = token[typeIconColorMap[type]]

    useEffect(() => {
        counter += 1

        return () => {
            counter -= 1
        }
    }, [])

    return (
        <Flex
            gap={8}
            vertical
            style={{ width: '100%' }}
            align="center"
            data-merchant={dataMerchant ? `${dataMerchant}-${counter}` : undefined}
        >
            {icon ?? <AppSvg name={iconName} size={48} color={defaultIconColor} />}
            <Title level={4} data-merchant={`${dataMerchant}-title`}>
                {title}
            </Title>
            {description && (
                <Text type="secondary" data-merchant={`${dataMerchant}-description`} style={{ textAlign: 'center' }}>
                    {description}
                </Text>
            )}
            {extra}
        </Flex>
    )
}
