import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    readMore: {
        id: 'shared.payment-description.typography.read_more',
        defaultMessage: 'Read more',
    },
    deposit: {
        id: 'shared.payment-description.typography.deposit',
        defaultMessage: 'Deposit {amount} {currency}',
    },
})
