/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Network } from './Network';
import {
    NetworkFromJSON,
    NetworkFromJSONTyped,
    NetworkToJSON,
} from './Network';

/**
 * 
 * @export
 * @interface Currency
 */
export interface Currency {
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    sign?: string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    externalCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Currency
     */
    crypto: boolean;
    /**
     * 
     * @type {number}
     * @memberof Currency
     */
    precision: number;
    /**
     * 
     * @type {number}
     * @memberof Currency
     */
    priority?: number;
    /**
     * 
     * @type {number}
     * @memberof Currency
     */
    equivalentPriority?: number;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    addressTagName?: string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    cryptoExplorer?: string;
    /**
     * 
     * @type {Array<Network>}
     * @memberof Currency
     */
    networks: Array<Network>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Currency
     */
    rateableTo: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Currency
     */
    swappableTo: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof Currency
     */
    payinEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Currency
     */
    payoutEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    minimumSwapAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    minimumPayinAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    minimumDirectPayinAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    brokerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    bankCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    apiCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Currency
     */
    enabled: boolean;
}

/**
 * Check if a given object implements the Currency interface.
 */
// @ts-ignore
export function instanceOfCurrency(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "crypto" in value;
    isInstance = isInstance && "precision" in value;
    isInstance = isInstance && "networks" in value;
    isInstance = isInstance && "rateableTo" in value;
    isInstance = isInstance && "swappableTo" in value;
    isInstance = isInstance && "payinEnabled" in value;
    isInstance = isInstance && "payoutEnabled" in value;
    isInstance = isInstance && "enabled" in value;

    return isInstance;
}

// @ts-ignore
export function CurrencyFromJSON(json: any): Currency {
    return CurrencyFromJSONTyped(json, false);
}

// @ts-ignore
export function CurrencyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Currency {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'code': json['code'],
        'sign': !exists(json, 'sign') ? undefined : json['sign'],
        'externalCode': !exists(json, 'external_code') ? undefined : json['external_code'],
        'crypto': json['crypto'],
        'precision': json['precision'],
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
        'equivalentPriority': !exists(json, 'equivalent_priority') ? undefined : json['equivalent_priority'],
        'addressTagName': !exists(json, 'address_tag_name') ? undefined : json['address_tag_name'],
        'cryptoExplorer': !exists(json, 'crypto_explorer') ? undefined : json['crypto_explorer'],
        'networks': ((json['networks'] as Array<any>).map(NetworkFromJSON)),
        'rateableTo': json['rateable_to'],
        'swappableTo': json['swappable_to'],
        'payinEnabled': json['payin_enabled'],
        'payoutEnabled': json['payout_enabled'],
        'minimumSwapAmount': !exists(json, 'minimum_swap_amount') ? undefined : json['minimum_swap_amount'],
        'minimumPayinAmount': !exists(json, 'minimum_payin_amount') ? undefined : json['minimum_payin_amount'],
        'minimumDirectPayinAmount': !exists(json, 'minimum_direct_payin_amount') ? undefined : json['minimum_direct_payin_amount'],
        'brokerCode': !exists(json, 'broker_code') ? undefined : json['broker_code'],
        'bankCode': !exists(json, 'bank_code') ? undefined : json['bank_code'],
        'apiCode': !exists(json, 'api_code') ? undefined : json['api_code'],
        'enabled': json['enabled'],
    };
}

// @ts-ignore
export function CurrencyToJSON(value?: Currency | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'full_name': value.fullName,
        'code': value.code,
        'sign': value.sign,
        'external_code': value.externalCode,
        'crypto': value.crypto,
        'precision': value.precision,
        'priority': value.priority,
        'equivalent_priority': value.equivalentPriority,
        'address_tag_name': value.addressTagName,
        'crypto_explorer': value.cryptoExplorer,
        'networks': ((value.networks as Array<any>).map(NetworkToJSON)),
        'rateable_to': value.rateableTo,
        'swappable_to': value.swappableTo,
        'payin_enabled': value.payinEnabled,
        'payout_enabled': value.payoutEnabled,
        'minimum_swap_amount': value.minimumSwapAmount,
        'minimum_payin_amount': value.minimumPayinAmount,
        'minimum_direct_payin_amount': value.minimumDirectPayinAmount,
        'broker_code': value.brokerCode,
        'bank_code': value.bankCode,
        'api_code': value.apiCode,
        'enabled': value.enabled,
    };
}

