/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectThumb } from './ProjectThumb';
import {
    ProjectThumbFromJSON,
    ProjectThumbFromJSONTyped,
    ProjectThumbToJSON,
} from './ProjectThumb';

/**
 * 
 * @export
 * @interface ListProjectsResponse
 */
export interface ListProjectsResponse {
    /**
     * 
     * @type {Array<ProjectThumb>}
     * @memberof ListProjectsResponse
     */
    thumbs: Array<ProjectThumb>;
    /**
     * 
     * @type {string}
     * @memberof ListProjectsResponse
     * @deprecated
     */
    cursor?: string;
}

/**
 * Check if a given object implements the ListProjectsResponse interface.
 */
// @ts-ignore
export function instanceOfListProjectsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "thumbs" in value;

    return isInstance;
}

// @ts-ignore
export function ListProjectsResponseFromJSON(json: any): ListProjectsResponse {
    return ListProjectsResponseFromJSONTyped(json, false);
}

// @ts-ignore
export function ListProjectsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListProjectsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'thumbs': ((json['thumbs'] as Array<any>).map(ProjectThumbFromJSON)),
        'cursor': !exists(json, 'cursor') ? undefined : json['cursor'],
    };
}

// @ts-ignore
export function ListProjectsResponseToJSON(value?: ListProjectsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'thumbs': ((value.thumbs as Array<any>).map(ProjectThumbToJSON)),
        'cursor': value.cursor,
    };
}

