import { useFormValidation, usePromise } from '@merchant/shared/hooks'
import { Button, Form } from '@merchant/ui-kit/ant-design'
import { FormattedMessage, useIntl } from 'react-intl'
import { lang as commonLang } from '../../lang'
import { newTwoFaFormDataItems, initValues } from './const'
import { processErrorResponse } from './utils'
import type { NewTwoFaFormData } from './types'
import type { ValidateTOTPOperationRequest, ValidateTOTPRequest } from '~api/instances/cabinet-api'
import { authApi } from '~api'
import { ModalNames } from '~constants/modal'
import { TwoFaConfirmationItem } from '~features'
import { useRegisterDirtyFormChecker } from '~hooks'

interface Props {
    onSuccess: () => void
    id: ValidateTOTPOperationRequest['id']
}

export function NewTwoFaCode({ onSuccess, id }: Props) {
    const intl = useIntl()
    const [form] = Form.useForm<NewTwoFaFormData>()
    const { onBlur, onFinishFailed, validationMode } = useFormValidation(Object.values(newTwoFaFormDataItems))
    useRegisterDirtyFormChecker(ModalNames.add2Fa, form)

    const { send, isLoading } = usePromise(
        async (values: ValidateTOTPRequest) => {
            return await authApi.validateTOTP({
                id,
                validateTOTPRequest: values,
            })
        },
        {
            onSuccess,
            showErrorNotification: false,
            onError: error => {
                form.setFields([
                    {
                        name: newTwoFaFormDataItems.totpCode,
                        errors: processErrorResponse(error, intl),
                    },
                ])
            },
        }
    )

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                size="large"
                initialValues={initValues}
                onFinish={send}
                requiredMark={false}
                onFinishFailed={onFinishFailed}
            >
                <TwoFaConfirmationItem
                    dataMerchant="add2fa-new-confirmation-item"
                    onBlur={onBlur('totpCode')}
                    validateTrigger={validationMode.totpCode}
                    isBackupCodeAllowed={false}
                />
                <Form.Item noStyle>
                    <Button
                        data-merchant="add2fa-new-continue-button"
                        htmlType="submit"
                        block
                        type="primary"
                        loading={isLoading}
                    >
                        <FormattedMessage {...commonLang.continue} />
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}
