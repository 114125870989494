import { assets } from '@merchant/shared/assets/nameMap'
import { InlineLinkButton, AppSvg, ModalResponsive } from '@merchant/shared/components'
import { useBreakpoint } from '@merchant/shared/hooks'
import { isKeyOfObject, isValueOfObject } from '@merchant/shared/utils'
import { Col, Row, Space, Skeleton, Spin } from '@merchant/ui-kit/ant-design'
import { Suspense, useCallback, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSearchParams } from 'react-router-dom'
import { useBoolean } from 'usehooks-ts'
import { actionPermissionMap, getModalProps, paramsToDeleteOnModalClose } from './const'
import { Content } from './Content'
import { ActionModalContextProvider } from './context'
import styles from './style.module.css'
import { normalizeQueryAction } from './utils'
import type { ReactNode } from 'react'
import type { MessageDescriptor } from 'react-intl'
import { useCacheInvalidation } from '~api/swr/hooks'
import { apiRestKeys } from '~api/swr/keys'
import { QueryParams } from '~constants/routes'
import { useUserPermissionContext } from '~contexts'
import { withDirtyCheckingContextProvider } from '~hoc/withDirtyCheckingContextProvider'
import { useDirtyFormCheckerControls } from '~hooks/useDirtyFormCheckerControls'
import { Actions } from '~types/actionModal'

function Component() {
    const { checkUserPermission } = useUserPermissionContext()
    const [searchParams, setSearchParams] = useSearchParams()
    const [modalTitleProps, setModalTitleProps] = useState<
        | {
              text: MessageDescriptor
              dataMerchant: string
          }
        | null
        | false
    >(null)
    const { value: isOpen, setFalse: close, setValue: setIsOpen } = useBoolean(false)
    const { value: showGoBack, setValue: setShowGoBack } = useBoolean(false)
    const [onBackClickHandler, setOnBackClickHandler] = useState<(() => void) | undefined>(undefined)
    const { value: shouldRevalidateDependentTables, setValue: setShouldRevalidateDependentTables } = useBoolean(false)
    const { invalidate } = useCacheInvalidation()
    const breakpoints = useBreakpoint()

    const resetSearchParams = () => {
        paramsToDeleteOnModalClose.forEach(param => searchParams.delete(param))
        setSearchParams(searchParams)
    }

    const afterClose = () => {
        resetSearchParams()
        setModalTitleProps(null)

        if (shouldRevalidateDependentTables) {
            invalidate(apiRestKeys.getBalances)
            invalidate(apiRestKeys.getTransactions)
        }
    }

    const action = searchParams.get(QueryParams.action)

    useEffect(() => {
        if (isValueOfObject(action, Actions) && !checkUserPermission(actionPermissionMap[action])) {
            return resetSearchParams()
        }
        normalizeQueryAction(action, searchParams, setSearchParams)
        setIsOpen(Boolean(action))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action])

    const { handleCancel } = useDirtyFormCheckerControls(close)

    const setOnBackClickHandlerCallback = useCallback(
        (cb: (() => void) | undefined) => setOnBackClickHandler(() => cb),
        []
    )

    let modalTitleContent: ReactNode | null = (
        <Skeleton title={{ width: '70%', style: { height: 16, margin: '4px 0' } }} paragraph={false} />
    )
    if (modalTitleProps) {
        modalTitleContent = (
            <Space align="center" style={{ marginRight: 28 }} styles={{ item: { display: 'inline-flex' } }} size={4}>
                {showGoBack && (
                    <InlineLinkButton
                        data-merchant="action-modal-go-back-button"
                        onClick={onBackClickHandler}
                        icon={<AppSvg className={styles.goBackButton} name={assets.arrowNarrowLeft} size={20} />}
                    />
                )}
                <span data-merchant={modalTitleProps.dataMerchant}>
                    <FormattedMessage {...modalTitleProps.text} />
                </span>
            </Space>
        )
    } else if (modalTitleProps === false) {
        modalTitleContent = null
    }

    return (
        <ModalResponsive
            open={isOpen}
            title={modalTitleContent}
            footer={null}
            onCancel={handleCancel}
            afterClose={afterClose}
            width={520}
            {...(isKeyOfObject(action, getModalProps) ? getModalProps(action, breakpoints) : {})}
            data-merchant={`action-modal-${action}`}
            forceRender
        >
            <Suspense
                fallback={
                    <Row justify="center">
                        <Col>
                            <Spin size="large" data-merchant="action-modal-loading-spin" />
                        </Col>
                    </Row>
                }
            >
                <ActionModalContextProvider
                    value={{
                        closeModal: close,
                        setShouldRevalidateDependentTables,
                        setTitle: setModalTitleProps,
                        setOnBackClickHandler: setOnBackClickHandlerCallback,
                        setShowGoBack,
                    }}
                >
                    <Content />
                </ActionModalContextProvider>
            </Suspense>
        </ModalResponsive>
    )
}

export const ActionModal = withDirtyCheckingContextProvider(Component)
