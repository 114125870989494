/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectAddress } from './ProjectAddress';
import {
    ProjectAddressFromJSON,
    ProjectAddressFromJSONTyped,
    ProjectAddressToJSON,
} from './ProjectAddress';

/**
 * 
 * @export
 * @interface ListProjectAddresses200Response
 */
export interface ListProjectAddresses200Response {
    /**
     * 
     * @type {string}
     * @memberof ListProjectAddresses200Response
     */
    cursorNext?: string;
    /**
     * 
     * @type {string}
     * @memberof ListProjectAddresses200Response
     */
    cursorPrev?: string;
    /**
     * 
     * @type {Array<ProjectAddress>}
     * @memberof ListProjectAddresses200Response
     */
    addresses: Array<ProjectAddress>;
}

/**
 * Check if a given object implements the ListProjectAddresses200Response interface.
 */
// @ts-ignore
export function instanceOfListProjectAddresses200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "addresses" in value;

    return isInstance;
}

// @ts-ignore
export function ListProjectAddresses200ResponseFromJSON(json: any): ListProjectAddresses200Response {
    return ListProjectAddresses200ResponseFromJSONTyped(json, false);
}

// @ts-ignore
export function ListProjectAddresses200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListProjectAddresses200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cursorNext': !exists(json, 'cursor_next') ? undefined : json['cursor_next'],
        'cursorPrev': !exists(json, 'cursor_prev') ? undefined : json['cursor_prev'],
        'addresses': ((json['addresses'] as Array<any>).map(ProjectAddressFromJSON)),
    };
}

// @ts-ignore
export function ListProjectAddresses200ResponseToJSON(value?: ListProjectAddresses200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cursor_next': value.cursorNext,
        'cursor_prev': value.cursorPrev,
        'addresses': ((value.addresses as Array<any>).map(ProjectAddressToJSON)),
    };
}

