import { ScrollToTop } from '@merchant/shared/components'
import { useBreakpoint } from '@merchant/shared/hooks'
import { Flex, Spin } from '@merchant/ui-kit/ant-design'
import { useBoolean } from 'usehooks-ts'
import { PageHeader } from './components'
import { pagePermissionMap } from './const'
import { MerchantTeamShowOptions } from './types'
import { getProjectBlocklistPermittedPages as getTeamManagementPermittedPages } from './utils'
import { AddMembers, DataView, EditRoles } from './views'
import type { ProjectTeamMember } from '~api/instances/cabinet-api'
import { useProjectBlocklist, useProjectTeamMembers } from '~api'
import { PageContentContainer } from '~components'
import { QueryParams } from '~constants/routes'
import { useUserPermissionContext } from '~contexts'
import { PlaceholderViewWrapper } from '~features'
import { withDirtyCheckingContextProvider } from '~hoc/withDirtyCheckingContextProvider'
import { useURLSearchNavigation } from '~hooks'

function Component() {
    const { value: isSuspensionModalOpen, setTrue: openSuspensionModal, setFalse: closeSuspensionModal } = useBoolean()
    const { checkUserPermission } = useUserPermissionContext()
    const { mutate: mutateTeamManagement } = useProjectTeamMembers()
    const { data: projectBlocklist, isLoading: isProjectBlocklistLoading } = useProjectBlocklist()
    const permittedPages = getTeamManagementPermittedPages(
        checkUserPermission,
        isProjectBlocklistLoading,
        projectBlocklist
    )
    const breakpoints = useBreakpoint()
    const { changePage, page, isPending } = useURLSearchNavigation({
        key: QueryParams.show,
        pages: permittedPages,
    })

    const resolveBlocklist = (cb?: () => void) => {
        if (projectBlocklist?.blocked) {
            openSuspensionModal()
        } else {
            cb?.()
        }

        return projectBlocklist?.blocked ?? true
    }

    const handleAddMembersSuccess = (newMembers: ProjectTeamMember[]) => {
        mutateTeamManagement((prev = []) => [...prev, ...newMembers], {
            revalidate: false,
        })
    }

    const onGoBack = () => changePage(MerchantTeamShowOptions.management)

    const views: Record<MerchantTeamShowOptions, JSX.Element> = {
        [MerchantTeamShowOptions.management]: <DataView resolveBlocklist={resolveBlocklist} />,
        [MerchantTeamShowOptions.addMembers]: <AddMembers onSuccess={handleAddMembersSuccess} onGoBack={onGoBack} />,
        [MerchantTeamShowOptions.editRoles]: <EditRoles onGoBack={onGoBack} />,
    }

    const view = views[page]

    return (
        <>
            <ScrollToTop updateOn={page} />
            <PageHeader
                page={page}
                isSuspensionModalOpen={isSuspensionModalOpen}
                closeSuspensionModal={closeSuspensionModal}
                projectBlocklist={projectBlocklist}
                onGoBackClick={() => changePage(MerchantTeamShowOptions.management)}
            />
            <PlaceholderViewWrapper dataMerchantPrefix="team-management">
                <Flex vertical gap={16}>
                    {checkUserPermission(pagePermissionMap[page]) && (
                        <PageContentContainer
                            styles={{
                                body: {
                                    padding: !breakpoints.md ? 12 : '20px 24px',
                                },
                            }}
                            style={{ marginTop: 0 }}
                        >
                            <Spin spinning={isPending} size="large" data-merchant="team-management-loading-spin">
                                {view}
                            </Spin>
                        </PageContentContainer>
                    )}
                </Flex>
            </PlaceholderViewWrapper>
        </>
    )
}

export const TeamManagement = withDirtyCheckingContextProvider(Component)
