import { NotificationMessage } from '@merchant/shared/components'
import { useNotifications } from '@merchant/shared/contexts'
import { useBreakpoint } from '@merchant/shared/hooks'
import { Button, Col, Divider, Flex, List, Skeleton, Space, Typography } from '@merchant/ui-kit/ant-design'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useBoolean } from 'usehooks-ts'
import { ChangePasswordModal } from './ChangePasswordModal'
import { ConfirmedTotps } from './ConfirmedTotps'
import { notificationMessageProps } from './const'
import { DeleteTwoFaModal } from './DeleteTwoFaModal'
import { lang } from './lang'
import { UnconfirmedTotps } from './UnconfirmedTotps'
import { processTotps } from './utils'
import type { Breakpoints } from '@merchant/shared/hooks'
import type { FlexProps } from '@merchant/ui-kit/ant-design'
import type { TOTP } from '~api/instances/cabinet-api'
import { authApi, useTotps } from '~api'
import { PageContentContainer } from '~components'
import { AddTwoFaModal } from '~features'
import { globalLang } from '~globalLang'

const { Text } = Typography

const listTitleStyles: React.CSSProperties = { fontWeight: 400 }
const listMetaStyles: React.CSSProperties = {
    maxWidth: 400,
    margin: '4px 20px 4px 0',
}
const dividerStyles: React.CSSProperties = { marginBlock: '16px 12px' }
const warningTextStyles: React.CSSProperties = { marginRight: 16 }

const getCommonFlexProps = (isVerticalAlignment: boolean): Omit<FlexProps, 'children'> => ({
    align: isVerticalAlignment ? 'normal' : 'center',
    flex: 1,
    justify: 'space-between',
    vertical: isVerticalAlignment,
})

const getTotpsFlexAlignment = (breakpoints: Breakpoints): FlexProps['align'] => {
    if (!breakpoints.md) {
        return 'normal'
    }

    return !breakpoints.lg ? 'flex-end' : 'center'
}

// eslint-disable-next-line max-lines-per-function
export function Security() {
    const {
        notification: { api: notificationApi },
    } = useNotifications()
    const {
        value: isChangePasswordModalOpen,
        setTrue: openChangePasswordModal,
        setFalse: closeChangePasswordModal,
    } = useBoolean(false)
    const breakpoints = useBreakpoint()
    const { value: isAddTwoFaModalOpen, setValue: setIsAddTwoFaModalOpen } = useBoolean(false)
    const { value: isDeleteTwoFaModalOpen, setValue: setIsDeleteTwoFaModalOpen } = useBoolean(false)
    const [twoFaDeleteItemId, setTwoFaDeleteItemId] = useState<TOTP['id']>()
    const {
        data: totps,
        mutate: mutateTotps,
        isLoading: isTotpsLoading,
        error: totpsError,
        hasActiveTotp: isTwoFaEnabled,
    } = useTotps()

    const { confirmedTotps, unconfirmedTotps } = processTotps(totps)
    const hasTotps = confirmedTotps.length > 0 || unconfirmedTotps.length > 0
    const commonFlexProps = getCommonFlexProps(!breakpoints.md)
    const handleDeleteTwoFaSuccess = () => {
        setIsDeleteTwoFaModalOpen(false)
        mutateTotps((totps: TOTP[] | undefined) => {
            return totps?.filter(totp => totp.id !== twoFaDeleteItemId)
        }, false)
        setTwoFaDeleteItemId(undefined)
        notificationApi.open({
            message: <NotificationMessage {...notificationMessageProps.delete} />,
        })
    }

    const onDeleteTwoFaClick = (id: TOTP['id']) => {
        setTwoFaDeleteItemId(id)
        setIsDeleteTwoFaModalOpen(true)
    }

    const handleDeleteTwoFaModalClose = () => {
        setTwoFaDeleteItemId(undefined)
        setIsDeleteTwoFaModalOpen(false)
    }

    const onAddNewTwoFaSuccess = (newTwoFa: TOTP) => {
        mutateTotps(totps ? [...totps, newTwoFa] : [newTwoFa])
    }

    const onImmediatelyDelete2Fa = async (id: NonNullable<TOTP['id']>) => {
        try {
            await authApi.deleteTOTP({
                id,
                deleteTOTPRequest: { verificationCode: '' },
            })
            mutateTotps((totps: TOTP[] | undefined) => {
                return totps?.filter(totp => totp.id !== id)
            }, false)
            notificationApi.open({
                message: <NotificationMessage {...notificationMessageProps.delete} />,
            })
        } catch (e) {
            console.error(e)
        }
    }

    const onConfirmUpTwoFa = (id: TOTP['id']) => {
        mutateTotps((prev = []) => prev.map(totp => (totp.id === id ? { ...totp, enabled: true } : totp)))
        notificationApi.open({
            message: <NotificationMessage {...notificationMessageProps.confirm} />,
        })
    }

    return (
        <PageContentContainer
            styles={{
                body: { padding: !breakpoints.md ? '24px 12px' : undefined },
            }}
        >
            <ChangePasswordModal open={isChangePasswordModalOpen} close={closeChangePasswordModal} />
            <AddTwoFaModal
                isOpen={isAddTwoFaModalOpen}
                close={() => setIsAddTwoFaModalOpen(false)}
                isTwoFaEnabled={isTwoFaEnabled}
                onModalSuccess={onAddNewTwoFaSuccess}
            />
            <DeleteTwoFaModal
                isLastTwoFa={confirmedTotps.length === 1}
                isOpen={isDeleteTwoFaModalOpen}
                close={handleDeleteTwoFaModalClose}
                onDeleteSuccess={handleDeleteTwoFaSuccess}
                id={twoFaDeleteItemId}
            />
            <List data-merchant="security-list">
                <List.Item style={{ paddingTop: 0 }} data-merchant="password-list-item">
                    <Flex {...commonFlexProps}>
                        <List.Item.Meta
                            data-merchant="password-list-item-meta"
                            style={listMetaStyles}
                            title={
                                <Text style={listTitleStyles} data-merchant={null}>
                                    <FormattedMessage {...lang.listPasswordTitle} />
                                </Text>
                            }
                            description={<FormattedMessage {...lang.listPasswordDescription} />}
                        />
                        <Flex gap={12} {...commonFlexProps} justify="flex-end">
                            <Text type="secondary" data-merchant={null}>
                                •••••••••••••
                            </Text>
                            <Button
                                data-merchant="change-password-open-modal-button"
                                onClick={openChangePasswordModal}
                                style={{ width: 90 }}
                            >
                                <FormattedMessage {...lang.listPasswordButton} />
                            </Button>
                        </Flex>
                    </Flex>
                </List.Item>
                <List.Item style={{ paddingBottom: 0 }} data-merchant="2fa-list-item">
                    <Skeleton loading={isTotpsLoading} active paragraph={{ rows: 2 }}>
                        <Flex {...commonFlexProps}>
                            <List.Item.Meta
                                style={listMetaStyles}
                                title={
                                    <Text style={listTitleStyles} data-merchant={null}>
                                        <FormattedMessage {...lang.listTwoFaTitle} />
                                    </Text>
                                }
                                data-merchant="2fa-list-item-meta"
                                description={
                                    totpsError ? (
                                        <Space direction="vertical">
                                            <Text type="danger" data-merchant="2fa-error-text">
                                                <FormattedMessage {...globalLang.requestErrorOccurred} />
                                            </Text>
                                            <Button
                                                data-merchant="2fa-try-again-button"
                                                type="primary"
                                                onClick={() =>
                                                    mutateTotps(undefined, {
                                                        revalidate: true,
                                                    })
                                                }
                                            >
                                                <FormattedMessage {...globalLang.tryAgain} />
                                            </Button>
                                        </Space>
                                    ) : (
                                        <FormattedMessage {...lang.listTwoFaDescription} />
                                    )
                                }
                            />
                            {!totpsError && (
                                <div>
                                    <Flex
                                        gap={12}
                                        align={getTotpsFlexAlignment(breakpoints)}
                                        vertical={!breakpoints.lg}
                                        justify="flex-end"
                                    >
                                        <Col>
                                            {!hasTotps && (
                                                <Text type="warning" style={warningTextStyles} data-merchant={null}>
                                                    <FormattedMessage {...lang.accountNotProtectedText} />
                                                </Text>
                                            )}
                                        </Col>
                                        <Col>
                                            <Button
                                                data-merchant="add-twofa-open-modal-button"
                                                type={hasTotps ? 'default' : 'primary'}
                                                onClick={() => setIsAddTwoFaModalOpen(true)}
                                                style={{ width: 90 }}
                                            >
                                                <FormattedMessage {...(hasTotps ? lang.addNew : lang.add)} />
                                            </Button>
                                        </Col>
                                    </Flex>
                                    {hasTotps && (
                                        <>
                                            <Divider type="horizontal" style={dividerStyles} />
                                            <Space direction="vertical" size={8}>
                                                <UnconfirmedTotps
                                                    isTwoFaEnabled={isTwoFaEnabled}
                                                    totps={unconfirmedTotps}
                                                    onConfirm={onConfirmUpTwoFa}
                                                    onDelete={onImmediatelyDelete2Fa}
                                                />
                                                <ConfirmedTotps totps={confirmedTotps} onDelete={onDeleteTwoFaClick} />
                                            </Space>
                                        </>
                                    )}
                                </div>
                            )}
                        </Flex>
                    </Skeleton>
                </List.Item>
            </List>
        </PageContentContainer>
    )
}
