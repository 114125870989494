import { colorPalette } from '@merchant/shared/themes/palette/currentBroker'
import { ProjectRoleColor } from '~api/instances/cabinet-api'
import { generateRolesColor } from '~utils'

type ProjectRoleColorMap = Record<ProjectRoleColor, string>
type ThemedProjectRolesColor = Record<'day' | 'night', ProjectRoleColorMap>

export const rolesColor: ThemedProjectRolesColor = {
    day: generateRolesColor(colorPalette.day, {
        [ProjectRoleColor.Pink]: '#F968EC',
        [ProjectRoleColor.Purple]: '#893FF1',
        [ProjectRoleColor.Blue]: '#184AFF',
        [ProjectRoleColor.LightBlue]: '#1890FF',
        [ProjectRoleColor.Cyan]: '#0CB1C0',
        [ProjectRoleColor.LightGreen]: '#07DE40',
        [ProjectRoleColor.Yellow]: '#D7AC0F',
        [ProjectRoleColor.DeepOrange]: '#DB6104',
        [ProjectRoleColor.Brown]: '#937153',
    }),
    night: generateRolesColor(colorPalette.night, {
        [ProjectRoleColor.Pink]: '#FA84F0',
        [ProjectRoleColor.Purple]: '#A770F5',
        [ProjectRoleColor.Blue]: '#4D73FF',
        [ProjectRoleColor.LightBlue]: '#4DA9FF',
        [ProjectRoleColor.Cyan]: '#0FDDF0',
        [ProjectRoleColor.LightGreen]: '#83FBA3',
        [ProjectRoleColor.Yellow]: '#F6D458',
        [ProjectRoleColor.DeepOrange]: '#D86D08',
        [ProjectRoleColor.Brown]: '#AC8A6C',
    }),
}
