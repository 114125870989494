import { Select, Button, Col, DatePicker, Flex, Form, Popover, Row, Tag, Typography } from '@merchant/ui-kit/ant-design'
import dayjs from 'dayjs'
import { FormattedMessage } from 'react-intl'
import { DateTypes, dateTypesOptions, filterFormKeys, initialFormValues, zeroBalanceOptions } from './const'
import { useCurrencyOptions } from './hooks/useCurrencyOptions'
import { useData } from './hooks/useData'
import { useExportCSV } from './hooks/useExport'
import { lang } from './lang'
import type { FilterForm } from './const'
import type { SelectProps } from '@merchant/ui-kit/ant-design'
import type { Dayjs } from 'dayjs'

const { Text } = Typography

const popoverRenderer: SelectProps['maxTagPlaceholder'] = omittedValues => (
    <Popover
        data-merchant="balances-snapshots-filters-currencies-popover"
        overlayStyle={{ pointerEvents: 'none', maxWidth: 300 }}
        content={
            <Flex gap={2} wrap>
                {omittedValues.map(({ label, key }) => (
                    <Tag key={key} style={{ margin: 0 }}>
                        {label}
                    </Tag>
                ))}
            </Flex>
        }
    >
        <span>+ {omittedValues.length}</span>
    </Popover>
)

interface Props {
    onFinish: (params: {
        date: Dayjs | undefined
        currencies: string[] | undefined
        showZeroes: 'hidden' | 'visible'
    }) => void
    isLoading: boolean
    filters: Omit<FilterForm, 'dateType'>
}

// eslint-disable-next-line max-lines-per-function
export function SnapshotsFilters({ onFinish, isLoading, filters }: Props) {
    const { form, selectedDateType, project } = useData()
    const { onExport, isExporting } = useExportCSV()
    const { options: currencyOptions } = useCurrencyOptions()

    const onFinishIntercept = ({ currencies, date, showZeroes }: FilterForm) => {
        onFinish({
            currencies,
            date: selectedDateType === DateTypes.custom ? date?.utc(true).startOf('day') : undefined,
            showZeroes,
        })
    }

    const onExportIntercept = () => {
        onExport({
            currencies: filters.currencies,
            date: (filters.date ?? dayjs()).utc(true).startOf('day').toDate(),
            includeZeroes: filters.showZeroes === 'visible',
        })
    }

    return (
        <Form
            form={form}
            initialValues={initialFormValues}
            onFinish={onFinishIntercept}
            data-merchant="balances-snapshots-filters-form"
        >
            <Flex gap={12} wrap style={{ margin: '24px 0' }} align="flex-start">
                <Row align="bottom" gutter={selectedDateType === DateTypes.custom ? [12, 12] : undefined}>
                    <Col xs={{ flex: 1 }}>
                        <Form.Item
                            style={{ marginBottom: 0 }}
                            name={filterFormKeys.dateType}
                            label={
                                <Text
                                    data-merchant="balances-snapshots-filters-date-label"
                                    type="secondary"
                                    style={{ fontSize: 12 }}
                                >
                                    <FormattedMessage {...lang.dateLabel} />
                                </Text>
                            }
                        >
                            <Select<DateTypes>
                                style={{ minWidth: 180 }}
                                options={dateTypesOptions}
                                data-merchant="balances-snapshots-filters-date-type-select"
                                onChange={value => {
                                    form.setFieldValue(
                                        filterFormKeys.date,
                                        value === DateTypes.realtime ? undefined : dayjs()
                                    )
                                    form.setFieldValue(filterFormKeys.dateType, value)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={{ flex: 1 }}>
                        <Form.Item
                            style={{ marginBottom: 0 }}
                            name={filterFormKeys.date}
                            hidden={selectedDateType !== DateTypes.custom}
                            rules={[{ required: selectedDateType === DateTypes.custom }]}
                        >
                            <DatePicker
                                style={{ width: '100%' }}
                                size="middle"
                                needConfirm
                                onChange={(_, date) =>
                                    !date && form.setFieldValue(filterFormKeys.dateType, DateTypes.realtime)
                                }
                                showNow={false}
                                data-merchant="balances-snapshots-filters-date-picker"
                                disabledDate={current =>
                                    current < dayjs(project?.createdAt).subtract(1, 'day') ||
                                    current > dayjs().endOf('day')
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item noStyle name={filterFormKeys.currencies}>
                    <Select
                        allowClear
                        style={{ minWidth: 'clamp(100px, 100%, 300px)' }}
                        placeholder={<FormattedMessage {...lang.currencySelectPlaceholder} />}
                        mode="multiple"
                        options={currencyOptions}
                        data-merchant="balances-snapshots-filters-currencies-select"
                        maxTagCount="responsive"
                        maxTagPlaceholder={popoverRenderer}
                        optionLabelProp="selectedLabel"
                    />
                </Form.Item>
                <Form.Item name={filterFormKeys.showZeroes}>
                    <Select
                        style={{ minWidth: 'clamp(100px, 100%, 210px)' }}
                        options={zeroBalanceOptions}
                        data-merchant="balances-snapshots-filters-zero-balances-select"
                    />
                </Form.Item>
                <Button
                    htmlType="submit"
                    type="primary"
                    data-merchant="balances-snapshots-filters-submit-button"
                    loading={isLoading}
                >
                    <FormattedMessage {...lang.applyButton} />
                </Button>
                <Button
                    onClick={onExportIntercept}
                    data-merchant="balances-snapshots-filters-export-button"
                    loading={isExporting}
                >
                    <FormattedMessage {...lang.exportCSVButton} />
                </Button>
            </Flex>
        </Form>
    )
}
