import { ModalResponsive, NotificationMessage } from '@merchant/shared/components'
import { useNotifications } from '@merchant/shared/contexts'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { FormattedMessageWithBoldText, ConfirmationView } from '..'
import { lang as commonLang } from './../../lang'
import { steps, initWhitelist } from './const'
import { lang } from './lang'
import { InputView } from './views'
import type { ConfirmationItems } from '../ConfirmationView/types'
import type { ModalProps } from '@merchant/ui-kit/ant-design'
import type { WhitelistAddress } from '~api/instances/cabinet-api'
import { projectApi, useProject, useWhitelist } from '~api'
import { GenerateVerificationCodeRequestPurpose } from '~api/instances/cabinet-api'
import { getWhitelistUniqueKey } from '~features/WhitelistPage/utils'
import { withDirtyCheckingContextProvider } from '~hoc/withDirtyCheckingContextProvider'
import { useDirtyFormCheckerControls } from '~hooks/useDirtyFormCheckerControls'

interface Props extends Omit<ModalProps, 'data-merchant'> {
    close: () => void
}

function Component({ close, ...rest }: Props) {
    const { notification } = useNotifications()
    const { mutate: mutateWhitelist } = useWhitelist()
    const [newWhitelist, setNewWhitelist] = useState<WhitelistAddress>(initWhitelist)
    const [step, setStep] = useState<keyof typeof steps>(steps.whitelistAddInput)
    const { handleCancel } = useDirtyFormCheckerControls(close)
    const { data: { id: projectId = '' } = {} } = useProject()

    const addNewWhitelist = () => {
        mutateWhitelist(
            prev => {
                const { addresses = [], enabled = false } = prev || {}

                return {
                    addresses: [
                        ...addresses,
                        {
                            ...newWhitelist,
                            key: getWhitelistUniqueKey(newWhitelist),
                        },
                    ],
                    enabled,
                }
            },
            { revalidate: false }
        )
    }

    const views = {
        [steps.whitelistAddInput]: () => {
            const onSuccess = (newItem: WhitelistAddress) => {
                setStep(steps.whitelistAddConfirmation)
                setNewWhitelist(newItem)
            }

            return <InputView onSuccess={onSuccess} />
        },
        [steps.whitelistAddConfirmation]: () => {
            const confirmationRequest = (values: ConfirmationItems) =>
                projectApi.addAddressToProjectWhitelist({
                    whitelistAddressUpdateRequest: {
                        ...newWhitelist,
                        ...values,
                    },
                    projectId,
                })

            const handleConfirmationSuccess = () => {
                notification.api.open({
                    message: (
                        <NotificationMessage
                            type="success"
                            title={<FormattedMessage {...lang.successNotificationTitle} />}
                            description={<FormattedMessage {...lang.successNotificationDescription} />}
                            dataMerchant="add-whitelist-success-notification"
                        />
                    ),
                })
                addNewWhitelist()
                close()
            }

            return (
                <ConfirmationView
                    alertMessage={<FormattedMessageWithBoldText message={commonLang.newAddressAlertDescription} />}
                    request={confirmationRequest}
                    onSuccess={handleConfirmationSuccess}
                    buttonTitle={commonLang.addNewAddress}
                    purpose={GenerateVerificationCodeRequestPurpose.AddToWhitelist}
                    whitelist={newWhitelist}
                />
            )
        },
    }

    const handleModalAfterClose = () => {
        setStep(steps.whitelistAddInput)
        setNewWhitelist(initWhitelist)
        rest.afterClose?.()
    }

    return (
        <ModalResponsive
            data-merchant="whitelist-add-address-modal"
            width={550}
            destroyOnClose
            title={
                <span data-merchant="whitelist-add-address-modal-title">
                    <FormattedMessage {...lang.modalTitle} />
                </span>
            }
            footer={null}
            onCancel={handleCancel}
            {...rest}
            afterClose={handleModalAfterClose}
        >
            {views[step]()}
        </ModalResponsive>
    )
}

export const AddWhitelistedAddressModal = withDirtyCheckingContextProvider(Component)
