/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SigninResponse
 */
export interface SigninResponse {
    /**
     * 
     * @type {string}
     * @memberof SigninResponse
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof SigninResponse
     */
    sessionStatus: SigninResponseSessionStatus;
    /**
     * 
     * @type {boolean}
     * @memberof SigninResponse
     */
    _2faRequired: boolean;
}

/**
* @export
* @enum {string}
*/
export enum SigninResponseSessionStatus {
    Unconfirmed = 'unconfirmed',
    Active = 'active'
}


/**
 * Check if a given object implements the SigninResponse interface.
 */
// @ts-ignore
export function instanceOfSigninResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "token" in value;
    isInstance = isInstance && "sessionStatus" in value;
    isInstance = isInstance && "_2faRequired" in value;

    return isInstance;
}

// @ts-ignore
export function SigninResponseFromJSON(json: any): SigninResponse {
    return SigninResponseFromJSONTyped(json, false);
}

// @ts-ignore
export function SigninResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SigninResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
        'sessionStatus': json['session_status'],
        '_2faRequired': json['2fa_required'],
    };
}

// @ts-ignore
export function SigninResponseToJSON(value?: SigninResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'session_status': value.sessionStatus,
        '2fa_required': value._2faRequired,
    };
}

