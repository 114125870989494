import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    amlKycTitle: {
        id: 'static-pages.aml-kyc.title',
        defaultMessage: 'KYC/AML Policy',
    },
    amlKycContent: {
        id: 'static-pages.aml-kyc.content',
        defaultMessage: '-',
    },
})
