import { lang as commonLang } from './../../lang'
import { MerchantTeamShowOptions } from './types'
import type { MessageDescriptor } from 'react-intl'
import { Permission } from '~api/instances/cabinet-api'

export const pageSubtitleDataMap: Record<
    MerchantTeamShowOptions,
    { subtitle: MessageDescriptor; subtitleDataMerchant: string }
> = {
    [MerchantTeamShowOptions.management]: {
        subtitle: commonLang.teamPageTitle,
        subtitleDataMerchant: 'merchant-team-management-title',
    },
    [MerchantTeamShowOptions.addMembers]: {
        subtitle: commonLang.addMembersPageTitle,
        subtitleDataMerchant: 'merchant-add-members-title',
    },
    [MerchantTeamShowOptions.editRoles]: {
        subtitle: commonLang.editRolesPageTitle,
        subtitleDataMerchant: 'merchant-edit-roles-title',
    },
}

export const blockHeaderCommonButtonStyles: React.CSSProperties = {
    minWidth: 150,
}
export const pagePermissionMap: Record<MerchantTeamShowOptions, Permission | boolean> = {
    [MerchantTeamShowOptions.management]: true,
    [MerchantTeamShowOptions.addMembers]: Permission.ChangeProjectTeamMembers,
    [MerchantTeamShowOptions.editRoles]: Permission.SeeProjectRoles,
} as const

export const PROJECT_BLOCKED_PERMITTED_PAGES = [MerchantTeamShowOptions.management]

export const fiatRelatedPermissions = [Permission.SeeFiatWithdrawalFlow, Permission.CommitFiatWithdrawals]
