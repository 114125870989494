import { emailValidationRegex } from '@merchant/shared/constants'
import {
    Select,
    Button,
    Col,
    Empty,
    Form,
    Input,
    Row,
    Space,
    Typography,
    type SelectProps,
} from '@merchant/ui-kit/ant-design'
import { capitalize } from 'lodash-es'
import React, { useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocalStorage } from 'usehooks-ts'
import { lang } from './lang'
import type { FilterFormData } from '~features/OrdersPage/types'
import { PaymentState } from '~api/instances/cabinet-api'
import { ActiveFilters, RangePickerResponsive, CollapseGapped } from '~components'
import { ordersFilterKey } from '~constants/localStorage'
import { globalLang } from '~globalLang'

const { Text } = Typography
const formItemStyles: React.CSSProperties = { marginBottom: 0 }
const rangePickerStyles: React.CSSProperties = { width: '100%' }

const statusOptions: SelectProps['options'] = Object.values(PaymentState).map(value => ({
    label: capitalize(value),
    value,
}))

const collapseKey = 'orders'

interface Props {
    isLoading: boolean
    onFinish: (values: FilterFormData) => void
    onReset: () => Promise<void>
    extra?: React.ReactNode
    activeFiltersCount: number
    initialValues: Partial<FilterFormData>
}
// eslint-disable-next-line max-lines-per-function
export function OrdersFiltersForm({ onFinish, onReset, isLoading, activeFiltersCount, extra, initialValues }: Props) {
    const [isOpen, setIsOpen] = useLocalStorage(ordersFilterKey, true)
    const intl = useIntl()
    const [form] = Form.useForm<FilterFormData>()
    const handleClearClick = async () => {
        await onReset()
        form.resetFields()
    }

    useEffect(() => {
        form.setFieldsValue(initialValues)
    }, [initialValues, form])

    const hasFilteredActiveCount = !isOpen && activeFiltersCount > 0

    return (
        <CollapseGapped
            onChange={keys => setIsOpen(keys.includes(collapseKey))}
            bordered={false}
            defaultActiveKey={isOpen ? collapseKey : undefined}
            data-merchant="orders-filters-collapse"
            items={[
                {
                    key: collapseKey,
                    extra,
                    forceRender: true,
                    label: (
                        <div data-merchant="orders-filters-collapse">
                            <Space direction="horizontal">
                                <FormattedMessage {...lang.filters} />{' '}
                                {hasFilteredActiveCount && (
                                    <ActiveFilters
                                        dataMerchant="orders"
                                        amount={activeFiltersCount}
                                        onClear={handleClearClick}
                                    />
                                )}
                            </Space>
                        </div>
                    ),
                    children: (
                        <Form<FilterFormData> form={form} onFinish={onFinish} initialValues={initialValues}>
                            <Row justify="start" gutter={[12, 10]} wrap>
                                <Col span={8} lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                    <Form.Item<FilterFormData> style={formItemStyles} name="state">
                                        <Select
                                            allowClear
                                            data-merchant="orders-filter-status-select"
                                            notFoundContent={<Empty data-merchant="orders-filters-state-empty" />}
                                            placeholder={intl.formatMessage(lang.statusPlaceholder)}
                                            options={statusOptions}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={8} lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                    <Form.Item<FilterFormData> style={formItemStyles} name="id">
                                        <Input
                                            allowClear
                                            autoComplete="off"
                                            data-merchant="orders-filter-order-id-input"
                                            placeholder={intl.formatMessage(lang.orderIdPlaceholder)}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={8} lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                    <Form.Item<FilterFormData> style={formItemStyles} name="orderId">
                                        <Input
                                            allowClear
                                            autoComplete="off"
                                            data-merchant="orders-filter-merchant-order-id-input"
                                            placeholder={intl.formatMessage(lang.merchantOrderIdPlaceholder)}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={8} lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                    <Form.Item<FilterFormData>
                                        style={formItemStyles}
                                        name="customerEmail"
                                        rules={[
                                            {
                                                pattern: emailValidationRegex,
                                                message: intl.formatMessage(globalLang.invalidEmailFormat),
                                            },
                                        ]}
                                    >
                                        <Input
                                            allowClear
                                            autoComplete="email"
                                            data-merchant="orders-filter-email-input"
                                            placeholder={intl.formatMessage(lang.clientEmailPlaceholder)}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={8} lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                    <Form.Item<FilterFormData> style={formItemStyles} name="customerId">
                                        <Input
                                            allowClear
                                            autoComplete="off"
                                            data-merchant="orders-filter-customer-id-input"
                                            placeholder={intl.formatMessage(lang.clientId)}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={8} lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                    <Form.Item<FilterFormData> style={formItemStyles} name="payinAddress">
                                        <Input
                                            allowClear
                                            autoComplete="none"
                                            data-merchant="orders-filter-address-input"
                                            placeholder={intl.formatMessage(lang.address)}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={8} lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                    <Form.Item<FilterFormData> style={formItemStyles} name="txnHash">
                                        <Input
                                            allowClear
                                            autoComplete="off"
                                            data-merchant="orders-filter-hash-input"
                                            placeholder={intl.formatMessage(lang.hash)}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col
                                    xl={{ span: 10 }}
                                    lg={{ span: 10 }}
                                    md={{ span: 12 }}
                                    sm={{ span: 12 }}
                                    xs={{ span: 24 }}
                                >
                                    <Form.Item<FilterFormData>
                                        style={formItemStyles}
                                        name="date"
                                        label={
                                            <Text type="secondary" data-merchant={null}>
                                                <FormattedMessage {...lang.createdAt} />
                                            </Text>
                                        }
                                    >
                                        <RangePickerResponsive
                                            id={{
                                                start: 'orders-filter-created-start-input',
                                                end: 'orders-filter-created-end-input',
                                            }}
                                            picker="date"
                                            style={rangePickerStyles}
                                            allowClear
                                            allowEmpty={[true, true]}
                                            placement="bottomLeft"
                                            data-merchant="orders-filter-created-range-picker"
                                        />
                                    </Form.Item>
                                </Col>

                                <Col
                                    lg={{ span: 3 }}
                                    xl={{ span: 3 }}
                                    md={{ span: 12 }}
                                    sm={{ span: 12 }}
                                    xs={{ span: 12 }}
                                >
                                    <Form.Item style={formItemStyles}>
                                        <Button
                                            data-merchant="orders-filters-submit-button"
                                            htmlType="submit"
                                            type="primary"
                                            block
                                            loading={isLoading}
                                        >
                                            <FormattedMessage {...lang.apply} />
                                        </Button>
                                    </Form.Item>
                                </Col>

                                <Col
                                    lg={{ span: 3 }}
                                    xl={{ span: 3 }}
                                    md={{ span: 12 }}
                                    sm={{ span: 12 }}
                                    xs={{ span: 12 }}
                                >
                                    <Form.Item style={formItemStyles}>
                                        <Button
                                            data-merchant="orders-filters-clear-button"
                                            onClick={handleClearClick}
                                            block
                                        >
                                            <FormattedMessage {...lang.clearAll} />
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    ),
                },
            ]}
        />
    )
}
