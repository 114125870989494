import { CopyButton, StatisticFormattedNumber } from '@merchant/shared/components'
import { shortenRate } from '@merchant/shared/utils'
import { Col, Row, Skeleton, Space, Typography } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { lang } from '../../lang'
import { lang as commonLang } from './lang'
import type { Swap } from '~api/instances/cabinet-api'
import { NoValueColumnContent } from '~components'

const { Text } = Typography

const middleAlignedColStyles: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
}
const spaceWrapperStyles: React.CSSProperties = { display: 'flex' }

interface Props {
    data?: Swap
    isLoading: boolean
}

export function ConvertInformation({ data, isLoading }: Props) {
    return (
        <Skeleton active loading={isLoading}>
            <Space size={14.5} direction="vertical" style={spaceWrapperStyles}>
                <Row justify="space-between" align="middle">
                    <Col style={middleAlignedColStyles}>
                        <Text data-merchant={null} type="secondary">
                            <FormattedMessage {...commonLang.youSend} />
                        </Text>
                    </Col>
                    <Col style={middleAlignedColStyles}>
                        <Text data-merchant={null}>
                            <StatisticFormattedNumber
                                value={data?.amountFrom}
                                suffix={data?.currencyFrom}
                                data-merchant="convert-information-send"
                            />
                        </Text>
                    </Col>
                </Row>
                <Row justify="space-between">
                    <Col style={middleAlignedColStyles}>
                        <Text data-merchant={null} type="secondary">
                            <FormattedMessage {...commonLang.exchangeRate} />
                        </Text>
                    </Col>
                    <Col style={middleAlignedColStyles}>
                        <Text data-merchant={null}>
                            <StatisticFormattedNumber value={1} suffix={data?.currencyFrom} /> ={' '}
                            <StatisticFormattedNumber
                                value={shortenRate(data?.rate) || ''}
                                suffix={data?.currencyTo}
                                data-merchant="convert-information-rate"
                            />
                        </Text>
                    </Col>
                </Row>
                <Row justify="space-between">
                    <Col style={middleAlignedColStyles}>
                        <Text type="secondary" data-merchant={null}>
                            <FormattedMessage {...commonLang.youGet} />
                        </Text>
                    </Col>
                    <Col style={middleAlignedColStyles}>
                        <Text data-merchant={null}>
                            <StatisticFormattedNumber
                                value={data?.amountTo}
                                suffix={data?.currencyTo}
                                data-merchant="convert-information-receive"
                            />
                        </Text>
                    </Col>
                </Row>
                <Row justify="space-between">
                    <Col style={middleAlignedColStyles}>
                        <Text data-merchant={null} type="secondary">
                            <FormattedMessage {...lang.transactionId} />
                        </Text>
                    </Col>
                    <Col style={middleAlignedColStyles}>
                        <Text data-merchant="convert-information-transaction-id">
                            {data?.txnId || NoValueColumnContent}
                        </Text>
                        <CopyButton
                            type="link"
                            inputValue={data?.txnId || ''}
                            data-merchant="convert-information-copy-transaction-id-button"
                            notificationContentSubject={lang.transactionId}
                        />
                    </Col>
                </Row>
            </Space>
        </Skeleton>
    )
}
