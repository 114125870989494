import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    createdAt: {
        id: 'withdrawals.table.created_at',
        defaultMessage: 'Created',
    },
    updatedAt: {
        id: 'withdrawals.table.updated_at',
        defaultMessage: 'Updated',
    },
    status: {
        id: 'withdrawals.table.status',
        defaultMessage: 'Status',
    },
    id: {
        id: 'withdrawals.table.id',
        defaultMessage: 'ID',
    },
    orderId: {
        id: 'withdrawals.table.order_id',
        defaultMessage: 'Merch. Withdrawal ID',
    },
    creditAmount: {
        id: 'withdrawals.table.creditAmount',
        defaultMessage: 'Credit amount',
    },
    receiveAmount: {
        id: 'withdrawals.table.receive_amount',
        defaultMessage: 'Receive amount',
    },
    addressAndTag: {
        id: 'withdrawals.table.address_and_tag',
        defaultMessage: 'Address & Tag',
    },
    showMore: {
        id: 'withdrawals.table.button.show_more',
        defaultMessage: 'Show more',
    },
    exportCSV: {
        id: 'withdrawals.table.button.export_csv',
        defaultMessage: 'Export .csv',
    },
    noWithdrawalsYet: {
        id: 'withdrawals.table.no_withdrawals_yet',
        defaultMessage: 'No withdrawals yet',
    },
})
