import { assets } from '@merchant/shared/assets/nameMap'
import { InlineLinkButton, AppSvg } from '@merchant/shared/components'
import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { pageSubtitleDataMap } from '../../const'
import { MerchantTeamShowOptions } from '../../types'
import type { Blocklist } from '~api/instances/cabinet-api'
import { ActionSuspendedAlert } from '~components/ActionSuspended/ActionSuspendedAlert'
import { SuspensionContext } from '~components/ActionSuspended/types'

const { Title } = Typography

interface Props {
    isSuspensionModalOpen: boolean
    closeSuspensionModal: () => void
    onGoBackClick: () => void
    page: MerchantTeamShowOptions
    projectBlocklist: Blocklist | undefined
}

export function PageHeader({
    onGoBackClick,
    isSuspensionModalOpen,
    closeSuspensionModal,
    projectBlocklist,
    page,
}: Props) {
    const { subtitleDataMerchant, subtitle } = pageSubtitleDataMap[page]
    const shouldShowBackButton = page !== MerchantTeamShowOptions.management
    const isBlocked = projectBlocklist?.blocked

    return (
        <Flex vertical style={{ marginBottom: 16 }} gap={16}>
            <Flex gap={4} align="center">
                {shouldShowBackButton && (
                    <InlineLinkButton
                        icon={<AppSvg size={24} name={assets.arrowNarrowLeft} />}
                        onClick={onGoBackClick}
                        data-merchant="team-management-back-button"
                    />
                )}
                <Title level={4} data-merchant={subtitleDataMerchant}>
                    <FormattedMessage {...subtitle} />
                </Title>
            </Flex>
            {isBlocked && (
                <ActionSuspendedAlert
                    isModalOpen={isSuspensionModalOpen}
                    onClose={closeSuspensionModal}
                    expiresAt={projectBlocklist.blockItems[0]?.expiresAt}
                    blockReason={projectBlocklist.blockItems[0]?.reason}
                    context={SuspensionContext.TeamManagement}
                />
            )}
        </Flex>
    )
}
