import { Flex, Select } from '@merchant/ui-kit/ant-design'
import { themesMap } from '../const'
import styles from './style.module.css'
import type { SelectProps } from '@merchant/ui-kit/ant-design'
import { useBreakpoint, useColorMode } from '~hooks'
import { isKeyOfObject } from '~utils'

const { Option } = Select

export function ColorModeSelect() {
    const breakpoints = useBreakpoint()
    const { colorMode, setColorMode } = useColorMode()

    const onSelect: SelectProps['onSelect'] = value => {
        if (isKeyOfObject(value, themesMap)) {
            setColorMode(value)
        }
    }

    return (
        <Select
            data-merchant="color-mode-select"
            value={colorMode}
            size="large"
            style={{ maxWidth: !breakpoints.md ? 228 : 260, flex: 1 }}
            onSelect={onSelect}
            popupClassName={styles.select}
        >
            {Object.values(themesMap).map(({ key, icon, label }) => (
                <Option key={key} value={key} data-merchant={`color-mode-select-${key}`}>
                    <Flex gap={8} align="center">
                        <Flex>{icon}</Flex>
                        {label}
                    </Flex>
                </Option>
            ))}
        </Select>
    )
}
