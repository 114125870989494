import { authTokenKey } from '@merchant/shared/constants/localStorage'
import { useNotifications } from '@merchant/shared/contexts'
import { useBreakpoint } from '@merchant/shared/hooks'
import { Layout } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { useReadLocalStorage } from 'usehooks-ts'
import { SideMenu } from './SideMenu'
import { useDefaultTimezone } from './useDefaultTimezone'
import { getIsSiderCollapsed } from './utils'
import { Footer, Header, NavigationMenu, TotalBalance } from '.'
import type { Breakpoints } from '@merchant/shared/hooks'
import type { Breakpoint } from '@merchant/ui-kit/ant-design'
import { routes } from '~constants/routes'
import { useUIContext } from '~contexts'
import { usePosthogIdentify, usePosthogPageview } from '~hooks'

const { Content, Sider } = Layout

const getLayoutStyles = (breakpoints: Partial<Record<Breakpoint, boolean>>): React.CSSProperties => {
    const styles: React.CSSProperties = {
        maxWidth: 1920,
        width: '100%',
        marginInline: 'auto',
        position: 'relative',
        minHeight: '100vh',
        padding: 0,
    }

    if (breakpoints.xxl) {
        styles.padding = '0 80px'
    } else if (breakpoints.md) {
        styles.padding = '0 24px'
    }

    if (!breakpoints.xxl) {
        styles.maxWidth = 1380
    }

    return styles
}
const siderStyles: React.CSSProperties = { marginRight: '24px' }
const getContentStyles = (breakpoints: Breakpoints): React.CSSProperties => ({
    height: 'auto',
    margin: !breakpoints.md ? '0 12px' : undefined,
})

export function Main() {
    useDefaultTimezone()
    usePosthogIdentify()
    usePosthogPageview()
    const authToken = useReadLocalStorage<string | undefined>(authTokenKey)
    const breakpoints = useBreakpoint()
    const {
        notification: { contextHolder: notificationContextHolder },
        message: { contextHolder: messageContextHolder },
    } = useNotifications()
    const location = useLocation()
    const { isSideMenuOpen } = useUIContext()
    const isPayment = location.pathname.includes(routes.payment)

    const shouldRenderSider = !!authToken && !isPayment && breakpoints.lg && !isSideMenuOpen

    const isSiderCollapsed = getIsSiderCollapsed(breakpoints)

    return (
        <Layout style={getLayoutStyles(breakpoints)}>
            {notificationContextHolder}
            {messageContextHolder}
            <Header />
            <Layout hasSider={shouldRenderSider}>
                {shouldRenderSider && (
                    <Sider
                        collapsed={isSiderCollapsed}
                        collapsedWidth={breakpoints.lg ? 64 : 0}
                        width={200}
                        style={!isSiderCollapsed ? siderStyles : undefined}
                    >
                        {!isSiderCollapsed && <TotalBalance />}
                        <NavigationMenu usageContext="layoutSider" />
                    </Sider>
                )}
                <SideMenu />
                <Content style={getContentStyles(breakpoints)}>
                    <Outlet />
                </Content>
            </Layout>
            <Footer />
        </Layout>
    )
}
