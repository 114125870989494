/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum WithdrawalsSorting {
    CreatedAt = 'created_at',
    UpdatedAt = 'updated_at'
}


// @ts-ignore
export function WithdrawalsSortingFromJSON(json: any): WithdrawalsSorting {
    return WithdrawalsSortingFromJSONTyped(json, false);
}

// @ts-ignore
export function WithdrawalsSortingFromJSONTyped(json: any, ignoreDiscriminator: boolean): WithdrawalsSorting {
    return json as WithdrawalsSorting;
}

// @ts-ignore
export function WithdrawalsSortingToJSON(value?: WithdrawalsSorting | null): any {
    return value as any;
}

