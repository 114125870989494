import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    created: {
        id: 'orders-page.table.created',
        defaultMessage: 'Created',
    },
    lastUpdated: {
        id: 'orders-page.table.updated',
        defaultMessage: 'Updated',
    },
    status: {
        id: 'orders-page.table.status',
        defaultMessage: 'Status',
    },
    orderId: {
        id: 'orders-page.table.order_id',
        defaultMessage: 'Order ID',
    },
    merchantOrderId: {
        id: 'orders-page.table.merch_order_id',
        defaultMessage: 'Merch.order ID',
    },
    clientEmail: {
        id: 'orders-page.table.client_email',
        defaultMessage: 'Client email',
    },
    orderDetails: {
        id: 'orders-page.table.order_details',
        defaultMessage: 'Order details',
    },
    price: {
        id: 'orders-page.table.price',
        defaultMessage: 'Price',
    },
    payAmount: {
        id: 'orders-page.table.pay_amount',
        defaultMessage: 'Pay amount',
    },
    received: {
        id: 'orders-page.table.received',
        defaultMessage: 'Received',
    },

    transactionDetailsModalTitle: {
        id: 'orders-page.modal.transaction_details_modal_title',
        defaultMessage: 'Transaction details',
    },
    exportCsv: {
        id: 'orders-page.button.export_csv',
        defaultMessage: 'Export .csv',
    },
    ok: {
        id: 'orders-page.modal.ok_button',
        defaultMessage: 'OK',
    },
    searchInputPlaceholder: {
        id: 'orders-page.input.search_input_placeholder',
        defaultMessage: 'Search Order',
    },
    allOrders: {
        id: 'orders-page.select.all_orders',
        defaultMessage: 'All Orders',
    },
    showMore: {
        id: 'orders-page.button.show_more',
        defaultMessage: 'Show more',
    },
    filters: {
        id: 'orders-page.typography.filters',
        defaultMessage: 'Filters',
    },
    statusPlaceholder: {
        id: 'orders-page.select.status',
        defaultMessage: 'Status',
    },
    clientEmailPlaceholder: {
        id: 'orders-page.input.client_email',
        defaultMessage: 'Client email',
    },
    clientId: {
        id: 'orders-page.input.client_id_placeholder',
        defaultMessage: 'Client ID',
    },
    address: {
        id: 'orders-page.input.address_placeholder',
        defaultMessage: 'Address',
    },
    hash: {
        id: 'orders-page.input.hash_placeholder',
        defaultMessage: 'Hash',
    },
    orderIdPlaceholder: {
        id: 'orders-page.input.order_id_placeholder',
        defaultMessage: 'Order ID',
    },
    merchantOrderIdPlaceholder: {
        id: 'orders-page.input.merchant_order_id_placeholder',
        defaultMessage: 'Merchant order ID',
    },
    apply: {
        id: 'orders-page.button.apply',
        defaultMessage: 'Apply',
    },
    clearAll: {
        id: 'orders-page.button.clear_all',
        defaultMessage: 'Clear all',
    },
    createdAt: {
        id: 'orders-page.input.created_at_label',
        defaultMessage: 'Created at',
    },
    noOrdersSubtitle: {
        id: 'orders-page.typography.no_orders_subtitle',
        defaultMessage: 'No orders yet',
    },
    client: {
        id: 'orders-page.table.client',
        defaultMessage: 'Client',
    },
})
