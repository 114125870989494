import { useBreakpoint } from '@merchant/shared/hooks'
import { DatePicker, type GetProps } from '@merchant/ui-kit/ant-design'
import styles from './style.module.css'

const { RangePicker } = DatePicker

type Props = GetProps<typeof RangePicker>

export function RangePickerResponsive({ ...props }: Props) {
    const breakpoints = useBreakpoint()

    return (
        <RangePicker
            {...props}
            placement="bottomLeft"
            popupAlign={{
                overflow: {
                    adjustX: breakpoints.md,
                    adjustY: breakpoints.md,
                    shiftX: false,
                    shiftY: false,
                },
                dynamicInset: breakpoints.md,
            }}
            popupClassName={!breakpoints.md ? styles.rangePicker : undefined}
        />
    )
}
