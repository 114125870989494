import { usePromise } from '@merchant/shared/hooks'
import dayjs from 'dayjs'
import { getExportFilename } from '../utils'
import type { ExportProjectBalancesSnapshotRequest } from '~api'
import { projectApi, useProject } from '~api'
import { downloadMedia } from '~utils'

type ExportCSVParams = Omit<ExportProjectBalancesSnapshotRequest, 'projectId'>

export const useExportCSV = () => {
    const { data: project } = useProject()
    const { send: exportCSV, isLoading } = usePromise(
        async (params: ExportCSVParams) => {
            const projectId = project?.id || ''
            const blob = await projectApi.exportProjectBalancesSnapshot({
                ...params,
                projectId,
            })

            return {
                blob,
                date: dayjs(params.date),
            }
        },
        {
            onSuccess: ({ blob, date }) => {
                const filename = getExportFilename(project, date)
                downloadMedia({ type: 'csv', filename, data: blob })
            },
        }
    )

    return {
        onExport: exportCSV,
        isExporting: isLoading,
    }
}
