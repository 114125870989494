import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    failedTimeline: {
        id: 'withdrawal.timeline.title.failed',
        defaultMessage: 'Withdrawal failed',
    },
    support: {
        id: 'withdrawal.timeline.failed.support',
        defaultMessage:
            'For any questions, feel free to contact our <support>support team</support> or your account manager.',
    },
})
