import { NotificationMessage } from '@merchant/shared/components'
import { useNotifications } from '@merchant/shared/contexts'
import { Button, Checkbox, Col, Divider, Flex, Form, Row, Table, Typography } from '@merchant/ui-kit/ant-design'
import cn from 'classnames'
import { isEmpty } from 'lodash-es'
import { useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useBoolean } from 'usehooks-ts'
import { blockHeaderCommonButtonStyles } from '../../const'
import { lang as commonLang } from './../../lang'
import { ConfirmPermissionsModal } from './components'
import { lang } from './lang'
import styles from './style.module.css'
import {
    getPermissionMessageDescriptor,
    normalizeFinishFormValues,
    getNormalizedFormInitData,
    getAllPermissions,
    getFiatlessPermissions,
} from './utils'
import type { FormValues } from './types'
import type { TableColumnsType } from '@merchant/ui-kit/ant-design'
import type { PermissionGroup, ProjectRole } from '~api/instances/cabinet-api'
import { useEditableProjectRoles, useCurrencies, useProject, useProjectPermissions } from '~api'
import { Permission } from '~api/instances/cabinet-api'
import { FormNames } from '~constants/form'
import { useUserPermissionContext } from '~contexts'
import { withDirtyCheckingContextProvider } from '~hoc/withDirtyCheckingContextProvider'
import { useDirtyFormCheckerControls, useRegisterDirtyFormChecker } from '~hooks'
import { getIfHasWithdrawableFiatCurrencies } from '~utils'

const { Paragraph, Text } = Typography

const FORM_ID = 'edit-roles-form'

interface EditRolesTableRow {
    permission: string
    isGroup?: boolean
}

interface Props {
    onGoBack: () => void
}

// eslint-disable-next-line max-lines-per-function
function Component({ onGoBack }: Props) {
    const { checkUserPermission } = useUserPermissionContext()
    const { data: projectPermissions, isLoading: isProjectPermissionsLoading } = useProjectPermissions()
    const {
        data: projectRoles = [],
        mutate: mutateProjectRoles,
        isLoading: isProjectRolesLoading,
    } = useEditableProjectRoles()
    const { data: project } = useProject()
    const { data: currencies } = useCurrencies()
    const { value: isConfirmModalOpen, setTrue: openConfirmModal, setFalse: closeConfirmModal } = useBoolean()
    const [newRolesPermissions, setNewRolesPermissions] = useState<ProjectRole[]>([])
    const [form] = Form.useForm<FormValues>()
    const { skipCheckAndCancel } = useDirtyFormCheckerControls(onGoBack, {
        checkForRouteChange: true,
    })

    const {
        notification: {
            api: { open: openNotification },
        },
    } = useNotifications()
    const isEditRolesAllowed = checkUserPermission(Permission.ChangeProjectRoles)

    const allPermissions: EditRolesTableRow[] = useMemo(() => {
        const shouldShowFiatPermissions = getIfHasWithdrawableFiatCurrencies(currencies)
        const getter = shouldShowFiatPermissions ? getAllPermissions : getFiatlessPermissions

        const permissions = getter(projectPermissions?.groups)

        return permissions
    }, [currencies, projectPermissions?.groups])

    useRegisterDirtyFormChecker(FormNames.editProjectRoles, {
        initial: () => projectRoles,
        current: () => normalizeFinishFormValues(form.getFieldsValue()),
    })

    const handleFormFinish = (values: FormValues) => {
        if (!isEditRolesAllowed) {
            return
        }

        const newRolesPermissions = normalizeFinishFormValues(values)
        setNewRolesPermissions(newRolesPermissions)
        openConfirmModal()
    }

    const handleModalSuccess = () => {
        closeConfirmModal()
        openNotification({
            message: (
                <NotificationMessage
                    type="success"
                    title={<FormattedMessage {...commonLang.changesSavedNotification} />}
                    dataMerchant="edit-roles-changes-saved-success-notification"
                />
            ),
        })
        mutateProjectRoles()
        skipCheckAndCancel()
    }

    useEffect(() => {
        if (isProjectRolesLoading || isEmpty(projectRoles)) {
            return
        }
        form.resetFields()
    }, [form, isProjectRolesLoading, projectRoles, project])

    return (
        <>
            <ConfirmPermissionsModal
                onSuccess={handleModalSuccess}
                newRolesPermissions={newRolesPermissions}
                initRolesPermissions={projectRoles}
                open={isConfirmModalOpen}
                onCancel={closeConfirmModal}
            />
            <Row justify="space-between" align="middle" gutter={12}>
                <Col>
                    <Flex vertical gap={4}>
                        <Paragraph style={{ margin: 0, fontSize: 16, fontWeight: 500 }} data-merchant={null}>
                            <FormattedMessage {...lang.blockTitle} />
                        </Paragraph>
                        <Text type="secondary" style={{ fontSize: 12 }} data-merchant={null}>
                            <FormattedMessage
                                {...lang.blockDescription}
                                values={{
                                    b: chunks => (
                                        <Text type="secondary" strong data-merchant={null}>
                                            {chunks}
                                        </Text>
                                    ),
                                    break: () => <br />,
                                }}
                            />
                        </Text>
                    </Flex>
                </Col>
                <Col>
                    {isEditRolesAllowed && (
                        <Button
                            htmlType="submit"
                            form={FORM_ID}
                            style={blockHeaderCommonButtonStyles}
                            type="primary"
                            data-merchant="edit-roles-save-button"
                        >
                            <FormattedMessage {...lang.saveButton} />
                        </Button>
                    )}
                </Col>
            </Row>

            <Form
                id={FORM_ID}
                form={form}
                layout="vertical"
                size="large"
                onFinish={handleFormFinish}
                initialValues={getNormalizedFormInitData(projectRoles)}
                disabled={!isEditRolesAllowed}
            >
                {projectRoles.map(role => (
                    <Form.Item key={role.id} hidden name={[role.id, 'id']} />
                ))}
                <Divider style={{ marginBlock: '12px 0' }} />
                <Table
                    className={styles.table}
                    onHeaderRow={() => ({ style: { borderRadius: 0 } })}
                    loading={isProjectPermissionsLoading || isProjectRolesLoading}
                    locale={{ emptyText: () => null }}
                    data-merchant="edit-roles-table"
                    pagination={false}
                    dataSource={allPermissions}
                    rowKey={row => row.permission}
                    sticky={{ offsetHeader: -1 }}
                    scroll={{ y: 700 }}
                    columns={[
                        {
                            key: 'permission',
                            title: 'Permission',
                            dataIndex: 'permission',
                            render: (value: Permission | PermissionGroup | string) => {
                                const messageDescriptor = getPermissionMessageDescriptor(value)

                                return messageDescriptor ? <FormattedMessage {...messageDescriptor} /> : value
                            },
                            onCell: ({ isGroup }) => ({
                                colSpan: isGroup ? projectRoles.length + 1 : 1,
                                style: {
                                    fontWeight: isGroup ? 600 : 400,
                                    fontSize: isGroup ? 14 : 12,
                                },
                                className: cn({ [styles.groupCell!]: isGroup }),
                            }),
                        },
                        ...projectRoles.map(
                            role =>
                                ({
                                    title: role.name,
                                    align: 'center',
                                    key: role.id,
                                    width: 120,
                                    render: ({ permission }: EditRolesTableRow) => (
                                        <Form.Item
                                            noStyle
                                            name={[role.id, 'permissions', permission]}
                                            valuePropName="checked"
                                        >
                                            <Checkbox data-merchant={`edit-roles-${role.id}-${permission}-checkbox`} />
                                        </Form.Item>
                                    ),
                                    onCell: ({ isGroup }: EditRolesTableRow) => ({
                                        colSpan: isGroup ? 0 : 1,
                                        style: { textAlign: 'center' },
                                    }),
                                }) satisfies TableColumnsType<EditRolesTableRow>[number]
                        ),
                    ]}
                />
            </Form>
        </>
    )
}

export const EditRoles = withDirtyCheckingContextProvider(Component)
