import { debounce } from 'lodash-es'
import { useMemo, useState } from 'react'
import { useCallbackRef } from './useCallbackRef'
import type { Dispatch, SetStateAction } from 'react'

/**
 * useDebouncedState
 */
export function useDebouncedState<T>(initialState: T | (() => T), timeout = 300) {
    const [state, setState] = useState(initialState)
    const [isWaiting, setIsWaiting] = useState(false)

    const debouncedSetState = useMemo(
        () =>
            debounce((value: T) => {
                setState(value)
                setIsWaiting(false)
            }, timeout),
        [timeout]
    )

    const debounced = useCallbackRef((value: T) => {
        !isWaiting && setIsWaiting(true)
        debouncedSetState(value)
    })

    const setImmediately: Dispatch<SetStateAction<T>> = useCallbackRef(next => {
        setState(next)
        setIsWaiting(false)
        debouncedSetState.cancel()
    })

    return [state, debounced, setImmediately, isWaiting] as const
}
