import { NotificationMessage } from '@merchant/shared/components'
import { useNotifications } from '@merchant/shared/contexts'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { modes } from './const'
import { lang } from './lang'
import type { CreateEditFormData, DeleteFormData } from './types'
import type { CallbackResponse, Callback, TOTPCredentials } from '~api/instances/cabinet-api'
import { projectApi, useProject, useProjectCallback } from '~api'

interface Props {
    onTestCallbackSent: (c: CallbackResponse) => void
    resolveBlocklist: (cb: () => void) => void
}

export function useData({ resolveBlocklist }: Props) {
    const { data: projectData, isLoading: isProjectLoading } = useProject()
    const { notification } = useNotifications()
    const [modalMode, setModalMode] = useState<(typeof modes)[keyof typeof modes] | null>(null)
    const projectId = projectData?.id || ''

    const resetModal = () => setModalMode(null)

    const { data: callback, mutate: mutateCallback, isLoading: isCallbackLoading } = useProjectCallback()

    const regeneration = {
        send: (totps: TOTPCredentials) =>
            projectApi.updateCallback({
                projectId,
                updateCallbackRequest: { publicKey: true, ...totps },
            }),
        onSuccess: (callback: Callback) => {
            mutateCallback(callback)
            setModalMode(null)
        },
    }

    const deletion = {
        send: (values: DeleteFormData) =>
            projectApi.deleteCallback({
                projectId,
                deleteCallbackRequest: values,
            }),
        onSuccess: () => {
            mutateCallback(undefined)
            setModalMode(null)
            notification.api.open({
                message: (
                    <NotificationMessage
                        type="success"
                        title={<FormattedMessage {...lang.deletionSuccessModalTitle} />}
                        dataMerchant="callback-deletion-success-notification"
                    />
                ),
            })
        },
    }

    const patching = {
        send: (values: CreateEditFormData) =>
            projectApi.updateCallback({
                updateCallbackRequest: {
                    publicKey: modalMode === modes.callbackUrlCreate,
                    ...values,
                },
                projectId,
            }),
        onSuccess: () => {
            mutateCallback(callback)
            resetModal()
        },
    }

    const getClickHandler = (mode: (typeof modes)[keyof typeof modes]) => () =>
        resolveBlocklist(() => setModalMode(mode))

    const isDataLoading = isProjectLoading || isCallbackLoading

    return {
        patching,
        regeneration,
        deletion,
        isDataLoading,
        callback,
        modalMode,
        resetModal,
        getClickHandler,
    }
}
