import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    totalBalance: {
        id: 'total-balance.typography.total_balance',
        defaultMessage: 'Total balance',
    },
    totalBalanceTooltip: {
        id: 'total-balance.tooltip.text',
        defaultMessage:
            '<bold>Note:</bold> Your total balance is calculated using the current exchange rates for all your held cryptocurrencies, converted to the {currency}. You can change the conversion currency in the <link>settings</link>.',
    },
    nationalCurrencyText: {
        id: 'total-balance.tooltip.national_currency_text',
        defaultMessage: 'National currency',
    },
    unavailable: {
        id: 'total-balance.typography.unavailable',
        defaultMessage: 'Currently unavailable',
    },
})
