import type { CurrenciesBase } from '~types'

export function getAddressTagName<T extends CurrenciesBase>(
    currencies?: T,
    currency?: string,
    network?: string
): string | undefined {
    if (!currency || !currencies) {
        return
    }

    const selectedNetwork = currencies[currency]?.networks.find(n => n.name === network)

    return selectedNetwork ? selectedNetwork.addressTagName : currencies[currency]?.addressTagName
}

export function getAddressRegexp<T extends CurrenciesBase>(currencies?: T, currency?: string, network?: string) {
    if (!currency || !currencies || !network) {
        return
    }

    const selectedNetwork = currencies[currency]?.networks.find(n => n.name === network)

    return selectedNetwork?.addressRegex ? new RegExp(selectedNetwork.addressRegex) : undefined
}
