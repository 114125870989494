import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    checkInternetConnection: {
        id: 'request-error.typography.check_connection',
        defaultMessage: 'Please check your internet connection and try again',
    },
    tools: {
        id: 'request-error.icons.tools',
        defaultMessage: '🛠',
    },
})
