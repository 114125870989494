import { StatisticFormattedNumber } from '@merchant/shared/components'
import { Flex, Form, Typography, type FormItemProps, type SelectProps } from '@merchant/ui-kit/ant-design'
import { isEmpty } from 'lodash-es'
import { useIntl, FormattedMessage } from 'react-intl'
import { lang } from './lang'
import { NetworkSelect } from './NetworkSelect'
import type { CryptoWithdrawalModalPermissions } from '../../types'
import type { useFormValidation } from '@merchant/shared/hooks'
import type { FormItemsKey } from '@merchant/shared/types'
import type { GetWhitelistResponse } from '~api'
import type { WhitelistWithKey } from '~api/swr/rest/types'
import type { WithdrawalCurrency } from '~features/ActionModal/types'
import type { WithdrawalCryptoFormData } from '~features/ActionModal/views/WithdrawalCrypto/types'
import type { CurrencySelectOptionData, CurrencySelectOptions } from '~types/currency'
import { CurrencyOption, ResponsiveDropdown, SearchableSelect } from '~components'
import { withdrawalCryptoFormKeys } from '~features/ActionModal/views/WithdrawalCrypto/const'
import { globalLang } from '~globalLang'
import compatSelectStyle from '~styles/compact.style.module.css'
import style from '~styles/select-groups.style.module.css'
import { getIfCurrencyWithdrawable, getWithdrawalOptionGroups } from '~utils'

const { Text } = Typography

const getCurrencySelect = (
    componentProps: SelectProps,
    currencyOptions: Props['currencySelectOptions'] | undefined,
    config: { showExtension: boolean }
) => {
    const { payoutDisabledCurrencies = {}, payoutEnabledCurrencies = {} } = currencyOptions || {}
    const getDisplayComponent = (currency: WithdrawalCurrency) => {
        const isWithdrawable = getIfCurrencyWithdrawable(currency)

        return (
            <CurrencyOption
                dataMerchant={`withdrawal-currency-item-${currency.code}`}
                data={currency}
                extension={
                    !isWithdrawable ? (
                        <Text data-merchant={null} style={{ paddingLeft: 8 }}>
                            <FormattedMessage {...globalLang.withdrawalsTempUnavailable} />
                        </Text>
                    ) : (
                        config.showExtension && (
                            <Text
                                data-merchant={null}
                                type="secondary"
                                style={{
                                    opacity: currency.amount === '0' ? 0.5 : 1,
                                }}
                            >
                                <StatisticFormattedNumber value={currency.amount} suffix={currency.code} />
                            </Text>
                        )
                    )
                }
            />
        )
    }

    const selectOptions = getWithdrawalOptionGroups(currencyOptions) satisfies SelectProps['options']

    return (
        <SearchableSelect<string, CurrencySelectOptionData & { label: JSX.Element | null; disabled?: boolean }>
            // TODO: fix type once it is possible to specify select option groups type using antd Select
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            options={selectOptions}
            popupClassName={style.popup}
            fieldNames={{ value: 'code' }}
            optionFilterProp="key"
            labelRender={props => {
                const currency = payoutDisabledCurrencies[props.value] || payoutEnabledCurrencies[props.value]

                return currency ? getDisplayComponent(currency) : props.label
            }}
            optionRender={option => getDisplayComponent(option.data)}
            {...componentProps}
        />
    )
}

interface Props {
    validationMode: ReturnType<typeof useFormValidation<keyof FormItemsKey<WithdrawalCryptoFormData>>>['validationMode']
    onBlur: ReturnType<typeof useFormValidation<keyof FormItemsKey<WithdrawalCryptoFormData>>>['onBlur']
    currencySelectOptions: CurrencySelectOptions
    cryptoWithdrawalPermissions: CryptoWithdrawalModalPermissions
    isWhitelistEnabled: GetWhitelistResponse['enabled'] | undefined
    selectedCurrencyData: WithdrawalCurrency | undefined
    onCurrencyChange: (code: string) => void
    selectedWhitelist: WhitelistWithKey | undefined
}

// eslint-disable-next-line max-lines-per-function
export function CurrencyNetworkItem({
    validationMode,
    onBlur,
    cryptoWithdrawalPermissions,
    isWhitelistEnabled,
    currencySelectOptions,
    selectedCurrencyData,
    onCurrencyChange,
    selectedWhitelist,
}: Props) {
    const form = Form.useFormInstance()
    const intl = useIntl()
    const selectCurrencyPlaceholder = intl.formatMessage(lang.selectCurrencyPlaceholder)
    const selectNetworkPlaceholder = intl.formatMessage(lang.selectNetworkPlaceholder)

    const {
        currencyFormItemProps,
        networkFormItemProps,
    }: Record<'currencyFormItemProps' | 'networkFormItemProps', FormItemProps> = {
        currencyFormItemProps: {
            validateTrigger: validationMode.currency,
            name: withdrawalCryptoFormKeys.currency,
            noStyle: !isWhitelistEnabled,
            rules: [
                {
                    required: true,
                    message: (
                        <span data-merchant="withdrawal-currency-required-error">
                            <FormattedMessage {...globalLang.requiredFieldMessage} />
                        </span>
                    ),
                },
            ],

            ...(isWhitelistEnabled && {
                label: intl.formatMessage(lang.selectCurrencyLabel),
            }),
        },
        networkFormItemProps: {
            noStyle: !isWhitelistEnabled,
            name: withdrawalCryptoFormKeys.network,
            dependencies: [withdrawalCryptoFormKeys.currency],
            rules: [
                {
                    required: !isEmpty(selectedCurrencyData?.networks),
                    message: (
                        <span data-merchant="withdrawal-network-required-error">
                            <FormattedMessage {...globalLang.requiredFieldMessage} />
                        </span>
                    ),
                },
            ],
            // if rendered conditionally network is not set on whitelist selection causing validation failure
            hidden: isWhitelistEnabled,

            ...(isWhitelistEnabled && {
                label: intl.formatMessage(globalLang.network),
            }),
        },
    }

    const {
        bottomSelectClassName = '',
        topSelectClassName = '',
    }: { bottomSelectClassName?: string; topSelectClassName?: string } = !isWhitelistEnabled
        ? {
              topSelectClassName: compatSelectStyle.selectCompactTopItem,
              bottomSelectClassName: compatSelectStyle.selectCompactBottomItem,
          }
        : {}

    return (
        <Form.Item label={<FormattedMessage {...lang.currencyAndNetwork} />} noStyle={isWhitelistEnabled}>
            <Flex vertical gap={!isWhitelistEnabled ? 4 : undefined}>
                <Form.Item {...currencyFormItemProps}>
                    <ResponsiveDropdown<SelectProps>
                        data-merchant="withdrawal-currency-select"
                        className={topSelectClassName}
                        renderMobile={(props, commonProps) =>
                            getCurrencySelect(
                                {
                                    ...props,
                                    style: { maxWidth: undefined, width: '100%' },
                                    dropdownStyle: { overflow: 'auto' },
                                    size: 'large',
                                    onSelect: commonProps.closeMobileDropdown,
                                },
                                currencySelectOptions,
                                {
                                    showExtension: cryptoWithdrawalPermissions.see_balance,
                                }
                            )
                        }
                        render={(props, commonProps) =>
                            getCurrencySelect(
                                {
                                    ...props,
                                    onClick: commonProps.openMobileDropdown,
                                },
                                currencySelectOptions,
                                {
                                    showExtension: cryptoWithdrawalPermissions.see_balance,
                                }
                            )
                        }
                        title={lang.selectCurrencyLabel}
                        onChange={value => {
                            onCurrencyChange(value)

                            form.setFieldsValue({
                                [withdrawalCryptoFormKeys.whitelistKey]: undefined,
                            })
                            form.setFieldsValue({
                                [withdrawalCryptoFormKeys.network]: undefined,
                            })
                        }}
                        onBlur={onBlur(withdrawalCryptoFormKeys.currency)}
                        placeholder={selectCurrencyPlaceholder}
                        showSearch
                        allowClear={!isWhitelistEnabled}
                    />
                </Form.Item>
                <Form.Item {...networkFormItemProps}>
                    <ResponsiveDropdown<SelectProps>
                        disabled={!!selectedWhitelist?.network}
                        className={bottomSelectClassName}
                        data-merchant="withdrawal-network-select"
                        placeholder={selectNetworkPlaceholder}
                        title={globalLang.network}
                        renderMobile={(props, commonProps) => (
                            <NetworkSelect
                                {...props}
                                listHeight={commonProps.listHeight}
                                onSelect={commonProps.closeMobileDropdown}
                                selectedCurrencyData={selectedCurrencyData}
                                style={{ maxWidth: undefined, width: '100%' }}
                                dropdownStyle={{ overflow: 'auto' }}
                                size="large"
                            />
                        )}
                        render={(props, commonProps) => (
                            <NetworkSelect
                                {...props}
                                onClick={commonProps.openMobileDropdown}
                                selectedCurrencyData={selectedCurrencyData}
                            />
                        )}
                    />
                </Form.Item>
            </Flex>
        </Form.Item>
    )
}
