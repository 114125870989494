import { assets } from '@merchant/shared/assets/nameMap'
import { AmountInput, AppSvg, EmailSupportLink } from '@merchant/shared/components'
import { useFormValidation } from '@merchant/shared/hooks'
import { getAddressTagName } from '@merchant/shared/utils'
import { Alert, Button, Divider, Dropdown, Flex, Form, Input, Typography } from '@merchant/ui-kit/ant-design'
import { lowerFirst, upperFirst } from 'lodash-es'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useBoolean } from 'usehooks-ts'
import { withdrawalCryptoFormKeys } from '../../const'
import { getAmountValueByMode } from '../../utils'
import { CurrencyNetworkItem, WhitelistedAddressSelect } from './components'
import { ReadonlyFormElement } from './components/ReadonlyFormElement'
import { formOrders } from './const'
import { lang } from './lang'
import { useData } from './useData'
import { useHandlers } from './useHandlers'
import { useRequests } from './useRequests'
import { validateAmount, getAddressFormItemRules, getAmountFormItemRules, getIfAmountIsLessThanFee } from './utils'
import type { WithdrawalCryptoElements } from './const'
import type { WithdrawalAmountMode, WithdrawalCryptoFormData } from '../../types'
import type { ReactNode } from 'react'
import { AvailableAmountExtra } from '~components'
import { ModalNames } from '~constants/modal'
import { AmountFormItem } from '~features/FormItems'
import { globalLang } from '~globalLang'
import { useRegisterDirtyFormChecker } from '~hooks'

const { Text, Paragraph } = Typography

interface Props {
    withdrawalFormData: WithdrawalCryptoFormData
    onSuccess: (withdrawalData: WithdrawalCryptoFormData) => void
}

// eslint-disable-next-line max-lines-per-function
export function WithdrawalInput({ onSuccess, withdrawalFormData }: Props) {
    const {
        withdrawalCurrencies,
        addressTag,
        balanceAmount,
        form,
        selectedCurrencyData,
        selectedNetworkData,
        whitelist,
        selectedWhitelist,
        selectedNetworkCode,
        withdrawalAmountPrecision,
        onCurrencyChange,
        cryptoWithdrawalPermissions,
        currencySelectOptions,
    } = useData()
    const [formOrder, setFormOrder] = useState<WithdrawalCryptoElements[]>(
        whitelist?.enabled ? formOrders.whitelistEnabled : formOrders.default
    )
    const amountMode = Form.useWatch(withdrawalCryptoFormKeys.amountMode, form)
    const amount = Form.useWatch(withdrawalCryptoFormKeys.amount, form)

    const { value: isWaitingDebounce, setValue: setIsWaitingDebounce } = useBoolean(false)
    const { value: isFormSubmitted, setTrue: setFormSubmitted } = useBoolean(false)
    const intl = useIntl()
    const { onBlur, onFinishFailed, validationMode } = useFormValidation(Object.values(withdrawalCryptoFormKeys))

    const { sendGetWithdrawalFee, isWithdrawalFeeLoading } = useRequests({
        form,
    })

    const { handleValueChange, handleFormFinish, handleBalanceClick, handleFormFinishFailed } = useHandlers({
        form,
        sendGetWithdrawalFee,
        setIsWaitingDebounce,
        withdrawalCurrencies,
        onSuccess,
        selectedCurrencyData,
        withdrawalAmountPrecision,
        onFinishFailed,
        cryptoWithdrawalPermissions,
        setFormSubmitted,
    })

    useEffect(() => {
        const { currency, network, amount } = withdrawalFormData
        if (currency && !!validateAmount(amount)) {
            sendGetWithdrawalFee({ amount, receiveCurrency: currency, receiveNetwork: network })
        }
    }, [sendGetWithdrawalFee, withdrawalFormData])

    useEffect(() => {
        if (whitelist?.enabled) {
            setFormOrder(formOrders.whitelistEnabled)
        } else {
            setFormOrder(formOrders.default)
        }
    }, [whitelist?.enabled])

    useRegisterDirtyFormChecker(ModalNames.withdrawalCryptoInput, form)

    const selectedCurrencyCodeSuffix = selectedCurrencyData?.code || <span />

    const addressTagName =
        getAddressTagName(withdrawalCurrencies, selectedCurrencyData?.code, selectedNetworkCode) ||
        intl.formatMessage(globalLang.addressTag)

    const whitelistAfterSelect = ({
        network,
        currency,
        address,
        addressTag,
    }: Pick<WithdrawalCryptoFormData, 'address' | 'addressTag' | 'currency' | 'network'>) => {
        form.setFields([
            {
                name: withdrawalCryptoFormKeys.network,
                value: network || undefined,
                errors: [],
            },
            {
                name: withdrawalCryptoFormKeys.currency,
                value: currency || selectedCurrencyData?.code,
                errors: [],
            },
            {
                name: withdrawalCryptoFormKeys.address,
                value: address,
                errors: [],
            },
            {
                name: withdrawalCryptoFormKeys.addressTag,
                value: addressTag,
                errors: [],
            },
            { name: withdrawalCryptoFormKeys.amount, errors: [] },
            { name: withdrawalCryptoFormKeys.receiveAmount, value: undefined },
            { name: withdrawalCryptoFormKeys.fee, value: undefined },
        ])
        handleValueChange({ currency: currency || undefined, network: network || undefined }, form.getFieldsValue())
    }

    const handleWhitelistClear = () =>
        form.setFields([
            {
                name: withdrawalCryptoFormKeys.network,
                value: undefined,
                errors: [],
            },
            {
                name: withdrawalCryptoFormKeys.receiveAmount,
                value: undefined,
                errors: [],
            },
            {
                name: withdrawalCryptoFormKeys.fee,
                value: undefined,
                errors: [],
            },
        ])

    const isWaitingFee = isWithdrawalFeeLoading || isWaitingDebounce
    const formElements: Record<WithdrawalCryptoElements, ReactNode> = {
        currencyWithNetwork: (
            <CurrencyNetworkItem
                cryptoWithdrawalPermissions={cryptoWithdrawalPermissions}
                isWhitelistEnabled={whitelist?.enabled}
                onBlur={onBlur}
                onCurrencyChange={onCurrencyChange}
                selectedCurrencyData={selectedCurrencyData}
                selectedWhitelist={selectedWhitelist}
                validationMode={validationMode}
                currencySelectOptions={currencySelectOptions}
            />
        ),
        address: (
            <Form.Item
                validateTrigger={[validationMode.address, 'onChange']}
                dependencies={[withdrawalCryptoFormKeys.network]}
                name={withdrawalCryptoFormKeys.address}
                label={<FormattedMessage {...lang.walletAddressLabel} />}
                rules={getAddressFormItemRules(whitelist, selectedNetworkData)}
                hidden={whitelist?.enabled}
            >
                <Input
                    data-merchant="withdrawal-address-input"
                    placeholder={intl.formatMessage(lang.walletAddressPlaceholder)}
                    onBlur={onBlur(withdrawalCryptoFormKeys.address)}
                />
            </Form.Item>
        ),
        whitelistKey: (
            <Form.Item
                validateTrigger={validationMode.whitelistKey}
                dependencies={[withdrawalCryptoFormKeys.network]}
                name={withdrawalCryptoFormKeys.whitelistKey}
                label={<FormattedMessage {...lang.walletAddressLabel} />}
                rules={[
                    {
                        required: true,
                        message: (
                            <span data-merchant="withdrawal-whitelist-required-error">
                                <FormattedMessage {...globalLang.requiredFieldMessage} />
                            </span>
                        ),
                    },
                ]}
            >
                <WhitelistedAddressSelect
                    onBlur={onBlur(withdrawalCryptoFormKeys.whitelistKey)}
                    selectedCurrencyData={selectedCurrencyData}
                    afterSelect={whitelistAfterSelect}
                    onClear={handleWhitelistClear}
                />
            </Form.Item>
        ),
        addressTag: (
            <>
                <Form.Item
                    label={upperFirst(addressTagName)}
                    hidden={whitelist?.enabled || !addressTag}
                    name={withdrawalCryptoFormKeys.addressTag}
                    dependencies={[withdrawalCryptoFormKeys.network]}
                >
                    <Input
                        data-merchant="withdrawal-address-tag-input"
                        onBlur={onBlur(withdrawalCryptoFormKeys.addressTag)}
                        placeholder={intl.formatMessage(lang.enterTagName, {
                            tagName: lowerFirst(addressTagName),
                        })}
                    />
                </Form.Item>
                <Form.Item hidden={!addressTag}>
                    <Alert
                        data-merchant="withdrawal-address-tag-alert"
                        message={
                            <Text data-merchant={null} style={{ opacity: '0.6' }}>
                                <FormattedMessage
                                    {...lang.tagAlertMessage}
                                    values={{
                                        tagName: upperFirst(addressTagName),
                                    }}
                                />
                            </Text>
                        }
                        type="warning"
                        showIcon
                        icon={<AppSvg size={24} name={assets.alertCircle} />}
                    />
                </Form.Item>
            </>
        ),
        amount: (
            <AmountFormItem
                form={form}
                precision={withdrawalAmountPrecision}
                name={[withdrawalCryptoFormKeys.amount, amountMode]}
                label={
                    <Flex gap={4} style={{ marginTop: 4 }}>
                        <FormattedMessage
                            {...(amountMode === 'receive' ? lang.receiveAmount : lang.withdrawalAmount)}
                        />
                        <Dropdown
                            trigger={['click']}
                            data-merchant="withdrawal-amount-mode-dropdown"
                            menu={{
                                'data-merchant': 'withdrawal-amount-mode-dropdown-menu',
                                selectedKeys: [amountMode],
                                onClick: ({ key }) => {
                                    const newAmountMode = key as WithdrawalAmountMode
                                    const amountValue = getAmountValueByMode(amount, amountMode) || ''
                                    form.setFields([
                                        {
                                            name: [withdrawalCryptoFormKeys.amount, newAmountMode],
                                            value: amountValue,
                                            errors: [],
                                        },
                                        {
                                            name: withdrawalCryptoFormKeys.amountMode,
                                            value: key,
                                            errors: [],
                                        },
                                    ])
                                    const changedValues =
                                        newAmountMode === 'credit'
                                            ? { amount: { credit: amountValue } }
                                            : { amount: { receive: amountValue } }
                                    setTimeout(() => handleValueChange(changedValues, form.getFieldsValue()))
                                },
                                items: [
                                    {
                                        style: { padding: 8 },
                                        label: (
                                            <>
                                                <Paragraph
                                                    data-merchant="withdrawal-amount-mode-dropdown-withdraw-title"
                                                    style={{ marginBottom: 0, fontWeight: 500 }}
                                                >
                                                    <FormattedMessage {...lang.withdrawalAmount} />
                                                </Paragraph>
                                                <Text
                                                    data-merchant="withdrawal-amount-mode-dropdown-withdraw-description"
                                                    type="secondary"
                                                    style={{ fontSize: 12 }}
                                                >
                                                    <FormattedMessage {...lang.withdrawalAmountDescription} />
                                                </Text>
                                            </>
                                        ),
                                        type: 'item',
                                        key: 'credit',
                                    },
                                    {
                                        label: (
                                            <>
                                                <Paragraph
                                                    data-merchant="withdrawal-amount-mode-dropdown-receive-title"
                                                    style={{ marginBottom: 0, fontWeight: 500 }}
                                                >
                                                    <FormattedMessage {...lang.receiveAmount} />
                                                </Paragraph>
                                                <Text
                                                    data-merchant="withdrawal-amount-mode-dropdown-receive-description"
                                                    type="secondary"
                                                    style={{ fontSize: 12 }}
                                                >
                                                    <FormattedMessage {...lang.receiveAmountDescription} />
                                                </Text>
                                            </>
                                        ),
                                        style: { padding: 8 },
                                        type: 'item',
                                        key: 'receive',
                                    },
                                ],
                            }}
                        >
                            <Button
                                data-merchant="withdrawal-amount-change-mode-button"
                                styles={{ icon: { display: 'inline-flex' } }}
                                size="small"
                                shape="circle"
                                icon={<AppSvg name={assets.chevronDown} size={14} />}
                            />
                        </Dropdown>
                    </Flex>
                }
                validateTrigger={['onSubmit', 'onBlur']}
                dependencies={[withdrawalCryptoFormKeys.currency, withdrawalCryptoFormKeys.network]}
                rules={getAmountFormItemRules(balanceAmount, cryptoWithdrawalPermissions, isFormSubmitted)}
                extra={
                    cryptoWithdrawalPermissions.see_balance && (
                        <AvailableAmountExtra
                            selectedAmountType={amountMode}
                            isError={() => {
                                if (!selectedCurrencyData) {
                                    return false
                                }

                                return (
                                    Number(form.getFieldValue(withdrawalCryptoFormKeys.withdrawalAmount)) >
                                    Number(balanceAmount)
                                )
                            }}
                            selectedCurrencyData={selectedCurrencyData}
                            data-merchant="withdrawal-fiat-available-balance-button"
                            onClick={handleBalanceClick}
                        />
                    )
                }
            >
                <AmountInput
                    placeholder="0"
                    onBlur={onBlur(withdrawalCryptoFormKeys.amount)}
                    precision={withdrawalAmountPrecision}
                    data-merchant="withdrawal-amount-input"
                    style={{ width: '100%' }}
                    suffix={selectedCurrencyCodeSuffix}
                />
            </AmountFormItem>
        ),
        withdrawalAmount: (
            <Form.Item
                dependencies={[
                    withdrawalCryptoFormKeys.currency,
                    withdrawalCryptoFormKeys.network,
                    withdrawalCryptoFormKeys.amount,
                ]}
                style={{ marginBottom: 0 }}
                layout="horizontal"
                label={
                    <Text data-merchant="withdrawal-amount-label" type="secondary">
                        <FormattedMessage {...lang.withdrawalAmountLabel} />
                    </Text>
                }
                tooltip={{
                    overlayInnerStyle: { width: 350 },
                    'data-merchant': null,
                    title: (
                        <Text data-merchant="withdrawal-amount-tooltip" type="secondary">
                            <FormattedMessage {...lang.withdrawalAmountTooltip} values={{ break: () => <br /> }} />
                        </Text>
                    ),
                }}
                name={withdrawalCryptoFormKeys.withdrawalAmount}
                wrapperCol={{ style: { flex: 'none', marginLeft: 'auto' } }}
            >
                <ReadonlyFormElement
                    isError={() =>
                        form.getFieldValue(withdrawalCryptoFormKeys.withdrawalAmount) >
                        Number(selectedCurrencyData?.amount)
                    }
                    dataMerchant="withdrawal-amount"
                    isLoading={isWaitingFee}
                    postfix={selectedCurrencyData?.code}
                />
            </Form.Item>
        ),
        fee: (
            <Form.Item
                dependencies={[
                    withdrawalCryptoFormKeys.currency,
                    withdrawalCryptoFormKeys.network,
                    withdrawalCryptoFormKeys.amount,
                ]}
                name={withdrawalCryptoFormKeys.fee}
                style={{ marginBottom: 0 }}
                tooltip={{
                    overlayInnerStyle: { width: 350 },
                    'data-merchant': null,
                    title: (
                        <Text data-merchant="withdrawal-fee-tooltip" type="secondary">
                            <FormattedMessage
                                {...lang.feeTooltip}
                                values={{ supportLink: chunks => <EmailSupportLink>{chunks}</EmailSupportLink> }}
                            />
                        </Text>
                    ),
                }}
                wrapperCol={{ style: { flex: 'none', marginLeft: 'auto' } }}
                label={
                    <Text data-merchant="withdrawal-fee-label" type="secondary">
                        <FormattedMessage {...lang.feeLabel} />
                    </Text>
                }
                layout="horizontal"
            >
                <ReadonlyFormElement
                    dataMerchant="withdrawal-fee"
                    isError={() => getIfAmountIsLessThanFee(form.getFieldValue)}
                    isLoading={isWaitingFee}
                    postfix={selectedCurrencyData?.code}
                />
            </Form.Item>
        ),
        receiveAmount: (
            <Form.Item
                dependencies={[
                    withdrawalCryptoFormKeys.currency,
                    withdrawalCryptoFormKeys.network,
                    withdrawalCryptoFormKeys.amount,
                ]}
                name={withdrawalCryptoFormKeys.receiveAmount}
                wrapperCol={{ style: { flex: 'none', marginLeft: 'auto' } }}
                label={
                    <Text data-merchant="withdrawal-receive-label" type="secondary">
                        <FormattedMessage {...lang.receiveAmountLabel} />
                    </Text>
                }
                tooltip={{
                    overlayInnerStyle: { width: 350 },
                    'data-merchant': null,
                    title: (
                        <Text data-merchant="withdrawal-receive-tooltip" type="secondary">
                            <FormattedMessage {...lang.receiveAmountTooltip} values={{ break: () => <br /> }} />
                        </Text>
                    ),
                }}
                layout="horizontal"
            >
                <ReadonlyFormElement
                    dataMerchant="withdrawal-receive-amount"
                    isLoading={isWaitingFee}
                    postfix={selectedCurrencyData?.code}
                />
            </Form.Item>
        ),
        whitelistAlert: (
            <Form.Item noStyle dependencies={[withdrawalCryptoFormKeys.whitelistKey]}>
                {({ getFieldValue }) =>
                    !getFieldValue(withdrawalCryptoFormKeys.whitelistKey) && (
                        <div style={{ paddingTop: 8 }}>
                            <Alert
                                data-merchant="withdrawal-enabled-whitelist-alert"
                                style={{ alignItems: 'center' }}
                                type="warning"
                                showIcon
                                icon={<AppSvg size={24} name={assets.alertCircle} />}
                                description={intl.formatMessage(lang.whitelistEnabledWarning)}
                            />
                        </div>
                    )
                }
            </Form.Item>
        ),
        divider: <Divider style={{ margin: '20px 0 16px' }} />,
        note: (
            <Form.Item
                name={withdrawalCryptoFormKeys.note}
                tooltip={{
                    'data-merchant': 'withdrawal-note-tooltip',
                    placement: 'bottom',
                    title: intl.formatMessage(lang.noteTooltip),
                }}
                label={
                    <Flex>
                        <FormattedMessage {...lang.noteLabel} />
                    </Flex>
                }
            >
                <Input
                    data-merchant="withdrawal-note"
                    placeholder={intl.formatMessage(lang.notePlaceholder)}
                    suffix={
                        <Text data-merchant={null} type="secondary">
                            <FormattedMessage {...globalLang.optional} />
                        </Text>
                    }
                />
            </Form.Item>
        ),
        amountMode: <Form.Item hidden noStyle name={withdrawalCryptoFormKeys.amountMode} />,
    }

    return (
        <Form
            form={form}
            requiredMark={false}
            layout="vertical"
            size="large"
            onFinish={handleFormFinish}
            onValuesChange={handleValueChange}
            initialValues={withdrawalFormData}
            onFinishFailed={handleFormFinishFailed}
            autoComplete="off"
            preserve={false}
        >
            {formOrder.map((key, index) => (
                <React.Fragment key={key + index}>{formElements[key]}</React.Fragment>
            ))}
            <Form.Item noStyle>
                <Button
                    htmlType="submit"
                    data-merchant="withdrawal-input-submit-button"
                    type="primary"
                    block
                    disabled={isWaitingFee || !cryptoWithdrawalPermissions.commit_crypto_withdrawals}
                >
                    <FormattedMessage {...globalLang.continue} />
                </Button>
            </Form.Item>
        </Form>
    )
}
