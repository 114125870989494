import { Button, Form, Input, type FormProps } from '@merchant/ui-kit/ant-design'
import { pick } from 'lodash-es'
import { FormattedMessage, useIntl } from 'react-intl'
import { RolesSelect } from '../RolesSelect'
import { lang } from './lang'
import type { FormItemsKey } from '@merchant/shared/types'
import type { ProjectTeamMember } from '~api/instances/cabinet-api'
import { useEditableProjectRoles } from '~api'
import { ModalNames } from '~constants/modal'
import { globalLang } from '~globalLang'
import { useRegisterDirtyFormChecker } from '~hooks'

type FormKeys = Pick<ProjectTeamMember, 'name' | 'roles'>

const formKeys: FormItemsKey<FormKeys> = {
    name: 'name',
    roles: 'roles',
}

interface Props extends Pick<FormProps, 'onFinish'> {
    editedMember: ProjectTeamMember | undefined
    initialSelectedMember: ProjectTeamMember | undefined
}

export function EditView({ editedMember, onFinish, initialSelectedMember }: Props) {
    const [form] = Form.useForm()
    const intl = useIntl()
    const { data: projectRoles } = useEditableProjectRoles()

    useRegisterDirtyFormChecker(ModalNames.editTeamMember, {
        initial: () => pick(initialSelectedMember, Object.values(formKeys)),
        current: form.getFieldsValue,
    })

    const emailLabel = intl.formatMessage(lang.emailLabel)
    const nameLabel = intl.formatMessage(lang.nameLabel)
    const roleLabel = intl.formatMessage(lang.roleLabel)

    return (
        <Form form={form} onFinish={onFinish} layout="vertical" size="large" initialValues={editedMember}>
            <Form.Item label={emailLabel}>
                <Input
                    value={editedMember?.email}
                    placeholder={emailLabel}
                    disabled
                    data-merchant="edit-member-email-input"
                />
            </Form.Item>
            <Form.Item
                name={formKeys.name}
                label={nameLabel}
                rules={[
                    {
                        required: true,
                        message: (
                            <span data-merchant="edit-member-name-required-message-error">
                                <FormattedMessage {...globalLang.requiredFieldMessage} />
                            </span>
                        ),
                    },
                ]}
            >
                <Input placeholder={nameLabel} data-merchant="edit-member-name-input" />
            </Form.Item>
            <Form.Item
                name={formKeys.roles}
                label={roleLabel}
                rules={[
                    {
                        required: true,
                        message: (
                            <span data-merchant="edit-member-roles-required-message-error">
                                <FormattedMessage {...globalLang.requiredFieldMessage} />
                            </span>
                        ),
                    },
                ]}
            >
                <RolesSelect
                    context="edit"
                    options={projectRoles}
                    roleLabel={roleLabel}
                    data-merchant="edit-member-role"
                />
            </Form.Item>
            <Form.Item noStyle>
                <Button block type="primary" htmlType="submit" data-merchant="edit-member-save-changes-button">
                    <FormattedMessage {...lang.saveChangesButton} />
                </Button>
            </Form.Item>
        </Form>
    )
}
