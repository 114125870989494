import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    balance: {
        id: 'convert-input.button.balance',
        defaultMessage: 'Balance',
    },
    convert: {
        id: 'convert-input.button.convert',
        defaultMessage: 'Convert',
    },
    exchangeRate: {
        id: 'convert-input.typography.exchange_rate',
        defaultMessage: 'Exchange rate',
    },
    floatingRateDescription: {
        id: 'convert-input.typography.floating_rate_description',
        defaultMessage:
            'The floating rate can change at any point due to market conditions, so you might receive more or less crypto than expected.',
    },
    estimatedSwapTime: {
        id: 'convert-input.typography.estimated_swap_time',
        defaultMessage: 'The estimated swap time is 2-3 minutes.',
    },
    currencyNotSupportedToWithdrawal: {
        id: 'convert-input.typography.not_supported_withdrawal',
        defaultMessage:
            '<tag>Notice</tag>: You are free to exchange your assets into any currency. However, please be aware that withdrawals are not permitted in {Currency}. You may need to convert your currency back before withdrawal',
    },
    notEnoughCoins: {
        id: 'convert-input.typography.not_enough_coins',
        defaultMessage: 'Not enough {Currency}',
    },
    amountBelowMinimum: {
        id: 'convert-input.typography.amount_below_minimum',
        defaultMessage: 'Amount is below minimum',
    },
    notSupportedPair: {
        id: 'convert-input.typography.not_supported_pair',
        defaultMessage: 'Pair not supported. Choose another currency',
    },
    notConvertibleCurrency: {
        id: 'convert-input.typography.not_convertible_currency',
        defaultMessage: 'Selected currency cannot be converted',
    },
    fiatCurrencyNotSupportedToWithdrawal: {
        id: 'convert-input.typography.fiat_not_supported_withdrawal',
        defaultMessage:
            '<tag>Notice</tag>: You are free to exchange your assets into any currency. However, please be aware that withdrawals are not permitted in {Currency}. You may need to convert your currency back before withdrawal. Please contact our <support>support</support> for direct withdrawal in this currency. ',
    },
    delimiter: {
        id: 'convert-input.typography.delimiter',
        defaultMessage: '·',
    },
    minimum: {
        id: 'convert-input.typography.minimum',
        defaultMessage: 'Min:',
    },
})
