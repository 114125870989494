import { protocolValidator } from '@merchant/shared/utils'
import { FormattedMessage } from 'react-intl'
import type { FormItemProps } from '@merchant/ui-kit/ant-design'
import { globalLang } from '~globalLang'

export const urlRules: FormItemProps['rules'] = [
    {
        type: 'url',
        message: (
            <span data-merchant="order-invalid-url-format-error">
                <FormattedMessage {...globalLang.invalidUrlFormat} />
            </span>
        ),
    },
    {
        message: (
            <span data-merchant="order-missing-protocol-error">
                <FormattedMessage {...globalLang.missingProtocol} />
            </span>
        ),
        validator: protocolValidator,
    },
]

export const DEFAULT_OPTION_VALUE = 'default'
export const CUSTOM_OPTION_VALUE = 'custom'
