import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    enableFormModalTitle: {
        id: 'whitelist-page.modal.enable_form_modal_title',
        defaultMessage: 'Enable Whitelist',
    },
    confirmationModalTitle: {
        id: 'whitelist-page.modal.confirmation_modal_title',
        defaultMessage: 'Confirm',
    },
    confirmationButtonTitle: {
        id: 'whitelist-page.modal.confirmation_button_title',
        defaultMessage: 'Confirm',
    },
})
