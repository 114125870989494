import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    noMerchantTitle: {
        id: 'history-page.typography.no_merchant_title',
        defaultMessage: '🚀',
    },
    noMerchantSubtitle: {
        id: 'history-page.typography.no_merchant_subtitle',
        defaultMessage: 'Start earning',
    },
    noMerchantText: {
        id: 'history-page.typography.no_merchant_text',
        defaultMessage: 'Before accessing all features, set up your merchant profile',
    },
    noMerchantButton: {
        id: 'history-page.button.button_title',
        defaultMessage: 'Set up your merchant',
    },
    setupTwoFaTitle: {
        id: 'integrations-page.typography.setup_two_fa_title',
        defaultMessage: '🔓',
    },
    setupTwoFaSubtitle: {
        id: 'integrations-page.typography.setup_two_fa_subtitle',
        defaultMessage: 'Secure Your Account',
    },
    setupTwoFaText: {
        id: 'integrations-page.typography.setup_two_fa_text',
        defaultMessage: 'Activate 2FA for enhanced protection before setting up other features',
    },
    setupTwoFaButton: {
        id: 'integrations-page.button.button_title',
        defaultMessage: 'Set Up 2FA',
    },
    noHoldingCurrenciesTitle: {
        id: 'payment-constructor.placeholder.no_holding_currencies_title',
        defaultMessage: '💰',
    },
    noHoldingCurrenciesSubtitle: {
        id: 'payment-constructor.placeholder.no_holding_currencies_subtitle',
        defaultMessage: 'Finish setting up',
    },
    noHoldingCurrenciesText: {
        id: 'payment-constructor.placeholder.no_holding_currencies_text',
        defaultMessage: 'To start creating orders set up your currencies',
    },
    noHoldingCurrenciesButton: {
        id: 'payment-constructor.placeholder.no_holding_currencies_button',
        defaultMessage: 'Set up currencies settings',
    },
})
