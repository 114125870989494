/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FeesPayer } from './FeesPayer';
import {
    FeesPayerFromJSON,
    FeesPayerFromJSONTyped,
    FeesPayerToJSON,
} from './FeesPayer';
import type { LossConfig } from './LossConfig';
import {
    LossConfigFromJSON,
    LossConfigFromJSONTyped,
    LossConfigToJSON,
} from './LossConfig';
import type { ProjectStatus } from './ProjectStatus';
import {
    ProjectStatusFromJSON,
    ProjectStatusFromJSONTyped,
    ProjectStatusToJSON,
} from './ProjectStatus';

/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    ownerId?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     * @deprecated
     */
    merchantId: string;
    /**
     * 
     * @type {ProjectStatus}
     * @memberof Project
     */
    status: ProjectStatus;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    link?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    memberName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Project
     */
    roleIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    supportEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    redirectUrlSuccess?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    redirectUrlFailure?: string;
    /**
     * 
     * @type {FeesPayer}
     * @memberof Project
     */
    feesPayer: FeesPayer;
    /**
     * 
     * @type {LossConfig}
     * @memberof Project
     */
    lossConfig: LossConfig;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    fiatWithdrawal: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Project
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Project
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the Project interface.
 */
// @ts-ignore
export function instanceOfProject(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "merchantId" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "feesPayer" in value;
    isInstance = isInstance && "lossConfig" in value;
    isInstance = isInstance && "fiatWithdrawal" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;

    return isInstance;
}

// @ts-ignore
export function ProjectFromJSON(json: any): Project {
    return ProjectFromJSONTyped(json, false);
}

// @ts-ignore
export function ProjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): Project {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'ownerId': !exists(json, 'owner_id') ? undefined : json['owner_id'],
        'merchantId': json['merchant_id'],
        'status': ProjectStatusFromJSON(json['status']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'link': !exists(json, 'link') ? undefined : json['link'],
        'memberName': !exists(json, 'member_name') ? undefined : json['member_name'],
        'roleIds': !exists(json, 'role_ids') ? undefined : json['role_ids'],
        'supportEmail': !exists(json, 'support_email') ? undefined : json['support_email'],
        'redirectUrlSuccess': !exists(json, 'redirect_url_success') ? undefined : json['redirect_url_success'],
        'redirectUrlFailure': !exists(json, 'redirect_url_failure') ? undefined : json['redirect_url_failure'],
        'feesPayer': FeesPayerFromJSON(json['fees_payer']),
        'lossConfig': LossConfigFromJSON(json['loss_config']),
        'fiatWithdrawal': json['fiat_withdrawal'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
    };
}

// @ts-ignore
export function ProjectToJSON(value?: Project | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'owner_id': value.ownerId,
        'merchant_id': value.merchantId,
        'status': ProjectStatusToJSON(value.status),
        'name': value.name,
        'description': value.description,
        'link': value.link,
        'member_name': value.memberName,
        'role_ids': value.roleIds,
        'support_email': value.supportEmail,
        'redirect_url_success': value.redirectUrlSuccess,
        'redirect_url_failure': value.redirectUrlFailure,
        'fees_payer': FeesPayerToJSON(value.feesPayer),
        'loss_config': LossConfigToJSON(value.lossConfig),
        'fiat_withdrawal': value.fiatWithdrawal,
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
    };
}

