/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CallbackVersion } from './CallbackVersion';
import {
    CallbackVersionFromJSON,
    CallbackVersionFromJSONTyped,
    CallbackVersionToJSON,
} from './CallbackVersion';

/**
 * 
 * @export
 * @interface Callback
 */
export interface Callback {
    /**
     * 
     * @type {string}
     * @memberof Callback
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof Callback
     */
    publicKey?: string;
    /**
     * 
     * @type {CallbackVersion}
     * @memberof Callback
     */
    version: CallbackVersion;
}

/**
 * Check if a given object implements the Callback interface.
 */
// @ts-ignore
export function instanceOfCallback(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "version" in value;

    return isInstance;
}

// @ts-ignore
export function CallbackFromJSON(json: any): Callback {
    return CallbackFromJSONTyped(json, false);
}

// @ts-ignore
export function CallbackFromJSONTyped(json: any, ignoreDiscriminator: boolean): Callback {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': !exists(json, 'url') ? undefined : json['url'],
        'publicKey': !exists(json, 'public_key') ? undefined : json['public_key'],
        'version': CallbackVersionFromJSON(json['version']),
    };
}

// @ts-ignore
export function CallbackToJSON(value?: Callback | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.url,
        'public_key': value.publicKey,
        'version': CallbackVersionToJSON(value.version),
    };
}

