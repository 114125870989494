import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    underpaymentLabel: {
        id: 'payments-settings-page.form.underpayment_label',
        defaultMessage: 'Allowed underpayment threshold',
    },
    underpaymentDescription: {
        id: 'payments-settings-page.form.underpayment_description',
        defaultMessage:
            'You can set the maximum underpayment allowed from your customers due to crypto volatility. The more restrictive of the thresholds will be applied for each payment. Your customers will not see these thresholds.',
    },
    underpaymentRelativeCheckboxTitle: {
        id: 'payments-settings-page.form.underpayment_relative_checkbox_title',
        defaultMessage: 'Invoice is accepted as completed if percent of underpayment is not more than:',
    },
})
