import { useBoolean } from 'usehooks-ts'

export function useLoadImage() {
    const { value: isImgError, setValue: setIsImgError } = useBoolean(false)
    const { value: isImgLoading, setValue: setIsImgLoading } = useBoolean(true)

    const handleError = () => {
        setIsImgLoading(false)
        setIsImgError(true)
    }

    const handleLoad = () => {
        setIsImgLoading(false)
    }

    const isFallback = !isImgLoading && isImgError

    return { isImgError, isImgLoading, handleError, handleLoad, isFallback }
}
