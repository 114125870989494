import { lang as commonLang } from './../../lang'
import { MerchantSettingsShowOptions } from './types'
import type { CardProps } from '@merchant/ui-kit/ant-design'
import type { MessageDescriptor } from 'react-intl'

export const pageTitleMap: Record<MerchantSettingsShowOptions, MessageDescriptor | undefined> = {
    [MerchantSettingsShowOptions.general]: commonLang.generalPageTitle,
    [MerchantSettingsShowOptions.assets]: commonLang.currenciesSettingsPageTitle,
    [MerchantSettingsShowOptions.paymentsSettings]: commonLang.paymentsSettingsPageTitle,
}

export const dataMerchantAttributesMap: Record<MerchantSettingsShowOptions, string> = {
    [MerchantSettingsShowOptions.general]: 'merchant-general-title',
    [MerchantSettingsShowOptions.assets]: 'merchant-assets-title',
    [MerchantSettingsShowOptions.paymentsSettings]: 'merchant-payments-settings-title',
}

export const compactCardsWrapperStyles: CardProps['styles'] = {
    body: { padding: '20px 24px 24px', overflowWrap: 'break-word' },
}
