import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg, CustomizedEmpty } from '@merchant/shared/components'
import styles from './style.module.css'
import type { ConfigProviderProps, ThemeConfig } from '@merchant/ui-kit/ant-design'
import type { IntlShape } from 'react-intl'
import { globalLang } from '~globalLang'

const renderEmpty = () => <CustomizedEmpty data-merchant="empty" />

export const getConfigProviderProps = (intl: IntlShape, theme: ThemeConfig): ConfigProviderProps => ({
    theme,
    renderEmpty,
    checkbox: {
        className: styles.checkbox,
    },
    notification: {
        className: styles.notification,
    },
    collapse: {
        expandIcon: ({ isActive }) => (
            <AppSvg
                size={24}
                style={{
                    transform: isActive ? 'rotate(180deg)' : 'rotate(0)',
                    transition: 'transform 0.3s',
                }}
                name={assets.chevronDown}
            />
        ),
    },
    radio: {
        className: styles.radioActive,
    },
    input: {
        autoComplete: 'off',
    },
    modal: {
        styles: {
            content: {
                paddingTop: 16,
            },
            header: {
                marginBottom: 16,
            },
        },
    },
    form: {
        scrollToFirstError: {
            behavior: 'smooth',
            block: 'center',
            skipOverflowHiddenElements: true,
        },
        colon: false,
        requiredMark: false,
        validateMessages: {
            required: intl.formatMessage(globalLang.requiredFieldMessage),
            types: {
                email: intl.formatMessage(globalLang.invalidEmailFormat),
                url: intl.formatMessage(globalLang.invalidUrlFormat),
            },
        },
    },
})
