import { EmailSupportLink } from '@merchant/shared/components'
import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import { capitalize } from 'lodash-es'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import type { FailedWithdrawal } from '~features/WithdrawalsPage/views/Withdrawal/types'

const { Text } = Typography

interface Props {
    data: FailedWithdrawal
}

const getRows = ({ data }: { data: Props['data'] }) => {
    return [
        <>
            <Flex vertical>
                <Text type="secondary" style={{ opacity: 0.5 }} data-merchant="withdrawal-failed-error">
                    {capitalize(data.error?.message)}
                </Text>
                <Text type="secondary" data-merchant="withdrawal-failed-support-text">
                    <FormattedMessage
                        {...lang.support}
                        values={{
                            support: chunks => <EmailSupportLink>{chunks}</EmailSupportLink>,
                        }}
                    />
                </Text>
            </Flex>
        </>,
    ]
}

export function FailedTimeline({ data }: Props) {
    return (
        <Flex vertical>
            <Text type="danger" data-merchant="withdrawal-timeline-failed-label">
                <FormattedMessage {...lang.failedTimeline} />
            </Text>
            {getRows({ data })}
        </Flex>
    )
}
