import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { constructorTypes } from './types'
import type { ConstructorTypes } from './types'
import { useHoldingCurrencies } from '~api'
import { Permission } from '~api/instances/cabinet-api'
import { QueryParams } from '~constants/routes'
import { useUserPermissionContext } from '~contexts'
import { useSwapEnabled } from '~hooks'
import { isHoldingCurrencyPredicate, isRateablePredicate } from '~utils'

const constructorTypePermissionMap: Record<ConstructorTypes, Permission> = {
    [constructorTypes.Invoice]: Permission.CreatePayment,
    [constructorTypes.Deposit]: Permission.CreatePayment,
    [constructorTypes.StaticAddress]: Permission.CreateDepositAddress,
}

function getIsValidConstructorType(
    queryOrderType: string | null,
    permittedTypes: ConstructorTypes[]
): queryOrderType is ConstructorTypes {
    return queryOrderType !== null && Object.values(permittedTypes).includes(queryOrderType as ConstructorTypes)
}

export function useOrderType() {
    const { checkUserPermission } = useUserPermissionContext()
    const [searchParams, setSearchParams] = useSearchParams()

    const permittedTypes: ConstructorTypes[] = Object.values(constructorTypes).filter(type =>
        checkUserPermission(constructorTypePermissionMap[type])
    )

    const queryOrderType = searchParams.get(QueryParams.type)
    const IsValidConstructorType = getIsValidConstructorType(queryOrderType, permittedTypes)
    const orderTypeNormalized = IsValidConstructorType ? queryOrderType : permittedTypes[0] || constructorTypes.Invoice

    const onTypeChange = (type: ConstructorTypes) => {
        searchParams.set(QueryParams.type, type)
        setSearchParams(searchParams)
    }

    useEffect(() => {
        if (!IsValidConstructorType) {
            onTypeChange(orderTypeNormalized)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [IsValidConstructorType, orderTypeNormalized])

    return {
        orderType: orderTypeNormalized,
        onTypeChange,
        permittedTypes,
    }
}

export function useCurrencyPredicates() {
    const { data: selectedHoldingCurrencies, isLoading: isLoadingHoldingCurrencies } = useHoldingCurrencies({
        process: data => data?.filter(item => item.selected),
    })

    const isSwapEnabled = useSwapEnabled()

    const predicates = [isRateablePredicate]
    if (!isSwapEnabled) {
        predicates.push(isHoldingCurrencyPredicate(selectedHoldingCurrencies))
    }

    return { predicates, isLoading: isLoadingHoldingCurrencies }
}
