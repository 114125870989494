import { PlaceholderViewKeys } from './const'
import { useHoldingCurrencies, useProject, useTotps } from '~api'

export function usePlaceholderViewData() {
    const { data: holdingCurrencies, isLoading: isLoadingHoldingCurrencies } = useHoldingCurrencies()
    const { data: project, isLoading: isLoadingProject } = useProject()
    const { hasActiveTotp, isLoading: isLoadingTotps, mutate: mutateTotps } = useTotps()

    let contentKey = null

    if (isLoadingHoldingCurrencies || isLoadingProject || isLoadingTotps) {
        contentKey = PlaceholderViewKeys.Loading
    } else if (!project) {
        contentKey = PlaceholderViewKeys.NoProject
    } else if (!holdingCurrencies?.[0]?.selected) {
        contentKey = PlaceholderViewKeys.NoHoldingCurrencies
    } else if (!hasActiveTotp) {
        contentKey = PlaceholderViewKeys.No2Fa
    }

    return {
        contentKey,
        mutateTotps,
    }
}
