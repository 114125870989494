import { checkIsAuthError } from '@merchant/shared/api/swr/utils'
import { NotificationMessage } from '@merchant/shared/components'
import { authTokenKey } from '@merchant/shared/constants/localStorage'
import { useNotifications } from '@merchant/shared/contexts'
import { useEffect } from 'react'
import useSWR from 'swr'
import { useLocalStorage } from 'usehooks-ts'
import type { Fetcher, SWRConfiguration } from 'swr'
import { useLogout } from '~hooks/useLogout'

let isRefreshing = false

export function useAuthSwr<
    T,
    SWROptions extends SWRConfiguration<T> | undefined = SWRConfiguration<T, Error, Fetcher<T>> | undefined,
>(key: string | Record<string, unknown> | null, cb: Fetcher<T>, swrConfig?: SWROptions) {
    const [authToken, setAuthToken] = useLocalStorage(authTokenKey, '')
    const swrResponse = useSWR<T>(authToken ? key : null, cb, swrConfig)
    const { notification } = useNotifications()
    const { logout } = useLogout()

    useEffect(() => {
        const onLogout = () => {
            new Promise(() => {
                logout()
                notification.api.open({
                    message: (
                        <NotificationMessage
                            type="warning"
                            title="Unauthorized"
                            description="You have been logged out"
                            dataMerchant="user-unauthorized-notification"
                        />
                    ),
                    key: 'unauthorized',
                })
            }).finally(() => {
                isRefreshing = false
            })
        }

        if (checkIsAuthError(swrResponse.error) && !!authToken) {
            if (!isRefreshing) {
                isRefreshing = true
                onLogout()
            }
        }
    }, [authToken, logout, notification.api, setAuthToken, swrResponse.error])

    return swrResponse
}
