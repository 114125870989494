import { ModalResponsive } from '@merchant/shared/components'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { FormattedMessageWithBoldText, ConfirmationView } from '..'
import { lang as commonLang } from './../../lang'
import { steps, modalTitleStepMap, type StepsValue } from './const'
import { lang } from './lang'
import { EnableWhitelistForm } from './views'
import type { ConfirmationItems } from '../ConfirmationView/types'
import type { ModalProps } from '@merchant/ui-kit/ant-design'
import { projectApi, useProject } from '~api'
import { GenerateVerificationCodeRequestPurpose } from '~api/instances/cabinet-api'
import { withDirtyCheckingContextProvider } from '~hoc/withDirtyCheckingContextProvider'
import { useDirtyFormCheckerControls } from '~hooks/useDirtyFormCheckerControls'

interface Props extends Omit<ModalProps, 'data-merchant'> {
    onModalSuccess: () => void
    closeModal: () => void
}

function Component({ onModalSuccess, closeModal, ...rest }: Props) {
    const [step, setStep] = useState<StepsValue>(steps.whitelistEnableAcknowledgement)
    const { handleCancel } = useDirtyFormCheckerControls(closeModal)
    const { data: { id: projectId = '' } = {} } = useProject()

    const views: Record<StepsValue, () => JSX.Element> = {
        [steps.whitelistEnableAcknowledgement]: () => {
            return (
                <EnableWhitelistForm
                    onSuccess={() => setStep(steps.whitelistEnableConfirmation)}
                    onCancel={closeModal}
                />
            )
        },
        [steps.whitelistEnableConfirmation]: () => {
            const confirmationRequest = (values: ConfirmationItems) =>
                projectApi.updateProjectWhitelist({
                    whitelistUpdateRequest: { enabled: true, ...values },
                    projectId,
                })

            return (
                <ConfirmationView
                    request={confirmationRequest}
                    onSuccess={onModalSuccess}
                    alertMessage={<FormattedMessageWithBoldText message={commonLang.newAddressAlertDescription} />}
                    buttonTitle={lang.confirmationButtonTitle}
                    purpose={GenerateVerificationCodeRequestPurpose.EnableWhitelist}
                />
            )
        },
    } as const

    return (
        <ModalResponsive
            data-merchant="whitelist-enable-modal"
            destroyOnClose
            title={
                <span data-merchant="whitelist-enable-modal-title">
                    <FormattedMessage {...modalTitleStepMap[step]} />
                </span>
            }
            afterClose={() => setStep(steps.whitelistEnableAcknowledgement)}
            footer={null}
            {...rest}
            onCancel={handleCancel}
        >
            {views[step]()}
        </ModalResponsive>
    )
}

export const EnableWhitelistModal = withDirtyCheckingContextProvider(Component)
