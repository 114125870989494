import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    resend: {
        id: 'resend-code.button.resend',
        defaultMessage: 'Resend',
    },
    resendIn: {
        id: 'resend-code.button.resend_in',
        defaultMessage: 'Resend in',
    },
    verificationCodeSentTo: {
        id: 'resend-code.notification.verification_code_sent_to',
        defaultMessage: 'Verification code sent to',
    },
    resendRequestError: {
        id: 'resend-code.notification.resend_request_error',
        defaultMessage: 'Resend request error',
    },
})
