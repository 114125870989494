import { Grid, Select as OriginalSelect } from 'antd'
import type { WithDataPointer } from './types'
import type { GetProps } from 'antd'
import type { BaseOptionType, DefaultOptionType } from 'antd/es/select'
import type { FlattenOptionData } from 'rc-select/lib/interface'

const { useBreakpoint } = Grid

export interface SelectProps<
    ValueType = unknown,
    OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
> extends GetProps<typeof OriginalSelect<ValueType, OptionType>>,
        WithDataPointer {
    optionIdPostfix?: (option: FlattenOptionData<OptionType>) => string | undefined
    selectRef?: SelectProps['ref']
}

export function Select<ValueType = unknown, OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType>({
    onChange,
    dropdownRender,
    optionRender,
    optionIdPostfix,
    selectRef,
    ...props
}: Omit<SelectProps<ValueType, OptionType>, 'ref'> & WithDataPointer) {
    const breakpoints = useBreakpoint()

    return (
        <OriginalSelect<ValueType, OptionType>
            virtual={breakpoints.md}
            {...props}
            onChange={onChange}
            optionRender={(option, info) => (
                <div
                    data-merchant={`${props['data-merchant']}-item-${optionIdPostfix?.(option) ?? option.value?.toString().toLowerCase()}`}
                >
                    {optionRender?.(option, info) ?? option.label}
                </div>
            )}
            dropdownRender={menu => (
                <div data-merchant={`${props['data-merchant']}-dropdown`}>{dropdownRender?.(menu) ?? menu}</div>
            )}
            {...props}
            ref={selectRef}
        />
    )
}

Select.Option = ({ ...props }: GetProps<typeof OriginalSelect.Option> & WithDataPointer) => {
    return <OriginalSelect.Option {...props} />
}

Select.OptGroup = ({ ...props }: GetProps<typeof OriginalSelect.OptGroup> & WithDataPointer) => {
    return <OriginalSelect.OptGroup {...props} />
}

export default Select
