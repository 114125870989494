import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { useBreakpoint } from '@merchant/shared/hooks'
import { Button, Card, ConfigProvider, theme } from '@merchant/ui-kit/ant-design'
import { getCardStyles, getCardBodyStyles, goBackButtonStyles } from './style'
import { useData } from './useData'

const { useToken } = theme

export function AuthorizationPage() {
    const { canGoBack, view, goBack } = useData()
    const breakpoints = useBreakpoint()
    const {
        token: { colorTextDescription },
    } = useToken()

    return (
        <Card
            bordered={false}
            style={getCardStyles(breakpoints)}
            styles={{ body: getCardBodyStyles(breakpoints) }}
            data-merchant="auth-page"
        >
            <ConfigProvider
                theme={{
                    components: {
                        Form: { itemMarginBottom: breakpoints.sm ? 24 : 16 },
                    },
                }}
            >
                {canGoBack && (
                    <Button
                        data-merchant="auth-page-back-button"
                        icon={<AppSvg size={24} name={assets.arrowNarrowLeft} color={colorTextDescription} />}
                        type="text"
                        style={goBackButtonStyles}
                        onClick={goBack}
                    />
                )}
                {view}
            </ConfigProvider>
        </Card>
    )
}
