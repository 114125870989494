let scrollPosition = 0
let body: HTMLElement | null = null
const DISABLE_SCROLLING_CLASS = 'scroll-disabled'

export const bodyScrollLock = {
    enable() {
        scrollPosition = window.scrollY || window.pageYOffset

        body = document.querySelector('body')
        body?.classList.add(DISABLE_SCROLLING_CLASS)
        if (body) {
            body.style.top = `-${scrollPosition}px`
        }
    },
    disable() {
        if (!body) {
            return
        }

        body.classList.remove(DISABLE_SCROLLING_CLASS)
        body.style.removeProperty('top')
        window.scrollTo(0, scrollPosition)

        body = null
    },
}
