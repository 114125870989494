import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    transactionId: {
        id: 'convert-confirmation.modal.transaction_id',
        defaultMessage: 'Transaction ID',
    },
    notificationAddressCopied: {
        id: 'withdrawal-modal.notification.address_copied',
        defaultMessage: 'Address copied to clipboard',
    },
    notPermittedTitle: {
        id: 'action-modal.modal.not_permitted_title',
        defaultMessage: 'Action not permitted',
    },
})
