/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FeesPayer } from './FeesPayer';
import {
    FeesPayerFromJSON,
    FeesPayerFromJSONTyped,
    FeesPayerToJSON,
} from './FeesPayer';
import type { ProjectStatus } from './ProjectStatus';
import {
    ProjectStatusFromJSON,
    ProjectStatusFromJSONTyped,
    ProjectStatusToJSON,
} from './ProjectStatus';

/**
 * 
 * @export
 * @interface CreateProjectRequest
 */
export interface CreateProjectRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequest
     */
    link: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequest
     */
    supportEmail?: string;
    /**
     * 
     * @type {ProjectStatus}
     * @memberof CreateProjectRequest
     */
    status?: ProjectStatus;
    /**
     * 
     * @type {FeesPayer}
     * @memberof CreateProjectRequest
     */
    feesPayer: FeesPayer;
}

/**
 * Check if a given object implements the CreateProjectRequest interface.
 */
// @ts-ignore
export function instanceOfCreateProjectRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "link" in value;
    isInstance = isInstance && "feesPayer" in value;

    return isInstance;
}

// @ts-ignore
export function CreateProjectRequestFromJSON(json: any): CreateProjectRequest {
    return CreateProjectRequestFromJSONTyped(json, false);
}

// @ts-ignore
export function CreateProjectRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateProjectRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'link': json['link'],
        'supportEmail': !exists(json, 'support_email') ? undefined : json['support_email'],
        'status': !exists(json, 'status') ? undefined : ProjectStatusFromJSON(json['status']),
        'feesPayer': FeesPayerFromJSON(json['fees_payer']),
    };
}

// @ts-ignore
export function CreateProjectRequestToJSON(value?: CreateProjectRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'link': value.link,
        'support_email': value.supportEmail,
        'status': ProjectStatusToJSON(value.status),
        'fees_payer': FeesPayerToJSON(value.feesPayer),
    };
}

