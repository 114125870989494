import { useNavigate } from 'react-router-dom'

export function useConditionalTabOpen() {
    const navigate = useNavigate()

    const handleConditionalTabOpen =
        ([getUrl, to]: [(location: Location) => string, string]) =>
        (e?: React.MouseEvent | React.KeyboardEvent) => {
            const url = getUrl(window.location)
            if (e && (e.ctrlKey || e.metaKey || ('button' in e && e.button === 1))) {
                window.open(url, '_blank')
            } else {
                navigate(to, { relative: 'path' })
            }
        }

    return { handleConditionalTabOpen }
}
