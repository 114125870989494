export function getImageComponentWrapperStyle(
    iconSize:
        | number
        | {
              width: number
              height: number
          }
): React.CSSProperties {
    const { width, height } = typeof iconSize === 'number' ? { width: iconSize, height: iconSize } : iconSize

    return {
        width: width - 4,
        height: height - 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
    }
}
