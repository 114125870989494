import { colorPalette } from './palette/currentBroker'
import type { ThemeConfig } from '@merchant/ui-kit/ant-design'
import { addAlpha } from '~utils'

export const enum Themes {
    MerchantDay = 'merchant_day',
    MerchantNight = 'merchant_night',
}

type ThemeTokens = Record<Themes, ThemeConfig>

const breakpointsMap = {
    screenXSMin: 375,
    screenXS: 375,
    screenXSMax: 575,

    screenSMMin: 576,
    screenSM: 576,
    screenSMMax: 767,

    screenMDMin: 768,
    screenMD: 768,
    screenMDMax: 1023,

    screenLGMin: 1024,
    screenLG: 1024,
    screenLGMax: 1327,

    screenXLMin: 1328,
    screenXL: 1328,
    screenXLMax: 1599,

    screenXXLMin: 1600,
    screenXXL: 1600,
}

export const themeTokens: ThemeTokens = {
    [Themes.MerchantNight]: {
        components: {
            Alert: {
                colorBgBase: 'transparent',
                colorWarningBg: 'transparent',
                colorWarningBorder: colorPalette.night.elementsWarning,
                colorWarning: colorPalette.night.elementsWarning,
                colorError: colorPalette.night.elementsDanger,
                colorErrorBg: 'transparent',
                colorErrorBorder: colorPalette.night.elementsDanger,

                marginXS: 12,
                paddingContentHorizontalLG: 16,
                paddingMD: 16,
            },
            Badge: {
                dotSize: 8,
            },
            Checkbox: {
                colorBorder: 'transparent',
                colorBgContainer: colorPalette.night.elementsBorder,
                colorWhite: colorPalette.night.elementsLight,
                colorTextDisabled: addAlpha(colorPalette.night.contentSecondary, 0.5),
                colorBgContainerDisabled: addAlpha(colorPalette.night.elementsBorder, 0.5),
                borderRadiusSM: 2,
            },
            Collapse: {
                headerBg: colorPalette.night.rowHover,
                contentBg: 'inherit',
                colorTextDisabled: colorPalette.night.contentSecondary,
            },
            Divider: {
                colorSplit: colorPalette.night.elementsBorder,
                marginLG: 24,
            },
            Dropdown: {
                controlItemBgActive: colorPalette.night.elementsPrimaryOpacity10,
                controlItemBgActiveHover: colorPalette.night.elementsPrimaryOpacity10,
                fontSize: 12,
                controlPaddingHorizontal: 16,
            },
            Layout: {
                headerBg: 'transparent',
                siderBg: 'transparent',
                colorText: addAlpha(colorPalette.night.elementsBorder, 0.5), // border color
            },
            Form: {
                itemMarginBottom: 16,
                lineHeight: 1.7,
                verticalLabelPadding: '0 0 4px 0',
                paddingXS: 12,
                marginLG: 24,
                controlHeightLG: 0,
                colorTextDescription: colorPalette.night.contentSecondary,
                colorTextHeading: colorPalette.night.contentPrimary,
            },
            Notification: {
                colorBgElevated: colorPalette.night.bgBack,
                colorText: colorPalette.night.contentSecondary,
                colorIcon: colorPalette.night.contentSecondary,
                colorSuccess: colorPalette.night.elementsSuccess,
                borderRadiusLG: 20,
                width: 400,
            },
            DatePicker: {
                lineHeight: 1.5714285714,
                colorBgContainer: 'transparent',
                fontSize: 14,
                fontSizeLG: 14,
                colorTextQuaternary: colorPalette.night.contentPrimary,
                colorBgElevated: colorPalette.night.bgAdditional,
                controlItemBgActive: colorPalette.night.elementsPrimaryOpacity10,
                colorTextDisabled: colorPalette.night.contentSecondary,
                colorTextDescription: colorPalette.night.contentSecondary,
                fontWeightStrong: 400,
                cellHoverWithRangeBg: addAlpha(colorPalette.night.elementsSecondary, 0.2),
                cellHoverBg: addAlpha(colorPalette.night.elementsSecondary, 0.2),
                cellActiveWithRangeBg: addAlpha(colorPalette.night.elementsSecondary, 0.2),
            },
            Descriptions: {
                itemPaddingBottom: 0,
                colorText: colorPalette.night.contentPrimary,
                colorTextTertiary: colorPalette.night.contentSecondary,
                padding: 0,
            },
            Tag: {
                defaultBg: colorPalette.night.elementsNeutralOpacity10,
                fontSizeSM: 12,
                colorSuccessBg: colorPalette.night.elementsSuccessOpacity10,
                colorSuccess: colorPalette.night.elementsSuccess,
                colorErrorBg: colorPalette.night.elementsDangerOpacity10,
                colorError: colorPalette.night.elementsDanger,
                colorText: colorPalette.night.elementsNeutral,
                colorFillTertiary: colorPalette.night.elementsNeutralOpacity10,
                colorTextDescription: colorPalette.night.elementsNeutral,
                colorTextHeading: colorPalette.night.elementsNeutralOpacity10,
                borderRadiusSM: 4,
            },
            Pagination: {
                colorPrimary: colorPalette.night.elementsPrimary,
                colorText: 'rgba(255, 255, 255, 0.65)',
                colorTextDisabled: 'rgba(255, 255, 255, 0.25)',
            },
            Statistic: {
                contentFontSize: 14,
                colorText: 'inherit',
                colorTextHeading: 'inherit',
            },
            Table: {
                rowHoverBg: colorPalette.night.rowHover,
                rowSelectedBg: colorPalette.night.bgPrimary,
                rowSelectedHoverBg: colorPalette.night.hoverRowMedium,
                filterDropdownBg: colorPalette.night.bgAdditional,
                filterDropdownMenuBg: colorPalette.night.bgAdditional,
                colorIconHover: colorPalette.night.contentSecondary,
                headerFilterHoverBg: colorPalette.night.rowHover,
                headerBg: colorPalette.night.bgPrimary,
                headerSplitColor: 'transparent',
                headerColor: colorPalette.night.contentSecondary,
                colorBgContainer: 'transparent',
                fontWeightStrong: 400,
                fontSize: 12,
                colorFillAlter: colorPalette.night.rowHover,
                paddingContentVerticalLG: 20,
                padding: 12,
                cellPaddingInline: 8,
            },
            Tooltip: {
                fontSize: 14,
                // boxShadowSecondary: '0px -10px 80px 0px rgba(0, 0, 0, 0.25)',
                colorBgSpotlight: colorPalette.night.bgAdditional,
                colorTextLightSolid: colorPalette.night.contentPrimary,
                paddingSM: 24,
                paddingXS: 16,
                borderRadius: 12,
            },
            Modal: {
                marginXS: 24,
                headerBg: 'transparent',
                contentBg: colorPalette.night.bgBack,
                colorIconHover: addAlpha(colorPalette.night.contentPrimary, 0.5),
                borderRadiusLG: 20,
                fontWeightStrong: 500,
                paddingContentHorizontalLG: 24,
                paddingMD: 24,
                padding: 16,
                colorBgMask: colorPalette.night.backgroundBlur,
            },
            Message: {
                colorText: colorPalette.night.contentPrimary,
                colorBgElevated: colorPalette.night.bgAdditional,
                colorInfo: colorPalette.night.elementsSecondary,
            },
            List: {
                colorTextDescription: colorPalette.night.contentSecondary,
                paddingSM: 0, // List header marginBlock
                itemPadding: '20px 12px',
            },
            Switch: {
                colorPrimary: colorPalette.night.elementsSecondary,
                colorPrimaryHover: colorPalette.night.elementsSecondary10L,
                colorTextQuaternary: colorPalette.night.elementsBorder,
                colorTextTertiary: '#3A3E46', // switch hover color
            },
            Select: {
                optionPadding: '5.5px 12px',
                colorTextTertiary: addAlpha(colorPalette.night.buttonBgPrimary, 0.7),
                colorBgContainerDisabled: colorPalette.night.rowHover,
                colorTextDisabled: colorPalette.night.contentSecondary,
                fontSize: 14,
                colorBgContainer: 'transparent',
                fontSizeLG: 14,
                colorTextQuaternary: colorPalette.night.contentPrimary,
                colorBgElevated: colorPalette.night.bgAdditional,
                controlItemBgActive: colorPalette.night.elementsPrimaryOpacity10,
                fontWeightStrong: 400,
            },
            Radio: {
                colorTextDisabled: addAlpha(colorPalette.night.contentPrimary, 0.5),
                dotColorDisabled: addAlpha(colorPalette.night.elementsPrimary, 0.5),
                colorPrimary: colorPalette.night.elementsPrimary,
                colorBorder: 'transparent',
                colorBgContainer: colorPalette.night.elementsBorder,
                wireframe: true,
            },
            Card: {
                colorBgContainer: colorPalette.night.bgPrimary,
                colorBorderSecondary: addAlpha(colorPalette.night.elementsBorder, 0.5),
                borderRadius: 20,
                borderRadiusOuter: 20,
                borderRadiusLG: 20,
            },
            Popover: {
                boxShadowSecondary: '0px -10px 80px 0px rgba(0, 0, 0, 0.25)',
                paddingXS: 12,
                colorTextLightSolid: colorPalette.night.contentPrimary,
                colorBgElevated: colorPalette.night.bgAdditional,
                fontWeightStrong: 400,
                marginXS: 0, // popover title margin-bottom
            },
            InputNumber: {
                colorBgContainerDisabled: colorPalette.night.rowHover,
                colorTextDisabled: addAlpha(colorPalette.night.contentPrimary, 0.5),
                colorBgContainer: 'transparent',
                fontSizeLG: 14,
                controlOutline: 'transparent',
                paddingXXS: 8, // suffix right space
            },
            Input: {
                colorBgContainerDisabled: colorPalette.night.rowHover,
                colorTextDisabled: addAlpha(colorPalette.night.contentPrimary, 0.5),
                colorBgContainer: 'transparent',
                fontSizeLG: 14,
                colorFillAlter: colorPalette.night.elementsBorder,
                controlOutline: 'transparent',
                paddingXXS: 8, // suffix right space
                colorTextQuaternary: '#6c7384',
                colorTextTertiary: '#fff',
            },
            QRCode: {
                boxShadow: '0px 0px 30px 0px rgba(0, 0, 0, 0.08)',
                colorBgContainer: colorPalette.night.elementsLight,
                colorText: colorPalette.night.elementsDarkGrey,
                colorBorder: colorPalette.night.elementsBorder,
            },
            Steps: {
                colorTextLightSolid: colorPalette.night.buttonContentPrimary,
                colorTextDescription: colorPalette.night.contentSecondary,
                colorSplit: colorPalette.night.elementsBorder,
                colorBgContainer: 'transparent',
                wireframe: true,
                colorTextDisabled: '#6c7384',
            },
            Segmented: {
                controlPaddingHorizontalSM: 12,
                colorBgLayout: colorPalette.night.elementsBg,
                colorText: colorPalette.night.elementsDarkGrey,
                colorTextDisabled: colorPalette.night.buttonContentDisabled,
                itemColor: colorPalette.night.contentSecondary,
                itemHoverBg: 'rgba(255, 255, 255, 0.15)',
                itemHoverColor: colorPalette.night.contentSecondary,
                fontSize: 12,
                itemSelectedBg: colorPalette.night.elementsLight,

                borderRadiusSM: 6,
                borderRadius: 8,

                controlPaddingHorizontal: 24,
            },
            Skeleton: {
                colorFill: colorPalette.night.elementsBorder,
                borderRadiusSM: 12,
            },
            Result: {
                paddingLG: 16,
                paddingXL: 56,
                marginXS: 12,
                extraMargin: '8px 0 0',
                colorFillAlter: 'transparent',
                titleFontSize: 20,
            },
            Button: {
                textHoverBg: colorPalette.night.rowHover,
                colorBgContainer: colorPalette.night.buttonBgSecondary,
                colorBorder: 'transparent',
                colorPrimary: colorPalette.night.buttonBgPrimary,
                colorPrimaryHover: colorPalette.night.elementsPrimary,
                colorPrimaryActive: colorPalette.night.elementsPrimary,
                colorTextDisabled: 'rgba(255, 255, 255, 0.2)',
                colorBgContainerDisabled: 'rgba(255, 255, 255, 0.05)',
                primaryColor: colorPalette.night.elementsControl,

                controlHeight: 32,
                controlHeightLG: 40,
                controlHeightSM: 24,
                fontSize: 12,
                fontSizeLG: 14,
                lineWidth: 1,
                lineWidthFocus: 2,
                marginXS: 8,
                motionDurationMid: '0.2s',
                motionDurationSlow: '0.3s',
                opacityLoading: 0.65,
                paddingContentHorizontal: 16,
                paddingInlineLG: 32,
                paddingXS: 8,

                controlOutline: 'transparent',
                controlTmpOutline: 'transparent',
            },
            Typography: {
                colorTextDescription: colorPalette.night.contentSecondary,
                titleMarginBottom: 0,
                titleMarginTop: 0,
                fontWeightStrong: 500,
                colorSuccess: colorPalette.night.elementsSuccess,
                colorTextDisabled: 'rgba(255, 255, 255, 0.5)',
            },
            Menu: {
                itemDisabledColor: addAlpha(colorPalette.night.contentSecondary, 0.5),
                iconMarginInlineEnd: 12,
                itemSelectedBg: colorPalette.night.elementsPrimaryOpacity10,
                itemActiveBg: colorPalette.night.hoverRowMedium,
                itemHoverBg: colorPalette.night.rowHover,
                itemSelectedColor: colorPalette.night.contentPrimary,
                colorBgContainer: 'transparent',
                colorText: colorPalette.night.contentSecondary,
                colorPrimary: colorPalette.night.contentPrimary,
                collapsedIconSize: 20,
            },
        },
        token: {
            colorPrimary: colorPalette.night.elementsPrimary,
            colorText: colorPalette.night.contentPrimary,
            colorBgContainer: colorPalette.night.bgBack,
            colorBgLayout: colorPalette.night.bgBack,
            colorBorder: colorPalette.night.elementsBorder,
            borderRadius: 10,
            borderRadiusLG: 12,
            borderRadiusSM: 8,
            colorBorderSecondary: colorPalette.night.elementsBorder,
            colorIcon: colorPalette.night.contentPrimary,
            colorSuccess: colorPalette.night.elementsSuccess,
            colorSuccessBg: colorPalette.night.elementsSuccessOpacity10,
            colorError: colorPalette.night.elementsDanger,
            colorErrorBg: colorPalette.night.elementsDangerOpacity10,

            colorLink: colorPalette.night.elementsSecondary,
            colorLinkHover: colorPalette.night.elementsSecondary10L,
            colorLinkActive: colorPalette.night.elementsSecondary,

            fontFamily: 'Inter',
            colorFillAlter: colorPalette.night.elementsBorder,
            colorWarning: colorPalette.night.elementsWarning,
            colorWarningBg: colorPalette.night.elementsWarningOpacity10,

            controlOutlineWidth: 0,

            colorTextDescription: colorPalette.night.contentSecondary,
            colorTextPlaceholder: colorPalette.night.contentSecondary,

            controlItemBgHover: addAlpha(colorPalette.night.elementsLight, 0.03),
            colorBgBase: colorPalette.night.bgPrimary,
            purple: '#4033CA',

            lineHeight: 1.5,
            lineWidthFocus: 2,

            ...breakpointsMap,
        },
    },
    [Themes.MerchantDay]: {
        components: {
            Alert: {
                colorBgBase: 'transparent',
                colorWarningBg: 'transparent',
                colorWarningBorder: colorPalette.night.elementsWarning,
                colorWarning: colorPalette.night.elementsWarning,
                colorError: colorPalette.night.elementsDanger,
                colorErrorBg: 'transparent',
                colorErrorBorder: colorPalette.night.elementsDanger,

                marginXS: 12,
                paddingContentHorizontalLG: 16,
                paddingMD: 16,
            },
            Badge: {
                dotSize: 8,
            },
            Card: {
                colorBgContainer: colorPalette.day.bgPrimary,
                colorBorderSecondary: addAlpha(colorPalette.day.elementsBorder, 0.5),
                borderRadius: 20,
                borderRadiusOuter: 20,
                borderRadiusLG: 20,
            },
            Checkbox: {
                colorBorder: 'transparent',
                colorBgContainer: colorPalette.day.elementsBorder,
                colorWhite: '#fff',
                colorTextDisabled: addAlpha(colorPalette.day.contentSecondary, 0.5),
                colorBgContainerDisabled: addAlpha(colorPalette.day.elementsBorder, 0.5),
                borderRadiusSM: 2,
            },
            Collapse: {
                headerBg: colorPalette.day.rowHover,
                contentBg: 'inherit',
                colorTextDisabled: colorPalette.day.contentSecondary,
            },
            Descriptions: {
                colorText: colorPalette.day.contentPrimary,
                colorTextTertiary: colorPalette.day.contentSecondary,
                padding: 0,
                itemPaddingBottom: 0,
            },
            Dropdown: {
                controlItemBgActive: colorPalette.day.elementsPrimaryOpacity10,
                controlItemBgActiveHover: colorPalette.day.elementsPrimaryOpacity10,
                fontSize: 12,
                controlPaddingHorizontal: 16,
            },
            InputNumber: {
                colorBgContainerDisabled: colorPalette.day.rowHover,
                colorTextDisabled: addAlpha(colorPalette.day.contentPrimary, 0.5),
                colorBgContainer: 'transparent',
                fontSizeLG: 14,
                controlOutline: 'transparent',
                paddingXXS: 8, // suffix right space
            },
            Input: {
                colorBgContainerDisabled: colorPalette.day.rowHover,
                colorTextDisabled: addAlpha(colorPalette.day.contentPrimary, 0.5),
                controlOutline: 'transparent',
                colorBgContainer: 'transparent',
                fontSizeLG: 14,

                paddingSM: 12,
                controlPaddingHorizontalSM: 12,

                paddingXXS: 8, // suffix right space
            },
            Message: {
                colorText: colorPalette.day.contentPrimary,
                colorBgElevated: colorPalette.day.bgAdditional,
                colorInfo: colorPalette.day.elementsSecondary,
            },
            List: {
                colorTextDescription: colorPalette.day.contentSecondary,
                paddingSM: 0, // List header marginBlock

                itemPadding: '20px 12px',
            },
            Menu: {
                itemDisabledColor: addAlpha(colorPalette.day.contentSecondary, 0.5),
                iconMarginInlineEnd: 12,
                itemSelectedBg: colorPalette.day.elementsPrimaryOpacity10,
                itemActiveBg: colorPalette.day.hoverRowMedium,
                itemHoverBg: colorPalette.day.rowHover,
                itemSelectedColor: colorPalette.day.contentPrimary,
                colorBgContainer: 'transparent',
                colorText: colorPalette.day.contentSecondary,
                colorPrimary: colorPalette.day.contentPrimary,
                collapsedIconSize: 20,
            },
            Modal: {
                marginXS: 24,
                headerBg: 'transparent',
                borderRadiusLG: 20,
                fontWeightStrong: 500,
                paddingContentHorizontalLG: 24,
                paddingMD: 24,
                padding: 16,
                colorBgMask: colorPalette.day.backgroundBlur,
                contentBg: colorPalette.day.bgBack,
                colorIconHover: addAlpha(colorPalette.day.contentPrimary, 0.5),
            },
            DatePicker: {
                lineHeight: 1.5714285714,
                colorBgContainer: 'transparent',
                fontSize: 14,
                fontSizeLG: 14,
                colorTextQuaternary: colorPalette.day.contentPrimary,
                colorBgElevated: colorPalette.day.bgAdditional,
                controlItemBgActive: colorPalette.day.elementsPrimaryOpacity10,
                colorTextDisabled: colorPalette.day.contentSecondary,
                colorTextDescription: colorPalette.day.contentSecondary,
                fontWeightStrong: 400,
                cellHoverWithRangeBg: addAlpha(colorPalette.day.elementsSecondary, 0.2),
                cellHoverBg: addAlpha(colorPalette.day.elementsSecondary, 0.2),
                cellActiveWithRangeBg: addAlpha(colorPalette.day.elementsSecondary, 0.2),
            },
            Notification: {
                colorText: colorPalette.day.contentSecondary,
                colorBgElevated: colorPalette.day.bgBack,
                colorIcon: colorPalette.day.contentSecondary,
                colorSuccess: colorPalette.day.elementsSuccess,
                borderRadiusLG: 20,
                width: 400,
            },
            Layout: {
                headerBg: 'transparent',
                colorText: addAlpha(colorPalette.day.elementsBorder, 0.5), // border color
                siderBg: 'transparent',
            },
            Popover: {
                boxShadowSecondary: '0px -10px 80px 0px rgba(0, 0, 0, 0.25)',
                paddingXS: 12,
                colorTextLightSolid: colorPalette.day.contentPrimary,
                colorBgElevated: colorPalette.day.bgAdditional,
                fontWeightStrong: 400,
                marginXS: 0, // popover title margin-bottom
            },
            Result: {
                paddingLG: 16,
                paddingXL: 56,
                marginXS: 12,
                extraMargin: '8px 0 0',
                colorFillAlter: 'transparent',
                titleFontSize: 20,
            },
            Segmented: {
                controlPaddingHorizontalSM: 12,
                colorBgLayout: colorPalette.day.elementsBg,
                colorText: colorPalette.day.elementsDarkGrey,
                colorTextDisabled: colorPalette.day.buttonContentDisabled,
                itemColor: colorPalette.day.contentSecondary,
                itemHoverColor: colorPalette.day.contentSecondary,
                fontSize: 12,
                itemSelectedBg: colorPalette.night.elementsLight,

                borderRadiusSM: 6,
                borderRadius: 8,

                controlPaddingHorizontal: 24,
            },
            Statistic: {
                contentFontSize: 14,
                colorText: 'inherit',
                colorTextHeading: 'inherit',
            },
            Table: {
                rowHoverBg: colorPalette.day.rowHover,
                rowSelectedBg: colorPalette.day.bgPrimary,
                rowSelectedHoverBg: colorPalette.day.hoverRowMedium,
                filterDropdownBg: colorPalette.day.bgAdditional,
                filterDropdownMenuBg: colorPalette.day.bgAdditional,
                colorIconHover: colorPalette.day.contentSecondary,
                headerFilterHoverBg: colorPalette.day.rowHover,
                headerBg: colorPalette.day.bgPrimary,
                headerSplitColor: 'transparent',
                headerColor: colorPalette.day.contentSecondary,
                colorBgContainer: 'transparent',
                fontWeightStrong: 400,
                fontSize: 12,
                colorFillAlter: colorPalette.day.rowHover,
                paddingContentVerticalLG: 20,
                padding: 12,
                cellPaddingInline: 8,
            },
            Typography: {
                colorTextDescription: colorPalette.day.contentSecondary,
                colorSuccess: colorPalette.day.elementsSuccess,
                colorTextDisabled: 'rgba(255, 255, 255, 0.5)',
                titleMarginBottom: 0,
                titleMarginTop: 0,
                fontWeightStrong: 500,
            },
            Tooltip: {
                fontSize: 14,
                // boxShadowSecondary: '0px -10px 40px 0px rgba(0, 0, 0, 0.25)',
                colorBgSpotlight: colorPalette.day.bgAdditional,
                colorTextLightSolid: colorPalette.day.contentPrimary,
                borderRadius: 12,
                paddingSM: 30,
                paddingXS: 16,
            },
            Form: {
                itemMarginBottom: 16,
                lineHeight: 1.7,
                verticalLabelPadding: '0 0 4px 0',
                paddingXS: 12,
                marginLG: 24,
                controlHeightLG: 0,
                colorTextDescription: colorPalette.day.contentSecondary,
                colorTextHeading: colorPalette.day.contentPrimary,
            },
            Radio: {
                // colorTextDisabled: addAlpha(colorPalette.day.contentPrimary, 0.5),
                dotColorDisabled: addAlpha(colorPalette.day.elementsPrimary, 0.5),
                colorPrimary: colorPalette.day.elementsPrimary,
                colorBorder: 'transparent',
                colorBgContainer: colorPalette.day.elementsBorder,
                wireframe: true,
            },
            QRCode: {
                boxShadow: '0px 0px 30px 0px rgba(0, 0, 0, 0.08)',
                colorBgContainer: colorPalette.day.elementsLight,
                colorText: colorPalette.day.elementsDarkGrey,
                colorBorder: colorPalette.day.elementsBorder,
            },
            Select: {
                optionPadding: '5.5px 12px',
                colorTextTertiary: addAlpha(colorPalette.day.buttonBgPrimary, 0.7),
                colorBgContainerDisabled: colorPalette.day.rowHover,
                colorTextDisabled: colorPalette.day.contentSecondary,
                fontSize: 14,
                colorBgContainer: 'transparent',
                fontSizeLG: 14,
                colorTextQuaternary: colorPalette.day.contentPrimary,
                colorBgElevated: colorPalette.day.bgAdditional,
                controlItemBgActive: colorPalette.day.elementsPrimaryOpacity10,
                fontWeightStrong: 400,
            },
            Skeleton: {
                colorFill: colorPalette.day.elementsBorder,
                borderRadiusSM: 12,
            },
            Steps: {
                colorTextLightSolid: colorPalette.day.buttonContentPrimary,
                colorTextDescription: colorPalette.day.contentSecondary,
                colorSplit: colorPalette.day.elementsBorder,
                colorBgContainer: 'transparent',
                wireframe: true,
                paddingLG: 18,
            },
            Switch: {
                colorPrimary: colorPalette.day.elementsSecondary,
                colorPrimaryHover: colorPalette.day.elementsSecondary10L,
                colorTextQuaternary: colorPalette.day.elementsBorder,
                colorTextTertiary: '#E8EBED', // switch hover color
            },
            Tag: {
                defaultBg: colorPalette.day.elementsNeutralOpacity10,
                fontSizeSM: 12,
                colorSuccessBg: colorPalette.day.elementsSuccessOpacity10,
                colorSuccess: colorPalette.day.elementsSuccess,
                colorErrorBg: colorPalette.day.elementsDangerOpacity10,
                colorError: colorPalette.day.elementsDanger,
                colorText: colorPalette.day.elementsNeutral,
                colorFillTertiary: colorPalette.day.elementsNeutralOpacity10,
                colorTextDescription: colorPalette.day.elementsNeutral,
                colorTextHeading: colorPalette.day.elementsNeutralOpacity10,
                borderRadiusSM: 4,
            },
            Button: {
                textHoverBg: colorPalette.day.rowHover,
                colorBgContainer: colorPalette.day.buttonBgSecondary,
                colorBorder: 'transparent',
                colorPrimary: colorPalette.day.buttonBgPrimary,
                colorPrimaryHover: colorPalette.day.elementsPrimary,
                colorPrimaryActive: colorPalette.day.elementsPrimary,
                colorTextDisabled: 'rgba(30, 33, 37, 0.2)',
                colorBgContainerDisabled: 'rgba(30, 33, 37, 0.05)',
                primaryColor: colorPalette.day.elementsControl,

                controlHeight: 32,
                controlHeightLG: 40,
                controlHeightSM: 24,
                fontSize: 12,
                fontSizeLG: 14,
                lineWidth: 1,
                lineWidthFocus: 2,
                marginXS: 8,
                motionDurationMid: '0.2s',
                motionDurationSlow: '0.3s',
                opacityLoading: 0.65,
                paddingContentHorizontal: 16,
                paddingInlineLG: 32,
                paddingXS: 8,

                controlOutline: 'transparent',
                controlTmpOutline: 'transparent',
            },
        },
        token: {
            colorBgElevated: colorPalette.day.bgAdditional,
            colorPrimary: colorPalette.day.elementsPrimary,
            colorBgLayout: colorPalette.day.bgBack,
            fontFamily: 'Inter',
            colorWarningBorder: '#ffb000',
            fontWeightStrong: 500,
            colorLink: colorPalette.day.elementsSecondary,
            colorLinkHover: colorPalette.day.elementsSecondary10L,
            colorLinkActive: colorPalette.day.elementsSecondary,
            colorBorder: colorPalette.day.elementsBorder,
            colorIcon: colorPalette.day.contentPrimary,
            colorSuccess: colorPalette.day.elementsSuccess,
            colorSuccessBg: colorPalette.day.elementsSuccessOpacity10,
            colorError: colorPalette.day.elementsDanger,
            colorErrorBg: colorPalette.day.elementsDangerOpacity10,
            colorWarning: colorPalette.day.elementsWarning,
            colorWarningBg: colorPalette.day.elementsWarningOpacity10,

            colorSplit: colorPalette.day.elementsBorder,
            colorBorderSecondary: colorPalette.day.elementsBorder,

            colorTextHeading: colorPalette.day.contentPrimary,
            colorText: colorPalette.day.contentPrimary,
            colorTextDescription: colorPalette.day.contentSecondary,
            colorTextPlaceholder: colorPalette.day.contentSecondary,

            borderRadius: 10,
            borderRadiusLG: 12,
            borderRadiusSM: 8,

            controlOutlineWidth: 0,
            controlItemBgHover: addAlpha(colorPalette.day.elementsDarkGrey, 0.04),
            colorBgBase: colorPalette.day.bgPrimary,
            purple: '#4033CA',

            lineHeight: 1.5,
            lineWidthFocus: 2,

            ...breakpointsMap,
        },
    },
}
