import { Button, Table } from '@merchant/ui-kit/ant-design'
import { isEmpty } from 'lodash-es'
import { useMemo, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import {
    PaymentDataModal,
    UnavailableCurrencyModal,
    type PaymentDataModalRef,
    type UnavailableCurrencyModalRef,
} from './components'
import { TableType } from './const'
import { lang } from './lang'
import { getContextSpecificCommonTableProps, getDepositAddressesColumns, getStaticAddressesTableColumns } from './utils'
import type { Props } from './types'
import { useCurrencies, useHoldingCurrencies } from '~api'
import { PageContentContainer } from '~components'
import { PlaceholderViewWrapper } from '~features/PlaceholderViewWrapper'
import { useNetworksMap } from '~hooks'

export function AddressesTable({ context, tableProps, moreButtonProps, filter, ...rest }: Props) {
    const unavailableCurrencyModalRef = useRef<UnavailableCurrencyModalRef>(null)
    const paymentDataModalRef = useRef<PaymentDataModalRef>(null)
    const { data: currencies } = useCurrencies()
    const { networks } = useNetworksMap()
    const { data: holdingCurrencies } = useHoldingCurrencies()

    const selectedHoldingCurrenciesMap = useMemo(() => {
        return (holdingCurrencies || []).reduce<Record<string, string>>((acc, { code, selected }) => {
            if (selected) {
                acc[code] = code
            }

            return acc
        }, {})
    }, [holdingCurrencies])

    const getConvertToCurrency = (currency: string | undefined) => {
        if (currency && !(currency in selectedHoldingCurrenciesMap)) {
            return holdingCurrencies?.[0]?.code
        }

        return
    }

    const { isVisible: isMoreButtonVisible, ...restMoreButtonProps } = moreButtonProps

    const contextSpecificCommonTableProps = getContextSpecificCommonTableProps(context, rest['data-merchant'])
    const isTableEmpty = isEmpty(tableProps.dataSource)

    const table =
        context === TableType.DepositAddresses ? (
            <Table
                showHeader={!isTableEmpty}
                columns={getDepositAddressesColumns({
                    currencies,
                    networks,
                    openUnavailableCurrencyModal: unavailableCurrencyModalRef.current?.open,
                    openPaymentDataModal: paymentDataModalRef.current?.open,
                    getConvertToCurrency,
                })}
                rowKey={({ id }) => id}
                {...contextSpecificCommonTableProps}
                {...tableProps}
            />
        ) : (
            <Table
                showHeader={!isTableEmpty}
                columns={getStaticAddressesTableColumns({
                    currencies,
                    networks,
                    openUnavailableCurrencyModal: unavailableCurrencyModalRef.current?.open,
                    openPaymentDataModal: paymentDataModalRef.current?.open,
                    getConvertToCurrency,
                })}
                rowKey={({ id }) => id}
                {...contextSpecificCommonTableProps}
                {...tableProps}
            />
        )

    return (
        <PlaceholderViewWrapper dataMerchantPrefix={rest['data-merchant']}>
            <PageContentContainer>
                {filter}
                <UnavailableCurrencyModal ref={unavailableCurrencyModalRef} />
                <PaymentDataModal ref={paymentDataModalRef} />
                {table}
                {isMoreButtonVisible && (
                    <Button
                        size="large"
                        style={{ marginTop: 24 }}
                        {...restMoreButtonProps}
                        data-merchant={`${rest['data-merchant']}-show-more-button`}
                    >
                        <FormattedMessage
                            {...(context === TableType.StaticAddresses
                                ? lang.staticAddressesShowMore
                                : lang.depositAddressesShowMore)}
                        />
                    </Button>
                )}
            </PageContentContainer>
        </PlaceholderViewWrapper>
    )
}
