export const enum ModalNames {
    'convertInput' = 'convertInput',
    'convertProgress' = 'convertProgress',

    'withdrawalCryptoInput' = 'withdrawalCryptoInput',
    'withdrawalCryptoSummary' = 'withdrawalCryptoSummary',
    'withdrawalCryptoConfirmation' = 'withdrawalCryptoConfirmation',
    'withdrawalCryptoProgress' = 'withdrawalCryptoProgress',

    'withdrawalFiatInput' = 'withdrawalFiatInput',
    'withdrawalFiatSummary' = 'withdrawalFiatSummary',
    'withdrawalFiatConfirmation' = 'withdrawalFiatConfirmation',
    'withdrawalFiatFinish' = 'withdrawalFiatFinish',

    'createApiKeyForm' = 'createApiKeyForm',
    'createApiKeyConfirmation' = 'createApiKeyConfirmation',
    'createApiKeyCreated' = 'createApiKeyCreated',

    'callbackUrlCreate' = 'callbackUrlCreate',
    'callbackUrlDelete' = 'callbackUrlDelete',
    'callbackUrlRegenerate' = 'callbackUrlRegenerate',
    'callbackUrlEdit' = 'callbackUrlEdit',

    'changePassword' = 'changePassword',

    'whitelistEnableAcknowledgement' = 'whitelistEnableAcknowledgement',
    'whitelistEnableConfirmation' = 'whitelistEnableConfirmation',
    'whitelistAddInput' = 'whitelistAddInput',
    'whitelistAddConfirmation' = 'whitelistAddConfirmation',
    'whitelistDelete' = 'whitelistDelete',
    'whitelistDisable' = 'whitelistDisable',

    'merchantCurrenciesSettings' = 'merchantCurrenciesSettings',

    'add2Fa' = 'add2Fa',
    'add2FaConfirmation' = 'add2FaConfirmation',
    'add2FaWriteDown' = 'add2FaWriteDown',
    'toggle2Fa' = 'toggle2Fa',
    'edit2Fa' = 'edit2Fa',
    'delete2Fa' = 'delete2Fa',

    // team management
    'editTeamMember' = 'editTeamMember',
    'teamMemberConfirmationDefault' = 'teamMemberConfirmationDefault',
    'deleteTeamMemberConfirmation' = 'deleteTeamMemberConfirmation',
}
