import { Form } from '@merchant/ui-kit/ant-design'
import { useEffect } from 'react'
import type { NewPaymentFormData } from './types'
import { useProject } from '~api'

export function useData() {
    const { data: project } = useProject()
    const [form] = Form.useForm<NewPaymentFormData>()

    useEffect(() => {
        if (!project) {
            return
        }

        form.setFieldsValue({
            feesPayer: project.feesPayer,
            successRedirectUrl: project.redirectUrlSuccess,
            failureRedirectUrl: project.redirectUrlFailure,
        })
    }, [form, project])

    return {
        form,
        project,
    }
}
