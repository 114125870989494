import { assets } from '@merchant/shared/assets/nameMap'
import { lang } from './lang'
import type { GlobalToken } from '@merchant/ui-kit/ant-design'
import type { MessageDescriptor } from 'react-intl'
import { WithdrawalState } from '~api'

export const withdrawalStatesMap: Record<
    WithdrawalState,
    {
        icon: (typeof assets)[keyof typeof assets]
        title: MessageDescriptor
        color: keyof Pick<GlobalToken, 'colorSuccess' | 'colorWarning' | 'colorError' | 'colorTextPlaceholder'>
    }
> = {
    [WithdrawalState.Pending]: {
        icon: assets.clock,
        color: 'colorWarning',
        title: lang.inProgress,
    },
    [WithdrawalState.Failed]: {
        icon: assets.alertCircle,
        color: 'colorError',
        title: lang.failed,
    },
    [WithdrawalState.Success]: {
        icon: assets.checkCircle,
        color: 'colorSuccess',
        title: lang.success,
    },
    [WithdrawalState.Canceled]: {
        icon: assets.xCircle,
        color: 'colorTextPlaceholder',
        title: lang.canceled,
    },
} as const
