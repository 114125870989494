import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    modalTitleCreate: {
        id: 'integrations-page.create-modal.create-title',
        defaultMessage: 'Create API Key',
    },
    modalTitleSuccess: {
        id: 'integrations-page.create-modal.success-title',
        defaultMessage: 'API key successfully created',
    },
    createButton: {
        id: 'integrations-page.create-modal.create_button',
        defaultMessage: 'Create',
    },
})
