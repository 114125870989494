import { formatNumber } from '@merchant/shared/utils'
import type { WithdrawalAmountMode, WithdrawalCryptoFormData } from './types'
import type { Formatter } from '@merchant/ui-kit/ant-design/es/statistic/utils'
import { WithdrawalState, type Withdrawal } from '~api'

export const formatNumberToFractionDigits =
    (minimumFractionDigits: number): Formatter =>
    value => {
        return formatNumber({
            value,
            numberFormatOptions: { minimumFractionDigits },
        })
    }

export const getAmountValueByMode = (amount: WithdrawalCryptoFormData['amount'], mode: WithdrawalAmountMode) => {
    if (mode === 'credit' && mode in amount) {
        return amount[mode]
    } else if (mode === 'receive' && mode in amount) {
        return amount[mode]
    }

    return undefined
}

export const isSomeDataLoading = (...args: boolean[]) => args.some(Boolean)

export const assembleWithdrawalTempRecord = ({
    txId = '',
    withdrawalFormData,
}: {
    withdrawalFormData: WithdrawalCryptoFormData
    txId: string | undefined
}): Withdrawal & {
    whitelistKey?: WithdrawalCryptoFormData['whitelistKey']
} => {
    const {
        receiveAmount = '',
        withdrawalAmount: creditAmount = '',
        fee: networkFee = '',
        currency: creditCurrency = '',
        network: receiveNetwork = '',
        address = '',
        whitelistKey = '',
    } = withdrawalFormData

    return {
        receiveAmount,
        id: txId,
        state: WithdrawalState.Pending,
        creditAmount,
        networkFee,
        creditCurrency,
        receiveNetwork,
        address,
        hash: '',
        createdAt: new Date(),
        updatedAt: new Date(),
        receiveCurrency: creditCurrency,
        whitelistKey,
    }
}
