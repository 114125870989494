import { Skeleton } from '@merchant/ui-kit/ant-design'
import cn from 'classnames'
import { memo } from 'react'
import { InView } from 'react-intersection-observer'
import { useAssetSource } from '../useAssetSrc'
import { skeletonStyle } from './const'
import styles from './styles.module.css'
import { useLoadImage } from './useLoadImage'
import { getIsNetworkShown, getWrapperClassName } from './utils'
import type { CoinSize } from '../types'
import type { CSSProperties, ReactNode, Ref } from 'react'

export interface Props {
    currencyCode: string
    size?: CoinSize
    className?: string
    lazyLoad?: boolean
    themed?: boolean
    style?: CSSProperties
    subPath?: string
    networkCode?: string
}

interface ImgContentProps extends Pick<Props, 'size' | 'networkCode' | 'className' | 'className'> {
    code: string
    path: string
    networkPath?: string
    wrapperRef?: Ref<HTMLDivElement>
    inView?: boolean
    style?: CSSProperties
    isNetwork?: boolean
}

function ImgContent({
    code,
    networkCode = '',
    size = 'middle',
    path,
    networkPath = '',
    wrapperRef,
    style,
    inView = true,
    className,
    isNetwork = false,
}: ImgContentProps) {
    const { handleError, handleLoad, isImgError, isImgLoading, isFallback } = useLoadImage()

    let content: ReactNode = null

    if (inView) {
        content = (
            <div className={getWrapperClassName(isNetwork, isFallback, isImgLoading)}>
                {!isImgError && (
                    <img
                        className={styles.img}
                        alt={`Cryptocurrency ${code} icon`}
                        src={path}
                        onError={handleError}
                        onLoad={handleLoad}
                    />
                )}
                {getIsNetworkShown({ currencyCode: code, networkCode, networkPath, isNetwork }) && (
                    <ImgContent path={networkPath} code={networkCode} isNetwork />
                )}
                {isImgLoading && (
                    <Skeleton.Avatar
                        active
                        shape="circle"
                        className={cn(styles.loadingSkeleton, { [styles.noBorder || '']: isNetwork })}
                        style={skeletonStyle}
                    />
                )}
                <span className={cn(styles.code, { [styles.hidden!]: !isFallback })}>{code.charAt(0)}</span>
            </div>
        )
    }

    if (isNetwork) {
        return content
    }

    return (
        <div
            ref={wrapperRef}
            style={style}
            className={cn(styles.container, styles[size], className, { [styles.filled!]: isFallback })}
        >
            {content}
        </div>
    )
}

function Component({
    currencyCode,
    size,
    style,
    themed,
    subPath = 'assets/coins',
    lazyLoad,
    className,
    networkCode,
}: Props) {
    const coinIconName = `${currencyCode}.svg`
    const networkIconName = `${networkCode}.svg`
    const coinSrc = useAssetSource({ name: coinIconName, themed, subPath })
    const networkSrc = useAssetSource({ name: networkIconName, themed, subPath })

    if (lazyLoad) {
        return (
            <InView triggerOnce>
                {({ inView, ref }) => (
                    <ImgContent
                        size={size}
                        code={currencyCode}
                        path={coinSrc}
                        networkPath={networkSrc}
                        networkCode={networkCode}
                        style={style}
                        inView={inView}
                        wrapperRef={ref}
                        className={className}
                    />
                )}
            </InView>
        )
    }

    return (
        <ImgContent
            key={currencyCode}
            size={size}
            path={coinSrc}
            code={currencyCode}
            style={style}
            className={className}
            networkPath={networkSrc}
            networkCode={networkCode}
        />
    )
}

export const AppCoin = memo(Component)
