import type { CreateApiKeyFormData } from '../../types'
import type { FormItemsKey } from '@merchant/shared/types/form'
import { ProjectAPIKeyPermissionID } from '~api'
import { ApiKeyMode } from '~features/IntegrationsPage/views/Integrations/const'

export const formItemsKey: FormItemsKey<CreateApiKeyFormData> = {
    name: 'name',
    permissions: 'permissions',
}

export const initialValues = { permissions: { [ProjectAPIKeyPermissionID.Full]: ApiKeyMode.Write }, name: '' }
