export enum PosthogAuthEvents {
    onSignUpPage = 'ms_lk_registration_start_screen',
    createAccount = 'ms_lk_registration_create_acc',
    createAccountConfirmation = 'ms_lk_registration_confirm',
    goToSignInPage = 'ms_lk_registration_to_login_flow',

    onSignInPage = 'ms_lk_login_screen',
    loginAttempt = 'ms_lk_login_login',
    goToSignUpPage = 'ms_lk_login_to_reg_flow',

    forgotPasswordRequest = 'ms_lk_forgot_password',
    resetPasswordRequest = 'ms_lk_new_password',
}

export type CommonAuthEventData = {
    system_theme: 'dark' | 'light'
    web_theme: 'dark' | 'light' | 'system'
}

export interface AuthEventDataMap {
    [PosthogAuthEvents.onSignUpPage]: CommonAuthEventData
    [PosthogAuthEvents.createAccount]: CommonAuthEventData & {
        success: boolean
    }
    [PosthogAuthEvents.createAccountConfirmation]: {
        success: boolean
        code: string
    }
    [PosthogAuthEvents.goToSignInPage]: CommonAuthEventData
    [PosthogAuthEvents.onSignInPage]: CommonAuthEventData
    [PosthogAuthEvents.loginAttempt]: CommonAuthEventData & {
        success: boolean
    }
    [PosthogAuthEvents.goToSignUpPage]: CommonAuthEventData
    [PosthogAuthEvents.forgotPasswordRequest]: CommonAuthEventData
    [PosthogAuthEvents.resetPasswordRequest]: CommonAuthEventData & {
        code: string
        success: boolean
    }
}
