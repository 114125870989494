import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { usePromise } from '@merchant/shared/hooks'
import { Button, Flex, Tooltip, Typography } from '@merchant/ui-kit/ant-design'
import { isEmpty } from 'lodash-es'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { lang } from './lang'
import type { CallbackResponse, Withdrawal } from '~api'
import { Permission, projectApi, useProject, useProjectCallback, WithdrawalState } from '~api'
import { CallbackResultModal } from '~components'
import { routes } from '~constants/routes'
import { useUserPermissionContext } from '~contexts'

const { Text } = Typography

interface Props {
    data: Withdrawal
}

export function WithdrawalActions({ data }: Props) {
    const { data: project } = useProject()
    const { checkUserPermission } = useUserPermissionContext()
    const { data: callback } = useProjectCallback()
    const [testCallbackResponse, setTestCallbackResponse] = useState<CallbackResponse | null>(null)
    const { send: sendCallback, isLoading: isSendingCallback } = usePromise(
        () => projectApi.sendProjectWithdrawalCallback({ projectId: project?.id || '', withdrawalId: data.id }),
        { onSuccess: setTestCallbackResponse }
    )
    const { send: cancel, isLoading: isCancelling } = usePromise(() =>
        projectApi.cancelProjectWithdrawal({ projectId: project?.id || '', id: data.id })
    )
    const canCancelWithdrawal = data.state === WithdrawalState.Pending && !data.hash

    const hasSetupCallback = !isEmpty(callback)
    const callbackButtonTooltipTitle = !hasSetupCallback ? (
        <Text data-merchant="withdrawal-send-status-callback-tooltip">
            <FormattedMessage
                {...lang.sendStatusCallbackTooltip}
                values={{
                    link: chunks => <Link to={routes.integrations}>{chunks}</Link>,
                }}
            />
        </Text>
    ) : null

    return (
        <>
            <CallbackResultModal
                type="withdrawal"
                data={testCallbackResponse}
                onCancel={() => setTestCallbackResponse(null)}
            />
            <Flex vertical gap={12} style={{ marginTop: 12 }}>
                {checkUserPermission(Permission.SendWithdrawalCallback) && (
                    <Tooltip data-merchant={null} placement="bottom" title={callbackButtonTooltipTitle}>
                        <Button
                            disabled={!hasSetupCallback}
                            size="large"
                            onClick={sendCallback}
                            loading={isSendingCallback}
                            data-merchant="withdrawal-send-status-callback-button"
                        >
                            <FormattedMessage {...lang.sendStatusCallback} />
                        </Button>
                    </Tooltip>
                )}
                {checkUserPermission(Permission.CancelCryptoWithdrawals) && canCancelWithdrawal && (
                    <Button
                        size="large"
                        onClick={cancel}
                        type="primary"
                        loading={isCancelling}
                        data-merchant="withdrawal-cancel-button"
                        style={{ gap: 4 }}
                        icon={
                            <AppSvg
                                size={16}
                                name={assets.plus}
                                color={token => token.colorBgContainer}
                                style={{ transform: 'rotate(45deg)', verticalAlign: 'middle' }}
                            />
                        }
                    >
                        <FormattedMessage {...lang.cancel} />
                    </Button>
                )}
            </Flex>
        </>
    )
}
