import { AppCoin, InlineLinkButton } from '@merchant/shared/components'
import { Flex, Typography } from '@merchant/ui-kit/ant-design'
import { capitalize } from 'lodash-es'
import { FormattedMessage } from 'react-intl'
import { lang as commonLang } from '../../lang'
import {
    AddressCell,
    CopyablePopover,
    DepositButton,
    type PaymentDataModalRef,
    type UnavailableCurrencyModalRef,
} from './components'
import { tableCellTextCommonStyle, TableType } from './const'
import { lang } from './lang'
import styles from './style.module.css'
import type { NormalizedCustomerAddress, NormalizedProjectAddress } from './types'
import type { TableColumnsType } from '@merchant/ui-kit/ant-design'
import type { Network } from '~api'
import type { Currencies } from '~api/types'
import { EmptyTable, NoValueColumnContent } from '~components'

const { Text } = Typography

type Params = {
    currencies?: Currencies
    networks: Record<string, Network>
    openUnavailableCurrencyModal: UnavailableCurrencyModalRef['open'] | undefined
    openPaymentDataModal: PaymentDataModalRef['open'] | undefined
    getConvertToCurrency: (currencyCode: string | undefined) => string | undefined
}

const commonRenderers = {
    renderCreatedAt: (created: (NormalizedCustomerAddress | NormalizedProjectAddress)['createdAt']) => (
        <Flex vertical>
            <Text style={tableCellTextCommonStyle} data-merchant="addresses-table-created-date">
                {created.format('DD/MM/YYYY')}
            </Text>
            <Text type="secondary" style={tableCellTextCommonStyle} data-merchant="addresses-table-created-time">
                {created.format('hh:mm:ss')}
            </Text>
        </Flex>
    ),
    renderCurrency:
        (currencies: Params['currencies']) =>
        (currency: (NormalizedCustomerAddress | NormalizedProjectAddress)['currency']) => (
            <Flex gap={12}>
                <AppCoin currencyCode={currency} />
                <Flex vertical>
                    <Text style={tableCellTextCommonStyle} data-merchant="addresses-table-currency-code">
                        {currency}
                    </Text>
                    <Text
                        type="secondary"
                        style={tableCellTextCommonStyle}
                        data-merchant="addresses-table-currency-name"
                    >
                        {currencies?.[currency]?.name}
                    </Text>
                </Flex>
            </Flex>
        ),
    renderNetwork:
        (currencies: Params['currencies']) => (thumb: NormalizedCustomerAddress | NormalizedProjectAddress) => {
            const network = currencies?.[thumb.currency]?.networks.find(({ code }) => code === thumb.network)

            if (!network) {
                return NoValueColumnContent
            }

            return (
                <Flex vertical>
                    <Text style={tableCellTextCommonStyle} data-merchant="addresses-table-network-name">
                        {network.name}
                    </Text>
                    <Text
                        type="secondary"
                        style={tableCellTextCommonStyle}
                        data-merchant="addresses-table-network-protocol"
                    >
                        {network.protocol}
                    </Text>
                </Flex>
            )
        },
    renderAddressAndTag:
        (params: Omit<Params, 'openPaymentDataModal'> & { context: TableType }) =>
        (thumb: NormalizedCustomerAddress | NormalizedProjectAddress) => (
            <AddressCell
                context={params.context}
                addressTag={thumb.addressTag}
                address={thumb.address}
                network={thumb.network ? params.networks[thumb.network] : undefined}
                currency={params.currencies?.[thumb.currency]}
                openUnavailableCurrencyModal={params.openUnavailableCurrencyModal}
                getConvertToCurrency={params.getConvertToCurrency}
            />
        ),
}

// eslint-disable-next-line max-lines-per-function
export const getStaticAddressesTableColumns = ({
    currencies,
    networks,
    openUnavailableCurrencyModal,
    openPaymentDataModal,
    getConvertToCurrency,
}: Params): TableColumnsType<NormalizedCustomerAddress> => [
    {
        title: <FormattedMessage {...lang.staticAddressesCreated} />,
        dataIndex: 'createdAt',
        render: commonRenderers.renderCreatedAt,
        width: 70,
    },
    {
        title: <FormattedMessage {...lang.staticAddressesIdAndSource} />,
        render: (thumb: NormalizedCustomerAddress) => (
            <Flex vertical>
                <CopyablePopover value={thumb.id} data-merchant="addresses-table-id-popover">
                    <Text
                        style={{
                            ...tableCellTextCommonStyle,
                            textWrap: 'nowrap',
                        }}
                        ellipsis
                        data-merchant="addresses-table-id"
                    >
                        {thumb.id}
                    </Text>
                </CopyablePopover>
                <Text type="secondary" style={tableCellTextCommonStyle} data-merchant="addresses-table-origin">
                    {thumb.origin}
                </Text>
            </Flex>
        ),
        width: 125,
    },
    {
        title: <FormattedMessage {...lang.staticAddressesClientIdAndEmail} />,
        render: (thumb: NormalizedCustomerAddress) => (
            <Flex vertical>
                <CopyablePopover value={thumb.customerId} data-merchant="addresses-table-customerId-popover">
                    <Text style={tableCellTextCommonStyle} ellipsis data-merchant="addresses-table-customerId">
                        {thumb.customerId}
                    </Text>
                </CopyablePopover>
                <Text type="secondary" style={tableCellTextCommonStyle} data-merchant="addresses-table-customerEmail">
                    {thumb.customerEmail}
                </Text>
            </Flex>
        ),
        width: 120,
    },
    {
        title: <FormattedMessage {...lang.staticAddressesCurrency} />,
        dataIndex: 'currency',
        render: commonRenderers.renderCurrency(currencies),
        width: 100,
    },
    {
        title: <FormattedMessage {...lang.staticAddressesNetwork} />,
        render: commonRenderers.renderNetwork(currencies),
        width: 70,
    },
    {
        title: <FormattedMessage {...lang.staticAddressesAddressAndTag} />,
        render: commonRenderers.renderAddressAndTag({
            context: TableType.StaticAddresses,
            currencies,
            getConvertToCurrency,
            networks,
            openUnavailableCurrencyModal,
        }),
        width: 115,
    },
    {
        title: <FormattedMessage {...lang.staticAddressesFeesPayer} />,
        dataIndex: 'feesPayer',
        render: (feesPayer: NormalizedCustomerAddress['feesPayer'], record) => (
            <Text data-merchant={`addresses-table-fees-payer-${record.id}`} ellipsis style={tableCellTextCommonStyle}>
                {feesPayer ? capitalize(feesPayer) : <FormattedMessage {...lang.staticAddressesDefaultFeesPayer} />}
            </Text>
        ),
        width: 70,
    },
    {
        title: <FormattedMessage {...lang.staticAddressesMetaInfo} />,
        render: (thumb: NormalizedCustomerAddress) =>
            thumb.paymentData ? (
                <InlineLinkButton
                    style={{
                        textDecoration: 'underline',
                        ...tableCellTextCommonStyle,
                    }}
                    onClick={() => openPaymentDataModal?.(thumb.paymentData)}
                    data-merchant="addresses-table-payment-data-button"
                >
                    <FormattedMessage {...commonLang.paymentData} />
                </InlineLinkButton>
            ) : (
                NoValueColumnContent
            ),
        width: 80,
    },
]

export const getDepositAddressesColumns = ({
    currencies,
    networks,
    openUnavailableCurrencyModal,
    getConvertToCurrency,
}: Params): TableColumnsType<NormalizedProjectAddress> => [
    {
        title: <FormattedMessage {...lang.depositAddressesCreated} />,
        dataIndex: 'createdAt',
        render: commonRenderers.renderCreatedAt,
        width: 70,
    },
    {
        title: <FormattedMessage {...lang.depositAddressesId} />,
        render: (thumb: NormalizedProjectAddress) => (
            <CopyablePopover value={thumb.id} data-merchant="addresses-table-id-popover">
                <Text
                    style={{
                        ...tableCellTextCommonStyle,
                        textWrap: 'nowrap',
                    }}
                    ellipsis
                    data-merchant="addresses-table-id"
                >
                    {thumb.id}
                </Text>
            </CopyablePopover>
        ),
        width: 125,
    },
    {
        title: <FormattedMessage {...lang.depositAddressesCurrency} />,
        dataIndex: 'currency',
        render: commonRenderers.renderCurrency(currencies),
        width: 70,
    },
    {
        title: <FormattedMessage {...lang.depositAddressesNetwork} />,
        render: commonRenderers.renderNetwork(currencies),
        width: 70,
    },
    {
        title: <FormattedMessage {...lang.depositAddressesAddressAndTag} />,
        render: commonRenderers.renderAddressAndTag({
            context: TableType.DepositAddresses,
            currencies,
            getConvertToCurrency,
            networks,
            openUnavailableCurrencyModal,
        }),
        width: 115,
    },
    {
        render: (thumb: NormalizedProjectAddress) => (
            <DepositButton
                data={thumb}
                openUnavailableCurrencyModal={openUnavailableCurrencyModal}
                data-merchant="addresses-table-deposit-button"
            />
        ),
        align: 'right',
        width: 80,
    },
]

export const getContextSpecificCommonTableProps = (context: TableType, dataMerchant: string) =>
    ({
        className: styles.table,
        style: { marginTop: 12 },
        pagination: false,
        tableLayout: 'fixed',
        scroll: { y: 740, x: 1000 },
        locale: {
            emptyText: (
                <EmptyTable
                    subtitleDescriptor={
                        context === TableType.DepositAddresses
                            ? lang.depositAddressesNoAddressesYet
                            : lang.staticAddressesNoAddressesYet
                    }
                />
            ),
        },
        'data-merchant': `${dataMerchant}-table`,
    }) as const
