import useSWR from 'swr'
import { cmsRestKeys } from '../keys'
import type { SWRConfiguration } from 'swr'
import type { LocaleCode } from '~constants'
import { getCommonAppSettings, getLocaleMessages, getSupportedLocales } from '~api/instances'

export const useCommonAppSettings = (config?: SWRConfiguration) => {
    return useSWR(cmsRestKeys.getCommonSettings, getCommonAppSettings, {
        ...config,
        fallbackData: window.__APP_SETTINGS_INITIAL__,
    })
}

export const useLocaleMessages = (locale: LocaleCode | null, config?: SWRConfiguration) => {
    return useSWR(
        locale ? cmsRestKeys.getLocaleMessages + locale : null,
        () => getLocaleMessages(locale as LocaleCode),
        {
            revalidateIfStale: false,
            keepPreviousData: true,
            fallbackData: locale ? window.__APP_MESSAGES_INITIAL__?.[locale] : null,
            ...config,
        }
    )
}

export const useSupportedLocales = (config?: SWRConfiguration) => {
    return useSWR(cmsRestKeys.getSupportedLocales, getSupportedLocales, {
        revalidateIfStale: false,
        keepPreviousData: true,
        fallbackData: window.__APP_SUPPORTED_LOCALES_INITIAL__,
        ...config,
    })
}
