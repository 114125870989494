import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    disableAlertDescription: {
        id: 'whitelist-page.alert.disable_description',
        defaultMessage:
            'After disabling the whitelist feature, withdrawals will be disabled for <boldText>72 hours</boldText> as a security measure to protect your account.',
    },
    modalTitle: {
        id: 'whitelist-page.modal.disable_modal_title',
        defaultMessage: 'Disable whitelist',
    },
})
