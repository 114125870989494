import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    unavailableDeposits: {
        id: 'addresses-table.unavailable_deposits',
        defaultMessage: 'Deposits in {currency}({network}) are temporarily unavailable! ',
    },
    unavailableDepositsButton: {
        id: 'addresses-table.unavailable_deposits_button',
        defaultMessage: 'What to do if {currency}({network}) has been recently sent to you?',
    },
})
