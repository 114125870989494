import { theme } from '@merchant/ui-kit/ant-design'
import { useIntl } from 'react-intl'
import {
    getOrdersMenuItem,
    getBalancesMenuItem,
    getTransactionMenuItem,
    getIntegrationsMenuItem,
    getSettingsMenuItem,
    getMerchantMenuItem,
    getSupportMenuItems,
    getAddressesMenuItem,
    getWithdrawalsMenuItem,
} from './menuItems'
import type { CommonParams, MenuItemExtendedType } from './types'
import { useProject } from '~api'
import { useUserPermissionContext } from '~contexts'

const { useToken } = theme

interface Props {
    isMerchantDisabled?: boolean
    isLayoutSiderCollapsed: boolean
}

export const useMenuItems = ({ isMerchantDisabled = false, isLayoutSiderCollapsed }: Props): MenuItemExtendedType[] => {
    const { checkUserPermission } = useUserPermissionContext()
    const intl = useIntl()
    const { data: project, isLoading: isProjectLoading, isDisabled: isProjectDisabled } = useProject()

    const { token } = useToken()

    const commonParams: CommonParams = {
        checkUserPermission,
        isLayoutSiderCollapsed,
    }

    return [
        getOrdersMenuItem(commonParams),
        getBalancesMenuItem(commonParams),
        getWithdrawalsMenuItem(commonParams),
        getAddressesMenuItem(commonParams),
        getTransactionMenuItem(commonParams),
        getMerchantMenuItem({
            ...commonParams,
            isMerchantDisabled,
            isProjectDisabled,
            token,
            isLayoutSiderCollapsed,
            isCurrencySettingDisabled: !project && !isProjectLoading,
            isLoadingData: isProjectLoading,
        }),
        getIntegrationsMenuItem(commonParams),
        getSettingsMenuItem(commonParams),
        {
            type: 'divider',
            key: 'nav-menu-divider',
            'data-merchant': 'nav-menu-divider',
            style: { marginBlock: '12px 8px' },
        },
        ...getSupportMenuItems(intl),
    ]
}
