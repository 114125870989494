import { Typography } from '@merchant/ui-kit/ant-design'

const { Paragraph } = Typography

export function formatStringToParagraphs(value: string | undefined, dataMerchant: string): React.ReactNode {
    if (!value) {
        return ''
    }

    return JSON.stringify(value)
        .replace(/^"|"$/g, '')
        .split('\\n')
        .map((line, index) => (
            <Paragraph style={{ margin: 0 }} key={index} data-merchant={`${dataMerchant}-${index}`}>
                {line}
            </Paragraph>
        ))
}
