import { Form } from '@merchant/ui-kit/ant-design'
import { useEffect } from 'react'
import type { FormInstance, FormItemProps } from '@merchant/ui-kit/ant-design'
import { getPrecisionCutValue } from '~utils'

interface Props<Values = unknown> extends FormItemProps<Values> {
    children: React.ReactNode
    precision: number
    form: FormInstance
}

export function AmountFormItem<Values = unknown>({ children, precision, form, name, ...rest }: Props<Values>) {
    useEffect(() => {
        const value = form.getFieldValue(name)
        if (value) {
            const decimalLength = String(value).split('.')[1]?.length || 0

            if (decimalLength > precision) {
                form.setFieldValue(name, getPrecisionCutValue({ value, precision }))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [precision])

    return (
        <Form.Item name={name} {...rest}>
            {children}
        </Form.Item>
    )
}
