import { Form } from '@merchant/ui-kit/ant-design'
import dayjs from 'dayjs'
import { FormattedMessage } from 'react-intl'
import { ExpirationDate } from '../../..'
import { lang } from '../lang'
import type { FormInstance, FormItemProps } from '@merchant/ui-kit/ant-design'
import type { NewPaymentFormData } from '~features/PaymentConstructor/view/OrderConstructor/types'
import { lang as commonLang } from '~features/PaymentConstructor/lang'
import { newOrderFormKeys } from '~features/PaymentConstructor/view/OrderConstructor/const'
import { globalLang } from '~globalLang'

interface Props extends FormItemProps {
    form: FormInstance<NewPaymentFormData>
}

export function ExpirationTimeItem({ form, ...rest }: Props) {
    return (
        <Form.Item
            {...rest}
            required
            name={newOrderFormKeys.pendingDeadlineAt}
            tooltip={<FormattedMessage {...lang.expirationTimeTooltip} />}
            rules={[
                {
                    required: true,
                    message: (
                        <span data-merchant="order-expiration-required-error">
                            <FormattedMessage {...globalLang.requiredFieldMessage} />
                        </span>
                    ),
                },
                {
                    validator(_, value: NewPaymentFormData['pendingDeadlineAt']) {
                        return typeof value !== 'number' && dayjs(value).endOf('minute').isBefore(dayjs())
                            ? Promise.reject()
                            : Promise.resolve()
                    },
                    message: (
                        <span data-merchant="order-expiration-too-early-error">
                            <FormattedMessage {...lang.expirationTimeIsTooEarly} />
                        </span>
                    ),
                },
            ]}
            label={<FormattedMessage {...commonLang.expirationTime} />}
        >
            <ExpirationDate
                setExpirationDateFieldValue={expirationDateValue =>
                    form.setFieldValue(newOrderFormKeys.pendingDeadlineAt, expirationDateValue)
                }
            />
        </Form.Item>
    )
}
