import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    successUrl: {
        id: 'merchant-page.input.success_url',
        defaultMessage: 'Success page URL',
    },
    failureUrl: {
        id: 'merchant-page.input.failure_url',
        defaultMessage: 'Failure page URL',
    },
    feeIsPaidBy: {
        id: 'merchant-page.form.fee_is_paid_by_label',
        defaultMessage: 'Fee is paid by',
    },
    feeIsPaidByDescription: {
        id: 'merchant-page.form.fee_is_paid_by_description',
        defaultMessage:
            'Default setting for who will cover payment fees: clients (buyers) or merchant (your shop). Can be changed anytime or specified for each payment via API.',
    },
    client: {
        id: 'merchant-page.radio.client',
        defaultMessage: 'Client',
    },
    merchant: {
        id: 'merchant-page.radio.merchant',
        defaultMessage: 'Merchant',
    },
    redirectLinks: {
        id: 'merchant-page.form.redirect_links_label',
        defaultMessage: 'Redirect links',
    },
    redirectLinksDescription: {
        id: 'merchant-page.form.redirect_links_label_description',
        defaultMessage: 'Direct users here after successful payments and after failed payments',
    },
    successLinkTooltip: {
        id: 'merchant-page.tooltip.success_link',
        defaultMessage: 'Success Page URL(Optional): Direct users here after successful payments, e.g., {url}',
    },
    successLinkTooltipUrl: {
        id: 'merchant-page.tooltip.success_link_url',
        defaultMessage: 'https://yourwebsite.com/success',
    },
    failLinkTooltip: {
        id: 'merchant-page.tooltip.fail_link',
        defaultMessage: 'Failure Page URL(Optional): Direct users here after failed payments, e.g., {url}',
    },
    failLinkTooltipUrl: {
        id: 'merchant-page.tooltip.fail_link_url',
        defaultMessage: 'https://yourwebsite.com/fail',
    },
    settingsSuccessChangeMessage: {
        id: 'merchant-page.settings.success_change_message',
        defaultMessage: 'Payments settings have been successfully updated',
    },
})
