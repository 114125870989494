import { Form, type SelectProps } from '@merchant/ui-kit/ant-design'
import { useEffect } from 'react'
import { useBoolean } from 'usehooks-ts'
import { newOrderFormKeys } from '../../../const'
import { CUSTOM_OPTION_VALUE } from './const'
import { useCurrencies, useProject } from '~api'
import { getBaseCurrenciesOptions } from '~components/BaseCurrencySelect/utils'

export function useData() {
    const form = Form.useFormInstance()
    const { data: { lossConfig } = {}, isLoading: isProjectLoading } = useProject()
    const { data: currencies } = useCurrencies()

    const { value: isAbsoluteCheckboxEnabled, setValue: setIsAbsoluteCheckboxEnabled } = useBoolean(false)
    const { value: isCustomSelected, setValue: setIsCustomSelected } = useBoolean(false)

    const baseCurrenciesOptions = getBaseCurrenciesOptions(currencies)

    const handleCheckboxChange = (checked: boolean) => {
        const formLossData = form.getFieldValue(newOrderFormKeys.lossConfig)
        setIsAbsoluteCheckboxEnabled(checked)
        form.setFields([
            {
                name: newOrderFormKeys.lossConfig,
                value: {
                    ...formLossData,
                    absolute: {
                        currency: checked
                            ? (lossConfig?.absolute?.currency ?? baseCurrenciesOptions?.[0]?.value)
                            : undefined,
                        amount: checked ? lossConfig?.absolute?.amount : undefined,
                    },
                },
                errors: [],
            },
            {
                name: [newOrderFormKeys.lossConfig, 'absolute', 'amount'],
                errors: [],
            },
        ])
    }

    const handleUnderpaymentSelectChange: SelectProps['onChange'] = value => {
        const isCustom = value === CUSTOM_OPTION_VALUE
        setIsCustomSelected(isCustom)
        if (!isCustom) {
            form.setFieldValue(newOrderFormKeys.lossConfig, undefined)
        }
    }

    useEffect(() => {
        if (isProjectLoading || !isCustomSelected) {
            return
        }

        form.setFieldValue(newOrderFormKeys.lossConfig, lossConfig)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, isCustomSelected, isProjectLoading])

    return {
        isProjectLoading,
        isAbsoluteCheckboxEnabled,
        isCustomSelected,
        baseCurrenciesOptions,
        handleCheckboxChange,
        handleUnderpaymentSelectChange,
        lossConfig,
    }
}
