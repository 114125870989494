import { Button, Flex } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { themesMap } from '../const'
import styles from './style.module.css'
import type { FlexProps, ModalProps } from '@merchant/ui-kit/ant-design'
import { ModalResponsive } from '~components/ModalResponsive'
import { globalLang } from '~globalLang'
import { useColorMode, useBreakpoint, type ColorMode } from '~hooks'
import { isKeyOfObject } from '~utils'

interface Props extends Omit<ModalProps, 'data-merchant'> {
    onCancel?: () => void
}

export function ColorModeModal({ onCancel, ...props }: Props) {
    const { colorMode, setColorMode } = useColorMode()
    const breakpoints = useBreakpoint()

    const onSelect = (key: ColorMode) => {
        if (isKeyOfObject(key, themesMap)) {
            setColorMode(key)
            onCancel?.()
        }
    }

    return (
        <ModalResponsive
            title={<FormattedMessage {...globalLang.colorMode} />}
            footer={null}
            styles={{ content: breakpoints.md ? undefined : { padding: '16px 12px 24px' } }}
            onCancel={onCancel}
            data-merchant="color-mode-modal"
            {...props}
        >
            {Object.values(themesMap).map(t => (
                <Button
                    data-merchant={`color-mode-modal-${t.key}-button`}
                    key={t.key}
                    onClick={() => onSelect(t.key)}
                    block
                    type="text"
                    size="large"
                    style={{ padding: 8, justifyContent: 'flex-start' }}
                    className={t.key === colorMode ? styles.themeButton : undefined}
                >
                    <Flex align="center" gap={8}>
                        {t.icon}
                        {t.label}
                    </Flex>
                </Button>
            ))}
        </ModalResponsive>
    )
}

export function ColorModeIcon(props: Omit<FlexProps, 'children'>) {
    const { colorMode } = useColorMode()

    return <Flex {...props}>{themesMap[colorMode].icon}</Flex>
}
