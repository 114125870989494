import { HttpError } from '@merchant/shared/api'
import { errorCodeMessageMap } from './const'
import type { IntlShape } from 'react-intl'
import type { APIErrorResponseErrorsInner } from '~api/instances/cabinet-api'
import { instanceOfAPIErrorResponseErrorsInner } from '~api/instances/cabinet-api'

const isErrorInstanceOfAPIErrorResponseErrorsInner = (error: object): error is APIErrorResponseErrorsInner => {
    return instanceOfAPIErrorResponseErrorsInner(error)
}

export function getErrorMessage(intl: IntlShape, error: unknown): string[] {
    if (!HttpError.isInstance(error) || !error.responseData?.errors || !Array.isArray(error.responseData.errors)) {
        return []
    }

    return error.responseData.errors.map((error: object) =>
        isErrorInstanceOfAPIErrorResponseErrorsInner(error) && error.code
            ? intl.formatMessage(errorCodeMessageMap[error.code] || {})
            : ''
    )
}
