import { useBreakpoint } from '@merchant/shared/hooks'
import { Spin, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import { Transactions } from './views'
import { useProject } from '~api'
import { PageContentContainer } from '~components'
import { PlaceholderView } from '~features'

const { Title } = Typography

export function TransactionsPage() {
    const breakpoints = useBreakpoint()
    const { data: project, isLoading } = useProject()
    const commonContainerStyles = {
        body: { padding: !breakpoints.md ? 12 : undefined },
    }

    let content
    if (isLoading) {
        content = (
            <PageContentContainer style={{ height: 300, display: 'grid', placeItems: 'center' }}>
                <Spin size="large" data-merchant="transactions-loading-spin" />
            </PageContentContainer>
        )
    } else if (!project) {
        content = (
            <PageContentContainer styles={commonContainerStyles}>
                <PlaceholderView buttonDataMerchant="transaction-page-no-merchant" />
            </PageContentContainer>
        )
    } else {
        content = (
            <PageContentContainer styles={commonContainerStyles}>
                <Transactions />
            </PageContentContainer>
        )
    }

    return (
        <>
            <Title level={4} data-merchant="transactions-page-title">
                <FormattedMessage {...lang.pageTitle} />
            </Title>
            {content}
        </>
    )
}
