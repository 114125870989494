import { FormattedMessage } from 'react-intl'
import type { MessageDescriptor } from 'react-intl'

interface Props {
    message: MessageDescriptor
}

export function FormattedMessageWithBoldText({ message }: Props) {
    return (
        <FormattedMessage
            {...message}
            values={{
                boldText: chunks => <span style={{ fontWeight: 700 }}>{chunks}</span>,
            }}
        />
    )
}
