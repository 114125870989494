import { assets } from '@merchant/shared/assets/nameMap'
import { EllipsisMiddle, InlineButton, AppSvg, CopyButton, StatisticFormattedNumber } from '@merchant/shared/components'
import { parseDateAndTime, getExplorerLink } from '@merchant/shared/utils'
import { Col, Row, Space, Typography } from '@merchant/ui-kit/ant-design'
import Big from 'big.js'
import { FormattedMessage } from 'react-intl'
import styles from '../../../style.module.css'
import { lang } from '../lang'
import type { Payin, Payment } from '~api/instances/cabinet-api'
import { useCurrencies } from '~api'
import { FeesPayer } from '~api/instances/cabinet-api'
import { globalLang } from '~globalLang'

const { Text } = Typography

interface Props {
    data: Payin
    isOnlyPayin: boolean
    payinOrdinalNumber: number
    feesPayer: Payment['feesPayer']
}

// eslint-disable-next-line max-lines-per-function
export function Payin({ data, isOnlyPayin, payinOrdinalNumber, feesPayer }: Props) {
    const { data: currencies } = useCurrencies()
    const payinDate = parseDateAndTime(data.declaredAt)
    const subtotalReceiveAmount = new Big(data.amount || 0).sub(data.fee || 0)

    const explorerLink = getExplorerLink({
        currencies,
        currencyCode: data.currency,
        hash: data.txnHash,
        networkCode: data.network,
    })

    return (
        <Space direction="vertical" size={16} style={{ width: '100%' }}>
            <Text data-merchant={null}>
                <FormattedMessage {...lang.fundsFromClient} />{' '}
                {!isOnlyPayin && (
                    <FormattedMessage {...lang.transactionNumber} values={{ number: payinOrdinalNumber }} />
                )}
            </Text>
            <Row justify="space-between">
                <Col>
                    <Text type="secondary" data-merchant={null}>
                        <FormattedMessage {...lang.date} />
                    </Text>
                </Col>
                <Col className={styles.middleCol} />
                <Col>
                    <Text data-merchant="order-timeline-payin-date">{payinDate?.date}</Text>{' '}
                    <Text type="secondary" data-merchant="order-timeline-payin-time">
                        {payinDate?.time}
                    </Text>
                </Col>
            </Row>
            <Row justify="space-between">
                <Col>
                    <Text type="secondary" data-merchant={null}>
                        <FormattedMessage {...lang.amountFromClient} />
                    </Text>
                </Col>
                <Col className={styles.middleCol} />
                <Col>
                    <StatisticFormattedNumber value={data.amount} suffix={data.currency} />
                </Col>
            </Row>
            <Row justify="space-between">
                <Col>
                    <Text type="secondary" data-merchant={null}>
                        <FormattedMessage
                            {...lang.depositFee}
                            values={{
                                who: (
                                    <FormattedMessage
                                        {...(feesPayer === FeesPayer.Customer || payinOrdinalNumber > 1
                                            ? lang.client
                                            : lang.merchant)}
                                    />
                                ),
                            }}
                        />
                    </Text>
                </Col>
                <Col className={styles.middleCol} />
                <Col>
                    <StatisticFormattedNumber prefix="-" value={data.fee} suffix={data.currency} />
                </Col>
            </Row>
            <Row justify="space-between">
                <Col>
                    <Text type="secondary" data-merchant={null}>
                        <FormattedMessage {...lang.subtotalAmount} />
                    </Text>
                </Col>
                <Col className={styles.middleCol} />
                <Col>
                    <Text
                        type={subtotalReceiveAmount.cmp(0) === 1 ? undefined : 'secondary'}
                        data-merchant="order-timeline-payin-currency"
                    >
                        <StatisticFormattedNumber
                            value={subtotalReceiveAmount.cmp(0) === 1 ? subtotalReceiveAmount.toString() : 0}
                            suffix={data.currency}
                        />
                    </Text>
                </Col>
            </Row>
            <Row justify="space-between">
                <Col>
                    <Text
                        type="secondary"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 6,
                        }}
                        data-merchant={null}
                    >
                        <FormattedMessage {...globalLang.hash} />
                        {explorerLink && (
                            <InlineButton
                                type="text"
                                href={explorerLink}
                                target="_blank"
                                data-merchant="order-details-open-payin-button"
                                icon={<AppSvg size={16} name={assets.share} />}
                            />
                        )}
                    </Text>
                </Col>
                <Col className={styles.middleCol} />
                <Col>
                    <Text style={{ display: 'flex', gap: 4 }} data-merchant={null}>
                        <EllipsisMiddle divider="." data-merchant={`order-timeline-payin-txnHash-${data.txnHash}`}>
                            {data.txnHash}
                        </EllipsisMiddle>
                        <CopyButton
                            notificationContentSubject={globalLang.hash}
                            inputValue={data.txnHash}
                            type="text"
                            data-merchant="order-details-payin-hash-copy-button"
                        />
                    </Text>
                </Col>
            </Row>
        </Space>
    )
}
