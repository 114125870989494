import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    all: {
        id: 'integrations.table.permissions-all',
        defaultMessage: 'All',
    },
    allReadOnly: {
        id: 'integrations.table.permissions-all-read-only',
        defaultMessage: 'Read only',
    },
    customPermissions: {
        id: 'integrations.table.permissions-custom',
        defaultMessage: `Read & Write: <strong>{writeCount}</strong>
            <break></break>
            Read only: <strong>{readCount}</strong>
        `,
    },
    writePermissionsTooltip: {
        id: 'integrations.table.write-permissions-tooltip',
        defaultMessage: `<label>READ & WRITE</label>
            {content}
        `,
    },
    readPermissionsTooltip: {
        id: 'integrations.table.read-permissions-tooltip',
        defaultMessage: `<label>READ ONLY</label>
            {content}
        `,
    },
    none: {
        id: 'integrations.table.none-permissions',
        defaultMessage: 'None',
    },
})
