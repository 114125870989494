import { StatisticFormattedNumber } from '@merchant/shared/components'
import { useBreakpoint } from '@merchant/shared/hooks'
import { Skeleton, Typography, type GetProp } from '@merchant/ui-kit/ant-design'

const { Text } = Typography

interface Props {
    value?: string
    postfix?: string
    isLoading: boolean
    isError?: boolean | (() => boolean)
    dataMerchant: string
}

export function ReadonlyFormElement({ value, postfix, isLoading, isError, dataMerchant }: Props) {
    const parsedValue = Number(value)
    const isValid = Boolean(parsedValue && postfix)
    const { xs } = useBreakpoint()
    const getValueTypeProp = (): GetProp<typeof Text, 'type'> | undefined => {
        const hasError = typeof isError === 'function' ? isError() : isError
        if (hasError) {
            return 'danger'
        }

        return !isValid ? 'secondary' : undefined
    }

    return (
        <Skeleton
            active
            loading={isLoading}
            paragraph={false}
            style={{ width: xs ? 100 : 150, marginBottom: 4, marginLeft: 'auto' }}
        >
            <StatisticFormattedNumber
                data-merchant={dataMerchant}
                value={parsedValue}
                valueRender={value => (
                    <Text data-merchant={null} type={getValueTypeProp()}>
                        {isValid ? value : '0.00'} {postfix}
                    </Text>
                )}
            />
        </Skeleton>
    )
}
