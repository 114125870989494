import { assets } from '@merchant/shared/assets/nameMap.ts'
import { AppSvg } from '@merchant/shared/components'
import { useBreakpoint } from '@merchant/shared/hooks'
import { Button, Col, ConfigProvider, Flex, Row, Skeleton } from '@merchant/ui-kit/ant-design'
import { useBoolean } from 'usehooks-ts'
import { InfoBlock, InfoBlockModal, PageTitle } from './components'
import { configProviderTheme } from './const.tsx'
import { useOrderType } from './hooks.ts'
import { constructorTypes } from './types.ts'
import { useData } from './useData.ts'
import { OrderConstructor } from './view/OrderConstructor'
import { StaticAddressConstructor } from './view/StaticAddressConstructor'
import { PageContentContainer } from '~components'
import { PlaceholderView } from '~features/PlaceholderView'
import { PlaceholderName } from '~features/PlaceholderView/types.ts'
import { SetupTwoFa } from '~features/SetupTwoFa/index.tsx'

// eslint-disable-next-line complexity
export function PaymentConstructor() {
    const { isProjectLoading, project, hasActiveTotp, isTotpsLoading, mutateTotps } = useData()

    const { value: isInfoModalOpen, setFalse: closeInfoModal, setTrue: openInfoModal } = useBoolean(false)
    const breakpoints = useBreakpoint()
    const { orderType } = useOrderType()

    const isDataLoading = isProjectLoading || isTotpsLoading
    let placeholderView = null

    if (!isDataLoading) {
        if (!project) {
            placeholderView = <PlaceholderView name={PlaceholderName.noMerchant} />
        } else if (!hasActiveTotp) {
            placeholderView = (
                <SetupTwoFa
                    buttonDataMerchant="constructor-no-totp"
                    onSuccess={newTotp =>
                        mutateTotps((prev = []) => [...prev, newTotp], {
                            revalidate: false,
                        })
                    }
                />
            )
        }
    }

    if (placeholderView) {
        return (
            <>
                <PageTitle />
                <PageContentContainer>{placeholderView}</PageContentContainer>
            </>
        )
    }

    const component =
        orderType !== constructorTypes.StaticAddress ? (
            <OrderConstructor type={orderType} />
        ) : (
            <StaticAddressConstructor />
        )

    return (
        <>
            {!breakpoints.lg && (
                <InfoBlockModal open={isInfoModalOpen} isLoading={isProjectLoading} onCancel={closeInfoModal} />
            )}
            <Flex justify="space-between" align="center">
                <Flex vertical>
                    <PageTitle />
                </Flex>
                {!breakpoints.lg && (
                    <Button shape="circle" onClick={openInfoModal} data-merchant="constructor-open-info-button">
                        <AppSvg size={20} name={assets.alertCircle} />
                    </Button>
                )}
            </Flex>
            <Row gutter={24} wrap={false}>
                <Col flex={1}>
                    <PageContentContainer
                        styles={{
                            body: {
                                padding: breakpoints.md ? undefined : '24px 12px',
                            },
                        }}
                    >
                        <Skeleton loading={isDataLoading} active paragraph={{ rows: 8 }}>
                            <ConfigProvider theme={configProviderTheme}>{component}</ConfigProvider>
                        </Skeleton>
                    </PageContentContainer>
                </Col>
                {breakpoints.lg && (
                    <Col>
                        <InfoBlock isLoading={isDataLoading} />
                    </Col>
                )}
            </Row>
        </>
    )
}
