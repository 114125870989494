interface DownloadMediaArgs {
    data: string | Blob
    type: string
    filename: string
}

export const downloadMedia = ({ data, type, filename }: DownloadMediaArgs) => {
    try {
        const blob = new Blob([data], { type })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = filename
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
    } catch (error) {
        console.error(error)
    }
}
