import { FormattedMessage } from 'react-intl'
import { lang } from '../lang'
import type { SelectProps } from '@merchant/ui-kit/ant-design'
import type { ReactNode } from 'react'
import type { WhitelistWithKey } from '~api/swr/rest/types'
import { globalLang } from '~globalLang'

export const sortByOptions: SelectProps<WhitelistWithKey>['options'] = [
    { label: <FormattedMessage {...lang.name} />, value: 'name' },
    { label: <FormattedMessage {...lang.currency} />, value: 'currency' },
    { label: <FormattedMessage {...globalLang.network} />, value: 'network' },
    { label: <FormattedMessage {...lang.address} />, value: 'address' },
    { label: <FormattedMessage {...lang.addressTag} />, value: 'addressTag' },
] as const satisfies {
    label: ReactNode
    value: keyof WhitelistWithKey
}[]
