import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    pageTitle: {
        id: 'balances-page.typography.title',
        defaultMessage: 'Balances',
    },
    snapshotsButton: {
        id: 'balances-page.snapshots_button',
        defaultMessage: 'Balance snapshots',
    },
    currenciesSettingsButton: {
        id: 'balances-page.button.currencies_settings_button',
        defaultMessage: 'Currencies settings',
    },
    defaultCurrencyTooltip: {
        id: 'balances-page.tooltip.default_currency_text',
        defaultMessage: 'You can change default conversion currency in {settingsLinkText}',
    },
    settingsLinkText: {
        id: 'balances-page.link.settings',
        defaultMessage: 'settings',
    },
    defaultConversionCurrency: {
        id: 'balances-page.typography.default_conversion_currency',
        defaultMessage: 'Default conversion currency',
    },
    showMore: {
        id: 'balances-page.button.show_more',
        defaultMessage: 'Show more',
    },
    searchCurrencyPlaceholder: {
        id: 'balances-page.input.search_currency_placeholder',
        defaultMessage: 'Currency',
    },
    recentTransactions: {
        id: 'balances-page.typography.recent_transactions',
        defaultMessage: 'Recent transactions',
    },
    viewAll: {
        id: 'balances-page.button.view_all',
        defaultMessage: 'View all',
    },
    disabledWithdrawal: {
        id: 'balances-page.tooltip.disabled_withdrawal',
        defaultMessage: 'Withdrawal not available for this currency. To withdraw your funds, please convert them.',
    },
    noBalancesSubtitle: {
        id: 'balances-page.empty_table.no_balances_subtitle',
        defaultMessage: 'No balances yet',
    },
    loadingBalancesFailed: {
        id: 'balances-page.empty_table.loading_failed_title',
        defaultMessage: 'Balances failed to load',
    },
    showZeroBalances: {
        id: 'balances-page.checkbox.show_zero_balances',
        defaultMessage: 'Show zero balances',
    },
    temporarilyUnavailableConvert: {
        id: 'balances-page.tooltip.temporarily_unavailable_title.',
        defaultMessage: 'Convert is temporarily unavailable for this currency',
    },
    unavailableFiatDeposit: {
        id: 'balances-page.tooltip.unavailable_fiat_deposit',
        defaultMessage:
            'Direct deposits of fiat currencies are not available from the dashboard. To top up your {currency} balance, please contact your account manager or <supportLink>support</supportLink>. Also, you can <convertLink>exchange</convertLink> available cryptocurrency to {currency} to increase this balance.',
    },
    unavailableCryptoDeposit: {
        id: 'balances-page.tooltip.unavailable_crypto_deposit',
        defaultMessage: 'Deposits are temporarily unavailable for {currency} currency',
    },
})
