import { Card, theme, Timeline } from '@merchant/ui-kit/ant-design'
import { CompletedTimeline, ConvertTimeline, CreatedTimeline, FailedTimeline, InProgressTimeline } from './components'
import { CanceledTimeline } from './components/CanceledTimeline'
import { isCancelled, isCompleted, isConverted, isFailed } from './utils'
import type { GlobalToken, TimelineItemProps } from '@merchant/ui-kit/ant-design'
import { WithdrawalState, type Withdrawal } from '~api'

const { useToken } = theme

interface Props {
    data: Withdrawal | undefined
}

function getInitialTimelineItem(data: Withdrawal, token: GlobalToken): TimelineItemProps {
    return {
        color: token.colorSuccess,
        children: <CreatedTimeline data={data} />,
        'data-merchant': 'withdrawal-timeline-created',
    }
}

function getConvertedTimelineItem(data: Withdrawal, token: GlobalToken): TimelineItemProps | null {
    if (isConverted(data)) {
        return {
            color: token.colorSuccess,
            children: <ConvertTimeline data={data} />,
            'data-merchant': 'withdrawal-timeline-converted',
        }
    }

    return null
}

function getInProgressTimelineItem(
    data: Withdrawal,
    token: GlobalToken,
    isFailedWithoutHash: boolean
): TimelineItemProps | null {
    if (!isFailedWithoutHash) {
        return {
            color: isCompleted(data) || isFailed(data) ? token.colorSuccess : token.colorTextPlaceholder,
            children: <InProgressTimeline data={data} />,
            'data-merchant': 'withdrawal-timeline-in-progress',
        }
    }

    return null
}

function getFinalTimelineItem(data: Withdrawal, token: GlobalToken): TimelineItemProps | null {
    if (isCompleted(data)) {
        return {
            color: token.colorSuccess,
            children: <CompletedTimeline data={data} />,
            'data-merchant': 'withdrawal-timeline-completed',
        }
    } else if (isFailed(data)) {
        return {
            color: token.colorError,
            children: <FailedTimeline data={data} />,
            'data-merchant': 'withdrawal-timeline-failed',
        }
    } else if (isCancelled(data)) {
        return {
            color: token.colorTextPlaceholder,
            children: <CanceledTimeline data={data} />,
            'data-merchant': 'withdrawal-timeline-cancelled',
        }
    }

    return null
}

export function WithdrawalTimeline({ data }: Props) {
    const { token } = useToken()
    if (!data) {
        return null
    }

    const isFailedWithoutHash = !data.hash && data.state === WithdrawalState.Failed

    const timelineItems: TimelineItemProps[] = [
        getInitialTimelineItem(data, token),
        getConvertedTimelineItem(data, token),
        getInProgressTimelineItem(data, token, isFailedWithoutHash),
        getFinalTimelineItem(data, token),
    ].filter((item): item is TimelineItemProps => !!item)

    return (
        <Card data-merchant="withdrawal-timeline-card" style={{ width: '100%' }}>
            <Timeline data-merchant="withdrawal-timeline-card-timeline" items={timelineItems} />
        </Card>
    )
}
