import { isLast } from '@merchant/shared/utils'
import { Flex, Tooltip, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { getPermissionTitle } from '../../utils'
import { lang } from './lang'
import { type APIKey, ProjectAPIKeyPermissionID, type ProjectAPIKeyPermission } from '~api'

const { Text, Paragraph } = Typography

type TooltipPermissionsListProps = {
    dataMerchant: string
    permissions: APIKey['permissions']
}

function TooltipPermissionsList({ dataMerchant, permissions }: TooltipPermissionsListProps) {
    return (
        <div data-merchant={dataMerchant}>
            {permissions.length ? (
                <Text data-merchant={null}>
                    {permissions.map(({ id }, index) => (
                        <span key={id}>
                            <span data-merchant={`${dataMerchant}-${id}`}>{getPermissionTitle(id)}</span>

                            {!isLast(permissions, index) && ', '}
                        </span>
                    ))}
                </Text>
            ) : (
                <Text data-merchant={`${dataMerchant}-none`} type="secondary" style={{ opacity: 0.5 }}>
                    <FormattedMessage {...lang.none} />
                </Text>
            )}
        </div>
    )
}

interface Props {
    apiKey: APIKey
    compact?: boolean
}

export function PermissionsColumn({ apiKey, compact: isCompact = false }: Props) {
    const { permissions, active, id } = apiKey

    const fullPermissions = permissions.find(permission => permission.id === ProjectAPIKeyPermissionID.Full)
    if (fullPermissions) {
        const description = fullPermissions.write ? lang.all : lang.allReadOnly

        return (
            <Text
                type={active ? undefined : 'secondary'}
                style={{ fontSize: 'inherit', fontWeight: isCompact ? 700 : undefined }}
                data-merchant={`${id}-api-key-permissions-all`}
            >
                <FormattedMessage {...description} />
            </Text>
        )
    }

    const { writePermissions, readPermissions } = permissions.reduce<{
        writePermissions: ProjectAPIKeyPermission[]
        readPermissions: ProjectAPIKeyPermission[]
    }>(
        (acc, permission) => {
            if (permission.write) {
                acc.writePermissions.push(permission)
            } else {
                acc.readPermissions.push(permission)
            }

            return acc
        },
        { writePermissions: [], readPermissions: [] }
    )

    return (
        <Tooltip
            data-merchant={null}
            placement="bottom"
            title={
                <Flex vertical gap={8} data-merchant={`${id}-api-key-permissions-tooltip`}>
                    <div>
                        <FormattedMessage
                            {...lang.writePermissionsTooltip}
                            values={{
                                label: chunks => (
                                    <Paragraph
                                        data-merchant={`${apiKey.id}-api-key-write-permissions-label`}
                                        style={{ marginBottom: 0 }}
                                        type="secondary"
                                    >
                                        {chunks}
                                    </Paragraph>
                                ),
                                content: (
                                    <TooltipPermissionsList
                                        dataMerchant={`${apiKey.id}-api-key-write-permissions-list`}
                                        permissions={writePermissions}
                                    />
                                ),
                            }}
                        />
                    </div>
                    <div>
                        <FormattedMessage
                            {...lang.readPermissionsTooltip}
                            values={{
                                label: chunks => (
                                    <Paragraph
                                        data-merchant={`${apiKey.id}-api-key-read-permissions-label`}
                                        style={{ marginBottom: 0 }}
                                        type="secondary"
                                    >
                                        {chunks}
                                    </Paragraph>
                                ),
                                content: (
                                    <TooltipPermissionsList
                                        dataMerchant={`${apiKey.id}-api-key-read-permissions-list`}
                                        permissions={readPermissions}
                                    />
                                ),
                            }}
                        />
                    </div>
                </Flex>
            }
        >
            <Text
                type={active ? undefined : 'secondary'}
                style={{ fontSize: 'inherit', textWrap: 'nowrap' }}
                data-merchant={`${id}-api-key-permissions-hoverbox`}
            >
                <FormattedMessage
                    {...lang.customPermissions}
                    values={{
                        break: () => <br />,
                        strong: chunks => <span style={{ fontWeight: 700 }}>{chunks}</span>,
                        writeCount: writePermissions.length,
                        readCount: readPermissions.length,
                    }}
                />
            </Text>
        </Tooltip>
    )
}
