import { Typography } from '@merchant/ui-kit/ant-design'
import { isEmpty } from 'lodash-es'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import type { CurrencyExtended } from '../types'
import type { MessageDescriptor } from 'react-intl'
import { NoValueColumnContent } from '~components'

const { Text } = Typography

interface Props {
    items: CurrencyExtended[]
    allItemsCount: number
    type: 'activated' | 'deactivated'
}

export function ItemsCount({ items, allItemsCount, type }: Props) {
    if (isEmpty(items)) {
        return NoValueColumnContent
    }

    let descriptor: MessageDescriptor = lang.currenciesCountSingular

    if (allItemsCount === items.length) {
        descriptor = lang.currenciesCountAll
    } else if (items.length >= 2) {
        descriptor = lang.currenciesCountPlural
    }

    return (
        <Text type={type === 'activated' ? 'success' : 'danger'} data-merchant={`currencies-${type}-count`}>
            <FormattedMessage {...descriptor} values={{ count: items.length }} />
        </Text>
    )
}
