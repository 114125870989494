import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { Alert, type AlertProps } from '@merchant/ui-kit/ant-design'
import { isArray, some } from 'lodash-es'
import type { Currencies } from '~api/types'
import { isFakeFiat } from '~utils'

const commonProps: Omit<AlertProps, 'data-merchant'> = {
    type: 'warning',
    showIcon: true,
    icon: (
        <div style={{ alignSelf: 'center' }}>
            <AppSvg size={24} name={assets.alertCircle} />
        </div>
    ),
}

const checkForAndGetEnabledCurrencies = (currencies: Currencies, selectedCurrencies: string[]) => {
    const hasNonWithdrawableCurrency = some(
        selectedCurrencies,
        currency => currencies[currency]?.payoutEnabled === false
    )

    const getWithdrawableCurrencies = () =>
        Object.values(currencies).reduce<string[]>((acc, c) => {
            if (c.payoutEnabled && !isFakeFiat(c)) {
                acc.push(c.code)
            }

            return acc
        }, [])

    return {
        hasNonWithdrawableCurrency,
        getWithdrawableCurrencies,
    }
}

interface Props extends AlertProps {
    currencies: Currencies
    selectedCurrencies: string | string[]
    renderMessage: (data: { getEnabledCurrenciesCode: () => string[] }) => React.ReactNode
}

export function WithdrawalNotPermittedAlert({ renderMessage, selectedCurrencies, currencies, ...rest }: Props) {
    const { hasNonWithdrawableCurrency, getWithdrawableCurrencies } = checkForAndGetEnabledCurrencies(
        currencies,
        isArray(selectedCurrencies) ? selectedCurrencies : [selectedCurrencies]
    )

    if (hasNonWithdrawableCurrency) {
        return (
            <Alert
                {...commonProps}
                description={renderMessage({
                    getEnabledCurrenciesCode: getWithdrawableCurrencies,
                })}
                {...rest}
            />
        )
    }

    return null
}
