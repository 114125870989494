import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    modalTitle: {
        id: 'whitelist-page.modal.add_whitelist_modal_title',
        defaultMessage: 'Add new whitelisted address',
    },
    successNotificationTitle: {
        id: 'whitelist-page.notification.add_whitelist_modal_success_title',
        defaultMessage: 'Your address has been added',
    },
    successNotificationDescription: {
        id: 'whitelist-page.notification.add_whitelist_modal_success_description',
        defaultMessage: 'You have successfully added your address to the whitelist.',
    },
})
