import { ConfigProvider } from '@merchant/ui-kit/ant-design'
import { DisableWhitelistModal, EnableWhitelistModal } from './components'
import { WhitelistContentKeys } from './types'
import { useData } from './useData'
import { Welcome, WhitelistEnabled } from './views'
import { PageContentContainer } from '~components'
import { PlaceholderViewWrapper } from '~features/PlaceholderViewWrapper'

export function WhitelistPage() {
    const {
        contentKey,
        isDisableModalOpen,
        closeDisableModal,
        openDisableModal,
        isEnableModalOpen,
        openEnableModal,
        closeEnableModal,
        onWhitelistToggleSuccess,
        blocklistData,
        isWhitelistLoading,
        whitelistError,
    } = useData()

    const contents: Record<WhitelistContentKeys, () => JSX.Element> = {
        [WhitelistContentKeys.DisabledWhitelist]: () => (
            <PageContentContainer>
                <Welcome onButtonClick={openEnableModal} />
            </PageContentContainer>
        ),
        [WhitelistContentKeys.EnabledWhitelist]: () => (
            <WhitelistEnabled blocklistData={blocklistData} openDisableModal={openDisableModal} />
        ),
    }

    const content = contents[contentKey]()

    return (
        <ConfigProvider
            theme={{
                components: {
                    Tooltip: { paddingXS: 12, paddingSM: 16, fontSize: 12 },
                },
            }}
        >
            <EnableWhitelistModal
                onModalSuccess={() => onWhitelistToggleSuccess(closeEnableModal)}
                open={isEnableModalOpen}
                closeModal={closeEnableModal}
            />
            <DisableWhitelistModal
                open={isDisableModalOpen}
                onCancel={closeDisableModal}
                onModalSuccess={() => onWhitelistToggleSuccess(closeDisableModal)}
                data-merchant="disable-whitelist-modal"
            />
            <PlaceholderViewWrapper
                dataMerchantPrefix="whitelist"
                isError={!!whitelistError}
                isLoading={isWhitelistLoading}
            >
                {content}
            </PlaceholderViewWrapper>
        </ConfigProvider>
    )
}
