import { Modal } from '@merchant/ui-kit/ant-design'
import { useEffect } from 'react'
import { useBoolean } from 'usehooks-ts'
import classNames from './style.module.css'
import { getModalInnerStyles, getModalStyle, getWrapperProps } from './utils'
import type { ModalProps } from '@merchant/ui-kit/ant-design'
import { assets } from '~assets/nameMap'
import { AppSvg } from '~components'
import { useBreakpoint } from '~hooks'
import { bodyScrollLock } from '~utils'

export function ModalResponsive({
    styles,
    style,
    open = false,
    wrapProps,
    afterOpenChange,
    'data-merchant': dataMerchant,
    ...props
}: ModalProps) {
    const breakpoints = useBreakpoint()
    const { value: isWrapperDisplayed, setValue: setIsWrapperDisplayed } = useBoolean(false)

    const handleAfterOpenChange = (isOpen: boolean) => {
        if (isOpen) {
            bodyScrollLock.enable()
        } else {
            setIsWrapperDisplayed(false)
            bodyScrollLock.disable()
        }

        afterOpenChange?.(isOpen)
    }

    useEffect(() => {
        if (open) {
            setIsWrapperDisplayed(true)
        }

        return () => {
            bodyScrollLock.disable()
        }
    }, [open, setIsWrapperDisplayed])

    return (
        <Modal
            data-merchant={dataMerchant}
            closeIcon={
                <AppSvg
                    size={16}
                    className={classNames.modalCloseIcon}
                    data-merchant={`${dataMerchant}-quit-button`}
                    name={assets.close}
                />
            }
            wrapProps={getWrapperProps({ wrapProps, breakpoints, open, isWrapperDisplayed })}
            afterOpenChange={handleAfterOpenChange}
            style={getModalStyle(style, breakpoints)}
            styles={getModalInnerStyles(styles, breakpoints)}
            open={open}
            {...props}
        />
    )
}
