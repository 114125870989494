import { Button, Flex, Typography, theme } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import type { TOTP } from '~api/instances/cabinet-api'

const { Text } = Typography
const { useToken } = theme

interface Props {
    onSuccess: () => void
    totpName?: TOTP['name']
}

export function UnconfirmedTotpInfo({ onSuccess, totpName }: Props) {
    const { token } = useToken()

    return (
        <Flex vertical gap={24}>
            <Text data-merchant={`confirmation-required-totp-name-${totpName}`}>
                <FormattedMessage
                    {...lang.confirmationRequiredFor}
                    values={{
                        totpName: <span style={{ color: token.colorLink }}>{totpName}</span>,
                    }}
                />
            </Text>
            <Button
                block
                type="primary"
                onClick={onSuccess}
                size="large"
                data-merchant="unconfirmed-2fa-modal-confirm-button"
            >
                <FormattedMessage {...lang.buttonTitle} />
            </Button>
        </Flex>
    )
}
