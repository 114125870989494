import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { useBreakpoint, useFormValidation, usePromise } from '@merchant/shared/hooks'
import { Alert, Button, Divider, Form, Input, Tooltip, Typography } from '@merchant/ui-kit/ant-design'
import { usePostHog } from 'posthog-js/react'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { lang } from '../../lang'
import styles from '../../style.module.css'
import { resetPasswordFormDataKeys } from './const'
import type { PasswordRecoveryFormData } from './types'
import { authApi } from '~api'
import { authCaptureEventParams, PosthogAuthEvents } from '~features/AuthorizationPage/posthog'
import { getFormStyles } from '~features/AuthorizationPage/style'
import { EmailConfirmationItem, PasswordWithTooltip } from '~features/FormItems'
import { useCodeConfirmationErrorHandling } from '~features/FormItems/useCodeErrorHandling'
import { globalLang } from '~globalLang'

const { Paragraph, Title } = Typography

const newPasswordStyles: React.CSSProperties = { marginBottom: 12 }
const alertStyles: React.CSSProperties = { alignItems: 'center' }

interface Props {
    onSuccess: () => void
    email: string
}

// eslint-disable-next-line max-lines-per-function
export function PasswordRecoverySetNew({ onSuccess, email }: Props) {
    const posthog = usePostHog()
    const [form] = Form.useForm()
    const breakpoints = useBreakpoint()
    const { onBlur, onFinishFailed, validationMode } = useFormValidation(Object.values(resetPasswordFormDataKeys))
    const { handleError } = useCodeConfirmationErrorHandling({
        confirmationStrategy: { email: 'code', resetPassword: 'code' },
    })
    const confirmNewPasswordError = form.getFieldError(resetPasswordFormDataKeys.newPasswordConfirm)
    const intl = useIntl()

    const { isLoading: isConfirmingNewPassword, send: confirmNewPassword } = usePromise(
        async ({ newPassword, verificationCode }: Omit<PasswordRecoveryFormData, 'newPasswordConfirm'>) => {
            await authApi.confirmResetPassword({
                confirmPasswordResetRequest: {
                    email,
                    newPassword,
                    verificationCode,
                },
            })
            posthog.capture(
                ...authCaptureEventParams[PosthogAuthEvents.resetPasswordRequest]({
                    code: verificationCode,
                    success: true,
                })
            )
        },
        {
            showErrorNotification: false,
            onSuccess,
            onError: (error, { verificationCode }) => {
                posthog.capture(
                    ...authCaptureEventParams[PosthogAuthEvents.resetPasswordRequest]({
                        code: verificationCode,
                        success: false,
                    })
                )
                handleError({ error, form })
            },
        }
    )

    const { send: requestCode } = usePromise(() => authApi.resetPassword({ passwordResetRequest: { email } }))

    return (
        <>
            <Title level={3} data-merchant="password-recovery-title">
                <FormattedMessage {...lang.newPassword} />
            </Title>
            <Form
                style={getFormStyles(breakpoints)}
                form={form}
                size="large"
                layout="vertical"
                onFinish={confirmNewPassword}
                onFinishFailed={onFinishFailed}
                requiredMark={false}
            >
                <EmailConfirmationItem
                    dataMerchant="password-recovery-verification-code-input"
                    onBlur={onBlur(resetPasswordFormDataKeys.verificationCode)}
                    name={resetPasswordFormDataKeys.verificationCode}
                    validateTrigger={validationMode.verificationCode}
                    email={email}
                    onResendClick={requestCode}
                    isInitiallySent
                    noExtra
                />
                <Divider type="horizontal" />
                <PasswordWithTooltip
                    label={
                        <Paragraph data-merchant="password-recovery-new-password-label">
                            <FormattedMessage {...lang.enterNewPasswordsLabel} />
                        </Paragraph>
                    }
                    form={form}
                    onBlur={onBlur(resetPasswordFormDataKeys.newPassword)}
                    validateTrigger={validationMode.newPassword}
                    style={newPasswordStyles}
                    name={resetPasswordFormDataKeys.newPassword}
                    inputPlaceholder={intl.formatMessage(lang.newPasswordPlaceholder)}
                    keepInvalidShown={!breakpoints.xs}
                    tooltipPlacement="bottom"
                    inputDataMerchant="password-recovery-password-input"
                />

                <Form.Item
                    validateTrigger={validationMode.newPasswordConfirm}
                    name={resetPasswordFormDataKeys.newPasswordConfirm}
                    dependencies={[resetPasswordFormDataKeys.newPassword]}
                    rules={[
                        {
                            required: true,
                            message: (
                                <span data-merchant="password-recovery-repeat-password-required-error-message">
                                    <FormattedMessage {...globalLang.requiredFieldMessage} />
                                </span>
                            ),
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue(resetPasswordFormDataKeys.newPassword) === value) {
                                    return Promise.resolve()
                                }

                                return Promise.reject(
                                    <span data-merchant="password-recovery-non-matching-passwords-error-message">
                                        {intl.formatMessage(lang.passwordsAreDifferent)}
                                    </span>
                                )
                            },
                        }),
                    ]}
                >
                    {/* TODO: Move to separate password component with custom icon color effect */}
                    <Input.Password
                        className={styles.input}
                        onBlur={onBlur(resetPasswordFormDataKeys.newPasswordConfirm)}
                        placeholder={intl.formatMessage(lang.repeatNewPasswordPlaceholder)}
                        data-merchant="password-recovery-password-confirm-input"
                        iconRender={visible => {
                            return visible ? (
                                /* TODO: Find out why class is not set without Tooltip wrapping */
                                <Tooltip open={false} data-merchant="password-recovery-visibility-tooltip">
                                    <EyeOutlined
                                        data-merchant="password-confirm-visibility-icon-hidden"
                                        className={
                                            confirmNewPasswordError.length ? styles.isInvalid : styles.visibleIcon
                                        }
                                    />
                                </Tooltip>
                            ) : (
                                <EyeInvisibleOutlined data-merchant="password-confirm-visibility-icon-visible" />
                            )
                        }}
                    />
                </Form.Item>
                <Form.Item>
                    <Alert
                        style={alertStyles}
                        type="warning"
                        icon={<AppSvg size={24} name={assets.alertCircle} />}
                        description={<FormattedMessage {...lang.afterResetDisableDisclaimer} />}
                        showIcon
                        data-merchant="after-reset-disclaimer-alert"
                    />
                </Form.Item>
                <Form.Item noStyle>
                    <Button
                        data-merchant="password-recovery-submit-button"
                        htmlType="submit"
                        loading={isConfirmingNewPassword}
                        type="primary"
                        block
                    >
                        <FormattedMessage {...globalLang.save} />
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}
