import { EmailSupportLink } from '@merchant/shared/components'
import { Divider, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'
import { getBaseCurrenciesOptions } from './utils'
import type { SelectProps } from '@merchant/ui-kit/ant-design'
import { useCurrencies } from '~api'
import { SearchableSelect } from '~components'

const { Paragraph } = Typography

export function BaseCurrencySelect(props: SelectProps) {
    const { data: currencies } = useCurrencies()
    const options = getBaseCurrenciesOptions(currencies)

    return (
        <SearchableSelect
            size="large"
            placement="bottomRight"
            defaultValue={options?.[0]}
            getPopupContainer={node => node}
            dropdownRender={menu => (
                <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <Paragraph
                        data-merchant={`${props['data-merchant']}-preferences-hint`}
                        style={{ margin: '8px 12px', maxWidth: 234 }}
                    >
                        <FormattedMessage
                            {...lang.currencyPreferencesHint}
                            values={{
                                supportLink: chunks => <EmailSupportLink>{chunks}</EmailSupportLink>,
                            }}
                        />
                    </Paragraph>
                </>
            )}
            options={options}
            filterOption={(input, option) => option?.key?.toLowerCase().includes(input.toLowerCase())}
            {...props}
        />
    )
}
