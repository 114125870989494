import type { Dayjs } from 'dayjs'
import type { Project } from '~api'
import { processProjectName as processProjectName } from '~utils'

export function getExportFilename(project: Project | undefined, date: Dayjs) {
    const { id, name } = project || {}
    const projectName = processProjectName(name || '')
    const brokerName = import.meta.env.VITE_BROKER
    const brokerNameProcessed = brokerName.replace(/\s+/g, '_')
    const chunkOfID = id?.slice(0, 8) ?? ''

    return (
        [brokerNameProcessed, 'balances-snapshot', chunkOfID, projectName, date.format('YYYY-MM-DD')].join('-') + '.csv'
    )
}
