/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ProjectStatusUpdate {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE'
}


// @ts-ignore
export function ProjectStatusUpdateFromJSON(json: any): ProjectStatusUpdate {
    return ProjectStatusUpdateFromJSONTyped(json, false);
}

// @ts-ignore
export function ProjectStatusUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectStatusUpdate {
    return json as ProjectStatusUpdate;
}

// @ts-ignore
export function ProjectStatusUpdateToJSON(value?: ProjectStatusUpdate | null): any {
    return value as any;
}

