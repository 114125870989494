import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    currency: {
        id: 'static-address-constructor.form.currency',
        defaultMessage: 'Currency',
    },
    currencyPlaceholder: {
        id: 'static-address-constructor.form.currency-placeholder',
        defaultMessage: 'Select currency',
    },
    network: {
        id: 'static-address-constructor.form.network',
        defaultMessage: 'Network',
    },
    networkPlaceholder: {
        id: 'static-address-constructor.form.network-placeholder',
        defaultMessage: 'Select network',
    },
    customerId: {
        id: 'static-address-constructor.form.customer_id',
        defaultMessage: 'Customer ID',
    },
    customerEmail: {
        id: 'static-address-constructor.form.customer_email',
        defaultMessage: 'Customer email',
    },
    feesPayer: {
        id: 'static-address-constructor.form.fees_payer',
        defaultMessage: 'Fee is paid by',
    },
    unavailableDeposits: {
        id: 'static-address-constructor.settings.unavailable_deposits_in_network',
        defaultMessage: 'Deposits in this network are temporarily unavailable',
    },
    customerIdTooltip: {
        id: 'static-address-constructor.form.customer_id-tooltip',
        defaultMessage:
            'This is an identifier for your customers. For your convenience, it can be any type (e.g., just a name/title or unique codes).',
    },
    feesPayerTooltip: {
        id: 'static-address-constructor.form.fees_payer-tooltip',
        defaultMessage:
            'Setting for who will pay commissions: according to your project Default setting (My merchant - Payments settings) or always Client (buyers) or always Merchant (your store)',
    },
})
