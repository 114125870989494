import { ModalResponsive } from '@merchant/shared/components'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Steps, modalTitleStepMap } from './const'
import { UnconfirmedTotpInfo } from './views'
import type { ModalProps } from '@merchant/ui-kit/ant-design'
import type { TOTP } from '~api/instances/cabinet-api'
import { useMerchant } from '~api'
import { Confirmation } from '~features/AddTwoFaModal/views'
import { useDirtyFormCheckerControls } from '~hooks'

interface Props extends Omit<ModalProps, 'data-merchant'> {
    selectedTotp: TOTP | null
    isTwoFaEnabled?: boolean
    onModalSuccess: (id: TOTP['id']) => void
}

export function UnconfirmedTotpModal({
    selectedTotp,
    isTwoFaEnabled = false,
    onCancel,
    onModalSuccess,
    ...rest
}: Props) {
    const { data: merchantData } = useMerchant()
    const { email = '' } = merchantData || {}
    const [step, setStep] = useState(Steps.information)
    const { handleCancel } = useDirtyFormCheckerControls(onCancel)
    if (!selectedTotp?.id) {
        return null
    }

    const views: Record<Steps, () => JSX.Element> = {
        [Steps.information]: () => (
            <UnconfirmedTotpInfo onSuccess={() => setStep(Steps.confirmation)} totpName={selectedTotp.name} />
        ),
        [Steps.confirmation]: () => (
            <Confirmation
                onSuccess={() => onModalSuccess(selectedTotp.id)}
                id={selectedTotp.id!}
                isTwoFaEnabled={isTwoFaEnabled}
                email={email}
            />
        ),
    }

    const modalTitle = <FormattedMessage {...modalTitleStepMap[step]} />

    return (
        <ModalResponsive
            data-merchant="unconfirmed-2fa-modal"
            footer={null}
            onCancel={handleCancel}
            title={modalTitle}
            {...rest}
        >
            {views[step]()}
        </ModalResponsive>
    )
}
