import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { Button } from '@merchant/ui-kit/ant-design'
import { isEmpty } from 'lodash-es'
import React from 'react'
import type { TOTP } from '~api/instances/cabinet-api'

const twoFaItemStyles: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    gap: 8,
}
const totpButtonStyles: React.CSSProperties = {
    width: 'auto',
    paddingBlock: 0,
    height: 'auto',
    display: 'flex',
}
interface Props {
    totps: TOTP[]
    onDelete: (id: TOTP['id']) => void
}

export function ConfirmedTotps({ totps, onDelete }: Props) {
    if (isEmpty(totps)) {
        return null
    }

    return totps.map(totp => (
        <div key={totp.id} style={twoFaItemStyles} data-merchant={`confirmed-totps-id-${totp.id}`}>
            {totp.name}
            <Button
                data-merchant="delete-twofa-button"
                icon={<AppSvg size={20} name={assets.trash} />}
                type="link"
                style={totpButtonStyles}
                onClick={() => onDelete(totp.id)}
            />
        </div>
    ))
}
