import { FormattedMessage } from 'react-intl'
import type { TagProps } from '@merchant/ui-kit/ant-design'
import type { Project } from '~api/instances/cabinet-api'
import { globalLang } from '~globalLang'

export const getTagProps = (isProjectLossEnabled: boolean = false): TagProps => {
    const descriptor = isProjectLossEnabled ? globalLang.enabled : globalLang.disabled

    return {
        color: isProjectLossEnabled ? 'success' : 'error',
        children: <FormattedMessage {...descriptor} />,
        style: { padding: '5px 6px' },
        bordered: false,
    }
}

export function getIsLossConfigEnabled(lossConfig: Project['lossConfig'] | undefined) {
    return !!lossConfig?.absolute || !!lossConfig?.relative
}
