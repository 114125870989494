import { EmailConfirmationFields } from '../EmailConfirmationItem/const'
import { TwoFaFields } from '../TwoFaConfirmationItem/const'
import type { MessageDescriptor } from 'react-intl'
import { APIErrorCode } from '~api/instances/cabinet-api'
import { resetPasswordFormDataKeys } from '~features/AuthorizationPage/views/PasswordRecoverySetNew/const'
import { signInFormDataKeys } from '~features/AuthorizationPage/views/SignIn/const'
import { globalLang } from '~globalLang'

export const confirmations = {
    email: {
        substrings: [APIErrorCode.InvalidOtp, APIErrorCode.VerificationLevelTooLow],
        fields: [EmailConfirmationFields.verificationCode],
    },
    signIn: {
        substrings: [APIErrorCode.AccountNotActive],
        fields: [signInFormDataKeys.email, signInFormDataKeys.password],
    },
    resetPassword: {
        substrings: [APIErrorCode.AccountNotActive],
        fields: [
            resetPasswordFormDataKeys.newPassword,
            resetPasswordFormDataKeys.newPasswordConfirm,
            resetPasswordFormDataKeys.verificationCode,
        ],
    },
    '2fa': {
        substrings: [APIErrorCode.InvalidTotp, APIErrorCode.VerificationLevelTooLow],
        fields: [TwoFaFields.totpCode, TwoFaFields.totpBackup],
    },
}

export const errorCodeMessageMap: Partial<Record<APIErrorCode, MessageDescriptor>> = {
    [APIErrorCode.InvalidOtp]: globalLang.invalidCode,
    [APIErrorCode.InvalidTotp]: globalLang.invalidCode,
    [APIErrorCode.VerificationLevelTooLow]: globalLang.verificationLevelTooLowError,
    [APIErrorCode.AccountNotActive]: globalLang.accountInactive,
}
