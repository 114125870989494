import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    cancellationText: {
        id: 'withdrawal-modal.typography.cancellation_text',
        defaultMessage: 'Are you sure you want to cancel this withdrawal?',
    },
    noTransactionId: {
        id: 'withdrawal-modal.notification.no_transaction_id',
        defaultMessage: 'No transaction ID',
    },
    notCancellableWithdrawalTitle: {
        id: 'withdrawal-modal.notification.not_cancellable_title',
        defaultMessage: 'Withdrawal cannot be canceled',
    },
    notCancellableWithdrawalDescription: {
        id: 'withdrawal-modal.notification.not_cancellable_description',
        defaultMessage:
            'Sorry, withdrawal status does not allow cancellation at this stage. Please wait for further updates.',
    },
    support: {
        id: 'withdrawal-modal.typography.support',
        defaultMessage:
            'For any questions, feel free to contact our <support>support team</support> or your account manager.',
    },
})
