import { CompactCardsWrapper } from '@merchant/shared/components'
import { Flex, Space, Tag, Typography, Collapse, Divider, Button } from '@merchant/ui-kit/ant-design'
import { isEmpty } from 'lodash'
import { FormattedMessage } from 'react-intl'
import { checkIsPermission } from '../../utils'
import { lang } from './lang'
import styles from './style.module.css'
import { includePermissionPredicate } from './utils'
import type { ButtonProps, ModalProps } from '@merchant/ui-kit/ant-design'
import type { ProjectRole, Permission } from '~api/instances/cabinet-api'
import { useCurrencies } from '~api'
import { permissionsLang } from '~features/MerchantPage/components/TeamManagement/permissionsLang'

const { Text } = Typography

const commonButtonProps: Omit<ButtonProps, 'data-merchant'> = {
    size: 'large',
    block: true,
    style: { marginInline: 0 },
}

interface Props extends Pick<ModalProps, 'onCancel'> {
    onConfirm: () => void
    initRolesPermissions: ProjectRole[]
    newRolesPermissions: ProjectRole[]
}

// eslint-disable-next-line max-lines-per-function
export function PermissionsDiff({ initRolesPermissions, newRolesPermissions, onCancel, onConfirm }: Props) {
    const { data: currencies } = useCurrencies()
    const newPermissionsMap = newRolesPermissions.reduce(
        (acc, role) => {
            acc[role.id] = new Set(role.permissions)

            return acc
        },
        {} as Record<string, Set<Permission>>
    )

    const renderRolesDifferences = () => {
        return initRolesPermissions.map(role => {
            const initialPermissionsSet = new Set(role.permissions.filter(includePermissionPredicate(currencies)))
            const newPermissionsSet = newPermissionsMap[role.id] || new Set()

            const addedPermissions = [...newPermissionsSet].filter(permission => !initialPermissionsSet.has(permission))
            const removedPermissions = [...initialPermissionsSet].filter(
                permission => !newPermissionsSet.has(permission)
            )
            const totalChangedCount = addedPermissions.length + removedPermissions.length

            const hasChanges = totalChangedCount > 0

            return (
                <Collapse
                    data-merchant={`team-management-collapse-role-${role.id}`}
                    key={role.id}
                    bordered={false}
                    ghost
                    expandIconPosition="end"
                    items={[
                        {
                            showArrow: hasChanges,
                            extra: hasChanges ? (
                                <Text
                                    type="secondary"
                                    style={{ fontWeight: 400 }}
                                    data-merchant="team-management-permissions-to-change"
                                >
                                    <FormattedMessage
                                        {...lang.willBeChanged}
                                        values={{
                                            count: totalChangedCount,
                                            b: chunks => (
                                                <Text type="secondary" strong data-merchant={null}>
                                                    {chunks}
                                                </Text>
                                            ),
                                        }}
                                    />
                                </Text>
                            ) : (
                                <Text
                                    type="secondary"
                                    style={{
                                        opacity: 0.3,
                                        fontSize: 14,
                                        fontWeight: 400,
                                    }}
                                    data-merchant={`team-management-role-no-changes-${role.id}`}
                                >
                                    <FormattedMessage {...lang.noChanges} />
                                </Text>
                            ),
                            collapsible: hasChanges ? undefined : 'disabled',
                            headerClass: styles.collapseHeader,
                            label: role.name,
                            children: hasChanges && (
                                <>
                                    <Divider style={{ marginBlock: 16 }} />
                                    <Flex vertical gap={24}>
                                        {!isEmpty(addedPermissions) && (
                                            <CollapseContent changedPermissions={addedPermissions} mode="added" />
                                        )}
                                        {!isEmpty(removedPermissions) && (
                                            <CollapseContent changedPermissions={removedPermissions} mode="removed" />
                                        )}
                                    </Flex>
                                </>
                            ),
                        },
                    ]}
                />
            )
        })
    }

    return (
        <Flex vertical>
            <CompactCardsWrapper dataMerchantPrefix="team-management-roles">
                {renderRolesDifferences()}
            </CompactCardsWrapper>
            <Flex gap={12} style={{ marginTop: 24 }}>
                <Button
                    data-merchant="team-management-confirm-permissions-modal-cancel-button"
                    onClick={onCancel}
                    {...commonButtonProps}
                >
                    <FormattedMessage {...lang.cancelButton} />
                </Button>
                <Button
                    data-merchant="team-management-confirm-permissions-modal-confirm-button"
                    onClick={onConfirm}
                    type="primary"
                    {...commonButtonProps}
                >
                    <FormattedMessage {...lang.okButton} />
                </Button>
            </Flex>
        </Flex>
    )
}

interface CollapseContentProps {
    changedPermissions: (Permission | string)[]
    mode: 'added' | 'removed'
}

function CollapseContent({ changedPermissions, mode = 'added' }: CollapseContentProps) {
    const modeDescriptor = mode === 'added' ? lang.willBeActivated : lang.willBeDeactivated
    const modeTextType = mode === 'added' ? 'success' : 'danger'

    return (
        <Flex vertical gap={8}>
            <Flex justify="space-between">
                <Text data-merchant={null}>
                    <FormattedMessage
                        {...modeDescriptor}
                        values={{
                            highlight: chunks => (
                                <Text
                                    data-merchant={`team-management-changed-permissions-${mode}-postfix`}
                                    strong
                                    type={modeTextType}
                                >
                                    {chunks}
                                </Text>
                            ),
                        }}
                    />
                </Text>
                <Text type={modeTextType} data-merchant={`team-management-changed-permissions-${mode}-count`}>
                    <FormattedMessage {...lang.countPermissions} values={{ count: changedPermissions.length }} />
                </Text>
            </Flex>
            <Space size={[4, 10]} direction="horizontal" wrap>
                {changedPermissions.map(permission => (
                    <Tag
                        bordered={false}
                        key={permission}
                        style={{ marginInline: 0 }}
                        data-merchant={`team-management-permission-tag-${mode}-${permission}`}
                    >
                        <Text style={{ fontSize: 12 }} data-merchant={null}>
                            {checkIsPermission(permission) ? (
                                <FormattedMessage {...permissionsLang[permission]} />
                            ) : (
                                permission
                            )}
                        </Text>
                    </Tag>
                ))}
            </Space>
        </Flex>
    )
}
