/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WithdrawalThumb } from './WithdrawalThumb';
import {
    WithdrawalThumbFromJSON,
    WithdrawalThumbFromJSONTyped,
    WithdrawalThumbToJSON,
} from './WithdrawalThumb';

/**
 * 
 * @export
 * @interface ListProjectWithdrawals200Response
 */
export interface ListProjectWithdrawals200Response {
    /**
     * 
     * @type {string}
     * @memberof ListProjectWithdrawals200Response
     */
    cursorNext?: string;
    /**
     * 
     * @type {string}
     * @memberof ListProjectWithdrawals200Response
     */
    cursorPrev?: string;
    /**
     * 
     * @type {Array<WithdrawalThumb>}
     * @memberof ListProjectWithdrawals200Response
     */
    withdrawals?: Array<WithdrawalThumb>;
}

/**
 * Check if a given object implements the ListProjectWithdrawals200Response interface.
 */
// @ts-ignore
export function instanceOfListProjectWithdrawals200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

// @ts-ignore
export function ListProjectWithdrawals200ResponseFromJSON(json: any): ListProjectWithdrawals200Response {
    return ListProjectWithdrawals200ResponseFromJSONTyped(json, false);
}

// @ts-ignore
export function ListProjectWithdrawals200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListProjectWithdrawals200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cursorNext': !exists(json, 'cursor_next') ? undefined : json['cursor_next'],
        'cursorPrev': !exists(json, 'cursor_prev') ? undefined : json['cursor_prev'],
        'withdrawals': !exists(json, 'withdrawals') ? undefined : ((json['withdrawals'] as Array<any>).map(WithdrawalThumbFromJSON)),
    };
}

// @ts-ignore
export function ListProjectWithdrawals200ResponseToJSON(value?: ListProjectWithdrawals200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cursor_next': value.cursorNext,
        'cursor_prev': value.cursorPrev,
        'withdrawals': value.withdrawals === undefined ? undefined : ((value.withdrawals as Array<any>).map(WithdrawalThumbToJSON)),
    };
}

