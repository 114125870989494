import { AddressCard } from '../components'
import type { ProjectAddress } from '~api'
import { useDepositAddress } from '~api'

interface Props {
    id: ProjectAddress['id']
}

export function ExistingAddressCard({ id }: Props) {
    const { data: address, isLoading, error } = useDepositAddress({ addressId: id })

    return <AddressCard address={address} isLoading={isLoading} state={null} context="existing" error={error} />
}
