import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    createdAt: {
        id: 'addresses-filters.form.created_at',
        defaultMessage: 'Created at',
    },
    id: {
        id: 'addresses-filters.form.id',
        defaultMessage: 'ID',
    },
    currency: {
        id: 'addresses-filters.form.currency',
        defaultMessage: 'Currency',
    },
    network: {
        id: 'addresses-filters.form.network',
        defaultMessage: 'Network',
    },
    address: {
        id: 'addresses-filters.form.address',
        defaultMessage: 'Address',
    },
    tag: {
        id: 'addresses-filters.form.tag',
        defaultMessage: 'Tag',
    },
    collapseTitle: {
        id: 'addresses-filters.form.collapse_title',
        defaultMessage: 'Filters',
    },
    apply: {
        id: 'addresses-filters.button.apply',
        defaultMessage: 'Apply',
    },
    clear: {
        id: 'addresses-filters.button.clear',
        defaultMessage: 'Clear',
    },
})
