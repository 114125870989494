import { EllipsisOutlined } from '@ant-design/icons'
import { EmailSupportLink, ModalResponsive } from '@merchant/shared/components'
import { useBreakpoint } from '@merchant/shared/hooks'
import { Button, Space, Tooltip, Typography, theme } from '@merchant/ui-kit/ant-design'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { useBoolean } from 'usehooks-ts'
import { lang } from '../lang'
import { modalStyles } from '../styles'
import type { ReactNode } from 'react'
import { Permission, type Balance, type Currency } from '~api/instances/cabinet-api'
import { ActionButton } from '~components'
import { QueryParams } from '~constants/routes'
import { useUserPermissionContext } from '~contexts'
import { globalLang } from '~globalLang'
import { Actions } from '~types/actionModal'
import { getIfCurrencyWithdrawable, isSwappablePredicate, generateQuery } from '~utils'

const { Text } = Typography
const { useToken } = theme

const overlayCommonInnerStyle = { fontSize: 12 }

interface Props {
    balance: Balance
    currency: Currency | undefined
    isConvertAvailable: boolean
    isMobile?: boolean
    onActionClick?: () => void
    isDepositAvailable: boolean
}

// eslint-disable-next-line complexity, max-lines-per-function
function BalanceActionButtons({
    balance,
    currency,
    isConvertAvailable,
    isMobile,
    onActionClick,
    isDepositAvailable,
}: Props) {
    const { checkUserPermission } = useUserPermissionContext()
    const hasAmount = Number(balance.amount) > 0
    const isPayoutEnabled = getIfCurrencyWithdrawable(currency)
    const insufficientToWithdraw = <FormattedMessage {...globalLang.insufficientFundsToWithdraw} />
    const notSupportedWithdrawal = <FormattedMessage {...lang.disabledWithdrawal} />

    let withdrawalTooltipTitle: ReactNode | null = null

    if (!hasAmount) {
        withdrawalTooltipTitle = insufficientToWithdraw
    } else if (!isPayoutEnabled) {
        withdrawalTooltipTitle = notSupportedWithdrawal
    }

    let depositTooltipTitle: ReactNode = null
    if (!currency?.crypto) {
        const convertLink = generateQuery({ action: Actions.convert, to_currency: balance.currency })
        depositTooltipTitle = (
            <FormattedMessage
                {...lang.unavailableFiatDeposit}
                values={{
                    currency: balance.currency,
                    supportLink: chunks => <EmailSupportLink style={{ fontSize: 12 }}>{chunks}</EmailSupportLink>,
                    convertLink: chunks => (
                        <Link to={convertLink}>
                            <span style={{ fontSize: 12 }}>{chunks}</span>
                        </Link>
                    ),
                }}
            />
        )
    } else if (!isDepositAvailable) {
        depositTooltipTitle = (
            <FormattedMessage {...lang.unavailableCryptoDeposit} values={{ currency: balance.currency }} />
        )
    }
    const withdrawButtonDisabled = !isPayoutEnabled || !hasAmount
    const buttonsSize = isMobile ? 'large' : undefined
    const withdrawalButtonAction = currency?.crypto ? Actions.withdrawalCrypto : Actions.withdrawalFiat

    const getConvertButton = () => {
        if (!isConvertAvailable) {
            return null
        }
        const hasCurrenciesToConvertTo = isSwappablePredicate(currency)
        let convertTooltipTitle: ReactNode | null = null
        const convertButtonDisabled = !hasCurrenciesToConvertTo || !hasAmount

        if (!hasCurrenciesToConvertTo) {
            convertTooltipTitle = <FormattedMessage {...lang.temporarilyUnavailableConvert} />
        } else if (!hasAmount) {
            convertTooltipTitle = <FormattedMessage {...globalLang.insufficientFundsToConvert} />
        }

        return (
            <Tooltip
                placement="bottom"
                title={convertTooltipTitle}
                overlayInnerStyle={overlayCommonInnerStyle}
                data-merchant="balance-convert-button-tooltip"
            >
                <span style={{ cursor: convertButtonDisabled ? 'not-allowed' : 'pointer' }}>
                    <ActionButton
                        onClick={onActionClick}
                        label="convert"
                        context="balance"
                        data-merchant={`balances-${balance.currency}-convert-button`}
                        action={Actions.convert}
                        currencyCode={balance.currency}
                        disabled={convertButtonDisabled}
                        size={buttonsSize}
                        style={{
                            width: isMobile ? '100%' : 100,
                            pointerEvents: convertButtonDisabled ? 'none' : 'auto',
                        }}
                    />
                </span>
            </Tooltip>
        )
    }

    return (
        <Space
            wrap={false}
            align="end"
            direction={isMobile ? 'vertical' : 'horizontal'}
            size={12}
            style={{ width: isMobile ? '100%' : undefined }}
            styles={{
                item: { flex: '1 1 0px', width: isMobile ? '100%' : undefined },
            }}
        >
            {checkUserPermission(Permission.CreateDepositAddress) && (
                <Tooltip
                    placement="bottom"
                    title={depositTooltipTitle}
                    overlayInnerStyle={overlayCommonInnerStyle}
                    data-merchant="balances-deposit-button-tooltip"
                >
                    <span
                        style={{
                            cursor: !isDepositAvailable ? 'not-allowed' : 'auto',
                        }}
                    >
                        <ActionButton
                            label="deposit"
                            onClick={onActionClick}
                            data-merchant={`balances-${balance.currency}-deposit-button`}
                            currencyCode={balance.currency}
                            action={Actions.deposit}
                            queryParams={{ [QueryParams.depositCurrency]: balance.currency }}
                            disabled={!isDepositAvailable}
                            size={buttonsSize}
                            context="balance"
                            style={{
                                width: isMobile ? '100%' : 100,
                                pointerEvents: isDepositAvailable ? 'all' : 'none',
                            }}
                        />
                    </span>
                </Tooltip>
            )}
            <Tooltip
                placement="bottom"
                title={withdrawalTooltipTitle}
                overlayInnerStyle={overlayCommonInnerStyle}
                data-merchant="balances-withdraw-button-tooltip"
            >
                <span
                    style={{
                        cursor: withdrawButtonDisabled ? 'not-allowed' : 'auto',
                    }}
                >
                    <ActionButton
                        label="withdrawal"
                        onClick={onActionClick}
                        data-merchant={`balances-${balance.currency}-withdraw-button`}
                        action={withdrawalButtonAction}
                        currencyCode={balance.currency}
                        disabled={withdrawButtonDisabled}
                        size={buttonsSize}
                        context="balance"
                        style={{
                            width: isMobile ? '100%' : 100,
                            pointerEvents: !withdrawButtonDisabled ? 'all' : 'none',
                        }}
                    />
                </span>
            </Tooltip>
            {getConvertButton()}
        </Space>
    )
}

export function BalanceActions({ balance, currency, isConvertAvailable, onActionClick, isDepositAvailable }: Props) {
    const { value: actionModalOpen, setTrue: openActionsModal, setFalse: closeActionsModal } = useBoolean()
    const breakpoints = useBreakpoint()
    const { token } = useToken()
    const hasAmount = Number(balance.amount) > 0

    const canNotWithdrawOrConvert = <FormattedMessage {...globalLang.insufficientFundsToConvertOrWithdraw} />

    const isPayoutEnabled = getIfCurrencyWithdrawable(currency)

    const hasAvailableActions = hasAmount || isPayoutEnabled
    const modalTitle = (
        <>
            <Text data-merchant={null}>{balance.currency}</Text>{' '}
            <Text type="secondary" data-merchant={null}>
                {currency?.name}
            </Text>
        </>
    )

    if (!breakpoints.md) {
        return (
            <>
                <ModalResponsive
                    title={<span data-merchant="balance-actions-modal-title">{modalTitle}</span>}
                    open={actionModalOpen}
                    onCancel={closeActionsModal}
                    footer={null}
                    style={modalStyles}
                    data-merchant="balance-actions-modal"
                >
                    <BalanceActionButtons
                        balance={balance}
                        currency={currency}
                        isConvertAvailable={isConvertAvailable}
                        isMobile
                        onActionClick={closeActionsModal}
                        isDepositAvailable={isDepositAvailable}
                    />
                </ModalResponsive>
                <Tooltip
                    title={hasAvailableActions ? null : canNotWithdrawOrConvert}
                    data-merchant="actions-unavailability-tooltip"
                >
                    <span
                        style={{
                            cursor: !hasAvailableActions ? 'not-allowed' : 'auto',
                        }}
                    >
                        <Button
                            disabled={!hasAvailableActions}
                            onClick={openActionsModal}
                            style={{
                                width: 40,
                                height: 40,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            data-merchant="balance-actions-button"
                        >
                            <EllipsisOutlined
                                style={{
                                    fontSize: 18,
                                    strokeWidth: '80',
                                    stroke: hasAvailableActions ? token.colorText : token.colorTextPlaceholder,
                                }}
                            />
                        </Button>
                    </span>
                </Tooltip>
            </>
        )
    }

    return (
        <BalanceActionButtons
            balance={balance}
            currency={currency}
            isConvertAvailable={isConvertAvailable}
            onActionClick={onActionClick}
            isDepositAvailable={isDepositAvailable}
        />
    )
}
