import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    system: {
        id: 'shared.theme-switcher.typography.system',
        defaultMessage: 'System',
    },
    light: {
        id: 'shared.theme-switcher.typography.light',
        defaultMessage: 'Light',
    },
    dark: {
        id: 'shared.theme-switcher.typography.dark',
        defaultMessage: 'Dark',
    },
})
