import { maxProjectNameCharsInCSV } from '~constants/settings'

export function processProjectName(projectName: string) {
    // Check that projectName is defined and is a string
    if (!projectName || typeof projectName !== 'string') {
        return 'merchant'
    }

    const result = projectName
        .replace(/\s+/g, '_') // Replace spaces with underscores
        .replace(/[^A-Za-z0-9_]/g, '') // Keep only Latin letters, numbers, and underscores
        .toLowerCase() // Convert all letters to lowercase
        .replace(/_+/g, '_') // Replace multiple consecutive underscores with a single underscore
        .replace(/^_+|_+$/g, '') // Remove underscores at the beginning and end of the string
        .slice(0, maxProjectNameCharsInCSV) // Limit the length to 50 characters

    return !result || result.replace(/_/g, '') === '' ? 'merchant' : result
}
