import { StyledQrCode } from '@merchant/shared/components'
import { Flex, Tooltip, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from './lang'

const { Text } = Typography

export function AppQR() {
    return (
        <Tooltip
            overlayStyle={{ maxWidth: '500px' }}
            overlayInnerStyle={{ padding: '24px 12px' }}
            title={
                <Flex vertical gap={12} align="center">
                    <Text data-merchant={null} style={{ fontSize: 16, fontWeight: 500 }}>
                        <FormattedMessage {...lang.scanToDownload} />
                    </Text>
                    <StyledQrCode
                        dataMerchant="add2fa-authenticator-app-download-qr-code"
                        value="https://onelink.to/kj3qy2"
                    />
                </Flex>
            }
            data-merchant="add2fa-authenticator-app-hint-tooltip"
        >
            <Text type="success" data-merchant="add2fa-authenticator-app-hint-text">
                <FormattedMessage {...lang.recommendedApp} />
            </Text>
        </Tooltip>
    )
}
