import { assets } from '@merchant/shared/assets/nameMap'
import { AppSvg } from '@merchant/shared/components'
import { Typography } from '@merchant/ui-kit/ant-design'
import { StorePlatform } from './const'

const { Link } = Typography

interface Props {
    store: StorePlatform
    href: string
}

/**
 * Application store button (StoreButton) component
 */
export function StoreButton({ store, href }: Props) {
    const dataMerchantPrefix = store === StorePlatform.Apple ? 'apple-market' : 'google-market'

    return (
        <Link href={href} target="_blank" data-merchant={`${dataMerchantPrefix}-link`}>
            {store === StorePlatform.Apple && <AppSvg size={{ width: 111, height: 38 }} name={assets.getAppStore} />}
            {store === StorePlatform.Google && <AppSvg size={{ width: 111, height: 38 }} name={assets.getGooglePay} />}
        </Link>
    )
}
