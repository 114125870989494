import type { EditApiKeyFormData } from './types'
import type { FormItemsKey } from '@merchant/shared/types'

export enum Steps {
    edit,
    twoFaConfirmation,
}

export const formItemsKey: FormItemsKey<EditApiKeyFormData> = {
    publicKey: 'publicKey',
    name: 'name',
    privateKeyMask: 'privateKeyMask',
    permissions: 'permissions',
}
