import { capitalize } from 'lodash-es'
import type { ErrorInner } from '~api/httpError/types'
import { HttpError } from '~api'

// const processMissingFieldError = (error: { field: string }): string =>
//     `Required field "${error.field}" is missing or empty in request body`

export const processErrorResponse = (error: unknown): string => {
    if (HttpError.isInstance(error)) {
        if (error.status === 429) {
            return 'Too many requests. Please try again later.'
        }

        if ((error.responseData?.errors?.length ?? 0) > 0) {
            return error.responseData?.errors?.map(({ message }) => capitalize(message)).join('; ') || ''
        }
    }

    // TODO: find out the way to use shared types from cabinet and checkout
    // if (isInstanceOf(error, blueprint)) {
    //     return processMissingFieldError(error)
    // }

    return ''
}

// TODO: make it good
export const getIfErrorHasSubstring = <T extends ErrorInner>(
    error: unknown,
    testString: string,
    field: keyof T
): boolean | undefined =>
    HttpError.isInstance(error) &&
    (error.responseData?.errors as T[] | undefined)?.some(
        e => typeof e[field] === 'string' && e[field].includes(testString)
    )
