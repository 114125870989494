import { EmailSupportLink } from '@merchant/shared/components'
import { useBreakpoint } from '@merchant/shared/hooks'
import { Select, Flex, List, Tooltip, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { lang } from '../../lang'
import { getCommonFlexProps, listTitleStyles, getSelectStyles } from '../../styles'
import { languages } from './const'
import type { ListItemProps } from '@merchant/ui-kit/ant-design/es/list'

const { Text } = Typography
const { Option } = Select

interface Props extends Omit<ListItemProps, 'onChange'> {
    isLoading: boolean
    value: string | undefined
}

export function LanguageItem({ isLoading, value, ...rest }: Props) {
    const breakpoints = useBreakpoint()
    const commonFlexProps = getCommonFlexProps(!breakpoints.md)

    return (
        <List.Item {...rest}>
            <Flex {...commonFlexProps}>
                <List.Item.Meta
                    title={
                        <Text style={listTitleStyles} data-merchant={null}>
                            <FormattedMessage {...lang.language} />
                        </Text>
                    }
                    data-merchant="settings-language-list-item-meta"
                />
                <Tooltip
                    title={
                        <Text type="secondary" data-merchant={null}>
                            <FormattedMessage
                                {...lang.languageTooltip}
                                values={{
                                    support: <EmailSupportLink />,
                                }}
                            />
                        </Text>
                    }
                    placement="bottom"
                    data-merchant="settings-language-tooltip"
                >
                    <Select
                        size="large"
                        style={getSelectStyles({ breakpoints, disabled: true })}
                        data-merchant="settings-language-select"
                        loading={isLoading}
                        value={value}
                        popupMatchSelectWidth={false}
                        placement="bottomRight"
                        disabled
                    >
                        {Object.entries(languages).map(([code, languageObj]) => (
                            <Option key={code} value={code} data-merchant={`settings-language-option-${code}`}>
                                <span
                                    style={{
                                        verticalAlign: 'middle',
                                        fontSize: 18,
                                    }}
                                >
                                    {languageObj.flag}
                                </span>
                                <Text type="secondary" data-merchant={null}>
                                    &nbsp;∙&nbsp;
                                </Text>
                                <Text data-merchant={null}>{languageObj.name}</Text>
                            </Option>
                        ))}
                    </Select>
                </Tooltip>
            </Flex>
        </List.Item>
    )
}
