import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    editCurrenciesButton: {
        id: 'currencies-settings.button.edit_currencies',
        defaultMessage: 'Edit accumulation currencies',
    },
    chooseCurrencies: {
        id: 'currencies-settings.button.choose_currencies',
        defaultMessage: 'Choose accumulation crypto currencies',
    },
    disableConversionCurrencyTooltipPrefix: {
        id: 'currencies-settings.tooltip.disable_conversion_currency_prefix',
        defaultMessage: 'You cannot disable conversion currency:',
    },
    disableConversionCurrencyTooltip: {
        id: 'currencies-settings.tooltip.disable_conversion_currency',
        defaultMessage:
            'This currency is currently set as your auto-conversion currency. To disable it, first select a different auto-conversion currency.',
    },

    congratulationModalContentTitle: {
        id: 'currencies-settings.modal.content_title',
        defaultMessage: '🎉',
    },
    congratulationModalContentSubtitle: {
        id: 'currencies-settings.modal.content_subtitle',
        defaultMessage: 'Congratulations',
    },
    congratulationModalContentText: {
        id: 'currencies-settings.modal.content_text',
        defaultMessage: 'Currency settings are completed. You can change settings on this page at any time.',
    },
    congratulationModalButton: {
        id: 'currencies-settings.modal.ok_button',
        defaultMessage: 'Got it',
    },

    stepOneTitle: {
        id: 'currencies-settings.typography.step_one_title',
        defaultMessage: 'Crypto payment options',
    },
    stepOneDescription: {
        id: 'currencies-settings.typography.step_one_description',
        defaultMessage: 'Your customers can pay with any of the 75+ supported cryptocurrencies.',
    },
    stepOneButton: {
        id: 'currencies-settings.typography.step_one_button',
        defaultMessage: 'Understood, Go next',
    },

    stepTwoTitle: {
        id: 'currencies-settings.typography.step_two_title',
        defaultMessage: 'Default conversion currency',
    },
    stepTwoDescription: {
        id: 'currencies-settings.typography.step_two_description',
        defaultMessage:
            'All incoming payments will automatically be converted to this currency unless specified otherwise below. Accumulating in stablecoins minimizes volatility risks and ensures balance stability.',
    },
    stepTwoButton: {
        id: 'currencies-settings.typography.step_two_button',
        defaultMessage: 'Got it, Next step',
    },

    stepThreeTitle: {
        id: 'currencies-settings.typography.step_three_title',
        defaultMessage: 'Direct accumulation · <tag>Optional</tag>',
    },
    stepThreeDescription: {
        id: 'currencies-settings.typography.step_three_description',
        defaultMessage: 'Select the cryptocurrencies you want to receive directly without conversion.',
    },
    stepThreeButton: {
        id: 'currencies-settings.typography.step_three_button',
        defaultMessage: 'All Set, Confirm settings',
    },
    stepThreeButtonTooltip: {
        id: 'currencies-settings.tooltip.step_three_button_tooltip',
        defaultMessage: 'After confirmation, settings can be changed anytime',
    },
})
