import { sortWithUndefinedAtBottom } from '@merchant/shared/utils'
import { isEmpty } from 'lodash-es'
import { useMemo, useState } from 'react'
import type { WhitelistWithKey } from '~api/swr/rest/types'
import { useWhitelist } from '~api'

interface Props {
    filteredData: WhitelistWithKey[]
}

export function useListFilters({ filteredData }: Props) {
    const { data: { addresses = [] } = {} } = useWhitelist()
    const [selectedCurrencyFilter, setSelectedCurrencyFilter] = useState<WhitelistWithKey['currency'][]>([])
    const [selectedNetworkFilter, setSelectedNetworkFilter] = useState<WhitelistWithKey['network'][]>([])
    const [fieldToSortBy, setFieldToSortBy] = useState<keyof WhitelistWithKey>()

    const sortedData = useMemo(() => {
        if (!fieldToSortBy) {
            return filteredData
        }

        return sortWithUndefinedAtBottom({
            data: filteredData,
            field: fieldToSortBy,
            direction: 'asc',
            compareFn: (a, b) => {
                const aValue = a[fieldToSortBy] ?? ''
                const bValue = b[fieldToSortBy] ?? ''

                return bValue.localeCompare(aValue)
            },
        })
    }, [filteredData, fieldToSortBy])

    const { currencyFilterOptions, networkFilterOptions } = useMemo(() => {
        const filterOptions = addresses.reduce(
            (acc, address) => {
                if (address.currency) {
                    acc.currencyFilterOptionsSet.add(address.currency)
                }
                if (address.network) {
                    acc.networkFilterOptionsSet.add(address.network)
                }

                return acc
            },
            {
                currencyFilterOptionsSet: new Set<NonNullable<WhitelistWithKey['currency']>>(),
                networkFilterOptionsSet: new Set<NonNullable<WhitelistWithKey['network']>>(),
            }
        )

        return {
            currencyFilterOptions: Array.from(filterOptions.currencyFilterOptionsSet).map(option => ({
                value: option,
                label: option,
            })),
            networkFilterOptions: Array.from(filterOptions.networkFilterOptionsSet).map(option => ({
                value: option,
                label: option,
            })),
        }
    }, [addresses])

    const filteredListData = useMemo(() => {
        return sortedData.filter(item => {
            return (
                (isEmpty(selectedCurrencyFilter) || selectedCurrencyFilter.includes(item.currency)) &&
                (isEmpty(selectedNetworkFilter) || selectedNetworkFilter.includes(item.network))
            )
        })
    }, [selectedCurrencyFilter, selectedNetworkFilter, sortedData])

    return {
        currencyFilterOptions,
        selectedCurrencyFilter,
        setSelectedCurrencyFilter,
        selectedNetworkFilter,
        setSelectedNetworkFilter,
        fieldToSortBy,
        setFieldToSortBy,
        filteredListData,
        networkFilterOptions,
    }
}
