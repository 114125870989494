import { authTokenKey, selectedProjectIdKey } from '@merchant/shared/constants/localStorage'
import { createGenericContext } from '@merchant/shared/utils'
import { isEmpty } from 'lodash-es'
import { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useReadLocalStorage } from 'usehooks-ts'
import type { Permission } from '~api/instances/cabinet-api'
import { useMerchant, useProjects, useProjectRoles, useProject } from '~api'
import { routes } from '~constants/routes'

const VITE_MODE = import.meta.env.MODE

interface Context {
    checkUserPermission: (
        permission: Permission | Permission[] | boolean,
        options?: { strategy?: 'every' | 'some' }
    ) => boolean
    isUserPermissionInitialized: boolean
}

const [useUserPermissionContext, Provider] = createGenericContext<Context>()

function UserPermissionProvider({ children }: { children: React.ReactNode }) {
    const location = useLocation()
    const authToken = useReadLocalStorage<string>(authTokenKey)
    const selectedProject = useReadLocalStorage<string>(selectedProjectIdKey)
    const { data: merchant, isLoading: isMerchantLoading, error: merchantError } = useMerchant()
    const { data: projects, isLoading: isProjectsLoading } = useProjects()
    const { data: allProjectRoles, isLoading: isProjectRolesLoading } = useProjectRoles()
    const { isLoading: isProjectLoading } = useProject()

    const selectedProjectData = useMemo(
        () => projects?.thumbs.find(project => project.id === selectedProject),
        [projects, selectedProject]
    )

    const isOwner =
        (projects && isEmpty(projects.thumbs)) ||
        (selectedProjectData && merchant && selectedProjectData.ownerId === merchant.id)

    const projectPermissionsSet = useMemo(() => {
        const permissionsSet = new Set<Permission>()

        const selectedProjectRoles = allProjectRoles?.filter(({ id }) => selectedProjectData?.roleIds?.includes(id))

        selectedProjectRoles?.forEach(role => role.permissions.forEach(permissionsSet.add, permissionsSet))

        return permissionsSet
    }, [allProjectRoles, selectedProjectData?.roleIds])

    const checkUserPermission: Context['checkUserPermission'] = useCallback(
        (permission, { strategy = 'every' } = {}) => {
            if (typeof permission === 'boolean') {
                return permission
            }

            if (isOwner || VITE_MODE === 'test') {
                return true
            }

            const fn = strategy === 'every' ? Array.prototype.every : Array.prototype.some

            return Array.isArray(permission)
                ? fn.call(permission, (item: Permission) => projectPermissionsSet.has(item))
                : projectPermissionsSet.has(permission)
        },
        [isOwner, projectPermissionsSet]
    )

    const getIsInitializing = () => {
        const isPaymentPage = location.pathname.includes(routes.payment)
        if (isOwner || !authToken || isPaymentPage) {
            return false
        }

        const hasNoMerchant = !merchant && !merchantError

        return isMerchantLoading || isProjectRolesLoading || isProjectsLoading || isProjectLoading || hasNoMerchant
    }

    return (
        <Provider
            value={{
                checkUserPermission,
                isUserPermissionInitialized: !getIsInitializing(),
            }}
        >
            {children}
        </Provider>
    )
}

export { useUserPermissionContext, UserPermissionProvider }
