import { defineMessages } from 'react-intl'

export const lang = defineMessages({
    suspensionSupport: {
        id: 'suspension-modal.suspended.support',
        defaultMessage:
            'If needed, contact {SupportLink} or your personal account manager for further assistance and explanations',
    },
    suspensionConfirmation: {
        id: 'suspension-modal.suspension.confirmation',
        defaultMessage: 'OK',
    },
    whitelistEnableBlockReason: {
        id: 'suspension-modal.suspended.whitelist_enable_block_reason',
        defaultMessage: 'Whitelist enabled',
    },
    whitelistDisableBlockReason: {
        id: 'suspension-modal.suspended.whitelist_disable_block_reason',
        defaultMessage: 'Whitelist disabled',
    },
    passwordChangeBlockReason: {
        id: 'suspension-modal.suspended.password_change_block_reason',
        defaultMessage: 'Password changed',
    },
    passwordResetBlockReason: {
        id: 'suspension-modal.suspended.password_reset_block_reason',
        defaultMessage: 'Password reset',
    },
    whitelistAddBlockReason: {
        id: 'suspension-modal.suspended.whitelist_add_block_reason',
        defaultMessage: 'Whitelist added',
    },
    whitelistRemoveBlockReason: {
        id: 'suspension-modal.suspended.whitelist_remove_block_reason',
        defaultMessage: 'Whitelist removed',
    },
    suspensionReason: {
        id: 'suspension-modal.suspended.reason',
        defaultMessage: 'Reason',
    },
    suspensionExpiresAt: {
        id: 'suspension-modal.suspended.expires_at',
        defaultMessage: 'Suspension expires at',
    },

    withdrawalSuspensionModalTitle: {
        id: 'suspension-modal.typography.withdrawal_title',
        defaultMessage: 'Withdrawals are temporarily suspended',
    },
    teamManagementSuspensionModalTitle: {
        id: 'suspension-modal.typography.team_management_title',
        defaultMessage: 'Team management is temporarily suspended',
    },
    integrationsSuspensionModalTitle: {
        id: 'suspension-modal.typography.integration_title',
        defaultMessage: 'Integrations management is temporarily suspended',
    },

    withdrawalSuspensionDescription: {
        id: 'suspension-modal.suspended.withdrawal_description',
        defaultMessage:
            "Please don't worry, this is a regular security measure. For the safety of your funds, all withdrawals have been temporarily suspended for 72 hours from the time of the recent security-related action (e.g., changes to your withdrawal whitelist, password modifications).",
    },
    teamManagementSuspensionDescription: {
        id: 'suspension-modal.suspended.team_management_description',
        defaultMessage:
            "Please don't worry, this is a regular security measure. For the safety of your funds, team management has been temporarily suspended for 72 hours from the time of the recent security-related action (e.g., changes to your withdrawal whitelist, password modifications).",
    },
    integrationsSuspensionDescription: {
        id: 'suspension-modal.suspended.integrations_description',
        defaultMessage:
            "Please don't worry, this is a regular security measure. For the safety of your funds, integrations management has been temporarily suspended for 72 hours from the time of the recent security-related action (e.g., changes to your withdrawal whitelist, password modifications).",
    },

    withdrawalAlertMessage: {
        id: 'suspension-modal.alert.withdrawal_message',
        defaultMessage: 'Your withdrawals are temporarily suspended.',
    },
    teamManagementAlertMessage: {
        id: 'suspension-modal.alert.team_management_message',
        defaultMessage: 'Team management from your account is temporarily suspended.',
    },
    integrationsAlertMessage: {
        id: 'suspension-modal.alert.integrations_message',
        defaultMessage: 'Integrations management from your account is temporarily suspended.',
    },
    moreInfoButton: {
        id: 'suspension-modal.alert.more_info_button',
        defaultMessage: 'More info',
    },
})
