import { FormattedMessage } from 'react-intl'
import { ApiKeyMode } from '../../const'
import { lang } from './lang'

export const permissionFullSegmentedOptions = [
    {
        label: (
            <span data-merchant="api-key-permissions-full-write">
                <FormattedMessage {...lang.all} />
            </span>
        ),
        value: ApiKeyMode.Write,
    },
    {
        label: (
            <span data-merchant="api-key-permissions-custom">
                <FormattedMessage {...lang.custom} />
            </span>
        ),
        value: null,
    },
    {
        label: (
            <span data-merchant="api-key-permissions-full-readonly">
                <FormattedMessage {...lang.readOnly} />
            </span>
        ),
        value: ApiKeyMode.Read,
    },
]
