import { Card, Flex, Typography } from '@merchant/ui-kit/ant-design'
import { FormattedMessage } from 'react-intl'
import { getRow } from '../../utils'
import { lang } from './lang'
import { getDateRow, getCopyableRow } from './utils'
import { WithdrawalActions } from './WithdrawalActions'
import type { CardProps } from '@merchant/ui-kit/ant-design'
import type { Network, Withdrawal } from '~api'
import { NoValueColumnContent } from '~components'
import { useNetworksMap } from '~hooks'

const { Text } = Typography

interface Props extends CardProps {
    data: Withdrawal | undefined
}

const getRows = ({ data, networks }: { data: Withdrawal; networks: Record<string, Network> }) => {
    const network = data.receiveNetwork && networks[data.receiveNetwork]

    return [
        getDateRow({
            label: lang.createdAt,
            value: data.createdAt,
            dataMerchant: 'withdrawal-created',
        }),
        getDateRow({
            label: lang.lastUpdatedAt,
            value: data.updatedAt,
            dataMerchant: 'withdrawal-updated',
        }),
        getCopyableRow({ label: lang.id, value: data.id, dataMerchant: 'withdrawal-id' }),
        getCopyableRow({ label: lang.orderId, value: data.orderId, dataMerchant: 'withdrawal-orderId' }),
        getCopyableRow({ label: lang.address, value: data.address, dataMerchant: 'withdrawal-address' }),
        getCopyableRow({
            label: lang.addressTag,
            value: data.addressTag,
            dataMerchant: 'withdrawal-addressTag',
        }),
        getRow(lang.note, data.note ? <Text data-merchant="withdrawal-note">{data.note}</Text> : NoValueColumnContent),
        getRow(
            lang.source,
            data.origin ? <Text data-merchant="withdrawal-origin">{data.origin}</Text> : NoValueColumnContent
        ),
        getRow(
            lang.creditAmount,
            <Flex>
                <Text data-merchant="withdrawal-creditAmount">
                    {data.creditAmount} {data.creditCurrency}
                </Text>
            </Flex>
        ),
        getRow(
            lang.fee,
            <Flex>
                <Text data-merchant="withdrawal-networkFee">
                    {data.networkFee || NoValueColumnContent} {data.receiveCurrency}
                </Text>
            </Flex>
        ),
        getRow(
            lang.receiveAmount,
            <Flex>
                <Text data-merchant="withdrawal-receiveAmounts">
                    {data.receiveAmount} {data.receiveCurrency}
                </Text>
            </Flex>
        ),
        getRow(
            lang.receiveNetwork,
            <Flex>
                <Text data-merchant="withdrawal-receiveNetwork">
                    {network ? `${network.name} ${network.protocol}` : data.receiveNetwork}
                </Text>
            </Flex>
        ),
        data.nominalReceiveAmount &&
            getRow(
                lang.nominalAmount,
                <Text data-merchant="withdrawal-nominalReceiveAmount">
                    {data.nominalReceiveAmount} {data.nominalReceiveCurrency}
                </Text>
            ),
    ]
}

export function WithdrawalInfo({ data, ...rest }: Props) {
    const { networks } = useNetworksMap()
    if (!data) {
        return null
    }
    const rows = getRows({ data, networks })

    return (
        <Card styles={{ body: { padding: '16px 20px' } }} style={{ width: '100%' }} {...rest}>
            <Text data-merchant={`withdrawal-info-title-${data.id}`} style={{ marginBottom: 16, display: 'block' }}>
                <FormattedMessage {...lang.cardTitle} />
            </Text>
            <Flex vertical gap={12}>
                {rows}
            </Flex>
            <WithdrawalActions data={data} />
        </Card>
    )
}
