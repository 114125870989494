/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TransactionDepositType } from './TransactionDepositType';
import {
    TransactionDepositTypeFromJSON,
    TransactionDepositTypeFromJSONTyped,
    TransactionDepositTypeToJSON,
} from './TransactionDepositType';

/**
 * 
 * @export
 * @interface TransactionDepositDetails
 */
export interface TransactionDepositDetails {
    /**
     * 
     * @type {TransactionDepositType}
     * @memberof TransactionDepositDetails
     */
    type: TransactionDepositType;
}

/**
 * Check if a given object implements the TransactionDepositDetails interface.
 */
// @ts-ignore
export function instanceOfTransactionDepositDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

// @ts-ignore
export function TransactionDepositDetailsFromJSON(json: any): TransactionDepositDetails {
    return TransactionDepositDetailsFromJSONTyped(json, false);
}

// @ts-ignore
export function TransactionDepositDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionDepositDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': TransactionDepositTypeFromJSON(json['type']),
    };
}

// @ts-ignore
export function TransactionDepositDetailsToJSON(value?: TransactionDepositDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': TransactionDepositTypeToJSON(value.type),
    };
}

