import type { ConvertData } from './types'

export const initConvertData: ConvertData = {
    send: {
        value: '',
        currency: '',
    },
    receive: {
        value: '',
        currency: '',
    },
}
