/* tslint:disable */
/* eslint-disable */
/**
 * Merchant Solution Cabinet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-draft
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SwapStatus {
    Pending = 'PENDING',
    Completed = 'COMPLETED',
    Failed = 'FAILED',
    RolledBack = 'ROLLED_BACK'
}


// @ts-ignore
export function SwapStatusFromJSON(json: any): SwapStatus {
    return SwapStatusFromJSONTyped(json, false);
}

// @ts-ignore
export function SwapStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwapStatus {
    return json as SwapStatus;
}

// @ts-ignore
export function SwapStatusToJSON(value?: SwapStatus | null): any {
    return value as any;
}

